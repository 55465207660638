import {
  BankIcon,
  CreditCardIcon,
  FileIcon,
  IconWrapperProps,
} from '@popsure/dirty-swan';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import {
  ActionListItem,
  ActionsList,
} from 'features/accountV2/components/ActionsList';
import { Link } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

export const BillingAndPayment = () => {
  const { t } = useSafeTranslation();

  const iconProps: IconWrapperProps = {
    size: 24,
    noMargin: true,
  };

  const billingPaymentItems: ActionListItem[] = [
    {
      title: t(
        'account.paymentMethods.paymentMethodButton.caption',
        'Payment method'
      ),
      icon: <CreditCardIcon {...iconProps} />,
      key: 'paymentMethod',
      as: Link,
      to: routes.me.paymentMethods.path,
    },
    {
      title: t(
        'account.paymentMethods.billingHistoryButton.caption',
        'Billing history'
      ),
      icon: <FileIcon {...iconProps} />,
      key: 'billingHistory',
      as: Link,
      to: routes.account.billingHistory.path,
    },
    {
      title: t(
        'account.paymentMethods.taxStatementsButton.caption',
        'Tax statements'
      ),
      icon: <BankIcon {...iconProps} />,
      as: Link,
      to: routes.account.taxStatements.path,
      key: 'taxStatements',
    },
  ];

  return (
    <div className="p-container wmx8">
      <NavButton
        title={t('account.backButton.caption', 'Account')}
        path={routes.account.path}
      />

      <h1 className="p-h1 mt8">
        {t('account.billingPayment.title', 'Billing and payment')}
      </h1>

      <ActionsList items={billingPaymentItems} />
    </div>
  );
};
