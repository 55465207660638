import * as Sentry from '@sentry/react';
import { InsuranceTypes } from 'models/insurances/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { pollCheckoutDocuments, storeDocuments } from '../actions';
import { UseCheckoutDocumentsReturnValues } from '../models';

const TIMEOUT_VALUE = 60_000;
const POLLING_INTERVAL = 1_000;

export const usePollCheckoutDocuments = (
  verticalId: InsuranceTypes,
  questionnaireId: string | undefined
): UseCheckoutDocumentsReturnValues => {
  const dispatch = useDispatch();

  const [documentsLoading, setDocumentsLoading] = useState(true);
  const [documentsError, setDocumentsError] = useState(false);

  const interval = useRef<NodeJS.Timeout>();
  const timeout = useRef<NodeJS.Timeout>();
  const isPollingActive = useRef(false);

  const stopPolling = useCallback(async () => {
    clearInterval(interval.current as NodeJS.Timeout);
    clearTimeout(timeout.current as NodeJS.Timeout);
    setDocumentsLoading(false);
    isPollingActive.current = false;
  }, []);

  const handleTimeout = useCallback(() => {
    stopPolling().then(() => {
      // We don't have an exception to capture if the timeout completes without the status changing
      // We still log an error message to Sentry as the user won't get to the checkout screen
      const error = {
        message: `${verticalId} signup: Failed to fetch checkout documents within the time limit`,
      };
      Sentry.captureException(error.message, {
        tags: { feature: 'CHECKOUT', priority: 'investigate-immediately' },
        level: 'fatal',
      });

      setDocumentsError(true);
    });
  }, [stopPolling, verticalId]);

  const startPollingDocuments = useCallback(async () => {
    isPollingActive.current = true;

    timeout.current = setTimeout(() => handleTimeout(), TIMEOUT_VALUE);

    interval.current = setInterval(async () => {
      const result = await pollCheckoutDocuments(verticalId, questionnaireId);

      if (!isPollingActive.current) return;

      if (result.status === 'FETCHING') {
        setDocumentsLoading(true);
      }

      if (result.status === 'COMPLETED') {
        stopPolling().then(() => {
          if (questionnaireId) {
            dispatch(storeDocuments(questionnaireId, result.payload));
          } else {
            setDocumentsError(true);
          }
        });
      }

      if (result.status === 'ERROR') {
        stopPolling().then(() => {
          setDocumentsError(true);
        });
      }
    }, POLLING_INTERVAL);
  }, [dispatch, handleTimeout, questionnaireId, stopPolling, verticalId]);

  useEffect(() => {
    return () => {
      isPollingActive.current = false;
      clearInterval(interval.current as NodeJS.Timeout);
      clearTimeout(timeout.current as NodeJS.Timeout);
    };
  }, []);

  return {
    documentsLoading,
    documentsError,
    startPollingDocuments,
  };
};
