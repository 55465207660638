import Fuse from 'fuse.js';

export const fuzzySearchOptions = <T, K extends keyof T>(
  searchString: string,
  list: T[],
  key: K
): T[] => {
  const fuse = new Fuse(list, {
    keys: [String(key)],
    includeScore: true,
  });
  const result = fuse.search(searchString);
  return result.map((option) => option.item);
};
