import classNames from 'classnames';
import { isMobileApp } from 'shared/util/isMobileApp';

import styles from './style.module.scss';

interface Props {
  title: string;
  icon: string | JSX.Element;
  onClick?: () => void;
  rightIcon?: string;
}

const ButtonCell = ({ title, icon, onClick, rightIcon, ...props }: Props) => (
  <button
    type="button"
    className={classNames(
      'br8 bg-white d-flex ai-center jc-between c-pointer',
      styles.buttonContainer,
      {
        [styles['button-container--no-hover']]: isMobileApp,
      }
    )}
    onClick={onClick}
    {...props}
  >
    <div className={styles.buttonContainerContent}>
      {typeof icon === 'string' ? (
        <img className={styles.icon} src={icon} alt="" />
      ) : (
        icon
      )}
      <div className={styles.title}>{title}</div>
    </div>
    {rightIcon && (
      <img className={styles['right-icon']} src={rightIcon} alt="" />
    )}
  </button>
);

export default ButtonCell;
