import { Button, LogOutIcon } from '@popsure/dirty-swan';
import LanguageSwitcher from 'components/LanguageSwitcher';
import { User } from 'models/user';
import { useState } from 'react';
import { Locale, useSafeTranslation } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';

import SvnrModal from '../SvnrModal';
import styles from './AccountHeader.module.scss';

interface AccountHeaderProps {
  accountInfo: User;
  onLanguageUpdate: (locale: Locale) => void;
  onSignOut: () => void;
  showLanguageSwitcher: boolean;
}

export const AccountHeader = ({
  accountInfo,
  showLanguageSwitcher,
  onLanguageUpdate,
  onSignOut,
}: AccountHeaderProps) => {
  const { t } = useSafeTranslation();

  const [showSvnrModal, setShowSvnrModal] = useState(false);
  const { email, firstName, lastName, svnr } = accountInfo;

  const fullName =
    firstName && lastName ? capitalizeName({ firstName, lastName }) : undefined;

  return (
    <div className={styles.wrapper}>
      <div>
        <div className="d-flex ai-center jc-between mb24">
          <div className="d-flex ai-center gap16">
            <h1 className="p-h1">{t('account.mainPage.title', 'Account')}</h1>

            {showLanguageSwitcher && (
              <LanguageSwitcher onUpdate={onLanguageUpdate} />
            )}
          </div>

          <Button
            className={styles.signOutButton}
            leftIcon={<LogOutIcon />}
            onClick={onSignOut}
            variant="filledWhite"
          >
            {t('account.mainPage.signOut', 'Sign out')}
          </Button>
        </div>

        {fullName && <h3 className="p-h3">{fullName}</h3>}

        {email && (
          <div className={`p-p tc-grey-500 ${styles.emailText}`}>{email}</div>
        )}

        {svnr && (
          <Button
            className={styles.svnrModalButton}
            variant="textColor"
            onClick={() => setShowSvnrModal(true)}
          >
            {`${t('account.personalInfo.socialSecurityNumber.label')}: ${svnr}`}
          </Button>
        )}
      </div>

      <SvnrModal
        isOpen={showSvnrModal}
        onClose={() => setShowSvnrModal(false)}
      />
    </div>
  );
};
