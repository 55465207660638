import { Stripe, StripeElements } from '@stripe/stripe-js';
import {
  PaymentMethodsAction,
  RequestAction,
  UserAction,
} from 'constants/actions';
import { mergePaymentMethod } from 'features/paymentMethods/paymentMethods.actions';
import { PaymentMethod } from 'models/paymentMethods';
import { AppState } from 'reducers';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import api from 'shared/api';

import { createPaymentMethod } from './paymentMethodSelector.api';
import { PaymentOption } from './paymentMethodSelector.models';

export type PaymentMethodSelectorThunkDispatch = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  RequestAction | UserAction | PaymentMethodsAction
>;

export const finalizeSetupIntent =
  ({
    stripeClient,
    clientSecret,
    makeDefault,
  }: {
    stripeClient: Stripe;
    clientSecret: string;
    makeDefault: boolean;
  }) =>
  async (dispatch: PaymentMethodSelectorThunkDispatch) => {
    const { error, setupIntent } = await stripeClient.retrieveSetupIntent(
      clientSecret
    );

    if (error) {
      throw error;
    }

    if (!setupIntent) {
      throw Error('Setup intent was not in the response');
    }

    const paymentMethod = await createPaymentMethod(api.network, {
      makeDefault,
      setupIntent,
    });

    dispatch(mergePaymentMethod(paymentMethod));

    return paymentMethod;
  };

export const prepareForPayment =
  ({
    paymentOption,
    stripeClient,
    elements,
    returnUrl,
    returnPath,
  }: {
    paymentOption: PaymentOption;
    stripeClient: Stripe;
    elements: StripeElements;
    returnUrl: string;
    returnPath?: string;
  }): ThunkAction<
    Promise<PaymentMethod>,
    AppState,
    Record<string, unknown>,
    RequestAction
  > =>
  async (dispatch: PaymentMethodSelectorThunkDispatch) => {
    switch (paymentOption.type) {
      case 'READY_FOR_SETUP': {
        const { setupData } = paymentOption;

        let paymentMethod: PaymentMethod;

        if (setupData.type === 'SEPA') {
          const response = await api.addSepaPaymentMethod(
            stripeClient,
            setupData
          );
          paymentMethod = response.data;
        } else if (setupData.type === 'CARD') {
          const response = await api.addCardPaymentMethod(
            stripeClient,
            setupData
          );
          paymentMethod = response.data;
        } else {
          throw Error(`Unsupported payment method type`);
        }

        dispatch(mergePaymentMethod(paymentMethod));

        return paymentMethod;
      }
      case 'READY_FOR_SETUP_WITH_REDIRECT': {
        await elements.submit();

        const {
          data: { clientSecret },
        } = await api.startPaymentMethodSetup();

        const { error } = await stripeClient.confirmSetup({
          clientSecret,
          elements,
          confirmParams: {
            return_url: `${returnUrl}?makeDefault=${paymentOption.setupData.makeDefault}&returnPath=${returnPath}`,
          },
          redirect: 'if_required',
        });

        if (error) {
          throw error;
        }

        return dispatch(
          finalizeSetupIntent({
            stripeClient,
            clientSecret,
            makeDefault: paymentOption.setupData.makeDefault,
          })
        );
      }
      case 'EXISTING_PAYMENT_METHOD':
        return paymentOption.paymentMethod;
      case 'NOT_READY_FOR_SETUP':
      case 'NOT_STARTED':
        throw Error(
          '[Payment method selector] Attempted to set up a payment method before the payment method selector was initialized'
        );
      default:
        throw Error(
          '[Payment method selector] Attempted to set up a payment method with an unexpected PaymentOption.type'
        );
    }
  };
