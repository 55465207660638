import { website } from '@getpopsure/private-constants';
import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { Button, Checkbox, Input } from '@popsure/dirty-swan';
import { NavButton } from 'components/NavButton';
import { Name } from 'models/user';
import { FormEvent, useEffect, useState } from 'react';
import { Trans, useSafeTranslation } from 'shared/i18n';
import { isName } from 'shared/validators';

import { SignInQuestions } from '../../models';
import styles from './NameWithTerms.module.scss';

export const NameWithTerms = ({
  onSubmitValue,
  setValidForSubmission,
  value,
}: QuestionnaireFormProps<SignInQuestions>) => {
  const { t } = useSafeTranslation();
  const [name, setName] = useState<Name>(
    isName(value) ? value : { firstName: '', lastName: '' }
  );
  const [termsAccepted, setTermsAccepted] = useState(false);

  const valid = isName(name) && termsAccepted;

  useEffect(() => {
    setValidForSubmission(valid);
  }, [setValidForSubmission, valid]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (valid) {
      onSubmitValue(name);
    }
  };

  useEffect(() => {
    setName(value as Name);
  }, [value]);

  return (
    <form onSubmit={handleSubmit} className={styles.container}>
      <div className="p-body">
        <NavButton title={t('components.signIn.back', 'Back')} renderInPortal />
        <h1 className="p-h1">
          {t('signIn.name.title', 'Let’s create your account')}
        </h1>

        <Input
          placeholder={t('signIn.name.firstname.placeholder', 'First name')}
          type="text"
          required={true}
          value={name?.firstName}
          className="mt16 wmx6"
          onChange={(e) =>
            setName({
              firstName: e.target.value,
              lastName: name?.lastName ?? '',
            })
          }
          data-cy="input-name-first"
        />
        <Input
          placeholder={t('signIn.name.lastname.placeholder', 'Last name')}
          className="mt8 wmx6"
          type="text"
          required={true}
          value={name?.lastName}
          onChange={(e) =>
            setName({
              firstName: name?.firstName ?? '',
              lastName: e.target.value,
            })
          }
          data-cy="input-name-last"
        />

        <Checkbox
          classNames={{
            container: 'mt24 mb8',
            label: 'd-block',
          }}
          wide
          bordered={false}
          onChange={(values) => setTermsAccepted(values.includes('TERMS'))}
          value={termsAccepted ? ['TERMS'] : []}
          options={{
            TERMS: (
              <Trans i18nKey="signIn.name.agreement">
                I have read and understood the{' '}
                <a
                  className="p-a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={website.privacyPolicy}
                >
                  privacy policy
                </a>{' '}
                and{' '}
                <a
                  className="p-a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={website.termsAndConditions}
                >
                  T&Cs
                </a>
                .
              </Trans>
            ),
          }}
        />

        <Button
          className="wmn3"
          disabled={!valid}
          type="submit"
          data-cy="button-continue"
        >
          {t('signIn.name.submit', 'Create account')}
        </Button>
      </div>
    </form>
  );
};
