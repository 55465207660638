import { BottomOrRegularModal, InfoCard } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { CoverageId, QuotePlan } from 'models/expat';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import { coverageByPlan, coverageModalContent } from '../content';
import SegmentedControl from './segmentedControl';
import styles from './style.module.scss';
import { retrieveCardState } from './util';

const ComparisonTable = ({
  selectedPlan,
  setSelectedPlan,
}: {
  selectedPlan: QuotePlan;
  setSelectedPlan: (plan: QuotePlan) => void;
}) => {
  const [coverage, setCoverage] = useState<CoverageId | undefined>();
  const { t } = useSafeTranslation();

  return (
    <div className={styles.container}>
      <BottomOrRegularModal
        title={coverage ? coverageModalContent(t)[coverage]?.title : ''}
        isOpen={coverage !== undefined}
        onClose={() => setCoverage(undefined)}
      >
        {coverage && coverageModalContent(t)[coverage].content}
      </BottomOrRegularModal>
      <SegmentedControl
        onChange={setSelectedPlan}
        selectedPlan={selectedPlan}
      />
      <div className={`mt16 ${styles['card-container']}`}>
        {coverageByPlan(selectedPlan, t).map(
          ({ title, icon, description, coveredInBasic, id }) => (
            <div key={title}>
              <InfoCard
                onClick={() => {
                  if (
                    retrieveCardState(coveredInBasic, selectedPlan, id) ===
                      'actionable' &&
                    id !== undefined
                  ) {
                    setCoverage(id);
                  }
                }}
                className={classNames('mt32 ml16 wmx4', styles['card-height'])}
                title={title}
                topIcon={icon}
                state={retrieveCardState(coveredInBasic, selectedPlan, id)}
                rightIcon={
                  retrieveCardState(coveredInBasic, selectedPlan, id) ===
                  'actionable'
                    ? 'info'
                    : undefined
                }
              >
                <div
                  className={`p-p ta-center tc-grey-600 ${styles['info-description']}`}
                >
                  {description}
                </div>
              </InfoCard>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ComparisonTable;
