import { Button, XIcon } from '@popsure/dirty-swan';
import { Fragment } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import { ChecklistItem } from '../ChecklistItem';
import { GenericStep } from './checklist.models';
import styles from './Checklist.module.scss';

interface ChecklistProps<T extends GenericStep> {
  steps: Array<T>;
  dividers?: Array<number>;
  submittedChecklist?: boolean;
  title: string;
  completeChecklist?: () => void;
}

const Divider = () => <div className={`mb32 ${styles.divider}`} />;

export const Checklist = <T extends GenericStep>({
  title,
  steps,
  dividers,
  submittedChecklist,
  completeChecklist,
}: ChecklistProps<T>) => {
  const { t } = useSafeTranslation();

  return (
    <div data-testid="checklist-container">
      {title && <p className="p-h4 mb24">{title}</p>}
      {completeChecklist && (
        <Button
          data-testid="mark-as-complete"
          hideLabel
          leftIcon={<XIcon />}
          variant="textColor"
          className={styles.closeButton}
          onClick={completeChecklist}
        >
          {t(
            'studentChecklist.title.markAsComplete.button',
            'Mark as completed'
          )}
        </Button>
      )}

      {steps.map((step, index) => (
        <Fragment key={step.id}>
          {dividers && dividers.includes(index) && <Divider />}
          <ChecklistItem step={step} submittedChecklist={submittedChecklist} />
        </Fragment>
      ))}
    </div>
  );
};
