import { BottomOrRegularModal } from '@popsure/dirty-swan';
import Markdown from 'components/markdown';
import {
  Modal,
  ModalWithTracking,
  Tariff,
} from 'features/privateHealthPreSignup/models';

import styles from './TableModal.module.scss';

const premiumPlans = ['NKSelectXL', 'HiMedicalPlus'] as const;
const plusPlans = ['NKSelectL'] as const;

type PremiumPlan = typeof premiumPlans[number];
type PlusPlan = typeof plusPlans[number];
type StandardPlan = Exclude<Tariff, PremiumPlan | PlusPlan>;

const standardPlans: StandardPlan[] = ['NKSelectS', 'HiMedical'];

const getPlanNameFromModalData = (modal: Modal | ModalWithTracking) => {
  if (!('tariff' in modal)) {
    return '';
  }

  if (modal.tariff === 'header') {
    return '';
  }

  if (modal.tariff === 'Public') {
    return '(Public health insurance)';
  }

  if (premiumPlans.includes(modal.tariff as PremiumPlan)) {
    return '(Premium plan)';
  }

  if (plusPlans.includes(modal.tariff as PlusPlan)) {
    return '(Plus plan)';
  }

  if (standardPlans.includes(modal.tariff as StandardPlan)) {
    return '(Standard plan)';
  }

  return '';
};

interface TableModalProps {
  modal: Modal | ModalWithTracking;
  setModal: (value: Modal | ModalWithTracking | null) => void;
}

export const TableModal = ({ modal, setModal }: TableModalProps) => {
  const handleClose = () => setModal(null);
  const subtitle = modal.subtitle || getPlanNameFromModalData(modal);

  return (
    <BottomOrRegularModal isOpen title={modal.title} onClose={handleClose}>
      <div className={`${modal.wide ? 'wmx8' : 'wmx6'} ${styles.container}`}>
        {subtitle && <p className="p-h3 tc-grey-500 mb16">{subtitle}</p>}
        {typeof modal.children === 'string' ? (
          <Markdown>{modal.children}</Markdown>
        ) : (
          modal.children
        )}
      </div>
    </BottomOrRegularModal>
  );
};
