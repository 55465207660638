import { SignupQuestionnaireType } from 'SignupQuestionnaire';

import { EligibilityCheck } from '../components/eligibilityCheck';
import { Processing } from '../components/processing';
import { Quote } from '../components/quote';
import { DisabilityTallyForm } from '../components/tallyForm';
import { DisabilityV2, DisabilityV2GroupIds } from '../models';

export const DisabilityV2Components = {
  QUOTE: Quote,
  ELIGIBILITY_CHECK: EligibilityCheck,
  TALLY_FORM: DisabilityTallyForm,
  PROCESSING_SCREEN: Processing,
} as const;

export type DisabilityV2Questionnaire = SignupQuestionnaireType<
  DisabilityV2,
  DisabilityV2GroupIds,
  typeof DisabilityV2Components
>;
