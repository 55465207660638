import { storeLegalClaimInfo } from 'actions/claims';
import { fetchAccountInfo } from 'actions/user';
import connectedLoader, { LoaderProps } from 'containers/loader';
import View from 'features/claims/components/legalClaim/confirmInfo';
import { LegalClaimInfo } from 'models/claims';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from 'reducers';
import { getLegalClaimInfo } from 'selectors/claims';
import { getAccountInfo } from 'selectors/user';

interface StateProps {
  policyInfo: {
    firstName?: string;
    lastName?: string;
    policyId: string;
  };
  initialValue?: string;
}

interface DispatchProps {
  fetchAccountInfo: () => void;
  onSubmitInfo: (claimInfo: Partial<LegalClaimInfo>) => void;
}

type OwnProps = RouteComponentProps<{ policyId: string }> & LoaderProps;

export type ViewProps = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (
  state: AppState,
  {
    match: {
      params: { policyId },
    },
  }: OwnProps
): StateProps => {
  const user = getAccountInfo(state);
  const phoneNumber = getLegalClaimInfo(state)?.phoneNumber;

  return {
    policyInfo: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      policyId,
    },
    initialValue: phoneNumber,
  };
};

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchAccountInfo: () => dispatch(fetchAccountInfo()),
  onSubmitInfo: (claimInfo: Partial<LegalClaimInfo>) =>
    dispatch(storeLegalClaimInfo(claimInfo)),
});

const ConnectedView = connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(View);

export default connectedLoader('ACCOUNT')(ConnectedView);
