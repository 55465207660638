import { TFunction } from '@getpopsure/i18n-react';
import { AddOns } from '@getpopsure/legal-insurance-pricing-engine-v2';

import { AddOnCoverageItem } from '../../models';

export const getAddOnCoverageItems = (
  t: TFunction,
  addOn: AddOns
): AddOnCoverageItem[] => {
  if (addOn === 'PROPERTY') {
    return [
      {
        id: 'landlord',
        title: t(
          'legal.qnr.quote.addOnModal.landlord.title',
          'Disputes with your landlord'
        ),
        description: t(
          'legal.qnr.quote.addOnModal.landlord.description',
          'e.g. rent increases, deposit, utility costs'
        ),
        covered: true,
      },
      {
        id: 'neighbours',
        title: t(
          'legal.qnr.quote.addOnModal.neighbours.title',
          'Disputes with your neighbours'
        ),
        description: t(
          'legal.qnr.quote.addOnModal.neighbours.description',
          'e.g. noise complaints'
        ),
        covered: true,
      },
      {
        id: 'propertyManagement',
        title: t(
          'legal.qnr.quote.addOnModal.propertyManagement.title',
          'Disputes with property management'
        ),
        covered: true,
      },
      {
        id: 'sublet',
        title: t(
          'legal.qnr.quote.addOnModal.sublet.title',
          'Issues arising when a landlord or tenant sublets the property'
        ),
        description: t(
          'legal.qnr.quote.addOnModal.sublet.description',
          'e.g. evicting the current tenant'
        ),
        covered: false,
      },
    ];
  }
  return [
    {
      id: 'falseAccusations',
      title: t(
        'legal.qnr.quote.addOnModal.falseAccusations.title',
        'False accusations of intentional criminal offences'
      ),
      description: t(
        'legal.qnr.quote.addOnModal.falseAccusations.description',
        'e.g. embezzlement, fraud, theft, trespassing, insult, assault'
      ),
      covered: true,
    },
    {
      id: 'guilty',
      title: t(
        'legal.qnr.quote.addOnModal.guilty.title',
        'Guilty of an intentional criminal offence'
      ),
      covered: false,
    },
  ];
};

export const getAdditionalInfo = (t: TFunction, addOn: AddOns) => {
  if (!addOn) {
    return null;
  }

  if (addOn === 'PROPERTY') {
    return t(
      'legal.qnr.quote.addOnModal.homeProtection.waitingPeriod.description',
      "**Waiting period?**\n\nThe waiting period for the basic plan is 6 months, and for the advanced plan, it's 3 months.\n\nKeep in mind that legal insurance covers events where the initial cause of the legal dispute occurs after the waiting period."
    );
  }

  if (addOn === 'CRIMINAL') {
    return t(
      'legal.qnr.quote.addOnModal.criminalProtection.waitingPeriod.description',
      '**Guilty of an intentional crime?**\n\nIf you are found guilty of intentionally committing a crime, your insurance policy will be terminated retroactively. You will be responsible for any incurred fees up to that point.\n\n**Waiting period**\n\nThere is no waiting period for criminal protection extension.'
    );
  }
};
