import { useEffect, useRef, useState } from 'react';

import { ProcessingView } from './Processing.view';

const INTERVAL_TIME = 1_500;
const TIMEOUT_TIME = 4_000;

interface Props {
  onLoadComplete: (answer?: unknown) => void;
  textList: string[];
  description?: string;
}

export const Processing = ({
  onLoadComplete,
  textList,
  description,
}: Props) => {
  const [elementIndex, setElementIndex] = useState(0);
  const [fadeInAnimation, setFadeInAnimation] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (textList.length === 1) {
      const timeoutId = setTimeout(() => {
        onLoadComplete(true);
      }, TIMEOUT_TIME);

      return () => clearTimeout(timeoutId);
    }
    const intervalId = setInterval(() => {
      setFadeInAnimation(true);
      setTimeout(() => {
        setElementIndex((index) => index + 1);
        setFadeInAnimation(false);
      }, 300);
    }, INTERVAL_TIME);
    intervalRef.current = intervalId;

    return () => {
      clearInterval(intervalId);
    };
  }, [onLoadComplete, textList.length]);

  if (elementIndex >= textList.length - 1 && intervalRef.current) {
    clearInterval(intervalRef.current);
    setTimeout(() => {
      onLoadComplete(true);
    }, INTERVAL_TIME);
  }

  return (
    <ProcessingView
      text={textList}
      fadeInAnimation={fadeInAnimation}
      elementIndex={elementIndex}
      description={description}
    />
  );
};
