import { CurrencyInput } from '@popsure/dirty-swan';
import { NavButton } from 'components/NavButton';
import QuestionnaireWrapper from 'components/questionnaireForms/QuestionnaireWrapper';
import { FormEvent } from 'react';
import { TFunction } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';

interface Props {
  handleSubmit: (e: FormEvent) => void;
  t: TFunction;
  loading: boolean;
  error?: string;
  validForSubmission: boolean;
  minAmount: number;
  amount?: number;
  setAmount: (amount: number) => void;
}

export const PrivateHealthClaimsAmountView = ({
  handleSubmit,
  t,
  loading,
  error,
  validForSubmission,
  amount,
  setAmount,
  minAmount,
}: Props) => (
  <>
    {isMobileApp && <NavButton title="Previous question" renderInPortal />}
    <QuestionnaireWrapper
      question={t('claims.privateHealth.amount.question', 'Claim amount')}
      description={t(
        'claims.privateHealth.amount.description',
        'If your claim is approved, the payout will be sent to the bank account you use to pay for your policy.'
      )}
      onSubmit={handleSubmit}
      validForSubmission={validForSubmission}
      loading={loading}
      error={error}
      buttonText={t('claims.privateHealth.amount.button', 'Submit claim')}
    >
      <CurrencyInput
        className="mt8"
        value={amount}
        onChange={(value) => setAmount(value)}
        placeholder={t(
          'claims.privateHealth.amount.amount.placeholder',
          'Amount'
        )}
        min={minAmount}
        required
      />
    </QuestionnaireWrapper>
  </>
);
