import { Card } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { ReactNode } from 'react';

import styles from './PolicySingleCard.module.scss';

interface PolicyDetailsCardProps {
  title?: string;
  children: ReactNode;
  action?: ReactNode;
  onClick?: () => void;
}

export const PolicySingleCard = (props: PolicyDetailsCardProps) => {
  const { title, children, onClick, action } = props;
  const isDesktop = useMediaQuery('ABOVE_DESKTOP');
  return (
    <Card
      classNames={{ wrapper: styles.card }}
      density={isDesktop ? 'spacious' : 'balanced'}
      onClick={onClick}
    >
      {title && (
        <div className={classNames('d-flex', styles.header)}>
          <h2 className="p-h2">{title}</h2>
          {action && action}
        </div>
      )}
      {children}
    </Card>
  );
};
