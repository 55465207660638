import { PoliciesStatus } from 'features/accountV2/util';
import { DeleteAccountModalProps } from 'models/account';

import { ActiveClaimsModal } from './ActiveClaimsModal';
import { ActivePoliciesModal } from './ActivePoliciesModal';
import { ActivePublicPoliciesModal } from './ActivePublicPoliciesModal';
import { NoPoliciesModal } from './NoPoliciesModal';

type Props = DeleteAccountModalProps & { status: PoliciesStatus };

export const DeleteAccountModal = ({
  showDeleteAccountModal,
  setShowDeleteAccountModal,
  t,
  status,
  deleteUserAccount,
  activeDeleteRequest,
}: Props) => {
  switch (status.type) {
    case 'NO_ACTIVE_OR_PENDING_POLICIES':
      return (
        <NoPoliciesModal
          showDeleteAccountModal={showDeleteAccountModal}
          setShowDeleteAccountModal={setShowDeleteAccountModal}
          t={t}
          deleteUserAccount={deleteUserAccount}
          activeDeleteRequest={activeDeleteRequest}
        />
      );
    case 'ACTIVE_OR_PENDING_POLICIES':
      return (
        <ActivePoliciesModal
          showDeleteAccountModal={showDeleteAccountModal}
          setShowDeleteAccountModal={setShowDeleteAccountModal}
          t={t}
          deleteUserAccount={deleteUserAccount}
          activeDeleteRequest={activeDeleteRequest}
        />
      );
    case 'NO_ACTIVE_POLICIES_ONLY_ACTIVE_CLAIMS':
      return (
        <ActiveClaimsModal
          showDeleteAccountModal={showDeleteAccountModal}
          setShowDeleteAccountModal={setShowDeleteAccountModal}
          t={t}
          deleteUserAccount={deleteUserAccount}
          activeDeleteRequest={activeDeleteRequest}
        />
      );
    case 'ONLY_ACTIVE_OR_PENDING_PUBLIC_POLICIES':
      return (
        <ActivePublicPoliciesModal
          showDeleteAccountModal={showDeleteAccountModal}
          setShowDeleteAccountModal={setShowDeleteAccountModal}
          providerId={status.provider}
          t={t}
          deleteUserAccount={deleteUserAccount}
          activeDeleteRequest={activeDeleteRequest}
        />
      );
  }
};
