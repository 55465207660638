import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { CheckoutPolicyRequestPayload } from 'features/checkout/models';
import { PolicyDetails } from 'features/generalPolicy/components/PolicyDetails/PolicyDetails';
import { InsuranceTypes } from 'models/insurances/types';

import { getCheckoutInfo } from './data';
import MockedCheckout from './MockedCheckout';

export const PreviewCheckout = <Questionnaire extends QuestionnaireQuestions>({
  questionnaireAnswers,
  checkoutIcon,
  featureName,
  additionalPolicyData = {},
  additionalData = {},
  fields = [],
}: QuestionnaireFormProps<Questionnaire> & {
  checkoutIcon?: string;
  additionalPolicyData?: Record<string, unknown>;
  additionalData?: Record<string, unknown>;
  fields?: { title: string; key: string }[];
}) => {
  const checkoutPolicyRequestPayload: CheckoutPolicyRequestPayload = {
    type: featureName as InsuranceTypes,
    policyInfo: {
      quoteId: String(questionnaireAnswers.quoteId),
      questionnaireId: String(questionnaireAnswers.questionnaireId),
      ...additionalPolicyData,
    },
  };

  return (
    <MockedCheckout
      questionnaireAnswers={questionnaireAnswers}
      checkoutPolicyRequestPayload={checkoutPolicyRequestPayload}
      policyDetailsComponent={
        <PolicyDetails
          additionalData={additionalData}
          questionnaireAnswers={{
            ...questionnaireAnswers,
            checkoutInfo: getCheckoutInfo(questionnaireAnswers),
          }}
          fields={fields}
        />
      }
      checkoutIcon={checkoutIcon}
    />
  );
};
