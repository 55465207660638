import { AppState } from 'reducers';

export const getAllQuestionnaireAnswers = (state: AppState) =>
  state.recommendationTool.answers;

export const getPersonaId = (state: AppState) =>
  state.recommendationTool.persona?.id;

export const getCurrentRecommendation = (state: AppState) =>
  state.recommendationTool?.recommendation;

export const getValidAnswers = (state: AppState) =>
  state.recommendationTool.answers;

export const getShowIntroPage = (state: AppState) =>
  state.recommendationTool.showIntroPage;

export const getQuestionnaireMetas = (state: AppState) =>
  state.recommendationTool.metas;
