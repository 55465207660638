import { capitalizeName } from '@getpopsure/public-utility';
import { Button, InformationBox } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { TallyModal } from 'components/TallyModal';
import { Policy } from 'models/policies';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';

import styles from './ChangePrivatehealthPaymentMethod.module.scss';

interface ChangePrivateHealthPaymentMethodViewProps {
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  openModal: boolean;
  policy: Policy;
  accountInfo: ReturnType<typeof getAccountInfo>;
}

export const ChangePrivateHealthPaymentMethodView = (
  props: ChangePrivateHealthPaymentMethodViewProps
) => {
  const { handleOpenModal, openModal, handleCloseModal, policy, accountInfo } =
    props;
  const { t } = useSafeTranslation();

  const urlParams = {
    policynumber: policy.attributes.policyNumber,
    Policyholder:
      accountInfo?.firstName && accountInfo?.lastName
        ? capitalizeName({
            firstName: accountInfo?.firstName,
            lastName: accountInfo?.lastName,
          })
        : null,
    DateOfBirth: accountInfo?.dateOfBirth || null,
    Email: accountInfo?.email || null,
  };

  return (
    <>
      <InformationBox
        title={t(
          'account.paymentMethods.changePrivateHealthMethod.title',
          'Private health is not affected'
        )}
        variant="information"
        className="mt8"
      >
        {t(
          'account.paymentMethods.changePrivateHealthMethod.description',
          'Payment for private policies must be changed separately.'
        )}
        <Button
          variant="textColor"
          className={classNames('d-inline bg-transparent', styles.button)}
          onClick={handleOpenModal}
        >
          {t(
            'account.paymentMethods.changePrivateHealthMethod.button',
            'Change method'
          )}
        </Button>
      </InformationBox>
      {openModal && (
        <TallyModal
          isVisible
          tallyId="nG9d6L"
          onClose={handleCloseModal}
          title="Private health change payment method"
          URLparams={urlParams}
        />
      )}
    </>
  );
};
