import { legal } from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';

export const REFERRAL_ENGINE_REQUEST_TYPE = 'REFERRAL_ENGINE';

export const REFERRAL_USER_DATA_REQUEST_TYPE = 'REFERRAL_USER_DATA';

export const REFERRAL_USER_DATA_UPDATE_TYPE = 'REFERRAL_USER_DATA_UPDATE';

export const FORMATTED_BONUS_AMOUNT = englishFormattedEuroCurrency(15, true);

export const FORMATTED_LIMIT_AMOUNT = englishFormattedEuroCurrency(300, true);

export const REFERRAL_ENGINE_TC_URL = legal.referralEngineTermsAndConditions;
