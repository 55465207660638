import { LiabilityClaims } from 'features/liabilityClaims/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';

import { LiabilityClaimsGettingStartedView } from './GettingStarted.view';

export const LiabilityClaimsGettingStarted = ({
  onSubmitValue,
  metaData: { policyId },
}: CustomComponentProps<LiabilityClaims>) => {
  const { t } = useSafeTranslation();

  const handleSubmit = () => {
    onSubmitValue(true);
  };

  return (
    <LiabilityClaimsGettingStartedView
      handleGetStarted={handleSubmit}
      t={t}
      policyId={policyId}
    />
  );
};
