import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { useUploadFiles } from 'hooks/useUploadFiles';
import { useEffect } from 'react';
import { UploadedFile } from 'shared/models/types';

import { UploadDocumentsView } from './UploadDocuments.view';

export const UploadDocuments = <Questionnaire extends QuestionnaireQuestions>({
  value,
  onSubmitValue,
  setValidForSubmission,
  showLegalNotice,
  maxFiles,
  isQuestionnaire,
}: QuestionnaireFormProps<Questionnaire> & {
  showLegalNotice?: boolean;
  maxFiles?: number;
  isQuestionnaire?: boolean;
}) => {
  const {
    uploadFile,
    uploadedFiles,
    loading: uploadLoading,
    removeFile,
    isSubmitValid,
  } = useUploadFiles({
    initialValue: (value as UploadedFile[]) ?? [],
    isQuestionnaire,
  });

  useEffect(() => {
    setValidForSubmission(uploadedFiles.length > 0 && !uploadLoading);
  }, [uploadedFiles, setValidForSubmission, uploadLoading]);

  const handleSubmit = async () => {
    if (uploadedFiles.length < 1 || !isSubmitValid) {
      throw new Error(`[SignupQuestionnaire] Document token(s) is missing`);
    }

    onSubmitValue(uploadedFiles);
  };

  return (
    <Header onSubmit={handleSubmit}>
      <UploadDocumentsView
        uploadedFiles={uploadedFiles}
        uploadLoading={uploadLoading}
        onFileSelect={uploadFile}
        onRemoveFile={removeFile}
        showLegalNotice={showLegalNotice}
        maxFiles={maxFiles}
      />
    </Header>
  );
};
