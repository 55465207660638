import { TFunction } from '@getpopsure/i18n-react';
import { faq } from '@getpopsure/private-constants';
import { Blocker } from 'components/Blocker';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { DependentsEmail } from 'features/expat/components/DependentsEmail';
import { DependentsPolicyHolder } from 'features/expat/components/DependentsPolicyHolder';
import { StartDate } from 'features/expat/components/StartDate/StartDate';
import {
  ExpatDependentQuestionnaire,
  genderMapping,
} from 'features/expat/models';
import { getDateOfBirthYearRange } from 'features/expat/utils';
import { PartialQuestionnaire } from 'models/questionnaireFramework';
import { generatePath } from 'react-router';
import { isUnder18 } from 'shared/util/isUnder18';

export const postQuoteDependentQNR = (
  t: TFunction
): PartialQuestionnaire<ExpatDependentQuestionnaire, 'signup'> => ({
  name: {
    required: true,
    type: 'NAME',
    props: {
      question: t('expat.qnr.dependentSignup.name.title', "What's their name?"),
    },
    retrieveAnswerObject: (
      name: ExpatDependentQuestionnaire['name']
    ): Partial<ExpatDependentQuestionnaire> => ({
      name,
    }),
    retrieveQuestionsToRemove: () => ['review'],
    retrieveNextPageId: () => 'gender',
    groupId: 'signup',
  },
  gender: {
    required: true,
    type: 'RADIO',
    props: {
      question: t(
        'expat.qnr.dependentSignup.gender.title',
        "What's their gender?"
      ),
      mapValue: genderMapping(t),
    },
    retrieveAnswerObject: (
      gender: ExpatDependentQuestionnaire['gender']
    ): Partial<ExpatDependentQuestionnaire> => ({
      gender,
    }),
    retrieveQuestionsToRemove: () => ['review'],
    retrieveNextPageId: () => 'hasPublicInsurance',
    groupId: 'signup',
  },
  hasPublicInsurance: {
    required: true,
    type: 'RADIO',
    props: {
      question: t(
        'expat.qnr.dependentSignup.publicGermanyInsurance.title',
        'Are they currently on public insurance in Germany?'
      ),
      mapValue: {
        YES: t('expat.qnr.signup.publicGermanyInsurance.yes', 'Yes'),
        NO: t('expat.qnr.signup.publicGermanyInsurance.no', 'No'),
      },
    },
    retrieveAnswerObject: (answer): Partial<ExpatDependentQuestionnaire> => ({
      hasPublicInsurance: answer === 'YES',
    }),
    retrieveNextPageId: (answer) => {
      if (answer === 'YES') {
        return 'germanCitizenInsuredInGermanyBeforeBlocker';
      }
      return 'citizenship';
    },
    transformValue: (answer: boolean) => (answer === true ? 'YES' : 'NO'),
    retrieveQuestionsToRemove: () => ['review'],
    groupId: 'signup',
  },
  germanCitizenInsuredInGermanyBeforeBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'expat.qnr.dependentSignup.insuredInGermanyBlocker.title',
          "We can't sign you up..."
        ),
        description: t(
          'expat.qnr.dependentSignup.insuredInGermanyBlocker.description',
          'Since you are currently insured with a German public insurance, it is not possible to switch to expat health insurance which offers less comprehensive coverage.\n\nYou can try out our digital recommendation tool to find out which health insurance is the best option for you.'
        ),
        buttonProps: [
          {
            type: 'href',
            href: generatePath(routes.insuranceTool.intro.path, {
              insuranceType: 'health',
            }),
            caption: t(
              'expat.qnr.dependentPostQuote.insuredInGermanyBlocker.exploreButton.cta',
              'Get recommendation'
            ),
          },
          {
            type: 'href',
            href: faq.publicHealthInsuranceDowngrade,
            caption: t(
              'expat.qnr.insuredInGermanyBlocker.faq.cta',
              'Learn more'
            ),
            variant: 'SECONDARY',
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    retrieveQuestionsToRemove: () => ['review'],
    groupId: 'signup',
  },
  citizenship: {
    required: true,
    type: 'COUNTRY_MULTI',
    props: {
      question: t(
        'expat.qnr.dependentSignup.citizenship.title',
        'Which countries do they hold a passport from?'
      ),
    },
    retrieveAnswerObject: (
      citizenship: ExpatDependentQuestionnaire['citizenship']
    ): Partial<ExpatDependentQuestionnaire> => ({
      citizenship,
    }),
    retrieveNextPageId: () => {
      return 'lastPermanentResidency';
    },
    retrieveQuestionsToRemove: () => ['lastPermanentResidency', 'review'],
    groupId: 'signup',
  },
  lastPermanentResidency: {
    required: true,
    type: 'COUNTRY_SINGLE',
    props: {
      question: t(
        'expat.qnr.dependentSignup.previousCountry.title',
        'What was their country of residence before arriving in Germany?'
      ),
      filterCountry: [{ code: 'DE', name: 'Germany' }],
    },
    retrieveAnswerObject: (
      lastPermanentResidency: ExpatDependentQuestionnaire['lastPermanentResidency']
    ): Partial<ExpatDependentQuestionnaire> => ({
      lastPermanentResidency,
    }),
    retrieveNextPageId: () => {
      return 'startDate';
    },
    retrieveQuestionsToRemove: () => ['review'],
    groupId: 'signup',
  },
  startDate: {
    required: true,
    type: 'CUSTOM',
    component: (props) =>
      StartDate({
        ...props,
        question: t(
          'expat.qnr.dependentSignup.startDate.title',
          'When would you like their coverage to start?'
        ),
        yearRange: {
          min: dayjs().year(),
          max: dayjs().add(1, 'years').year(),
        },
      }),
    retrieveAnswerObject: (
      startDate: ExpatDependentQuestionnaire['startDate']
    ): Partial<ExpatDependentQuestionnaire> => ({
      startDate,
    }),
    retrieveNextPageId: (_answer, answers) => {
      if (
        answers?.dateOfBirth !== undefined &&
        isUnder18(answers.dateOfBirth)
      ) {
        return 'isMainPolicyTheLegalGuardian';
      }
      return 'review';
    },
    retrieveQuestionsToRemove: () => ['review'],
    groupId: 'signup',
  },
  isMainPolicyTheLegalGuardian: {
    required: (answers) => {
      return (
        answers.dateOfBirth !== undefined && isUnder18(answers.dateOfBirth)
      );
    },
    type: 'CUSTOM',
    component: DependentsPolicyHolder,
    retrieveAnswerObject: (answer): Partial<ExpatDependentQuestionnaire> => ({
      isMainPolicyTheLegalGuardian: answer === 'YES',
    }),
    retrieveNextPageId: (answer) => {
      if (answer === 'YES') {
        return 'review';
      }
      return 'parentGuardianName';
    },
    transformValue(value) {
      return value ? 'YES' : 'NO';
    },
    retrieveQuestionsToRemove: () => [
      'parentGuardianName',
      'parentGuardianDateOfBirth',
      'parentGuardianEmail',
      'review',
    ],
    groupId: 'signup',
  },
  parentGuardianName: {
    required: (answers) => {
      return (
        answers.dateOfBirth !== undefined &&
        isUnder18(answers.dateOfBirth) &&
        answers.isMainPolicyTheLegalGuardian === false
      );
    },
    type: 'NAME',
    props: {
      question: t(
        'expat.qnr.dependentSignup.parentGuardianName.title',
        "What's the name of their parent or legal guardian?"
      ),
    },
    retrieveAnswerObject: (
      parentGuardianName: ExpatDependentQuestionnaire['parentGuardianName']
    ): Partial<ExpatDependentQuestionnaire> => ({
      parentGuardianName,
    }),
    retrieveQuestionsToRemove: () => ['review'],
    retrieveNextPageId: () => 'parentGuardianDateOfBirth',
    groupId: 'signup',
  },
  parentGuardianDateOfBirth: {
    required: (answers) => {
      return (
        answers.dateOfBirth !== undefined &&
        isUnder18(answers.dateOfBirth) &&
        answers.isMainPolicyTheLegalGuardian === false
      );
    },
    type: 'DATE',
    props: {
      question: t(
        'expat.qnr.dependentSignup.parentGuardianDateOfBirth.title',
        'When was the legal guardian / parent born?'
      ),
      yearRange: getDateOfBirthYearRange(),
    },
    validateAnswer: (answer) => {
      if (isUnder18(answer)) {
        return t(
          'expat.qnr.signup.parentGuardianDateOfBirth.error.age',
          'Legal guardian/parent should be over 18 years old.\nPlease make sure the birth date is correct.'
        );
      }
      return null;
    },
    retrieveAnswerObject: (
      parentGuardianDateOfBirth: string
    ): Partial<ExpatDependentQuestionnaire> => ({
      parentGuardianDateOfBirth,
    }),
    retrieveNextPageId: () => 'parentGuardianEmail',
    retrieveQuestionsToRemove() {
      return ['review'];
    },
    groupId: 'signup',
  },
  parentGuardianEmail: {
    required: (answers) => {
      return (
        answers.dateOfBirth !== undefined &&
        isUnder18(answers.dateOfBirth) &&
        answers.isMainPolicyTheLegalGuardian === false
      );
    },
    type: 'CUSTOM',
    component: (props) =>
      DependentsEmail({
        ...props,
        question: t(
          'expat.qnr.signup.parentGuardianEmail.title',
          "What's their email address?"
        ),
      }),
    retrieveAnswerObject: (
      parentGuardianEmail: string
    ): Partial<ExpatDependentQuestionnaire> => ({
      parentGuardianEmail,
    }),
    retrieveNextPageId: () => 'review',
    retrieveQuestionsToRemove() {
      return ['review'];
    },
    groupId: 'signup',
  },
});
