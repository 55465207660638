import { StepType } from 'features/studentChecklist/models';
import { getChecklistStatus } from 'features/studentChecklist/utils';
import { Policy } from 'models/policies';

export const getCurrentStep = (policy: Policy) => {
  const { currentSteps } = getChecklistStatus(
    policy.attributes?.studentChecklist || null,
    policy.status
  );

  const stepsIds = currentSteps.reduce(
    (acc: Array<StepType>, current) =>
      current.active ? acc.concat(current.id) : acc,
    []
  );

  return stepsIds[stepsIds.length - 1];
};
