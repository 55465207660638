import { trackStructuredEvent } from '@getpopsure/analytics';
import { calendly } from '@getpopsure/private-constants';
import {
  BottomOrRegularModal,
  Button,
  CurrencyInput,
} from '@popsure/dirty-swan';
import { MIN_COVERAGE } from 'features/life/models';
import { FormEvent, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { formatCurrencyWithSpace } from 'shared/util/formatCurrencyWithSpace';

import styles from './style.module.scss';
import { ErrorType, validate } from './validation';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: number) => void;
  coverage: number;
  maxCoverage: number;
}

const handleBookAnAppointment = () => {
  trackStructuredEvent({
    category: 'LIFE_INSURANCE',
    action: 'CLICKED_BOOK_AN_APPOINTMENT',
  });
  window.Calendly.initPopupWidget({ url: calendly.lifeInsurance });
};

const CoverageModal = ({
  isOpen,
  onClose,
  onChange,
  coverage,
  maxCoverage,
}: Props) => {
  const [value, setValue] = useState(coverage);
  const [error, setError] = useState<ErrorType | null>(null);

  const errorMessageMapping: { [K in ErrorType]: string } = {
    UNDER_MIN: `The smallest coverage amount we can offer is ${formatCurrencyWithSpace(
      MIN_COVERAGE,
      0
    )}`,
    OVER_MAX: `The maximum coverage amount we can offer is (${formatCurrencyWithSpace(
      maxCoverage,
      0
    )})`,
  };

  useEffect(() => {
    setValue(coverage);
    setError(null);
  }, [coverage, isOpen]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    setError(null);

    const validationError = validate(value, maxCoverage);

    if (validationError) {
      setError(validationError);
      return;
    }

    setError(null);
    onChange(value);
    onClose();
  };

  return (
    <BottomOrRegularModal
      title="Coverage amount"
      isOpen={isOpen}
      onClose={onClose}
    >
      <form className={styles.modal} onSubmit={(e) => onSubmit(e)}>
        <p className="p-p wmx5">
          Please enter your desired coverage amount ranging from{' '}
          {formatCurrencyWithSpace(MIN_COVERAGE, 0)} to{' '}
          {formatCurrencyWithSpace(maxCoverage, 0)}.
        </p>
        <CurrencyInput
          className="mt16 wmx5"
          placeholder="Coverage"
          prefix="€"
          value={value}
          onChange={(v) => setValue(v)}
          error={(error && errorMessageMapping[error]) ?? undefined}
          required
        />
        <AnimateHeight
          duration={300}
          height={error === 'OVER_MAX' ? 'auto' : 0}
        >
          <div className="p-notice p-notice--primary mt16 wmx5 p-p--small">
            <p>
              We can only offer you a maximum coverage of{' '}
              {formatCurrencyWithSpace(maxCoverage, 0)}.
            </p>
            <p className="mt16">
              Do you have more questions?{' '}
              <input
                type="button"
                className={`p-a p-p--small c-pointer ${styles.calendlyButton}`}
                onClick={handleBookAnAppointment}
                value="Reach out to us "
              />
              for more information.
            </p>
          </div>
        </AnimateHeight>
        <Button className="wmn3 mt16" disabled={!value}>
          Apply
        </Button>
      </form>
    </BottomOrRegularModal>
  );
};

export default CoverageModal;
