import { getPriceBreakdown } from '@getpopsure/private-health-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { TableInfoButton } from '@popsure/dirty-swan';
import { Tariff } from 'features/privateHealthPreSignup/models';

import styles from './CashbackCell.module.scss';

interface TableCashbackProps {
  birthYear?: number;
  sickDayPayout?: number;
  tariff: Tariff;
  onClick: () => void;
}

const NoDataPreview = () => (
  <div>
    <p className={`d-flex ${styles.container}`}>
      Up to
      <span className="p-h3 p--serif tc-primary-500 ml8">€—</span>
    </p>
    <p className="p-p--small tc-grey-500">per year</p>
  </div>
);

export const CashbackCell = (props: TableCashbackProps) => {
  const { birthYear, sickDayPayout, tariff, onClick } = props;

  if (birthYear === undefined || sickDayPayout === undefined) {
    return <NoDataPreview />;
  }

  const cashbackObject = getPriceBreakdown({
    birthYear,
    tariff,
    sickDayPayout,
  }).cashback;

  if (!cashbackObject) {
    return <NoDataPreview />;
  }

  const { totalPotentialCashback } = cashbackObject;

  return (
    <div>
      <p className={`d-flex ${styles.container}`}>
        Up to
        <span className="p-h3 p--serif tc-primary-500">
          &nbsp;
          {englishFormattedEuroCurrency(totalPotentialCashback, true)}
        </span>
        <TableInfoButton className={styles.button} onClick={onClick} />
      </p>
      <p className="p-p--small tc-grey-500">per year</p>
    </div>
  );
};
