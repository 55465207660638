import { Policy } from 'models/policies';

import { CancelPolicyButton, ContactUsButton } from '../../components/buttons';
import { PolicyDetailsButton } from '../../sections/PolicyDetails/PolicyDetails.models';

export const getBikeButtons = (policy: Policy): PolicyDetailsButton[] => {
  const buttonArray: PolicyDetailsButton[] = [
    { id: 'CONTACT_US_BUTTON', component: ContactUsButton },
  ];

  if (policy.status !== 'CANCELED') {
    buttonArray.push({
      id: 'CANCEL_POLICY_BUTTON',
      component: CancelPolicyButton,
    });
  }

  return buttonArray;
};
