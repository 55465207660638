import { Input } from '@popsure/dirty-swan';
import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, useState } from 'react';

import QuestionnaireWrapper from '../QuestionnaireWrapper';

interface Props
  extends QuestionnaireFormProps<number>,
    QuestionnaireWrapperProps {
  validate?: (value: number) => boolean;
}

export const NumberInputForm = ({
  value,
  question,
  description,
  onSubmitValue,
  buttonText,
  header,
  loading,
  featureName,
  error,
  additionalInfo,
  placeholder,
  validate,
}: Props) => {
  const [number, setNumber] = useState<string>(value ? String(value) : '');

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!number) {
      throw new Error(
        `[${featureName}] Number value is missing for submission for question: ${question}`
      );
    }
    onSubmitValue(Number(number));
  };

  const isValid = number && (validate ? validate(parseFloat(number)) : true);

  return (
    <QuestionnaireWrapper
      onSubmit={handleSubmit}
      header={header}
      question={question}
      description={description}
      error={error}
      loading={loading}
      buttonText={buttonText}
      validForSubmission={!!isValid}
      additionalInfo={additionalInfo}
    >
      <Input
        placeholder={placeholder}
        onChange={(e) => setNumber(e.target.value)}
        type="number"
        value={number}
        pattern="[0-9]*"
        data-cy="input-number"
      />
    </QuestionnaireWrapper>
  );
};
