import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import creditCardIcon from 'features/dentalClaims/icons/credit-card.svg';
import documentIcon from 'features/dentalClaims/icons/document.svg';
import { generatePath, useHistory } from 'react-router';
import { TFunction, Trans } from 'shared/i18n';

import {
  GettingStartedCard,
  Props as GettingStartedCardProps,
} from './components/GettingStartedCard';
import styles from './styles.module.scss';

interface Props {
  handleGetStarted: () => void;
  t: TFunction;
  policyId: string;
}

export const DentalClaimsGettingStartedView = ({
  handleGetStarted,
  t,
  policyId,
}: Props) => {
  const { location } = useHistory<{ from?: string }>();
  const cardData: GettingStartedCardProps[] = [
    {
      title: t(
        'claims.dental.gettingStarted.cardInfo.1.title',
        'Have a cost plan to confirm?'
      ),
      description: (
        <Trans i18nKey="claims.dental.gettingStarted.cardInfo.1.description">
          <p>
            Submit all pages of the{' '}
            {/* TODO: [KONG] Put into private constants repo */}
            <a
              className="p-a"
              href="https://feather-insurance.com/blog/what-is-a-heil-und-kostenplan/"
              rel="noopener noreferrer"
              target="_blank"
            >
              cost plan
            </a>{' '}
            for review and we'll confirm if your dental treatment is covered.
          </p>
        </Trans>
      ),

      icon: {
        src: documentIcon,
        alt: t(
          'claims.dental.gettingStarted.cardInfo.1.iconalt',
          'purple document'
        ),
      },
    },
    {
      title: t(
        'claims.dental.gettingStarted.cardInfo.2.title',
        'Pay your invoice as soon as possible'
      ),
      description: t(
        'claims.dental.gettingStarted.cardInfo.2.description',
        'We recommend that you pay the invoice quickly to **avoid late fees** while we review your claim. You can request an extension from your medical professional if needed.'
      ),
      icon: {
        src: creditCardIcon,
        alt: t(
          'claims.dental.gettingStarted.cardInfo.2.iconalt',
          'purple credit card'
        ),
      },
    },
  ];

  return (
    <div className={`mb80 ${styles.container}`}>
      <div className="p-body">
        <NavButton
          title={t('claims.dental.gettingStarted.navButton.title', 'Back')}
          path={
            location.state?.from !== 'policy'
              ? routes.claims.create.path
              : generatePath(routes.me.policies.detail.path, { policyId })
          }
        />
        <h1 className="p-h1">
          {t('claims.dental.gettingStarted.title', 'Getting started')}
        </h1>
        <p className="p-p mt8 mb24">
          {t(
            'claims.dental.gettingStarted.description',
            'Here are important things to know when making a claim:'
          )}
        </p>
        {cardData.map(({ title, description, icon }) => (
          <GettingStartedCard
            className="mt16"
            title={title}
            icon={icon}
            description={description}
            key={title}
          />
        ))}
        <button
          type="button"
          onClick={handleGetStarted}
          className={`p-btn--primary mt32 wmn3 ${styles.button}`}
        >
          {t('claims.dental.gettingStarted.cta', 'Get Started')}
        </button>
      </div>
    </div>
  );
};
