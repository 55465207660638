import { Button } from '@popsure/dirty-swan';
import { markHealthCardAsReceived } from 'actions/healthCard';
import { fetchPolicyDetail, mergePolicyAttr } from 'actions/policies';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { HealthCardDispatch } from 'models/healthCard';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ConfirmReceivedHealthCardModal } from '../ConfirmReceivedHealthCardModal';
import styles from './MarkHealthCardAsReceived.module.scss';

const REQUEST_TYPE = 'UPDATE_HEALTH_CARD_DETAILS';

export const MarkHealthCardAsReceived = () => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const { policyId } = useParams<{ policyId: string }>();
  const dispatch = useDispatch<HealthCardDispatch>();
  const { loading, error } = useRequestStatus(REQUEST_TYPE);

  const openConfirmationModal = () => setShowConfirmModal(true);

  const handleCancel = () => setShowConfirmModal(false);

  const handleMarkAsReceived = async () => {
    await dispatch(markHealthCardAsReceived(policyId));
    await dispatch(fetchPolicyDetail(policyId));
    dispatch(mergePolicyAttr(policyId, { showHealthCardConfirmation: true }));
  };

  return (
    <>
      <div className={styles.container}>
        <Button
          className="w100 wmx3"
          variant="filledGray"
          onClick={openConfirmationModal}
        >
          I received it
        </Button>
      </div>
      {showConfirmModal &&
        createPortal(
          <ConfirmReceivedHealthCardModal
            loading={loading}
            onMarkAsReceived={handleMarkAsReceived}
            onCancel={handleCancel}
            error={error}
          />,
          document.body
        )}
    </>
  );
};
