import { CreditCardIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { useSafeTranslation } from 'shared/i18n';

export const PrivateHealthChangePaymentMethodButton = (
  props: PolicyDetailsButtonProps
) => {
  const { onOpenModal } = props;
  const { t } = useSafeTranslation();

  const handleChangePaymentMethod = () =>
    onOpenModal('PRIVATE_HEALTH_CHANGE_PAYMENT_METHOD');

  return (
    <ButtonCell
      title={t(
        'myPolicies.policyDetails.privateHealth.changePaymentMethodButton.caption',
        'Change payment method'
      )}
      icon={<CreditCardIcon noMargin size={24} className="ml16" />}
      onClick={handleChangePaymentMethod}
    />
  );
};
