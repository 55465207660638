import { toast } from '@popsure/dirty-swan';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { PaymentMethodsAction, RequestAction } from 'constants/actions';
import { APIResponseError } from 'models/error';
import { PaymentMethod } from 'models/paymentMethods';
import { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { formatErrorMessageFromError } from 'selectors/requests';
import endpoint from 'shared/api';

import { Policy } from '../../models/policies';

export type PaymentMethodDispatch = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  RequestAction | PaymentMethodsAction
>;

export function mergePaymentMethods(
  paymentMethods: PaymentMethod[]
): PaymentMethodsAction {
  return {
    type: 'MERGE_PAYMENT_METHODS',
    paymentMethods,
  };
}

export function mergePaymentMethod(
  paymentMethod: PaymentMethod
): PaymentMethodsAction {
  return {
    type: 'MERGE_PAYMENT_METHOD',
    paymentMethod,
  };
}

export function detachPaymentMethod(
  paymentMethodId: string
): PaymentMethodsAction {
  return {
    type: 'DETACH_PAYMENT_METHOD',
    paymentMethodId,
  };
}

export function fetchPaymentMethods() {
  return async (dispatch: PaymentMethodDispatch) => {
    const requestType = 'GET_PAYMENT_METHODS';

    dispatch(setRequestInProcess(true, requestType));

    try {
      const { data } = await endpoint.getPaymentMethods();
      dispatch(mergePaymentMethods(data));
      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };
}

export function makePaymentMethodDefault(paymentMethodId: string) {
  return async (dispatch: PaymentMethodDispatch) => {
    const requestType = 'MAKE_PAYMENT_METHOD_A_DEFAULT';

    dispatch(setRequestInProcess(true, requestType));

    try {
      const { data } = await endpoint.makePaymentMethodDefault(paymentMethodId);
      dispatch(mergePaymentMethods(data));
      dispatch(setRequestInProcess(false, requestType));
      return 'SUCCESS';
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      toast(formatErrorMessageFromError(error as APIResponseError), {
        type: 'error',
      });
      return 'ERROR';
    }
  };
}

export function deletePaymentMethod(paymentMethodId: string) {
  return async (dispatch: PaymentMethodDispatch) => {
    const requestType = 'DELETE_PAYMENT_METHOD';

    dispatch(setRequestInProcess(true, requestType));

    try {
      await endpoint.deletePaymentMethod(paymentMethodId);
      dispatch(detachPaymentMethod(paymentMethodId));
      dispatch(setRequestInProcess(false, requestType));
      return 'SUCCESS';
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      toast(formatErrorMessageFromError(error as APIResponseError), {
        type: 'error',
      });
      return 'ERROR';
    }
  };
}

export const getActivePrivateHealthPolicy = () => {
  return async (): Promise<Policy | null> => {
    try {
      const { data } = await endpoint.getMyPolicies();

      const policy = data.filter(
        (p) =>
          p.type === 'PRIVATE_HEALTH' &&
          p.status === 'ACTIVE' &&
          !p.mainPolicyId
      );

      return policy[0] || null;
    } catch {
      return null;
    }
  };
};
