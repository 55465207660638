import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import Navigation from 'components/common/navigation';
import { IconCard } from 'components/IconCard';
import routes from 'constants/routes';
import { useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import styles from './GettingStarted.module.scss';
import oneIcon from './icons/1.svg';
import twoIcon from './icons/2.svg';
import threeIcon from './icons/3.svg';

export const GettingStarted = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  setValidForSubmission,
}: QuestionnaireFormProps<Questionnaire, boolean>) => {
  const { t } = useSafeTranslation();
  const { policyId } = useParams<{ policyId: string }>();
  const { location } = useHistory<{ from?: string }>();

  useEffect(() => {
    window.scrollTo(0, 0);
    setValidForSubmission(true);
  }, [setValidForSubmission]);

  const handleSubmit = () => {
    onSubmitValue(true);
  };

  return (
    <Header onSubmit={handleSubmit}>
      <div className={`${styles.container}`}>
        <div className="w100">
          <Navigation
            previousScreen="My policy"
            previousUrl={
              location.state?.from !== 'policy'
                ? routes.claims.create.path
                : generatePath(routes.me.policies.detail.path, { policyId })
            }
          />
        </div>
        <div className={styles.animatedContainer}>
          <div className="mt40 mb16">
            <h1 className="p-h1">
              {t(
                'legal.qnr.claims.howClaimsWork.title',
                'How the claims process works'
              )}
            </h1>
          </div>
          <div className={styles.cardContainer}>
            <IconCard
              title={t(
                'legal.qnr.claims.bookConsultation.title',
                'Book a consultation'
              )}
              iconSrc={oneIcon}
              iconAlt="credit card"
              className="bs-sm bg-white px24 py24 br8"
            >
              {t(
                'legal.qnr.claims.bookConsultation.description',
                'Every claim starts with a phone consultation. A lawyer will look at the specifics of your case and find the best approach to address it.'
              )}
            </IconCard>
            <IconCard
              title={t(
                'legal.qnr.claims.receiveClaimNumber.title',
                'Receive your claim number'
              )}
              iconSrc={twoIcon}
              iconAlt="line chart"
              className="bs-sm bg-white px24 py24 br8"
            >
              {t(
                'legal.qnr.claims.receiveClaimNumber.description',
                'Roland will provide the claim number (Schadennummer) via email before the consultation.'
              )}
            </IconCard>
            <IconCard
              title={t(
                'legal.qnr.claims.submitClaim.title',
                'Submit your claim'
              )}
              iconSrc={threeIcon}
              iconAlt="euro coins"
              className="bs-sm bg-white px24 py24 br8"
            >
              {t(
                'legal.qnr.claims.submitClaim.description',
                'After the phone consultation, come back here to submit your claim using the claim number.'
              )}
            </IconCard>
          </div>
        </div>
      </div>
    </Header>
  );
};
