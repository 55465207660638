export const InfoCard = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => (
  <div className="p-container d-flex ai-center jc-center">
    <div className={`br8 ta-center p24 bg-grey-100 wmx6 ${className}`}>
      {children}
    </div>
  </div>
);
