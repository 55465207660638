import { Region, regions } from '@getpopsure/public-models';
import routes from 'constants/routes';
import type { Locale } from 'shared/i18n';

export const isRegionValid = (region: string): region is Region => {
  return regions.includes(region as Region);
};

export type Feature =
  | 'policies'
  | 'recommendationTool'
  | 'claims'
  | 'account'
  | 'languageSwitcher'
  | 'additionalCoverage';

export type FeatureAccessByRegion = Record<Region, Array<Feature>>;

export const featureAccessByRegionMap: FeatureAccessByRegion = {
  de: [
    'policies',
    'recommendationTool',
    'claims',
    'account',
    'languageSwitcher',
    'additionalCoverage',
  ],
  fr: ['policies', 'account'],
  es: ['policies', 'account'],
  nl: ['policies', 'account'],
  it: ['policies', 'account'],
  be: ['policies', 'account'],
  se: ['policies', 'account'],
  mt: ['policies', 'account'],
  at: ['policies', 'account'],
  fi: ['policies', 'account'],
  cy: ['policies', 'account'],
  gr: ['policies', 'account'],
  ee: ['policies', 'account'],
  pt: ['policies', 'account'],
  lt: ['policies', 'account'],
  hr: ['policies', 'account'],
  lv: ['policies', 'account'],
  mc: ['policies', 'account'],
  eu: [],
};

export const getFeatureByRegion = (
  region: Region,
  _featureDictionary = featureAccessByRegionMap
) => {
  return (feature: Feature): boolean => {
    if (!_featureDictionary[region]) return false;
    return _featureDictionary[region].includes(feature);
  };
};

export const getRouteLocaleConfig: () => {
  path: string;
  nonEnglishLocales: Exclude<Locale, 'en'>[];
  regions?: { id: Region; locales: Locale[] }[];
  exact?: boolean;
}[] = () => [
  { path: routes.account.path, nonEnglishLocales: ['de', 'es'] },
  { path: routes.account.signIn.path, nonEnglishLocales: ['de', 'es'] },
  { path: routes.claims.dental.path, nonEnglishLocales: ['de'], exact: false },
  {
    path: routes.claims.liability.path,
    nonEnglishLocales: ['de'],
    exact: false,
  },
  { path: routes.claims.path, exact: false, nonEnglishLocales: ['de'] },
  {
    path: routes.me.communicationPreferences.path,
    nonEnglishLocales: ['de'],
  },
  {
    path: routes.me.paymentMethods.path,
    nonEnglishLocales: ['de', 'es'],
  },
  { path: routes.me.policies.detail.path, nonEnglishLocales: ['de', 'es'] },
  {
    path: routes.me.policies.detail.studentChecklist.path,
    nonEnglishLocales: ['de'],
  },
  { path: routes.me.policies.path, nonEnglishLocales: ['de', 'es'] },
  { path: routes.me.accountSettings.path, nonEnglishLocales: ['de', 'es'] },
  {
    path: routes.policies.dental.path,
    nonEnglishLocales: ['de'],
    exact: false,
  },
  {
    path: routes.policies.household.path,
    nonEnglishLocales: ['de'],
    exact: false,
  },
  {
    path: routes.policies.legal.path,
    nonEnglishLocales: ['de'],
    exact: false,
  },
  {
    path: routes.claims.legal.path,
    nonEnglishLocales: ['de'],
    exact: false,
  },
  {
    path: routes.policies.liability.path,
    nonEnglishLocales: ['de'],
    exact: false,
  },
  {
    path: routes.policies.expatSpain.path,
    nonEnglishLocales: ['es'],
    exact: false,
  },
  /*
   * These routes allow us to open a different Intercom Messenger based on the region.
   * The messenger does not allow query params to determine the language or region,
   * so we need to specify these values within the URL path.
   */
  {
    path: `/:locale${routes.me.policies.detail.path}`,
    nonEnglishLocales: ['de', 'es'],
  },
  { path: `/:locale${routes.account.path}`, nonEnglishLocales: ['de', 'es'] },
  {
    path: `/:locale${routes.me.accountSettings.path}`,
    nonEnglishLocales: ['de', 'es'],
  },
];
