import { trackReferAFriendModalOpened } from '@getpopsure/analytics';
import { CardProps } from '@popsure/dirty-swan';
import routes from 'constants/routes';
import { getReferralCode } from 'features/referralEngine/actions';
import { REFERRAL_ENGINE_REQUEST_TYPE } from 'features/referralEngine/constants';
import { ReferralEngineDispatch } from 'features/referralEngine/models';
import { getUserReferralCode } from 'features/referralEngine/selectors';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getUserId } from 'selectors/user';

import { ReferralCodeGeneratorView } from './ReferralCodeGenerator.view';

export const ReferralCodeGeneratorContainer = ({
  medium,
  cardProps = {},
}: {
  medium: 'account' | 'dashboard';
  cardProps?: CardProps;
}) => {
  const { loading, error } = useRequestStatus(REFERRAL_ENGINE_REQUEST_TYPE);
  const dispatch = useDispatch<ReferralEngineDispatch>();
  const userId = useSelector(getUserId);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const referralCode = useSelector(getUserReferralCode);
  const showWidget = useQueryParamValue('widget') === 'referral';
  const history = useHistory();

  const handleCloseModal = () => {
    /**
     * Clear search query to prevent it to display the modal
     * when the user navigates the app or refreshes the page
     */
    if (showWidget) {
      history.replace(routes.me.policies.path);
    }

    setOpenModal(false);
  };

  const handleGenerateReferralCode = () => {
    setOpenModal(true);

    trackReferAFriendModalOpened({
      medium,
      user_id: String(userId),
    });

    /* If the referral code is not stored on Redux, fetch it from the backend */
    if (!referralCode) {
      dispatch(getReferralCode);
    }
  };

  useEffect(() => {
    if (showWidget) {
      /*
       * If the referral code is not stored on Redux, fetch it from the backend
       * this is an async function, so we don't have to wait for it to finish
       * the modal will display a loader when the referral code is being fetched
       */
      if (!referralCode) {
        dispatch(getReferralCode);
      }

      setOpenModal(true);
    }
  }, [dispatch, referralCode, showWidget]);

  return (
    <ReferralCodeGeneratorView
      cardProps={cardProps}
      onButtonClick={handleGenerateReferralCode}
      handleCloseModal={handleCloseModal}
      openModal={openModal}
      referralCode={referralCode}
      loading={loading}
      error={error}
    />
  );
};
