import { trackTaskEngineCardClicked } from '@getpopsure/analytics';
import { Address, isPartialAddressValid } from '@getpopsure/public-models';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { flushRequestError } from 'actions/request';
import { updateTask } from 'features/taskEngine/api/taskEngine.api';
import { CustomTask } from 'features/taskEngine/components/CustomTask';
import { updateTaskMutationErrorHandler } from 'features/taskEngine/utils/updateTaskMutationErrorHandler';
import { UpdateBikeQuestionnaireDispatch } from 'models/policies';
import { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import { TASK_ENGINE_REQUEST_TYPE } from '../../constants';
import { Task, TaskEngineOptions } from '../../taskEngine.models';

export const LiabilityAddressUpdate = ({
  task,
  options,
}: {
  task: Task;
  options?: TaskEngineOptions;
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch<UpdateBikeQuestionnaireDispatch>();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useSafeTranslation();

  const mutation = useMutation({
    mutationFn: (address: Address) => {
      return updateTask({
        taskId: task.id,
        payload: { address },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });

  const openModal = () => {
    trackTaskEngineCardClicked({
      task_type: task.description.type,
      task_id: task.id,
    });

    if (options?.onTaskClick) {
      options.onTaskClick(task);
    }

    setIsOpen(true);
  };

  const closeModal = () => {
    dispatch(flushRequestError(TASK_ENGINE_REQUEST_TYPE));
    setIsOpen(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget).entries();
    const address = Object.fromEntries(formData);
    const addressWithCountry = { ...address, country: 'DE' };

    if (isPartialAddressValid(addressWithCountry)) {
      mutation.mutate(addressWithCountry, {
        onSuccess: () => setIsOpen(false),
        onError: (error) => updateTaskMutationErrorHandler(error),
      });
    }
  };

  return (
    <CustomTask
      metadata={{
        title: t(
          'taskEngine.liabilityAddressUpdate.title',
          'Update your address'
        ),
        subtitle: t('taskEngine.liabilityAddressUpdate.subtitle', 'Liability'),
      }}
      modal={{
        isOpen,
        loading: mutation.isLoading,
        error: mutation.isError ? (mutation.error as Error).message : null,
        onOpen: openModal,
        onClose: closeModal,
      }}
      onSubmit={handleSubmit}
      actions={[
        {
          id: '1',
          type: 'ADDRESS',
        },
        {
          id: '2',
          type: 'SUBMIT',
          attributes: {
            value: 'Submit',
          },
        },
      ]}
    />
  );
};
