import { TFunction } from '@getpopsure/i18n-react';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { FAQInfo } from 'components/faq';

export const faqQuestions = (
  t: TFunction,
  noPaperWorkLimit: number
): FAQInfo[] => [
  {
    questionId: 1,
    question: t(
      'disability.quote.faq.1.question',
      'How much will disability insurance provide in compensation?'
    ),
    answer: t('disabilityV2.quote.faq.1.answer', {
      defaultValue:
        'If you are eligible, insurance will cover up to a monthly payout sum up to {{noPaperWorkLimit}} or 80% your net salary at the time of application (whichever is lower) without additional paperwork. If you’d like a higher monthly payout sum, you’ll need to contact our support team, so we can help you through the extended application process.',
      noPaperWorkLimit: englishFormattedEuroCurrency(noPaperWorkLimit, true),
    }),
  },
  {
    questionId: 2,
    question: t(
      'disability.quote.faq.2.question',
      'How are benefits paid out?'
    ),
    answer: t(
      'disability.quote.faq.2.answer',
      'Once we’ve received confirmation that you have an occupational disability that is expected to impact your ability to work for at least 6 months, with more than 50% reduction in working capacity, we’ll send a monthly payout sum to the bank account of your choice.'
    ),
  },
  {
    questionId: 3,
    question: t('disability.quote.faq.3.question', 'Am I eligible?'),
    answer: t(
      'disability.quote.faq.3.answer',
      'If you’ve already been diagnosed with an occupational disability, then it’s unfortunately too late to sign up for insurance. If you have a pre-existing condition like diabetes or have been treated for depression, it still might be possible to sign up for disability insurance with a higher monthly premium or exclusions for disabilities related to these conditions.'
    ),
  },
  {
    questionId: 4,
    question: t(
      'disability.quote.faq.4.question',
      'Can I raise my compensation limit at another time?'
    ),
    answer: t(
      'disability.quote.faq.4.answer',
      'Yes, you’re able to raise your monthly payout sum. Depending on your age and health, when you request the increase, we might just need you to answer a few questions, or we might also send you to get a check-up.'
    ),
  },
  {
    questionId: 5,
    question: t(
      'disability.quote.faq.5.question',
      'What happens if I lose my job and am unable to pay?'
    ),
    answer: t(
      'disability.quote.faq.5.answer',
      'If you lose your job while on disability insurance and are not able to pay, we do offer relief plans provided that you have been insured for more than 1 year. Please contact us for more details to see if you qualify.'
    ),
  },
  {
    questionId: 6,
    question: t(
      'disability.quote.faq.6.question',
      'Will I be denied disability insurance if I smoke?'
    ),
    answer: t(
      'disability.quote.faq.6.answer',
      'Nope, you won’t be denied for just smoking (you might be denied for smoking-related illnesses, though, or other pre-existing conditions).'
    ),
  },
  {
    questionId: 7,
    question: t('disability.quote.faq.7.question', 'How can I claim?'),
    answer: t(
      'disability.quote.faq.7.answer',
      'The first step will always be to reach out to Feather, and we can help you with the claims process. Keep in mind that you’ll only be able to claim disability insurance once we receive a medical confirmation that you have an occupational disability that is expected to impact your work performance for at least 6 months, with more than a 50% decrease in your working capacity.'
    ),
  },
  {
    questionId: 8,
    question: t(
      'disability.quote.faq.8.question',
      'What is the difference between a concrete and abstract referral?'
    ),
    answer: t(
      'disability.quote.faq.8.answer',
      '**Concrete referral:** The insurance can only refer you to another occupation if it is a similar job that corresponds to your skills, experience, and previous salary with a comparative social standing.\n\nAn example of this would be someone  who works as a sales representative, due to a steadily worsening back condition, and is no longer able to drive for hours to visit customers. Their employer offers them a job in the office service at the same salary, which they accept.\n\n**Abstract referral:** If you can work in any capacity at all, it would be considered an “abstract referral.”\n\nDisability insurance will not force someone to find a new position in a different field, or if they find a similar position with a pay cut, this also won’t be counted as it is an abstract referral.'
    ),
  },
];
