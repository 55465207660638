import { Button } from '@popsure/dirty-swan';

export type CTAProps = {
  header: string;
  title: string;
  subTitle: string;
  buttonText: string;
};

export const CTABox = ({
  header,
  title,
  subTitle,
  buttonText,
  onSubmit,
}: { onSubmit: () => void } & CTAProps) => {
  return (
    <div className="p-container bg-grey-100 d-flex fd-column jc-center ai-center c-gap16 r-gap4 py40 my80">
      <div className="p-h1">{header}</div>
      <div className="p-h1--xl p--serif tc-primary-500 mt8">{title}</div>
      <div className="p-p--small fw-normal tc-grey-500">{subTitle}</div>
      <Button className="wmn4 mt16" onClick={onSubmit}>
        {buttonText}
      </Button>
    </div>
  );
};
