import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Beneficiary } from 'features/life/models';
import { ModalType } from 'features/policySingle/components/modals';
import { DATE_FORMAT } from 'features/policySingle/constants';
import { Duration } from 'models/policies';
import { capitalizeName } from 'shared/util/capitalizeName';

import { PolicyDetailsInfo } from '../PolicyDetails.models';
import styles from './PolicyDetailsRenderer.module.scss';

const isValueType = (
  value: unknown
): value is {
  type: string;
  text: string;
} => typeof value === 'object' && value !== null && 'type' in value;

export const PolicyDetailsRenderer = ({
  policyType,
  infos,
  onOpenModal,
}: {
  policyType: string;
  infos: PolicyDetailsInfo[];
  onOpenModal: (modalType: ModalType) => void;
}) => {
  return (
    <>
      {infos.map(({ id, title: infoTitle, value, category, density }) => {
        if (
          policyType === 'LIABILITY' &&
          isValueType(value) &&
          value.type === 'Button'
        ) {
          return (
            <div
              className={classNames(
                `${density === 'condensed' ? 'mt8' : 'mt24'}`,
                styles.row
              )}
              key={infoTitle}
            >
              <span className="p-p tc-grey-600">{infoTitle}</span>
              <button
                className="p-p p-a ds-interactive-component"
                type="button"
                onClick={() => onOpenModal('LIABILITY_UPDATE_ADDRESS')}
                data-testid="policy-detail-edit-address-button"
              >
                {value.text}
              </button>
            </div>
          );
        }

        if (id === 'frameNumber' || infoTitle === 'Frame number') {
          return (
            <div
              className={classNames(
                `${density === 'condensed' ? 'mt8' : 'mt24'}`,
                styles.row
              )}
              key={infoTitle}
            >
              <span className="p-p tc-grey-600">{infoTitle}</span>
              <span
                className="p-p"
                data-testid="policy-detail-frame-number-value"
              >
                {String(value)}
              </span>
            </div>
          );
        }

        if (
          (id === 'purchaseReceipts' || infoTitle === 'Purchase receipts') &&
          Array.isArray(value)
        ) {
          return (
            <div
              className={classNames(
                `${density === 'condensed' ? 'mt8' : 'mt24'}`,
                styles.row
              )}
              key={infoTitle}
            >
              <span className="p-p tc-grey-600">{infoTitle}</span>
              <div className="d-flex">
                <span className="p-p mr8">
                  {String(value.length)} receipt{value.length > 1 && 's'}
                </span>
              </div>
            </div>
          );
        }

        if (infoTitle === 'Duration') {
          return (
            <div
              className={classNames(
                `${density === 'condensed' ? 'mt8' : 'mt24'}`,
                styles.row,
                styles.infoDurationContainer
              )}
              key={infoTitle}
            >
              <span className="p-p tc-grey-600">{infoTitle}</span>
              <span className="p-p">
                <div>
                  {dayjs((value as Duration).endDate).year() -
                    dayjs((value as Duration).startDate).year()}{' '}
                  years
                </div>
                <div>
                  {dayjs((value as Duration).startDate).format(DATE_FORMAT)}
                </div>
                <div>
                  to {dayjs((value as Duration).endDate).format(DATE_FORMAT)}
                </div>
              </span>
            </div>
          );
        }
        if (category === 'BENEFICIARIES') {
          return value && (value as Beneficiary[]).length > 0 ? (
            <div
              key="beneficiaries-section"
              className={classNames(
                `${density === 'condensed' ? 'mt8' : 'mt24'}`,
                styles.infoBeneficiariesContainer
              )}
            >
              <div className="fw-bold p-p">{infoTitle}</div>
              {(value as Beneficiary[]).map(
                ({
                  id: beneficiaryId,
                  name: beneficiaryName,
                  payoutPercentage,
                  coverage,
                }) => (
                  <div
                    className={classNames('mt24', styles.row)}
                    key={beneficiaryId}
                  >
                    <span className="p-p tc-grey-600">
                      {capitalizeName(beneficiaryName)}
                    </span>
                    <span className="p-p">
                      {payoutPercentage}%
                      {coverage
                        ? ` (${englishFormattedEuroCurrency(coverage, true)})`
                        : undefined}
                    </span>
                  </div>
                )
              )}
            </div>
          ) : (
            <></>
          );
        }

        if (typeof value === 'string' || typeof value === 'number') {
          return (
            <div
              className={classNames(
                `${density === 'condensed' ? 'mt8' : 'mt24'}`,
                styles.row
              )}
              key={infoTitle}
            >
              <span className="p-p tc-grey-600">{infoTitle}</span>
              <span className="p-p">{value}</span>
            </div>
          );
        }

        return null;
      })}
    </>
  );
};
