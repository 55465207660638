export type LookupObject = Record<
  string,
  string | number | boolean | undefined | null
>;

export const createTallyURLParamsString = (urlParamsObj: LookupObject) => {
  const isNotNullish = (maybeNullishValue: unknown) =>
    maybeNullishValue !== undefined && maybeNullishValue !== null;

  return Object.entries(urlParamsObj)
    .flatMap(([key, value]) =>
      isNotNullish(value) ? [`&${key}=${String(value)}`] : []
    )
    .join('');
};
