import { faq } from '@getpopsure/private-constants';

export const MANUAL_PAYMENT_FAQ_URL = `${faq.base}/en/articles/146449-how-to-make-a-manual-payment-for-your-private-health-insurance`;
export const HOW_DOES_PAYMENT_WORK_FAQ_URL = `${faq.base}/en/articles/28670-how-does-payment-work-for-private-health-insurance`;
export const PROVIDE_IBAN_VIA_TALLY_URL = 'https://tally.so/r/nG9d6L';

export const IbanPaymentMethods = [
  'MANUAL_PAYMENT',
  'PROVIDED_VIA_TALLY',
] as const;

export type IbanPaymentMethod = typeof IbanPaymentMethods[number];
