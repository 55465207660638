import { TFunction } from '@getpopsure/i18n-react';
import { HouseholdQuestionnaire } from 'features/household/models';

import { checkoutQuestionnaire } from './checkout';
import { householdInfoQuestionnaire } from './householdInfo';
import { personalInfoQuestionnaire } from './personalInfo';
import { policyOptionsQuestionnaire } from './policyOptions';
import { reviewInfoQuestionnaire } from './reviewInfo';

export const getTranslatedQuestionnaire = (
  t: TFunction
): HouseholdQuestionnaire => ({
  ...policyOptionsQuestionnaire(t),
  ...householdInfoQuestionnaire(t),
  ...personalInfoQuestionnaire(t),
  ...reviewInfoQuestionnaire(),
  ...checkoutQuestionnaire(),
});
