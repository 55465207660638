import { MultiDropzone } from '@popsure/dirty-swan';
import QuestionnaireWrapper from 'components/questionnaireForms/QuestionnaireWrapper';
import { FormEvent } from 'react';
import { TFunction } from 'shared/i18n';
import { UploadedFile } from 'shared/models/types';

interface Props {
  handleSubmit: (e: FormEvent) => void;
  t: TFunction;
  validForSubmission: boolean;
  blockerRoute: string;
  submissionLoading?: boolean;
  onFileSelect: (files: File[]) => void;
  onRemoveFile: (id: string) => void;
  uploadedFiles: UploadedFile[];
  uploadLoading: boolean;
  error?: string;
}

export const BikeClaimsPurchaseReceiptsView = ({
  handleSubmit,
  t,
  validForSubmission,
  blockerRoute,
  submissionLoading,
  onFileSelect,
  onRemoveFile,
  uploadedFiles,
  uploadLoading,
  error,
}: Props) => (
  <QuestionnaireWrapper
    question={t(
      'claims.bike.purchaseReceipts.question',
      'Do you have the purchase receipt of your bike and lock?'
    )}
    onSubmit={handleSubmit}
    validForSubmission={validForSubmission}
    skipDetails={{
      title: t(
        'claims.bike.questionnaire.purchaseReceipts.skipDetails.title',
        "I don't have it"
      ),
      path: blockerRoute,
    }}
    loading={submissionLoading || uploadLoading}
    error={error}
  >
    <div className="wmx6">
      <MultiDropzone
        onFileSelect={onFileSelect}
        uploadedFiles={uploadedFiles}
        uploading={uploadLoading}
        onRemoveFile={onRemoveFile}
      />
    </div>
  </QuestionnaireWrapper>
);
