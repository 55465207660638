import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { IconTile } from 'components/IconTile';
import { Pension } from 'features/pension/models';
import { useSafeTranslation } from 'shared/i18n';

import financeIcon from './assets/finance.svg';
import investingIcon from './assets/investing.svg';
import personalInfoIcon from './assets/personal-info.svg';
import styles from './GetStarted.module.scss';

export const GetStarted = ({
  onSubmitValue,
}: QuestionnaireFormProps<Pension>) => {
  const { t } = useSafeTranslation();

  return (
    <div
      className={`p-container d-flex fd-column ai-start jc-center ${styles.container}`}
    >
      <h1 className="p-h1">
        {t('pension.qnr.getStarted.title', "Let's prepare you for retirement!")}
      </h1>
      <p className="p-p mt16">
        {t(
          'pension.qnr.getStarted.description',
          "We'll ask you questions about the following:"
        )}
      </p>
      <div className={`d-grid gap16 mt24 w100 ${styles.grid}`}>
        <IconTile
          title={t(
            'pension.qnr.getStarted.tile.1.title',
            'Investment strategy'
          )}
          paragraphClassName="p-p--small"
          iconSrc={investingIcon}
          iconAlt={t('pension.qnr.getStarted.tile.1.iconalt', 'investing')}
        />
        <IconTile
          title={t('pension.qnr.getStarted.tile.2.title', 'Personal info')}
          paragraphClassName="p-p--small"
          iconSrc={personalInfoIcon}
          iconAlt={t('pension.qnr.getStarted.tile.2.iconalt', 'personal info')}
        />
        <IconTile
          title={t('pension.qnr.getStarted.tile.3.title', 'Financial history')}
          paragraphClassName="p-p--small"
          iconSrc={financeIcon}
          iconAlt={t('pension.qnr.getStarted.tile.3.iconalt', 'finance')}
        />
      </div>
      <button
        className="p-btn p-btn--primary mt24 wmn4"
        onClick={() => onSubmitValue(true)}
        data-cy="next-steps-button"
        type="button"
      >
        {t('pension.qnr.getStarted.continueButton.caption', 'Continue')}
      </button>
    </div>
  );
};
