import routes from 'constants/routes';
import { Route, Switch } from 'react-router-dom';

import { DependentSignupPage } from './components/DependentSignup';
import { SignupPage } from './index';

export const ExpatSpainRouter = () => {
  return (
    <Switch>
      <Route
        path={routes.policies.expatSpain.dependents.path}
        component={DependentSignupPage}
      />
      <Route path={routes.policies.expatSpain.path} component={SignupPage} />
    </Switch>
  );
};

export {};
