import { ExpatQuestionnaire } from 'features/expat/models';
import { PartialQuestionnaire } from 'models/questionnaireFramework';

import { ReviewCheckout } from '../../components/ReviewCheckout';

export const checkoutQNR = (): PartialQuestionnaire<
  ExpatQuestionnaire,
  'signup'
> => ({
  reviewCheckout: {
    required: true,
    type: 'CUSTOM',
    groupId: 'signup',
    component: ReviewCheckout,
    retrieveAnswerObject: (
      reviewCheckout: ExpatQuestionnaire['reviewCheckout']
    ): Partial<ExpatQuestionnaire> => ({
      reviewCheckout,
    }),
  },
});
