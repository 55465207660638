import { useSafeTranslation } from 'shared/i18n';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import styles from './style.module.scss';

const Chip = ({
  name,
  price,
  onSelect,
}: {
  name: string;
  price: number;
  onSelect?: () => void;
}) => {
  const { t } = useSafeTranslation();

  return (
    <div className={`mt16 ${styles.container}`}>
      <div className={styles.innerContainer}>
        <div className="p-h4">{name}</div>
        <div className="mt16 p--serif p-h1--xl tc-purple-500">
          {englishFormattedEuroCurrency(price, 0)}
        </div>
        <div className="p-h4 mt8 tc-purple-500">
          {t('expatEu.qnr.quote.plan.perMonth', 'per month')}
        </div>
        <button
          className={`p-btn p-btn--primary mt16 ${styles.button}`}
          onClick={onSelect}
          data-cy={`policies-expat-button-quote-${name}`}
          type="button"
        >
          {t('expatEu.qnr.quote.plan.planButton.label', 'Select {{name}}', {
            name,
          })}
        </button>
      </div>
    </div>
  );
};

export default Chip;
