import { getHouseholdPrices } from '@getpopsure/household-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { LinkBadge } from 'components/LinkBadge';
import { HouseholdQuestionnaireRoutes } from 'features/household/models';
import { getPolicyOptionsOrDefaults } from 'features/household/selectors';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import houseIcon from './assets/house.svg';
import moneyIcon from './assets/money.svg';
import pinIcon from './assets/pin.svg';
import shieldIcon from './assets/shieldHollow.svg';
import styles from './PlanSection.module.scss';

type PlanSectionProps = {
  questionnaireRoutes: HouseholdQuestionnaireRoutes | undefined;
};

export const PlanSection = ({ questionnaireRoutes }: PlanSectionProps) => {
  const { t } = useSafeTranslation();
  const { city, hasSecurityDevice, insuredSum, postcode, propertyInSqMeters } =
    useSelector(getPolicyOptionsOrDefaults);

  const householdPrices = getHouseholdPrices({
    postcode,
    propertyInSqMeters,
    insuredSum,
    hasSecurityDevice,
    bikeInsuredSum: 0,
  });

  const basicPrice = householdPrices?.basicPremium
    ? englishFormattedEuroCurrency(householdPrices.basicPremium, 2)
    : 0;

  return (
    <section className="pt64">
      <h2 className="p-h2 mb16">
        {t('page.household.addons.plan.title', 'Plan')}
      </h2>
      <div
        className={`d-flex p24 pl48 jc-between gap16 bg-white br8 ${styles.content}`}
      >
        <div>
          <h3 className={`p-h3 ${styles.title}`}>
            {t(
              'page.household.addons.plan.householdcontentsinsurance',
              '🛡 Household contents insurance'
            )}
          </h3>
          {hasSecurityDevice ? (
            <p className="p-p tc-grey-500 ">
              {t(
                'page.household.addons.plan.securitydevicediscount',
                'With 5% home security device discount'
              )}
            </p>
          ) : null}
          <div
            className={`d-flex f-wrap ai-center gap8 ${styles.badgeContainer}`}
          >
            <LinkBadge
              className="bg-grey-200"
              iconSrc={moneyIcon}
              iconAlt={t(
                'page.household.addons.plan.badge.money.alt',
                'money icon'
              )}
              to={questionnaireRoutes?.insuredSum?.path || ''}
            >
              {insuredSum.toLocaleString()}
            </LinkBadge>
            <LinkBadge
              className="bg-grey-200"
              iconSrc={pinIcon}
              iconAlt={t(
                'page.household.addons.plan.badge.pin.alt',
                'map pin icon'
              )}
              to={questionnaireRoutes?.address?.path || ''}
            >{`${postcode} ${city}`}</LinkBadge>
            <LinkBadge
              className="bg-grey-200"
              iconSrc={houseIcon}
              iconAlt={t(
                'page.household.addons.plan.badge.house.alt',
                'house icon'
              )}
              to={questionnaireRoutes?.livingSpace?.path || ''}
            >{`${propertyInSqMeters} ${t(
              'page.household.questionnaire.livingspace.unit.sqmeters'
            )}`}</LinkBadge>
            <LinkBadge
              className="bg-grey-200"
              iconSrc={shieldIcon}
              iconAlt={t(
                'page.household.addons.plan.badge.shield.alt',
                'shield icon'
              )}
              to={questionnaireRoutes?.securityDevices?.path || ''}
            >
              {hasSecurityDevice
                ? t(
                    'page.household.quote.calculator.hassecuritydevices',
                    'Home security devices'
                  )
                : t(
                    'page.household.quote.calculator.hasnosecuritydevices',
                    'No home security devices'
                  )}
            </LinkBadge>
          </div>
        </div>
        <div className={`d-flex fd-column ai-center ${styles.priceContainer}`}>
          <h2 className="p-h2">{basicPrice}</h2>
        </div>
      </div>
    </section>
  );
};
