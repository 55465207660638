import { UploadDocumentModal } from 'features/policySingle/components/legacy/modals/bike/UploadDocumentModal';
import { getFileType } from 'shared/util/getFileType';
import { v4 as uuidv4 } from 'uuid';

import { PolicySingleModalProps } from '../../../components/modals';

export const BikeUploadDocumentModal = (props: PolicySingleModalProps) => {
  const {
    onClose,
    policyData: {
      id,
      attributes: { questionnaireId },
      userUploads,
    },
  } = props;

  const uploadedDocs = (userUploads || []).map(
    ({ title: fileName, downloadURL }) => ({
      id: uuidv4(),
      name: fileName,
      type: getFileType(downloadURL),
      progress: 100,
      previewUrl: downloadURL,
    })
  );

  return (
    <UploadDocumentModal
      isOpen
      onClose={onClose}
      policyId={id}
      questionnaireId={questionnaireId}
      uploadedDocs={uploadedDocs}
    />
  );
};
