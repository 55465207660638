import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { ClaimsAction } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import {
  LiabilityClaims,
  LiabilityClaimsDispatch,
  SubmitInfo,
} from 'features/liabilityClaims/models';
import { APIResponseError } from 'models/error';
import endpoint from 'shared/api';

export const storeLiabilityClaimsAnswers = (
  answer: Partial<LiabilityClaims>
): ClaimsAction => ({
  type: 'STORE_LIABILITY_CLAIM',
  liability: answer,
});

export const flushLiabilityClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_LIABILITY_CLAIM',
});

export const retrievePolicyStartDate =
  (policyId: string) =>
  async (dispatch: LiabilityClaimsDispatch): Promise<string | null> => {
    const requestType: RequestType = 'LOAD_MY_POLICY_DETAIL';
    dispatch(setRequestInProcess(true, requestType));

    try {
      const {
        data: {
          attributes: { startDate },
        },
      } = await endpoint.getPolicyDetail(policyId);
      dispatch(setRequestInProcess(false, requestType));

      if (startDate) return startDate;
      return null;
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      return null;
    }
  };

export const submitClaim =
  (answers: SubmitInfo) => async (dispatch: LiabilityClaimsDispatch) => {
    const requestType: RequestType = 'CREATE_CLAIM';
    dispatch(setRequestInProcess(true, requestType));

    try {
      await endpoint.submitClaim(answers);
      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

export const handleSubmitClaim =
  (policyId: string, answers: Partial<LiabilityClaims>) =>
  async (dispatch: LiabilityClaimsDispatch) => {
    if (
      !answers.dateOfEvent ||
      !answers.claimType ||
      !answers.description ||
      !answers.uploadedDocumentTokens ||
      (answers.claimType === 'KEY_LOST' &&
        (!answers.payoutDetails || !answers.payoutDetails.iban))
    ) {
      throw new Error(
        `[Liability Claims] Answer(s) are missing for submission for policy id: ${policyId}`
      );
    }

    const isThirdPartyInfoRequired = Boolean(answers.claimType !== 'KEY_LOST');

    const tokens = answers.uploadedDocumentTokens
      .map(({ token }) => token)
      .filter((token): token is string => token !== undefined);

    const submittableInfo: SubmitInfo = {
      type: 'LIABILITY',
      userPolicyId: policyId,
      dateOfEvent: answers.dateOfEvent,
      description: answers.description,
      claimType: answers.claimType,
      uploadedDocumentTokens: tokens,
      ...(isThirdPartyInfoRequired &&
      answers.thirdPartyName &&
      answers.thirdPartyEmail &&
      answers.thirdPartyAddress
        ? {
            thirdParty: {
              name: answers.thirdPartyName,
              email: answers.thirdPartyEmail,
              address: answers.thirdPartyAddress,
            },
          }
        : {}),
      ...(answers.claimType === 'KEY_LOST' && answers.payoutDetails
        ? answers.payoutDetails
        : {}),
    };

    dispatch(submitClaim(submittableInfo));
  };
