import { Trans } from '@getpopsure/i18n-react';
import routes from 'constants/routes';
import { checkWhite } from 'icons';
import { generatePath, Link, useParams } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import styles from './BoolConsultationSubmitted.module.scss';

export const BookConsultationSubmitted = () => {
  const { t } = useSafeTranslation();
  const { policyId } = useParams<{ policyId: string }>();

  return (
    <div className={`d-flex p-body ai-center jc-center ${styles.container}`}>
      <div className="p-body d-flex fd-column jc-center mt80 mb80 ai-center">
        <img
          src={checkWhite}
          className={styles.image}
          alt={t(
            'legal.qnr.claims.bookConsultation.submitted.checkAlt',
            'check circle'
          )}
        />
        <h1 className="p-h2 mt32">
          {t(
            'legal.qnr.claims.bookConsultation.submitted.title',
            'Request submitted!'
          )}
        </h1>
        <p className="p-p fw-normal mt24 tc-grey-700 ta-center wmx8">
          <Trans i18nKey="legal.qnr.claims.bookConsultation.v2.submitted.explanation">
            <b className="fw-bold">Within 3 business days</b>, a lawyer from our
            independent partner (TeReBe) will call you. After the consultation,
            you can submit your claim in your account.
          </Trans>
        </p>
        <Link
          className={`p-btn--secondary bg-white wmn4 mt24 ${styles.button}`}
          to={generatePath(routes.me.policies.detail.path, {
            policyId,
          })}
        >
          {t(
            'legal.qnr.claims.bookConsultation.submitted.cta',
            'Back to policy'
          )}
        </Link>
      </div>
    </div>
  );
};
