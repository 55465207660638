import { PartialQuestionnaire } from 'models/questionnaireFramework';

import { ProcessingAnswers, ProcessingGroup } from './processing';

export const sixMonthsOldOrNot = {
  less: 'Less than 6 months ago',
  more: 'More than 6 months ago',
};

export type IsixMonthsOldOrNot = keyof typeof sixMonthsOldOrNot;

interface Personal {
  bikePurchasePrice: number;
  bikePurchaseDate: IsixMonthsOldOrNot;
  isVintageBike: boolean;
  isElectricBike: boolean;
  hasCarbonFrameBike: boolean;
  postalCode: string;
  hasBike?: boolean;
}

export type QuestionnaireAnswers = Personal & ProcessingAnswers;

export type GroupId = 'personalInfo' | ProcessingGroup;

export type BikeQuestionnaire = PartialQuestionnaire<
  QuestionnaireAnswers,
  GroupId
>;
