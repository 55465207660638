import { language } from '@getpopsure/i18n-react';
import { website } from '@getpopsure/private-constants';
import Session from '@getpopsure/session';
import LanguageSwitcher from 'components/LanguageSwitcher';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import { selectedCategoryForPath } from 'features/navigationBar/utlis/selectedCategoryForPath';
import { isSignupPath } from 'features/navigationBar/utlis/signup';
import { useRegion } from 'hooks/useRegion';
import { useLocation } from 'react-router-dom';
import { getFeatureByRegion } from 'routeLocales';

import NavigationBarButton from '../button';
import logo from './img/logo.svg';
import styles from './style.module.scss';

interface LocationWithState {
  from?: {
    main?: boolean;
  };
}

const MobileNavigationBar = ({ showLoggedIn }: { showLoggedIn?: boolean }) => {
  const location = useLocation<LocationWithState>();
  const selectedCategory = selectedCategoryForPath(location.pathname);
  const { isAuthenticated } = Session;
  const { region } = useRegion();
  const locale = language();
  const isSignup = isSignupPath(location.pathname);
  const showLoggedInView = showLoggedIn || (isAuthenticated && !isSignup);

  const hasAccessToFeature = getFeatureByRegion(region);

  const containerStyle = showLoggedInView
    ? styles['container-logged-in']
    : styles['container-logged-out'];

  if (!isAuthenticated && location?.state?.from?.main) {
    return <NavButton title="Main page" />;
  }

  return (
    <div className={containerStyle}>
      <div
        className={
          showLoggedInView
            ? `wmx5 ${styles['content-authenticated']}`
            : styles.content
        }
      >
        {showLoggedInView ? (
          <>
            {hasAccessToFeature('policies') && (
              <NavigationBarButton
                title="Policies"
                category="policies"
                link={routes.me.policies.path}
                selected={selectedCategory === 'policies'}
                data-cy="navigationbar-policies"
              />
            )}
            {hasAccessToFeature('recommendationTool') && (
              <NavigationBarButton
                title="Advice"
                category="advice"
                link={routes.insuranceTool.path}
                selected={selectedCategory === 'advice'}
                data-cy="navigationbar-recommendations"
              />
            )}
            {hasAccessToFeature('claims') && (
              <NavigationBarButton
                title="Claims"
                category="claim"
                link={routes.claims.path}
                selected={selectedCategory === 'claim'}
                data-cy="navigationbar-claim"
              />
            )}
            {hasAccessToFeature('claims') && (
              <NavigationBarButton
                title="Account"
                category="account"
                link={routes.account.path}
                selected={selectedCategory === 'account'}
              />
            )}
          </>
        ) : (
          <>
            <a href={`${website.base}/${locale}-${region}`}>
              <img src={logo} className={styles.logo} alt="Home" />
            </a>
            {isSignup && <LanguageSwitcher type="dropdown" />}
            {!isSignup && (
              <NavigationBarButton
                title="Log in"
                category="login"
                link={routes.account.signIn.path}
                selected={selectedCategory === 'login'}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MobileNavigationBar;
