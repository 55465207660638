import { submitQuestionnaireAnswer } from 'actions/liability';
import { Liability as Questionnaire } from 'models/genericQuestionnaire';
import { QuestionId, QuestionnaireValueTypeMapping } from 'models/liability';
import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from 'reducers';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';

interface StateProps<K extends QuestionId> {
  initialValue?: Partial<Questionnaire>[K];
}

interface DispatchProps<K extends QuestionId> {
  onSubmitValue: (answer: QuestionnaireValueTypeMapping[K]) => void;
}

type OwnProps = RouteComponentProps<{}> & { questionnaireSection: string };

export type ViewProps<K extends QuestionId> = StateProps<K> &
  DispatchProps<K> &
  OwnProps;

export const withContainer = <P extends object, K extends QuestionId>(
  QuestionComponent: ComponentType<P>,
  questionId: K
) => {
  const WrapperComponent = (props) => <QuestionComponent {...(props as P)} />;

  const mapStateToProps = (state: AppState): StateProps<K> => {
    const questionValue =
      getGenericQuestionnaire(state).liability?.[questionId];
    return {
      initialValue: questionValue,
    };
  };

  const mapDispatchToProps = (dispatch): DispatchProps<K> => ({
    onSubmitValue: (answer: QuestionnaireValueTypeMapping[K]) =>
      dispatch(submitQuestionnaireAnswer(answer)),
  });

  return connect<StateProps<K>, DispatchProps<K>, OwnProps, AppState>(
    mapStateToProps,
    mapDispatchToProps
  )(WrapperComponent);
};
