import { PhoneNumber } from 'libphonenumber-js/max';

export const checkPhoneNumber = (
  phoneNumber?: PhoneNumber
): string | undefined => {
  if (!phoneNumber) {
    return 'It seems that the phone number that you’ve entered is incorrect.';
  }

  if (phoneNumber.isValid() === false) {
    return 'It seems that the phone number that you’ve entered is incorrect.';
  }

  const phoneNumberType = phoneNumber.getType();

  if (
    phoneNumberType !== undefined &&
    phoneNumberType !== 'MOBILE' &&
    phoneNumberType !== 'FIXED_LINE_OR_MOBILE'
  ) {
    return 'Please choose a mobile phone number.';
  }

  return undefined;
};
