import { BottomOrRegularModal, Input } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { APIResponseError } from 'models/error';
import { FormEvent, useState } from 'react';

import styles from './LoginModal.module.scss';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  email: string;
  handleSendNewCode: () => void;
  handleSignin: (loginCode: string) => void;
  loading?: boolean;
  error?: APIResponseError;
}

const LoginModalView = ({
  email,
  loading,
  error,
  isOpen,
  handleClose,
  handleSendNewCode,
  handleSignin,
}: Props) => {
  const [loginCode, setLoginCode] = useState('');

  const valid = loginCode.length > 0;

  const invalidLoginCode = error?.response?.status === 422;
  const errorMessage = invalidLoginCode
    ? 'The code you entered was not recognized. Please try again or request a new code'
    : error?.message;

  return (
    <BottomOrRegularModal
      title="Sign in to Feather"
      isOpen={isOpen}
      onClose={() => {
        setLoginCode('');
        handleClose();
      }}
    >
      <form
        className={`p-p pt16 p24 ${styles.container}`}
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          handleSignin(loginCode);
        }}
      >
        <p>Looks like there’s already an account for that email address.</p>
        <p className="mt16">
          We’ve sent a code to <span className="fw-bold">{email}</span>.
        </p>
        <p>Please enter the code below to log in.</p>
        <button
          type="button"
          className={`p-a p-p c-pointer ${styles['send-code-button']}`}
          onClick={(e: FormEvent) => {
            e.preventDefault();
            handleSendNewCode();
          }}
        >
          Send new code
        </button>
        <Input
          data-cy="temporary-login-code"
          className="mt16"
          value={loginCode}
          type="text"
          placeholder="Temporary login code"
          onChange={(e) => {
            setLoginCode(e.target.value);
          }}
          required
          error={errorMessage}
        />
        <button
          className={classNames(
            `p-btn--primary mt24 wmn3 ${styles['signin-button']}`,
            {
              'p-btn--loading': loading,
            }
          )}
          data-cy="temporary-login-code-submit"
          type="submit"
          disabled={!valid}
        >
          Sign in
        </button>
      </form>
    </BottomOrRegularModal>
  );
};

export default LoginModalView;
