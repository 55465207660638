import {
  trackGTMEvent,
  trackPrivateHealthBookCallClicked,
  trackPrivateHealthQuoteEmailSent,
} from '@getpopsure/analytics';
import { Button, MessageSquareIcon, ShareIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import {
  bannerColorMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';
import { useSafeTranslation } from 'shared/i18n';

import styles from './Header.module.scss';

const privateImage = imageTypeMapping.PRIVATE_HEALTH;

type HeaderProps = {
  onSendQuoteButtonClick(): void;
  onTalkToExpertButtonClick(): void;
};

export const Header = (props: HeaderProps) => {
  const { onSendQuoteButtonClick, onTalkToExpertButtonClick } = props;

  const { t } = useSafeTranslation();

  return (
    <>
      <div
        className={styles.colorBanner}
        style={{ backgroundColor: bannerColorMapping.PRIVATE_HEALTH }}
      />
      <header className="p-container d-flex fd-column ai-center">
        <img
          className={classNames('br8', styles.icon)}
          src={privateImage}
          alt="Private health icon"
        />
        <h1 className="p-h1 p--serif mt24">Your coverage</h1>
        <div className={classNames(styles.container, 'd-flex gap8 mt24')}>
          <Button
            className="tc-grey-700"
            variant="textColor"
            onClick={() => {
              trackPrivateHealthQuoteEmailSent({});
              trackGTMEvent({
                event: 'private-quote',
                eventModel: {
                  event_category: 'quote',
                  event_label: 'private_quote_email',
                },
              });
              onSendQuoteButtonClick();
            }}
            leftIcon={<ShareIcon />}
          >
            {t('private.qnr.quote.header.cta.share', 'Share by email')}
          </Button>
          <Button
            className="tc-grey-700"
            variant="textColor"
            onClick={() => {
              trackPrivateHealthBookCallClicked({});
              onTalkToExpertButtonClick();
            }}
            leftIcon={<MessageSquareIcon />}
          >
            {t('private.qnr.quote.header.cta.expert', 'Talk to an expert')}
          </Button>
        </div>
      </header>
    </>
  );
};
