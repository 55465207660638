import { RequestType } from 'constants/requestTypes';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getRequestError, getRequestInProcess } from 'selectors/requests';

/**
 * You can use the useRequestStatus custom hook to get the loading and error
 * status of a specific request
 *
 * @param REQUEST_TYPE string the current request type of the action
 */
export const useRequestStatus = (REQUEST_TYPE: RequestType) => {
  const error = useSelector((state: AppState) =>
    getRequestError(state, REQUEST_TYPE)
  );
  const loading = useSelector((state: AppState) =>
    getRequestInProcess(state, REQUEST_TYPE)
  );

  return { loading, error };
};
