import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { Tariff } from 'features/privateHealthPreSignup/models';

import styles from './CTACell.module.scss';

interface CTACellProps<T extends { tariff?: Tariff }> {
  name: string;
  row: T;
  selectedPlan?: Tariff;
  showPrice?: boolean;
  onCTAClick: () => void;
}

export const CTACell = <
  T extends { tariff?: Tariff; contribution?: number | string }
>({
  name,
  row,
  showPrice = true,
  selectedPlan,
  onCTAClick,
}: CTACellProps<T>) => {
  const priceStr =
    typeof row.contribution === 'number'
      ? englishFormattedEuroCurrency(Math.round(row.contribution), true)
      : row.contribution;

  if (!priceStr) {
    return <p className="p-h3">{name}</p>;
  }

  const { tariff } = row;

  const isSelected = selectedPlan === row.tariff;

  return (
    <div className="w100 d-flex fd-column ai-center jc-center gap8 wmx5">
      <Button
        className={classNames('w100 d-flex fd-column', styles.ctaButton, {
          [styles.selected]: isSelected,
        })}
        variant="textColor"
        type="submit"
        onClick={onCTAClick}
        disabled={!tariff}
      >
        {tariff ? name : 'Public health'}
        {showPrice && <span className="p-p tc-purple-500">{priceStr}/mo</span>}
      </Button>
    </div>
  );
};
