import { EditIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';

export const PrivatePensionManagePolicyButton = (
  props: PolicyDetailsButtonProps
) => {
  const { onOpenModal } = props;

  const handleManagePolicyButton = () => {
    onOpenModal('PRIVATE_PENSION_MANAGE_POLICY');
  };

  return (
    <ButtonCell
      title="Manage policy"
      icon={<EditIcon noMargin size={24} className="ml16" />}
      onClick={handleManagePolicyButton}
    />
  );
};
