import { PolicyShareButton } from 'components/PolicyShareButton/PolicyShareButton';
import { getRoutes } from 'features/questionnaireFramework/utils/getRoutes';
import hashSum from 'hash-sum';
import { FeatureName } from 'models/questionnaire';
import {
  AnswerTypes,
  PartialQuestionnaire,
  Question,
} from 'models/questionnaireFramework';
import { useMemo, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import { isStagingOrDev } from 'shared/util/isStagingOrDev';

import PreventSkipRedirect from './components/PreventSkipRedirect';
import QuestionComponent from './components/QuestionComponent';
import QuestionRoute from './components/QuestionRoute';
import { QuestionnaireError } from './models';
import { getNextQuestionId } from './utils/getNextQuestionId';

// eslint-disable-next-line @typescript-eslint/ban-types
const Questionnaire = <Questionnaire extends Object, GroupId extends string>({
  questionnaire,
  questionnaireAnswers,
  handleAnswerQuestion,
  featureName,
  questionnaireUrl,
  handleRemoveAnswer,
  sendQuestionnaireError,
  share,
}: {
  featureName: FeatureName;
  questionnaire: PartialQuestionnaire<Questionnaire, GroupId>;
  questionnaireAnswers: Partial<Questionnaire>;
  handleAnswerQuestion: (answer: { [k: string]: AnswerTypes }) => void;
  handleRemoveAnswer?: (questionIds: Array<keyof Questionnaire>) => void;
  questionnaireUrl?: string;
  sendQuestionnaireError?: (error: QuestionnaireError) => void;
  share?: {
    verticalId: keyof GenericQuestionnaireState;
  };
}) => {
  /**
   *
   * We can use the currentId to check if the Questionnaire has mounted for the first time.
   * We need this check to ensure that we only redirect to the first page
   * if the questionnaire has mounted.
   * In every other case we try to get the next question id.
   *
   */
  const [currentId, setCurrentId] = useState<keyof Questionnaire>();

  const [validationError, setValidationError] = useState<string | null>(null);
  const { url } = useRouteMatch();
  const history = useHistory();
  const questionnairePrefix = `${url}${
    questionnaireUrl ? `/${questionnaireUrl}` : ''
  }`;
  const routes = getRoutes(questionnaire, questionnairePrefix);

  const unansweredQuestionId = useMemo(() => {
    if (currentId) {
      return getNextQuestionId(currentId, questionnaire, questionnaireAnswers);
    }
    return null;
  }, [currentId, questionnaire, questionnaireAnswers]);

  const firstQuestionId = Object.keys(questionnaire)[0] as keyof Questionnaire;

  const nextPath = unansweredQuestionId
    ? routes[unansweredQuestionId].path
    : routes[firstQuestionId].path;
  const requiresAuth =
    unansweredQuestionId && routes && routes[unansweredQuestionId].requiresAuth;

  const handleSubmitValue = async (
    questionId: keyof Questionnaire,
    questionObject: Question<Questionnaire, GroupId>,
    currentAnswer: AnswerTypes
  ) => {
    if (questionObject.validateAnswer) {
      const error = questionObject.validateAnswer(
        currentAnswer,
        questionnaireAnswers
      );

      if (error) {
        setValidationError(error);
        return;
      }
    }
    setValidationError(null);

    let hasChanges = false;

    if (questionObject.retrieveAnswerObject) {
      const allUpdatableAnswers = questionObject.retrieveAnswerObject(
        currentAnswer,
        questionnaireAnswers
      );

      /**
       *
       * We can compare the current answers with the next possible answers.
       * This is possible as we can apply all the updatable answers with the current state
       * and then compare the two. There should be no need to persist the previous
       * and next state in this case as the comparison has to happen before the next
       * render (due to the fact that remove answers relies on the diff)
       *
       */
      hasChanges =
        hashSum(questionnaireAnswers) !==
        hashSum({ ...questionnaireAnswers, ...allUpdatableAnswers });
      if (hasChanges) {
        handleAnswerQuestion(allUpdatableAnswers);
      }
    }

    if (handleRemoveAnswer && questionObject.retrieveQuestionsToRemove) {
      const questionsToRemove =
        questionObject.retrieveQuestionsToRemove(currentAnswer);
      if (questionsToRemove) {
        /**
         *
         * We use a simple diffing mechanism by comparing hashes to ensure that these changes are only applied
         * when there was an actual change otherwise we don't need to remove the answers.
         *
         */
        if (hasChanges) {
          handleRemoveAnswer(questionsToRemove);
        }
      }
    }

    setCurrentId(questionId);

    if (!questionObject.retrieveNextPageId) {
      history.push(questionnairePrefix);
      return;
    }

    const nextId = questionObject.retrieveNextPageId(
      currentAnswer,
      questionnaireAnswers
    );
    history.push(routes[nextId].path ?? url);
  };

  return (
    <Switch>
      <QuestionRoute
        // TODO: Make this dynamic
        path={`${questionnairePrefix}/:groupId/:questionId`}
        requiresAuth={requiresAuth ?? false}
      >
        <PreventSkipRedirect
          questions={questionnaire}
          answers={questionnaireAnswers}
          routes={routes}
        >
          <>
            {isStagingOrDev && share && (
              <PolicyShareButton
                questionnaireAnswers={questionnaireAnswers}
                verticalId={share.verticalId}
              />
            )}

            <QuestionComponent
              featureName={featureName}
              questionnaire={questionnaire}
              questionnaireAnswers={questionnaireAnswers}
              onSubmitValue={handleSubmitValue}
              routes={routes}
              baseUrl={url}
              error={validationError}
              sendQuestionnaireError={sendQuestionnaireError}
            />
          </>
        </PreventSkipRedirect>
      </QuestionRoute>
      <Route path={`${url}`}>
        <Redirect to={{ pathname: nextPath, state: history.location.state }} />
      </Route>
    </Switch>
  );
};

export default Questionnaire;
