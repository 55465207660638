import { TFunction } from '@getpopsure/i18n-react';
import { getTrackingObject } from '@getpopsure/tracker';
import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint from 'shared/api';
import { CreateQuoteDispatch } from 'SignupQuestionnaire/actions/account';

import {
  CreateQuoteReturnType,
  ExpatSpainDependent,
  QuestionnaireType,
  QuoteResponse,
  SubmittableExpatSpainDependentQuestionnaire,
  SubmittableExpatSpainQuestionnaire,
  SubmittableExpatSpainQuote,
  SubmittableQuestionnaire,
  ZSubmittableExpatSpainDependentQuestionnaire,
  ZSubmittableExpatSpainQuestionnaire,
  ZSubmittableExpatSpainQuote,
} from './models';

export const createExpatSpainQuote = (answers: SubmittableExpatSpainQuote) => {
  return endpoint.network.post<QuoteResponse[]>(
    '/signups/expat-spain/quotes',
    answers
  );
};

const createExpatSpainQuestionnaire = async (
  answers: SubmittableQuestionnaire,
  questionnaireType: QuestionnaireType
) => {
  const source = getTrackingObject();
  return endpoint.network.post<{
    id: string;
    answers:
      | SubmittableExpatSpainQuestionnaire
      | SubmittableExpatSpainDependentQuestionnaire;
  }>('/questionnaires/', {
    answers,
    questionnaireType,
    metadata: source,
  });
};

export const createPostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'expatSpain'> | RequestAction
  > =>
  async (
    dispatch: CreateQuoteDispatch<'expatSpain'>,
    getState: () => AppState
  ) => {
    const questionnaire = getGenericQuestionnaire(getState()).expatSpain || {};

    try {
      if (!questionnaire.quote) {
        throw new Error(`[EXPAT_SPAIN] Quote not defined`);
      }

      const quoteValidation =
        ZSubmittableExpatSpainQuote.safeParse(questionnaire);

      if (!quoteValidation.success) {
        throw new Error(
          `[EXPAT_SPAIN - Submission] Questionnaire quote errors: ${quoteValidation.error.toString()}`
        );
      }

      const validatedQuote = quoteValidation.data;

      const validationResult =
        ZSubmittableExpatSpainQuestionnaire.safeParse(questionnaire);

      if (!validationResult.success) {
        throw new Error(
          `[EXPAT_SPAIN - Submission] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const validatedAnswers = validationResult.data;

      const { data: quotes } = await createExpatSpainQuote(validatedQuote);

      const [quote] = quotes;

      dispatch(setRequestInProcess(true, 'CREATE_POST_QUOTE_SUBMIT_INFO'));

      const {
        email,
        name: { firstName, lastName },
      } = validatedAnswers.personalInfo;

      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            email,
          })
        );
      }

      const {
        data: { id: questionnaireId },
      } = await createExpatSpainQuestionnaire(validatedAnswers, 'INCOMING_ES');
      dispatch(
        storeGenericQuestionnaireAnswer('expatSpain', {
          quoteId: quote.id,
          questionnaireId,
          quote: { ...questionnaire.quote, id: quote.id },
        })
      );

      await generateCheckoutDocuments(
        questionnaireId,
        quote.id,
        'INCOMING_ES',
        t
      );

      dispatch(setRequestInProcess(false, 'CREATE_POST_QUOTE_SUBMIT_INFO'));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          'CREATE_POST_QUOTE_SUBMIT_INFO'
        )
      );
      Sentry.captureException(
        `[EXPAT_SPAIN Review] Failed to submit with following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };

export type ExpatSpainDispatch<T extends Action = never> = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  GenericQuestionnaireAction<'expatSpain'> | T
>;

export const storeDependentGenericQuestionnaireAnswer =
  (answer: Partial<ExpatSpainDependent>) =>
  (dispatch: ExpatSpainDispatch, getState: () => AppState) => {
    const state = getState();
    const dependent = state.genericQuestionnaire.expatSpain?.dependent;

    dispatch(
      storeGenericQuestionnaireAnswer('expatSpain', {
        dependent: { ...dependent, ...answer },
      })
    );
  };

export const removeDependentGenericQuestionnaireAnswer =
  (questionIds: Array<keyof ExpatSpainDependent>) =>
  (dispatch: ExpatSpainDispatch, getState: () => AppState) => {
    const dependents = getGenericQuestionnaire(getState()).expatSpain
      ?.dependent;

    const nextState: Record<string, unknown> = {
      ...dependents,
    };
    for (const id of questionIds) {
      if (nextState[id] !== undefined) {
        delete nextState[id];
      }
    }

    dispatch(
      storeGenericQuestionnaireAnswer('expatSpain', {
        dependent: nextState,
      })
    );
  };

export const createDependentPostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'expatSpain'> | RequestAction
  > =>
  async (
    dispatch: CreateQuoteDispatch<'expatSpain'>,
    getState: () => AppState
  ) => {
    const questionnaire =
      getGenericQuestionnaire(getState()).expatSpain?.dependent || {};

    try {
      if (!questionnaire.quote) {
        throw new Error(`[EXPAT_SPAIN_DEPENDENT] Quote not defined`);
      }

      const quoteValidation =
        ZSubmittableExpatSpainQuote.safeParse(questionnaire);

      if (!quoteValidation.success) {
        throw new Error(
          `[EXPAT_SPAIN_DEPENDENT - Submission] Questionnaire quote errors: ${quoteValidation.error.toString()}`
        );
      }

      const validatedQuote = quoteValidation.data;

      const validationResult =
        ZSubmittableExpatSpainDependentQuestionnaire.safeParse(questionnaire);

      if (!validationResult.success) {
        throw new Error(
          `[EXPAT_SPAIN_DEPENDENT- Submission] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const validatedAnswers = validationResult.data;

      const { data: quotes } = await createExpatSpainQuote(validatedQuote);

      const [quote] = quotes;

      dispatch(setRequestInProcess(true, 'CREATE_POST_QUOTE_SUBMIT_INFO'));

      const {
        data: { id: questionnaireId },
      } = await createExpatSpainQuestionnaire(
        validatedAnswers,
        'INCOMING_ES_DEPENDENT'
      );
      dispatch(
        storeDependentGenericQuestionnaireAnswer({
          quoteId: quote.id,
          questionnaireId,
          quote: { ...questionnaire.quote, id: quote.id },
        })
      );

      await generateCheckoutDocuments(
        questionnaireId,
        quote.id,
        'INCOMING_ES',
        t
      );

      dispatch(setRequestInProcess(false, 'CREATE_POST_QUOTE_SUBMIT_INFO'));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          'CREATE_POST_QUOTE_SUBMIT_INFO'
        )
      );
      Sentry.captureException(
        `[EXPAT_SPAIN_DEPENDENT Review] Failed to submit with following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };
