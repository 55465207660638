import { QuotePlan } from 'models/expat';

import styles from './style.module.scss';

const indexForSelectedPlan: { [k in QuotePlan]: number } = {
  BASIC: 0,
  PREMIUM: 1,
};

const SegmentedControl = ({
  onChange,
  selectedPlan,
}: {
  onChange: (plan: QuotePlan) => void;
  selectedPlan: QuotePlan;
}) => (
  <div className={styles.container}>
    <div
      className={`${styles['selected-background']} ${
        styles[
          `selected-background--position-${indexForSelectedPlan[selectedPlan]}`
        ]
      }`}
    />
    <button
      className={`ds-interactive-component ${styles.item} ${
        selectedPlan === 'BASIC' ? styles['item--selected'] : ''
      }`}
      onClick={() => onChange('BASIC')}
      type="button"
    >
      Basic
    </button>
    <button
      className={`ds-interactive-component ${styles.item} ${
        selectedPlan === 'PREMIUM' ? styles['item--selected'] : ''
      }`}
      onClick={() => onChange('PREMIUM')}
      type="button"
    >
      Premium
    </button>
  </div>
);

export default SegmentedControl;
