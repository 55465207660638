import { fetchAllClaims } from 'actions/claims';
import { deleteUserAccount, fetchAccountInfo } from 'actions/user';
import LoadingSpinner from 'components/loadingSpinner';
import { calculatePolicyStatus } from 'features/accountV2/util';
import { useGetPolicies } from 'hooks/useGetPolicies';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getClaims } from 'selectors/claims';
import {
  getAccountInfo,
  getDataRemovalRequests,
  isDelinquentUser,
} from 'selectors/user';

import AccountSettingsView from './AccountSettings.view';

export const AccountSettings = () => {
  const dispatch =
    useDispatch<ThunkDispatch<AppState, Record<string, unknown>, AnyAction>>();
  const user = useSelector(getAccountInfo);
  const { policies, isLoading: loading } = useGetPolicies();
  const claims = useSelector(getClaims);
  const dataRemovalRequests = useSelector(getDataRemovalRequests);
  const isDelinquent = useSelector(isDelinquentUser);
  const policyStatus = calculatePolicyStatus(policies ?? [], claims ?? []);

  const deleteAccount = async (
    successCb: () => void,
    failureCb: () => void
  ) => {
    const response = await dispatch(deleteUserAccount());

    if (response === 'SUCCESS') {
      if (successCb) {
        successCb();
      }
    } else if (failureCb) {
      failureCb();
    }
  };

  useEffect(() => {
    dispatch(fetchAccountInfo());
    dispatch(fetchAllClaims());
  }, [dispatch]);

  if (loading || user === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <AccountSettingsView
      activeDeleteRequest={
        dataRemovalRequests ? dataRemovalRequests.length > 0 : false
      }
      deleteUserAccount={deleteAccount}
      isDelinquent={Boolean(isDelinquent)}
      policyStatus={policyStatus}
      user={user}
    />
  );
};
