import {
  getRoutes,
  QuestionnaireQuestions,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import { submitScreenedOutQuestionnaire } from 'features/life/actions';
import { useCheckoutStarted } from 'hooks/useCheckoutStarted';
import { UserWithBrokerMandate } from 'models/user';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { AppState } from 'reducers';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { Life, questionGroupsMapping, questionnaireIdMapping } from './models';
import { getTranslatedQuestionnaire, LifeComponents } from './questionnaire';
import { getRules, removeAnswersLogic } from './questionnaire/rules';

const prefillName = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<Life> = {};
    const { firstName, lastName } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    return toUpdate;
  }

  return null;
};

export const SignupPage = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.genericQuestionnaire.lifeV2) || {};
  const dispatch = useDispatch();
  const account = useSelector(getAccountInfo);
  const { t } = useSafeTranslation();

  const onAnswer = <QuestionId extends keyof Life>(
    questionId: QuestionId,
    answer: Life[QuestionId],
    additionalData?: Partial<Life>
  ) => {
    dispatch(
      storeGenericQuestionnaireAnswer('lifeV2', { [questionId]: answer })
    );

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(removeGenericQuestionnaireAnswer('lifeV2', answersToRemove));

    const toUpdate = prefillName(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );

    if (['quote', 'review'].includes(questionId) && additionalData) {
      dispatch(storeGenericQuestionnaireAnswer('lifeV2', additionalData));
    }

    if (
      questionId === 'hasReviewedEligibility' &&
      answer === false &&
      questionnaireAnswers.hasReviewedEligibility !== false
    ) {
      dispatch(submitScreenedOutQuestionnaire());
    }

    if (toUpdate) {
      dispatch(storeGenericQuestionnaireAnswer('lifeV2', toUpdate));
    }
  };

  const questions = getTranslatedQuestionnaire(t);

  const rules = getRules();

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, routes.policies.life.path),
    rules,
  };

  useCheckoutStarted('LIFE', questionnaireAnswers.email);

  return (
    <Switch>
      <Route path={routes.policies.life.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: LifeComponents,
            progressBar: {
              questionnaireIdMapping,
              questionGroupsMapping,
            },
          }}
          basePath={routes.policies.life.path}
          questionId="intro"
          featureName="Life"
          share={{
            verticalId: 'lifeV2',
          }}
        />
      </Route>
    </Switch>
  );
};
