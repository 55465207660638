import { EuroClaimIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';

export type TravelHealthClaimType = 'TRAVEL_HEALTH_CLAIMS';

export const TravelHealthClaimButton = (props: PolicyDetailsButtonProps) => {
  const { onOpenModal } = props;

  const handleManagePolicyButton = () => {
    onOpenModal('TRAVEL_HEALTH_CLAIMS');
  };

  return (
    <ButtonCell
      title="Make a claim"
      icon={<EuroClaimIcon noMargin size={24} className="ml16" />}
      onClick={handleManagePolicyButton}
    />
  );
};
