import { TFunction } from '@getpopsure/i18n-react';
import {
  EuroClaimIcon,
  HeartHandIcon,
  LockIcon,
  MessageCircleIcon,
  SmartphoneIcon,
  UsersIcon,
} from '@popsure/dirty-swan';
import { FAQInfo } from 'components/faq';

export const coverageData = (t: TFunction) => [
  {
    title: t(
      'expatLongTerm.qnr.quote.whyFeather1.title',
      '95,000 happy customers'
    ),
    description: t(
      'expatLongTerm.qnr.quote.whyFeather1.description',
      'Experience peace of mind with insurance crafted by expats, for expats.'
    ),
    icon: <UsersIcon size={32} />,
  },
  {
    title: t(
      'expatLongTerm.qnr.quote.whyFeather2.title',
      '22,000+ claims processed'
    ),
    description: t(
      'expatLongTerm.qnr.quote.whyFeather2.description',
      'Fast and reliable service to get your money back in days, not months.'
    ),
    icon: <EuroClaimIcon size={32} />,
  },
  {
    title: t('expatLongTerm.qnr.quote.whyFeather3.title', '4.9 Star rating'),
    description: t(
      'expatLongTerm.qnr.quote.whyFeather3.description',
      "Over 1,700 reviews can't be wrong."
    ),
    icon: <HeartHandIcon size={32} />,
  },
  {
    title: t(
      'expatLongTerm.qnr.quote.whyFeather4.title',
      'English-speaking support'
    ),
    description: t(
      'expatLongTerm.qnr.quote.whyFeather4.description',
      'No need for translations—our team speaks your language.'
    ),
    icon: <MessageCircleIcon size={32} />,
  },
  {
    title: t('expatLongTerm.qnr.quote.whyFeather5.title', 'All digital'),
    description: t(
      'expatLongTerm.qnr.quote.whyFeather5.description',
      'Sign up, file claims, and manage your policy within our app.'
    ),
    icon: <SmartphoneIcon size={32} />,
  },
  {
    title: t('expatLongTerm.qnr.quote.whyFeather6.title', 'Privacy first'),
    description: t(
      'expatLongTerm.qnr.quote.whyFeather6.description',
      'We store your data in Europe; it remains private, secure and GDPR compliant.'
    ),
    icon: <LockIcon size={32} />,
  },
];

export const faqData = (t: TFunction): FAQInfo[] => [
  {
    questionId: 1,
    question: t(
      'expatLongTerm.qnr.quote.faq1.question',
      'Am I eligible for expat insurance?'
    ),
    answer: t(
      'expatLongTerm.qnr.quote.faq1.answer',
      'This policy is best suited for:\n\nThose who have been in Germany over 5 years and are not eligible for public or private health, or those who are applying for a visa renewal and not eligible for public or private health.'
    ),
  },
  {
    questionId: 2,
    question: t(
      'expatLongTerm.qnr.quote.faq2.question',
      'Does this policy fulfill the requirements for my visa?'
    ),
    answer: t(
      'expatLongTerm.qnr.quote.faq2.answer',
      'Yes, this policy meets the legal requirements for getting your first visa or visa renewal.'
    ),
  },
  {
    questionId: 3,
    question: t(
      'expatLongTerm.qnr.quote.faq3.question',
      'When will I get my documents after signing up?'
    ),
    answer: t(
      'expatLongTerm.qnr.quote.faq3.answer',
      'We can get you covered within 1-2 days of application.'
    ),
  },
  {
    questionId: 4,
    question: t(
      'expatLongTerm.qnr.quote.faq4.question',
      'What is the maximum amount of time that I can be covered?'
    ),
    answer: t(
      'expatLongTerm.qnr.quote.faq4.answer',
      'With our long term policy, there is no time limit to the coverage.'
    ),
  },
  {
    questionId: 5,
    question: t(
      'expatLongTerm.qnr.quote.faq5.question',
      'What is the expat cancellation policy?'
    ),
    answer: t(
      'expatLongTerm.qnr.quote.faq5.answer',
      'There is a 14 day cooling off period from the start date of the policy.\n\nAfter this period, you can still cancel anytime on a monthly basis! Whenever you cancel, you would then no longer be charged during the next monthly billing cycle.'
    ),
  },
  {
    questionId: 6,
    question: t(
      'expatLongTerm.qnr.quote.faq6.question',
      'Are there any waiting periods?'
    ),
    answer: t(
      'expatLongTerm.qnr.quote.faq6.answer',
      'There is no waiting period for long term expat health, but please note, pre-existing conditions will not be covered under the policy.'
    ),
  },
  {
    questionId: 7,
    question: t(
      'expatLongTerm.qnr.quote.faq7.question',
      'Do I have to pay medical costs myself before getting reimbursed?'
    ),
    answer: t(
      'expatLongTerm.qnr.quote.faq7.answer',
      "Yes, you'll have to pay upfront and submit photos of all the bills or receipts from your account to get reimbursed. You must pay the doctor or clinic as soon as possible. The reimbursement will be made to you, not to the doctor."
    ),
  },
  {
    questionId: 8,
    question: t(
      'expatLongTerm.qnr.quote.faq8.question',
      'Can I add dependents to my expat plan?'
    ),
    answer: t(
      'expatLongTerm.qnr.quote.faq8.answer',
      'Yes, dependents can be added to your plan. You would simply purchase a new policy for them and we can store it under one account.'
    ),
  },
  {
    questionId: 9,
    question: t(
      'expatLongTerm.qnr.quote.faq9.question',
      'Are dental treatments covered?'
    ),
    answer: t(
      'expatLongTerm.qnr.quote.faq9.answer',
      'Yes, there is cover immediately for dental accidents, and routine dental care is also included after 6 months on the policy.'
    ),
  },
  {
    questionId: 10,
    question: t(
      'expatLongTerm.qnr.quote.faq10.question',
      'Can I switch between the short term to long term expat policies?'
    ),
    answer: t(
      'expatLongTerm.qnr.quote.faq10.answer',
      'Yes! If you would like to switch to our long term plan, we can assist you to do so and ensure there are no gaps nor overlap in coverage.'
    ),
  },
];
