import { Card, CardProps } from '@popsure/dirty-swan';
import { useSafeTranslation } from 'shared/i18n';

import { LoaderIcon } from './assets/LoaderIcon';
import { IntercomLoaderWrapper } from './IntercomLoaderWrapper';

export interface IntercomLauncherProps {
  icon?: string | JSX.Element | null;
  cardProps?: CardProps;
}

export const IntercomLauncher = ({
  icon = null,
  cardProps = {},
}: IntercomLauncherProps) => {
  const { t } = useSafeTranslation();
  return (
    <IntercomLoaderWrapper
      render={({ error, loading, handleClick }) => {
        if (error)
          return (
            <Card
              titleVariant="small"
              density="compact"
              icon={icon}
              title={t('account.actions.contactUs.title', 'Contact us')}
              {...cardProps}
            />
          );

        if (loading)
          return (
            <Card
              titleVariant="small"
              density="compact"
              title={t('account.actions.contactUs.title', 'Contact us')}
              icon={<LoaderIcon />}
              {...cardProps}
            />
          );

        return (
          <Card
            titleVariant="small"
            density="compact"
            title={t('account.actions.contactUs.title', 'Contact us')}
            icon={icon}
            actionIcon={null}
            {...cardProps}
            onClick={() => handleClick()}
          />
        );
      }}
    />
  );
};
