import { Address } from '@getpopsure/public-models';
import {
  capitalizeName,
  englishFormattedEuroCurrency,
} from '@getpopsure/public-utility';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import {
  getCoverageTableData,
  getCoverageTableHeaders,
} from 'features/household/components/HouseholdCoverageDetails/coverageTableData';
import { getPoliciesListAction } from 'features/policiesDashboard/utils/getPoliciesListAction';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';
import { Policy } from 'models/policies';
import { generatePath } from 'react-router';

import { CancelPolicyButton, ContactUsButton } from '../../components/buttons';
import type { PolicySingleModal } from '../../components/modals';
import {
  CancelPolicyModal,
  DelinquencyBlockerCancelPolicyModal,
  DelinquencyBlockerCreateClaimModal,
} from '../../components/modals';
import { DATE_FORMAT } from '../../constants';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from '../../PolicySingle.models';
import { PolicyDetailsInfo } from '../../sections/PolicyDetails/PolicyDetails.models';
import { getPolicyDocuments } from '../../sections/PolicyDocuments/utils/getPolicyDocuments';

/**
 * Old household attributes do not have the personalInfo and householdInfo properties
 * so, we have to check if the object has the legacy format
 */
interface HouseholdLegacyAttributes {
  address: Address;
  flatSize: number;
  postcode: string;
}

type HouseholdAttributesWithLegacy =
  | Policy['attributes']['householdAttributes']
  | HouseholdLegacyAttributes;

const isHouseholdLegacyAttributesObject = (
  attributes: HouseholdAttributesWithLegacy
): attributes is HouseholdLegacyAttributes => {
  return (attributes as HouseholdLegacyAttributes)?.address !== undefined;
};

const getSubtitle = (householdAttributes: HouseholdAttributesWithLegacy) => {
  if (isHouseholdLegacyAttributesObject(householdAttributes)) {
    const { address } = householdAttributes;
    return `${address.street} ${address.houseNumber}`;
  }

  return householdAttributes?.personalInfo?.address
    ? `${householdAttributes?.personalInfo?.address.street} ${householdAttributes?.personalInfo?.address.houseNumber}`
    : undefined;
};

const getAddress = (householdAttributes: HouseholdAttributesWithLegacy) => {
  if (isHouseholdLegacyAttributesObject(householdAttributes)) {
    return householdAttributes?.address;
  }

  return householdAttributes?.personalInfo?.address;
};

export const getHouseholdConfig = (args: GetPolicyConfigArgs): PolicyConfig => {
  const [policy, t] = args;
  const {
    price,
    billingType,
    policyNumber,
    insuredPerson,
    startDate,
    householdAttributes,
    insuredSum,
  } = policy.attributes;

  const action = getPoliciesListAction(policy);
  const policyStatus =
    action && action !== 'DETAIL'
      ? 'ACTION_NEEDED'
      : getPolicyStatus(policy, true);
  const title = getTitleMapping(t).HOUSEHOLD;

  const header: PolicyConfig['header'] = {
    title,
    subtitle: getSubtitle(householdAttributes),
    backgroundColor: bannerColorMapping.HOUSEHOLD,
    insuranceType: policy.type,
    policyStatus,
    startDate: policy.attributes.startDate,
  };

  if (price) {
    header.price = {
      amount: parseFloat(price),
      billingType: billingType as BillingType,
    };
  }

  let addressRows: PolicyDetailsInfo[] = [];

  const address = getAddress(householdAttributes);

  if (address) {
    const { street, houseNumber, postcode, city } = address;
    addressRows = [
      {
        title: t('myPolicies.policyDetails.household.address', 'Address'),
        value: `${street} ${houseNumber}`,
      },
      {
        title: '',
        value: `${postcode} ${city}`,
      },
    ];
  }

  const bikeRows: PolicyDetailsInfo[] = [];

  if (householdAttributes?.personalInfo?.bikeInsuredSum) {
    bikeRows.push({
      title: t(
        'myPolicies.policyDetails.household.bikeInsuredSum',
        'Bike insured sum'
      ),
      value: englishFormattedEuroCurrency(
        householdAttributes?.personalInfo.bikeInsuredSum,
        true
      ),
    });
  }

  const addonsRows: PolicyDetailsInfo[] = [];

  if (
    householdAttributes?.personalInfo?.addOns &&
    householdAttributes?.personalInfo?.addOns.length > 0
  ) {
    const addonStrings = householdAttributes.personalInfo.addOns.flatMap(
      (addon) =>
        addon !== 'EXTENDED_COVERAGE'
          ? [
              addon === 'ADDITIONAL_BIKE_COVERAGE'
                ? t(
                    'myPolicies.policyDetails.household.addon.additionalBikeCoverage',
                    'Additional bike coverage'
                  )
                : t(
                    'myPolicies.policyDetails.household.addon.brokenGlassCoverage',
                    'Broken glass coverage'
                  ),
            ]
          : []
    );

    addonsRows.push({
      title: t('myPolicies.policyDetails.household.addons', 'Add-ons'),
      value: addonStrings[0],
    });

    if (addonStrings[1]) {
      addonsRows.push({
        title: '',
        value: addonStrings[1],
      });
    }
  }

  const sections: PolicySingleSection[] = [
    {
      id: 'POLICY_DETAILS',
      props: {
        policyType: policy.type,
        info: [
          {
            title: t('myPolicies.policyDetails.policyNumber', 'Policy number'),
            value: policyNumber,
          },
          {
            title: t(
              'myPolicies.policyDetails.insuredPerson',
              'Insured person'
            ),
            value: insuredPerson?.name && capitalizeName(insuredPerson.name),
          },
          {
            title: t('myPolicies.policyDetails.startDate', 'Start date'),
            value: startDate && dayjs(startDate).format(DATE_FORMAT),
          },
          ...addressRows,
          {
            title: t('myPolicies.policyDetails.household.plan.title', 'Plan'),
            value: householdAttributes?.personalInfo?.addOns.includes(
              'EXTENDED_COVERAGE'
            )
              ? t(
                  'myPolicies.policyDetails.household.plan.extended',
                  'Extended coverage'
                )
              : t(
                  'myPolicies.policyDetails.household.plan.basic',
                  'Basic coverage'
                ),
          },
          ...addonsRows,
          {
            title: t('myPolicies.policyDetails.coverage', 'Insured sum'),
            value: insuredSum && englishFormattedEuroCurrency(insuredSum, true),
          },
          ...bikeRows,
          {
            title: t(
              'myPolicies.policyDetails.household.homeSecurityDevice',
              'Home security device'
            ),
            value: householdAttributes?.householdInfo?.hasSecurityDevice
              ? 'Yes'
              : 'No',
          },
        ],
        buttons: [
          {
            id: 'CONTACT_US',
            component: ContactUsButton,
          },
          ...(policy.status !== 'CANCELED'
            ? [
                {
                  id: 'CANCEL_POLICY',
                  component: CancelPolicyButton,
                },
              ]
            : []),
        ],
      },
    },
    {
      id: 'POLICY_COVERAGE',
      props: {
        entries:
          policy.attributes?.planId === 'ADVANCED'
            ? [
                {
                  id: 'advanced-burglary',
                  title: t(
                    'myPolicies.policyDetails.household.covered.advancedBurglary.title',
                    'Burglary'
                  ),
                  covered: true,
                },
                {
                  id: 'advanced-fire-damage',
                  title: t(
                    'myPolicies.policyDetails.household.covered.advancedFireDamage.title',
                    'Fire damage'
                  ),
                  covered: true,
                },
                {
                  id: 'advanced-water-damage',
                  title: t(
                    'myPolicies.policyDetails.household.covered.advancedWaterDamage.title',
                    'Water damage (due to pipes or containers)'
                  ),
                  covered: true,
                },
                {
                  id: 'advanced-bike-theft',
                  title: t(
                    'myPolicies.policyDetails.household.covered.advancedBikeTheft.title',
                    'Bike theft'
                  ),
                  covered: true,
                },
                {
                  id: 'advanced-building-fixtures',
                  title: t(
                    'myPolicies.policyDetails.household.covered.advancedBuildingFixtures.title',
                    'Building fixtures (e.g. sinks, doors)'
                  ),
                  covered: false,
                },
                {
                  id: 'advanced-blown-fuse',
                  title: t(
                    'myPolicies.policyDetails.household.covered.advancedBlownFuse.title',
                    'Blown fuse'
                  ),
                  covered: false,
                },
                {
                  id: 'advanced-pipes',
                  title: t(
                    'myPolicies.policyDetails.household.covered.advancedPipes.title',
                    'Pipes under the floor'
                  ),
                  covered: false,
                },
              ]
            : [
                {
                  id: 'basic-burglary',
                  title: t(
                    'myPolicies.policyDetails.household.covered.basicBurglary.title',
                    'Burglary'
                  ),
                  covered: true,
                },
                {
                  id: 'basic-fire-damage',
                  title: t(
                    'myPolicies.policyDetails.household.covered.basicFireDamage.title',
                    'Fire damage'
                  ),
                  covered: true,
                },
                {
                  id: 'basic-water-damage',
                  title: t(
                    'myPolicies.policyDetails.household.covered.basicWaterDamage.title',
                    'Water damage (due to pipes or containers)'
                  ),
                  covered: true,
                },
                {
                  id: 'basic-building-fixtures',
                  title: t(
                    'myPolicies.policyDetails.household.covered.basicBuildingFixtures.title',
                    'Building fixtures (e.g. sinks, doors)'
                  ),
                  covered: false,
                },
                {
                  id: 'basic-blown-fuse',
                  title: t(
                    'myPolicies.policyDetails.household.covered.basicBlownFuse.title',
                    'Blown fuse'
                  ),
                  covered: false,
                },
                {
                  id: 'basic-pipes',
                  title: t(
                    'myPolicies.policyDetails.household.covered.basicPipes.title',
                    'Pipes under the floor'
                  ),
                  covered: false,
                },
              ],
        modal: {
          coverageTableData: getCoverageTableData(t),
          coverageTableHeaders: getCoverageTableHeaders(t),
        },
      },
    },
    {
      id: 'POLICY_CLAIMS',
      props: {
        claims: policy.claims,
        route: generatePath(routes.claims.household.path, {
          policyId: policy.id,
        }),
        insuranceType: 'HOUSEHOLD',
        activeUntil:
          policy.status === 'ACTIVE'
            ? undefined
            : policy.attributes.activeUntil,
        policyStatus: policy.status,
      },
    },
    {
      id: 'POLICY_DOCUMENTS',
      props: {
        documents: getPolicyDocuments(policy),
      },
    },
  ];

  const modals: PolicySingleModal[] = [
    {
      id: 'CANCEL_POLICY',
      component: CancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CREATE_CLAIM',
      component: DelinquencyBlockerCreateClaimModal,
    },
  ];

  return { header, modals, sections };
};
