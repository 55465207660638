import { question } from 'icons';

import styles from './style.module.css';

const ToolTip = ({
  icon = question,
  message,
  className,
}: {
  icon?: string;
  message: string;
  className?: string;
}) => (
  <>
    <img
      src={icon}
      alt="tooltip"
      className={`${styles.tooltip} ${className}`}
      data-tip={message}
    />
  </>
);

export default ToolTip;
