import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import endpoint from 'shared/api';

import { AuthProvider } from '../../googleOAuth.models';

export const getAuthProviders = async (): Promise<AuthProvider[]> => {
  const { data } = await endpoint.getAuthProviders();
  return data;
};

export const useGetAuthProviders = () => {
  const {
    isLoading,
    data,
    error: errorField,
  } = useQuery<AuthProvider[], AxiosError>({
    queryKey: ['authProviders'],
    queryFn: getAuthProviders,
    enabled: true,
    refetchInterval: 5000,
  });

  return {
    error: errorField,
    isLoading,
    authProviders: data ?? [],
  };
};
