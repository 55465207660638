import { PartialQuestionnaire } from 'models/questionnaireFramework';

import { createQuestionOrder } from '../createQuestionOrder';

// eslint-disable-next-line @typescript-eslint/ban-types
export const getNextQuestionId = <Questionnaire extends Object, GroupId>(
  currentQuestionId: keyof Questionnaire,
  questions: PartialQuestionnaire<Questionnaire, GroupId>,
  answers: Partial<Questionnaire>
): keyof Questionnaire | null => {
  const questionOrder = createQuestionOrder(questions);
  const currentQuestionObject = questionOrder.find(
    ({ id }) => id === currentQuestionId
  );

  const questionIdx = currentQuestionObject
    ? questionOrder.indexOf(currentQuestionObject)
    : 0;

  const lastUnansweredQuestion = questionOrder.find(
    ({ id: _id, required }, idx) => {
      const isCurrentPageBehind = currentQuestionObject
        ? questionIdx < idx
        : false;

      const isRequired =
        typeof required === 'function' ? required(answers) : required;

      return isCurrentPageBehind && isRequired;
    }
  );

  return lastUnansweredQuestion?.id ?? null;
};
