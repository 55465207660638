import { StripeError } from '@stripe/stripe-js';
import {
  CollectableBillingAddress,
  StripeBillingAddress,
  ZStripeBillingAddress,
} from 'models/paymentMethods';

const isValidStripeBillingAddress = (
  value: Partial<StripeBillingAddress>
): value is StripeBillingAddress => {
  return ZStripeBillingAddress.safeParse(value).success;
};

export const toStripeBillingAddress = (
  collectableAddress: Partial<CollectableBillingAddress>
): StripeBillingAddress | undefined => {
  const stripeBillingAddress: Partial<StripeBillingAddress> = {
    ...collectableAddress,
    country: collectableAddress.country?.code,
  };

  if (isValidStripeBillingAddress(stripeBillingAddress)) {
    return stripeBillingAddress;
  }

  return undefined;
};

export const isStripeError = (error: unknown): error is StripeError => {
  return (
    error !== null &&
    typeof error === 'object' &&
    ['type', 'charge', 'payment_intent', 'payment_method', 'setup_intent'].some(
      (property: string) =>
        Object.prototype.hasOwnProperty.call(error, property)
    )
  );
};
