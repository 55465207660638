import {
  Address,
  BreedsSpain,
  Country,
  dangerousBreedsSpain,
} from '@getpopsure/public-models';
import { CheckoutInfo } from 'features/checkout/models';
import { Name } from 'models/user';
import { TFunction } from 'shared/i18n/utils';
import { ActionResponse } from 'shared/models/types';
import {
  ZAddressSchema,
  ZCountrySchema,
  ZNameSchema,
} from 'shared/models/validations';
import { BLOCKER, SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

export const isDangerous = (breed: string) => {
  return dangerousBreedsSpain.some(
    (dangerousBreed) =>
      dangerousBreed.toLocaleLowerCase() === breed.toLocaleLowerCase()
  );
};

export const isDangerousBreed = ({
  breed,
  mixedBreed,
}: Partial<DogLiabilitySpain>) => {
  if (breed) {
    return isDangerous(breed);
  }
  if (mixedBreed) {
    return mixedBreed.some(isDangerous);
  }
  return false;
};

export const IdentificationDocuments = ['DNI', 'NIE', 'PASSPORT'] as const;
export type IdentificationDocument = typeof IdentificationDocuments[number];

export const identificationDocumentMapping = (
  t: TFunction
): Record<IdentificationDocument, string> => ({
  DNI: t(
    'dogLiabilitySpain.qnr.identificationDocument.dni',
    'Spanish national ID (DNI)'
  ),
  NIE: t(
    'dogLiabilitySpain.qnr.identificationDocument.nie',
    'Foreigner identity number (NIE)'
  ),
  PASSPORT: t(
    'dogLiabilitySpain.qnr.identificationDocument.passport',
    'Passport'
  ),
});

export const identificationNumberMapping = (
  t: TFunction
): Record<
  IdentificationDocument,
  {
    title: string;
    placeholder: string;
  }
> => ({
  DNI: {
    title: t(
      'dogLiabilitySpain.qnr.signup.identificationNumber.dni.title',
      "What's your Spanish national ID number (DNI)?"
    ),
    placeholder: t(
      'dogLiabilitySpain.qnr.signup.identificationNumber.dni.placeholder',
      'National ID number'
    ),
  },
  NIE: {
    title: t(
      'dogLiabilitySpain.qnr.signup.identificationNumber.nie.title',
      "What's your foreigner identity number (NIE)?"
    ),
    placeholder: t(
      'dogLiabilitySpain.qnr.signup.identificationNumber.nie.placeholder',
      'Foreigner identity number'
    ),
  },
  PASSPORT: {
    title: t(
      'dogLiabilitySpain.qnr.signup.identificationNumber.passport.title',
      "What's your passport number?"
    ),
    placeholder: t(
      'dogLiabilitySpain.qnr.signup.identification.passport.placeholder',
      'Passport number'
    ),
  },
});

export const Genders = ['MALE', 'FEMALE'] as const;
export type Gender = typeof Genders[number];

export const genderMapping = (t: TFunction): Record<Gender, string> => ({
  MALE: t('dogLiabilitySpain.qnr.gender.optionMale', 'Male'),
  FEMALE: t('dogLiabilitySpain.qnr.gender.optionFemale', 'Female'),
});

const PLANS = ['BASIC'] as const;

export type Plan = typeof PLANS[number];

// TODO: define the quote type
export const ZQuote = z.object({
  id: z.string(),
  plan: z.enum(PLANS),
  coveredArea: z.string(),
  isDangerousBreed: z.boolean(),
});

export type Quote = z.TypeOf<typeof ZQuote>;

// TODO: define the checkout policy details
export type CheckoutPolicyDetails = {
  dogName: string;
  coveredArea: string;
  microchipNumber: string;
};

export type CreateQuoteReturnType = {
  status: ActionResponse;
};

export type DogLiabilitySpain = {
  intro: SCREEN;
  isMixedBreed: 'YES' | 'NO';
  breed?: BreedsSpain;
  mixedBreed?: BreedsSpain[];
  postalCode: number;
  isDangerousBreed: boolean;
  quote: Quote;
  signupIntro: SCREEN;

  dogName: string;
  microchipNumber: string;
  name: Name;
  email: string;
  dateOfBirth: string;
  under18Blocker: BLOCKER;
  gender: Gender;
  address: Address;

  identificationDocument: IdentificationDocument;
  identificationNumber: string;
  citizenship?: Country[];

  startDate: string;

  review: boolean;
  downloadDocuments: boolean;

  checkoutInfo: CheckoutInfo<CheckoutPolicyDetails>;
  reviewCheckout: boolean;
  checkout: boolean;

  quoteId: string;
  questionnaireId: string;
};

export const ZSubmittableDogLiabilitySpainQuestionnaire = z
  .object({
    isMixedBreed: z.enum(['YES', 'NO']),
    breed: z.optional(z.string()),
    mixedBreed: z.optional(z.array(z.string())),
    dogName: z.string(),
    microchipNumber: z.string(),
    email: z.string(),
    name: ZNameSchema,
    dateOfBirth: z.string(),
    gender: z.enum(Genders),
    address: ZAddressSchema,
    identificationDocument: z.enum(IdentificationDocuments),
    identificationNumber: z.string(),
    citizenship: z.optional(z.array(ZCountrySchema)),
    startDate: z.string(),
  })
  .transform(
    ({
      isMixedBreed,
      breed,
      mixedBreed,
      dogName,
      microchipNumber,
      email,
      name,
      dateOfBirth,
      gender,
      address,
      identificationDocument,
      identificationNumber,
      citizenship,
      startDate,
    }) => {
      const breeds = breed ? [breed] : mixedBreed ?? [];
      const dangerousBreed = breeds.some(isDangerous);
      return {
        personalInfo: {
          isMixedBreed: isMixedBreed === 'YES',
          breeds,
          dogName,
          email,
          name,
          address,
          gender,
          dateOfBirth,
          identificationDocument,
          identificationNumber,
          citizenship,
        },
        tariffInfo: {
          startDate,
          microchipNumber,
          isDangerousBreed: dangerousBreed,
        },
      };
    }
  );
export type SubmittableDogLiabilitySpainQuestionnaire = z.infer<
  typeof ZSubmittableDogLiabilitySpainQuestionnaire
>;

export type DogLiabilitySpainGroupIds = 'preQuote' | 'signup';
