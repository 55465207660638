import { BottomOrRegularModal, Button } from '@popsure/dirty-swan';
import { Checklist } from 'features/studentChecklist/components';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useSafeTranslation } from 'shared/i18n';

import { Step } from './models';

interface StudentChecklistViewProps {
  steps: Step[];
  title: string;
  completeChecklist?: () => void;
}

export const StudentChecklistView = ({
  steps,
  title,
  completeChecklist,
}: StudentChecklistViewProps) => {
  const { t } = useSafeTranslation();
  const [isChecklistCompleteModalOpen, setChecklistCompleteModalOpen] =
    useState(false);

  return (
    <>
      <Checklist
        steps={steps}
        title={title}
        {...(completeChecklist && {
          completeChecklist: () => setChecklistCompleteModalOpen(true),
        })}
      />

      {completeChecklist &&
        createPortal(
          <BottomOrRegularModal
            dismissible={true}
            onClose={() => setChecklistCompleteModalOpen(false)}
            isOpen={isChecklistCompleteModalOpen}
            title={t(
              'studentChecklist.title.markAsComplete.modal.title',
              'Already sent everything to the provider?'
            )}
          >
            <div className="p24">
              <p className="p-p mb24">
                {t(
                  'studentChecklist.title.markAsComplete.modal.description',
                  'If you already sent everything to the provider directly, you can mark this checklist as completed.'
                )}
              </p>
              <Button onClick={completeChecklist}>
                {t(
                  'studentChecklist.title.markAsComplete.modal.button',
                  'Mark as completed'
                )}
              </Button>
            </div>
          </BottomOrRegularModal>,
          document.body
        )}
    </>
  );
};
