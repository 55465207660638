import { EditBeneficiaries } from 'features/lifeBeneficiaries/models';
import { Reducer } from 'redux';
import { UploadedFile } from 'shared/models/types';

import { LifeBeneficiariesActions } from './lifeBeneficiaries.actions';

interface LifeBeneficiariesQuestionnaireState {
  questionnaire?: Partial<EditBeneficiaries>;
  singlePolicyHolderId?: UploadedFile[];
}

export const defaultState: LifeBeneficiariesQuestionnaireState = {};

const lifeBeneficiaries: Reducer<
  LifeBeneficiariesQuestionnaireState,
  LifeBeneficiariesActions
> = (state = defaultState, action) => {
  switch (action.type) {
    case 'FLUSH_LIFE_BENEFICIARIES':
      return {
        ...state,
        questionnaire: undefined,
      };
    case 'STORE_LIFE_BENEFICIARIES':
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          ...action.questionnaire,
        },
        singlePolicyHolderId: state.questionnaire?.policyHolderIdUpload,
      };
    default:
      return state;
  }
};

export default lifeBeneficiaries;
