import dayjs from 'dayjs';
import { PolicyAttributes } from 'models/policies';
import { TFunction } from 'shared/i18n';

import { PolicyStatusOrNull } from '../utils/getPolicyStatus';

export type BillingType = 'MONTH' | 'YEAR';

export type FormattedPolicyStatus = null | {
  text: string;
  info?: string;
  variant: 'neutral200' | 'information' | 'success' | 'primary900';
};

const getActiveUntilMessage = (activeUntil: string, t: TFunction) => {
  const isActiveUntilInTheFuture = dayjs().isBefore(dayjs(activeUntil));

  if (isActiveUntilInTheFuture) {
    return t(
      'policies.dashboard.cardStatus.activeUntil',
      'Ends on {{endDate}}',
      {
        endDate: dayjs(activeUntil).format('MMM DD'),
      }
    );
  }

  return t(
    'policies.dashboard.cardStatus.activeUntilPast',
    'Ended on {{endDate}}',
    {
      endDate: dayjs(activeUntil).format('MMM DD'),
    }
  );
};

export const mapPolicyStatus = (
  status: PolicyStatusOrNull,
  {
    activeUntil,
    providerName,
    startDate,
  }: Pick<PolicyAttributes, 'activeUntil' | 'startDate'> & {
    providerName?: string;
  },
  t: TFunction
): FormattedPolicyStatus => {
  switch (status) {
    case 'ACTION_NEEDED':
      return {
        text: t('policies.dashboard.cardStatus.actionNeeded', 'Action needed'),
        variant: 'primary900',
      };
    case 'MANAGED_BY_PROVIDER':
      return {
        text: t(
          'policies.dashboard.cardStatus.managedByProvider',
          'Managed by {{providerName}}',
          { providerName: providerName || 'provider' }
        ),
        info: t(
          'policies.dashboard.cardStatus.managedByProviderInfo',
          '{{providerName}} manages your policy. Contact them to check the latest status.',
          { providerName: providerName || 'provider' }
        ),
        variant: 'neutral200',
      };
    case 'ACTIVE':
      return {
        text: t('policies.dashboard.cardStatus.active', 'Active'),
        variant: 'success',
      };
    case 'PROCESSING':
      return {
        text: t('policies.dashboard.cardStatus.processing', 'Processing'),
        variant: 'information',
      };
    case 'PENDING':
      return startDate
        ? {
            text: t(
              'policies.dashboard.cardStatus.pendingStartDate',
              'Starts on {{startDate}}',
              { startDate: dayjs(startDate).format('MMM DD') }
            ),
            variant: 'neutral200',
          }
        : {
            text: t('policies.dashboard.cardStatus.processing', 'Processing'),
            variant: 'neutral200',
          };
    case 'CANCELED':
      return activeUntil
        ? {
            text: getActiveUntilMessage(activeUntil, t),
            variant: 'neutral200',
          }
        : {
            text: t('policies.dashboard.cardStatus.canceled', 'Canceled'),
            variant: 'neutral200',
          };
    case 'WITHDRAWN':
      return {
        text: t('policies.dashboard.cardStatus.withdrawn', 'Withdrawn'),
        variant: 'neutral200',
      };
    case 'NOT_ELIGIBLE':
      return {
        text: t('policies.dashboard.cardStatus.notEligible', 'Not eligible'),
        variant: 'neutral200',
      };
    default:
      return null;
  }
};
