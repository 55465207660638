import { DependentsTypes } from 'features/recommendationTool/models/shared';
import { submitQuestionId } from 'features/recommendationTool/questionnaires/processing';
import { dependentsMapping } from 'features/recommendationTool/questionnaires/shared';
import { HealthQuestionnaire } from 'models/recommendationTool';

export const dependentsInfo: HealthQuestionnaire = {
  dependents: {
    required: (answers) => !answers.dependents,
    type: 'CHECKBOX',
    props: {
      question: 'Do you have any financial responsibilities?',
      mapValue: dependentsMapping,
      optionClassName: 'w30',
      inlineLayout: true,
    },
    retrieveAnswerObject: (dependents: DependentsTypes[]) => ({
      dependents: dependents.includes('NONE') ? [] : dependents,
    }),
    groupId: 'dependentsInfo',
    retrieveNextPageId: () => submitQuestionId,
  },
};
