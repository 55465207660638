import { TFunction } from '@getpopsure/i18n-react';
import {
  PrivatePreSignup,
  PrivatePreSignupQuestionnaire,
} from 'features/privateHealthPreSignup/models';

import { preQuoteQNR } from './preQuote';
import { quoteAndAddonsQNR } from './quoteAndAddons';

export const getTranslatedQuestionnaire = (
  t: TFunction,
  answers: Partial<PrivatePreSignup>
): PrivatePreSignupQuestionnaire => ({
  ...preQuoteQNR(t, answers),
  ...quoteAndAddonsQNR(),
});
