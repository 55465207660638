import { updateBikeClaimsPurchaseReceipts } from 'features/bikeClaims/actions';
import { BikeClaims, BikeClaimsDispatch } from 'features/bikeClaims/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useUploadFiles } from 'hooks/useUploadFiles';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { UploadedFile } from 'shared/models/types';

import { BikeClaimsPurchaseReceiptsView } from './PurchaseReceipts.view';

export const BikeClaimsPurchaseReceipts = ({
  value,
  onSubmitValue,
  routes,
  metaData: { questionnaireId, policyId },
}: CustomComponentProps<BikeClaims>) => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch<BikeClaimsDispatch>();

  const { loading: updateQuestionnaireLoading, error } = useRequestStatus(
    'UPDATE_QUESTIONNAIRE'
  );

  const {
    uploadFile,
    uploadedFiles,
    loading: uploadLoading,
    removeFile: onRemoveFile,
    isSubmitValid,
  } = useUploadFiles({
    initialValue: (value as UploadedFile[]) ?? [],
    isQuestionnaire: true,
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const tokens = uploadedFiles.flatMap(({ token }) =>
      token !== undefined ? [token] : []
    );

    if (tokens.length < 1) {
      throw new Error(
        `[Bike Claims] Purchase receipt token(s) is missing for submission for policy id: ${policyId}`
      );
    }

    const responseStatus = await dispatch(
      updateBikeClaimsPurchaseReceipts({
        questionnaireId,
        tokens,
      })
    );

    if (responseStatus === 'SUCCESS') {
      onSubmitValue(uploadedFiles);
    }
  };

  return (
    <BikeClaimsPurchaseReceiptsView
      handleSubmit={handleSubmit}
      t={t}
      validForSubmission={isSubmitValid}
      blockerRoute={routes.blockerPurchaseReceipts.path}
      uploadedFiles={uploadedFiles}
      uploadLoading={uploadLoading}
      submissionLoading={updateQuestionnaireLoading}
      onRemoveFile={onRemoveFile}
      onFileSelect={uploadFile}
      error={error?.message}
    />
  );
};
