import { RouteWithAuthentication } from 'components/RouteWithAuthentication';
import { Route } from 'react-router-dom';

interface Props {
  requiresAuth: boolean;
  children: JSX.Element;
  path: string;
}

const QuestionRoute = ({ path, requiresAuth, children }: Props) => {
  if (requiresAuth) {
    return (
      <RouteWithAuthentication path={path} component={children} exact={false} />
    );
  }

  return <Route path={path}>{children}</Route>;
};

export default QuestionRoute;
