import svgCheckSplash from './check-splash.svg';
import svgCheckWhite from './check-white.svg';
import svgContract from './contract.svg';
import svgFeatherIcon from './feather-icon.svg';
import svgFeatherIconCircle from './feather-icon-circle.svg';
import svgInfo from './info.svg';
import svgQuestion from './question.svg';

export { default as featherIconSilhouette } from './feather-icon-silhouette.svg';

export const question = svgQuestion;
export const info = svgInfo;
export const featherIcon = svgFeatherIcon;
export const featherIconCircle = svgFeatherIconCircle;
export const checkSplash = svgCheckSplash;
export const checkWhite = svgCheckWhite;
export const contract = svgContract;
