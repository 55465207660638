import { insurance } from '@getpopsure/private-constants';
import routes from 'constants/routes';
import {
  bannerColorMapping,
  imageSrcSetTypeMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';

import { CardText, CategoryLookup, Section } from '../models/lookups';
import { typeformLinkWithVertical } from './shared';

export const lifeCategory: CategoryLookup = {
  shortTitle: 'Life',
  title: 'Life insurance',
  excerpt: 'Support for your loved ones in the event of your passing',
  description:
    'Life insurance is a policy that pays out a fixed sum (of your choosing) if you pass away. Coverage is generally used by dependents and loved ones to pay for the cost of living until they regain financial stability, and includes a spouse, child, or even a business partner.',
  icon: imageTypeMapping.LIFE,
  iconSrcSet: imageSrcSetTypeMapping.LIFE,
  bannerColor: bannerColorMapping.LIFE,
  landingPage: insurance.life.landingPage,
  quotePage: routes.policies.life.path,
  enabled: true,
};

export const lifeSection: Section = {
  title: '',
  description:
    'Our recommendation takes several factors into account: your personal risk, the number of beneficiaries, and any potential changes that might happen in the future.',
  icon: '',
  status: {
    ELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.life.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('life'),
      },
    },
    PARTIAL: {
      cta: {
        caption: 'Learn more',
        href: insurance.life.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('life'),
      },
    },
    MORE_INFO_REQUIRED: {
      cta: {
        caption: 'Learn more',
        href: insurance.life.landingPage,
      },
    },
    INELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.life.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('life'),
      },
    },
  },
};

export const lifeCardText: CardText = {
  LIFE_DEPENDENTS_SUPPORT_PRO: {
    title: 'Protection because your needs outweigh your assets',
    description:
      'Your savings and other assets would not be enough to provide financial stability to those you wish to support in the event of your passing.',
  },
  LIFE_SECURE_LOWER_PRICE_PRO: {
    title: 'Secure a lower price now and add your loved ones later on',
    description:
      'Responsibilities change, and you may want to add others to your plan in the future; the longer you wait, the more you risk becoming ineligible later on.',
  },
  LIFE_AGE_LOWER_PRICE_PRO: {
    title: 'The price is lower when you’re younger',
    description:
      "Because the cost of life insurance increases with age, it's generally better to sign up sooner rather than later. Purchasing life insurance now will lock in lower monthly payments.",
  },
  LIFE_MORTGAGE_REQUIREMENTS_PRO: {
    title: 'Fulfills the requirement for mortgages',
    description:
      'Financial institutions that offer mortgages like banks and credit unions often require life insurance to cover your mortgage in the event of your passing. With a life insurance plan, you can adjust your coverage amount as you pay off your mortgage.',
  },
  LIFE_DEBT_COVER_PRO: {
    title: 'Coverage for any debt',
    description:
      'Life insurance can make sure that neither your relatives nor future dependents have to take on your debt. This debt can actually live on after you die. And, even if no one is relying on you financially, life insurance can make sure that no one else has to carry the burden.',
  },
  LIFE_ASSETS_ALREADY_MEET_NEEDS_CON: {
    title: 'Your assets already meet your needs',
    description:
      'Your financial safety net is enough to provide financial stability to those you wish to support in the event of your passing. Life insurance can still be useful if you’d like to provide additional financial security or if your savings diminish in the upcoming years.',
  },
  LIFE_RESPONSIBILITIES_DONT_REQUIRE_CON: {
    title: "Your responsibilities don't require life insurance",
    description:
      "Given that there's no one you'd like to support financially, life insurance might not be necessary. Keep in mind that the price increases as you get older and eligibility is not guaranteed, so if you plan on supporting someone in the future it is a good idea to get insured earlier.",
  },
  LIFE_UNDER_18_PARTIAL: {
    title: 'You are not eligible',
    description:
      'You need to be at least 18 years old to purchase life insurance with us. You can get a parent or legal guardian to sign you up in the meantime.',
  },
  LIFE_OVER_65_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      "If you're over 65 years old, you aren't able to purchase life insurance with us at this time.",
  },
};
