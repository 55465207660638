import { TFunction } from '@getpopsure/i18n-react';
import { getTrackingObject } from '@getpopsure/tracker';
import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { AxiosInstance } from 'axios';
import { RequestAction } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkAction } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint from 'shared/api';
import { CreateQuoteDispatch } from 'SignupQuestionnaire/actions/account';

import {
  CoverageInfo,
  CreateQuoteReturnType,
  FlaggedAnswers,
  getFlaggedAnswers,
  getMaxCoverage,
  getMaxTerm,
  Life,
  QuestionnaireLabels,
  questionnaireLabelsInfo,
  QuestionnaireType,
  ZIneligibleSubmittableLifeQuestionnaire,
  ZPreQuoteLifeQuestionnaire,
  ZSubmittableLifeQuestionnaire,
} from '../models';

const createLifeV2Questionnaire = (
  network: AxiosInstance,
  answers: Partial<Life> & { flags?: FlaggedAnswers },
  questionnaireType: QuestionnaireType,
  labels?: QuestionnaireLabels
) => {
  const source = getTrackingObject();
  return network.post<{ id: string }>('/questionnaires/', {
    answers,
    questionnaireType,
    metadata: { source, ...labels },
  });
};

export const createPreQuote =
  (): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'lifeV2'> | RequestAction
  > =>
  async (dispatch: CreateQuoteDispatch<'lifeV2'>, getState: () => AppState) => {
    const requestType = 'CREATE_QUOTE';
    const questionnaireAnswers =
      getGenericQuestionnaire(getState()).lifeV2 || {};

    if (questionnaireAnswers.quote?.questionnaireId) {
      return { status: 'SUCCESS' };
    }

    try {
      const data = {
        ...questionnaireAnswers,
        coverage: getMaxCoverage(questionnaireAnswers),
        duration: getMaxTerm(questionnaireAnswers),
      };

      const validationResult = ZPreQuoteLifeQuestionnaire.safeParse(data);

      if (!validationResult.success) {
        throw new Error(
          `[LIFE - PreQuote] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const submittableData = validationResult.data;

      const { data: questionnaire } = await createLifeV2Questionnaire(
        endpoint.network,
        submittableData,
        'LIFE_PRE_QUOTE'
      );

      const questionnaireId = questionnaire.id;

      const { data: quote } = await endpoint.createQuotes(
        'life',
        questionnaireId
      );

      dispatch(setRequestInProcess(false, requestType));

      return {
        status: 'SUCCESS',
        result: {
          quote: {
            ...quote,
            coverageInfo: {
              duration: submittableData.duration,
              coverage: submittableData.coverage,
            },
            questionnaireId,
          },
          questionnaireId,
          quoteId: quote.id,
        },
      };
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          'CREATE_POST_QUOTE_SUBMIT_INFO'
        )
      );
      Sentry.captureException(
        `[LIFE PreQuote] Failed to submit with following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };

export const submitScreenedOutQuestionnaire =
  (): ThunkAction<
    Promise<void>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'lifeV2'> | RequestAction
  > =>
  async (dispatch: CreateQuoteDispatch<'lifeV2'>, getState: () => AppState) => {
    const questionnaire = getGenericQuestionnaire(getState()).lifeV2 || {};
    const requestType: RequestType = 'SUBMIT_QUESTIONNAIRE';
    dispatch(setRequestInProcess(true, requestType));

    const data = {
      ...questionnaire,
      coverage: questionnaire.quote?.coverageInfo.coverage,
      duration: questionnaire.quote?.coverageInfo.duration,
    };

    const validationResult =
      ZIneligibleSubmittableLifeQuestionnaire.safeParse(data);

    if (!validationResult.success) {
      throw new Error(
        `[LIFE - Post Quote] Questionnaire answer validation errors: ${validationResult.error.toString()}`
      );
    }

    const validatedData = validationResult.data;

    const flags = getFlaggedAnswers(questionnaire);

    try {
      await createLifeV2Questionnaire(
        endpoint.network,
        {
          ...validatedData,
          flags,
        },
        'LIFE_SCREENED_OUT'
      );

      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

export const createPostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'lifeV2'> | RequestAction
  > =>
  async (dispatch: CreateQuoteDispatch<'lifeV2'>, getState: () => AppState) => {
    const questionnaire = getGenericQuestionnaire(getState()).lifeV2 || {};

    try {
      if (!questionnaire.quote) {
        throw new Error(`[LIFE] Quote not defined`);
      }

      const data = {
        ...questionnaire,
        coverage: questionnaire.quote.coverageInfo.coverage,
        duration: questionnaire.quote.coverageInfo.duration,
      };

      const validationResult = ZSubmittableLifeQuestionnaire.safeParse(data);

      if (!validationResult.success) {
        throw new Error(
          `[LIFE - Post Quote] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const validatedData = validationResult.data;

      const {
        email,
        name: { firstName, lastName },
        dateOfBirth,
      } = validatedData;

      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            dateOfBirth,
            email,
          })
        );
      }

      const { data: result } = await createLifeV2Questionnaire(
        endpoint.network,
        validatedData,
        'LIFE_POST_QUOTE',
        questionnaireLabelsInfo(validatedData)
      );

      const { data: quote } = await endpoint.createQuotes('life', result.id);

      await generateCheckoutDocuments(result.id, quote.id, 'LIFE_V2', t);

      dispatch(setRequestInProcess(false, 'CREATE_POST_QUOTE_SUBMIT_INFO'));

      return {
        status: 'SUCCESS',
        result: {
          quote: {
            ...quote,
            questionnaireId: result.id,
            coverageInfo: {
              duration: validatedData.duration,
              coverage: validatedData.coverage,
            },
          },
          questionnaireId: result.id,
          quoteId: quote.id,
        },
      };
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          'CREATE_POST_QUOTE_SUBMIT_INFO'
        )
      );
      Sentry.captureException(
        `[LIFE Post Quote] Failed to submit with following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };

export const reviewAction =
  (): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'lifeV2'> | RequestAction
  > =>
  async () => {
    return { status: 'SUCCESS' };
  };

export const createNewQuote =
  (
    questionnaireAnswers: Partial<Life> & CoverageInfo,
    questionnaireId: string
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'lifeV2'> | RequestAction
  > =>
  async (dispatch: CreateQuoteDispatch<'lifeV2'>) => {
    const requestType = 'CREATE_QUOTE';
    dispatch(setRequestInProcess(true, requestType));

    try {
      const validationResult =
        ZPreQuoteLifeQuestionnaire.safeParse(questionnaireAnswers);

      if (!validationResult.success) {
        throw new Error(
          `[LIFE - Create Quote] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const answers = validationResult.data;

      await endpoint.updateLifeQuestionnaire(answers, questionnaireId);
      const { data: quote } = await endpoint.createQuotes(
        'life',
        questionnaireId
      );

      dispatch(setRequestInProcess(false, requestType));

      return {
        status: 'SUCCESS',
        result: {
          quote: {
            ...quote,
            coverageInfo: {
              duration: questionnaireAnswers.duration,
              coverage: questionnaireAnswers.coverage,
            },
            questionnaireId,
          },
          questionnaireId,
          quoteId: quote.id,
        },
      };
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      Sentry.captureException(error, {
        tags: {
          feature: 'LIFE',
        },
        extra: {
          description: 'Failed to create new quote',
          questionnaireId,
        },
      });
      return { status: 'ERROR' };
    }
  };
