import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { SliderWithModal } from 'components/SliderWithModal';

import styles from './IncomeProtection.module.scss';

const SLIDER_STEP_SIZE = 150; // 30 * 5€
const MAXIMUM_DAILY_AMOUNT = 400;
const MINIUM_SLIDER_VALUE = 1_500;
const MAXIMUM_SLIDER_VALUE = MAXIMUM_DAILY_AMOUNT * 30;

const roundToNearestStep = (n: number) => n - (n % SLIDER_STEP_SIZE);

const getRecommendedValue = (income: number) => {
  return (
    Math.min(
      Math.floor((income * 0.7) / 12 / 30 / 5) * 5,
      MAXIMUM_DAILY_AMOUNT
    ) * 30
  );
};

export const IncomeProtection = ({
  income,
  sickDayPayout,
  handleSliderChange,
}: {
  income: number;
  sickDayPayout: number;
  handleSliderChange: (value: number) => void;
}) => {
  const monthlyIncome = income / 12;
  const recommendedValue = roundToNearestStep(getRecommendedValue(income));

  const minValue = MINIUM_SLIDER_VALUE;
  const maxValue = Math.min(
    roundToNearestStep(monthlyIncome * 0.8),
    MAXIMUM_SLIDER_VALUE
  );

  const onSliderChange = (value: number) => {
    handleSliderChange(value / 30);
  };

  return (
    <div className={`p32 mt24 w100 br8 ${styles.container}`}>
      <p className="p-h3">Income protection</p>
      <p className="p-p mt16 wmx9">
        The income protection kicks in if you're unable to work due to a
        sickness that lasts longer than 42 days. It is added by default on
        public insurance if you’re employed, and is especially important for
        freelancers since it can cover the cost of living if you get sick. You
        can cover up to 100% of your net income.
      </p>
      <div className="mt24 mb48 wmx9">
        <SliderWithModal
          label="Your payout:"
          sublabel={`(${englishFormattedEuroCurrency(
            sickDayPayout,
            true
          )}/day)`}
          value={sickDayPayout * 30}
          valueSuffix="/month"
          max={maxValue}
          min={minValue}
          step={SLIDER_STEP_SIZE}
          setValue={onSliderChange}
          recommendedValue={recommendedValue}
          options={{
            showRecommendationBubble: true,
            boldValue: true,
            boldLabels: false,
            noEdit: true,
          }}
        />
      </div>
    </div>
  );
};
