import { insurance } from '@getpopsure/private-constants';
import routes from 'constants/routes';
import {
  bannerColorMapping,
  imageSrcSetTypeMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';

import { CategoryLookup, Section } from '../models/lookups';

export const disabilityCategory: CategoryLookup = {
  shortTitle: 'Disability',
  title: 'Disability insurance',
  excerpt: 'Replaces lost income if you’re unable to work',
  description:
    'Disability insurance provides financial protection by replacing lost income in case of a disability that impacts your capacity to work. Examples are psychosomatic illnesses, accidents, cancer, and heart disease. Disability affects around 25% of people for a least 6 months and is paid out until the person is recovered and can work in their previous field or until retirement.',
  bannerColor: bannerColorMapping.DISABILITY,
  icon: imageTypeMapping.DISABILITY,
  iconSrcSet: imageSrcSetTypeMapping.DISABILITY,
  enabled: false,
  landingPage: insurance.disability.landingPage,
  quotePage: routes.policies.disability.path,
};

export const disabilitySection: Section = {
  title: '',
  description: 'disability description',
  icon: '',
};
