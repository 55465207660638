import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { Input } from '@popsure/dirty-swan';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import endpoint from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';

export const LegalPostcode = <Questionnaire extends QuestionnaireQuestions>({
  value,
  onSubmitValue,
  setValidForSubmission,
}: QuestionnaireFormProps<Questionnaire>) => {
  const [postcode, setPostcode] = useState<string>((value as string) ?? '');
  const [state, setState] = useState({
    type: 'INITIAL',
  });
  const { t } = useSafeTranslation();

  useEffect(() => {
    setValidForSubmission(postcode !== '');
  }, [setValidForSubmission, postcode]);

  const handleSubmit = async () => {
    setState({ type: 'LOADING' });
    const {
      data: { valid },
    } = await endpoint.validatePostcode('LEGAL', postcode);
    if (valid) {
      onSubmitValue(postcode);
    } else {
      setState({
        type: 'ERROR',
      });
    }
  };

  return (
    <Header onSubmit={handleSubmit}>
      <Input
        placeholder={t('legal.qnr.form.postcode.placeholder', 'Postcode')}
        type="text"
        required
        value={postcode}
        onChange={(e) => setPostcode(e.target.value)}
        data-cy="input-text"
      />
      <AnimateHeight
        duration={300}
        height={state.type === 'ERROR' ? 'auto' : 0}
      >
        <div className="p-notice p-notice--warning mt16 p-p">
          {t(
            'legal.qnr.form.postcode.error',
            'It seems like the postcode you’ve entered does not exist in Germany. Please try again.'
          )}
        </div>
      </AnimateHeight>
    </Header>
  );
};
