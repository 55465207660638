import {
  DependentsFamilyAddIcon,
  DependentsFamilyIcon,
} from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { useSafeTranslation } from 'shared/i18n';

export type CompanyLifeModalType = 'COMPANY_LIFE_ADD_BENEFICIARIES';

export const CompanyLifeAddBeneficiary = (props: PolicyDetailsButtonProps) => {
  const { onOpenModal, policyData } = props;
  const { t } = useSafeTranslation();

  const hasBeneficiaries = Boolean(
    policyData.attributes.policyData?.Beneficiaries
  );

  const title = hasBeneficiaries
    ? t(
        'myPolicies.policyDetails.companyLife.editBeneficiaries.caption',
        'Edit beneficiaries'
      )
    : t(
        'myPolicies.policyDetails.companyLife.addBeneficiaries.caption',
        'Add beneficiaries'
      );

  const icon = hasBeneficiaries ? (
    <DependentsFamilyIcon noMargin size={24} className="ml16" />
  ) : (
    <DependentsFamilyAddIcon noMargin size={24} className="ml16" />
  );

  const handleBeneficiariesButton = () =>
    onOpenModal('COMPANY_LIFE_ADD_BENEFICIARIES');

  return (
    <ButtonCell title={title} icon={icon} onClick={handleBeneficiariesButton} />
  );
};
