const INSURANCE_TYPES_ON_PYTHON_BACKEND = ['INCOMING', 'BIKE', 'LIFE'] as const;
export type PythonBackendInsuranceType =
  typeof INSURANCE_TYPES_ON_PYTHON_BACKEND[number];

const INSURANCE_TYPES_ON_SIGNUPS_BACKEND = [
  'LIABILITY',
  'HOUSEHOLD',
  'LEGAL',
  'EXPAT',
  'DOG_LIABILITY',
  'DENTAL_V2',
  'INCOMING_ES',
  'INCOMING_EU',
  'INCOMING_LT',
] as const;
export type SignupsBackendInsuranceType =
  typeof INSURANCE_TYPES_ON_SIGNUPS_BACKEND[number];

export const insuranceTypeToEndpointPrefix: Record<
  PythonBackendInsuranceType | SignupsBackendInsuranceType,
  string
> = {
  HOUSEHOLD: '/signups/household',
  LIABILITY: '/signups/liability',
  LEGAL: '/signups/legal',
  INCOMING: '/expat_health',
  BIKE: '/bike',
  DENTAL_V2: '/signups/dental',
  LIFE: '/life',
  EXPAT: '/signups/expat',
  DOG_LIABILITY: '/signups/dog_liability',
  INCOMING_ES: '/signups/expat-spain',
  INCOMING_EU: '/signups/expat-eu',
  INCOMING_LT: '/signups/expat-lt',
};

export interface CheckStatus {
  id: string;
  description: string | React.ReactNode;
}

export type DownloadStatus = 'INITIAL' | 'GENERATING' | 'COMPLETED' | 'FAILED';
