import { submitBrokerMandateSignature } from 'actions/user';
import BrokerMandate from 'features/brokerMandate/components';
import { connect } from 'react-redux';
import { AppState } from 'reducers';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  getRequestErrorMessage,
  getRequestInProcess,
} from 'selectors/requests';

interface StateProps {
  isLoading: boolean;
  error?: string;
}

interface DispatchProps {
  submitSignature: (signature: string) => void;
}

export type ViewProps = StateProps & DispatchProps;

const mapStateToProps = (state: AppState) => {
  const isLoading = getRequestInProcess(
    state,
    'SUBMIT_BROKER_MANDATE_SIGNATURE'
  );
  const error = getRequestErrorMessage(
    state,
    'SUBMIT_BROKER_MANDATE_SIGNATURE'
  );
  return { isLoading, error };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => ({
  submitSignature: (signature: string) =>
    dispatch(submitBrokerMandateSignature(signature)),
});

export default connect<StateProps, DispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(BrokerMandate);
