import { associatedPathForLiabilityRoutes } from 'constants/routes';
import { PageId } from 'models/liability';
import { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppState } from 'reducers';
import { getLastUnansweredQuestion } from 'selectors/liability';

export const withRedirect = <P extends object>(
  ChildComponent: ComponentType<P>,
  pageId: PageId
) => {
  const WrapperComponent = (props) => {
    const lastUnansweredQuestion = useSelector((state: AppState) =>
      getLastUnansweredQuestion(state, pageId)
    );

    if (lastUnansweredQuestion) {
      const redirectPath =
        associatedPathForLiabilityRoutes[lastUnansweredQuestion];

      return <Redirect to={redirectPath} />;
    }

    return <ChildComponent {...(props as P)} />;
  };

  return WrapperComponent;
};
