import bikeNewJson from './bikeNew.json';
import bikeUsedJson from './bikeUsed.json';
import eBikeNewJson from './eBikeNew.json';
import eBikeUsedJson from './eBikeUsed.json';
import zoneLookupJson from './postcode.json';

export const COMMISSION = 0.25;
export const TAX = 0.19;

type Price =
  | 1000
  | 1200
  | 1400
  | 1600
  | 1800
  | 2000
  | 2200
  | 2400
  | 2600
  | 2800
  | 3000
  | 3200
  | 3400
  | 3600
  | 3800
  | 4000
  | 4200
  | 4400
  | 4600
  | 4800
  | 5000
  | 5200
  | 5400
  | 5600
  | 5800
  | 6000
  | 6200
  | 6400
  | 6600
  | 6800
  | 7000
  | 7200
  | 7400
  | 7600
  | 7800
  | 8000
  | 8200
  | 8400
  | 8600
  | 8800
  | 9000
  | 9200
  | 9400
  | 9600
  | 9800
  | 10000
  | 10200
  | 10400
  | 10600
  | 10800
  | 11000
  | 11200
  | 11400
  | 11600
  | 11800
  | 12000;

type Zone = 0 | 1 | 2 | 3 | 4;
type ZoneIndex = 'zone1' | 'zone2' | 'zone3' | 'zone4';
type AgeIndex = 'age1' | 'age2' | 'age3';

export type PriceMapping = Record<Price, number>;
type AgeMapping = Record<AgeIndex, PriceMapping>;
type Quote = Record<ZoneIndex, AgeMapping>;

const newBikeQuote: Quote = bikeNewJson;
const usedBikeQuote: Quote = bikeUsedJson;
const newEBikeQuote: Quote = eBikeNewJson;
const usedEBikeQuote: Quote = eBikeUsedJson;
export const zoneLookup: Record<string, Zone> = JSON.parse(
  JSON.stringify(zoneLookupJson)
); // this is a hacky solution to let Typescript accept custom typing of zoneLookup JSON values

export const zoneMapping: Record<Zone, ZoneIndex> = {
  0: 'zone1', // missing business requirement, waiting for response from the provider
  1: 'zone1',
  2: 'zone2',
  3: 'zone3',
  4: 'zone4',
};

export const bikeToQuoteLookup = {
  BIKE: {
    NEW: newBikeQuote,
    USED: usedBikeQuote,
  },
  E_BIKE: {
    NEW: newEBikeQuote,
    USED: usedEBikeQuote,
  },
};
