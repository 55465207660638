import { capitalizeSingleWord } from 'shared/util/capitalizeSingleWord';
import { validate as isValidUUID } from 'uuid';

const domains = ['claims', 'recommendation-tool'];

const getDomainFromPath = (path: string) =>
  // Return the domain if it's in our list
  (domains.filter((d) => path.includes(d)).pop() || '')
    .split('-')
    .map((s) => capitalizeSingleWord(s))
    .join(' ');

const getSubdomainFromPath = (path: string) => {
  // Return whatever comes after '/policies', '/recommendation-tool' or '/claims'
  // as the title's "subdomain"
  const regExp = new RegExp(/(?:policies|recommendation-tool|claims)\/(\w+)/g);

  const subdomainStr = regExp.exec(path)?.[1] || '';

  return capitalizeSingleWord(subdomainStr);
};

const getDetailsFromPath = (path: string) => {
  // Return the last part of the path as the title's "details"
  return (path.split('/').pop() || '')
    .split('-')
    .map((s) => capitalizeSingleWord(s))
    .join(' ');
};

export const generatePageTitle = (path: string) => {
  // Filter out all uuids from the path to ensure human readable titles
  const pathWithoutUUIDs = path
    .split('/')
    .filter((s) => !isValidUUID(s))
    .join('/');

  const domainStr = getDomainFromPath(pathWithoutUUIDs);
  const subdomainStr = getSubdomainFromPath(pathWithoutUUIDs);
  const detailStr = getDetailsFromPath(pathWithoutUUIDs);

  const stringsWithoutDuplicatesAndEmpty = Array.from(
    new Set([domainStr, subdomainStr, detailStr])
  ).filter(Boolean);

  return stringsWithoutDuplicatesAndEmpty.join(' - ');
};
