import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { AxiosResponse } from 'axios';
import { HealthCardActions } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import { APIResponseError } from 'models/error';
import {
  HealthCard,
  HealthCardDispatch,
  UpdateHealthCardAddress,
  UploadHealthCardPhotoPayload,
} from 'models/healthCard';
import endpoint from 'shared/api';

export const mergeHealthCardDetails = (
  id: string,
  details: HealthCard
): HealthCardActions => ({
  type: 'MERGE_HEALTH_CARD_DETAILS',
  payload: {
    id,
    details,
  },
});

export const fetchHealthCard =
  (healthCardId: string) => async (dispatch: HealthCardDispatch) => {
    const REQUEST_TYPE = 'FETCH_HEALTH_CARD_DETAILS';
    dispatch(setRequestInProcess(true, REQUEST_TYPE));

    try {
      const { data } = await endpoint.fetchHealthCardDetails(healthCardId);

      dispatch(mergeHealthCardDetails(healthCardId, data));
      dispatch(setRequestInProcess(false, REQUEST_TYPE));
      return data;
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, REQUEST_TYPE));
    }
  };

export const updateHealthCardAddress = (payload: UpdateHealthCardAddress) => {
  return async (dispatch: HealthCardDispatch) => {
    const { id } = payload;
    const REQUEST_TYPE = 'UPDATE_HEALTH_CARD_DETAILS';
    dispatch(setRequestInProcess(true, REQUEST_TYPE));

    try {
      await endpoint.updateHealthCardAddress(payload);

      const { data } = await endpoint.fetchHealthCardDetails(id);

      await dispatch(mergeHealthCardDetails(id, data));

      dispatch(setRequestInProcess(false, REQUEST_TYPE));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, REQUEST_TYPE));
    }
  };
};

export const fetchPhotoUploadToken =
  (healthCardId: string) => async (dispatch: HealthCardDispatch) => {
    const REQUEST_TYPE = 'FETCH_HEALTH_CARD_TOKEN';
    dispatch(setRequestInProcess(true, REQUEST_TYPE));

    try {
      const response = await endpoint.fetchPhotoUploadToken(healthCardId);
      if ('data' in response) {
        const { token } = response.data;
        return token;
      }
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, REQUEST_TYPE));
    } finally {
      dispatch(setRequestInProcess(false, REQUEST_TYPE));
    }
  };

export const uploadHealthCardPhoto =
  (payload: UploadHealthCardPhotoPayload) =>
  async (dispatch: HealthCardDispatch) => {
    const REQUEST_TYPE = 'UPDATE_HEALTH_CARD_DETAILS';
    dispatch(setRequestInProcess(true, REQUEST_TYPE));
    const { file, fileExtension, token } = payload;
    try {
      const {
        data: { photoId, uploadUrl },
      } = await endpoint.createHealthCardPhotoUpload(payload);
      await endpoint.uploadFile(file, fileExtension, uploadUrl);
      const {
        data: { downloadUrl },
      } = await endpoint.markHealthCardPhotoUploaded(photoId, token);
      dispatch(setRequestInProcess(false, REQUEST_TYPE));
      return downloadUrl;
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, REQUEST_TYPE));
    }
  };

export const updateHealthCardDetails =
  (callback: Promise<AxiosResponse>, requestType?: RequestType) =>
  async (dispatch: HealthCardDispatch) => {
    const REQUEST_TYPE = requestType ?? 'UPDATE_HEALTH_CARD_DETAILS';
    dispatch(setRequestInProcess(true, REQUEST_TYPE));
    try {
      await callback;
      dispatch(setRequestInProcess(false, REQUEST_TYPE));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, REQUEST_TYPE));
    }
  };

export const approveHealthCardPhoto = (photoId: string) =>
  updateHealthCardDetails(endpoint.approveHealthCardPhoto(photoId));

export const finalizeHealthCard = (healthCardId: string) =>
  updateHealthCardDetails(endpoint.finalizeHealthCard(healthCardId));

export const markHealthCardAsReceived = (policyId: string) =>
  updateHealthCardDetails(endpoint.markHealthCardAsReceived(policyId));

export const createHealthCardRecord = (policyId: string) =>
  updateHealthCardDetails(
    endpoint.createHealthCardRecord(policyId),
    'CREATE_HEALTH_CARD'
  );
