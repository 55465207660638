import { trackStructuredEvent } from '@getpopsure/analytics';
import { calendly } from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { useSafeTranslation } from 'shared/i18n';

import styles from './CTASection.module.scss';

type CTASectionProps = {
  price: number | undefined;
  onContinueClick: () => void;
  isLoading: boolean;
};

export const CTASection = ({
  isLoading,
  onContinueClick,
  price,
}: CTASectionProps) => {
  const { t } = useSafeTranslation();

  const onScheduleCallClick = () => {
    trackStructuredEvent({
      category: 'DISABILITY_INSURANCE',
      action: 'CLICKED_BOOK_AN_APPOINTMENT',
    });
    window.Calendly.initPopupWidget({
      url: `${calendly.base}/occupational-disability`,
    });
  };

  return (
    <section className="bg-white">
      <div className="p-container bg-grey-100 br8">
        <h1 className="p-h1 p--serif pt40 pb8 ta-center">
          {t('disabilityV2.quote.title', 'Disability insurance')}
        </h1>
        <div className="d-flex fd-column ai-center jc-center w100">
          {isLoading || typeof price !== 'number' ? (
            <span
              className={`ds-spinner ds-spinner__l ${styles.loadingSpinner}`}
            />
          ) : (
            <>
              <h3 className="p-h1--xl p--serif tc-primary-500 ta-center">
                {englishFormattedEuroCurrency(price, true)}
              </h3>
              <p className="p-p--small ta-center tc-grey-500">
                {t('disabilityV2.quote.calculator.perMonth', 'per month')}
              </p>
            </>
          )}
        </div>
        <div className="d-flex fd-column ai-center gap16 pt48 pb48">
          <button
            className="w100 p-btn p-btn--primary wmx4"
            onClick={onContinueClick}
            type="button"
            data-cy="button-continue"
            disabled={isLoading}
          >
            {t('disabilityV2.quote.continueButton2.caption', 'Continue')}
          </button>
          <button
            className="p-btn--secondary bg-white wmn4"
            onClick={onScheduleCallClick}
            type="button"
          >
            {t(
              'disabilityV2.quote.scheduleCallButton.caption',
              'Schedule a call'
            )}
          </button>
        </div>
      </div>
    </section>
  );
};
