import { useTranslation } from '@getpopsure/i18n-react';
import { website } from '@getpopsure/private-constants';
import { chat } from 'features/recommendationTool/assets/icons';

import styles from './OverviewFooter.module.scss';

export const OverviewFooter = () => {
  const { t } = useTranslation();

  return (
    <div className="mt24 mb24 ta-center">
      <p className="p-p mb16 tc-grey-600">
        {t(
          'recommendationtool.overview.advicedisclaimer',
          'This recommendation doesn’t replace expert insurance advice.'
        )}
      </p>

      <a
        className={`p-btn--secondary ${styles.button}`}
        href={website.support}
        target="_blank"
        rel="noreferrer"
      >
        <div className="d-flex ai-center">
          <img
            width="24px"
            height="24px"
            className="mr16"
            src={chat}
            aria-hidden
            alt=""
          />
          <div>
            {t(
              'recommendationtool.overview.getadvice',
              'Get advice from an expert'
            )}
          </div>
        </div>
      </a>
    </div>
  );
};
