import { TFunction } from '@getpopsure/i18n-react';
import { DisabilityQuestionnaire } from 'features/disability/models';

import { preQuoteQNR } from './preQuote';
import { quoteAndAddonsQNR } from './quoteAndAddons';
import { tallyFormQuestionnaire } from './tallyForm';

export const getTranslatedQuestionnaire = (
  t: TFunction
): DisabilityQuestionnaire => ({
  ...preQuoteQNR(t),
  ...quoteAndAddonsQNR(),
  ...tallyFormQuestionnaire(),
});
