import {
  getRoutes,
  PageNotFound,
  QuestionnaireQuestions,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { UserWithBrokerMandate } from 'models/user';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';
import { getAccountInfo } from 'selectors/user';
import { SignupQuestionnaire } from 'SignupQuestionnaire';
import { SignupSpecificComponents } from 'SignupQuestionnaire/SignupQuestionnaire';

import { GeneralCheckout } from './components/Checkout/Checkout';
import { GeneralReview } from './components/Review/Review';
import { verticalIdToInsuranceType } from './functions';
import { QuestionnaireJSON } from './model';
import { useStateManagement } from './useStateManagement';

const prefillNameAndBirthDate = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<QuestionnaireQuestions> = {};
    const { firstName, lastName } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    return toUpdate;
  }

  return null;
};

export const SignupPage = () => {
  const { verticalId } = useParams<{ verticalId: string }>();

  const {
    questionnaireAnswers,
    updateQuestionnaireAnswers,
    removeQuestionnaireAnswers,
  } = useStateManagement(verticalIdToInsuranceType(verticalId));

  const account = useSelector(getAccountInfo);

  const [json, setJson] = useState<QuestionnaireJSON | false>();

  useEffect(() => {
    const load = async () => {
      try {
        const value = (await import(`./${verticalId}.json`)).default;
        setJson(value);
        updateQuestionnaireAnswers({
          insuranceType: verticalIdToInsuranceType(verticalId),
        });
      } catch (e) {
        setJson(false);
      }
    };

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verticalId]);

  if (json === undefined) {
    return <LoadingSpinner />;
  }

  if (!json || !json.meta.published) {
    return <PageNotFound />;
  }

  const onAnswer = (
    questionId: string,
    answer: unknown,
    additionalData: Partial<Record<string, unknown>> = {}
  ) => {
    if (!json) {
      return;
    }
    updateQuestionnaireAnswers({
      ...additionalData,
      [questionId]: answer,
    });

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    removeQuestionnaireAnswers(answersToRemove);

    const toUpdate = prefillNameAndBirthDate(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );
    if (toUpdate) {
      updateQuestionnaireAnswers(toUpdate);
    }
  };

  const { meta, questions, rules, removeAnswersLogic } = json;

  const basePath = routes.policies.general.path.replace(
    ':verticalId',
    verticalId
  );
  const questionnairePath = routes.policies.general.questionnaire.path.replace(
    ':verticalId',
    verticalId
  );

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, basePath),
    rules,
  };

  return (
    <Switch>
      <Route path={questionnairePath}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: {
              ...SignupSpecificComponents,
              REVIEW: GeneralReview,
              CHECKOUT: GeneralCheckout,
            },
            questions,
          }}
          basePath={basePath}
          questionId="intro"
          featureName={meta.insuranceType}
          share={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            verticalId,
          }}
        />
      </Route>
    </Switch>
  );
};
