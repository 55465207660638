import { useTranslation } from '@getpopsure/i18n-react';
import { AddOns } from '@getpopsure/legal-insurance-pricing-engine-v2';
import { BottomOrRegularModal } from '@popsure/dirty-swan';
import Markdown from 'components/markdown';
import { useState } from 'react';
import { createPortal } from 'react-dom';

import CoverageItem from '../CoverageItem/CoverageItem';
import {
  getAdditionalInfo,
  getAddOnCoverageItems,
} from './content/legalAddOns';

const CoverageDetails = ({ addOnName }: { addOnName: AddOns }) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const addOnContentMapping = {
    PROPERTY: {
      title: 'Home extension',
      coverageItems: getAddOnCoverageItems(t, 'PROPERTY'),
      additionalInfo: getAdditionalInfo(t, 'PROPERTY') ?? '',
    },
    CRIMINAL: {
      title: 'Criminal protection extension',
      coverageItems: getAddOnCoverageItems(t, 'CRIMINAL'),
      additionalInfo: getAdditionalInfo(t, 'CRIMINAL') ?? '',
    },
  };

  const { title, coverageItems, additionalInfo } =
    addOnContentMapping[addOnName];

  return (
    <div>
      {createPortal(
        <BottomOrRegularModal
          isOpen={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          title={title}
        >
          <div className="p-p p24 mt8">
            {coverageItems.map((item) => (
              <div key={item.id}>
                <CoverageItem entry={item} />
              </div>
            ))}
            <Markdown>{additionalInfo}</Markdown>
          </div>
        </BottomOrRegularModal>,
        document.body
      )}
      <button
        type="button"
        onClick={() => setOpenModal(true)}
        className="p-a fw-bold bg-transparent c-pointer"
      >
        {t('legal.qnr.quote.addOnModal.button.label', 'See coverage details')}
      </button>
    </div>
  );
};

export default CoverageDetails;
