import {
  RecommendationType,
  TQuickAdvice,
  VerticalCategory,
} from 'features/recommendationTool/models/recommendation';

import * as badges from '../assets/badges';
import { CardText, CategoryLookup, Section } from '../models/lookups';
import { bikeCardText, bikeCategory, bikeSection } from './bike';
import { carCategory, carSection } from './car';
import { dentalCardText, dentalCategory, dentalSection } from './dental';
import { disabilityCategory, disabilitySection } from './disability';
import { dogLiabilityCategory, dogLiabilitySection } from './dogLiability';
import {
  ehicHealthSection,
  expatHealthSection,
  healthCardText,
  healthCategory,
  privateHealthSection,
  publicHealthSection,
} from './health';
import {
  householdCardText,
  householdCategory,
  householdSection,
} from './household';
import { legalCardText, legalCategory, legalSection } from './legal';
import {
  liabilityCardText,
  liabilityCategory,
  liabilitySection,
} from './liability';
import { lifeCardText, lifeCategory, lifeSection } from './life';
import { travelHealthCategory, travelHealthSection } from './travelHealth';

export const categoryLookup: Record<VerticalCategory, CategoryLookup> = {
  HEALTH_INSURANCE: healthCategory,
  DENTAL: dentalCategory,
  LIABILITY: liabilityCategory,
  LIFE: lifeCategory,
  HOUSEHOLD: householdCategory,
  LEGAL: legalCategory,
  BIKE: bikeCategory,
  DISABILITY: disabilityCategory,
  CAR: carCategory,
  DOG_LIABILITY: dogLiabilityCategory,
  TRAVEL: travelHealthCategory,
  QUICK_RECOMMENDATION: disabilityCategory,
};

export const sectionLookup: Record<RecommendationType, Section> = {
  EHIC_HEALTH: ehicHealthSection,
  EXPAT_HEALTH: expatHealthSection,
  PUBLIC_HEALTH: publicHealthSection,
  PRIVATE_HEALTH: privateHealthSection,
  DENTAL: dentalSection,
  LIFE: lifeSection,
  LIABILITY: liabilitySection,
  HOUSEHOLD: householdSection,
  LEGAL: legalSection,
  BIKE: bikeSection,
  DISABILITY: disabilitySection,
  CAR: carSection,
  DOG_LIABILITY: dogLiabilitySection,
  TRAVEL: travelHealthSection,
  QUICK_RECOMMENDATION: disabilitySection,
};

export const cardTextLookup: CardText = {
  ...bikeCardText,
  ...dentalCardText,
  ...householdCardText,
  ...legalCardText,
  ...lifeCardText,
  ...liabilityCardText,
  ...healthCardText,
};

type Advice = {
  title: string;
  icon: string;
  icon2x?: string;
  icon3x?: string;
};

export const adviceLookup: Record<
  TQuickAdvice | 'NOT_NEEDED' | 'OPTIONAL' | 'LIKELY_NEEDED',
  Advice
> = {
  NEEDED: {
    title: 'Needed',
    icon: badges.needed,
    icon2x: badges.needed2x,
    icon3x: badges.needed3x,
  },
  NOT_NEEDED: {
    title: 'Not needed',
    icon: badges.notneeded,
    icon2x: badges.notneeded2x,
    icon3x: badges.notneeded3x,
  },
  OPTIONAL: {
    title: 'Optional',
    icon: badges.optional,
    icon2x: badges.optional2x,
    icon3x: badges.optional3x,
  },
  LIKELY_NEEDED: {
    title: 'Likely needed',
    icon: badges.likelyneeded,
    icon2x: badges.likelyneeded2x,
    icon3x: badges.likelyneeded3x,
  },
  STRONGLY_SUGGESTED: {
    title: 'Strongly suggested',
    icon: badges.likelyneeded,
    icon2x: badges.likelyneeded2x,
    icon3x: badges.likelyneeded3x,
  },
  WORTH_CONSIDERING: {
    title: 'Worth considering',
    icon: badges.optional,
    icon2x: badges.optional2x,
    icon3x: badges.optional3x,
  },
  NOT_RELEVANT: {
    title: 'Not relevant',
    icon: badges.notneeded,
    icon2x: badges.notneeded2x,
    icon3x: badges.notneeded3x,
  },
};

export const categoryGroupLookup: Record<TQuickAdvice, string> = {
  NOT_RELEVANT: 'Not relevant',
  NEEDED: 'Needed',
  WORTH_CONSIDERING: 'Worth considering',
  STRONGLY_SUGGESTED: 'Strongly suggested',
};
