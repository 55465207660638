import { TFunction } from '@getpopsure/i18n-react';
import { getTrackingObject } from '@getpopsure/tracker';
import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkAction } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint from 'shared/api';
import { CreateQuoteDispatch } from 'SignupQuestionnaire/actions/account';

import {
  CreateQuoteReturnType,
  SubmittableBikeQuestionnaire,
  ZQuote,
  ZSubmittableBikeQuestionnaire,
} from './models';

const createBikeQuote = (quote: number) => {
  return endpoint.network.post<{ id: string; price: number; planId: string }[]>(
    '/signups/bike/quotes',
    { price: quote }
  );
};

const submitBikeQuoteInformation = async (
  answers: SubmittableBikeQuestionnaire
) => {
  const source = getTrackingObject();
  const uploadTokens = answers.tariffInfo.receipts
    ?.map((file) => file.token)
    .filter((token) => token !== undefined);
  return endpoint.network.post<{
    id: string;
    answers: SubmittableBikeQuestionnaire;
  }>('/questionnaires/', {
    answers,
    uploadTokens,
    questionnaireType: 'BIKE_V2',
    metadata: source,
  });
};

export const createPostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'bike'> | RequestAction
  > =>
  async (dispatch: CreateQuoteDispatch<'bike'>, getState: () => AppState) => {
    const questionnaire = getGenericQuestionnaire(getState()).bike || {};

    try {
      // Quote validation
      if (!questionnaire.quote) {
        throw new Error(
          `Quote is not defined. Please try again from the beginning.`
        );
      }
      const quoteValidation = ZQuote.safeParse(questionnaire.quote);
      if (!quoteValidation.success) {
        throw new Error(
          `Quote is invalid. Please try again from the beginning or contact support if the error happens again. Error: ${quoteValidation.error.toString()}`
        );
      }
      const validatedQuote = quoteValidation.data;
      const {
        data: [quote],
      } = await createBikeQuote(validatedQuote.price);

      // Questionnaire answers validation
      const questionnaireValidation =
        ZSubmittableBikeQuestionnaire.safeParse(questionnaire);
      if (!questionnaireValidation.success) {
        throw new Error(
          `Questionnaire is invalid. Please try again from the beginning or contact support if the error happens again. Error: ${questionnaireValidation.error.toString()}`
        );
      }
      const validatedAnswers = questionnaireValidation.data;

      dispatch(setRequestInProcess(true, 'CREATE_POST_QUOTE_SUBMIT_INFO'));

      const {
        email,
        name: { firstName, lastName },
      } = validatedAnswers.personalInfo;

      // check if the account exists and create a new account if needed
      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            email,
          })
        );
      }

      const {
        data: { id: questionnaireId },
      } = await submitBikeQuoteInformation(validatedAnswers);
      dispatch(
        storeGenericQuestionnaireAnswer('bike', {
          quoteId: quote.id,
          questionnaireId,
          quote: { ...questionnaire.quote, id: quote.id },
        })
      );

      await generateCheckoutDocuments(questionnaireId, quote.id, 'BIKE_V2', t);

      dispatch(setRequestInProcess(false, 'CREATE_POST_QUOTE_SUBMIT_INFO'));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          'CREATE_POST_QUOTE_SUBMIT_INFO'
        )
      );
      Sentry.captureException(error, {
        tags: {
          feature: 'POST_QUOTE_SUBMISSION',
          vertical: 'BIKE_V2',
        },
        extra: {
          description: 'Failed to submit with',
        },
      });
      return { status: 'ERROR' };
    }
  };
