import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { AxiosInstance } from 'axios';
import { RequestAction } from 'constants/actions';
import { APIResponseError } from 'models/error';
import { InsuranceTypes } from 'models/insurances/types';
import { AppState } from 'reducers';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import endpoint from 'shared/api';

export const getPolicyRecommendations = (
  network: AxiosInstance,
  insuranceType: InsuranceTypes
) => {
  return network.get<{ recommendations: InsuranceTypes[] }>(
    `/me/policies/recommendations/${insuranceType}`
  );
};

export type PolicyRecommendationsDispatch = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  RequestAction
>;

export type PolicyRecommendationsAction<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  Record<string, unknown>,
  RequestAction
>;

export const fetchPolicyRecommendations =
  (
    insuranceType: InsuranceTypes
  ): PolicyRecommendationsAction<Promise<InsuranceTypes[] | undefined>> =>
  async (dispatch: PolicyRecommendationsDispatch) => {
    try {
      dispatch(setRequestInProcess(true, 'POLICY_RECOMMENDATIONS'));

      const { data: policies } = await getPolicyRecommendations(
        endpoint.network,
        insuranceType
      );

      dispatch(setRequestInProcess(false, 'POLICY_RECOMMENDATIONS'));

      return policies.recommendations;
    } catch (error) {
      setRequestErrored(error as APIResponseError, 'POLICY_RECOMMENDATIONS');
    }
  };
