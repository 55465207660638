import { Badge } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { mapPolicyStatus } from 'features/policiesDashboard/models';
import { PolicyStatusOrNull } from 'features/policiesDashboard/utils/getPolicyStatus';
import { InsuranceTypes } from 'models/insurances/types';
import {
  getTitleMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';
import { PolicyAttributes } from 'models/policies';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';

import styles from './PolicyCard.module.scss';

interface PolicyCardProps {
  attributes: PolicyAttributes;
  content?: ReactNode[];
  to?: string;
  providerName?: string;
  status: PolicyStatusOrNull;
  type: InsuranceTypes;
}

const PolicyCardContent = ({
  attributes,
  content = [],
  providerName,
  status,
  type,
}: Omit<PolicyCardProps, 'onClick'>) => {
  const { t } = useSafeTranslation();
  const title = getTitleMapping(t)?.[type];
  const formattedStatus = mapPolicyStatus(
    status,
    { ...attributes, providerName },
    t
  );
  const companyName = attributes.policyData?.['Company name'] || '';

  return (
    <>
      <img
        alt={title}
        className={classNames(styles.cardImage, 'br8')}
        src={imageTypeMapping?.[type] || ''}
      />

      <div
        className={classNames(
          styles.cardContent,
          'd-flex jc-between w100 w100 ta-left'
        )}
      >
        <div>
          <h4 className="p-h4">
            {title}
            <span className="ml8 p-p tc-grey-600">{companyName}</span>
          </h4>

          <div className="tc-grey-600 p-p--small">
            {content.map(
              (item) =>
                item && (
                  <div className={styles.contentItem} key={String(item)}>
                    {item}
                  </div>
                )
            )}
          </div>
        </div>

        {formattedStatus && (
          <div className="mt8">
            <Badge variant={formattedStatus.variant} size="small">
              {formattedStatus.text}
            </Badge>
          </div>
        )}
      </div>
    </>
  );
};

const PolicyCard = ({ to, ...rest }: PolicyCardProps) => {
  const sharedProps = {
    'data-cy': 'policy-list-card',
    'data-testid': 'policy-list-card',
    className: classNames(
      'ds-card--no-dropshadow bg-white br8 p24 d-flex ai-center gap16',
      styles.policyCard,
      {
        'ds-card--actionable': !!to,
        [styles.policyCardNoHover]: isMobileApp,
        [styles.policyCardNoAction]: !to,
      }
    ),
  };

  if (!to) {
    return (
      <div {...sharedProps}>
        <PolicyCardContent {...rest} />
      </div>
    );
  }

  return (
    <Link
      {...sharedProps}
      to={to}
      type="button"
      onClick={() => window.scroll(0, 0)}
    >
      <PolicyCardContent {...rest} />
    </Link>
  );
};

export { PolicyCard };
