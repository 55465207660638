import FAQ from 'components/faq';
import { useSafeTranslation } from 'shared/i18n';

import { faqQuestions } from './faqQuestions';

export const FAQSection = () => {
  const { t } = useSafeTranslation();

  return (
    <section className="p-container mt80 mb80">
      <FAQ data={faqQuestions(t)} />
    </section>
  );
};
