import {
  getPriceBreakdown,
  tariffNameMapping,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Tariff } from 'features/privateHealthPreSignup/models';

import styles from './CashbackModal.module.scss';

interface CashbackModalProps {
  birthYear: number;
  income: number;
  tariff: Tariff;
}

export const CashbackModal = (props: CashbackModalProps) => {
  const { birthYear, income, tariff } = props;

  const cashbackObject = getPriceBreakdown({
    birthYear,
    tariff,
    income,
  }).cashback;

  if (!cashbackObject) return null;

  const {
    guaranteedMonthlyCashback,
    yearlyNoClaimsBonuses,
    firstYearNoClaimsBonus,
    totalPotentialCashback,
  } = cashbackObject;

  const formattedGuaranteedYearlyCashback: string =
    englishFormattedEuroCurrency(guaranteedMonthlyCashback * 12, true);

  const formattedMonthlyCashback: string = englishFormattedEuroCurrency(
    guaranteedMonthlyCashback,
    true
  );

  return (
    <>
      <p className="p-p">
        Depending on how many claims you make, you may get some money back from
        your insurance.
      </p>
      <div className={`mt32 mb16 d-flex jc-between ${styles.row}`}>
        <p className="p-h3">Monthly cashback</p>
        <p className="p-h3">{formattedMonthlyCashback}</p>
      </div>
      <p className="p-p">
        You will automatically get a monthly cashback payment of{' '}
        {formattedMonthlyCashback} to your bank account (
        {formattedGuaranteedYearlyCashback} per year). When making claims, you
        will have to use this cashback to pay the first{' '}
        {formattedGuaranteedYearlyCashback} worth of medical expenses, even
        though your deductible is {englishFormattedEuroCurrency(0, true)}. If
        you make less than {formattedGuaranteedYearlyCashback} worth of claims
        in the year, you get to keep the difference.
      </p>
      {tariff === 'NKSelectXL' && (
        <p className="p-p mt16">
          On the {tariffNameMapping[tariff]} plan, claims for{' '}
          <b className="fw-bold"> purely preventative treatments </b>{' '}
          (outpatient and dental) are paid directly and will not be taken out of
          your cashback.
        </p>
      )}
      <div className="mt24 mb16 d-flex jc-between">
        <p className="p-h3">No-claim-in-a-year bonus</p>
        <p className="p-h3">
          {englishFormattedEuroCurrency(firstYearNoClaimsBonus, true)}
        </p>
      </div>
      <div className={`p-p ${styles.noClaimsContainer}`}>
        <p>You can get this bonus if you:</p>
        <ul>
          <li>submitted no claims for a whole calendar year</li>
          <li>were covered for all 12 months of that year</li>
          <li>are still covered for the following year</li>
        </ul>
        <p className="mt24">
          The first eligible year starts on{' '}
          <span className="fw-bold">January 1st</span>
        </p>
        <p>
          This reward will increase if you don’t submit claims for multiple
          years in a row. If you go to the doctor, simply pay out-of-pocket to
          stay eligible for the bonus. If you change your mind, you can always
          submit the bills up to 2 years later. Some conditions apply.
        </p>
        {(tariff === 'NKSelectXL' ||
          tariff === 'NKSelectL' ||
          tariff === 'NKSelectS') && (
          <p className="p-p mt16">
            Claims for <b className="fw-bold">purely preventative treatments</b>{' '}
            (outpatient and dental) will not affect your bonus.
          </p>
        )}
        <p className="p-p mt16">Your bonus:</p>
        {yearlyNoClaimsBonuses.map((value, index) => (
          <p className="p-p" key={value}>
            {englishFormattedEuroCurrency(value, true)} after {index + 1} year
            {index === 0 ? '' : 's'}
          </p>
        ))}
        <p className="p-p">
          {englishFormattedEuroCurrency(yearlyNoClaimsBonuses[4], true)} for
          every other year
        </p>
      </div>
      <div className={`mt24 d-flex jc-between ${styles.divider}`}>
        <p className="p-h3">Potential cashback for first year</p>
        <p className="p-h2">
          {englishFormattedEuroCurrency(totalPotentialCashback, true)}
        </p>
      </div>
    </>
  );
};
