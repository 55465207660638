import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { WaveHandIcon } from 'components/waveHandIcon/WaveHandIcon';
import { ExpatSpainDependent } from 'features/expatSpain/models';
import { useEffect } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import styles from './DependentsIntro.module.scss';

export const DependentsIntro = ({
  onSubmitValue,
  setValidForSubmission,
}: QuestionnaireFormProps<ExpatSpainDependent>) => {
  const { t } = useSafeTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    setValidForSubmission(true);
  }, [setValidForSubmission]);

  const handleGetStarted = () => {
    onSubmitValue(true);
  };

  return (
    <div className={styles.container}>
      <div className="p-body">
        <WaveHandIcon />
        <h1 className="p-h1 mt16">
          {t(
            'expatSpain.qnr.components.dependentsIntro.title',
            "Hey! Let's cover your loved one"
          )}
        </h1>
        <p className="p-p mt16">
          {t(
            'expatSpain.qnr.components.dependentsIntro.description',
            'You can simply answer the following questions for them and their new policy will be added to your account.'
          )}
        </p>
        <button
          className={`p-btn p-btn--primary ws3 mt24 ${styles.nextButton}`}
          data-cy="button-continue"
          onClick={handleGetStarted}
          type="button"
        >
          {t('expatSpain.qnr.components.dependentsIntro.cta', 'Get Started')}
        </button>
      </div>
    </div>
  );
};
