import { CoverageDetails } from 'components/CoverageDetails';
import { useSafeTranslation } from 'shared/i18n';

import { getCoverageTableData } from './coverageTableData';

type LiabilityCoverageDetailsProps = {
  buttonCaption?: string;
  grey?: boolean;
  small?: boolean;
  useAlternateDefaultCaption?: boolean;
};

export const LiabilityCoverageDetails = ({
  buttonCaption,
  grey,
  small,
  useAlternateDefaultCaption = false,
}: LiabilityCoverageDetailsProps) => {
  const { t } = useSafeTranslation();

  const coverageTableData = getCoverageTableData(t);

  return (
    <CoverageDetails
      grey={grey}
      small={small}
      buttonCaption={buttonCaption}
      coverageTableData={coverageTableData}
      useAlternateDefaultCaption={useAlternateDefaultCaption}
    />
  );
};
