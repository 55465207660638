import { TFunction } from '@getpopsure/i18n-react';
import { website } from '@getpopsure/private-constants';
import { Blocker } from 'components/Blocker';
import InputForm from 'components/questionnaireForms/InputForm';
import { LiabilityClaimSubmitted as ClaimSubmitted } from 'features/liabilityClaims/components/ClaimSubmitted';
import { LiabilityClaimsGettingStarted as GettingStarted } from 'features/liabilityClaims/components/GettingStarted';
import { PayoutDetails } from 'features/liabilityClaims/components/PayoutDetails';
import { LiabilityClaimsProcessing as Processing } from 'features/liabilityClaims/components/Processing';
import { ThirdPartyAddress } from 'features/liabilityClaims/components/ThirdPartyAddress';
import { UploadDocuments } from 'features/liabilityClaims/components/UploadDocuments';
import {
  claimTypeMapping,
  LiabilityClaims,
  LiabilityClaimsMetaData,
  QuestionId,
  Questionnaire,
} from 'features/liabilityClaims/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { emailAddressValidator } from 'shared/util/emailValidator';
import { isValidIban } from 'shared/util/isValidIban';

import { getDateOfEventRange, validateDateOfEvent } from '../utils';

export const translatedQuestionnaire = (
  t: TFunction,
  metaData: LiabilityClaimsMetaData
): Questionnaire => ({
  intro: {
    required: true,
    type: 'CUSTOM',
    component: GettingStarted,
    metaData: {
      policyId: metaData.policyId,
    },
    retrieveAnswerObject: (
      answer: LiabilityClaims['intro']
    ): Partial<LiabilityClaims> => ({
      intro: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'dateOfEvent',
    groupId: 'questions',
    questionId: 'intro',
    requiresAuth: true,
  },

  dateOfEvent: {
    required: true,
    type: 'DATE',
    props: {
      question: t(
        'claims.liability.questionnaire.dateofevent.question',
        'When did the event happen?'
      ),
      yearRange: getDateOfEventRange(metaData.policyStartDate),
    },
    retrieveAnswerObject: (
      answer: LiabilityClaims['dateOfEvent']
    ): Partial<LiabilityClaims> => ({
      dateOfEvent: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'claimType',
    groupId: 'questions',
    questionId: 'dateOfEvent',
    validateAnswer: (answer: LiabilityClaims['dateOfEvent']) =>
      validateDateOfEvent(t, answer, metaData.policyStartDate),
    requiresAuth: true,
  },

  claimType: {
    required: true,
    type: 'RADIO',
    props: {
      question: t(
        'claims.liability.questionnaire.claimtype.question',
        'Select a claim type'
      ),
      description: t(
        'claims.liability.questionnaire.claimtype.description',
        'Please select the type of claim that you wish to submit.'
      ),
      mapValue: claimTypeMapping(t),
    },
    retrieveAnswerObject: (
      answer: LiabilityClaims['claimType']
    ): Partial<LiabilityClaims> => ({
      claimType: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'description',
    groupId: 'questions',
    questionId: 'claimType',
    requiresAuth: true,
  },

  description: {
    required: true,
    type: 'TEXT',
    props: {
      question: t(
        'claims.liability.questionnaire.description.question',
        'Describe what happened in detail'
      ),
      placeholder: t(
        'claims.liability.questionnaire.description.placeholder',
        'Explain what the damage is, who caused it, and how.'
      ),
      additionalInfo: {
        title: t(
          'claims.liability.questionnaire.description.additionalinfo.title',
          'Describing the event'
        ),
        description: t(
          'claims.liability.questionnaire.description.additionalinfo.description',
          'Help us understand the nature of the damage, including how the damage was caused and who caused the damage. Maybe you’ve caused an accident with your bike, spilled coffee on a friend’s camera, or your child has knocked over your sister’s laptop.'
        ),
      },
      maxLength: 1000,
    },
    retrieveAnswerObject: (
      answer: LiabilityClaims['description']
    ): Partial<LiabilityClaims> => ({
      description: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'uploadedDocumentTokens',
    groupId: 'questions',
    questionId: 'description',
    requiresAuth: true,
  },

  uploadedDocumentTokens: {
    required: true,
    type: 'CUSTOM',
    component: UploadDocuments,
    metaData: { policyId: metaData.policyId },
    retrieveAnswerObject: (
      answer: LiabilityClaims['uploadedDocumentTokens']
    ): Partial<LiabilityClaims> => ({
      uploadedDocumentTokens: answer,
    }),
    retrieveNextPageId: (_, answers): QuestionId => {
      if (answers?.claimType === 'KEY_LOST') {
        return 'payoutDetails';
      }
      return 'thirdPartyName';
    },
    groupId: 'questions',
    questionId: 'uploadedDocumentTokens',
    requiresAuth: true,
  },

  thirdPartyName: {
    required: (answers) => Boolean(answers.claimType !== 'KEY_LOST'),
    type: 'INPUT',
    props: {
      question: t(
        'claims.liability.questionnaire.thirdPartyName.question',
        'Who is the injured party?'
      ),
      placeholder: t(
        'claims.liability.questionnaire.thirdPartyName.placeholder',
        'Full name or company name'
      ),
      additionalInfo: {
        title: t(
          'claims.liability.questionnaire.thirdPartyName.additionalInfo.title',
          'Who can be the injured party?'
        ),
        description: t(
          'claims.liability.questionnaire.thirdPartyName.additionalInfo.description',
          '* A person/company whose property has been damaged\n\n* A person who is physically injured by the damage\n\nThis person or company should not be insured under your policy.'
        ),
      },
    },
    retrieveAnswerObject: (
      answer: LiabilityClaims['thirdPartyName']
    ): Partial<LiabilityClaims> => ({
      thirdPartyName: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'thirdPartyEmail',
    groupId: 'questions',
    questionId: 'thirdPartyName',
    requiresAuth: true,
  },

  thirdPartyEmail: {
    required: (answers) => Boolean(answers.claimType !== 'KEY_LOST'),
    type: 'CUSTOM',
    component: (props) =>
      InputForm({
        ...props,
        value: typeof props.value === 'string' ? props.value : undefined,
        question: t(
          'claims.liability.questionnaire.thirdpartyemail.question',
          'What is their email address?'
        ),
        placeholder: t(
          'claims.liability.questionnaire.thirdpartyemail.placeholder',
          'Email'
        ),
        type: 'email',
        skipDetails: {
          title: t(
            'claims.liability.questionnaire.skipdetails.donthaveit',
            "I don't have it"
          ),
          onClick: () => {
            props.onSubmitValue(null);
          },
          path: '',
        },
      }),
    retrieveAnswerObject: (
      answer: LiabilityClaims['thirdPartyEmail']
    ): Partial<LiabilityClaims> => ({
      thirdPartyEmail: answer,
    }),
    retrieveNextPageId: (answer): QuestionId =>
      answer ? 'thirdPartyAddress' : 'thirdPartyEmailBlocker',
    groupId: 'questions',
    questionId: 'thirdPartyEmail',
    requiresAuth: true,
    validateAnswer: (
      answer: LiabilityClaims['thirdPartyEmail']
    ): string | null => {
      if (answer === null) {
        return null;
      }
      const isEmail = emailAddressValidator(answer);
      if (!isEmail) {
        return t(
          'claims.liability.questionnaire.thirdpartyemail.validationerror',
          'Please enter a valid email.'
        );
      }
      return null;
    },
  },

  thirdPartyEmailBlocker: {
    required: (answers) => Boolean(answers.thirdPartyEmail === null),
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'claims.liability.questionnaire.blocker.title',
          'Unfortunately...'
        ),
        description: t(
          'claims.liability.questionnaire.blocker.description',
          'The contact details of the injured party are required to examine the incident and settle your claim.\n\nIf you have any questions, feel free to reach out.'
        ),
        buttonProps: [
          {
            type: 'href',
            href: website.support,
            caption: t(
              'claims.liability.questionnaire.blocker.contact',
              'Get in touch'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'questions',
  },

  thirdPartyAddress: {
    required: (answers) => Boolean(answers.claimType !== 'KEY_LOST'),
    type: 'CUSTOM',
    component: (props) =>
      ThirdPartyAddress({
        ...props,
        buttonText: t(
          'claims.liability.questionnaire.submit.button.caption',
          'Submit claim'
        ),
        skipDetails: {
          title: t(
            'claims.liability.questionnaire.skipdetails.donthaveit',
            "I don't have it"
          ),
          onClick: () => {
            props.onSubmitValue(null);
          },
          path: '',
        },
      }),
    metaData: { policyId: metaData.policyId },
    retrieveAnswerObject: (
      answer: LiabilityClaims['thirdPartyAddress']
    ): Partial<LiabilityClaims> => ({
      thirdPartyAddress: answer,
    }),
    retrieveNextPageId: (answer): QuestionId =>
      answer ? 'processing' : 'thirdPartyAddressBlocker',
    groupId: 'questions',
    questionId: 'thirdPartyAddress',
    requiresAuth: true,
  },

  thirdPartyAddressBlocker: {
    required: (answers) => Boolean(answers.thirdPartyAddress === null),
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'claims.liability.questionnaire.blocker.title',
          'Unfortunately...'
        ),
        description: t(
          'claims.liability.questionnaire.blocker.description',
          'The contact details of the injured party are required to examine the incident and settle your claim.\n\nIf you have any questions, feel free to reach out.'
        ),
        buttonProps: [
          {
            type: 'href',
            href: website.support,
            caption: t(
              'claims.liability.questionnaire.blocker.contact',
              'Get in touch'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'questions',
  },

  payoutDetails: {
    required: (answers) => Boolean(answers.claimType === 'KEY_LOST'),
    type: 'CUSTOM',
    component: (props: CustomComponentProps<LiabilityClaims>) =>
      PayoutDetails({
        ...props,
        value: props.value
          ? (props.value as { amount: number; iban: string })
          : undefined,
        question: t(
          'claims.liability.questionnaire.payoutdetails.question',
          'Payout details'
        ),
        buttonText: t(
          'claims.liability.questionnaire.submit.button.caption',
          'Submit claim'
        ),
        text: {
          amount: {
            title: t(
              'claims.liability.questionnaire.payoutdetails.amount.title',
              'Claim amount'
            ),
            description: t(
              'claims.liability.questionnaire.payoutDetails.amount.description',
              "You can skip this if you don't know the amount yet"
            ),
            placeholder: t(
              'claims.liability.questionnaire.payoutDetails.amount.placeholder',
              'Amount'
            ),
          },
          iban: {
            title: t(
              'claims.liability.questionnaire.payoutDetails.iban.title',
              'Your IBAN'
            ),
            description: t(
              'claims.liability.questionnaire.payoutDetails.iban.description',
              "The IBAN of your account where you'll receive the payout if approved"
            ),
            placeholder: t(
              'claims.liability.questionnaire.payoutDetails.iban.placeholder',
              'IBAN'
            ),
          },
        },
        metaData: { policyId: metaData.policyId },
      }),
    retrieveAnswerObject: (
      payoutDetails: LiabilityClaims['payoutDetails']
    ): Partial<LiabilityClaims> => ({
      payoutDetails,
    }),
    retrieveNextPageId: (): QuestionId => 'processing',
    groupId: 'questions',
    questionId: 'payoutDetails',
    requiresAuth: true,
    validateAnswer: (answer: LiabilityClaims['payoutDetails']) => {
      if (!isValidIban(answer.iban)) {
        return t(
          'claims.liability.questionnaire.payoutdetails.validationerror',
          'Please enter a valid IBAN.'
        );
      }
      return null;
    },
  },

  processing: {
    required: true,
    type: 'CUSTOM',
    component: Processing,
    retrieveAnswerObject: (
      answer: LiabilityClaims['processing']
    ): Partial<LiabilityClaims> => ({
      processing: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'submitted',
    groupId: 'questions',
    questionId: 'processing',
    requiresAuth: true,
  },

  submitted: {
    required: true,
    type: 'CUSTOM',
    component: ClaimSubmitted,
    groupId: 'questions',
    questionId: 'submitted',
    requiresAuth: true,
  },
});
