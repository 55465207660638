import FAQ from 'components/faq';
import { useSafeTranslation } from 'shared/i18n';

import { faqQuestions } from './faqQuestions';
import styles from './FAQSection.module.scss';

export const FAQSection = () => {
  const { t } = useSafeTranslation();

  return (
    <section className="bg-white py80">
      <div className=" p-container ">
        <FAQ data={faqQuestions(t)} markdownClassName={styles.markdown} />
      </div>
    </section>
  );
};
