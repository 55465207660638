import { Address } from '@getpopsure/public-models';
import { Button, MapPinIcon } from '@popsure/dirty-swan';
import { updateHealthCardAddress } from 'actions/healthCard';
import { InlineButton } from 'features/studentChecklist/components';
import { StepProps } from 'features/studentChecklist/models';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'reducers';
// eslint-disable-next-line
import { getHealthCardAddress, getHealthCardId } from 'selectors/healthCard';
import { getRequestError, getRequestInProcess } from 'selectors/requests';

import AddressModal from '../addressModal';
import AddressPreview from './AddressPreview';

const REQUEST_TYPE = 'UPDATE_HEALTH_CARD_DETAILS';

const AddressStep = ({ completed, submittedChecklist }: StepProps) => {
  const { policyId } = useParams<{ policyId: string }>();
  const [modal, setModal] = useState<boolean>(false);
  const healthCardId = useSelector(getHealthCardId(policyId));
  const address = useSelector(getHealthCardAddress(policyId));
  const dispatch = useDispatch();
  const error = useSelector((state: AppState) =>
    getRequestError(state, REQUEST_TYPE)
  );
  const loading = useSelector((state: AppState) =>
    getRequestInProcess(state, REQUEST_TYPE)
  );
  useEffect(() => {
    if (!error && !loading) {
      setModal(false);
    }
    // Close modal after changing the address
  }, [address, error, loading]);

  if (!healthCardId)
    throw new Error(
      "[HEALTH_CARD_CHECKLIST]: health card checklist doesn't exist"
    );

  const onConfirmAddress = (addressToBeConfirmed: Address) =>
    dispatch(
      updateHealthCardAddress({
        id: healthCardId,
        address: addressToBeConfirmed,
      })
    );

  const toggleModal = () => setModal((modalStatus) => !modalStatus);

  if (submittedChecklist) return <AddressPreview address={address} />;

  return (
    <>
      {completed && address ? (
        <div>
          <AddressPreview address={address} />
          <InlineButton onClick={toggleModal}>Edit address</InlineButton>
        </div>
      ) : (
        <Button
          data-testid="address-button"
          className="mt8 wmx3 w100"
          leftIcon={<MapPinIcon />}
          onClick={toggleModal}
        >
          Confirm address
        </Button>
      )}
      {modal &&
        createPortal(
          <AddressModal
            toggleModal={toggleModal}
            onSave={onConfirmAddress}
            error={error?.message}
            loading={loading}
            userAddress={address ?? undefined}
          />,
          document.body
        )}
    </>
  );
};

export default AddressStep;
