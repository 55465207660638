import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import * as Sentry from '@sentry/react';
import { ErrorWithAction } from 'components/ErrorWithAction';
import routes from 'constants/routes';
import { createExpatSpainQuote } from 'features/expatSpain/actions';
import { ExpatSpain } from 'features/expatSpain/models';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';
import { Processing } from 'shared/pages/Processing';

const TIME_OUT = 1_000;

export const QuoteProcessing = ({
  onSubmitValue,
  questionnaireAnswers,
}: QuestionnaireFormProps<ExpatSpain>) => {
  const { t } = useSafeTranslation();
  const history = useHistory();

  const [error, setError] = useState(false);

  const loaded = useRef(false);

  useEffect(() => {
    const submitInfo = async () => {
      const { dateOfBirth, occupation, otherOccupation } = questionnaireAnswers;

      if (!dateOfBirth || !occupation) {
        return null;
      }

      try {
        const { data: quotes } = await createExpatSpainQuote({
          dateOfBirth,
          occupation,
          otherOccupation,
        });
        onSubmitValue(quotes);
      } catch (e) {
        setError(true);
        Sentry.captureException(`[Expat Spain] Failed to fetch quote: ${e}`);
        return null;
      }

      loaded.current = true;
    };

    let id: ReturnType<typeof setTimeout>;

    if (loaded.current === false) {
      id = setTimeout(() => {
        submitInfo();
      }, TIME_OUT);
    }

    return () => {
      clearTimeout(id);
    };
  }, [error, loaded, onSubmitValue, questionnaireAnswers]);

  const textList: string[] = [
    t('expatSpain.qnr.quoteProcessing.title', 'Processing quote...'),
  ];

  const description: string = t(
    'expatSpain.qnr.quoteProcessing.subtitle',
    "We're preparing a quote based on the answers you provided."
  );

  if (error) {
    return (
      <ErrorWithAction
        title={t(
          'expatSpain.qnr.quoteProcessing.error.title',
          'Something went wrong'
        )}
        description={t(
          'expatSpain.qnr.quoteProcessing.error.subtitle',
          'Sorry, something didn’t work as it should. Starting over should address this issue.'
        )}
        cta={{
          title: t('expatSpain.qnr.quoteProcessing.button.label', 'Start over'),
          onClick: () => history.push(routes.policies.expatSpain.path),
        }}
      />
    );
  }

  return (
    <Processing
      onLoadComplete={onSubmitValue}
      textList={textList}
      description={description}
    />
  );
};
