import { flushGenericQuestionnaire } from 'actions/genericQuestionnaire';
import { useWindowSize } from 'hooks/useWindowSize';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

export const PetHealthSignupTally = ({ src }: { src: string }) => {
  const size = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(flushGenericQuestionnaire('petHealth'));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <script async src="https://tally.so/widgets/embed.js" />
      </Helmet>
      <iframe
        width={size.width ?? '100%'}
        height={size.height ?? '100%'}
        data-tally-src={src}
        title="Sign up for Pet Health"
      />
    </>
  );
};
