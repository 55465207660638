import { Gender } from '@getpopsure/public-models';
import RadioForm from 'components/questionnaireForms/RadioForm';
import {
  ViewProps,
  withContainer,
} from 'features/liability/containers/withContainer';
import {
  getGenderMapping,
  getQuestionsMapping,
  QuestionId,
} from 'models/liability';
import { useSafeTranslation } from 'shared/i18n';

const questionId: QuestionId = 'gender';

const LiabilityGender = ({
  initialValue,
  onSubmitValue,
}: ViewProps<typeof questionId>) => {
  const { t } = useSafeTranslation();

  return (
    <RadioForm<Gender, ReturnType<typeof getGenderMapping>>
      value={initialValue}
      onSubmitValue={(value: Gender) => {
        onSubmitValue({ questionId, value });
      }}
      question={getQuestionsMapping(t)[questionId]}
      mapValue={getGenderMapping(t)}
      featureName="Liability"
    />
  );
};

export default withContainer(LiabilityGender, questionId);
