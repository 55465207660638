import { ExpatDependentQuestionnaire } from 'features/expat/models';
import { PartialQuestionnaire } from 'models/questionnaireFramework';

import { ReviewCheckout } from '../../components/ReviewCheckout';

export const checkoutDependentQNR = (): PartialQuestionnaire<
  ExpatDependentQuestionnaire,
  'signup'
> => ({
  reviewCheckout: {
    required: true,
    type: 'CUSTOM',
    groupId: 'signup',
    component: (props) =>
      ReviewCheckout({
        ...props,
        policyMemeberStatus: 'DEPENDENTS_ONLY',
      }),
    retrieveAnswerObject: (
      reviewCheckout: ExpatDependentQuestionnaire['reviewCheckout']
    ): Partial<ExpatDependentQuestionnaire> => ({
      reviewCheckout,
    }),
  },
});
