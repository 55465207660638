// TODO: move actions to /features/recommendationTool/actions
/* eslint-disable import/no-cycle */
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RecommendationQuestionnaireAction } from 'constants/actions';
import { APIResponseError } from 'models/error';
import {
  HealthQuestionnaireAnswers,
  RecommendationDetails,
  RecommendationPersonaResponse,
  RecommendationSnapshotRequest,
  RecommendationSnapshotResponse,
  RecommendationToolDispatch,
} from 'models/recommendationTool';
import endpoint from 'shared/api';

export const answerRecommendationToolQuestionnaire = (
  answer: Partial<HealthQuestionnaireAnswers>
): RecommendationQuestionnaireAction => ({
  type: 'RECOMMENDATION_TOOL_ANSWER_QUESTION',
  question: answer,
});

export const clearRecommendationTool =
  (): RecommendationQuestionnaireAction => ({
    type: 'CLEAR_RECOMMENDATION_TOOL',
  });

export const clearRecommendationToolRecommendation =
  (): RecommendationQuestionnaireAction => ({
    type: 'CLEAR_RECOMMENDATION_TOOL_RECOMMENDATION',
  });

export const mergeRecommendationPersona = (
  payload: RecommendationPersonaResponse
): RecommendationQuestionnaireAction => ({
  type: 'MERGE_RECOMMENDATION_TOOL_PERSONA',
  payload,
});

export const clearRecommendationPersona =
  (): RecommendationQuestionnaireAction => ({
    type: 'CLEAR_RECOMMENDATION_TOOL_PERSONA',
  });

export const mergeRecommendationDetails = (
  payload: RecommendationDetails
): RecommendationQuestionnaireAction => ({
  type: 'MERGE_RECOMMENDATION_TOOL_DETAILS',
  payload,
});

export const mergeRecommendationAnswers = (
  payload: RecommendationSnapshotResponse['answers']
): RecommendationQuestionnaireAction => ({
  type: 'MERGE_RECOMMENDATION_TOOL_ANSWERS',
  payload,
});

export const getRecommendationToolMetas = (
  payload: unknown
): RecommendationQuestionnaireAction => ({
  type: 'GET_RECOMMENDATION_TOOL_METAS',
  payload,
});

const REQUEST_TYPE = 'RECOMMENDATION_TOOL';

export const sendQuestionnaire =
  (data: RecommendationSnapshotRequest) =>
  async (dispatch: RecommendationToolDispatch) => {
    dispatch(setRequestInProcess(true, REQUEST_TYPE));
    try {
      const response = await endpoint.createRecommendationSnapshot(data);
      if ('data' in response) {
        const { recommendation } = response.data;
        dispatch(mergeRecommendationDetails(recommendation));
        dispatch(setRequestInProcess(false, REQUEST_TYPE));
      }
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, REQUEST_TYPE));
    }
  };

export const getRecommendationById =
  (recommendationId: string) =>
  async (dispatch: RecommendationToolDispatch) => {
    const requestType = 'GET_RECOMMENDATION_BY_ID';

    dispatch(setRequestInProcess(true, requestType));

    try {
      const { data } = await endpoint.getRecommendationById(recommendationId);

      if (data) {
        dispatch(mergeRecommendationDetails(data));
      }

      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };
