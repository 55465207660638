import { createElement } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

interface Props {
  path: string;
  component: JSX.Element;
  exact?: boolean;
  isAuthenticated: boolean;
}

export const RouteWithAuthenticationView = ({
  path,
  component,
  isAuthenticated,
  exact = false,
}: Props) => (
  <Route
    path={path}
    exact={exact}
    render={(props: RouteComponentProps<Record<string, string | undefined>>) =>
      isAuthenticated ? (
        createElement(() => component, props)
      ) : (
        <Redirect
          to={{
            pathname: '/account/signin',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
