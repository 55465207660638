import dayjs from 'dayjs';

type IsUnder18FuncSignature = {
  (dateOfBirth?: string): boolean;
  (birthYear?: number): boolean;
};

export const isUnder18: IsUnder18FuncSignature = (
  param?: string | number
): boolean => {
  if (typeof param === 'string') {
    return dayjs().diff(param, 'years') < 18;
  }

  if (typeof param === 'number') {
    return dayjs().year() - param < 18;
  }

  return true;
};
