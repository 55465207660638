import { RegularModal } from '@popsure/dirty-swan';
import ReactMarkdown from 'react-markdown';

import styles from './style.module.scss';

const modalContent = `The Sozialversicherungsnummer, is the German Social Security number.
It's the unique number the german public pension scheme
(the Deutsche Rentenversicherung) uses to identify you.

You receive it after you have signed up for health insurance.
**But it is not related to your health insurance.**

# When will I need this number?

When you first arrive in Germany, your employer will use it
to calculate the deduction in your salary that you contribute
to your public pension. You may also need it when you switch employers.`;

const PersonalInfoSVNRModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => (
  <RegularModal onClose={onClose} isOpen={isOpen}>
    <div className="wmx6 p16">
      <h3 className="p-h3">What's this?</h3>
      <ReactMarkdown className={styles.body} source={modalContent} />
    </div>
  </RegularModal>
);

export default PersonalInfoSVNRModal;
