import { TFunction } from '@getpopsure/i18n-react';
import { getRoutes, Rule } from '@getpopsure/qnr-framework';
import routes from 'constants/routes';
import { useState } from 'react';
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router';
import { useSafeTranslation } from 'shared/i18n';
import {
  SignupQuestionnaire,
  SignupQuestionnaireType,
} from 'SignupQuestionnaire';

import { GettingStarted } from './components/GettingStarted';
import { LegalClaimsAndConsultations, LegalClaimType } from './models';

export const LegalClaimsAndConsultationsComponents = {
  INTRO: GettingStarted,
} as const;

export type ClaimQuestionnaire = SignupQuestionnaireType<
  LegalClaimsAndConsultations,
  'preQuestionnaire',
  typeof LegalClaimsAndConsultationsComponents
>;

const mapClaimType = (
  t: TFunction
): Record<LegalClaimType, { title: string; description: string }> => ({
  BOOK_A_CONSULTATION: t(
    'legal.qnr.claims.claimType.bookConsultation',
    'Book a consultation'
  ),
  LEGAL_CLAIM: {
    title: t('legal.qnr.claims.claimType.submitClaim', 'Add a claim'),
    description: t(
      'legal.qnr.claims.claimType.submitClaim.description',
      'I already have a claim number'
    ),
  },
});

export const getTranslatedQuestionnaire = (
  t: TFunction
): ClaimQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'INTRO',
    groupId: 'preQuestionnaire',
    props: {},
    screen: {
      noMaxWidth: true,
    },
  },
  {
    id: 'claimType',
    required: true,
    type: 'RADIO',
    groupId: 'preQuestionnaire',
    props: {
      mapValue: mapClaimType(t),
    },
    screen: {
      question: 'What would you like to do?',
    },
  },
];

const rules: Rule<LegalClaimsAndConsultations>[] = [];

export const ClaimsAndConsultations = () => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const { policyId } = useParams<{ policyId: string }>();
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState<
    Partial<LegalClaimsAndConsultations>
  >({
    intro: false,
  });
  const { url } = useRouteMatch();

  const onAnswer = <QuestionId extends keyof LegalClaimsAndConsultations>(
    questionId: QuestionId,
    answer: LegalClaimsAndConsultations[QuestionId]
  ) => {
    if (questionId === 'intro') {
      setQuestionnaireAnswers({
        ...questionnaireAnswers,
        intro: true,
      });
    }
    if (questionId === 'claimType' && answer) {
      setQuestionnaireAnswers({
        ...questionnaireAnswers,
        claimType: answer as LegalClaimType,
      });
      if (answer === 'BOOK_A_CONSULTATION') {
        setTimeout(() => {
          history.push(
            generatePath(routes.claims.legal.bookConsultation.path, {
              policyId,
            })
          );
        }, 200);
      }
      if (answer === 'LEGAL_CLAIM') {
        setTimeout(() => {
          history.push(
            generatePath(routes.claims.legal.submitClaim.path, {
              policyId,
            })
          );
        }, 200);
      }
    }
  };

  const questions = getTranslatedQuestionnaire(t);
  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, url),
    rules,
  };

  if (questionnaireAnswers.claimType) {
    return <></>;
  }

  return (
    <Switch>
      <Route
        path={routes.claims.legal.claimsAndConsultations.questionnaire.path}
      >
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: LegalClaimsAndConsultationsComponents,
          }}
          basePath={url}
          questionId="intro"
          featureName="LegalClaimsAndConsultations"
        />
      </Route>
    </Switch>
  );
};
