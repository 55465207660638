import { EuroClaimIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import routes from 'constants/routes';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { generatePath, useHistory } from 'react-router';

export type CompanyHealthClaimType = 'COMPANY_HEALTH_CLAIMS';

export const CompanyHealthClaimButton = ({
  policyData,
}: PolicyDetailsButtonProps) => {
  const history = useHistory();

  const handleOnClick = () =>
    history.push(
      generatePath(routes.claims.companyHealth.path, {
        policyId: policyData.id,
      })
    );

  return (
    <ButtonCell
      title="Submit a claim"
      icon={<EuroClaimIcon noMargin size={24} className="ml16" />}
      onClick={handleOnClick}
    />
  );
};
