import { TFunction } from '@getpopsure/i18n-react';
import { Region } from '@getpopsure/public-models';
import { InsuranceTypes } from 'models/insurances/types';

import { AdditionalInfoType } from '../types';
import { getAdditionalInfo } from './additionalInfo';
import { getUrl } from './getUrl';

interface ConfigReturnType {
  additionalInfo: AdditionalInfoType;
  url?: string;
}

export const getConfig = (
  t: TFunction,
  insuranceType?: InsuranceTypes,
  region?: Region | null
): ConfigReturnType => ({
  url: insuranceType ? getUrl(insuranceType, region) || '' : '',
  additionalInfo: insuranceType
    ? getAdditionalInfo(t, insuranceType) || {}
    : {},
});
