import { RefreshCwIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { useSafeTranslation } from 'shared/i18n';

export const ExpatUpgradePublicPrivateHealthButton = (
  props: PolicyDetailsButtonProps
) => {
  const { t } = useSafeTranslation();
  const { onOpenModal } = props;
  const handleButtonClick = () =>
    onOpenModal('EXPAT_UPGRADE_TO_PRIVATE_PUBLIC');

  return (
    <ButtonCell
      key="UPGRADE_PUBLIC_PRIVATE_HEALTH"
      title={t(
        'myPolicies.policyDetails.upgradeButton.caption',
        'Switch to private or public'
      )}
      icon={<RefreshCwIcon noMargin size={24} className="ml16" />}
      onClick={handleButtonClick}
    />
  );
};
