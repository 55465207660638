import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Toggle } from '@popsure/dirty-swan';
import classnames from 'classnames';

import styles from './ToggleCard.module.scss';

type ToggleCardProps = {
  id: string;
  description: string;
  title: string;
  disabled?: boolean;
  isSelected: boolean;
  onChange: () => void;
  price: number;
  className?: string;
};

export const ToggleCard = ({
  id,
  description,
  disabled = false,
  isSelected,
  onChange,
  price,
  title,
  className,
}: ToggleCardProps) => {
  const toggleKey = id;
  return (
    <div
      className={`p32 bg-white mt16 br8 d-flex jc-between gap16 ${className} ${
        styles.content
      } ${isSelected ? styles.selected : ''}`}
    >
      <div>
        <h4 className={classnames('p-h4 d-flex f-wrap', styles.heading)}>
          {title}
        </h4>
        <p className={classnames('p-p mt8 mb16', styles.description)}>
          {description}
        </p>
      </div>
      <div className={classnames('d-flex', styles.toggleContainer)}>
        <Toggle
          bordered={false}
          classNames={{ label: styles.toggleLabel }}
          disabled={disabled}
          onChange={onChange}
          options={{ [toggleKey]: '' }}
          value={isSelected ? [toggleKey] : []}
        />
        <h3 className={classnames('p-h3', styles.transition)}>
          + {englishFormattedEuroCurrency(price)}
        </h3>
      </div>
    </div>
  );
};
