import { TFunction } from '@getpopsure/i18n-react';
import {
  AccidentCarIcon,
  BatteryIcon,
  BikeBicycleIcon,
  CalendarIcon,
  ChainBikeBrokenIcon,
  ClockTimeIcon,
  FireIcon,
  LoaderIcon,
  PaintCosmeticIcon,
  SearchIcon,
  TrailerPartsIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const bike = (t: TFunction): AdditionalInfoType => ({
  price: 4.48,
  description: t(
    'policyModal.bike.description',
    'Protection for both new and old bikes, whether they’re e-bikes or regular bikes. It covers theft (including parts and battery) as well as damage from wear and tear, vandalism, and production errors.'
  ),
  accordions: [
    {
      title: t('policyModal.bike.title1', "What's covered?"),
      items: [
        {
          id: 'covered-1',
          icon: <LoaderIcon size={24} />,
          question: t('policyModal.bike.covered1.question', 'Theft'),
          answer: t(
            'policyModal.bike.covered1.answer',
            'Covers loss from theft, robbery and burglary, including from car roof racks and bicycle stands, as long as the bike is locked to a fixed object (not only to itself).'
          ),
        },
        {
          id: 'covered-2',
          icon: <AccidentCarIcon size={24} />,
          question: t('policyModal.bike.covered2.question', 'Damage'),
          answer: t(
            'policyModal.bike.covered2.answer',
            'Covers repair costs arising from accidents, operating and production errors.'
          ),
        },
        {
          id: 'covered-3',
          icon: <TrailerPartsIcon size={24} />,
          question: t(
            'policyModal.bike.covered3.question',
            'Parts and accessories'
          ),
          answer: t(
            'policyModal.bike.covered3.answer',
            'Covers theft or damage of parts connected to the bike/e-bike that are included in the policy.'
          ),
        },
        {
          id: 'covered-4',
          icon: <ChainBikeBrokenIcon size={24} />,
          question: t('policyModal.bike.covered4.question', 'Wear & tear'),
          answer: t(
            'policyModal.bike.covered4.answer',
            'Covers damages due to wear and tear on bikes less than 5 years old, including brakes and tires.'
          ),
        },
        {
          id: 'covered-5',
          icon: <FireIcon size={24} />,
          question: t('policyModal.bike.covered5.question', 'Vandalism'),
          answer: t(
            'policyModal.bike.covered5.answer',
            'Protection against intentional damage and destruction by unknown third parties'
          ),
        },
        {
          id: 'covered-6',
          icon: <BatteryIcon size={24} />,
          question: t(
            'policyModal.bike.covered6.question',
            'E-bike battery protection'
          ),
          answer: t(
            'policyModal.bike.covered6.answer',
            'Insured against theft, damage, and wear & tear (only if performance drops below 65% in the first 3 years).'
          ),
        },
      ],
    },
    {
      title: t('policyModal.bike.title2', "What's not covered"),
      items: [
        {
          id: 'not-covered-1',
          icon: <BikeBicycleIcon size={24} />,
          question: t(
            'policyModal.bike.notCovered1.question',
            'Bikes used commercially'
          ),
          answer: t(
            'policyModal.bike.notCovered1.answer',
            'Bikes used for commercial purposes, such as delivery or other jobs, are not eligible for coverage.'
          ),
        },
        {
          id: 'not-covered-2',
          icon: <SearchIcon size={24} />,
          question: t(
            'policyModal.bike.notCovered2.question',
            'Bike inspection fees'
          ),
          answer: t(
            'policyModal.bike.notCovered2.answer',
            'Repairs for parts and labor are included; however, inspection fees from bike shops are not covered.'
          ),
        },
        {
          id: 'not-covered-3',
          icon: <BatteryIcon size={24} />,
          question: t(
            'policyModal.bike.notCovered3.question',
            'Scooters or e-bikes without pedal assistance'
          ),
          answer: t(
            'policyModal.bike.notCovered3.answer',
            'Scooters or e-bikes powered solely by electric motors that require registration are not covered.'
          ),
        },
        {
          id: 'not-covered-4',
          icon: <PaintCosmeticIcon size={24} />,
          question: t(
            'policyModal.bike.notCovered4.question',
            'Cosmetic repairs'
          ),
          answer: t(
            'policyModal.bike.notCovered4.answer',
            'Cosmetic repairs like scratches or paint touch-ups aren’t covered. Only functional damage is included.'
          ),
        },
      ],
    },
  ],
  details: [
    {
      icon: ClockTimeIcon,
      title: t('policyModal.bike.details.title1', 'No waiting periods'),
      description: t(
        'policyModal.bike.details.description1',
        'You can use your insurance right away'
      ),
    },
    {
      icon: CalendarIcon,
      title: t(
        'policyModal.bike.details.title2',
        '1 year contract, then monthly cancellation'
      ),
      description: t(
        'policyModal.bike.details.description2',
        'After the first year, you can cancel at any time and the policy will terminate at the end of the current month.'
      ),
    },
  ],
  buttonText: t('policyModal.bike.buttonText', 'Continue to quote'),
});
