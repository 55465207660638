import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { Button } from '@popsure/dirty-swan';
import classnames from 'classnames';
import { EditableSlider } from 'components/SliderWithModal/EditableSlider';
import { createNewQuote, createPreQuote } from 'features/life/actions';
import {
  getMaxCoverage,
  getMaxTerm,
  Life,
  LifePreQuoteDispatch,
  MIN_COVERAGE,
  MIN_TERM,
} from 'features/life/models';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getRequestError, getRequestInProcess } from 'selectors/requests';
import { QuotePage as SignupQuotePage } from 'SignupQuestionnaire/components/QuotePage';
import { QuotePageConfiguration } from 'SignupQuestionnaire/components/QuotePage/QuotePage';

import CoverageModal from './coverageModal';
import styles from './QuotePage.module.scss';
import TermModal from './termModal';

type Modal = 'TERM' | 'COVERAGE';
type FetchStatus = 'IDLE' | 'DISABLED' | 'FETCHING' | 'ERROR';

export const QuotePage = (
  props: {
    config: QuotePageConfiguration<Life['quote']>;
  } & QuestionnaireFormProps<Life, Life['quote']>
) => {
  const { questionnaireAnswers, onSubmitValue } = props;
  const maxCoverage = getMaxCoverage(questionnaireAnswers);
  const maxTerm = getMaxTerm(questionnaireAnswers);
  const [state, setState] = useState({
    questionnaireId: questionnaireAnswers.questionnaireId ?? undefined,
    quote: questionnaireAnswers.quote ?? undefined,
    quoteId: questionnaireAnswers.quoteId ?? undefined,
  });

  const [term, setTerm] = useState(
    questionnaireAnswers?.quote?.coverageInfo?.duration ?? maxTerm
  );
  const [coverage, setCoverage] = useState(
    questionnaireAnswers?.quote?.coverageInfo?.coverage ?? maxCoverage
  );
  const [openModal, setOpenModal] = useState<Modal | null>(null);
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>('IDLE');

  const loading = useSelector((appState: AppState) =>
    getRequestInProcess(appState, 'CREATE_QUOTE')
  );
  const error = useSelector((appState: AppState) =>
    getRequestError(appState, 'CREATE_QUOTE')
  );
  const dispatch = useDispatch<LifePreQuoteDispatch>();

  useEffect(() => {
    const run = async () => {
      const { result } = await dispatch(createPreQuote());
      if (result) {
        setState(result);
      }
    };
    run();
  }, [dispatch]);

  useEffect(() => {
    if (fetchStatus === 'FETCHING') {
      handleCreateNewQuote();
    }

    if (fetchStatus === 'FETCHING' && !loading) {
      setFetchStatus('IDLE');
    }

    if (error) {
      setFetchStatus('ERROR');
    }
  }, [fetchStatus, error, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateNewQuote = async () => {
    if (!state?.quote?.questionnaireId) {
      throw new Error(
        '[Life] Questionnaire answers are missing for fetching price'
      );
    }

    const { result } = await dispatch(
      createNewQuote(
        { ...questionnaireAnswers, duration: term, coverage },
        state.quote.questionnaireId
      )
    );
    if (result) {
      setState(result);
    }
  };

  return (
    <>
      <TermModal
        isOpen={openModal === 'TERM'}
        onClose={() => setOpenModal(null)}
        onChange={(changeTerm: number) => {
          setFetchStatus('FETCHING');
          setTerm(changeTerm);
        }}
        term={term}
        maxTerm={maxTerm}
      />
      <CoverageModal
        isOpen={openModal === 'COVERAGE'}
        onClose={() => setOpenModal(null)}
        onChange={(changeCoverage: number) => {
          setFetchStatus('FETCHING');
          setCoverage(changeCoverage);
        }}
        coverage={coverage}
        maxCoverage={maxCoverage}
      />
      <SignupQuotePage {...props}>
        <div className={`w100 br8 ${styles.container}`}>
          <div
            className={classnames('w100 ai-center', styles.contentContainer)}
          >
            <div
              className={`w100 mr24 d-flex fd-column ai-center jc-center ${styles.pricingContainer}`}
            >
              {fetchStatus === 'ERROR' ? (
                <div className="ta-center p-p tc-grey-600">
                  <div>Sorry, something went wrong.</div>
                  <div>
                    Please{' '}
                    <button
                      className={`p-a c-pointer fw-bold p-p ${styles.errorButton}`}
                      onClick={() => setFetchStatus('FETCHING')}
                      type="button"
                    >
                      try again
                    </button>{' '}
                    shortly.
                  </div>
                </div>
              ) : (
                <div
                  className={classnames(
                    'p-container w100 d-flex fd-column ai-center jc-center',
                    styles.pricingBox,
                    {
                      [styles.buttonDisabled]: fetchStatus !== 'IDLE',
                    }
                  )}
                >
                  <div className="p--serif p-h1--xl tc-primary-500">
                    {!state?.quote?.price || loading ? (
                      <div className="ds-spinner ds-spinner__l" />
                    ) : (
                      englishFormattedEuroCurrency(state?.quote.price)
                    )}
                  </div>
                  <div className="tc-grey-500">per month</div>
                </div>
              )}
            </div>
            <div
              className={`w100 jc-center ai-center ${styles.sliderContainer}`}
            >
              <div className="p-container wmx9 br8 bg-white d-flex fd-column w100 jc-center ai-center c-gap16 r-gap48 mt40 px32 py24">
                <div className="d-flex fd-column jc-center ai-center ta-center p-p">
                  <div className="p-h4 mr8">Term (years)</div>
                  <p className="tc-grey-700">
                    We recommend getting coverage until your mortgage is paid
                    off or your youngest child has completed school or
                    university (whichever comes later).
                  </p>
                </div>
                <EditableSlider
                  options={{
                    darkBG: true,
                    bubbleVariant: true,
                    noFormatting: true,
                  }}
                  min={MIN_TERM}
                  max={maxTerm}
                  value={term}
                  setValue={setTerm}
                  onChangeStart={() => setFetchStatus('DISABLED')}
                  onChangeEnd={() => setFetchStatus('FETCHING')}
                  step={1}
                  label="Term"
                  onEditClick={() => setOpenModal('TERM')}
                />
              </div>
              <div className="p-container wmx9 bg-white d-flex fd-column w100 jc-center ai-center c-gap16 r-gap48 mt40 px32 py24">
                <div className="d-flex br8 fd-column jc-center ai-center ta-center p-p">
                  <div className="p-h4 mr8">Coverage amount</div>
                  <p className="tc-grey-700">
                    In most cases, 3 - 5x your gross annual salary is enough to
                    cover all basic expenses for a couple of years and will
                    allow them to recover from their loss and will provide a
                    financial cushion while they recover from their loss.
                  </p>
                </div>
                <EditableSlider
                  options={{
                    darkBG: true,
                    bubbleVariant: true,
                    shortenNumbers: true,
                  }}
                  min={MIN_COVERAGE}
                  max={maxCoverage}
                  value={coverage}
                  setValue={setCoverage}
                  onChangeStart={() => setFetchStatus('DISABLED')}
                  onChangeEnd={() => setFetchStatus('FETCHING')}
                  step={5_000}
                  label="Coverage"
                  onEditClick={() => setOpenModal('COVERAGE')}
                />
              </div>
            </div>
            <div className="p-container wmx9 ai-center jc-center d-flex fd-column mt48">
              <Button
                className="wmn4"
                onClick={() =>
                  state.quote &&
                  onSubmitValue(state.quote, {
                    questionnaireId: state.questionnaireId,
                    quoteId: state.quoteId,
                  })
                }
                data-cy="quote-continue"
              >
                Continue
              </Button>
              <div className="wmx4 p-p--small mt16 ai-center jc-center ta-center d-flex tc-grey-500">
                Possible exclusions and/or surcharges could apply based on the
                results of your health and lifestyle questionnaire.
              </div>
            </div>
          </div>
        </div>
      </SignupQuotePage>
    </>
  );
};
