import { GoogleOAuthProvider as GoogleOAuthProviderComponent } from '@react-oauth/google';
import { ReactNode } from 'react';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID_OAUTH ?? '';

export const GoogleOAuthProvider = ({ children }: { children: ReactNode }) => {
  if (window.Cypress || !clientId) {
    return <>{children}</>;
  }

  return (
    <GoogleOAuthProviderComponent clientId={clientId}>
      {children}
    </GoogleOAuthProviderComponent>
  );
};
