import { TFunction } from '@getpopsure/i18n-react';
import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkAction } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint from 'shared/api';
import { CreateQuoteDispatch } from 'SignupQuestionnaire/actions/account';

import {
  CreateQuoteReturnType,
  ZQuote,
  ZSubmittableDogQuestionnaire,
} from '../models';

export const createPostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'dog'> | RequestAction
  > =>
  async (dispatch: CreateQuoteDispatch<'dog'>, getState: () => AppState) => {
    const questionnaire = getGenericQuestionnaire(getState()).dog || {};

    try {
      const quoteValidation = ZQuote.safeParse(questionnaire.quote);

      if (!quoteValidation.success) {
        throw new Error(
          `[Dog - Submission] Questionnaire quote errors:${quoteValidation.error.toString()}`
        );
      }

      const validatedQuote = quoteValidation.data;

      const validationResult = ZSubmittableDogQuestionnaire.safeParse({
        ...questionnaire,
        isDeductible: validatedQuote.isDeductible,
        deductibleAmount: validatedQuote.deductibleAmount,
      });

      if (!validationResult.success) {
        throw new Error(
          `[Dog - Submission] Questionnaire answer validation errors:${validationResult.error.toString()}`
        );
      }
      const validatedAnswers = validationResult.data;

      const {
        data: { id: quoteId, price },
      } = await endpoint.createDogQuotes(validatedQuote);

      dispatch(setRequestInProcess(true, 'DOG_SUBMIT_INFO'));

      const {
        email,
        name: { firstName, lastName },
        dateOfBirth,
      } = validatedAnswers.personalInfo;

      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            dateOfBirth,
            email,
          })
        );
      }

      const {
        data: { id: questionnaireId },
      } = await endpoint.submitDogQuoteInformation(validationResult.data, {
        labels: {
          title: {
            personalInfo: {
              name: "What's your name?",
              email: "What's your email address?",
              dateOfBirth: 'When were you born?',
              address: "What's your address?",
              isLivingInGermany: 'Are you currently living in Germany?',
              numberOfPreviousClaims:
                'How many dog liability insurance claims have you made in the last 3 years?',
              previousClaimsTotalAmount:
                "What's the total amount you claimed in the last 3 years?",
              isPureBreed: 'Is your dog mixed or purebred?',
              pureBreedName: 'What breed is your dog?',
              dogName: "What's your dog's name?",
              isInterestedInDogHealthInsurance:
                'Dog health insurance is coming soon. Want to be informed?',
            },
            tariffInfo: {
              startDate: 'When would you like to start your coverage?',
              isDeductible: 'Is insurance deductible?',
              deductibleAmount:
                'The deductible is the amount you pay out of pocket per insured event before the insurance starts to pay. (in €)',
            },
          },
          answers: {
            personalInfo: {
              numberOfPreviousClaims: {
                NONE: '0',
                ONE: '1',
                TWO_OR_MORE: '2 or more',
              },
            },
            tariffInfo: {},
          },
        },
        questionOrder: {
          personalInfo: [
            'isLivingInGermany',
            'numberOfPreviousClaims',
            'previousClaimsTotalAmount',
            'isPureBreed',
            'pureBreedName',
            'dogName',
            'email',
            'name',
            'dateOfBirth',
            'address',
            'isInterestedInDogHealthInsurance',
          ],
          tariffInfo: ['startDate', 'isDeductible', 'deductibleAmount'],
        },
      });
      dispatch(
        storeGenericQuestionnaireAnswer('dog', {
          quoteId,
          questionnaireId,
          quote: { ...validatedQuote, id: quoteId, price },
        })
      );

      await generateCheckoutDocuments(
        questionnaireId,
        quoteId,
        'DOG_LIABILITY',
        t
      );

      dispatch(setRequestInProcess(false, 'DOG_SUBMIT_INFO'));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, 'DOG_SUBMIT_INFO'));
      Sentry.captureException(
        `[Dog Review] Failed to submit with following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };
