import swapKeyValue from 'shared/util/swapKeyValue';

export type EligibilityStatus =
  | 'ELIGIBLE'
  | 'PARTIAL'
  | 'INELIGIBLE'
  | 'MORE_INFO_REQUIRED';

export type RecommendationV2 = {
  id: string;
  data: RecommendationData;
  identifierString: string;
  snapshotId: string;
  createdAt: string;
};

export type RecommendationCardType = 'INELIGIBLE' | 'PARTIAL' | 'PRO' | 'CON';

export type RecommendationCard = {
  key: string;
  type: RecommendationCardType;
};

export const quickAdvice = [
  'NEEDED',
  'STRONGLY_SUGGESTED',
  'WORTH_CONSIDERING',
  'NOT_RELEVANT',
] as const;

export type TQuickAdvice = typeof quickAdvice[number];

export type RecommendationEntryOptions = {
  advice?: TQuickAdvice;
  amortizationOfBikeInYears?: number;
  recommendedCoverage?: number;
  riskArea: number;
};

export type QuickAssessment = {
  price?: number;
  advice?: TQuickAdvice;
};

export type RecommendationEntry = {
  key: RecommendationType;
  cards: RecommendationCard[];
  price: number;
  eligibilityStatus: EligibilityStatus;
  options: RecommendationEntryOptions;
  quickAssessment: QuickAssessment;
};

export type RecommendationData = RecommendationEntry[];

export type CollapsibleCardType = EligibilityStatus | 'PRO' | 'CON';

// Mirroring the backend return type: https://github.com/getPopsure/recommendation-tool-backend/blob/main/src/modules/recommendation/recommendation-engine/recommendation-engine.types.ts
export type RecommendationType =
  | 'PUBLIC_HEALTH'
  | 'PRIVATE_HEALTH'
  | 'EXPAT_HEALTH'
  | 'EHIC_HEALTH'
  | 'LIABILITY'
  | 'DENTAL'
  | 'LIFE'
  | 'HOUSEHOLD'
  | 'LEGAL'
  | 'BIKE'
  | 'DISABILITY'
  | 'QUICK_RECOMMENDATION'
  | 'CAR'
  | 'DOG_LIABILITY'
  | 'TRAVEL';

export type VerticalCategory =
  | Exclude<
      RecommendationType,
      'PUBLIC_HEALTH' | 'PRIVATE_HEALTH' | 'EXPAT_HEALTH' | 'EHIC_HEALTH'
    >
  | 'HEALTH_INSURANCE';

export const AllowedInsuranceTypes = [
  'health',
  'liability',
  'life',
  'dental',
  'household',
  'legal',
  'bike',
  'quickRecommendation',
  'disability',
  'car',
  'dogLiability',
  'travel',
] as const;

export type TAllowedInsuranceTypes = typeof AllowedInsuranceTypes[number];

export const AllowedQuestionnaireTypes = [
  'health',
  'liability',
  'life',
  'dental',
  'household',
  'legal',
  'bike',
  'quickRecommendation',
] as const;

export type QuestionnaireTypes = typeof AllowedQuestionnaireTypes[number];

export type AllowedWelcomeParams = TAllowedInsuranceTypes | 'intro';

export const categoryByInsuranceType: Record<
  TAllowedInsuranceTypes,
  VerticalCategory
> = {
  health: 'HEALTH_INSURANCE',
  liability: 'LIABILITY',
  life: 'LIFE',
  dental: 'DENTAL',
  household: 'HOUSEHOLD',
  legal: 'LEGAL',
  bike: 'BIKE',
  quickRecommendation: 'QUICK_RECOMMENDATION',
  disability: 'DISABILITY',
  car: 'CAR',
  dogLiability: 'DOG_LIABILITY',
  travel: 'TRAVEL',
};

export const insuranceTypeByCategory: Record<
  VerticalCategory,
  TAllowedInsuranceTypes
> = swapKeyValue<
  Record<TAllowedInsuranceTypes, VerticalCategory>,
  VerticalCategory
>(categoryByInsuranceType);

export const AllowedCategoryGroups = [
  'basic',
  'additional',
  'notNeeded',
] as const;

export type TAllowedCategoryGroups = typeof AllowedCategoryGroups[number];

export type VerticalWithAdvice = {
  id: VerticalCategory;
  remapKey?: RecommendationType;
  disabled?: boolean;
};

export const enabledVerticals: Array<VerticalWithAdvice> = [
  {
    id: 'HEALTH_INSURANCE',
    remapKey: 'PRIVATE_HEALTH',
  },
  {
    id: 'LIABILITY',
  },
  {
    id: 'LIFE',
  },
  {
    id: 'DENTAL',
  },
  {
    id: 'HOUSEHOLD',
  },
  {
    id: 'LEGAL',
  },
  {
    id: 'BIKE',
  },
  {
    id: 'CAR',
  },
  {
    id: 'DOG_LIABILITY',
  },
  {
    id: 'DISABILITY',
  },
  {
    id: 'TRAVEL',
  },
];
