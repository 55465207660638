import { trackStructuredEvent } from '@getpopsure/analytics';
import { calendly } from '@getpopsure/private-constants';
import featherLogoIcon from 'icons/feather-icon.svg';
import { useSafeTranslation } from 'shared/i18n';

import policyIcon from './policy.svg';
import styles from './style.module.scss';

interface Props {
  bookACallGACategory?: string;
  title?: string;
  description?: string;
  secondaryAction?: React.ReactNode;
  secondaryActionLink?: string;
  showSecondaryIcon?: boolean;
  openInNewTab?: boolean;
  bookACallUrl?: string;
  bookACallAction?: () => void;
  bookACallActionTitle?: string;
  showBookCallAction?: boolean;
}

const MoreQuestionsSection = (props: Props) => {
  const { t } = useSafeTranslation();

  const {
    bookACallGACategory = '',
    title,
    description,
    secondaryAction,
    secondaryActionLink,
    showSecondaryIcon,
    openInNewTab,
    bookACallUrl,
    bookACallAction,
    bookACallActionTitle,
    showBookCallAction = true,
  } = props;
  const handleBookACall = () => {
    trackStructuredEvent({
      category: bookACallGACategory,
      action: 'CLICKED_BOOK_AN_APPOINTMENT',
    });
    window.Calendly.initPopupWidget({
      url: bookACallUrl ?? calendly.genericHelp,
    });
  };

  const hasSecondaryAction = !!secondaryActionLink;

  return (
    <section className={`${styles['section-bottom']}`}>
      <div className={`p-body ${styles['section-bottom-container']}`}>
        <img src={featherLogoIcon} alt="logo" className={styles['logo-icon']} />
        <div className="p--serif p-h1 ta-center mt24">
          {title ||
            t('components.morequestionssection.title', 'Have more questions?')}
        </div>
        <div className="p-p ta-center wmx8 mt24">
          {description ||
            t(
              'components.morequestionssection.description',
              'Reach out to us for more information or read our policy statement'
            )}
        </div>
        <div
          className={`mt24 wmn3 ${styles['statement-links-container']} ${
            hasSecondaryAction && showBookCallAction
              ? ''
              : styles['statement-links-container--center']
          }`}
        >
          {showBookCallAction && (
            <button
              className="ds-interactive-component p-a fw-bold"
              onClick={bookACallAction ?? handleBookACall}
              type="button"
            >
              {bookACallActionTitle ||
                t('components.morequestionssection.bookacall', 'Book a call')}
            </button>
          )}

          {hasSecondaryAction && (
            <>
              {showBookCallAction && <div className={styles.divider} />}
              <a
                className={`p-a fw-bold ${styles['policy-link']}`}
                href={secondaryActionLink}
                {...(openInNewTab && {
                  target: '_blank',
                  rel: 'noopener noreferrer',
                })}
              >
                {showSecondaryIcon && (
                  <img className="mr8" src={policyIcon} alt="purple document" />
                )}
                {secondaryAction ||
                  t(
                    'components.morequestionssection.secondaryaction.default',
                    'Policy statement'
                  )}
              </a>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default MoreQuestionsSection;
