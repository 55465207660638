import { insurance } from '@getpopsure/private-constants';
import {
  DeductibleForTariff,
  defaultAddonsLookup,
  EmploymentStatus,
  getAgeFromBirthYear,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { getContributionPrices } from '@getpopsure/public-health-insurance-pricing-engine';
import { Country } from '@getpopsure/public-models';
import {
  employmentStatusToLegacyEmploymentStatusMapping,
  PrivatePreSignup,
  privateToPublicEmploymentStatusMapping,
  ResidencePermitType,
  Tariff,
} from 'features/privateHealthPreSignup/models';
import { Name } from 'models/user';

export const getPublicHealthContribution = ({
  birthYear,
  income,
  employmentStatus,
}: {
  birthYear: number;
  income: number;
  employmentStatus: EmploymentStatus;
}) => {
  const publicEmploymentStatus =
    privateToPublicEmploymentStatusMapping[employmentStatus];

  const age = getAgeFromBirthYear(birthYear);

  const contributionPrices = getContributionPrices({
    provider: 'TK',
    age,
    monthlyIncome: income / 12,
    employmentStatus: publicEmploymentStatus,
  });

  const selfEmployedContributionPrices = getContributionPrices({
    provider: 'TK',
    age,
    monthlyIncome: income / 12,
    employmentStatus: 'SELF_EMPLOYED',
  });

  if (!contributionPrices || !selfEmployedContributionPrices) return null;

  const employerContribution =
    publicEmploymentStatus === 'EMPLOYED'
      ? selfEmployedContributionPrices.total - contributionPrices.total
      : 0;

  return {
    tariffName: 'Public',
    ...contributionPrices,
    employerContribution,
    tariffTotal: contributionPrices.total + employerContribution,
  };
};

export const shouldShowDependentVersion = ({
  dependentTypes,
  familyCoverage,
  spouseHasOwnIncome,
}: Partial<PrivatePreSignup>) => {
  if (
    familyCoverage !== 'YES' ||
    (dependentTypes?.length === 1 &&
      dependentTypes?.includes('SPOUSE') &&
      spouseHasOwnIncome)
  ) {
    return false;
  }
  return true;
};

export const constructPrivateHealthUrl = ({
  name,
  email,
  citizenship,
  residencyPermitType,
  employmentStatus,
  income,
  tariff,
  deductible,
  sickDayPayout,
}: {
  name?: Name;
  email?: string;
  citizenship?: Country[];
  residencyPermitType?: ResidencePermitType;
  employmentStatus: EmploymentStatus;
  income: number;
  tariff: Tariff;
  deductible: DeductibleForTariff<Tariff>;
  sickDayPayout?: number;
}): string => {
  const baseURL = `${insurance.privateHealth.signup}?`;
  const urlParams = new URLSearchParams({});

  const addOns = defaultAddonsLookup[tariff];

  const addOnsString = addOns.length > 0 ? String(addOns) : '';
  // When creating the URL to pass on data to the private signup,
  // we need to map to the LegacyEmploymentStatus as it doesn't support the new EmploymentStatus type yet
  // TODO(KK): Remove once support has been added to private signup
  const legacyEmploymentStatus =
    employmentStatusToLegacyEmploymentStatusMapping[employmentStatus];

  urlParams.set('tariff', tariff);
  urlParams.set('deductible', deductible);

  urlParams.set('addOns', addOnsString);

  if (addOns.includes('KT') && sickDayPayout) {
    urlParams.set('sickDayPayout', String(sickDayPayout));
  }

  urlParams.set('employmentStatus', legacyEmploymentStatus);
  urlParams.set('income', String(income));

  if (name) {
    urlParams.set('firstName', name?.firstName);
    urlParams.set('lastName', name?.lastName);
  }

  if (email) {
    urlParams.set('email', email);
  }

  if (citizenship) {
    const countryCodesOnly = citizenship.map(({ code }) => code);
    urlParams.set('citizenship', String(countryCodesOnly));
  }

  if (residencyPermitType) {
    urlParams.set('residencyPermitType', residencyPermitType);
  }

  if (employmentStatus === 'EMPLOYED_OUTSIDE_OF_GERMANY') {
    urlParams.set('employedOutsideGermany', String(true));
  }

  if (employmentStatus === 'EMPLOYED_IN_GERMANY') {
    urlParams.set('employedOutsideGermany', String(false));
  }

  return `${baseURL}${String(urlParams)}`;
};
