const swapKeyValue = <T extends Record<string, S>, S extends string>(
  values: T
) => {
  const formatedValues = {} as Record<string, unknown>;

  Object.entries(values).forEach(([key, value]) => {
    formatedValues[value] = key;
  });

  return formatedValues as { [K in keyof T as T[K]]: K };
};

export default swapKeyValue;
