import type { UploadedFile as BaseUploadedFile } from '@popsure/dirty-swan';
import { APIResponseError } from 'models/error';
import { useContext, useEffect, useState } from 'react';
import { formatErrorMessageFromError } from 'selectors/requests';
import api from 'shared/api';
import { getFileType } from 'shared/util/getFileType';

import { generateSignedUploadUrl } from '../api/taskEngine.api';
import { TaskEngineContext } from '../utils/taskEngine.context';

export interface UploadedFile extends BaseUploadedFile {
  type: string;
}

export const useFileUpload = () => {
  const {
    files: { setUploadedFiles, uploadedFiles },
  } = useContext(TaskEngineContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const uploadComplete: boolean =
      uploadedFiles.filter(({ progress, error }) => progress === 100 || error)
        .length === uploadedFiles.length;
    if (uploadComplete) {
      setLoading(false);
    }
  }, [uploadedFiles, setLoading]);

  const uploadFile = (files: File[]) => {
    setLoading(true);

    files.forEach(async (file: File) => {
      let fileId: string | null = null;

      try {
        const {
          data: { url, blobName: id },
        } = await generateSignedUploadUrl(file.name);

        fileId = id;

        await api.uploadFile(file, file.type, url, (p) => {
          const fileProgress = (p.loaded / p.total) * 100;
          const fileType = getFileType(file.name);
          const newUploadedFile: UploadedFile = {
            id: fileId ?? '',
            type: fileType,
            name: file.name,
            progress: fileProgress,
          };
          setUploadedFiles?.((prev: UploadedFile[]) => {
            const filteredFiles = prev.filter(
              ({ id: fileIdToCheckAgainst, error }) =>
                fileIdToCheckAgainst !== fileId || error
            );
            return [...filteredFiles, { ...newUploadedFile }];
          });
        });
      } catch (error) {
        const erroredFile: UploadedFile = {
          id: fileId ?? '',
          type: getFileType(file.name),
          name: file.name,
          progress: 0,
          error: formatErrorMessageFromError(error as APIResponseError),
        };

        setUploadedFiles?.((prev: UploadedFile[]) => {
          const filteredFiles = prev.filter(({ id }) => id !== fileId);
          return [...filteredFiles, { ...erroredFile }];
        });
      }
    });
  };

  const removeFile = (fileId: string) => {
    const filteredUploadedFiles = uploadedFiles.filter(
      ({ id: uploadedFileId }) => uploadedFileId !== fileId
    );
    setUploadedFiles?.([...filteredUploadedFiles]);
  };

  const isSubmitValid =
    uploadedFiles.length > 0 &&
    uploadedFiles.filter(({ progress }) => progress === 100).length ===
      uploadedFiles.length;

  return {
    uploadFile,
    uploadedFiles,
    loading,
    removeFile,
    isSubmitValid,
  };
};
