import { Input } from '@popsure/dirty-swan';
import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, HTMLInputTypeAttribute, useState } from 'react';

import QuestionnaireWrapper from '../QuestionnaireWrapper';

interface Props
  extends QuestionnaireFormProps<string>,
    QuestionnaireWrapperProps {
  validate?: (value: string) => boolean;
  type?: HTMLInputTypeAttribute;
}

export const InputForm = ({
  value,
  onSubmitValue,
  question,
  description,
  additionalInfo,
  buttonText,
  featureName,
  header,
  error,
  loading,
  questionClassName = '',
  placeholder,
  skipDetails,
  icon,
  validate,
  type = 'text',
}: Props) => {
  const [inputValue, setInputValue] = useState<string>(value ?? '');

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!inputValue) {
      throw new Error(
        `[${featureName}] Input value is missing for submission for question: ${question}`
      );
    }
    onSubmitValue(inputValue);
  };

  const isValid = inputValue && (validate ? validate(inputValue) : true);

  return (
    <QuestionnaireWrapper
      onSubmit={handleSubmit}
      icon={icon}
      header={header}
      question={question}
      questionClassName={questionClassName}
      description={description}
      error={error}
      loading={loading}
      validForSubmission={!!isValid}
      buttonText={buttonText}
      skipDetails={skipDetails}
      additionalInfo={additionalInfo}
    >
      <Input
        placeholder={placeholder}
        type={type}
        required
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        data-cy="input-text"
      />
    </QuestionnaireWrapper>
  );
};
