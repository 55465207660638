import { RecommendationQuestionnaireAction } from 'constants/actions';
import type { RecommendationToolInitialState } from 'models/recommendationTool/';
import { Reducer } from 'redux';

export const defaultState: RecommendationToolInitialState = {
  answers: {},
  showIntroPage: undefined,
};

const recommendationTool: Reducer<
  RecommendationToolInitialState,
  RecommendationQuestionnaireAction
> = (state = defaultState, action) => {
  switch (action.type) {
    case 'RECOMMENDATION_TOOL_ANSWER_QUESTION':
      return {
        ...state,
        answers: {
          ...state.answers,
          ...action.question,
        },
      };
    case 'CLEAR_RECOMMENDATION_TOOL':
      return {
        ...state,
        answers: {},
        recommendation: undefined,
      };
    case 'MERGE_RECOMMENDATION_TOOL_PERSONA':
      return {
        ...state,
        persona: {
          ...action.payload,
        },
      };
    case 'CLEAR_RECOMMENDATION_TOOL_PERSONA':
      return {
        ...state,
        persona: {},
      };
    case 'MERGE_RECOMMENDATION_TOOL_DETAILS':
      return {
        ...state,
        recommendation: action.payload,
      };
    case 'MERGE_RECOMMENDATION_TOOL_ANSWERS':
      return {
        ...state,
        answers: action.payload,
      };
    case 'CLEAR_RECOMMENDATION_TOOL_RECOMMENDATION':
      return {
        ...state,
        recommendation: undefined,
      };
    case 'SHOW_RECOMMENDATION_TOOL_INTRO':
      return {
        ...state,
        showIntroPage: action.payload,
      };
    case 'GET_RECOMMENDATION_TOOL_METAS':
      return {
        ...state,
        metas: action.payload,
      };
    default:
      return state;
  }
};

export default recommendationTool;
