import { BottomOrRegularModal } from '@popsure/dirty-swan';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import client from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';

import addToAppleWalletButton from '../../assets/addToAppleWallet.svg';
import addToGoogleWalletButton from '../../assets/addToGoogleWallet.svg';
import { PolicySingleModalProps } from './PolicySingleModal.models';

export const AddToWalletModal = (props: PolicySingleModalProps) => {
  const [applePassURL, setApplePassURL] = useState<string>();
  const [googlePassURL, setGooglePassURL] = useState<string>();
  const [error, setError] = useState<string>();
  const {
    onClose,
    policyData: { id },
  } = props;
  const { t } = useSafeTranslation();
  const onAddToAppleWalletClick = async () => {
    let response;
    try {
      response = await client.getAppleWalletPassLink({
        policyId: id,
      });

      if (response?.data?.signedPassURL) {
        setApplePassURL(response.data.signedPassURL);
      } else {
        setError("Couldn't generate Apple Wallet pass.");
      }
    } catch (e) {
      setError(
        `Couldn't generate Apple Wallet pass: "\n${(e as Error).message}"`
      );
    }
  };

  const onAddToGoogleWalletClick = async () => {
    let response;
    try {
      response = await client.getGoogleWalletPassLink({
        policyId: id,
      });

      if (response?.data?.signedPassURL) {
        setGooglePassURL(response.data.signedPassURL);
      } else {
        setError("Couldn't generate Google Wallet pass.");
      }
    } catch (e) {
      setError(
        `Couldn't generate Google Wallet pass: "\n${(e as Error).message}"`
      );
    }
  };

  useEffect(() => {
    setError('');
    if (applePassURL) {
      window.document.location = applePassURL;
    }
    if (googlePassURL) {
      window.document.location = googlePassURL;
    }
  }, [applePassURL, googlePassURL]);

  return (
    <BottomOrRegularModal
      isOpen
      onClose={onClose}
      title={t(
        'myPolicies.policyDetails.addToWalletModal.title',
        'Digital wallet card'
      )}
    >
      <div className="px24 pb24 pt16">
        <p className="p-p mb16">
          You can add this insurance card to your phone’s digital wallet and
          present it to the service provider.
        </p>
        <div className="d-flex gap16">
          {applePassURL ? (
            <a href={applePassURL}>
              <img src={addToAppleWalletButton} alt="apple wallet" />
            </a>
          ) : (
            <button
              className="ds-interactive-component pointer"
              type="button"
              onClick={onAddToAppleWalletClick}
            >
              <img src={addToAppleWalletButton} alt="apple wallet" />
            </button>
          )}
          {googlePassURL ? (
            <a href={googlePassURL}>
              <img src={addToGoogleWalletButton} alt="google wallet" />
            </a>
          ) : (
            <button
              className="ds-interactive-component pointer"
              type="button"
              onClick={onAddToGoogleWalletClick}
            >
              <img src={addToGoogleWalletButton} alt="google wallet" />
            </button>
          )}
        </div>
        <AnimateHeight duration={300} height={error ? 'auto' : 0}>
          <div className="p-notice p-notice--warning mt16">{error}</div>
        </AnimateHeight>
      </div>
    </BottomOrRegularModal>
  );
};
