import { trackTaskEngineCardClicked } from '@getpopsure/analytics';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTask } from 'features/taskEngine/api/taskEngine.api';
import { CustomTask } from 'features/taskEngine/components/CustomTask';
import {
  DateLikeSchema,
  PrivateHealthRelocationDateUpdateTaskSchema,
  Task,
  TaskEngineOptions,
} from 'features/taskEngine/taskEngine.models';
import { updateTaskMutationErrorHandler } from 'features/taskEngine/utils/updateTaskMutationErrorHandler';
import { APIResponseError } from 'models/error';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import { TASK_ENGINE_SENTRY_CATEGORY } from '../../constants';

export const PrivateHealthRelocationDateUpdate = ({
  task,
  options,
}: {
  task: Task;
  options?: TaskEngineOptions;
}) => {
  const queryClient = useQueryClient();
  const { t } = useSafeTranslation();
  const [showModal, setShowModal] = useState(false);

  const mutation = useMutation({
    mutationFn: (relocationDate: Date) => {
      return updateTask({
        taskId: task.id,
        payload: { relocationDate },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });

  const parsedTask =
    PrivateHealthRelocationDateUpdateTaskSchema.safeParse(task);

  if (!parsedTask.success) {
    return null;
  }

  const {
    id,
    description: { type },
  } = parsedTask.data;

  const openModal = () => {
    trackTaskEngineCardClicked({
      task_type: type,
      task_id: id,
    });

    if (options?.onTaskClick) {
      options.onTaskClick(task);
    }

    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const relocationDate = formData.get('relocation-date');

    const parsedRelocationDate = DateLikeSchema.safeParse(relocationDate);
    if (!parsedRelocationDate.success) {
      throw new Error(
        `${TASK_ENGINE_SENTRY_CATEGORY}: PrivateHealthRelocationDateUpdate Relocation date task missing for update`
      );
    }

    mutation.mutate(parsedRelocationDate.data, {
      onSuccess: () => setShowModal(false),
      onError: (error) => updateTaskMutationErrorHandler(error),
    });
  };

  return (
    <CustomTask
      modal={{
        isOpen: showModal,
        loading: mutation.isLoading,
        error: mutation.isError
          ? (mutation.error as APIResponseError).response?.data?.message
          : null,
        onOpen: openModal,
        onClose: closeModal,
      }}
      onSubmit={handleSubmit}
      metadata={{
        title: t(
          'taskEngine.privateHealthRelocationDateUpdate.title',
          'Provide your date of entry to Germany'
        ),
        subtitle: t(
          'taskEngine.privateHealthRelocationDateUpdate.subtitle',
          'This is the date on which you relocated to Germany'
        ),
      }}
      actions={[
        {
          id: '1',
          type: 'DATE',
          attributes: {
            name: 'relocation-date',
            yearBoundaries: {
              min: new Date().getFullYear() - 10,
              max: new Date().getFullYear() + 50,
            },
            displayCalendar: true,
          },
        },
        {
          id: '2',
          type: 'SUBMIT',
          attributes: {
            value: t(
              'taskEngine.privateHealthRelocationDateUpdate.modal.button.title',
              'Submit'
            ),
          },
        },
      ]}
    />
  );
};
