import { storeLegalClaimInfo, submitLegalClaim } from 'actions/claims';
import connectedError, { ErrorProps } from 'containers/error';
import connectedLoader, { LoaderProps } from 'containers/loader';
import View from 'features/claims/components/legalClaim/describeClaim';
import { ClaimSubmitInfo, LegalClaimInfo } from 'models/claims';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from 'reducers';
import { getLegalClaimInfo } from 'selectors/claims';

interface StateProps {
  policyInfo: {
    policyId: string;
  };
  detailAnswers?: {
    situation?: string;
    peopleInvolved?: string;
    wantedOutcome?: string;
  };
  phoneNumber?: string;
}

interface DispatchProps {
  storeClaimInfo: (claimInfo: Partial<LegalClaimInfo>) => void;
  submitClaimInfo: (claimSubmitInfo: ClaimSubmitInfo) => void;
}

type OwnProps = RouteComponentProps<{ policyId: string }> &
  LoaderProps &
  ErrorProps;

export type ViewProps = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (
  state: AppState,
  {
    match: {
      params: { policyId },
    },
  }: OwnProps
): StateProps => {
  const claimInfo = getLegalClaimInfo(state);

  return {
    policyInfo: {
      policyId,
    },
    detailAnswers: claimInfo?.detail,
    phoneNumber: claimInfo?.phoneNumber,
  };
};

const mapDispatchToProps = (dispatch): DispatchProps => ({
  storeClaimInfo: (claimInfo: Partial<LegalClaimInfo>) =>
    dispatch(storeLegalClaimInfo(claimInfo)),
  submitClaimInfo: (claimSubmitInfo: ClaimSubmitInfo) =>
    dispatch(submitLegalClaim({ claimInfo: claimSubmitInfo })),
});

const ConnectedView = connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(View);

export default connectedLoader('CREATE_CLAIM')(
  connectedError('CREATE_CLAIM')(ConnectedView)
);
