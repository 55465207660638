import { AppState } from 'reducers';

export const getClaims = (state: AppState) => state.claims.claims;

export const getCurrentClaim = (state: AppState) => state.claims.currentClaim;

export const getSubmittableClaimInfo = (state: AppState) =>
  state.claims.submittableClaimInfo;

export const getLegalClaimInfo = (state: AppState) =>
  state.claims.legalClaimInfo;

export const getLiabilityClaim = (state: AppState) => state.claims.liability;
