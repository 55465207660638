import { QuoteProcessing } from 'features/expat/components/Processing';
import { Quote } from 'features/expat/components/Quote';
import {
  ExpatDependentQuestionnaire,
  QuestionnaireQuote,
} from 'features/expat/models';
import { PartialQuestionnaire } from 'models/questionnaireFramework';

export const quoteDependentQNR = (): PartialQuestionnaire<
  ExpatDependentQuestionnaire,
  'quote'
> => ({
  processing: {
    required: (answers) => {
      return answers && (!answers.quote || !answers.quote.options);
    },
    type: 'CUSTOM',
    groupId: 'quote',
    component: QuoteProcessing,
    retrieveAnswerObject: (
      quote: QuestionnaireQuote
    ): Partial<ExpatDependentQuestionnaire> => ({
      quote,
    }),
    retrieveNextPageId: () => 'quote',
  },
  quote: {
    required: (answers) => {
      return (
        answers &&
        answers.quote !== undefined &&
        answers.quote.selectedQuote === undefined
      );
    },
    type: 'CUSTOM',
    groupId: 'quote',
    component: Quote,
    retrieveAnswerObject: (
      quote: QuestionnaireQuote
    ): Partial<ExpatDependentQuestionnaire> => ({
      quote,
    }),
    retrieveQuestionsToRemove: () => ['review'],
  },
});
