import { Policy } from 'models/policies';

import { CancelPolicyButton, ContactUsButton } from '../../components/buttons';
import { PolicyDetailsButton } from '../../sections/PolicyDetails/PolicyDetails.models';

export const getGenericButtons = (policy: Policy): PolicyDetailsButton[] => {
  const buttons: PolicyDetailsButton[] = [
    { id: 'CONTACT_US', component: ContactUsButton },
  ];

  if (policy.status !== 'CANCELED') {
    buttons.push({ id: 'CANCEL_POLICY', component: CancelPolicyButton });
  }

  return buttons;
};
