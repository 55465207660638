import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import routes from 'constants/routes';
import { Link } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

interface Prop {
  className: string;
}

const Buttons = ({ className }: Prop) => {
  const { t } = useSafeTranslation();
  return (
    <div className={className}>
      <Button
        className={classNames('ws3', styles.btn)}
        as={Link}
        to={routes.account.signIn.path}
        data-cy="main-login"
      >
        {t('main.logInButton.caption', 'Log in')}
      </Button>

      <Button
        className={classNames('ws3', styles.btn)}
        as={Link}
        variant="filledWhite"
        type="button"
        to={routes.me.policies.list.path}
        data-cy="main-show-policies"
      >
        {t('main.seePoliciesButton.caption', 'See our policies')}
      </Button>
    </div>
  );
};

export default Buttons;
