import { StripeBillingAddress } from 'models/paymentMethods';
import { useSafeTranslation } from 'shared/i18n';

export const BillingAddress = ({
  address,
}: {
  address: StripeBillingAddress;
}) => {
  const { t } = useSafeTranslation();

  return (
    <>
      <div className="p-p mt24">
        {t(
          'components.paymentMethodSelector.billingAddress.title',
          'Billing address'
        )}
      </div>
      <div className="p16 bg-grey-200 mt8 br8 d-flex ai-center jc-between w100">
        <div className="tc-grey-600 p-p">
          <p>{address.line1}</p>
          {address.line2 && <p>{address.line2}</p>}
          <p>{`${address.postal_code} ${address.city}, ${address.country}`}</p>
        </div>
      </div>
    </>
  );
};
