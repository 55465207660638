import { getTrackingObject } from '@getpopsure/tracker';
import {
  DisabilityNotPersistedQuotesDto,
  DisabilityPersistedQuotesDto,
  DisabilityQuestionnaireAnswers,
  DisabilityQuestionnaireLabels,
  DisabilityQuotesResponse,
} from 'features/disability/models';
import { Client } from 'shared/api';

export function createDisabilityQuote(
  this: Client,
  quotesDto: DisabilityNotPersistedQuotesDto | DisabilityPersistedQuotesDto
) {
  return this.network.post<DisabilityQuotesResponse>(
    '/signups/disability/quotes',
    quotesDto
  );
}

export function createDisabilityQuestionnaire(
  this: Client,
  answers: DisabilityQuestionnaireAnswers,
  labels: DisabilityQuestionnaireLabels
) {
  const source = getTrackingObject();

  return this.network.post<{ id: string }>('/questionnaires/', {
    answers,
    questionnaireType: 'DISABILITY',
    metadata: { source, ...labels },
  });
}
