import { useRequestStatus } from 'hooks/useRequestStatus';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  StudentChecklistDispatch,
  uploadStudentChecklistDocument,
} from '../../actions';
import { EStudentChecklistRequestTypes, StepProps } from '../../models';
import { getStudentChecklist } from '../../selectors';
import { getUploadedFilesPreview, handleDownloadDocument } from '../../utils';
import { LetterOfAcceptanceView } from './LetterOfAcceptance.view';

export const LetterOfAcceptanceContainer = ({ completed }: StepProps) => {
  const { policyId } = useParams<{ policyId: string }>();
  const studentChecklist = useSelector(getStudentChecklist(policyId));
  const { loading, error } = useRequestStatus(
    EStudentChecklistRequestTypes.UPLOAD_DOCUMENT
  );
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const dispatch = useDispatch<StudentChecklistDispatch>();

  const letterOfAcceptanceUrl = studentChecklist?.letterOfAcceptanceUrl;

  const isValidNewFile = !!file && typeof file !== 'string';

  /**
   * Component clean-up
   */
  useEffect(() => {
    return () => {
      setFile(null);
      setIsUploadModalOpen(false);
    };
  }, []);
  const handleCloseUploadModal = () => {
    setFile(null);
    setIsUploadModalOpen(false);
  };
  const handleOpenUploadModal = () => setIsUploadModalOpen(true);
  const handleSubmit = () => {
    if (isValidNewFile) {
      dispatch(
        uploadStudentChecklistDocument(policyId, 'letterOfAcceptanceId', file)
      );
    }
  };

  const handleSelectFile = (selectedFiles: File[]) => setFile(selectedFiles[0]);

  const handleRemoveFile = () => setFile(null);

  const handleDownloadLetter = () => {
    if (letterOfAcceptanceUrl) handleDownloadDocument(letterOfAcceptanceUrl);
  };

  const previewFiles = getUploadedFilesPreview(file);

  return (
    <LetterOfAcceptanceView
      completed={completed}
      letterOfAcceptanceUrl={letterOfAcceptanceUrl}
      handleDownloadLetter={handleDownloadLetter}
      handleOpenUploadModal={handleOpenUploadModal}
      isUploadModalOpen={isUploadModalOpen}
      error={error}
      loading={loading}
      uploadedFiles={previewFiles}
      validFile={isValidNewFile}
      handleRemoveFile={handleRemoveFile}
      handleSelectFile={handleSelectFile}
      handleSubmit={handleSubmit}
      handleCloseUploadModal={handleCloseUploadModal}
    />
  );
};
