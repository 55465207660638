import { PaymentMethodBrand } from 'models/paymentMethods';

import amex from './amex.png';
import diners from './diners.png';
import discover from './discover.png';
import jcb from './jcb.png';
import mastercard from './mastercard.png';
import paypal from './paypal.svg';
import sepa from './sepa.svg';
import union from './union.png';
import unknown from './unknown.png';
import visa from './visa.png';

export const mapping: { [k in PaymentMethodBrand]: string } = {
  'American Express': amex,
  'Diners Club': diners,
  Discover: discover,
  JCB: jcb,
  MasterCard: mastercard,
  Visa: visa,
  UnionPay: union,
  'SEPA Direct Debit': sepa,
  PayPal: paypal,
  Unknown: unknown,
};
