import { insurance } from '@getpopsure/private-constants';
import routes from 'constants/routes';
import {
  bannerColorMapping,
  imageSrcSetTypeMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';

import { CategoryLookup, Section } from '../models/lookups';

export const dogLiabilityCategory: CategoryLookup = {
  shortTitle: 'Dog liability',
  title: 'Dog liability insurance',
  excerpt: 'Covers damage caused by dogs to others or their belongings',
  description:
    "Dog liability insurance pays for the damage your dog causes to third parties or their belongings. As a dog owner, you are legally responsible for your dog's actions, and the insurance is mandatory in some regions of Germany (Berlin, Brandenburg, Hamburg, Lower Saxony, Thuringia and Schleswig-Holstein).",
  icon: imageTypeMapping.DOG_LIABILITY,
  iconSrcSet: imageSrcSetTypeMapping.DOG_LIABILITY,
  bannerColor: bannerColorMapping.DOG_LIABILITY,
  enabled: false,
  landingPage: insurance.dogLiability.landingPage,
  quotePage: routes.policies.dogLiability.path,
};

export const dogLiabilitySection: Section = {
  title: '',
  description: 'dogLiability description',
  icon: '',
};
