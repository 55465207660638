import { Address } from '@getpopsure/public-models';

import styles from './style.module.scss';

interface AddressPreviewProps {
  address: Address | null;
}

const AddressPreview = ({ address }: AddressPreviewProps) =>
  address ? (
    <div className={styles.completed}>
      <div className="d-flex">
        <p className="p-p--small tc-grey-500">{address.street}</p>
        <p className={`p-p--small tc-grey-500 ${styles.ml4}`}>
          {address.houseNumber}
        </p>
      </div>
      <p className="p-p--small tc-grey-500">{address.additionalInformation}</p>
      <div className="d-flex">
        <p className="p-p--small tc-grey-500">{address.postcode},</p>
        <p className={`p-p--small tc-grey-500 ${styles.ml4}`}>{address.city}</p>
      </div>
    </div>
  ) : null;

export default AddressPreview;
