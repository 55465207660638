import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import endpoint from 'shared/api';

import {
  BikeClaims,
  BikeClaimsSubmitInfo,
  ZBikeClaimsSubmissionSchema,
} from '../models';

export const useSubmitBikeClaim = () => {
  const onSubmitClaim = ({
    questionnaire,
    policyId,
  }: {
    questionnaire: Partial<BikeClaims>;
    policyId: string;
  }) => {
    const validation = ZBikeClaimsSubmissionSchema.parse(questionnaire);
    const {
      claimType,
      documents,
      details,
      payoutDetails: { iban, amount },
    } = validation;

    const flattenedTokens = documents.flatMap(({ token }) =>
      token !== undefined ? [token] : []
    );

    const submittableInfo: BikeClaimsSubmitInfo = {
      type: 'BIKE',
      userPolicyId: policyId,
      uploadedDocumentTokens: flattenedTokens,
      claimType,
      details,
      iban,
      amount,
    };

    return endpoint.submitClaim(submittableInfo);
  };

  const mutation = useMutation({
    mutationFn: onSubmitClaim,
    onError: (error, variables) => {
      const { policyId, questionnaire } = variables;
      Sentry.captureException(error, {
        tags: {
          feature: 'CLAIMS',
          vertical: 'BIKE',
        },
        extra: {
          policyId,
          questionnaire,
          description: 'Error happened while submitting Bike claims',
        },
      });
    },
  });

  return mutation;
};
