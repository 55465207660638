import { capitalizeName } from '@getpopsure/public-utility';
import routes from 'constants/routes';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';
import { generatePath } from 'react-router';

import type { PolicySingleModal } from '../../components/modals';
import {
  CancelPolicyModal,
  DelinquencyBlockerCancelPolicyModal,
} from '../../components/modals';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from '../../PolicySingle.models';
import { getPolicyDocuments } from '../../sections/PolicyDocuments/utils/getPolicyDocuments';
import { getGenericButtons } from '../generic/getGenericButtons';
import { getGenericInfo } from '../generic/getGenericInfo';
import { PetHealthClaimButton } from './buttons/PetHealthClaimButton';
import { PetHealthClaimModal } from './modals/PetHealthClaimModal';

export const getPetHealthConfig = (args: GetPolicyConfigArgs): PolicyConfig => {
  const [policy, t, featureFlags] = args;

  const policyStatus = getPolicyStatus(policy, true);
  const title = getTitleMapping(t).PET_HEALTH;
  const capitalizedName = policy.attributes?.insuredPerson?.name
    ? capitalizeName(policy.attributes?.insuredPerson.name)
    : '';
  const subtitle = capitalizedName || '';
  const showCommonClaimsButton =
    featureFlags?.flagsReady && featureFlags?.isCommonClaims;

  const header: PolicyConfig['header'] = {
    title,
    subtitle,
    backgroundColor: bannerColorMapping.PET_HEALTH,
    insuranceType: policy.type,
    policyStatus,
    startDate: policy.attributes.startDate,
  };

  if (policy.attributes.price) {
    header.price = {
      amount: parseFloat(policy.attributes.price),
      billingType: policy.attributes.billingType as BillingType,
    };
  }

  const sections: PolicySingleSection[] = showCommonClaimsButton
    ? [
        {
          id: 'POLICY_DETAILS',
          props: {
            policyType: policy.type,
            info: getGenericInfo(policy, t),
            buttons: [...getGenericButtons(policy)],
          },
        },
        {
          id: 'POLICY_CLAIMS',
          props: {
            claims: policy.claims,
            route: generatePath(routes.claims.common.path, {
              policyId: policy.id,
            }),
            insuranceType: 'PET_HEALTH',
            activeUntil:
              policy.status === 'ACTIVE'
                ? undefined
                : policy.attributes.activeUntil,
            policyStatus: policy.status,
            screen: {
              button: {
                dataCy: 'pet-health-policy-detail-make-a-claim',
                title: t(
                  'myPolicies.policyDetails.petHealth.newClaim.caption',
                  'New claim'
                ),
              },
            },
          },
        },
        {
          id: 'POLICY_DOCUMENTS',
          props: {
            documents: getPolicyDocuments(policy),
          },
        },
      ]
    : [
        {
          id: 'POLICY_DETAILS',
          props: {
            policyType: policy.type,
            info: getGenericInfo(policy, t),
            buttons: [
              {
                id: 'PET_HEALTH_CLAIM',
                component: PetHealthClaimButton,
              },
              ...getGenericButtons(policy),
            ],
          },
        },
        {
          id: 'POLICY_DOCUMENTS',
          props: {
            documents: getPolicyDocuments(policy),
          },
        },
      ];

  const modals: PolicySingleModal[] = [
    {
      id: 'CANCEL_POLICY',
      component: CancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
    {
      id: 'PET_HEALTH_CLAIM',
      component: PetHealthClaimModal,
    },
  ];

  return { header, modals, sections };
};
