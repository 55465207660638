import { BottomOrRegularModal, MultiDropzone } from '@popsure/dirty-swan';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useUploadFiles } from 'hooks/useUploadFiles';
import { UpdateBikeQuestionnaireDispatch } from 'models/policies';
import AnimateHeight from 'react-animate-height';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { UploadedFile } from 'shared/models/types';

import { updateBikeQuestionnaire } from '../actions';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  uploadedDocs: UploadedFile[];
  policyId: string;
  questionnaireId?: string;
}

export const UploadDocumentModal = ({
  isOpen,
  onClose,
  uploadedDocs,
  policyId,
  questionnaireId,
}: Props) => {
  const { t } = useSafeTranslation();

  const { loading: submitLoading, error } = useRequestStatus(
    'UPDATE_QUESTIONNAIRE'
  );
  const { loading: policyLoading } = useRequestStatus('LOAD_MY_POLICY_DETAIL');

  const {
    uploadFile,
    uploadedFiles,
    loading: uploadLoading,
  } = useUploadFiles({
    initialValue: uploadedDocs,
    isQuestionnaire: true,
  });

  const dispatch = useDispatch<UpdateBikeQuestionnaireDispatch>();

  const onSubmit = async () => {
    if (!questionnaireId) {
      throw new Error(
        '[Bike] Missing questionnaire id for new documents upload'
      );
    }

    const tokens = uploadedFiles.flatMap(({ token }) =>
      token === undefined ? [] : [token]
    );

    const responseStatus = await dispatch(
      updateBikeQuestionnaire({
        answers: {},
        questionnaireId,
        policyId,
        uploadTokens: tokens,
      })
    );

    if (responseStatus === 'SUCCESS') {
      onClose();
    }
  };

  const validForSubmission =
    uploadedFiles.some(({ token }) => token !== undefined) &&
    !submitLoading &&
    !uploadLoading;

  if (policyLoading) {
    return <TimedLoadSpinner />;
  }

  return (
    <BottomOrRegularModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('policy.bike.uploadDocumentModal.title', 'Purchase receipts')}
    >
      <div className="px24 pb24 p-p">
        <Markdown paragraphClassName="p-p mt16" openLinksInNewTab>
          {t(
            'policy.bike.uploadDocumentModal.description',
            'Upload the purchase receipt for your **bike**, **lock** and **other accessories**.'
          )}
        </Markdown>
        <div className="mt16">
          <MultiDropzone
            onFileSelect={uploadFile}
            uploadedFiles={uploadedFiles}
            uploading={uploadLoading}
            /* TODO: [KONG] We are blocking remove functionality for now until BE returns tokens for existing docs
            onRemoveFile={() => {}}
            */
          />
        </div>
        <AnimateHeight duration={300} height={error ? 'auto' : 0}>
          <div className="p-notice p-notice--warning mt16 p-p">
            {t(
              'policy.bike.frameNumberUploadDocument.error',
              'Something went wrong and we were unable to upload your documents. Please try again.'
            )}
          </div>
        </AnimateHeight>
        <button
          type="button"
          className={classNames(`mt24 p-btn--primary wmn2`, {
            'p-btn--loading': submitLoading,
          })}
          disabled={!validForSubmission}
          onClick={onSubmit}
        >
          {t('policy.bike.uploadDocumentModal.cta', 'Upload')}
        </button>
      </div>
    </BottomOrRegularModal>
  );
};
