import { Button } from '@popsure/dirty-swan';
import { fetchHealthCard, finalizeHealthCard } from 'actions/healthCard';
import { StepProps } from 'features/studentChecklist/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { HealthCardDispatch } from 'models/healthCard';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line
import { getHealthCardId } from 'selectors/healthCard';

const REQUEST_TYPE = 'UPDATE_HEALTH_CARD_DETAILS';

const SendHealthCard = ({ completed }: StepProps) => {
  const dispatch = useDispatch<HealthCardDispatch>();
  const { policyId } = useParams<{ policyId: string }>();
  const healthCardId = useSelector(getHealthCardId(policyId));
  const { loading, error } = useRequestStatus(REQUEST_TYPE);

  if (!healthCardId)
    throw new Error(
      '[HEALTH_CARD_CHECKLIST] trying to send a photo to a non existing health card'
    );

  const handleSendPhoto = async () => {
    await dispatch(finalizeHealthCard(healthCardId));
    await dispatch(fetchHealthCard(healthCardId));
  };

  if (completed) return null;

  return (
    <div className="mt8">
      <Button
        data-testid="submit-health-card"
        className="w100 wmx3"
        onClick={handleSendPhoto}
        loading={loading}
      >
        Send health card
      </Button>
      <AnimateHeight duration={150} height={error ? 'auto' : 0}>
        <div className="p-notice p-notice--danger mt8 p-p">
          {error?.message}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default SendHealthCard;
