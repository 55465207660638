import type {
  DentalClaims,
  Questionnaire as DentalQuestionnaire,
} from 'features/dentalClaims/models';
import Questionnaire from 'features/questionnaireFramework';
import { QuestionnaireError } from 'features/questionnaireFramework/models';

interface ViewProps {
  questionnaire: DentalQuestionnaire;
  questionnaireAnswers: Partial<DentalClaims>;
  handleAnswerQuestion: (answer: Partial<DentalClaims>) => void;
  sendQuestionnaireError: (error: QuestionnaireError) => void;
}

export const DentalClaimsView = ({
  questionnaireAnswers,
  handleAnswerQuestion,
  questionnaire,
  sendQuestionnaireError,
}: ViewProps) => {
  return (
    <>
      <Questionnaire
        featureName="Dental Claims"
        questionnaire={questionnaire}
        questionnaireAnswers={questionnaireAnswers}
        handleAnswerQuestion={handleAnswerQuestion}
        sendQuestionnaireError={sendQuestionnaireError}
      />
    </>
  );
};
