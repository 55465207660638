import routes from 'constants/routes';
import { CreateClaimButton } from 'features/policySingle/components/buttons/CreateClaimButton';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { useSafeTranslation } from 'shared/i18n';

export const DentalCreateClaimButton = ({
  policyData,
  onOpenModal,
}: PolicyDetailsButtonProps) => {
  const { t } = useSafeTranslation();

  return (
    <CreateClaimButton
      policyData={policyData}
      onOpenModal={onOpenModal}
      route={routes.claims.dental.path}
      title={t(
        'myPolicies.policyDetails.dentalClaimsButton.caption',
        'Claims and cost plans'
      )}
    />
  );
};
