import GenericReview from 'components/review';
import { useLoadPolicyDetail } from 'features/claimsProcess/hooks';
import {
  createDependentPostQuote,
  CreateQuoteDispatch,
} from 'features/expat/actions';
import {
  answersToReviewValues,
  ExpatDependentQuestionnaire,
} from 'features/expat/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

export const DependentsReview = ({
  routes,
  onSubmitValue,
  questionnaireAnswers,
}: CustomComponentProps<ExpatDependentQuestionnaire>) => {
  const { t } = useSafeTranslation();

  const { loading, error } = useRequestStatus('EXPAT_SUBMIT_INFO');

  const { loading: policyLoading, policy } = useLoadPolicyDetail(
    questionnaireAnswers.mainPolicyId ?? ''
  );

  const dispatch = useDispatch<CreateQuoteDispatch>();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const { status } = await dispatch(createDependentPostQuote(t));
    if (status === 'SUCCESS') {
      onSubmitValue(true);
    }
  };

  const questionReviewValues = answersToReviewValues(
    t,
    questionnaireAnswers,
    routes,
    policy
  );

  return (
    <GenericReview
      title={
        <h1 className="p-h1 mb8">
          {t(
            'expat.qnr.depenentReview.title',
            "Here's what we know about them:"
          )}
        </h1>
      }
      questionReviewValues={questionReviewValues}
      loading={loading === true || policyLoading === true}
      error={error?.message}
      handleSubmit={handleSubmit}
      insuranceType="INCOMING"
    />
  );
};
