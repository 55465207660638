import api from 'shared/api';

import { Task, UpdateTask } from '../taskEngine.models';

export const getTasks = async () => {
  const response = await api.network.post<Array<Task>>('/task-engine/tasks');
  return response.data;
};

export const updateTask = ({ taskId, payload }: UpdateTask) =>
  api.network.put(`/task-engine/tasks/${taskId}`, payload);

export const generateSignedUploadUrl = (fileName: string, bucket?: string) =>
  api.network.post('/storage/generate-v4-upload-signed-url', {
    fileName,
    bucket,
  });
