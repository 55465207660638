import { useTranslation } from '@getpopsure/i18n-react';
import { AddOns } from '@getpopsure/legal-insurance-pricing-engine-v2';
import classNames from 'classnames';
import { QuoteInfo } from 'features/legal/models';

import { ToggleCard } from '../ToggleCard/ToggleCard';
import styles from './style.module.scss';

type AddOnsSectionProps = {
  quoteCustomization: Partial<QuoteInfo>;
  setQuoteCustomization: React.Dispatch<
    React.SetStateAction<Partial<QuoteInfo>>
  >;
  addons: Record<AddOns, number>;
};

const AddOnsSection = ({
  quoteCustomization,
  setQuoteCustomization,
  addons,
}: AddOnsSectionProps) => {
  const { t } = useTranslation();

  const handleToggle = (value: AddOns) => () => {
    setQuoteCustomization((currentOptions) => {
      if (currentOptions.addons?.includes(value)) {
        return {
          ...currentOptions,
          addons: currentOptions.addons.filter((option) => option !== value),
        };
      }

      return {
        ...currentOptions,
        addons: currentOptions.addons?.concat(value),
      };
    });
  };

  const isHomeSelected =
    quoteCustomization.addons?.includes('PROPERTY') ?? false;
  const isCriminalProtectionSelected =
    quoteCustomization.addons?.includes('CRIMINAL') ?? false;

  return (
    <>
      <header
        className={classNames('fd-column ai-center mb24', styles.addOnsHeader)}
      >
        <h2 className="p-h2 p--serif ta-center">
          {t('legal.qnr.quote.addOns.title', 'Customize your coverage')}
        </h2>
        <div className={`ws2 ${styles.headerUnderline}`} />
      </header>
      <ToggleCard
        className="mb24"
        isSelected={isHomeSelected}
        onChange={handleToggle('PROPERTY')}
        toggleKey="PROPERTY"
        price={addons.PROPERTY}
        title={t('legal.qnr.quote.addOns.home.title', 'Home')}
        description={t(
          'legal.qnr.quote.addOns.home.description',
          'Additional coverage for disputes that arise as a tenant or homeowner, including issues with rental contracts, rent increases, property management, or noise complaints.'
        )}
      />
      <ToggleCard
        isSelected={isCriminalProtectionSelected}
        onChange={handleToggle('CRIMINAL')}
        toggleKey="CRIMINAL"
        price={addons.CRIMINAL}
        title={t(
          'legal.qnr.quote.addOns.criminal.title',
          'Criminal protection'
        )}
        description={t(
          'legal.qnr.quote.addOns.criminal.description',
          'It adds extra protection against false accusations of intentional offences like theft, embezzlement, and fraud, if you are found not guilty.'
        )}
      />
    </>
  );
};

export default AddOnsSection;
