import { TFunction } from '@getpopsure/i18n-react';
import {
  TableHeader,
  TableRowHeader,
  TableTrueFalse,
} from '@popsure/dirty-swan';

import { CTACell } from '../../CTACell/CTACell';
import {
  LegalQuotePlan,
  Modal,
  TableData,
  ValueWithDescription,
} from '../../models';
import { ValueCell } from '../../ValueCell/ValueCell';
import briefcaseIcon from '../icons/briefcase.svg';
import carIcon from '../icons/car.svg';
import heartIcon from '../icons/heart.svg';

export const getTableHeaders = (
  t: TFunction,
  setModal: (modal: Modal) => void,
  showPrice: boolean,
  planMapping: LegalQuotePlan
) => {
  const renderValueCell = (input: ValueWithDescription) => (
    <ValueCell value={input.value} description={input.description} />
  );

  const renderTrueFalse = (value: boolean) => <TableTrueFalse value={value} />;

  const headers: Array<TableHeader<TableData>> = [
    {
      id: 1,
      label: '',
      default: true,
      cells: [
        {
          key: 'name',
          label: t(
            'legal.qnr.quote.coverageTable.name.header',
            'Coverage overview'
          ),
          render: (value) => (
            <CTACell
              value={value}
              showPrice={showPrice}
              planMapping={planMapping}
            />
          ),
        },

        {
          key: 'moneyBack',
          label: (
            <TableRowHeader
              label={t(
                'legal.qnr.quote.coverageTable.moneyBack.header',
                'Money-back guarantee'
              )}
              subtitle={t(
                'legal.qnr.quote.coverageTable.moneyBack.subtitle',
                'if no consultations or claims made'
              )}
            />
          ),
          render: renderValueCell,
        },
        {
          key: 'deductible',
          label: (
            <TableRowHeader
              label={t(
                'legal.qnr.quote.coverageTable.deductible.header',
                'Deductible'
              )}
              onClickInfo={() =>
                setModal({
                  wide: true,
                  title: t(
                    'legal.qnr.quote.coverageTable.deductible.modal.title',
                    'What is a deductible?'
                  ),
                  children: t(
                    'legal.qnr.quote.coverageTable.deductible.modal.description',
                    "The deductible is the amount you have to pay out-of-pocket before the insurance begins to cover costs.\n\n It does not apply to initial phone consultations with a lawyer or mediator.\n\n **How is it calculated?**\n\n If you had legal insurance in Germany before, your deductible for the first year can be between €0 and €300. This depends on your claims history, how long you had the previous insurance, and any gaps in coverage between your current and previous insurance.\n\n If you haven't had legal insurance in the past 5 years, your first-year deductible will be €300.\n\n **Does the deductible change over time?**\n\n Your deductible will go down by €100 each claim-free insurance year until it reaches €0.\n\n If the insurance pays out your claim, the deductible will increase to €500, again over time, it will go down by by €100 each claim-free insurance year until it reaches €0."
                  ),
                })
              }
            />
          ),
        },
        {
          key: 'worldWideCoverage',
          label: t(
            'legal.qnr.quote.coverageTable.worldWideCoverage.header',
            'Worldwide coverage'
          ),
        },
        {
          key: 'layoutConsultation',
          label: (
            <TableRowHeader
              label={t(
                'legal.qnr.quote.coverageTable.layoutConsultation.header',
                'Initial consultation with a lawyer or mediator'
              )}
              onClickInfo={() =>
                setModal({
                  wide: true,
                  title: t(
                    'legal.qnr.quote.coverageTable.layoutConsultation.modal.title',
                    'Initial consultation with a lawyer or mediator'
                  ),
                  children: t(
                    'legal.qnr.quote.coverageTable.layoutConsultation.modal.description',
                    "A phone consultation is the initial step to assess your legal case and provide guidance on the next steps. You can present your situation, ask questions, and receive preliminary advice.\n\n **How long would it take?**\n\n The call takes place within 3 business days and typically lasts 10 to 15 minutes.\n\n **Cost and waiting times?**\n\n It is offered at no extra cost. There's no waiting period for an initial consultation."
                  ),
                })
              }
            />
          ),
          render: renderTrueFalse,
        },
      ],
    },
    {
      id: 2,
      label: (
        <p className="d-flex ai-center p-h4 c-gap8">
          <img src={heartIcon} alt="heart icon" /> Personal
        </p>
      ),
      cells: [
        {
          key: 'disputes',
          label: (
            <TableRowHeader
              label={t(
                'legal.qnr.quote.coverageTable.disputes.header',
                'Disputes about personal contracts'
              )}
              onClickInfo={() =>
                setModal({
                  wide: true,
                  title: t(
                    'legal.qnr.quote.coverageTable.disputes.modal.title',
                    'Disputes about personal contracts'
                  ),
                  children: t(
                    'legal.qnr.quote.coverageTable.disputes.modal.description',
                    'e.g. sales contracts, travel contracts, insurance contracts'
                  ),
                })
              }
            />
          ),
          render: renderValueCell,
        },
        {
          key: 'contractReview',
          label: (
            <TableRowHeader
              label={t(
                'legal.qnr.quote.coverageTable.contractReview.header',
                'Reviewing the contract before signing'
              )}
              onClickInfo={() =>
                setModal({
                  wide: true,
                  title: t(
                    'legal.qnr.quote.coverageTable.contractReview.modal.title',
                    'Reviewing the contract before signing'
                  ),
                  children: t(
                    'legal.qnr.quote.coverageTable.contractReview.modal.description',
                    "For example, you are about to change apartments and want your new rental contract reviewed before signing it. That's not covered.\n\nLegal insurance functions as damage insurance rather than preventive insurance. Coverage is provided only in the event of a breach or damage."
                  ),
                })
              }
            />
          ),
          render: renderTrueFalse,
        },
        {
          key: 'socialLaw',
          label: (
            <TableRowHeader
              label={t(
                'legal.qnr.quote.coverageTable.socialLaw.header',
                'Social law'
              )}
              onClickInfo={() =>
                setModal({
                  wide: true,
                  title: t(
                    'legal.qnr.quote.coverageTable.socialLaw.modal.title',
                    'Social law'
                  ),
                  children: t(
                    'legal.qnr.quote.coverageTable.socialLaw.modal.description',
                    'e.g. disputes about statutory health insurance'
                  ),
                })
              }
            />
          ),
          render: renderTrueFalse,
        },
        {
          key: 'administrativeLaw',
          label: (
            <TableRowHeader
              label={t(
                'legal.qnr.quote.coverageTable.administrativeLaw.header',
                'Administrative law'
              )}
              onClickInfo={() =>
                setModal({
                  wide: true,
                  title: t(
                    'legal.qnr.quote.coverageTable.administrativeLaw.modal.title',
                    'Administrative law'
                  ),
                  children: t(
                    'legal.qnr.quote.coverageTable.administrativeLaw.modal.description',
                    "e.g. disputes with the residents' registration office (Bürgeramt)"
                  ),
                })
              }
            />
          ),
          render: renderValueCell,
        },
        {
          key: 'universityAdmission',
          label: t(
            'legal.qnr.quote.coverageTable.universityAdmission.header',
            'University admission disputes'
          ),
          render: renderValueCell,
        },
        {
          key: 'legalAdvice',
          label: t(
            'legal.qnr.quote.coverageTable.legalAdvice.header',
            'Legal advice about family and inheritance law'
          ),
          render: renderValueCell,
        },
        {
          key: 'onlineCopyright',
          label: t(
            'legal.qnr.quote.coverageTable.onlineCopyright.header',
            'Online copyright infringements'
          ),
        },
        {
          key: 'immigration',
          label: t(
            'legal.qnr.quote.coverageTable.immigration.header',
            'Matters relating to immigration'
          ),
          render: renderTrueFalse,
        },
      ],
    },
    {
      id: 3,
      label: (
        <p className="d-flex ai-center p-h4 c-gap8">
          <img src={carIcon} alt="car icon" /> Traffic
        </p>
      ),
      cells: [
        {
          key: 'accidentDispute',
          label: (
            <TableRowHeader
              label={t(
                'legal.qnr.quote.coverageTable.accidentDispute.header',
                'Disputes after traffic accidents'
              )}
              onClickInfo={() =>
                setModal({
                  wide: true,
                  title: t(
                    'legal.qnr.quote.coverageTable.accidentDispute.modal.title',
                    'Disputes after traffic accidents'
                  ),
                  children: t(
                    'legal.qnr.quote.coverageTable.accidentDispute.modal.description',
                    'e.g. disputes about an accident caused by someone who refuses to cover the damages, vehicle damaged by vandalism'
                  ),
                })
              }
            />
          ),
          render: renderTrueFalse,
        },
        {
          key: 'adminOffences',
          label: (
            <TableRowHeader
              label={t(
                'legal.qnr.quote.coverageTable.adminOffences.header',
                'Administrative offences'
              )}
              onClickInfo={() =>
                setModal({
                  wide: true,
                  title: t(
                    'legal.qnr.quote.coverageTable.adminOffences.modal.title',
                    'Administrative offences'
                  ),
                  children: t(
                    'legal.qnr.quote.coverageTable.adminOffences.modal.description',
                    'e.g. disputes about speeding tickets, parking fines etc.'
                  ),
                })
              }
            />
          ),
          render: renderTrueFalse,
        },
        {
          key: 'contractDisputes',
          label: (
            <TableRowHeader
              label={t(
                'legal.qnr.quote.coverageTable.contractDisputes.header',
                'Disputes about contracts'
              )}
              onClickInfo={() =>
                setModal({
                  wide: true,
                  title: t(
                    'legal.qnr.quote.coverageTable.contractDisputes.modal.title',
                    'Disputes about contracts'
                  ),
                  children: t(
                    'legal.qnr.quote.coverageTable.contractDisputes.modal.description',
                    'e.g. warranty rights after the purchase of a defective vehicle'
                  ),
                })
              }
            />
          ),
          render: renderTrueFalse,
        },
      ],
    },
    {
      id: 4,
      label: (
        <p className="d-flex ai-center p-h4 c-gap8">
          <img src={briefcaseIcon} alt="briefcase icon" /> Professional
        </p>
      ),
      cells: [
        {
          key: 'termination',
          label: t(
            'legal.qnr.quote.coverageTable.termination.header',
            'Wrongful termination, warnings or unfair assessments'
          ),
          render: renderValueCell,
        },
        {
          key: 'terminationAgreement',
          label: t(
            'legal.qnr.quote.coverageTable.terminationAgreement.header',
            'Advice on termination agreement'
          ),
          render: renderValueCell,
        },
        {
          key: 'workPlaceDiscrimination',
          label: t(
            'legal.qnr.quote.coverageTable.workPlaceDiscrimination.header',
            'Protection against discrimination at work place'
          ),
          render: renderValueCell,
        },
        {
          key: 'freelance',
          label: t(
            'legal.qnr.quote.coverageTable.freelance.header',
            'Full-time freelance / self-employment'
          ),
          render: renderTrueFalse,
        },
        {
          key: 'secondaryJob',
          label: t(
            'legal.qnr.quote.coverageTable.secondaryJob.header',
            'Self-employed secondary job'
          ),
          render: renderValueCell,
        },
      ],
    },
  ];
  return headers;
};

export const getTableData = (t: TFunction): Array<TableData> => {
  return [
    {
      id: 0,
      name: 'BASIC',
      moneyBack: {
        value: t(
          'legal.qnr.quote.coverageTable.moneyBack.basic.value',
          '14 days'
        ),
        description: t(
          'legal.qnr.quote.coverageTable.moneyBack.basic.description',
          'from the coverage start date'
        ),
      },
      deductible: t(
        'legal.qnr.quote.coverageTable.deductible.basic.description',
        'Up to €300'
      ),
      worldWideCoverage: t(
        'legal.qnr.quote.coverageTable.worldWideCoverage.basic.value',
        '€250,000'
      ),
      layoutConsultation: true,

      disputes: {
        value: true,
        description: t(
          'legal.qnr.quote.coverageTable.disputes.basic.description',
          '6-month waiting period'
        ),
      },
      contractReview: false,
      socialLaw: true,
      administrativeLaw: {
        value: true,
        description: t(
          'legal.qnr.quote.coverageTable.administrativeLaw.basic.description',
          '6-month waiting period'
        ),
      },
      universityAdmission: { value: false },
      legalAdvice: {
        value: t(
          'legal.qnr.quote.coverageTable.legalAdvice.basic.value',
          '€250 / legal case'
        ),
        description: t(
          'legal.qnr.quote.coverageTable.legalAdvice.basic.description',
          '1-year waiting period'
        ),
      },
      onlineCopyright: t(
        'legal.qnr.quote.coverageTable.onlineCopyright.basic.value',
        'Only initial consultation'
      ),
      immigration: false,

      accidentDispute: true,
      adminOffences: true,
      contractDisputes: true,

      termination: {
        value: true,
        description: t(
          'legal.qnr.quote.coverageTable.termination.basic.description',
          '6-month waiting period'
        ),
      },
      terminationAgreement: {
        value: t(
          'legal.qnr.quote.coverageTable.terminationAgreement.basic.value',
          '€1000 / year'
        ),
        description: t(
          'legal.qnr.quote.coverageTable.terminationAgreement.basic.description',
          '6-month waiting period'
        ),
      },
      workPlaceDiscrimination: {
        value: true,
        description: t(
          'legal.qnr.quote.coverageTable.workPlaceDiscrimination.basic.description',
          '6-month waiting period'
        ),
      },
      freelance: false,
      secondaryJob: { value: false },
    },
    {
      id: 1,
      name: 'ADVANCED',
      moneyBack: {
        value: t(
          'legal.qnr.quote.coverageTable.moneyBack.advanced.value',
          '60 days'
        ),
        description: t(
          'legal.qnr.quote.coverageTable.moneyBack.advanced.description',
          'from the coverage start date'
        ),
      },
      deductible: t(
        'legal.qnr.quote.coverageTable.deductible.advanced.description',
        'Up to €300'
      ),
      worldWideCoverage: t(
        'legal.qnr.quote.coverageTable.worldWideCoverage.advanced.value',
        '€500,000'
      ),
      layoutConsultation: true,

      disputes: {
        value: true,
      },
      contractReview: false,
      socialLaw: true,
      administrativeLaw: {
        value: true,
        description: t(
          'legal.qnr.quote.coverageTable.administrativeLaw.advanced.description',
          '3-month waiting period'
        ),
      },
      universityAdmission: {
        value: true,
        description: t(
          'legal.qnr.quote.coverageTable.universityAdmission.advanced.description',
          '5-year waiting period'
        ),
      },
      legalAdvice: {
        value: t(
          'legal.qnr.quote.coverageTable.legalAdvice.advanced.value',
          '€2500 / legal case'
        ),
        description: t(
          'legal.qnr.quote.coverageTable.legalAdvice.advanced.description',
          '1-year waiting period'
        ),
      },
      onlineCopyright: t(
        'legal.qnr.quote.coverageTable.onlineCopyright.advanced.value',
        '€1,000 / year'
      ),
      immigration: false,

      accidentDispute: true,
      adminOffences: true,
      contractDisputes: true,

      termination: {
        value: true,
        description: t(
          'legal.qnr.quote.coverageTable.termination.advanced.description',
          '3-month waiting period'
        ),
      },
      terminationAgreement: {
        value: t(
          'legal.qnr.quote.coverageTable.terminationAgreement.advanced.value',
          '€1000 / year'
        ),
        description: t(
          'legal.qnr.quote.coverageTable.terminationAgreement.advanced.description',
          '3-month waiting period'
        ),
      },
      workPlaceDiscrimination: {
        value: true,
        description: t(
          'legal.qnr.quote.coverageTable.workPlaceDiscrimination.advanced.description',
          '3-month waiting period'
        ),
      },
      freelance: false,
      secondaryJob: {
        value: t(
          'legal.qnr.quote.coverageTable.secondaryJob.advanced.value',
          'Max turnover of €22,000'
        ),
        description: t(
          'legal.qnr.quote.coverageTable.secondaryJob.advanced.description',
          '3-month waiting period'
        ),
      },
    },
  ];
};
