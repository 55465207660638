import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import styles from './style.module.scss';

const Chip = ({
  name,
  price,
  onSelect,
}: {
  name: string;
  price: number;
  onSelect?: () => void;
}) => (
  <div className={`mt16 ${styles.container}`}>
    <div className={styles['inner-container']}>
      <div className="p-h4">{name} Cover</div>
      <div className="mt16 p--serif p-h1--xl tc-purple-500">
        {englishFormattedEuroCurrency(price, 0)}
      </div>
      <div className="p-h4 mt8 tc-purple-500">per month</div>
      <button
        className={`p-btn p-btn--primary mt16 ${styles.button}`}
        onClick={onSelect}
        data-cy={`policies-expat-button-quote-${name}`}
        type="button"
      >
        Select {name}
      </button>
    </div>
  </div>
);

export default Chip;
