import Markdown from 'components/markdown';
import {
  CheckoutDocument,
  CheckoutDocumentCategory,
} from 'features/checkoutDocuments/models';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

export const DocumentsReview = ({
  documents,
}: {
  documents: CheckoutDocument[];
}) => {
  const { t } = useSafeTranslation();
  const documentLinkMapping = documents.reduce((mapping, currentDoc) => {
    const { url, category } = currentDoc;
    return { ...mapping, [category]: url };
  }, {} as Record<CheckoutDocumentCategory, string>);

  return (
    <section className={`bg-white ${styles.container}`}>
      <Markdown openLinksInNewTab={true}>
        {t('components.checkout.documents.section', {
          defaultValue: `By clicking “Buy now” I confirm to have reviewed the insurance
    [conditions/IPID]({{insuranceConditions}}), [advice record]({{adviceRecord}}), 
    and [licensing information]({{licensingInformation}}).`,
          insuranceConditions: documentLinkMapping.INSURANCE_CONDITIONS,
          adviceRecord: documentLinkMapping.ADVICE_RECORD,
          licensingInformation:
            documentLinkMapping.FEATHER_INTRODUCTORY_INFORMATION,
        })}
      </Markdown>
    </section>
  );
};
