export const capitalizeSingleWord = (word: string): string => {
  if (word.length === 0) {
    return '';
  }

  const letters = word.split('');
  const firstLetter = letters[0].toLocaleUpperCase();
  const wordWithoutFirstLetter = word.slice(1).toLocaleLowerCase();
  return firstLetter + wordWithoutFirstLetter;
};
