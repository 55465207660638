import { insurance } from '@getpopsure/private-constants';
import {
  getRoutes,
  QuestionnaireQuestions,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import LoadingSpinner from 'components/loadingSpinner';
import PageNotFound from 'components/pageNotFound';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { useLoadPolicyDetail } from 'features/claimsProcess/hooks';
import { UserWithBrokerMandate } from 'models/user';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import { AppState } from 'reducers';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import {
  removeDependentGenericQuestionnaireAnswer,
  storeDependentGenericQuestionnaireAnswer,
} from './actions';
import { ExpatEuDependent, isValidRegion, regionToNameMapping } from './models';
import {
  ExpatEuDependentComponents,
  getTranslatedQuestionnaire,
} from './questionnaire/dependents';

export const isOver = (dateOfBirth: string, threshold: number): boolean =>
  dayjs().diff(dateOfBirth, 'years') >= threshold;

export const YEARLY_MIN_INCOME_THRESHOLD =
  12 * insurance.healthInsurance.miniJobThreshold;

const rules: Rule<ExpatEuDependent>[] = [
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: { type: 'years', value: 75 },
    },
    then: {
      goTo: 'ageIs75AndAboveBlocker',
    },
  },
  {
    id: 'occupation',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'EMPLOYED' },
    },
    then: {
      goTo: 'regionOfEmployment',
    },
    else: {
      goTo: 'quoteOptions',
    },
  },
  {
    id: 'regionOfEmployment',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'YES' },
    },
    then: {
      goTo: 'employedInRegionBlocker',
    },
    else: {
      goTo: 'quoteOptions',
    },
  },
  {
    id: 'employedInRegionBlocker',
    if: {
      op: 'equals',
      variable: { type: 'static', value: true },
    },
    then: {
      goTo: 'quoteOptions',
    },
  },
  {
    id: 'startDate',
    if: (_, questionnaireAnswers) => {
      if (isOver(questionnaireAnswers.dateOfBirth ?? '', 18)) {
        return true;
      }
      return false;
    },
    then: {
      goTo: 'review',
    },
    else: {
      goTo: 'isMainPolicyTheLegalGuardian',
    },
  },
  {
    id: 'isMainPolicyTheLegalGuardian',
    if: {
      op: 'equals',
      variable: { type: 'static', value: true },
    },
    then: {
      goTo: 'review',
    },
    else: {
      goTo: 'legalGuardianName',
    },
  },
  {
    id: 'legalGuardianName',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianDateOfBirth',
    },
  },
  {
    id: 'legalGuardianDateOfBirth',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianEmail',
    },
  },
  {
    id: 'startDate',
    if: (_, questionnaireAnswers) => {
      if (isOver(questionnaireAnswers.dateOfBirth ?? '', 18)) {
        return true;
      }
      return false;
    },
    then: {
      goTo: 'review',
    },
    else: {
      goTo: 'isMainPolicyTheLegalGuardian',
    },
  },
  {
    id: 'isMainPolicyTheLegalGuardian',
    if: {
      op: 'equals',
      variable: { type: 'static', value: true },
    },
    then: {
      goTo: 'review',
    },
    else: {
      goTo: 'legalGuardianName',
    },
  },
  {
    id: 'legalGuardianName',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianDateOfBirth',
    },
  },
  {
    id: 'legalGuardianDateOfBirth',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianEmail',
    },
  },
];

const removeReview: RemoveAnswerType<ExpatEuDependent> = {
  op: 'always',
  questions: ['review'],
};

const removeAnswersLogic: Partial<
  Record<keyof ExpatEuDependent, RemoveAnswerType<ExpatEuDependent>>
> = {
  dateOfBirth: {
    op: 'always',
    questions: [
      'quoteOptions',
      'quote',
      'legalGuardianName',
      'legalGuardianDateOfBirth',
      'legalGuardianEmail',
      'review',
    ],
  },
  occupation: {
    op: 'always',
    questions: ['quoteOptions', 'quote'],
  },
  quote: removeReview,
  name: removeReview,
  review: {
    op: 'always',
    questions: ['hasDownloadedDocs'],
  },
};

const prefillNameAndBirthDate = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<ExpatEuDependent> = {};
    const { firstName, lastName } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    return toUpdate;
  }

  return null;
};

export const SignupDependentsPage = () => {
  const questionnaireAnswers =
    useSelector(
      (state: AppState) => state.genericQuestionnaire.expatEu?.dependent
    ) || {};
  const dispatch = useDispatch();
  const account = useSelector(getAccountInfo);
  const { t } = useSafeTranslation();
  const history = useHistory();

  const { localeId: localeParamId, mainPolicyId } = useParams<{
    localeId?: string;
    mainPolicyId: string;
  }>();

  const { loading: policyLoading, policy } = useLoadPolicyDetail(mainPolicyId);

  useEffect(() => {
    dispatch(
      storeDependentGenericQuestionnaireAnswer({
        region: policy?.region,
        mainPolicyId,
      })
    );
  }, [mainPolicyId, policy, dispatch]);

  if (policyLoading) {
    return <LoadingSpinner />;
  }

  if (!policy || policy.region !== localeParamId) {
    return <PageNotFound />;
  }

  const localeId = policy.region;

  if (!localeId || !isValidRegion(localeId)) {
    return <PageNotFound />;
  }

  const onAnswer = <QuestionId extends keyof ExpatEuDependent>(
    questionId: QuestionId,
    answer: ExpatEuDependent[QuestionId]
  ) => {
    dispatch(
      storeDependentGenericQuestionnaireAnswer({
        [questionId]: answer,
        ...(questionId === 'intro' ? { isPolicyHolder: false } : {}),
      })
    );

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(removeDependentGenericQuestionnaireAnswer(answersToRemove));

    const toUpdate = prefillNameAndBirthDate(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );
    if (toUpdate) {
      dispatch(storeDependentGenericQuestionnaireAnswer(toUpdate));
    }
  };

  const continueFromBlocker = () => {
    dispatch(
      storeDependentGenericQuestionnaireAnswer({
        employedInRegionBlocker: true,
      })
    );
    history.push(expatEuRoutes.quoteOptions.path);
  };

  // TODO: Move this country-handling logic where it's centralized, translatable and reusable (f.e. i18n library)
  const region =
    localeId === 'nl' ? 'the Netherlands' : regionToNameMapping[localeId];

  const questions = getTranslatedQuestionnaire(
    t,
    region,
    localeId,
    continueFromBlocker
  );

  const basePath = generatePath(routes.policies.expatEu.dependents.path, {
    localeId: String(localeId),
    mainPolicyId,
  });

  const baseQuestionnairePath =
    routes.policies.expatEu.dependents.questionnaire.path
      .replace(':localeId', String(localeId))
      .replace(':mainPolicyId', mainPolicyId);

  const expatEuRoutes = getRoutes(questions, basePath);

  const questionnaire = {
    components: questions,
    routes: expatEuRoutes,
    rules,
  };

  return (
    <Switch>
      <Route path={baseQuestionnairePath}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: ExpatEuDependentComponents,
          }}
          basePath={basePath}
          questionId="intro"
          featureName="ExpatEuDependent"
        />
      </Route>
    </Switch>
  );
};
