import { Task, TaskType } from '../taskEngine.models';

/**
 * This function provides a way to filter tasks that are behind feature flags.
 *
 * To use it:
 * create a new feature flag on the Unleash admin panel
 * add a new TaskType to the taskMapping dictionary
 * check the status of the newly created flag using the isTaskEnabled function.
 *
 * Any task that is not in the mapping will be included in the resulting array.

 * The task engine provides a fallback for tasks that haven't been implemented on the frontend,
 * so any tasks that don't have a UI component will be skipped.
 *
 *
 * --- NOTE ---
 * If you edit this function, don't forget to edit the mock implementation
 * located in src/features/taskEngine/TaskEngine.test.tsx
 */
export const useGetTasksWithFeatureFlags = (tasks: Task[]) => {
  const taskMapping: Partial<Record<TaskType, boolean>> = {};

  return tasks.filter((task) => {
    /**
     * Skip the check if the task is not in the mapping.
     */
    if (taskMapping[task.description.type] === undefined) return true;

    return Boolean(taskMapping[task.description.type]);
  });
};
