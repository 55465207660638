import { Policy } from 'models/policies';

/**
 * Returns a string with all active policies,
 * separated by a URL safe character
 *
 * @param policies
 *
 * @returns string
 */
export const getInsuranceParamFromPolicies = (policies?: Policy[]): string => {
  if (!policies) return '';

  const insuranceTypes = policies.reduce((acc: string[], policy: Policy) => {
    // Filter out inactive policies
    if (['ACTIVE', 'COVERED'].indexOf(policy.status) === -1) {
      return acc;
    }

    // Filter out repeated insurance types
    if (acc.indexOf(policy.type) === -1) {
      acc.push(policy.type);
    }

    return acc;
  }, []);

  return insuranceTypes.join(',');
};
