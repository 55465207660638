import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import browserHistory from 'shared/browserHistory';
import { emailAddressValidator } from 'shared/util/emailValidator';

export interface LocationWithState {
  from: {
    hash: string;
    pathname: string;
    search: string;
  };
}

interface EmailFromUrl {
  authenticate: boolean;
  emailParam: string | null;
  referrer: string | null;
  clearEmailParam: (clearState?: boolean) => void;
  referrerParam?: string;
}

export const useGetEmailFromUrl = (): EmailFromUrl => {
  const location = useLocation<LocationWithState>();
  const [authenticate, setAuthenticate] = useState<boolean>(false);
  const [emailParam, setEmailParam] = useState<string | null>(null);

  useEffect(() => {
    const search = location?.search || location?.state?.from?.search;
    if (search) {
      const query = new URLSearchParams(search);
      const emailParamFromUrl = query.get('authenticate');

      if (typeof emailParamFromUrl !== 'string') return;

      /**
       * The plus `+` sign is used to add empty spaces in the URLs
       * so in order to support email addresses with plus signs (email+tag@test.com)
       * we have to replace empty characters with the plus sign
       */
      const trimmedEmail = emailParamFromUrl.replace(' ', '+');

      if (emailAddressValidator(trimmedEmail)) {
        setAuthenticate(true);
        setEmailParam(trimmedEmail);
      }
    }
  }, [location]);

  const clearEmailParam = (clearState?: boolean) => {
    /**
     * If the state param is defined, replace location with this value
     * otherwise, use the previous location state
     */
    if (clearState) browserHistory.replace(location.pathname, {});
    setEmailParam(null);
  };

  return {
    authenticate,
    emailParam,
    clearEmailParam,
    referrer: location?.state?.from?.pathname ?? null,
    referrerParam: location?.state?.from?.search,
  };
};
