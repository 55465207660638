import { universities } from '@getpopsure/public-models';
import { AutoSuggestInput, Button } from '@popsure/dirty-swan';
import { Option } from '@popsure/dirty-swan/dist/esm/lib/models/autoSuggestInput';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { HealthQuestionnaireAnswers } from 'models/recommendationTool';
import { University } from 'models/recommendationTool/healthQuestionnaire';
import { useState } from 'react';

import styles from './style.module.scss';

const UniversityName = (
  props: CustomComponentProps<HealthQuestionnaireAnswers>
) => {
  const { value: defaultValue, onSubmitValue } = props;
  const [university, setUniversity] = useState<University>(
    (defaultValue as University) ?? ''
  );
  const [selectedValues, setSelectedValues] = useState<Array<Option>>([]);
  const [suggestions, setSuggestions] = useState<Array<Option>>([]);

  const handleSkipClick = () => onSubmitValue();

  const handleSelectUniversity = () => {
    if (university) onSubmitValue(university);
  };

  return (
    <div className={styles.container}>
      <div className="p-body">
        <h1 className="p-h1">What’s the university’s name?</h1>
        <div className="mt16 w100 wmx6">
          <AutoSuggestInput
            placeholder="University name"
            currentOption={university ?? ''}
            suggestions={suggestions}
            onChange={(value) => {
              setUniversity(value);
            }}
            handleSuggestionSelected={(value) => {
              setSelectedValues([...selectedValues, value]);
              setUniversity(value.value);
            }}
            handleSuggestionFetchRequest={({ value }) => {
              const filteredOptions = universities
                .map((option) => ({ value: option }))
                .filter((option) =>
                  option.value.toLowerCase().includes(value.toLowerCase())
                );
              setSuggestions(filteredOptions);
            }}
            handleSuggestionClearRequest={() => setSuggestions([])}
            wrapText
          />
        </div>
        <div className={`d-flex f-wrap mt24 ${styles['button-container']}`}>
          <Button
            className="w100"
            disabled={!university}
            onClick={handleSelectUniversity}
          >
            Continue
          </Button>
          <Button
            className="w100"
            variant="textColor"
            onClick={handleSkipClick}
          >
            My university is not listed
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UniversityName;
