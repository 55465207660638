import { TFunction } from '@getpopsure/i18n-react';

import { questionPropLookup } from '../lookups';
import { DisabilityV2Questionnaire } from '.';

export const getExtremeSportsQuestionnaire = (
  t: TFunction
): DisabilityV2Questionnaire => [
  {
    id: 'participatesInRockClimbing',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).participatesInRockClimbing,
    },
  },
  {
    id: 'rockClimbingDetails',
    groupId: 'signup',
    type: 'CHECKBOX',
    props: {
      mapValue: {
        OVER_4000_METERS_ALTITUDE: t(
          'disabilityV2.qnr.signup.rockClimbingDetails.over4000MetersAltitude',
          'I go over 4000m of altitude'
        ),
        NO_RECOMMENDED_SAFETY_EQUIPMENT: t(
          'disabilityV2.qnr.signup.rockClimbingDetails.noRecommendedSafetyEquipment',
          'I do not use recommended safety equipment'
        ),
        ICE_OR_GLACIER_CLIMBING: t(
          'disabilityV2.qnr.signup.rockClimbingDetails.iceOrGlacierClimbing',
          'I climb ice or glaciers'
        ),
        NONE_OF_THE_ABOVE: t(
          'disabilityV2.qnr.signup.rockClimbingDetails.noneOfTheAbove',
          'None of the above'
        ),
      },
    },
    screen: {
      ...questionPropLookup(t).rockClimbingDetails,
    },
  },
  {
    id: 'participatesInScubaDiving',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).participatesInScubaDiving,
    },
  },
  {
    id: 'scubaDivingDetails',
    groupId: 'signup',
    type: 'CHECKBOX',
    props: {
      mapValue: {
        DEEPER_THAN_50_METERS: t(
          'disabilityV2.qnr.signup.scubaDivingDetails.deeperThan50Meters',
          'I go deeper than 50m'
        ),
        CAVE_OR_ICE_DIVING: t(
          'disabilityV2.qnr.signup.scubaDivingDetails.caveOrIceDiving',
          'I go diving in caves or ice'
        ),
        REBREATHER_OR_SOLO_DIVING: t(
          'disabilityV2.qnr.signup.scubaDivingDetails.rebreatherOrSoloDiving',
          'I go diving with a rebreather or solo diving'
        ),
        NONE_OF_THE_ABOVE: t(
          'disabilityV2.qnr.signup.scubaDivingDetails.noneOfTheAbove',
          'None of the above'
        ),
      },
    },
    screen: {
      ...questionPropLookup(t).scubaDivingDetails,
    },
  },
  {
    id: 'participatesInBoulderingAndMountainSports',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).participatesInBoulderingAndMountainSports,
    },
  },
  {
    id: 'participatesInExtremeMountainSports',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).participatesInExtremeMountainSports,
    },
  },
  {
    id: 'participatesInMartialArts',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).participatesInMartialArts,
    },
  },
  {
    id: 'participatesInCompetitiveMartialArts',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).participatesInCompetitiveMartialArts,
    },
  },
  {
    id: 'participatesInHorseRiding',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).participatesInHorseRiding,
    },
  },
  {
    id: 'participatesInEquestrianSports',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).participatesInEquestrianSports,
    },
  },
  {
    id: 'participatesInCompetitiveRacing',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).participatesInCompetitiveRacing,
    },
  },
  {
    id: 'participatesInAviationSports',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).participatesInAviationSports,
    },
  },
];
