import { trackTaskEngineCardClicked } from '@getpopsure/analytics';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTask } from 'features/taskEngine/api/taskEngine.api';
import { CustomTask } from 'features/taskEngine/components/CustomTask';
import { updateTaskMutationErrorHandler } from 'features/taskEngine/utils/updateTaskMutationErrorHandler';
import { APIResponseError } from 'models/error';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import { TASK_ENGINE_SENTRY_CATEGORY } from '../../constants';
import {
  PrivateHealthTaxIdUpdateTaskSchema,
  Task,
  TaskEngineOptions,
} from '../../taskEngine.models';

export const PrivateHealthTaxIdUpdate = ({
  task,
  options,
}: {
  task: Task;
  options?: TaskEngineOptions;
}) => {
  const queryClient = useQueryClient();
  const { t } = useSafeTranslation();
  const [showModal, setShowModal] = useState(false);

  const parsedTask = PrivateHealthTaxIdUpdateTaskSchema.safeParse(task);

  const mutation = useMutation({
    mutationFn: (germanTaxId: string) => {
      return updateTask({
        taskId: task.id,
        payload: { germanTaxId },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });

  // If the task is not valid, return null
  if (!parsedTask.success) {
    return null;
  }

  const {
    id,
    description: {
      type,
      attributes: { fullName },
    },
  } = parsedTask.data;

  const openModal = () => {
    trackTaskEngineCardClicked({
      task_type: type,
      task_id: id,
    });

    if (options?.onTaskClick) {
      options.onTaskClick(task);
    }

    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const germanTaxId = formData.get('tax-id');

    if (typeof germanTaxId !== 'string') {
      throw new Error(
        `${TASK_ENGINE_SENTRY_CATEGORY}: PrivateHealthTaxIdUpdate Tax ID task missing for update`
      );
    }

    mutation.mutate(germanTaxId, {
      onSuccess: () => setShowModal(false),
      onError: (error) => updateTaskMutationErrorHandler(error),
    });
  };

  const subtitle = `${t(
    'taskEngine.privateHealthTaxIdUpdate.subtitle',
    'Private'
  )} · ${fullName}`;

  return (
    <CustomTask
      metadata={{
        title: t(
          'taskEngine.privateHealthTaxIdUpdate.title',
          'Add your tax ID'
        ),
        subtitle,
        description: t(
          'taskEngine.privateHealthTaxIdUpdate.modal.description',
          'Your tax ID (steuerliche Identifikationsnummer or Steuer-ID) is a unique number automatically assigned and sent to you when you first register in Germany. This is not your tax number (Steuernummer). \n\nWith your tax ID we can pass on the amounts of the tax reducing health insurance costs to the tax authorities so you automatically pay less tax.'
        ),
      }}
      modal={{
        isOpen: showModal,
        loading: mutation.isLoading,
        error: mutation.isError
          ? (mutation.error as APIResponseError).response?.data?.message
          : null,
        onOpen: openModal,
        onClose: closeModal,
      }}
      onSubmit={handleSubmit}
      actions={[
        {
          id: '1',
          type: 'INPUT',
          attributes: {
            name: 'tax-id',
            type: 'text',
            placeholder: t(
              'taskEngine.privateHealthTaxIdUpdate.modal.input.placeholder',
              'Tax ID'
            ),
            pattern: '^[a-zA-Z0-9]{11}$',
            required: true,
          },
        },
        {
          id: '2',
          type: 'SUBMIT',
          attributes: {
            value: t(
              'taskEngine.privateHealthTaxIdUpdate.modal.button.title',
              'Submit'
            ),
          },
        },
      ]}
    />
  );
};
