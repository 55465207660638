import { allCountryCodes } from '@getpopsure/public-models';
import { isDateFuture } from '@getpopsure/public-utility';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { emailAddressValidator } from 'shared/util/emailValidator';
import { z } from 'zod';

// Needed for .isValid() function to work properly
// https://day.js.org/docs/en/parse/is-valid
dayjs.extend(customParseFormat);
export const DATE_FORMAT = 'YYYY-MM-DD';
export const FORMATTED_DATE_FORMAT = 'DD MMM YYYY';

export const ZGenderSchema = z.enum(['MALE', 'FEMALE', 'OTHER']);

export const ZEmailSchema = z.string().refine(
  (emailStr) => emailAddressValidator(emailStr),
  (emailStr) => ({
    validation: 'email',
    code: 'invalid_string',
    message: `'${emailStr}' is not a valid email string`,
  })
);

export const ZDateStringSchema = z.string().refine(
  (dateStr) => dayjs(dateStr, DATE_FORMAT, true).isValid(),
  (dateStr) => ({
    code: 'invalid_date',
    message: `'${dateStr}' is not a valid date string`,
  })
);

export const ZFutureDateString = z
  .string()
  .refine(
    (dateStr) => dayjs(dateStr, DATE_FORMAT, true).isValid(),
    (dateStr) => ({
      code: 'invalid_date',
      message: `'${dateStr}' is not a valid date string.`,
    })
  )
  .refine(
    (dateStr) => isDateFuture(dateStr),
    (dateStr) => {
      return {
        code: 'too_small',
        message: `'${dateStr}' is not in the future.`,
      };
    }
  );

export const ZNameSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
});

export const ZCountrySchema = z.object({
  code: z.enum(allCountryCodes),
  name: z.string(),
});

export const ZUploadedDocumentsSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.string(),
  progress: z.number(),
  token: z.string(),
  previewUrl: z.string(),
});

export const ZPayoutDetailsSchema = z.object({
  amount: z.number(),
  iban: z.string(),
});

export const ZAddressSchema = z.object({
  additionalInformation: z.string().optional(),
  city: z.string(),
  country: z.string(),
  houseNumber: z.string(),
  postcode: z.string(),
  street: z.string(),
});

export const ZUserWithBrokerMandateSchema = z.object({
  id: z.string(),
  email: ZEmailSchema,
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  gender: ZGenderSchema.nullish(),
  dateOfBirth: z.coerce.date().nullish(),
  svnr: z.string().nullish(),
  isOnUnifiedPayments: z.boolean().nullish(),
  dataRemovalRequests: z.array(z.string()),
  signedBrokerMandate: z.boolean(),
});
