import { BottomOrRegularModal } from '@popsure/dirty-swan';
import routes from 'constants/routes';
import { IntercomLoaderWrapper } from 'features/intercomLauncher';
import { Trans, useSafeTranslation } from 'shared/i18n';

interface DelinquencyBlockerProps {
  onClose: () => void;
  isOpen: boolean;
  description?: string;
}

const DelinquencyBlocker = ({
  onClose,
  isOpen,
  description,
}: DelinquencyBlockerProps) => {
  const { t } = useSafeTranslation();

  return (
    <BottomOrRegularModal
      dismissible={true}
      onClose={onClose}
      isOpen={isOpen}
      title={t(
        'account.delinquencyBlocker.title',
        'You have an outstanding payment'
      )}
    >
      <div className="px24 py24">
        {description && <p className="p-p mb24">{description}</p>}
        <Trans i18nKey="account.delinquencyBlocker.core.description">
          <p className="p-p mb24">
            Please contact us to resolve the issue and provide the preferred
            payment method for collecting the missing funds. You can also check
            the{' '}
            <a className="p-a" href={routes.account.billingHistory.path}>
              billing history
            </a>{' '}
            in your Feather account to see which payments failed.
          </p>
        </Trans>
        <IntercomLoaderWrapper
          render={({ handleClick }) => (
            <button
              type="button"
              className="p-btn--primary ws3"
              onClick={handleClick}
            >
              {t('account.delinquencyBlocker.speakToUs.label', 'Speak to us')}
            </button>
          )}
        />
      </div>
    </BottomOrRegularModal>
  );
};

export default DelinquencyBlocker;
