import { Card } from '@popsure/dirty-swan';

import { whyFeatherContent } from './content';
import styles from './WhyFeather.module.scss';

export const WhyFeather = () => (
  <div className={`d-grid gap16 mt24 ${styles.container}`}>
    {whyFeatherContent.map(({ id, title, icon, description }) => (
      <Card
        key={id}
        title={title}
        icon={
          <img
            src={icon}
            alt=""
            width={32}
            height={32}
            className={styles.icon}
          />
        }
        description={description}
        dropShadow={false}
        classNames={{
          wrapper: styles.card,
        }}
      />
    ))}
  </div>
);
