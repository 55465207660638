import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { BillingAction } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import { APIResponseError } from 'models/error';
import { Dispatch } from 'redux';
import api from 'shared/api';

import {
  createBillingHistoryReceipt,
  getBillingHistoryCharges,
  getBillingHistoryDisputeInvoices,
  getBillingHistoryReceipt,
} from '../api';
import { Billing, DisputeInvoice, Receipt } from '../models';
import { updateBillingStatusForRefunds } from '../utils';

// Successful, pending and failed charges
// ----------------------------------------------------------------------------
const storeBillingHistory = (
  billings: Billing[],
  hasMorePages: boolean,
  fetchAfter: string | undefined
): BillingAction => ({
  type: 'STORE_BILLINGS',
  billings,
  hasMorePages,
  fetchAfter,
});

const updateBillingHistory = (
  billings: Billing[],
  hasMorePages: boolean,
  fetchAfter: string | undefined
): BillingAction => ({
  type: 'UPDATE_BILLINGS',
  billings,
  hasMorePages,
  fetchAfter,
});

export const updateCharge = (billing: Billing): BillingAction => ({
  type: 'UPDATE_BILLING',
  billing,
});

export const retrieveBillingHistory = () => async (dispatch: Dispatch) => {
  const requestType: RequestType = 'GET_BILLING_HISTORY';
  dispatch(setRequestInProcess(true, requestType));

  try {
    const {
      data: { charges, hasMorePages, fetchAfter },
    } = await getBillingHistoryCharges(api.network);
    const billings = charges.map((billing: Billing) =>
      updateBillingStatusForRefunds(billing)
    );

    dispatch(storeBillingHistory(billings, hasMorePages, fetchAfter));
    dispatch(setRequestInProcess(false, requestType));
  } catch (error) {
    dispatch(setRequestErrored(error as APIResponseError, requestType));
  }
};

export const retrieveNextBillingHistoryPage =
  (fetchAfterParam: string) => async (dispatch: Dispatch) => {
    const requestType: RequestType = 'GET_NEXT_BILLING_HISTORY_PAGE';
    dispatch(setRequestInProcess(true, requestType));

    try {
      const {
        data: { charges, hasMorePages, fetchAfter },
      } = await getBillingHistoryCharges(api.network, fetchAfterParam);
      const billings = charges.map((billing: Billing) =>
        updateBillingStatusForRefunds(billing)
      );

      dispatch(updateBillingHistory(billings, hasMorePages, fetchAfter));
      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

// Receipts
// ----------------------------------------------------------------------------
const updateChargeReceipt = (receipt: Receipt): BillingAction => ({
  type: 'UPDATE_CHARGE_RECEIPT',
  receipt,
});

export const requestReceipt =
  (stripeId: string) => async (dispatch: Dispatch) => {
    const requestType: RequestType = 'REQUEST_BILLING_HISTORY_RECEIPT';
    dispatch(setRequestInProcess(true, requestType));

    try {
      const { data } = await createBillingHistoryReceipt(api.network, stripeId);

      dispatch(updateChargeReceipt(data));
      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

export const getReceipt = (stripeId: string) => async (dispatch: Dispatch) => {
  const requestType: RequestType = 'GET_BILLING_HISTORY_RECEIPT';
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { data } = await getBillingHistoryReceipt(api.network, stripeId);

    dispatch(updateChargeReceipt(data));
    dispatch(setRequestInProcess(false, requestType));
  } catch (error) {
    dispatch(setRequestErrored(error as APIResponseError, requestType));
  }
};

// Dispute invoices
// ----------------------------------------------------------------------------

export const storeDisputeInvoices = (
  disputeInvoices: DisputeInvoice[]
): BillingAction => ({
  type: 'STORE_DISPUTE_INVOICES',
  disputeInvoices,
});

export const retrieveDisputeInvoices = () => async (dispatch: Dispatch) => {
  const requestType: RequestType = 'GET_BILLING_HISTORY_DISPUTE_INVOICES';
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { data } = await getBillingHistoryDisputeInvoices(api.network);

    dispatch(storeDisputeInvoices(data.invoices));
    dispatch(setRequestInProcess(false, requestType));
  } catch (error) {
    dispatch(setRequestErrored(error as APIResponseError, requestType));
  }
};
