import { Address } from '@getpopsure/public-models';
import type { ClaimsAction, RequestAction } from 'constants/actions';
import { TFunction } from 'i18next';
import { InsuranceTypes } from 'models/insurances/types';
import { PartialQuestionnaire } from 'models/questionnaireFramework';
import type { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { PayoutDetails, UploadedFile } from 'shared/models/types';

export type Questionnaire = PartialQuestionnaire<LiabilityClaims, GroupId>;
export type QuestionId = keyof LiabilityClaims;

export type GroupId = 'questions';

export type DateOfEventError = 'FUTURE_DATE' | 'AFTER_POLICY_START_DATE';

export type LiabilityClaimType =
  | 'INJURY_TO_OTHERS'
  | 'PROPERTY_DAMAGE'
  | 'KEY_LOST'
  | 'OTHER';

export const claimTypeMapping = (
  t: TFunction
): { [K in LiabilityClaimType]: string } => ({
  INJURY_TO_OTHERS: t(
    'claims.liability.questionnaire.claimtype.injurytoothers',
    'Physical injury to others'
  ),
  PROPERTY_DAMAGE: t(
    'claims.liability.questionnaire.claimtype.propertydamage',
    'Property damage'
  ),
  KEY_LOST: t(
    'claims.liability.questionnaire.claimtype.keylost',
    'Loss of keys'
  ),
  OTHER: t('claims.liability.questionnaire.claimtype.misc', 'Other'),
});

export interface LiabilityClaims {
  intro: boolean;
  dateOfEvent: string;
  claimType: LiabilityClaimType;
  description: string;
  uploadedDocumentTokens: UploadedFile[];
  thirdPartyName: string;
  thirdPartyEmail: string;
  thirdPartyAddress: Address;
  thirdPartyEmailBlocker: boolean;
  thirdPartyAddressBlocker: boolean;
  payoutDetails: PayoutDetails;
  processing: boolean;
  submitted: boolean;
}

export interface LiabilityClaimsMetaData {
  policyId: string;
  policyStartDate: string;
}

export type LiabilityClaimsDispatch = ThunkDispatch<
  AppState,
  unknown,
  RequestAction | ClaimsAction
>;

export interface SubmitInfo {
  type: InsuranceTypes;
  userPolicyId: string;

  dateOfEvent: string;
  claimType: LiabilityClaimType;
  description: string;
  uploadedDocumentTokens: string[];
  thirdPartyName?: string;
  thirdPartyEmail?: string;
  thirdPartyAddress?: Address;
  amount?: number;
  iban?: string;
}
