import { Policy } from 'models/policies';

import { CancelPolicyButton, ContactUsButton } from '../../components/buttons';
import { PolicyDetailsButton } from '../../sections/PolicyDetails/PolicyDetails.models';
import { CompanyHealthClaimButton } from './components/CompanyHealthClaim';

export const buttonsForCompanyHealthPolicy = (
  policy: Policy
): PolicyDetailsButton[] => {
  const buttonArray: PolicyDetailsButton[] = [
    { id: 'CONTACT_US', component: ContactUsButton },
  ];

  if (policy.status === 'ACTIVE') {
    buttonArray.push({
      id: 'COMPANY_HEALTH_CLAIM',
      component: CompanyHealthClaimButton,
    });
  }

  if (policy.status !== 'CANCELED') {
    buttonArray.push({ id: 'CANCEL_POLICY', component: CancelPolicyButton });
  }

  return buttonArray;
};
