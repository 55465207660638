import { ErrorWithAction } from 'components/ErrorWithAction';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import routes from 'constants/routes';
import {
  flushLiabilityClaimsAnswers,
  retrievePolicyStartDate,
  storeLiabilityClaimsAnswers,
} from 'features/liabilityClaims/actions';
import View from 'features/liabilityClaims/LiabilityClaims.view';
import type {
  LiabilityClaims as LiabilityClaimsModel,
  LiabilityClaimsDispatch,
  LiabilityClaimsMetaData,
} from 'features/liabilityClaims/models';
import { translatedQuestionnaire } from 'features/liabilityClaims/questionnaire';
import { getLiabilityClaims } from 'features/liabilityClaims/selectors';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

export const LiabilityClaims = () => {
  const [policyStartDate, setPolicyStartDate] = useState<string | null>('');

  const { t } = useSafeTranslation();
  const dispatch = useDispatch<LiabilityClaimsDispatch>();
  const history = useHistory();

  const answers = useSelector(getLiabilityClaims) ?? {};
  const { policyId }: { policyId: string } = useParams();

  const { loading: fetchPolicyLoading, error: fetchPolicyError } =
    useRequestStatus('LOAD_MY_POLICY_DETAIL');

  const flushAnswers = useCallback(() => {
    dispatch(flushLiabilityClaimsAnswers());
  }, [dispatch]);

  useEffect(() => {
    const handleRetrievePolicyStartDate = async () => {
      const response = await dispatch(retrievePolicyStartDate(policyId));
      setPolicyStartDate(response);
    };

    handleRetrievePolicyStartDate();

    return flushAnswers;
  }, [policyId, dispatch, flushAnswers]);

  const handleSubmitAnswer = (answer: Partial<LiabilityClaimsModel>) => {
    dispatch(storeLiabilityClaimsAnswers(answer));
  };

  if (fetchPolicyLoading) return <TimedLoadSpinner />;

  if (fetchPolicyError || policyStartDate === null) {
    const handleResetFlow = async () => {
      const startPath = generatePath(routes.me.policies.detail.path, {
        policyId,
      });
      await flushAnswers();
      history.push(startPath);
    };

    return (
      <ErrorWithAction
        title={t(
          'claims.liability.mainerror.title',
          'You are missing some info'
        )}
        description={t(
          'claims.liability.mainerror.description',
          'It seems like you are missing some info. Click to go back to policy page and try again.'
        )}
        cta={{
          title: t('claims.liability.mainerror.cta', 'Go to policy'),
          onClick: handleResetFlow,
        }}
      />
    );
  }

  const metaData: LiabilityClaimsMetaData = {
    policyId,
    policyStartDate,
  };

  return (
    <View
      questionnaireAnswers={answers}
      questionnaire={translatedQuestionnaire(t, metaData)}
      handleAnswerQuestion={handleSubmitAnswer}
    />
  );
};
