import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { fetchAccountInfo } from 'actions/user';
import { RequestAction } from 'constants/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import {
  GenericQuestionnaireAction,
  GenericQuestionnaireState,
} from 'reducers/genericQuestionnaire';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';

export type CreateQuoteDispatch<Key extends keyof GenericQuestionnaireState> =
  ThunkDispatch<
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<Key> | RequestAction
  >;

export type CreateQuoteReturnType<Questionnaire> = {
  status: ActionResponse;
  result?: Partial<Questionnaire>;
};

export type CreatePostQuoteAction<
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof GenericQuestionnaireState
> = ThunkAction<
  Promise<CreateQuoteReturnType<Questionnaire>>,
  AppState,
  Record<string, unknown>,
  GenericQuestionnaireAction<Key> | RequestAction
>;

export const validateAccount =
  (email: string, onUserExists: (userExists: boolean) => void) =>
  async (
    dispatch: ThunkDispatch<
      AppState,
      Record<string, unknown>,
      | GenericQuestionnaireAction<keyof GenericQuestionnaireState>
      | RequestAction
    >
  ) => {
    dispatch(setRequestInProcess(true, 'VALIDATE_ACCOUNT'));

    try {
      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      dispatch(setRequestInProcess(false, 'VALIDATE_ACCOUNT'));

      onUserExists(userExists);

      if (userExists) {
        await endpoint.sendSignInEmail(email);
        return;
      }
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'VALIDATE_ACCOUNT')
      );
    }
  };

export const signinAccount =
  (
    code: string,
    email: string,
    verticalId: keyof GenericQuestionnaireState,
    onLogin?: (email?: string) => void
  ) =>
  async (
    dispatch: ThunkDispatch<
      AppState,
      Record<string, unknown>,
      | GenericQuestionnaireAction<keyof GenericQuestionnaireState>
      | RequestAction
    >
  ) => {
    dispatch(setRequestInProcess(true, 'SIGN_IN_WITH_LOGIN_CODE'));

    try {
      await endpoint.signInWithTemporaryLoginCode(code, email);

      const user = await dispatch(fetchAccountInfo());

      const hasFullName = !!(user?.firstName && user.lastName);
      const userAnswers = {
        email: user?.email,
        ...(hasFullName
          ? {
              name: {
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
              },
            }
          : {}),
      };

      dispatch(storeGenericQuestionnaireAnswer(verticalId, userAnswers));

      dispatch(setRequestInProcess(false, 'SIGN_IN_WITH_LOGIN_CODE'));

      onLogin?.(user?.email);
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'SIGN_IN_WITH_LOGIN_CODE')
      );
    }
  };
