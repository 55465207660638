export const formatCardExpiryDate = ({
  month,
  year,
}: {
  month: string;
  year: string;
}): string => {
  const paddedMonth = month.padStart(2, '0');
  const paddedYear = year.slice(-2);

  return `${paddedMonth} / ${paddedYear}`;
};

export const formatCardLastDigits = (lastDigits: string) => {
  return `•••• ${lastDigits}`;
};
