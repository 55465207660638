import { CardButton } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { NavButton } from 'components/NavButton';
import { QuestionReviewValue } from 'models/review';
import { FormEvent } from 'react';
import AnimateHeight from 'react-animate-height';
import { TFunction } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';

import styles from './style.module.scss';

interface Props {
  onSubmit: (e: FormEvent) => void;
  loading?: boolean;
  error?: string;
  questionReviewValues: QuestionReviewValue[];
  t: TFunction;
}

export const ReviewView = ({
  loading,
  error,
  onSubmit,
  questionReviewValues,
  t,
}: Props) => (
  <form className={`mt80 p-body ${styles.container}`} onSubmit={onSubmit}>
    {isMobileApp && <NavButton title="Previous question" renderInPortal />}
    <h1 className="p-h1">
      {t('claims.bike.review.header', 'Review and submit')}
    </h1>
    <div className="p-p mt8">
      {t(
        'claims.bike.review.description',
        'Before submitting your claim, review or edit the information you’ve provided so far.'
      )}
    </div>
    <div className="mt24">
      {questionReviewValues.map(({ title, value, path }) => {
        if (!title || !path || !value) {
          return <></>;
        }

        return (
          <CardButton
            key={`${title}-${value}`}
            className="mt8 wmx5"
            title={title}
            description={value}
            href={path}
          />
        );
      })}
    </div>
    <AnimateHeight duration={300} height={error ? 'auto' : 0}>
      <div className="p-notice p-notice--warning mt16 wmx5">{error}</div>
    </AnimateHeight>
    <button
      className={classNames('p-btn--primary mt24 wmn3 mb80', {
        'p-btn--loading': loading,
      })}
      data-cy="button-review"
      type="submit"
    >
      {t('claims.bike.review.button', 'Continue')}
    </button>
  </form>
);
