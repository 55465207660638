import dayjs from 'dayjs';
import { Policy } from 'models/policies';

export type PolicyStatus =
  | 'ACTION_NEEDED'
  | 'ACTIVE'
  | 'PROCESSING'
  | 'PENDING'
  | 'CANCELED'
  | 'WITHDRAWN'
  | 'NOT_ELIGIBLE'
  | 'MANAGED_BY_PROVIDER'
  | 'DROPPED_OUT'
  | null;

export type PolicyStatusOrNull = PolicyStatus | null;

const getPolicyStatus = (
  { createdAt, providerName, status, type }: Policy,
  showActiveStatus = false
): PolicyStatusOrNull => {
  if (['ACTIVE', 'COVERED'].includes(status)) {
    if (
      type === 'PUBLIC_HEALTH' &&
      createdAt &&
      providerName &&
      dayjs().diff(dayjs(createdAt), 'month') > 6
    ) {
      return 'MANAGED_BY_PROVIDER';
    }

    if (showActiveStatus) {
      return 'ACTIVE';
    }
  }

  if (
    [
      'APPLICATION_RECEIVED',
      'APPLICATION_SENT',
      'PROCESSING',
      'ERR_MISSING_INFO',
    ].includes(status)
  ) {
    return 'PROCESSING';
  }

  if (status === 'PENDING' || status === 'CANCELED') {
    return status;
  }

  if (status === 'DROPPED_OUT') {
    return 'WITHDRAWN';
  }

  if (['ERR_NOT_ELIGIBLE', 'CANT_BE_COVERED'].includes(status)) {
    return 'NOT_ELIGIBLE';
  }

  return null;
};

export { getPolicyStatus };
