import { HealthQuestionnaireAnswers } from 'models/recommendationTool';
import {
  InsuranceSituationCurrentInsurance,
  InsuranceSituationPreviousInsurance,
  PreviousInsurance,
} from 'models/recommendationTool/healthQuestionnaire';

const insuranceSituationCurrentInsuranceAnswerObject = (
  answer: InsuranceSituationCurrentInsurance
) => ({
  insuranceSituationCurrentInsurance: answer,
  ...([
    'CURRENTLY_ON_PUBLIC_IN_EU',
    'CURRENTLY_ON_PRIVATE_IN_DE',
    'CURRENTLY_ON_PUBLIC_IN_DE',
  ].includes(answer)
    ? { previousInsurance: answer }
    : {}),
});

const insuranceSituationCurrentInsuranceRetrieveNextPageId = (
  answer: InsuranceSituationCurrentInsurance
) => {
  if (
    [
      'CURRENTLY_ON_PUBLIC_IN_EU',
      'CURRENTLY_ON_PRIVATE_IN_DE',
      'CURRENTLY_ON_PUBLIC_IN_DE',
    ].includes(answer)
  ) {
    return 'occupation';
  }
  return 'currentInsuranceInsuredInGermany';
};

const insuranceSituationPreviousInsuranceAnswerObject = (
  answer: InsuranceSituationPreviousInsurance
): Partial<HealthQuestionnaireAnswers> => ({
  insuranceSituationPreviousInsurance: answer,
  ...(answer === 'LAST_INSURANCE_IN_DE_WAS_PRIVATE'
    ? { previousInsurance: answer }
    : {}),
  ...(answer === 'LAST_INSURANCE_IN_DE_WAS_EXPAT' || answer === 'NONE'
    ? { previousInsurance: 'NONE' }
    : {}),
});

const insuranceSituationPreviousInsuranceRetrieveNextPageId = (
  answer: InsuranceSituationPreviousInsurance
) => {
  switch (answer) {
    case 'LAST_INSURANCE_IN_EU_WAS_PUBLIC':
      return 'publicInLastThreeMonths';
    case 'LAST_INSURANCE_IN_EU_WAS_PRIVATE':
      return 'previousInsuranceInsuredInGermany';
    default:
      return 'occupation';
  }
};

const currentInsuranceInsuredInGermanyAnswerObject = (answer: boolean) => ({
  currentInsuranceInsuredInGermany: answer,
  ...(answer === false
    ? // TODO: Remove forced typing
      { previousInsurance: 'CURRENTLY_ON_PRIVATE_IN_EU' as PreviousInsurance }
    : {}),
});

const currentInsuranceInsuredInGermanyRetrieveNextPageId = (
  answer: boolean
) => {
  if (answer) {
    return 'currentInsuranceLastInsuranceInGermany';
  }

  return 'occupation';
};

const currentInsuranceLastInsuranceInGermanyAnswerObject = (
  answer: boolean
) => ({
  currentInsuranceLastInsuranceInGermany: answer,
  ...(answer
    ? // TODO: Remove forced typing
      {
        previousInsurance:
          'RECENTLY_ON_PUBLIC_IN_DE_OR_EU' as PreviousInsurance,
      }
    : { previousInsurance: 'CURRENTLY_ON_PRIVATE_IN_EU' as PreviousInsurance }),
});

const previousInsuranceInsuredInGermanyAnswerObject = (answer: boolean) => ({
  previousInsuranceInsuredInGermany: answer,
  ...(answer === false
    ? // TODO: Remove forced typing
      {
        previousInsurance:
          'LAST_INSURANCE_IN_EU_WAS_PRIVATE' as PreviousInsurance,
      }
    : {}),
});

const previousInsuranceLastInsuranceInGermanyAnswerObject = (
  answer: boolean
) => ({
  previousInsuranceLastInsuranceInGermany: answer,
  ...(answer
    ? // TODO: Remove forced typing
      {
        previousInsurance:
          'RECENTLY_ON_PUBLIC_IN_DE_OR_EU' as PreviousInsurance,
      }
    : {
        previousInsurance:
          'LAST_INSURANCE_IN_EU_WAS_PRIVATE' as PreviousInsurance,
      }),
});

export const retrieveAnswerObject = {
  insuranceSituationCurrentInsuranceAnswerObject,
  insuranceSituationPreviousInsuranceAnswerObject,
  currentInsuranceInsuredInGermanyAnswerObject,
  currentInsuranceLastInsuranceInGermanyAnswerObject,
  previousInsuranceInsuredInGermanyAnswerObject,
  previousInsuranceLastInsuranceInGermanyAnswerObject,
};

export const retrieveNextPageId = {
  insuranceSituationCurrentInsuranceRetrieveNextPageId,
  insuranceSituationPreviousInsuranceRetrieveNextPageId,
  currentInsuranceInsuredInGermanyRetrieveNextPageId,
};
