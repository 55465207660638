import Markdown from 'components/markdown';

import styles from './styles.module.scss';

export interface Props {
  title: string;
  description: string | React.ReactNode;
  icon: { src: string; alt: string };
  className?: string;
}

export const GettingStartedCard = ({
  className,
  title,
  description,
  icon,
}: Props) => (
  <div
    className={`d-flex ai-center p32 wmx8 bg-white br8 ${styles.card} ${className}`}
  >
    <img className={`mr24 ${styles.icon}`} src={icon.src} alt={icon.alt} />
    <div>
      <h2 className="p-h4">{title}</h2>
      {typeof description === 'string' ? (
        <Markdown paragraphClassName="p-p tc-grey-600 mt8" openLinksInNewTab>
          {description}
        </Markdown>
      ) : (
        <div className="p-p tc-grey-600 mt8">{description}</div>
      )}
    </div>
  </div>
);
