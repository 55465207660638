import Account from 'components/signupAuthentication/account';
import routes from 'constants/routes';

const LiabilityAccount = () => (
  <Account
    verticalId="liability"
    newAccountPath={routes.policies.liability.email.path}
    existingAccountPath={routes.policies.liability.questionnaire.name.path}
  />
);

export default LiabilityAccount;
