import Email from 'components/signupAuthentication/email';
import routes from 'constants/routes';

const LiabilityEmail = () => (
  <Email
    verticalId="liability"
    redirectPath={routes.policies.liability.questionnaire.name.path}
  />
);

export default LiabilityEmail;
