import { HealthCardActions } from 'constants/actions';
import { HealthCard } from 'models/healthCard';
import { Reducer } from 'redux';

interface HealthCardState {
  [id: string]: HealthCard;
}

export const defaultState: HealthCardState = {};

const healthCard: Reducer<HealthCardState, HealthCardActions> = (
  state = defaultState,
  action
): HealthCardState => {
  switch (action.type) {
    case 'MERGE_HEALTH_CARD_DETAILS': {
      return {
        ...state,
        [action.payload.id]: action.payload.details,
      };
    }
    default:
      return state;
  }
};

export default healthCard;
