import { Address } from '@getpopsure/public-models';
import { CheckoutInfo } from 'features/checkout/models';
import { ActionResponse } from 'shared/models/types';
import { ZAddressSchema, ZNameSchema } from 'shared/models/validations';
import type { BLOCKER, SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

export const ZQuote = z.object({
  id: z.string(),
  plan: z.enum(['DEDUCTIBLE', 'NON_DEDUCTIBLE']),
  price: z.number(),
  isDeductible: z.boolean(),
  deductibleAmount: z.number(),
});

export type Quote = z.TypeOf<typeof ZQuote>;

export type DogQuote = {
  isDeductible: boolean;
  deductibleAmount: number;
  price: number;
};

export const NUMBER_OF_PREVIOUS_CLAIMS = [
  'NONE',
  'ONE',
  'TWO_OR_MORE',
] as const;

type NumberOfPreviousClaims = typeof NUMBER_OF_PREVIOUS_CLAIMS[number];

export type CheckoutPolicyDetails = {
  deductibleAmount: number;
  dogName: string;
};

export type Dog = {
  intro: SCREEN;
  isLivingInGermany: boolean;
  nonGermanResidentBlocker: BLOCKER;
  numberOfPreviousClaims: NumberOfPreviousClaims;
  previousClaimsTotalAmount: number;
  pastClaimsBlocker: BLOCKER;
  isMixedBread: 'YES' | 'NO';
  breed: string;
  dangerousBreedsBlocker: BLOCKER;
  account: boolean;
  email: string;
  dogName: string;
  name: { firstName: string; lastName: string };
  dateOfBirth: string;
  ageBlocker: BLOCKER;
  address: Address;
  startDate: string;
  review: boolean;
  quote: Quote;

  checkoutInfo: CheckoutInfo<CheckoutPolicyDetails>;
  reviewCheckout: boolean;
  checkout: boolean;

  quoteId: string;
  questionnaireId: string;
};

export type CreateQuoteReturnType = {
  status: ActionResponse;
};

const DogAnswerKeys = [
  'isLivingInGermany',
  'numberOfPreviousClaims',
  'previousClaimsTotalAmount',
  'isPureBreed',
  'pureBreedName',
  'dogName',
  'email',
  'name',
  'dateOfBirth',
  'address',
  'isInterestedInDogHealthInsurance',
] as const;

type DogAnswerKey = typeof DogAnswerKeys[number];

export const ZSubmittableDogQuestionnaire = z
  .object({
    name: ZNameSchema,
    email: z.string(),
    dateOfBirth: z.string(),
    address: ZAddressSchema,
    isLivingInGermany: z.boolean(),
    numberOfPreviousClaims: z.enum(NUMBER_OF_PREVIOUS_CLAIMS),
    previousClaimsTotalAmount: z.optional(z.number()),
    isMixedBread: z.enum(['YES', 'NO']),
    breed: z.optional(z.string()),
    dogName: z.string(),
    isDeductible: z.boolean(),
    deductibleAmount: z.number(),
    startDate: z.string(),
  })
  .transform(
    ({
      name,
      email,
      dateOfBirth,
      address,
      isLivingInGermany,
      numberOfPreviousClaims,
      previousClaimsTotalAmount,
      isMixedBread,
      breed,
      dogName,
      isDeductible,
      deductibleAmount,
      startDate,
    }) => ({
      personalInfo: {
        name,
        email,
        dateOfBirth,
        address,
        isLivingInGermany,
        numberOfPreviousClaims,
        previousClaimsTotalAmount,
        isPureBreed: isMixedBread === 'NO',
        pureBreedName: breed,
        dogName,
      },
      tariffInfo: {
        isDeductible,
        deductibleAmount,
        startDate,
      },
    })
  );

export type SubmittableDogQuestionnaire = z.TypeOf<
  typeof ZSubmittableDogQuestionnaire
>;

export type PersonalInfo = SubmittableDogQuestionnaire['personalInfo'];

export type TariffInfo = SubmittableDogQuestionnaire['tariffInfo'];

export type DogMeta = {
  labels: {
    title: {
      personalInfo: Record<DogAnswerKey, string>;
      tariffInfo: Record<keyof TariffInfo, string>;
    };
    answers: {
      personalInfo: Partial<Record<DogAnswerKey, unknown>>;
      tariffInfo: Partial<Record<keyof TariffInfo, unknown>>;
    };
  };
  questionOrder: {
    personalInfo: DogAnswerKey[];
    tariffInfo: string[];
  };
};

export type DogGroupIds = 'preQuote' | 'signup';
