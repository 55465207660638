import type { HouseholdQuestionnaire } from 'features/recommendationTool/models/household';
import { getAccountInfo } from 'features/recommendationTool/questionnaires/account';

import { createProcessingQuestion, submitQuestionId } from '../processing';

const metersToFeet = (m: number) => 3.28084 * m;
const feetToMeters = (f: number) => f / 3.28084;

const householdQuestionnaire: HouseholdQuestionnaire = {
  totalLivingSpace: {
    required: true,
    type: 'NUMERIC_CONVERSION',
    props: {
      question: 'What’s the total living space of your home?',
      description:
        'The total living space should be written in your rental or purchase contract.',
      unitLabels: ['m²', 'sq. ft'],
      conversionFunctions: [metersToFeet, feetToMeters],
    },
    retrieveAnswerObject: ([totalLivingSpaceAsMeters]: [number]) => ({
      totalLivingSpace: totalLivingSpaceAsMeters,
    }),
    transformValue: (livingSpace?: number) => [
      livingSpace,
      livingSpace && metersToFeet(livingSpace),
      0,
    ],
    retrieveNextPageId: () => 'postalCode',
    groupId: 'personalInfo',
  },
  postalCode: {
    required: (answers) => !answers.postalCode,
    type: 'INPUT',
    props: {
      question: 'What is your postal code?',
    },
    retrieveAnswerObject: (postalCode: string) => ({
      postalCode,
    }),
    groupId: 'personalInfo',
    retrieveNextPageId: () => submitQuestionId,
  },
  ...createProcessingQuestion('household'),
};

const accountInfo = getAccountInfo<HouseholdQuestionnaire>('totalLivingSpace');

const householdQuestionnaireWithAccount: HouseholdQuestionnaire = {
  ...accountInfo,
  ...householdQuestionnaire,
};

export default householdQuestionnaireWithAccount;
