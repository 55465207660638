import { trackStructuredEvent } from '@getpopsure/analytics';
import { calendly } from '@getpopsure/private-constants';
import { BottomOrRegularModal, Button, Input } from '@popsure/dirty-swan';
import { FormEvent, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { MIN_TERM } from '../../../models';
import styles from './style.module.scss';
import { ErrorType, validate } from './validation';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: number) => void;
  term: number;
  maxTerm: number;
}

const handleBookAnAppointment = () => {
  trackStructuredEvent({
    category: 'LIFE_INSURANCE',
    action: 'CLICKED_BOOK_AN_APPOINTMENT',
  });
  window.Calendly.initPopupWidget({ url: calendly.lifeInsurance });
};

const TermModal = ({ isOpen, onClose, onChange, term, maxTerm }: Props) => {
  const [value, setValue] = useState(term);
  const [error, setError] = useState<ErrorType | null>(null);

  const errorMapping: { [K in ErrorType]: string } = {
    UNDER_MIN: `Our minimum term of coverage is ${MIN_TERM} years`,
    OVER_MAX: `The longest term we can offer is ${maxTerm} years`,
  };

  useEffect(() => {
    setValue(term);
    setError(null);
  }, [term, isOpen]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    setError(null);

    const validationError = validate(value, maxTerm);

    if (validationError) {
      setError(validationError);
      return;
    }

    onChange(value);
    onClose();
  };

  return (
    <BottomOrRegularModal
      title="Term of coverage"
      isOpen={isOpen}
      onClose={onClose}
    >
      <form className={styles.modal} onSubmit={onSubmit}>
        <p className="p-p">Please enter the desired number of years below: </p>
        <Input
          className="mt16 wmx5"
          placeholder="Term (years)"
          value={value ? String(value) : ''}
          onChange={(e) => setValue(Number(e.target.value))}
          pattern="[0-9]*"
          type="text"
          error={(error && errorMapping[error]) ?? undefined}
        />
        <AnimateHeight
          duration={300}
          height={error === 'OVER_MAX' ? 'auto' : 0}
        >
          <div className="p-notice p-notice--primary mt16 wmx5 p-p--small">
            <p>
              Based on our risk assessment, we can only offer you a maximum term
              of {maxTerm} years.
            </p>
            <p className="mt16">
              Do you have more questions?{' '}
              <button
                className={`p-a p-p--small c-pointer ${styles.calendlyButton}`}
                onClick={handleBookAnAppointment}
                value="Reach out to us "
                type="button"
              >
                for more information.
              </button>
            </p>
          </div>
        </AnimateHeight>
        <Button className="wmn3 mt16" disabled={!value}>
          Apply
        </Button>{' '}
      </form>
    </BottomOrRegularModal>
  );
};

export default TermModal;
