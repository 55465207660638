import { calendly, faq } from '@getpopsure/private-constants';
import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import MoreQuestionsSection from 'components/moreQuestions';
import ReviewBadge from 'components/reviewBadge';
import { createDisabilityQuote } from 'features/disabilityV2/actions';
import {
  DisabilityV2,
  ZQuestionnaireAnswersRequiredForCalculatorSchema,
} from 'features/disabilityV2/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import disabilityIcon from './assets/disabilityIcon.png';
import disabilityIcon2x from './assets/disabilityIcon2x.png';
import disabilityIcon3x from './assets/disabilityIcon3x.png';
import { CTASection } from './CTASection';
import { DisabilityCalculator } from './DisabilityCalculator';
import { FAQSection } from './FAQSection';
import { WhatsCoveredSection } from './WhatsCoveredSection';
import { WhatsNotCoveredSection } from './WhatsNotCoveredSection';

const MINIMUM_MONTHLY_PAYOUT = 100;
const MAXIMUM_EMPLOYED_MONTHLY_PAYOUT = 3_000;
const MAXIMUM_SELF_EMPLOYED_MONTHLY_PAYOUT = 2_000;
const SLIDER_STEP_SIZE = 5;

export const Quote = ({
  onSubmitValue,
  questionnaireAnswers,
}: QuestionnaireFormProps<DisabilityV2>) => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch();
  const { monthlyPayout: monthlyPayoutFromReduxState } = questionnaireAnswers;
  const parsedAnswers =
    ZQuestionnaireAnswersRequiredForCalculatorSchema.safeParse(
      questionnaireAnswers
    );

  if (!parsedAnswers.success) {
    throw new Error(
      `[Disability] Questionnaire answer validation errors:${parsedAnswers.error.toString()}`
    );
  }
  const { error } = useRequestStatus('CREATE_QUOTE');

  const { occupation, monthlyNetIncome } = parsedAnswers.data;

  const occupationMonthlyMaximumPayout =
    occupation === 'EMPLOYED'
      ? MAXIMUM_EMPLOYED_MONTHLY_PAYOUT
      : MAXIMUM_SELF_EMPLOYED_MONTHLY_PAYOUT;

  const maximumMonthlyPayout = Math.min(
    Math.floor(((monthlyNetIncome || 0) * 0.8) / SLIDER_STEP_SIZE) *
      SLIDER_STEP_SIZE,
    occupationMonthlyMaximumPayout
  );

  const defaultMonthlyPayout =
    Math.floor((maximumMonthlyPayout * 0.8) / SLIDER_STEP_SIZE) *
    SLIDER_STEP_SIZE;

  const [monthlyPayout, setMonthlyPayout] = useState(
    monthlyPayoutFromReduxState || defaultMonthlyPayout
  );

  const [pendingRequestCount, setPendingRequestCount] = useState(0);
  const [state, setState] = useState({
    monthlyPayout,
    quote: questionnaireAnswers.quote || undefined,
    quoteId: questionnaireAnswers.quote?.id || undefined,
  });

  const getQuote = async () => {
    dispatch(
      storeGenericQuestionnaireAnswer('disabilityV2', { monthlyPayout })
    );

    // Accommodate for slow connections by keeping track of requests to be able to show a loading spinner while at least one request is still pending.
    // TODO: fix typing for this fn
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const { result } = await dispatch(createDisabilityQuote(monthlyPayout));

    setState(result);
    setPendingRequestCount((count) => count - 1);
  };

  const price = state.quote?.price;

  const isLoading = pendingRequestCount > 0;

  const onContinueClick = () => {
    onSubmitValue(state.quote, {
      monthlyPayout,
      quote: state.quote,
      quoteId: state.quote?.id,
    });
  };

  return (
    <div className="d-flex fd-column">
      <div className="d-flex fd-column ai-center jc-center pt72">
        <img
          src={disabilityIcon}
          srcSet={`${disabilityIcon} 1x, ${disabilityIcon2x} 2x, ${disabilityIcon3x} 3x`}
          alt={t('disabilityV2.quote.image.alt', 'wheelchair')}
        />
        <h1 className="p-h1 p--serif mt24 mb8">
          {t('disabilityV2.quote.title', 'Disability insurance')}
        </h1>
      </div>
      <DisabilityCalculator
        error={error}
        getQuote={getQuote}
        isLoading={isLoading}
        monthlyPayout={monthlyPayout}
        onContinueClick={onContinueClick}
        price={price}
        setMonthlyPayout={setMonthlyPayout}
        min={MINIMUM_MONTHLY_PAYOUT}
        max={maximumMonthlyPayout}
        stepSize={SLIDER_STEP_SIZE}
      />
      <WhatsCoveredSection />
      <WhatsNotCoveredSection />
      <CTASection
        isLoading={isLoading}
        onContinueClick={onContinueClick}
        price={price}
      />
      <div className="bg-white py96">
        <div className="p-container d-flex ai-center jc-center">
          <ReviewBadge />
        </div>
      </div>
      <FAQSection noPaperWorkLimit={occupationMonthlyMaximumPayout} />
      <MoreQuestionsSection
        bookACallUrl={calendly.disabilityInsurance}
        bookACallGACategory="DISABILITY_INSURANCE"
        openInNewTab
        description={t(
          'disabilityV2.quote.moreQuestions.description',
          'Reach out to us for more information or check out our FAQ for answers to common questions about disability insurance.'
        )}
        secondaryAction={t(
          'disabilityV2.quote.moreQuestions.secondaryCTA.caption',
          'Visit our FAQ'
        )}
        secondaryActionLink={faq.base}
      />
    </div>
  );
};
