import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import { Disability } from 'features/disability/models';
import { getTranslatedQuestionnaire } from 'features/disability/questionnaire';
import { getDisabilityAnswers } from 'features/disability/selectors';
import Questionnaire from 'features/questionnaireFramework';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

export const Signup = () => {
  const { t } = useSafeTranslation();

  const answers = useSelector(getDisabilityAnswers) || {};
  const dispatch = useDispatch();

  const handleAnswerQuestion = (answer: Partial<Disability>) => {
    dispatch(storeGenericQuestionnaireAnswer('disability', answer));
  };

  const handleRemoveAnswer = (questionIds: (keyof Disability)[]) => {
    dispatch(removeGenericQuestionnaireAnswer('disability', questionIds));
  };

  return (
    <Questionnaire
      featureName="Disability"
      questionnaire={getTranslatedQuestionnaire(t)}
      questionnaireAnswers={answers}
      handleAnswerQuestion={handleAnswerQuestion}
      handleRemoveAnswer={handleRemoveAnswer}
      share={{
        verticalId: 'disability',
      }}
    />
  );
};
