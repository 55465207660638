import { BottomOrRegularModal, CurrencyInput } from '@popsure/dirty-swan';
import { useState } from 'react';

type InputModalProps = {
  aboveMaxErrorMessage: string;
  belowMinErrorMessage: string;
  buttonCaption: string;
  description: string;
  isOpen: boolean;
  max: number;
  min: number;
  onClose: () => void;
  placeholder: string;
  setValue: (value: number) => void;
  title: string;
  value: number;
};

export const InputModal = ({
  aboveMaxErrorMessage,
  belowMinErrorMessage,
  buttonCaption,
  description,
  isOpen,
  max,
  min,
  onClose,
  placeholder,
  setValue,
  title,
  value,
}: InputModalProps) => {
  const [inputErrorMessage, setInputErrorMessage] = useState('');
  const [currentInputValue, setCurrentInputValue] = useState(value);

  const handleOnClose = () => {
    // Clear error message when modal gets closed
    setInputErrorMessage('');
    onClose();
  };

  return (
    <BottomOrRegularModal isOpen={isOpen} title={title} onClose={handleOnClose}>
      <div className="wmx6 mt16 pb24 pl24 pr24">
        <p className="p-p mb16">{description}</p>
        <CurrencyInput
          value={value}
          placeholder={placeholder}
          onChange={(currentValue) => {
            let errorMessage = '';

            if (currentValue > max) {
              errorMessage = aboveMaxErrorMessage;
            }
            if (currentValue < min) {
              errorMessage = belowMinErrorMessage;
            }
            setInputErrorMessage(errorMessage);
            setCurrentInputValue(currentValue);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === 'Space') {
              if (currentInputValue <= max && currentInputValue >= min) {
                setValue(currentInputValue);
                onClose();
              }
            }
          }}
          error={inputErrorMessage}
        />
        <button
          disabled={!!inputErrorMessage}
          className="p-btn--primary wmn3 mt16"
          onClick={() => {
            setValue(currentInputValue);
            onClose();
          }}
        >
          {buttonCaption}
        </button>
      </div>
    </BottomOrRegularModal>
  );
};
