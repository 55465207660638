import { Beneficiary } from 'features/life/models';
import { Name } from 'models/user';
import { UploadedFile } from 'shared/models/types';
import { z } from 'zod';

export type EditBeneficiaries = {
  intro: boolean;
  review: boolean;
  name: Name;
  dateOfBirth: string;
  processing: boolean;
  submitted: boolean;
  currentBeneficiaryId?: string;
  uploads: UploadedFile[];
  policyHolderIdUpload: UploadedFile[];
  beneficiaries: Beneficiary[];
};

export const ZCreateBeneficiaries = z.object({
  beneficiariesWithUploadTokens: z.array(
    z.object({
      name: z.object({
        firstName: z.string(),
        lastName: z.string(),
      }),
      dateOfBirth: z.string(),
      payoutPercentage: z.number().gt(0).max(100),
      uploadTokens: z.array(z.string()).nonempty(),
    })
  ),
  identificationDocumentToken: z.string(),
});
