import {
  bannerColorMapping,
  imageSrcSetTypeMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';

import { CategoryLookup, Section } from '../models/lookups';

export const carCategory: CategoryLookup = {
  shortTitle: 'Car',
  title: 'Car insurance',
  excerpt: 'Protection in the case of a car accident',
  description:
    'Car insurance offers both liability protection and vehicle protection. The liability protection pays for the damage you cause to third-party vehicles, or other property (e.g., buildings or traffic facilities) as well as for personal injury and financial loss. Vehicle protection covers the damage caused to your vehicle by theft, weather, glass breakage, and wildlife-related accidents. The coverage can be extended to also cover damage caused by a collision with another vehicle, as well as unintentional self-inflicted damage and vandalism.',
  bannerColor: bannerColorMapping.CAR,
  icon: imageTypeMapping.CAR,
  iconSrcSet: imageSrcSetTypeMapping.CAR,
  enabled: false,
};

export const carSection: Section = {
  title: '',
  description: 'Car description',
  icon: '',
};
