import {
  getRoutes,
  PageNotFound,
  QuestionnaireQuestions,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import LoadingSpinner from 'components/loadingSpinner';
import { QuotePage } from 'components/QuotePage';
import routes from 'constants/routes';
import { UserWithBrokerMandate } from 'models/user';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';
import { getAccountInfo } from 'selectors/user';
import { SignupQuestionnaire } from 'SignupQuestionnaire';
import { SignupSpecificComponents } from 'SignupQuestionnaire/SignupQuestionnaire';

import { PreviewCheckout } from './components/Checkout/PreviewCheckout';
import { PreviewReview } from './components/Review/PreviewReview';
import { QuestionnaireJSON } from './model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const QuoteTransformer = ({ quote_page, ...props }: any) => {
  const data = quote_page?.data?.attributes;

  return (
    <QuotePage
      plans={[
        {
          price: 100,
          name: 'Basic',
        },
      ]}
      onBookACall={() => {}}
      // When plans change
      // eslint-disable-next-line no-console
      onOptionsChange={console.log}
      {...props}
      {...data}
    />
  );
};

const prefillNameAndBirthDate = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<QuestionnaireQuestions> = {};
    const { firstName, lastName } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    return toUpdate;
  }

  return null;
};

export const PreviewSignupPage = () => {
  const { verticalId } = useParams<{ verticalId: string }>();

  const [questionnaireAnswers, setQuestionnaireAnswers] =
    useState<QuestionnaireQuestions>({});

  const updateQuestionnaireAnswers = (data: QuestionnaireQuestions) => {
    setQuestionnaireAnswers((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const removeQuestionnaireAnswers = (questionnaireIds: string[] = []) => {
    setQuestionnaireAnswers((prev) => {
      return Object.entries(prev).reduce((acc, [id, value]) => {
        if (questionnaireIds.includes(id)) {
          return acc;
        }
        acc[id] = value;
        return acc;
      }, {} as QuestionnaireQuestions);
    });
  };

  const account = useSelector(getAccountInfo);

  const [json, setJson] = useState<QuestionnaireJSON | false>();

  useEffect(() => {
    const load = async () => {
      try {
        fetch(
          `${process.env.REACT_APP_STRAPI_URL}?filters[id]=${verticalId}&populate=deep`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
            },
          }
        )
          .then((data) => data.json())
          .then((questionnaire) => {
            if (questionnaire.data.length === 0) {
              setJson(false);
              return;
            }

            const [{ attributes }] = questionnaire.data;
            const questions = attributes.questions.map(
              // @TODO move the transformations to the API side
              // eslint-disable-next-line
              // @ts-ignore
              ({ basic, __component, ...data }: unknown) => ({
                ...basic,
                ...data,
                id: basic.questionId,
                props: data.props ?? {},
                screen: data.screen ?? {},
                type: String(
                  __component
                    .replace('questionnaire.', '')
                    .replace('-question', '')
                ).toUpperCase(),
              })
            );
            setJson({
              ...attributes,
              questions,
              removeAnswersLogic: {},
            });
            updateQuestionnaireAnswers({
              insuranceType: attributes.meta.insuranceType,
            });
          });
      } catch (e) {
        setJson(false);
      }
    };

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verticalId]);

  if (json === undefined) {
    return <LoadingSpinner />;
  }

  if (!json) {
    return <PageNotFound />;
  }

  const { meta, questions, rules, removeAnswersLogic } = json;

  const onAnswer = (
    questionId: string,
    answer: unknown,
    additionalData: Partial<Record<string, unknown>> = {}
  ) => {
    if (!json) {
      return;
    }
    updateQuestionnaireAnswers({
      ...additionalData,
      [questionId]: answer,
    });

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    removeQuestionnaireAnswers(answersToRemove);

    const toUpdate = prefillNameAndBirthDate(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );
    if (toUpdate) {
      updateQuestionnaireAnswers(toUpdate);
    }
  };

  const basePath = routes.policies.general.preview.path.replace(
    ':verticalId',
    verticalId
  );
  const questionnairePath =
    routes.policies.general.preview.questionnaire.path.replace(
      ':verticalId',
      verticalId
    );

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, basePath),
    rules,
  };

  return (
    <Switch>
      <Route path={questionnairePath}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: {
              ...SignupSpecificComponents,
              REVIEW: PreviewReview,
              CHECKOUT: PreviewCheckout,
              QUOTE: QuoteTransformer,
            },
            questions,
          }}
          basePath={basePath}
          questionId="intro"
          featureName={meta.insuranceType}
          share={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            verticalId,
          }}
        />
      </Route>
    </Switch>
  );
};
