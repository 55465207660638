import BooleanForm from 'components/questionnaireForms/BooleanForm';
import {
  ViewProps,
  withContainer,
} from 'features/liability/containers/withContainer';
import { getQuestionsMapping, QuestionId } from 'models/liability';
import { useSafeTranslation } from 'shared/i18n';

const questionId: QuestionId = 'currentlyLivingInGermany';

const LiabilityLivingInGermany = ({
  initialValue,
  onSubmitValue,
}: ViewProps<typeof questionId>) => {
  const { t } = useSafeTranslation();
  return (
    <BooleanForm
      value={initialValue}
      question={getQuestionsMapping(t)[questionId]}
      onSubmitValue={(value: boolean) => {
        onSubmitValue({ questionId, value });
      }}
      featureName="Liability"
    />
  );
};

export default withContainer(LiabilityLivingInGermany, questionId);
