import styles from './ArrowButton.module.scss';

export type ArrowButtonVariant = 'ARROW_LEFT' | 'ARROW_RIGHT';

type ArrowButtonProps = {
  isActive: boolean;
  variant: ArrowButtonVariant;
  onClick: () => void;
};

export const ArrowButton = ({
  isActive = false,
  onClick,
  variant,
}: ArrowButtonProps): JSX.Element => (
  <button
    onClick={onClick}
    className={`p-btn--secondary d-flex ${styles.arrowButton} ${
      variant === 'ARROW_LEFT' ? styles.left : styles.right
    }`}
    type="button"
    aria-label={variant === 'ARROW_LEFT' ? 'Previous' : 'Next'}
  >
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isActive ? styles.arrowActive : styles.arrowInactive}
    >
      <path
        d="M1 1.00003L7 7.00003L13 1.00003"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </button>
);
