import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, useState } from 'react';

import QuestionnaireWrapper from '../QuestionnaireWrapper';
import styles from './TextForm.module.scss';

interface Props
  extends QuestionnaireFormProps<string>,
    QuestionnaireWrapperProps {
  maxLength?: number;
}

export const TextForm = ({
  value,
  question,
  description,
  onSubmitValue,
  buttonText,
  header,
  loading,
  featureName,
  error,
  additionalInfo,
  placeholder,
  questionClassName = '',
  maxLength = 400,
}: Props) => {
  const [text, setText] = useState<string | undefined>(value);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!text) {
      throw new Error(
        `[${featureName}] Text value is missing for submission for question: ${question}`
      );
    }
    onSubmitValue(text);
  };

  return (
    <QuestionnaireWrapper
      header={header}
      question={question}
      questionClassName={questionClassName}
      description={description}
      onSubmit={handleSubmit}
      error={error}
      loading={loading}
      buttonText={buttonText}
      validForSubmission={!!text}
      additionalInfo={additionalInfo}
    >
      <textarea
        className={`p-input wmn3 ${styles.textbox}`}
        placeholder={placeholder}
        onChange={(e) => setText(e.target.value)}
        value={text}
        maxLength={maxLength}
        data-cy="input-text"
      />
    </QuestionnaireWrapper>
  );
};
