import { Address, isPartialAddressValid } from '@getpopsure/public-models';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import { ReferralUserDataForm } from 'features/referralEngine/components/ReferralUserDataForm';
import { ReferralUserData } from 'features/referralEngine/models';
import { APIResponseError } from 'models/error';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';
import { isValidIban } from 'shared/util/isValidIban';

import styles from './ReferralPayoutDetails.module.scss';

interface ReferralPayoutDetailsViewProps {
  referralData?: ReferralUserData;

  onSubmit(details: ReferralUserData): void;

  fetchLoading?: boolean;
  updateLoading?: boolean;
  fetchError?: APIResponseError;
  updateError?: APIResponseError;
}

export const ReferralPayoutDetailsView = (
  props: ReferralPayoutDetailsViewProps
) => {
  const {
    onSubmit,
    referralData,
    fetchLoading,
    updateLoading,
    fetchError,
    updateError,
  } = props;
  const [payoutDetails, setPayoutDetails] = useState<ReferralUserData>({
    iban: '',
    accountHolder: '',
  });

  const [address, setAddress] = useState<Partial<Address> | undefined>();
  const [ibanError, setIbanError] = useState<string | undefined>();
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const { t } = useSafeTranslation();

  useEffect(() => {
    if (isFirstRender && referralData) {
      const { iban, accountHolder, ...defaultAddress } = referralData;
      setPayoutDetails((prevState) => ({
        ...prevState,
        ...referralData,
      }));
      setAddress((prevState) => ({
        ...prevState,
        ...defaultAddress,
      }));
      setIsFirstRender(false);
    }
  }, [isFirstRender, referralData]);

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIbanError(undefined);

    if (!isValidIban(payoutDetails.iban)) {
      setIbanError('Enter a valid IBAN');
      return;
    }

    onSubmit({ ...payoutDetails, ...address });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement> | string) => {
    if (typeof e === 'string') {
      setPayoutDetails((prevState) => ({ ...prevState, iban: e }));
      return;
    }

    setPayoutDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAddressChange = (newAddress: Partial<Address>) =>
    setAddress((prevState) => ({ ...prevState, ...newAddress }));

  const { iban, accountHolder } = payoutDetails;

  const isValidForm = Boolean(
    iban &&
      accountHolder &&
      address &&
      isPartialAddressValid(address) &&
      isValidIban(iban)
  );

  return (
    <div className={`wmx7 ${styles.container}`}>
      <NavButton
        path={routes.account.path}
        title={t('account.backButton.caption', 'Account')}
      />
      <h1 className="p-h1 mb24">
        {t('referralengine.payoutdetails.title', 'Bonus payout details')}
      </h1>
      <p className="p-p mb24">
        {t(
          'referralengine.payoutdetails.subtitle',
          'Choose a bank account for your bonus payouts'
        )}
      </p>
      <ReferralUserDataForm
        handleFormSubmit={handleFormSubmit}
        handleInputChange={handleInputChange}
        handleAddressChange={handleAddressChange}
        isValidForm={isValidForm}
        address={address}
        iban={iban}
        accountHolder={accountHolder}
        fetchLoading={fetchLoading}
        updateLoading={updateLoading}
      />
      {ibanError && (
        <AnimateHeight duration={300} height={ibanError ? 'auto' : 0}>
          <div className="p-notice p-notice--danger mt16 p-p">{ibanError}</div>
        </AnimateHeight>
      )}
      {(updateError || fetchError) && (
        <AnimateHeight
          duration={300}
          height={updateError || fetchError ? 'auto' : 0}
        >
          <div className="p-notice p-notice--danger mt16 p-p">
            {updateError?.message || fetchError?.message}
          </div>
        </AnimateHeight>
      )}
    </div>
  );
};
