import { Button } from '@popsure/dirty-swan';
import { ExpatQuestionnaire } from 'features/expat/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';

import familyIcon from './assets/family.svg';
import styles from './DependentsToCover.module.scss';

export const DependentsToCover = ({
  onSubmitValue,
}: CustomComponentProps<ExpatQuestionnaire>) => {
  const { t } = useSafeTranslation();
  return (
    <div
      className={`p-container d-flex fd-column ai-start jc-center wmx10 ${styles.container}`}
    >
      <img src={familyIcon} alt="family icon" />
      <h1 className="p-h1 mt16 mb8 ta-center">
        {t('expat.qnr.dependentsToCover.title', 'Have loved ones to cover?')}
      </h1>
      <div className="p-p ai-start">
        {t(
          'expat.qnr.dependentsToCover.description',
          'After purchasing this policy, you can buy and manage additional policies for your loved ones.'
        )}
      </div>
      <div className="d-flex fd-row gap16 mt24 ai-center jc-center">
        <Button
          onClick={() => onSubmitValue(false)}
          data-cy="dependents-cover-no"
        >
          {t(
            'expat.qnr.dependentsToCover.noDependentsButton.cta',
            "No, it's just me"
          )}
        </Button>
        <Button
          onClick={() => onSubmitValue(true)}
          variant="filledWhite"
          data-cy="dependents-cover-yes"
        >
          {t(
            'expat.qnr.dependentsToCover.dependentsButton.cta',
            'Yes, ask me later'
          )}
        </Button>
      </div>
    </div>
  );
};
