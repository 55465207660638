import { reviews } from '@getpopsure/private-constants';

import googleLogo from '../img/google-logo.svg';
import googleLogoWhite from '../img/google-logo-white.svg';
import Rating from '../rating';
import styles from './style.module.scss';

// TODO: dynamically get google data
const rating = 4.8;
const numberOfReviews = 328;

const GoogleReviewBadge = ({
  displayReviews,
  fontWhite,
}: {
  displayReviews?: boolean;
  fontWhite?: boolean;
}) => (
  <a
    className={styles.container}
    href={reviews.google}
    target="_blank"
    rel="noopener noreferrer"
  >
    <div className={styles['inner-container']}>
      <div
        className={`mr8 p-h3 ${
          fontWhite ? styles['white-font'] : 'tc-grey-700'
        }`}
      >
        {rating.toFixed(1)}
      </div>
      <div className="mr8">
        <Rating rating={rating} reviewType="google" />
      </div>
      {!displayReviews &&
        (fontWhite ? (
          <img
            className={styles['logo-big']}
            src={googleLogoWhite}
            alt="google logo"
          />
        ) : (
          <img
            className={styles['logo-big']}
            src={googleLogo}
            alt="google logo"
          />
        ))}
    </div>
    {displayReviews && (
      <div className={`mt8 ${styles['inner-container']}`}>
        <div className="p-p--small tc-grey-600 fw-bold mr8">
          {numberOfReviews} <span className="fw-normal">reviews</span>
        </div>
        {fontWhite ? (
          <img
            className={styles['logo-small']}
            src={googleLogoWhite}
            alt="google logo"
          />
        ) : (
          <img
            className={styles['logo-small']}
            src={googleLogo}
            alt="google logo"
          />
        )}
      </div>
    )}
  </a>
);

export default GoogleReviewBadge;
