import {
  associatedCountryFlagForCountryCode,
  Country,
  getAllCountries,
} from '@getpopsure/public-models';
import { AutoSuggestMultiSelect } from '@popsure/dirty-swan';
import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import { filterOutCountries } from 'shared/util/filterOutCountries';

import QuestionnaireWrapper from '../QuestionnaireWrapper';
import styles from './CountryMultiForm.module.scss';

interface Props
  extends QuestionnaireFormProps<Country[]>,
    QuestionnaireWrapperProps {
  filterCountry?: Country[];
  locale?: Parameters<typeof getAllCountries>[0];
}

export const CountryMultiForm = ({
  value,
  question,
  description,
  onSubmitValue,
  buttonText,
  header,
  loading,
  featureName,
  error,
  additionalInfo,
  filterCountry,
  locale,
}: Props) => {
  const { t } = useSafeTranslation();

  const [countries, setCountries] = useState<Country[] | undefined>(value);

  const countriesList =
    filterCountry && filterCountry.length > 0
      ? filterOutCountries(filterCountry, locale)
      : getAllCountries(locale);

  const valid = countries && countries.length > 0;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!countries || countries.length < 1) {
      throw new Error(
        `[${featureName}] Countries value is missing for submission for question: ${question}`
      );
    }
    onSubmitValue(countries);
  };

  return (
    <QuestionnaireWrapper
      header={header}
      question={question}
      description={description}
      onSubmit={handleSubmit}
      buttonText={buttonText}
      validForSubmission={!!valid}
      loading={loading}
      error={error}
      additionalInfo={additionalInfo}
    >
      <div className={styles.selectContainer}>
        <AutoSuggestMultiSelect
          options={countriesList.map((country) => ({
            value: country.name,
            leftIcon: associatedCountryFlagForCountryCode(country.code),
          }))}
          setValues={(values) => {
            const selectedCountriesWithCode = countriesList.filter((country) =>
              values.map((val) => val.value).includes(country.name)
            );
            setCountries(selectedCountriesWithCode);
          }}
          selectedValues={countries?.map((country) => ({
            value: country.name,
            leftIcon: associatedCountryFlagForCountryCode(country.code),
          }))}
          placeholder={t(
            'qnf.forms.countryselect.country.placeholder',
            'Country'
          )}
          chipsListClassName="wmx9"
        />
      </div>
    </QuestionnaireWrapper>
  );
};
