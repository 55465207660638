import type { BLOCKER, SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

export const ZQuote = z.object({
  plan: z.enum(['SURGERY', 'STANDARD']),
  price: z.number(),
  isDeductible: z.boolean(),
});

export type Quote = z.TypeOf<typeof ZQuote>;

export type PetHealthQuote = {
  isDeductible: boolean;
  deductibleAmount: number;
  price: number;
};

export type PetHealth = {
  intro: SCREEN;
  species: 'CAT' | 'DOG';
  isPureBreed: 'YES' | 'NO';
  breed: string;
  shoulderHeight: 'SMALLER_EQUAL_44_CM' | 'OVER_44_CM';
  dateOfBirth: string;
  raceGroup: string;
  tooYoungBlocker: BLOCKER;
  ageBlocker: BLOCKER;
  quote: Quote;
  redirect: boolean;
};

export const ZSubmittablePetHealthQuestionnaire = z.union([
  z.object({
    species: z.literal('DOG').optional(),
    isPureBreed: z.enum(['YES', 'NO']),
    breed: z.optional(z.string()).optional(),
    shoulderHeight: z.enum(['SMALLER_EQUAL_44_CM', 'OVER_44_CM']).optional(),
    dateOfBirth: z.string(),
    raceGroup: z.string(),
  }),
  z.object({
    species: z.literal('CAT'),
    dateOfBirth: z.string(),
  }),
]);

export type SubmittablePetHealthQuestionnaire = z.TypeOf<
  typeof ZSubmittablePetHealthQuestionnaire
>;

export type PetHealthIds = 'preQuote';
