import routes from 'constants/routes';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';

export const RecommendationToolRouting = () => {
  const { search } = useLocation();
  const insuranceToolWithSearchParams = `${routes.insuranceTool.intro.path}${
    search ? `?${search}` : ''
  }`;
  return (
    <Switch>
      <Route
        exact={true}
        path={routes.healthInsuranceTool.path}
        render={() => (
          <Redirect
            to={generatePath(insuranceToolWithSearchParams, {
              insuranceType: 'health',
            })}
          />
        )}
      />
      <Route
        path={routes.healthInsuranceTool.v2.questionnaire.path}
        render={() => (
          <Redirect
            to={generatePath(insuranceToolWithSearchParams, {
              insuranceType: 'health',
            })}
          />
        )}
      />
    </Switch>
  );
};
