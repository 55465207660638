import { TFunction } from '@getpopsure/i18n-react';
import {
  BriefcaseIcon,
  CalendarIcon,
  ClockTimeIcon,
  CloudDrizzleIcon,
  EuroClaimIcon,
  HatGraduationIcon,
  HomeIcon,
  MinusCircleIcon,
  SofaIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const disability = (t: TFunction): AdditionalInfoType => ({
  price: 20,
  description: t(
    'policyModal.disability.description',
    'Also known as income projection insurance, it provides a monthly payout if you’re no longer able to work due to an unexpected disability, like a psychosomatic illness, accident, cancer, or heart disease. Disability affects around 25% of people for a least 6 months, yet public and private health insurance will only pay for the first 6 months. Disability insurance covers you until retirement or until you’re able to work again.'
  ),
  accordions: [
    {
      title: t('policyModal.disability.title2', 'Common cases'),
      items: [
        {
          id: 'common-1',
          question: t(
            'policyModal.disability.common1.question',
            'Chronic back pain'
          ),
          answer: t(
            'policyModal.disability.common1.answer',
            'Sam is currently working as a chef but is recently diagnosed with chronic back pain and told by her doctor that because of how physically demanding her job is, she’ll never be able to work in gastronomy again.\n\nShe receives her disability insurance after the first 6 months. After that, she decides to go to a 5-month design boot camp and get a new job in another industry. She’ll continue paying her income insurance after her new employment begins.'
          ),
        },
        {
          id: 'common-2',
          question: t('policyModal.disability.common2.question', 'Cancer'),
          answer: t(
            'policyModal.disability.common2.answer',
            'Mark is diagnosed with thyroid cancer and needs to have his thyroid completely removed while taking medication and going through therapy to fight off the cancer. After 3 years, he feels well enough to continue his former work and continues to pay into income insurance at that point.\n\n15% of the people who will receive income insurance will have cancer-related occupational disabilities. In this scenario, they will receive their monthly payout until they can work or retire.'
          ),
        },
        {
          id: 'common-3',
          question: t('policyModal.disability.common3.question', 'Burnout'),
          answer: t(
            'policyModal.disability.common3.answer',
            'Mathias took on an internship in his dream industry where he worked upwards of 35 hours per week and studied simultaneously. It was exhausting, but he managed to get a good grade and graduate with a great start-up job.\n\nHe begins working upwards of 70 hours per week, while wearing multiple hats to ensure the company is successful. Four years later, the start-up, unfortunately, failed after an established foreign competitor joined the market.\n\nThe shock triggered his untreated burnout, and he became completely incapable of working or finding a new job. His doctor diagnosed him with long-term burnout, and now his income insurance is the only source of income.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.disability.title1', 'What it helps with'),
      items: [
        {
          id: 'helps-1',
          icon: <HomeIcon size={24} />,
          question: t(
            'policyModal.disability.helps1.question',
            'Debt and mortgage'
          ),
          answer: t(
            'policyModal.disability.helps1.answer',
            'If you currently have any debt or a mortgage, disability insurance will compensate for any lost income and allow you to meet monthly repayments for these financial obligations'
          ),
        },
        {
          id: 'helps-2',
          icon: <EuroClaimIcon size={24} />,
          question: t('policyModal.disability.helps2.question', 'Lost income'),
          answer: t(
            'policyModal.disability.helps2.answer',
            'If you depend on your current income, insurance will jump in to provide you with a higher income than unemployment would (which some people might not even be entitled to).'
          ),
        },
        {
          id: 'helps-3',
          icon: <HatGraduationIcon size={24} />,
          question: t(
            'policyModal.disability.helps3.question',
            'Childcare and education'
          ),
          answer: t(
            'policyModal.disability.helps3.answer',
            'If you’re paying for the education of your child or partner, having a safety net in case of a potential disability would provide the necessary finances to continue.'
          ),
        },
        {
          id: 'helps-4',
          icon: <SofaIcon size={24} />,
          question: t(
            'policyModal.disability.helps4.question',
            'Personal lifestyle'
          ),
          answer: t(
            'policyModal.disability.helps4.answer',
            'If you’re used to going on vacation, going out to eat, going to the movies, or doing other things that require your current income, disability insurance will help you keep this standard of living.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.disability.title3', "What's not covered"),
      items: [
        {
          id: 'not-covered-1',
          icon: <BriefcaseIcon size={24} />,
          question: t(
            'policyModal.disability.notCovered1.question',
            'Performance-based unemployment'
          ),
          answer: t(
            'policyModal.disability.notCovered1.answer',
            'If you get fired or quit your job and are physically and mentally fit, then insurance will not cover this. It covers you if you are incapable of working to a certain extent and not because you wish to remain unemployed.'
          ),
        },
        {
          id: 'not-covered-2',
          icon: <ClockTimeIcon size={24} />,
          question: t(
            'policyModal.disability.notCovered2.question',
            'Short-term illnesses'
          ),
          answer: t(
            'policyModal.disability.notCovered2.answer',
            'Short-term illnesses like the flu or food poisoning that last about a week or a broken wrist that lasts 6 weeks are almost always covered under health insurance and your employer’s sick leave.'
          ),
        },
        {
          id: 'not-covered-3',
          icon: <MinusCircleIcon size={24} />,
          question: t(
            'policyModal.disability.notCovered3.question',
            'Excluded occupations'
          ),
          answer: t(
            'policyModal.disability.notCovered3.answer',
            'Certain dangerous occupations aren’t covered, like working with hazardous substances, in crisis areas, at a height of more than 10 meters, in aviation, in diving, in mountain sports, in martial arts, in motor sports, and in other high-risk fields.'
          ),
        },
        {
          id: 'not-covered-4',
          icon: <CloudDrizzleIcon size={24} />,
          question: t(
            'policyModal.disability.notCovered4.question',
            'Intentionally caused illnesses'
          ),
          answer: t(
            'policyModal.disability.notCovered4.answer',
            'Intentionally caused occupational disabilities are not covered (including self-harm and attempted suicide). '
          ),
        },
      ],
    },
  ],
  details: [
    {
      icon: CalendarIcon,
      title: t(
        'policyModal.disability.details.title',
        'One month’s notice for cancellations'
      ),
      description: t(
        'policyModal.disability.details.description',
        'Your plan will remain active until the end of the current month plus the next month.'
      ),
    },
  ],
  buttonText: t('policyModal.disability.buttonText', 'Continue to quote'),
});
