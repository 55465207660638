import { getCheckoutInfo } from 'features/checkout/selectors';
import View from 'features/liability/pages/checkout/components/policyDetails/view';
import type { PolicyDetails as LiabilityPolicyDetails } from 'models/liability';
import { useSelector } from 'react-redux';

const PolicyDetails = () => {
  const checkoutInfo = useSelector(
    getCheckoutInfo<LiabilityPolicyDetails>('LIABILITY')
  );

  return <View checkoutInfo={checkoutInfo} />;
};

export default PolicyDetails;
