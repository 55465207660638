import { CurrencyInput, Input } from '@popsure/dirty-swan';
import { NavButton } from 'components/NavButton';
import QuestionnaireWrapper from 'components/questionnaireForms/QuestionnaireWrapper';
import { FormEvent } from 'react';
import AnimateHeight from 'react-animate-height';
import { TFunction } from 'shared/i18n';
import { PayoutDetails } from 'shared/models/types';
import { isMobileApp } from 'shared/util/isMobileApp';

interface Props {
  handleSubmit: (e: FormEvent) => void;
  t: TFunction;
  loading: boolean;
  error?: string;
  validForSubmission: boolean;
  payoutDetails: PayoutDetails;
  setPayoutDetails: (payoutDetails: PayoutDetails) => void;
  minAmount: number;
  ibanError?: string | null;
}

export const HouseholdClaimsPayoutDetailsView = ({
  handleSubmit,
  t,
  loading,
  error,
  validForSubmission,
  payoutDetails,
  setPayoutDetails,
  minAmount,
  ibanError,
}: Props) => (
  <QuestionnaireWrapper
    question={t('claims.household.payoutDetails.question', 'Payout details')}
    onSubmit={handleSubmit}
    validForSubmission={validForSubmission}
    loading={loading}
    error={error}
    buttonText={t('claims.household.payoutDetails.button', 'Submit claim')}
  >
    <>{isMobileApp && <NavButton title="Previous question" renderInPortal />}</>
    <h2 className="p-h4">
      {t('claims.household.payoutDetails.amount.label', 'Claim amount')}
    </h2>
    <p className="p-p--small mt8">
      {t(
        'claims.household.payoutDetails.amount.description',
        "You can skip this if you don't know the amount yet"
      )}
    </p>
    <CurrencyInput
      className="mt8"
      value={payoutDetails.amount}
      onChange={(value) =>
        setPayoutDetails({ ...payoutDetails, amount: value })
      }
      placeholder={t(
        'claims.household.payoutDetails.amount.placeholder',
        'Amount'
      )}
      min={minAmount}
    />
    <h2 className="mt24 p-h4">
      {t('claims.household.payoutDetails.iban.label', 'Your IBAN')}
    </h2>
    <p className="p-p--small mt8">
      {t(
        'claims.household.payoutDetails.iban.description',
        'The bank account where you’ll receive the payout if approved'
      )}
    </p>
    <Input
      className="mt8"
      value={payoutDetails.iban}
      onChange={(e) =>
        setPayoutDetails({ ...payoutDetails, iban: e.target.value })
      }
      placeholder={t('claims.household.payoutDetails.iban.placeholder', 'IBAN')}
      required
    />
    <AnimateHeight duration={300} height={ibanError ? 'auto' : 0}>
      <p className="p-notice p-notice--warning mt16">{ibanError}</p>
    </AnimateHeight>
  </QuestionnaireWrapper>
);
