import { TFunction } from '@getpopsure/i18n-react';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import type { CoverageTableDataEntry } from 'components/CoverageDetails/CoverageModal';

import damageIcon from './assets/damage.svg';
import documentIcon from './assets/document.svg';
import featherIcon from './assets/feather.svg';
import houseIcon from './assets/house.svg';
import shieldIcon from './assets/shield.svg';

const getFeatherGuaranteeTableData = (
  t: TFunction
): CoverageTableDataEntry => ({
  title: t('liability.coveragedetails.1.title', 'Feather Guarantee'),
  iconSrc: featherIcon,
  rows: [
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.1.section.1.text',
            'We’ll cover any liability claim another liability insurance would'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'liability.coveragedetails.1.section.1.smalltext',
              '(With a comparable tariff)'
            )}
          </p>
        </>,
        true,
      ],
    },
  ],
});

const getHomeTableData = (t: TFunction): CoverageTableDataEntry => ({
  title: t(
    'liability.coveragedetails.2.title',
    'Apartments, homes, and rentals'
  ),
  iconSrc: houseIcon,
  rows: [
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.2.section.1.text',
            'Liability claims for owning or renting a home or land'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'liability.coveragedetails.2.section.1.smalltext',
              '(Only the home or land you’re living in or using for personal use is covered)'
            )}
          </p>
        </>,
        true,
      ],
    },
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.2.section.2.text',
            'Damage to rented property'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'liability.coveragedetails.2.section.2.smalltext',
              '(Exclusions include wear and tear, utility heating systems, broken glass, and mold growth)'
            )}
          </p>
        </>,
        true,
      ],
    },
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.2.section.3.text',
            'Lost keys and property belonging to other individuals'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'liability.coveragedetails.2.section.3.smalltext',
              '(Please see policy documents for exclusions)'
            )}
          </p>
        </>,
        true,
      ],
    },
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.2.section.4.text',
            'Damage to an individual or their property is covered if they are unable to pay'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'liability.coveragedetails.2.section.4.smalltext',
              '(Financial losses are not included)'
            )}
          </p>
        </>,
        true,
      ],
    },
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.2.section.5.text',
            'Damages from renting an apartment or home'
          )}
          <p className="p-p--small tc-grey-500">
            {t('liability.coveragedetails.2.section.5.smalltext', ' ')}
          </p>
        </>,
        true,
      ],
    },
    {
      cells: [
        <>
          {t('liability.coveragedetails.2.section.6.text', 'Gradual damages')}
          <p className="p-p--small tc-grey-500">
            {t(
              'liability.coveragedetails.2.section.6.smalltext',
              '(From Temperature, Gases, Steam or moisture, Effects of exposure to: smoke, soot, dust and the like)'
            )}
          </p>
        </>,
        true,
      ],
    },
  ],
});

const getDamageTableData = (t: TFunction): CoverageTableDataEntry => ({
  title: t('liability.coveragedetails.3.title', 'Damage or injury'),
  iconSrc: damageIcon,
  rows: [
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.3.section.1.text',
            'Unintentional negligence'
          )}
          <p className="p-p--small tc-grey-500">
            {t('liability.coveragedetails.3.section.1.smalltext', ' ')}
          </p>
        </>,
        t('liability.coveragedetails.3.section.1.value', {
          defaultValue: '{{limit}}',
          limit: englishFormattedEuroCurrency(20_000, true),
        }),
      ],
    },
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.3.section.2.text',
            'Liability claims from sport, exercising, and biking'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'liability.coveragedetails.3.section.2.smalltext',
              '(Electric bikes are only covered under 25km/h)'
            )}
          </p>
        </>,
        true,
      ],
    },
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.3.section.3.text',
            'Liability claims as a pet owner'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'liability.coveragedetails.3.section.3.smalltext',
              '(Only small pets are included. Dogs are excluded with the exception of guide dogs)'
            )}
          </p>
        </>,
        true,
      ],
    },
  ],
});

const getEmploymentTableData = (t: TFunction): CoverageTableDataEntry => ({
  title: t('liability.coveragedetails.4.title', 'Employment'),
  iconSrc: documentIcon,
  rows: [
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.4.section.1.text',
            'Some self-employed / freelance activities'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'liability.coveragedetails.4.section.1.smalltext',
              '(See the full list of professions in the policy documents)'
            )}
          </p>
        </>,
        t('liability.coveragedetails.4.section.1.value', {
          defaultValue: '{{limit}} annually',
          limit: englishFormattedEuroCurrency(15_000, true),
        }),
      ],
    },
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.4.section.2.text',
            'Coverage continuation for 12 months when unemployed'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'liability.coveragedetails.4.section.2.smalltext',
              "(Your coverage will continue if you can't pay due to unemployment. Some restrictions apply. See policy details for more information)"
            )}
          </p>
        </>,
        true,
      ],
    },
  ],
});

const getAdditionalCoveragesTableData = (
  t: TFunction
): CoverageTableDataEntry => ({
  title: t('liability.coveragedetails.5.title', 'Additional Coverages'),
  iconSrc: shieldIcon,
  rows: [
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.5.section.1.text',
            'Protection if another person cannot pay a liability claim against them'
          )}
          <p className="p-p--small tc-grey-500">
            {t('liability.coveragedetails.5.section.1.smalltext', ' ')}
          </p>
        </>,
        true,
      ],
    },
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.5.section.2.text',
            'Liability claims caused by identity theft'
          )}
          <p className="p-p--small tc-grey-500">
            {t('liability.coveragedetails.5.section.2.smalltext', ' ')}
          </p>
        </>,
        true,
      ],
    },
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.5.section.3.text',
            'Worldwide coverage'
          )}
          <p className="p-p--small tc-grey-500">
            {t('liability.coveragedetails.5.section.3.smalltext', ' ')}
          </p>
        </>,
        true,
      ],
    },
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.5.section.4.text',
            "Coverage for personal injury when the other person isn't known"
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'liability.coveragedetails.5.section.4.smalltext',
              '(Must be an intentional criminal act filed with the police)'
            )}
          </p>
        </>,
        <>
          {t('liability.coveragedetails.5.section.4.value', {
            defaultValue: 'Physical harm {{limit}}',
            limit: englishFormattedEuroCurrency(50_000, true),
          })}
          <p className="p-p--small tc-grey-500">
            {t(
              'liability.coveragedetails.5.section.4.valuesubtext',
              '(Does not include mental health treatment)'
            )}
          </p>
        </>,
      ],
    },
    {
      cells: [
        <>
          {t(
            'liability.coveragedetails.5.section.5.text',
            'Liability related legal insurance'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'liability.coveragedetails.5.section.5.smalltext',
              '(Please see policy documents for exclusions)'
            )}
          </p>
        </>,
        t('liability.coveragedetails.5.section.5.value', {
          defaultValue: '{{limit}}',
          limit: englishFormattedEuroCurrency(150_000, true),
        }),
      ],
    },
  ],
});

export const getCoverageTableData = (
  t: TFunction
): CoverageTableDataEntry[] => [
  getFeatherGuaranteeTableData(t),
  getHomeTableData(t),
  getDamageTableData(t),
  getEmploymentTableData(t),
  getAdditionalCoveragesTableData(t),
];
