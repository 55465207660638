import { ErrorWithAction } from 'components/ErrorWithAction';
import QuoteLoading from 'components/quoteLoading';
import {
  flushPrivatePreSignupAnswers,
  PrivatePreSignupDispatch,
} from 'features/privateHealthPreSignup/actions';
import {
  PrivatePreSignup,
  ZPrivatePreSignupRequiredAnswersForRedirectSchema,
} from 'features/privateHealthPreSignup/models';
import { constructPrivateHealthUrl } from 'features/privateHealthPreSignup/util';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';

export const RedirectToPrivateSignup = ({
  questionnaireAnswers,
  routes,
}: CustomComponentProps<Partial<PrivatePreSignup>>) => {
  const { t } = useSafeTranslation();

  const account = useSelector(getAccountInfo);

  const dispatch = useDispatch<PrivatePreSignupDispatch>();
  const history = useHistory();
  const [error, setError] = useState<string | undefined>();

  const handleResetFlow = async () => {
    dispatch(flushPrivatePreSignupAnswers);
    history.push(routes.welcome?.path || '');
  };

  if (error) {
    return (
      <ErrorWithAction
        title={t(
          'private.qnr.redirectToPrivateSignup.error.title',
          'Something went wrong'
        )}
        description={t(
          'private.qnr.redirectToPrivateSignup.error.description',
          'Sorry, something didn’t work as it should. Starting over should address this issue.'
        )}
        cta={{
          title: t(
            'private.qnr.redirectToPrivateSignup.error.ctaCaption',
            'Start over'
          ),
          onClick: handleResetFlow,
        }}
      />
    );
  }

  let url = '';

  try {
    const validationResult =
      ZPrivatePreSignupRequiredAnswersForRedirectSchema.safeParse({
        ...questionnaireAnswers,
        name:
          account?.firstName && account?.lastName
            ? { firstName: account?.firstName, lastName: account?.lastName }
            : undefined,
        email: account?.email,
      });

    if (!validationResult.success) {
      const errorMessage = `[Private Pre-Signup Redirect] Questionnaire answer validation errors: ${validationResult.error.toString()}`;
      // eslint-disable-next-line no-console
      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    const {
      employmentStatus,
      income,
      quote: { tariff, deductible, sickDayPayout },
      name,
      email,
      citizenship,
      residencyPermitType,
    } = validationResult.data;

    url = constructPrivateHealthUrl({
      employmentStatus,
      income,
      tariff,
      deductible,
      sickDayPayout,
      name,
      email,
      citizenship,
      residencyPermitType,
    });
  } catch (exception) {
    setError((exception as Error).toString());
  }

  return (
    <QuoteLoading
      isUrlExternal
      route={url}
      primaryText={[
        t(
          'private.qnr.redirectToPrivateSignup.primaryText',
          'Alright! Let’s get started'
        ),
      ]}
      subText={t(
        'private.qnr.redirectToPrivateSignup.subText',
        'We’re directing you to our application process, this will only take a few moments'
      )}
      subTextClassName="wmx6"
    />
  );
};
