import { BottomOrRegularModal, InfoIcon } from '@popsure/dirty-swan';
import { useState } from 'react';

import styles from './style.module.scss';

export interface CellProps {
  title: string;
  value: string | string[];
  additionalInfo?: { title: string; description: string };
  noBreak?: boolean;
}

const Cell = ({ title, value, additionalInfo, noBreak }: CellProps) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {additionalInfo && (
        <BottomOrRegularModal
          isOpen={openModal}
          title={additionalInfo.title}
          onClose={() => setOpenModal(false)}
        >
          <p className="pb24 pl24 pr24 pt16 p-p">
            {additionalInfo.description}
          </p>
        </BottomOrRegularModal>
      )}
      <div className={`mt8 ${styles.cell}`}>
        <div className="p-p tc-grey-500">{title}</div>
        {additionalInfo && (
          <button
            className="ds-interactive-component c-pointer ml8"
            onClick={() => setOpenModal(true)}
            type="button"
          >
            <InfoIcon size={24} color="primary-500" noMargin />
          </button>
        )}
        {typeof value === 'string' ? (
          <div
            className={`p-p wmx4 ml-auto ta-right ${styles.cellValue} ${
              noBreak ? styles.noBreak : ''
            }`}
          >
            {value}
          </div>
        ) : (
          <div className={`ml-auto ta-right ${styles.cellValueList}`}>
            {value.map((item) => (
              <div
                className={`p-p wmx4 ${styles.cellValue} ${
                  noBreak ? styles.noBreak : ''
                }`}
              >
                {item}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Cell;
