import { requestLoginCode } from 'actions/account';
import { flushRequestError } from 'actions/request';
import View from 'components/signupAuthentication/email/components/loginModal/view';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useDispatch } from 'react-redux';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';

import { signinAccount } from '../../actions/account';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onLogin: () => void;
  email: string;
  verticalId: keyof GenericQuestionnaireState;
};

const LoginModal = ({ email, isOpen, onClose, verticalId, onLogin }: Props) => {
  const { loading, error } = useRequestStatus('SIGN_IN_WITH_LOGIN_CODE');
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(flushRequestError('SIGN_IN_WITH_LOGIN_CODE'));
    onClose();
  };

  const handleSendNewCode = () => {
    dispatch(requestLoginCode(email));
  };

  const handleSignin = (loginCode: string) => {
    dispatch(signinAccount(loginCode, email, verticalId, onLogin));
  };

  return (
    <View
      email={email}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      error={error}
      handleSignin={handleSignin}
      handleSendNewCode={handleSendNewCode}
    />
  );
};

export default LoginModal;
