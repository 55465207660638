import { calendly, faq } from '@getpopsure/private-constants';
import MoreQuestionsSection from 'components/moreQuestions';
import ReviewBadge from 'components/reviewBadge';
import { getDisabilityQuote } from 'features/disability/actions';
import {
  Disability,
  DisabilityNotPersistedQuotesDto,
  DisabilityRequiredAnswersForCalculatorSchema,
} from 'features/disability/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import disabilityIcon from './assets/disabilityIcon.png';
import disabilityIcon2x from './assets/disabilityIcon2x.png';
import disabilityIcon3x from './assets/disabilityIcon3x.png';
import { CTASection } from './CTASection';
import { DisabilityCalculator } from './DisabilityCalculator';
import { FAQSection } from './FAQSection';
import { WhatsCoveredSection } from './WhatsCoveredSection';
import { WhatsNotCoveredSection } from './WhatsNotCoveredSection';

const MINIMUM_MONTHLY_PAYOUT = 100;
const MAXIMUM_MONTHLY_PAYOUT = 3_000;
const SLIDER_STEP_SIZE = 5;

export const QuotePage = ({
  questionnaireAnswers: answers,
  onSubmitValue,
}: CustomComponentProps<Disability>) => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch();

  const { error } = useRequestStatus('GET_DISABILITY_QUOTE');

  const { monthlyNetIncome, monthlyPayout: monthlyPayoutFromReduxState } =
    answers;

  const maximumMonthlyPayout = Math.min(
    Math.floor(((monthlyNetIncome || 0) * 0.7) / SLIDER_STEP_SIZE) *
      SLIDER_STEP_SIZE,
    MAXIMUM_MONTHLY_PAYOUT
  );

  const defaultMonthlyPayout =
    Math.floor((maximumMonthlyPayout * 0.8) / SLIDER_STEP_SIZE) *
    SLIDER_STEP_SIZE;

  const [monthlyPayout, setMonthlyPayout] = useState(
    monthlyPayoutFromReduxState || defaultMonthlyPayout
  );

  const [pendingRequestCount, setPendingRequestCount] = useState(0);

  const getQuote = async () => {
    const validationResult =
      DisabilityRequiredAnswersForCalculatorSchema.safeParse({
        ...answers,
        monthlyPayout,
      });

    if (!validationResult.success) {
      throw new Error(
        `[Disability] Questionnaire answer validation errors:${validationResult.error.toString()}`
      );
    }

    const validatedAnswers = validationResult.data;

    const quotesDto: DisabilityNotPersistedQuotesDto = {
      dateOfBirth: validatedAnswers.dateOfBirth,
      officeWork: validatedAnswers.officeWork,
      degree: validatedAnswers.degree,
      monthlyPayout: validatedAnswers.monthlyPayout,
      heightInCm: validatedAnswers.height.inCm,
      weightInKg: validatedAnswers.weight.inKg,
    };

    // Accommodate for slow connections by keeping track of requests to be able to show a loading spinner while at least one request is still pending.
    setPendingRequestCount((count) => count + 1);
    await dispatch(getDisabilityQuote(quotesDto));
    setPendingRequestCount((count) => count - 1);
  };

  const price = answers.calculatorQuote?.basicPrice || 0;

  const isLoading = pendingRequestCount > 0;

  return (
    <div className="d-flex fd-column">
      <div className="d-flex fd-column ai-center jc-center pt72">
        <img
          src={disabilityIcon}
          srcSet={`${disabilityIcon} 1x, ${disabilityIcon2x} 2x, ${disabilityIcon3x} 3x`}
          alt={t('disability.quote.image.alt', 'wheelchair')}
        />
        <h1 className="p-h1 p--serif mt24 mb8">
          {t('disability.quote.title', 'Disability insurance')}
        </h1>
      </div>
      <DisabilityCalculator
        error={error}
        getQuote={getQuote}
        isLoading={isLoading}
        monthlyPayout={monthlyPayout}
        onContinueClick={() => onSubmitValue(monthlyPayout)}
        price={price}
        setMonthlyPayout={setMonthlyPayout}
        min={MINIMUM_MONTHLY_PAYOUT}
        max={maximumMonthlyPayout}
        stepSize={SLIDER_STEP_SIZE}
      />
      <WhatsCoveredSection />
      <WhatsNotCoveredSection />
      <CTASection
        isLoading={isLoading}
        onContinueClick={() => onSubmitValue(monthlyPayout)}
        price={price}
      />
      <div className="bg-white py96">
        <div className="p-container d-flex ai-center jc-center">
          <ReviewBadge />
        </div>
      </div>
      <FAQSection />
      <MoreQuestionsSection
        bookACallUrl={calendly.disabilityInsurance}
        bookACallGACategory="DISABILITY_INSURANCE"
        openInNewTab
        description={t(
          'disability.quote.moreQuestions.description',
          'Reach out to us for more information or check out our FAQ for answers to common questions about disability insurance.'
        )}
        secondaryAction={t(
          'disability.quote.moreQuestions.secondaryCTA.caption',
          'Visit our FAQ'
        )}
        secondaryActionLink={faq.base}
      />
    </div>
  );
};
