import { capitalizeName } from '@getpopsure/public-utility';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { getCoverageTableData } from 'features/liability/components/LiabilityCoverageDetails/coverageTableData';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';
import { getInclusiveMembersMapping } from 'models/liability';
import { generatePath } from 'react-router';

import { CancelPolicyButton, ContactUsButton } from '../../components/buttons';
import type { PolicySingleModal } from '../../components/modals';
import {
  CancelPolicyModal,
  DelinquencyBlockerCancelPolicyModal,
  DelinquencyBlockerCreateClaimModal,
} from '../../components/modals';
import { DATE_FORMAT } from '../../constants';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from '../../PolicySingle.models';
import { PolicyDetailsInfo } from '../../sections/PolicyDetails/PolicyDetails.models';
import { getPolicyDocuments } from '../../sections/PolicyDocuments/utils/getPolicyDocuments';
import { LiabilityUpdateAddressModal } from './modals/LiabilityUpdateAddressModal';

export const getLiabilityConfig = (args: GetPolicyConfigArgs): PolicyConfig => {
  const [policy, t] = args;
  const {
    policyNumber,
    insuredPerson,
    price,
    billingType,
    startDate,
    inclusiveMembers,
    address,
  } = policy.attributes;

  const policyStatus = getPolicyStatus(policy, true);
  const title = getTitleMapping(t).LIABILITY;
  const capitalizedName = insuredPerson?.name
    ? capitalizeName(insuredPerson.name)
    : '';
  const subtitle = capitalizedName || '';

  const header: PolicyConfig['header'] = {
    title,
    subtitle,
    backgroundColor: bannerColorMapping.LIABILITY,
    insuranceType: policy.type,
    policyStatus,
    startDate,
  };

  if (price) {
    header.price = {
      amount: parseFloat(price),
      billingType: billingType as BillingType,
    };
  }

  const inclusiveMembersInfo: PolicyDetailsInfo[] = [];

  if (inclusiveMembers?.length) {
    inclusiveMembersInfo.push({
      title: t(
        'myPolicies.policyDetails.liability.inclusiveMembers',
        'Included household member(s)'
      ),
      value: inclusiveMembers
        .map((iM) => getInclusiveMembersMapping(t)[iM])
        .join(', '),
    });
  }

  let addressRows: PolicyDetailsInfo[];

  if (address) {
    const { street, houseNumber, postcode, city, additionalInformation } =
      address;
    addressRows = [
      {
        title: t('myPolicies.policyDetails.liability.address', 'Address'),
        value: `${street} ${houseNumber}, ${
          additionalInformation ? `${additionalInformation},` : ''
        } ${postcode} ${city}`,
      },
      {
        title: '',
        value: {
          text: t(
            'myPolicies.policyDetails.liability.addressEdit',
            'Edit address'
          ),
          type: 'Button',
        },
        density: 'condensed',
      },
    ];
  } else {
    addressRows = [
      {
        title: t('myPolicies.policyDetails.liability.address', 'Address'),
        value: {
          text: t(
            'myPolicies.policyDetails.liability.addressAdd',
            'Add address'
          ),
          type: 'Button',
        },
        density: 'condensed',
      },
    ];
  }

  const sections: PolicySingleSection[] = [
    {
      id: 'POLICY_DETAILS',
      props: {
        policyType: policy.type,
        info: [
          {
            title: t('myPolicies.policyDetails.policyNumber', 'Policy number'),
            value: policyNumber,
          },
          {
            title: t(
              'myPolicies.policyDetails.insuredPerson',
              'Insured person'
            ),
            value: insuredPerson?.name && capitalizeName(insuredPerson.name),
          },
          {
            title: t('myPolicies.policyDetails.startDate', 'Start date'),
            value: startDate && dayjs(startDate).format(DATE_FORMAT),
          },
          ...inclusiveMembersInfo,
          ...addressRows,
        ],
        buttons: [
          {
            id: 'CONTACT_US',
            component: ContactUsButton,
          },
          ...(policy.status !== 'CANCELED'
            ? [
                {
                  id: 'CANCEL_POLICY',
                  component: CancelPolicyButton,
                },
              ]
            : []),
        ],
      },
    },
    {
      id: 'POLICY_COVERAGE',
      props: {
        entries: [
          {
            id: 'basic-lost-keys',
            title: t(
              'myPolicies.policyDetails.liability.covered.basicLostKeys.title',
              'Lost keys'
            ),
            covered: true,
          },
          {
            id: 'basic-else-property',
            title: t(
              'myPolicies.policyDetails.liability.covered.basicElseProperty.title',
              "Damage to someone else's property"
            ),
            covered: true,
          },
          {
            id: 'basic-injury',
            title: t(
              'myPolicies.policyDetails.liability.covered.basicInjury.title',
              'Unintentional physical injury to others'
            ),
            covered: true,
          },
          {
            id: 'basic-broken-glass',
            title: t(
              'myPolicies.policyDetails.liability.covered.basicBrokenGlass.title',
              'Broken glass'
            ),
            covered: false,
          },
          {
            id: 'basic-own-property',
            title: t(
              'myPolicies.policyDetails.liability.covered.basicOwnProperty.title',
              'Damage to your own property'
            ),
            covered: false,
          },
          {
            id: 'basic-fines-penalities',
            title: t(
              'myPolicies.policyDetails.liability.covered.basicFinesPenalities.title',
              'Fines and penalties'
            ),
            covered: false,
          },
        ],
        modal: {
          coverageTableData: getCoverageTableData(t),
          coverageTableHeaders: [],
        },
      },
    },
    {
      id: 'POLICY_CLAIMS',
      props: {
        claims: policy.claims,
        route: generatePath(routes.claims.liability.path, {
          policyId: policy.id,
        }),
        insuranceType: 'LIABILITY',
        activeUntil:
          policy.status === 'ACTIVE'
            ? undefined
            : policy.attributes.activeUntil,
        policyStatus: policy.status,
        screen: {
          button: {
            dataCy: 'liability-policy-detail-make-a-claim',
          },
        },
      },
    },
    {
      id: 'POLICY_DOCUMENTS',
      props: {
        documents: getPolicyDocuments(policy),
      },
    },
  ];

  const modals: PolicySingleModal[] = [
    {
      id: 'CANCEL_POLICY',
      component: CancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CREATE_CLAIM',
      component: DelinquencyBlockerCreateClaimModal,
    },
    {
      id: 'LIABILITY_UPDATE_ADDRESS',
      component: LiabilityUpdateAddressModal,
    },
  ];

  return { header, modals, sections };
};
