import styles from './style.module.css';

interface Props {
  error: string;
}

const Error = ({ error }: Props) => (
  <>
    <div className={styles['error-screen-container']}>
      <div>
        <div style={{ textAlign: 'center' }}>
          Something went wrong:
          <br />
          <span
            className={styles['error-screen-message']}
            data-testid="error-text-container"
          >
            {error}
          </span>
        </div>
        <button
          type="button"
          style={{ marginTop: '32px' }}
          className="p-btn--primary wmn3"
          onClick={() => {
            window.location.reload();
          }}
        >
          Retry
        </button>
      </div>
    </div>
  </>
);

export default Error;
