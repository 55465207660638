import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { IconCard } from 'components/IconCard';
import { useSafeTranslation } from 'shared/i18n';

import styles from './AddonsSection.module.scss';
import bikeLockIcon from './assets/bikeLock.svg';
import brokenGlassIcon from './assets/brokenGlass.svg';
import keysIcon from './assets/keys.svg';
import truckIcon from './assets/truck.svg';
import windowImg from './assets/window.png';
import windowImg2x from './assets/window2x.png';
import windowImg3x from './assets/window3x.png';

export const AddonsSection = () => {
  const { t } = useSafeTranslation();

  return (
    <section className="p-container mt80 mb80">
      <div className="d-flex ai-center jc-center f-wrap c-gap24 r-gap32">
        <div className={`w100 ${styles.titleContainer}`}>
          <h2 className="p-h2 p--serif">
            {t('page.household.quote.addons.title', 'Customize with add-ons')}
          </h2>
          <p className={`p-p ${styles.description}`}>
            {t(
              'page.household.quote.addons.description',
              'We also offer additional coverage through add-ons.'
            )}
          </p>
        </div>
        <img
          className={`w100 ${styles.image}`}
          src={windowImg}
          srcSet={`${windowImg} 1x, ${windowImg2x} 2x, ${windowImg3x} 3x`}
          alt={t('page.household.quote.addons.image.alt', 'broken window')}
        />
      </div>
      <div className={`d-grid gap24 mt56 ${styles.cardGrid}`}>
        <IconCard
          className="p24"
          title={t('page.household.quote.addons.card.1.title', 'Bicycle theft')}
          iconSrc={bikeLockIcon}
          iconAlt={t(
            'page.household.quote.addons.card.1.icon.alt',
            'broken bike lock'
          )}
        >
          {t(
            'page.household.quote.addons.card.1.description',
            'If your bike, saddle, lights, or basket are stolen inside or outside your home, insurance will pay to replace it.'
          )}
        </IconCard>
        <IconCard
          className="p24"
          iconSrc={brokenGlassIcon}
          title={t('page.household.quote.addons.card.2.title', 'Broken glass')}
          iconAlt={t(
            'page.household.quote.addons.card.2.icon.alt',
            'broken glass'
          )}
        >
          {t(
            'page.household.quote.addons.card.2.description',
            'Regardless if it’s a regular window, an aquarium, or even your induction stovetop, you’re covered.'
          )}
        </IconCard>
        <IconCard
          className="p24"
          iconSrc={keysIcon}
          title={t('page.household.quote.addons.card.3.title', 'Negligence')}
          iconAlt={t('page.household.quote.addons.card.3.icon.alt', 'keys')}
        >
          {t(
            'page.household.quote.addons.card.3.description',
            'An unlocked door that led to a burglary? Accidentally left a candle burning? Even when you’re careless, you’ll be covered.'
          )}
        </IconCard>
        <IconCard
          className="p24"
          iconSrc={truckIcon}
          title={t(
            'page.household.quote.addons.card.4.title',
            'Moving expenses'
          )}
          iconAlt={t(
            'page.household.quote.addons.card.4.icon.alt',
            'moving truck'
          )}
        >
          {t('page.household.quote.addons.card.4.description', {
            defaultValue:
              'If you’re no longer able to live inside of your apartment due to an insured event, you can get {{coverage}} of support. ',
            coverage: englishFormattedEuroCurrency(5000, true),
          })}
        </IconCard>
      </div>
    </section>
  );
};
