import { getCookieRegion, setCookieRegion } from '@getpopsure/i18n-react';
import { Region } from '@getpopsure/public-models';
import Session from '@getpopsure/session';
import { useGetPolicies } from 'hooks/useGetPolicies';
import { Policy } from 'models/policies';
import { useSelector } from 'react-redux';
import { isRegionValid } from 'routeLocales';
import { getUserRegion } from 'selectors/user';

import { isStagingOrDev } from '../shared/util/isStagingOrDev';

interface UseRegionReturnValue {
  loading: boolean;
  region: Region;
}

/**
 * The useRegion hook returns the region of the current user.
 *
 * The region will be determined in the following order:
 * 1. If there is a valid region cookie, it'll return the value from the cookie
 * 2. If the user is authenticated and no valid region cookie, it'll calculate the region based on the policies
 *   a. The region with the most policies will be selected and the region cookie will be updated
 * 3. If the user is not authenticated and there is no valid region cookie, it'll return 'de'
 *
 */
export const useRegionInternal = (): UseRegionReturnValue => {
  const { isAuthenticated } = Session;
  const userRegion = useSelector(getUserRegion);
  const regionCookieValue = String(getCookieRegion());
  const region = isRegionValid(regionCookieValue) ? regionCookieValue : 'de';

  const { isLoading, policies } = useGetPolicies();

  if (isAuthenticated && policies && policies.length > 0) {
    const calculatedRegion = calculateRegion(policies);
    setCookieRegion(calculatedRegion);

    return { region: calculatedRegion, loading: isLoading };
  }

  if (isAuthenticated && policies && policies.length === 0) {
    return {
      region: userRegion && isRegionValid(userRegion) ? userRegion : 'de',
      loading: isLoading,
    };
  }

  return { region, loading: isLoading };
};

/**
 *
 * Calculates the possible region based on the policies and
 * selects the region with the most policies.
 * This functionality will at some point be moved to the backend and
 * made configurable in the user settings at a later point in time.
 * If two regions contain the same number of policies and one of them is `de`,
 * we will always select the `de` one.
 *
 */
export const calculateRegion = (policies: Policy[]): Region => {
  // count region occurrences
  const count = policies.reduce((regions, { region: reg = 'de' }) => {
    if (!regions[reg]) {
      regions[reg] = 1;
    } else {
      regions[reg]++;
    }
    return regions;
  }, {} as Record<Region, number>);

  // sort desc and take the top item
  const [[region]] = Object.entries(count).sort(
    ([regionAId, countA], [regionBId, countB]) => {
      if (countA === countB && regionAId === 'de') {
        return -1;
      }

      if (countA === countB && regionBId === 'de') {
        return 1;
      }

      return countB - countA;
    }
  );

  return region as Region;
};

const persistedRegion = String(getCookieRegion());
export const useRegion = (): UseRegionReturnValue => {
  const { region, loading } = useRegionInternal();

  if (isStagingOrDev && isRegionValid(persistedRegion)) {
    return { region: persistedRegion, loading };
  }

  return { region, loading };
};
