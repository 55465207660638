import { Button } from '@popsure/dirty-swan';
import {
  createHealthCardRecord,
  markHealthCardAsReceived,
} from 'actions/healthCard';
// eslint-disable-next-line import/no-cycle
import { fetchPolicyDetail, mergePolicyAttr } from 'actions/policies';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { HealthCardDispatch } from 'models/healthCard';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ConfirmReceivedHealthCardModal } from '../ConfirmReceivedHealthCardModal';

const CREATE_REQUEST_TYPE = 'CREATE_HEALTH_CARD';
const UPDATE_REQUEST_TYPE = 'UPDATE_HEALTH_CARD_DETAILS';

export const RequestHealthCard = () => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const { policyId } = useParams<{ policyId: string }>();
  const dispatch = useDispatch<HealthCardDispatch>();
  const { loading: requestLoading, error: requestError } =
    useRequestStatus(CREATE_REQUEST_TYPE);
  const { loading: markAsReceivedLoading, error: markAsReceivedError } =
    useRequestStatus(UPDATE_REQUEST_TYPE);

  const error = requestError?.message || markAsReceivedError?.message;

  const refreshPolicy = async () => dispatch(fetchPolicyDetail(policyId));

  const handleRequestHealthCard = async () => {
    await dispatch(createHealthCardRecord(policyId));
    await refreshPolicy();
  };

  const openConfirmationModal = () => setShowConfirmModal(true);

  const handlCancel = () => setShowConfirmModal(false);

  const handleMarkAsReceived = async () => {
    await dispatch(markHealthCardAsReceived(policyId));
    await refreshPolicy();
    dispatch(mergePolicyAttr(policyId, { showHealthCardConfirmation: true }));
  };

  return (
    <>
      <div className="d-flex fd-column">
        <div className="d-flex ai-center">
          <Button
            data-testid="request-health-card-button"
            className="w100 wmx3"
            onClick={handleRequestHealthCard}
            loading={requestLoading}
          >
            Request Health Card
          </Button>
          <Button
            data-testid="mark-health-card-as-received"
            className="ml16 w100 wmx3"
            variant="filledGray"
            onClick={openConfirmationModal}
          >
            I already have it
          </Button>
        </div>
        <AnimateHeight
          duration={150}
          height={requestError || markAsReceivedError ? 'auto' : 0}
        >
          <div className="p-notice p-notice--danger mt16 p-p">
            {error && `Error: ${error}`}
          </div>
        </AnimateHeight>
      </div>
      {showConfirmModal &&
        createPortal(
          <ConfirmReceivedHealthCardModal
            loading={markAsReceivedLoading}
            onMarkAsReceived={handleMarkAsReceived}
            onCancel={handlCancel}
            error={markAsReceivedError}
          />,
          document.body
        )}
    </>
  );
};
