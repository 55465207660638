import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Toggle } from '@popsure/dirty-swan';

import styles from './ToggleCard.module.scss';

type ToggleCardProps = {
  children?: React.ReactNode;
  className?: string;
  description: string;
  disabled?: boolean;
  isSelected: boolean;
  onChange: () => void;
  price: number | string;
  subtitle?: string;
  title: string;
  toggleKey: string;
};

export const ToggleCard = ({
  children,
  className = '',
  description,
  disabled = false,
  isSelected,
  toggleKey,
  onChange,
  price,
  title,
  subtitle = '',
}: ToggleCardProps) => (
  <div className={`p24 bg-white br8 ${className}`}>
    <div className={`d-flex jc-between gap16 ${styles.content}`}>
      <div>
        <h4 className="p-h4 d-flex f-wrap gap8">
          {title}{' '}
          <span className={`tc-grey-600 fw-normal ${styles.subtitle}`}>
            {subtitle}
          </span>
        </h4>
        <p className={`p-p mt8 ${styles.description}`}>{description}</p>
      </div>
      <div className={`d-flex fd-column ai-end ${styles.toggleContainer}`}>
        <div className="ta-right">
          <Toggle
            classNames={{ label: styles.toggleLabel }}
            disabled={disabled}
            value={isSelected ? [toggleKey] : []}
            bordered={false}
            options={{ [toggleKey]: '' }}
            onChange={onChange}
          />
        </div>
        <h3
          className={`p-h3 mt16 ${styles.transition} ${
            disabled || !isSelected ? 'tc-grey-500' : ''
          }`}
        >
          {typeof price === 'number'
            ? englishFormattedEuroCurrency(price, 2)
            : price}
        </h3>
      </div>
    </div>
    {children}
  </div>
);
