import { insurance } from '@getpopsure/private-constants';
import routes from 'constants/routes';
import {
  bannerColorMapping,
  imageSrcSetTypeMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';

import { CardText, CategoryLookup, Section } from '../models/lookups';
import { typeformLinkWithVertical } from './shared';

export const liabilityCategory: CategoryLookup = {
  title: 'Personal liability insurance',
  shortTitle: 'Personal liability',
  excerpt: 'Protection for damage you cause to people or their belongings',
  description:
    "Liability insurance covers damage you do to other people’s belongings or if you injure someone. That includes the injured person's hospital bills and any missed wages from being unable to work. Because of how costly this can get, around 80% of all Germans have liability insurance. Additionally, liability insurance will cover damages you’ve done to a flat you’re renting, like scratches on the floor from small animals as well as the cost to replace lost keys.",
  icon: imageTypeMapping.LIABILITY,
  iconSrcSet: imageSrcSetTypeMapping.LIABILITY,
  bannerColor: bannerColorMapping.LIABILITY,
  landingPage: insurance.liability.landingPage,
  quotePage: routes.policies.liability.quote.path,
  enabled: true,
};

export const liabilitySection: Section = {
  title: '',
  description:
    'Because of how common accidents are (both to other people’s belongings and injuries to others), everyone should have liability insurance. Just keep in mind that unlike plans in other countries, Germany separates household and liability insurance. Any damage done to other people, whether breaking an object or injuring someone, is covered under liability insurance. Damage to any belongings in your apartment from a fire or vandalism is covered by household insurance.',
  icon: '',
  icon2x: '',
  icon3x: '',
  status: {
    ELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.liability.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('liability'),
      },
    },
    PARTIAL: {
      cta: {
        caption: 'Learn more',
        href: insurance.liability.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('liability'),
      },
    },
    MORE_INFO_REQUIRED: {
      cta: {
        caption: 'Learn more',
        href: insurance.liability.landingPage,
      },
    },
    INELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.liability.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('liability'),
      },
    },
  },
};

export const liabilityCardText: CardText = {
  LIABILITY_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'You need to be 18 years or older to purchase our liability insurance. You can ask a parent or a guardian to create a plan for you in the meantime.',
  },
  LIABILITY_FINANCIAL_BANKING_ACCIDENTS_PRO: {
    title: 'Financial backing for accidents',
    description:
      'If you accidentally injure a surgeon’s hands, you’ll not only need to pay for their hospital and physical therapy visits but also any lost income from the injury. Liability insurance will cover this.',
  },
  LIABILITY_COVERS_THIRD_PARTY_DAMAGES_PRO: {
    title: 'Covers third-party damages',
    description:
      'If someone injures you or damages one of your belongings and can’t provide financial compensation, your liability insurance will step in to pay for these expenses. In some cases, liability insurance will also represent you in court.',
  },
  LIABILITY_FOR_LOVED_ONES_PRO: {
    title: 'Also for your loved ones',
    description:
      'You can add any loved ones who live with you to your plan with a simple click of a button and by providing us with their basic information. This is also less expensive than getting them their own liability plan.',
  },
  LIABILITY_LANDLORDS_REQUIRE_IT_PRO: {
    title: 'Landlords might require it',
    description:
      'Depending on the city you live in, some German landlords ask for liability insurance to make sure any damages that might happen will be covered. You can also increase your chance of getting an apartment by including your coverage document.',
  },
  LIABILITY_IN_GERMANY_FOR_LESS_THAN_ONE_MONTH_CON: {
    title: "If you're in Germany for less than one month",
    description:
      'Liability insurance is a monthly policy, so if you’re only planning to stay a few weeks, then you might want to stick to travel insurance or liability insurance from your home country that covers claims abroad.',
  },
};
