import { insurance } from '@getpopsure/private-constants';
import {
  EmploymentStatus,
  getAgeFromBirthYear,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { PrivatePreSignup } from 'features/privateHealthPreSignup/models';

export const EMPLOYEE_INCOME_THRESHOLD =
  insurance.privateHealth.employeeThreshold;
export const NON_EMPLOYEE_LOWER_INCOME_THRESHOLD = 25_000;
export const FREELANCER_UPPER_INCOME_THRESHOLD = 30_000;
export const OTHER_UPPER_INCOME_THRESHOLD = 60_000;
export const HANSE_MERKUR_AGE_THRESHOLD = 35;

export const getIncomeFollowUpScreen = ({
  employmentStatus,
  income = 0,
  birthYear,
}: {
  employmentStatus?: EmploymentStatus;
  income?: number;
  birthYear?: number;
}): keyof PrivatePreSignup => {
  const isOverHanseMerkurAgeThreshold =
    getAgeFromBirthYear(birthYear ?? 99) > HANSE_MERKUR_AGE_THRESHOLD;

  switch (employmentStatus) {
    case 'EMPLOYED_IN_GERMANY':
      if (income < EMPLOYEE_INCOME_THRESHOLD) {
        return 'incomeUnderEmployeeThresholdBlocker';
      }
      return 'familyCoverage';

    case 'OTHER':
      if (
        income < NON_EMPLOYEE_LOWER_INCOME_THRESHOLD ||
        (income < OTHER_UPPER_INCOME_THRESHOLD && isOverHanseMerkurAgeThreshold)
      ) {
        return 'freelancerNotEligibleBlocker';
      }

      if (income < OTHER_UPPER_INCOME_THRESHOLD) {
        return 'hanseMerkurRedirect';
      }

      return 'familyCoverage';

    case 'EMPLOYED_OUTSIDE_OF_GERMANY':
    case 'SELF_EMPLOYED':
    default:
      if (
        income < NON_EMPLOYEE_LOWER_INCOME_THRESHOLD ||
        (income < FREELANCER_UPPER_INCOME_THRESHOLD &&
          isOverHanseMerkurAgeThreshold)
      ) {
        return 'freelancerNotEligibleBlocker';
      }

      if (income < FREELANCER_UPPER_INCOME_THRESHOLD) {
        return 'hanseMerkurRedirect';
      }

      return 'familyCoverage';
  }
};
