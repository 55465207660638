import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import { Household } from 'features/household/models';
import { getTranslatedQuestionnaire } from 'features/household/questionnaire';
import { getHouseholdAnswers } from 'features/household/selectors';
import Questionnaire from 'features/questionnaireFramework';
import { useCheckoutStarted } from 'hooks/useCheckoutStarted';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

export const SignupPage = () => {
  const { t } = useSafeTranslation();

  const answers = useSelector(getHouseholdAnswers) || {};
  const dispatch = useDispatch();

  const handleAnswerQuestion = (answer: Partial<Household>) => {
    dispatch(storeGenericQuestionnaireAnswer('household', answer));
  };

  const handleRemoveAnswer = (questionIds: (keyof Household)[]) => {
    dispatch(removeGenericQuestionnaireAnswer('household', questionIds));
  };

  useCheckoutStarted('HOUSEHOLD', answers.email);

  return (
    <Questionnaire
      featureName="Household"
      questionnaire={getTranslatedQuestionnaire(t)}
      questionnaireAnswers={answers}
      handleAnswerQuestion={handleAnswerQuestion}
      handleRemoveAnswer={handleRemoveAnswer}
      share={{
        verticalId: 'household',
      }}
    />
  );
};
