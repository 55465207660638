import { useLocation } from 'react-router';

import Desktop from './desktop';
import Mobile from './mobile';
import styles from './style.module.scss';

const NavigationBar = ({
  hideOnMainPage = true,
  showLoggedIn,
}: {
  hideOnMainPage?: boolean;
  showLoggedIn?: boolean;
}) => {
  const location = useLocation();

  if (hideOnMainPage && location.pathname === '/') {
    return null;
  }

  return (
    <>
      <div className={styles.desktop}>
        <Desktop showLoggedIn={showLoggedIn} />
      </div>
      <div className={styles.mobile}>
        <Mobile showLoggedIn={showLoggedIn} />
      </div>
    </>
  );
};

export default NavigationBar;
