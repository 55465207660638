import { Route, Switch } from 'react-router-dom';

import { PrivateHealthClaimsSubmitted } from './components/ClaimSubmitted';
import { PrivateHealthClaims } from './PrivateHealthClaims.container';
import { privateHealthClaimsRoutes } from './privateHealthClaims.routes';

export const PrivateHealthClaimsRouter = () => (
  <Switch>
    <Route
      exact={true}
      path={privateHealthClaimsRoutes.submitted.path}
      component={PrivateHealthClaimsSubmitted}
    />
    <Route component={PrivateHealthClaims} />
  </Switch>
);

export {};
