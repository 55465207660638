import { AxiosResponse } from 'axios';
import {
  IsEligibleUserResponse,
  ReferralCampaign,
  ReferralCodeResponse,
  ReferralCodeVerifyRequest,
  ReferralUserData,
  ReferralUserDataResponse,
} from 'features/referralEngine/models';
import api, { Client } from 'shared/api';

export function generateReferralCode(this: Client) {
  return this.network.post<ReferralCodeResponse>(
    '/referral-engine/referral-code'
  );
}

export function isEligibleUser(
  this: Client,
  requestBody: ReferralCodeVerifyRequest
) {
  return this.network.post<
    ReferralCodeVerifyRequest,
    AxiosResponse<IsEligibleUserResponse>
  >('/referral-engine/referral/is-eligible-user', requestBody);
}

export function getReferralUserData(this: Client) {
  return this.network.get<ReferralUserData & { id: string }>(
    '/referral-engine/referral-user-data'
  );
}

export function postReferralUserData(
  this: Client,
  requestBody: ReferralUserData
) {
  return this.network.post<ReferralUserDataResponse>(
    '/referral-engine/referral-user-data',
    { ...requestBody }
  );
}

export function patchReferralUserData(
  this: Client,
  requestBody: ReferralUserData
) {
  return this.network.patch<ReferralUserDataResponse>(
    '/referral-engine/referral-user-data',
    { ...requestBody }
  );
}

export async function getActiveReferralCampaign() {
  const response = await api.network.get<ReferralCampaign[]>(
    '/referral-engine/referral-campaign'
  );
  return response.data;
}
