import { kebabCaseToCamelCase } from 'features/questionnaireFramework/utils/camelCaseToKebapCase';
import { getLastUnansweredQuestionId } from 'features/questionnaireFramework/utils/getLastUnansweredQuestionId';
import {
  PartialQuestionnaire,
  RoutesObject,
} from 'models/questionnaireFramework';
import { useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';

interface Props<Questionnaire, GroupId> {
  questions: PartialQuestionnaire<Questionnaire, GroupId>;
  answers: Partial<Questionnaire>;
  routes: RoutesObject<Questionnaire>;
  children: JSX.Element;
}

const PreventSkipRedirect = <Questionnaire, GroupId>({
  questions,
  answers,
  routes,
  children,
}: Props<Questionnaire, GroupId>) => {
  const { questionId } = useParams<{
    questionId: string;
  }>();
  const currentQuestionId = kebabCaseToCamelCase(
    questionId
  ) as keyof Questionnaire;
  const lastUnansweredQuestionId = useMemo(
    () => getLastUnansweredQuestionId(currentQuestionId, questions, answers),
    [currentQuestionId, answers, questions]
  );

  if (lastUnansweredQuestionId) {
    const { path } = routes[lastUnansweredQuestionId];
    return <Redirect to={path} />;
  }

  return children;
};

export default PreventSkipRedirect;
