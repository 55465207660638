import classNames from 'classnames';

import styles from './Header.module.scss';

export const QuoteHeader = ({
  title,
  headerImage,
  headerColor,
}: {
  title?: string;
  headerImage: { src: string; alt: string };
  headerColor: string;
}) => {
  return (
    <header>
      <div
        className={classNames('px16', styles.headerImgWrapper)}
        style={{ backgroundColor: headerColor }}
      >
        <img
          src={headerImage.src}
          alt={headerImage.alt}
          className={styles.headerImg}
        />
      </div>
      {title && (
        <div
          className={classNames(
            'p-body d-flex fd-column ai-center',
            styles.titleContainer
          )}
        >
          <h1 className="p-h1 p--serif">{title}</h1>
        </div>
      )}
    </header>
  );
};
