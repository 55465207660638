import { TallyModal } from 'components/TallyModal';
import { PolicySingleModalProps } from 'features/policySingle/components/modals';

export type ExpatUpgradeToPrivatePublicModalType =
  'EXPAT_UPGRADE_TO_PRIVATE_PUBLIC';

export const ExpatUpgradeToPrivatePublicModal = ({
  onClose,
}: PolicySingleModalProps) => (
  <TallyModal
    isVisible
    onClose={onClose}
    tallyId="3X0024"
    title="Expat upgrade to private or public health"
  />
);
