import routes from 'constants/routes';
import { checkSplash } from 'icons';
import { Link } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

const ClaimConfirmation = () => {
  const { t } = useSafeTranslation();
  return (
    <div className="p-body">
      <div className={`wmx8 w100 ${styles.container}`}>
        <img src={checkSplash} alt="check" className={styles.icon} />
        <h1 className={`p-h1 mt40 ${styles['confirmation-header']}`}>
          {t('claims.generic.confirmation.title', 'Claim submitted!')}
        </h1>
        <p className="p-p mt16 wmx6 ta-center">
          {t(
            'claims.generic.confirmation.description',
            'There’s nothing left for you to do! We will get in contact with you once your request has been processed.'
          )}
        </p>
        <Link
          to={routes.claims.path}
          className={`p-btn p-btn--primary mt32 wmx4 ${styles.button}`}
          data-cy="me-claims-button-policies"
        >
          {t(
            'claims.generic.confirmation.goToClaimsButton.caption',
            'Go to Claims'
          )}
        </Link>
      </div>
    </div>
  );
};

export default ClaimConfirmation;
