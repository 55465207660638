import { trackInsuranceSubscribed } from '@getpopsure/analytics/dist/events';
import { email } from '@getpopsure/private-constants';
import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { Button, InformationBox } from '@popsure/dirty-swan';
import { flushGenericQuestionnaire } from 'actions/genericQuestionnaire';
import { fetchAccountInfo } from 'actions/user';
import classNames from 'classnames';
import { ErrorWithAction } from 'components/ErrorWithAction';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { redirectSuccessfulCheckout } from 'features/checkout/utils';
import { areDocumentsValid } from 'features/checkoutDocuments/actions';
import { getDocuments } from 'features/checkoutDocuments/checkoutDocuments.selectors';
import { usePollCheckoutDocuments } from 'features/checkoutDocuments/hooks';
import {
  submitApplicationAction,
  SubmitApplicationDispatch,
} from 'features/pension/actions';
import { Pension } from 'features/pension/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { FormEvent, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import { useSafeTranslation } from 'shared/i18n';

import { PensionDocumentsReview } from '../PensionDocumentsReview/PensionDocumentsReview';
import { PolicyDetails } from '../PolicyDetails';
import { SepaMandate } from '../SepaMandate/SepaMandate';
import styles from './SubmitApplication.module.scss';

export const SubmitApplication = ({
  questionnaireAnswers,
}: QuestionnaireFormProps<Pension>) => {
  const dispatch = useDispatch<SubmitApplicationDispatch>();
  const history = useHistory();
  const [checkboxValues, setCheckboxValues] = useState<string[]>([]);
  const { t } = useSafeTranslation();
  const { loading, error } = useRequestStatus('PENSION_SUBMIT_APPLICATION');

  const questionnaire = useSelector(getGenericQuestionnaire).pension;
  const iban = questionnaireAnswers.sepa?.iban;
  const accountHolder = questionnaireAnswers.sepa?.accountHolder;

  if (!questionnaire) {
    throw new Error('[Pension]: Questionnaire not found');
  }

  if (!iban) {
    throw new Error('[Pension]: IBAN is missing from the questionnaire info');
  }

  if (!accountHolder) {
    throw new Error(
      '[Pension]: Account holder is missing from the questionnaire info'
    );
  }

  const { startPollingDocuments, documentsError, documentsLoading } =
    usePollCheckoutDocuments('PENSION', questionnaire.questionnaireId);
  const documents = useSelector(getDocuments(questionnaire.questionnaireId));

  useEffect(() => {
    dispatch(fetchAccountInfo());
  }, [dispatch]);

  useEffect(() => {
    startPollingDocuments();
  }, [startPollingDocuments]);

  const isValidForSubmission =
    checkboxValues.includes('sepaMandate') &&
    checkboxValues.includes('hasReviewedDocuments');

  const handleSubmitApplication = async (e: FormEvent) => {
    e.preventDefault();
    const { status } = await dispatch(submitApplicationAction());

    if (status === 'SUCCESS') {
      redirectSuccessfulCheckout('pension');
      dispatch(flushGenericQuestionnaire('pension'));
      trackInsuranceSubscribed({
        vertical: 'pension',
      });
    }
  };

  if (documentsError) {
    return (
      <ErrorWithAction
        title={t(
          'pension.qnr.submitApplication.documentsError.title',
          'Something went wrong'
        )}
        description={t(
          'pension.qnr.submitApplication.documentsError.description',
          "Some of the required documents couldn't be generated.\n\nGoing back to the previous page and trying again should fix the issue."
        )}
        cta={{
          title: t('checkout.documentsError.cta', 'Go back'),
          onClick: () => history.goBack(),
        }}
      />
    );
  }

  if (documentsLoading) {
    return (
      <TimedLoadSpinner
        title={t(
          'pension.qnr.submitApplication.loading.title',
          'Preparing your application'
        )}
        description={t(
          'pension.qnr.submitApplication.loading.description1',
          "We're gathering your documents and policy details so you can review them before submitting your application."
        )}
        delayedAnimatedText={t(
          'pension.qnr.submitApplication.loading.description2',
          "This takes a little longer than usual - it's quite the stack 🥞"
        )}
        delayInMilliseconds={0}
      />
    );
  }

  return (
    <div className="p-body">
      <form
        className="d-flex fd-column ai-center mt80 mb80"
        onSubmit={handleSubmitApplication}
      >
        <img
          className={`${styles.icon} br8`}
          src={imageTypeMapping.PENSION}
          alt="A palm tree and a sunbed"
        />
        <h1 className="p-h1 py24">
          {t('pension.qnr.reviewCheckout.title', 'Submit application')}
        </h1>
        <div className="mt16 d-flex f-wrap ai-start gap24">
          <div className={styles.columnItem}>
            <PolicyDetails />
          </div>
          <div className={styles.columnItem}>
            <SepaMandate
              accountHolder={accountHolder}
              iban={iban}
              checkboxValues={checkboxValues}
              setCheckboxValues={setCheckboxValues}
            />
          </div>
        </div>
        <div
          className={`d-flex ai-center fd-column mt32 w100 ${styles.buttonContainer}`}
        >
          <div
            className={classNames(
              'd-flex fd-column gap32',
              styles.buttonDocsContainer
            )}
          >
            {documents && areDocumentsValid(documents) && (
              <PensionDocumentsReview
                documents={documents}
                checkboxValue={checkboxValues}
                setCheckboxValues={setCheckboxValues}
              />
            )}
            <Button
              loading={loading}
              disabled={!isValidForSubmission}
              className="wmn3"
              data-cy="button-submit-application"
              type="submit"
            >
              {t(
                'pension.qnr.reviewCheckout.submit.caption',
                'Submit application'
              )}
            </Button>
            <AnimateHeight duration={300} height={error ? 'auto' : 0}>
              <InformationBox variant="warning" className="mt24 wmx6">
                {t(
                  'pension.qnr.reviewCheckout.submit.error.message',
                  'We could not submit your pension application. Please try again or contact us at {{supportEmail}}.',
                  { supportEmail: email.help }
                )}
              </InformationBox>
            </AnimateHeight>
          </div>
        </div>
      </form>
    </div>
  );
};
