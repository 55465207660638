import { language, Locale } from '@getpopsure/i18n-react';
import { capitalizeName } from '@getpopsure/public-utility';
import Session from '@getpopsure/session';
import { requestLoginCode } from 'actions/account';
import {
  mergeRecommendationAnswers,
  mergeRecommendationDetails,
  mergeRecommendationPersona,
} from 'actions/recommendationTool';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { fetchAccountInfo } from 'actions/user';
import { RecommendationQuestionnaireAction } from 'constants/actions';
import { fetchRecommendationPersona } from 'features/recommendationTool/api';
import {
  CreatePersonaStatus,
  GetRecommendationStatus,
  RecommendationAccountStatus,
  RecommendationToolAction,
} from 'features/recommendationTool/models';
import { APIResponseError } from 'models/error';
import { RecommendationPersonaRequest } from 'models/recommendationTool';
import { UserNewWithRequiredInfo } from 'models/user';
import endpoint, { Client } from 'shared/api';

import { TAllowedInsuranceTypes } from '../models/recommendation';
import questionnaires from '../questionnaires';
import { removeKeysFromObject } from '../utils/removeKeysFromObject';

Client.prototype.fetchRecommendationPersona = fetchRecommendationPersona;

export const getPersonaAndRecommendation: RecommendationToolAction<
  Promise<GetRecommendationStatus | void>
> = async (dispatch) => {
  dispatch(setRequestInProcess(true, 'RECOMMENDATION_TOOL'));

  try {
    const { data } = await endpoint.fetchRecommendationPersona();
    if (data.length > 0) {
      const { snapshots, ...persona } = data[0];
      await dispatch(mergeRecommendationPersona(persona));

      if (snapshots.length > 0) {
        const { recommendation, answers } = snapshots[0];
        dispatch(mergeRecommendationDetails(recommendation));
        dispatch(
          mergeRecommendationAnswers({
            ...answers,
          })
        );
        return 'GET_RECOMMENDATION_SUCCESS';
      }

      return 'GET_PERSONA_SUCCESS';
    }
  } catch (error) {
    setRequestErrored(error as APIResponseError, 'RECOMMENDATION_TOOL');
  } finally {
    dispatch(setRequestInProcess(false, 'RECOMMENDATION_TOOL'));
  }
};

export const createRecommendationPersona: RecommendationToolAction<
  Promise<CreatePersonaStatus>
> = async (dispatch) => {
  dispatch(setRequestInProcess(true, 'RECOMMENDATION_TOOL'));
  let userInfo;

  if (Session.isAuthenticated) {
    userInfo = await dispatch(fetchAccountInfo());
  }

  /** Early return if the user already has a persona & snapshot */
  try {
    const getRecommendationStatus = await dispatch(getPersonaAndRecommendation);
    if (
      getRecommendationStatus === 'GET_PERSONA_SUCCESS' ||
      getRecommendationStatus === 'GET_RECOMMENDATION_SUCCESS'
    ) {
      return 'SKIP';
    }

    /** Otherwise create a persona */
    const name =
      userInfo?.firstName &&
      userInfo.lastName &&
      capitalizeName({
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      });

    const persona: RecommendationPersonaRequest = {
      name: name ?? 'Unauthenticated user',
    };

    const response = await endpoint.createRecommendationPersona(persona);

    if ('data' in response) {
      await dispatch(mergeRecommendationPersona(response.data));
      return 'SUCCESS';
    }

    /**
     * If there is no data in the response
     * we can asume that the request was not successful
     */
    dispatch(setRequestInProcess(false, 'RECOMMENDATION_TOOL'));
    return 'ERROR';
  } catch (error) {
    dispatch(
      setRequestErrored(error as APIResponseError, 'RECOMMENDATION_TOOL')
    );
    return 'ERROR';
  }
};

export const signInAndCreatePersona =
  ({
    email,
    code,
  }: {
    email: string;
    code: string;
  }): RecommendationToolAction<Promise<RecommendationAccountStatus>> =>
  async (dispatch) => {
    dispatch(setRequestInProcess(true, 'SIGN_IN_WITH_LOGIN_CODE'));

    try {
      await endpoint.signInWithTemporaryLoginCode(code, email);

      await dispatch(createRecommendationPersona);

      dispatch(setRequestInProcess(false, 'SIGN_IN_WITH_LOGIN_CODE'));
      return 'SUCCESS';
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'SIGN_IN_WITH_LOGIN_CODE')
      );
      return 'ERROR';
    }
  };

export const signUpAndCreatePersona =
  (
    user: UserNewWithRequiredInfo
  ): RecommendationToolAction<Promise<RecommendationAccountStatus>> =>
  async (dispatch) => {
    dispatch(setRequestInProcess(true, 'CREATE_USER'));

    try {
      await endpoint.createAccount({ ...user, language: language() as Locale });
      await dispatch(createRecommendationPersona);

      dispatch(setRequestInProcess(false, 'CREATE_USER'));

      return 'SUCCESS';
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, 'CREATE_USER'));

      return 'ERROR';
    }
  };

export type ValidateAccountStatus = 'USER_EXISTS' | 'NEW_USER' | 'ERROR';

export const validateAccount =
  (email: string): RecommendationToolAction<Promise<ValidateAccountStatus>> =>
  async (dispatch) => {
    dispatch(setRequestInProcess(true, 'VALIDATE_ACCOUNT'));

    try {
      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      if (userExists) {
        await dispatch(requestLoginCode(email));

        return 'USER_EXISTS';
      }

      return 'NEW_USER';
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'VALIDATE_ACCOUNT')
      );

      return 'ERROR';
    } finally {
      dispatch(setRequestInProcess(false, 'VALIDATE_ACCOUNT'));
    }
  };

export const resetQuestionnaire =
  (id: TAllowedInsuranceTypes): RecommendationToolAction<Promise<void>> =>
  async (dispatch, getState) => {
    const { isAuthenticated } = Session;
    const state = getState();
    const currentAnswers = state.recommendationTool.answers;
    const questionnaire = questionnaires[id];
    const newAnswers = removeKeysFromObject(
      currentAnswers,
      Object.keys(questionnaire)
    );

    dispatch(
      mergeRecommendationAnswers({
        ...newAnswers,
        ...(isAuthenticated
          ? { continueWithAccount: currentAnswers.continueWithAccount }
          : {}),
      })
    );
  };

export const updateShowIntroPage = (
  showPage: boolean
): RecommendationQuestionnaireAction => ({
  type: 'SHOW_RECOMMENDATION_TOOL_INTRO',
  payload: showPage,
});
