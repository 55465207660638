import { memo, useContext } from 'react';

import { TaskError } from './components/TaskError';
import { TaskEngineProps } from './taskEngine.models';
import { TaskEngineView } from './TaskEngine.view';
import { getFilteredTasks } from './utils/getFilteredTasks';
import { getMappedComponents } from './utils/getMappedComponents';
import {
  TaskEngineContext,
  TaskEngineProvider,
} from './utils/taskEngine.context';
import { useGetTasksWithFeatureFlags } from './utils/useGetTasksWithFeatureFlags';

const TaskEngineWithContext = ({ options, render }: TaskEngineProps) => {
  const { tasks, error } = useContext(TaskEngineContext);
  const enabledTasks = useGetTasksWithFeatureFlags(tasks);
  const filteredTasks = getFilteredTasks(enabledTasks, options?.filter);
  const mappedTasks = getMappedComponents(filteredTasks, options);

  if (render) return render({ tasks: mappedTasks, error });

  if (error) return <TaskError className="mb24" error={error} />;

  return <TaskEngineView screen={options?.screen} tasks={mappedTasks} />;
};

export const TaskEngine = memo((props: TaskEngineProps) => {
  return (
    <TaskEngineProvider>
      <TaskEngineWithContext options={props.options} render={props.render} />
    </TaskEngineProvider>
  );
});
