import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { BottomOrRegularModal } from '@popsure/dirty-swan';
import { useQuery } from '@tanstack/react-query';
import { getActiveReferralCampaign } from 'features/referralEngine/api';
import { handAndCoin } from 'features/referralEngine/assets';
import {
  FORMATTED_BONUS_AMOUNT,
  FORMATTED_LIMIT_AMOUNT,
  REFERRAL_ENGINE_TC_URL,
} from 'features/referralEngine/constants';
import { useGetPolicies } from 'hooks/useGetPolicies';
import { APIResponseError } from 'models/error';
import { getTitleMapping } from 'models/insurances/types/mapping';
import { Fragment } from 'react';
import { Trans, useSafeTranslation } from 'shared/i18n';

import { ReferralCodeCta } from '../ReferralCodeCta';
import styles from './ReferralModal.module.scss';

interface ReferralModalProps {
  onClose(): void;

  referralCode?: string;
  loading?: boolean;
  error?: APIResponseError;
}

export const ReferralModal = (props: ReferralModalProps) => {
  const { policies } = useGetPolicies();
  const { onClose, referralCode, loading, error } = props;
  const { t } = useSafeTranslation();
  const { data: activeReferralCampaigns } = useQuery({
    queryKey: ['referralCampaigns'],
    queryFn: getActiveReferralCampaign,
    initialData: [],
  });

  const activeReferralCampaignsForUser =
    policies &&
    activeReferralCampaigns.filter((campaign) =>
      policies.some((policy) => policy.type === campaign.sourceInsuranceType)
    );

  return (
    <BottomOrRegularModal title="" isOpen onClose={onClose}>
      <div className="d-flex fd-column px24 pb24">
        <div className="d-flex fd-column ai-center">
          <img
            src={handAndCoin}
            alt=""
            className="mx-auto mb16"
            width="96"
            height="97"
          />
          <h1 className="p-h2 ta-center w100 wmx5 mb32">
            {t(
              'referralengine.generator.modal.title',
              'Get {{bonus}} for each person you refer to Feather',
              { bonus: FORMATTED_BONUS_AMOUNT }
            )}
          </h1>
        </div>
        <Trans i18nKey="referralengine.generator.modal.step.1">
          <p className="p-p px24 py16 bg-grey-100 br8 d-flex ai-center mb8">
            <span className="p-h1 tc-primary-500 p--serif mr24">1</span>
            <span className={`fw-bold ${styles.shareSpacing}`}>Share</span> your
            promo code
          </p>
        </Trans>
        <Trans i18nKey="referralengine.generator.modal.step.2">
          <p className="p-p px24 py16 bg-grey-100 br8 d-flex ai-center mb8">
            <span className="p-h1 tc-primary-500 p--serif mr24">2</span>
            <span>
              Your friend buys their{' '}
              <b className="fw-bold">first Feather policy</b>
            </span>
          </p>
        </Trans>
        <Trans i18nKey="referralengine.generator.modal.step.3">
          <p className="p-p px24 py16 bg-grey-100 br8 d-flex ai-center mb8">
            <span className="p-h1 tc-primary-500 p--serif mr24">3</span>
            <span>
              <b className="fw-bold">
                Both of you receive a {{ bonus: FORMATTED_BONUS_AMOUNT }} payout
              </b>{' '}
              after 3 months*
            </span>
          </p>
        </Trans>

        {activeReferralCampaignsForUser &&
          activeReferralCampaignsForUser.length > 0 &&
          activeReferralCampaignsForUser.map((campaign) => (
            <Fragment key={campaign.id}>
              <Trans i18nKey="referralengine.generator.modal.step.4">
                <p
                  className="p-p px24 py16 bg-grey-100 br8 d-flex ai-center mb8"
                  data-testid={`referral-campaign-${campaign.id}`}
                >
                  <span className="p-h1 tc-primary-500 p--serif mr24" />
                  <span>
                    Time-limited offer:{' '}
                    <b className="fw-bold mt8">
                      <br />
                      Get{' '}
                      {{
                        amount: englishFormattedEuroCurrency(
                          campaign.amount,
                          true
                        ),
                      }}{' '}
                      for each{' '}
                      {{
                        vertical:
                          getTitleMapping(t)?.[campaign.sourceInsuranceType],
                      }}{' '}
                      insurance referral
                    </b>
                  </span>
                </p>
              </Trans>
            </Fragment>
          ))}

        <h2 className="p-h4 mt24 mb8">
          {t('referralengine.generator.modal.codetitle', 'Your promo code')}
        </h2>

        <ReferralCodeCta
          referralCode={referralCode}
          loading={loading}
          error={error}
        />

        <Trans i18nKey="referralengine.generator.modal.disclaimer">
          <p className="p-p p-p--small">
            *Only valid once your policy has been active for 3 months. You will
            receive an email to set up the bank account to which the payout will
            be sent. The payout is limited to{' '}
            {{ bonusLimit: FORMATTED_LIMIT_AMOUNT }} per year per person. See
            our{' '}
            <a
              className="p-a"
              href={REFERRAL_ENGINE_TC_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </a>{' '}
            for more information.
          </p>
        </Trans>
      </div>
    </BottomOrRegularModal>
  );
};
