import { Country } from '@getpopsure/public-models';
import { StripeCardElement } from '@stripe/stripe-js';
import { z } from 'zod';

interface Attributes {
  brand: PaymentMethodBrand;
  identifier: string;
  expiry?: {
    month: string;
    year: string;
  };
}

export type PaymentMethodBrand =
  | 'American Express'
  | 'Diners Club'
  | 'Discover'
  | 'JCB'
  | 'MasterCard'
  | 'UnionPay'
  | 'Visa'
  | 'SEPA Direct Debit'
  | 'PayPal'
  | 'Unknown';

export type PaymentMethod = {
  id: string;
  isDefault: boolean;
  attributes: Attributes;
} & (
  | {
      type: 'CARD';
    }
  | {
      type: 'SEPA';
      address: StripeBillingAddress | null;
    }
  | {
      type: 'PAYPAL';
    }
);

const ZPaymentBaseSetupData = z.object({
  makeDefault: z.boolean(),
});

const ZStripeCardElement: z.ZodType<StripeCardElement> = z.any();

export const ZStripeBillingAddress = z.object({
  line1: z.string().min(1),
  line2: z.string().optional(),
  city: z.string().min(1),
  postal_code: z.string().min(1),
  state: z.string(),
  country: z.string(),
});

const ZCardSetupData = ZPaymentBaseSetupData.merge(
  z.object({
    type: z.literal('CARD'),
    cardElement: ZStripeCardElement,
    cardHolderName: z.string().optional(),
    postalCode: z.string().optional(),
  })
);

const ZSepaSetupData = ZPaymentBaseSetupData.merge(
  z.object({
    type: z.literal('SEPA'),
    iban: z.string(),
    accountHolderName: z.string(),
    accountHolderEmail: z.string(),
    address: ZStripeBillingAddress,
  })
);

export type StripeBillingAddress = z.infer<typeof ZStripeBillingAddress>;
export type CardSetupData = z.infer<typeof ZCardSetupData>;
export type SepaSetupData = z.infer<typeof ZSepaSetupData>;

export const isCardSetupData = (
  setupData: CardSetupData | SepaSetupData
): setupData is CardSetupData => ZCardSetupData.safeParse(setupData).success;

export interface SetupIntent {
  clientSecret: string;
}

export type PaymentType = 'CARD' | 'SEPA' | 'PAYPAL';

export type CollectableBillingAddress = Omit<
  StripeBillingAddress,
  'country'
> & { country: Country };
