import { Address } from '@getpopsure/public-models';
import { Button } from '@popsure/dirty-swan';
import { createPortal } from 'react-dom';
import { useSafeTranslation } from 'shared/i18n';

import { AddressPreview, ConfirmAddressModal } from './components';
import style from './GermanAddress.module.scss';

interface GermanAddressViewProps {
  address: Address | null;
  completed?: boolean;
  error?: string;
  loading?: boolean;
  openModal: boolean;
  questionnaireAddress: Address | null;

  handleSaveAddress(address: Address): void;

  onCloseModal(): void;

  onOpenModal(): void;
}

export const GermanAddressView = (props: GermanAddressViewProps) => {
  const {
    address,
    completed,
    error,
    loading,
    openModal,
    questionnaireAddress,
    handleSaveAddress,
    onCloseModal,
    onOpenModal,
  } = props;

  const { t } = useSafeTranslation();

  const onSave = (newAddress: Address) => handleSaveAddress(newAddress);

  if (address && completed) {
    return <AddressPreview address={address} />;
  }

  return (
    <>
      <p className="p-p p-p--small tc-grey-500">
        {t(
          'studentChecklist.germanAddress.description',
          "If you're not in Germany yet, you can provide it when you arrive."
        )}
      </p>
      <Button onClick={onOpenModal} className={style.button}>
        {t(
          'studentChecklist.germanAddress.updateButton.title',
          'Confirm address'
        )}
      </Button>
      {openModal &&
        createPortal(
          <ConfirmAddressModal
            error={error}
            loading={loading}
            questionnaireAddress={questionnaireAddress}
            onClose={onCloseModal}
            onSave={onSave}
          />,
          document.body
        )}
    </>
  );
};
