import { getHouseholdPrices } from '@getpopsure/household-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Household } from 'features/household/models';
import { getPolicyOptionsOrDefaults } from 'features/household/selectors';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import styles from './PriceDetailsSection.module.scss';

type PriceDetailsSectionProps = {
  addonOptions: Household['addonOptions'];
};

export const PriceDetailsSection = ({
  addonOptions,
}: PriceDetailsSectionProps) => {
  const { t } = useSafeTranslation();
  const { hasSecurityDevice, insuredSum, postcode, propertyInSqMeters } =
    useSelector(getPolicyOptionsOrDefaults);

  const { bikeInsuredSum, selectedAddons } = addonOptions;

  const extendedCoverageSelected = selectedAddons.includes('EXTENDED_COVERAGE');
  const brokenGlassCoverageSelected = selectedAddons.includes(
    'BROKEN_GLASS_COVERAGE'
  );
  const additionalBikeCoverageSelected = selectedAddons.includes(
    'ADDITIONAL_BIKE_COVERAGE'
  );

  const householdPrices = getHouseholdPrices({
    postcode,
    propertyInSqMeters,
    insuredSum,
    hasSecurityDevice,
    bikeInsuredSum,
  });

  const basicPrice = householdPrices ? householdPrices.basicPremium : 0;

  const extendedCoveragePrice =
    householdPrices && extendedCoverageSelected
      ? householdPrices.extendedPremium - householdPrices.basicPremium
      : 0;

  const brokenGlassPrice =
    householdPrices && brokenGlassCoverageSelected
      ? householdPrices.glassPrice
      : 0;

  const additionalBikeCoveragePrice =
    householdPrices &&
    extendedCoverageSelected &&
    additionalBikeCoverageSelected
      ? householdPrices.bikePrice
      : 0;

  const totalMonthlySum =
    basicPrice +
    extendedCoveragePrice +
    brokenGlassPrice +
    additionalBikeCoveragePrice;

  return (
    <section className="pt48">
      <h2 className="p-h2 mb16">
        {t('page.household.addons.pricedetails.title', 'Price details')}
      </h2>
      <div className={`d-grid p24 bg-white br8 c-gap8 ${styles.priceGrid}`}>
        <h3 className="p-h3">
          {t(
            'page.household.addons.pricedetails.monthlytotal',
            'Your monthly total'
          )}
          <span className={`p-p tc-grey-500 ${styles.blockOnMobile}`}>
            {' '}
            {t('page.household.addons.pricedetails.withtax', 'incl. tax')}
          </span>
        </h3>
        <h2 className={`p-h2 ${styles.end}`}>
          {englishFormattedEuroCurrency(totalMonthlySum, 2)}
        </h2>

        <p className="p-p">
          {t(
            'page.household.addons.pricedetails.householdcontentscoverage',
            '🛡  Household contents coverage'
          )}
          {hasSecurityDevice && (
            <span className={`p-p tc-grey-500 ${styles.blockOnMobile}`}>
              {' '}
              {t(
                'page.household.addons.pricedetails.withdiscount',
                'with 5% discount'
              )}
            </span>
          )}
        </p>
        <p className={`p-p ${styles.end}`}>
          {englishFormattedEuroCurrency(basicPrice, 2)}
        </p>

        {!!extendedCoveragePrice && (
          <>
            <p className="p-p">
              {t(
                'page.household.addons.pricedetails.extendedcoverage',
                '💯  Extended coverage'
              )}
            </p>
            <p className={`p-p ${styles.end}`}>
              {englishFormattedEuroCurrency(extendedCoveragePrice, 2)}
            </p>
          </>
        )}

        {!!brokenGlassPrice && (
          <>
            <p className="p-p">
              {t(
                'page.household.addons.pricedetails.brokenglasscoverage',
                '🔨  Broken glass'
              )}
            </p>
            <p className={`p-p ${styles.end}`}>
              {englishFormattedEuroCurrency(brokenGlassPrice, 2)}
            </p>
          </>
        )}

        {!!additionalBikeCoveragePrice && (
          <>
            <p className="p-p">
              {t(
                'page.household.addons.pricedetails.additionalbikecoverage',
                '🚲  Additional bike coverage'
              )}
            </p>
            <p className={`p-p ${styles.end}`}>
              {englishFormattedEuroCurrency(additionalBikeCoveragePrice, 2)}
            </p>
          </>
        )}
      </div>
    </section>
  );
};
