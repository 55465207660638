import { legal, website } from '@getpopsure/private-constants';
import Signature from 'components/common/signature';
import { ViewProps } from 'features/brokerMandate/containers';
import React, { useState } from 'react';

import styles from './style.module.scss';

const BrokerMandate = ({ isLoading, submitSignature, error }: ViewProps) => {
  const [signature, setSignature] = useState('');
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const valid = signature.length > 0 && hasAcceptedTerms;

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitSignature(signature);
  };

  return (
    <div className="p-body">
      <div className={styles.container}>
        <div className="p-h1">Confirm signature</div>
        <div className="p-p mt8">
          In order to get covered, we require a signature from you as an act of
          consent. This will be the final step before your policy will begin.
        </div>
        <div className={styles['signature-container']}>
          <Signature
            onChange={(s) => {
              setSignature(s);
            }}
          />
          <form onSubmit={handleFormSubmit}>
            <input
              className="p-checkbox"
              id="brokerMandate"
              type="checkbox"
              onChange={() => {
                setHasAcceptedTerms(!hasAcceptedTerms);
              }}
              checked={hasAcceptedTerms}
            />
            <label className="p-label mt32" htmlFor="brokerMandate">
              <div>
                {' '}
                I have read and understood the{' '}
                <a
                  className="p-a"
                  href={website.privacyPolicy}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  privacy policy
                </a>
                ,{' '}
                <a
                  className="p-a"
                  href={website.termsAndConditions}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  T&Cs
                </a>{' '}
                and{' '}
                <a
                  className="p-a"
                  href={legal.brokerMandate}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Feather broker mandate
                </a>{' '}
                and I hereby confirm that I give my full consent.
              </div>
            </label>
            <div style={{ float: 'right' }}>
              <button
                className={`p-btn p-btn--primary ${
                  isLoading ? 'p-btn--loading' : ''
                } mt16`}
                type="submit"
                disabled={!valid || isLoading}
              >
                Confirm signature
              </button>
              {error && <div className={`mt8 ${styles.error}`}>{error}</div>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BrokerMandate;
