import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

interface Props {
  route?: string;
  isUrlExternal?: boolean;
  primaryText?: string[];
  subText?: string;
  subTextClassName?: string;
  loader?: React.ReactNode;
  onFinished?: () => void;
}

const INTERVAL_TIME = 1_500;
const TIMEOUT_TIME = 4_000;

const QuoteLoading = ({
  route,
  isUrlExternal,
  primaryText,
  subText,
  subTextClassName,
  loader,
  onFinished,
}: Props) => {
  const history = useHistory();
  const [elementIndex, setElementIndex] = useState(0);
  const [fadeInAnimation, setFadeInAnimation] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [finished, setFinished] = useState(false);

  const { t } = useSafeTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadingText = primaryText ?? [
    t('quote.loading.text.first', 'Preparing your options…'),
    t('quote.loading.text.second', 'Calculating risk…'),
    t('quote.loading.text.third', 'Calculating pricing…'),
  ];
  const loadingSubText =
    subText ??
    t(
      'quote.loading.subText',
      'We’re preparing some options that we believe are the best for you based on the answers you provided.'
    );
  const loadingSubTextClass = subTextClassName ?? 'wmx7';

  const actionAfterLoading = useCallback(() => {
    onFinished?.();

    if (route) {
      if (isUrlExternal) {
        window.location.replace(route);
      } else {
        history.push(route);
      }
    }
  }, [isUrlExternal, route, onFinished, history]);

  useEffect(() => {
    if (loadingText.length === 1) {
      const timeoutId = setTimeout(() => {
        actionAfterLoading();
      }, TIMEOUT_TIME);
      return () => clearTimeout(timeoutId);
    }

    const intervalId = setInterval(() => {
      setFadeInAnimation(true);
      setTimeout(() => {
        setElementIndex((index) => index + 1);
        setFadeInAnimation(false);
      }, 300);
    }, INTERVAL_TIME);
    intervalRef.current = intervalId;

    if (
      elementIndex >= loadingText.length - 1 &&
      intervalRef.current &&
      !finished
    ) {
      setFinished(true);
      clearInterval(intervalRef.current);
      const timeoutId = setTimeout(() => {
        actionAfterLoading();
      }, INTERVAL_TIME);
      intervalRef.current = timeoutId;
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [
    loadingText,
    route,
    isUrlExternal,
    actionAfterLoading,
    finished,
    elementIndex,
  ]);

  return (
    <div className="p-body">
      <div className={styles.container}>
        {loader ?? (
          <div className={`ds-spinner ds-spinner__m ${styles.spinner}`} />
        )}
        <div
          className={`p-h1 mt24 ta-center ${
            fadeInAnimation ? styles['text-disappear'] : styles['text-appear']
          }`}
        >
          {loadingText[elementIndex]}
        </div>
        <div
          className={`p-p mt16 ${loadingSubTextClass} ${styles.description}`}
        >
          {loadingSubText}
        </div>
      </div>
    </div>
  );
};

export default QuoteLoading;
