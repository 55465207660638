import { PlusIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { useSafeTranslation } from 'shared/i18n';

export const PetHealthClaimButton = (props: PolicyDetailsButtonProps) => {
  const { onOpenModal } = props;
  const { t } = useSafeTranslation();

  const handleMakeClaimButton = () => {
    onOpenModal('PET_HEALTH_CLAIM');
  };

  return (
    <ButtonCell
      title={t(
        'myPolicies.policyDetails.petHealthClaimsButton.caption',
        'New claim'
      )}
      icon={<PlusIcon noMargin size={24} className="ml16" />}
      onClick={handleMakeClaimButton}
    />
  );
};
