import { Input } from '@popsure/dirty-swan';
import classNames from 'classnames';
import {
  ExpatDependentQuestionnaire,
  ExpatQuestionnaire,
} from 'features/expat/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import styles from './DependentsEmail.module.scss';

export const DependentsEmail = ({
  value,
  onSubmitValue,
  question,
}: CustomComponentProps<ExpatQuestionnaire | ExpatDependentQuestionnaire>) => {
  const [email, setEmail] = useState<string>((value as string) ?? '');

  const { t } = useSafeTranslation();

  return (
    <div className={classNames('d-flex jc-between f-wrap', styles.container)}>
      <div className="p-body">
        <h1 className="p-h1">{question}</h1>
        <div className={styles.contentContainer}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitValue(email);
            }}
          >
            <div className="mt8 wmx6">
              <Input
                className="mt24"
                type="email"
                autoComplete="email"
                placeholder={t(
                  'expat.qnr.dependentsEmail.input.placeholder',
                  'Email address'
                )}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                data-cy="dependent-guardian-email"
              />
            </div>
            <button
              className="p-btn p-btn--primary mt24 wmn3 mb16"
              data-cy="button-continue"
              type="submit"
              disabled={!email}
            >
              {t('expat.qnr.dependentsEmail.button.cta', 'Continue')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
