import { Country } from '@getpopsure/public-models';
import dayjs from 'dayjs';
import { HealthQuestionnaireAnswers } from 'models/recommendationTool';

export const getIsArrivalDateRequired = (
  answers: Partial<HealthQuestionnaireAnswers>
) =>
  !answers.nationalities ||
  answers.nationalities.findIndex((country) => country.code === 'DE') === -1;

export const getArrivalDateYearRange = () => {
  const now = dayjs();
  return {
    min: now.subtract(115, 'years').year(),
    max: now.add(2, 'years').year(),
  };
};

export const nationalitiesRetrieveNextPageId = (
  nationalities: Array<Country>
): keyof Partial<HealthQuestionnaireAnswers> => {
  const nationalitiesIncludeDe =
    nationalities.findIndex((country) => country.code === 'DE') !== -1;

  if (nationalitiesIncludeDe) {
    return 'dateOfBirth';
  }

  return 'arrivalDate';
};

export const getDateOfBirthYearRange = () => {
  const now = dayjs();
  return {
    min: now.subtract(100, 'years').year(),
    max: now.year(),
  };
};

export const nationalitiesAnswerObject = (
  nationalities: Country[]
): Partial<HealthQuestionnaireAnswers> => {
  const nationalitiesIncludeDe =
    nationalities.findIndex((country) => country.code === 'DE') !== -1;

  if (nationalitiesIncludeDe) {
    // TODO: Resetting / removing certain questions should be done outside of this fn
    return { nationalities, arrivalDate: undefined };
  }

  return { nationalities };
};
