import { sendQuestionnaire } from 'actions/recommendationTool';
import Error from 'components/error';
import { RequestType } from 'constants/requestTypes';
import routes from 'constants/routes';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';
import {
  getCurrentRecommendation,
  getPersonaId,
  getQuestionnaireMetas,
  getValidAnswers,
} from 'selectors/recommendationTool';

import { TAllowedInsuranceTypes } from '../../models/recommendation';
import { LoadingSkeleton } from '../LoadingSkeleton';

const REQUEST_TYPE: RequestType = 'RECOMMENDATION_TOOL';

const SubmitAnswers = ({
  insuranceType,
}: {
  insuranceType: TAllowedInsuranceTypes;
}) => {
  const [isFetched, setFetched] = useState(false);
  const recommendation = useSelector(getCurrentRecommendation);
  const answers = useSelector(getValidAnswers);
  const personaId = useSelector(getPersonaId);
  const questionnaireMetas = useSelector(getQuestionnaireMetas);
  const dispatch = useDispatch();
  const { loading, error } = useRequestStatus(REQUEST_TYPE);

  useEffect(() => {
    if (personaId) {
      dispatch(
        sendQuestionnaire({
          personaId,
          answers,
          metas: questionnaireMetas,
        })
      );
      setFetched(true);
    }
    // eslint-disable-next-line
  }, []);

  if (!personaId) {
    return <Redirect to={routes.insuranceTool.path} />;
  }

  if (loading || !isFetched) {
    return <LoadingSkeleton />;
  }

  if (!loading && error) {
    return <Error error={error?.message} />;
  }

  return (
    <Redirect
      to={generatePath(routes.insuranceTool.recommendation.path, {
        recommendationId: recommendation?.id,
        insuranceType,
      })}
    />
  );
};

export { SubmitAnswers };
