import { BottomOrRegularModal, Button } from '@popsure/dirty-swan';
import React from 'react';
import AnimateHeight from 'react-animate-height';

import style from './style.module.scss';

interface ModalWrapperProps {
  onClose: () => void;
  error: string | null;
  onSubmit: () => void;
  loading: boolean;
  children: React.ReactNode;
  disabled: boolean;
}

const ModalWrapper = ({
  children,
  onClose,
  error,
  onSubmit,
  loading,
  disabled,
}: ModalWrapperProps) => (
  <BottomOrRegularModal title="Upload your photo" isOpen onClose={onClose}>
    <div className={style.container}>
      <p className="p-p mb16">
        This photo will be used for your health card. It should be a picture of
        your face on a neutral background, and yes you can smile 😄
      </p>
      {children}
      <AnimateHeight duration={150} height={error ? 'auto' : 0}>
        <div className="p-notice p-notice--danger mt16 p-p">{error}</div>
      </AnimateHeight>
      <Button
        data-testid="photo-upload-submit-button"
        onClick={onSubmit}
        className="mt32 w100 wmx3"
        disabled={disabled}
        loading={loading}
      >
        Submit
      </Button>
    </div>
  </BottomOrRegularModal>
);

export default ModalWrapper;
