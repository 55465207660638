import { ClockTimeIcon } from '@popsure/dirty-swan';
import routes from 'constants/routes';
import { ViewProps } from 'features/claimsProcess/confirmation';
import { generatePath, Link } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

const LegalClaim = ({ policyId }: ViewProps) => {
  const { t } = useSafeTranslation();

  return (
    <div className={`wmx8 w100 ${styles.container}`}>
      <ClockTimeIcon color="primary-500" size={88} />
      <h1 className="p-h2 mt24 ta-center">
        {t(
          'claims.legal.confirmation.title',
          'Thank you for submitting your claim'
        )}
      </h1>
      <p className="p-p mt8 wmx5 ta-center">
        {t(
          'claims.legal.confirmation.description',
          'We will be in contact within the next 2 business days with details of your claim.'
        )}
      </p>
      <Link
        to={generatePath(routes.me.policies.detail.path, {
          policyId,
        })}
        className={`p-btn p-btn--primary mt24 wmx4 ${styles.button}`}
      >
        {t(
          'claims.legal.confirmation.backButton.caption',
          'Return to my policy'
        )}
      </Link>
    </div>
  );
};

export default LegalClaim;
