import { FileExtension } from 'models/healthCard';
import { getFileType } from 'shared/util/getFileType';

import { getImageSize } from './getImageSize';

const IMAGE_SIZE_LIMIT = 15_000_000;
const IMAGE_MIN_WIDTH = 300;
const IMAGE_MIN_HEIGHT = 400;
const VALID_TYPES: Array<FileExtension> = ['png', 'bmp', 'heic', 'jpg', 'jpeg'];

export const isValidPhoto = async (file: File) => {
  try {
    const { size } = file;
    const fileType = getFileType(file.name) as FileExtension;
    if (!VALID_TYPES.includes(fileType)) return false;

    const { width, height } = await getImageSize(file);
    return (
      size < IMAGE_SIZE_LIMIT &&
      width >= IMAGE_MIN_WIDTH &&
      height >= IMAGE_MIN_HEIGHT &&
      VALID_TYPES.includes(fileType)
    );
  } catch (e) {
    if (typeof e === 'string') {
      throw new Error(e);
    }
  }
};
