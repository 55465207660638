import { TFunction } from '@getpopsure/i18n-react';
import {
  BankIcon,
  ClockTimeIcon,
  DentalToothTeethIcon,
  DependentsFamilyIcon,
  DoctorStethoscopeIcon,
  EuroClaimIcon,
  EyeVisionIcon,
  HeartHandIcon,
  HeartIcon,
  HospitalBuildingIcon,
  PregnacyIcon,
  TrendingUpIcon,
  WorldGlobeEarthIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const privateHealth = (t: TFunction): AdditionalInfoType => ({
  description: t(
    'policyModal.privateHealth.description',
    'Private health insurance provides the best coverage in Germany. This includes coveted fertility treatments, a budget for chiropractors, vision aids, private rooms when staying overnight at a hospital, and many other perks that public insurance cannot offer.'
  ),
  accordions: [
    {
      title: t('policyModal.privateHealth.title1', "What's covered"),
      items: [
        {
          id: 'covered-1',
          icon: <DoctorStethoscopeIcon size={24} />,
          question: t(
            'policyModal.privateHealth.covered1.question',
            'Comprehensive health insurance'
          ),
          answer: t(
            'policyModal.privateHealth.covered1.answer',
            "Everything from general doctors' visits, specialists, checkups, medication, and hospital stays to vaccinations and medical transportation. Everything you would expect from a full health insurance."
          ),
        },
        {
          id: 'covered-2',
          icon: <DentalToothTeethIcon size={24} />,
          question: t('policyModal.privateHealth.covered2.question', 'Dental'),
          answer: t(
            'policyModal.privateHealth.covered2.answer',
            'Includes dental checkups, cleanings and procedures as well as inlays, tooth replacements and for children also orthodontics'
          ),
        },
        {
          id: 'covered-3',
          icon: <HeartHandIcon size={24} />,
          question: t(
            'policyModal.privateHealth.covered3.question',
            'Treatments and therapies'
          ),
          answer: t(
            'policyModal.privateHealth.covered3.answer',
            'Preventative care treatments, therapeutic measures, mental health therapy, radiation therapy, medical aids and in some plans also natural health treatments'
          ),
        },
        {
          id: 'covered-4',
          icon: <EyeVisionIcon size={24} />,
          question: t('policyModal.privateHealth.covered4.question', 'Vision'),
          answer: t(
            'policyModal.privateHealth.covered4.answer',
            '- Includes a bi-annual budged of €150 - €450 depending on your chosen plan\n- Refractive eye surgery (e.g. LASIK) for €3,000 - €5,000 depending on your chosen plan'
          ),
        },
        {
          id: 'covered-5',
          icon: <PregnacyIcon size={24} />,
          question: t(
            'policyModal.privateHealth.covered5.question',
            'Pregnancy and childbirth'
          ),
          answer: t(
            'policyModal.privateHealth.covered5.answer',
            '- Full pregnancy coverage\n- Fertility treatment (e.g. IVF) (certain conditions apply)\n- Midwife support during pregnancy, birth and after birth'
          ),
        },
        {
          id: 'covered-6',
          icon: <HospitalBuildingIcon size={24} />,
          question: t(
            'policyModal.privateHealth.covered6.question',
            'Hospital stays'
          ),
          answer: t(
            'policyModal.privateHealth.covered6.answer',
            '- Shared room or private room depending on the plan chosen\n- Visit any public and private hospital\n- Treatment by head physician on some plans'
          ),
        },
        {
          id: 'covered-7',
          icon: <WorldGlobeEarthIcon size={24} />,
          question: t(
            'policyModal.privateHealth.covered7.question',
            'Worldwide coverage'
          ),
          answer: t(
            'policyModal.privateHealth.covered7.answer',
            '- Worldwide coverage of stays up to 1 month\n- EU-wide coverage for longer stays\n- Repatriation costs'
          ),
        },
      ],
    },
    {
      title: t('policyModal.privateHealth.title2', 'Important to know'),
      items: [
        {
          id: 'not-covered-1',
          icon: <TrendingUpIcon size={24} />,
          question: t(
            'policyModal.privateHealth.notCovered1.question',
            'Getting older won’t affect your price'
          ),
          answer: t(
            'policyModal.privateHealth.notCovered1.answer',
            'The price is based on your age when you sign-up. It then only increases when overall healthcare costs go up – historically about 2% per year for public and private insurance alike.'
          ),
        },
        {
          id: 'not-covered-2',
          icon: <HeartIcon size={24} />,
          question: t(
            'policyModal.privateHealth.notCovered2.question',
            'Eligibility depends on your income and health'
          ),
          answer: t(
            'policyModal.privateHealth.notCovered2.answer',
            'The minimum income threshold for private depends on your occupation and age. On the health side, we look at your medical history for any pre-existing conditions. Please keep in mind that some conditions might lead to coverage restrictions or a surcharge.'
          ),
        },
        {
          id: 'not-covered-3',
          icon: <DependentsFamilyIcon size={24} />,
          question: t(
            'policyModal.privateHealth.notCovered3.question',
            'Your dependents could be eligible at an extra cost'
          ),
          answer: t(
            'policyModal.privateHealth.notCovered3.answer',
            'Thinking of adding your dependents? We can check their eligibility to make sure your entire family can be covered.'
          ),
        },
      ],
    },
    {
      title: t(
        'policyModal.privateHealth.title3',
        'Advantages compared to public'
      ),
      items: [
        {
          id: 'advantages-1',
          icon: <BankIcon size={24} />,
          question: t(
            'policyModal.privateHealth.advantages1.question',
            'Less expensive than public'
          ),
          answer: t(
            'policyModal.privateHealth.advantages1.answer',
            'Since private insurance takes on customers based on their health, it is generally more affordable than public, especially if you sign-up while you’re under the age of 45 and healthy. Once signed up, your premium will not increase because you become older.'
          ),
        },
        {
          id: 'advantages-2',
          icon: <ClockTimeIcon size={24} />,
          question: t(
            'policyModal.privateHealth.advantages2.question',
            'Faster appointments and better coverage'
          ),
          answer: t(
            'policyModal.privateHealth.advantages2.answer',
            'Most doctors reserve appointments for private health insurance holders, which makes it easier for you to access healthcare in your area, especially in big cities. In addition to that, you generally get better coverage compared to public insurance, for example in terms of dental and vision care.'
          ),
        },
        {
          id: 'advantages-3',
          icon: <EuroClaimIcon size={24} />,
          question: t(
            'policyModal.privateHealth.advantages3.question',
            'Yearly cashback'
          ),
          answer: t(
            'policyModal.privateHealth.advantages3.answer',
            'If you make no claims during a calendar year, you will receive a payout the following year. The payout increases every consecutive year without claim.'
          ),
        },
      ],
    },
  ],
  buttonText: t('policyModal.privateHealth.actionButton', 'Continue to quote'),
  buttons: [
    {
      text: t(
        'policyModal.privateHealth.buttons.compareHealthInsurances',
        'Compare health insurances'
      ),
      href: 'https://feather-insurance.com/health-insurance',
    },
  ],
});
