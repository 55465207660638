import { BillingAction } from 'constants/actions';
import { Billing, DisputeInvoice } from 'features/billingHistory/models';
import { Reducer } from 'redux';

export interface BillingState {
  billings: Billing[];
  disputeInvoices: DisputeInvoice[];
  hasMorePages: boolean;
  fetchAfter: string | undefined;
}

export const defaultState: BillingState = {
  billings: [],
  disputeInvoices: [],
  hasMorePages: false,
  fetchAfter: undefined,
};

export const reducer: Reducer<BillingState, BillingAction> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    // Successfull, pending, and failed charges
    case 'STORE_BILLINGS':
      return {
        ...state,
        billings: action.billings,
        hasMorePages: action.hasMorePages,
        fetchAfter: action.fetchAfter,
      };
    case 'UPDATE_BILLINGS':
      return {
        ...state,
        billings: [...state.billings, ...action.billings],
        hasMorePages: action.hasMorePages,
        fetchAfter: action.fetchAfter,
      };
    case 'UPDATE_BILLING':
      return {
        ...state,
        billings: state.billings.map((billing) =>
          billing.stripeId === action.billing.stripeId
            ? action.billing
            : billing
        ),
      };

    // Receipts
    case 'UPDATE_CHARGE_RECEIPT':
      return {
        ...state,
        billings: (state.billings || []).map((billing) =>
          billing.stripeId === action.receipt.stripeId
            ? { ...billing, receipt: action.receipt }
            : billing
        ),
      };

    // Dispute invoices
    case 'STORE_DISPUTE_INVOICES':
      return {
        ...state,
        disputeInvoices: action.disputeInvoices,
      };

    default:
      return state;
  }
};

export default reducer;
