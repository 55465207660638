import { TranslationFn } from '@getpopsure/i18n-react';
import { DownloadButton, illustrations } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { Dispatch, Fragment, SetStateAction } from 'react';
import AnimateHeight from 'react-animate-height';

import styles from './DownloadPolicy.module.scss';
import { CheckStatus, DownloadStatus } from './models';

interface Props {
  checkList: CheckStatus[];
  loading: boolean;
  error?: string;
  onContinue: () => void;
  onDownload: () => void;
  downloadStatus: DownloadStatus;
  checkListStatus: Record<string, boolean>;
  setCheckListStatus: Dispatch<SetStateAction<Record<string, boolean>>>;
  t: TranslationFn;
}

export const DownloadPolicyView = ({
  checkList,
  loading,
  error,
  onContinue,
  onDownload,
  downloadStatus,
  checkListStatus,
  setCheckListStatus,
  t,
}: Props) => {
  const downloaded = downloadStatus === 'COMPLETED';
  const generating = downloadStatus === 'GENERATING';
  const isValidToContinue =
    checkList.filter((item) => checkListStatus[item.id] === true).length ===
    checkList.length;

  return (
    <div className="mt80 mb80">
      <div
        className={`p-body d-flex fd-column ai-center jc-center ${styles.container}`}
      >
        <img
          src={illustrations.document1}
          className={styles.icon}
          alt="purple document"
        />
        <h1 className="p-h1 mt24 ta-center">
          {t(
            'components.downloadpolicy.title',
            'Review the policy before signing up'
          )}
        </h1>
        <p className="p-p mt8 ta-center wmx8">
          {t(
            'components.downloadpolicy.description',
            "In these documents, you'll find the details on what's covered, what isn't and what the terms are. We'll also save these in your account for later reference."
          )}
        </p>
        <DownloadButton
          className="mt40"
          downloadStatus={downloadStatus}
          onDownload={onDownload}
        />
        {generating && (
          <p className={`mt16 p-p ${styles.generatingDescription}`}>
            {t(
              'components.downloadpolicy.generating',
              "It can take a few minutes - it's quite the stack"
            )}{' '}
            🥞
          </p>
        )}
        {downloaded && (
          <div
            className={`mt24 d-flex fd-column ai-center ${styles.checkboxContainer}`}
          >
            <div className="p-label-container wmx8">
              {checkList.map(({ id, description: itemDescription }) => (
                <Fragment key={id}>
                  <input
                    className="p-checkbox"
                    id={id}
                    name={id}
                    type="checkbox"
                    checked={checkListStatus[id] === true}
                    onChange={(e) =>
                      setCheckListStatus({
                        ...checkListStatus,
                        [id]: e.target.checked,
                      })
                    }
                  />
                  <label
                    htmlFor={id}
                    className={`p-label p-p mt16 ${styles.checkboxContent}`}
                    data-cy={
                      typeof itemDescription === 'string'
                        ? `download-${id}`
                        : null
                    }
                  >
                    {itemDescription}
                  </label>
                </Fragment>
              ))}
            </div>
            <AnimateHeight duration={300} height={error ? 'auto' : 0}>
              <div className="p-notice p-notice--danger p-p mt16 wmx5">
                {error}
              </div>
            </AnimateHeight>
            <button
              className={classNames('p-btn--primary mt24 ws4', {
                'p-btn--loading': loading,
              })}
              disabled={!isValidToContinue}
              data-cy="button-continue"
              onClick={onContinue}
              type="button"
            >
              {t('components.downloadpolicy.cta', 'Continue')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
