import {
  associatedCountryFlagForCountryCode,
  Country,
  getAllCountries,
} from '@getpopsure/public-models';
import { AutoSuggestInput } from '@popsure/dirty-swan';
import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import { filterOutCountries } from 'shared/util/filterOutCountries';

import QuestionnaireWrapper from '../QuestionnaireWrapper';

interface Props
  extends QuestionnaireFormProps<Country>,
    QuestionnaireWrapperProps {
  filterCountry?: Country[];
  locale?: Parameters<typeof getAllCountries>[0];
}

interface Option {
  value: string;
  leftIcon?: string;
}

export const CountrySingleForm = ({
  value,
  question,
  description,
  onSubmitValue,
  buttonText,
  header,
  loading,
  featureName,
  error,
  additionalInfo,
  filterCountry,
  locale,
}: Props) => {
  const { t } = useSafeTranslation();

  const [currentCountry, setCurrentCountry] = useState<Country | undefined>(
    value
  );
  const [currentOption, setCurrentOption] = useState(
    currentCountry?.name ?? ''
  );
  const [suggestions, setSuggestions] = useState<Option[]>([]);

  const countries =
    filterCountry && filterCountry.length > 0
      ? filterOutCountries(filterCountry, locale)
      : getAllCountries(locale);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!currentCountry) {
      throw new Error(
        `[${featureName}] Country value is missing for submission for question: ${question}`
      );
    }
    onSubmitValue(currentCountry);
  };

  return (
    <QuestionnaireWrapper
      header={header}
      question={question}
      description={description}
      onSubmit={handleSubmit}
      error={error}
      loading={loading}
      validForSubmission={!!currentCountry}
      buttonText={buttonText}
      additionalInfo={additionalInfo}
    >
      <AutoSuggestInput
        currentOption={currentOption}
        placeholder={t(
          'qnf.forms.countryselect.country.placeholder',
          'Country'
        )}
        suggestions={suggestions}
        onChange={(inputValue) => {
          setCurrentOption(inputValue);
          const selectedCountry = countries.find(
            (country) => country.name === inputValue
          );
          setCurrentCountry(selectedCountry);
        }}
        handleSuggestionSelected={(option) => {
          setCurrentOption(option.value);
          const selectedCountry = countries.find(
            (country) => country.name === option.value
          );
          setCurrentCountry(selectedCountry);
        }}
        handleSuggestionFetchRequest={(option) => {
          const filteredOptions = countries
            .filter((country) =>
              country.name.toLowerCase().startsWith(option.value.toLowerCase())
            )
            .map((country) => ({
              value: country.name,
              leftIcon: associatedCountryFlagForCountryCode(country.code),
            }));
          setSuggestions(filteredOptions);
        }}
        handleSuggestionClearRequest={() => setSuggestions([])}
      />
    </QuestionnaireWrapper>
  );
};
