const sessionKey = 'questionnaires';

export const writeSession = (data: unknown) => {
  localStorage.setItem(sessionKey, JSON.stringify(data));
};

export const readSession = (): unknown => {
  try {
    const session = localStorage.getItem(sessionKey);
    return JSON.parse(session as string);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
    return {};
  }
};

export function verticalIdToInsuranceType(input: string): string {
  return input.replace('-', '_').toUpperCase();
}
