import { HealthQuestionnaire } from 'models/recommendationTool';
import {
  InsuranceSituation,
  InsuranceSituationCurrentInsurance,
  InsuranceSituationPreviousInsurance,
  PublicInLastThreeMonths,
} from 'models/recommendationTool/healthQuestionnaire';

import { retrieveAnswerObject, retrieveNextPageId } from './utils';

const insuranceSituationMapping: {
  [k in InsuranceSituation]: string;
} = {
  YES: 'Yes',
  EXPAT: 'Only on expat/incoming insurance',
  NO: 'No',
};

const insuranceSituationCurrentInsuranceMapping: {
  [k in InsuranceSituationCurrentInsurance]: string;
} = {
  CURRENTLY_ON_PUBLIC_IN_DE: 'Public insurance in Germany',
  CURRENTLY_ON_PUBLIC_IN_EU: 'Public insurance elsewhere in the EU/EEA',
  CURRENTLY_ON_PRIVATE_IN_DE: 'Private insurance in Germany',
  CURRENTLY_ON_PRIVATE_IN_EU: 'Private insurance elsewhere in the EU/EEA',
};

const insuranceSituationPreviousInsuranceMapping: {
  [k in InsuranceSituationPreviousInsurance]: string;
} = {
  LAST_INSURANCE_IN_EU_WAS_PUBLIC:
    'Yes, last insured on public in Germany/EU/EEA',
  LAST_INSURANCE_IN_DE_WAS_PRIVATE: 'Yes, last insured on private in Germany',
  LAST_INSURANCE_IN_EU_WAS_PRIVATE:
    'Yes, last insured on private in the EU/EEA',
  LAST_INSURANCE_IN_DE_WAS_EXPAT: 'I’ve only ever had expat insurance',
  NONE: 'No',
};

const publicInLastThreeMonthsMapping: {
  [k in PublicInLastThreeMonths]: string;
} = {
  RECENTLY_ON_PUBLIC_IN_DE_OR_EU: 'Less than 3 months ago',
  LAST_ON_PUBLIC: 'More than 3 months ago',
};

export const insuranceHistory: HealthQuestionnaire = {
  insuranceSituation: {
    required: (answers) => !answers.previousInsurance,
    type: 'RADIO',
    props: {
      question: 'Are you currently health insured anywhere in the EU/EEA?',
      mapValue: insuranceSituationMapping,
      additionalInfo: {
        title: 'Which other countries are included?',
        description:
          'United Kingdom, Switzerland, Turkey, Israel, North Macedonia, Montenegro, Bosnia Herzegovina, Serbia, Norway, Liechtenstein, Iceland, Tunisia and Morocco',
      },
    },
    retrieveAnswerObject: (insuranceSituation: InsuranceSituation) => ({
      insuranceSituation,
    }),
    retrieveNextPageId: (answer: InsuranceSituation) =>
      answer === 'YES'
        ? 'insuranceSituationCurrentInsurance'
        : 'insuranceSituationPreviousInsurance',
    groupId: 'insuranceHistory',
  },

  insuranceSituationCurrentInsurance: {
    required: (answers) =>
      answers.insuranceSituation === 'YES' && !answers.previousInsurance,
    type: 'RADIO',
    props: {
      question: 'What kind of health insurance do you currently have?',
      description: 'Select the first option that applies.',
      mapValue: insuranceSituationCurrentInsuranceMapping,
      additionalInfo: {
        title: 'What is public insurance?',
        description:
          'Public insurance refers to state insurance or universal health care.',
      },
    },
    retrieveAnswerObject:
      retrieveAnswerObject.insuranceSituationCurrentInsuranceAnswerObject,
    retrieveNextPageId:
      retrieveNextPageId.insuranceSituationCurrentInsuranceRetrieveNextPageId,
    groupId: 'insuranceHistory',
  },

  insuranceSituationPreviousInsurance: {
    required: (answers) =>
      (answers.insuranceSituation === 'EXPAT' ||
        answers.insuranceSituation === 'NO') &&
      !answers.previousInsurance,
    type: 'RADIO',
    props: {
      question: 'Have you ever been health insured in the EU/EEA?',
      description: 'Exclude any expat/incoming insurances you had.',
      mapValue: insuranceSituationPreviousInsuranceMapping,
      additionalInfo: {
        title: 'What is public insurance?',
        description:
          'Public insurance refers to state insurance or universal health care.',
      },
    },
    retrieveAnswerObject:
      retrieveAnswerObject.insuranceSituationPreviousInsuranceAnswerObject,
    retrieveNextPageId:
      retrieveNextPageId.insuranceSituationPreviousInsuranceRetrieveNextPageId,
    groupId: 'insuranceHistory',
  },

  publicInLastThreeMonths: {
    required: (answers) =>
      answers.insuranceSituationPreviousInsurance ===
        'LAST_INSURANCE_IN_EU_WAS_PUBLIC' && !answers.previousInsurance,
    type: 'RADIO',
    props: {
      question: 'When did your public health coverage end?',
      mapValue: publicInLastThreeMonthsMapping,
    },
    retrieveAnswerObject: (
      publicInLastThreeMonths: PublicInLastThreeMonths
    ) => ({
      publicInLastThreeMonths,
      previousInsurance: publicInLastThreeMonths,
    }),
    retrieveNextPageId: () => 'occupation',
    groupId: 'insuranceHistory',
  },

  currentInsuranceInsuredInGermany: {
    required: (answers) =>
      answers.insuranceSituationCurrentInsurance ===
        'CURRENTLY_ON_PRIVATE_IN_EU' && !answers.previousInsurance,
    type: 'BOOLEAN',
    props: {
      question:
        'Have you had *public* health insurance in Germany at any time in the last 3 months?',
    },
    retrieveAnswerObject:
      retrieveAnswerObject.currentInsuranceInsuredInGermanyAnswerObject,
    retrieveNextPageId:
      retrieveNextPageId.currentInsuranceInsuredInGermanyRetrieveNextPageId,
    groupId: 'insuranceHistory',
  },

  currentInsuranceLastInsuranceInGermany: {
    required: (answers) =>
      Boolean(answers.currentInsuranceInsuredInGermany) &&
      !answers.previousInsurance,
    type: 'BOOLEAN',
    props: {
      question:
        'Did you have this public health insurance for at least 12 consecutive months before cancelling it?',
    },
    retrieveAnswerObject:
      retrieveAnswerObject.currentInsuranceLastInsuranceInGermanyAnswerObject,
    retrieveNextPageId: () => 'occupation',
    groupId: 'insuranceHistory',
  },

  previousInsuranceInsuredInGermany: {
    required: (answers) =>
      answers.insuranceSituationPreviousInsurance ===
        'LAST_INSURANCE_IN_EU_WAS_PRIVATE' && !answers.previousInsurance,
    type: 'BOOLEAN',
    props: {
      question:
        'Have you had *public* health insurance in Germany at any moment in the last 3 months?',
    },
    retrieveAnswerObject:
      retrieveAnswerObject.previousInsuranceInsuredInGermanyAnswerObject,
    retrieveNextPageId: (answer: boolean) => {
      if (answer) {
        return 'previousInsuranceLastInsuranceInGermany';
      }

      return 'occupation';
    },
    groupId: 'insuranceHistory',
  },

  previousInsuranceLastInsuranceInGermany: {
    required: (answers) =>
      Boolean(answers.previousInsuranceInsuredInGermany) &&
      !answers.previousInsurance,
    type: 'BOOLEAN',
    props: {
      question:
        'Have you had that public insurance for at least 12 consecutive months before cancelling it?',
    },
    retrieveAnswerObject:
      retrieveAnswerObject.previousInsuranceLastInsuranceInGermanyAnswerObject,
    retrieveNextPageId: () => 'occupation',
    groupId: 'insuranceHistory',
  },
};
