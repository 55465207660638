import { Checkbox } from '@popsure/dirty-swan';
import classNames from 'classnames';
import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, ReactNode, useState } from 'react';

import QuestionnaireWrapper from '../QuestionnaireWrapper';
import styles from './CheckboxForm.module.scss';

export interface CheckboxWithDescription {
  title: string;
  description?: string;
  icon?: (selected: boolean) => ReactNode;
}

interface Props<ValueType extends string>
  extends QuestionnaireFormProps<ValueType[]>,
    QuestionnaireWrapperProps {
  mapValue: { [k in ValueType]: string | CheckboxWithDescription };
  showNotification?: (checkBoxValue: ValueType[]) => React.ReactNode;
  wide?: boolean;
  inlineLayout?: boolean;
  optionClassName?: string;
}

export const CheckboxForm = <ValueType extends string>({
  value,
  question,
  description,
  mapValue,
  onSubmitValue,
  buttonText,
  header,
  loading,
  featureName,
  error,
  additionalInfo,
  questionClassName = '',
  twoColumnLayout = false,
  noneCTACaption = '',
  showNotification,
  wide,
  inlineLayout,
  optionClassName,
}: Props<ValueType> & {
  twoColumnLayout?: boolean;
  noneCTACaption?: string;
}) => {
  const defaultValues = noneCTACaption
    ? value?.filter((v) => v !== 'NONE')
    : value;

  const [checkboxValues, setCheckboxValues] = useState<ValueType[]>(
    defaultValues ?? []
  );

  // If a noneCTACaption is set, allow submitting without any value
  const validForSubmission = noneCTACaption
    ? true
    : checkboxValues && checkboxValues.length > 0;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (noneCTACaption && checkboxValues?.length === 0) {
      onSubmitValue(['NONE'] as ValueType[]);
    } else {
      if (!checkboxValues || checkboxValues.length < 1) {
        throw new Error(
          `[${featureName}] Checkbox values are missing for submission from following options: ${Object.keys(
            mapValue
          ).join(', ')} for question: ${question}`
        );
      }
      onSubmitValue(checkboxValues);
    }
  };

  const notification = showNotification?.(checkboxValues);

  let ctaCaption = buttonText;
  if (noneCTACaption && checkboxValues.length === 0) {
    ctaCaption = noneCTACaption;
  }

  return (
    <QuestionnaireWrapper
      onSubmit={handleSubmit}
      header={header}
      question={question}
      questionClassName={questionClassName}
      description={description}
      error={error}
      loading={loading}
      validForSubmission={!!validForSubmission}
      buttonText={ctaCaption}
      additionalInfo={additionalInfo}
      noMaxWidth
    >
      <div>
        <Checkbox
          options={mapValue}
          value={checkboxValues}
          onChange={setCheckboxValues}
          wide={wide || twoColumnLayout}
          inlineLayout={inlineLayout || twoColumnLayout}
          classNames={{
            container: classNames(
              twoColumnLayout ? `d-grid ${styles.grid}` : `d-flex`,
              {
                [styles.wide]: wide || twoColumnLayout,
              }
            ),
            option: optionClassName,
          }}
        />

        {notification}
      </div>
    </QuestionnaireWrapper>
  );
};
