import { RefObject, useEffect, useState } from 'react';

/**
 * useIntersectionOver
 *
 * This hook receives an element reference and returns
 * the intersection observer object
 *
 * @param {RebObject<Element>} elementRef - Reference of the element
 * to get intersection observer changes
 *
 * @param {IntersectionObserverInit} options - Options to configure
 * the intersection observer:
 * * {Element | Document | null} root - Root element to get intersection,
 * by default intersects with window
 * * {number | number[]} number - value from 0 to 1 configuration
 * the intersection threshold
 * * {string} rootMargin - internal margin defined to override
 * intersection border location
 *
 * @returns {IntersectionObserverEntry | undefined}
 *
 */
const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  { root = null, rootMargin = '0%', threshold = 0 }: IntersectionObserverInit
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  useEffect(() => {
    const node = elementRef?.current;

    if (!window.IntersectionObserver || !node) {
      return;
    }

    const observer = new IntersectionObserver(
      ([updatedEntry]) => setEntry(updatedEntry),
      {
        root,
        rootMargin,
        threshold,
      }
    );

    observer.observe(node);

    return () => observer.disconnect();
  }, [root, rootMargin, elementRef, threshold]);

  return entry;
};

export default useIntersectionObserver;
