import { IconTile } from 'components/IconTile';
import { Disability } from 'features/disability/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';

import ballIcon from './assets/ball.svg';
import heartIcon from './assets/heart.svg';
import styles from './NextSteps.module.scss';

export const NextSteps = ({
  onSubmitValue,
}: CustomComponentProps<Disability>) => {
  const { t } = useSafeTranslation();

  return (
    <div
      className={`p-container d-flex fd-column ai-start jc-center ${styles.container}`}
    >
      <h1 className="p-h1">
        {t('disability.nextSteps.title', "Great. Let's get you signed up!")}
      </h1>
      <p className="p-p mt16">
        {t(
          'disability.nextSteps.description',
          'Here’s what the process will look like:'
        )}
      </p>
      <div className={`d-grid gap16 mt24 w100 ${styles.grid}`}>
        <IconTile
          title={t('disability.nextSteps.tile.1.title', 'Medical history')}
          paragraphClassName="p-p--small"
          iconSrc={heartIcon}
          iconAlt={t('disability.nextSteps.tile.1.iconalt', 'ball')}
        >
          {t(
            'disability.nextSteps.tile.1.description',
            "We'll ask a few questions about your medical history to evaluate your current health status."
          )}
        </IconTile>
        <IconTile
          title={t('disability.nextSteps.tile.2.title', 'Lifestyle')}
          paragraphClassName="p-p--small"
          iconSrc={ballIcon}
          iconAlt={t('disability.nextSteps.tile.2.iconalt', 'ball')}
        >
          {t(
            'disability.nextSteps.tile.2.description',
            "We'll ask a few questions about your lifestyle and about your insurance history."
          )}
        </IconTile>
      </div>
      <button
        className="p-btn p-btn--primary mt24 wmn4"
        onClick={() => onSubmitValue(true)}
        data-cy="next-steps-button"
        type="button"
      >
        {t(
          'disability.nextSteps.continueButton.caption',
          'Continue application'
        )}
      </button>
    </div>
  );
};
