import { Card, VideoIcon } from '@popsure/dirty-swan';
import routes from 'constants/routes';
import { PolicySingleGenericSectionProps } from 'features/policySingle/PolicySingle.models';
import { generatePath, useHistory } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import styles from './VideoDoctorAppointment.module.scss';

export const VideoDoctorAppointment = (
  props: PolicySingleGenericSectionProps
) => {
  const { policyData } = props;
  const { t } = useSafeTranslation();
  const history = useHistory();

  const handleVideoDoctorAppointmentClick = () => {
    history.push(
      generatePath(routes.policies.expatEu.videoDoctorAppointment.path, {
        policyId: policyData.id,
        localeId: policyData.region,
      })
    );
  };

  return (
    <div className="p-container">
      <Card
        title={t(
          'myPolicies.sections.expatEu.videoDoctorAppointment.title',
          'Video doctor'
        )}
        description={t(
          'myPolicies.sections.expatEu.videoDoctorAppointment.description',
          'Get a doctor consultation from the comfort of your home'
        )}
        onClick={handleVideoDoctorAppointmentClick}
        classNames={{ buttonWrapper: styles.card }}
        icon={<VideoIcon color="grey-700" size={32} />}
      />
    </div>
  );
};
