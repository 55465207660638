import { getTrackingObject } from '@getpopsure/tracker';
import { AxiosInstance } from 'axios';
import { PrivatePreSignup } from 'features/privateHealthPreSignup/models';

export const submitPrivatePreSignupQuestionnaire = (
  network: AxiosInstance,
  {
    answers,
    basicPrice,
    plusPrice,
    premiumPrice,
    email,
    sendEmail,
    name,
  }: {
    answers: Partial<PrivatePreSignup>;
    basicPrice?: number;
    plusPrice?: number;
    premiumPrice?: number;
    email?: string;
    sendEmail?: boolean;
    name?: string;
  }
) => {
  const source = getTrackingObject();
  return network.post<{ id: string }>('/questionnaires/', {
    questionnaireType: 'PRIVATE_HEALTH_PRE_SIGNUP',
    answers: {
      ...answers,
      basicPrice,
      plusPrice,
      premiumPrice,
      email,
      sendEmail,
      name,
    },
    metadata: { source },
  });
};
