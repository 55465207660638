import { capitalizeName as _capitalizeName } from '@getpopsure/public-utility';

export const trimSpaces = (str: string) => str.replace(/\s+/g, ' ').trim();

export const capitalizeName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}): string =>
  _capitalizeName({
    firstName,
    lastName,
  }) || '';

export const capitalizeNameSplit = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}): string | [string, string] => {
  const capitalizedName =
    _capitalizeName({
      firstName,
      lastName,
    }) || '';

  const indexToSplitAt = trimSpaces(firstName).length;

  return [
    capitalizedName.slice(0, indexToSplitAt),
    capitalizedName.slice(indexToSplitAt + 1),
  ];
};
