import { Icon } from '@popsure/dirty-swan/dist/esm/lib/components/cards/icons';
import classNames from 'classnames';
import { ChangeEvent } from 'react';

import styles from './style.module.scss';

interface CardWithCheckboxProps {
  id: string;
  title: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  description?: string;
  icon?: Icon;
  loading?: boolean;
}

const CardWithCheckbox = (props: CardWithCheckboxProps) => {
  const { id, title, description, icon, onChange, checked, loading } = props;
  return (
    <div
      className={classNames(
        'p-label-container d-flex mt24 w100',
        styles.container,
        { [styles.loading]: loading }
      )}
    >
      <input
        id={id}
        className="p-checkbox"
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={loading}
      />
      <label htmlFor={id} className="p-label w100">
        <div>
          <p className="p-h4">{title}</p>
          {description && <p className="p-p--small mr16">{description}</p>}
        </div>
        {icon && (
          <img
            src={icon.src}
            alt={icon.alt}
            className={classNames(styles.icon, 'ws1 wmn1 mr16')}
          />
        )}
      </label>
    </div>
  );
};

export default CardWithCheckbox;
