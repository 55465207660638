import { ArrowLeftIcon, XIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import routes from 'constants/routes';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { isMobileApp } from 'shared/util/isMobileApp';

import styles from './style.module.scss';

interface Props extends React.HTMLProps<HTMLButtonElement> {
  path?: string;
  tall?: boolean;
  title: string;
  onClick?: () => void;
}

const NavButtonContent = ({
  className,
  onClick,
  path,
  tall = true,
  title,
  ...rest
}: Props) => {
  const history = useHistory();
  const aboveTablet = useMediaQuery('ABOVE_DESKTOP');

  const isSignUpPath = history.location.pathname.startsWith('/policies/');

  const handleOnClick = () => {
    if (onClick) {
      onClick?.();

      return;
    }

    path ? history.push(path) : history.goBack();
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(
          'd-flex jc-between',
          className,
          styles.container,
          {
            [styles.containerTall]: !!tall,
            [styles.containerMobileApp]: isMobileApp,
          }
        )}
      >
        <button
          {...rest}
          className={classNames(
            styles.button,
            'd-inline-flex ai-center p-p c-pointer ds-interactive-component'
          )}
          onClick={handleOnClick}
          type="button"
        >
          <ArrowLeftIcon
            size={aboveTablet ? 16 : 24}
            className="d-inline-flex"
          />

          <span className={styles.title}>{title}</span>
        </button>

        {isMobileApp && isSignUpPath && (
          <button
            {...rest}
            className={classNames(
              styles.button,
              'd-inline-flex ai-center p-p c-pointer ds-interactive-component'
            )}
            onClick={() => history.push(routes.me.policies.list.path)}
            type="button"
          >
            <XIcon size={aboveTablet ? 16 : 24} className="d-inline-flex" />
          </button>
        )}
      </div>
    </div>
  );
};

interface NavButtonProps extends Props {
  renderInPortal?: boolean;
}

export const NavButton = ({ renderInPortal, ...rest }: NavButtonProps) => {
  const aboveTablet = useMediaQuery('ABOVE_DESKTOP');

  if (renderInPortal && !aboveTablet) {
    return createPortal(<NavButtonContent {...rest} />, document.body);
  }

  return <NavButtonContent {...rest} />;
};
