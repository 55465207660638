import { CheckThickIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import React from 'react';

import { GenericStep } from '../Checklist/checklist.models';
import styles from './ChecklistItem.module.scss';

interface ChecklistItemProp {
  step: GenericStep;
  submittedChecklist?: boolean;
}

export const ChecklistItem = (props: ChecklistItemProp) => {
  const {
    step: { number, title, description, children, active, completed },
    submittedChecklist,
  } = props;

  const renderChildrenContainer = Boolean(
    (active || completed) && (description || children)
  );

  return (
    <div className={`d-flex w100 mb32 ${styles.step}`}>
      <div className={`d-flex w100 ${styles.header}`}>
        <div
          className={classNames(
            'p-h2 p--serif d-flex',
            styles.number,
            active ? 'tc-primary-500' : 'tc-grey-500',
            { [styles['number--active']]: active }
          )}
        >
          {completed ? (
            <CheckThickIcon
              color={active ? 'primary-500' : 'grey-400'}
              size={24}
              noMargin
            />
          ) : (
            number
          )}
        </div>
        <h1
          className={classNames('p-p', styles.title, {
            'tc-grey-500': !active,
          })}
        >
          {title}
        </h1>
      </div>

      {renderChildrenContainer && (
        <div
          className={classNames('w100', {
            [styles['title-spacing']]: !!title,
            [styles['number-spacing']]: !!number,
          })}
        >
          {active && <p className="p-p--small tc-grey-500">{description}</p>}
          {(active || completed) &&
            React.Children.map(children, (child) =>
              React.isValidElement(child)
                ? React.cloneElement(child, {
                    active,
                    completed,
                    submittedChecklist,
                  })
                : child
            )}
        </div>
      )}
    </div>
  );
};
