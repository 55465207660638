import { Household, HouseholdQuestionnaire } from 'features/household/models';

import { Review } from './Review';

export const reviewInfoQuestionnaire = (): HouseholdQuestionnaire => ({
  review: {
    required: true,
    type: 'CUSTOM',
    groupId: 'signup',
    component: Review,
    retrieveAnswerObject: (
      review: Household['review']
    ): Partial<Household> => ({
      review,
    }),
  },
});
