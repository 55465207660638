import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPolicyChecklists } from 'selectors/policies';

import { PolicyChecklists } from './PolicyChecklists';

export const PolicyChecklistsContainer = ({
  policySingleV2,
}: {
  policySingleV2?: boolean;
}) => {
  const { policyId } = useParams<{ policyId: string }>();
  const { withStudentChecklist, withHealthCardChecklist } = useSelector(
    getPolicyChecklists(policyId)
  );

  return (
    <PolicyChecklists
      withStudentChecklist={withStudentChecklist}
      withHealthCardChecklist={withHealthCardChecklist}
      policySingleV2={policySingleV2}
    />
  );
};

export default PolicyChecklistsContainer;
