import { insurance } from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import routes from 'constants/routes';
import {
  bannerColorMapping,
  imageSrcSetTypeMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';
import { UnifiedQuestionnaireAnswers } from 'models/recommendationTool';

import { CardText, CategoryLookup, Section } from '../models/lookups';
import { RecommendationEntryOptions } from '../models/recommendation';
import { typeformLinkWithVertical } from './shared';

export const bikeCategory: CategoryLookup = {
  shortTitle: 'Bike',
  title: 'Bike insurance',
  excerpt: 'Coverage for bikes in the case of theft, damages, and more',
  description:
    'Bike insurance offers protection for newly purchased bikes less than 6 months old. Our coverage protects both e-bikes and normal bikes against theft (including parts and battery). You can extend your coverage to include vandalism, production errors, and even wear and tear.',
  icon: imageTypeMapping.BIKE,
  iconSrcSet: imageSrcSetTypeMapping.BIKE,
  bannerColor: bannerColorMapping.BIKE,
  landingPage: insurance.bike.landingPage,
  quotePage: routes.policies.bike.path,
  enabled: true,
};

const maxAmortizationInYears = 40;

const renderDescription = (
  { bikePurchasePrice = 0 }: UnifiedQuestionnaireAnswers,
  options: RecommendationEntryOptions
) => {
  const bikePrice = englishFormattedEuroCurrency(bikePurchasePrice, true);
  const bikeYears =
    options?.amortizationOfBikeInYears ?? maxAmortizationInYears;
  const formatedBikeYears =
    bikeYears > maxAmortizationInYears ? maxAmortizationInYears : bikeYears;

  return `From a cost perspective, the insurance is worth it if your **${bikePrice}** bike gets stolen in less than **${formatedBikeYears}** years. The likelihood of your bike being stolen depends on the city you live in and how you secure your bike.`;
};

export const bikeSection: Section = {
  title: '',
  description: renderDescription,
  icon: '',
  status: {
    ELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.bike.landingPage,
      },
    },
    PARTIAL: {
      cta: {
        caption: 'Learn more',
        href: insurance.bike.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('bike'),
      },
    },
    MORE_INFO_REQUIRED: {
      cta: {
        caption: 'Learn more',
        href: insurance.bike.landingPage,
      },
    },
    INELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.bike.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('bike'),
      },
    },
  },
};

export const bikeCardText: CardText = {
  BIKE_CARBON_FRAME_PARTIAL: {
    title: "Our bike insurance doesn't cover carbon frame bikes yet.",
    description:
      'Still, you might be able to get coverage for your bike under [extended household insurance](https://faq.feather-insurance.com/en/articles/28620-how-can-i-use-household-contents-insurance-to-cover-my-bike).',
  },
  BIKE_VERY_HIGH_RISK_AREA_PRO: {
    title: 'You live in a very high risk area',
    description:
      'Your postal code is in an area where theft and vandalism are very frequent compared to the rest of the country.',
  },
  BIKE_HIGH_RISK_AREA_PRO: {
    title: 'You live in a high risk area',
    description:
      'Your postal code is in an area where theft and vandalism are quite frequent compared to the rest of the country.',
  },
  BIKE_AVERAGE_RISK_AREA_PRO: {
    title: 'You live in an average risk area',
    description:
      'Your postal code is in an area with an average risk for theft and vandalism compared to the rest of the country.',
  },
  BIKE_EXTRA_COVERAGE_PRO: {
    title: 'Extra coverage available',
    description:
      'Our comfort bike plan is still only a few euros per month and includes extra features like wear and tear for the first three years, damage to your bike after an accident, and even vandalism.',
  },
  BIKE_BATTERY_COVERED_PRO: {
    title: 'Covers your battery',
    description:
      'Our comfort tariff covers wear and tear for the battery, and our basic tariff covers battery theft.',
  },
  BIKE_ACCESSORIES_COVERED_PRO: {
    title: 'Covers accessories',
    description:
      "The following are examples of what's covered: Trailer, lighting, basket, lock, bag, flag, helmet, child's seat, bell, air pump, reflector, rain cover, seat cushion, lock, tow bar, mudguard, speedometer (no multi-functional devices), drinking bottle, and tools/patches. All of these must be attached in some way to the bike when stolen.",
  },
  BIKE_WORLDWIDE_COVERAGE_PRO: {
    title: 'Worldwide coverage',
    description:
      "As long as you're a resident in Germany, your bike is covered wherever you go!",
  },
  BIKE_CARBON_FRAME_VINTAGE_PARTIAL: {
    title: "Our bike insurance doesn't cover carbon frame vintage bikes yet.",
    description:
      'Still, you might be able to get coverage for your bike under [extended household insurance](https://faq.feather-insurance.com/en/articles/28620-how-can-i-use-household-contents-insurance-to-cover-my-bike).',
  },
  BIKE_VINTAGE_PARTIAL: {
    title: "Our bike insurance doesn't cover vintage bikes yet.",
    description:
      'Still, you might be able to get coverage for your bike under [extended household insurance](https://faq.feather-insurance.com/en/articles/28620-how-can-i-use-household-contents-insurance-to-cover-my-bike).',
  },
  BIKE_HIGH_POLICY_COST_CON: {
    title:
      "The cost of your policy might be too high based on the bike's price",
    description:
      "Your bike's purchase price might be lower than what you'd pay for insurance long-term. You can still get extra coverage with our Comfort bike insurance plan to cover things like wear and tear and vandalism for both your bike and bike parts, which could be more beneficial.",
  },
  BIKE_LOW_RISK_AREA_CON: {
    title: 'Low risk area',
    description:
      'Although you live in a low risk area for bike theft and the purchase price might not justify bike insurance long term, you might want to consider comfort bike insurance to cover wear and tear, bike accessories, and even damage to your bike.',
  },
  BIKE_COVERAGE_LIMITATIONS_CON: {
    title: 'After 3 years, coverage limitations kick in',
    description:
      "For comfort bike insurance, you'll be able to enjoy the same coverage as before, but after 3 years, wear and tear is no longer covered.",
  },
  BIKE_EXCLUSIONS_APPLY_CON: {
    title: 'Some exclusions apply',
    description:
      'We unfortunately cannot cover the following: - Bicycles that were not purchased from an official dealer - Bikes used for your job',
  },
};
