import * as Sentry from '@sentry/react';
import { fetchPolicyDetail } from 'actions/policies';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import { PostQuoteQuestionnaire as BikePostQuoteQuestionnaire } from 'models/bike';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';
import { NestedPartial } from 'shared/models/util';

export const updateBikeQuestionnaire =
  ({
    answers,
    questionnaireId,
    policyId,
    uploadTokens,
  }: {
    answers: NestedPartial<BikePostQuoteQuestionnaire>;
    questionnaireId: string;
    policyId: string;
    uploadTokens?: string[];
  }): ThunkAction<
    Promise<ActionResponse>,
    AppState,
    Record<string, unknown>,
    RequestAction
  > =>
  async (
    dispatch: ThunkDispatch<AppState, Record<string, unknown>, RequestAction>
  ) => {
    const requestType: RequestType = 'UPDATE_QUESTIONNAIRE';

    dispatch(setRequestInProcess(true, requestType));

    try {
      await endpoint.updateBikeQuestionnaire(
        answers,
        questionnaireId,
        uploadTokens
      );

      dispatch(fetchPolicyDetail(policyId));
      dispatch(setRequestInProcess(false, requestType));

      return 'SUCCESS';
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      Sentry.captureException(
        `[Bike] Failed trying to update bike policy with policy id: ${policyId} and questionnaire id: ${questionnaireId}`
      );

      return 'ERROR';
    }
  };
