import { InfoCard } from '@popsure/dirty-swan';
import { IconTile } from 'components/IconTile';
import Markdown from 'components/markdown';
import SectionHeader from 'components/sectionHeader';

import styles from './WhatsCoveredSection.module.scss';

export type CoveredItem = {
  title: string;
  icon?: {
    src: string;
    alt: string;
  };
  description: string;
};

export type CoveredSection = {
  title: string;
  layout?: {
    compact?: boolean;
    cards?: boolean;
  };
  items: CoveredItem[];
};

export const WhatsCoveredSection = ({
  title,
  items,
  layout,
}: CoveredSection) => {
  return (
    <section className={`p-container ${styles.container}`}>
      <div
        className={`d-flex ai-center jc-between gap8 ${styles.titleContainer}`}
      >
        <div className={`d-flex fd-column ${styles.sectionHeaderContainer}`}>
          <SectionHeader title={title} />
        </div>
      </div>
      <div
        className={`d-flex fd-row f-wrap jc-center c-gap16 r-gap32 mt32 ${styles.tileContainer}`}
      >
        <Items
          items={items}
          compact={layout?.compact ?? false}
          cards={layout?.cards ?? false}
        />
      </div>
    </section>
  );
};

const Items = ({
  items,
  compact,
  cards,
}: {
  items: CoveredItem[];
  compact: boolean;
  cards: boolean;
}) => {
  return (
    <>
      {items.map(
        ({ title: itemTitle, icon = { src: '', alt: '' }, description }) => {
          const className = compact ? 'wmx4' : 'wmx6';
          return cards ? (
            <InfoCard
              key={itemTitle}
              className={className}
              title={itemTitle}
              topIcon={icon}
              state="static"
            >
              <div className="p-p tc-grey-600 ta-center">
                <Markdown>{description}</Markdown>
              </div>
            </InfoCard>
          ) : (
            <IconTile
              key={itemTitle}
              className={className}
              title={itemTitle}
              iconSrc={icon?.src}
              iconAlt={icon?.alt}
            >
              {description}
            </IconTile>
          );
        }
      )}
    </>
  );
};
