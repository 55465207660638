import { TFunction } from '@getpopsure/i18n-react';
import {
  BikeBicycleIcon,
  CalendarIcon,
  ClockTimeIcon,
  DropletIcon,
  FireIcon,
  GlassIcon,
  GlobeNetworkEarthIcon,
  HomeIcon,
  LoaderIcon,
  SofaIcon,
  TruckIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const household = (t: TFunction): AdditionalInfoType => ({
  price: 2.5,
  description: t(
    'policyModal.household.description',
    'Household contents insurance covers any belongings in your home that aren’t attached in some way to the building. This can include things like furniture, dishes, electronics, clothing, books, and other belongings.'
  ),
  accordions: [
    {
      title: t('policyModal.household.title1', "What's covered"),
      items: [
        {
          id: 'covered-1',
          icon: <HomeIcon size={24} />,
          question: t(
            'policyModal.household.covered1.question',
            'Burglary and vandalism'
          ),
          answer: t(
            'policyModal.household.covered1.answer',
            'You’ll be reimbursed for items stolen or damaged during break-ins.'
          ),
        },
        {
          id: 'covered-2',
          icon: <DropletIcon size={24} />,
          question: t(
            'policyModal.household.covered2.question',
            'Water damage'
          ),
          answer: t(
            'policyModal.household.covered2.answer',
            "You're covered for water damage to your belongings caused by a burst pipe or broken container (e.g. aquarium, water bed). The pipes themselves are not covered."
          ),
        },
        {
          id: 'covered-3',
          icon: <FireIcon size={24} />,
          question: t('policyModal.household.covered3.question', 'Fire'),
          answer: t(
            'policyModal.household.covered3.answer',
            'Your belongings are covered, as well as your temporary accommodation (for up to 180 days) if you’re unable to live in your apartment.'
          ),
        },
        {
          id: 'covered-4',
          icon: <SofaIcon size={24} />,
          question: t('policyModal.household.covered4.question', 'Moving'),
          answer: t(
            'policyModal.household.covered4.answer',
            'If you’re moving, both apartments are temporarily covered until you’re in the new apartment.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.household.title2', 'Add-ons'),
      items: [
        {
          id: 'addons-1',
          icon: <BikeBicycleIcon size={24} />,
          question: t(
            'policyModal.household.addons1.question',
            'Bicycle theft'
          ),
          answer: t(
            'policyModal.household.addons1.answer',
            'If your bike, saddle, lights, or basket are stolen inside or outside your home, the insurance will pay to replace them.'
          ),
        },
        {
          id: 'addons-2',
          icon: <GlassIcon size={24} />,
          question: t('policyModal.household.addons2.question', 'Broken glass'),
          answer: t(
            'policyModal.household.addons2.answer',
            'Whether it’s windows, induction stoves, mirrors, or glass furniture, you’re covered for repair or replacement costs if something gets smashed.'
          ),
        },
        {
          id: 'addons-3',
          icon: <FireIcon size={24} />,
          question: t('policyModal.household.addons3.question', 'Negligence'),
          answer: t(
            'policyModal.household.addons3.answer',
            'E.g. accidental fire due to a candle you left burning.'
          ),
        },
        {
          id: 'addons-4',
          icon: <TruckIcon size={24} />,
          question: t(
            'policyModal.household.addons4.question',
            'Moving expenses'
          ),
          answer: t(
            'policyModal.household.addons4.answer',
            'If you’re no longer able to live inside of your apartment due to an insured event, you can get €5,000 for moving expenses on top of the accommodation being covered.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.household.title3', 'What’s not covered'),
      items: [
        {
          id: 'not-covered-1',
          icon: <GlassIcon size={24} />,
          question: t(
            'policyModal.household.notCovered1.question',
            'Building fixtures'
          ),
          answer: t(
            'policyModal.household.notCovered1.answer',
            'Things like doors or sinks are not covered as they are building fixtures and are covered by property insurance and your liability insurance.'
          ),
        },
        {
          id: 'not-covered-2',
          icon: <LoaderIcon size={24} />,
          question: t(
            'policyModal.household.notCovered2.question',
            'Blown fuses'
          ),
          answer: t(
            'policyModal.household.notCovered2.answer',
            'Electronics damaged by blown fuses are not covered (unless caused by lightning), but such cases are covered by electronics insurance.'
          ),
        },
        {
          id: 'not-covered-3',
          icon: <DropletIcon size={24} />,
          question: t('policyModal.household.notCovered3.question', 'Pipes'),
          answer: t(
            'policyModal.household.notCovered3.answer',
            'Pipes under the floor or in the walls are not covered as the landlord should cover them under their building insurance.'
          ),
        },
        {
          id: 'not-covered-4',
          icon: <GlobeNetworkEarthIcon size={24} />,
          question: t(
            'policyModal.household.notCovered4.question',
            'Large-scale conflict'
          ),
          answer: t(
            'policyModal.household.notCovered4.answer',
            'If large-scale conflict breaks out and causes damages to your belongings, they won’t be covered.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.household.title4', 'Important to know'),
      items: [
        {
          id: 'important-1',
          question: t(
            'policyModal.household.important1.question',
            'Reimbursement value'
          ),
          answer: t(
            'policyModal.household.important1.answer',
            'You’ll be reimbursed for the price of your valuables when they were new, instead of their value at the time they were stolen or damaged.'
          ),
        },
        {
          id: 'important-2',
          question: t(
            'policyModal.household.important2.question',
            'The entire flat must be insured'
          ),
          answer: t(
            'policyModal.household.important2.answer',
            'Unfortunately, single rooms cannot be insured. If you live in a shared household, you will need to have one policy to cover all the belongings and can split the cost with the household members.'
          ),
        },
        {
          id: 'important-3',
          question: t(
            'policyModal.household.important3.question',
            'Household vs liability insurance'
          ),
          answer: t(
            'policyModal.household.important3.answer',
            'While household covers the belongings in your apartment for fires and theft, it’s liability insurance that covers the damage you do to other people’s (e.g. your landlord’s) belongings.'
          ),
        },
      ],
    },
  ],
  details: [
    {
      icon: CalendarIcon,
      title: t('policyModal.household.details.title1', 'Cancel anytime'),
      description: t(
        'policyModal.household.details.description1',
        'Cancel before the 1st of the month'
      ),
    },
    {
      icon: ClockTimeIcon,
      title: t('policyModal.household.details.title2', 'No waiting periods'),
      description: t(
        'policyModal.household.details.description2',
        'You can use your policy right away'
      ),
    },
  ],
  buttonText: t('policyModal.household.buttonText', 'Get covered'),
});
