import { AxiosInstance } from 'axios';

import { CheckoutInfo, CheckoutPolicyRequestPayload } from '../models';

export function createCheckoutIdAndRetrieveCheckoutInfo(
  network: AxiosInstance,
  data: CheckoutPolicyRequestPayload
) {
  return network.post<CheckoutInfo>('/checkout', data);
}

export function processCheckoutSubscription(
  network: AxiosInstance,
  checkoutId: string,
  paymentMethodId: string,
  referralCode?: string,
  returnPath?: string
) {
  return network.post<{
    actionRequired: boolean;
    clientSecret: string;
  }>(`/checkout/${checkoutId}/process`, {
    paymentMethodId,
    referralCode,
    returnPath,
  });
}

export function confirmCheckoutSubscription(
  network: AxiosInstance,
  checkoutId: string,
  referralCode?: string
) {
  return network.post(`/checkout/${checkoutId}/confirm`, {
    referralCode,
  });
}

export function finalizeCheckout(
  network: AxiosInstance,
  checkoutId: string,
  paymentSucceeded: boolean,
  referralCode?: string
) {
  return network.post(`/checkout/${checkoutId}/finalize`, {
    referralCode,
    paymentSucceeded,
  });
}
