import Questionnaire from 'features/questionnaireFramework';

import {
  PrivateHealthClaims,
  Questionnaire as PrivateHealthQuestionnaire,
} from './models';

interface ViewProps {
  questionnaire: PrivateHealthQuestionnaire;
  questionnaireAnswers: Partial<PrivateHealthClaims>;
  handleAnswerQuestion: (answer: Partial<PrivateHealthClaims>) => void;
}

export const PrivateHealthClaimsView = ({
  questionnaireAnswers,
  handleAnswerQuestion,
  questionnaire,
}: ViewProps) => {
  return (
    <Questionnaire
      featureName="Private Health Claims"
      questionnaire={questionnaire}
      questionnaireAnswers={questionnaireAnswers}
      handleAnswerQuestion={handleAnswerQuestion}
    />
  );
};
