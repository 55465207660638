import styles from './style.module.scss';

export const ErrorBoundaryFallback = () => (
  <div className={styles.container}>
    <div className="p-body">
      <div className={styles.content}>
        <h1 className="p-h1 mt24">Oops!</h1>

        <p className="p-p mt24">
          It seems like something went wrong. Any saved information may be lost.
          <br /> We recommend updating your browser to the latest version.
        </p>
        <div className="mt16">
          <a href="/#" className={`p-btn p-a ${styles.button}`}>
            Refresh the page
          </a>
        </div>
      </div>
    </div>
  </div>
);
