import { flushDentalClaimsAnswers } from 'features/dentalClaims/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ClaimSubmitted } from 'shared/pages/ClaimSubmitted';

export const DentalClaimsSubmitted = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleUnmount = () => {
      dispatch(flushDentalClaimsAnswers());
    };

    return handleUnmount;
  }, [dispatch]);

  return <ClaimSubmitted vertical="dentalV2" />;
};
