import { PaymentMethodBrand } from 'models/paymentMethods';

import { mapping } from './icons';
import styles from './style.module.scss';

const CardBrand = ({ brand }: { brand: PaymentMethodBrand }) => {
  const icon = mapping[brand];

  return (
    <div className={styles.brand}>
      <img title={brand} src={icon} alt={`${brand} icon`} />
    </div>
  );
};

export default CardBrand;
