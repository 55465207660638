import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import { v4 as uuidv4 } from 'uuid';

import DocumentCell from '../../components/legacy/documentCell';
import { PolicySingleCard } from '../../components/PolicySingleCard';
import { PolicyDocumentsProps } from './PolicyDocuments.models';
import styles from './PolicyDocuments.module.scss';

const ITEM_LIMIT = 10;

export const PolicyDocuments = (props: PolicyDocumentsProps) => {
  const { documents } = props;
  const { t } = useSafeTranslation();
  const [itemsLimit, setItemsLimit] = useState(5);

  if (!documents || documents.length === 0) return null;

  const showMore = () => {
    setItemsLimit((limit) => Math.min(limit + ITEM_LIMIT, documents.length));
  };

  const visibleDocuments = documents.slice(0, itemsLimit);

  return (
    <div className="p-container">
      <PolicySingleCard
        title={t('myPolicies.policyDocuments.title', 'Policy documents')}
      >
        <div className={styles.documentsWrapper}>
          {visibleDocuments.map((document) => (
            <DocumentCell
              key={uuidv4()}
              title={document.title}
              downloadURL={document.downloadURL}
            />
          ))}
        </div>
        {itemsLimit < (documents?.length || 0) && (
          <div className="d-flex ai-center jc-center mt24">
            <button
              className="p-p tc-grey-500 fw-700 ds-interactive-component ds-card--no-dropshadow"
              onClick={showMore}
              type="button"
            >
              {t(
                'myPolicies.policyDocuments.showMoreButton.title',
                'Show more'
              )}
            </button>
          </div>
        )}
      </PolicySingleCard>
    </div>
  );
};
