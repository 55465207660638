import { TFunction } from '@getpopsure/i18n-react';
import { getTrackingObject } from '@getpopsure/tracker';
import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkAction } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint from 'shared/api';
import { CreateQuoteDispatch } from 'SignupQuestionnaire/actions/account';

import { getJobCollar } from '../constants/profession';
import {
  CreateQuoteReturnType,
  labelAnswers,
  QuestionnaireTitles,
  QuestionnaireType,
  questionOrder,
  Quote,
  QuoteDto,
  SubmittableDisabilityV2Questionnaire,
  ZQuestionnaireAnswersRequiredForCalculatorSchema,
  ZQuoteDto,
  ZSubmittableDisabilityV2Questionnaire,
} from '../models';

export const createDisabilityV2Quote = async (quoteDto: QuoteDto) => {
  return endpoint.network.post<Quote>('/signups/disability/quotes', quoteDto);
};

export const createDisabilityV2Questionnaire = async (
  answers: Partial<SubmittableDisabilityV2Questionnaire>,
  questionnaireType: QuestionnaireType,
  labelTitles?: QuestionnaireTitles
) => {
  const source = getTrackingObject();
  return endpoint.network.post<{ id: string }>('/questionnaires/', {
    answers,
    questionnaireType,
    metadata: {
      labels: {
        title: labelTitles,
        answers: labelAnswers,
      },
      questionOrder,
      source,
    },
  });
};

export const createDisabilityQuote =
  (
    monthlyPayout: number
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'disabilityV2'> | RequestAction
  > =>
  async (
    dispatch: CreateQuoteDispatch<'disabilityV2'>,
    getState: () => AppState
  ) => {
    const requestType = 'CREATE_QUOTE';

    const questionnaireAnswers =
      getGenericQuestionnaire(getState()).disabilityV2 || {};

    const parsedAnswers =
      ZQuestionnaireAnswersRequiredForCalculatorSchema.safeParse(
        questionnaireAnswers
      );

    if (!parsedAnswers.success) {
      throw new Error(
        `[Disability] Questionnaire answer validation  for calculator errors:${parsedAnswers.error.toString()}`
      );
    }

    const {
      profession,
      height: heightInCm,
      weight: weightInKg,
      dateOfBirth,
    } = parsedAnswers.data;
    dispatch(setRequestInProcess(true, requestType));

    try {
      const jobCollar = getJobCollar(profession.value);

      const data: QuoteDto = {
        dateOfBirth,
        // We assign the 'C' job collar for the user to be eligible for the policy
        jobCollar: jobCollar === 'X' ? 'C' : jobCollar,
        monthlyPayout,
        heightInCm,
        weightInKg,
      };

      const validatedResult = ZQuoteDto.safeParse(data);

      if (!validatedResult.success) {
        throw new Error(
          `[Disability] Quote validation errors:${validatedResult.error.toString()}`
        );
      }

      const { data: quote } = await createDisabilityV2Quote(
        validatedResult.data
      );

      dispatch(setRequestInProcess(false, requestType));
      return {
        status: 'SUCCESS',
        result: {
          quote: {
            price: quote.price,
            id: quote.id,
          },
          quoteId: quote.id,
        },
      };
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'GET_DISABILITY_QUOTE')
      );
      Sentry.captureException(
        `[Disability PreQuote] Failed to submit with following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };

export const submitScreenedOutQuestionnaire =
  (): ThunkAction<
    Promise<void>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'disabilityV2'> | RequestAction
  > =>
  async (
    dispatch: CreateQuoteDispatch<'disabilityV2'>,
    getState: () => AppState
  ) => {
    const questionnaire =
      getGenericQuestionnaire(getState()).disabilityV2 || {};

    const requestType: RequestType = 'SUBMIT_QUESTIONNAIRE';

    dispatch(setRequestInProcess(true, requestType));

    const validatedAnswers =
      ZSubmittableDisabilityV2Questionnaire.safeParse(questionnaire);

    if (!validatedAnswers.success) {
      throw new Error(
        `[DISABILITY_V2 - Submission] Questionnaire answer validation errors: ${validatedAnswers.error.toString()}`
      );
    }

    const { data } = validatedAnswers;
    try {
      await createDisabilityV2Questionnaire(data, 'DISABILITY_SCREENED_OUT');

      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

export const submitDisabilityQuestionnaire =
  (
    t: TFunction,
    labelTitles: QuestionnaireTitles
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'disabilityV2'> | RequestAction
  > =>
  async (
    dispatch: CreateQuoteDispatch<'disabilityV2'>,
    getState: () => AppState
  ) => {
    const questionnaire =
      getGenericQuestionnaire(getState()).disabilityV2 || {};

    try {
      if (!questionnaire.quote) {
        throw new Error(`[DISABILITY_V2] Quote not defined`);
      }

      const validationResult =
        ZSubmittableDisabilityV2Questionnaire.safeParse(questionnaire);

      if (!validationResult.success) {
        throw new Error(
          `[DISABILITY_V2 - Submission] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const validatedAnswers = validationResult.data;

      const {
        dateOfBirth,
        profession,
        height: { inCm: heightInCm },
        weight: { inKg: weightInKg },
      } = validatedAnswers.personalInfo;
      const { monthlyPayout } = validatedAnswers.tariffInfo;
      const jobCollar = getJobCollar(profession);

      const quoteData: QuoteDto = {
        dateOfBirth,
        // We assign the 'C' job collar for the user to be eligible for the policy
        jobCollar: jobCollar === 'X' ? 'C' : jobCollar,
        monthlyPayout,
        heightInCm,
        weightInKg,
      };

      const validatedResult = ZQuoteDto.safeParse(quoteData);

      if (!validatedResult.success) {
        throw new Error(
          `[Disability] Quote validation errors:${validatedResult.error.toString()}`
        );
      }
      const { data: quoteResult } = await createDisabilityV2Quote(
        validatedResult.data
      );

      dispatch(setRequestInProcess(true, 'SUBMIT_QUESTIONNAIRE'));

      const {
        personalInfo: {
          name: { firstName, lastName },
          email,
        },
      } = validatedAnswers;

      // check if the account exists and create a new account if needed
      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            email,
          })
        );
      }

      const { data: questionnaireResult } =
        await createDisabilityV2Questionnaire(
          validatedAnswers,
          'DISABILITY',
          labelTitles
        );

      await generateCheckoutDocuments(
        questionnaireResult.id,
        validatedAnswers.tariffInfo.quoteId,
        'DISABILITY',
        t
      );
      dispatch(
        storeGenericQuestionnaireAnswer('disabilityV2', {
          quoteId: quoteResult.id,
          questionnaireId: questionnaireResult.id,
          quote: { ...questionnaire.quote, id: quoteResult.id },
        })
      );
      dispatch(setRequestInProcess(false, 'SUBMIT_QUESTIONNAIRE'));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'SUBMIT_QUESTIONNAIRE')
      );
      Sentry.captureException(
        `[DISABILITY_V2 Review] Failed to submit with following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };
