import googleStarEmpty from '../img/google-star-empty.svg';
import googleStarHalf from '../img/google-star-half.svg';
import googleStarWhole from '../img/google-star-whole.svg';
import trustPilotStarEmpty from '../img/trustpilot-star-empty.svg';
import trustPilotStarHalf from '../img/trustpilot-star-half.svg';
import trustPilotStarWhole from '../img/trustpilot-star-whole.svg';
import styles from './style.module.scss';
import { getNumberOfStars } from './util';

export type ReviewType = 'trustpilot' | 'google';

export const mapReviewStarIcon: {
  [K in ReviewType]: {
    wholeStarIcon: string;
    halfStarIcon: string;
    emptyStarIcon: string;
  };
} = {
  trustpilot: {
    wholeStarIcon: trustPilotStarWhole,
    halfStarIcon: trustPilotStarHalf,
    emptyStarIcon: trustPilotStarEmpty,
  },
  google: {
    wholeStarIcon: googleStarWhole,
    halfStarIcon: googleStarHalf,
    emptyStarIcon: googleStarEmpty,
  },
};

const Rating = ({
  rating,
  reviewType,
}: {
  rating: number;
  reviewType: ReviewType;
}) => {
  const { wholeStarIcon, halfStarIcon, emptyStarIcon } =
    mapReviewStarIcon[reviewType];
  const { wholeStars, halfStars, emptyStars } = getNumberOfStars(rating);
  return (
    <div className={styles.container}>
      {[...Array(wholeStars)].map((_, index) => (
        <img
          key={`rating-whole-star-${String(index)}`}
          className={styles.icon}
          src={wholeStarIcon}
          alt={`${reviewType} star`}
        />
      ))}
      {[...Array(halfStars)].map((_, index) => (
        <img
          key={`rating-half-star-${String(index)}`}
          className={styles.icon}
          src={halfStarIcon}
          alt={`${reviewType} star`}
        />
      ))}
      {[...Array(emptyStars)].map((_, index) => (
        <img
          key={`rating-empty-star-${String(index)}`}
          className={styles.icon}
          src={emptyStarIcon}
          alt={`${reviewType} star`}
        />
      ))}
    </div>
  );
};

export default Rating;
