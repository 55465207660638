import { AppState } from 'reducers';

import { CheckoutDocument } from './models';

export const getDocuments =
  (questionnaireId?: string) =>
  (state: AppState): CheckoutDocument[] => {
    const { items } = state.checkoutDocuments;

    if (!items || !questionnaireId) {
      return [];
    }

    return items[questionnaireId];
  };
