import { TallyModal } from 'components/TallyModal';
import dayjs from 'dayjs';
import { PolicySingleModalProps } from 'features/policySingle/components/modals';
import { useSelector } from 'react-redux';
import { getAccountInfo } from 'selectors/user';

export const PrivatePensionCancelPolicyModal = (
  props: PolicySingleModalProps
) => {
  const {
    onClose,
    policyData: {
      id,
      attributes: { insuredPerson, policyNumber },
    },
  } = props;
  const account = useSelector(getAccountInfo);

  const URLparams = {
    policy_id: id,
    customer_email: account?.email,
    policy_number: policyNumber,
    first_name: insuredPerson?.name?.firstName,
    last_name: insuredPerson?.name?.lastName,
    date_of_birth: account?.dateOfBirth
      ? dayjs(account?.dateOfBirth).format('DD.MM.YYYY')
      : '',
  };

  return (
    <TallyModal
      isVisible
      onClose={onClose}
      tallyId="3jQbd6"
      title="Private Pension Insurance Cancellation"
      URLparams={URLparams}
    />
  );
};
