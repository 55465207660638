import { trackTaskEngineCardClicked } from '@getpopsure/analytics';
import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTask } from 'features/taskEngine/api/taskEngine.api';
import { CustomTask } from 'features/taskEngine/components/CustomTask';
import {
  PassportInfoSchema,
  PassportInfoSchemaType,
  PrivateHealthPassportInfoUpdateTaskSchema,
  Task,
  TaskEngineOptions,
} from 'features/taskEngine/taskEngine.models';
import { APIResponseError } from 'models/error';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import { TASK_ENGINE_SENTRY_CATEGORY } from '../../constants';

export const PrivateHealthPassportInfoUpdate = ({
  task,
  options,
}: {
  task: Task;
  options?: TaskEngineOptions;
}) => {
  const queryClient = useQueryClient();
  const { t } = useSafeTranslation();
  const [showModal, setShowModal] = useState(false);

  const mutation = useMutation({
    mutationFn: (passportInfo: PassportInfoSchemaType) => {
      return updateTask({
        taskId: task.id,
        payload: {
          ...passportInfo,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });

  const parsedTask = PrivateHealthPassportInfoUpdateTaskSchema.safeParse(task);

  if (!parsedTask.success) {
    Sentry.captureException(
      `${TASK_ENGINE_SENTRY_CATEGORY}: Invalid task data for PrivateHealthPassportInfoUpdate task: ${parsedTask.error.errors}`
    );
    return null;
  }

  const {
    id,
    description: { type },
  } = parsedTask.data;

  const openModal = () => {
    trackTaskEngineCardClicked({
      task_type: type,
      task_id: id,
    });

    if (options?.onTaskClick) {
      options.onTaskClick(task);
    }

    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const passportNumber = formData.get('passport-number');
    const passportExpiry = formData.get('passport-expiry');

    const parsedPassportInfo = PassportInfoSchema.safeParse({
      passportNumber,
      passportExpiry,
    });

    if (!parsedPassportInfo.success) {
      throw new Error(
        `${TASK_ENGINE_SENTRY_CATEGORY}: PrivateHealthPassportInfoUpdate Passport number task missing for update`
      );
    }

    mutation.mutate(
      {
        passportNumber: parsedPassportInfo.data.passportNumber,
        passportExpiry: parsedPassportInfo.data.passportExpiry,
      },
      {
        onSuccess: () => {
          setShowModal(false);
        },
      }
    );
  };

  return (
    <CustomTask
      metadata={{
        title: t(
          'taskEngine.privateHealthPassportInfoUpdate.title',
          'Provide your passport information'
        ),
        subtitle: t(
          'taskEngine.privateHealthPassportInfoUpdate.subtitle',
          'Private Health'
        ),
        description: t(
          'taskEngine.privateHealthPassportInfoUpdate.description',
          'You skipped this question during the sign-up. We need this information to process your application further.'
        ),
      }}
      modal={{
        isOpen: showModal,
        loading: mutation.isLoading,
        error: mutation.isError
          ? (mutation.error as APIResponseError).response?.data?.message
          : null,
        onOpen: openModal,
        onClose: closeModal,
      }}
      onSubmit={handleSubmit}
      actions={[
        {
          id: 'passport-number',
          type: 'INPUT',
          attributes: {
            name: 'passport-number',
            type: 'text',
            required: true,
            placeholder: t(
              'taskEngine.privateHealthPassportInfoUpdate.modal.input.placeholder',
              'Passport number'
            ),
          },
        },
        {
          id: 'passport-expiry-label',
          type: 'LABEL',
          attributes: {
            htmlFor: 'passport-expiry',
            children: t(
              'taskEngine.privateHealthPassportInfoUpdate.modal.label',
              'Passport expiration date'
            ),
          },
        },
        {
          id: 'passport-expiry',
          type: 'DATE',
          attributes: {
            name: 'passport-expiry',
            yearBoundaries: {
              min: new Date().getFullYear(),
              max: new Date().getFullYear() + 20,
            },
            displayCalendar: true,
          },
        },
        {
          id: 'submit-button',
          type: 'SUBMIT',
          attributes: {
            value: t(
              'taskEngine.privateHealthPassportInfoUpdate.modal.button.title',
              'Submit'
            ),
          },
        },
      ]}
    />
  );
};
