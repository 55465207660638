import { useSafeTranslation } from 'shared/i18n';

import { PolicySingleCard } from '../../components/PolicySingleCard';
import { PolicyDetailsProps } from './PolicyDetails.models';
import styles from './PolicyDetails.module.scss';
import { PolicyDetailsRenderer } from './PolicyDetailsRenderer';

export const PolicyDetails = (props: PolicyDetailsProps) => {
  const { t } = useSafeTranslation();
  const { policyType, onOpenModal, info, buttons, policyData } = props;

  return (
    <div className="p-container">
      <PolicySingleCard
        title={t('myPolicies.policyDetails.title', 'Policy details')}
      >
        <PolicyDetailsRenderer
          policyType={policyType}
          onOpenModal={onOpenModal}
          infos={info}
        />
        {buttons.length > 0 && (
          <div className={styles.buttonContainer}>
            {buttons.map((button) => (
              <button.component
                policyData={policyData}
                onOpenModal={onOpenModal}
                key={button.id}
              />
            ))}
          </div>
        )}
      </PolicySingleCard>
    </div>
  );
};
