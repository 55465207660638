import Checkout from 'features/checkout';
import { CheckoutPolicyRequestPayload } from 'features/checkout/models';
import { Household } from 'features/household/models';
import { CustomComponentProps } from 'models/questionnaireFramework';

import { PolicyDetails } from './PolicyDetails';

export const ReviewCheckout = ({
  questionnaireAnswers,
  routes,
}: CustomComponentProps<Household>) => {
  const checkoutPolicyRequestPayload: CheckoutPolicyRequestPayload = {
    type: 'HOUSEHOLD',
    policyInfo: {
      quoteId: questionnaireAnswers.quote?.quoteId,
      questionnaireId: questionnaireAnswers.quote?.questionnaireId,
      startDate: questionnaireAnswers.startDate,
    },
  };

  return (
    <Checkout
      checkoutPolicyRequestPayload={checkoutPolicyRequestPayload}
      policyDetailsComponent={<PolicyDetails />}
      changeStartDatePath={routes.startDate.path}
    />
  );
};
