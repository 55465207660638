import { trackStructuredEvent } from '@getpopsure/analytics';
import { calendly, faq, insurance } from '@getpopsure/private-constants';
import { InfoCard } from '@popsure/dirty-swan';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import blueCalendarIcon from 'features/expat/icons/calendar-blue.svg';
import {
  contentMapping,
  ExpatDependentQuestionnaire,
  ExpatQuestionnaire,
  QuestionnaireQuote,
  QuotePlan,
} from 'features/expat/models';
import featherLogoIcon from 'icons/feather-icon.svg';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useState } from 'react';
import { Trans, useSafeTranslation } from 'shared/i18n';

import styles from './Quote.module.scss';
import policyIcon from './view/assets/policy.svg';
import quoteHeader from './view/assets/quote-icon.svg';
import Chip from './view/chip';
import ComparisonTable from './view/comparisonTable';
import { coverage, notCovered } from './view/content';

export const Quote = ({
  value,
  onSubmitValue,
  questionnaireAnswers,
}: CustomComponentProps<ExpatQuestionnaire | ExpatDependentQuestionnaire>) => {
  const [selectedPlan, setSelectedPlan] = useState<QuotePlan>('PREMIUM');

  const chips = (value as QuestionnaireQuote).options
    .sort(({ planId: a }, { planId: b }) => {
      return contentMapping[a].id < contentMapping[b].id ? -1 : 1;
    })
    .map((quoteOption) => (
      <Chip
        key={quoteOption.id}
        name={contentMapping[quoteOption.planId].name}
        price={quoteOption.price}
        onSelect={() => {
          onSubmitValue({
            ...(value as QuestionnaireQuote),
            selectedQuote: quoteOption,
          });
        }}
      />
    ));

  const handleBookACall = () => {
    trackStructuredEvent({
      category: 'EXPAT_HEALTH_INSURANCE',
      action: 'CLICKED_BOOK_AN_APPOINTMENT',
    });
    window.Calendly.initPopupWidget({ url: calendly.expatHealthHelp });
  };

  const { t } = useSafeTranslation();

  return (
    <div className={`w100 ${styles.container}`}>
      <div className="p-body">
        <section className={classNames('pt32', styles['section-top'])}>
          <img src={quoteHeader} alt="quote header" />
          <h1 className={`p-h1 mt24 p--serif ${styles.header}`}>
            {questionnaireAnswers.isPolicyHolder
              ? t('expat.qnr.quote.title', 'Here are your options')
              : t('expat.qnr.dependentsQuote.title', 'Here are their options')}
          </h1>
          <div className={`mt8 wmx9 ${styles['chip-container']}`}>{chips}</div>
          <div className={`mt48 ${styles['coverage-card-container']}`}>
            {coverage(t).map(({ title, icon, description }) => (
              <InfoCard
                key={title}
                className="wmx4 mt32 ml16"
                title={title}
                topIcon={icon}
                state="static"
              >
                <div className="p-p tc-grey-600 ta-center">
                  <Markdown openLinksInNewTab={true}>{description}</Markdown>
                </div>
              </InfoCard>
            ))}
          </div>
          <div className={`w100 mt40 ${styles['additional-info']}`}>
            <img src={blueCalendarIcon} alt="calendar" />
            <div className="ml16">
              <div className="p-h4">
                {t(
                  'expat.qnr.quote.waitingPeriod.title',
                  '31 day waiting period'
                )}
              </div>
              <div className="p-p mt8">
                {questionnaireAnswers.isPolicyHolder ? (
                  <Trans i18nKey="expat.qnr.quote.waitingPeriod.content">
                    You must wait 31 days after the start of coverage to use the
                    benefits. This does not apply to accidents or medical
                    interventions made to avoid an acute danger to your life.
                    The waiting period is waived if you had comparable insurance
                    up to the start date of this insurance or if you start your
                    expat health policy within 31 days of arriving in Germany.{' '}
                    <a
                      className="p-a c-pointer"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={faq.expatHealthInsuranceWaitingPeriod}
                    >
                      Learn more
                    </a>
                  </Trans>
                ) : (
                  <Trans i18nKey="expat.qnr.dependentsQuote.waitingPeriod.content">
                    They must wait 31 days after the start of coverage to use
                    the benefits. This does not apply to accidents or medical
                    interventions made to avoid an acute danger to their life.
                    The waiting period is waived if they had comparable
                    insurance up to the start date of this insurance or if they
                    start your policy within 31 days of arriving in Germany.{' '}
                    <a
                      className="p-a c-pointer"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={faq.expatHealthInsuranceWaitingPeriod}
                    >
                      Learn more
                    </a>
                  </Trans>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className={`mt80 ${styles['section-middle']}`}>
          <h2 className="p-h1 ta-center p--serif">
            {t('expat.qnr.quote.included.title', "What's included")}
          </h2>
          <div className={`mt16 ${styles['policy-statement-container']}`}>
            <div className="p-p ta-center wmx7">
              {t(
                'expat.qnr.quote.included.content',
                'For a closer look, you can read the'
              )}{' '}
            </div>
            <img src={policyIcon} alt="policy icon" />{' '}
            <a
              className="p-a c-pointer"
              href={insurance.expathHealth.policyDetail}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('expat.qnr.quote.included.linkText', 'Policy statement')}
            </a>
          </div>
          <div className="mt40">
            <ComparisonTable
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />
          </div>
        </section>
        <section className={styles['section-middle']}>
          <h2 className="p-h1 ta-center p--serif">
            {t('expat.qnr.quote.notIncluded.title', "What's not covered")}
          </h2>
          <div className="p-p mt16 ta-center">
            {t(
              'expat.qnr.quote.notIncluded.content',
              'What our customers find useful to know about'
            )}
          </div>
          <div className={`mt16 ${styles['not-covered-card-container']}`}>
            {notCovered(selectedPlan, t).map(({ title, description, icon }) => (
              <InfoCard
                key={title}
                className="mt32 ml16 wmx4"
                title={title}
                topIcon={icon}
                state="static"
              >
                <div
                  className={`p-p ta-center tc-grey-600 ${styles['info-description']}`}
                >
                  {description}
                </div>
              </InfoCard>
            ))}
          </div>
        </section>
      </div>
      <section className={`${styles['section-bottom']}`}>
        <div className={`p-body ${styles['section-bottom-container']}`}>
          <img
            src={featherLogoIcon}
            alt="logo"
            className={styles['logo-icon']}
          />
          <div className="p--serif p-h1 ta-center mt24">
            {t('expat.qnr.quote.moreQuestions.title', 'Have more questions?')}
          </div>
          <div className="p-p ta-center wmx8 mt24">
            {t(
              'expat.qnr.quote.moreQuestions.content',
              'Reach out to us for more information or check out our website to get answers to common questions about expat health insurance.'
            )}
          </div>
          <div className={`mt24 wmn3 ${styles['statement-links-container']}`}>
            <button
              type="button"
              className="p-a ds-interactive-component"
              onClick={handleBookACall}
            >
              {t('expat.qnr.quote.moreQuestions.bookCall', 'Book a call')}
            </button>
            <div className={styles.divider} />
            <a
              className="p-a"
              href={faq.expatHealthInsurance}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('expat.qnr.quote.moreQuestions.faq', 'Visit our FAQ')}
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
