import { completeChecklist } from 'features/studentChecklist/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import { AppState } from '../../reducers';
import { getPolicyDetail } from '../../selectors/policies';
import { getChecklistStepsStatus } from './selectors';
import { StudentChecklistView } from './StudentChecklist.view';
import { studentChecklistLookup } from './utils/studentChecklistLookup';
import { studentChecklistTitleLookup } from './utils/studentChecklistTitleLookup';

export const StudentChecklistContainer = () => {
  const dispatch = useDispatch();
  const { policyId } = useParams<{ policyId: string }>();
  const policy = useSelector((state: AppState) =>
    getPolicyDetail(state, policyId)
  );
  const isChecklistCompleted = Boolean(
    policy?.attributes?.studentChecklist?.isCompleted
  );
  const { currentSteps } = getChecklistStepsStatus(policy);
  const { t } = useSafeTranslation();

  const steps = studentChecklistLookup(currentSteps, t);
  const title = studentChecklistTitleLookup(currentSteps, t);

  const handleOnCompleteChecklist = async () => {
    await dispatch(completeChecklist(policyId));
  };

  return (
    <StudentChecklistView
      steps={steps}
      title={title}
      {...(!isChecklistCompleted && {
        completeChecklist: handleOnCompleteChecklist,
      })}
    />
  );
};
