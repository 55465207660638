import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

import headIcon from '../assets/head.svg';
import styles from './PriceDetailsSection.module.scss';

type PriceDetailsSectionProps = {
  basicPrice: number;
  premiumPrice: number;
  premiumSelected: boolean;
};

export const PriceDetailsSection = ({
  basicPrice,
  premiumPrice,
  premiumSelected,
}: PriceDetailsSectionProps) => {
  const { t } = useSafeTranslation();

  const totalMonthlySum = premiumSelected ? premiumPrice : basicPrice;

  return (
    <section className="pt48">
      <h2 className="p-h2 mb16">
        {t('disability.addons.priceDetails.title', 'Price details')}
      </h2>
      <div className={`d-grid p24 bg-white br8 c-gap8 ${styles.priceGrid}`}>
        <h3 className="p-h3">
          {t(
            'disability.addons.priceDetails.monthlytotal',
            'Your monthly total'
          )}
        </h3>
        <h2 className={`p-h2 ${styles.end}`}>
          {englishFormattedEuroCurrency(totalMonthlySum)}
        </h2>
        <p className="p-p">
          {t(
            'disability.addons.priceDetails.disabilityInsurance',
            '💼 Disability insurance'
          )}
        </p>
        <p className={`p-p ${styles.end}`}>
          {englishFormattedEuroCurrency(basicPrice)}
        </p>

        <AnimateHeight duration={300} height={premiumSelected ? 'auto' : 0}>
          <p className={`p-p d-flex ai-center ${styles.tinyGap}`}>
            <img src={headIcon} alt="" role="presentation" />
            {t('disability.addons.priceDetails.mentalHealth', 'Mental health')}
          </p>
        </AnimateHeight>
        <AnimateHeight
          className={styles.end}
          duration={300}
          height={premiumSelected ? 'auto' : 0}
        >
          <p className="p-p">
            {englishFormattedEuroCurrency(premiumPrice - basicPrice)}
          </p>
        </AnimateHeight>
      </div>
    </section>
  );
};
