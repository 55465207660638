import { Button, ShieldIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import {
  CoverageModal,
  CoverageTableDataEntry,
} from 'components/CoverageDetails/CoverageModal';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import { PolicySingleCard } from '../../components/PolicySingleCard';
import checkIcon from './icons/check.svg';
import crossIcon from './icons/cross.svg';
import styles from './PolicyCoverage.module.scss';

export type PolicyCoverageItem = {
  id: string;
  title: string;
  covered: boolean;
  description?: string;
};

export type PolicyCoverageProps = {
  entries: PolicyCoverageItem[];
  url?: string;
  modal?: {
    coverageTableData: CoverageTableDataEntry[];
    coverageTableHeaders: React.ReactNode[];
  };
};

export const PolicyCoverage = (props: PolicyCoverageProps) => {
  const { url, entries, modal } = props;
  const { t } = useSafeTranslation();
  const [showModal, setShowModal] = useState(false);

  if (!entries || entries.length === 0) return null;

  const breakPoint = Math.ceil(entries.length / 2);

  const renderOpenModalButton = modal ? (
    <Button
      leftIcon={<ShieldIcon />}
      variant="filledGray"
      onClick={() => {
        setShowModal(true);
      }}
    >
      {t(
        'myPolicies.policyCoverage.showFullCoverage.title',
        'Full coverage table'
      )}
    </Button>
  ) : null;

  return (
    <div className="p-container">
      {modal && (
        <CoverageModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          firstItemOpenByDefault
          coverageTableData={modal.coverageTableData}
          coverageTableHeaders={modal.coverageTableHeaders}
        />
      )}
      <PolicySingleCard
        title={t('myPolicies.policyCoverage.title', 'Coverage overview')}
        action={
          url ? (
            <a
              className="p-btn--secondary-grey d-flex ai-center jc-center"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ShieldIcon size={20} noMargin className="mr8" />
              {t(
                'myPolicies.policyCoverage.showFullCoverage.title',
                'Full coverage table'
              )}
            </a>
          ) : (
            renderOpenModalButton
          )
        }
      >
        <div className="d-flex fd-row f-wrap pb8">
          <div>
            {entries.slice(0, breakPoint).map((entry) => (
              <CoverageItem key={entry.id} entry={entry} />
            ))}
          </div>
          <div>
            {entries.slice(breakPoint).map((entry) => (
              <CoverageItem key={entry.id} entry={entry} />
            ))}
          </div>
        </div>
      </PolicySingleCard>
    </div>
  );
};

const CoverageItem = ({ entry }: { entry: PolicyCoverageItem }) => (
  <div className={classNames('d-flex fd-row mb24 mr8', styles.coverageItem)}>
    <div className="mr16">
      {entry.covered ? (
        <img src={checkIcon} alt="circle with checkmark" />
      ) : (
        <img src={crossIcon} alt="cross" />
      )}
    </div>
    <div className={styles.row}>
      <div className="p-p tc-grey-900">{entry.title}</div>
      <div className="p-p--small tc-grey-600">{entry.description}</div>
    </div>
  </div>
);
