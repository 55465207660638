import { CurrencyInput, Input } from '@popsure/dirty-swan';
import type { CurrencyInputProps } from '@popsure/dirty-swan/dist/cjs/lib/components/input/currency';
import { useState } from 'react';

export type CurrencyInputTaskProps = {
  onValidate(valid: boolean): void;
} & Omit<CurrencyInputProps, 'onChange'>;

const MIN_AMOUNT = 0.01;

export const CurrencyInputTask = (props: CurrencyInputTaskProps) => {
  const [value, setValue] = useState<number>(0);
  const { onValidate, name, ...propsWithoutName } = props;

  return (
    <>
      <Input type="hidden" name={name} value={value} />
      <CurrencyInput
        {...propsWithoutName}
        min={MIN_AMOUNT}
        onChange={(newValue) => {
          setValue(newValue);
          onValidate(newValue !== undefined && newValue > MIN_AMOUNT);
        }}
      />
    </>
  );
};
