import { Address } from '@getpopsure/public-models';
import AddressForm from 'components/questionnaireForms/AddressForm';
import routes from 'constants/routes';
import {
  ViewProps,
  withContainer,
} from 'features/liability/containers/withContainer';
import { getQuestionsMapping, QuestionId } from 'models/liability';
import { useSafeTranslation } from 'shared/i18n';

const questionId: QuestionId = 'address';

const LiabilityAddress = ({
  initialValue,
  onSubmitValue,
}: ViewProps<typeof questionId>) => {
  const { t } = useSafeTranslation();
  return (
    <AddressForm
      value={initialValue}
      question={getQuestionsMapping(t)[questionId]}
      onSubmitValue={(value: Address) => {
        onSubmitValue({ questionId, value });
      }}
      featureName="Liability"
      skipDetails={{
        title: t(
          'page.liability.qnr.address.skip.buttoncaption',
          'Provide later'
        ),
        path: routes.policies.liability.questionnaire.startDate.path,
      }}
    />
  );
};

export default withContainer(LiabilityAddress, 'address');
