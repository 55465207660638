import Session from '@getpopsure/session';
import { Account } from 'features/recommendationTool/models/account';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { Redirect } from 'react-router-dom';

const LoadingContainer = (props: CustomComponentProps<Account>) => {
  const { questionnaireAnswers, routes, metaData } = props;
  const assertedRoute = metaData?.nextPageId as keyof typeof routes;
  const route = routes[assertedRoute]?.path;
  const shouldSkipAccountFlow =
    Session.isAuthenticated ||
    questionnaireAnswers.continueWithAccount === false;

  if (route && shouldSkipAccountFlow) {
    return <Redirect to={route} />;
  }

  return <Redirect to={routes.continueWithAccount.path} />;
};

export default LoadingContainer;
