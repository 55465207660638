import { TFunction } from '@getpopsure/i18n-react';
import { DentalClaimsSubmitted as ClaimSubmitted } from 'features/dentalClaims/components/ClaimSubmitted';
import { DentalClaimsGettingStarted as GettingStarted } from 'features/dentalClaims/components/GettingStarted';
import { DentalClaimsProcessing as Processing } from 'features/dentalClaims/components/Processing';
import {
  claimTypeMapping,
  DentalClaims,
  DentalClaimsMetaData,
  QuestionId,
  Questionnaire,
} from 'features/dentalClaims/models';

import { DentalClaimsPayoutDetails as PayoutDetails } from '../components/PayoutDetails';
import { DentalClaimsUploadDocuments as UploadDocuments } from '../components/UploadDocuments';

export const translatedQuestionnaire = (
  t: TFunction,
  metaData: DentalClaimsMetaData
): Questionnaire => ({
  intro: {
    required: true,
    type: 'CUSTOM',
    component: GettingStarted,
    metaData: {
      policyId: metaData.policyId,
    },
    retrieveAnswerObject: (
      answer: DentalClaims['intro']
    ): Partial<DentalClaims> => ({
      intro: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'claimTypes',
    groupId: 'question',
    questionId: 'intro',
    requiresAuth: true,
  },

  claimTypes: {
    required: true,
    type: 'CHECKBOX',
    props: {
      question: t(
        'claims.dental.questionnaire.claimType.question',
        'Select a claim type'
      ),
      description: t(
        'claims.dental.questionnaire.claimType.description',
        'Please select the type of claim that you wish to submit. You can select multiple answers.'
      ),
      mapValue: claimTypeMapping(t, metaData.planId),
    },
    retrieveAnswerObject: (
      answer: DentalClaims['claimTypes']
    ): Partial<DentalClaims> => ({
      claimTypes: answer,
    }),
    retrieveNextPageId: (answer: DentalClaims['claimTypes']): QuestionId => {
      if (answer.includes('OTHER')) {
        return 'otherClaimType';
      }
      return 'uploadedDocumentTokens';
    },
    groupId: 'question',
    questionId: 'claimTypes',
    requiresAuth: true,
  },

  otherClaimType: {
    required: (questionnaire) =>
      Boolean(questionnaire.claimTypes?.includes('OTHER')),
    type: 'INPUT',
    props: {
      question: t(
        'claims.dental.questionnaire.otherClaimType.question',
        'What is this claim for?'
      ),
      placeholder: t(
        'claims.dental.questionnaire.otherClaimType.placeholder',
        'E.g. dental cleaning'
      ),
    },
    retrieveAnswerObject: (
      answer: DentalClaims['otherClaimType']
    ): Partial<DentalClaims> => ({
      otherClaimType: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'uploadedDocumentTokens',
    groupId: 'question',
    questionId: 'otherClaimType',
    requiresAuth: true,
  },

  uploadedDocumentTokens: {
    required: true,
    type: 'CUSTOM',
    component: UploadDocuments,
    metaData: { policyId: metaData.policyId },
    retrieveAnswerObject: (
      answer: DentalClaims['uploadedDocumentTokens']
    ): Partial<DentalClaims> => ({
      uploadedDocumentTokens: answer,
    }),
    retrieveNextPageId: () => 'payoutDetails',
    groupId: 'question',
    questionId: 'uploadedDocumentTokens',
    requiresAuth: true,
  },

  payoutDetails: {
    required: true,
    type: 'CUSTOM',
    component: PayoutDetails,
    metaData: { policyId: metaData.policyId },
    retrieveAnswerObject: (
      answer: DentalClaims['payoutDetails']
    ): Partial<DentalClaims> => ({
      payoutDetails: answer,
    }),
    retrieveNextPageId: () => 'processing',
    groupId: 'question',
    questionId: 'payoutDetails',
    requiresAuth: true,
  },

  processing: {
    required: true,
    type: 'CUSTOM',
    component: Processing,
    retrieveAnswerObject: (
      answer: DentalClaims['processing']
    ): Partial<DentalClaims> => ({
      processing: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'submitted',
    groupId: 'question',
    questionId: 'processing',
    requiresAuth: true,
  },

  submitted: {
    required: true,
    type: 'CUSTOM',
    component: ClaimSubmitted,
    groupId: 'question',
    questionId: 'submitted',
    requiresAuth: true,
  },
});
