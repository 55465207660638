import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { Input } from '@popsure/dirty-swan';
import { useEffect, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

export const EmailBasic = <Questionnaire extends QuestionnaireQuestions>({
  value,
  onSubmitValue,
  setValidForSubmission,
}: QuestionnaireFormProps<Questionnaire>) => {
  const [email, setEmail] = useState<string>((value as string) ?? '');

  const { t } = useSafeTranslation();

  useEffect(() => {
    setValidForSubmission(email !== '');
  }, [setValidForSubmission, email]);

  return (
    <Header onSubmit={() => onSubmitValue(email)}>
      <Input
        className="mt24"
        type="email"
        autoComplete="email"
        placeholder={t('generic.qnr.emailBasic.title', 'Email address')}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        data-cy="generic-email-basic"
      />
    </Header>
  );
};
