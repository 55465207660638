import { insurance } from '@getpopsure/private-constants';
import { BottomOrRegularModal } from '@popsure/dirty-swan';
import { PublicHealthProvider } from 'models/insurances/providers';
import { Trans, useSafeTranslation } from 'shared/i18n';

import { ViewProps } from '../../../cancelModal/types';
import styles from '../style.module.scss';

export const CancelPublicHealthModal = ({
  onCloseModal,
  isOpen,
  onMarkPolicyDroppedOut,
  providerName,
  policyStatus,
}: Pick<
  ViewProps,
  | 'onCloseModal'
  | 'isOpen'
  | 'onMarkPolicyDroppedOut'
  | 'providerName'
  | 'policyStatus'
>) => {
  const { t } = useSafeTranslation();

  const handleRemoveFromFeatherClick = () => {
    onMarkPolicyDroppedOut();
  };

  const publicHealthProviderName =
    providerName.toLowerCase() as Lowercase<PublicHealthProvider>;
  const alreadyMarkedDroppedOut = policyStatus === 'DROPPED_OUT';

  const contactLinkHref =
    publicHealthProviderName &&
    insurance.publicHealth[publicHealthProviderName]?.contact;

  return (
    <BottomOrRegularModal
      title={t('myPolicies.cancelModal.publicHealth.title', 'Cancel policy')}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <div
        className={`mt24 ${styles.container}`}
        data-testid="cancel-modal-public"
      >
        <Trans i18nKey="myPolicies.cancelModal.publicHealth.description">
          <p className="p-p mb24">
            Unfortunately, to cancel this public health policy you’ll need to
            <strong className="fw-bold">
              {' '}
              directly reach out to the provider{' '}
            </strong>
            and request a cancellation.
          </p>
          <p className="p-p mb24">
            You can remove the policy from your Feather account if you wish.
            This will only deactivate the policy{' '}
            <strong className="fw-bold"> in the Feather system </strong>, but
            will not cancel your coverage with the insurance provider.
          </p>
        </Trans>
        <div className={`d-flex f-wrap ${styles['button-container']}`}>
          <a
            className="p-btn--primary wmn2"
            href={contactLinkHref}
            target="_blank"
            rel="noreferrer"
          >
            {t(
              'myPolicies.cancelModal.publicHealth.contactProviderButton.caption',
              'Contact Provider'
            )}
          </a>
          {!alreadyMarkedDroppedOut && (
            <button
              className={`p-btn--secondary wmn2 mr16 ${styles['secondary-button']}`}
              onClick={handleRemoveFromFeatherClick}
              type="button"
            >
              {t(
                'myPolicies.cancelModal.publicHealth.removeFromFeatherButton.caption',
                'Remove from Feather'
              )}
            </button>
          )}
        </div>
      </div>
    </BottomOrRegularModal>
  );
};
