import { CheckoutInfo } from 'features/checkout/models';
import { Name } from 'models/user';
import { ActionResponse, UploadedFile } from 'shared/models/types';
import {
  ZNameSchema,
  ZUploadedDocumentsSchema,
} from 'shared/models/validations';
import { BLOCKER, SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

const BikeTypeEnum = ['BIKE', 'E_BIKE'] as const;
type BikeType = typeof BikeTypeEnum[number];

const QuotePlan = ['BASIC'] as const;
export type Plan = typeof QuotePlan[number];

export const ZQuote = z.object({
  id: z.string(),
  planId: z.enum(QuotePlan),
  price: z.number(),
});
export type Quote = z.TypeOf<typeof ZQuote>;

export type CheckoutPolicyDetails = unknown;

export type CreateQuoteReturnType = {
  status: ActionResponse;
};

export type Bike = {
  intro: SCREEN;
  postalCode: string;
  dateOfBirth: string;
  ageBlocker?: BLOCKER;
  isBikeAgeOverOneYear: 'YES' | 'NO';
  purchasePrice: number;
  purchasePriceBlocker?: BLOCKER;
  type: BikeType;
  quote: Quote;

  signupIntro: SCREEN;
  name: Name;
  email: string;
  brand: string;
  model: string;
  frameNumber?: string;
  receipts?: UploadedFile[];
  startDate: string;
  review: boolean;

  hasDownloadedDocs: boolean;
  downloadDocuments: boolean;
  checkoutInfo: CheckoutInfo<CheckoutPolicyDetails>;
  reviewCheckout: boolean;
  checkout: boolean;

  quoteId: string;
  questionnaireId: string;
};

export const ZSubmittableBikeQuestionnaire = z
  .object({
    name: ZNameSchema,
    email: z.string(),
    postalCode: z.string(),
    dateOfBirth: z.string(),
    isBikeAgeOverOneYear: z.enum(['YES', 'NO']),
    purchasePrice: z.number(),
    type: z.enum(BikeTypeEnum),
    brand: z.string(),
    model: z.string(),
    frameNumber: z.string().optional(),
    receipts: z.array(ZUploadedDocumentsSchema).optional(),
    startDate: z.string(),
  })
  .transform(
    ({
      name,
      email,
      dateOfBirth,
      postalCode,
      type,
      brand,
      model,
      frameNumber,
      isBikeAgeOverOneYear,
      purchasePrice,
      receipts,
      startDate,
    }) => ({
      personalInfo: {
        name,
        email,
        dateOfBirth,
      },
      tariffInfo: {
        postalCode,
        type,
        brand,
        model,
        frameNumber,
        isBikeAgeOverOneYear: isBikeAgeOverOneYear === 'YES',
        purchasePrice,
        receipts,
        startDate,
      },
    })
  );
export type SubmittableBikeQuestionnaire = z.infer<
  typeof ZSubmittableBikeQuestionnaire
>;

export type BikeGroupIds = 'preQuote' | 'signup';
