import { TFunction } from '@getpopsure/i18n-react';
import routes from 'constants/routes';
import {
  BikeClaimType,
  claimTypeMapping as BikeClaimTypeMapping,
} from 'features/bikeClaims/models';
import {
  claimTypeMapping as DentalClaimTypeMapping,
  DentalAdvancedPlanClaimType,
} from 'features/dentalClaims/models';
import {
  claimTypeMapping as GenericClaimTypeMapping,
  GenericClaimType,
} from 'features/genericClaims/models';
import {
  claimTypeMapping as HouseholdClaimTypeMapping,
  HouseholdClaimType,
} from 'features/householdClaims/models';
import {
  claimTypeMapping as LiabilityClaimTypeMapping,
  LiabilityClaimType,
} from 'features/liabilityClaims/models';
import {
  claimTypeMapping as PrivateHealthClaimTypeMapping,
  PrivateHealthClaimType,
} from 'features/privateHealthClaims/models';
import {
  Claim,
  ClaimStatuses,
  ClaimTypes,
  InternalClaimStatus,
  mapClaimTypes as ExpatClaimTypeMapping,
} from 'models/claims';
import { InsuranceTypes } from 'models/insurances/types';

export const insuranceWithClaimRoute: InsuranceTypes[] = [
  'BIKE',
  'DENTAL',
  'HOUSEHOLD',
  'LIABILITY',
  'PRIVATE_HEALTH',
  'LEGAL',
  'INCOMING',
  'PET_HEALTH',
];

export const getClaimRouteForInsuranceType = (type: InsuranceTypes) => {
  switch (type) {
    case 'BIKE':
      return routes.claims.bike.path;
    case 'DENTAL':
      return routes.claims.dental.path;
    case 'HOUSEHOLD':
      return routes.claims.household.path;
    case 'LIABILITY':
      return routes.claims.liability.path;
    case 'PRIVATE_HEALTH':
      return routes.claims.privateHealth.path;
    case 'LEGAL':
      return routes.claims.legal.claimsAndConsultations.path;
    case 'INCOMING':
      return routes.claims.expat.path;
    case 'PET_HEALTH':
      return routes.claims.common.path;
    default:
      return undefined;
  }
};

export const getClaimTypeMapping = (
  t: TFunction,
  {
    insuranceType,
    claimType,
  }: { insuranceType: InsuranceTypes; claimType: unknown }
) => {
  switch (insuranceType) {
    case 'BIKE': {
      const claimTypeTitle =
        BikeClaimTypeMapping(t)[claimType as BikeClaimType];
      return typeof claimTypeTitle === 'string'
        ? claimTypeTitle
        : claimTypeTitle?.title;
    }
    case 'DENTAL': {
      const claimTypeTitle = DentalClaimTypeMapping(t, 'ADVANCED')[
        claimType as DentalAdvancedPlanClaimType
      ];
      return typeof claimTypeTitle === 'string'
        ? claimTypeTitle
        : claimTypeTitle?.title;
    }
    case 'HOUSEHOLD':
      return HouseholdClaimTypeMapping(t)[claimType as HouseholdClaimType];
    case 'LIABILITY':
      return LiabilityClaimTypeMapping(t)[claimType as LiabilityClaimType];
    case 'PRIVATE_HEALTH': {
      const claimTypeTitle =
        PrivateHealthClaimTypeMapping(t)[claimType as PrivateHealthClaimType];
      return typeof claimTypeTitle === 'string'
        ? claimTypeTitle
        : claimTypeTitle?.title;
    }
    case 'INCOMING':
    case 'INCOMING_LT':
      return ExpatClaimTypeMapping[claimType as ClaimTypes];

    case 'COMPANY_HEALTH':
    case 'COMPANY_LIFE':
    case 'DISABILITY':
    case 'PET_HEALTH':
    case 'TRAVEL':
    case 'GENERIC':
      return GenericClaimTypeMapping({ t, insuranceType })[
        claimType as GenericClaimType
      ];
  }
  return null;
};

export const getDisplayAmount = (claim: Claim) => {
  switch (claim.insuranceType) {
    case 'BIKE':
      return getBikeDisplayAmount(claim);
    case 'DENTAL':
      return getDentalAmountDisplayStatus(claim);
    case 'HOUSEHOLD':
      return getHouseholdAmountDisplay(claim);
    case 'LIABILITY':
      return getLiabilityDisplayAmount(claim);
    case 'PRIVATE_HEALTH':
      return getPrivateHealthDisplayAmount(claim);
    case 'INCOMING':
      return getIncomingDisplayAmount(claim);
    case 'LEGAL':
    default:
      return null;
  }
};

const getBikeDisplayAmount = (claim: Claim) => {
  switch (claim.status) {
    case 'SUBMITTED': {
      return claim.internalStatus === 'RECEIVED' ? claim.amount : null;
    }
    case 'ACTION_NEEDED': {
      return claim.internalStatus === 'INCOMPLETE' ? claim.amount : null;
    }
    case 'PROCESSING': {
      return ['SUBMITTED_TO_PROVIDER', 'FEATHER_PAID_OUT'].includes(
        claim.internalStatus
      )
        ? claim.amount
        : null;
    }
    case 'PAID_OUT': {
      return claim.internalStatus === 'CUSTOMER_PAID_OUT'
        ? claim.refundAmount
        : null;
    }
    case 'CANCELLED':
      return ['DUPLICATE', 'CLOSED'].includes(claim.internalStatus)
        ? claim.amount
        : null;
    case 'PROCESSED':
      return claim.internalStatus === 'CLOSED' ? claim.amount : null;
    default:
      return null;
  }
};

const getDentalAmountDisplayStatus = (claim: Claim) => {
  switch (claim.status) {
    case 'SUBMITTED': {
      return claim.internalStatus === 'RECEIVED' ? claim.amount : null;
    }
    case 'ACTION_NEEDED': {
      return claim.internalStatus === 'INCOMPLETE' ? claim.amount : null;
    }
    case 'PROCESSING': {
      return ['SUBMITTED_TO_PROVIDER', 'FEATHER_PAID_OUT'].includes(
        claim.internalStatus
      )
        ? claim.amount
        : null;
    }
    case 'PAID_OUT': {
      return claim.internalStatus === 'CUSTOMER_PAID_OUT'
        ? claim.refundAmount
        : null;
    }
    case 'CANCELLED':
      return claim.internalStatus === 'DUPLICATE' ? claim.amount : null;
    default:
      return null;
  }
};

const getIncomingDisplayAmount = (claim: Claim) => {
  switch (claim.status) {
    case 'SUBMITTED': {
      return claim.internalStatus === 'RECEIVED' ? claim.amount : null;
    }
    case 'ACTION_NEEDED': {
      return claim.internalStatus === 'INCOMPLETE' ? claim.amount : null;
    }
    case 'PROCESSING': {
      return ['SUBMITTED_TO_PROVIDER', 'FEATHER_PAID_OUT'].includes(
        claim.internalStatus
      )
        ? claim.amount
        : null;
    }
    case 'PAID_OUT': {
      return claim.internalStatus === 'CUSTOMER_PAID_OUT'
        ? claim.refundAmount
        : null;
    }
    case 'CANCELLED':
      return claim.internalStatus === 'DUPLICATE' ? claim.amount : null;
    default:
      return null;
  }
};

const getHouseholdAmountDisplay = (claim: Claim) => {
  switch (claim.status) {
    case 'SUBMITTED': {
      return claim.internalStatus === 'RECEIVED' ? claim.amount : null;
    }
    case 'ACTION_NEEDED': {
      return claim.internalStatus === 'INCOMPLETE' ? claim.amount : null;
    }
    case 'PROCESSING': {
      return ['SUBMITTED_TO_PROVIDER', 'FEATHER_PAID_OUT'].includes(
        claim.internalStatus
      )
        ? claim.amount
        : null;
    }
    case 'PAID_OUT': {
      return claim.internalStatus === 'CUSTOMER_PAID_OUT'
        ? claim.refundAmount
        : null;
    }
    case 'CANCELLED':
      return claim.internalStatus === 'DUPLICATE' ? claim.amount : null;
    default:
      return null;
  }
};

const getLiabilityDisplayAmount = (claim: Claim) => {
  switch (claim.status) {
    case 'SUBMITTED': {
      return claim.internalStatus === 'RECEIVED' ? claim.amount : null;
    }
    case 'ACTION_NEEDED': {
      return claim.internalStatus === 'INCOMPLETE' ? claim.amount : null;
    }
    case 'PROCESSING': {
      return ['SUBMITTED_TO_PROVIDER', 'FEATHER_PAID_OUT'].includes(
        claim.internalStatus
      )
        ? claim.amount
        : null;
    }
    case 'PAID_OUT': {
      return claim.internalStatus === 'CUSTOMER_PAID_OUT'
        ? claim.refundAmount
        : null;
    }
    case 'CANCELLED':
      return claim.internalStatus === 'DUPLICATE' ? claim.amount : null;
    default:
      return null;
  }
};

const getPrivateHealthDisplayAmount = (claim: Claim) => {
  switch (claim.status) {
    case 'SUBMITTED': {
      return claim.internalStatus === 'RECEIVED' ? claim.amount : null;
    }
    case 'ACTION_NEEDED': {
      return claim.internalStatus === 'INCOMPLETE' ? claim.amount : null;
    }
    case 'PROCESSING': {
      return claim.internalStatus === 'SUBMITTED_TO_PROVIDER'
        ? claim.amount
        : null;
    }
    case 'CANCELLED':
      return claim.internalStatus === 'DUPLICATE' ? claim.amount : null;
    default:
      return null;
  }
};

export const getClaimStatusInformation = (
  t: TFunction,
  {
    status,
    internalStatus,
  }: { status: ClaimStatuses; internalStatus: InternalClaimStatus }
): { title: string; description: string } | undefined => {
  switch (status) {
    case 'SUBMITTED':
      return {
        title: t(
          'claims.information.status.submitted.title',
          'We are reviewing your claim'
        ),
        description: t(
          'claims.information.status.submitted.description',
          'This could take 3-6 weeks.'
        ),
      };
    case 'PROCESSING':
      return {
        title: t(
          'claims.information.status.processing.title',
          'We are reviewing your claim'
        ),
        description:
          internalStatus === 'IN_REVIEW'
            ? t(
                'claims.information.status.inReview.description',
                'We will be in touch if we need additional information.'
              )
            : t(
                'claims.information.status.processing.description',
                'This could take 3-6 weeks.'
              ),
      };
    case 'PAID_OUT':
      return {
        title: t(
          'claims.information.status.paidOut.title',
          'Your claim is paid out'
        ),
        description: t(
          'claims.information.status.paidOut.description',
          'You can expect to receive the reimbursement in the next two weeks. We sent you an email with further details.'
        ),
      };
    case 'ACTION_NEEDED':
      return {
        title: t(
          'claims.information.status.actionNeeded.title',
          'Provide additional information'
        ),
        description: t(
          'claims.information.status.actionNeeded.description',
          'We sent you an email with further instructions.'
        ),
      };
    case 'CANCELLED':
      return {
        title: t(
          'claims.information.status.cancelled.title',
          'This claim is a duplicate'
        ),
        description: t(
          'claims.information.status.cancelled.description',
          'We have already received this claim information in another submission.'
        ),
      };
    case 'DENIED':
      return {
        title: t(
          'claims.information.status.denied.title',
          'We can’t reimburse you for this claim'
        ),
        description: t(
          'claims.information.status.denied.description',
          'We sent you an email with further details.'
        ),
      };
    case 'PROCESSED':
      return {
        title: t(
          'claims.information.status.processed.title',
          'Your claim has been processed'
        ),
        description: t(
          'claims.information.status.processed.description',
          'We sent you an email with further details.'
        ),
      };
    case 'SETTLED':
      return {
        title: t(
          'claims.information.status.settled.title',
          'Your claim is settled with the third party'
        ),
        description: '',
      };
    case 'APPROVED':
      return {
        title: t(
          'claims.information.status.settled.approved',
          'Cost plan is approved.'
        ),
        description: 'We sent you an email with further details.',
      };
    case 'WITHDRAWN':
      return {
        title: t(
          'claims.information.status.withdrawn.title',
          'This claim is canceled by your request'
        ),
        description: '',
      };
    case 'ON_HOLD':
      return {
        title: t(
          'claims.information.status.onHold.title',
          'Additional information is requested from the injured party'
        ),
        description: t(
          'claims.information.status.onHold.description',
          "We sent an email to them with further details. We'll move forward with the claim once we get the information."
        ),
      };
  }
};
