import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { useSafeTranslation } from 'shared/i18n';

import headIcon from '../assets/head.svg';
import { ToggleCard } from './ToggleCard';

type CustomizationSectionProps = {
  premiumSelected: boolean;
  setPremiumSelected: React.Dispatch<React.SetStateAction<boolean>>;
  price: number;
};

export const CustomizationSection = ({
  premiumSelected,
  setPremiumSelected,
  price,
}: CustomizationSectionProps) => {
  const { t } = useSafeTranslation();

  const priceStr = englishFormattedEuroCurrency(price);

  return (
    <section className="pt64">
      <h2 className="p-h2 mb16">
        {t('disability.addons.customization.title', 'Add-ons')}
      </h2>
      <ToggleCard
        className="mt16"
        isSelected={premiumSelected}
        onChange={setPremiumSelected}
        toggleKey="premiumSelected"
        price={priceStr}
        showPlus
        title={t(
          'disability.addons.customization.mentalHealth.title',
          'Mental health'
        )}
        description={t(
          'disability.addons.customization.mentalHealth.description',
          'Get covered for occupational disabilities caused by a mental or psychosomatic illness that interrupt your work performance by more than 50% for at least 6 months.'
        )}
        icon={headIcon}
      />
    </section>
  );
};
