import { getRoutes, Rule } from '@getpopsure/qnr-framework';
import routes from 'constants/routes';
import { Route, Switch } from 'react-router-dom';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { IncomeProtection } from './models';
import { getQuestionnaire, IncomeProtectionComponents } from './questionnaire';

const rules: Rule<IncomeProtection>[] = [];

export const SignupPage = () => {
  const questionnaireAnswers = {};

  const onAnswer = () => {
    // do nothing - this is just a placeholder for now
  };

  const questions = getQuestionnaire();

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, routes.policies.incomeProtection.path),
    rules,
  };

  return (
    <Switch>
      <Route path={routes.policies.incomeProtection.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: IncomeProtectionComponents,
          }}
          basePath={routes.policies.incomeProtection.path}
          questionId="quote"
          featureName="IncomeProtection"
        />
      </Route>
    </Switch>
  );
};
