import { email } from '@getpopsure/private-constants';
import { useSubmitBikeClaim } from 'features/bikeClaims/hooks/useSubmitBikeClaim';
import { BikeClaims } from 'features/bikeClaims/models';
import { getBikeClaims } from 'features/bikeClaims/selectors';
import { retrieveBikeClaimQuestionReviewValues } from 'features/bikeClaims/utils';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { ZodError } from 'zod';

import { ReviewView } from './Review.view';

export const Review = ({
  onSubmitValue,
  metaData: { policyId },
  routes,
}: CustomComponentProps<BikeClaims>) => {
  const { t } = useSafeTranslation();
  const questionnaire = useSelector(getBikeClaims);
  const {
    mutate: submitBikeClaim,
    error,
    isError,
    isLoading,
  } = useSubmitBikeClaim();

  if (!questionnaire) {
    throw new Error('[Bike Claims] Questionnaire is undefined for review');
  }

  const questionReviewValues = retrieveBikeClaimQuestionReviewValues(
    questionnaire,
    routes,
    t
  );

  const errorMessage = isError
    ? error instanceof ZodError
      ? t(
          'claims.bike.review.validationError.message',
          'Some information needed to submit your claim are missing or invalid. Please try again or contact us at {{supportEmail}}.',
          { supportEmail: email.help }
        )
      : t(
          'claims.bike.review.error.message',
          'An error occurred while submitting your claim. Please try again or contact us at {{supportEmail}}.',
          { supportEmail: email.help }
        )
    : undefined;

  const onSubmitClaim = async (e: FormEvent) => {
    e.preventDefault();

    submitBikeClaim(
      { questionnaire, policyId },
      {
        onSuccess: () => onSubmitValue(true),
      }
    );
  };

  return (
    <ReviewView
      questionReviewValues={questionReviewValues}
      t={t}
      loading={isLoading}
      error={errorMessage}
      onSubmit={onSubmitClaim}
    />
  );
};
