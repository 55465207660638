import TimedLoadSpinner from 'components/timedLoadSpinner';
import { useGetPolicies } from 'hooks/useGetPolicies';
import { useSafeTranslation } from 'shared/i18n';

import { CreateClaimView } from './CreateClaim.view';

export const CreateClaim = () => {
  const { t } = useSafeTranslation();
  const { policies = [], isLoading } = useGetPolicies();

  if (isLoading) {
    return <TimedLoadSpinner delayInMilliseconds={200} />;
  }

  return <CreateClaimView policies={policies} t={t} />;
};
