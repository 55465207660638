import { Reducer } from 'redux';

import { PaymentScreenAction } from './actions';
import { CheckoutInfo } from './paymentScreen.models';

export type PaymentScreenState = {
  checkouts: { [id: string]: CheckoutInfo | undefined };
};

export const defaultState: PaymentScreenState = {
  checkouts: {},
};

export const reducer: Reducer<PaymentScreenState, PaymentScreenAction> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case 'PAYMENT_SCREEN_MERGE_CHECKOUT':
      return {
        ...state,
        checkouts: {
          ...state.checkouts,
          [action.checkoutInfo.id]: action.checkoutInfo,
        },
      };
    default:
      return state;
  }
};

export default reducer;
