import { RequestType } from 'constants/requestTypes';
import { connect } from 'react-redux';
import { AppState } from 'reducers';
import { getRequestInProcess } from 'selectors/requests';

export interface LoaderProps {
  loading: boolean;
}

function mapStateToProps(requestTypes: RequestType[]) {
  return (state: AppState) => {
    return {
      loading: requestTypes.reduce((anyRequestInProcess, requestType) => {
        return anyRequestInProcess || getRequestInProcess(state, requestType);
      }, false),
    };
  };
}

const ConnectedLoaderView = (...requestTypes: RequestType[]) =>
  connect<LoaderProps, {}, {}, AppState>(mapStateToProps(requestTypes));

export default ConnectedLoaderView;
