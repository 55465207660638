import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './SectionHeader.module.scss';

interface SectionHeaderProps {
  buttonLabel?: string;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

const SectionHeader = ({
  buttonLabel,
  children,
  className,
  onClick,
}: SectionHeaderProps) => (
  <div className={classNames(className, 'd-flex jc-between')}>
    <h3 className={classNames(styles.header, 'p-h3 w50')}>{children}</h3>

    <div className="p-h3 fw-normal w50 ta-right">
      {onClick && buttonLabel && (
        <button
          className={classNames(
            'p-a p-p ds-interactive-component',
            styles.button
          )}
          data-testid="section-header-button"
          onClick={onClick}
          type="button"
        >
          {buttonLabel}
        </button>
      )}
    </div>
  </div>
);

export { SectionHeader };
