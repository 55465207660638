import CancelApplicationModal from 'features/policySingle/components/legacy/cancelApplication';
import { PolicySingleModalProps } from 'features/policySingle/components/modals';

export const PublicHealthCancelApplicationModal = (
  props: PolicySingleModalProps
) => {
  const { policyData, onClose } = props;

  return (
    <CancelApplicationModal isOpen policyId={policyData.id} onClose={onClose} />
  );
};
