import { CardInfo } from 'models/liability';

export interface Props extends CardInfo {
  className?: string;
}

const CoverageCard = ({ title, description, icon, className = '' }: Props) => (
  <div className={`d-flex ai-start ${className}`}>
    <img className="mr16" src={icon.src} alt={icon.alt} />
    <div>
      <div className="p-p fw-bold">{title}</div>
      <div className="p-p tc-grey-600">{description}</div>
    </div>
  </div>
);

export default CoverageCard;
