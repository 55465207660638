import * as Sentry from '@sentry/react';
import history from 'shared/browserHistory';

import packageJson from '../../package.json';
import { TASK_ENGINE_SENTRY_CATEGORY } from '../features/taskEngine/constants';

const { version } = packageJson;

function configSentry() {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: 'https://75a3a165880047a39ca43e93a63048f2@sentry.io/1262839',
      normalizeDepth: 10, // it's for redux middleware to parse the state better
      release: version,
      environment: process.env.REACT_APP_ENVIRONMENT,
      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        Sentry.replayIntegration({
          beforeErrorSampling: (event) => {
            return (
              event.message?.includes(TASK_ENGINE_SENTRY_CATEGORY) || false
            );
          },
        }),
      ],
    });
  }

  return null;
}

export default configSentry();
