import { getActivePolicies } from 'features/policiesDashboard/utils/getActivePolicies';
import { Claim } from 'models/claims';
import { PublicHealthProvider } from 'models/insurances/providers';
import { Policy } from 'models/policies';

export type PoliciesStatus =
  | { type: 'NO_ACTIVE_OR_PENDING_POLICIES' }
  | { type: 'ACTIVE_OR_PENDING_POLICIES' }
  | { type: 'NO_ACTIVE_POLICIES_ONLY_ACTIVE_CLAIMS' }
  | {
      type: 'ONLY_ACTIVE_OR_PENDING_PUBLIC_POLICIES';
      provider: PublicHealthProvider;
    };

const allowedClaimInsuranceTypes = ['DENTAL', 'INCOMING'];
const closedClaimStatus = [
  'PAID_OUT',
  'CUSTOMER_PAID_OUT',
  'OTHER_PAID_OUT',
  'CLOSED',
  'PROCESSED_WITH_OTHER_CLAIM',
];

const hasActiveClaims = (claims: Claim[]): boolean => {
  return claims.some((claim) => {
    return (
      allowedClaimInsuranceTypes.includes(claim.insuranceType) &&
      !closedClaimStatus.includes(claim.status)
    );
  });
};

const publicHealthActiveOrPendingStatus = [
  'APPLICATION_SENT',
  'PROCESSING',
  'COVERED',
];

export const hasActivePublicPolicies = (policies: Policy[]): boolean => {
  return policies.every((policy) => {
    return (
      policy.type === 'PUBLIC_HEALTH' &&
      publicHealthActiveOrPendingStatus.includes(policy.status)
    );
  });
};

export const calculatePolicyStatus = (
  policies: Policy[],
  claims: Claim[]
): PoliciesStatus => {
  const activePolicies = getActivePolicies(policies);
  const hasActivePolicies = activePolicies.length > 0;
  const activeClaims = hasActiveClaims(claims);
  const hasOnlyActivePublicPolicies =
    hasActivePolicies && hasActivePublicPolicies(activePolicies);

  if (!hasActivePolicies && !activeClaims) {
    return { type: 'NO_ACTIVE_OR_PENDING_POLICIES' };
  }

  if (hasOnlyActivePublicPolicies) {
    return {
      type: 'ONLY_ACTIVE_OR_PENDING_PUBLIC_POLICIES',
      provider: policies[0].providerName as PublicHealthProvider,
    };
  }

  if (!hasActivePolicies && activeClaims) {
    return { type: 'NO_ACTIVE_POLICIES_ONLY_ACTIVE_CLAIMS' };
  }

  return { type: 'ACTIVE_OR_PENDING_POLICIES' };
};
