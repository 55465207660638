import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import fuseIcon from 'features/householdClaims/icons/fuse.svg';
import phoneIcon from 'features/householdClaims/icons/phone.svg';
import { generatePath, useHistory } from 'react-router';
import { TFunction } from 'shared/i18n';

import {
  GettingStartedCard,
  Props as GettingStartedCardProps,
} from './components/GettingStartedCard';
import styles from './styles.module.scss';

interface Props {
  handleGetStarted: () => void;
  t: TFunction;
  policyId: string;
}

export const HouseholdClaimsGettingStartedView = ({
  handleGetStarted,
  t,
  policyId,
}: Props) => {
  const { location } = useHistory<{ from?: string }>();
  const cardData: GettingStartedCardProps[] = [
    {
      title: t(
        'claims.household.gettingStarted.cardInfo.1.title',
        'Record the damage as carefully as possible'
      ),
      description: t(
        'claims.household.gettingStarted.cardInfo.1.description',
        'We recommend that you record the damage thoroughly. Take pictures from different angles, write down what happened, and include the incident report, original receipts, and the police report if applicable.'
      ),
      icon: {
        src: phoneIcon,
        alt: t(
          'claims.household.gettingStarted.cardInfo.1.iconalt',
          'purple phone'
        ),
      },
    },

    {
      title: t(
        'claims.household.gettingStarted.cardInfo.2.title',
        'What isn’t covered?'
      ),
      description: t(
        'claims.household.gettingStarted.cardInfo.2.description',
        'Keep in mind that household contents insurance does not cover damage to building fixtures, or pipes (under the floor), and damages caused by a blown fuse or large-scale conflict.'
      ),
      icon: {
        src: fuseIcon,
        alt: t(
          'claims.household.gettingStarted.cardInfo.2.iconalt',
          'purple fuse'
        ),
      },
    },
  ];

  return (
    <div className={`mb80 ${styles.container}`}>
      <div className="p-body">
        <NavButton
          title={t('claims.household.gettingStarted.navButton.title', 'Back')}
          path={
            location.state?.from !== 'policy'
              ? routes.claims.create.path
              : generatePath(routes.me.policies.detail.path, { policyId })
          }
        />
        <h1 className="p-h1">
          {t('claims.household.gettingStarted.title', 'Getting started')}
        </h1>
        <p className="p-p mt8 mb24">
          {t(
            'claims.household.gettingStarted.description',
            'Here are a few useful tips to consider when making a claim:'
          )}
        </p>
        {cardData.map(({ title, description, icon }) => (
          <GettingStartedCard
            className="mt16"
            title={title}
            icon={icon}
            description={description}
            key={title}
          />
        ))}
        <button
          type="button"
          onClick={handleGetStarted}
          className={`p-btn--primary mt32 wmn3 ${styles.button}`}
        >
          {t('claims.household.gettingStarted.cta', 'Get started')}
        </button>
      </div>
    </div>
  );
};
