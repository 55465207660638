import { useSafeTranslation } from 'shared/i18n';

export const ProviderNotifiesUniversity = () => {
  const { t } = useSafeTranslation();
  return (
    <p className="p-p p-p--small tc-grey-500">
      {t(
        'studentChecklist.providerNotifiesUniversity.description',
        'Your university will automatically receive the M10 Meldung with your insurance status within 3 days. You won’t be informed about this, so you can check directly with your university.'
      )}
    </p>
  );
};
