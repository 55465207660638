import * as Sentry from '@sentry/react';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { LifeBeneficiariesActions, RequestAction } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import { Beneficiary } from 'features/life/models';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';

import { EditBeneficiaries, ZCreateBeneficiaries } from '../models';

export const storeAddBeneficiariesAnswers = (
  answer: Partial<EditBeneficiaries>
): LifeBeneficiariesActions => ({
  type: 'STORE_LIFE_BENEFICIARIES',
  questionnaire: answer,
});

export const flushBeneficiariesAnswers = (): LifeBeneficiariesActions => ({
  type: 'FLUSH_LIFE_BENEFICIARIES',
});

export const createBeneficiaries =
  (
    policyId: string,
    beneficiaries: Beneficiary[],
    policyHolderIdentificationToken: string
  ) =>
  async (
    dispatch: ThunkDispatch<AppState, unknown, RequestAction>
  ): Promise<ActionResponse> => {
    const requestType: RequestType = 'CREATE_LIFE_BENEFICIARIES';

    try {
      if (!policyId) {
        throw new Error(
          '[Life Beneficiaries] Policy ID is missing to creating beneficiaries.'
        );
      }

      const beneficiariesWithUploadTokens = beneficiaries.map(
        (beneficiary) => ({
          ...beneficiary,
          uploadTokens:
            beneficiary.uploads?.map((upload) => upload.token ?? '') ?? [],
        })
      );

      const validationResult = ZCreateBeneficiaries.safeParse({
        beneficiariesWithUploadTokens,
        identificationDocumentToken: policyHolderIdentificationToken,
      });
      if (!validationResult.success) {
        throw new Error(
          `[LIFE - Beneficiaries] Beneficiaries update validation errors: ${validationResult.error.toString()}`
        );
      }

      const validatedData = validationResult.data;

      dispatch(setRequestInProcess(true, requestType));
      await endpoint.createLifeBeneficiaries(
        validatedData.beneficiariesWithUploadTokens,
        validatedData.identificationDocumentToken,
        policyId
      );
      dispatch(setRequestInProcess(false, requestType));

      return 'SUCCESS';
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      return 'ERROR';
    }
  };
