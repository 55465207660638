import { TaskEngine } from 'features/taskEngine';
import { TaskError } from 'features/taskEngine/components/TaskError';

export const PolicyTaskEngine = ({ policyId }: { policyId: string }) => {
  return (
    <TaskEngine
      options={{
        filter: (task) =>
          task.description.type !== 'CLAIM_MISSING_INFO_REDIRECT' &&
          task?.description?.attributes?.policyId === policyId,
      }}
      render={({ tasks, error }) => {
        if (error)
          return <TaskError error={error} className="p-container mb24" />;

        if (tasks.length === 0) return null;

        return (
          <div className="p-container mb40 d-flex fd-column gap16">{tasks}</div>
        );
      }}
    />
  );
};
