import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Toggle } from '@popsure/dirty-swan';

import styles from './ToggleCard.module.scss';

type ToggleCardProps = {
  children?: React.ReactNode;
  className?: string;
  description: string;
  disabled?: boolean;
  isSelected: boolean;
  onChange: (value: boolean) => void;
  toggleKey: string;
  price: number | string;
  subtitle?: string;
  title: string;
  showPlus?: boolean;
  icon?: string;
};

export const ToggleCard = ({
  children,
  className = '',
  description,
  disabled = false,
  isSelected,
  onChange,
  toggleKey,
  price,
  title,
  subtitle = '',
  showPlus,
  icon = '',
}: ToggleCardProps) => (
  <div className={`p24 bg-white br8 ${className}`}>
    <div className={`d-flex jc-between gap16 ${styles.content}`}>
      <div>
        <h4 className={`p-h4 ${styles.heading}`}>
          <span className={`d-flex ${styles.tinyGap}`}>
            {icon && <img src={icon} alt="" role="presentation" />}
            {title}{' '}
          </span>
          {subtitle && (
            <span className={`tc-grey-600 fw-normal ${styles.subtitle}`}>
              {subtitle}
            </span>
          )}
        </h4>
        <p className={`p-p mt8 ${styles.description}`}>{description}</p>
      </div>
      <div className={`d-flex fd-column ai-end ${styles.toggleContainer}`}>
        <Toggle
          bordered={false}
          classNames={{ label: styles.toggleLabel }}
          disabled={disabled}
          onChange={(value: string[]) => onChange(value.includes(toggleKey))}
          options={{ [toggleKey]: '' }}
          value={isSelected ? [toggleKey] : []}
        />
        <h3
          className={`p-h3 mt16 ${styles.transition} ${
            disabled || !isSelected ? 'tc-grey-500' : ''
          }`}
        >
          {!!showPlus && '+ '}
          {typeof price === 'number'
            ? englishFormattedEuroCurrency(price)
            : price}
        </h3>
      </div>
    </div>
    {children}
  </div>
);
