import StepBar from 'components/stepBar';
import routes from 'constants/routes';
import { useClaimSteps } from 'features/claimsProcess/hooks';
import { checkSplash } from 'icons';
import { Link } from 'react-router-dom';

import styles from './style.module.scss';

const ClaimSubmitted = () => {
  const steps = useClaimSteps();
  return (
    <div className="p-body">
      <div className={`wmx8 w100 ${styles.container}`}>
        <div className="mt40">
          <StepBar currentStep={steps.length + 1} steps={steps.length} />
        </div>
        <img src={checkSplash} alt="check" className={`mt64 ${styles.icon}`} />
        <h1 className={`p-h1 mt40 ${styles['confirmation-header']}`}>
          Claim submitted!
        </h1>
        <p className="p-p mt16 wmx6 ta-center">
          There’s nothing left for you to do! We will get in contact with you
          once your request has been processed via email.
        </p>
        <p className="p-p mt8 wmx6 ta-center">
          You can track the status of your claim by clicking the button below.
        </p>
        <Link
          to={routes.claims.path}
          className={`p-btn p-btn--primary mt32 wmx4 ${styles.button}`}
        >
          Go to Claims
        </Link>
      </div>
    </div>
  );
};

export default ClaimSubmitted;
