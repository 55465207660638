import { BikeClaims } from 'features/bikeClaims/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';
import { Processing } from 'shared/pages/Processing';

export const BikeClaimsProcessing = ({
  onSubmitValue,
}: CustomComponentProps<BikeClaims>) => {
  const { t } = useSafeTranslation();

  const textList: string[] = [
    t('claims.bike.processing.loadingText', 'Processing claim...'),
  ];

  return <Processing onLoadComplete={onSubmitValue} textList={textList} />;
};
