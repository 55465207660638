import { TFunction } from '@getpopsure/i18n-react';
import { website } from '@getpopsure/private-constants';
import { Blocker } from 'components/Blocker';
import {
  Household,
  HouseholdQuestionnaire,
  questionPropLookup,
} from 'features/household/models';

import { Overview } from './Overview';

export const householdInfoQuestionnaire = (
  t: TFunction
): HouseholdQuestionnaire => ({
  overview: {
    required: true,
    type: 'CUSTOM',
    component: Overview,
    retrieveAnswerObject: (): Partial<Household> => ({
      overview: false,
    }),
    groupId: 'signup',
    retrieveQuestionsToRemove: () => ['review'],
  },

  propertyOwnership: {
    required: true,
    type: 'RADIO',
    props: {
      mapValue: {},
      ...questionPropLookup(t).propertyOwnership,
    },
    retrieveAnswerObject: (
      propertyOwnership: Household['propertyOwnership']
    ): Partial<Household> => ({
      propertyOwnership,
    }),
    groupId: 'signup',
    retrieveQuestionsToRemove: () => ['review'],
  },

  homeType: {
    required: true,
    type: 'RADIO',
    props: {
      mapValue: {},
      ...questionPropLookup(t).homeType,
    },
    retrieveAnswerObject: (
      homeType: Household['homeType']
    ): Partial<Household> => ({
      homeType,
    }),
    groupId: 'signup',
    retrieveQuestionsToRemove: () => ['review'],
  },

  isPrimaryResidence: {
    required: true,
    type: 'BOOLEAN',
    props: {
      ...questionPropLookup(t).isPrimaryResidence,
    },
    retrieveAnswerObject: (
      isPrimaryResidence: Household['isPrimaryResidence']
    ): Partial<Household> => ({
      isPrimaryResidence,
    }),
    retrieveNextPageId: (primaryResidence: Household['isPrimaryResidence']) =>
      !primaryResidence ? 'blocker' : 'coveringEveryHouseholdMember',
    groupId: 'signup',
    retrieveQuestionsToRemove: () => ['review'],
  },

  blocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t('page.household.questionnaire.blocker.title', "We're sorry"),
        description: t(
          'page.household.questionnaire.blocker.description',
          "Based on the information you've provided, we, unfortunately, aren't able to accept your application for household contents insurance at the moment.\n\nIf you have any questions, feel free to reach out."
        ),
        buttonProps: [
          {
            type: 'href',
            href: website.support,
            caption: t(
              'page.household.questionnaire.blocker.button.caption',
              'Get in touch'
            ),
          },
        ],
        ...props,
      }),
    groupId: 'signup',
  },

  coveringEveryHouseholdMember: {
    required: true,
    type: 'BOOLEAN',
    props: {
      ...questionPropLookup(t).coveringEveryHouseholdMember,
    },
    retrieveAnswerObject: (
      coveringEveryHouseholdMember: Household['coveringEveryHouseholdMember']
    ): Partial<Household> => ({
      coveringEveryHouseholdMember,
    }),
    retrieveNextPageId: (
      coveringEveryHouseholdMember: Household['coveringEveryHouseholdMember']
    ) => (!coveringEveryHouseholdMember ? 'blocker' : 'numberOfPreviousClaims'),
    groupId: 'signup',
    retrieveQuestionsToRemove: () => ['review'],
  },

  numberOfPreviousClaims: {
    required: true,
    type: 'RADIO',
    props: {
      mapValue: {},
      ...questionPropLookup(t).numberOfPreviousClaims,
    },
    retrieveAnswerObject: (
      numberOfPreviousClaims: Household['numberOfPreviousClaims']
    ): Partial<Household> => ({
      numberOfPreviousClaims,
      ...(numberOfPreviousClaims === 'NONE'
        ? {
            isClaimsAmountMoreThan1500: false,
          }
        : {}),
    }),
    retrieveNextPageId: (
      numberOfPreviousClaims: Household['numberOfPreviousClaims']
    ) => {
      if (numberOfPreviousClaims === 'THREE_OR_MORE') {
        return 'blocker';
      }

      if (numberOfPreviousClaims === 'NONE') {
        return 'account';
      }

      return 'isClaimsAmountMoreThan1500';
    },
    groupId: 'signup',
    retrieveQuestionsToRemove: () => ['review'],
  },

  isClaimsAmountMoreThan1500: {
    required: ({ numberOfPreviousClaims }: Partial<Household>) =>
      numberOfPreviousClaims !== 'NONE',
    type: 'BOOLEAN',
    props: {
      ...questionPropLookup(t).isClaimsAmountMoreThan1500,
    },
    retrieveAnswerObject: (
      isClaimsAmountMoreThan1500: Household['isClaimsAmountMoreThan1500']
    ): Partial<Household> => ({
      isClaimsAmountMoreThan1500,
    }),
    retrieveNextPageId: (
      isClaimsAmountMoreThan1500: Household['isClaimsAmountMoreThan1500']
    ) => (isClaimsAmountMoreThan1500 ? 'blocker' : 'account'),
    groupId: 'signup',
    retrieveQuestionsToRemove: () => ['review'],
  },
});
