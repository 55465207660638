import DelinquencyBlocker from 'components/DelinquencyBlocker';
import { useSafeTranslation } from 'shared/i18n';

import { PolicySingleModalProps } from './PolicySingleModal.models';

export const DelinquencyBlockerCancelPolicyModal = (
  props: PolicySingleModalProps
) => {
  const { onClose } = props;
  const { t } = useSafeTranslation();

  return (
    <DelinquencyBlocker
      isOpen
      onClose={onClose}
      description={t(
        'account.delinquencyBlocker.policyCancellation.description',
        'You can request a policy cancellation once the outstanding insurance premiums are successfully collected.'
      )}
    />
  );
};
