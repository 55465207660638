import { IbanInput } from '@popsure/dirty-swan';
import classnames from 'classnames';
import StepBar from 'components/stepBar';
import {
  useClaimSteps,
  useCurrentClaimStep as _useCurrentClaimStep,
} from 'features/claimsProcess/hooks';
// eslint-disable-next-line import/no-cycle
import { ViewProps } from 'features/claimsProcess/paymentInfo';
import { ClaimSubmitInfo } from 'models/claims';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { isValidIban } from 'shared/util/isValidIban';

import styles from './style.module.scss';

const ClaimPaymentInfo = ({
  createClaim,
  submittableClaimInfo,
  loading,
  error,
  policyId,
  useCurrentClaimStep = _useCurrentClaimStep,
}: ViewProps & { useCurrentClaimStep?: typeof _useCurrentClaimStep }) => {
  const [iban, setIban] = useState<string | undefined>(
    submittableClaimInfo?.iban ?? undefined
  );
  const [ibanError, setIbanError] = useState<string | undefined>(undefined);

  const validateIban = (value: string) => {
    setIbanError(undefined);
    setIban(value);

    if (!isValidIban(value)) {
      setIbanError('Enter a valid IBAN');
    }
  };

  const steps = useClaimSteps();
  const currentStep = useCurrentClaimStep();
  const currentIndex = (currentStep && steps.indexOf(currentStep) + 1) ?? 1;

  return (
    <div className="p-body">
      <form
        className={`wmx8 w100 ${styles.container}`}
        onSubmit={(e) => {
          e.preventDefault();

          if (!iban || !submittableClaimInfo?.uploadedFiles) {
            throw new Error(
              '[Expat Claims] Claim type info is required to submit claim.'
            );
          }

          const uploadedFiles = submittableClaimInfo?.uploadedFiles || [];

          const uploadTokens = uploadedFiles
            .map((file) => file.token)
            .filter(
              (token: string | undefined): token is string =>
                token !== undefined
            );

          const claimInfo: ClaimSubmitInfo = {
            userPolicyId: policyId,
            uploadTokens,
            iban,
            claimTypes: submittableClaimInfo.claimType
              ? [submittableClaimInfo.claimType]
              : undefined,
          };

          if (submittableClaimInfo.details !== undefined) {
            claimInfo.details = submittableClaimInfo.details;
          }

          createClaim(claimInfo);
        }}
      >
        <div className="mt40">
          <StepBar currentStep={currentIndex} steps={steps.length} />
        </div>
        <div className={`mt32 ${styles.header}`}>
          <h4 className="p-h4 tc-purple-500">Step {currentIndex}</h4>
          <div className="p-h2">Submit payout details</div>
        </div>
        <div className="p-p mt16">
          Please provide the details of the bank account where you would like to
          receive the payout.{' '}
        </div>
        <div className="mt16 wmx5">
          <IbanInput value={iban} placeholder="IBAN" onChange={validateIban} />
          {ibanError && (
            <div className="p-notice p-notice--danger mt16">{ibanError}</div>
          )}
        </div>
        <div className="p-p mt16">
          If your claim is accepted, the payout will be sent to you
          automatically.
        </div>
        <div className="mt24">
          <AnimateHeight duration={300} height={error ? 'auto' : 0}>
            <div className="p-notice p-notice--danger mt16">{error}</div>
          </AnimateHeight>
          <button
            type="submit"
            className={classnames(
              `p-btn p-btn--primary p-p wmn3 ${styles.button}`,
              {
                'p-btn--loading': loading,
              }
            )}
            disabled={!iban || !ibanError || loading}
          >
            Submit Claim
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClaimPaymentInfo;
