import classnames from 'classnames';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { ClaimStatuses, ClaimTypes, mapClaimTypes } from 'models/claims';
import { generatePath, Link } from 'react-router-dom';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import styles from './style.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

const mapClaimStatus: { [k in ClaimStatuses]: string } = {
  SUBMITTED: 'Submitted',
  PROCESSING: 'Processing',
  ACTION_NEEDED: 'Requires info',
  PAID_OUT: 'Paid out',
  PROCESSED: 'Closed',
  CANCELLED: '',
  SETTLED: '',
  DENIED: '',
  APPROVED: '',
  WITHDRAWN: '',
  ON_HOLD: '',
};

const ClaimProcess = ({
  type,
  price,
  dateOfClaim,
  status,
  policyId,
  claimId,
}: {
  type: ClaimTypes;
  price?: number;
  dateOfClaim: string;
  status: ClaimStatuses;
  policyId: string;
  claimId: string;
}) => {
  const mapClaimStatusClassname: { [K in ClaimStatuses]: string } = {
    SUBMITTED: styles['paid-status'],
    PROCESSING: styles['pending-status'],
    ACTION_NEEDED: styles['pending-status'],
    PAID_OUT: styles['paid-status'],
    PROCESSED: styles['closed-status'],
    CANCELLED: '',
    SETTLED: '',
    DENIED: '',
    APPROVED: '',
    WITHDRAWN: '',
    ON_HOLD: '',
  };

  return (
    <Link
      className={styles.container}
      to={generatePath(routes.me.claims.claimStatus.path, {
        policyId,
        claimId,
      })}
    >
      <div>
        <div className={`p-p ${styles['claim-type']}`}>
          {mapClaimTypes[type]}
        </div>
        {price && (
          <div className="p-p--small tc-grey-500">
            {englishFormattedEuroCurrency(price)}
          </div>
        )}
        <div className="p-p--small mt8">
          {dayjs(dateOfClaim).format(DATE_FORMAT)}
        </div>
      </div>
      <div
        className={classnames(
          `p-p--small ${styles.status} ${mapClaimStatusClassname[status]}`
        )}
      >
        {mapClaimStatus[status]}
      </div>
    </Link>
  );
};

export default ClaimProcess;
