import { legal } from '@getpopsure/private-constants';
import { MultiDropzone } from '@popsure/dirty-swan';
import { NavButton } from 'components/NavButton';
import QuestionnaireWrapper from 'components/questionnaireForms/QuestionnaireWrapper';
import { FormEvent } from 'react';
import { TFunction, Trans } from 'shared/i18n';
import { UploadedFile } from 'shared/models/types';
import { isMobileApp } from 'shared/util/isMobileApp';

interface Props {
  handleSubmit: (e: FormEvent) => void;
  t: TFunction;
  submissionLoading?: boolean;
  onFileSelect: (files: File[]) => void;
  onRemoveFile: (id: string) => void;
  uploadedFiles: UploadedFile[];
  uploadLoading: boolean;
  isSubmitValid: boolean;
}

export const PrivateHealthClaimsUploadDocumentsView = ({
  handleSubmit,
  onFileSelect,
  onRemoveFile,
  uploadedFiles,
  t,
  submissionLoading,
  uploadLoading,
  isSubmitValid,
}: Props) => (
  <QuestionnaireWrapper
    question={t(
      'claims.privateHealth.uploadDocuments.question',
      'Upload documents'
    )}
    onSubmit={handleSubmit}
    additionalInfo={{
      title: t(
        'claims.privateHealth.uploadDocuments.additionalInfo.title',
        'What documents should I provide?'
      ),
      description: t(
        'claims.privateHealth.uploadDocuments.additionalInfo.details',
        '- Invoices / receipts\n- Referral from general practitioner / specialist\n- Prescriptions with diagnosis (medications, aids, and remedies)\n- Cost plans\n\nWe accept both scanned documents and photos of documents. Make sure that the text is readable before submitting.'
      ),
    }}
    validForSubmission={isSubmitValid}
    loading={uploadLoading || submissionLoading}
  >
    <div className="wmx6">
      {isMobileApp && <NavButton title="Previous question" renderInPortal />}

      <MultiDropzone
        onFileSelect={onFileSelect}
        uploadedFiles={uploadedFiles}
        uploading={uploadLoading}
        onRemoveFile={onRemoveFile}
      />
    </div>
    <Trans i18nKey="claims.privateHealth.uploadDocuments.agreement">
      <p className="mt24 p-p">
        By uploading any documents you agree to our{' '}
        <a
          className="p-a"
          href={legal.declarationDataProtection}
          target="_blank"
          rel="noopener noreferrer"
        >
          data processing agreement
        </a>
        .
      </p>
    </Trans>
  </QuestionnaireWrapper>
);
