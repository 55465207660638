import { PartialQuestionnaire } from 'models/questionnaireFramework';

import { createQuestionOrder } from '../createQuestionOrder';

export const getLastUnansweredQuestionId = <
  Questionnaire extends Object,
  GroupId
>(
  currentQuestionId: keyof Questionnaire,
  questions: PartialQuestionnaire<Questionnaire, GroupId>,
  answers: Partial<Questionnaire>
): keyof Questionnaire | null => {
  const questionOrder = createQuestionOrder(questions);
  const currentQuestionObject = questionOrder.find(
    ({ id }) => id === currentQuestionId
  );

  const lastUnasweredQuestion = questionOrder.find(({ id, required }, idx) => {
    const isCurrentPageAhead = currentQuestionObject
      ? questionOrder.indexOf(currentQuestionObject) > idx
      : false;
    const isRequired =
      typeof required === 'function' ? required(answers) : required;

    return isCurrentPageAhead && isRequired && answers?.[id] === undefined;
  });

  return lastUnasweredQuestion?.id ?? null;
};
