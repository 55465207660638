import { HealthCardChecklist } from 'features/healthCard';
import { PolicySingleCard } from 'features/policySingle/components/PolicySingleCard';
import { StudentChecklist } from 'features/studentChecklist';

interface PolicyChecklistsProps {
  withHealthCardChecklist: boolean;
  withStudentChecklist: boolean;
  policySingleV2?: boolean;
}

export const PolicyChecklists = (props: PolicyChecklistsProps) => {
  const { withStudentChecklist, withHealthCardChecklist, policySingleV2 } =
    props;

  if (!withStudentChecklist && !withHealthCardChecklist) return null;

  if (policySingleV2) {
    return (
      <div className="p-container">
        {withStudentChecklist && (
          <PolicySingleCard>
            <StudentChecklist />
          </PolicySingleCard>
        )}
        {withHealthCardChecklist && (
          <PolicySingleCard>
            <HealthCardChecklist />
          </PolicySingleCard>
        )}
      </div>
    );
  }

  return (
    <div className="ds-card ds-card--no-dropshadow mt16 mb8 p24">
      {withStudentChecklist && <StudentChecklist />}
      {withHealthCardChecklist && <HealthCardChecklist />}
    </div>
  );
};
