import { MenuDotsMoreVerticalIcon } from '@popsure/dirty-swan';
import CardBrand from 'components/cardBrand';
import dayjs from 'dayjs';
import { PaymentMethod } from 'models/paymentMethods';
import { ReactNode, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import {
  formatCardExpiryDate,
  formatCardLastDigits,
} from 'shared/util/cardFormatters';

import {
  Dropdown,
  DropdownAction,
  DropdownList,
} from '../DropDown/DropDown.view';
import styles from './style.module.scss';

interface Actions {
  onDelete: (paymentMethodId: string) => void;
  onMakeDefault: (paymentMethodId: string) => void;
}

interface DropdownStateProps {
  expandedDropdownId: string | null;
  setExpandedDropdownId: (id: string | null) => void;
}

type LineProps = {
  paymentMethod: PaymentMethod;
} & Actions &
  DropdownStateProps;

type LineGroupProps = {
  containerCyTag: string;
  paymentMethods: PaymentMethod[];
} & Actions &
  DropdownStateProps;

type ListProps = {
  defaultPaymentMethod?: PaymentMethod;
  otherPaymentMethods: PaymentMethod[];
  renderDefaultPaymentMethodAfter: ReactNode;
  showPaymentMethodTitle?: boolean;
} & Actions;

const Line = ({
  paymentMethod,
  expandedDropdownId,
  setExpandedDropdownId,
  onDelete,
  onMakeDefault,
  ...props
}: LineProps) => {
  const { t } = useSafeTranslation();

  const expiry = paymentMethod?.attributes?.expiry;
  const lineId = paymentMethod.id;
  const expiresAt = expiry ? formatCardExpiryDate(expiry) : undefined;
  const isExpired = expiry
    ? dayjs()
        .year(Number(expiry?.year))
        .month(Number(expiry?.month))
        .isBefore(dayjs())
    : false;

  const showDropdown = () => {
    setExpandedDropdownId(lineId);
  };

  const hideDropdown = () => {
    setExpandedDropdownId(null);
  };

  return (
    <div className={styles.line} {...props}>
      <CardBrand brand={paymentMethod.attributes.brand} />

      <div className={styles.description}>
        {paymentMethod.type === 'PAYPAL' ? (
          <>
            <div className={styles['last-digits']}>
              {paymentMethod.attributes.brand}
            </div>
            <div className={styles['expires-at']}>
              {paymentMethod.attributes.identifier}
            </div>
          </>
        ) : (
          <>
            <div className={styles['last-digits']}>
              {formatCardLastDigits(paymentMethod.attributes.identifier)}
            </div>
            {expiresAt && (
              <div className={styles['expires-at']}>
                {t('account.paymentMethods.expiresAt.label', {
                  defaultValue: 'Expires {{expiresAt}}',
                  expiresAt,
                  interpolation: { escapeValue: false },
                })}
              </div>
            )}
          </>
        )}
      </div>

      {paymentMethod.isDefault && (
        <div className="py8 px16 br8 d-flex ai-center bg-green-100 fw-bold p-p--small">
          {t('account.paymentMethods.status.default', 'Default')}
        </div>
      )}

      {!paymentMethod.isDefault && isExpired && (
        <div className="py8 px16 br8 d-flex ai-center bg-grey-100 fw-bold p-p--small">
          {t('account.paymentMethods.status.expired', 'Expired')}
        </div>
      )}

      <Dropdown onOutsideClick={hideDropdown}>
        <button
          className={styles.overflow}
          onClick={showDropdown}
          data-cy="payment-method-menu"
          type="button"
        >
          <MenuDotsMoreVerticalIcon size={28} color="primary-500" noMargin />
        </button>

        <DropdownList isExpanded={expandedDropdownId === lineId}>
          {!paymentMethod.isDefault && (
            <DropdownAction
              isDangerous={false}
              text={t(
                'account.paymentMethods.makeDefaultButton.caption',
                'Make default'
              )}
              onClick={() => {
                onMakeDefault(paymentMethod.id);
              }}
              data-cy="payment-method-make-default"
            />
          )}
          <DropdownAction
            isDangerous={true}
            text={t('account.paymentMethods.deleteButton.caption', 'Delete')}
            onClick={() => {
              onDelete(paymentMethod.id);
            }}
            data-cy="payment-method-delete"
          />
        </DropdownList>
      </Dropdown>
    </div>
  );
};

const LineGroup = ({
  paymentMethods,
  containerCyTag,
  ...props
}: LineGroupProps) => {
  if (!paymentMethods.length) {
    return <></>;
  }

  return (
    <>
      <div
        className={`${styles['line-group']} mt8`}
        data-cy={containerCyTag}
        data-testid={containerCyTag}
      >
        {paymentMethods.map((paymentMethod) => (
          <Line
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            data-cy={`payment-method-line-${paymentMethod.id}`}
            {...props}
          />
        ))}
      </div>
    </>
  );
};

const PaymentMethodsList = ({
  defaultPaymentMethod,
  otherPaymentMethods,
  renderDefaultPaymentMethodAfter,
  showPaymentMethodTitle = true,
  ...props
}: ListProps) => {
  const { t } = useSafeTranslation();

  const [expandedDropdownId, setExpandedDropdownId] = useState<string | null>(
    null
  );

  return (
    <>
      {showPaymentMethodTitle && (
        <h3 className="p-h3 mt24">
          {t(
            'account.paymentMethods.defaultPaymentMethod.title',
            'Payment method'
          )}
        </h3>
      )}

      <p className="p-p mt16">
        {t(
          'account.paymentMethods.defaultPaymentMethod.description',
          'This method will be used to pay for all your policies'
        )}
      </p>

      <LineGroup
        paymentMethods={defaultPaymentMethod ? [defaultPaymentMethod] : []}
        expandedDropdownId={expandedDropdownId}
        setExpandedDropdownId={setExpandedDropdownId}
        containerCyTag="payment-methods-group-default"
        {...props}
      />

      {renderDefaultPaymentMethodAfter}

      <h3 className="p-h3 mt24">
        {t('account.paymentMethods.previouslyUsed.title', 'Previously used')}
      </h3>

      <LineGroup
        paymentMethods={otherPaymentMethods}
        expandedDropdownId={expandedDropdownId}
        setExpandedDropdownId={setExpandedDropdownId}
        containerCyTag="payment-methods-group-non-default"
        {...props}
      />
    </>
  );
};

export default PaymentMethodsList;
