import { TFunction } from '@getpopsure/i18n-react';
import {
  ArrowUpIcon,
  CoinsMoneyIcon,
  EuroClaimIcon,
  GlobeNetworkEarthIcon,
  PigMoneySavingsIcon,
  TrendingUpIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const pension = (t: TFunction): AdditionalInfoType => ({
  description: t(
    'policyModal.pension.description',
    'The private pension plan allows you to invest flexibly, withdraw money whenever you want, and take your pension with you if you leave Germany. Since the public pension system often isn’t enough to cover basic expenses in retirement, the government recommends supplementing it with a private plan to ensure financial stability when you retire.'
  ),
  accordions: [
    {
      title: t('policyModal.pension.title', 'How it works'),
      items: [
        {
          id: 'covered-1',
          icon: <EuroClaimIcon size={24} />,
          question: t(
            'policyModal.pension.covered1.question',
            'Tax efficient, ETF-based'
          ),
          answer: t(
            'policyModal.pension.covered1.answer',
            'Normally, you have to pay taxes on the profits from your investments. With this pension plan, you can enjoy the benefits of ETFs, such as lower fees and higher growth potential, while also gaining a significant tax advantage as soon as you retire. You can choose to invest 100% in ETFs, or balance the investment with a portion of less volatile government bonds.'
          ),
        },
        {
          id: 'covered-2',
          icon: <CoinsMoneyIcon size={24} />,
          question: t(
            'policyModal.pension.covered2.question',
            'Lowest fees on the market (0.7% per year)'
          ),
          answer: t(
            'policyModal.pension.covered2.answer',
            "- Upfront fees: **€0**\n- Management: **€1.50**/mo, **€0.5%**/yr\n- Investment fees: **€0.2%**/yr\n\nOur policy features an ETF-based product, which generally incurs lower fees compared to traditional investment types. This means we can keep fees low and you'll retain more of your money over time."
          ),
        },
        {
          id: 'covered-3',
          icon: <TrendingUpIcon size={24} />,
          question: t(
            'policyModal.pension.covered3.question',
            'Higher profit potential'
          ),
          answer: t(
            'policyModal.pension.covered3.answer',
            'ETFs allow you to invest in the stock market, giving your money the chance to grow as the market increases over time. This makes ETFs a smart choice for building your retirement savings.'
          ),
        },
        {
          id: 'covered-4',
          icon: <PigMoneySavingsIcon size={24} />,
          question: t(
            'policyModal.pension.covered4.question',
            'Flexible contributions'
          ),
          answer: t(
            'policyModal.pension.covered4.answer',
            'Increase, decrease, or pause your monthly contributions based on your lifestyle. Withdraw money whenever you want.'
          ),
        },
        {
          id: 'covered-5',
          icon: <ArrowUpIcon size={24} />,
          question: t(
            'policyModal.pension.covered5.question',
            'Flexible pension payouts'
          ),
          answer: t(
            'policyModal.pension.covered5.answer',
            'At retirement, you can freely combine monthly pension payouts and one-off payments, according to your financial needs and preferences.'
          ),
        },
      ],
    },
  ],
  details: [
    {
      icon: GlobeNetworkEarthIcon,
      title: t(
        'policyModal.pension.details.title1',
        'Take the pension worldwide'
      ),
      description: t(
        'policyModal.pension.details.description1',
        'Unsure about staying in Germany long-term? No problem, your pension plan will move with you. The only thing that you need is an EU bank account.'
      ),
    },
    {
      icon: CoinsMoneyIcon,
      title: t('policyModal.pension.details.title2', 'No upfront fees'),
      description: t(
        'policyModal.pension.details.description2',
        "Don't pay any extra at the start, so more of your money can go towards investments. This is where we differ from the market"
      ),
    },
  ],
  buttonText: t('policyModal.pension.buttonText', 'Get started'),
});
