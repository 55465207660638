const KG_TO_LBS_CONVERSION_RATE = 2.20462;

export const kgToLbs = (kg: number) => kg * KG_TO_LBS_CONVERSION_RATE;
export const lbsToKg = (lbs: number) => lbs / KG_TO_LBS_CONVERSION_RATE;

const CM_TO_INCH_CONVERSION_RATE = 2.54;

export const centimetersToFeet = (cm: number): [number, number] => {
  const inches = Math.round(cm / CM_TO_INCH_CONVERSION_RATE);
  return [Math.floor(inches / 12), inches % 12];
};

export const feetToCentimeters = (n: number, m: number) => {
  const total = n * 12 + m;
  const cm = Math.round(total * CM_TO_INCH_CONVERSION_RATE);
  return cm;
};
