import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { ClaimsAction } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import { APIResponseError } from 'models/error';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';

import {
  HouseholdClaims,
  HouseholdClaimsDispatch,
  HouseholdClaimsSubmitInfo,
} from '../models/HouseholdClaims.model';

export const storeHouseholdClaimsAnswers = (
  answer: Partial<HouseholdClaims>
): ClaimsAction => ({
  type: 'STORE_HOUSEHOLD_CLAIM',
  household: answer,
});

export const flushHouseholdClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_HOUSEHOLD_CLAIM',
});

export const submitClaim =
  (answers: HouseholdClaimsSubmitInfo) =>
  async (dispatch: HouseholdClaimsDispatch): Promise<ActionResponse> => {
    const requestType: RequestType = 'CREATE_CLAIM';
    dispatch(setRequestInProcess(true, requestType));

    try {
      await endpoint.submitClaim(answers);
      dispatch(setRequestInProcess(false, requestType));
      return 'SUCCESS';
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      return 'ERROR';
    }
  };
