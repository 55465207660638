import { TFunction } from '@getpopsure/i18n-react';
import { InsuranceTypes } from 'models/insurances/types';

import { AdditionalInfoType } from '../../types';
import { bike } from './bike';
import { dental } from './dental';
import { disability } from './disability';
import { dogLiability } from './dogLiability';
import { expatHealth } from './expatHealth';
import { household } from './household';
import { incomingEs } from './incomingEs';
import { incomingEu } from './incomingEu';
import { legal } from './legal';
import { liability } from './liability';
import { life } from './life';
import { pension } from './pension';
import { petHealth } from './petHealth';
import { privateHealth } from './privateHealth';
import { publicHealth } from './publicHealth';
import { travelHealth } from './travelHealth';

export const getAdditionalInfo = (
  t: TFunction,
  insuranceType: InsuranceTypes
): AdditionalInfoType | null => {
  const additionalInfo: Partial<Record<InsuranceTypes, AdditionalInfoType>> = {
    BIKE: bike(t),
    DENTAL: dental(t),
    DISABILITY: disability(t),
    DOG_LIABILITY: dogLiability(t),
    HOUSEHOLD: household(t),
    INCOMING: expatHealth(t),
    INCOMING_ES: incomingEs(t),
    INCOMING_EU: incomingEu(t),
    LEGAL: legal(t),
    LIFE: life(t),
    LIABILITY: liability(t),
    PENSION: pension(t),
    PET_HEALTH: petHealth(t),
    PRIVATE_HEALTH: privateHealth(t),
    PUBLIC_HEALTH: publicHealth(t),
    TRAVEL: travelHealth(t),
  };

  return additionalInfo?.[insuranceType] || null;
};
