import { BottomOrRegularModal } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { DeleteAccountModalProps } from 'models/account';
import { PublicHealthProvider } from 'models/insurances/providers';
import { Trans } from 'shared/i18n';

import styles from './style.module.scss';

const providerLinks: Record<PublicHealthProvider, string> = {
  TK: 'https://www.tk.de/service/app/2055914/contact/contact.app',
  AOK: 'https://en.zuwanderer.aok.de/contact-with-aok',
  BARMER: 'https://www.barmer.de/en/consultations',
  DAK: 'https://www.dak.de/dak/kontakt/contact-us-english-2258216.html#/',
};

export const ActivePublicPoliciesModal = ({
  t,
  showDeleteAccountModal,
  setShowDeleteAccountModal,
  providerId,
  deleteUserAccount,
  activeDeleteRequest,
}: DeleteAccountModalProps & { providerId: PublicHealthProvider }) => (
  <BottomOrRegularModal
    title={t(
      'account.deleteAccountModal.onlyActiveOrPendingPublicPolicies.title',
      'You have an active policy or application'
    )}
    isOpen={showDeleteAccountModal}
    onClose={() => setShowDeleteAccountModal(false)}
  >
    <div
      className={classNames('wmx6 pt16 px24 pb24', styles.deleteAccountModal)}
      data-testid="active-public-policies-delete-modal"
    >
      <p className="p-p">
        <Trans i18nKey="account.deleteAccountModal.onlyActiveOrPendingPublicPolicies.description.1">
          You currently have an active{' '}
          <span className="fw-bold">public health insurance</span> or a pending
          application.
        </Trans>
      </p>
      <p className="p-p mt24">
        <Trans i18nKey="account.deleteAccountModal.onlyActiveOrPendingPublicPolicies.description.2">
          To cancel either, you must{' '}
          <a
            href={providerLinks[providerId]}
            rel="noop noreferrer"
            target="_blank"
            className="p-a"
          >
            reach out to the insurance provider
          </a>{' '}
          directly. Requesting an account deletion will only remove your details
          from the Feather system, but will not cancel your coverage or
          application with the insurance provider.
        </Trans>
      </p>
      <div className={styles.buttonContainer}>
        <button
          type="button"
          className="p-btn--primary mt24"
          onClick={(e) => {
            e.preventDefault();
            deleteUserAccount();
            setShowDeleteAccountModal(false);
          }}
          disabled={activeDeleteRequest}
        >
          {activeDeleteRequest
            ? t(
                'account.deleteAccountModal.pendingAccountDeletionButton.caption',
                'Request already sent'
              )
            : t(
                'account.deleteAccountModal.requestAccountDeletionButton.caption',
                'Request account deletion'
              )}
        </button>
      </div>
    </div>
  </BottomOrRegularModal>
);
