import { createRecommendationPersona } from 'features/recommendationTool/actions';
import { Account } from 'features/recommendationTool/models/account';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useDispatch } from 'react-redux';

import ContinueWithAccountView from './ContinueWithAccount';

const ContinueWithAccountContainer = (props: CustomComponentProps<Account>) => {
  const { onSubmitValue } = props;
  const { loading, error } = useRequestStatus('RECOMMENDATION_TOOL');
  const dispatch = useDispatch();

  const handleContinueWithAccount = async (continueWithAccount: boolean) => {
    if (continueWithAccount) {
      /** Move customer to sign in question */
      onSubmitValue(continueWithAccount);
      return;
    }

    /** Else create a persona and move to questionnaire */
    await dispatch(createRecommendationPersona);
    onSubmitValue(continueWithAccount);
  };

  return (
    <ContinueWithAccountView
      onContinueWithAccount={handleContinueWithAccount}
      personaLoading={loading}
      personaError={error}
    />
  );
};

export default ContinueWithAccountContainer;
