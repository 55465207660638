import Session from '@getpopsure/session';
import { BottomOrRegularModal, Button, Input } from '@popsure/dirty-swan';
import { fetchAccountInfo } from 'actions/user';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getRequestInProcess } from 'selectors/requests';
import { getAccountInfo, getEmail } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';

import emailSvg from './assets/email.svg';
import styles from './EmailAddressCollectionModal.module.scss';

type OnSubmitArgs = {
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string | undefined>>;
  name?: string;
  email?: string;
};

export const EmailAddressCollectionModal = ({
  title,
  description,
  buttonCaption,
  showMailIcon,
  isOpen,
  onClose,
  onSubmit,
}: {
  title?: string;
  description?: string;
  buttonCaption?: string;
  showMailIcon?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: ({ setSubmitting, setError, name, email }: OnSubmitArgs) => void;
}) => {
  const { t } = useSafeTranslation();

  const accountRequest = (state: AppState) =>
    getRequestInProcess(state, 'ACCOUNT');
  const dispatch = useDispatch();

  const authenticated = Session.isAuthenticated;
  const accountInfo = useSelector(getAccountInfo);
  const [email, setEmail] = useState(useSelector(getEmail) ?? '');

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const loading = useSelector(accountRequest);
  const valid = email.length > 0;

  let name: string | undefined;
  if (accountInfo && accountInfo.firstName && accountInfo.lastName) {
    name = capitalizeName({
      firstName: accountInfo.firstName,
      lastName: accountInfo.lastName,
    });
  }

  useEffect(() => {
    if (isOpen && authenticated) {
      dispatch(fetchAccountInfo());
    }
  }, [dispatch, isOpen, authenticated]);

  return (
    <BottomOrRegularModal
      title={
        title ||
        t('components.emailAddressCollectionModal.title', 'Your email address')
      }
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setError('');
      }}
    >
      {showMailIcon && (
        <img
          className="mt24 ml-auto mr-auto d-block"
          src={emailSvg}
          alt="letter with a purple background"
        />
      )}
      <form
        className={`px24 pb24 ${styles.container}`}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit({ setSubmitting, setError, name, email });
        }}
      >
        {loading ? (
          <div className="ml-auto mr-auto mt48 mb48 ds-spinner ds-spinner__l" />
        ) : (
          <>
            <Input
              className="mt16"
              type="email"
              autoComplete="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p className="p-p--small mt8">
              {description ||
                t(
                  'components.emailAddressCollectionModal.description',
                  'We will be able to locate your quote during the call using this email address.'
                )}
            </p>
            {error && <div className="p-notice--danger mt24">{error}</div>}
            <Button
              className="mt16 w100"
              disabled={!valid}
              loading={submitting}
            >
              {buttonCaption ||
                t(
                  'components.emailAddressCollectionModal.buttonCaption',
                  'Continue'
                )}
            </Button>
          </>
        )}
      </form>
    </BottomOrRegularModal>
  );
};
