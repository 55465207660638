import { Address } from '@getpopsure/public-models';
import React from 'react';

export type StepType =
  | 'LETTER_OF_ACCEPTANCE'
  | 'PROVIDER_NOTIFIES_UNIVERSITY'
  | 'GERMAN_ADDRESS'
  | 'BANK_ACCOUNT'
  | 'PROOF_OF_ENROLLMENT'
  | 'SUBMIT_APPLICATION'
  | 'PROCESSING_APPLICATION';

export enum EStudentChecklistRequestTypes {
  UPLOAD_DOCUMENT = 'STUDENT_CHECKLIST_UPLOAD_DOCUMENT',

  UPDATE = 'STUDENT_CHECKLIST_UPDATE',
  COMPLETE = 'STUDENT_CHECKLIST_COMPLETE',
}

export type StudentChecklistRequestType = `${EStudentChecklistRequestTypes}`;

export interface StepProps {
  completed?: boolean;
  active?: boolean;
  submittedChecklist?: boolean;
}

export type Step = {
  id: StepType;
  number?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactElement<StepProps>;
} & StepProps;

export type StepWithStatus = Step & { active: boolean; completed: boolean };

export interface StudentChecklistAttribute {
  /** @deprecated use checklistAddress instead */
  hasAddress: boolean;
  /** @deprecated */
  hasDownloadedPreliminaryCoverage?: boolean;
  isCompleted?: boolean;
  iban: string | null;
  proofOfUniEnrollmentUrl?: string;
  /** @deprecated */
  proofOfHealthInsuranceCoverageUrl?: string;
  letterOfAcceptanceUrl: string | null;
  letterOfAcceptanceCreatedAt: string | null;
  questionnaireAddress: Address | null;
  address: Address | null;
}

export function isChecklistCompleted(checklist: StudentChecklistAttribute) {
  const { iban, proofOfUniEnrollmentUrl } = checklist;

  return (
    Boolean(iban) &&
    Boolean(proofOfUniEnrollmentUrl) &&
    Boolean(checklist.address) &&
    Boolean(checklist.letterOfAcceptanceUrl)
  );
}

export interface StudentChecklistRequest {
  iban?: string;
  proofOfUniEnrollmentId?: string;
  /** @deprecated */
  hasDownloadedPreliminaryCoverage?: boolean;
  isCompleted?: boolean;
  letterOfAcceptanceId?: string;
  address?: Address;
}

export interface StudentChecklistResponse {
  address: Address | null;
  hasDownloadedPreliminaryCoverage: boolean;
  iban: string | null;
  isCompleted: boolean;
  letterOfAcceptanceId: string | null;
  proofOfUniEnrollmentId: string | null;
  /** @deprecated */
  signedLetterForUniId: string | null;
}
