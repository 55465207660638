import { associatedPathForLiabilityRoutes } from 'constants/routes';
import { formatAnswers } from 'features/liability/utils';
import {
  getQuestionReviewLabelMapping,
  PageId,
  questionOrderWithAddress,
  questionOrderWithRelocationDate,
  reviewQuestions,
  skippablePageId,
} from 'models/liability';
import { QuestionReviewValue } from 'models/review';
import { AppState } from 'reducers';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import { TFunction } from 'shared/i18n';

export const getQuestionReviewValues =
  (t: TFunction) =>
  (state: AppState): QuestionReviewValue[] => {
    const questionnaire = getGenericQuestionnaire(state).liability;
    return reviewQuestions.map((id) => ({
      title: getQuestionReviewLabelMapping(t)[id] ?? null,
      value: questionnaire ? formatAnswers(t)(id, questionnaire[id]) : null,
      path: associatedPathForLiabilityRoutes[id] ?? null,
    }));
  };

export const getCurrentQuestionOrder = (state: AppState): PageId[] => {
  const currentlyLivingInGermany =
    getGenericQuestionnaire(state).liability?.currentlyLivingInGermany;
  let questionOrder: PageId[] = questionOrderWithAddress;

  if (currentlyLivingInGermany === false) {
    questionOrder = questionOrderWithRelocationDate;
  }

  return questionOrder;
};

export const getLastUnansweredQuestion = (
  state: AppState,
  pageId: PageId
): PageId | null => {
  const questionnaire = getGenericQuestionnaire(state).liability;
  const questionOrder: PageId[] = getCurrentQuestionOrder(state);
  const lastUnasweredQuestion = questionOrder.find((id, idx) => {
    const isCurrentPageAhead = questionOrder.indexOf(pageId) > idx;
    const isNotSkippable = !skippablePageId.includes(id);

    return (
      isCurrentPageAhead &&
      isNotSkippable &&
      (questionnaire?.[id] === undefined || questionnaire?.[id] === null)
    );
  });
  return lastUnasweredQuestion ?? null;
};
