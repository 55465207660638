import { TFunction } from '@getpopsure/i18n-react';
import {
  getRoutes,
  removeAnswers,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import { ClaimsAction } from 'constants/actions';
import routes from 'constants/routes';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { AppState } from 'reducers';
import { useSafeTranslation } from 'shared/i18n';
import {
  SignupQuestionnaire,
  SignupQuestionnaireType,
} from 'SignupQuestionnaire';

import { submitBookConsultation } from './actions';
import { BookConsultationSubmitted } from './components/BookConsultationSubmitted';
import { PhoneInput } from './components/PhoneInput';
import { expectedOutcome, LegalBookConsultation, timeDate } from './models';

export type ClaimGroupIds = 'questionnaire';

export const LegalClaimsComponents = {
  PHONE: PhoneInput,
  SUBMITTED: BookConsultationSubmitted,
} as const;

export type ClaimQuestionnaire = SignupQuestionnaireType<
  LegalBookConsultation,
  ClaimGroupIds,
  typeof LegalClaimsComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction
): ClaimQuestionnaire => [
  {
    id: 'expectedOutcome',
    required: true,
    type: 'RADIO',
    groupId: 'questionnaire',
    props: {
      mapValue: expectedOutcome(t),
    },
    screen: {
      question: t(
        'legal.qnr.claims.bookConsultation.expectedOutcome.title',
        'What is the consultation about?'
      ),
    },
  },
  {
    id: 'description',
    required: true,
    type: 'TEXT',
    props: {
      placeholder: t(
        'legal.qnr.claims.bookConsultation.description.placeholder',
        'E.g. my work contract was unjustly terminated after a dispute. I want to understand my options and potentially if I have a legal case.'
      ),
      maxLength: 10_000,
    },
    screen: {
      question: t(
        'legal.qnr.claims.bookConsultation.description.title',
        'Describe your legal matter'
      ),
      additionalInfo: {
        title: t(
          'legal.qnr.claims.bookConsultation.description.additionalInfo.title',
          'Describing your legal case'
        ),
        description: t(
          'legal.qnr.claims.bookConsultation.description.additionalInfo.description',
          'Help us understand your legal case, including what happened and who is involved.'
        ),
      },
    },
    groupId: 'questionnaire',
  },
  {
    id: 'phoneNumber',
    required: true,
    type: 'PHONE',
    groupId: 'questionnaire',
    props: {},
    screen: {
      question: t(
        'legal.qnr.claims.bookConsultation.phoneNumber.title',
        "What's your phone number?"
      ),
      description: 'An English-speaking lawyer will call you at this number.',
    },
  },
  {
    id: 'timeDate',
    required: true,
    type: 'RADIO',
    groupId: 'questionnaire',
    props: {
      mapValue: timeDate(t),
    },
    screen: {
      question: t(
        'legal.qnr.claims.bookConsultation.timeDate.title',
        'When do you prefer the call to happen?'
      ),
      additionalInfo: {
        title: t(
          'legal.qnr.claims.bookConsultation.timeDate.additionalInfo.title',
          'How would it work?'
        ),
        description: t(
          'legal.qnr.claims.bookConsultation.timeDate.additionalInfo.description',
          'An English-speaking lawyer will contact you within 3 business days, aiming to accommodate your preferred time slot.\n\nKindly note that there may be instances where the call takes place outside of the chosen time slot due to unforeseen circumstances.'
        ),
      },
    },
  },
  {
    id: 'processing',
    required: true,
    type: 'PROCESSING',
    props: {
      textList: [
        t(
          'legal.qnr.claims.bookConsultation.processing.listItem.title',
          'Processing request'
        ),
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'submitted',
    required: true,
    type: 'SUBMITTED',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
];

const rules: Rule<LegalBookConsultation>[] = [];

const removeProcessing: RemoveAnswerType<LegalBookConsultation> = {
  op: 'always',
  questions: ['processing', 'submitted'],
};

const removeAnswersLogic: Partial<
  Record<keyof LegalBookConsultation, RemoveAnswerType<LegalBookConsultation>>
> = {
  expectedOutcome: removeProcessing,
  description: removeProcessing,
  phoneNumber: removeProcessing,
  timeDate: removeProcessing,
};

export const storeLegalBookConsultationAnswers = (
  answer: Partial<LegalBookConsultation>
): ClaimsAction => ({
  type: 'STORE_LEGAL_BOOK_CONSULTATION',
  legalBookConsultation: answer,
});

export const flushLegalBookConsultationAnswers = (): ClaimsAction => ({
  type: 'FLUSH_LEGAL_BOOK_CONSULTATION',
});

export const BookConsultation = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.claims.legalBookConsultation) || {};
  const { policyId } = useParams<{ policyId: string }>();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const { t } = useSafeTranslation();

  const flushAnswers = useCallback(() => {
    dispatch(flushLegalBookConsultationAnswers());
  }, [dispatch]);

  useEffect(() => {
    return flushAnswers;
  }, [policyId, dispatch, flushAnswers]);

  const onAnswer = <QuestionId extends keyof LegalBookConsultation>(
    questionId: QuestionId,
    answer: LegalBookConsultation[QuestionId]
  ) => {
    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    const nextState = removeAnswers(
      {
        ...questionnaireAnswers,
        [questionId]: answer,
      },
      answersToRemove
    );

    dispatch(storeLegalBookConsultationAnswers(nextState));

    if (
      questionId === 'timeDate' &&
      answer &&
      !questionnaireAnswers.submitted
    ) {
      dispatch(submitBookConsultation(policyId, nextState));
    }
  };

  const questions = getTranslatedQuestionnaire(t);

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, url),
    rules,
  };

  return (
    <Switch>
      <Route path={routes.claims.legal.bookConsultation.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: LegalClaimsComponents,
          }}
          basePath={url}
          questionId="expectedOutcome"
          featureName="LegalClaimSubmitClaim"
        />
      </Route>
    </Switch>
  );
};
