import { Reducer } from 'redux';

import { CheckoutDocumentsActions } from './checkoutDocuments.actions';
import { CheckoutDocument } from './models';

interface CheckoutDocumentsState {
  items?: { [id: string]: CheckoutDocument[] };
}

export const defaultState: CheckoutDocumentsState = {};

const checkoutDocuments: Reducer<
  CheckoutDocumentsState,
  CheckoutDocumentsActions
> = (state = defaultState, action) => {
  switch (action.type) {
    case 'STORE_CHECKOUT_DOCUMENTS':
      return {
        ...state,
        items: { ...state.items, [action.questionnaireId]: action.documents },
      };
    default:
      return state;
  }
};

export default checkoutDocuments;
