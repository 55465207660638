import {
  centimetersToFeet,
  feetToCentimeters,
} from '@getpopsure/public-utility';
import { Input, SegmentedControl } from '@popsure/dirty-swan';
import QuestionnaireWrapper from 'components/questionnaireForms/QuestionnaireWrapper';
import { HeightMetric } from 'models/metric';
import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { ChangeEvent, FormEvent, useState } from 'react';

const metricValues: HeightMetric[] = ['cm', 'feet'];

interface Height {
  cm: number;
  feet: number;
  inches: number;
}

interface Props
  extends QuestionnaireFormProps<number>,
    QuestionnaireWrapperProps {
  validate?: (value: number) => boolean;
  placeholderPerUnit?: { cm: string; feet: string; inches: string };
}

/**
 *
 * This HeightInputForm component is a 1:1 copy of the life flow height component logic.
 * It uses the common QuestionnaireWrapper to align the styling with the other questionaire components.
 * We can currently not share the component between disability and life as their interfaces differ.
 * To streamline the implementations in the future (once the life flow is also implemented
 * using the Questionaire form) we can use this component for any height input.
 */
export const HeightInputForm = ({
  value,
  onSubmitValue,
  question,
  header,
  description,
  loading,
  error,
  buttonText,
  validate,
  placeholderPerUnit,
}: Props) => {
  const [metric, setMetric] = useState<HeightMetric>('cm');
  const [height, setHeight] = useState<Partial<Height> | undefined>({
    cm: value,
    ...centimetersToFeet(value ?? 0),
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!height || !height.cm) {
      throw new Error('[Disability] Height value is missing for submission');
    }
    onSubmitValue(height.cm);
  };

  const updateCm = (e: ChangeEvent<HTMLInputElement>) => {
    const cm = Number(e.target.value);
    setHeight({ cm, ...centimetersToFeet(cm) });
  };

  const updateFeet = (e: ChangeEvent<HTMLInputElement>) => {
    const feet = Number(e.target.value);
    if (height?.inches) {
      const totalFeet = feet + Number((height.inches / 12).toFixed(2));
      setHeight({ ...height, feet, cm: feetToCentimeters(totalFeet) });
    } else {
      setHeight({ ...height, feet, cm: feetToCentimeters(feet) });
    }
  };

  const updateInches = (e: ChangeEvent<HTMLInputElement>) => {
    const inches = Number(e.target.value);
    const totalFeet = height?.feet
      ? height.feet + Number((inches / 12).toFixed(2))
      : inches;
    const cm = feetToCentimeters(totalFeet);
    setHeight({ cm, ...centimetersToFeet(cm) });
  };

  const isValid =
    height && height.cm && (validate ? validate(height.cm) : true);

  return (
    <QuestionnaireWrapper
      header={header}
      question={question}
      description={description}
      onSubmit={handleSubmit}
      validForSubmission={!!isValid}
      buttonText={buttonText}
      error={error}
      loading={loading}
    >
      <div className="d-flex gap8 w100">
        {metric === 'cm' ? (
          <Input
            className="w100 wmx4"
            placeholder="Centimeters"
            onChange={updateCm}
            type="number"
            value={String(height?.cm)}
            pattern="[0-9]*"
            data-cy="input"
          />
        ) : (
          <>
            <Input
              className="w100 wmx2 mr8"
              placeholder={placeholderPerUnit?.feet}
              onChange={updateFeet}
              type="number"
              value={String(height?.feet)}
              pattern="[0-9]*"
            />
            <Input
              className="w100 wmx2"
              placeholder={placeholderPerUnit?.inches}
              onChange={updateInches}
              type="number"
              value={String(height?.inches)}
              pattern="[0-9]*"
            />
          </>
        )}
        <SegmentedControl
          onChange={(nextValue) => setMetric(metricValues[nextValue])}
          values={metricValues}
          selectedIndex={metricValues.indexOf(metric)}
        />
      </div>
    </QuestionnaireWrapper>
  );
};
