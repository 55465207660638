import { BottomOrRegularModal, DateSelector } from '@popsure/dirty-swan';
import InfoBox from 'components/infoBox';
import dayjs from 'dayjs';
import {
  ExpatDependentQuestionnaire,
  ExpatQuestionnaire,
} from 'features/expat/models';
import { isMoveDateExceedingWaitingPeriod } from 'features/expat/utils';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

import styles from './StartDate.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

type ErrorType = 'warning' | 'danger';

export const StartDate = ({
  value,
  onSubmitValue,
  questionnaireAnswers,
  question,
}: CustomComponentProps<ExpatQuestionnaire | ExpatDependentQuestionnaire>) => {
  const [startDate, setStartDate] = useState<string>((value as string) ?? '');
  const [error, setErrorMsg] = useState<
    { type: ErrorType; message: string } | undefined
  >();
  const [modalOpen, setModalOpen] = useState(false);

  const { t } = useSafeTranslation();

  const validateStartDate = (startDateInput: string) => {
    if (dayjs().isAfter(startDateInput)) {
      setErrorMsg({
        message: t(
          'expat.qnr.startDate.error.dateInThePast',
          'Oops, looks like that date is in the past.'
        ),
        type: 'danger',
      });
    } else if (
      dayjs(startDateInput).diff(questionnaireAnswers.arrivalDate, 'days') < 0
    ) {
      setErrorMsg({
        message: t('expat.qnr.startDate.error.beforeArrivalDate', {
          defaultValue: `Coverage start date should be after your arrival date in Germany ({{date}})`,
          date: dayjs(questionnaireAnswers.arrivalDate).format(DATE_FORMAT),
        }),
        type: 'warning',
      });
    } else if (dayjs().isSame(startDateInput, 'day')) {
      setErrorMsg({
        message: t(
          'expat.qnr.startDate.error.earliestStartDate',
          'The earliest start date is tomorrow.'
        ),
        type: 'danger',
      });
    } else if (
      dayjs().add(1, 'year').subtract(1, 'days').isBefore(startDateInput, 'day')
    ) {
      setErrorMsg({
        message: t(
          'expat.qnr.startDate.error.maxFutureStartDate',
          'Coverage start date should not be more than 1 year in the future. Please adjust the start date.'
        ),
        type: 'warning',
      });
    } else {
      onSubmitValue(startDate);
    }
  };

  const showInfoBox = isMoveDateExceedingWaitingPeriod(
    startDate,
    questionnaireAnswers.arrivalDate
  );

  return (
    <>
      <BottomOrRegularModal
        title={t('expat.qnr.startDate.modal.title', 'Important waiting period')}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <div className={`p-p mt16 ${styles.modal}`}>
          {t(
            'expat.qnr.startDate.modal.description',
            'You must wait 31 days after the cover starts to use the benefits. This does not apply to accidents or medical interventions made to avert an acute danger to your life. The waiting period is waived if you had a comparable insurance up to the start date of this insurance.'
          )}
        </div>
      </BottomOrRegularModal>
      <div className={styles.container}>
        <div className="p-body">
          <h1 className="p-h1">{question}</h1>
          <div className={styles['content-container']}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!startDate) {
                  throw new Error('[Expat] Start date is required to proceed.');
                }
                validateStartDate(startDate);
              }}
            >
              <div className="wmx5 mt8">
                <DateSelector
                  value={startDate}
                  yearBoundaries={{
                    min: new Date().getFullYear(),
                    max: new Date().getFullYear() + 1,
                  }}
                  onChange={(date) => setStartDate(date)}
                  displayCalendar
                />
              </div>
              <AnimateHeight
                duration={300}
                height={error !== undefined ? 'auto' : 0}
              >
                <div className={`p-notice p-notice--${error?.type} mt16 wmx5`}>
                  {error?.message}
                </div>
              </AnimateHeight>
              <button
                className={`p-btn p-btn--primary mt24 wmn3 mb16 ${styles.button}`}
                data-cy="button-continue"
                type="submit"
              >
                Continue
              </button>
            </form>
            {showInfoBox === true && (
              <div className="mt8">
                <InfoBox
                  title={t(
                    'expat.qnr.startDate.info.title',
                    'Important waiting period'
                  )}
                >
                  {' '}
                  <div className="p-p--small">
                    {t(
                      'expat.qnr.startDate.info.description',
                      'You must wait 31 days after the cover starts to access most benefits.'
                    )}{' '}
                    <button
                      className="ds-interactive-component p-a c-pointer"
                      onClick={() => setModalOpen(true)}
                      type="button"
                    >
                      {t('expat.qnr.startDate.info.link', 'Learn more')}
                    </button>
                  </div>
                </InfoBox>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
