import { Button } from '@popsure/dirty-swan';
import feathersSvg from 'features/recommendationTool/assets/icons/feathers.svg';

import styles from './RecommendationErrorPage.module.scss';

interface RecommendationErrorPageProps {
  title: string;
  description: string;
  onCtaClick?: () => void;
}

export const RecommendationErrorPage = (
  props: RecommendationErrorPageProps
) => {
  const { title, description, onCtaClick } = props;
  return (
    <div
      className={`p-body d-flex ai-center jc-center ${styles.container}`}
      data-testid="recommendation-error-page"
    >
      <div className="d-flex fd-column ai-center jc-center wmx6">
        <img className="mb32" src={feathersSvg} alt="feathers" />
        <h2 className="p-h2 tc-grey-900 mb8">{title}</h2>
        <p className="p-p tc-grey-600 mb40 ta-center">{description}</p>
        {onCtaClick && (
          <Button onClick={onCtaClick}>Restart questionnaire</Button>
        )}
      </div>
    </div>
  );
};
