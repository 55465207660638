import { insurance } from '@getpopsure/private-constants';
import routes from 'constants/routes';
import {
  bannerColorMapping,
  imageSrcSetTypeMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';

import { CardText, CategoryLookup, Section } from '../models/lookups';
import { typeformLinkWithVertical } from './shared';

export const legalCategory: CategoryLookup = {
  shortTitle: 'Legal',
  title: 'Legal insurance',
  excerpt: 'Protection for your legal rights',
  description:
    'Legal insurance offers protection in five areas: disorderly conduct, automotive, professional, private life, and real estate disputes. This provides you with comprehensive legal coverage for most situations from disturbing the neighbors with loud music (disorderly conduct), getting into a car accident (automotive), disputing an unlawful termination (professional), identity theft (private life), and illegal increases in rent (real estate).',
  icon: imageTypeMapping.LEGAL,
  iconSrcSet: imageSrcSetTypeMapping.LEGAL,
  bannerColor: bannerColorMapping.LEGAL,
  landingPage: insurance.legal.landingPage,
  quotePage: routes.policies.legal.path,
  enabled: true,
};

export const legalSection: Section = {
  title: '',
  description:
    'This insurance is best for people who aren’t familiar with the German legal system and need support for unjust accusations or unfair behavior towards you that can lead to a court case.',
  icon: '',
  status: {
    ELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.legal.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('legal'),
      },
    },
    PARTIAL: {
      cta: {
        caption: 'Learn more',
        href: insurance.legal.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('legal'),
      },
    },
    MORE_INFO_REQUIRED: {
      cta: {
        caption: 'Learn more',
        href: insurance.legal.landingPage,
      },
    },
    INELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.legal.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('legal'),
      },
    },
  },
};

export const legalCardText: CardText = {
  LEGAL_PAYS_FOR_LEGAL_ACTION_PRO: {
    title: 'Pays for legal action',
    description:
      "Even if you're familiar with the German legal system, legal insurance will cover the costs for your lawyer and any additional advisory meetings you have with your lawyer.",
  },
  LEGAL_PROTECTION_AS_TENANT_OR_OWNER_PRO: {
    title: 'Protection as a tenant or homeowner',
    description:
      'Legal insurance will cover disputes that arise as a tenant including conflicts about the rental agreement, rent increases, or claims arising from renting an apartment. It will also cover anyone who had a dispute with neighbours or legal problems with authorities (i.e. unlawful noise).',
  },
  LEGAL_COVERS_EMPLOYER_DISPUTES_PRO: {
    title: 'Covers employer disputes',
    description:
      "You're protected against unlawful actions from your employer. That includes things like unjust termination, a ban on vacation, no right for sick leave, working additional unpaid hours against your contract, etc.",
  },
  LEGAL_FAMILY_MEMBERS_ADDED_PRO: {
    title: 'Family members can be added',
    description:
      'You can also add your spouse for about €5 per month or your parents if they are over 65, unemployed, and living with you. Unmarried children are automatically included in your legal contract.',
  },
  LEGAL_PRIVATE_MATTERS_COVERED_PRO: {
    title: 'Most private matters are covered',
    description:
      "You're covered as a private individual for any cases filed against you. This includes contracts signed during the time you had legal insurance that were not followed, getting reimbursement for canceled holidays, and other personal legal matters like finding witnesses, expert opinions, etc.",
  },
  LEGAL_PROTECTION_AS_PRIVATE_DRIVER_PRO: {
    title: 'Protection as a private driver',
    description:
      "You're covered if you're driving within Germany (just not if it's your job). Some examples of legal protection as a driver includes deciding who is at fault for an accident, unjustified fines or accusations, and hit-and-run cases where you're the victim.",
  },
  LEGAL_PICK_OWN_LAWYER_PRO: {
    title: 'Pick your own lawyer',
    description:
      "You're free to choose your own lawyer or use our network to find an English-speaking lawyer who specializes in your area of law.",
  },
  LEGAL_COMFORTABLE_WITH_GERMAN_LEGAL_SYSTEM_CON: {
    title: "You're comfortable with the German legal system",
    description:
      "If you're a lawyer yourself or are married to a lawyer who would represent you, you might not want legal insurance. We do, however, advise you to speak with them since German law tends to have specializations like Schufa law vs. marital law that they might not be familiar with.",
  },
  LEGAL_BE_AWARE_OF_EXCLUSIONS_CON: {
    title: 'Be aware of the exclusions',
    description:
      "There are quite a few specific things not covered by legal insurance, but the general rule of thumb is that if you knew while doing something that you'll need legal protection for in the future. Examples of this include ongoing legal matters and  immigration law.",
  },
  LEGAL_WAITING_PERIODS_APPLY_CON: {
    title: 'Waiting periods apply',
    description:
      "Depending on which claim you're making, there are several waiting periods that apply. For example, if you're in financial trouble because you filed your taxes wrong for 2021, but you got insurance in 2022, you won't be covered since the insured event happened before your insurance policy began.",
  },
  LEGAL_ISSUE_IN_PROGRESS_NOT_COVERED_CON: {
    title: 'Does not cover issues in progress',
    description:
      "Any ongoing or anticipated legal issues won't be covered by our plan. This is the case with almost all insurance policies in Germany including dental, liability, household, occupational disability, expat, etc.",
  },
  LEGAL_ENTITLED_TO_GOVERNMENT_ADVICE_CON: {
    title: 'You might already be entitled to legal advice from the government',
    description:
      "If you're able to apply for financial support through the government for your legal case, then the government will cover you before your legal insurance does. To find out if you are eligible for financial support, you'll need to see specific organizations for legal matters. You can google the following in these areas to find more information: - Mieterverein - Arbeitnehmerhilfe - Rechtsberatung",
  },
};
