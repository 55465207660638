import type { CollarClassification } from '../models';
import { jobsList } from './professionList';

export const distinctProfessions = [
  ...new Set(jobsList.map((occupation) => occupation.Occupation)),
];

export const getProfessionOptions = distinctProfessions.map((job) => ({
  value: job,
  label: job,
}));

export const getJobCollar = (profession: string): CollarClassification => {
  const jobCollar = jobsList.find(
    (occupation) => occupation.Occupation === profession
  )?.['Feather RC'] as CollarClassification | undefined;

  if (!jobCollar) {
    throw new Error(`Job collar not found for ${profession}`);
  }
  return jobCollar;
};
