import { TFunction } from '@getpopsure/i18n-react';
import { calendly, insurance, website } from '@getpopsure/private-constants';
import { Blocker } from 'components/Blocker';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import {
  Disability,
  DisabilityQuestionnaire,
} from 'features/disability/models';
import { isUnder18 } from 'shared/util/isUnder18';

import { HeightInputForm } from '../../components/Height';
import { questionPropLookup } from '../lookups';

const centimetersToFeet = (cm: number) => cm / 30.48;

const kgToLbs = (kg: number) => kg * 2.20462;
const lbsToKg = (lbs: number) => lbs / 2.20462;

const isNumber = (value: unknown): value is number => typeof value === 'number';

export const preQuoteQNR = (t: TFunction): DisabilityQuestionnaire => ({
  dateOfBirth: {
    required: true,
    type: 'DATE',
    props: {
      ...questionPropLookup(t).dateOfBirth,
      yearRange: {
        min: dayjs().subtract(100, 'years').year(),
        max: dayjs().subtract(18, 'years').year(),
      },
    },
    retrieveAnswerObject: (
      dateOfBirth: Disability['dateOfBirth']
    ): Partial<Disability> => ({
      dateOfBirth,
    }),
    groupId: 'signup',
    retrieveNextPageId: (answer: string) =>
      isUnder18(answer) ? 'ageBlocker' : 'occupation',
  },
  ageBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t('disability.qnr.ageBlocker.title', 'Unfortunately...'),
        description: t('disability.qnr.ageBlocker.description.1', {
          defaultValue:
            'You need to be at least 18 years old to purchase disability insurance with us.\n\nUntil then, **we might have some other options for you**. You can reach out to one of our insurance experts or try our [digital recommendation tool]({{url}}) to see what types of policies may fit your lifestyle.',
          url: routes.insuranceTool.path,
        }),
        buttonProps: [
          {
            type: 'href',
            href: website.support,
            caption: t(
              'disability.qnr.ageBlocker.speakToExpert.caption',
              'Speak to an expert'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'signup',
  },
  occupation: {
    required: true,
    type: 'RADIO',
    props: {
      mapValue: {},
      ...questionPropLookup(t).occupation,
    },
    retrieveAnswerObject: (
      occupation: Disability['occupation']
    ): Partial<Disability> => ({
      occupation,
    }),
    groupId: 'signup',
    retrieveNextPageId: (answer: Disability['occupation']) => {
      switch (answer) {
        case 'UNEMPLOYED':
          return 'unemployedBlocker';
        case 'STUDENT':
          return 'studentBlocker';
        default:
          return 'monthlyNetIncome';
      }
    },
  },
  studentBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t('disability.qnr.studentBlocker.title', 'Unfortunately...'),
        description: t(
          'disability.qnr.studentBlocker.description',
          "It looks like you might not be eligible for disability insurance at the moment.\n\nStill, depending on the coverage you're looking for, we might have an option for you. You can speak to one of our insurance experts by scheduling a call."
        ),
        buttonProps: [
          {
            type: 'calendly',
            trackingCategory: 'DISABILITY_INSURANCE',
            caption: t(
              'disability.qnr.studentBlocker.bookACallButton.caption',
              'Schedule a call'
            ),
            calendlyLink: `${calendly.base}/occupational-disability`,
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'signup',
  },
  unemployedBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t('disability.qnr.unemployedBlocker.title', 'Unfortunately...'),
        description: t(
          'disability.qnr.unemployedBlocker.description',
          "We aren't able to accept your application for disability insurance at the moment.\n\nStill, depending on the coverage you're looking for, we might have another option for you. You can reach out to one of our insurance experts."
        ),
        buttonProps: [
          {
            type: 'href',
            href: website.support,
            caption: t(
              'disability.qnr.unemployedBlocker.contactUsButton.caption',
              'Contact us'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'signup',
  },
  monthlyNetIncome: {
    required: true,
    type: 'CURRENCY',
    props: {
      ...questionPropLookup(t).monthlyNetIncome,
    },
    retrieveAnswerObject: (
      monthlyNetIncome: Disability['monthlyNetIncome']
    ): Partial<Disability> => ({
      monthlyNetIncome,
    }),
    groupId: 'signup',
    retrieveNextPageId: (answer: Disability['monthlyNetIncome']) =>
      answer >= insurance.healthInsurance.miniJobThreshold
        ? 'officeWork'
        : 'monthlyNetIncomeBlocker',
  },
  monthlyNetIncomeBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'disability.qnr.monthlyNetIncomeBlocker.title',
          'Unfortunately...'
        ),
        description: t(
          'disability.qnr.monthlyNetIncomeBlocker.description',
          "We aren't able to accept your application for disability insurance at the moment.\n\nStill, depending on the coverage you're looking for, we might have another option for you. You can reach out to one of our insurance experts."
        ),
        buttonProps: [
          {
            type: 'href',
            href: website.support,
            caption: t(
              'disability.qnr.monthlyNetIncomeBlocker.contactUs.caption',
              'Contact us'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'signup',
  },
  officeWork: {
    required: true,
    type: 'BOOLEAN',
    props: {
      ...questionPropLookup(t).officeWork,
    },
    retrieveAnswerObject: (
      officeWork: Disability['officeWork']
    ): Partial<Disability> => ({
      officeWork,
    }),
    groupId: 'signup',
    retrieveNextPageId: () => 'degree',
  },
  degree: {
    required: true,
    type: 'RADIO',
    props: {
      mapValue: {},
      ...questionPropLookup(t).degree,
    },
    retrieveAnswerObject: (
      degree: Disability['degree']
    ): Partial<Disability> => ({
      degree,
    }),
    groupId: 'signup',
    retrieveNextPageId: () => 'height',
  },
  height: {
    required: true,
    type: 'CUSTOM',
    component: (props) =>
      HeightInputForm({
        ...questionPropLookup(t).height,
        ...props,
        value: isNumber(props.value) ? props.value : undefined,
        placeholderPerUnit: {
          cm: t('disability.qnr.height.unit.cm', 'cm'),
          feet: t('disability.qnr.height.unit.feet', 'feet'),
          inches: t('disability.qnr.height.unit.inches', 'inches'),
        },
      }),
    retrieveAnswerObject: (value: number): Partial<Disability> => ({
      height: {
        inCm: value,
        inFt: centimetersToFeet(value),
      },
    }),
    transformValue: (height?: Disability['height']) => height?.inCm,
    groupId: 'signup',
    retrieveNextPageId: () => 'weight',
  },
  weight: {
    required: true,
    type: 'NUMERIC_CONVERSION',
    props: {
      ...questionPropLookup(t).weight,
      unitLabels: [
        t('disability.qnr.weight.unit.kg', 'kg'),
        t('disability.qnr.weight.unit.lbs', 'lbs'),
      ],
      conversionFunctions: [kgToLbs, lbsToKg],
    },
    retrieveAnswerObject: ([kg, lbs]: [
      number,
      number
    ]): Partial<Disability> => ({
      weight: {
        inKg: kg,
        inLbs: lbs,
      },
    }),
    transformValue: (weight?: Disability['weight']) => [
      weight?.inKg,
      weight?.inLbs,
    ],
    groupId: 'signup',
    retrieveNextPageId: () => 'monthlyPayout',
  },
});
