import classnames from 'classnames';
import Navigation from 'components/common/navigation';
import routes from 'constants/routes';
import type { ViewProps } from 'features/claims/containers/legalClaim/describeClaim';
import AnimateHeight from 'react-animate-height';
import { generatePath } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

const situationQuestion =
  'Please describe the situation. What happened and when did it occur?';
const peopleInvolvedQuestion =
  'Please tell us who is involved in the situation.';
const wantedOutcomeQuestion = 'What is the outcome that you are looking for?';

const DescribeClaim = ({
  policyInfo: { policyId },
  loading,
  error,
  detailAnswers,
  submitClaimInfo,
  storeClaimInfo,
  phoneNumber,
  history,
}: ViewProps) => {
  const { t } = useSafeTranslation();

  const confirmInfoPath = generatePath(
    routes.me.claims.legalClaim.confirmInfo.path,
    {
      policyId,
    }
  );

  if (phoneNumber === undefined) {
    history.push(confirmInfoPath);
  }

  const submittable =
    detailAnswers?.situation &&
    detailAnswers?.peopleInvolved &&
    detailAnswers?.wantedOutcome;

  return (
    <div className={`wmx11 w100 ${styles.container}`}>
      <div className="p-body">
        <Navigation
          previousScreen={t(
            'claims.legal.previousScreen.confirmInformation',
            'Confirm information'
          )}
          previousUrl={confirmInfoPath}
        />
        <form
          className="wmx11"
          onSubmit={(e) => {
            e.preventDefault();

            if (
              !detailAnswers?.situation ||
              !detailAnswers?.peopleInvolved ||
              !detailAnswers?.wantedOutcome
            ) {
              throw new Error(
                '[Legal] Claim detail answers are required to submit claim.'
              );
            }

            const situationAnswer = `${situationQuestion}:\n${detailAnswers?.situation}`;
            const peopleInvolvedAnswer = `${peopleInvolvedQuestion}:\n${detailAnswers?.peopleInvolved}`;
            const wantedOutcomeAnswer = `${wantedOutcomeQuestion}:\n${detailAnswers?.wantedOutcome}`;
            const answer = `${situationAnswer}\n\n${peopleInvolvedAnswer}\n\n${wantedOutcomeAnswer}`;

            submitClaimInfo({
              userPolicyId: policyId,
              contacts: phoneNumber,
              details: answer,
            });
          }}
        >
          <div className={styles['header-container']}>
            <div className="p-p tc-primary-500 mt40">
              {t('claims.legal.step.2', 'Step 2')}
            </div>
            <h1 className="p-h2 mt8">
              {t('claims.legal.describeClaim.title', 'Describe your claim')}
            </h1>
            <div className="p-p mt8">
              {t(
                'claims.legal.describeClaim.description',
                'Please answer the following three questions as clearly as possible:'
              )}
            </div>
          </div>
          <div className={styles['form-container']}>
            <div
              className={`mt16 wmx7 ${styles['description-input-container']}`}
            >
              <div className="p-h4">
                {t(
                  'claims.legal.describeClaim.situationQuestion',
                  'Please describe the situation. What happened and when did it occur?'
                )}
              </div>
              <textarea
                value={detailAnswers?.situation}
                className={`mt16 p-input wmx5 wmn3 ${styles.description}`}
                placeholder={t(
                  'claims.legal.describeClaim.situationQuestion.placeholder',
                  'Reason for contacting...'
                )}
                onChange={(e) =>
                  storeClaimInfo({
                    detail: { ...detailAnswers, situation: e.target.value },
                  })
                }
                required={true}
              />
            </div>
            <div
              className={`mt8 wmx7 ${styles['description-input-container']}`}
            >
              <div className="p-h4">
                {t(
                  'claims.legal.describeClaim.peopleInvolvedQuestion',
                  'Please tell us who is involved in the situation.'
                )}
              </div>
              <textarea
                className={`mt16 p-input wmx5 wmn3 ${styles.description}`}
                placeholder={t(
                  'claims.legal.describeClaim.peopleInvolvedQuestion.placeholder',
                  'The people involved are...'
                )}
                value={detailAnswers?.peopleInvolved}
                onChange={(e) =>
                  storeClaimInfo({
                    detail: {
                      ...detailAnswers,
                      peopleInvolved: e.target.value,
                    },
                  })
                }
                required={true}
              />
            </div>
            <div
              className={`mt8 wmx7 ${styles['description-input-container']}`}
            >
              <div className="p-h4">
                {t(
                  'claims.legal.describeClaim.wantedOutcomeQuestion',
                  'What is the outcome that you are looking for?'
                )}
              </div>
              <textarea
                className={`mt16 p-input wmx5 wmn3 ${styles.description}`}
                placeholder={t(
                  'claims.legal.describeClaim.wantedOutcomeQuestion.placeholder',
                  'The outcome I’m looking for is...'
                )}
                value={detailAnswers?.wantedOutcome}
                onChange={(e) =>
                  storeClaimInfo({
                    detail: { ...detailAnswers, wantedOutcome: e.target.value },
                  })
                }
                required={true}
              />
            </div>
            <AnimateHeight duration={300} height={error ? 'auto' : 0}>
              <div className="p-notice--danger mt16 wmx4">{error}</div>
            </AnimateHeight>
            <button
              className={classnames(
                `p-btn--primary mt32 wmn3 ${styles['submit-button']}`,
                {
                  'p-btn--loading': loading,
                }
              )}
              disabled={!submittable}
              type="submit"
            >
              {t(
                'claims.legal.describeClaim.submitClaimButton.caption',
                'Submit claim'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DescribeClaim;
