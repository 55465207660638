import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Cell from 'features/checkout/components/Cell';
import { ReferrerCodeVerify } from 'features/referralEngine';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { useSafeTranslation } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';
import { capitalizeSingleWord } from 'shared/util/capitalizeSingleWord';

import styles from './PolicyDetails.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

export const PolicyDetails = () => {
  const { t } = useSafeTranslation();

  const questionnaire = useSelector((state: AppState) => {
    return state.genericQuestionnaire.dentalV2;
  });

  if (!questionnaire?.checkoutInfo) {
    return <></>;
  }

  const { startDate, policyDetails } = questionnaire.checkoutInfo.mainPolicy;

  return (
    <div className={classNames('p24 bg-white br8', styles.container)}>
      <div className="p-h2 mb16">
        {t('dental.qnr.checkout.title', 'Policy details')}
      </div>
      {policyDetails.legalGuardianName && (
        <>
          <div className="p-h3">
            {t(
              'dental.qnr.checkout.legalGuardianTitle',
              'Parent / legal guardian'
            )}
          </div>
          <Cell
            title={t('dental.qnr.checkout.legalGuardianName', 'Name')}
            value={capitalizeName(policyDetails.legalGuardianName)}
          />
          <Cell
            title={t(
              'dental.qnr.checkout.legalGuardianDateOfBirth',
              'Date of birth'
            )}
            value={dayjs(policyDetails.legalGuardianDateOfBirth).format(
              DATE_FORMAT
            )}
          />
          <div className={`bg-grey-200 mt16 mb16 ${styles.divider}`} />
        </>
      )}
      {policyDetails.planId && (
        <Cell
          title={t('dental.qnr.checkout.plan', 'Plan')}
          value={capitalizeSingleWord(policyDetails.planId)}
        />
      )}
      {policyDetails.toothReplacementCoverage &&
        policyDetails.planId !== 'BASIC' && (
          <Cell
            title={t(
              'dental.qnr.checkout.provider',
              'Tooth replacement coverage'
            )}
            value={policyDetails.toothReplacementCoverage}
          />
        )}
      {startDate && (
        <Cell
          title={t(
            'dental.qnr.checkout.policydetails.requestedStartDate',
            'Requested start date'
          )}
          value={dayjs(startDate).format(DATE_FORMAT)}
          additionalInfo={{
            title: t(
              'dental.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
              'Requested start date'
            ),
            description: t(
              'dental.qnr.checkout.policydetails.requestedStartDate.additionalInfo.description',
              'Your coverage should begin on {{coverageDate}}, but only after you have received the official confirmation.',
              {
                coverageDate: dayjs(startDate).format(DATE_FORMAT),
              }
            ),
          }}
        />
      )}
      <div className={`bg-grey-200 mt16 mb16 ${styles.divider}`} />
      {questionnaire.checkoutInfo?.totalMonthlyPrice && (
        <div className="d-flex ai-start">
          <div className="p-h3">
            {t('dental.qnr.checkout.total', 'Your monthly total')}
          </div>
          <div className="p-h3 ml-auto">
            {englishFormattedEuroCurrency(
              questionnaire.checkoutInfo.totalMonthlyPrice
            )}
          </div>
        </div>
      )}
      <ReferrerCodeVerify />
    </div>
  );
};
