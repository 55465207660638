import { BottomOrRegularModal } from '@popsure/dirty-swan';
import classnames from 'classnames';
import InfoBox from 'components/infoBox';
import { QuestionnaireWrapperProps } from 'models/questionnaire';
import { FormEvent, ReactElement, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Link } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import QuestionnaireMarkdown from '../QuestionnaireMarkdown';
import styles from './QuestionnaireWrapper.module.scss';

interface Props extends QuestionnaireWrapperProps {
  onSubmit: (e: FormEvent) => void;
  children: ReactElement | ReactElement[];
  validForSubmission: boolean;
  noMaxWidth?: boolean;
}

export const QuestionnaireWrapper = ({
  icon,
  header,
  question,
  questionClassName = '',
  description,
  onSubmit,
  children,
  error = '',
  loading = false,
  validForSubmission,
  buttonText,
  skipDetails,
  additionalInfo,
  noMaxWidth = false,
  secondaryAction,
}: Props) => {
  const { t } = useSafeTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <form
        ref={formRef}
        className={`mt80 p-body ai-center d-flex ${styles.container}`}
        onSubmit={onSubmit}
      >
        <div className="d-block w100">
          {icon && <img src={icon.src} alt={icon.alt} />}
          {header && <div className="p-h4 tc-primary-500 mb8">{header}</div>}
          <h1 className={`p-h1 ${questionClassName}`}>
            <QuestionnaireMarkdown>{question}</QuestionnaireMarkdown>
          </h1>
          {description && (
            <p className="p-p mt8">
              <QuestionnaireMarkdown>{description}</QuestionnaireMarkdown>
            </p>
          )}
          <div
            className={`mt16 ${
              additionalInfo
                ? `d-flex jc-between gap48 ${styles.additionalInfoWrapper}`
                : ''
            }`}
          >
            <div className={noMaxWidth ? '' : styles.contentInnerContainer}>
              {children}
              {secondaryAction}
              <AnimateHeight duration={300} height={error ? 'auto' : 0}>
                <div className="p-notice p-notice--warning mt24 p-p wmx6">
                  {error}
                </div>
              </AnimateHeight>
              <button
                className={classnames(
                  `p-btn--primary wmx5 wmn3 mt24 mr16 mb8 ${styles.button}`,
                  {
                    'p-btn--loading': loading,
                  }
                )}
                disabled={!validForSubmission}
                data-cy="button-continue"
                type="submit"
              >
                {buttonText ?? t('qnf.continuebutton.caption', 'Continue')}
              </button>
              {skipDetails && skipDetails.onClick !== undefined ? (
                <button
                  onClick={() => skipDetails.onClick && skipDetails.onClick()}
                  className={`p-btn--secondary mt24 wmn2 ${styles.button}`}
                  data-cy="button-skip"
                  type="button"
                >
                  {skipDetails.title}
                </button>
              ) : (
                skipDetails &&
                skipDetails.path && (
                  <Link
                    className={`p-btn--secondary mt24 wmn2 ${styles.button}`}
                    to={skipDetails.path}
                    data-cy="button-skip"
                  >
                    {skipDetails.title}
                  </Link>
                )
              )}
            </div>
            {additionalInfo && (
              <div className={styles.additionalInfo}>
                <InfoBox
                  title={additionalInfo.title}
                  description={additionalInfo.description}
                >
                  {additionalInfo.children}
                  {additionalInfo.moreInfo && (
                    <button
                      className="p-a ds-interactive-component mt16 p-p--small"
                      onClick={() => setIsModalOpen(true)}
                      type="button"
                    >
                      {t('qnf.learnmorebutton.caption', 'Learn more')}
                    </button>
                  )}
                  {additionalInfo.moreInfoLink && (
                    <a
                      className="p-a ds-interactive-component mt16 p-p--small"
                      href={additionalInfo.moreInfoLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('qnf.learnmorebutton.caption', 'Learn more')}
                    </a>
                  )}
                </InfoBox>
              </div>
            )}
          </div>
        </div>
      </form>
      {additionalInfo?.moreInfo && (
        <BottomOrRegularModal
          title={additionalInfo.title}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          {additionalInfo.moreInfo}
        </BottomOrRegularModal>
      )}
    </>
  );
};
