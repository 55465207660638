import { StripeError } from '@stripe/stripe-js';
import {
  CollectableBillingAddress,
  PaymentMethod,
  StripeBillingAddress,
  ZStripeBillingAddress,
} from 'models/paymentMethods';

import { PaymentOption } from './paymentMethodSelector.models';

const isValidStripeBillingAddress = (
  value: Partial<StripeBillingAddress>
): value is StripeBillingAddress => {
  return ZStripeBillingAddress.safeParse(value).success;
};

export const toStripeBillingAddress = (
  collectableAddress: Partial<CollectableBillingAddress>
): StripeBillingAddress | undefined => {
  const stripeBillingAddress: Partial<StripeBillingAddress> = {
    ...collectableAddress,
    country: collectableAddress.country?.code,
  };

  if (isValidStripeBillingAddress(stripeBillingAddress)) {
    return stripeBillingAddress;
  }

  return undefined;
};

export const isStripeError = (error: unknown): error is StripeError => {
  return (
    error !== null &&
    typeof error === 'object' &&
    ['type', 'charge', 'payment_intent', 'payment_method', 'setup_intent'].some(
      (property: string) =>
        Object.prototype.hasOwnProperty.call(error, property)
    )
  );
};

export const getPaymentOptionById = (
  id: string,
  paymentOptions: PaymentMethod[]
): PaymentMethod | undefined => paymentOptions.find((item) => item.id === id);

export const isReadyForPayment = (paymentOption: PaymentOption): boolean =>
  paymentOption.type === 'EXISTING_PAYMENT_METHOD' ||
  paymentOption.type === 'READY_FOR_SETUP' ||
  paymentOption.type === 'READY_FOR_SETUP_WITH_REDIRECT';

export const isCustomerFacingError = (error: StripeError) =>
  ['invalid_request_error', 'card_error', 'validation_error'].includes(
    error.type
  );
