import DateForm from 'components/questionnaireForms/DateForm';
import {
  ViewProps,
  withContainer,
} from 'features/liability/containers/withContainer';
import { getQuestionsMapping, QuestionId } from 'models/liability';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import { isDatePast } from 'shared/util/isDatePast';

const questionId: QuestionId = 'relocationDate';

const LiabilityRelocationDate = ({
  initialValue,
  onSubmitValue,
}: ViewProps<typeof questionId>) => {
  const { t } = useSafeTranslation();
  const [error, setError] = useState('');

  const validate = (date: string) => {
    setError('');
    if (isDatePast(date)) {
      setError('Oops, looks like that date is in the past');
      return;
    }
    onSubmitValue({ questionId, value: date });
  };

  return (
    <DateForm
      value={initialValue}
      onSubmitValue={validate}
      yearRange={{
        min: new Date().getFullYear(),
        max: new Date().getFullYear() + 5,
      }}
      error={error}
      displayCalendar
      featureName="Liability"
      question={getQuestionsMapping(t)[questionId]}
    />
  );
};

export default withContainer(LiabilityRelocationDate, questionId);
