import { type MultiDropzoneProps, MultiDropzone } from '@popsure/dirty-swan';
import { useFileUpload } from 'features/taskEngine/hooks/useFileUpload';
import { useEffect } from 'react';

export type FileInputProps = {
  onValidate: (valid: boolean) => void;
} & Omit<
  MultiDropzoneProps,
  'uploading' | 'onFileSelect' | 'uploadedFiles' | 'onRemoveFile'
>;

export const FileInput = (props: FileInputProps) => {
  const { onValidate } = props;
  const {
    uploadFile,
    uploadedFiles,
    loading: uploading,
    removeFile,
    isSubmitValid,
  } = useFileUpload();

  useEffect(() => {
    onValidate(isSubmitValid);
    // `onValidate` runs in an infinite loop when added to the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitValid]);

  return (
    <MultiDropzone
      {...props}
      uploadedFiles={uploadedFiles}
      onFileSelect={(files: File[]) => {
        uploadFile(files);
      }}
      onRemoveFile={removeFile}
      uploading={uploading}
    />
  );
};
