import View from 'features/claims/components/legalClaim/confirmation';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from 'reducers';

interface StateProps {
  policyId: string;
}

type OwnProps = RouteComponentProps<{ policyId: string }>;

export type ViewProps = StateProps & OwnProps;

const mapStateToProps = (
  _: AppState,
  {
    match: {
      params: { policyId },
    },
  }: OwnProps
): StateProps => ({
  policyId,
});

export default connect<StateProps, null, OwnProps, AppState>(mapStateToProps)(
  View
);
