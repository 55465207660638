import { Task } from '../../taskEngine.models';

export const getFilteredTasks = (
  tasks: Task[],
  filter?: (value: Task, index: number, array: Task[]) => boolean
): Array<Task> => {
  if (!filter) return tasks;

  return tasks.filter(filter);
};
