import { trackTaskEngineCardClicked } from '@getpopsure/analytics';
import { Address, isPartialAddressValid } from '@getpopsure/public-models';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTask } from 'features/taskEngine/api/taskEngine.api';
import { CustomTask } from 'features/taskEngine/components/CustomTask';
import {
  PrivateHealthAddressUpdateTaskSchema,
  Task,
  TaskEngineOptions,
} from 'features/taskEngine/taskEngine.models';
import { updateTaskMutationErrorHandler } from 'features/taskEngine/utils/updateTaskMutationErrorHandler';
import { APIResponseError } from 'models/error';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

export const PrivateHealthAddressUpdate = ({
  task,
  options,
}: {
  task: Task;
  options?: TaskEngineOptions;
}) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useSafeTranslation();

  const mutation = useMutation({
    mutationFn: (address: Address) => {
      return updateTask({
        taskId: task.id,
        payload: { address },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });

  const parsedTask = PrivateHealthAddressUpdateTaskSchema.safeParse(task);

  if (!parsedTask.success) {
    return null;
  }

  const {
    id,
    description: { type },
  } = parsedTask.data;

  const openModal = () => {
    trackTaskEngineCardClicked({
      task_type: type,
      task_id: id,
    });

    if (options?.onTaskClick) {
      options.onTaskClick(task);
    }

    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget).entries();
    const address = Object.fromEntries(formData);
    const addressWithCountry = { ...address, country: 'DE' };

    if (isPartialAddressValid(addressWithCountry)) {
      mutation.mutate(addressWithCountry, {
        onSuccess: () => {
          setIsOpen(false);
        },
        onError: (error) => updateTaskMutationErrorHandler(error),
      });
    }
  };

  return (
    <CustomTask
      metadata={{
        title: t(
          'taskEngine.privateHealthAddressUpdate.title',
          'Provide your address'
        ),
        subtitle: t(
          'taskEngine.privateHealthAddressUpdate.subtitle',
          'Private Health'
        ),
        description: t(
          'taskEngine.privateHealthAddressUpdate.description',
          'Please provide us your German address before policy start date.'
        ),
      }}
      modal={{
        isOpen,
        loading: mutation.isLoading,
        error: mutation.isError
          ? (mutation.error as APIResponseError).response?.data?.message
          : null,
        onOpen: openModal,
        onClose: closeModal,
      }}
      onSubmit={handleSubmit}
      actions={[
        {
          id: '1',
          type: 'ADDRESS',
        },
        {
          id: '2',
          type: 'SUBMIT',
          attributes: {
            value: 'Submit',
          },
        },
      ]}
    />
  );
};
