import { ChevronUpIcon } from '@popsure/dirty-swan';
import Markdown from 'components/markdown';
import { CollapsibleCardType } from 'features/recommendationTool/models/recommendation';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import styles from './CollapsibleCard.module.scss';

const colorStyleLookup = {
  PRO: 'pro',
  CON: 'con',
  ELIGIBLE: 'info',
  INELIGIBLE: 'ineligible',
  MORE_INFO_REQUIRED: 'info',
  PARTIAL: 'info',
};

const gridColumnStyleLookup = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const CollapsibleCard = ({
  children,
  gridColumn,
  markdownClassName = '',
  openByDefault = false,
  title,
  type = 'ELIGIBLE',
}: {
  children: React.ReactNode | string;
  gridColumn?: 'LEFT' | 'RIGHT';
  markdownClassName?: string;
  openByDefault?: boolean;
  title: string;
  type?: CollapsibleCardType;
}) => {
  const [isOpen, setIsOpen] = useState(openByDefault);

  const colorStyle = styles[colorStyleLookup[type]];

  const headerStyle = `d-flex ai-start jc-between ${styles.header} ${
    isOpen ? styles.headerOpen : ''
  }`;

  const columnStyle = gridColumn
    ? styles[gridColumnStyleLookup[gridColumn]]
    : '';

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const content =
    typeof children === 'string' ? (
      <Markdown
        className={`${styles.markdown} ${markdownClassName}`}
        paragraphClassName="p-p tc-grey-600"
        openLinksInNewTab
      >
        {children}
      </Markdown>
    ) : (
      children
    );

  return (
    <div
      className={`d-flex fd-column p24 ${styles.container} ${colorStyle} ${columnStyle}`}
    >
      <button type="button" className={headerStyle} onClick={handleClick}>
        <h4 className="p-h4">{title}</h4>
        <span
          className={`${styles.chevron} ${!isOpen && styles.chevronClosed}`}
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          <ChevronUpIcon color="grey-500" size={20} />
        </span>
      </button>
      <AnimateHeight duration={300} height={isOpen ? 'auto' : 0}>
        {content}
      </AnimateHeight>
    </div>
  );
};
