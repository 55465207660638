import { HouseholdClaims } from 'features/householdClaims/models';
import { useUploadFiles } from 'hooks/useUploadFiles';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import { UploadedFile } from 'shared/models/types';

import { HouseholdClaimsUploadDocumentsView } from './UploadDocuments.view';

export const HouseholdClaimsUploadDocuments = ({
  value,
  onSubmitValue,
  metaData: { policyId },
}: CustomComponentProps<HouseholdClaims>) => {
  const { t } = useSafeTranslation();

  const {
    uploadFile,
    uploadedFiles,
    loading: uploadLoading,
    removeFile,
    isSubmitValid,
  } = useUploadFiles({
    initialValue: (value as UploadedFile[]) ?? [],
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (uploadedFiles.length < 1) {
      throw new Error(
        `[Household Claims] Document token(s) is missing for submission for policy id: ${policyId}`
      );
    }

    onSubmitValue(uploadedFiles);
  };

  return (
    <HouseholdClaimsUploadDocumentsView
      uploadedFiles={uploadedFiles}
      uploadLoading={uploadLoading}
      onFileSelect={uploadFile}
      onRemoveFile={removeFile}
      handleSubmit={handleSubmit}
      t={t}
      isSubmitValid={isSubmitValid}
    />
  );
};
