import type {
  PrivateHealthClaims as PrivateHealthClaimsModel,
  PrivateHealthClaimsDispatch,
  PrivateHealthClaimsMetaData,
} from 'features/privateHealthClaims/models';
import { PrivateHealthClaimsView } from 'features/privateHealthClaims/PrivateHealthClaims.view';
import { translatedQuestionnaire } from 'features/privateHealthClaims/questionnaire';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import {
  flushPrivateHealthClaimsAnswers,
  storePrivateHealthClaimsAnswers,
} from './actions';
import { getPrivateHealthClaims } from './selectors';

export const PrivateHealthClaims = () => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch<PrivateHealthClaimsDispatch>();

  const answers = useSelector(getPrivateHealthClaims) ?? {};
  const { policyId }: { policyId: string } = useParams();

  const flushAnswers = useCallback(() => {
    dispatch(flushPrivateHealthClaimsAnswers());
  }, [dispatch]);

  useEffect(() => {
    return flushAnswers;
  }, [policyId, dispatch, flushAnswers]);

  const handleSubmitAnswer = (answer: Partial<PrivateHealthClaimsModel>) => {
    dispatch(storePrivateHealthClaimsAnswers(answer));
  };

  const metaData: PrivateHealthClaimsMetaData = {
    policyId,
  };

  return (
    <PrivateHealthClaimsView
      questionnaireAnswers={answers}
      questionnaire={translatedQuestionnaire(t, metaData)}
      handleAnswerQuestion={handleSubmitAnswer}
    />
  );
};
