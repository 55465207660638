import { SubmitAnswers } from 'features/recommendationTool/components/SubmitAnswers';
import { TAllowedInsuranceTypes } from 'features/recommendationTool/models/recommendation';

export const submitQuestionId = 'submit';

export const createProcessingQuestion = (
  insuranceType: TAllowedInsuranceTypes
): Partial<unknown> => ({
  submit: {
    required: false,
    type: 'CUSTOM',
    component: () => <SubmitAnswers insuranceType={insuranceType} />,
    groupId: 'processing',
    questionId: 'submit',
  },
});
