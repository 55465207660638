import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import dayjs from 'dayjs';
import Cell from 'features/checkout/components/Cell';
import { getCheckoutInfo } from 'features/checkout/selectors';
import { ReferrerCodeVerify } from 'features/referralEngine';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import styles from './PolicyDetails.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

export type PolicyDetailsProps = {
  questionnaireAnswers: QuestionnaireQuestions;
  additionalData: Record<string, unknown>;
  fields: { title: string; key: string }[];
};

export const PolicyDetails = ({
  additionalData,
  questionnaireAnswers,
  fields,
}: PolicyDetailsProps) => {
  const { t } = useSafeTranslation();

  const lookup = {
    ...additionalData,
    ...questionnaireAnswers,
  };

  const cells = fields.map(({ title, key }) => {
    return {
      title,
      value: lookup[key],
    };
  });

  // We need to access the main policy information via Redux for now
  // We can remove this once we have decoupled checkout from Redux

  const checkoutInfo = useSelector(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getCheckoutInfo(questionnaireAnswers.insuranceType)
  );

  return (
    <div className={styles.container}>
      <div className="p-h2 mb16">
        {t('general.qnr.checkout.policydetails.title', 'Policy details')}
      </div>
      <Cell
        title={t(
          'general.qnr.checkout.policydetails.requestedStartDate',
          'Requested start date'
        )}
        value={dayjs(checkoutInfo?.mainPolicy.startDate).format(DATE_FORMAT)}
        additionalInfo={{
          title: t(
            'general.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
            'Requested start date'
          ),
          description: t(
            'general.qnr.checkout.policydetails.requestedStartDate.additionalInfo.description',
            'Your coverage should begin on {{coverageDate}}, but only after you have received the official confirmation.',
            {
              coverageDate: dayjs(checkoutInfo?.mainPolicy.startDate).format(
                DATE_FORMAT
              ),
            }
          ),
        }}
      />
      {cells.map(({ title, value }) => {
        if (value) {
          return <Cell key={title} title={title} value={String(value)} />;
        }
        return null;
      })}

      <div className={`bg-grey-200 mt32 mb32 ${styles.divider}`} />
      {checkoutInfo?.mainPolicy.monthlyPrice && (
        <div className="d-flex">
          <div className="p-h3">
            {t('expatEu.qnr.checkout.policyDetails.total', 'Total per month')}
          </div>
          <div className="p-h3 ml-auto">
            {englishFormattedEuroCurrency(checkoutInfo.mainPolicy.monthlyPrice)}
          </div>
        </div>
      )}

      <ReferrerCodeVerify />
    </div>
  );
};
