import {
  categoryGroupLookup,
  categoryLookup,
} from 'features/recommendationTool/lookups';
import { CategoryLookup } from 'features/recommendationTool/models/lookups';
import {
  enabledVerticals,
  insuranceTypeByCategory,
  quickAdvice,
  RecommendationData,
  RecommendationType,
  TAllowedInsuranceTypes,
  TQuickAdvice,
  VerticalCategory,
} from 'features/recommendationTool/models/recommendation';

export type RecommendationGroupVerticalData = {
  insuranceType: TAllowedInsuranceTypes;
  price?: number;
  showFromLabel: boolean;
  key: VerticalCategory;
} & CategoryLookup;

export type RecommendationGroup = Array<{
  id: TQuickAdvice;
  title: string;
  verticals: Array<RecommendationGroupVerticalData>;
}>;

const groupOrder: Record<TQuickAdvice, number> = {
  NEEDED: 1,
  STRONGLY_SUGGESTED: 2,
  WORTH_CONSIDERING: 3,
  NOT_RELEVANT: 4,
};

export const getEnabledVerticalId = (
  key: RecommendationType
): VerticalCategory | undefined =>
  enabledVerticals.find((item) => [item?.remapKey, item.id].includes(key))?.id;

export const groupRecommendationVerticals = (
  recommendationData: RecommendationData
): RecommendationGroup => {
  const getGroupedData = (id: TQuickAdvice) => {
    const data: Array<RecommendationGroupVerticalData> = [];

    recommendationData.forEach(({ key, quickAssessment, options, ...rest }) => {
      const advice =
        options?.advice || quickAssessment?.advice || 'WORTH_CONSIDERING';

      if (advice !== id) {
        return;
      }

      const verticalCategory = getEnabledVerticalId(key);

      if (verticalCategory) {
        const price = rest?.price || quickAssessment?.price;
        data.push({
          key: verticalCategory,
          insuranceType: insuranceTypeByCategory[verticalCategory],
          price: verticalCategory === key ? price : undefined,
          showFromLabel:
            !rest?.price && ['HOUSEHOLD', 'LIFE'].includes(verticalCategory),
          ...categoryLookup[verticalCategory],
        });
      }
    });

    return data;
  };

  return quickAdvice
    .map((id) => ({
      id,
      title: categoryGroupLookup[id],
      verticals: getGroupedData(id),
    }))
    .sort((a, b) => (groupOrder[a.id] > groupOrder[b.id] ? 1 : -1));
};
