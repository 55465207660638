import classnames from 'classnames';
import { mapping } from 'components/cardBrand/icons';
import cardRectangle from 'features/paymentMethodSelector/icons/card-rectangle.svg';
import { getPaymentOptionById } from 'features/paymentMethodSelector/paymentMethodSelector.utils';
import { PaymentMethod } from 'models/paymentMethods';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { TFunction, useSafeTranslation } from 'shared/i18n';
import {
  formatCardExpiryDate,
  formatCardLastDigits,
} from 'shared/util/cardFormatters';

import styles from './style.module.scss';

export interface PaymentInfoProps {
  selectedPaymentOption?: PaymentMethod;
  onChange: (value?: PaymentMethod) => void;
  setAddNewPayment: (value: boolean) => void;
  paymentOptions: PaymentMethod[];
}

const buildAddPaymentOptionLabelObject = ({ t }: { t: TFunction }) => ({
  value: 'ADD_NEW_PAYMENT',
  label: t(
    'components.paymentMethodSelector.addPaymentMethod.stripeElementLabel',
    {
      defaultValue: 'Add new payment method',
    }
  ),
});

export const PaymentSelect = ({
  paymentOptions,
  selectedPaymentOption,
  onChange,
  setAddNewPayment,
}: PaymentInfoProps) => {
  const { t } = useSafeTranslation();
  const [
    selectedPaymentOptionLabelObject,
    setSelectedPaymentOptionLabelObject,
  ] = useState<{ value: string; label?: string }>({
    value: selectedPaymentOption?.id ?? '',
  });

  useEffect(() => {
    if (selectedPaymentOption === undefined) {
      setSelectedPaymentOptionLabelObject(
        buildAddPaymentOptionLabelObject({ t })
      );
    } else {
      setSelectedPaymentOptionLabelObject({
        value: selectedPaymentOption?.id ?? '',
      });
      setAddNewPayment(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentOption]);

  const selectablePaymentOptionsLabelObjects = paymentOptions
    .flatMap((option) => [{ value: option.id, label: option.id }])
    .concat([
      buildAddPaymentOptionLabelObject({
        t,
      }),
    ]);

  /**
   * TODO: [KONG] Replace type
   */
  // eslint-disable-next-line
  const CustomizedValueContainer = ({ selectProps }: any) => {
    const paymentOptionInfo = getPaymentOptionById(
      selectedPaymentOptionLabelObject?.value,
      paymentOptions
    );

    const handleMenuClick = () => {
      if (selectProps.menuIsOpen === true) {
        selectProps.onMenuClose();
      } else {
        selectProps.onMenuOpen();
      }
    };

    return (
      <button
        data-testid="payment-select-value-container"
        data-cy="payment-select-value-container"
        className={`ds-interactive-component w90 d-flex ai-center br8 p16 bg-grey-200 color-grey-200 c-pointer ${styles['existing-card-dropdown']}`}
        onClick={handleMenuClick}
        type="button"
      >
        {selectedPaymentOptionLabelObject.value === 'ADD_NEW_PAYMENT' ? (
          <div className="p-p">{selectedPaymentOptionLabelObject.label}</div>
        ) : paymentOptionInfo ? (
          <>
            <img
              alt={paymentOptionInfo.attributes.brand}
              className={`bg-grey-200 br2 ${styles['existing-card-icon']}`}
              src={
                paymentOptionInfo &&
                mapping[paymentOptionInfo?.attributes.brand]
              }
            />
            <div
              className={`p-p tc-grey-900 ${styles['existing-card-ending']}`}
            >
              {paymentOptionInfo &&
                (paymentOptionInfo.type === 'PAYPAL'
                  ? paymentOptionInfo?.attributes.identifier ?? 'PayPal'
                  : formatCardLastDigits(
                      paymentOptionInfo?.attributes.identifier
                    ))}
            </div>
            {paymentOptionInfo.attributes.expiry && (
              <div
                className={`p-p tc-grey-600 ml24 ${styles['existing-card-expire-date']}`}
              >
                {formatCardExpiryDate(paymentOptionInfo.attributes.expiry)}
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </button>
    );
  };

  /**
   * TODO: [KONG] Replace type
   */
  // eslint-disable-next-line
  const CustomizedSelectOption = ({ innerProps, isSelected, data }: any) => {
    const paymentMethodInfo = getPaymentOptionById(data.value, paymentOptions);
    const addNewPaymentIcon = cardRectangle;

    return (
      <div
        className={classnames(
          styles['existing-card-container'],
          'd-flex ai-center c-pointer px16 py16 px0',
          {
            [styles['existing-card-container-selected']]: isSelected,
          }
        )}
        {...innerProps}
      >
        <img
          alt={paymentMethodInfo?.attributes.brand}
          className={`bg-grey-200 br2 ${styles['existing-card-icon']}`}
          src={
            data.value === 'ADD_NEW_PAYMENT'
              ? addNewPaymentIcon
              : paymentMethodInfo &&
                mapping[paymentMethodInfo?.attributes.brand]
          }
        />
        {data.value === 'ADD_NEW_PAYMENT' ? (
          <div
            className="p-p ml16"
            data-testid="payment-select-add-new-payment"
            data-cy="payment-select-add-new-payment"
          >
            {data.label}
          </div>
        ) : (
          <>
            <div
              className={`p-p tc-grey-900 ${styles['existing-card-ending']}`}
            >
              {paymentMethodInfo &&
                (paymentMethodInfo.type === 'PAYPAL'
                  ? paymentMethodInfo?.attributes.identifier ?? 'PayPal'
                  : formatCardLastDigits(
                      paymentMethodInfo?.attributes.identifier
                    ))}
            </div>
            {paymentMethodInfo?.attributes.expiry && (
              <div
                className={`p-p tc-grey-600 ${styles['existing-card-expire-date']}`}
              >
                {paymentMethodInfo &&
                  (paymentMethodInfo.type === 'PAYPAL'
                    ? paymentMethodInfo.attributes.identifier
                    : formatCardExpiryDate(
                        paymentMethodInfo?.attributes.expiry
                      ))}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const customStyles = {
    /**
     * TODO: [KONG] Replace type
     */
    // eslint-disable-next-line
    menu: (provided: any) => ({
      ...provided,
      borderRadius: '8px',
      border: '1px solid #8E8CEE',
      backgroundColor: 'white',
      padding: '4px 0',
      position: 'absolute',
      width: '100%',
      zIndex: '9',
    }),
    menuList: () => ({
      maxHeight: '216px',
      overflow: 'auto',
    }),
    control: () => ({
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  };

  return (
    <div className={`br8 bg-grey-200 ${styles['payment-select-container']}`}>
      <Select
        styles={customStyles}
        value={selectedPaymentOptionLabelObject}
        options={selectablePaymentOptionsLabelObjects}
        onChange={(item) => {
          if (item) {
            onChange(getPaymentOptionById(item.value, paymentOptions));
            setSelectedPaymentOptionLabelObject(item);
            setAddNewPayment(item.value === 'ADD_NEW_PAYMENT');
          }
        }}
        isSearchable={false}
        components={{
          Option: CustomizedSelectOption,
          ValueContainer: CustomizedValueContainer,
        }}
      />
    </div>
  );
};
