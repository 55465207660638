import { useQueryParamValue } from 'hooks/useQueryParamValue';
import {
  InsuranceTypes,
  ValidVerticalUrlParams,
} from 'models/insurances/types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  fetchPolicyRecommendations,
  PolicyRecommendationsDispatch,
} from './actions/PolicyRecommendations.actions';
import { PetHealthCard } from './components/PetHealth';

const mapToVertical: Partial<
  Record<ValidVerticalUrlParams | InsuranceTypes, InsuranceTypes>
> = {
  dog: 'DOG_LIABILITY',
};

const verticalCards: Partial<Record<InsuranceTypes, React.ElementType>> = {
  PET_HEALTH: PetHealthCard,
};

/**
 * PolicyRecommendations is a self contained component that can be dropped into
 * any part of the application and will return policy recommendations based on a provided
 * vertical id.
 *
 * The component knows how to interact with the API to fetch and handle the response.
 * Based on the response the component might show one or more recommendations
 * or render null if no recommendations are applicable to a vertical.
 * The recommendations themselves are calculated based on the provided vertical id and the policies the current user has.
 *
 * The current implementation only covers dog-liability/pet-health cross recommendations but will be extended further in the future.
 *
 */
export const PolicyRecommendations = () => {
  const signupSuccessParam = useQueryParamValue(
    'signupSuccess'
  ) as ValidVerticalUrlParams;

  const dispatch = useDispatch<PolicyRecommendationsDispatch>();

  const insuranceType = mapToVertical[signupSuccessParam];

  const [recommendedPolicies, setRecommendedPolicies] = useState<
    InsuranceTypes[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!insuranceType) {
        return;
      }
      const policies = await dispatch(
        fetchPolicyRecommendations(insuranceType)
      );

      if (policies) {
        setRecommendedPolicies(policies);
      }
    };
    fetchData();

    return () => {
      setRecommendedPolicies([]);
    };
  }, [dispatch, insuranceType]);

  if (recommendedPolicies.length > 0) {
    return (
      <>
        {recommendedPolicies.map((type) => {
          const Component = verticalCards[type];
          if (Component) {
            return <Component key={type} />;
          }
          return null;
        })}
      </>
    );
  }

  return null;
};
