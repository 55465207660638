import { storeSubmittableClaimInfo, submitPrivateClaim } from 'actions/claims';
import routes from 'constants/routes';
import connectedError, { ErrorProps } from 'containers/error';
import connectedLoader, { LoaderProps } from 'containers/loader';
import View from 'features/claimsProcess/paymentInfo/view';
import { ClaimSubmitInfo, ClaimSubmittableInfo } from 'models/claims';
import { connect, MapDispatchToProps } from 'react-redux';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { AppState } from 'reducers';
import { getSubmittableClaimInfo } from 'selectors/claims';
import { getPolicyDetail } from 'selectors/policies';

interface StateProps {
  submittableClaimInfo?: Partial<ClaimSubmittableInfo>;
  policyId: string;
}

interface DispatchProps {
  createClaim: (claimInfo: ClaimSubmitInfo) => void;
}

type OwnProps = RouteComponentProps<{ policyId: string }> &
  LoaderProps &
  ErrorProps;

export type ViewProps = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (
  state: AppState,
  {
    history,
    match: {
      params: { policyId },
    },
  }: OwnProps
): StateProps => {
  const submittableClaimInfo = getSubmittableClaimInfo(state);

  if (submittableClaimInfo?.uploadedFiles === undefined) {
    const policy = getPolicyDetail(state, policyId);
    const uploadDocsPath =
      policy?.type === 'INCOMING'
        ? generatePath(routes.me.claims.expatClaim.uploadDocs.path, {
            policyId,
          })
        : generatePath(routes.me.claims.uploadDocuments.path, { policyId });

    history.replace(uploadDocsPath);
  }

  return {
    policyId,
    submittableClaimInfo,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch
): DispatchProps => ({
  createClaim: (claimInfo: ClaimSubmitInfo) => {
    dispatch(storeSubmittableClaimInfo({ iban: claimInfo.iban }));
    dispatch<any>(submitPrivateClaim(claimInfo));
  },
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(connectedLoader('CREATE_CLAIM')(connectedError('CREATE_CLAIM')(View)));
