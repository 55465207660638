import { BottomOrRegularModal, Button } from '@popsure/dirty-swan';
import { cancelApplication } from 'actions/policies';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getRequestError, getRequestInProcess } from 'selectors/requests';

interface CancelApplicationModalProps {
  policyId: string;
  isOpen: boolean;
  onClose: () => void;
}

const REQUEST_TYPE = 'CANCEL_APPLICATION';

const CancelApplicationModal = (props: CancelApplicationModalProps) => {
  const { isOpen, onClose, policyId } = props;
  const loading = useSelector((state: AppState) =>
    getRequestInProcess(state, REQUEST_TYPE)
  );
  const error = useSelector((state: AppState) =>
    getRequestError(state, REQUEST_TYPE)
  );
  const dispatch = useDispatch();

  const handleCancelApplication = () => dispatch(cancelApplication(policyId));

  return (
    <BottomOrRegularModal
      isOpen={isOpen}
      title="Cancel application"
      onClose={onClose}
    >
      <div className="pt16 pb24 px24">
        <p className="p-p">
          Are you sure you want to cancel your application for <br /> Health
          Insurance?
        </p>
        <Button
          className="w100 mt16"
          onClick={handleCancelApplication}
          loading={loading}
        >
          Cancel my application
        </Button>
        <AnimateHeight duration={300} height={error ? 'auto' : 0}>
          <div className="p-notice p-notice--warning mt16 p-p wmx5">
            {error?.message}
          </div>
        </AnimateHeight>
      </div>
    </BottomOrRegularModal>
  );
};

export default CancelApplicationModal;
