import routes from 'constants/routes';
import { TAllowedInsuranceTypes } from 'features/recommendationTool/models/recommendation';
import { RecommendationDetails } from 'models/recommendationTool';
import { generatePath } from 'react-router';

import { hasMoreInfoRequired } from '../hasMoreInfoRequired';

export const getOverviewVerticalRoute = (
  insuranceType: TAllowedInsuranceTypes,
  recommendation?: RecommendationDetails
) => {
  const isMissingQuestionnaireInfo =
    !recommendation?.id ||
    hasMoreInfoRequired(recommendation?.data, insuranceType);

  const route = isMissingQuestionnaireInfo
    ? routes.insuranceTool.questionnaire.path
    : routes.insuranceTool.recommendation.path;

  return generatePath(route, {
    insuranceType,
    recommendationId: recommendation?.id,
  });
};
