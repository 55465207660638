import type { ClaimsAction, RequestAction } from 'constants/actions';
import { Plan } from 'features/dental/models';
import { TFunction } from 'i18next';
import { InsuranceTypes } from 'models/insurances/types';
import { PartialQuestionnaire } from 'models/questionnaireFramework';
import type { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { UploadedFile } from 'shared/models/types';
import {
  ZPayoutDetailsSchema,
  ZUploadedDocumentsSchema,
} from 'shared/models/validations';
import { z } from 'zod';

export type Questionnaire = PartialQuestionnaire<DentalClaims, GroupId>;
export type QuestionId = keyof DentalClaims;

export type GroupId = 'question';

const DentalClaimTypeArray = [
  'COST_PLAN',
  'DENTAL_CLEANING',
  'HIGH_QUALITY_FILLING',
  'ROOT_CANAL_OR_PERIO_TREATMENT',
  'MOUTHGUARD',
  'EXTRACTION',
  'REPLACEMENT',
  'WHITENING',
  'OTHER',
] as const;
export type DentalAdvancedPlanClaimType = typeof DentalClaimTypeArray[number];
export type DentalBasicPlanClaimType = Exclude<
  DentalAdvancedPlanClaimType,
  'EXTRACTION' | 'REPLACEMENT' | 'WHITENING'
>;

type ClaimTypeMappingResult = {
  COST_PLAN: {
    title: string;
    description: string;
  };
  DENTAL_CLEANING: string;
  HIGH_QUALITY_FILLING: string;
  ROOT_CANAL_OR_PERIO_TREATMENT: string;
  MOUTHGUARD: string;
  OTHER: string;
  EXTRACTION?: { title: string; description: string };
  REPLACEMENT?: { title: string; description: string };
  WHITENING?: string;
};

export const claimTypeMapping = (
  t: TFunction,
  plan: Plan
): ClaimTypeMappingResult => {
  const basicPlanMapping = {
    COST_PLAN: {
      title: t(
        'claims.dental.questionnaire.claimtype.costplan.title',
        'Cost plan'
      ),
      description: t(
        'claims.dental.questionnaire.claimtype.costplan.description',
        'Receive a coverage estimate for your planned treatment'
      ),
    },
    DENTAL_CLEANING: t(
      'claims.dental.questionnaire.claimtype.dentalcleaning',
      'Dental cleaning'
    ),
    HIGH_QUALITY_FILLING: t(
      'claims.dental.questionnaire.claimtype.highqualityfilling',
      'High-quality filling'
    ),
    ROOT_CANAL_OR_PERIO_TREATMENT: t(
      'claims.dental.questionnaire.claimtype.rootcanalorperiotreatment',
      'Root canal or periodontitis treatment'
    ),
    MOUTHGUARD: t(
      'claims.dental.questionnaire.claimtype.mouthguard',
      'Mouthguard / bite guard'
    ),
    OTHER: t('claims.dental.questionnaire.claimtype.otherClaim', 'Other'),
  };

  if (plan === 'ADVANCED') {
    return {
      EXTRACTION: {
        title: t(
          'claims.dental.questionnaire.claimtype.extraction.title',
          'Tooth extraction'
        ),
        description: t(
          'claims.dental.questionnaire.claimtype.extraction.description',
          'Excluding wisdom teeth'
        ),
      },
      REPLACEMENT: {
        title: t(
          'claims.dental.questionnaire.claimtype.replacement.title',
          'Tooth replacement procedure'
        ),

        description: t(
          'claims.dental.questionnaire.claimtype.replacement.description',
          'Implants, crowns, veneers, onlays, inlays, bridges'
        ),
      },
      WHITENING: t(
        'claims.dental.questionnaire.claimtype.whitening',
        'Teeth whitening / bleaching'
      ),
      ...basicPlanMapping,
    };
  }

  return basicPlanMapping;
};

export interface DentalClaims {
  intro: boolean;
  claimTypes: DentalBasicPlanClaimType[] | DentalAdvancedPlanClaimType[];
  otherClaimType: string;
  uploadedDocumentTokens: UploadedFile[];
  payoutDetails: { amount: number; iban: string };
  processing: boolean;
  submitted: boolean;
}

export interface DentalClaimsMetaData {
  policyId: string;
  planId: Plan;
}

export type DentalClaimsDispatch = ThunkDispatch<
  AppState,
  unknown,
  RequestAction | ClaimsAction
>;

export interface SubmitInfo {
  type: InsuranceTypes;
  userPolicyId: string;
  claimTypes: {
    claimType: DentalBasicPlanClaimType | DentalAdvancedPlanClaimType;
    additionalInfo?: string;
  }[];
  otherClaimType?: string;
  uploadedDocumentTokens: string[];
  amount: number;
  iban: string;
}

export const ZDentalClaimsSubmissionSchema = z.object({
  claimTypes: z.array(z.enum(DentalClaimTypeArray)),
  otherClaimType: z.string().optional(),
  uploadedDocumentTokens: z.array(ZUploadedDocumentsSchema),
  payoutDetails: ZPayoutDetailsSchema,
});
