import {
  answerRecommendationToolQuestionnaire,
  getRecommendationToolMetas,
} from 'actions/recommendationTool';
import PageNotFound from 'components/pageNotFound';
import routes from 'constants/routes';
import Questionnaire from 'features/questionnaireFramework';
import { getNextUnansweredQuestionId } from 'features/questionnaireFramework/utils/getNextUnansweredQuestion';
import type { InsuranceTypeRouteProps } from 'features/recommendationTool/newRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  getAllQuestionnaireAnswers,
  getPersonaId,
} from 'selectors/recommendationTool';

import { QuestionnaireAnswers } from '../models/liability';
import { QuestionnaireTypes } from '../models/recommendation';
import allQuestionnaires from '../questionnaires';
import { isValidInsuranceType } from '../utils/isValidInsuranceType';

export const QuestionnaireWrapper = ({
  match: {
    params: { insuranceType },
  },
}: InsuranceTypeRouteProps) => {
  const dispatch = useDispatch();
  const questionnaire = allQuestionnaires[insuranceType as QuestionnaireTypes];
  const handleAnswerQuestion = (answer: Partial<QuestionnaireAnswers>) => {
    dispatch(answerRecommendationToolQuestionnaire(answer));
  };
  const questionnaireAnswers = useSelector(getAllQuestionnaireAnswers);
  const personaId = useSelector(getPersonaId);
  const unansweredQuestions = getNextUnansweredQuestionId(
    questionnaire,
    questionnaireAnswers
  );
  dispatch(
    getRecommendationToolMetas({
      questionnaire: insuranceType,
      metadata: questionnaire,
    })
  );

  if (!isValidInsuranceType(insuranceType)) {
    return <PageNotFound />;
  }

  if (!personaId) {
    return <Redirect to={routes.insuranceTool.path} />;
  }

  if (!unansweredQuestions && !personaId) {
    throw new Error(
      '[RECOMMENDATION_TOOL]: trying to get a recommendation without a persona'
    );
  }

  return (
    <Questionnaire
      featureName={`Recommendation tool - ${insuranceType}`}
      questionnaire={questionnaire}
      questionnaireAnswers={questionnaireAnswers}
      handleAnswerQuestion={handleAnswerQuestion}
    />
  );
};
