import { paramsSetUrl } from '@getpopsure/public-utility';
import routes from 'constants/routes';
import { Link } from 'react-router-dom';
import { Trans } from 'shared/i18n';

import styles from './PrivateDescriptionModal.module.scss';

export const PrivateDescriptionModal = () => {
  return (
    <div className="wmx6">
      <Trans i18nKey="private.qnr.quote.shortTermCard.modal.description.1">
        <p className="p-p mb16">
          Since you’re from outside the EU and have been in Germany for less
          than 4 years, we offer you our short-term coverage that’s valid up to
          5 years after you enter Germany and fulfills all requirements for your
          visa. This tariff has similar benefits to our long-term plans but does
          not include <strong className="fw-bold">old age savings</strong>,
          which makes it up to 50% cheaper.
        </p>
      </Trans>
      <Trans i18nKey="private.qnr.quote.shortTermCard.modal.description.2">
        <p className="p-p mb16">
          The old age savings reserves are meant to keep private insurance
          affordable as customers get older, but aren’t necessary for those who
          plan on leaving Germany.
        </p>
      </Trans>
      <Trans i18nKey="private.qnr.quote.shortTermCard.modal.description.3">
        <p className="p-p mb16">
          If you stay in Germany for longer than 5 years, we will help you
          transfer to our long-term tariff. The switch is easy and any of your
          pre-existing conditions will be covered. You will start paying old age
          savings from that point onward.
        </p>
      </Trans>

      <Trans i18nKey="private.qnr.quote.shortTermCard.modal.description.4">
        <p className="p-p mb16">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className={`p-a d-flex ai-center ${styles.link}`}
            to={`${paramsSetUrl(routes.policies.privateHealthV2.quote.path, [
              { key: 'showLongTerm', value: 'true' },
            ])}`}
          >
            See the long-term private plan
          </Link>
        </p>
      </Trans>
    </div>
  );
};
