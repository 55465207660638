import { Address } from '@getpopsure/public-models';
import AddressForm from 'components/questionnaireForms/AddressForm';
import { handleSubmitClaim } from 'features/liabilityClaims/actions';
import {
  LiabilityClaims,
  LiabilityClaimsDispatch,
} from 'features/liabilityClaims/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { QuestionnaireWrapperProps } from 'models/questionnaire';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

/** TODO: [KONG] This component is left as CUSTOM within QNR framework as it's difficult to pass a custom action function
 *
 * See https://www.notion.so/featherinsurance/Questionnaire-Framework-Improvements-bc66dbbfaa994401a370b65a9ca9e781
 */
export const ThirdPartyAddress = ({
  value,
  onSubmitValue,
  questionnaireAnswers,
  metaData: { policyId },
  skipDetails,
  buttonText,
}: CustomComponentProps<LiabilityClaims> & {
  buttonText?: string;
  skipDetails: QuestionnaireWrapperProps['skipDetails'];
}) => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch<LiabilityClaimsDispatch>();

  const { loading, error } = useRequestStatus('CREATE_CLAIM');

  const handleSubmit = async (address: Address) => {
    if (address)
      await dispatch(
        handleSubmitClaim(policyId, {
          ...questionnaireAnswers,
          thirdPartyAddress: address,
        })
      );

    onSubmitValue(address);
  };

  return (
    <AddressForm
      value={value as Address}
      question={t(
        'claims.liability.questionnaire.thirdpartyaddress.question',
        'What is their address?'
      )}
      loading={loading}
      error={error?.message}
      buttonText={buttonText}
      onSubmitValue={handleSubmit}
      featureName="Liability Claims"
      skipDetails={skipDetails}
    />
  );
};
