import { TFunction } from '@getpopsure/i18n-react';
import { faq } from '@getpopsure/private-constants';
import { Blocker } from 'components/Blocker';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { DependentsEmail } from 'features/expat/components/DependentsEmail';
import { DependentsToCover } from 'features/expat/components/DependentsToCover';
import { Email } from 'features/expat/components/Email';
import { StartDate } from 'features/expat/components/StartDate/StartDate';
import { ExpatQuestionnaire, genderMapping } from 'features/expat/models';
import { getDateOfBirthYearRange } from 'features/expat/utils';
import { PartialQuestionnaire } from 'models/questionnaireFramework';
import { generatePath } from 'react-router';
import { isUnder18 } from 'shared/util/isUnder18';

export const postQuoteQNR = (
  t: TFunction
): PartialQuestionnaire<ExpatQuestionnaire, 'signup'> => ({
  email: {
    required: true,
    type: 'CUSTOM',
    component: Email,
    retrieveQuestionsToRemove: () => ['review'],
    retrieveAnswerObject: ({
      email,
      name,
      account,
    }): Partial<ExpatQuestionnaire> => ({
      email,
      name,
      account,
    }),
    retrieveNextPageId: () => 'name',
    groupId: 'signup',
  },
  name: {
    required: true,
    type: 'NAME',
    props: {
      question: t('expat.qnr.signup.name.title', "What's your name?"),
    },
    retrieveAnswerObject: (
      name: ExpatQuestionnaire['name']
    ): Partial<ExpatQuestionnaire> => ({
      name,
    }),
    retrieveQuestionsToRemove: () => ['review'],
    retrieveNextPageId: () => 'gender',
    groupId: 'signup',
  },
  gender: {
    required: true,
    type: 'RADIO',
    props: {
      question: t('expat.qnr.signup.gender.title', "What's your gender?"),
      mapValue: genderMapping(t),
    },
    retrieveAnswerObject: (
      gender: ExpatQuestionnaire['gender']
    ): Partial<ExpatQuestionnaire> => ({
      gender,
    }),
    retrieveQuestionsToRemove: () => ['review'],
    retrieveNextPageId: () => 'hasPublicInsurance',
    groupId: 'signup',
  },
  hasPublicInsurance: {
    required: true,
    type: 'RADIO',
    props: {
      question: t(
        'expat.qnr.signup.publicGermanyInsurance.title',
        'Are you currently on public health insurance in Germany?'
      ),
      mapValue: {
        YES: t('expat.qnr.signup.publicPrivateGermanyInsurance.yes', 'Yes'),
        NO: t('expat.qnr.signup.publicPrivateGermanyInsurance.no', 'No'),
      },
    },
    retrieveAnswerObject: (answer): Partial<ExpatQuestionnaire> => ({
      hasPublicInsurance: answer === 'YES',
    }),
    transformValue: (answer: boolean) => (answer === true ? 'YES' : 'NO'),
    retrieveNextPageId: (answer) => {
      if (answer === 'YES') {
        return 'germanCitizenInsuredInGermanyBeforeBlocker';
      }
      return 'citizenship';
    },
    retrieveQuestionsToRemove: () => ['review'],
    groupId: 'signup',
  },
  germanCitizenInsuredInGermanyBeforeBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'expat.qnr.insuredInGermanyBlocker.title',
          "We can't sign you up..."
        ),
        description: t(
          'expat.qnr.insuredInGermanyBlocker.description',
          'Since you are currently insured with a German public insurance, it is not possible to switch to expat health insurance which offers less comprehensive coverage.\n\nYou can try out our digital recommendation tool to find out which health insurance is the best option for you.'
        ),
        buttonProps: [
          {
            type: 'href',
            href: generatePath(routes.insuranceTool.intro.path, {
              insuranceType: 'health',
            }),
            caption: t(
              'expat.qnr.insuredInGermanyBlocker.exploreButton.cta',
              'Get recommendation'
            ),
          },
          {
            type: 'href',
            href: faq.publicHealthInsuranceDowngrade,
            caption: t(
              'expat.qnr.insuredInGermanyBlocker.faq.cta',
              'Learn more'
            ),
            variant: 'SECONDARY',
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    retrieveQuestionsToRemove: () => ['review'],
    groupId: 'signup',
  },
  citizenship: {
    required: true,
    type: 'COUNTRY_MULTI',
    props: {
      question: t(
        'expat.qnr.signup.citizenship.title',
        'Which countries do you hold a passport from?'
      ),
    },
    retrieveAnswerObject: (
      citizenship: ExpatQuestionnaire['citizenship']
    ): Partial<ExpatQuestionnaire> => ({
      citizenship,
    }),
    retrieveNextPageId: () => {
      return 'lastPermanentResidency';
    },
    retrieveQuestionsToRemove: () => ['lastPermanentResidency', 'review'],
    groupId: 'signup',
  },
  lastPermanentResidency: {
    required: true,
    type: 'COUNTRY_SINGLE',
    props: {
      question: t(
        'expat.qnr.signup.previousCountry.title',
        'What was your country of residence before arriving in Germany?'
      ),
      filterCountry: [{ code: 'DE', name: 'Germany' }],
    },
    retrieveAnswerObject: (
      lastPermanentResidency: ExpatQuestionnaire['lastPermanentResidency']
    ): Partial<ExpatQuestionnaire> => ({
      lastPermanentResidency,
    }),
    retrieveNextPageId: () => {
      return 'startDate';
    },
    retrieveQuestionsToRemove: () => ['review'],
    groupId: 'signup',
  },
  startDate: {
    required: true,
    type: 'CUSTOM',
    component: (props) =>
      StartDate({
        ...props,
        question: t(
          'expat.qnr.signup.startDate.title',
          'When would you like your coverage to start?'
        ),
        yearRange: {
          min: dayjs().year(),
          max: dayjs().add(1, 'years').year(),
        },
      }),
    retrieveAnswerObject: (
      startDate: ExpatQuestionnaire['startDate']
    ): Partial<ExpatQuestionnaire> => ({
      startDate,
    }),
    retrieveNextPageId: (_answer, answers) => {
      return isUnder18(answers?.dateOfBirth) ? 'review' : 'addDependents';
    },
    retrieveQuestionsToRemove: () => ['review'],
    groupId: 'signup',
  },
  parentGuardianName: {
    required: (answers) => {
      return (
        answers.dateOfBirth !== undefined && isUnder18(answers.dateOfBirth)
      );
    },
    type: 'NAME',
    props: {
      question: t(
        'expat.qnr.signup.parentGuardianName.title',
        "What's the name of your parent or legal guardian?"
      ),
    },
    retrieveAnswerObject: (
      parentGuardianName: ExpatQuestionnaire['parentGuardianName']
    ): Partial<ExpatQuestionnaire> => ({
      parentGuardianName,
    }),
    retrieveQuestionsToRemove: () => ['review'],
    retrieveNextPageId: () => 'parentGuardianDateOfBirth',
    groupId: 'signup',
  },
  parentGuardianDateOfBirth: {
    required: (answers) => {
      return (
        answers.dateOfBirth !== undefined && isUnder18(answers.dateOfBirth)
      );
    },
    type: 'DATE',
    props: {
      question: t(
        'expat.qnr.signup.parentGuardianDateOfBirth.title',
        'When were they born?'
      ),
      yearRange: getDateOfBirthYearRange(),
    },
    validateAnswer: (answer) => {
      if (isUnder18(answer)) {
        return t(
          'expat.qnr.signup.parentGuardianDateOfBirth.error.age',
          'Legal guardian/parent should be over 18 years old.\nPlease make sure the birth date is correct.'
        );
      }
      return null;
    },
    retrieveAnswerObject: (
      parentGuardianDateOfBirth: string
    ): Partial<ExpatQuestionnaire> => ({
      parentGuardianDateOfBirth,
    }),
    retrieveNextPageId: () => 'parentGuardianEmail',
    retrieveQuestionsToRemove() {
      return ['review'];
    },
    groupId: 'signup',
  },
  parentGuardianEmail: {
    required: (answers) => {
      return (
        answers.dateOfBirth !== undefined && isUnder18(answers.dateOfBirth)
      );
    },
    type: 'CUSTOM',
    component: (props) =>
      DependentsEmail({
        ...props,
        question: t(
          'expat.qnr.signup.parentGuardianEmail.title',
          "What's their email address?"
        ),
      }),
    retrieveAnswerObject: (
      parentGuardianEmail: string
    ): Partial<ExpatQuestionnaire> => ({
      parentGuardianEmail,
    }),
    retrieveNextPageId: () => 'review',
    retrieveQuestionsToRemove() {
      return ['review'];
    },
    groupId: 'signup',
  },
  addDependents: {
    required: (answer) => {
      return !isUnder18(answer.dateOfBirth);
    },
    type: 'CUSTOM',
    component: DependentsToCover,
    retrieveAnswerObject: (
      addDependents: ExpatQuestionnaire['addDependents']
    ): Partial<ExpatQuestionnaire> => ({
      addDependents,
    }),
    retrieveNextPageId: () => 'review',
    retrieveQuestionsToRemove: () => ['review'],
    groupId: 'signup',
  },
});
