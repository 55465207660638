import { COOKIE_KEY } from '@getpopsure/cookie-consent';
import { legal, website } from '@getpopsure/private-constants';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import {
  ActionListItem,
  ActionsList,
} from 'features/accountV2/components/ActionsList';
import Cookies from 'js-cookie';
import { useSafeTranslation } from 'shared/i18n';

export const PrivacyAndTerms = () => {
  const { t } = useSafeTranslation();

  const handleDeleteCookie = () => {
    Cookies.remove(COOKIE_KEY, {
      domain:
        process.env.NODE_ENV === 'development'
          ? undefined
          : 'feather-insurance.com',
    });
    window.location.reload();
  };

  const items: ActionListItem[] = [
    {
      onClick: handleDeleteCookie,
      title: t(
        'account.privacyTerms.cookieConsentButton.caption',
        'Cookie consent'
      ),
      key: 'cookieConsent',
    },
    {
      title: t('account.privacyTerms.imprintButton.caption', 'Imprint'),
      key: 'imprint',
      as: 'a',
      target: '_blank',
      href: website.impressum,
    },
    {
      title: t(
        'account.privacyTerms.privacyPolicyButton.caption',
        'Privacy policy'
      ),
      key: 'privacyPolicy',
      as: 'a',
      target: '_blank',
      href: website.privacyPolicy,
    },
    {
      title: t(
        'account.privacyTerms.termsButton.caption',
        'Terms and conditions'
      ),
      key: 'terms',
      as: 'a',
      target: '_blank',
      href: website.termsAndConditions,
    },
    {
      title: t('account.privacyTerms.securityButton.caption', 'Security'),
      key: 'security',
      as: 'a',
      target: '_blank',
      href: `${website.base}/security/vulnerability-disclosure-policy`,
    },
    {
      title: t(
        'account.privacyTerms.introductoryInformationButton.caption',
        'Introductory Information'
      ),
      key: 'introductoryInformation',
      as: 'a',
      target: '_blank',
      href: legal.introductoryInformation,
    },
  ];

  return (
    <div className="p-container wmx8">
      <NavButton
        title={t('account.backButton.caption', 'Account')}
        path={routes.account.path}
      />

      <h1 className="p-h1 mt8">
        {t('account.privacyAndTerms.title', 'Privacy, terms and security')}
      </h1>

      <ActionsList items={items} />
    </div>
  );
};
