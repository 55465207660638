import { TFunction } from '@getpopsure/i18n-react';

import { ArrowIcon, CelebrateIcon, ClockIcon } from '../assets';
import { Step, StepType } from '../models';
import {
  BankAccount,
  GermanAddress,
  LetterOfAcceptance,
  ProofOfEnrollment,
  ProviderNotifiesUniversity,
} from '../modules';

export const studentChecklistLookup = (currentSteps: Step[], t: TFunction) => {
  const contentLookup: Record<StepType, Omit<Step, 'id' | 'active'>> = {
    LETTER_OF_ACCEPTANCE: {
      number: <ArrowIcon />,
      title: t(
        'studentChecklist.letterOfAcceptance.title',
        'Upload your letter of acceptance'
      ),
      description: t(
        'studentChecklist.letterOfAcceptance.description',
        'This proof of acceptance is provided by your university.'
      ),
      children: <LetterOfAcceptance />,
    },
    PROVIDER_NOTIFIES_UNIVERSITY: {
      number: <ClockIcon />,
      title: t(
        'studentChecklist.providerNotifiesUniversity.title',
        'Provider notifies your university'
      ),
      children: <ProviderNotifiesUniversity />,
    },
    GERMAN_ADDRESS: {
      number: <ArrowIcon />,
      title: t(
        'studentChecklist.germanAddress.title',
        'Confirm your German address'
      ),
      children: <GermanAddress />,
    },
    BANK_ACCOUNT: {
      number: <ArrowIcon />,
      title: t('studentChecklist.bankAccount.title', 'Provide your IBAN'),
      children: <BankAccount />,
    },
    PROOF_OF_ENROLLMENT: {
      number: <ArrowIcon />,
      title: t(
        'studentChecklist.proofOfEnrollment.title',
        'Upload your Immatrikulations-bescheinigung'
      ),
      children: <ProofOfEnrollment />,
    },
    SUBMIT_APPLICATION: {
      number: <ClockIcon />,
      title: t(
        'studentChecklist.submitApplication.title',
        'Provider processes your application'
      ),
    },
    PROCESSING_APPLICATION: {
      number: <CelebrateIcon />,
      title: t(
        'studentChecklist.processingApplication.title',
        'Almost done. The provider is processing your application.'
      ),
      description: t(
        'studentChecklist.processingApplication.description',
        'This can take up to 7 working days.'
      ),
    },
  };

  return currentSteps.map((step) => ({
    ...step,
    ...contentLookup[step.id],
  }));
};
