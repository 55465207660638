import { InfoCard } from '@popsure/dirty-swan';
import SectionHeader from 'components/sectionHeader';
import { CardInfo } from 'models/liability';

import styles from './style.module.scss';

interface Props {
  cardInfo: CardInfo[];
  title?: string;
}

const InfoCardSection = ({ cardInfo, title }: Props) => (
  <>
    {title && <SectionHeader title={title} />}
    <div className={styles['info-card-container']}>
      {cardInfo.map(({ title, description, icon }, key) => (
        <InfoCard
          key={key}
          title={title}
          state="static"
          topIcon={icon}
          className="wmx6 mt16"
        >
          <p className="p-p tc-grey-600 ta-center">{description}</p>
        </InfoCard>
      ))}
    </div>
  </>
);

export default InfoCardSection;
