import { TallyModal } from 'components/TallyModal';
import { PolicySingleModalProps } from 'features/policySingle/components/modals';
import { useSelector } from 'react-redux';
import { getEmail, getUserId } from 'selectors/user';

export const PetHealthClaimModal = (props: PolicySingleModalProps) => {
  const {
    onClose,
    policyData: {
      id,
      attributes: { insuredPerson, startDate, policyNumber },
    },
  } = props;

  const email = useSelector(getEmail);
  const userId = useSelector(getUserId);

  const URLparams = {
    policyId: id,
    policyNumber,
    firstName: insuredPerson?.name?.firstName,
    lastName: insuredPerson?.name?.lastName,
    startDate,
    userId,
    email,
  };

  return (
    <TallyModal
      isVisible
      onClose={onClose}
      tallyId="wAJZWk"
      title="Pet Health Claim"
      URLparams={URLparams}
    />
  );
};
