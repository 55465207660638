import { getCurrentClaim } from 'actions/claims';
import withLoadingSpinner from 'components/withLoadingSpinner';
import connectedLoader, { LoaderProps } from 'containers/loader';
import View from 'features/claimsProcess/claimStatus/view';
import { Claim } from 'models/claims';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from 'reducers';
import { getCurrentClaim as getCurrentClaimState } from 'selectors/claims';

interface StateProps {
  policyId: string;
  claimId: string;
  currentClaim?: Claim;
}

interface DispatchProps {
  fetchClaim: (claimId: string) => void;
}

type OwnProps = RouteComponentProps<{ policyId: string; claimId: string }> &
  LoaderProps;

export type ViewProps = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (
  state: AppState,
  {
    match: {
      params: { policyId, claimId },
    },
  }: OwnProps
): StateProps => {
  const currentClaim = getCurrentClaimState(state);

  return {
    policyId,
    claimId,
    currentClaim,
  };
};

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchClaim: (claimId: string) => dispatch(getCurrentClaim(claimId)),
});

const ConnectedLoaderView = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps
)(
  withLoadingSpinner(View, {
    isLoading: (props: ViewProps) => props.loading || !props.currentClaim,
    loadData: (props: ViewProps) => props.fetchClaim(props.claimId),
  })
);

export default connectedLoader('GET_CURRENT_CLAIM')(ConnectedLoaderView);
