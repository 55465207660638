import classNames from 'classnames';

import { TaskEngineScreenOptions } from './taskEngine.models';
import styles from './TaskEngine.module.scss';

interface TaskEngineViewProps {
  screen?: TaskEngineScreenOptions;
  tasks: JSX.Element[];
}

export const TaskEngineView = (props: TaskEngineViewProps) => {
  const { tasks, screen } = props;

  if (tasks.length === 0) return null;

  return (
    <section className="mb80" data-testid="task-engine-container">
      {screen?.title && <h1 className="p-h3 mb16">{screen.title}</h1>}
      <div className={classNames('d-grid gap16', styles.container)}>
        {tasks}
      </div>
    </section>
  );
};
