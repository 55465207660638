import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import dayjs from 'dayjs';
import { Policy } from 'models/policies';
import { TFunction } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';

import { DATE_FORMAT } from '../../constants';
import { PolicyDetailsInfo } from '../../sections/PolicyDetails/PolicyDetails.models';

export const getGenericInfo = (policy: Policy, t: TFunction) => {
  const {
    insuredPerson,
    policyNumber,
    startDate,
    endDate,
    policyHolder,
    insuredSum,
  } = policy.attributes;
  const infoArray: Array<PolicyDetailsInfo> = [
    {
      id: 'policyNumber',
      title: t('myPolicies.policyDetails.policyNumber', 'Policy number'),
      value: policyNumber,
    },
    {
      title: t('myPolicies.policyDetails.insuredPerson', 'Insured person'),
      value: insuredPerson && capitalizeName(insuredPerson.name),
    },
    {
      id: 'startDate',
      title: t('myPolicies.policyDetails.startDate', 'Start date'),
      value: startDate && dayjs(startDate).format(DATE_FORMAT),
    },
    {
      title: t('myPolicies.policyDetails.endDate', 'End date'),
      value: endDate && dayjs(endDate).format(DATE_FORMAT),
    },
    {
      title: t('myPolicies.policyDetails.coverage', 'Insured sum'),
      value: insuredSum && englishFormattedEuroCurrency(insuredSum, true),
    },
    {
      title: t('myPolicies.policyDetails.policyHolder', 'Policy Holder'),
      value: policyHolder?.name && capitalizeName(policyHolder.name),
    },
  ];

  if (policy.attributes.policyData) {
    Object.entries(policy.attributes.policyData).forEach(([title, value]) =>
      infoArray.push({
        title,
        value,
      })
    );
  }

  return infoArray;
};
