import { TFunction } from '@getpopsure/i18n-react';
import { FAQInfo } from 'components/faq';

export const faqData = (t: TFunction): FAQInfo[] => [
  {
    questionId: 1,
    question: t(
      'dental.qnr.preQuote.quote.faq.eligible.question',
      'How does dental insurance work?'
    ),
    answer: t('dental.qnr.preQuote.quote.faq.eligible.answer', {
      defaultValue:
        "When you receive dental treatments, you'll have to pay for it upfront. Your public insurance might cover some of the treatment, but you’ll only be billed for what’s leftover. You just need to submit the signed bill in your Feather account under the “My policies” page. You’ll be reimbursed directly by us in 3 - 6 weeks depending on the treatment and amount of the claim.",
    }),
  },
  {
    questionId: 2,
    question: t(
      'dental.qnr.preQuote.quote.faq.reimbursement.question',
      'How are dental services reimbursed?'
    ),
    answer: t(
      'dental.qnr.preQuote.quote.faq.reimbursement.answer',
      'We reimburse the costs of dental services in line with the service costs outlined in the Fee Schedule for Dentists (Gebührenordnungen für Zahnärzte, GOZ) and/or the Fee Schedule for Doctors (Gebührenordnungen für Ärzte). We reimburse technical services (e.g. materials and labor costs) at reasonable rates.\n\nPersonal services: Up to 3.5 faktor\n\nLaboratory service: Up to 1.3 faktor\n\nMedical/Technical services: Up to 2.5 faktor'
    ),
  },
  {
    questionId: 3,
    question: t(
      'dental.qnr.preQuote.quote.faq.rootCanals.question',
      'Does supplemental dental insurance cover root canals?'
    ),
    answer: t(
      'dental.qnr.preQuote.quote.faq.rootCanals.answer',
      'Yes, the advanced plan includes an unlimited claimable amount for root canal treatment. The basic plan can cover up to €150 in the first year and up to €300 in the first two years combined with no limit after that.'
    ),
  },
  {
    questionId: 4,
    question: t(
      'dental.qnr.preQuote.quote.faq.crowns.question',
      'Does supplemental dental insurance cover crowns?'
    ),
    answer: t(
      'dental.qnr.preQuote.quote.faq.crowns.answer',
      'Yes, the Feather Advanced dental insurance plan covers up to 90% of the cost of crowns, bridges, implants, inlays, onlays, dentures, and other replacement procedures for up to €1,500 in the first year, €3,000 in the first two years combined; €4,500 in the first three years combined; €6,000 in the first four years combined. After the first four years, there is no limit.'
    ),
  },
  {
    questionId: 5,
    question: t(
      'dental.qnr.preQuote.quote.faq.fillings.question',
      'Does supplemental dental insurance cover fillings?'
    ),
    answer: t(
      'dental.qnr.preQuote.quote.faq.fillings.answer',
      'Yes, the advanced dental plan includes an unlimited claimable amount for high-quality fillings and cosmetically perfect composite fillings. The basic dental plan covers up to €150 in the first year and up to €300 in the first two years combined with no limit after that. In comparison, public insurance only provides basic fillings for back teeth that wouldn’t necessarily match your tooth’s color. Public insurance also only covers high-quality, cosmetic fillings for your front four teeth.'
    ),
  },
  {
    questionId: 6,
    question: t(
      'dental.qnr.preQuote.quote.faq.orthodonticTreatment.question',
      'Does supplemental dental insurance cover orthodontic treatment?'
    ),
    answer: t(
      'dental.qnr.preQuote.quote.faq.orthodonticTreatment.answer',
      "Yes, dental insurance will cover the cost of orthodontic treatments if you're under 21 with up to €150 in the first year and €300 in the first two calendar years combined.\n\nIn case of an accident, your insurance will cover all your orthodontic costs but won't cover any existing orthodontic treatments or hardware."
    ),
  },
  {
    questionId: 7,
    question: t(
      'dental.qnr.preQuote.quote.faq.mouthguards.question',
      'Does supplemental dental insurance cover mouthguards?'
    ),
    answer: t(
      'dental.qnr.preQuote.quote.faq.mouthguards.answer',
      'Yes, dental insurance will cover mouthguards to protect against bruxism, or teeth-grinding.'
    ),
  },
  {
    questionId: 8,
    question: t(
      'dental.qnr.preQuote.quote.faq.preexistingTreatments.question',
      'Does supplemental dental insurance cover pre-existing treatments?'
    ),
    answer: t(
      'dental.qnr.preQuote.quote.faq.preexistingTreatments.answer',
      "No, dental insurance won't cover pre-existing or ongoing treatments, issues, or complaints. It may also be difficult to get dental insurance if you're already missing many teeth."
    ),
  },
  {
    questionId: 9,
    question: t(
      'dental.qnr.preQuote.quote.faq.waitingTimes.question',
      'Are there waiting times?'
    ),
    answer: t(
      'dental.qnr.preQuote.quote.faq.waitingTimes.answer',
      'Nope! There are no waiting times to use dental insurance once the policy becomes active! Just remember that any procedures you had an appointment for or planned before applying for dental insurance won’t be covered, so it’s good to sign up for insurance and then plan for upcoming procedures.'
    ),
  },
];
