import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';

export const useStateManagement = (
  verticalId: string
): {
  questionnaireAnswers: Partial<QuestionnaireQuestions>;
  updateQuestionnaireAnswers: (data: Partial<QuestionnaireQuestions>) => void;
  removeQuestionnaireAnswers: (data: string[]) => void;
} => {
  // For now we need to connect this to Redux until we can refactor the checkout
  // to be independent of any vertical specific information and Redux.
  const dispatch = useDispatch();

  const questionnaireAnswers =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useSelector((state: AppState) => state.genericQuestionnaire[verticalId]) ||
    {};

  const updateQuestionnaireAnswers = (
    data: Partial<QuestionnaireQuestions>
  ) => {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      storeGenericQuestionnaireAnswer(verticalId, data)
    );
  };

  const removeQuestionnaireAnswers = (questionIds: string[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(removeGenericQuestionnaireAnswer(verticalId, questionIds));
  };

  return {
    questionnaireAnswers,
    updateQuestionnaireAnswers,
    removeQuestionnaireAnswers,
  };
};
