import { captureException } from '@sentry/react';
import axios from 'axios';
import { PolicyInfo } from 'features/checkout/models';
import { useEffect, useState } from 'react';
import endpoint from 'shared/api';
import { isMobileApp } from 'shared/util/isMobileApp';

import { createPolicyTerms, downloadDocuments } from './api';
import {
  DownloadStatus,
  PythonBackendInsuranceType,
  SignupsBackendInsuranceType,
} from './models';

const WAIT_TIME = 1000;

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  a.download = filename;

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 150);
  };

  a.addEventListener('click', clickHandler);
  a.click();

  return a;
};

const downloadUrl = (url: string, filename: string) => {
  const a = document.createElement('a');

  a.href = url;
  a.download = filename;

  a.click();

  return a;
};

export const useDownloadStatus = (
  insuranceType: PythonBackendInsuranceType | SignupsBackendInsuranceType,
  hasDownloadedDocs?: boolean
): [DownloadStatus, (policyInfo: PolicyInfo) => void] => {
  const [downloadStatus, setDownloadStatus] =
    useState<DownloadStatus>('INITIAL');

  useEffect(() => {
    if (hasDownloadedDocs === true) {
      setDownloadStatus('COMPLETED');
    }
    // eslint-disable-next-line
  }, []);

  const { network } = endpoint;

  const handleDownload = async (policyInfo: PolicyInfo) => {
    setDownloadStatus('GENERATING');
    try {
      const {
        data: { id },
      } = await createPolicyTerms(network, insuranceType, policyInfo);

      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { data: download } = await downloadDocuments(
          network,
          insuranceType,
          id
        );

        // eslint-disable-next-line no-await-in-loop
        await wait(WAIT_TIME);

        if (download.status === 'READY') {
          // eslint-disable-next-line no-await-in-loop
          const response = await axios.get(download.url, {
            responseType: 'blob',
          });
          if (isMobileApp) {
            downloadUrl(download.url, download.filename);
          } else {
            downloadBlob(response.data, download.filename);
          }
          setDownloadStatus('COMPLETED');
          break;
        }
      }
    } catch (error) {
      captureException(error);
      setDownloadStatus('FAILED');
    }
  };

  return [downloadStatus, handleDownload];
};
