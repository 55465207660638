import { faq } from '@getpopsure/private-constants';
import { TFunction } from 'shared/i18n';

import { DisabilityV2 } from '../models';

export type QuestionTextData = {
  question: string;
  description?: string;
  placeholder?: string;
  placeholderPerUnit?: [string, string];
  additionalInfo?: {
    title: string;
    description: string;
  };
  mapValue?: Record<string, string>;
  options?: Record<string, unknown>;
};

export const questionPropLookup = (
  t: TFunction
): Record<keyof DisabilityV2, QuestionTextData> => ({
  dateOfBirth: {
    question: t('disabilityV2.qnr.dateofbirth.question', 'When were you born?'),
  },
  ageYoungerThan18Blocker: { question: '' },
  ageOlderThan50Blocker: { question: '' },
  occupation: {
    question: t(
      'disabilityV2.qnr.occupation.question',
      'What is your main occupation in Germany?'
    ),
    mapValue: {
      EMPLOYED: t('disabilityV2.qnr.occupation.employed', 'Employed'),
      SELF_EMPLOYED: t(
        'disabilityV2.qnr.occupation.selfEmployed',
        'Self-employed/ Freelancer'
      ),
      STUDENT: t('disabilityV2.qnr.occupation.student', 'Student'),
      UNEMPLOYED: t('disabilityV2.qnr.occupation.unemployed', 'Unemployed'),
      OTHER: t('disabilityV2.qnr.occupation.otherOccupation', 'Other'),
    },
  },
  occupationBlocker: { question: '' },
  profession: {
    question: t(
      'disabilityV2.qnr.occupation.profession.question',
      'What is your job title?'
    ),
    placeholder: t(
      'disabilityV2.qnr.occupation.profession.placeholder',
      'Job title'
    ),
  },
  monthlyNetIncome: {
    question: t(
      'disabilityV2.qnr.occupation.monthlyNetIncome.question',
      'What is your monthly net income in Euros?'
    ),
    placeholder: t(
      'disabilityV2.qnr.occupation.monthlyNetIncome.placeholder',
      'Monthly sum'
    ),
  },
  monthlyNetIncomeBlocker: { question: '' },
  degree: {
    question: t(
      'disabilityV2.qnr.degree.question',
      'What is your highest level of education?'
    ),
    mapValue: {
      NO_DEGREE: t('disabilityV2.qnr.degree.noDegree', 'No degree'),
      HIGH_SCHOOL: t(
        'disabilityV2.qnr.degree.highSchool',
        'High-school diploma'
      ),
      UNIVERSITY_SHORT_TERM: t(
        'disabilityV2.qnr.degree.uniShort',
        'University (1-2 years)'
      ),
      UNIVERSITY_LONG_TERM: t(
        'disabilityV2.qnr.degree.uniLong',
        'University (3+ years)'
      ),
    },
  },
  height: {
    question: t('disabilityV2.qnr.height.question', 'How tall are you?'),
    placeholderPerUnit: [
      t('disabilityV2.qnr.height.placeholder.cm', 'Centimeters'),
      t('disabilityV2.qnr.height.placeholder.ft', 'Feet'),
    ],
  },
  weight: {
    question: t('disabilityV2.qnr.weight.question', 'What is your weight?'),
    placeholderPerUnit: [
      t('disabilityV2.qnr.weight.placeholder.kg', 'Kilograms'),
      t('disabilityV2.qnr.weight.placeholder.lbs', 'Pound'),
    ],
  },
  monthlyPayout: {
    question: '',
  },
  intro: { question: '' },
  quote: { question: '' },
  tallyForm: { question: '' },
  signupIntro: { question: '' },
  review: {
    question: '',
  },
  name: {
    question: t('disabilityV2.qnr.name.question', 'What is your name?'),
  },
  email: {
    question: t('disabilityV2.qnr.email.question', 'What is your email?'),
  },
  citizenship: {
    question: t(
      'disabilityV2.qnr.citizenship.question',
      'Which countries do you hold a passport from?'
    ),
  },
  employer: {
    question: t('disabilityV2.qnr.employer.question', 'Who is your employer?'),
  },
  address: {
    question: t(
      'disabilityV2.qnr.address.question',
      'What is your (German) address?'
    ),
  },
  startDate: {
    question: t(
      'disabilityV2.qnr.startDate.question',
      'When do you want your coverage to start?'
    ),
    additionalInfo: {
      title: t(
        'disabilityV2.qnr.startDate.additionalInfo.title',
        'Coverage start date'
      ),
      description: t(
        'disabilityV2.qnr.startDate.additionalInfo.description',
        'Your coverage begins on the first day of the month you select.'
      ),
    },
  },
  stayAbroad: {
    question: t(
      'disabilityV2.qnr.stayAbroad.question',
      'Are you planning to stay abroad for more than 3 months at one time within the next year?'
    ),
    additionalInfo: {
      title: t(
        'disabilityV2.qnr.stayAbroad.additionalInfo.title',
        'Stay abroad'
      ),
      description: t(
        'disabilityV2.qnr.stayAbroad.additionalInfo.description',
        'This is the time spent outside of Germany'
      ),
    },
  },
  stayAbroadDetails: {
    question: t(
      'disabilityV2.qnr.stayAbroadAdditionalInfo.question',
      'What country are you planning to stay abroad in for more than 3 months at one time within the next year and what is the reason for it?'
    ),
  },
  hasPreviousInsurance: {
    question: t(
      'disabilityV2.qnr.hasPreviousInsurance.question',
      'Have you applied for occupational disability or disability insurance within the past 5 years or were you rejected or accepted with difficulty?'
    ),
  },
  previousInsuranceDetails: {
    question: t(
      'disabilityV2.qnr.previousInsuranceDetails.question',
      'Please explain in more detail.'
    ),
  },
  hasActiveOrPendingInsurance: {
    question: t(
      'disabilityV2.qnr.hasActiveOrPendingInsurance.question',
      'Do you have any other active or pending occupational disability or disability insurance plan(s)?'
    ),
  },
  activeOrPendingInsuranceDetails: {
    question: t(
      'disabilityV2.qnr.activeOrPendingInsuranceDetails.question',
      'Please explain in more detail (Coverage amount and provider)'
    ),
  },
  dynamicPremiums: {
    question: t(
      'disabilityV2.qnr.dynamicPremiums.question',
      'Would you like to have dynamic premiums?'
    ),
    additionalInfo: {
      title: t(
        'disabilityV2.qnr.dynamicPremiums.additionalInfo.title',
        'Dynamic premiums'
      ),
      description: t(
        'disabilityV2.qnr.dynamicPremiums.additionalInfo.description',
        'Your cover sum and premium will automatically increase by 2% yearly to compensate for inflation. You will be notified in good time before each increase and may opt-out if desired. [Learn more]({{dynamicPremiumsFaq}})',
        {
          dynamicPremiumsFaq: faq.disabilityInsuranceDynamicPremiums,
        }
      ),
    },
  },
  isSmoker: {
    question: t('disabilityV2.qnr.isSmoker.question', 'Are you a smoker?'),
    additionalInfo: {
      title: t(
        'disabilityV2.qnr.isSmoker.additionalInfo.title',
        'What counts as smoking?'
      ),
      description: t(
        'disabilityV2.qnr.isSmoker.additionalInfo.description',
        'Using any of these products: cigarettes, marijuana, e-cigarettes, vapes, hookahs, cigars, chewing tobacco, nicotine replacement therapies'
      ),
    },
  },
  hasDegreeOfDisability: {
    question: t(
      'disabilityV2.qnr.hasDegreeOfDisability.question',
      'Do you have a degree of disability (GdB), a need for care or a reduction in earning capacity? Or has a corresponding application been made?'
    ),
  },
  degreeOfDisabilityHealthIssuesDetails: {
    question: t(
      'disabilityV2.qnr.degreeOfDisabilityHealthIssuesDetails.question',
      'What health issue is associated with your degree of disability (GdB)?'
    ),
  },
  degreeOfDisabilityPercentage: {
    question: t(
      'disabilityV2.qnr.degreeOfDisabilityPercentage.question',
      'What degree of disability (GdB) percentage do you have?'
    ),
  },
  degreeOfDisabilityDuration: {
    question: t(
      'disabilityV2.qnr.degreeOfDisabilityDuration.question',
      'For how long have you had a degree of disability (GdB), a need for care or a reduction in earning capacity?'
    ),
  },
  hasReceivedPhysicalExam: {
    question: t(
      'disabilityV2.qnr.hasReceivedPhysicalExam.question',
      'In the last 5 years, have you been examined or treated by a doctor or other medical practitioner?'
    ),
  },
  hasPrescribedMedication: {
    question: t(
      'disabilityV2.qnr.hasPrescribedMedication.question',
      "Have you been prescribed or given any medication by a doctor or practitioner in the last 6 months (Birth control doesn't count)?"
    ),
  },
  prescribedMedicationDetails: {
    question: t(
      'disabilityV2.qnr.prescribedMedicationDetails.question',
      'What kind of medication?'
    ),
  },
  prescribedMedicationDosage: {
    question: t(
      'disabilityV2.qnr.prescribedMedicationDosage.question',
      'What dose did you have to take?'
    ),
  },
  prescribedMedicationDuration: {
    question: t(
      'disabilityV2.qnr.prescribedMedicationDuration.question',
      'How long did you have to take it?'
    ),
  },
  prescribedMedicationReason: {
    question: t(
      'disabilityV2.qnr.prescribedMedicationReason.question',
      'Why was it taken?'
    ),
  },
  hasConsecutiveSickLeave: {
    question: t(
      'disabilityV2.qnr.hasConsecutiveSickLeave.question',
      'In the last 5 years, have you continuously been on sick leave for more than 14 days?'
    ),
  },
  sickLeaveReason: {
    question: t(
      'disabilityV2.qnr.sickLeaveReason.question',
      'Why were you on sick leave?'
    ),
  },
  sickLeaveDuration: {
    question: t(
      'disabilityV2.qnr.sickLeaveDuration.question',
      'From when to when were you on a sick leave?'
    ),
  },
  hasSickLeaveClearance: {
    question: t(
      'disabilityV2.qnr.hasSickLeaveClearance.question',
      'Has the illness been cured?'
    ),
  },
  hasReceivedSubstanceAbuseTreatment: {
    question: t(
      'disabilityV2.qnr.hasReceivedSubstanceAbuseTreatment.question',
      'In the last 5 years, have you received counseling or treatment for the use of alcohol, drugs, or other addictive substances?'
    ),
  },
  hasTreatmentForNervousOrMusculoskeletalIssues: {
    question: t(
      'disabilityV2.qnr.hasTreatmentForNervousOrMusculoskeletalIssues.question',
      'Have you been treated/examined in the last 5 years for illnesses, disorders or complaints of the nervous system or the musculoskeletal system?'
    ),
  },
  nervousOrMusculoskeletalIssuesDetails: {
    question: t(
      'disabilityV2.qnr.nervousOrMusculoskeletalIssuesDetails.question',
      'What were you treated for?'
    ),
  },
  nervousOrMusculoskeletalIssuesTreatmentDuration: {
    question: t(
      'disabilityV2.qnr.nervousOrMusculoskeletalIssuesTreatmentDuration.question',
      'From when to when were you treated?'
    ),
  },
  isCuredFromNervousOrMusculoskeletalTreatment: {
    question: t(
      'disabilityV2.qnr.isCuredFromNervousOrMusculoskeletalTreatment.question',
      'Is the illness completely cured?'
    ),
  },
  hasTreatmentForHeartTumorKidneyDiabetes: {
    question: t(
      'disabilityV2.qnr.hasTreatmentForHeartTumorKidneyDiabetes.question',
      'Have you been treated/examined in the last 5 years for a heart disease, tumor/cancer disease, kidney disease, or diabetes?'
    ),
  },
  hasUsedOrPrescribedMedicalAids: {
    question: t(
      'disabilityV2.qnr.hasUsedOrPrescribedMedicalAids.question',
      'Have you used medical aids in the last 5 years or have you been prescribed such aids?'
    ),
  },
  medicalAidsDetails: {
    question: t(
      'disabilityV2.qnr.medicalAidsDetails.question',
      'What kind of aids?'
    ),
  },
  medicalAidPrescriptionReason: {
    question: t(
      'disabilityV2.qnr.medicalAidPrescriptionReason.question',
      'Why were they prescribed?'
    ),
  },
  medicalAidPrescriptionStartDate: {
    question: t(
      'disabilityV2.qnr.medicalAidPrescriptionStartDate.question',
      'When was it prescribed?'
    ),
  },
  isMedicalAidStillUsed: {
    question: t(
      'disabilityV2.qnr.isMedicalAidStillUsed.question',
      'Do you still use this aid?'
    ),
  },
  hasReceivedPsychoTherapy: {
    question: t(
      'disabilityV2.qnr.hasReceivedPsychoTherapy.question',
      'Do you have or have you had any psychological complaints, disorders, or illnesses in the last 5 years and have you been treated for them?'
    ),
  },
  psychoTherapyDetails: {
    question: t(
      'disabilityV2.qnr.psychoTherapyDetails.question',
      'What exactly were you treated for?'
    ),
  },
  isStillReceivingPsychoTherapy: {
    question: t(
      'disabilityV2.qnr.isStillReceivingPsychoTherapy.question',
      'Are you still being examined, counseled, or treated?'
    ),
  },
  isCuredFromPsychoTherapy: {
    question: t(
      'disabilityV2.qnr.isCuredFromPsychoTherapy.question',
      'Are you completely free of symptoms?'
    ),
  },
  psychoTherapyEndDate: {
    question: t(
      'disabilityV2.qnr.psychoTherapyEndDate.question',
      'Since when are you completely free of symptoms?'
    ),
  },
  hasReceivedHivTreatment: {
    question: t(
      'disabilityV2.qnr.hasReceivedHivTreatment.question',
      'Have you ever been diagnosed, treated or tested positive for HIV (Human Immunodeficiency Virus) or AIDS (Acquired Immunodeficiency Syndrome)?'
    ),
  },

  hasBeenHospitalized: {
    question: t(
      'disabilityV2.qnr.hasBeenHospitalized.question',
      'Have you been hospitalized in the last 10 years or are you planning to be hospitalized in the next 12 months?'
    ),
  },
  hospitalizationReason: {
    question: t(
      'disabilityV2.qnr.hospitalizationReason.question',
      'What exactly were you treated for?'
    ),
  },
  hospitalizationDuration: {
    question: t(
      'disabilityV2.qnr.hospitalizationDuration.question',
      'From when to when were you treated?'
    ),
  },
  isIllnessCured: {
    question: t(
      'disabilityV2.qnr.isIllnessCured.question',
      'Is the illness completely cured?'
    ),
  },

  // Extreme sports
  participatesInRockClimbing: {
    question: t(
      'disabilityV2.qnr.participatesInRockClimbing.question',
      'Do you participate in mountain or rock climbing?'
    ),
  },
  rockClimbingDetails: {
    question: t(
      'disabilityV2.qnr.rockClimbingDetails.question',
      'Which situations apply to you while mountain/rock climbing?'
    ),
  },
  participatesInScubaDiving: {
    question: t(
      'disabilityV2.qnr.participatesInScubaDiving.question',
      'Do you participate in scuba diving?'
    ),
  },
  scubaDivingDetails: {
    question: t(
      'disabilityV2.qnr.scubaDivingDetails.question',
      'Which situations apply to you when you go scuba diving?'
    ),
  },

  participatesInBoulderingAndMountainSports: {
    question: t(
      'disabilityV2.qnr.participatesInBoulderingAndMountainSports.question',
      'Do you participate in bouldering or mountain sports?'
    ),
  },
  participatesInExtremeMountainSports: {
    question: t(
      'disabilityV2.qnr.participatesInExtremeMountainSports.question',
      'Do you do mountain sports at altitudes above 5,300m, solo? Or are you doing alpine or ice climbing, heli-skiing or extreme skiing? '
    ),
  },

  participatesInMartialArts: {
    question: t(
      'disabilityV2.qnr.participatesInMartialArts.question',
      'Do you participate in martial arts?'
    ),
  },
  participatesInCompetitiveMartialArts: {
    question: t(
      'disabilityV2.qnr.participatesInCompetitiveMartialArts.question',
      'Do you compete in martial arts'
    ),
  },

  participatesInHorseRiding: {
    question: t(
      'disabilityV2.qnr.participatesInHorseRiding.question',
      'Do you participate in horse riding?'
    ),
  },
  participatesInEquestrianSports: {
    question: t(
      'disabilityV2.qnr.participatesInEquestrianSports.question',
      'Do you do eventing, galloping or harness racing? Or rodeo or show jumping?'
    ),
  },

  participatesInCompetitiveRacing: {
    question: t(
      'disabilityV2.qnr.participatesInCompetitiveRacing.question',
      'Do you participate in competitive racing (e.g. motorsport, downhill biking)?'
    ),
  },

  participatesInAviationSports: {
    question: t(
      'disabilityV2.qnr.participatesInAviationSports.question',
      'Do you participate in aviation sports (e.g. bungee jumping, skydiving)?'
    ),
  },
  checkoutInfo: {
    question: '',
  },
  reviewCheckout: {
    question: '',
  },
  checkout: {
    question: '',
  },
  quoteId: {
    question: '',
  },
  questionnaireId: {
    question: '',
  },
  hasDownloadedDocs: {
    question: '',
  },
  downloadDocuments: {
    question: '',
  },
  postSignupBlocker: { question: '' },
  jobCollar: {
    question: '',
  },
  hasEligibilityCheck: {
    question: '',
  },
});
