import { Address, isPartialAddressValid } from '@getpopsure/public-models';
import { AutocompleteAddress } from '@popsure/dirty-swan';
import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import QuestionnaireWrapper from '../QuestionnaireWrapper';

interface Props
  extends Omit<QuestionnaireFormProps<Address>, 'value'>,
    QuestionnaireWrapperProps {
  value?: Partial<Address>;
  placeholders?: {
    manualAddressEntry?: string;
    street?: string;
    houseNumber?: string;
    additionalInformation?: string;
    postcode?: string;
    city?: string;
  };
  manualAddressEntryTexts?: { preText?: string; cta?: string };
  loading?: boolean;
}

export const AddressForm = ({
  value,
  onSubmitValue,
  question,
  description,
  buttonText,
  featureName,
  skipDetails,
  error,
  placeholders,
  manualAddressEntryTexts,
  loading,
}: Props) => {
  const { t } = useSafeTranslation();

  const [address, setAddress] = useState<Partial<Address> | undefined>(value);

  const validForSubmission = address && isPartialAddressValid(address);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!address) {
      throw new Error(
        `[${featureName}] Address is missing for submission for question: ${question}`
      );
    }
    if (isPartialAddressValid(address)) {
      onSubmitValue(address);
    }
  };

  return (
    <QuestionnaireWrapper
      onSubmit={handleSubmit}
      question={question}
      description={description}
      error={error}
      buttonText={buttonText}
      validForSubmission={!!validForSubmission}
      skipDetails={skipDetails}
      loading={loading}
      noMaxWidth
    >
      <div data-cy="autocomplete-address-wrapper">
        <AutocompleteAddress
          address={address}
          onAddressChange={setAddress}
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
          placeholders={
            placeholders || {
              manualAddressEntry: t(
                'qnf.forms.address.manualaddressentry.placeholder',
                'Search for address'
              ),
              street: t('qnf.forms.address.street.placeholder', 'Street'),
              houseNumber: t(
                'qnf.forms.address.housenumber.placeholder',
                'House Number'
              ),
              additionalInformation: t(
                'qnf.forms.address.additionalinfo.placeholder',
                'Additional information (C/O, apartment, ...)'
              ),
              postcode: t('qnf.forms.address.postcode.placeholder', 'Postcode'),
              city: t('qnf.forms.address.city.placeholder', 'City'),
            }
          }
          manualAddressEntryTexts={
            manualAddressEntryTexts || {
              preText: t('qnf.forms.address.manualaddressentry.pretext', 'Or '),
              cta: t(
                'qnf.forms.address.manualaddressentry.cta',
                'enter address manually'
              ),
            }
          }
        />
      </div>
    </QuestionnaireWrapper>
  );
};
