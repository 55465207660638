import { trackTaskEngineCardClicked } from '@getpopsure/analytics';
import { toast } from '@popsure/dirty-swan';
import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import routes from 'constants/routes';
import { TASK_ENGINE_SENTRY_CATEGORY } from 'features/taskEngine/constants';
import { FormEvent, useContext, useState } from 'react';
import { useLocation } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import { updateTask } from '../../api/taskEngine.api';
import { CustomTask } from '../../components/CustomTask';
import type { UploadedFile } from '../../hooks/useFileUpload';
import {
  BikeReceiptsUpdateDescriptionSchema,
  Task,
  TaskEngineOptions,
  UploadReceiptsTaskDataType,
} from '../../taskEngine.models';
import { TaskEngineContext } from '../../utils/taskEngine.context';
import { updateTaskMutationErrorHandler } from '../../utils/updateTaskMutationErrorHandler';

export const BikeReceiptsUpdate = ({
  task,
  options,
}: {
  task: Task;
  options?: TaskEngineOptions;
}) => {
  const { t } = useSafeTranslation();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const {
    files: { uploadedFiles, resetUploadedFiles },
  } = useContext(TaskEngineContext);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (payload: UploadReceiptsTaskDataType) => {
      return updateTask({
        taskId: task.id,
        payload,
      });
    },
    onSuccess: async () => {
      toast(
        t('taskEngine.bikeReceiptsUpdate.toast.success', 'Task completed'),
        { type: 'success' }
      );
      await queryClient.invalidateQueries({ queryKey: ['tasks'] });
      if (location.pathname === routes.me.policies.detail.path)
        // TODO: replace with invalidateQueries once redux fetchPolicyDetail is replaced with tanstack
        window.location.reload();
    },
  });

  const parsedTask = BikeReceiptsUpdateDescriptionSchema.safeParse(
    task.description
  );

  if (!parsedTask.success) {
    Sentry.captureException('Failed to parse system task', {
      tags: {
        feature: TASK_ENGINE_SENTRY_CATEGORY,
      },
      extra: {
        task,
        validationErrors: parsedTask.error,
      },
    });
    return null;
  }

  const onOpen = () => {
    trackTaskEngineCardClicked({
      task_type: task.description.type,
      task_id: task.id,
    });

    if (options?.onTaskClick) {
      options.onTaskClick(task);
    }

    setIsOpen(true);
  };

  const onClose = () => {
    resetUploadedFiles();
    setIsOpen(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      uploadIds: uploadedFiles.map(
        (uploadedFile: UploadedFile) => uploadedFile.id
      ),
    };

    mutation.mutate(data, {
      onSuccess: () => {
        resetUploadedFiles();
        setIsOpen(false);
      },
      onError: (error) => updateTaskMutationErrorHandler(error),
    });
  };

  const subtitle = `${t('taskEngine.bikeReceiptsUpdate.subtitle', 'Bike')} · ${
    parsedTask.data.attributes.brand
  } ${parsedTask.data.attributes.model}`;

  return (
    <div>
      <CustomTask
        metadata={{
          title: t(
            'taskEngine.bikeReceiptsUpdate.title',
            'Upload purchase receipts'
          ),
          subtitle,
          description: t(
            'taskEngine.bikeReceiptsUpdate.description',
            'Upload the purchase receipt for your bike, lock, and other accessories.'
          ),
        }}
        modal={{
          isOpen,
          onOpen,
          onClose,
        }}
        onSubmit={handleSubmit}
        actions={[
          {
            id: '1',
            type: 'FILE_QUESTIONNAIRE',
            attributes: {
              onValidate: () => uploadedFiles.length > 0,
            },
          },
          {
            id: '2',
            type: 'SUBMIT',
            attributes: {
              value: t('taskEngine.bikeReceiptsUpdate.submit.title', 'Submit'),
            },
          },
        ]}
      />
    </div>
  );
};
