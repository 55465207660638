import { TFunction } from '@getpopsure/i18n-react';
import {
  ArthritisIcon,
  CalendarIcon,
  ClockTimeIcon,
  EuroClaimIcon,
  GlassIcon,
  GlobeNetworkEarthIcon,
  PawPetIcon,
  SmartphoneIcon,
  SofaIcon,
  SpeakerIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const dogLiability = (t: TFunction): AdditionalInfoType => ({
  price: 5.58,
  description: t(
    'policyModal.dogLiability.description',
    "Dog liability insurance pays for the damage your dog causes to third parties. As a dog owner, you are legally responsible for your dog's actions. It's mandatory in some regions of Germany (Berlin, Brandenburg, Hamburg, Lower Saxony, Thuringia and Schleswig-Holstein) and in certain parts of Europe."
  ),
  accordions: [
    {
      title: t('policyModal.dogLiability.title1', "What's covered?"),
      items: [
        {
          id: 'covered-1',
          icon: <ArthritisIcon size={24} />,
          question: t(
            'policyModal.dogLiability.covered1.question',
            'Medical injuries to other people'
          ),
          answer: t(
            'policyModal.dogLiability.covered1.answer',
            'If your dog gets excited and runs into someone, causing them to fall and hurt themselves, your policy will cover the expenses.'
          ),
        },
        {
          id: 'covered-2',
          icon: <SofaIcon size={24} />,
          question: t(
            'policyModal.dogLiability.covered2.question',
            'Damage to rented apartments'
          ),
          answer: t(
            'policyModal.dogLiability.covered2.answer',
            'If your dog chews up furniture you rented, the coverage will make sure you can keep your security deposit.'
          ),
        },
        {
          id: 'covered-3',
          icon: <SpeakerIcon size={24} />,
          question: t(
            'policyModal.dogLiability.covered3.question',
            'Property damage'
          ),
          answer: t(
            'policyModal.dogLiability.covered3.answer',
            "You'll be covered if your dog damages someone's belongings (e.g. your friend’s carpet or laptop)."
          ),
        },
        {
          id: 'covered-4',
          icon: <EuroClaimIcon size={24} />,
          question: t(
            'policyModal.dogLiability.covered4.question',
            'Financial loss'
          ),
          answer: t(
            'policyModal.dogLiability.items.answer4',
            "If your dog bites the mailperson and they can't work after that, dog liability insurance will cover their wages."
          ),
        },
      ],
    },
    {
      title: t('policyModal.dogLiability.title2', "What's not covered"),
      items: [
        {
          id: 'not-covered-1',
          icon: <SofaIcon size={24} />,
          question: t(
            'policyModal.dogLiability.notCovered1.question',
            'Damage to your own property'
          ),
          answer: t(
            'policyModal.dogLiability.notCovered1.answer',
            'Replacement or repair of things you own is not covered.'
          ),
        },
        {
          id: 'not-covered-2',
          icon: <SmartphoneIcon size={24} />,
          question: t(
            'policyModal.dogLiability.notCovered2.question',
            'Rented electronics'
          ),
          answer: t(
            'policyModal.dogLiability.notCovered2.answer',
            'If your dog damages an electronic device you rented from someone else, it’s not covered.'
          ),
        },
        {
          id: 'not-covered-3',
          icon: <GlassIcon size={24} />,
          question: t(
            'policyModal.dogLiability.notCovered3.question',
            'Broken glass'
          ),
          answer: t(
            'policyModal.dogLiability.notCovered3.answer',
            'If you’re renting a flat, glass breakage isn’t covered. You can get our household insurance to cover broken glass.'
          ),
        },
        {
          id: 'not-covered-4',
          icon: <PawPetIcon size={24} />,
          question: t(
            'policyModal.dogLiability.notCovered4.question',
            'Dangerous breeds'
          ),
          answer: t(
            'policyModal.dogLiability.notCovered4.answer',
            'Purebred dogs of the following breeds are considered dangerous and, unfortunately, cannot be covered.\n- American PitBull Terrier\n- American Staffordshire Terrier\n- Bullmastiff\n- Bullterrier\n- Dogo Argentino\n- Dogue de Bordeaux\n- Fila Brasileiro\n- Mastiff\n- Mastín Español\n- Mastino Napoletano\n- Pitbull-Terrier\n- Rottweiler\n- Staffordshire Bullterrier\n- Tosa Inu'
          ),
        },
      ],
    },
  ],
  details: [
    {
      icon: GlobeNetworkEarthIcon,
      title: t('policyModal.dogLiability.details.title1', 'Worldwide coverage'),
      description: t(
        'policyModal.dogLiability.details.description1',
        'If you permanently move away from Germany you can keep your dog liability insurance coverage if you want'
      ),
    },
    {
      icon: ClockTimeIcon,
      title: t('policyModal.dogLiability.details.title2', 'No waiting periods'),
      description: t(
        'policyModal.dogLiability.details.description2',
        'You can use your insurance right away'
      ),
    },
    {
      icon: CalendarIcon,
      title: t(
        'policyModal.dogLiability.details.title3',
        'Monthly cancellation'
      ),
      description: t(
        'policyModal.dogLiability.details.description3',
        'Simply cancel before the renewal date'
      ),
    },
  ],
  buttonText: t('policyModal.dogLiability.buttonText', 'Continue to quote'),
});
