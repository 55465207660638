import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { WaveHandIcon } from 'components/waveHandIcon/WaveHandIcon';
import { useEffect } from 'react';

type Props<Questionnaire extends QuestionnaireQuestions> = {
  title: string;
  subTitle: string;
} & QuestionnaireFormProps<Questionnaire, boolean>;

export const CustomIntro = <Questionnaire extends QuestionnaireQuestions>({
  title,
  subTitle,
  onSubmitValue,
  setValidForSubmission,
  icon,
  iconTitle,
}: {
  icon?: {
    src: string;
    alt: string;
    width?: string;
    height?: string;
  };
  iconTitle?: string;
} & Props<Questionnaire>) => {
  const handleSubmit = () => {
    onSubmitValue(true);
  };

  useEffect(() => {
    setValidForSubmission(true);
  });

  return (
    <Header onSubmit={handleSubmit}>
      <div className="p-body">
        {iconTitle && (
          <div className="mb8 fw-bold tc-primary-500">{iconTitle}</div>
        )}
        {icon ? (
          <img {...icon} src={icon.src} alt={icon.alt} />
        ) : (
          <WaveHandIcon />
        )}
        <h1 className="p-h1 mt16">{title}</h1>
        <p className="p-p mt16">{subTitle}</p>
      </div>
    </Header>
  );
};
