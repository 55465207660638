import * as Sentry from '@sentry/react';
import { CollapsibleCard } from 'components/CollapsibleCard';
import {
  RecommendationData,
  RecommendationType,
  VerticalCategory,
} from 'features/recommendationTool/models/recommendation';
import { formatDecimalCurrency } from 'features/recommendationTool/utils/formatDecimalCurrency';
import { useSelector } from 'react-redux';
import { getValidAnswers } from 'selectors/recommendationTool';
import { useSafeTranslation } from 'shared/i18n';

import { adviceLookup, cardTextLookup, sectionLookup } from '../../lookups';
import { SectionCard } from '../SectionCard';
import styles from './RecommendationSection.module.scss';
import { recommendationCompareFn } from './utils';

const healthRecommendationKeys: RecommendationType[] = [
  'PUBLIC_HEALTH',
  'PRIVATE_HEALTH',
  'EXPAT_HEALTH',
  'EHIC_HEALTH',
];

export const filtersByCategory = (
  category: VerticalCategory
): RecommendationType[] => {
  let keys: RecommendationType[] = [];
  if (category === 'HEALTH_INSURANCE') {
    keys = healthRecommendationKeys;
  } else {
    keys = [category];
  }
  return keys;
};

export const RecommendationSection = ({
  recommendationData,
  category,
}: {
  category: VerticalCategory;
  recommendationData: RecommendationData;
}) => {
  const { t } = useSafeTranslation();
  const answers = useSelector(getValidAnswers);
  const sortedRecommendationData = recommendationData
    .slice(0)
    .sort(recommendationCompareFn);

  return (
    <>
      {sortedRecommendationData.map(
        ({
          key,
          cards,
          eligibilityStatus,
          options,
          price,
          quickAssessment,
        }) => {
          const finalPrice = price || quickAssessment?.price;
          const isIneligible = eligibilityStatus === 'INELIGIBLE';

          if (key === 'EHIC_HEALTH' && isIneligible) {
            return null;
          }

          if (!filtersByCategory(category).includes(key)) {
            return null;
          }

          const infoCards = cards.filter(
            (card) => card.type === 'INELIGIBLE' || card.type === 'PARTIAL'
          );

          const proCards = cards.filter((card) => card.type === 'PRO');
          const conCards = cards.filter((card) => card.type === 'CON');
          const expertLink = sectionLookup[key]?.status?.[eligibilityStatus]
            .expert?.caption
            ? `[${sectionLookup[key]?.status?.[eligibilityStatus].expert?.caption}](${sectionLookup[key].status?.[eligibilityStatus].expert?.href})`
            : '';

          let { title, icon, icon2x, icon3x } = sectionLookup[key];
          const { description } = sectionLookup[key];

          const sectionDescription =
            typeof description === 'string'
              ? description
              : description?.(answers, options);

          if (options?.advice && !healthRecommendationKeys.includes(key)) {
            ({ title, icon, icon2x, icon3x } = adviceLookup[options.advice]);
          }

          const { totalLivingSpace } = answers;
          const recommendedHouseholdSum =
            isIneligible && key === 'HOUSEHOLD' && totalLivingSpace ? (
              <div>
                Based on the size of your home ({totalLivingSpace} m²), we
                recommend an insured sum of around
                <span className="ml8 mr8 fw-bold">
                  {formatDecimalCurrency(totalLivingSpace * 500)}
                </span>
                to{' '}
                <span className="mr8 fw-bold">
                  {formatDecimalCurrency(totalLivingSpace * 750)}
                </span>
                depending on the value of your belongings.
              </div>
            ) : undefined;

          const recommendedCoverageSum = options?.recommendedCoverage ? (
            <>
              <div className="tc-grey-700 mb8">
                {t(
                  'recommendationtool.recommendations.recommendedSum',
                  'Recommended insured sum:'
                )}
                <span className="ml8 fw-bold">
                  {formatDecimalCurrency(options.recommendedCoverage)}
                </span>
              </div>
              <div>
                {t(
                  'recommendationtool.recommendations.recommendedSumDescription',
                  'This is our recommended insured sum which is the estimated amount of coverage you need based on your answers.'
                )}
              </div>
            </>
          ) : undefined;

          return (
            <SectionCard
              key={key}
              icon={icon}
              icon2x={icon2x}
              icon3x={icon3x}
              title={title}
              description={`${sectionDescription} ${expertLink}`}
              coverageDetails={
                recommendedCoverageSum || recommendedHouseholdSum
              }
              content={
                <>
                  {!!infoCards.length && (
                    <div className="mt32 w100">
                      {infoCards.map(({ key: InfoCardKey, type }) => {
                        if (!cardTextLookup[InfoCardKey]) {
                          Sentry.captureException(
                            `Key ${InfoCardKey} does not exist in HealthRecommendationTool lookup table`
                          );
                          return null;
                        }
                        return (
                          <CollapsibleCard
                            title={cardTextLookup[InfoCardKey].title}
                            type={type}
                            key={InfoCardKey}
                            openByDefault
                          >
                            {cardTextLookup[InfoCardKey].description}
                          </CollapsibleCard>
                        );
                      })}
                    </div>
                  )}
                  {(!!proCards.length || !!conCards.length) && (
                    <div className={`d-grid gap16 mt32 w100 ${styles.grid}`}>
                      <div className="d-flex fd-column gap16">
                        {proCards.length ? (
                          proCards.map(({ key: proCardKey, type }) => {
                            if (!cardTextLookup[proCardKey]) {
                              Sentry.captureException(
                                `Key ${proCardKey} does not exist in HealthRecommendationTool lookup table`
                              );
                              return null;
                            }
                            return (
                              <CollapsibleCard
                                title={cardTextLookup[proCardKey].title}
                                type={type}
                                gridColumn="LEFT"
                                key={proCardKey}
                              >
                                {cardTextLookup[proCardKey].description}
                              </CollapsibleCard>
                            );
                          })
                        ) : (
                          <div className="bg-grey-100 br8 tc-grey-600 p24">
                            {t(
                              'recommendationtool.recommendations.noReasons',
                              'No strong reasons for it'
                            )}
                          </div>
                        )}
                      </div>
                      <div className="d-flex fd-column gap16">
                        {conCards.length ? (
                          conCards.map(({ key: conCardKey, type }) => {
                            if (!cardTextLookup[conCardKey]) {
                              Sentry.captureException(
                                `Key ${conCardKey} does not exist in HealthRecommendationTool lookup table`
                              );
                              return null;
                            }
                            return (
                              <CollapsibleCard
                                title={cardTextLookup[conCardKey].title}
                                type={type}
                                gridColumn="RIGHT"
                                key={conCardKey}
                              >
                                {cardTextLookup[conCardKey].description}
                              </CollapsibleCard>
                            );
                          })
                        ) : (
                          <div className="bg-grey-100 br8 tc-grey-600 p24">
                            {t(
                              'recommendationtool.recommendations.noReasonsAgainst',
                              'No strong reasons against'
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              }
              price={
                !isIneligible && finalPrice
                  ? formatDecimalCurrency(finalPrice)
                  : undefined
              }
              CTA={
                <a
                  className={`p-btn--primary d-inline-flex ai-center ${styles.cta}`}
                  href={sectionLookup[key].status?.[eligibilityStatus].cta.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  {sectionLookup[key].status?.[eligibilityStatus].cta.caption}
                </a>
              }
            />
          );
        }
      )}
    </>
  );
};
