import classNames from 'classnames';

import styles from './style.module.scss';

interface Props {
  title: string;
  className?: string;
  greyUnderline?: boolean;
  centeredOnMobile?: boolean;
}

const SectionHeader = ({
  title,
  className,
  greyUnderline = false,
  centeredOnMobile = false,
}: Props) => (
  <div
    className={classNames('d-flex fd-column', {
      [styles.centeredOnMobile]: centeredOnMobile,
    })}
  >
    <h2 className={classNames('p-h2 p--serif', className)}>{title}</h2>
    <div
      className={classNames('ws2', styles.headerUnderline, {
        [styles.headerUnderlineGrey]: greyUnderline,
      })}
    />
  </div>
);

export default SectionHeader;
