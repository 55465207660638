import { NavButton } from 'components/NavButton';
import PageNotFound from 'components/pageNotFound';
import { QuestionnaireError } from 'features/questionnaireFramework/models';
import { kebabCaseToCamelCase } from 'features/questionnaireFramework/utils/camelCaseToKebapCase';
import { getQuestionComponent } from 'features/questionnaireFramework/utils/getQuestionComponent';
import { getQuestionObject } from 'features/questionnaireFramework/utils/getQuestionObject';
import { FeatureName } from 'models/questionnaire';
import {
  AnswerTypes,
  PartialQuestionnaire,
  Question,
  RoutesObject,
} from 'models/questionnaireFramework';
import React from 'react';
import { useParams } from 'react-router-dom';
import { isMobileApp } from 'shared/util/isMobileApp';

import styles from './QuestionComponent.module.scss';

// eslint-disable-next-line @typescript-eslint/ban-types
interface QuestionProps<Questionnaire extends Object, GroupId> {
  questionnaireAnswers: Partial<Questionnaire>;
  routes: RoutesObject<Questionnaire>;
  featureName: FeatureName;
  questionnaire: PartialQuestionnaire<Questionnaire, GroupId>;
  onSubmitValue: (
    currentId: keyof Questionnaire,
    questionObject: Question<Questionnaire, GroupId>,
    answer: AnswerTypes
  ) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  baseUrl?: string;
  error: string | null;
  sendQuestionnaireError?: (error: QuestionnaireError) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const QuestionComponent = <Questionnaire extends Object, GroupId>({
  questionnaire,
  onSubmitValue,
  featureName,
  questionnaireAnswers,
  routes,
  error,
  sendQuestionnaireError,
}: QuestionProps<Questionnaire, GroupId>) => {
  const { questionId } = useParams<{
    groupId: string;
    questionId: string;
  }>();
  const question = kebabCaseToCamelCase(questionId) as keyof Questionnaire;
  const questionObject: Question<Questionnaire, GroupId> = getQuestionObject(
    questionnaire,
    question
  );

  if (!questionObject) return <PageNotFound />;

  const { type } = questionObject;

  const handleSubmitValue = (answer: AnswerTypes) => {
    if (typeof answer !== undefined) {
      onSubmitValue(question, questionObject, answer);
    }
  };

  const newProps = {
    key: String(question),
    featureName,
    questionnaireAnswers,
    onSubmitValue: handleSubmitValue,
    ...(error ? { error } : {}),
    ...(sendQuestionnaireError ? { sendQuestionnaireError } : {}),
  };

  if (type === 'CUSTOM') {
    if (
      !questionObject.component ||
      React.isValidElement(questionObject.component)
    ) {
      throw new Error(
        `[QUESTIONNAIRE_FRAMEWORK]: invalid component used in ${featureName} - ${String(
          question
        )}`
      );
    }

    return React.createElement(questionObject.component, {
      routes,
      value:
        questionObject.transformValue?.(questionnaireAnswers[question]) ||
        questionnaireAnswers[question],
      ...newProps,
      ...(questionObject?.metaData
        ? { metaData: questionObject.metaData }
        : {}),
    });
  }

  const { defaultValue, ...questionObjectProps } = questionObject.props;

  const component = getQuestionComponent(type);

  return (
    <>
      {isMobileApp && (
        <div className={styles.hideOnDesktop}>
          <NavButton title="Previous Question" renderInPortal />
        </div>
      )}

      {React.createElement(component, {
        ...newProps,
        ...questionObjectProps,
        /**
         * Prefill the form with the questionnaire answer
         * or with the default value that comes from the props
         */
        value:
          questionObject.transformValue?.(
            questionnaireAnswers[question] ?? defaultValue
          ) ||
          (questionnaireAnswers[question] ?? defaultValue),
      })}
    </>
  );
};

export default QuestionComponent;
