import { dangerousBreeds } from '@getpopsure/public-models';
import {
  getRoutes,
  QuestionnaireQuestions,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import { useCheckoutStarted } from 'hooks/useCheckoutStarted';
import { UserWithBrokerMandate } from 'models/user';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { AppState } from 'reducers';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { Dog } from './models';
import { DogComponents, getTranslatedQuestionnaire } from './questionnaire';

const getRules = (): Rule<Dog>[] => [
  {
    id: 'isLivingInGermany',
    if: {
      op: 'equals',
      variable: { type: 'static', value: false },
    },
    then: {
      goTo: 'nonGermanResidentBlocker',
    },
  },
  {
    id: 'numberOfPreviousClaims',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'ONE' },
    },
    then: {
      goTo: 'previousClaimsTotalAmount',
    },
  },
  {
    id: 'numberOfPreviousClaims',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'TWO_OR_MORE' },
    },
    then: {
      goTo: 'pastClaimsBlocker',
    },
  },
  {
    id: 'previousClaimsTotalAmount',
    if: {
      op: 'greaterThan',
      variable: { type: 'static', value: 1500 },
    },
    then: {
      goTo: 'pastClaimsBlocker',
    },
  },
  {
    id: 'isMixedBread',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'NO' },
    },
    then: {
      goTo: 'breed',
    },
  },
  {
    id: 'breed',
    if: {
      op: 'isIn',
      variable: { type: 'static', value: dangerousBreeds },
    },
    then: {
      goTo: 'dangerousBreedsBlocker',
    },
  },
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'year',
        value: 18,
      },
    },
    then: {
      goTo: 'address',
    },
    else: {
      goTo: 'ageBlocker',
    },
  },
];

const removeReview: RemoveAnswerType<Dog> = {
  op: 'always',
  questions: ['review'],
};

const removeAnswersLogic: Partial<Record<keyof Dog, RemoveAnswerType<Dog>>> = {
  quote: removeReview,
  dateOfBirth: removeReview,
  breed: removeReview,
  numberOfPreviousClaims: removeReview,
  isLivingInGermany: removeReview,
  name: removeReview,
  email: removeReview,
  dogName: removeReview,
  previousClaimsTotalAmount: removeReview,
  address: removeReview,
  isMixedBread: {
    op: 'always',
    questions: ['breed', 'review'],
  },
};

const prefillNameAndBirthDate = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<Dog> = {};
    const { firstName, lastName, dateOfBirth } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    if (dateOfBirth) {
      toUpdate = { ...toUpdate, dateOfBirth };
    }
    return toUpdate;
  }

  return null;
};

export const SignupPage = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.genericQuestionnaire.dog) || {};
  const dispatch = useDispatch();
  const account = useSelector(getAccountInfo);
  const { t } = useSafeTranslation();

  const onAnswer = <QuestionId extends keyof Dog>(
    questionId: QuestionId,
    answer: Dog[QuestionId]
  ) => {
    dispatch(storeGenericQuestionnaireAnswer('dog', { [questionId]: answer }));

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(removeGenericQuestionnaireAnswer('dog', answersToRemove));

    const toUpdate = prefillNameAndBirthDate(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );
    if (toUpdate) {
      dispatch(storeGenericQuestionnaireAnswer('dog', toUpdate));
    }
  };

  const questions = getTranslatedQuestionnaire(t);

  const rules = getRules();

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, routes.policies.dogLiability.path),
    rules,
  };

  useCheckoutStarted('DOG_LIABILITY', questionnaireAnswers?.email);

  return (
    <Switch>
      <Route path={routes.policies.dogLiability.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: DogComponents,
          }}
          basePath={routes.policies.dogLiability.path}
          questionId="intro"
          featureName="Dog"
          share={{
            verticalId: 'dog',
          }}
        />
      </Route>
    </Switch>
  );
};
