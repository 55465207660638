import { RemoveAnswerType, Rule } from '@getpopsure/qnr-framework';

import { Legal } from '../models';

export const getRules = (): Rule<Legal>[] => [
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'year',
        value: 18,
      },
    },
    then: {
      goTo: 'postcode',
    },
    else: {
      goTo: 'under18Blocker',
    },
  },
  {
    id: 'employmentStatus',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: 'SELF_EMPLOYED',
      },
    },
    then: {
      goTo: 'selfEmployedNotice',
    },
  },
  {
    id: 'hadGermanLegalInsurance',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'insurer',
    },
  },
  {
    id: 'insurer',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'numberOfPreviousClaims',
    },
  },
  {
    id: 'numberOfPreviousClaims',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: 'NONE',
      },
    },
    then: {
      goTo: 'terminationParty',
    },
  },
  {
    id: 'numberOfPreviousClaims',
    if: {
      op: 'isIn',
      variable: {
        type: 'static',
        value: ['ONE', 'TWO', 'THREE'],
      },
    },
    then: {
      goTo: 'yearsSinceLastClaim',
    },
  },
  {
    id: 'numberOfPreviousClaims',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: 'MORE_THAN_THREE',
      },
    },
    then: {
      goTo: 'numberOfPreviousClaimsBlocker',
    },
  },
  {
    id: 'yearsSinceLastClaim',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'terminationParty',
    },
  },
  {
    id: 'terminationParty',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: 'MYSELF',
      },
    },
    then: {
      goTo: 'endDateOfPreviousInsurance',
    },
  },
  {
    id: 'terminationParty',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: 'INSURER',
      },
    },
    then: {
      goTo: 'terminationPartyBlocker',
    },
  },
  {
    id: 'terminationParty',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: 'STILL_ACTIVE',
      },
    },
    then: {
      goTo: 'preQuoteReview',
    },
  },
  {
    id: 'endDateOfPreviousInsurance',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'preQuoteReview',
    },
  },
  {
    id: 'signupIntro',
    if: (_answer, answers) => {
      return answers.quote?.addons?.includes('CRIMINAL') ?? false;
    },
    then: {
      goTo: 'hasCriminalConvictions',
    },
  },
  {
    id: 'hasCriminalConvictions',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasCriminalConvictionsBlocker',
    },
    else: {
      goTo: 'hasPendingCriminalInvestigation',
    },
  },
  {
    id: 'hasPendingCriminalInvestigation',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasPendingCriminalInvestigationBlocker',
    },
  },
  {
    id: 'hasPendingCriminalInvestigationBlocker',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'email',
    },
  },
  {
    id: 'address',
    if: (_value, answers) => {
      return answers.includeSpouse === true;
    },
    then: {
      goTo: 'partnerName',
    },
  },
];

export const removeAnswersLogic: Partial<
  Record<keyof Legal, RemoveAnswerType<Legal>>
> = {
  includeSpouse: {
    op: 'always',
    questions: ['partnerName'],
  },
  hadGermanLegalInsurance: {
    op: 'always',
    questions: [
      'insurer',
      'numberOfPreviousClaims',
      'numberOfPreviousClaimsBlocker',
      'yearsSinceLastClaim',
      'terminationParty',
      'terminationPartyBlocker',
      'endDateOfPreviousInsurance',
      'preQuoteReview',
    ],
  },
  yearsSinceLastClaim: {
    op: 'always',
    questions: ['preQuoteReview'],
  },
  terminationParty: {
    op: 'always',
    questions: [
      'terminationPartyBlocker',
      'endDateOfPreviousInsurance',
      'preQuoteReview',
    ],
  },
  preQuoteReview: {
    op: 'always',
    questions: ['review'],
  },
  quote: {
    op: 'always',
    questions: [
      'hasCriminalConvictions',
      'hasPendingCriminalInvestigation',
      'partnerName',
      'review',
    ],
  },
  hasCriminalConvictions: {
    op: 'always',
    questions: [
      'hasCriminalConvictionsBlocker',
      'hasPendingCriminalInvestigation',
      'hasPendingCriminalInvestigationBlocker',
    ],
  },
  hasPendingCriminalInvestigation: {
    op: 'always',
    questions: ['hasPendingCriminalInvestigationBlocker'],
  },
  review: {
    op: 'always',
    questions: ['checkoutInfo'],
  },
};
