import routes from 'constants/routes';
import AuthenticatedRoute from 'containers/authenticatedRoute';
import { Switch, useParams } from 'react-router';

import ConfirmPaymentRedirectGateway from './containers/ConfirmPaymentRedirectGateway/ConfirmPaymentRedirectGateway.container';
import PaymentScreen from './containers/PaymentScreen/PaymentScreen.container';
import StripeRedirectGateway from './containers/StripeRedirectGateway/StripeRedirectGateway.container';
import UrlGateway from './containers/UrlGateway/UrlGateway.container';

const Router = () => {
  const { localeId } = useParams<{ localeId?: string }>();

  if (localeId) {
    return (
      <Switch>
        <AuthenticatedRoute
          exact
          component={UrlGateway}
          path={routes.paymentScreenRegionalised.path}
        />
        <AuthenticatedRoute
          exact
          path={routes.paymentScreenRegionalised.pay.path}
          component={PaymentScreen}
        />
        <AuthenticatedRoute
          exact
          path={routes.paymentScreenRegionalised.confirmSetup.path}
          component={StripeRedirectGateway}
        />
        <AuthenticatedRoute
          exact={true}
          path={routes.paymentScreenRegionalised.confirmPayment.path}
          component={ConfirmPaymentRedirectGateway}
        />
      </Switch>
    );
  }
  return (
    <Switch>
      <AuthenticatedRoute
        exact={true}
        path={routes.paymentScreen.path}
        component={UrlGateway}
      />

      <AuthenticatedRoute
        exact={true}
        path={routes.paymentScreen.pay.path}
        component={PaymentScreen}
      />

      <AuthenticatedRoute
        exact={true}
        path={routes.paymentScreen.confirmSetup.path}
        component={StripeRedirectGateway}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.paymentScreen.confirmPayment.path}
        component={ConfirmPaymentRedirectGateway}
      />
    </Switch>
  );
};

export default Router;
