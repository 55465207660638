import documentIcon from '../icons/document.svg';
import familyIcon from '../icons/family.svg';
import moneyIcon from '../icons/money.svg';
import passportIcon from '../icons/passport.svg';

type WelcomeItem = {
  title: string;
  description: string;
  icon: string;
  alt: string;
};

export const addBeneficiariesWelcomeItems: WelcomeItem[] = [
  {
    title: 'Provide details about your beneficiaries',
    description:
      'We need their name, date of birth, and a copy of their ID to ensure they can claim the benefit without any complications.',
    icon: documentIcon,
    alt: 'Illustration of a document',
  },
  {
    title: 'Assign payout sums',
    description:
      'You can add multiple beneficiaries and split the payout sum between them as you wish.',
    icon: moneyIcon,
    alt: 'Illustration of a stack of Euro coins',
  },
  {
    title: 'Upload your photo ID',
    description:
      'A copy of your photo ID (e.g. passport, identity card) is required to confirm your identity.',
    icon: passportIcon,
    alt: 'Illustration of a passport cover',
  },
];

export const editBeneficiariesWelcomeItems: WelcomeItem[] = [
  {
    title: 'Edit your existing beneficiaries',
    description:
      'You can remove beneficiaries or redistribute the payout sum as you wish.',
    icon: moneyIcon,
    alt: 'Illustration of a stack of Euro coins',
  },
  {
    title: 'Add a new beneficiary',
    description:
      'We need their name, date of birth, and a copy of their ID to ensure they can claim the benefit without any complications.',
    icon: familyIcon,
    alt: 'Illustration of two adults and a child hugging',
  },
  {
    title: 'Upload your photo ID',
    description:
      'A copy of your photo ID (e.g. passport, identity card) is required to confirm your identity and update your beneficiaries.',
    icon: passportIcon,
    alt: 'Illustration of a passport cover',
  },
];
