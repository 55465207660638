import { LiabilityClaims } from 'features/liabilityClaims/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useUploadFiles } from 'hooks/useUploadFiles';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import { UploadedFile } from 'shared/models/types';

import { UploadDocumentsView } from './UploadDocuments.view';

export const UploadDocuments = ({
  value,
  onSubmitValue,
}: CustomComponentProps<LiabilityClaims>) => {
  const { t } = useSafeTranslation();

  const [validationError, setValidationError] = useState('');

  const {
    uploadFile,
    uploadedFiles,
    loading: uploadLoading,
    removeFile,
    isSubmitValid,
  } = useUploadFiles({
    initialValue: (value as UploadedFile[]) ?? [],
  });

  const { loading: submitClaimLoading, error } =
    useRequestStatus('CREATE_CLAIM');

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (uploadedFiles.length < 1) {
      setValidationError('Document token(s) is missing for submission');
      return;
    }

    onSubmitValue(uploadedFiles);
  };

  return (
    <UploadDocumentsView
      uploadedFiles={uploadedFiles}
      uploadLoading={uploadLoading}
      onFileSelect={uploadFile}
      onRemoveFile={removeFile}
      handleSubmit={handleSubmit}
      t={t}
      error={error?.message || validationError}
      submissionLoading={submitClaimLoading}
      isSubmitValid={isSubmitValid}
    />
  );
};
