import { XCircleIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { useSafeTranslation } from 'shared/i18n';

export const DisabilityCancelPolicyButton = (
  props: PolicyDetailsButtonProps
) => {
  const { onOpenModal } = props;
  const { t } = useSafeTranslation();

  const handleCancelPolicyButton = () => {
    onOpenModal('DISABILITY_CANCEL_POLICY');
  };

  return (
    <ButtonCell
      title={t(
        'myPolicies.policyDetails.disability.cancelPolicyButton.caption',
        'Cancel this policy'
      )}
      icon={<XCircleIcon noMargin size={24} className="ml16" />}
      onClick={handleCancelPolicyButton}
    />
  );
};
