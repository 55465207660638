interface Model {
  id: string;
}

export const flatten = <T extends Model>(
  items: T[],
  excludeId?: string
): { [id: string]: T } => {
  let flattenItems = {};

  items.forEach((item) => {
    if (excludeId === item.id) {
      return;
    }

    flattenItems = { ...flattenItems, [item.id]: item };
  });

  return flattenItems;
};
