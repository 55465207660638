import { SmartphoneIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import { useSafeTranslation } from 'shared/i18n';

import { PolicyDetailsButtonProps } from '../../sections/PolicyDetails/PolicyDetails.models';

export const DigitalWalletsButton = (props: PolicyDetailsButtonProps) => {
  const { onOpenModal } = props;
  const { t } = useSafeTranslation();

  return (
    <ButtonCell
      title={t(
        'myPolicies.policyDetails.digitalWalletsButton.caption',
        'Digital insurance card'
      )}
      icon={<SmartphoneIcon noMargin size={24} className="ml16" />}
      onClick={() => onOpenModal('ADD_TO_WALLET')}
    />
  );
};
