import { useWindowSize } from 'hooks/useWindowSize';
import { Helmet } from 'react-helmet';

export const SignupTally = () => {
  const size = useWindowSize();

  return (
    <>
      <Helmet>
        <script async src="https://tally.so/widgets/embed.js" />
      </Helmet>
      <iframe
        width={size.width ?? '100%'}
        height={size.height ?? '100%'}
        data-tally-src="https://forms.feather-insurance.com/en-fr/travel-health-insurance"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
        data-cy="tally-iframe"
        title="Register for Travel Health"
      />
    </>
  );
};
