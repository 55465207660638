import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import { InsuranceTypes } from 'models/insurances/types';

export const getCheckoutInfo = (
  questionnaireAnswers: QuestionnaireQuestions
) => ({
  ...questionnaireAnswers,
  totalMonthlyPrice: 100,
  mainPolicy: {
    id: 'main-policy-id',
    startDate: '2025-01-01',
    proratedPrice: 1200,
    proratedPeriod: { start: '2025-01-01', end: '2025-12-31' },
    monthlyPrice: 100,
    policyDetails: {
      type: 'GENERIC' as InsuranceTypes,
      planId: 'BASIC' as const,
    },
    policyHolder: {
      email: 'test@feather-insurance.com',
      name: { firstName: 'Max', lastName: 'Mustermann' },
      address: {
        street: 'Kopenicker',
        houseNumber: '100',
        postcode: '10179',
        city: 'Berlin',
        country: 'DE',
      },
      dateOfBirth: '1980-01-01',
    },
  },
});
