import { toast } from '@popsure/dirty-swan';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction, UserAction } from 'constants/actions';
import routes from 'constants/routes';
import { APIResponseError } from 'models/error';
import { UserWithBrokerMandate } from 'models/user';
import { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import endpoint from 'shared/api';
import browserHistory from 'shared/browserHistory';
import { Locale } from 'shared/i18n';
import { ActionResponse } from 'shared/models/types';

type UnknownObject = Record<string, unknown>;
type MergeRequestAction = RequestAction | UserAction;

export function mergeAccountInfo(
  accountInfo: UserWithBrokerMandate
): UserAction {
  return {
    type: 'MERGE_ACCOUNT_INFO',
    accountInfo,
  };
}

export function submitBrokerMandateSignature(signature: string) {
  return async (
    dispatch: ThunkDispatch<AppState, UnknownObject, MergeRequestAction>
  ) => {
    const requestType = 'SUBMIT_BROKER_MANDATE_SIGNATURE';
    dispatch(setRequestInProcess(true, requestType));
    try {
      await endpoint.signBrokerMandate(signature);
      const { data } = await endpoint.getAccountInfo();
      dispatch(mergeAccountInfo(data));
      dispatch(setRequestInProcess(false, requestType));

      browserHistory.push(routes.me.policies.path);

      toast('You successfully signed the broker mandate', { type: 'success' });
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };
}

export function fetchAccountInfo() {
  return async (
    dispatch: ThunkDispatch<AppState, UnknownObject, MergeRequestAction>
  ) => {
    const requestType = 'ACCOUNT';

    dispatch(setRequestInProcess(true, requestType));

    try {
      const { data: user } = await endpoint.getAccountInfo();
      dispatch(mergeAccountInfo(user));
      dispatch(setRequestInProcess(false, requestType));
      return user;
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      throw error;
    }
  };
}

function mergeUserLanguage(locale: Locale): UserAction {
  return {
    type: 'MERGE_USER_LANGUAGE',
    locale,
  };
}

export function deleteUserAccount(): (
  dispatch: ThunkDispatch<AppState, UnknownObject, MergeRequestAction>
) => Promise<ActionResponse> {
  return async (dispatch) => {
    const request = 'DELETE_USER_ACCOUNT';
    dispatch(setRequestInProcess(true, request));
    try {
      await endpoint.deleteUserAccount();
      const { data } = await endpoint.getAccountInfo();
      dispatch(mergeAccountInfo(data));
      dispatch(setRequestInProcess(false, request));
      return 'SUCCESS';
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, request));
      return 'ERROR';
    }
  };
}

export function updateUserLanguage(locale: Locale, successCb?: () => void) {
  return async (
    dispatch: ThunkDispatch<AppState, UnknownObject, MergeRequestAction>
  ) => {
    const request = 'UPDATE_USER_LANGUAGE';
    dispatch(setRequestInProcess(true, request));
    try {
      await endpoint.updateUserLangugae(locale);
      dispatch(mergeUserLanguage(locale));
      dispatch(setRequestInProcess(false, request));
      if (successCb) {
        successCb();
      }
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, request));
    }
  };
}
