import { Input, SegmentedControl } from '@popsure/dirty-swan';
import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, useState } from 'react';

import QuestionnaireWrapper from '../QuestionnaireWrapper';
import styles from './NumericConversionInputForm.module.scss';

interface Props
  extends QuestionnaireFormProps<[number, number, number]>,
    QuestionnaireWrapperProps {
  validate?: (value: number) => boolean;
  unitLabels: [string, string];
  conversionFunctions: [(n: number) => number, (n: number) => number];
  placeholderPerUnit?: [string, string];
}

export const NumericConversionInputForm = ({
  value,
  question,
  description,
  onSubmitValue,
  buttonText,
  header,
  loading,
  featureName,
  error,
  additionalInfo,
  unitLabels,
  conversionFunctions,
  placeholder,
  placeholderPerUnit,
  validate,
}: Props) => {
  const [convertPrimaryToSecondary, convertSecondaryToPrimary] =
    conversionFunctions;

  const initialPrimaryValue = value?.[0];
  const initialSecondaryValue = value?.[1];
  const initiallySelectedUnit = value?.[2];

  const [primaryValue, setPrimaryValue] = useState(initialPrimaryValue);
  const [secondaryValue, setSecondaryValue] = useState(initialSecondaryValue);
  const [selectedUnit, setSelectedUnit] = useState(initiallySelectedUnit || 0);

  const showPrimary = selectedUnit === 0;

  const valueStr = showPrimary
    ? primaryValue?.toFixed(2).replace(/\.00$/, '')
    : secondaryValue?.toFixed(2).replace(/\.00$/, '');

  const isValid = primaryValue && (validate ? validate(primaryValue) : true);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!primaryValue || !secondaryValue) {
      throw new Error(
        `[${featureName}] Numeric value is missing for submission for question: ${question}.
        '${unitLabels[0]}': '${primaryValue}'
        '${unitLabels[1]}': '${secondaryValue}'`
      );
    }
    onSubmitValue([
      Number(primaryValue.toFixed(2)),
      Number(secondaryValue.toFixed(2)),
      selectedUnit,
    ]);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const n = Number(e.target.value);

    if (showPrimary) {
      setPrimaryValue(n);
      setSecondaryValue(convertPrimaryToSecondary(n));
    } else {
      setPrimaryValue(convertSecondaryToPrimary(n));
      setSecondaryValue(n);
    }
  };

  const placeholderStr = placeholder || placeholderPerUnit?.[selectedUnit];

  return (
    <QuestionnaireWrapper
      header={header}
      question={question}
      description={description}
      onSubmit={handleSubmit}
      validForSubmission={!!isValid}
      buttonText={buttonText}
      error={error}
      loading={loading}
      additionalInfo={additionalInfo}
    >
      <div className="d-flex gap8 w100">
        <Input
          className="w100 wmx4"
          placeholder={placeholderStr}
          onChange={handleChange}
          type="number"
          value={valueStr ?? ''}
          pattern="[0-9]*"
          data-cy="input-numeric"
        />
        <SegmentedControl
          className={styles.units}
          values={unitLabels}
          selectedIndex={showPrimary ? 0 : 1}
          onChange={(n) => setSelectedUnit(n)}
        />
      </div>
    </QuestionnaireWrapper>
  );
};
