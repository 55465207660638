import routes from 'constants/routes';
import { Route, Switch } from 'react-router-dom';

import { SignupDependentsPage } from './dependents';
import { SignupPage } from './main';

export const ExpatEuRouter = () => {
  return (
    <Switch>
      <Route
        path={routes.policies.expatEu.dependents.path}
        component={SignupDependentsPage}
      />
      <Route path={routes.policies.expatEu.path} component={SignupPage} />
      <Route
        path={routes.policies.expatEu.noRegion.path}
        component={SignupPage}
      />
    </Switch>
  );
};
