import Checkout from 'features/checkout';
import { CheckoutPolicyRequestPayload } from 'features/checkout/models';

interface Props {
  checkoutPolicyRequestPayload: CheckoutPolicyRequestPayload;
  policyDetailsComponent: JSX.Element;
  changeStartDatePath?: string;
}

const LiabilityCheckoutView = ({
  checkoutPolicyRequestPayload,
  policyDetailsComponent,
  changeStartDatePath,
}: Props) => {
  return (
    <Checkout
      checkoutPolicyRequestPayload={checkoutPolicyRequestPayload}
      policyDetailsComponent={policyDetailsComponent}
      changeStartDatePath={changeStartDatePath}
    />
  );
};

export default LiabilityCheckoutView;
