import { Country } from '@getpopsure/public-models';
import { RemoveAnswerType, Rule } from '@getpopsure/qnr-framework';

import { isEligibleBMI, Life, Occupations, riskySports } from '../models';

export const getRules = (): Rule<Life>[] => [
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'year',
        value: 65,
      },
    },
    then: {
      goTo: 'over65Blocker',
    },
  },
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'year',
        value: 18,
      },
    },
    then: {
      goTo: 'height',
    },
    else: {
      goTo: 'under18Blocker',
    },
  },
  {
    id: 'weight',
    if: (value, { height }) => {
      if (!value || !height) {
        return false;
      }
      return !isEligibleBMI({ height, weight: value as number });
    },
    then: {
      goTo: 'bmiBlocker',
    },
  },
  {
    id: 'isManualLabor',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: false,
      },
    },
    then: {
      goTo: 'degree',
    },
  },
  {
    id: 'tobaccoUse',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'tobaccoUseRecent',
    },
  },
  {
    id: 'occupation',
    if: {
      op: 'isIn',
      variable: {
        type: 'dynamic',
        value: [Occupations.RETIRED, Occupations.UNEMPLOYED],
      },
    },
    then: {
      goTo: 'occupationBlocker',
    },
  },
  {
    id: 'countryOfResidence',
    if: (value) => {
      return value !== undefined && (value as Country).code !== 'DE';
    },
    then: {
      goTo: 'countryBlocker',
    },
  },
  {
    id: 'travelPlansLength',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'travelPlansCountry',
    },
  },
  // insurance history
  {
    id: 'previousApplications',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'previousApplicationsRefused',
    },
  },
  {
    id: 'otherActiveInsurance',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'otherActiveInsuranceCoverage',
    },
  },
  {
    id: 'otherActiveInsuranceCoverage',
    if: () => true,
    then: {
      goTo: 'income',
    },
  },
  // lifestyle
  {
    id: 'sportsParticipation',
    if: {
      op: 'contains',
      variable: {
        type: 'static',
        value: riskySports.CLIMBING,
      },
    },
    then: {
      goTo: 'climbing',
    },
  },
  {
    id: 'sportsParticipation',
    if: {
      op: 'contains',
      variable: {
        type: 'static',
        value: riskySports.MARTIAL_ARTS,
      },
    },
    then: {
      goTo: 'martialArts',
    },
  },
  {
    id: 'sportsParticipation',
    if: {
      op: 'contains',
      variable: {
        type: 'static',
        value: riskySports.EXTREME_SPORTS,
      },
    },
    then: {
      goTo: 'extremeSports',
    },
  },
  {
    id: 'sportsParticipation',
    if: {
      op: 'contains',
      variable: {
        type: 'static',
        value: riskySports.SCUBA_DIVING,
      },
    },
    then: {
      goTo: 'scubaDiving',
    },
  },
  {
    id: 'sportsParticipation',
    if: {
      op: 'contains',
      variable: {
        type: 'static',
        value: riskySports.BOULDERING,
      },
    },
    then: {
      goTo: 'bouldering',
    },
  },
  {
    id: 'weapons',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'fireArmsUse',
    },
  },
  {
    id: 'dangerousActivities',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'dangerousActivitiesDetails',
    },
  },
  // medical history
  {
    id: 'medicationUse',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'medicationUseHealthStatus',
    },
  },
  {
    id: 'medicationUseHealthStatus',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: false,
      },
    },
    then: {
      goTo: 'medicationUseDetail',
    },
  },
  {
    id: 'inabilityToWork',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'inabilityToWorkReason',
    },
  },
  {
    id: 'inabilityToWorkReason',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'inabilityToWorkHealthStatus',
    },
  },
  {
    id: 'inabilityToWorkHealthStatus',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: false,
      },
    },
    then: {
      goTo: 'inabilityToWorkDetail',
    },
  },
  {
    id: 'bloodPressureTreatment',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'bloodPressureMeasures',
    },
  },
  {
    id: 'bloodPressureMeasures',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: false,
      },
    },
    then: {
      goTo: 'bloodPressure',
    },
  },
  {
    id: 'bloodPressureMeasures',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: false,
      },
    },
    then: {
      goTo: 'bloodPressureDate',
    },
  },
  {
    id: 'hospitalStay',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hospitalStayHealthStatus',
    },
  },
  {
    id: 'hospitalStayHealthStatus',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: false,
      },
    },
    then: {
      goTo: 'hospitalStayDetails',
    },
  },
  {
    id: 'medicalCheckups',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'medicalCheckupsDetails',
    },
  },
  {
    id: 'alcoholDrugsDoctor',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'alcoholDrugsDetails',
    },
  },
  {
    id: 'drugsUsage',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'drugsUsageDetails',
    },
  },
  {
    id: 'hiv',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hivDetails',
    },
  },
  {
    id: 'hasReviewedEligibility',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: false,
      },
    },
    then: {
      goTo: 'eligibilityBlocker',
    },
  },
];

const removeQuote: RemoveAnswerType<Life> = {
  op: 'always',
  questions: ['quote'],
};

const removeEligibility: RemoveAnswerType<Life> = {
  op: 'always',
  questions: ['hasReviewedEligibility'],
};

const removeReviewedPersonalInfo: RemoveAnswerType<Life> = {
  op: 'always',
  questions: ['hasReviewedPersonalInfo'],
};

const removeReviewedInsuranceHistory: RemoveAnswerType<Life> = {
  op: 'always',
  questions: ['hasReviewedInsuranceHistory'],
};

const removeReviewedMedicalHistory: RemoveAnswerType<Life> = {
  op: 'always',
  questions: ['hasReviewedMedicalHistory'],
};

const removeReviewedLifestyle: RemoveAnswerType<Life> = {
  op: 'always',
  questions: ['hasReviewedLifestyle'],
};

const removeReview: RemoveAnswerType<Life> = {
  op: 'always',
  questions: ['review'],
};

export const removeAnswersLogic: Partial<
  Record<keyof Life, RemoveAnswerType<Life>>
> = {
  dateOfBirth: removeQuote,
  height: removeQuote,
  weight: removeQuote,
  isManualLabor: removeQuote,
  degree: removeQuote,
  tobaccoUse: removeQuote,
  tobaccoUseRecent: removeQuote,
  quote: removeEligibility,
  occupation: removeReviewedPersonalInfo,
  countryOfResidence: removeReviewedPersonalInfo,
  address: removeReviewedPersonalInfo,
  travelPlansLength: removeReviewedPersonalInfo,
  travelPlansCountry: removeReviewedPersonalInfo,
  hasReviewedPersonalInfo: removeEligibility,
  previousApplications: removeReviewedInsuranceHistory,
  previousApplicationsRefused: removeReviewedInsuranceHistory,
  otherActiveInsurance: removeReviewedInsuranceHistory,
  otherActiveInsuranceCoverage: removeReviewedInsuranceHistory,
  income: removeReviewedInsuranceHistory,
  hasReviewedInsuranceHistory: removeEligibility,
  medicationUse: removeReviewedMedicalHistory,
  medicationUseHealthStatus: removeReviewedMedicalHistory,
  medicationUseDetail: removeReviewedMedicalHistory,
  inabilityToWork: removeReviewedMedicalHistory,
  inabilityToWorkReason: removeReviewedMedicalHistory,
  inabilityToWorkHealthStatus: removeReviewedMedicalHistory,
  inabilityToWorkDetail: removeReviewedMedicalHistory,
  healthConditions: removeReviewedMedicalHistory,
  bloodPressureTreatment: removeReviewedMedicalHistory,
  bloodPressureMeasures: removeReviewedMedicalHistory,
  bloodPressure: removeReviewedMedicalHistory,
  bloodPressureDate: removeReviewedMedicalHistory,
  hospitalStay: removeReviewedMedicalHistory,
  hospitalStayHealthStatus: removeReviewedMedicalHistory,
  hospitalStayDetails: removeReviewedMedicalHistory,
  medicalCheckups: removeReviewedMedicalHistory,
  medicalCheckupsDetails: removeReviewedMedicalHistory,
  alcoholDrugsDoctor: removeReviewedMedicalHistory,
  alcoholDrugsDetails: removeReviewedMedicalHistory,
  drugsUsage: removeReviewedMedicalHistory,
  drugsUsageDetails: removeReviewedMedicalHistory,
  hiv: removeReviewedMedicalHistory,
  hivDetails: removeReviewedMedicalHistory,
  hasReviewedMedicalHistory: removeEligibility,
  sportsParticipation: removeReviewedLifestyle,
  climbing: removeReviewedLifestyle,
  bouldering: removeReviewedLifestyle,
  martialArts: removeReviewedLifestyle,
  extremeSports: removeReviewedLifestyle,
  scubaDiving: removeReviewedLifestyle,
  weapons: removeReviewedLifestyle,
  fireArmsUse: removeReviewedLifestyle,
  dangerousActivities: removeReviewedLifestyle,
  dangerousActivitiesDetails: removeReviewedLifestyle,
  hasReviewedLifestyle: removeEligibility,
  name: removeReview,
  email: removeReview,
};
