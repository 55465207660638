import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import * as Sentry from '@sentry/react';
import { ErrorWithAction } from 'components/ErrorWithAction';
import routes from 'constants/routes';
import { useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import {
  Dental,
  DentalQuotePlan,
  QuoteCustomization,
  QuoteOption,
} from '../../models';
import advancedIcon from './icons/advanced.svg';
import basicIcon from './icons/basic.svg';
import DentalQuoteView from './Quote.view';

export const Quote = ({
  onSubmitValue,
  questionnaireAnswers,
}: QuestionnaireFormProps<Dental>) => {
  const history = useHistory();
  const { t } = useSafeTranslation();

  const [quoteCustomization, setQuoteCustomization] =
    useState<QuoteCustomization>({
      plan: 'BASIC',
      toothReplacementCoverage: '90%',
    });

  const { plan, toothReplacementCoverage } = quoteCustomization;

  const quotes: QuoteOption[] | undefined =
    questionnaireAnswers.quoteProcessing;

  const basicPrice = quotes?.find(({ planId }) => planId === 'BASIC')?.price;

  const advancedPrice = quotes?.find(
    ({ planId, attributes }) =>
      planId === 'ADVANCED' &&
      attributes.toothReplacementCoverage === toothReplacementCoverage
  )?.price;

  const selectedQuote = quotes?.find(({ planId, attributes }) =>
    plan === 'BASIC'
      ? plan === planId
      : plan === planId &&
        attributes.toothReplacementCoverage === toothReplacementCoverage
  );
  const handleQuoteError = () => {
    const qnrIntro = generatePath(routes.policies.dental.questionnaire.path);
    history.push(qnrIntro);
  };

  if (!selectedQuote || !basicPrice || !advancedPrice) {
    Sentry.captureException(`[Dental quote] Failed to render quote details`);

    return (
      <ErrorWithAction
        title={t(
          'dental.qnr.preQuote.quote.error.title',
          'Something went wrong'
        )}
        description={t(
          'dental.qnr.preQuote.quote.error.description',
          'Sorry, something didn’t work as it should. Starting over should address this issue.'
        )}
        cta={{
          title: t('dental.qnr.preQuote.quote.error.cta', 'Start over'),
          onClick: handleQuoteError,
        }}
      />
    );
  }

  const planInfoMapping: DentalQuotePlan = {
    BASIC: {
      name: 'Basic',
      icon: basicIcon,
      price: basicPrice,
    },
    ADVANCED: {
      name: 'Advanced',
      icon: advancedIcon,
      price: advancedPrice,
    },
  };

  const { price, id } = selectedQuote;

  const handleSubmitQuote = () => {
    if (plan === 'BASIC') {
      onSubmitValue({ id, plan, price });
    }

    onSubmitValue({ id, plan, price, toothReplacementCoverage });
  };

  return (
    <DentalQuoteView
      plan={plan}
      toothReplacementCoverage={toothReplacementCoverage}
      planInfoMapping={planInfoMapping}
      handleSubmit={handleSubmitQuote}
      selectedQuote={selectedQuote}
      setQuoteCustomization={setQuoteCustomization}
    />
  );
};
