import { BottomOrRegularModal, Input } from '@popsure/dirty-swan';
import classNames from 'classnames';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { UpdateBikeQuestionnaireDispatch } from 'models/policies';
import { FormEvent, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import { updateBikeQuestionnaire } from '../actions';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  questionnaireId?: string;
  policyId: string;
}

export const FrameNumberModal = ({
  isOpen,
  onClose,
  questionnaireId,
  policyId,
}: Props) => {
  const { t } = useSafeTranslation();
  const [frameNumber, setFrameNumber] = useState('');

  const { loading: submitLoading, error } = useRequestStatus(
    'UPDATE_QUESTIONNAIRE'
  );
  const { loading: policyLoading } = useRequestStatus('LOAD_MY_POLICY_DETAIL');
  const dispatch = useDispatch<UpdateBikeQuestionnaireDispatch>();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!frameNumber) {
      throw new Error('[Bike] Frame number missing for update');
    }

    if (!questionnaireId) {
      throw new Error(
        '[Bike] Missing questionnaire id for frame number update'
      );
    }

    const responseStatus = await dispatch(
      updateBikeQuestionnaire({
        answers: { bikeInfo: { frameNumber } },
        questionnaireId,
        policyId,
      })
    );

    if (responseStatus === 'SUCCESS') {
      onClose();
    }
  };

  const validForSubmission = !submitLoading && frameNumber;

  if (policyLoading) {
    return <TimedLoadSpinner />;
  }

  return (
    <BottomOrRegularModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('policy.bike.frameNumberModal.title', 'Frame number')}
    >
      <form
        className="px24 pb24 p-p"
        data-testid="policy-detail-frame-number-modal"
        onSubmit={onSubmit}
      >
        <p className="mt16">
          {t(
            'policy.bike.frameNumberModal.description',
            'We store it in your account in case you have to make a claim. Most bicycles have a frame number stamped into the metal under the bottom bracket. Occasionally, the frame number will be on the inside of the chain stay or on the down tube or top tube.'
          )}
        </p>
        <Input
          value={frameNumber}
          onChange={(e) => setFrameNumber(e.target.value)}
          type="text"
          className="mt16"
          placeholder={t(
            'policy.bike.frameNumberModal.input.placeholder',
            'Frame number'
          )}
          data-testid="policy-detail-frame-number-input"
        />
        <AnimateHeight duration={300} height={error ? 'auto' : 0}>
          <div
            className="p-notice p-notice--warning mt16 p-p"
            data-testid="policy-detail-frame-number-error"
          >
            {t(
              'policy.bike.frameNumberModal.error',
              'Something went wrong and we were unable to update your frame number. Please try again later.'
            )}
          </div>
        </AnimateHeight>
        <button
          type="submit"
          className={classNames(`mt24 p-btn--primary wmn2`, {
            'p-btn--loading': submitLoading,
          })}
          disabled={!validForSubmission}
          data-testid="policy-detail-frame-number-submit-button"
        >
          {t('policy.bike.frameNumberModal.cta', 'Submit')}
        </button>
      </form>
    </BottomOrRegularModal>
  );
};
