import React, { Component } from 'react';
import SignaturePad from 'signature_pad';

import styles from './style.module.scss';

interface Props {
  onChange: (base64signature: string) => void;
}

interface State {
  hasContent: boolean;
}

class Signature extends Component<Props, State> {
  private canvasRef: React.RefObject<HTMLCanvasElement>;

  private canvas: any;

  private signaturePad: any;

  constructor(props) {
    super(props);
    this.state = {
      hasContent: false,
    };
    this.canvasRef = React.createRef();
    this.notifyOnChange = this.notifyOnChange.bind(this);
    this.resizeCanvas = this.resizeCanvas.bind(this);
    this.clear = this.clear.bind(this);
    this.undo = this.undo.bind(this);
  }

  public componentDidMount() {
    this.canvas = this.canvasRef.current;
    this.signaturePad = new SignaturePad(this.canvas);
    this.signaturePad.addEventListener(
      'endStroke',
      () => {
        this.notifyOnChange();
      },
      { once: true }
    );
    this.resizeCanvas();
  }

  public notifyOnChange() {
    const { onChange } = this.props;
    onChange(this.signaturePad.isEmpty() ? '' : this.signaturePad.toDataURL());
    this.setState({ hasContent: !this.signaturePad.isEmpty() });
  }

  public resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.canvas.width = this.canvas.offsetWidth * ratio;
    this.canvas.height = this.canvas.offsetHeight * ratio;
    this.canvas.getContext('2d').scale(ratio, ratio);
  }

  public undo() {
    const data = this.signaturePad.toData();

    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }

    this.notifyOnChange();
  }

  public clear() {
    this.signaturePad.clear();
    this.notifyOnChange();
  }

  public render() {
    return (
      <div className={styles.container}>
        <canvas
          className={styles['signature-container']}
          ref={this.canvasRef}
        />
        <div className={styles['button-container']}>
          <button
            className={`p-btn--secondary ${styles['signature-button']}`}
            style={{ marginRight: '8px' }}
            type="button"
            onClick={this.undo}
          >
            Undo
          </button>
          <button
            className={`p-btn--danger ${styles['signature-button']}`}
            type="button"
            onClick={this.clear}
          >
            Clear
          </button>
        </div>
      </div>
    );
  }
}

export default Signature;
