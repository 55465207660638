import Session from '@getpopsure/session';
import { validateAccount } from 'actions/account';
import { fetchAccountInfo } from 'actions/user';
import EmailView from 'components/signupAuthentication/email/view';
import { ExpatQuestionnaire } from 'features/expat/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountInfo } from 'selectors/user';

export const Email = ({
  value,
  routes,
  onSubmitValue,
}: CustomComponentProps<ExpatQuestionnaire>) => {
  const account = useSelector(getAccountInfo);

  const { loading, error } = useRequestStatus('VALIDATE_ACCOUNT');

  const dispatch = useDispatch();

  const { isAuthenticated } = Session;

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchAccountInfo());
    }
  }, [dispatch, isAuthenticated]);

  const handleValidateAccount = (
    email: string,
    onUserExists: (userExists: boolean) => void
  ) => {
    if (email === account?.email && isAuthenticated) {
      onSubmitValue({
        email,
        name:
          account.firstName && account.lastName
            ? {
                firstName: account.firstName,
                lastName: account.lastName,
              }
            : undefined,
        account: true,
      });
    } else {
      dispatch(validateAccount('expat', email, routes.name.path, onUserExists));
    }
  };

  const answer = (value as string) ?? account?.email;

  return (
    <EmailView
      key={answer}
      verticalId="expat"
      redirectPath={routes.name.path}
      redirectExistingUserPath={routes.name.path}
      storedAnswer={answer}
      loading={loading}
      error={error}
      handleValidateAccount={handleValidateAccount}
    />
  );
};
