import Navigation from 'components/common/navigation';
import routes from 'constants/routes';
import { ClaimSubmitInfo } from 'models/claims';
import { ChangeEvent, FormEvent, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

interface Props {
  onCreateClaim: (claimInfo: ClaimSubmitInfo) => void;
  isLoading: boolean;
  error?: string;
}

type OwnProps = RouteComponentProps<{ policyId: string }>;

export const Claims = ({
  onCreateClaim,
  isLoading,
  error,
}: Props & OwnProps) => {
  const { t } = useSafeTranslation();

  const { policyId } = useParams<{ policyId: string }>();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [claimDescription, setClaimDescription] = useState('');
  const isValidForSubmission = !!(phoneNumber && claimDescription);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!policyId) {
      throw new Error('[CLAIMS] Policy ID is missing for submitting claim.');
    }

    if (!isValidForSubmission) {
      return;
    }

    onCreateClaim({
      details: claimDescription,
      contacts: phoneNumber,
      userPolicyId: policyId,
    });
  };

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = e;
    setClaimDescription(value);
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    setPhoneNumber(value);
  };
  return (
    <div className="root-container">
      <Navigation
        title={t('claims.generic.title', 'Claims')}
        previousUrl={routes.me.policies.path}
        previousScreen={t('claims.generic.previousScreen', 'My policies')}
      />
      <form className="mt40" onSubmit={handleSubmit}>
        <label htmlFor="description">
          <h4 className="p-h4">
            {t(
              'claims.generic.claimDescription.instructions',
              'Briefly describe what happened'
            )}
          </h4>
          <textarea
            className="p-input mt8"
            data-cy="me-claims-description"
            onChange={handleDescriptionChange}
            value={claimDescription}
            id="description"
            name="description"
            rows={4}
            style={{ width: '100%' }}
          />
        </label>
        <label htmlFor="phone">
          <h4 className="p-h4 mt24">
            {t(
              'claims.generic.phoneNumber.instructions',
              'Please provide a phone number where we can reach you in case we need more information regarding the claim.'
            )}
          </h4>
          <input
            data-cy="me-claims-phone"
            className={`p-input mt8 ${styles['phone-input']}`}
            type="tel"
            id="phone"
            name="phone"
            placeholder={t(
              'claims.generic.phoneNumber.placeholder',
              'e.g. 0176 11111111'
            )}
            value={phoneNumber}
            onChange={handlePhoneChange}
          />
        </label>
        <input
          data-cy="me-claims-submit"
          className="p-btn--primary mt16 wmn3"
          type="submit"
          name="phone"
          value={
            isLoading
              ? t('claims.generic.submitButton.caption.loading', 'Loading...')
              : t('claims.generic.submitButton.caption.submit', 'Submit')
          }
          disabled={!isValidForSubmission}
        />
        {error && (
          <p
            className={styles['global-error-message']}
            style={{ textAlign: 'left' }}
          >{`${error}`}</p>
        )}
      </form>
    </div>
  );
};

export default Claims;
