import { legal } from '@getpopsure/private-constants';
import { MultiDropzone } from '@popsure/dirty-swan';
import StepBar from 'components/stepBar';
import {
  useClaimSteps,
  useCurrentClaimStep,
} from 'features/claimsProcess/hooks';
import type { ViewProps } from 'features/claimsProcess/uploadDocuments';
import { useUploadFiles } from 'hooks/useUploadFiles';
import { mapClaimTypes } from 'models/claims';
import { useEffect, useState } from 'react';

import styles from './style.module.scss';

const ClaimUploadDocuments = ({
  storedClaimTypeInfo,
  storedUploadedFiles,
  storedDetails,
  storeSubmittableClaimInfo,
  continueToPaymentInfo,
  isStorybook = false,
}: ViewProps & {
  isStorybook?: boolean;
}) => {
  const { uploadFile, uploadedFiles, loading, removeFile, isSubmitValid } =
    useUploadFiles({ initialValue: storedUploadedFiles ?? [] });

  const [claimDescription, setClaimDescription] = useState<string | undefined>(
    storedDetails
  );
  const steps = useClaimSteps();
  const currentStep = useCurrentClaimStep();
  const currentIndex = (currentStep && steps.indexOf(currentStep) + 1) ?? 1;

  useEffect(() => {
    if (!isStorybook) {
      storeSubmittableClaimInfo({
        uploadedFiles,
        details: claimDescription,
      });
    }
  }, [storeSubmittableClaimInfo, claimDescription, uploadedFiles, isStorybook]);

  return (
    <div className="p-body">
      <form
        className={`wmx8 w100 ${styles.container}`}
        onSubmit={(e) => {
          e.preventDefault();

          if (uploadedFiles === undefined || uploadedFiles.length < 1) {
            throw new Error(
              '[Expat] Upload claim related documents to continue.'
            );
          }

          continueToPaymentInfo();
        }}
      >
        <div className="mt40">
          <StepBar currentStep={currentIndex} steps={steps.length} />
        </div>
        <div className={`mt32 ${styles.header}`}>
          <h4 className="p-h4 tc-purple-500">Step {currentIndex}</h4>
          <div className="p-h2">Provide details of your claim</div>
          {storedClaimTypeInfo && (
            <div className={`mt16 ta-center p-p--small ${styles.chip}`}>
              {mapClaimTypes[storedClaimTypeInfo.claimType]}
            </div>
          )}
        </div>
        <div className="mt16">
          <div className="p-p">
            Please upload any documents associated with your claim.
          </div>
        </div>
        <div className="mt16 wmx7">
          <MultiDropzone
            onFileSelect={uploadFile}
            uploadedFiles={uploadedFiles}
            uploading={loading}
            onRemoveFile={removeFile}
          />
        </div>
        <div className="mt24">
          <div className="p-h4">
            Is there anything you’d like to tell us about your claim?
          </div>
          <textarea
            className={`mt8 p-input wmx5 wmn4 ${styles['claim-text-area']}`}
            placeholder="Please describe..."
            onChange={(e) => setClaimDescription(e.target.value)}
            value={claimDescription}
          />
        </div>
        <div className="mt24">
          <div className="p-p wmx5">
            By uploading any documents, you agree to our health{' '}
            <a
              href={legal.declarationDataProtection}
              target="_blank"
              rel="noopener noreferrer"
              className={`tc-purple-500 ${styles.link}`}
            >
              data processing agreement
            </a>
            .
          </div>
          <button
            className={`p-btn p-btn--primary mt16 p-p wmn3 ${styles.button}`}
            disabled={!isSubmitValid}
            type="submit"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClaimUploadDocuments;
