import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { Button, InformationBox, Input } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { GoogleLoginButton } from 'features/GoogleOAuth';
import { useState } from 'react';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import { useSafeTranslation } from 'shared/i18n';
import { useEmailQuestion } from 'SignupQuestionnaire/components//Email/useEmailQuestion';
import LoginModal from 'SignupQuestionnaire/components/Email/LoginModal';

import styles from './EmailWithGoogle.module.scss';

export const EmailWithGoogle = <Questionnaire extends QuestionnaireQuestions>({
  value,
  onSubmitValue,
  verticalId,
  setValidForSubmission,
  showTerms = true,
}: {
  verticalId: keyof GenericQuestionnaireState;
  showTerms?: boolean;
} & QuestionnaireFormProps<Questionnaire>) => {
  const { t } = useSafeTranslation();
  const [googleLoginError, setGoogleLoginError] = useState(false);
  const {
    email,
    handleInputChange,
    handleModalClose,
    handleSubmit,
    onLogin,
    requiresLoginCode,
  } = useEmailQuestion({
    onSubmitValue,
    setValidForSubmission,
    showTerms,
    value,
  });

  return (
    <div className="p-body">
      <div className={styles.container}>
        <Header onSubmit={handleSubmit}>
          <h1 className="p-h1">
            {t(
              'components.emailWithGoogle.title',
              "What's your email address?"
            )}
          </h1>
          <Input
            className={classNames(styles.emailInput, 'mt16')}
            type="email"
            value={email}
            placeholder={t(
              'components.emailWithGoogle.placeholder',
              'Email address'
            )}
            onChange={handleInputChange}
            required
            data-cy="input-email"
          />
          <div
            className={classNames(styles.buttonContainer, 'd-flex gap8 mt24')}
          >
            <Button className="wmn3" data-cy="button-continue">
              {t('components.emailWithGoogle.button', 'Continue with email')}
            </Button>

            <GoogleLoginButton
              className="wmn3"
              onSuccess={() => onSubmitValue('')}
              onLoginError={() => setGoogleLoginError(true)}
            />
          </div>
          <div>
            {googleLoginError && (
              <InformationBox className="mt16 wmx6" variant="error">
                {t(
                  'components.emailWithGoogle.googleLoginError',
                  "Your account isn't linked to Google yet—please log in with your email and sign-in code first, then link your Google account in Account settings."
                )}
              </InformationBox>
            )}
          </div>
        </Header>
      </div>

      <LoginModal
        email={email}
        isOpen={requiresLoginCode}
        onClose={handleModalClose}
        onLogin={onLogin}
        verticalId={verticalId}
      />
    </div>
  );
};
