import { Header, QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { DateSelector, Input } from '@popsure/dirty-swan';
import dayjs from 'dayjs';
import { TravelHealthV1 } from 'features/travelHealthV1/models';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

const GENDER_OPTIONS = [
  { value: 'MALE', label: 'Male' },
  { value: 'FEMALE', label: 'Female' },
];

type UpdateKeys = 'firstName' | 'lastName' | 'dateOfBirth' | 'gender';

type CoveredPersonEntry = {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
};

const isOver21 = (dateOfBirth: string): boolean => {
  return dayjs().diff(dayjs(dateOfBirth), 'years') >= 21;
};

const validateAgeConstraints = (
  coveredEntries: CoveredPersonEntry[]
): boolean => {
  return (
    coveredEntries.filter((coveredEntry) => isOver21(coveredEntry.dateOfBirth))
      .length <= 1
  );
};

const validateGenderPresence = (
  coveredEntries: CoveredPersonEntry[]
): boolean => {
  return coveredEntries
    .filter(({ dateOfBirth }) => isOver21(dateOfBirth))
    .every(({ gender }) => gender === 'MALE' || gender === 'FEMALE');
};

export const CoveredGroup = ({
  setValidForSubmission,
  onSubmitValue,
  questionnaireAnswers,
  value,
}: QuestionnaireFormProps<TravelHealthV1>) => {
  const [covered, setCovered] = useState<CoveredPersonEntry[]>(() => {
    return (
      (value as CoveredPersonEntry[]) ??
      Array(questionnaireAnswers.numberOfCovered ?? 0).fill({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        gender: '',
      })
    ).map((coveredPerson, idx) => {
      return {
        ...coveredPerson,
        id: `covered-person-${idx}`,
      };
    });
  });

  const onUpdateCoveredPerson = (key: UpdateKeys, val: string, id: string) => {
    setCovered((previousCovered) => {
      return previousCovered.map((previousCoveredPerson) => {
        if (previousCoveredPerson.id === id) {
          return { ...previousCoveredPerson, [key]: val };
        }
        return previousCoveredPerson;
      });
    });
  };
  const isValid =
    covered.every(
      (coveredPerson) =>
        coveredPerson.firstName !== '' &&
        coveredPerson.lastName !== '' &&
        coveredPerson.dateOfBirth !== ''
    ) &&
    validateAgeConstraints(covered) &&
    validateGenderPresence(covered);

  useEffect(() => {
    setValidForSubmission(isValid);
  }, [isValid, setValidForSubmission]);

  const handleSubmit = () => {
    if (isValid) {
      onSubmitValue(covered.map(({ id, ...coveredPerson }) => coveredPerson));
    }
  };

  return (
    <Header onSubmit={handleSubmit}>
      <>
        {covered.map((coveredPerson) => {
          return (
            <CoveredPerson
              key={coveredPerson.id}
              {...coveredPerson}
              onChange={(key, val) =>
                onUpdateCoveredPerson(key, val, coveredPerson.id)
              }
            />
          );
        })}
        <AnimateHeight
          duration={300}
          height={!validateAgeConstraints(covered) ? 'auto' : 0}
        >
          <div className="p-notice p-notice--warning mt16 p-p">
            Only one additional adult and seven children ages under 21 are
            allowed
          </div>
        </AnimateHeight>
        <AnimateHeight
          duration={300}
          height={!validateGenderPresence(covered) ? 'auto' : 0}
        >
          <div className="p-notice p-notice--warning mt16 p-p">
            Additional adults must specify gender
          </div>
        </AnimateHeight>
      </>
    </Header>
  );
};

type CoveredPersonProps = {
  onChange: (key: UpdateKeys, value: string) => void;
} & CoveredPersonEntry;

const CoveredPerson = ({
  id,
  firstName,
  lastName,
  dateOfBirth,
  gender,
  onChange,
}: CoveredPersonProps) => {
  return (
    <div className="d-flex fd-column my16 gap16">
      <Input
        placeholder="First name"
        value={firstName}
        onChange={(e) => {
          onChange('firstName', e.target.value);
        }}
      />
      <Input
        placeholder="Last name"
        value={lastName}
        onChange={(e) => {
          onChange('lastName', e.target.value);
        }}
      />
      <DateSelector
        onChange={(date: string) => {
          onChange('dateOfBirth', date);
          if (!isOver21(date)) onChange('gender', '');
        }}
        value={dateOfBirth}
        displayCalendar
        yearBoundaries={{
          min: dayjs().subtract(64, 'years').year(),
          max: dayjs().year(),
        }}
      />

      {isOver21(dateOfBirth) && (
        <div className="p-label-container">
          <div className="mt8 mb8">Gender</div>

          {GENDER_OPTIONS.map(({ value, label }) => (
            <div key={`${id}-${value}`}>
              <input
                id={`gender-${id}-${value}`}
                name={`gender-${id}`}
                className="p-radio"
                type="radio"
                value={value}
                onChange={() => onChange('gender', value)}
                checked={gender === value}
              />
              <label
                htmlFor={`gender-${id}-${value}`}
                className="p-label p-label--bordered mb8"
              >
                {label}
              </label>
            </div>
          ))}
        </div>
      )}

      <hr className="mt4 mb4" />
    </div>
  );
};
