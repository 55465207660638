import GenericAccount from 'components/signupAuthentication/account';
import { Household } from 'features/household/models';
import { CustomComponentProps } from 'models/questionnaireFramework';

export const Account = ({ onSubmitValue }: CustomComponentProps<Household>) => (
  <GenericAccount
    verticalId="household"
    onCreateNewAccount={() => {
      onSubmitValue(null);
    }}
    onContinueWithCurrentAccount={(account) => {
      onSubmitValue(account);
    }}
  />
);
