import { IconCard } from 'components/IconCard';
import SectionHeader from 'components/sectionHeader';

import styles from './WhatsNotCoveredSection.module.scss';

export type NotCoveredSection = {
  title: string;
  items: {
    title: string;
    icon?: {
      src: string;
      alt: string;
    };
    description: string;
  }[];
};

export const WhatsNotCoveredSection = ({ title, items }: NotCoveredSection) => {
  return (
    <section className="p-container mt80 mb72">
      <div className={`d-flex fd-column ${styles.titleContainer}`}>
        <SectionHeader title={title} greyUnderline />
      </div>
      <div className="d-flex fd-row f-wrap jc-center c-gap8 r-gap24 mt32">
        {items.map(({ title: itemTitle, icon, description }) => {
          return (
            <div className="wmx6" key={itemTitle}>
              <IconCard
                className="p8"
                title={itemTitle}
                iconSrc={icon?.src ?? ''}
                iconAlt={icon?.alt ?? ''}
              >
                {description}
              </IconCard>
            </div>
          );
        })}
      </div>
    </section>
  );
};
