import { AppState } from 'reducers';
import { createSelector } from 'reselect';

export const selectI18nOptions = createSelector(
  [
    (state: AppState) => state.i18n.fallbackOption,
    (state: AppState) => state.i18n.translationSource,
  ],

  (fallbackOption, translationSource) => ({
    fallbackOption,
    translationSource,
  })
);
