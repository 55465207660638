import { Button } from '@popsure/dirty-swan';
import { APIResponseError } from 'models/error';
import AnimateHeight from 'react-animate-height';

import heart from './assets/heart.svg';
import styles from './ContinueWithAccount.module.scss';

type ContinueWithAccountProps = {
  onContinueWithAccount(answer: boolean): void;
  personaLoading: boolean;
  personaError?: APIResponseError;
};

const ContinueWithAccountView = (props: ContinueWithAccountProps) => {
  const { onContinueWithAccount, personaLoading, personaError } = props;

  const handleContinueWithAccount =
    (continueWithAccount: boolean) => (): void =>
      onContinueWithAccount(continueWithAccount);

  return (
    <div className={styles.container}>
      <div className="p-body">
        <img src={heart} alt="" className="mb24" />
        <h1 className="p-h1 mb16">
          Want to save your answers in a Feather account?
        </h1>
        <p className="p-p mb32">
          You’ll be able to access your recommendation across devices.
        </p>
        <Button
          onClick={handleContinueWithAccount(true)}
          data-cy="button-continue-with-account"
        >
          Continue with account
        </Button>
        <Button
          className={styles.secondaryButton}
          variant="textColor"
          onClick={handleContinueWithAccount(false)}
          loading={personaLoading}
          data-cy="button-continue-anonymously"
        >
          Continue anonymously
        </Button>
        <AnimateHeight
          className="mt24 wmx8"
          duration={300}
          height={personaError ? 'auto' : 0}
        >
          <div className="p-notice p-notice--danger p-p">
            {personaError?.message}
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};

export default ContinueWithAccountView;
