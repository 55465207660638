import NameForm from 'components/questionnaireForms/NameForm';
import { signUpAndCreatePersona } from 'features/recommendationTool/actions';
import { RecommendationToolDispatch } from 'features/recommendationTool/models';
import { Account } from 'features/recommendationTool/models/account';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { Name } from 'models/user';
import { useDispatch } from 'react-redux';

const FullNameContainer = (props: CustomComponentProps<Account>) => {
  const { featureName, questionnaireAnswers, onSubmitValue } = props;
  const { loading, error } = useRequestStatus('CREATE_USER');
  const dispatch = useDispatch<RecommendationToolDispatch>();
  const email = questionnaireAnswers.signIn;

  if (!email) {
    throw new Error(
      '[RECOMMENDATION_TOOL]: trying to create a user without an email address'
    );
  }

  const handleSubmitName = async ({ firstName, lastName }: Name) => {
    const signupStatus = await dispatch(
      signUpAndCreatePersona({ email, firstName, lastName })
    );
    if (signupStatus === 'SUCCESS') onSubmitValue({ firstName, lastName });
  };

  return (
    <NameForm
      onSubmitValue={handleSubmitName}
      question="What's your name?"
      featureName={featureName}
      loading={loading}
      error={error?.message}
    />
  );
};

export default FullNameContainer;
