import { TFunction } from '@getpopsure/i18n-react';
import { getTrackingObject } from '@getpopsure/tracker';
import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkAction } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';

import {
  getLabels,
  LegalSubmissionDispatch,
  SubmitQuestionnaireLabels,
  SubmittableLegalQuestionnaire,
  SubmittableLegalQuote,
  ZSubmittableLegalQuestionnaire,
  ZSubmittableLegalQuote,
} from '../models';

const createLegalQuestionnaire = (
  answers: SubmittableLegalQuestionnaire,
  labels: SubmitQuestionnaireLabels
) => {
  const source = getTrackingObject();
  return endpoint.network.post<{ id: string }>('/questionnaires/', {
    answers,
    questionnaireType: 'LEGALV2',
    metadata: { source, ...labels },
  });
};

const createQuote = (answers: SubmittableLegalQuote) => {
  return endpoint.network.post<{ id: string; price: number }>(
    `/signups/v2/legal/quotes`,
    { ...answers }
  );
};

export type CreateQuoteReturnType = {
  status: ActionResponse;
};

export const createPostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'legal'> | RequestAction
  > =>
  async (dispatch: LegalSubmissionDispatch, getState: () => AppState) => {
    dispatch(setRequestInProcess(true, 'SUBMIT_QUESTIONNAIRE'));

    try {
      const questionnaire = getGenericQuestionnaire(getState()).legal || {};

      const validationResult = ZSubmittableLegalQuestionnaire.safeParse({
        ...questionnaire,
        insurer: questionnaire.insurer?.value,
      });

      if (!validationResult.success) {
        throw new Error(
          `[Legal - Submission] Questionnaire answer validation errors:${validationResult.error.toString()}`
        );
      }
      const validatedAnswers = validationResult.data;

      const {
        email,
        name: { firstName, lastName },
        dateOfBirth,
      } = validatedAnswers.personalInfo;
      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            dateOfBirth,
            email,
          })
        );
      }

      const labels = getLabels(t);

      const {
        data: { id: questionnaireId },
      } = await createLegalQuestionnaire(validatedAnswers, labels);

      const validationQuoteResult = ZSubmittableLegalQuote.safeParse({
        ...questionnaire,
        plan: questionnaire.quote?.plan ?? 'BASIC',
        addons: questionnaire.quote?.addons,
        insurer: questionnaire.insurer?.value,
      });

      if (!validationQuoteResult.success) {
        throw new Error(
          `[Legal - Submission] Quote answer validation errors:${validationQuoteResult.error.toString()}`
        );
      }
      const validatedQuoteAnswers = validationQuoteResult.data;

      const {
        data: { id: quoteId },
      } = await createQuote(validatedQuoteAnswers);

      dispatch(
        storeGenericQuestionnaireAnswer('legal', {
          quoteId,
          questionnaireId,
          quote: {
            questionnaireId,
            quoteId,
            plan: validatedQuoteAnswers.plan,
            addons: validatedQuoteAnswers.addons,
          },
        })
      );

      await generateCheckoutDocuments(questionnaireId, quoteId, 'LEGAL_V2', t);

      dispatch(setRequestInProcess(false, 'SUBMIT_QUESTIONNAIRE'));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'SUBMIT_QUESTIONNAIRE')
      );
      Sentry.captureException(
        `[Legal Review] Failed to submit with following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };
