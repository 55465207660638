import { InsuranceTypes } from 'models/insurances/types';
import { getTitleMapping } from 'models/insurances/types/mapping';
import type { TFunction } from 'shared/i18n';

const PetHealthClaimTypeArray = [
  'SURGERY',
  'PREVENTATIVE_TREATMENT',
  'VETERINARY_VISITS',
  'MEDICATION',
  'OTHER',
] as const;
export type PetHealthClaimType = typeof PetHealthClaimTypeArray[number];

const TravelClaimTypeArray = [
  'AMBULANCE',
  'HOSPITAL_OVERNIGHT',
  'MEDICATION',
  'DOCTORS_VISIT',
  'OTHER',
] as const;
export type TravelClaimType = typeof TravelClaimTypeArray[number];

const GenericClaimTypeArray = ['OTHER'] as const;
export type GenericClaimType = typeof GenericClaimTypeArray[number];

type AllGenericClaimType =
  | GenericClaimType
  | TravelClaimType
  | PetHealthClaimType;

export const claimTypeMapping = ({
  t,
  insuranceType,
}: {
  t: TFunction;
  insuranceType: InsuranceTypes;
}): Record<AllGenericClaimType, string> => ({
  SURGERY: t('claims.generic.questionnaire.claimType.surgery.title', 'Surgery'),
  PREVENTATIVE_TREATMENT: t(
    'claims.generic.questionnaire.claimType.preventativeTreatment.title',
    'Preventative treatment'
  ),
  VETERINARY_VISITS: t(
    'claims.generic.questionnaire.claimType.veterinaryVisits.title',
    'Veterinary visits'
  ),
  MEDICATION: t(
    'claims.generic.questionnaire.claimType.medication.title',
    'Medication'
  ),
  AMBULANCE: t(
    'claims.generic.questionnaire.claimType.ambulance.title',
    'Ambulance'
  ),
  HOSPITAL_OVERNIGHT: t(
    'claims.generic.questionnaire.claimType.hospitalOvernight.title',
    'Hospital overnight'
  ),
  DOCTORS_VISIT: t(
    'claims.generic.questionnaire.claimType.doctorsVisit.title',
    "Doctor's visit"
  ),
  OTHER: `${getTitleMapping(t)[insuranceType]} - ${t(
    'claims.generic.questionnaire.claimType.other.title',
    'Other'
  )}`,
});
