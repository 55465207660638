import { featherIcon } from 'icons';

import style from '../style.module.scss';

const CompletedView = ({ photo }: { photo: string }) => (
  <div className="d-flex fd-column ai-center w100 wmx4 m-auto ta-center py24 px16">
    <img src={featherIcon} alt="Feather logo" className="mb32" />
    <h1 className="p-h1 mb8">Nice take 👏</h1>
    <p className="p-p mb16">
      You can now return to your main device to complete your application.
    </p>
    <img src={photo} className={`wmx3 ${style.photo}`} alt="Preview" />
  </div>
);

export default CompletedView;
