type HeaderProps = {
  title: string;
  headerImage: { src: string; alt: string };
  priceHeader?: {
    title: string;
    subTitle: string;
  };
};

export const Header = ({ title, headerImage, priceHeader }: HeaderProps) => {
  return (
    <>
      <img className="br4" src={headerImage.src} alt={headerImage.alt} />
      <h1 className="p-h1 p--serif mt16">{title}</h1>
      {priceHeader && (
        <div className="d-flex fd-column ai-center jc-center mt8">
          <div className="p-h1--xl p--serif tc-primary-500">
            {priceHeader.title}
          </div>
          <div className="p-p--small fw-normal tc-grey-500">
            {priceHeader.subTitle}
          </div>
        </div>
      )}
    </>
  );
};
