import { website } from '@getpopsure/private-constants';
import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { Input } from '@popsure/dirty-swan';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import { Trans, useSafeTranslation } from 'shared/i18n';

import styles from './Email.module.scss';
import LoginModal from './LoginModal';
import { useEmailQuestion } from './useEmailQuestion';

export const Email = <Questionnaire extends QuestionnaireQuestions>({
  value,
  onSubmitValue,
  verticalId,
  setValidForSubmission,
  showTerms = true,
}: {
  verticalId: keyof GenericQuestionnaireState;
  showTerms?: boolean;
} & QuestionnaireFormProps<Questionnaire>) => {
  const { t } = useSafeTranslation();
  const {
    email,
    handleInputChange,
    handleModalClose,
    toggleTermsAccepted,
    handleSubmit,
    onLogin,
    requiresLoginCode,
  } = useEmailQuestion({
    onSubmitValue,
    setValidForSubmission,
    showTerms,
    value,
  });

  return (
    <>
      <LoginModal
        email={email}
        isOpen={requiresLoginCode}
        onClose={handleModalClose}
        onLogin={onLogin}
        verticalId={verticalId}
      />
      <Header onSubmit={handleSubmit}>
        <Input
          className="mt16 wmx6"
          type="email"
          autoComplete="email"
          value={email}
          placeholder={t('components.email.placeholder', 'Email address')}
          onChange={handleInputChange}
          required
          data-cy="input-email"
        />
        {!showTerms ? (
          <></>
        ) : (
          <div className="mt16 p-label-container">
            <input
              id="terms"
              className="p-checkbox"
              type="checkbox"
              data-testid="email-tnc"
              onChange={toggleTermsAccepted}
            />
            <label
              htmlFor="terms"
              className={`p-label ${styles.label}`}
              data-cy="email-tnc"
            >
              <div className="p-p">
                <Trans i18nKey="components.email.agreement">
                  I have read and understood the{' '}
                  <a
                    className="p-a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={website.privacyPolicy}
                  >
                    privacy policy
                  </a>{' '}
                  and{' '}
                  <a
                    className="p-a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={website.termsAndConditions}
                  >
                    T&Cs
                  </a>
                  .
                </Trans>
              </div>
            </label>
          </div>
        )}
      </Header>
    </>
  );
};
