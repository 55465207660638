import Markdown from 'components/markdown';

export const IconTile = ({
  title,
  iconSrc,
  iconAlt,
  children,
  className = '',
  paragraphClassName = '',
  badge,
}: {
  title: string;
  iconSrc: string;
  iconAlt: string;
  children?: string;
  className?: string;
  paragraphClassName?: string;
  badge?: {
    title: string;
    className?: string;
  };
}) => (
  <div
    className={`bg-white br8 d-flex fd-column ai-center jc-start p24 ${className}`}
  >
    <img src={iconSrc} alt={iconAlt} />
    <div>
      <h4 className="p-h4 w100 ta-center mt16 mb8">{title}</h4>
      {children && (
        <Markdown
          paragraphClassName={`p-p tc-grey-600 ta-center ${paragraphClassName}`}
          openLinksInNewTab
        >
          {children}
        </Markdown>
      )}
    </div>
    {badge?.title && (
      <div className={`p-badge--active mt8 ${badge.className || ''}`}>
        {badge.title}
      </div>
    )}
  </div>
);
