import { Address } from '@getpopsure/public-models';
import { AutocompleteAddress, BottomOrRegularModal } from '@popsure/dirty-swan';
import classNames from 'classnames';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { UpdateBikeQuestionnaireDispatch } from 'models/policies';
import { FormEvent, useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import { updateLiabilityQuestionnaire } from '../actions';
import styles from './AddressModal.module.scss';

const isValidAddress = (value: Partial<Address>): value is Address => {
  return !(
    !value.street ||
    !value.houseNumber ||
    !value.postcode ||
    !value.city ||
    !value.country
  );
};

const hasChanged = (valueA: Partial<Address>, valueB: Partial<Address>) => {
  return !(
    valueA.street === valueB.street &&
    valueA.houseNumber === valueB.houseNumber &&
    valueA.additionalInformation === valueB.additionalInformation &&
    valueA.postcode === valueB.postcode &&
    valueA.city === valueB.city &&
    valueA.country === valueB.country
  );
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  questionnaireId?: string;
  policyId: string;
  address: Partial<Address> | undefined;
}

export const AddressModal = ({
  isOpen,
  onClose,
  questionnaireId,
  policyId,
  address: initAddress,
}: Props) => {
  const { t } = useSafeTranslation();
  const [address, setAddress] = useState<Partial<Address> | undefined>(
    initAddress
  );

  const { loading: submitLoading, error: requestError } = useRequestStatus(
    'UPDATE_QUESTIONNAIRE'
  );
  const [isErrorState, setIsErrorState] = useState<boolean>(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setIsErrorState(requestError !== undefined);
    }
  }, [requestError]);

  const { loading: policyLoading } = useRequestStatus('LOAD_MY_POLICY_DETAIL');
  const dispatch = useDispatch<UpdateBikeQuestionnaireDispatch>();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!address) {
      setIsErrorState(true);
      throw new Error('[Liability] address missing for update');
    }

    if (!isValidAddress(address)) {
      setIsErrorState(true);
      throw new Error('[Liability] address missing fields for update');
    }

    if (!questionnaireId) {
      setIsErrorState(true);
      throw new Error(
        '[Liability] Missing questionnaire id for address update'
      );
    }

    const responseStatus = await dispatch(
      updateLiabilityQuestionnaire({
        answers: { personalInfo: { address } },
        questionnaireId,
        policyId,
      })
    );

    if (responseStatus === 'SUCCESS') {
      onClose();
    }
  };

  const validForSubmission =
    !submitLoading &&
    address &&
    isValidAddress(address) &&
    (!initAddress || hasChanged(address, initAddress));

  if (policyLoading) {
    return <TimedLoadSpinner />;
  }

  return (
    <BottomOrRegularModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        address
          ? t('policy.liability.addressModal.titleEdit', 'Edit your address')
          : t('policy.liability.addressModal.titleAdd', 'Add your address')
      }
    >
      <form
        className={`px24 pt24 pb24 p-p ${styles.form}`}
        data-testid="policy-detail-edit-address-modal"
        onSubmit={onSubmit}
      >
        <AutocompleteAddress
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
          onAddressChange={(updatedAddress) => {
            setAddress(updatedAddress);
            setIsErrorState(false);
          }}
          address={address}
        />
        <AnimateHeight duration={300} height={isErrorState ? 'auto' : 0}>
          <div
            className="p-notice p-notice--warning mt16 p-p"
            data-testid="policy-detail-edit-address-error"
          >
            {t(
              'policy.liability.addressModal.error',
              'Something went wrong and we were unable to update your address.'
            )}
          </div>
        </AnimateHeight>
        <button
          type="submit"
          className={classNames(`mt24 p-btn--primary wmn2`, {
            'p-btn--loading': submitLoading,
          })}
          disabled={!validForSubmission}
          data-testid="policy-detail-address-submit-button"
        >
          {t('policy.liability.addressModal.cta', 'Submit')}
        </button>
      </form>
    </BottomOrRegularModal>
  );
};
