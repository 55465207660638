import { captureException } from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import LoadingSpinner from 'components/loadingSpinner';
import { RegionSelector as RegionSelectorView } from 'components/RegionSelector';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import {
  NewUserSchema,
  regionToNameMapping,
  SignInQuestions,
} from '../../models';

export const RegionSelector = ({
  onSubmitValue,
  questionnaireAnswers,
}: CustomComponentProps<SignInQuestions>) => {
  const { t } = useSafeTranslation();
  const { loading, error } = useRequestStatus('CREATE_USER');
  const dispatch = useDispatch();
  const errorMessage = t(
    'signup.qnr.region.errorMessageServer',
    'An error has occured, please try again.'
  );

  const handleSubmit = (
    selectedRegion: string,
    setError: (error: string) => void
  ) => {
    const region = Object.entries(regionToNameMapping).find(([_, name]) => {
      return name === selectedRegion;
    })?.[0];

    if (!region) {
      setError(
        t('signup.qnr.region.errorMessage', 'Please select a valid region.')
      );
      return;
    }

    const validationResult = NewUserSchema.safeParse({
      firstName: questionnaireAnswers.name?.firstName,
      lastName: questionnaireAnswers.name?.lastName,
      email: questionnaireAnswers.email,
      region,
      dateOfBirth: null,
      gender: null,
    });

    if (!validationResult.success) {
      setError(errorMessage);

      captureException(validationResult.error, {
        level: 'error',
        tags: {
          feature: 'SIGNIN',
          description: 'Validation error on NewUserSchema',
        },
      });

      return;
    }

    dispatch(
      createAccountV2(validationResult.data, () => onSubmitValue(region))
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <RegionSelectorView
      errorMessage={error ? errorMessage : undefined}
      errorVariant="error"
      onSubmit={handleSubmit}
      regions={regionToNameMapping}
    />
  );
};
