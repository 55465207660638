import {
  capitalizeName,
  englishFormattedEuroCurrency,
} from '@getpopsure/public-utility';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Cell from 'features/checkout/components/Cell';
import { PolicyMembers } from 'features/checkout/models';
import { getCheckoutInfo } from 'features/checkout/selectors';
import { getExpatAnswers } from 'features/expat/selectors';
import { mapPlanId } from 'features/expat/utils';
import { ReferrerCodeVerify } from 'features/referralEngine';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import styles from './PolicyDetails.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

type PolicyCheckoutDetailsProps = {
  policyMembersStatus: PolicyMembers;
};

export const PolicyDetails = ({
  policyMembersStatus,
}: PolicyCheckoutDetailsProps) => {
  const policyInfo = useSelector(getCheckoutInfo('EXPAT_V2'));
  const questionnaireAnswers = useSelector(getExpatAnswers);
  const { t } = useSafeTranslation();

  if (!policyInfo) {
    return <></>;
  }

  const { mainPolicy, totalMonthlyPrice, subPolicies } = policyInfo;

  if (!totalMonthlyPrice) {
    return <></>;
  }

  switch (policyMembersStatus) {
    case 'OWNER_ONLY':
      if (!mainPolicy) {
        return <></>;
      }

      return (
        <div className={classNames(styles.container, 'br8 p24 bg-white')}>
          <div className="p-h2 mb16">
            {t('expat.qnr.checkout.policyDetails.title', 'Policy details')}
          </div>
          <Cell
            title={t('expat.qnr.checkout.policyDetails.name', 'Name')}
            value={capitalizeName(mainPolicy.policyHolder.name) ?? ''}
          />
          <Cell
            title={t(
              'expat.qnr.checkout.policyDetails.requestedStartDate',
              'Requested start date'
            )}
            value={dayjs(mainPolicy.startDate).format(DATE_FORMAT)}
            additionalInfo={{
              title: t(
                'expat.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
                'Requested start date'
              ),
              description: t(
                'expat.qnr.checkout.policydetails.requestedStartDate.additionalInfo.description',
                'Your coverage should begin on {{coverageDate}}, but only after you have received the official confirmation.',
                {
                  coverageDate: dayjs(mainPolicy.startDate).format(DATE_FORMAT),
                }
              ),
            }}
          />
          <Cell
            title={t('expat.qnr.checkout.policyDetails.plan', 'Plan')}
            value={mapPlanId[mainPolicy.policyDetails.planId]}
          />
          <Cell
            title={t(
              'expat.qnr.checkout.policyDetails.dateOfBirth',
              'Date of Birth'
            )}
            value={dayjs(mainPolicy.policyHolder.dateOfBirth).format(
              DATE_FORMAT
            )}
          />
          <Cell
            title={t('expat.qnr.checkout.policyDetails.email', 'Email')}
            value={mainPolicy.policyHolder.email}
          />
          {questionnaireAnswers?.parentGuardianName &&
            questionnaireAnswers?.parentGuardianDateOfBirth &&
            questionnaireAnswers?.parentGuardianEmail && (
              <>
                <div className="p-h3 mt24">
                  {t(
                    'expat.qnr.checkout.policyDetails.subTitle',
                    'Parent / Legal guardian'
                  )}
                </div>
                <Cell
                  title={t('expat.qnr.checkout.policyDetails.name', 'Name')}
                  value={
                    capitalizeName(questionnaireAnswers.parentGuardianName) ??
                    ''
                  }
                />
                <Cell
                  title={t(
                    'expat.qnr.checkout.policyDetails.dateOfBirth',
                    'Date of Birth'
                  )}
                  value={dayjs(
                    questionnaireAnswers.parentGuardianDateOfBirth
                  ).format(DATE_FORMAT)}
                />
                <Cell
                  title={t('expat.qnr.checkout.policyDetails.email', 'Email')}
                  value={questionnaireAnswers?.parentGuardianEmail}
                />
              </>
            )}
          <div
            className={`mt24 p-h3 d-flex jc-between pt16 ${styles['price-container']}`}
          >
            <div>
              {t(
                'expat.qnr.checkout.policyDetails.totalPerMonth',
                'Total per month'
              )}
            </div>
            {totalMonthlyPrice && (
              <div>{englishFormattedEuroCurrency(totalMonthlyPrice)}</div>
            )}
          </div>
          <ReferrerCodeVerify />
        </div>
      );

    case 'DEPENDENTS_ONLY':
      if (!subPolicies) {
        return <></>;
      }

      return (
        <div className={classNames(styles.container, 'br8 p24 bg-white')}>
          <div className="p-h2 mb16">
            {t('expat.qnr.checkout.policyDetails.title', 'Policy details')}
          </div>
          {subPolicies.map(({ policyHolder, policyDetails, startDate, id }) => (
            <div key={id}>
              <Cell
                title={t('expat.qnr.checkout.policyDetails.name', 'Name')}
                value={capitalizeName(policyHolder.name) ?? ''}
              />
              <Cell
                title={t(
                  'expat.qnr.checkout.policyDetails.requestedStartDate',
                  'Requested start date'
                )}
                value={dayjs(startDate).format(DATE_FORMAT)}
                additionalInfo={{
                  title: t(
                    'expat.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
                    'Requested start date'
                  ),
                  description: t(
                    'expat.qnr.checkout.policydetails.requestedStartDate.additionalInfo.description',
                    'Your coverage should begin on {{coverageDate}}, but only after you have received the official confirmation.',
                    {
                      coverageDate: dayjs(startDate).format(DATE_FORMAT),
                    }
                  ),
                }}
              />
              <Cell
                title={t('expat.qnr.checkout.policyDetails.plan', 'Plan')}
                value={mapPlanId[policyDetails.planId]}
              />
              <Cell
                title={t(
                  'expat.qnr.checkout.policyDetails.dateOfBirth',
                  'Date of Birth'
                )}
                value={dayjs(policyHolder.dateOfBirth).format(DATE_FORMAT)}
              />
              <div className="p-h3 mt24">
                {t(
                  'expat.qnr.checkout.policyDetails.subTitle',
                  'Parent / Legal guardian'
                )}
              </div>
              <Cell
                title={t('expat.qnr.checkout.policyDetails.name', 'Name')}
                value={capitalizeName(mainPolicy.policyHolder.name) ?? ''}
              />
              <Cell
                title={t(
                  'expat.qnr.checkout.policyDetails.dateOfBirth',
                  'Date of Birth'
                )}
                value={dayjs(mainPolicy.policyHolder.dateOfBirth).format(
                  DATE_FORMAT
                )}
              />
              <Cell
                title={t('expat.qnr.checkout.policyDetails.email', 'Email')}
                value={mainPolicy.policyHolder.email}
              />
            </div>
          ))}
          <div
            className={`mt24 p-h3 d-flex jc-between pt16 ${styles['price-container']}`}
          >
            <div>
              {t(
                'expat.qnr.checkout.policyDetails.totalPerMonth',
                'Total per month'
              )}
            </div>
            {totalMonthlyPrice && (
              <div>{englishFormattedEuroCurrency(totalMonthlyPrice)}</div>
            )}
          </div>
          <ReferrerCodeVerify />
        </div>
      );

    default:
      return <></>;
  }
};
