import * as Sentry from '@sentry/react';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { ClaimsAction } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import { Plan, Provider } from 'models/bike';
import { APIResponseError } from 'models/error';
import endpoint from 'shared/api';
import { ActionResponse, UploadedFile } from 'shared/models/types';

import { BikeClaims, BikeClaimsDispatch } from '../models/BikeClaims.model';

export const storeBikeClaimsAnswers = (
  answer: Partial<BikeClaims>
): ClaimsAction => ({
  type: 'STORE_BIKE_CLAIM',
  bike: answer,
});

export const flushBikeClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_BIKE_CLAIM',
});

export const retrieveBikePolicyDetails =
  (policyId: string) =>
  async (
    dispatch: BikeClaimsDispatch
  ): Promise<{
    policyStartDate: string;
    planId?: Plan;
    questionnaireId: string;
    providerId: Provider;
  } | null> => {
    const requestType: RequestType = 'LOAD_MY_POLICY_DETAIL';
    dispatch(setRequestInProcess(true, requestType));

    try {
      const {
        data: {
          providerId,
          attributes: { frameNumber, startDate, planId, questionnaireId },
          userUploads,
        },
      } = await endpoint.getPolicyDetail(policyId);

      dispatch(setRequestInProcess(false, requestType));

      if (
        !startDate ||
        (providerId === 'BERLIN_DIREKT' && !planId) ||
        !questionnaireId
      ) {
        return null;
      }

      if (frameNumber) {
        dispatch(storeBikeClaimsAnswers({ frameNumber }));
      }

      if (userUploads && userUploads.length > 0) {
        const purchaseReceipts: UploadedFile[] = userUploads.map(
          ({ title, downloadURL: previewUrl }) => ({
            id: title,
            name: title,
            type: '',
            progress: 100,
            previewUrl,
          })
        );
        dispatch(storeBikeClaimsAnswers({ purchaseReceipts }));
      }

      return {
        policyStartDate: startDate,
        planId: planId as Plan | undefined,
        questionnaireId,
        providerId: providerId as Provider,
      };
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      return null;
    }
  };

export const updateBikeClaimsPurchaseReceipts =
  ({
    questionnaireId,
    tokens,
  }: {
    questionnaireId: string;
    tokens: string[];
  }) =>
  async (dispatch: BikeClaimsDispatch): Promise<ActionResponse> => {
    const requestType: RequestType = 'UPDATE_QUESTIONNAIRE';
    dispatch(setRequestInProcess(true, requestType));

    try {
      await endpoint.updateBikeQuestionnaire({}, questionnaireId, tokens);
      dispatch(setRequestInProcess(false, requestType));
      return 'SUCCESS';
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));

      Sentry.captureException(
        `[Bike Claims] Failed trying to store uploaded documents tokens with questionnaire id: ${questionnaireId}`
      );

      return 'ERROR';
    }
  };
