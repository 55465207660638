import { CheckboxWithDescription } from 'components/questionnaireForms/CheckboxForm/CheckboxForm';
import {
  children,
  grandmother,
  heart,
  household,
  none,
  person,
} from 'features/recommendationTool/assets/icons';
import {
  DependentsTypes,
  MainOccupation,
  OtherOccupation,
} from 'features/recommendationTool/models/shared';

export const mainOcuppationMapping: Record<MainOccupation, string> = {
  EMPLOYED: 'Employed in Germany',
  EMPLOYED_OUTSIDE_DE: 'Employed outside of Germany',
  SELF_EMPLOYED: 'Self-employed',
  STUDENT: 'Student',
  UNEMPLOYED: 'Unemployed',
  OTHER: 'Other',
};

export const otherOccupationMapping: Record<OtherOccupation, string> = {
  ARTIST: 'Artist',
  RETIRED: 'Retired',
  CIVIL_SERVANT: 'Civil servant',
};

export const renderIcon = (src: string) => (
  <img alt="" aria-hidden src={src} width="24px" height="24px" />
);

export const dependentsMapping: Record<
  DependentsTypes,
  CheckboxWithDescription
> = {
  SPOUSE: {
    title: 'Spouse',
    icon: () => renderIcon(heart),
  },
  CHILDREN: {
    title: 'Children',
    icon: () => renderIcon(children),
  },
  PARENTS: {
    title: 'Parents',
    icon: () => renderIcon(grandmother),
  },
  MORTGAGE: {
    title: 'Mortgage',
    icon: () => renderIcon(household),
  },
  OTHER: {
    title: 'Other',
    icon: () => renderIcon(person),
  },
  NONE: {
    title: 'None',
    icon: () => renderIcon(none),
  },
};
