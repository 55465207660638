import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { useEffect } from 'react';

type Props<Questionnaire extends QuestionnaireQuestions> = {
  content?: React.ReactNode;
} & QuestionnaireFormProps<Questionnaire, boolean>;

export const CustomBox = <Questionnaire extends QuestionnaireQuestions>({
  content,
  onSubmitValue,
  setValidForSubmission,
}: Props<Questionnaire>) => {
  const handleSubmit = () => {
    onSubmitValue(true);
  };

  useEffect(() => {
    setValidForSubmission(true);
  });

  return (
    <Header onSubmit={handleSubmit}>
      <div className="p-body">{content}</div>
    </Header>
  );
};
