import { TFunction } from '@getpopsure/i18n-react';
import { website } from '@getpopsure/private-constants';
import { isDateFuture } from '@getpopsure/public-utility';
import { Blocker } from 'components/Blocker';
import dayjs from 'dayjs';
import {
  Household,
  HouseholdQuestionnaire,
  questionPropLookup,
} from 'features/household/models';
import { getLatestStartDate } from 'features/household/utils';
import { isUnder18 } from 'shared/util/isUnder18';

import { Account } from './Account';
import { Email } from './Email';

export const personalInfoQuestionnaire = (
  t: TFunction
): HouseholdQuestionnaire => ({
  account: {
    type: 'CUSTOM',
    retrieveAnswerObject: (
      account: Household['account']
    ): Partial<Household> => ({ account }),
    retrieveNextPageId: (account: Household['account']) =>
      account ? 'name' : 'email',
    groupId: 'signup',
    component: Account,
    retrieveQuestionsToRemove: (account: Household['account']) =>
      !account ? ['email', 'name', 'dateOfBirth', 'gender', 'review'] : null,
  },

  email: {
    required: true,
    type: 'CUSTOM',
    groupId: 'signup',
    component: Email,
    retrieveQuestionsToRemove: () => ['review'],
  },

  name: {
    required: true,
    type: 'NAME',
    props: {
      ...questionPropLookup(t).name,
    },
    retrieveAnswerObject: (name: Household['name']): Partial<Household> => ({
      name,
    }),
    groupId: 'signup',
    retrieveQuestionsToRemove: () => ['review'],
    retrieveNextPageId: () => 'dateOfBirth',
  },

  dateOfBirth: {
    required: true,
    type: 'DATE',
    props: {
      ...questionPropLookup(t).dateOfBirth,
      yearRange: {
        min: dayjs().subtract(100, 'years').year(),
        max: dayjs().subtract(18, 'years').year(),
      },
    },
    retrieveAnswerObject: (
      dateOfBirth: Household['dateOfBirth']
    ): Partial<Household> => ({
      dateOfBirth,
    }),
    groupId: 'signup',
    retrieveNextPageId: (answer: string) =>
      isUnder18(answer) ? 'ageBlocker' : 'gender',
    retrieveQuestionsToRemove: () => ['review'],
  },

  ageBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'page.household.questionnaire.ageblocker.title',
          "We're sorry"
        ),
        description: t(
          'page.household.questionnaire.ageblocker.description',
          'You need to be at least 18 years old to purchase household contents insurance with us.\n\nIf you have any questions, feel free to reach out.'
        ),
        buttonProps: [
          {
            type: 'href',
            href: website.support,
            caption: t(
              'page.household.questionnaire.ageblocker.button.caption',
              'Get in touch'
            ),
          },
        ],
        ...props,
      }),
    groupId: 'signup',
  },

  gender: {
    required: true,
    type: 'RADIO',
    props: {
      mapValue: {},
      ...questionPropLookup(t).gender,
    },
    retrieveAnswerObject: (
      gender: Household['gender']
    ): Partial<Household> => ({
      gender,
    }),
    groupId: 'signup',
    retrieveQuestionsToRemove: () => ['review'],
  },

  startDate: {
    required: true,
    type: 'DATE',
    props: {
      ...questionPropLookup(t).startDate,
      yearRange: {
        min: dayjs().year(),
        max: dayjs().add(2, 'years').year(),
      },
    },
    retrieveAnswerObject: (
      startDate: Household['startDate']
    ): Partial<Household> => ({
      startDate,
    }),
    groupId: 'signup',
    validateAnswer: (answer: string) => {
      const tomorrowDateStr = dayjs().add(1, 'day').format('DD MMM YYYY');

      if (!isDateFuture(answer)) {
        return t(
          'page.household.questionnaire.startdate.error.onlyfuturedate',
          {
            defaultValue:
              "We're only able to offer this coverage starting from {{tomorrowDateStr}}. Please select another date.",
            tomorrowDateStr,
          }
        );
      }
      const latestStartDate = dayjs(getLatestStartDate(tomorrowDateStr));
      const answerDate = dayjs(answer);

      if (answerDate.isAfter(latestStartDate)) {
        return t('page.household.questionnaire.startdate.error.max2years', {
          defaultValue:
            "We're currently only able to provide plans with a start date before {{latestStartDateStr}}. Please select another date.",
          latestStartDateStr: latestStartDate
            .add(1, 'day')
            .format('DD MMM YYYY'),
        });
      }
      return null;
    },
    retrieveNextPageId: () => 'review',
    retrieveQuestionsToRemove: () => ['review'],
  },
});
