import { Country, getAllCountries } from '@getpopsure/public-models';

export const filterOutCountries = (
  countries: Country[],
  locale?: Parameters<typeof getAllCountries>[0]
) => {
  const countryCodes = countries.map(({ code }) => code);
  const countryNames = countries.map(({ name }) => name);

  return getAllCountries(locale).filter(
    ({ code, name }) =>
      !countryCodes.includes(code) && !countryNames.includes(name)
  );
};
