import { storeSubmittableClaimInfo } from 'actions/claims';
import routes from 'constants/routes';
import View from 'features/claimsProcess/uploadDocuments/view';
import { ClaimType } from 'models/claims';
import { connect, MapDispatchToProps } from 'react-redux';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { AppState } from 'reducers';
import { getSubmittableClaimInfo } from 'selectors/claims';
import { UploadedFile } from 'shared/models/types';

interface StateProps {
  storedClaimTypeInfo?: ClaimType;
  storedUploadedFiles?: UploadedFile[];
  storedDetails?: string;
}

interface DispatchProps {
  storeSubmittableClaimInfo: (claimInfo: {
    uploadedFiles: UploadedFile[];
    details?: string;
  }) => void;
  continueToPaymentInfo: () => void;
}

type OwnProps = RouteComponentProps<{ policyId: string }>;

export type ViewProps = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: AppState): StateProps => {
  const submittableClaimInfo = getSubmittableClaimInfo(state);

  return {
    storedClaimTypeInfo: submittableClaimInfo?.claimType,
    storedDetails: submittableClaimInfo?.details,
    storedUploadedFiles: submittableClaimInfo?.uploadedFiles,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  {
    history,
    match: {
      params: { policyId },
    },
  }
): DispatchProps => ({
  storeSubmittableClaimInfo: (claimInfo: {
    uploadedFiles: UploadedFile[];
    details?: string;
  }) => {
    dispatch(storeSubmittableClaimInfo({ ...claimInfo }));
  },
  continueToPaymentInfo: () => {
    history.push(
      generatePath(routes.me.claims.paymentInfo.path, {
        policyId,
      })
    );
  },
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(View);
