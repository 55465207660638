export interface RedirectToIntroPageParams {
  personaId?: string | null;
  showIntroPage?: boolean;
}

export const getRedirectToIntroPage = ({
  personaId,
  showIntroPage,
}: RedirectToIntroPageParams): boolean => {
  if (!personaId) {
    return true;
  }

  if (typeof showIntroPage === 'undefined') return true;

  return showIntroPage;
};
