import {
  removeDependentGenericQuestionnaireAnswer,
  storeDependentGenericQuestionnaireAnswer,
} from 'features/expat/actions';
import { ExpatDependentQuestionnaire } from 'features/expat/models';
import { getTranslatedDependentQuestionnaire } from 'features/expat/questionnaire';
import { getExpatDependentAnswers } from 'features/expat/selectors';
import Questionnaire from 'features/questionnaireFramework';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

export const DependentsSignupPage = () => {
  const { t } = useSafeTranslation();
  const answers = useSelector(getExpatDependentAnswers) || {};
  const { mainPolicyId } = useParams<{ mainPolicyId: string }>();
  const dispatch = useDispatch();

  const handleAnswerQuestion = (
    answer: Partial<ExpatDependentQuestionnaire>
  ) => {
    dispatch(
      storeDependentGenericQuestionnaireAnswer({
        ...answer,
      })
    );
  };

  const handleRemoveAnswer = (
    questionIds: (keyof ExpatDependentQuestionnaire)[]
  ) => {
    dispatch(removeDependentGenericQuestionnaireAnswer(questionIds));
  };

  return (
    <Questionnaire
      featureName="Expat"
      questionnaire={getTranslatedDependentQuestionnaire(t, mainPolicyId)}
      questionnaireAnswers={answers}
      handleAnswerQuestion={handleAnswerQuestion}
      handleRemoveAnswer={handleRemoveAnswer}
    />
  );
};
