import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import {
  Button,
  ChevronRightIcon,
  InformationBox,
  PlusIcon,
  XIcon,
} from '@popsure/dirty-swan';
import routes from 'constants/routes';
import { storeAddBeneficiariesAnswers } from 'features/lifeBeneficiaries/actions';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { AppState } from 'reducers';
import { getPolicyDetail } from 'selectors/policies';

import styles from './Review.module.scss';

const Review = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  setValidForSubmission,
}: QuestionnaireFormProps<Questionnaire, boolean>) => {
  const [payoutSumWarning, setPayoutSumWarning] = useState(false);
  const [payoutForAllWarning, setPayoutForAllWarning] = useState(false);
  const { policyId } = useParams<{ policyId: string }>();

  const policy = useSelector((state: AppState) =>
    policyId !== undefined ? getPolicyDetail(state, policyId) : undefined
  );

  const answers =
    useSelector((state: AppState) => state.lifeBeneficiaries.questionnaire) ||
    {};

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setValidForSubmission(true);
  }, [setValidForSubmission]);

  const payoutSum =
    answers.beneficiaries?.reduce((acc, beneficiary) => {
      const payout = Number(beneficiary.payoutPercentage);
      return acc + (Number.isNaN(payout) ? 0 : payout);
    }, 0) ?? 0;

  const handleSplitEuroPayout = (
    fullCoverage?: number,
    payoutPercentage?: number
  ) => {
    if (!fullCoverage || !payoutPercentage) {
      return '—';
    }

    const splitInEuro = englishFormattedEuroCurrency(
      (fullCoverage / 100) * payoutPercentage,
      true
    );

    return String(splitInEuro).substring(1);
  };

  const handleSetPayout = (
    e: ChangeEvent<HTMLInputElement>,
    beneficiaryId: string
  ) => {
    const updatedBeneficiaries = answers.beneficiaries?.map((beneficiary) => {
      if (beneficiary.id === beneficiaryId) {
        return {
          ...beneficiary,
          payoutPercentage: e.target.value ? Number(e.target.value) : undefined,
        };
      }
      return beneficiary;
    });
    dispatch(
      storeAddBeneficiariesAnswers({
        ...answers,
        beneficiaries: updatedBeneficiaries,
      })
    );
  };

  const handleDeleteBeneficiary = (beneficiaryId: string) => {
    const updatedBeneficiaries = answers.beneficiaries?.filter(
      (beneficiary) => {
        return beneficiary.id !== beneficiaryId;
      }
    );
    dispatch(
      storeAddBeneficiariesAnswers({
        ...answers,
        beneficiaries: updatedBeneficiaries,
      })
    );
  };

  const handleAddBeneficiary = () => {
    dispatch(
      storeAddBeneficiariesAnswers({
        ...answers,
        currentBeneficiaryId: undefined,
        name: undefined,
        dateOfBirth: undefined,
        uploads: [],
      })
    );
    history.push(
      generatePath(routes.me.policies.life.beneficiaries.questionnaire.path, {
        policyId,
        groupId: 'questionnaire',
        questionId: 'intro',
      })
    );
  };

  const handleEditBeneficiary = (beneficiaryId: string) => {
    const editBeneficiary = answers.beneficiaries?.find(
      (beneficiary) => beneficiary.id === beneficiaryId
    );
    dispatch(
      storeAddBeneficiariesAnswers({
        ...answers,
        currentBeneficiaryId: editBeneficiary?.id,
        name: editBeneficiary?.name,
        dateOfBirth: editBeneficiary?.dateOfBirth,
        uploads: editBeneficiary?.uploads,
      })
    );
    history.push(
      generatePath(routes.me.policies.life.beneficiaries.questionnaire.path, {
        policyId,
        groupId: 'questionnaire',
        questionId: 'intro',
      })
    );
  };

  const handleSubmit = () => {
    const allBeneficiariesHavePayouts = answers.beneficiaries?.reduce(
      (acc, beneficiary) => acc && !!beneficiary.payoutPercentage,
      true
    );
    setPayoutSumWarning(payoutSum !== 100);
    setPayoutForAllWarning(!allBeneficiariesHavePayouts);

    if (payoutSum === 100 && allBeneficiariesHavePayouts) {
      onSubmitValue(true);
    }
  };

  return (
    <Header onSubmit={handleSubmit}>
      <>
        {answers.beneficiaries?.map((beneficiary) => {
          return (
            <div
              className={`mb16 d-flex ai-center ${styles.beneficiaries}`}
              key={beneficiary.id}
            >
              <button
                className={`${styles.container} ${styles.beneficiaryCard} c-pointer ta-left w100 py0 px16`}
                type="button"
                onClick={() => handleEditBeneficiary(beneficiary.id)}
                data-cy="edit-beneficiary"
              >
                <div className="p-p tc-grey-700">{`${beneficiary?.name.firstName} ${beneficiary?.name.lastName}`}</div>
                <ChevronRightIcon size={24} color="primary-500" />
              </button>
              <input
                className={`ml16 p-input d-flex ${styles.payoutInput} ${styles.payoutSplit}`}
                placeholder="%  Payout"
                value={beneficiary?.payoutPercentage}
                onChange={(e) => handleSetPayout(e, beneficiary.id)}
                type="number"
                data-testid="ds-input-input"
              />
              <div
                className={`ml16 p-input d-flex gap8 bg-grey-300 ${styles.payoutSplitEuro} ${styles.payoutSplit}`}
              >
                <p className="tc-grey-600">€</p>
                <p>
                  {handleSplitEuroPayout(
                    policy?.attributes.coverage,
                    beneficiary.payoutPercentage
                  )}
                </p>
              </div>
              <button
                className="ml16 bg-transparent c-pointer"
                type="button"
                onClick={() => handleDeleteBeneficiary(beneficiary.id)}
                data-cy="remove-beneficiary"
              >
                <p className={styles.xIcon}>
                  <XIcon size={24} color="primary-500" />
                </p>
                <p className={`p-h4 tc-primary-500 ${styles.removeButton}`}>
                  Remove
                </p>
              </button>
            </div>
          );
        })}
        <Button
          variant="textColor"
          className={styles.addBeneficiaryButton}
          leftIcon={<PlusIcon />}
          onClick={handleAddBeneficiary}
          type="button"
        >
          Add beneficiaries
        </Button>
        {payoutSumWarning && (
          <InformationBox
            variant="warning"
            className={`${styles.addBeneficiaryButton} mt24`}
          >
            {payoutSum > 100 && 'The payout sum exceeds 100%. '}
            Please adjust the payouts so that they add up to 100%.
          </InformationBox>
        )}
        {payoutForAllWarning && (
          <InformationBox
            variant="warning"
            className={`${styles.addBeneficiaryButton} mt24`}
          >
            Please assign the payout percentage to each beneficiary.
          </InformationBox>
        )}
        <p className="p-p tc-grey-700 mt24">
          {100 - payoutSum < 0 ? 0 : 100 - payoutSum}% left to assign.
        </p>
      </>
    </Header>
  );
};

export default Review;
