import classNames from 'classnames';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import { formatCardLastDigits } from 'shared/util/cardFormatters';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import { DisputeInvoice } from '../models';
import {
  getPaymentMethodIcon,
  mapBillingStatus,
  mapBillingType,
  mapStatusBadgeColors,
} from '../utils';
import styles from './BillingItem/styles.module.scss';
import { DisputeInvoiceDetailsModal } from './DisputeInvoiceDetailsModal';
import { StatusBadge } from './StatusBadge';

export const DisputeInvoiceList = ({
  className = '',
  disputeInvoices = [],
}: {
  className?: string;
  disputeInvoices?: DisputeInvoice[];
}) => {
  const { t } = useSafeTranslation();
  const [selectedDisputeInvoice, setSelectedDisputeInvoice] =
    useState<DisputeInvoice | null>(null);

  if (disputeInvoices.length === 0) return null;

  return (
    <>
      {selectedDisputeInvoice && (
        <DisputeInvoiceDetailsModal
          onClose={() => setSelectedDisputeInvoice(null)}
          disputeInvoice={selectedDisputeInvoice}
        />
      )}

      <div className={`d-flex fd-column ai-center w100 ${className}`}>
        <div className="bg-white br8 w100">
          {disputeInvoices.map((invoice, i) => {
            const cx = classNames(
              'w100 py16 px16 ds-interactive-component',
              styles.container,
              {
                [styles.bottomBorder]: i !== disputeInvoices.length - 1,
              }
            );

            return (
              <div
                role="button"
                tabIndex={0}
                className={cx}
                key={invoice.stripeId}
                onClick={() => setSelectedDisputeInvoice(invoice)}
                onKeyUp={(ev) => ev.preventDefault()}
              >
                <div
                  className={`mr24 ${styles.leftColumn} ${styles.noMarginMobile} ${styles.minHeightMobile}`}
                >
                  <h4 className="p-h4 tc-grey-700">
                    {mapBillingType[invoice.paymentType]}
                  </h4>
                  <div
                    className={`p-p--small tc-grey-500 ${styles.topMarginMobile} ${styles.paymentDate}`}
                  >
                    {dayjs(invoice.createdAt).format('DD MMM YYYY')}
                  </div>
                </div>

                <div
                  className={classNames(
                    styles.rightColumn,
                    styles.minHeightMobile
                  )}
                >
                  <div
                    className={`mr32 ${styles.noMarginMobile} ${styles.topMarginMobile}`}
                  >
                    <StatusBadge
                      title={mapBillingStatus(t)[invoice.status]}
                      badgeColor={mapStatusBadgeColors[invoice.status]}
                    />
                  </div>
                  <div
                    className={`p-h4 mr24 tc-grey-700 ${styles.noMarginMobile} ${styles.fixedWidthAmount}`}
                  >
                    {englishFormattedEuroCurrency(invoice.amount)}
                  </div>

                  <img
                    className={`mr16 bg-grey-200 ${styles.brandIcon} ${styles.hideOnMobile}`}
                    src={getPaymentMethodIcon(invoice)}
                    alt="payment method logo"
                  />

                  <div
                    className={`p-p--small tc-grey-600 ${styles.hideOnMobile} ${styles.paypalEmail}`}
                  >
                    {invoice.paymentMethodDetails.type === 'paypal'
                      ? invoice.paymentMethodDetails.identifier
                      : formatCardLastDigits(
                          invoice.paymentMethodDetails.lastDigits
                        )}
                  </div>
                  <div
                    className={`d-flex jc-center ai-center ml32 mr8 ${styles.receiptSpinner}`}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
