import { Badge, Card } from '@popsure/dirty-swan';
import classNames from 'classnames';
import Tooltip from 'components/common/tooltip';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import { mapPolicyStatus } from 'features/policiesDashboard/models';
import { PolicyStatusOrNull } from 'features/policiesDashboard/utils/getPolicyStatus';
import { Price } from 'features/policySingle/components/price';
import { PriceProps } from 'features/policySingle/components/price/Price';
import { info } from 'icons';
import { InsuranceTypes } from 'models/insurances/types';
import ReactTooltip from 'react-tooltip';
import { useSafeTranslation } from 'shared/i18n';

import { getImageSrc } from './getImageSrc';
import styles from './Header.module.scss';

export interface HeaderProps {
  title: string;
  subtitle?: string;
  companyName?: string;
  policyStatus: PolicyStatusOrNull;
  price?: PriceProps;
  insuranceType: InsuranceTypes;
  activeUntil?: string;
  providerName?: string;
  startDate?: string;
  backgroundColor: string;
}

export const Header = (props: HeaderProps) => {
  const {
    title,
    subtitle,
    companyName,
    policyStatus,
    price,
    insuranceType,
    activeUntil,
    providerName,
    startDate,
    backgroundColor,
  } = props;
  const { t } = useSafeTranslation();
  const { src, srcSet, sizes } = getImageSrc(insuranceType);

  const statusBadge = mapPolicyStatus(
    policyStatus,
    { activeUntil, providerName, startDate },
    t
  );

  return (
    <div>
      <ReactTooltip effect="solid" className={styles.tooltip} />
      <div className={styles.navWrapper} style={{ backgroundColor }}>
        <div className="p-container">
          <NavButton
            tall={false}
            path={routes.me.policies.path}
            title={t('myPolicies.policyDetails.backButton.caption', 'Policies')}
          />
        </div>
      </div>
      <div className={classNames('px16', styles.wrapper)}>
        <div className={styles.background} style={{ backgroundColor }} />
        <Card
          classNames={{ wrapper: classNames('w100 wmx12 m-auto', styles.card) }}
        >
          <div className="d-flex ai-start jc-between">
            <div className={styles.contentWrapper}>
              <div className={styles.contentContainer}>
                <h1 className={`p-h1 ${styles.title}`}>{title}</h1>
                <div className={styles.subtitlesContainer}>
                  {companyName && (
                    <p
                      className={classNames(
                        'p-p--small tc-grey-600',
                        styles.companyName
                      )}
                    >
                      {companyName}
                    </p>
                  )}
                  <p
                    className={classNames(
                      'p-p p-p--small tc-grey-600',
                      styles.policyHolder
                    )}
                  >
                    {subtitle}
                  </p>
                  {price && (
                    <Price
                      amount={price.amount}
                      billingType={price.billingType}
                      className={classNames('p-p--small tc-grey-600', {
                        [styles.price]: price && subtitle,
                      })}
                    />
                  )}
                </div>
              </div>
              {statusBadge && (
                <Badge
                  className={`${styles.badge} d-inline-flex ai-center`}
                  size="small"
                  variant={statusBadge.variant}
                >
                  {statusBadge.text}
                  {statusBadge?.info && (
                    <Tooltip
                      className="ml8"
                      icon={info}
                      message={statusBadge?.info}
                    />
                  )}
                </Badge>
              )}
            </div>
            <img
              className={classNames('br8', styles.icon)}
              src={src}
              srcSet={srcSet}
              alt={title}
              sizes={sizes}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};
