import { calendly } from '@getpopsure/private-constants';
import { Household, HouseholdAddon } from 'features/household/models';
import { getPolicyOptionsOrDefaults } from 'features/household/selectors';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import styles from './AddonsPage.module.scss';
import { CustomizationSection } from './CustomizationSection';
import { PlanSection } from './PlanSection';
import { PriceDetailsSection } from './PriceDetailsSection';

export const AddonsPage = ({
  onSubmitValue,
  routes,
}: CustomComponentProps<Household>) => {
  const { t } = useSafeTranslation();

  const policyOptions = useSelector(getPolicyOptionsOrDefaults);

  const BASIC_BIKE_COVERAGE = Math.round(policyOptions.insuredSum * 0.01);

  const MINIMUM_BIKE_INSURED_SUM = BASIC_BIKE_COVERAGE + 1;
  const MAXIMUM_BIKE_INSURED_SUM = 3000;

  const SLIDER_STEP_SIZE_IN_EURO = 50;

  // Set default value to 20% of the distance between min and max
  let DEFAULT_BIKE_INSURED_SUM =
    MINIMUM_BIKE_INSURED_SUM +
    (MAXIMUM_BIKE_INSURED_SUM - MINIMUM_BIKE_INSURED_SUM) * 0.2;

  // But clamp it to a multiple of SLIDER_STEP_SIZE_IN_EURO
  DEFAULT_BIKE_INSURED_SUM -=
    DEFAULT_BIKE_INSURED_SUM % SLIDER_STEP_SIZE_IN_EURO;

  const [selectedAddons, setSelectedAddons] = useState<HouseholdAddon[]>(
    policyOptions.selectedAddons
  );

  const [bikeInsuredSum, setBikeInsuredSum] = useState(
    policyOptions.bikeInsuredSum || DEFAULT_BIKE_INSURED_SUM
  );

  // Filter the selected addons for later submission
  const filteredSelectedAddons = selectedAddons.flatMap((addon) => {
    if (
      addon === 'ADDITIONAL_BIKE_COVERAGE' &&
      !selectedAddons.includes('EXTENDED_COVERAGE')
    ) {
      return [];
    }
    return [addon];
  });

  return (
    <div className={styles.container}>
      <PlanSection questionnaireRoutes={routes} />
      <CustomizationSection
        selectedAddons={selectedAddons}
        setSelectedAddons={setSelectedAddons}
        bikeInsuredSum={bikeInsuredSum}
        setBikeInsuredSum={setBikeInsuredSum}
        sliderConfig={{
          BASIC_BIKE_COVERAGE,
          MINIMUM_BIKE_INSURED_SUM,
          MAXIMUM_BIKE_INSURED_SUM,
          SLIDER_STEP_SIZE_IN_EURO,
        }}
      />
      <PriceDetailsSection addonOptions={{ selectedAddons, bikeInsuredSum }} />
      <div className="d-flex fd-column ai-center gap16 pt48 pb48">
        <button
          className="p-btn--primary wmn3"
          data-cy="button-continue"
          onClick={() =>
            onSubmitValue({
              selectedAddons: filteredSelectedAddons,
              bikeInsuredSum:
                selectedAddons.includes('ADDITIONAL_BIKE_COVERAGE') &&
                selectedAddons.includes('EXTENDED_COVERAGE')
                  ? bikeInsuredSum
                  : 0,
            })
          }
          type="button"
        >
          {t('page.household.addons.cta.continue.caption', 'Continue')}
        </button>
        <a
          className="p-btn--secondary bg-white wmn3"
          href={calendly.householdContents}
          target="_blank"
          rel="noreferrer"
        >
          {t(
            'page.household.addons.cta.schedulecall.caption',
            'Schedule a call'
          )}
        </a>
      </div>
    </div>
  );
};
