import { TFunction } from 'shared/i18n';

export const preQuoteQuestions = (t: TFunction) => ({
  name: t('legal.qnr.signup.name.question', 'What is your name?'),
  email: t('legal.qnr.signup.email.question', 'What is your email?'),
  gender: t('legal.qnr.signup.gender.question', 'What is your gender?'),
  address: t(
    'legal.qnr.signup.address.question',
    'What is your address in Germany?'
  ),
  startDate: t(
    'legal.qnr.signup.startDate.question',
    'When would you like your coverage to start?'
  ),
  dateOfBirth: t(
    'legal.qnr.preQuote.dateOfBirth.question',
    'When were you born?'
  ),
  postcode: t(
    'legal.qnr.preQuote.postcode.question',
    'What is your postcode in Germany?'
  ),
  includeSpouse: t(
    'legal.qnr.preQuote.includeSpouse.question',
    'Do you want to include a spouse or a partner?'
  ),
  employmentStatus: t(
    'legal.qnr.preQuote.employmentStatus.question',
    'What is your employment status?'
  ),
  hadGermanLegalInsurance: t(
    'legal.qnr.preQuote.hadGermanLegalInsurance.question',
    'Did you have legal insurance in Germany in the last five years?'
  ),
  insurer: t(
    'legal.qnr.preQuote.insurer.question',
    'What was the name of your last legal insurance provider in Germany?'
  ),
  numberOfPreviousClaims: t(
    'legal.qnr.preQuote.numberOfPreviousClaims.question',
    'How many legal insurance claims did you make in Germany over the past five years?'
  ),
  yearsSinceLastClaim: t(
    'legal.qnr.preQuote.yearsSinceLastClaim.question',
    'How many years has it been since your last legal insurance claim?'
  ),
  terminationParty: t(
    'legal.qnr.preQuote.terminationParty.question',
    'Who terminated your last legal insurance contract?'
  ),
  endDateOfPreviousInsurance: t(
    'legal.qnr.preQuote.endDateOfPreviousInsurance.question',
    'What is the end date for your previous legal insurance?'
  ),
  hasCriminalConvictions: t(
    'legal.qnr.signup.hasCriminalConviction',
    'Have you been convicted of any criminal or regulatory offenses in the last 2 years?'
  ),
  hasPendingCriminalInvestigation: t(
    'legal.qnr.signup.hasPendingCriminalInvestigation',
    'Are you currently involved in any pending criminal investigation?'
  ),
  partnerName: t(
    'legal.qnr.signup.partnerName.question',
    "What is your spouse or partner's name?"
  ),
  propertyOwnership: t(
    'legal.qnr.signup.propertyOwnership.question',
    'Are you renting or owning this property?'
  ),
});
