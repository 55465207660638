import { MIN_COVERAGE } from '../../../../models';

export type ErrorType = 'UNDER_MIN' | 'OVER_MAX';

export const validate = (
  coverage: number,
  maxCoverage: number
): ErrorType | null => {
  if (coverage < MIN_COVERAGE) {
    return 'UNDER_MIN';
  }

  if (coverage > maxCoverage) {
    return 'OVER_MAX';
  }

  return null;
};
