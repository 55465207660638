import { ValidVerticalUrlParams } from 'models/insurances/types';
import { useSafeTranslation } from 'shared/i18n';

import { ClaimSubmittedView } from './ClaimSubmitted.view';

interface Props {
  children?: React.ReactNode;
  vertical: ValidVerticalUrlParams | 'private';
}

export const ClaimSubmitted = ({ children, vertical }: Props) => {
  const { t } = useSafeTranslation();
  return (
    <ClaimSubmittedView t={t} vertical={vertical}>
      {children}
    </ClaimSubmittedView>
  );
};
