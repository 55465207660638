import { blog, insurance } from '@getpopsure/private-constants';
import { FAQInfo } from 'components/faq';

export const shortTermFAQContent: Array<FAQInfo> = [
  {
    questionId: 1,
    question: 'Is there a cancelation period for private insurance?',
    answer:
      'There is a 1 month cancellation period. You need to provide the following:\n\n * Notice of cancellation, 15 days before the end of the month\n * Proof of a follow-up health insurance **_OR_** your de-registration from Germany and plane tickets, if you move away',
  },
  {
    questionId: 2,
    question: 'How can I switch to long-term private coverage?',
    answer: `If you start out with the short-term coverage, you will only need to switch to the long-term coverage if you stay in Germany for longer than 5 years. Our team will then happily assist you with the switch and your pre-existing conditions will be covered.\n\nIf you want to apply to the long-term cover right away, you can look at it [here](${insurance.privateHealth.preSignup}/quote?light=eligible).`,
  },
  {
    questionId: 3,
    question:
      'What happens if I do end up staying in Germany for more than 5 years?',
    answer:
      'You can easily switch to our long-term private health coverage after you have spent 5 years in Germany.\n\nSo, what’s the difference between the two?\nEvery long-term private health coverage includes a component called “old-age savings”, but the short-term coverage does not - which is why the monthly premium is extremely affordable.\n\nWhat do old-age savings do? Typically, you need more medical assistance as you get older. But obviously, you do not want your private health to skyrocket. That is why the long-term health cover collects savings that ensure your private health cover stays affordable as you get older. That’s why, if you’re planning on staying in Germany only for a short period, you do not need this component. So, if you end up staying longer, you can simply upgrade.',
  },
  {
    questionId: 4,
    question:
      'Will my private short-term coverage fulfill the requirement for my blue card/residence permit application?',
    answer:
      'Private short-term coverage fulfills the requirements for blue card applications.',
  },
  {
    questionId: 5,
    question: 'How can I switch between public and private health insurance?',
    answer: `To cancel public health insurance, you will go through 3 steps:\n\n 1. Hand in a [cancelation form](https://docs.google.com/document/d/1i8-vRkDsEMxPWmNvfDiFCTaX5Riq61bFzT5_-Uu2_wI) to your public provider. This will kick off the cancellation period, which is typically 2 months plus the ongoing month. _Example: You hand in the cancelation form on January 15th, so your private insurance can start earliest on April 1st._\n2. Apply for private health insurance.\n3. Forward the confirmation of private health coverage to your public provider. This confirmation document is uploaded automatically to your Feather account after your coverage is confirmed.\n\nCheck out our [article](${blog.base}switching-private-public-mythbusters) to see how you can switch from private to public insurance!`,
  },
  {
    questionId: 6,
    question: 'Can private health insurance companies deny coverage?',
    answer:
      'Yes, you can be denied coverage by private health insurance companies. The most common reasons for a denial are the following pre-existing health conditions: ADHD, diabetes, arthritis, autoimmune diseases, asthma, cancer and mental health conditions. Other health conditions can lead to risk surcharges or exclusions of coverage.\n\nIf you cannot be covered or if your coverage needs to be modified, our team will reach out to you with step by step guidance, so you can take the decision that is right for you.',
  },
  {
    questionId: 7,
    question: 'How does the monthly payment work?',
    answer:
      'The total monthly cost will be deducted from your bank account. Employers pay approximately 50% of your cost by transferring their monthly share to your bank account.\n\nWhy is the payment not automatically done by your employer when you receive your salary? You are the legal owner of your own private insurance policy, so your bank account must be used.',
  },
  {
    questionId: 8,
    question: 'When will I know more about my application?',
    answer:
      'You will already hear back within a couple of days. In case the insurance provider asks for additional information, our team will assist you.\n\nIf you have not had comprehensive health insurance in the past 12 months, medical examinations might be needed. In this case, our team will automatically reach out and help you set up the appointments.',
  },
];
