import { DownloadIcon, FileTextIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { PolicyDocument } from 'models/policies';

import styles from './style.module.scss';

const DocumentCell = ({ title, downloadURL }: PolicyDocument) => (
  <a
    href={downloadURL}
    style={{ textDecoration: 'none', display: 'block' }}
    target="_blank"
    rel="noopener noreferrer"
  >
    <div className={classNames('py16', styles.container)}>
      <FileTextIcon
        size={24}
        color="grey-500"
        noMargin
        className="ml16 mr16 ws1"
      />
      <div className={`p-p ${styles.title}`}>{title}</div>

      <DownloadIcon
        className={styles.icon}
        color="primary-500"
        size={24}
        noMargin
      />
    </div>
  </a>
);

export default DocumentCell;
