import { fetchAccountInfo } from 'actions/user';
import { Policy } from 'models/policies';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountInfo } from 'selectors/user';

import {
  getActivePrivateHealthPolicy,
  PaymentMethodDispatch,
} from '../../paymentMethods.actions';
import { ChangePrivateHealthPaymentMethodView } from './ChangePrivateHealthPaymentMethod.view';

export const ChangePrivateHealthPaymentMethod = () => {
  const [policy, setPolicy] = useState<Policy | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const dispatch = useDispatch<PaymentMethodDispatch>();
  const accountInfo = useSelector(getAccountInfo);

  useEffect(() => {
    const fetchActivePrivateHealthPolicy = async () => {
      await dispatch(fetchAccountInfo());
      const response = await dispatch(getActivePrivateHealthPolicy());
      setPolicy(response);
    };

    fetchActivePrivateHealthPolicy();
  }, [dispatch]);

  if (!accountInfo || !policy) {
    return null;
  }

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  return (
    <ChangePrivateHealthPaymentMethodView
      policy={policy}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      openModal={openModal}
      accountInfo={accountInfo}
    />
  );
};
