import { validateAccount } from 'actions/account';
import View from 'components/signupAuthentication/email/view';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useDispatch } from 'react-redux';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';

interface Props {
  verticalId: keyof GenericQuestionnaireState;
  redirectPath: string;
  redirectExistingUserPath?: string;
  storedAnswer?: string;
}

const Email = ({
  verticalId,
  redirectPath,
  redirectExistingUserPath,
  storedAnswer = '',
}: Props) => {
  const { loading, error } = useRequestStatus('VALIDATE_ACCOUNT');
  const dispatch = useDispatch();

  const handleValidateAccount = (
    email: string,
    onUserExists: (userExists: boolean) => void
  ) => {
    dispatch(validateAccount(verticalId, email, redirectPath, onUserExists));
  };

  return (
    <View
      redirectPath={redirectPath}
      redirectExistingUserPath={redirectExistingUserPath}
      loading={loading}
      error={error}
      handleValidateAccount={handleValidateAccount}
      verticalId={verticalId}
      storedAnswer={storedAnswer}
    />
  );
};

export default Email;
