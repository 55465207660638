import { InfoIcon } from '@popsure/dirty-swan';
import Markdown from 'components/markdown';
import React from 'react';

import styles from './style.module.scss';

interface Props {
  title: string;
  description?: string;
  children?: React.ReactNode;
}

const InfoBox = ({ title, description, children }: Props) => (
  <div className={styles['info-box']}>
    <div className={styles['info-icon-container']}>
      <InfoIcon size={24} color="primary-500" noMargin />
    </div>
    <div>
      <p className="p-p--small fw-bold">{title}</p>
      {description && (
        <Markdown paragraphClassName="mt8 p-p--small" openLinksInNewTab>
          {description}
        </Markdown>
      )}
      {children && <div className="mt8">{children}</div>}
    </div>
  </div>
);

export default InfoBox;
