import classnames from 'classnames';
import ErrorView from 'components/error';
import LoadingSpinner from 'components/loadingSpinner';
import StepBar from 'components/stepBar';
import { ViewProps } from 'features/claimsProcess/claimTypes';
import {
  useClaimSteps,
  useCurrentClaimStep,
  useLoadPolicyDetail as _useLoadPolicyDetail,
} from 'features/claimsProcess/hooks';
import { additionalClaimInfoForInsuranceType } from 'features/claimsProcess/utils';
import { ClaimType, ClaimTypes, mapClaimTypes } from 'models/claims';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useParams as _useParams } from 'react-router';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import styles from './style.module.scss';

const ClaimTypeView = ({
  storeClaimType,
  initialValue,
  useParams = _useParams,
  useLoadPolicyDetail = _useLoadPolicyDetail,
}: ViewProps & {
  useParams?: typeof _useParams;
  useLoadPolicyDetail?: typeof _useLoadPolicyDetail;
}) => {
  const { policyId } = useParams<{ policyId: string }>();
  const steps = useClaimSteps();
  const currentStep = useCurrentClaimStep();
  const [claimTypeInput, setClaimTypeInput] = useState<ClaimType | undefined>(
    initialValue
  );
  const { loading, error, policy } = useLoadPolicyDetail(policyId);

  if (loading === true || steps.length === 0) {
    return <LoadingSpinner />;
  }

  if (error !== undefined) {
    return <ErrorView error={error} />;
  }

  if (policy === undefined) {
    throw new Error(`Can’t fetch policy detail for policy id ${policyId}`);
  }

  const claimAdditionalInfo = additionalClaimInfoForInsuranceType(policy.type);
  const currentIndex = (currentStep && steps.indexOf(currentStep) + 1) ?? 1;

  return (
    <div className="p-body">
      <form
        className={`wmx8 w100 ${styles.container}`}
        onSubmit={(e) => {
          e.preventDefault();
          if (!claimTypeInput) {
            throw new Error('Claim type info is required to make a claim');
          }
          storeClaimType(claimTypeInput);
        }}
      >
        <div className="mt40">
          <StepBar currentStep={currentIndex} steps={steps.length} />
        </div>
        <div className={`mt32 ${styles.header}`}>
          <h4 className="p-h4 tc-purple-500">Step 1</h4>
          <div className="p-h2">Select a claim type</div>
          <div className="p-p mt16">
            Please select the type of claim that you wish to submit.
          </div>
        </div>
        <div className={`mt8 ${styles['pill-container']}`}>
          {Object.entries(mapClaimTypes).map(([value, label]) => (
            <button
              key={value}
              className={classnames(`mt16 ta-center p-p ${styles.pill}`, {
                [styles['selected-pill']]: value === claimTypeInput?.claimType,
              })}
              onClick={(e) => {
                e.preventDefault();
                if (value === claimTypeInput?.claimType) {
                  setClaimTypeInput(undefined);
                  return;
                }
                setClaimTypeInput({
                  ...claimTypeInput,
                  claimType: value as ClaimTypes,
                });
              }}
            >
              {label}
            </button>
          ))}
        </div>
        <AnimateHeight
          duration={300}
          height={claimTypeInput?.claimType === 'OTHER' ? 'auto' : 0}
        >
          {claimTypeInput?.claimType === 'OTHER' && (
            <>
              <div className="p-h4 mt24">
                Please describe your type of claim
              </div>
              <input
                className="p-input mt8 wmx5"
                placeholder="Type of claim"
                onChange={(e) =>
                  setClaimTypeInput({
                    ...claimTypeInput,
                    additionalInfo: e.target.value,
                  })
                }
              />
            </>
          )}
        </AnimateHeight>
        {claimAdditionalInfo && (
          <AnimateHeight
            duration={300}
            height={claimTypeInput?.claimType ? 'auto' : 0}
          >
            <div className={`mt24 wmx6 ${styles['type-info-container']}`}>
              <div className="p-h4">
                {claimTypeInput?.claimType &&
                  mapClaimTypes[claimTypeInput?.claimType]}
              </div>
              <div className="mt8 p-p">
                Here is some useful information about using your insurance for
                this type of claim:
              </div>
              <div className={`mt16 ${styles['type-info-details']}`}>
                <div className="p-p">Average processing time</div>
                <div className="p-h4">
                  {claimAdditionalInfo.processingTime} days
                </div>
              </div>
              <div className={styles['type-info-details']}>
                <div className="p-p">Deductible</div>
                <div className="p-h4">
                  {englishFormattedEuroCurrency(claimAdditionalInfo.deductible)}
                </div>
              </div>
            </div>
          </AnimateHeight>
        )}
        <button
          className={`p-btn p-btn--primary mt24 p-p wmn3 ${styles.button}`}
          disabled={!claimTypeInput?.claimType}
        >
          Continue
        </button>
      </form>
    </div>
  );
};

export default ClaimTypeView;
