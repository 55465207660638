import { language, Locale, TFunction } from '@getpopsure/i18n-react';
import { Insurer } from '@getpopsure/public-health-insurance-pricing-engine';
import { getTrackingObject } from '@getpopsure/tracker';
import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';

import {
  Plan,
  QuestionnaireType,
  QuoteOption,
  SubmittableDentalQuestionnaire,
  ToothReplacementCoverage,
  ZSubmittableDentalQuestionnaire,
  ZSubmittableDentalQuote,
} from './models';

const submitDentalQuoteInformation = (
  answers: SubmittableDentalQuestionnaire,
  questionnaireType: QuestionnaireType
) => {
  const source = getTrackingObject();
  return endpoint.network.post<{
    id: string;
    answers: SubmittableDentalQuestionnaire;
  }>('/questionnaires/', {
    answers,
    questionnaireType,
    metadata: source,
  });
};

export const createQuote = (
  dateOfBirth: string,
  publicInsurer: Insurer,
  plan?: Plan,
  toothReplacementCoverage?: ToothReplacementCoverage
) => {
  return endpoint.network.post<QuoteOption[]>('/signups/dental/quotes', {
    dateOfBirth,
    publicInsurer,
    plan,
    toothReplacementCoverage,
  });
};

export type DentalSubmissionDispatch = ThunkDispatch<
  AppState,
  unknown,
  RequestAction | GenericQuestionnaireAction<'dentalV2'>
>;

export type CreateQuoteReturnType = {
  status: ActionResponse;
};

export const submitDentalPostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'dentalV2'> | RequestAction
  > =>
  async (dispatch: DentalSubmissionDispatch, getState: () => AppState) => {
    dispatch(setRequestInProcess(true, 'SUBMIT_QUESTIONNAIRE'));

    try {
      const questionnaire = getGenericQuestionnaire(getState()).dentalV2 || {};

      const validationResult =
        ZSubmittableDentalQuestionnaire.safeParse(questionnaire);

      if (!validationResult.success) {
        throw new Error(
          `[Dental - Submission] Questionnaire answer validation errors:${validationResult.error.toString()}`
        );
      }
      const validatedAnswers = validationResult.data;

      const {
        email,
        name: { firstName, lastName },
        dateOfBirth,
      } = validatedAnswers.personalInfo;
      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            dateOfBirth,
            email,
          })
        );
      }

      const {
        data: { id: questionnaireId },
      } = await submitDentalQuoteInformation(
        validatedAnswers,
        'DENTAL_POST_QUOTE'
      );

      const validationQuoteResult = ZSubmittableDentalQuote.safeParse({
        ...questionnaire,
        plan: questionnaire.quote?.plan ?? 'BASIC',
        toothReplacementCoverage: questionnaire.quote?.toothReplacementCoverage,
        publicInsurer: questionnaire.publicInsurer?.value,
      });

      if (!validationQuoteResult.success) {
        throw new Error(
          `[Dental - Submission] Quote answer validation errors:${validationQuoteResult.error.toString()}`
        );
      }
      const validatedQuoteAnswers = validationQuoteResult.data;

      const { data: quotes } = await createQuote(
        validatedQuoteAnswers.dateOfBirth,
        validatedQuoteAnswers.publicInsurer,
        validatedQuoteAnswers.plan,
        validatedQuoteAnswers.toothReplacementCoverage
      );

      const [quote] = quotes;

      dispatch(
        storeGenericQuestionnaireAnswer('dentalV2', {
          quoteId: quote.id,
          questionnaireId,
          quote: {
            ...quote,
            plan: quote.planId,
          },
        })
      );

      const currentLanguage = language() as Locale;

      await generateCheckoutDocuments(
        questionnaireId,
        quote.id,
        'DENTAL_V2',
        t,
        currentLanguage
      );

      dispatch(setRequestInProcess(false, 'SUBMIT_QUESTIONNAIRE'));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'SUBMIT_QUESTIONNAIRE')
      );
      Sentry.captureException(
        `[Dental Review] Failed to submit with following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };
