import Session from '@getpopsure/session';
import * as Sentry from '@sentry/react';

const isDevEnv = process.env.NODE_ENV === 'development';
const apiEndpoint = process.env.REACT_APP_ENDPOINT;
const baseURL =
  isDevEnv || !apiEndpoint ? window.location.toString() : apiEndpoint;

function initializeSession() {
  Session.setBaseUrl(baseURL);
  Session.fetchCsrfToken().catch((error) =>
    Sentry.captureException(error, {
      level: 'fatal',
      tags: {
        feature: 'session',
        description:
          'There was an error during the initial setup of the session library.',
      },
    })
  );
}

export default initializeSession;
