import classNames from 'classnames';
import FAQView, { FAQInfo } from 'components/faq';
import Markdown from 'components/markdown';

import styles from './FAQ.module.scss';

export type FAQProps = {
  data: FAQInfo[];
  title?: string;
  footer?: string;
};

export const FAQ = ({ title, data, footer }: FAQProps) => {
  return (
    <div className="p-container mt80 pb80">
      <section
        className={classNames('d-flex fd-column', styles.titleContainer)}
      >
        <FAQView title={title} data={data} />
        {footer && (
          <Markdown className="w100 mt32 tc-grey-600">{footer}</Markdown>
        )}
      </section>
    </div>
  );
};
