import { signOut as onSignOut } from 'actions/session';
import { fetchAccountInfo, updateUserLanguage } from 'actions/user';
import ErrorView from 'components/error';
import LoadingSpinner from 'components/loadingSpinner';
import { RequestAction } from 'constants/actions';
import { useGetPolicies } from 'hooks/useGetPolicies';
import { useRegion } from 'hooks/useRegion';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { getFeatureByRegion } from 'routeLocales';
import { getEmail, getUserId } from 'selectors/user';
import { Locale } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';

import { AccountView } from './Account.view';

export const Account = () => {
  const { region } = useRegion();
  const dispatch =
    useDispatch<
      ThunkDispatch<AppState, Record<string, unknown>, RequestAction>
    >();

  const updateLanguage = (locale: Locale, successCb?: () => void) =>
    dispatch(updateUserLanguage(locale, successCb));

  useEffect(() => {
    dispatch(fetchAccountInfo());
  }, [dispatch]);

  const userId = useSelector(getUserId);
  const email = useSelector(getEmail);
  const { policies, isLoading: loading, error } = useGetPolicies();
  const hasAccessToFeature = getFeatureByRegion(region);

  if (error) {
    return <ErrorView error={error.message} />;
  }

  if (loading && !(userId && email)) {
    return <LoadingSpinner />;
  }

  return (
    <AccountView
      onSignOut={() => dispatch(onSignOut())}
      updateUserLanguage={updateLanguage}
      showLanguageSwitcher={
        hasAccessToFeature('languageSwitcher') && !isMobileApp
      }
      policies={policies}
      userId={userId}
    />
  );
};
