import { TFunction } from '@getpopsure/i18n-react';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import { IntercomLoaderWrapper } from 'features/intercomLauncher';

import { BillingList } from './components/BillingList';
import { DisputeInvoiceList } from './components/DisputeInvoiceList';
import { TransactionDetailsModal } from './components/TransactionDetailsModal';
import { Billing, DisputeInvoice } from './models';
import styles from './styles.module.scss';

interface Props {
  t: TFunction;
  billings: Billing[];
  disputeInvoices: DisputeInvoice[];
  hasMorePages: boolean;
  fetchAfter: string | undefined;
  setSelectedBillingId: (id: string) => void;
  selectedBillingInfo?: Billing;
}

export const BillingHistoryView = ({
  t,
  billings,
  disputeInvoices,
  hasMorePages,
  fetchAfter,
  setSelectedBillingId,
  selectedBillingInfo,
}: Props) => (
  <>
    {selectedBillingInfo && (
      <TransactionDetailsModal
        t={t}
        billingInfo={selectedBillingInfo}
        onClose={() => setSelectedBillingId('')}
      />
    )}
    <div className="mb80">
      <div className="p-body">
        <NavButton
          title={t(
            'account.billingHistory.backButton.title',
            'Billing and payment'
          )}
          path={routes.account.billingAndPayment.path}
        />

        <h1 className="p-h1">
          {t('account.billinghistory.title', 'Billing history')}
        </h1>

        <DisputeInvoiceList
          className={styles.billingList}
          disputeInvoices={disputeInvoices}
        />

        <BillingList
          className={styles.billingList}
          billings={billings}
          hasMorePages={hasMorePages}
          fetchAfter={fetchAfter}
          setSelectedBillingId={setSelectedBillingId}
        />
        <div className="p-p tc-grey-700 fw-bold mt48">
          {t('account.billinghistory.footer.title', 'Understanding your bill')}
        </div>
        <p className="p-p tc-grey-600 mt8">
          {t(
            'account.billinghistory.footer.description',
            'You are billed on the 1st day of every month. If you signed up for a new policy, you will see a partial payment for the period between the start date of the insurance and the last day of the calendar month.'
          )}
        </p>
        <div className="d-flex ai-center mt24">
          <div className="p-p tc-grey-600">
            {t(
              'account.billinghistory.footer.morequestion.question',
              'Have more questions?'
            )}
          </div>
          &nbsp;
          <IntercomLoaderWrapper
            render={({ handleClick }) => (
              <button
                className="p-a fw-bold ds-interactive-component"
                onClick={handleClick}
                type="button"
              >
                {t('account.billinghistory.footer.talkToUs.link', 'Talk to us')}
              </button>
            )}
          />
          .
        </div>
      </div>
    </div>
  </>
);
