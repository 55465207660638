import { PartialQuestionnaire } from 'models/questionnaireFramework';

import { ProcessingAnswers, ProcessingGroup } from './processing';

export const dentalCleaningMapping = {
  ONCE_PER_YEAR: 'Once per year',
  TWICE_PER_YEAR: 'Twice per year',
  THREE_TIMES_OR_MORE_PER_YEAR: 'Three times or more per year',
};

export type IDentalCleaning = keyof typeof dentalCleaningMapping;

interface Personal {
  dateOfBirth: string;
  isCurrentlyCoveredWithGermanPublicHealthProvider: boolean;
  dentalCleaning: IDentalCleaning;
}

export type QuestionnaireAnswers = Personal & ProcessingAnswers;

export type GroupId = 'personalInfo' | ProcessingGroup;

export type DentalQuestionnaire = PartialQuestionnaire<
  QuestionnaireAnswers,
  GroupId
>;
