import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { Card } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import styles from './NoticeTable.module.scss';

type Props<Questionnaire extends QuestionnaireQuestions> = {
  rows: [string, string][];
  description: string;
} & QuestionnaireFormProps<Questionnaire, boolean>;

export const NoticeTable = <Questionnaire extends QuestionnaireQuestions>({
  rows,
  description,
  onSubmitValue,
  setValidForSubmission,
}: Props<Questionnaire>) => {
  const [checked, setChecked] = useState(false);
  const handleSubmit = () => {
    onSubmitValue(true);
  };

  useEffect(() => {
    setValidForSubmission(checked);
  }, [checked, setValidForSubmission]);

  return (
    <Header onSubmit={handleSubmit}>
      <div className="p-body">
        <Card title="" classNames={{ wrapper: 'wmx6', children: 'wmx5' }}>
          {rows.map(([left, right], idx) => {
            return (
              <div
                className={classNames('d-flex fd-row pt16 pb16', styles.row)}
                key={`table-entry-${left}-${right}`}
              >
                <div className={classNames('w50', { 'fw-bold': idx === 0 })}>
                  {left}
                </div>
                <div className={classNames('w50', { 'fw-bold': idx === 0 })}>
                  {right}
                </div>
              </div>
            );
          })}
        </Card>
      </div>

      <input
        className="p-checkbox"
        name="check"
        type="checkbox"
        id="noticeCheck"
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
      />
      <label
        htmlFor="noticeCheck"
        className={classNames('p-label p-p mt16', styles.checkboxContent)}
        data-cy="check-notice-table"
      >
        {description}
      </label>
    </Header>
  );
};
