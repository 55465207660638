import { tariffNameMapping } from '@getpopsure/private-health-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import {
  ActivityIcon,
  DentalToothTeethIcon,
  DependentsFamilyIcon,
  EyeVisionIcon,
  GlobeNetworkEarthIcon,
  HeartIcon,
  HospitalBuildingIcon,
  TableHeader,
  TableRowHeader,
} from '@popsure/dirty-swan';
import {
  deductibleInfoMapping,
  ModalWithTracking,
  TableData,
  Tariff,
  TariffsWithPublic,
} from 'features/privateHealthPreSignup/models';

import {
  renderBoolean,
  renderContribution,
  renderSickPayCoverage,
  renderStringOrBoolean,
  renderStringOrBooleanNoProgress,
  renderTableCTA,
  renderValueWithDescription,
  withInfoModal,
} from './tableRenderers';

export const getShortTermTableHeaders = ({
  setModal,
  handleOpenContributionModal,
  showPrice,
  selectedPlan,
  setSelectedPlan,
}: {
  setModal: (modal: ModalWithTracking) => void;
  handleOpenContributionModal: (tariff: TariffsWithPublic) => void;
  showPrice: boolean;
  selectedPlan?: Tariff;
  setSelectedPlan: (tariff: Tariff) => void;
}): Array<TableHeader<TableData>> => [
  {
    id: 1,
    label: '',
    default: true,
    cells: [
      {
        key: 'name',
        label: 'Choose a plan',
        render: renderTableCTA({ setSelectedPlan, selectedPlan, showPrice }),
      },
      {
        key: 'contribution',
        label: 'Your contribution',
        render: renderContribution(handleOpenContributionModal),
      },
      {
        key: 'dependents',
        label: 'Dependents coverage',
        render: withInfoModal(
          renderStringOrBoolean,
          'dependentsInfo',
          setModal,
          'Dependents coverage'
        ),
      },
      {
        key: 'deductible',
        label: 'Deductible (excess)',
        render: withInfoModal(
          renderStringOrBoolean,
          'deductibleInfo',
          setModal,
          'What is a deductible?'
        ),
      },
    ],
  },
  {
    id: 2,
    label: (
      <p className="d-flex ai-center p-h4 c-gap8">
        <HeartIcon size={20} noMargin /> General
      </p>
    ),
    cells: [
      {
        key: 'generalDoctor',
        label: (
          <TableRowHeader
            label="General doctors' visits"
            onClickInfo={() =>
              setModal({
                fieldKey: 'generalDoctor',
                tariff: 'header',
                title: "General doctors' visits",
                children:
                  'E.g. General practitioner, eye doctor,  gynecologist, pediatrician, emergency doctor',
              })
            }
          />
        ),
        render: renderStringOrBoolean,
      },
      {
        key: 'specialist',
        label: (
          <TableRowHeader
            label="Specialists"
            onClickInfo={() =>
              setModal({
                fieldKey: 'specialist',
                tariff: 'header',
                title: 'Specialists',
                children:
                  'E.g. dermatologist, orthopedic doctor, ear-nose-throat doctor',
              })
            }
          />
        ),
        render: renderStringOrBoolean,
      },
      {
        key: 'medication',
        label: <TableRowHeader label="Medication" />,
        render: withInfoModal(
          renderStringOrBoolean,
          'medicationInfo',
          setModal,
          'Medication'
        ),
      },
      {
        key: 'vaccination',
        label: <TableRowHeader label="Vaccinations" />,
        render: withInfoModal(
          renderStringOrBoolean,
          'vaccinationInfo',
          setModal,
          'Vaccinations'
        ),
      },
      {
        key: 'transports',
        label: (
          <TableRowHeader
            label="Transportation"
            onClickInfo={() =>
              setModal({
                fieldKey: 'transports',
                tariff: 'header',
                title: 'Transportation',
                children:
                  'Covered in the event of an emergency: ambulance, taxi, or Uber to and from the closest suitable hospital or clinic.',
              })
            }
          />
        ),
        render: withInfoModal(
          renderStringOrBoolean,
          'transportsInfo',
          setModal,
          'Transports'
        ),
      },
      {
        key: 'sickPayCoverage',
        label: (
          <TableRowHeader
            label="Income protection"
            onClickInfo={() =>
              setModal({
                fieldKey: 'sickPayCoverage',
                tariff: 'header',
                title: 'Income protection',
                children:
                  "The income protection kicks in if you're unable to work due to a sickness that lasts longer than 42 days. It is added by default on public insurance if you’re employed, and is especially important for freelancers since it can cover the cost of living if you get sick. You can cover up to 100% of your net income.\n\n The payout amount can be adjusted when signing up.",
              })
            }
          />
        ),
        render: renderSickPayCoverage,
      },
      {
        key: 'digitalHealthApps',
        label: (
          <TableRowHeader
            label="Digital health apps"
            subtitle="E.g. Headspace, Apple fitness"
          />
        ),
        render: withInfoModal(
          renderStringOrBoolean,
          'digitalHealthAppsInfo',
          setModal,
          'Digital health apps'
        ),
      },
      {
        key: 'nonMedicalPrescriptions',
        label: (
          <TableRowHeader
            label="Non-medical prescriptions"
            subtitle="E.g. contraceptives, supplements"
          />
        ),
        render: renderStringOrBoolean,
      },
      {
        key: 'purelyCosmeticProcedures',
        label: (
          <TableRowHeader
            label="Purely cosmetic procedures"
            subtitle="E.g. tattoo removal, botox, liposuction, facelift"
          />
        ),
        render: renderStringOrBoolean,
      },
    ],
  },
  {
    id: 3,
    label: (
      <p className="d-flex ai-center p-h4 c-gap8">
        <DentalToothTeethIcon size={20} noMargin /> Dental
      </p>
    ),
    cells: [
      {
        key: 'annualCoverage',
        label: (
          <TableRowHeader
            label="Annual coverage limit"
            subtitle="For all dental treatments"
          />
        ),
        render: withInfoModal(
          renderStringOrBoolean,
          'annualCoverageInfo',
          setModal,
          'Annual coverage limit'
        ),
      },
      {
        key: 'dentalCheckups',
        label: <TableRowHeader label="Dental checkups" />,
        render: renderStringOrBoolean,
      },
      {
        key: 'dentalCleanings',
        label: (
          <TableRowHeader label="Dental cleanings" subtitle="Prophylaxis" />
        ),
        render: renderValueWithDescription,
      },
      {
        key: 'dentist',
        label: (
          <TableRowHeader label="Dental procedures" subtitle="E.g. fillings" />
        ),
        render: renderStringOrBoolean,
      },
      {
        key: 'inlays',
        label: <TableRowHeader label="Inlays" />,
        render: withInfoModal(
          renderStringOrBoolean,
          'inlaysInfo',
          setModal,
          'Inlays'
        ),
      },
      {
        key: 'dentures',
        label: (
          <TableRowHeader
            label="Tooth replacement"
            subtitle="false teeth & night guards"
            onClickInfo={() =>
              setModal({
                fieldKey: 'dentures',
                title: 'Tooth replacement',
                tariff: 'header',
                children:
                  'What counts as dentures:\n\n• dental protheses\n\n• crowns, veneers\n\n• bridges\n\n• implants and the necessary preparatory surgical measures to build up the jaw bone\n\n• Night guards and splints (e.g. grinding and snoring splints)\n\n• Functional analysis and functional therapy measures in connection with dental prostheses, dentures and splint therapy\n\n• the repair of dentures',
              })
            }
          />
        ),
        render: withInfoModal(
          renderStringOrBoolean,
          'denturesInfo',
          setModal,
          'Dentures'
        ),
      },
      {
        key: 'orthodonticsChildren',
        label: <TableRowHeader label="Orthodontics for children" />,
        render: withInfoModal(
          renderStringOrBoolean,
          'orthodonticsChildrenInfo',
          setModal,
          'Orthodontics for children'
        ),
      },
    ],
  },
  {
    id: 4,
    label: (
      <p className="d-flex ai-center p-h4 c-gap8">
        <ActivityIcon size={20} noMargin /> Treatment and therapies
      </p>
    ),
    cells: [
      {
        key: 'preventiveCare',
        label: <TableRowHeader label="Preventive care treatments" />,
        render: withInfoModal(
          renderStringOrBoolean,
          'preventiveCareInfo',
          setModal,
          'Preventive care treatments'
        ),
      },
      {
        key: 'therapeutic',
        label: (
          <TableRowHeader
            label="Therapeutic measures"
            subtitle="E.g. physiotherapy or speech therapy"
          />
        ),
        render: withInfoModal(
          renderStringOrBooleanNoProgress,
          'therapeuticInfo',
          setModal,
          'Therapeutic measures'
        ),
      },
      {
        key: 'mentalHealth',
        label: <TableRowHeader label="Mental health therapy" />,
        render: withInfoModal(
          renderStringOrBoolean,
          'mentalHealthInfo',
          setModal,
          'Mental health therapy'
        ),
      },
      {
        key: 'radioTherapy',
        label: <TableRowHeader label="Radiation therapy" />,
        render: renderStringOrBoolean,
      },
      {
        key: 'natural',
        label: (
          <TableRowHeader
            label="Natural health treatments"
            subtitle="E.g. acupuncture, osteopathy, cupping"
          />
        ),
        render: withInfoModal(
          renderStringOrBoolean,
          'naturalInfo',
          setModal,
          'Natural health treatments'
        ),
      },
      {
        key: 'medicalAids',
        label: <TableRowHeader label="Medical aids" />,
        render: withInfoModal(
          renderStringOrBoolean,
          'medicalAidsInfo',
          setModal,
          'Medical aids'
        ),
      },
    ],
  },
  {
    id: 5,
    label: (
      <p className="d-flex ai-center p-h4 c-gap8">
        <EyeVisionIcon size={20} noMargin /> Vision
      </p>
    ),
    cells: [
      {
        key: 'visionAids',
        label: (
          <TableRowHeader
            label="Vision aids"
            subtitle="E.g. glasses or contact lenses"
          />
        ),
        render: withInfoModal(
          renderStringOrBoolean,
          'visionAidsInfo',
          setModal,
          'Vision aids'
        ),
      },
      {
        key: 'refractiveSurgery',
        label: (
          <TableRowHeader
            label="Refractive eye surgery"
            subtitle="E.g. LASIK"
          />
        ),
        render: withInfoModal(
          renderStringOrBoolean,
          'refractiveSurgeryInfo',
          setModal,
          'Refractive eye surgery'
        ),
      },
    ],
  },
  {
    id: 6,
    label: (
      <p className="d-flex ai-center p-h4 c-gap8">
        <DependentsFamilyIcon size={20} noMargin /> Pregnancy and childbirth
      </p>
    ),
    cells: [
      {
        key: 'pregnancy',
        label: <TableRowHeader label="Pregnancy coverage" />,
        render: renderStringOrBoolean,
      },
      {
        key: 'fertilityTreatment',
        label: <TableRowHeader label="Fertility treatment" />,
        render: withInfoModal(
          renderStringOrBoolean,
          'fertilityTreatmentInfo',
          setModal,
          'Fertility treatment'
        ),
      },
      {
        key: 'midwife',
        label: (
          <TableRowHeader
            label="Midwife support"
            subtitle="Before and after giving birth"
          />
        ),
        render: withInfoModal(
          renderStringOrBoolean,
          'midwifeInfo',
          setModal,
          'Midwife support'
        ),
      },
      {
        key: 'nonMedicalPregnancyServices',
        label: (
          <TableRowHeader
            label="Non-medical pregnancy services"
            subtitle="E.g. pre-natal yoga, doula"
          />
        ),
        render: withInfoModal(
          renderStringOrBoolean,
          'nonMedicalPregnancyServicesInfo',
          setModal,
          'Non-medical pregnancy services'
        ),
      },
    ],
  },
  {
    id: 7,
    label: (
      <p className="d-flex ai-center p-h4 c-gap8">
        <HospitalBuildingIcon size={20} noMargin /> Hospital
      </p>
    ),
    cells: [
      {
        key: 'boardAndRoom',
        label: <TableRowHeader label="Board and room" />,
        render: renderStringOrBoolean,
      },
      {
        key: 'privateHospitals',
        label: <TableRowHeader label="Private hospitals" />,
        render: withInfoModal(
          renderStringOrBoolean,
          'privateHospitalsInfo',
          setModal,
          'Private hospitals'
        ),
      },
      {
        key: 'headPhysician',
        label: <TableRowHeader label="Treatment by a head physician" />,
        render: withInfoModal(
          renderStringOrBoolean,
          'headPhysicianInfo',
          setModal,
          'Treatment by a head physician'
        ),
      },
    ],
  },
  {
    id: 8,
    label: (
      <p className="d-flex ai-center p-h4 c-gap8">
        <GlobeNetworkEarthIcon size={20} noMargin /> Outside of Germany
      </p>
    ),
    cells: [
      {
        key: 'worldwideCover',
        label: (
          <TableRowHeader
            label="Worldwide cover"
            subtitle="outside of Europe up to 1 month"
          />
        ),
        render: withInfoModal(
          renderStringOrBoolean,
          'worldwideCoverInfo',
          setModal,
          'Worldwide cover'
        ),
      },
      {
        key: 'euWideCover',
        label: (
          <TableRowHeader label="EU-wide cover" subtitle="Including EEA" />
        ),
        render: withInfoModal(
          renderStringOrBoolean,
          'euWideCoverInfo',
          setModal,
          'EU-wide cover'
        ),
      },
      {
        key: 'repatriation',
        label: (
          <TableRowHeader
            label="Repatriation costs"
            subtitle="Up to 8 weeks outside Germany"
          />
        ),
        render: renderBoolean,
      },
    ],
  },
];

export const getShortTermTableData = ({
  standardPrice,
  premiumPrice,
  publicHealthPriceString,
}: {
  standardPrice: number;
  premiumPrice: number;
  publicHealthPriceString?: string;
}): Array<TableData> => [
  {
    id: 0,
    tariff: 'HiMedical',
    tariffKey: 'HiMedical',
    name: tariffNameMapping.HiMedical,
    contribution: standardPrice,
    dependents: 'Extra cost',
    dependentsInfo: `The cost to add a non-working partner or child to your private health insurance depends on their age, health status and coverage.\n\n For each child the additional cost can range from approximately ≈ ${englishFormattedEuroCurrency(
      90,
      0
    )} - ${englishFormattedEuroCurrency(
      220,
      true
    )}/month and from ≈ ${englishFormattedEuroCurrency(
      180,
      true
    )} - ${englishFormattedEuroCurrency(
      700,
      true
    )}/month for adults.\n\n As an employee your employer will contribute up to 50% towards the cost of private health insurance for non-working dependents (spouse, legal partner or children).`,
    deductible: `10%, max ${englishFormattedEuroCurrency(500, true)}`,
    deductibleInfo: deductibleInfoMapping.HiMedical,

    generalDoctor: '100%',
    specialist: '100%',
    medication: '100%',
    medicationInfo:
      'Medicines not included:\n\n• contraceptives,\n\n• nutraceutical products for the elderly,\n\n• nutriments and dietary supplements except for the aforementioned nutrients with medicinal properties,\n\n• dietetic products,\n\n• hormone preparations for anti-aging measures,\n\n• hair restorers,\n\n• potency-enhancing preparations,\n\n• cosmetics,\n\n• disinfectants,\n\n• bath additives and\n\n• comparable “lifestyle products”.',
    vaccination: '100%',
    vaccinationInfo:
      'Vaccinations [recommended by Robert-Koch-Institute](https://www.rki.de/DE/Content/Kommissionen/STIKO/Empfehlungen/Aktuelles/Impfkalender.pdf) (not including vaccinations that are work or travel-related). The Covid-19 vaccine is covered.',
    transports: '100%',
    transportsInfo:
      'Emergency transportation to a hospital. Non-hospital transportation is only covered for dialysis or chemotherapy in the event of an emergency.',
    sickPayCoverage: 'Up to 100% of net income',
    digitalHealthApps: `${englishFormattedEuroCurrency(60, true)} per year`,
    nonMedicalPrescriptions: false,
    purelyCosmeticProcedures: false,

    annualCoverage: `${englishFormattedEuroCurrency(500, true)}`,
    dentalCheckups: '100%',
    dentalCleanings: {
      value: false,
    },
    dentist: '100%',
    inlays: '100%',
    dentures: '100%',
    orthodonticsChildren: '100%',
    orthodonticsChildrenInfo:
      '100% covered (up to the annual limit) if the treatment is started before the age of 18.',
    visionAids: false,
    refractiveSurgery: false,
    preventiveCare: 'Only for children',
    preventiveCareInfo: 'Only for children up to the age of 18.',
    therapeutic: `Up to ${englishFormattedEuroCurrency(750, true)}`,
    therapeuticInfo: `Up to ${englishFormattedEuroCurrency(
      750,
      true
    )} per year. Higher limits apply for accidents or serious illnesses.`,
    mentalHealth: false,
    radioTherapy: '100%',
    natural: false,
    medicalAids: '75%-100%',
    medicalAidsInfo: `Aids are technical means and body replacements that compensate for or alleviate disabilities, the consequences of illness or accidents (e.g. bandages, insoles, prostheses).\n\n Dialysis machines are also reimbursed.\n\nExpenses above ${englishFormattedEuroCurrency(
      350,
      0
    )} must be pre-approved (otherwise, we cover up to 75%).\n\n \n\n**100% coverage for medical aids:**\n\n• which directly alleviate or compensate for disabilities or the consequences of illnesses or accidents (e.g. invalid carriages, prostheses),\n\n• if the insured person needs them for therapeutic and diagnostic purposes (e.g. blood pressure monitors), or\n\n• in order to stay alive (life-saving medical aids such as breathing aids).\n\n• provision of instructions on how to use the medical aids and for their maintenance and repair.\n\n \n\n**Limits apply to the following:**\n\n• ${englishFormattedEuroCurrency(
      250,
      0
    )} per calendar year for each insured person for orthopaedic shoes or the orthopaedic adaptation of shoes or insoles.\n\n• The insured person can receive up to a total of ${englishFormattedEuroCurrency(
      1500,
      0
    )} for a hearing aid for each ear throughout the period when the insurance is in force. If the hearing loss can only be corrected through the use of hearing implants, we will reimburse an overall amount of up to € 4,000 per ear for this care.\n\n \n\n**The following are not covered:**\n\n• repair of orthopedic footwear.\n\n• medical aids for which compulsory care insurance has to provide reimbursement,\n\n• medical aids which are part of fitness/wellness and/or recreational facilities,\n\n• everyday personal effects and hygiene products (e.g. medical thermometers, anti-allergy bedding).`,

    pregnancy: '100%',
    fertilityTreatment: false,
    midwife: '100%',
    nonMedicalPregnancyServices: false,

    boardAndRoom: 'Shared room',
    privateHospitals: true,
    privateHospitalsInfo:
      'Up to 100% of the cost billed by a public hospital in Germany. No limit for emergency treatments.',
    headPhysician: false,
    euWideCover: true,
    euWideCoverInfo:
      'Up to what is covered in Germany, more than this with pre-approval',
    repatriation: true,
    worldwideCover: true,
    worldwideCoverInfo:
      'Up to what is covered in Germany, more with pre-approval.\n\nUp to 12 weeks in any country where you have citizenship.\n\nUp to 4 weeks in every other country.',
  },
  {
    id: 1,
    tariff: 'HiMedicalPlus',
    tariffKey: 'HiMedicalPlus',
    name: tariffNameMapping.HiMedicalPlus,
    contribution: premiumPrice,
    dependents: 'Extra cost',
    dependentsInfo: `The cost to add a non-working partner or child to your private health insurance depends on their age, health status and coverage.\n\n For each child the additional cost can range from approximately ≈ ${englishFormattedEuroCurrency(
      90,
      0
    )} - ${englishFormattedEuroCurrency(
      220,
      true
    )}/month and from ≈ ${englishFormattedEuroCurrency(
      180,
      true
    )} - ${englishFormattedEuroCurrency(
      700,
      true
    )}/month for adults.\n\n As an employee your employer will contribute up to 50% towards the cost of private health insurance for non-working dependents (spouse, legal partner or children).`,
    deductible: `10%, max ${englishFormattedEuroCurrency(500, true)}`,
    deductibleInfo: deductibleInfoMapping.HiMedicalPlus,

    generalDoctor: '100%',
    specialist: '100%',
    medication: '100%',
    medicationInfo:
      'Medicines not included:\n\n• contraceptives,\n\n• nutraceutical products for the elderly,\n\n• nutriments and dietary supplements except for the aforementioned nutrients with medicinal properties,\n\n• dietetic products,\n\n• hormone preparations for anti-aging measures,\n\n• hair restorers,\n\n• potency-enhancing preparations,\n\n• cosmetics,\n\n• disinfectants,\n\n• bath additives and\n\n• comparable “lifestyle products”.',
    vaccination: '100%',
    vaccinationInfo:
      'Vaccinations [recommended by Robert-Koch-Institute](https://www.rki.de/DE/Content/Kommissionen/STIKO/Empfehlungen/Aktuelles/Impfkalender.pdf) (not including vaccinations that are work or travel-related). The Covid-19 vaccine is covered.',
    transports: '100%',
    transportsInfo:
      'Emergency transportation to a hospital. Non-hospital transportation is only covered for dialysis or chemotherapy in the event of an emergency.',
    sickPayCoverage: 'Up to 100% of net income',
    digitalHealthApps: `${englishFormattedEuroCurrency(120, true)} per year`,
    nonMedicalPrescriptions: false,
    purelyCosmeticProcedures: false,

    annualCoverage: englishFormattedEuroCurrency(1500, true),
    dentalCheckups: '100%',
    dentalCleanings: {
      value: true,
    },
    dentist: '100%',
    inlays: '100%',
    dentures: '100%',
    orthodonticsChildren: '100%',
    orthodonticsChildrenInfo:
      '100% covered (up to the annual limit) if the treatment is started before the age of 18.',
    visionAids: `${englishFormattedEuroCurrency(250, true)} every 2 years`,
    visionAidsInfo:
      'You can claim every two years or if your eyesight worsens by a power of 0.5 within a year',
    refractiveSurgery: `${englishFormattedEuroCurrency(750, true)} per eye`,
    refractiveSurgeryInfo: `${englishFormattedEuroCurrency(
      750,
      true
    )} per eye after 12 months of coverage, once in the lifetime of the policy.`,
    preventiveCare: '100%',
    preventiveCareInfo:
      'Identical coverage to public health insurance, excluding any age limits that public insurance has.',
    therapeutic: `Up to ${englishFormattedEuroCurrency(1500, true)}`,
    therapeuticInfo: `Up to ${englishFormattedEuroCurrency(
      1500,
      true
    )} per year. Higher limits apply for accidents or serious illnesses.`,
    mentalHealth: '100%',
    mentalHealthInfo: 'Up to 25 sessions per year.',
    radioTherapy: '100%',
    natural: '100%',
    naturalInfo: `100% covered when performed by a **licensed doctor**. Up to ${englishFormattedEuroCurrency(
      500,
      true
    )} per year for other treatment providers (e.g. osteopaths).`,
    medicalAids: '75%-100%',
    medicalAidsInfo: `Aids are technical means and body replacements that compensate for or alleviate disabilities, the consequences of illness or accidents (e.g. bandages, insoles, prostheses).\n\n Dialysis machines are also reimbursed.\n\nExpenses above ${englishFormattedEuroCurrency(
      350,
      true
    )} must be pre-approved (otherwise, we cover up to 75%).\n\n \n\n**100% coverage for medical aids:**\n\n• which directly alleviate or compensate for disabilities or the consequences of illnesses or accidents (e.g. invalid carriages, prostheses),\n\n• if the insured person needs them for therapeutic and diagnostic purposes (e.g. blood pressure monitors), or\n\n• in order to stay alive (life-saving medical aids such as breathing aids).\n\n• provision of instructions on how to use the medical aids and for their maintenance and repair.\n\n \n\n**Limits apply to the following:**\n\n• ${englishFormattedEuroCurrency(
      250,
      true
    )} per calendar year for each insured person for orthopaedic shoes or the orthopaedic adaptation of shoes or insoles.\n\n• The insured person can receive up to a total of ${englishFormattedEuroCurrency(
      1500,
      true
    )} for a hearing aid for each ear throughout the period when the insurance is in force. If the hearing loss can only be corrected through the use of hearing implants, we will reimburse an overall amount of up to € 4,000 per ear for this care.\n\n \n\n**The following are not covered:**\n\n• repair of orthopedic footwear.\n\n• medical aids for which compulsory care insurance has to provide reimbursement,\n\n• medical aids which are part of fitness/wellness and/or recreational facilities,\n\n• everyday personal effects and hygiene products (e.g. medical thermometers, anti-allergy bedding).`,

    pregnancy: '100%',
    fertilityTreatment: '100%',
    fertilityTreatmentInfo: `Written pre-approval is required.\n\nWe reimburse 100% of the costs of fertility treatment up to ${englishFormattedEuroCurrency(
      2500,
      0
    )} in total for the entire period of insurance.\n\n \n\nSome conditions apply:\n\n• The woman is no more than 39 years old at the time of the treatment.\n\n• The treatment is carried out on married couples who are living together.\n\n• Only the egg cells and sperm cells of the couple may be used.\n\n• The treatment is in accordance with German law.\n\n \n\nIf these conditions are met, the following are covered:\n\n• insemination cycles in the natural cycle, and\n\n• insemination cycles after hormonal stimulation, or\n\n• in vitro fertilization (IVF), or\n\n• intracytoplasmic sperm injection (ICSI) (including the necessary IVF), or\n\n• gamete intrafallopian transfer (GIFT).\n\n \n\nIf the insured person or his/her partner is entitled to benefits for reproductive medical procedures from another funding agency (e.g. statutory or private health insurance, the state), full use must be made of those benefits as a matter of priority. We then only pay for any costs which remain after the payment made by the other funding agency.`,
    midwife: '100%',
    nonMedicalPregnancyServices: false,

    boardAndRoom: 'Private room (1 bed)',
    privateHospitals: true,
    privateHospitalsInfo:
      'Up to 100% of the cost billed by a public hospital in Germany. No limit for emergency treatments.',
    headPhysician: '100%',
    headPhysicianInfo:
      'Get the best treatment when you’re in the hospital. This allows you to get treated by the head physician or specialist of your choice. Without this, you will still get standard medical treatment and are fully covered for hospital stays, but have to rely on the doctor that’s assigned to you.\n\nIt’s especially useful if you’re in the hospital for routine or non-life-threatening treatments and want to be sure you will be treated by the most experienced doctor available.',
    euWideCover: true,
    euWideCoverInfo:
      'Up to what is covered in Germany, more than this with pre-approval',
    repatriation: true,
    worldwideCover: true,
    worldwideCoverInfo:
      'Up to what is covered in Germany, more with pre-approval.\n\nUp to 12 weeks in any country where you have citizenship.\n\nUp to 4 weeks in every other country.',
  },
  {
    id: 3,
    name: 'Public',
    tariffKey: 'Public',
    contribution: publicHealthPriceString,
    dependents: true,
    dependentsInfo:
      'No extra cost to add children and non-working legal partners',
    deductible: false,

    generalDoctor: '100%',
    specialist: '100%',
    medication: '90%',
    vaccination: '100%',
    vaccinationInfo:
      'Coverage for vaccinations [recommended by the STIKO](https://www.bundesgesundheitsministerium.de/en/topics/vaccinations.html).',
    transports: '100%',
    transportsInfo:
      'In the event of an emergency, ambulance, taxi or Uber to the closest suitable hospital or clinic. The return trip can be covered in some cases.\n\nNon-hospital transportation is only for dialysis or chemotherapy in the event of an emergency.',
    sickPayCoverage: 'Limited to €3,491',
    digitalHealthApps: 'Certified apps',
    digitalHealthAppsInfo:
      'Only certified apps are covered. [List of certified apps](https://diga.bfarm.de/de/verzeichnis)',
    nonMedicalPrescriptions: false,
    purelyCosmeticProcedures: false,

    annualCoverage: 'Limited',
    annualCoverageInfo:
      'Reduced coverage for tooth replacement, root canal treatments, gum treatments, and other limitations can lead to high out-of-pocket payments.',
    dentalCheckups: '100%',
    dentalCleanings: {
      value: false,
      description: 'Prophylaxis is not included',
    },
    dentist: '100%',
    inlays: '30%',
    dentures: '50%',
    orthodonticsChildren: '80%-100%',
    preventiveCare: 'Partially covered',
    preventiveCareInfo:
      'Most preventive care treatments are only available after the age of 35 (full body checkup, skin cancer screening.\n\nThere are no age restrictions for annual gynecologist check-ups.\n\nHere is a [complete list.](https://www.krankenkassen.de/gesetzliche-krankenkassen/leistungen-gesetzliche-krankenkassen/gesetzlich-vorgeschriebene-leistungen/gesetzliche-krankenkassen-Vorsorgeuntersuchungen/)',
    therapeutic: '90%',
    therapeuticInfo:
      'The amount of prescribed sessions for a condition (e.g. physio therapy) is usually limited to a handful of sessions for most conditions.',
    mentalHealth: '100%',
    mentalHealthInfo:
      'Pre-approval required. Finding a therapist may be difficult',
    radioTherapy: '100%',
    natural: false,
    medicalAids: '75%-90%',
    medicalAidsInfo: 'Medical aids: 90% \n\n Hearing aids: 75%, up to €780',
    visionAids: false,
    refractiveSurgery: false,

    pregnancy: '100%',
    fertilityTreatment: 'Rarely',
    fertilityTreatmentInfo:
      'In some cases partially covered for married couples (Age 25-40).\n\nPre-approval required.',
    midwife: '100%',
    midwifeInfo:
      'Can be difficult to find a midwife on public health insurance in large cities.',
    nonMedicalPregnancyServices: 'Some services',
    nonMedicalPregnancyServicesInfo:
      'Coverage depends on the provider. Some providers cover birth preparation classes for the partner (up to a limit), specific prenatal and postnatal exercise classes, and doulas when prescribed by a doctor.',

    boardAndRoom: 'Shared room',
    privateHospitals: false,
    headPhysician: false,
    euWideCover: 'Partially covered',
    repatriation: false,
    worldwideCover: false,
  },
];
