import { SetupIntent } from '@stripe/stripe-js';
import { AxiosInstance } from 'axios';
import { PaymentMethod } from 'models/paymentMethods';

export const createPaymentMethod = async (
  network: AxiosInstance,
  {
    makeDefault,
    setupIntent,
  }: { makeDefault: boolean; setupIntent: SetupIntent }
) => {
  const { data: paymentMethod } = await network.post<PaymentMethod>(
    '/me/payment_methods/',
    {
      stripeId: setupIntent.payment_method,
      makeDefault,
    }
  );

  return paymentMethod;
};
