import { Address } from '@getpopsure/public-models';
import { Name } from 'models/user';
import { isAddress, isName } from 'shared/validators';

interface StoreGecDataAttributes {
  email: string;
  phone?: string;
  name?: Name;
  address?: Address;
}

/**
 * Stores a global gecData variable that can be accessed by
 * Google Tag Manager and used for enhanced conversions
 *
 * @param data StoreGecDataAttributes
 *
 * @return void
 */
export const storeGecDataObject = (data: StoreGecDataAttributes) => {
  const { email, name, address } = data;
  const isValidName = isName(name);
  const isValidAddress = isAddress(address);

  window.gecData = {
    email,
    ...(isValidName && isValidAddress
      ? {
          address: {
            first_name: name?.firstName,
            last_name: name?.lastName,
            street: `${address?.street} ${address?.houseNumber}`,
            city: address?.city,
            postal_code: address?.postcode,
            country: address?.country,
          },
        }
      : {}),
  };
};
