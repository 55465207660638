import { AutoSuggestInput, Input } from '@popsure/dirty-swan';
import { CollectableBillingAddress } from 'models/paymentMethods';
import { TFunction } from 'shared/i18n';

import type { Option } from './AddressInput.container';

interface Props {
  t: TFunction;
  setAddress: (address: Partial<CollectableBillingAddress>) => void;
  address: Partial<CollectableBillingAddress>;
  clearSuggestions: () => void;
  suggestions: Option[];
  countryInput: string;
  setSelectedCountry: (countryName: string) => void;
  handleSuggestionFetchRequest: (countryName: string) => void;
}

export const AddressInputView = ({
  address,
  setAddress,
  clearSuggestions,
  suggestions,
  countryInput,
  setSelectedCountry,
  handleSuggestionFetchRequest,
  t,
}: Props) => (
  <>
    <div className="p-p mt24">
      {t('components.paymentinfo.billingAddress.title', 'Billing address')}
    </div>
    <Input
      className="mt8"
      value={address?.line1 ?? ''}
      placeholder={t(
        'components.paymentinfo.billingAddress.edit.line1.placeholder',
        'Street'
      )}
      onChange={(e) => setAddress({ ...address, line1: e.target.value })}
      type="text"
      data-cy="address-input-line1"
    />
    <Input
      className="mt8"
      value={address?.line2 ?? ''}
      placeholder={t(
        'components.paymentinfo.billingAddress.edit.line2.placeholder',
        'Additional info (C/O, apartment, etc.)'
      )}
      onChange={(e) => setAddress({ ...address, line2: e.target.value })}
      type="text"
      data-cy="address-input-line2"
    />
    <div className="d-flex gap16">
      <Input
        className="mt8 w50"
        value={address?.city ?? ''}
        placeholder={t(
          'components.paymentinfo.billingAddress.edit.city.placeholder',
          'City'
        )}
        onChange={(e) => setAddress({ ...address, city: e.target.value })}
        type="text"
        data-cy="address-input-city"
      />
      <Input
        className="mt8 w50"
        value={address?.postal_code ?? ''}
        placeholder={t(
          'components.paymentinfo.billingAddress.edit.postal_code.placeholder',
          'Postal / ZIP code'
        )}
        onChange={(e) =>
          setAddress({ ...address, postal_code: e.target.value })
        }
        type="text"
        data-cy="address-input-postal_code"
      />
    </div>
    <Input
      className="mt8"
      value={address?.state ?? ''}
      placeholder={t(
        'components.paymentinfo.billingAddress.edit.state.placeholder',
        'State/Province/Region'
      )}
      onChange={(e) => setAddress({ ...address, state: e.target.value })}
      type="text"
      data-cy="address-input-state"
    />
    <AutoSuggestInput
      currentOption={countryInput}
      suggestions={suggestions}
      onChange={(input) => setSelectedCountry(input)}
      handleSuggestionSelected={(option) => setSelectedCountry(option.value)}
      handleSuggestionFetchRequest={(option) =>
        handleSuggestionFetchRequest(option.value)
      }
      handleSuggestionClearRequest={clearSuggestions}
      placeholder={t(
        'components.paymentinfo.billingAddress.edit.country.placeholder',
        'Country'
      )}
      className="mt8"
      data-cy="address-input-country"
      inputProps={{ autoComplete: 'address-input-country' }}
    />
  </>
);
