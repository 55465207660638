import { useTranslation } from '@getpopsure/i18n-react';
import * as Sentry from '@sentry/react';
import ErrorView from 'components/error';
import { useWindowSize } from 'hooks/useWindowSize';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

export const VideoDoctorAppointment = () => {
  const size = useWindowSize();
  const { t } = useTranslation();
  const params = useParams() as { policyId?: string };

  if (!params.policyId) {
    Sentry.captureException(
      `[Video doctor appointment] Trying to initiate a video doctor appointment without a policy id.`
    );
    return <ErrorView error="Missing policy id" />;
  }

  return (
    <>
      <Helmet>
        <script async src="https://tally.so/widgets/embed.js" />
      </Helmet>
      <iframe
        width={size.width ?? '100%'}
        height={size.height ?? '100%'}
        data-tally-src={`https://forms.feather-insurance.com/expat-video-doc?id=${params.policyId}`}
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
        title={t('page.videodoctor.title', 'Book a video doctor appointment')}
      />
    </>
  );
};
