import { Address } from '@getpopsure/public-models';
import {
  AutocompleteAddress,
  Button,
  IbanInput,
  Input,
} from '@popsure/dirty-swan';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { ChangeEvent, FormEvent } from 'react';
import { useSafeTranslation } from 'shared/i18n';

interface ReferralUserDataFormProps {
  handleFormSubmit(e: FormEvent): void;
  handleInputChange(e: ChangeEvent<HTMLInputElement> | string): void;
  handleAddressChange(newAddress: Partial<Address>): void;
  isValidForm: boolean;
  address?: Partial<Address>;
  iban?: string;
  accountHolder?: string;
  fetchLoading?: boolean;
  updateLoading?: boolean;
}

export const ReferralUserDataForm = (props: ReferralUserDataFormProps) => {
  const {
    handleFormSubmit,
    handleInputChange,
    handleAddressChange,
    isValidForm,
    iban,
    accountHolder,
    address,
    fetchLoading,
    updateLoading,
  } = props;
  const { t } = useSafeTranslation();

  if (fetchLoading) return <TimedLoadSpinner />;

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <div className="bg-white br8 p24 mb24">
          <p className="p-p mb8">
            {t('referralengine.payoutdetails.form.bank', 'Bank account')}
          </p>
          <IbanInput
            name="iban"
            value={iban}
            className="mb8"
            placeholder={t(
              'referralengine.payoutdetails.form.iban.placeholder',
              'IBAN'
            )}
            onChange={handleInputChange}
          />
          <Input
            name="accountHolder"
            value={accountHolder}
            className="mb24"
            placeholder={t(
              'referralengine.payoutdetails.form.accountHolder.placeholder',
              "Account holder's name"
            )}
            onChange={handleInputChange}
          />
          <p className="p-p mb8">
            {t('referralengine.payoutdetails.form.billing', 'Billing address')}
          </p>
          <AutocompleteAddress
            address={address}
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
            onAddressChange={handleAddressChange}
          />
        </div>
        <div className="w100 d-flex jc-center">
          <Button
            disabled={!isValidForm}
            type="submit"
            className="w100 wmx3"
            loading={updateLoading}
          >
            {t('referralengine.payoutdetails.form.submit', 'Save')}
          </Button>
        </div>
      </form>
    </>
  );
};
