import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import { ExpatQuestionnaire } from 'features/expat/models';
import { getTranslatedQuestionnaire } from 'features/expat/questionnaire';
import { getExpatAnswers } from 'features/expat/selectors';
import Questionnaire from 'features/questionnaireFramework';
import { useCheckoutStarted } from 'hooks/useCheckoutStarted';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

export const SignupPage = () => {
  const { t } = useSafeTranslation();

  const answers = useSelector(getExpatAnswers) || {};
  const dispatch = useDispatch();

  const handleAnswerQuestion = (answer: Partial<ExpatQuestionnaire>) => {
    dispatch(storeGenericQuestionnaireAnswer('expat', answer));
  };

  const handleRemoveAnswer = (questionIds: (keyof ExpatQuestionnaire)[]) => {
    dispatch(removeGenericQuestionnaireAnswer('expat', questionIds));
  };

  useCheckoutStarted('EXPAT_V2', answers.email);

  return (
    <Questionnaire
      featureName="Expat"
      questionnaire={getTranslatedQuestionnaire(t)}
      questionnaireAnswers={answers}
      handleAnswerQuestion={handleAnswerQuestion}
      handleRemoveAnswer={handleRemoveAnswer}
      share={{
        verticalId: 'expat',
      }}
    />
  );
};
