import { language } from '@getpopsure/i18n-react';
import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { DownloadPolicy } from 'components/DownloadPolicy';
import {
  CheckStatus,
  PythonBackendInsuranceType,
  SignupsBackendInsuranceType,
} from 'components/DownloadPolicy/models';
import Markdown from 'components/markdown';
import { useSafeTranslation } from 'shared/i18n';

export const Download = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  questionnaireAnswers,
  verticalId,
  checkList,
  locale,
}: {
  checkList?: CheckStatus[];
  verticalId: PythonBackendInsuranceType | SignupsBackendInsuranceType;
  locale?: boolean;
} & QuestionnaireFormProps<Questionnaire>) => {
  const { t } = useSafeTranslation();
  const { quoteId, questionnaireId } = questionnaireAnswers;
  const hasCompletedDownload = Boolean(questionnaireAnswers.hasDownloadedDocs);
  const handleContinue = () => onSubmitValue(true);

  const list = checkList
    ? checkList.map(({ id, description }) => {
        if (typeof description === 'string') {
          const descriptionToNode = (
            <span data-cy={`download-${id}`}>
              <Markdown openLinksInNewTab>{description}</Markdown>
            </span>
          );
          return { id, description: descriptionToNode };
        }
        return { id, description };
      })
    : [
        {
          id: 'confirm',
          description: t(
            'generic.qnr.download.documents.agreement',
            'I confirm that I have received all documents and written terms related to the insurance. I agree with the terms.'
          ),
        },
      ];

  return (
    <DownloadPolicy
      insuranceType={verticalId}
      checkList={list}
      handleContinue={handleContinue}
      policyInfo={{
        quoteId: String(quoteId),
        questionnaireId: String(questionnaireId),
        language: locale ? language() : undefined,
      }}
      hasCompletedDownload={hasCompletedDownload}
    />
  );
};
