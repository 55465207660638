import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { getTrackingObject } from '@getpopsure/tracker';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { mergeCheckoutInfo } from 'features/paymentScreen/paymentScreen.actions';
import {
  linkToStripe,
  startCheckout,
} from 'features/paymentScreen/paymentScreen.api';
import { PaymentScreenThunkDispatch } from 'features/paymentScreen/paymentScreen.thunks';
import { InsuranceTypes, policyInfoKindMapping } from 'models/insurances/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import api from 'shared/api';

export const GenericCheckout = <Questionnaire extends QuestionnaireQuestions>({
  type,
  questionnaireAnswers,
}: QuestionnaireFormProps<Questionnaire> & {
  type: InsuranceTypes;
}) => {
  const history = useHistory();
  const dispatch = useDispatch<PaymentScreenThunkDispatch>();
  const startDate = questionnaireAnswers?.startDate
    ? String(questionnaireAnswers.startDate)
    : undefined;
  const monthlyPriceInEuros = Object(questionnaireAnswers?.quote).price;
  const questionnaireId = questionnaireAnswers?.questionnaireId
    ? String(questionnaireAnswers?.questionnaireId)
    : undefined;
  const quoteId = questionnaireAnswers?.quoteId
    ? String(questionnaireAnswers?.quoteId)
    : undefined;
  // Policy info kind value is need for backend to be able to process the checkout correctly - by applying different logic for each policy info type
  const policyInfoKind = policyInfoKindMapping[type];
  const source = getTrackingObject();

  if (!startDate || !monthlyPriceInEuros || !questionnaireId || !quoteId) {
    throw new Error('Missing start date and monthlyPrice data for checkout');
  }

  const callInitializeCheckout = async () => {
    await linkToStripe(api.network);

    const { data: checkout } = await startCheckout(api.network, {
      startDate,
      insuranceType: type,
      monthlyPriceInEuros,
      policyDetails: {
        type,
      },
      policyInfo: {
        questionnaireId,
        quoteId,
        source,
      },
      ...(policyInfoKind && { policyInfoKind }),
    });

    if (!checkout) {
      throw new Error('Failed to initialize checkout');
    }

    dispatch(mergeCheckoutInfo(checkout));

    history.replace(
      generatePath(routes.paymentScreen.pay.path, {
        checkoutId: checkout.id,
      })
    );
  };

  useEffect(() => {
    callInitializeCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingSpinner />;
};
