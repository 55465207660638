import { capitalizeName } from '@getpopsure/public-utility';
import { getPoliciesListAction } from 'features/policiesDashboard/utils/getPoliciesListAction';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import { ContactUsButton } from 'features/policySingle/components/buttons';
import {
  DelinquencyBlockerCancelPolicyModal,
  PolicySingleModal,
} from 'features/policySingle/components/modals';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from 'features/policySingle/PolicySingle.models';
import { getPolicyDocuments } from 'features/policySingle/sections/PolicyDocuments/utils/getPolicyDocuments';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';

import { getGenericInfo } from '../generic/getGenericInfo';
import { DisabilityCancelPolicyButton } from './buttons/DisabilityCancelPolicyButton';
import { DisabilityCancelPolicyModal } from './modals/DisabilityCancelPolicyModal';

export const getDisabilityConfig = (
  args: GetPolicyConfigArgs
): PolicyConfig => {
  const [policy, t] = args;
  const action = getPoliciesListAction(policy);
  const policyStatus =
    action && action !== 'DETAIL'
      ? 'ACTION_NEEDED'
      : getPolicyStatus(policy, true);
  const title = getTitleMapping(t)[policy.type];
  const capitalizedName = policy.attributes.insuredPerson?.name
    ? capitalizeName(policy.attributes.insuredPerson?.name)
    : '';
  const subtitle = capitalizedName || '';

  const header: PolicyConfig['header'] = {
    title,
    backgroundColor: bannerColorMapping.DISABILITY,
    insuranceType: policy.type,
    subtitle,
    policyStatus,
    startDate: policy.attributes.startDate,
  };

  if (policy.attributes.price) {
    header.price = {
      amount: parseFloat(policy.attributes.price),
      billingType: policy.attributes.billingType as BillingType,
    };
  }

  const sections: PolicySingleSection[] = [
    {
      id: 'POLICY_DETAILS',
      props: {
        policyType: policy.type,
        info: getGenericInfo(policy, t),
        buttons: [
          {
            id: 'CONTACT_US',
            component: ContactUsButton,
          },
          ...(policy.status !== 'CANCELED'
            ? [
                {
                  id: 'DISABILITY_CANCEL_POLICY',
                  component: DisabilityCancelPolicyButton,
                },
              ]
            : []),
        ],
      },
    },
    {
      id: 'POLICY_DOCUMENTS',
      props: {
        documents: getPolicyDocuments(policy),
      },
    },
  ];

  const modals: PolicySingleModal[] = [
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
    {
      id: 'DISABILITY_CANCEL_POLICY',
      component: DisabilityCancelPolicyModal,
    },
  ];

  return { header, modals, sections };
};
