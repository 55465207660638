import styles from './LoadingSkeleton.module.scss';

const DummyHeroBanner = () => <div className={styles.dummyHeroBanner} />;

const DummyHeaderCard = () => (
  <div className={`d-flex fd-column ai-center ws12 ${styles.dummyHeaderCard}`}>
    <div className={styles.dummyHeaderCardIcon} />
    <div className={`mt16 ${styles.dummyHeaderCardTitle}`} />
    <div className={`mt32 ${styles.dummyHeaderCardDescription}`} />
  </div>
);

const DummySectionCard = () => (
  <div className={`ws12 mt32 ${styles.dummySectionCard}`} />
);

export const DummyPage = () => (
  <div
    className={`d-flex fd-column ai-center mt80 ml-auto mr-auto ${styles.container}`}
  >
    <DummyHeroBanner />
    <div className={styles.shiftup}>
      <DummyHeaderCard />
      <DummySectionCard />
      <DummySectionCard />
    </div>
  </div>
);
