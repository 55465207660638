import ReactMarkdown from 'react-markdown';

import styles from './SectionCard.module.scss';

export const SectionCard = ({
  content,
  price,
  CTA,
  description,
  coverageDetails,
  icon,
  icon2x,
  icon3x,
  title,
}: {
  CTA: JSX.Element;
  content?: JSX.Element;
  price?: string;
  description: string;
  coverageDetails?: JSX.Element;
  icon: string;
  icon2x?: string;
  icon3x?: string;
  title: string;
}) => {
  const srcSet =
    icon2x || icon3x ? `${icon} 1x, ${icon2x} 2x, ${icon3x} 3x` : '';

  return (
    <section
      className={`d-flex f-wrap p40 mb32 ml8 mr8 wmx12 gap16 ${styles.container}`}
    >
      <div className={`d-flex ai-center jc-start ${styles.header}`}>
        <img
          className={styles.icon}
          src={icon}
          srcSet={srcSet}
          alt="section icon"
        />
        <h3 className={`p-h2 ${styles.title}`}>{title}</h3>
      </div>
      {price && (
        <div className="d-flex ai-center js-center fw-bold">
          <span className="tc-grey-700 mr8">~ {price}</span>
          <span className="tc-grey-500">per month</span>
        </div>
      )}
      <div className={`d-flex fd-column ai-center jc-center ${styles.cta}`}>
        {CTA}
      </div>
      <ReactMarkdown
        className={`p-p tc-grey-600 mt8 w100 ${styles.markdown}`}
        source={description}
        renderers={{
          link: ({ href, children }) => (
            <a href={href} className="p-a" target="_blank" rel="noreferrer">
              {children}
            </a>
          ),
        }}
      />
      {coverageDetails && (
        <div className="p-p tc-grey-600 mt8 w100">{coverageDetails}</div>
      )}
      {content}
    </section>
  );
};
