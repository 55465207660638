import { website } from '@getpopsure/private-constants';
import { Button, Card, illustrations } from '@popsure/dirty-swan';
import { useSafeTranslation } from 'shared/i18n';

import styles from './ConfirmUpgrade.module.scss';

type ConfirmUpgradeProps = {
  onSubmit: () => void;
};

export const ConfirmUpgrade = ({ onSubmit }: ConfirmUpgradeProps) => {
  const { t } = useSafeTranslation();

  return (
    <div className={`mb80 ${styles.animatedContainer}`}>
      <div className="p-body">
        <div>
          <div className="my16">
            <h1 className="p-h1">
              {t(
                'expatLongTerm.qnr.confirmUpgrade.gettingStarted.title',
                'Switching to long-term expat health insurance'
              )}
            </h1>
            <p className="p-p mt16">
              {t(
                'expatLongTerm.qnr.confirmUpgrade.gettingStarted.description',
                'Here is some description'
              )}
            </p>
          </div>
          <div className="ws8 d-flex fd-column gap16">
            <Card
              title={t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.noTimeLimit.title',
                'No time limit'
              )}
              titleVariant="medium"
              description={t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.noTimeLimit.description',
                'You can remain on long-term expat health insurance for as long as you need.'
              )}
              icon={<img src={illustrations.calendar} alt="calendar" />}
            />
            <Card
              title={t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.cancelHealthPolicy.title',
                'We’ll cancel your expat health policy '
              )}
              titleVariant="medium"
              description={t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.cancelHealthPolicy.description',
                'We will handle the cancelation of the expat health policy to ensure there is no gap between your health insurances.'
              )}
              icon={<img src={illustrations.shield} alt="money" />}
            />
            <Card
              title={t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.ongoingTreatments.title',
                'Ongoing treatments will not be covered'
              )}
              titleVariant="medium"
              description={t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.ongoingTreatments.description',
                'Treatments for conditions that started while you were on the expat health insurance plan are not covered, as they are considered as pre-existing conditions for this plan.'
              )}
              icon={<img src={illustrations.medicine} alt="credit card" />}
            />
          </div>
        </div>
        <div>
          <Button className="wmx5 wmn3 mt24 mr16 mb8" onClick={onSubmit}>
            {t(
              'expatLt.qnr.confirmUpgrade.continue.button.text',
              'Get started'
            )}
          </Button>

          <a
            className="tc-primary-500 p-btn--secondary wmn3 mt24"
            href={website.support}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('expatLt.qnr.confirmUpgrade.contact.button.text', 'Book a call')}
          </a>
        </div>
      </div>
    </div>
  );
};
