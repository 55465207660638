import AddressForm from 'components/questionnaireForms/AddressForm';
import BooleanForm from 'components/questionnaireForms/BooleanForm';
import CheckboxForm from 'components/questionnaireForms/CheckboxForm';
import CountryMultiForm from 'components/questionnaireForms/CountryMultiForm';
import CountrySingleForm from 'components/questionnaireForms/CountrySingleForm';
import CurrencyInputForm from 'components/questionnaireForms/CurrencyInputForm';
import DateForm from 'components/questionnaireForms/DateForm';
import DropdownSingleForm from 'components/questionnaireForms/DropdownSingleForm';
import InputForm from 'components/questionnaireForms/InputForm';
import NameForm from 'components/questionnaireForms/NameForm';
import NumberInputForm from 'components/questionnaireForms/NumberInputForm';
import NumericConversionInputForm from 'components/questionnaireForms/NumericConversionInputForm';
import RadioForm from 'components/questionnaireForms/RadioForm';
import TextForm from 'components/questionnaireForms/TextForm';
import { QuestionType } from 'models/questionnaireFramework';

export const getQuestionComponent = (type: QuestionType) => {
  const componentMapping: {
    [key in QuestionType]: (args: any) => JSX.Element;
  } = {
    ADDRESS: AddressForm,
    BOOLEAN: BooleanForm,
    CHECKBOX: CheckboxForm,
    COUNTRY_MULTI: CountryMultiForm,
    COUNTRY_SINGLE: CountrySingleForm,
    CURRENCY: CurrencyInputForm,
    DATE: DateForm,
    INPUT: InputForm,
    NAME: NameForm,
    NUMBER: NumberInputForm,
    RADIO: RadioForm,
    TEXT: TextForm,
    DROPDOWN_SINGLE: DropdownSingleForm,
    NUMERIC_CONVERSION: NumericConversionInputForm,
  };
  return componentMapping[type];
};
