import {
  capitalizeName,
  englishFormattedEuroCurrency,
} from '@getpopsure/public-utility';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Cell from 'features/checkout/components/Cell';
import { ReferrerCodeVerify } from 'features/referralEngine';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { useSafeTranslation } from 'shared/i18n';
import { capitalizeSingleWord } from 'shared/util/capitalizeSingleWord';

import styles from './PolicyDetails.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

export const PolicyDetails = () => {
  const { t } = useSafeTranslation();

  const checkoutInfo = useSelector((state: AppState) => {
    const questionnaireState = state.genericQuestionnaire.legal;

    return questionnaireState?.checkoutInfo
      ? questionnaireState.checkoutInfo
      : undefined;
  });

  const questionnaireAnswers =
    useSelector((state: AppState) => state.genericQuestionnaire.legal) || {};

  if (!checkoutInfo) {
    return <></>;
  }

  const { startDate, policyDetails } = checkoutInfo.mainPolicy;

  return (
    <div className={classNames('p24 bg-white br8', styles.container)}>
      <div className="p-h2 mb16">
        {t('legal.checkout.title', 'Policy details')}
      </div>
      {startDate && (
        <Cell
          title={t(
            'page.legal.qnr.checkout.policydetails.requestedStartDate',
            'Requested start date'
          )}
          value={dayjs(checkoutInfo.mainPolicy.startDate).format(DATE_FORMAT)}
          additionalInfo={{
            title: t(
              'page.legal.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
              'Requested start date'
            ),
            description: t(
              'page.legal.qnr.checkout.policydetails.requestedStartDate.additionalInfo.description',
              'Your coverage should begin on {{coverageDate}}, but only after you have received the official confirmation.',
              {
                coverageDate: dayjs(checkoutInfo.mainPolicy.startDate).format(
                  DATE_FORMAT
                ),
              }
            ),
          }}
        />
      )}
      {policyDetails.partnerName && (
        <Cell
          title={t(
            'legal.qnr.checkout.partnerName',
            'Included spouse or partner'
          )}
          value={capitalizeName(policyDetails.partnerName) ?? ''}
        />
      )}
      {policyDetails.provider && (
        <Cell
          title={t('legal.qnr.checkout.provider', 'Provider')}
          value={capitalizeSingleWord(policyDetails.provider)}
        />
      )}
      {policyDetails.deductible !== undefined && (
        <Cell
          title={t('legal.qnr.checkout.deductible', 'Deductible')}
          value={englishFormattedEuroCurrency(
            checkoutInfo?.mainPolicy.policyDetails.deductible,
            true
          )}
          additionalInfo={{
            title: t('legal.qnr.checkout.modal.title', 'What is a deductible?'),
            description: t('legal.qnr.checkout.modal.description', {
              defaultValue:
                'The deductible is the amount you have to pay out-of-pocket before the insurance begins to cover costs. It will go down by {{price}} each claim-free insurance year, until it is €0.\n\n The deductible does not apply to initial phone consultations with a legal expert, which are free of charge.',
              price: englishFormattedEuroCurrency(100, true),
            }),
          }}
        />
      )}
      <div className={`bg-grey-200 mt16 mb16 ${styles.divider}`} />
      <Cell
        title={t('legal.qnr.checkout.plan', {
          defaultValue: '{{planId}} plan',
          planId: capitalizeSingleWord(questionnaireAnswers.quote?.plan ?? ''),
        })}
        value={englishFormattedEuroCurrency(
          checkoutInfo?.mainPolicy.policyDetails.basePremium
        )}
      />
      {Object.entries(checkoutInfo?.mainPolicy.policyDetails.addons).map(
        ([addon, price]) => {
          return (
            <Cell
              title={t('legal.qnr.checkout.addon', {
                defaultValue: '{{addon}} add-on',
                addon: capitalizeSingleWord(addon),
              })}
              value={englishFormattedEuroCurrency(price)}
            />
          );
        }
      )}
      <div className={`bg-grey-200 mt16 mb16 ${styles.divider}`} />
      {checkoutInfo?.totalMonthlyPrice && (
        <div className="d-flex ai-start">
          <div className="p-h3">
            {t('legal.checkout.total', 'Total per month')}
          </div>
          <div className="p-h3 ml-auto">
            {englishFormattedEuroCurrency(checkoutInfo?.totalMonthlyPrice)}
          </div>
        </div>
      )}
      <ReferrerCodeVerify />
    </div>
  );
};
