import * as Sentry from '@sentry/react';

import { TASK_ENGINE_SENTRY_CATEGORY } from '../constants';

export const updateTaskMutationErrorHandler = (error: unknown) => {
  Sentry.addBreadcrumb({
    category: TASK_ENGINE_SENTRY_CATEGORY,
    message: 'Error updating task',
    level: 'error',
  });
  Sentry.captureException(error);
};
