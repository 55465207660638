import { fetchPolicyDetail } from 'actions/policies';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { APIResponseError } from 'models/error';
import { Policy } from 'models/policies';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getPolicyDetail } from 'selectors/policies';

export const useLoadPolicyDetail = (
  policyId: string
): { loading: boolean; error?: APIResponseError; policy?: Policy } => {
  const dispatch = useDispatch();

  const policy = useSelector((state: AppState) =>
    policyId !== undefined ? getPolicyDetail(state, policyId) : undefined
  );

  const { loading, error } = useRequestStatus('LOAD_MY_POLICY_DETAIL');

  useEffect(() => {
    if (policyId) {
      dispatch(fetchPolicyDetail(policyId));
    }
  }, [dispatch, policyId]);

  return { loading, error, policy };
};
