import { Card } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { AdditionalCoverageItem } from 'features/policiesDashboard/utils/getAdditionalCoverage';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import styles from './AdditionalCoverageCard.module.scss';

interface CardProps extends Omit<AdditionalCoverageItem, 'type' | 'title'> {
  title: ReactNode;
  borderedImage?: boolean;
  onClick?: () => void;
}

const AdditionalCoverageCard = ({
  borderedImage,
  image,
  href,
  to,
  onClick,
  ...item
}: CardProps) => {
  const handleOnClick = () => {
    if (!href && !onClick) {
      window.scroll(0, 0);
    }

    onClick?.();
  };

  if (!href && !to && !onClick) {
    return null;
  }

  return (
    <Card
      {...item}
      {...(href && { as: 'a', href })}
      {...(to && { as: Link, to })}
      onClick={handleOnClick}
      density="compact"
      icon={
        <div
          className={classNames(
            styles.imageWrapper,
            {
              [styles.bordered]: borderedImage,
            },
            'br8'
          )}
        >
          <img aria-hidden src={image} alt="" width={64} />
        </div>
      }
      dropShadow={false}
      titleVariant="medium"
      descriptionVariant="small"
      classNames={{
        buttonWrapper: styles.card,
        wrapper: styles.innerCard,
        title: classNames({ [styles.cardTitle]: !borderedImage }),
      }}
      actionIcon={null}
    />
  );
};

export { AdditionalCoverageCard };
