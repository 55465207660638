import { CheckIcon, XIcon } from '@popsure/dirty-swan';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useSafeTranslation } from 'shared/i18n';

import styles from './CoverageModal.module.scss';
import { CoverageModalRegular } from './CoverageModalRegular';
import { CoverageModalTabbed } from './CoverageModalTabbed';

export type CoverageTableDataEntry = {
  iconSrc: string;
  rows: CoverageTableRow[];
  title: string;
};

export type CoverageTableRow = {
  cells: CoverageTableDataCell[];
  noSeparator?: boolean;
  smallPadding?: boolean;
  smallText?: boolean;
};

export type CoverageTableDataCell = React.ReactNode;

type CoverageModalProps = {
  firstItemOpenByDefault?: boolean;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  coverageTableData: CoverageTableDataEntry[];
  coverageTableHeaders?: React.ReactNode[];
};

export type CoverageModalVariantProps = CoverageModalProps & {
  coverageTableHeaders?: React.ReactNode[];
  coverageTableData: CoverageTableDataEntry[];
  title: string;
  renderDataCell: (
    value: string | React.ReactNode | boolean,
    cellWidth?: number
  ) => JSX.Element;
};

const booleanToDataCell = (
  value: boolean,
  cellWidth?: number,
  key?: string
) => (
  <td
    key={key}
    className={styles.iconCell}
    style={cellWidth ? { width: `${cellWidth}px` } : {}}
    aria-label={value ? 'Yes' : 'No'}
  >
    {value ? (
      <CheckIcon color="purple-500" size={24} />
    ) : (
      <XIcon color="grey-400" size={24} />
    )}
  </td>
);

const renderDataCell = (
  value: string | React.ReactNode | boolean,
  cellWidth?: number,
  key?: string
) => {
  if (typeof value === 'boolean') {
    return booleanToDataCell(value, cellWidth, key);
  }
  return (
    <td key={key} style={cellWidth ? { width: `${cellWidth}px` } : {}}>
      {value}
    </td>
  );
};

export const CoverageModal = ({
  title = '',
  ...restProps
}: CoverageModalProps) => {
  const isDesktop = useMediaQuery('ABOVE_MOBILE');

  const { t } = useSafeTranslation();

  const titleOrDefault =
    title || t('components.coveragemodal.title.default', 'Coverage details');

  const modalProps = {
    ...restProps,
    title: titleOrDefault,
    renderDataCell,
  };

  return isDesktop ? (
    <CoverageModalRegular {...modalProps} />
  ) : (
    <CoverageModalTabbed {...modalProps} />
  );
};
