import { TFunction } from '@getpopsure/i18n-react';
import dayjs from 'dayjs';
import { CellProps } from 'features/checkout/components/Cell';
import { CheckoutInfo } from 'features/paymentScreen/paymentScreen.models';
import { InsuranceTypes } from 'models/insurances/types';
import { getTitleMapping } from 'models/insurances/types/mapping';

export type PolicyDetailsConfig = {
  policyDetails: CellProps[];
  totalPrice: number;
  otherSections?: {
    title: string;
    cells: CellProps[];
  }[];
};

export type PolicyDetailsConfigFunction = (
  checkoutInfo: CheckoutInfo,
  t: TFunction,
  questionnaire?: Record<string, unknown>,
  quote?: Record<string, unknown>
) => PolicyDetailsConfig;

const DATE_FORMAT = 'DD MMM YYYY';

const getDefaultPolicyDetails = (
  checkoutInfo: CheckoutInfo,
  t: TFunction
): PolicyDetailsConfig => {
  const {
    totalMonthlyPrice,
    mainPolicy: {
      startDate,
      policyDetails: { type: insuranceType, planId },
    },
  } = checkoutInfo;

  return {
    policyDetails: [
      {
        title: t('paymentScreen.policyDetails.insuranceType', 'Insurance'),
        value: getTitleMapping(t)[insuranceType],
      },
      ...(planId
        ? [
            {
              title: t('paymentScreen.policyDetails.planId', 'Plan'),
              value: planId,
            },
          ]
        : []),
      {
        title: t('paymentScreen.policyDetails.startDate', 'Start date'),
        value: dayjs(startDate).format(DATE_FORMAT),
      },
    ],
    totalPrice: totalMonthlyPrice,
  };
};

export const getPolicyDetails = (
  checkoutInfo: CheckoutInfo,
  t: TFunction,
  questionnaire?: Record<string, unknown>,
  quote?: Record<string, unknown>
): PolicyDetailsConfig => {
  const {
    mainPolicy: {
      policyDetails: { type: insuranceType },
    },
  } = checkoutInfo;

  const getPolicyDetailsConfig =
    policyDetailsMapping[insuranceType] ?? getDefaultPolicyDetails;

  return getPolicyDetailsConfig(checkoutInfo, t, questionnaire, quote);
};

const policyDetailsMapping: Partial<
  Record<InsuranceTypes, PolicyDetailsConfigFunction>
> = {
  PET_HEALTH: getDefaultPolicyDetails,
};
