import { isPartialAddressValid } from '@getpopsure/public-models';
import { AutocompleteAddress } from '@popsure/dirty-swan';
import type { AutocompleteAddressProps } from '@popsure/dirty-swan/src/lib/components/autocompleteAddress';

export type AddressInputProps = {
  onValidate(valid: boolean): void;
} & Omit<AutocompleteAddressProps, 'apiKey' | 'onAddressChange'>;
export const AddressInput = (props: AddressInputProps) => {
  const { onValidate } = props;

  return (
    <AutocompleteAddress
      {...props}
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
      onAddressChange={(prevAddress) =>
        onValidate(isPartialAddressValid(prevAddress))
      }
    />
  );
};
