import {
  GenericQuestionnaireAction,
  GenericQuestionnaireState,
} from 'reducers/genericQuestionnaire';
import { WithRequiredProperty } from 'shared/models/util';

export function storeGenericQuestionnaireAnswer<
  T extends keyof GenericQuestionnaireState
>(
  questionnaire: T,
  answer: GenericQuestionnaireState[T]
): GenericQuestionnaireAction<T> {
  return {
    type: 'GENERIC_QUESTIONNAIRE_ANSWERED_QUESTION',
    answer,
    questionnaire,
  };
}

export function flushGenericQuestionnaire<
  T extends keyof GenericQuestionnaireState
>(questionnaire: T): GenericQuestionnaireAction<T> {
  return {
    type: 'FLUSH_GENERIC_QUESTIONNAIRE',
    questionnaire,
  };
}

export function setCurrentGenericQuestionnaireAnswer<
  T extends keyof GenericQuestionnaireState
>(
  questionnaire: T,
  answer: GenericQuestionnaireState[T]
): GenericQuestionnaireAction<T> {
  return {
    type: 'SET_CURRENT_GENERIC_QUESTIONNAIRE',
    answer,
    questionnaire,
  };
}

// questionIds had a type of never[] since the keys of GenericQuestionnaireState were optional.
// To be able to correctly type questionIds we need to make the property of key T required by using
// WithRequiredProperty<GenericQuestionnaireState, T> to receive a version of GenericQuestionnaireState where this property is not optional.
export function removeGenericQuestionnaireAnswer<
  T extends keyof GenericQuestionnaireState
>(
  questionnaire: T,
  questionIds: Array<
    keyof WithRequiredProperty<GenericQuestionnaireState, T>[T]
  >
): GenericQuestionnaireAction<T> {
  return {
    type: 'REMOVE_ANSWER',
    questionnaire,
    questionIds,
  };
}
