import {
  AllowedInsuranceTypes,
  TAllowedInsuranceTypes,
} from 'features/recommendationTool/models/recommendation';

export const isValidInsuranceType = (
  insuranceType: TAllowedInsuranceTypes
): boolean => {
  if (insuranceType && !AllowedInsuranceTypes.includes(insuranceType)) {
    return false;
  }
  return true;
};
