import GenericReview from 'components/review';
import { createQuote, CreateQuoteDispatch } from 'features/household/actions';
import {
  addonTypeLookup,
  answersToReviewValues,
  answerValueToStringLookup,
  Household,
  HouseholdAnswersBackendSchema,
  HouseholdQuestionnaireLabels,
  HouseholdQuotesDto,
  questionPropLookup,
  ZHouseholdAnswersRequiredForQuoteSchema,
} from 'features/household/models';
import { createTitles } from 'features/household/utils';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

export const Review = ({
  routes,
  onSubmitValue,
  questionnaireAnswers,
}: CustomComponentProps<Household>) => {
  const { t } = useSafeTranslation();
  const questionReviewValues = answersToReviewValues(
    t,
    questionnaireAnswers,
    routes
  );

  const dispatch = useDispatch<CreateQuoteDispatch>();

  const { loading, error } = useRequestStatus('CREATE_QUOTE');
  const [validationError, setValidationError] = useState<string | undefined>();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const validationResult =
      ZHouseholdAnswersRequiredForQuoteSchema.safeParse(questionnaireAnswers);

    if (!validationResult.success) {
      const { issues } = validationResult.error;

      issues.forEach((issue) => {
        if (issue.path.includes('startDate') && issue.code === 'too_small') {
          setValidationError(
            t(
              'page.household.questionnaire.review.startDateNotInFuture.errorMessage',
              'Please choose a coverage start date in the future.'
            )
          );
        }
      });

      throw new Error(
        `[Household - Review] Questionnaire answer validation errors:\n${validationResult.error.toString()}`
      );
    }

    const {
      account,
      householdAnswersTransformedForBackendSubmission,
      householdQuotesDto,
    } = validationResult.data;

    const answers: HouseholdAnswersBackendSchema =
      householdAnswersTransformedForBackendSubmission;

    const labels: HouseholdQuestionnaireLabels = {
      labels: {
        title: {
          personalInfo: createTitles(answers, t).personalInfo,
          householdInfo: createTitles(answers, t).householdInfo,
        },
        answers: {
          personalInfo: {
            addOns: addonTypeLookup(t),
            gender: answerValueToStringLookup(t).gender,
            numberOfPreviousClaims:
              answerValueToStringLookup(t).numberOfPreviousClaims,
            propertyOwnership: answerValueToStringLookup(t).propertyOwnership,
          },
          householdInfo: {
            homeType: answerValueToStringLookup(t).homeType,
            securityDevices:
              questionPropLookup(t).securityDevices.mapValue || {},
          },
        },
      },
      questionOrder: {
        personalInfo: [
          'name',
          'email',
          'gender',
          'address',
          'dateOfBirth',
          'insuredSum',
          'addOns',
          'bikeInsuredSum',
          'startDate',
          'propertyOwnership',
          'isPrimaryResidence',
          'coveringEveryHouseholdMember',
          'numberOfPreviousClaims',
          'isClaimsAmountMoreThan1500',
        ],
        householdInfo: ['livingSpace', 'securityDevices', 'homeType'],
      },
    };

    const quotesDto: HouseholdQuotesDto = householdQuotesDto;

    const createNewAccount = !account;

    const { status } = await dispatch(
      createQuote(answers, labels, quotesDto, createNewAccount, t)
    );

    if (status === 'SUCCESS') {
      onSubmitValue(true);
    }
  };

  return (
    <GenericReview
      questionReviewValues={questionReviewValues}
      loading={loading}
      error={validationError || error?.message}
      handleSubmit={handleSubmit}
      insuranceType="HOUSEHOLD"
    />
  );
};
