import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import {
  createDisabilityQuestionnaire,
  createDisabilityQuote,
} from 'features/disability/api';
import {
  DisabilityNotPersistedQuotesDto,
  DisabilityQuestionnaireAnswers,
  DisabilityQuestionnaireLabels,
} from 'features/disability/models';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import endpoint, { Client } from 'shared/api';
import { ActionResponse } from 'shared/models/types';

// Augment the shared api with our endpoint functions
Client.prototype.createDisabilityQuote = createDisabilityQuote;
Client.prototype.createDisabilityQuestionnaire = createDisabilityQuestionnaire;

export type CreateQuoteAction<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  Record<string, unknown>,
  GenericQuestionnaireAction<'disability'> | RequestAction
>;

export type CreateQuoteDispatch = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  GenericQuestionnaireAction<'disability'> | RequestAction
>;

export type CreateQuoteReturnType = {
  status: ActionResponse;
};

// Gets a quote without persisting it
export const getDisabilityQuote =
  (
    quotesDto: DisabilityNotPersistedQuotesDto
  ): ThunkAction<
    Promise<void>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'disability'> | RequestAction
  > =>
  async (dispatch) => {
    const requestType = 'GET_DISABILITY_QUOTE';

    dispatch(setRequestInProcess(true, requestType));

    try {
      const {
        data: { basicPrice, premiumPrice },
      } = await endpoint.createDisabilityQuote({
        ...quotesDto,
        persistQuote: false,
      });

      dispatch(
        storeGenericQuestionnaireAnswer('disability', {
          calculatorQuote: {
            basicPrice,
            premiumPrice,
          },
        })
      );

      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

// Gets a quote and persists the quote and questionnaire
export const createDisabilityQuoteAndQuestionnaire =
  (
    answers: DisabilityQuestionnaireAnswers,
    labels: DisabilityQuestionnaireLabels
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'disability'> | RequestAction
  > =>
  async (dispatch) => {
    const requestType = 'CREATE_QUOTE';

    dispatch(setRequestInProcess(true, requestType));

    const quotesDto: DisabilityNotPersistedQuotesDto = {
      dateOfBirth: answers.personalInfo.dateOfBirth,
      officeWork: answers.personalInfo.officeWork,
      degree: answers.personalInfo.degree,
      monthlyPayout: answers.tariffInfo.monthlyPayout,
      heightInCm: answers.personalInfo.height.inCm,
      weightInKg: answers.personalInfo.weight.inKg,
    };

    try {
      const {
        data: { jobCollar },
      } = await endpoint.createDisabilityQuote({
        ...quotesDto,
        persistQuote: false,
      });

      const {
        data: { id: questionnaireId },
      } = await endpoint.createDisabilityQuestionnaire(
        {
          ...answers,
          personalInfo: { ...answers.personalInfo, jobCollar },
          squarelifePersonalInfo: {
            origin: 0,
            tariff: answers.tariffInfo.addOns.premiumCoverage
              ? 'featherBuPremium'
              : 'featherBuBasic',
            bmi: {
              height: answers.personalInfo.height.inCm,
              weight: answers.personalInfo.weight.inKg,
            },
            coverage: answers.tariffInfo.monthlyPayout,
            end_age: 65,
            job_collar: jobCollar,
          },
        },
        labels
      );

      const {
        data: { id: quoteId, basicPrice, premiumPrice },
      } = await endpoint.createDisabilityQuote({
        ...quotesDto,
        persistQuote: true,
        premiumCoverage: answers.tariffInfo.addOns.premiumCoverage,
        questionnaireId,
      });

      const price = answers.tariffInfo.addOns.premiumCoverage
        ? premiumPrice
        : basicPrice;

      dispatch(
        storeGenericQuestionnaireAnswer('disability', {
          quote: {
            questionnaireId,
            quoteId,
            price,
          },
          addOns: {
            premiumCoverage: answers.tariffInfo.addOns.premiumCoverage,
          },
        })
      );

      dispatch(setRequestInProcess(false, requestType));

      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      // eslint-disable-next-line no-console
      console.error(error);
      return { status: 'ERROR' };
    }
  };
