import { cancelPolicy, markPolicyDroppedOut } from 'actions/policies';
import { RequestAction } from 'constants/actions';
import CancelPolicyDetail from 'features/policySingle/components/legacy/modals/cancel';
import { PolicyCancellationReason } from 'models/policies';
import { connect } from 'react-redux';
import { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { getPolicyDetail } from 'selectors/policies';
import {
  getRequestErrorMessage,
  getRequestInProcess,
} from 'selectors/requests';

import { DispatchProps, OwnProps, StateProps } from './types';

const mapStateToProps = (state: AppState, { id }: OwnProps) => {
  const requestType = 'CANCEL_POLICY';
  const policy = getPolicyDetail(state, id);

  if (!policy) {
    throw Error(`[DETAIL][CANCELING] Can’t find policy with id: ${id}`);
  }

  const { type } = policy;

  return {
    id,
    type,
    activeUntil: policy?.attributes.activeUntil,
    policyNumber: policy?.attributes.policyNumber,
    providerName: policy.providerName,
    policyStatus: policy.status,
    startDate: policy?.attributes.startDate,
    createdAt: policy.createdAt,
    isCancelling: getRequestInProcess(state, requestType),
    cancellingError: getRequestErrorMessage(state, requestType),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, Record<string, unknown>, RequestAction>,
  { id }: OwnProps
) => {
  return {
    onCancelPolicy: (
      reason?: PolicyCancellationReason,
      additionalInfo?: string,
      onSuccessMessage?: string
    ) => dispatch(cancelPolicy(id, reason, additionalInfo, onSuccessMessage)),
    onMarkPolicyDroppedOut: () => dispatch(markPolicyDroppedOut(id)),
  };
};

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(CancelPolicyDetail);
