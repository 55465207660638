import { ArrowRightIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';

import styles from './style.module.scss';

interface Icon {
  src: string;
  alt: string;
}

export type CardProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  leftIcon?: Icon;
  rightIcon?: 'arrow' | Icon;
} & Omit<JSX.IntrinsicElements['div'], 'title'>;

export default ({
  className = '',
  title,
  children,
  leftIcon,
  rightIcon,
  ...props
}: CardProps) => {
  return (
    <span
      className={classNames(
        'd-flex fd-column ai-center p24 ds-card--actionable',
        className
      )}
      {...props}
    >
      <span className="d-flex fd-row ai-center">
        {leftIcon && (
          <img
            className="mr24"
            width="48px"
            height="48px"
            src={leftIcon.src}
            alt={leftIcon.alt}
          />
        )}
        <span className="p-h4">{title}</span>
        {rightIcon && (
          <>
            {rightIcon === 'arrow' ? (
              <ArrowRightIcon color="purple-500" size={24} />
            ) : (
              <img
                className={styles.rightIcon}
                width="24px"
                height="24px"
                src={rightIcon.src}
                alt={rightIcon.alt}
              />
            )}
          </>
        )}
      </span>
      <p className="tc-grey-600 p-p mt16">{children}</p>
    </span>
  );
};
