const SVNRFormatter = (svnr: string) => {
  const trimmedSvnr = svnr.replace(/\W+/g, '');

  if (trimmedSvnr.length !== 12) {
    return svnr;
  }

  const location = trimmedSvnr.substring(0, 2);
  const dateOfBirth = trimmedSvnr.substring(2, 8);
  const lastName = trimmedSvnr.substring(8, 9).toUpperCase();
  const checkDigits = trimmedSvnr.substring(9);

  return `${location} ${dateOfBirth} ${lastName} ${checkDigits}`;
};

export default SVNRFormatter;
