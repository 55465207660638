import { faq } from '@getpopsure/private-constants';
import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { Button } from '@popsure/dirty-swan';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import { useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import { IconCard } from '../../../../components/IconCard';
import styles from './GettingStarted.module.scss';
import checkDoc from './icons/checkDoc.svg';
import creditCardIcon from './icons/creditCard.svg';
import moneyIcon from './icons/money.svg';

export const GettingStarted = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  setValidForSubmission,
}: QuestionnaireFormProps<Questionnaire, boolean>) => {
  const { policyId } = useParams<{ policyId: string }>();
  const { location } = useHistory<{ from?: string }>();

  const { t } = useSafeTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    setValidForSubmission(true);
  }, [setValidForSubmission]);

  const handleSubmit = () => {
    onSubmitValue(true);
  };

  return (
    <div className={`mb80 ${styles.animatedContainer}`}>
      <Header onSubmit={handleSubmit}>
        <div className="p-body">
          <div className="w100 ai-center d-flex">
            <NavButton
              title={t(
                'claims.expatSpain.gettingStarted.navButton.title',
                'Back'
              )}
              path={
                location.state?.from !== 'policy'
                  ? routes.claims.create.path
                  : generatePath(routes.me.policies.detail.path, { policyId })
              }
            />
          </div>
          <div>
            <div className="my16">
              <h1 className="p-h1">
                {t('claims.expatSpain.gettingStarted.title', 'Getting started')}
              </h1>
              <p className="p-p mt16">
                {t(
                  'claims.expatSpain.gettingStarted.description',
                  'Here are a few useful tips to consider when making a claim:'
                )}
              </p>
            </div>
            <div className={styles.cardContainer}>
              <IconCard
                title={t(
                  'claims.expatSpain.gettingStarted.payInvoice.title',
                  'Pay your invoice as soon as possible'
                )}
                iconSrc={creditCardIcon}
                iconAlt="credit card"
                className="bs-sm bg-white px24 py24 br8 wmx8"
              >
                {t(
                  'claims.expatSpain.gettingStarted.payInvoice.description',
                  'We recommend that you pay your invoices quickly to **avoid late fees** while we review your claim.'
                )}
              </IconCard>
              <IconCard
                title={t(
                  'claims.expatSpain.gettingStarted.reimbursement.title',
                  'Reimbursement amount'
                )}
                iconSrc={moneyIcon}
                iconAlt="euro coins"
                className="bs-sm bg-white px24 py24 br8 wmx8"
              >
                {t(
                  'claims.expatSpain.gettingStarted.reimbursement.description',
                  `Keep in mind you do not have any co-pays or a deductible with your policy. You will be reimbursed according to [terms](https://docs.google.com/document/d/1EZFViruCvlkCRodyAvqXhM2AFMv-3vy25X-AJWde6Vk/edit?usp=sharing) of your Expat health insurance plan. Remember that health conditions that started before the policy began are typically not covered.`
                )}
              </IconCard>

              <IconCard
                title={t(
                  'claims.expatSpain.gettingStarted.checkGuide.title',
                  'Check our claims guide'
                )}
                iconSrc={checkDoc}
                iconAlt="euro coins"
                className="bs-sm bg-white px24 py24 br8 wmx8"
              >
                {t(
                  'claims.expatSpain.gettingStarted.checkGuide.description',
                  `We explain how to use your expat health insurance and submit claims in [our guide]({{faqExpatGuide}}).`,
                  { faqExpatGuide: faq.expatEsHealthInsurance }
                )}
              </IconCard>
            </div>
          </div>
          <Button
            className="wmx5 wmn3 mt24 mr16 mb8"
            onClick={() => setValidForSubmission(true)}
          >
            {t('claims.expatSpain.gettingStarted.button.text', 'Get Started')}
          </Button>
        </div>
      </Header>
    </div>
  );
};
