import { TFunction } from '@getpopsure/i18n-react';
import dayjs from 'dayjs';
import { PrivateHealthClaimsAmount as Amount } from 'features/privateHealthClaims/components/Amount';
import { PrivateHealthClaimsGettingStarted as GettingStarted } from 'features/privateHealthClaims/components/GettingStarted';
import {
  claimTypeMapping,
  PrivateHealthClaims,
  PrivateHealthClaimsMetaData,
  QuestionId,
  Questionnaire,
} from 'features/privateHealthClaims/models';

import { PrivateHealthClaimsUploadDocuments as UploadDocuments } from '../components/UploadDocuments';

const currentYear = dayjs().year();
const yearLookup: Record<string, string> = {
  currentYear: String(currentYear),
  oneYearAgo: String(currentYear - 1),
  twoYearsAgo: String(currentYear - 2),
};

export const translatedQuestionnaire = (
  t: TFunction,
  metaData: PrivateHealthClaimsMetaData
): Questionnaire => ({
  intro: {
    required: true,
    type: 'CUSTOM',
    component: GettingStarted,
    metaData: {
      policyId: metaData.policyId,
    },
    retrieveAnswerObject: (
      answer: PrivateHealthClaims['intro']
    ): Partial<PrivateHealthClaims> => ({
      intro: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'claimTypes',
    groupId: 'questionnaire',
    questionId: 'intro',
    requiresAuth: true,
  },

  claimTypes: {
    required: true,
    type: 'CHECKBOX',
    props: {
      question: t(
        'claims.privateHealth.questionnaire.claimTypes.question',
        'Select a claim type'
      ),
      mapValue: claimTypeMapping(t),
      showNotification: (checkboxValues) => {
        for (const checkBoxValue of checkboxValues) {
          if (
            [
              'SPECIALIST_VISIT',
              'PHYSICAL_OR_SPEECH_THERAPY',
              'NON_MEDICAL_PROFESSIONAL',
            ].includes(checkBoxValue)
          ) {
            return (
              <div className="p-notice--info mt16">
                {t(
                  'claims.privateHealth.questionnaire.claimTypes.notice',
                  "Don't forget to upload the referral to get fully reimbursed."
                )}
              </div>
            );
          }
        }
        return null;
      },
    },
    retrieveAnswerObject: (
      answer: PrivateHealthClaims['claimTypes']
    ): Partial<PrivateHealthClaims> => ({
      claimTypes: answer,
    }),
    retrieveNextPageId: (): QuestionId => 'uploadedDocumentTokens',
    groupId: 'questionnaire',
    questionId: 'claimTypes',
    requiresAuth: true,
  },

  uploadedDocumentTokens: {
    required: true,
    type: 'CUSTOM',
    component: UploadDocuments,
    metaData: { policyId: metaData.policyId },
    retrieveAnswerObject: (
      answer: PrivateHealthClaims['uploadedDocumentTokens']
    ): Partial<PrivateHealthClaims> => ({
      uploadedDocumentTokens: answer,
    }),
    retrieveNextPageId: () => 'year',
    groupId: 'questionnaire',
    questionId: 'uploadedDocumentTokens',
    requiresAuth: true,
  },
  year: {
    required: true,
    type: 'RADIO',
    props: {
      question: t(
        'claims.privateHealth.questionnaire.year.question',
        'Which year is this claim for?'
      ),
      mapValue: yearLookup,
    },
    groupId: 'questionnaire',
    questionId: 'year',
    retrieveAnswerObject: (
      year: PrivateHealthClaims['year']
    ): Partial<PrivateHealthClaims> => ({
      year: yearLookup[year],
    }),
    retrieveNextPageId: () => 'amount',
    requiresAuth: true,
  },

  amount: {
    required: true,
    type: 'CUSTOM',
    component: Amount,
    metaData: { policyId: metaData.policyId },
    retrieveAnswerObject: (
      answer: PrivateHealthClaims['amount']
    ): Partial<PrivateHealthClaims> => ({
      amount: answer,
    }),
    retrieveNextPageId: () => 'processing',
    groupId: 'questionnaire',
    questionId: 'amount',
    requiresAuth: true,
  },
});
