import {
  BottomOrRegularModal,
  Button,
  MultiDropzone,
} from '@popsure/dirty-swan';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';
import { UploadedFile } from 'shared/models/types';

import styles from './ProofOfEnrollmentModal.module.scss';

interface ProofOfEnrollmentModalProps {
  handleFileSelect: (files: File[]) => void;
  onSubmit: () => void;
  uploadedFile: Array<UploadedFile>;
  handleRemoveFile: () => void;
  loading: boolean;
  error?: string;
  validFile: boolean;
  onClose: () => void;
}

export const ProofOfEnrollmentModal = (props: ProofOfEnrollmentModalProps) => {
  const {
    handleFileSelect,
    onSubmit,
    uploadedFile,
    handleRemoveFile,
    loading,
    error,
    validFile,
    onClose,
  } = props;
  const { t } = useSafeTranslation();
  return (
    <BottomOrRegularModal
      title={t(
        'studentChecklist.proofOfEnrollment.uploadModal.title',
        'Upload your Immatrikulationsbescheinigung'
      )}
      isOpen
      onClose={onClose}
    >
      <div className={styles.dropzoneWrapper}>
        <MultiDropzone
          onFileSelect={handleFileSelect}
          uploadedFiles={uploadedFile}
          uploading={false}
          onRemoveFile={handleRemoveFile}
          maxFiles={1}
        />
        <div className={styles.container}>
          <AnimateHeight duration={300} height={error ? 'auto' : 0}>
            <div className="p-notice p-notice--danger mt16 w100">{error}</div>
          </AnimateHeight>
          <Button
            data-testid="submit-proof-of-enrollment"
            className="w100 wmx3 mt16"
            onClick={onSubmit}
            disabled={!validFile}
            loading={loading}
          >
            {t(
              'studentChecklist.proofOfEnrollment.uploadModal.button.submit',
              'Submit'
            )}
          </Button>
        </div>
      </div>
    </BottomOrRegularModal>
  );
};
