import {
  flushPrivateHealthClaimsAnswers,
  submitClaim,
} from 'features/privateHealthClaims/actions';
import {
  PrivateHealthClaims,
  PrivateHealthClaimsDispatch,
  PrivateHealthClaimsSubmitInfo,
  ZPrivateHealthClaimsSubmissionSchema,
} from 'features/privateHealthClaims/models';
import { getPrivateHealthClaims } from 'features/privateHealthClaims/selectors';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import { privateHealthClaimsRoutes } from '../../privateHealthClaims.routes';
import { PrivateHealthClaimsAmountView } from './Amount.view';

const MIN_AMOUNT = 0.01;

export const PrivateHealthClaimsAmount = ({
  value,
  metaData: { policyId },
}: CustomComponentProps<PrivateHealthClaims>) => {
  const { t } = useSafeTranslation();
  const history = useHistory();

  const [amount, setAmount] = useState<number | undefined>(
    (value as number) ?? undefined
  );

  const { loading, error } = useRequestStatus('CREATE_CLAIM');
  const dispatch = useDispatch<PrivateHealthClaimsDispatch>();

  const questionnaire = useSelector(getPrivateHealthClaims);

  const isSubmissionValid =
    Boolean(amount) && Number(amount) >= MIN_AMOUNT && !loading;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const validation = ZPrivateHealthClaimsSubmissionSchema.safeParse({
      ...questionnaire,
      amount,
    });

    if (!validation.success) {
      throw new Error(
        `[Private Health Claims] Validation error for policy number: ${policyId} - ${String(
          validation.error
        )}`
      );
    }

    const {
      claimTypes,
      uploadedDocumentTokens,
      amount: validatedClaimAmount,
      year,
    } = validation.data;

    const submittableClaimTypes = claimTypes.map((type) => ({
      claimType: type,
    }));
    const tokens = uploadedDocumentTokens.flatMap(({ token }) =>
      token !== undefined ? [token] : []
    );

    const submittableInfo: PrivateHealthClaimsSubmitInfo = {
      type: 'PRIVATE_HEALTH',
      userPolicyId: policyId,
      uploadedDocumentTokens: tokens,
      claimTypes: submittableClaimTypes,
      amount: validatedClaimAmount,
      year,
    };

    const response = await dispatch(submitClaim(submittableInfo));

    if (response === 'SUCCESS') {
      history.replace(
        generatePath(privateHealthClaimsRoutes.submitted.path, { policyId })
      );
      dispatch(flushPrivateHealthClaimsAnswers());
    }
  };

  return (
    <PrivateHealthClaimsAmountView
      handleSubmit={handleSubmit}
      t={t}
      loading={loading}
      error={error?.message}
      amount={amount}
      setAmount={setAmount}
      validForSubmission={isSubmissionValid}
      minAmount={MIN_AMOUNT}
    />
  );
};
