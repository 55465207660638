import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';

import planIcon from './assets/plan.svg';
import styles from './FrozenFooter.module.scss';

export const FrozenFooter = ({
  caption,
  price,
  onContinueClick,
}: {
  caption: string;
  price: number;
  onContinueClick: () => void;
}) => (
  <footer
    className={classNames(
      'w100 d-flex jc-center ai-center bg-primary-50',
      styles.fixedPriceBar
    )}
  >
    <div
      className={classNames(
        'p-container jc-between ai-center ws12',
        styles.desktopSticky
      )}
    >
      <div className="d-flex ai-center gap8">
        <img src={planIcon} alt="" />
        <div className="d-flex ai-baseline gap8">
          <h3 className="p-h3">{caption}</h3>
        </div>
      </div>
      <div className="d-flex ai-center gap16">
        <p className="p--serif p-p--small tc-primary-500">
          <span className="p-h2 tc-primary-500">
            {englishFormattedEuroCurrency(price, true)}
          </span>
          /mo
        </p>
        <Button
          type="submit"
          className="ws3"
          variant="filledColor"
          onClick={onContinueClick}
          data-cy="button-continue"
        >
          Continue
        </Button>
      </div>
    </div>
    <div
      className={classNames(
        'p-container ai-center jc-between gap16 w100',
        styles.mobileSticky
      )}
    >
      <div className="d-flex ai-center gap16">
        <img src={planIcon} alt="" />
        <div>
          <h3 className="p-h3">{caption}</h3>
          <p className="p--serif p-p--small tc-primary-500">
            <span className="p-h2 tc-primary-500">
              {englishFormattedEuroCurrency(price, true)}
            </span>
            /mo
          </p>
        </div>
      </div>
      <Button
        type="submit"
        className="ws2"
        variant="filledColor"
        onClick={onContinueClick}
      >
        Continue
      </Button>
    </div>
  </footer>
);
