import classNames from 'classnames';

import styles from './QuotePage.module.scss';

export const SelectCard = ({
  title,
  price,
  description,
  isChecked,
  onChange,
}: {
  title: string;
  price: string;
  description: string;
  isChecked: boolean;
  onChange: () => void;
}) => {
  return (
    <div
      onClick={onChange}
      className={classNames(
        'ws5 d-flex fd-column bg-white ai-center br-16 mt16 jc-between ds-card--actionable',
        {
          [styles.btnContainer]: isChecked,
        }
      )}
      role="button"
      onKeyDown={onChange}
      tabIndex={0}
    >
      <div className="d-flex fd-column ai-center mt24">
        <div className="p-h4">{title}</div>
        <div className="mt16 p--serif p-h1--xl tc-purple-500">{price}</div>
        <div className="p-p--small tc-grey-500">per month</div>
        <div className="p-p mt16 tc-grey-600 ai-center jc-center ta-center ws4">
          {description}
        </div>
      </div>
      <div
        className={classNames(
          'p-label-container p16 ai-center jc-center mt16 mb24',
          styles.radioBtn
        )}
      >
        <input
          id={`option${title}`}
          className="p-radio"
          type="radio"
          checked={isChecked}
          onChange={onChange}
        />
        <label htmlFor={`option${title}`} className="p-label ml16">
          <span className="sr-only">Select option</span>
        </label>
      </div>
    </div>
  );
};
