import { PaymentMethod } from 'models/paymentMethods';
import { AppState } from 'reducers';

export const getPaymentMethods = (state: AppState): PaymentMethod[] => {
  const { items } = state.paymentMethods;

  if (!items) {
    return [];
  }

  return Object.keys(items).map((key) => {
    return items[key];
  });
};

export const getDefaultPaymentMethod = (
  state: AppState
): PaymentMethod | undefined => {
  return getPaymentMethods(state).find((pm) => pm.isDefault);
};

export const getNonDefaultPaymentMethods = (
  state: AppState
): PaymentMethod[] => {
  return getPaymentMethods(state).filter((pm) => !pm.isDefault);
};
