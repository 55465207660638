import { AutoSuggestInput } from '@popsure/dirty-swan';
import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, useState } from 'react';
import { fuzzySearchOptions } from 'shared/util/fuzzySearchOptions';

import QuestionnaireWrapper from '../QuestionnaireWrapper';

interface Option<ValueType> {
  value: ValueType;
  label: string;
}

interface Props<ValueType>
  extends QuestionnaireFormProps<Option<ValueType>>,
    QuestionnaireWrapperProps {
  options: Option<ValueType>[];
}

export const DropdownSingleForm = <ValueType extends string>({
  options,
  value,
  question,
  description,
  onSubmitValue,
  buttonText,
  header,
  loading,
  featureName,
  error,
  additionalInfo,
  placeholder = '',
  secondaryAction,
}: Props<ValueType>) => {
  const [answer, setAnswer] = useState<Option<ValueType> | undefined>(value);
  const [currentOption, setCurrentOption] = useState<ValueType | string>(
    answer?.label ?? ''
  );
  const [suggestions, setSuggestions] = useState<Option<ValueType>[]>([]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!answer) {
      throw new Error(
        `[${featureName}] Dropdown value is missing for submission on question: ${question}`
      );
    }
    onSubmitValue(answer);
  };

  return (
    <QuestionnaireWrapper
      header={header}
      question={question}
      description={description}
      onSubmit={handleSubmit}
      error={error}
      loading={loading}
      buttonText={buttonText}
      validForSubmission={!!answer}
      additionalInfo={additionalInfo}
      secondaryAction={secondaryAction}
    >
      <AutoSuggestInput
        currentOption={currentOption}
        placeholder={placeholder}
        suggestions={suggestions}
        onChange={(val) => {
          setCurrentOption(val);
          const selectedOption = options.find((option) => option.label === val);
          setAnswer(selectedOption);
        }}
        handleSuggestionSelected={(val) => {
          setCurrentOption(val.value);
          const selectedOption = options.find(
            (option) => option.value === val.value
          );
          setAnswer(selectedOption);
        }}
        handleSuggestionFetchRequest={({ value: val }) => {
          const filteredOptions = fuzzySearchOptions(val, options, 'label');
          setSuggestions(filteredOptions);
        }}
        handleSuggestionClearRequest={() => setSuggestions([])}
      />
    </QuestionnaireWrapper>
  );
};
