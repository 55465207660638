import {
  PrivatePreSignup,
  PrivatePreSignupQuestionnaire,
} from 'features/privateHealthPreSignup/models';
import { shouldShowDependentVersion } from 'features/privateHealthPreSignup/util';

import { CalculatingPrice } from './CalculatingPrice';
import { FamilyCoverageBlocker } from './FamilyCoverageBlocker';
import { PlanReview } from './PlanReview/PlanReview';
import { Quote } from './Quote';
import { RedirectToPrivateSignup } from './RedirectToPrivateSignup';

export const quoteAndAddonsQNR = (): PrivatePreSignupQuestionnaire => ({
  dependentSignupBlocker: {
    required: (questionnaireAnswers: Partial<PrivatePreSignup>) =>
      shouldShowDependentVersion(questionnaireAnswers),
    type: 'CUSTOM',
    component: FamilyCoverageBlocker,
    groupId: 'signup',
  },
  calculatePrice: {
    required: (questionnaireAnswers: Partial<PrivatePreSignup>) =>
      !shouldShowDependentVersion(questionnaireAnswers),
    type: 'CUSTOM',
    groupId: 'signup',
    component: CalculatingPrice,
    retrieveAnswerObject: (
      calculatePrice: PrivatePreSignup['calculatePrice']
    ): Partial<PrivatePreSignup> => ({
      calculatePrice,
    }),
  },
  quote: {
    required: true,
    type: 'CUSTOM',
    groupId: 'signup',
    component: Quote,
    retrieveAnswerObject: (
      quote: PrivatePreSignup['quote']
    ): Partial<PrivatePreSignup> => ({
      quote,
    }),
  },
  planReview: {
    required: true,
    type: 'CUSTOM',
    groupId: 'signup',
    component: PlanReview,
    retrieveAnswerObject: (
      planReview: PrivatePreSignup['planReview']
    ): Partial<PrivatePreSignup> => ({
      planReview,
    }),
  },
  redirectToPrivateSignup: {
    required: true,
    type: 'CUSTOM',
    groupId: 'signup',
    component: RedirectToPrivateSignup,
    retrieveAnswerObject: (
      redirectToPrivateSignup: PrivatePreSignup['redirectToPrivateSignup']
    ): Partial<PrivatePreSignup> => ({
      redirectToPrivateSignup,
    }),
  },
});
