import { flushGenericQuestionnaire } from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import { UserRegistrationInfo } from 'models/genericQuestionnaire';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';

export const EndQuestionnaireRedirect = ({
  value,
}: CustomComponentProps<UserRegistrationInfo>) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // User created an account at this point, we can flush the questionnaire
    dispatch(flushGenericQuestionnaire('userCreation'));
  }, [dispatch]);

  return <Redirect to={(value as string) ?? routes.me.policies.path} />;
};
