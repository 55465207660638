import { trackStructuredEvent } from '@getpopsure/analytics';
import { Button } from '@popsure/dirty-swan';
import { NavButton } from 'components/NavButton';
import { TallyModal } from 'components/TallyModal/TallyModal';
import routes from 'constants/routes';
import { CategoryLookup } from 'features/recommendationTool/models/lookups';
import {
  RecommendationData,
  VerticalCategory,
} from 'features/recommendationTool/models/recommendation';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentRecommendation } from 'selectors/recommendationTool';
import { useSafeTranslation } from 'shared/i18n';

import { HeaderCard } from '../HeaderCard';
import { RecommendationSection } from '../RecommendationSection';
import styles from './RecommendationPage.module.scss';

export const RecommendationPage = ({
  recommendationData,
  category,
  onEditQuestionnaire,
  lookup,
}: {
  recommendationData: RecommendationData;
  category: VerticalCategory;
  onEditQuestionnaire(): void;
  lookup: CategoryLookup;
}) => {
  const { t } = useSafeTranslation();

  const [isSurveyFormVisible, setIsSurveyFormVisible] = useState(false);
  const toggleSurveyVisibility = () =>
    setIsSurveyFormVisible(!isSurveyFormVisible);
  const recommendation = useSelector(getCurrentRecommendation);
  const URLparams = {
    snapshotId: recommendation?.snapshotId,
    vertical: category,
  };

  const handleEditQuestionnaire = () => {
    trackStructuredEvent({
      category: 'RECOMMENDATION_TOOL',
      action: 'CLICKED_EDIT_QUESTIONNAIRE',
    });
    onEditQuestionnaire();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="d-flex fd-column ai-center"
      data-testid="recommendation-wrapper"
    >
      <div className="p-body">
        <NavButton
          tall={false}
          data-cy="button-all-recommendations"
          path={routes.insuranceTool.path}
          title={t(
            'recommendationtool.recommendations.backToOverview',
            'All recommendations'
          )}
        />
      </div>

      <div
        className={styles.hero}
        style={{ backgroundColor: lookup.bannerColor }}
      />

      <div className={styles.shiftup}>
        <HeaderCard
          lookup={lookup}
          onEditQuestionnaire={handleEditQuestionnaire}
        />

        <RecommendationSection
          category={category}
          recommendationData={recommendationData}
        />
        <div className={styles.survey}>
          <Button variant="textColor" onClick={toggleSurveyVisibility}>
            Was this recommendation helpful?
          </Button>
          {isSurveyFormVisible && (
            <TallyModal
              tallyId="3yJoNx"
              URLparams={URLparams}
              onClose={toggleSurveyVisibility}
              isVisible
              title="Recommendation Survey"
            />
          )}
        </div>
      </div>
    </div>
  );
};
