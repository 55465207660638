import { TFunction } from '@getpopsure/i18n-react';
import { calendly } from '@getpopsure/private-constants';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';

import { createPostQuote, reviewAction } from '../actions';
import { GetStarted } from '../components/GetStarted/GetStarted';
import { InfoScreen } from '../components/InfoScreen/InfoScreen';
import { InvestmentSustainability } from '../components/InvestmentApproach/InvestmentSustainability';
import { LegalDisclaimer } from '../components/LegalDisclaimer/LegalDisclaimer';
import { PaymentDetails } from '../components/PaymentDetails/PaymentDetails';
import { Quote } from '../components/Quote';
import { SubmitApplication } from '../components/SubmitApplication/SubmitApplication';
import { questionPropLookup } from '../lookups';
import {
  identificationDocumentMapping,
  incomeSourceMapping,
  InvestmentStrategyMap,
  InvestmentSustainabilityMap,
  occupationMapping,
  paysTaxesInGermanyMapping,
  Pension,
  PensionGroupIds,
} from '../models';

export const PensionComponents = {
  GET_STARTED: GetStarted,
  INFO_SCREEN: InfoScreen,
  QUOTE: Quote,
  LEGAL_DISCLAIMER: LegalDisclaimer,
  SUBMIT_APPLICATION: SubmitApplication,
  PAYMENT_DETAILS: PaymentDetails,
  INVESTMENT_SUSTAINABILITY: InvestmentSustainability,
};

export type PensionQuestionnaire = SignupQuestionnaireType<
  Pension,
  PensionGroupIds,
  typeof PensionComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction
): PensionQuestionnaire => {
  const {
    investmentStrategy,
    savingForRetirement,
    privatePensionLongerThan5Years,
    email,
    name,
    dateOfBirth,
    townOfBirth,
    address,
    taxResidency,
    citizenship,
    identificationDocument,
    uploadIdCard,
    uploadPassport,
    idCardIssueDate,
    idCardExpiryDate,
    passportIssueDate,
    passportExpiryDate,
    isSmoker,
    occupation,
    paysTaxesInGermany,
    income,
    incomeSource,
    jobTitle,
    taxId,
  } = questionPropLookup(t);

  return [
    {
      id: 'quote',
      required: true,
      type: 'QUOTE',
      props: {},
      screen: {
        layout: 'Standalone',
      },
      groupId: 'signup',
    },
    {
      id: 'intro',
      groupId: 'signup',
      type: 'GET_STARTED',
      required: true,
      props: {},
      screen: {
        layout: 'Standalone',
      },
    },
    // Investment strategy
    {
      id: 'investmentSustainability',
      groupId: 'signup',
      type: 'INVESTMENT_SUSTAINABILITY',
      required: true,
      screen: {
        // ...investmentSustainability,
        layout: 'Standalone',
      },
      props: {
        // mapValue: InvestmentSustainabilityMap(t),
      },
      validations: [
        {
          op: 'Custom',
          fn: (answer: unknown) => {
            return answer !== 'GREEN_INVESTING';
          },
          msg: () => {
            return {
              type: 'Error',
              msg: t(
                'pension.qnr.investmentStrategy.errorMessage',
                "We can't offer a 100% green product with low fees. If you're interested in a 100% green product, [please schedule a call]({{calendlyUrl}}).",
                {
                  calendlyUrl: calendly.pensionInsurance,
                }
              ),
            };
          },
        },
      ],
    },
    {
      id: 'investmentStrategy',
      groupId: 'signup',
      type: 'RADIO',
      required: true,
      screen: {
        ...investmentStrategy,
      },
      props: {
        mapValue: InvestmentStrategyMap(t),
      },
    },
    {
      id: 'savingForRetirement',
      groupId: 'signup',
      type: 'BOOLEAN',
      required: true,
      screen: {
        ...savingForRetirement,
      },
      props: {},
    },
    {
      id: 'privatePensionInfo',
      groupId: 'signup',
      type: 'INFO_SCREEN',
      screen: {
        layout: 'Standalone',
      },
      props: {
        title: t(
          'pension.qnr.privatePensionInfo.title',
          'We recommend private pension insurance only if you plan to use it for your retirement.'
        ),
      },
    },
    {
      id: 'privatePensionLongerThan5Years',
      groupId: 'signup',
      required: true,
      type: 'BOOLEAN',
      screen: {
        ...privatePensionLongerThan5Years,
      },
      props: {},
    },
    {
      id: 'privatePensionLongerThan5YearsInfo',
      groupId: 'signup',
      type: 'INFO_SCREEN',
      screen: {
        layout: 'Standalone',
      },
      props: {
        title: t(
          'pension.qnr.privatePensionLongerThan5YearsInfo.title',
          'We recommend private pension insurance only if you plan to keep for more than 5 years.'
        ),
      },
    },
    {
      id: 'hasReviewedInvestmentStrategyInfo',
      required: true,
      type: 'REVIEW',
      groupId: 'signup',
      props: {
        title: t(
          'pension.qnr.hasReviewedInvestmentStrategyInfo.title',
          'Review your details'
        ),
        isDescriptionVisible: false,
        requestType: 'REVIEW_SUBMIT_INFO',
        reviewValues: [
          {
            title: t(
              'pension.qnr.hasReviewedInvestmentStrategyInfo.investmentSustainability.title',
              'Investment approach'
            ),
            value: {
              type: 'String',
              key: 'investmentSustainability',
              valueMap: InvestmentSustainabilityMap(t),
            },
            path: 'investmentSustainability',
          },
          {
            title: t(
              'pension.qnr.hasReviewedInvestmentStrategyInfo.investmentStrategy.title',
              'Investment strategy'
            ),
            value: {
              type: 'String',
              key: 'investmentStrategy',
              valueMap: {
                ALL_IN: 'All-in',
                AGGRESSIVE: 'Aggressive',
              },
            },
            path: 'investmentStrategy',
          },
          {
            title: t(
              'pension.qnr.hasReviewedInvestmentStrategyInfo.savingForRetirement.title',
              'Use this product for retirement?'
            ),
            value: { type: 'Boolean', key: 'savingForRetirement' },
            path: 'savingForRetirement',
          },
          {
            title: t(
              'pension.qnr.hasReviewedInvestmentStrategyInfo.privatePensionInfo.title',
              'Confirm you wish to continue?'
            ),
            value: {
              type: 'Boolean',
              key: 'privatePensionInfo',
              valueMap: {
                true: 'I confirm',
                false: 'I do not confirm',
              },
            },
            path: 'privatePensionInfo',
          },
          {
            title: t(
              'pension.qnr.hasReviewedInvestmentStrategyInfo.privatePensionLongerThan5Years.title',
              'Keep for more than 5 years?'
            ),
            value: { type: 'Boolean', key: 'privatePensionLongerThan5Years' },
            path: 'privatePensionLongerThan5Years',
          },
          {
            title: t(
              'pension.qnr.hasReviewedInvestmentStrategyInfo.privatePensionLongerThan5YearsInfo.title',
              'Confirm you wish to continue?'
            ),
            value: {
              type: 'Boolean',
              key: 'privatePensionLongerThan5YearsInfo',
              valueMap: {
                true: 'I confirm',
                false: 'I do not confirm',
              },
            },
            path: 'privatePensionLongerThan5YearsInfo',
          },
        ],
        verticalId: 'pension',
        insuranceType: 'PENSION',
        createPostQuote: reviewAction,
      },
      screen: {
        layout: 'Standalone',
      },
    },
    // Personal info
    {
      id: 'email',
      type: 'EMAIL',
      groupId: 'signup',
      required: true,
      props: {
        verticalId: 'pension',
      },
      screen: {
        ...email,
      },
    },
    {
      id: 'name',
      type: 'NAME',
      groupId: 'signup',
      required: true,
      props: {},
      screen: {
        ...name,
      },
    },
    {
      id: 'dateOfBirth',
      type: 'DATE',
      groupId: 'signup',
      required: true,
      props: {
        yearRange: {
          min: { op: 'subtract', type: 'years', value: 100 },
          max: { op: 'add', type: 'years', value: 0 },
        },
        displayCalendar: true,
      },
      screen: {
        ...dateOfBirth,
      },
    },
    {
      id: 'under18Blocker',
      type: 'BLOCKER',
      groupId: 'signup',
      props: {
        title: t('pension.qnr.under18Blocker.title', "We can't sign you up..."),
        description: t('pension.qnr.under18Blocker.description', {
          defaultValue:
            'We currently only cover people who are over 18 years old. \n\n You can speak to one of our insurance experts if you have any questions.',
        }),
        buttonProps: [
          {
            type: 'href',
            href: calendly.pensionInsurance,
            caption: t(
              'pension.qnr.under18Blocker.speakToExpert.caption',
              'Speak to us'
            ),
          },
        ],
        iconType: 'SHIELD',
      },
      screen: {
        layout: 'Standalone',
      },
    },
    {
      id: 'over50Blocker',
      type: 'BLOCKER',
      groupId: 'signup',
      props: {
        title: t('pension.qnr.over50Blocker.title', "We can't sign you up..."),
        description: t('pension.qnr.over50Blocker.description', {
          defaultValue:
            'We recommend this pension plan to only people who are under 50 years old. \n\n You can speak to one of our insurance experts if you have any questions.',
        }),
        buttonProps: [
          {
            type: 'href',
            href: calendly.pensionInsurance,
            caption: t(
              'pension.qnr.over50Blocker.speakToExpert.caption',
              'Speak to us'
            ),
          },
        ],
        iconType: 'SHIELD',
      },
      screen: {
        layout: 'Standalone',
      },
    },
    {
      id: 'townOfBirth',
      type: 'INPUT',
      groupId: 'signup',
      required: true,
      props: {
        ...townOfBirth,
      },
      screen: {
        ...townOfBirth,
      },
    },
    {
      id: 'address',
      type: 'ADDRESS',
      groupId: 'signup',
      required: true,
      props: {},
      screen: {
        ...address,
      },
    },
    {
      id: 'taxResidency',
      type: 'COUNTRY_MULTI',
      groupId: 'signup',
      required: true,
      props: {},
      screen: {
        ...taxResidency,
      },
    },
    {
      id: 'citizenship',
      type: 'COUNTRY_SINGLE',
      groupId: 'signup',
      required: true,
      props: {},
      screen: {
        ...citizenship,
      },
    },
    {
      id: 'identificationDocument',
      type: 'RADIO',
      groupId: 'signup',
      props: {
        mapValue: identificationDocumentMapping(t),
      },
      screen: {
        ...identificationDocument,
      },
    },
    {
      id: 'uploadIdCard',
      groupId: 'signup',
      type: 'UPLOAD',
      screen: {
        ...uploadIdCard,
      },
      props: {
        isQuestionnaire: true,
      },
    },
    {
      id: 'uploadPassport',
      groupId: 'signup',
      type: 'UPLOAD',
      screen: {
        ...uploadPassport,
      },
      props: {
        isQuestionnaire: true,
      },
    },
    {
      id: 'passportIssueDate',
      type: 'DATE',
      groupId: 'signup',
      props: {
        yearRange: {
          min: { op: 'subtract', type: 'years', value: 100 },
          max: { op: 'add', type: 'years', value: 10 },
        },
        displayCalendar: true,
      },
      screen: {
        ...passportIssueDate,
      },
      validations: [
        {
          op: 'DateIsInPast',
          msg: {
            type: 'Error',
            value: t(
              'pension.qnr.personalInfo.passportIssueDate.error.message',
              'Your passport issue date is in the future. Please provide a valid issue date.'
            ),
          },
        },
      ],
    },
    {
      id: 'passportExpiryDate',
      type: 'DATE',
      groupId: 'signup',
      props: {
        yearRange: {
          min: { op: 'subtract', type: 'years', value: 0 },
          max: { op: 'add', type: 'years', value: 100 },
        },
        displayCalendar: true,
      },
      screen: {
        ...passportExpiryDate,
      },
      validations: [
        {
          op: 'DateIsInFuture',
          msg: {
            type: 'Error',
            value: t(
              'pension.qnr.personalInfo.passportExpiry.error.message',
              'Your passport has expired. Please provide a valid expiry date.'
            ),
          },
        },
      ],
    },
    {
      id: 'idCardIssueDate',
      type: 'DATE',
      groupId: 'signup',
      props: {
        yearRange: {
          min: { op: 'subtract', type: 'years', value: 100 },
          max: { op: 'add', type: 'years', value: 10 },
        },
        displayCalendar: true,
      },
      screen: {
        ...idCardIssueDate,
      },
      validations: [
        {
          op: 'DateIsInPast',
          msg: {
            type: 'Error',
            value: t(
              'pension.qnr.personalInfo.idCardIssueDate.error.message',
              'Your id issue date is in the future. Please provide a valid issue date.'
            ),
          },
        },
      ],
    },
    {
      id: 'idCardExpiryDate',
      type: 'DATE',
      groupId: 'signup',
      props: {
        yearRange: {
          min: { op: 'subtract', type: 'years', value: 0 },
          max: { op: 'add', type: 'years', value: 100 },
        },
        displayCalendar: true,
      },
      screen: {
        ...idCardExpiryDate,
      },
      validations: [
        {
          op: 'DateIsInFuture',
          msg: {
            type: 'Error',
            value: t(
              'pension.qnr.personalInfo.idCardExpiryDate.error.message',
              'Your id has expired. Please provide a valid expiry date.'
            ),
          },
        },
      ],
    },
    {
      id: 'isSmoker',
      required: true,
      groupId: 'signup',
      type: 'BOOLEAN',
      props: {},
      screen: {
        ...isSmoker,
      },
    },
    {
      id: 'hasReviewedPersonalInfo',
      required: true,
      type: 'REVIEW',
      groupId: 'signup',
      props: {
        title: t(
          'pension.qnr.hasReviewedPersonalInfo.title',
          'Review your details'
        ),
        isDescriptionVisible: false,
        requestType: 'REVIEW_SUBMIT_INFO',
        reviewValues: [
          {
            title: t(
              'pension.qnr.hasReviewedPersonalInfo.email.title',
              'Email'
            ),
            value: { type: 'String', key: 'email' },
            path: 'email',
          },
          {
            title: t('pension.qnr.hasReviewedPersonalInfo.name.title', 'Name'),
            value: {
              type: 'Strings',
              key: 'name',
              keys: ['firstName', 'lastName'],
            },
            path: 'name',
          },
          {
            title: t(
              'pension.qnr.hasReviewedPersonalInfo.dateOfBirth.title',
              'Date of birth'
            ),
            value: { type: 'Date', format: 'DD MMM YYYY', key: 'dateOfBirth' },
            path: 'dateOfBirth',
          },
          {
            title: t(
              'pension.qnr.hasReviewedPersonalInfo.townOfBirth.title',
              'Place of birth'
            ),
            value: { type: 'String', key: 'townOfBirth' },
            path: 'townOfBirth',
          },
          {
            title: t(
              'pension.qnr.hasReviewedPersonalInfo.address.title',
              'Address'
            ),
            value: {
              type: 'Strings',
              key: 'address',
              keys: ['street', 'houseNumber', 'postcode', 'city'],
            },
            path: 'address',
          },
          {
            title: t(
              'pension.qnr.hasReviewedPersonalInfo.taxResidency.title',
              'Tax residency'
            ),
            value: { type: 'Array', pick: ['name'], key: 'taxResidency' },
            path: 'taxResidency',
          },
          {
            title: t(
              'pension.qnr.hasReviewedPersonalInfo.citizenship.title',
              'Nationality'
            ),
            value: { type: 'Strings', keys: ['name'], key: 'citizenship' },
            path: 'citizenship',
          },
          {
            title: t(
              'pension.qnr.hasReviewedPersonalInfo.identificationDocument.title',
              'ID type'
            ),
            value: {
              type: 'String',
              key: 'identificationDocument',
              valueMap: {
                PASSPORT: 'Passport',
                NATIONAL_ID: 'National ID',
              },
            },
            path: 'identificationDocument',
          },
          {
            title: t(
              'pension.qnr.signup.hasReviewedPersonalInfo.uploadPassport',
              'Copy of ID or passport'
            ),
            value: {
              type: 'Array',
              key: 'uploadPassport',
              transform: (el) => (el as Record<string, unknown>).name as string,
            },
            path: 'uploadPassport',
          },
          {
            title: t(
              'pension.qnr.signup.hasReviewedPersonalInfo.uploadIdCard',
              'Copy of ID or passport'
            ),
            value: {
              type: 'Array',
              key: 'uploadIdCard',
              transform: (el) => (el as Record<string, unknown>).name as string,
            },
            path: 'uploadIdCard',
          },
          {
            title: t(
              'pension.qnr.hasReviewedPersonalInfo.passportIssueDate.title',
              'Issue date'
            ),
            value: {
              type: 'Date',
              key: 'passportIssueDate',
              format: 'DD MMM YYYY',
            },
            path: 'passportIssueDate',
          },
          {
            title: t(
              'pension.qnr.hasReviewedPersonalInfo.passportExpiryDate.title',
              'Expiration date'
            ),
            value: {
              type: 'Date',
              key: 'passportExpiryDate',
              format: 'DD MMM YYYY',
            },
            path: 'passportExpiryDate',
          },
          {
            title: t(
              'pension.qnr.hasReviewedPersonalInfo.idCardIssueDate.title',
              'Issue date'
            ),
            value: {
              type: 'Date',
              key: 'idCardIssueDate',
              format: 'DD MMM YYYY',
            },
            path: 'idCardIssueDate',
          },
          {
            title: t(
              'pension.qnr.hasReviewedPersonalInfo.idCardExpiryDate.title',
              'Expiration date'
            ),
            value: {
              type: 'Date',
              key: 'idCardExpiryDate',
              format: 'DD MMM YYYY',
            },
            path: 'idCardExpiryDate',
          },
          {
            title: t(
              'pension.qnr.hasReviewedPersonalInfo.isSmoker.title',
              'Smoker?'
            ),
            value: {
              type: 'Boolean',
              key: 'isSmoker',
            },
            path: 'isSmoker',
          },
        ],
        verticalId: 'pension',
        insuranceType: 'PENSION',
        createPostQuote: reviewAction,
      },
      screen: {
        layout: 'Standalone',
      },
    },
    // Financial history
    {
      id: 'occupation',
      type: 'RADIO',
      groupId: 'signup',
      required: true,
      props: {
        mapValue: occupationMapping(t),
      },
      screen: {
        ...occupation,
      },
    },
    {
      id: 'paysTaxesInGermany',
      groupId: 'signup',
      type: 'RADIO',
      screen: {
        ...paysTaxesInGermany,
      },
      props: {
        mapValue: paysTaxesInGermanyMapping(t),
      },
    },
    {
      id: 'paysTaxesInGermanyBlocker',
      type: 'BLOCKER',
      groupId: 'signup',
      props: {
        title: t(
          'pension.qnr.paysTaxesInGermanyBlocker.title',
          "Unfortunately, we can't sign you up"
        ),
        description: t('pension.qnr.paysTaxesInGermanyBlocker.description', {
          defaultValue:
            'We currently only cover people who pay taxes in Germany. \n\n You can speak to one of our insurance experts if you have any questions.',
        }),
        buttonProps: [
          {
            type: 'href',
            href: calendly.pensionInsurance,
            caption: t(
              'pension.qnr.paysTaxesInGermanyBlocker.speakToExpert.caption',
              'Speak to us'
            ),
          },
        ],
        iconType: 'SHIELD',
      },
      screen: {
        layout: 'Standalone',
      },
    },
    {
      id: 'jobTitle',
      groupId: 'signup',
      type: 'INPUT',
      screen: {
        ...jobTitle,
      },
      props: {
        placeholder: t('pension.qnr.jobTitle.input.placeholder', 'Job title'),
      },
    },
    {
      id: 'income',
      groupId: 'signup',
      type: 'CURRENCY',
      required: true,
      screen: {
        ...income,
      },
      props: {
        validate: (value) => value !== undefined,
      },
    },
    {
      id: 'taxId',
      groupId: 'signup',
      type: 'NUMBER',
      required: true,
      screen: {
        ...taxId,
      },
      props: {
        placeholder: t('pension.qnr.taxId.input.placeholder', 'Tax ID'),
      },
    },
    {
      id: 'incomeSource',
      groupId: 'signup',
      required: true,
      type: 'RADIO',
      props: {
        mapValue: incomeSourceMapping(t),
      },
      screen: {
        ...incomeSource,
      },
    },
    {
      id: 'sepa',
      groupId: 'signup',
      type: 'PAYMENT_DETAILS',
      required: true,
      screen: {
        layout: 'Standalone',
      },
      props: {},
    },
    {
      id: 'hasReviewedFinancialHistoryInfo',
      groupId: 'signup',
      required: true,
      type: 'REVIEW',
      props: {
        title: t(
          'pension.qnr.hasReviewedFinancialHistoryInfo.title',
          'Review your details'
        ),
        isDescriptionVisible: false,
        insuranceType: 'PENSION',
        verticalId: 'pension',
        reviewValues: [
          {
            title: t(
              'pension.qnr.hasReviewedFinancialHistory.occupation.title',
              'Employment status'
            ),
            value: {
              type: 'String',
              key: 'occupation',
              valueMap: occupationMapping(t),
            },
            path: 'occupation',
          },
          {
            title: t(
              'pension.qnr.hasReviewedFinancialHistory.paysTaxesInGermany.title',
              'Paying taxes in Germany?'
            ),
            value: {
              type: 'String',
              key: 'paysTaxesInGermany',
              valueMap: {
                YES: 'Yes',
                NO: 'No',
              },
            },
            path: 'paysTaxesInGermany',
          },
          {
            title: t(
              'pension.qnr.hasReviewedFinancialHistory.jobTitle.title',
              'Job title'
            ),
            value: {
              type: 'String',
              key: 'jobTitle',
            },
            path: 'jobTitle',
          },
          {
            title: t(
              'pension.qnr.hasReviewedFinancialHistory.income.title',
              'Income'
            ),
            value: {
              type: 'String',
              key: 'income',
              // TODO: format income to match design
            },
            path: 'income',
          },
          {
            title: t(
              'pension.qnr.hasReviewedFinancialHistory.taxId.title',
              'Tax ID'
            ),
            value: {
              type: 'String',
              key: 'taxId',
            },
            path: 'taxId',
          },
          {
            title: t(
              'pension.qnr.hasReviewedFinancialHistory.incomeSource.title',
              'Source of monthly contributions'
            ),
            value: {
              type: 'String',
              key: 'incomeSource',
              valueMap: {
                INCOME: 'My income',
                SAVINGS: 'Savings',
              },
            },
            path: 'incomeSource',
          },
          {
            title: t(
              'pension.qnr.hasReviewedFinancialHistory.sepa.title',
              "IBAN and bank account holder's name"
            ),
            value: {
              type: 'Strings',
              key: 'sepa',
              keys: ['iban', 'accountHolder'],
            },
            path: 'sepa',
          },
        ],
        requestType: 'REVIEW_SUBMIT_INFO',
        createPostQuote: () => createPostQuote(t),
      },
      screen: {
        layout: 'Standalone',
      },
    },
    {
      id: 'legalDisclaimer',
      groupId: 'signup',
      type: 'LEGAL_DISCLAIMER',
      required: true,
      screen: {
        layout: 'Standalone',
      },
      props: {},
    },
    {
      id: 'submitApplication',
      groupId: 'signup',
      required: true,
      type: 'SUBMIT_APPLICATION',
      props: {},
      screen: {
        layout: 'Standalone',
      },
    },
  ];
};
