import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import LoadingSpinner from 'components/loadingSpinner';
import { useLoadPolicyDetail } from 'features/expat/hooks';
import { ExpatSpainDependent } from 'features/expatSpain/models';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import styles from './DependentsPolicyHolder.module.scss';

type BooleanOptions = [boolean, string][];

export const DependentsPolicyHolder = ({
  value,
  questionnaireAnswers,
  onSubmitValue,
}: QuestionnaireFormProps<ExpatSpainDependent>) => {
  const { t } = useSafeTranslation();

  const [booleanValue, setBooleanValue] = useState<boolean>(
    (value as boolean) ?? false
  );

  const options: BooleanOptions = [
    [
      true,
      t(
        'expatSpain.qnr.dependentSignup.policyHolderGuardianOrParent.yes',
        'Yes'
      ),
    ],
    [
      false,
      t('expatSpain.qnr.dependentSignup.policyHolderGuardianOrParent.no', 'No'),
    ],
  ];

  const { loading, error, policy } = useLoadPolicyDetail(
    questionnaireAnswers.mainPolicyId ?? ''
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    Sentry.captureException(
      '[Expat Spain Dependent]: Failed to fetch main policy holder name'
    );
  }

  const { name } = policy?.attributes.insuredPerson || {};

  const policyHolder =
    name && error === undefined ? `${name?.firstName} ${name?.lastName}` : '-';

  return (
    <div className={classNames('d-flex jc-between f-wrap', styles.container)}>
      <div className="p-body">
        <h1 className="p-h1">
          {t(
            'expatSpain.qnr.dependentSignup.policyHolderGuardianOrParent.title',
            {
              defaultValue:
                'Is {{policyHolder}} their legal guardian / parent?',
              policyHolder,
            }
          )}
        </h1>
        <div
          className={classNames(
            'p-label-container mt16',
            styles.innerContainer
          )}
        >
          {options.map(([mappableValue, label], index) => (
            <div className={index !== 0 ? 'mt8' : ''} key={label}>
              <input
                id={label as string}
                className="p-radio"
                type="radio"
                value={label as string}
                onChange={() => setBooleanValue(mappableValue as boolean)}
                checked={booleanValue === mappableValue}
              />
              <label
                htmlFor={label as string}
                className="p-label p-label--bordered"
                data-cy={`radio-${label}`}
              >
                {label}
              </label>
            </div>
          ))}
        </div>
        <button
          className="p-btn p-btn--primary mt24 wmn3 mb16"
          data-cy="button-continue"
          type="button"
          onClick={() => onSubmitValue(booleanValue)}
          disabled={booleanValue === undefined}
        >
          {t(
            'expatSpain.qnr.dependentSignup.policyHolderGuardianOrParent.button.cta',
            'Continue'
          )}
        </button>
      </div>
    </div>
  );
};
