import LoadingSpinner from 'components/loadingSpinner';
import RadioForm from 'components/questionnaireForms/RadioForm';
import { useLoadPolicyDetail } from 'features/expat/hooks';
import { ExpatDependentQuestionnaire } from 'features/expat/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';

export const DependentsPolicyHolder = ({
  value,
  questionnaireAnswers,
  ...props
}: CustomComponentProps<ExpatDependentQuestionnaire>) => {
  const { t } = useSafeTranslation();

  const { loading, error, policy } = useLoadPolicyDetail(
    questionnaireAnswers.mainPolicyId ?? ''
  );

  if (loading === true) {
    return <LoadingSpinner />;
  }

  const { name } = policy?.attributes.insuredPerson || {};

  const policyHolder =
    name && error === undefined ? `${name?.firstName} ${name?.lastName}` : '-';

  return (
    <RadioForm
      key={policyHolder}
      {...props}
      error={error?.message}
      value={value as string}
      question={t('expat.qnr.signup.policyHolderGuardianOrParent.title', {
        defaultValue: 'Is {{policyHolder}} their legal guardian / parent?',
        policyHolder,
      })}
      mapValue={{
        YES: t('expat.qnr.signup.policyHolderGuardianOrParent.yes', 'Yes'),
        NO: t('expat.qnr.signup.policyHolderGuardianOrParent.no', 'No'),
      }}
    />
  );
};
