import { submitGenericClaim } from 'actions/claims';
import Claims from 'features/claims/components';
import { ClaimSubmitInfo } from 'models/claims';
import { connect } from 'react-redux';
import { AppState } from 'reducers';
import {
  getRequestErrorMessage,
  getRequestInProcess,
} from 'selectors/requests';

const mapStateToProps = (state: AppState) => {
  const requestType = 'CREATE_CLAIM';

  return {
    isLoading: getRequestInProcess(state, requestType) || false,
    error: getRequestErrorMessage(state, requestType),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreateClaim: (claimInfo: ClaimSubmitInfo) =>
    dispatch(submitGenericClaim(claimInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Claims);
