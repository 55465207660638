import { email } from '@getpopsure/private-constants';
import dayjs from 'dayjs';
import { getClaimStepsForInsuranceType } from 'features/claimsProcess/utils';
import { ClaimStatuses, StepStatuses } from 'models/claims';
import { InsuranceTypes } from 'models/insurances/types';
import React from 'react';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import styles from './style.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

interface ComponentProps {
  date: string;
  title: string;
  price?: number;
  currentStatus: ClaimStatuses;
  insuranceType: InsuranceTypes;
}

const TimelineEvent = ({
  title,
  descriptions,
}: {
  title: string;
  descriptions?: string[];
}) => (
  <div>
    <div className="p-p">{title}</div>
    {descriptions &&
      descriptions.map((des, idx) => (
        <div
          className="p-p--small tc-grey-500 wmx4"
          key={String(`description-${idx}`)}
        >
          {des}
        </div>
      ))}
  </div>
);

const TimelineBar = ({ steps }: { steps: StepStatuses[] }) => {
  const ellipseClassNameForStepStatus = (stepStatus: StepStatuses): string => {
    switch (stepStatus) {
      case 'completed':
        return styles['timeline-ellipse-filled'];
      case 'error':
        return styles['timeline-ellipse-filled-error'];
      case 'uncompleted':
        return '';
    }
  };

  const timelineBarClassNameForStepStatus = (
    stepStatus: StepStatuses
  ): string => {
    switch (stepStatus) {
      case 'completed':
        return styles['timeline-bar-filled'];
      case 'error':
        return styles['timeline-bar-filled-error'];
      case 'uncompleted':
        return '';
    }
  };

  return (
    <div className={styles['timeline-container']}>
      {steps.map((status, idx) => {
        return (
          <React.Fragment key={String(`description-${idx}`)}>
            {idx !== 0 && (
              <div
                className={`${
                  styles['timeline-bar']
                } ${timelineBarClassNameForStepStatus(status)}`}
              />
            )}
            <div
              className={`${
                styles['timeline-ellipse']
              } ${ellipseClassNameForStepStatus(status)}`}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

const ClaimStatus = ({
  date,
  title,
  price,
  currentStatus,
  insuranceType,
}: ComponentProps) => {
  const claimSumittedDescription = [title];
  const steps = getClaimStepsForInsuranceType({
    insuranceType,
    currentStatus,
  });

  if (price) {
    claimSumittedDescription.push(englishFormattedEuroCurrency(price));
  }

  const timelineStatusesMapping: {
    [K in ClaimStatuses]: {
      title: string;
      descriptions?: string[];
    };
  } = {
    SUBMITTED: {
      title: `Claim submitted ${dayjs(date).format(DATE_FORMAT)}`,
      descriptions: claimSumittedDescription,
    },
    PROCESSING: {
      title: 'We’re currently processing your claim',
      descriptions: [
        'Claims like this usually take 2-3 days to process but please be patient as they may take longer',
      ],
    },
    ACTION_NEEDED: {
      title: '',
    },
    PAID_OUT: {
      title: 'You have received your payout',
    },
    PROCESSED: {
      title: 'The claim has been closed',
      descriptions: [
        `You can view the details in the Submitted documents section. If you have any further questions, please get in touch with us via ${email.help}.`,
      ],
    },
    CANCELLED: {
      title: '',
    },
    SETTLED: {
      title: '',
    },
    DENIED: {
      title: '',
    },
    APPROVED: {
      title: '',
    },
    WITHDRAWN: {
      title: '',
    },
    ON_HOLD: {
      title: '',
    },
  };

  return (
    <div className="d-flex">
      <TimelineBar steps={steps.map(({ stepStatus }) => stepStatus)} />
      <div className={styles['timeline-text-container']}>
        {steps.map(({ status }) => {
          const { title: timelineEvent, descriptions } =
            timelineStatusesMapping[status];
          return (
            <TimelineEvent title={timelineEvent} descriptions={descriptions} />
          );
        })}
      </div>
    </div>
  );
};

export default ClaimStatus;
