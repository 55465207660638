import { Button } from '@popsure/dirty-swan';
import routes from 'constants/routes';
import { generatePath, useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';
import { paramsSetUrl } from 'shared/util/paramsSetUrl';

import styles from './FinalStep.module.scss';
import checkIcon from './icons/check.svg';

type FinalStepProps = {
  mainPolicyId: string;
};

export const FinalStep = ({ mainPolicyId }: FinalStepProps) => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const onContinue = () => {
    history.push(
      generatePath(routes.policies.expat.dependents.path, {
        mainPolicyId,
      })
    );
  };

  const onCancel = () => {
    history.push(
      paramsSetUrl(routes.me.policies.path, [
        { key: 'signupSuccess', value: 'expat' },
      ])
    );
  };

  return (
    <div
      className={`p-container d-flex fd-column ai-center jc-center wmx6 ${styles.container}`}
    >
      <img src={checkIcon} alt="person icon" />
      <h1 className="p-h1 mt16 mb8 ta-center">
        {t('expat.qnr.finalStep.title', 'Great, ready to cover someone else?')}
      </h1>
      <div className="p-p ta-center">
        {t(
          'expat.qnr.finalStep.description',
          'You can also do it later in your Feather account.'
        )}
      </div>
      <div className="d-flex fd-column gap16 mt24 ai-center jc-center">
        <Button
          onClick={onContinue}
          className="wmn3"
          data-cy="final-step-add-policy"
        >
          {t('expat.qnr.finalStep.primaryButton.cta', 'Add another policy')}
        </Button>
        <Button
          onClick={onCancel}
          variant="filledWhite"
          className="wmn3"
          data-cy="final-step-done"
        >
          {t('expat.qnr.finalStep.secondaryButton.cta', "I'm done")}
        </Button>
      </div>
    </div>
  );
};
