import 'shared/errorReporting';

import { render } from 'react-dom';
import initializeSession from 'shared/sessionProvider';

import Root from './root';

initializeSession();

render(<Root />, document.getElementById('root'));
