import { toast } from '@popsure/dirty-swan';
import LoadingSpinner from 'components/loadingSpinner';
import BillingScreenView from 'features/paymentMethods/containers/BillingScreen/BillingScreen.view';
import {
  deletePaymentMethod,
  fetchPaymentMethods,
  makePaymentMethodDefault,
  PaymentMethodDispatch,
} from 'features/paymentMethods/paymentMethods.actions';
import {
  getDefaultPaymentMethod,
  getNonDefaultPaymentMethods,
} from 'features/paymentMethods/paymentMethods.selectors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getRequestInProcess } from 'selectors/requests';
import { useSafeTranslation } from 'shared/i18n';

import { ChangePrivateHealthPaymentMethod } from '../ChangePrivateHealthPaymentMethod/ChangePrivateHealthPaymentMethod';

export const BillingScreen = ({
  showPaymentMethodTitle,
}: {
  showPaymentMethodTitle?: boolean;
}) => {
  const { t } = useSafeTranslation();
  const [isFirstFetch, setIsFirstFetch] = useState<boolean>(true);
  const defaultPaymentMethod = useSelector(getDefaultPaymentMethod);
  const otherPaymentMethods = useSelector(getNonDefaultPaymentMethods);

  const isLoadingPaymentMethods = useSelector((state: AppState) =>
    getRequestInProcess(state, 'GET_PAYMENT_METHODS')
  );

  const isDeletingPaymentMethod = useSelector((state: AppState) =>
    getRequestInProcess(state, 'DELETE_PAYMENT_METHOD')
  );

  const isUpdatingDefaultPaymentMethod = useSelector((state: AppState) =>
    getRequestInProcess(state, 'MAKE_PAYMENT_METHOD_A_DEFAULT')
  );

  const dispatch = useDispatch<PaymentMethodDispatch>();

  useEffect(() => {
    if (isFirstFetch) {
      dispatch(fetchPaymentMethods());
      setIsFirstFetch(false);
    }
  }, [dispatch, isFirstFetch]);

  const isLoading =
    isFirstFetch ||
    isLoadingPaymentMethods ||
    isDeletingPaymentMethod ||
    isUpdatingDefaultPaymentMethod;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const handleMakeDefault = async (paymentMethodId: string) => {
    const response = await dispatch(makePaymentMethodDefault(paymentMethodId));

    if (response === 'SUCCESS') {
      toast(
        t(
          'account.paymentMethods.updatedDefaultNotification',
          'Default payment method updated'
        ),
        {
          type: 'success',
        }
      );
    }
  };

  const handleDelete = async (paymentMethodId: string) => {
    const response = await dispatch(deletePaymentMethod(paymentMethodId));

    if (response === 'SUCCESS') {
      toast(
        t(
          'account.paymentMethods.deletedNotification',
          'Payment method removed'
        ),
        { type: 'success' }
      );
    }
  };

  return (
    <BillingScreenView
      defaultPaymentMethod={defaultPaymentMethod}
      renderDefaultPaymentMethodAfter={<ChangePrivateHealthPaymentMethod />}
      otherPaymentMethods={otherPaymentMethods}
      handleDelete={handleDelete}
      handleMakeDefault={handleMakeDefault}
      showPaymentMethodTitle={showPaymentMethodTitle}
    />
  );
};
