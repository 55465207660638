import {
  getRoutes,
  QuestionnaireQuestions,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import {
  flushGenericQuestionnaire,
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import { UserWithBrokerMandate } from 'models/user';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { AppState } from 'reducers';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { getQuote } from './components/quote';
import { Bike } from './models';
import { BikeComponents, getTranslatedQuestionnaire } from './questionnaire';

const rules: Rule<Bike>[] = [
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: {
        type: 'year',
        value: 18,
      },
    },
    then: {
      goTo: 'isBikeAgeOverOneYear',
    },
    else: {
      goTo: 'ageBlocker',
    },
  },
  {
    id: 'purchasePrice',
    if: (price) => (price as number) <= 12000,
    then: {
      goTo: 'type',
    },
    else: {
      goTo: 'purchasePriceBlocker',
    },
  },
];

const removeReview: RemoveAnswerType<Bike> = {
  op: 'always',
  questions: ['review'],
};

const removeQuote: RemoveAnswerType<Bike> = {
  op: 'always',
  questions: ['quote'],
};

// if answer to the record key changes, clean up answers to the questions in record value
const removeAnswersLogic: Partial<Record<keyof Bike, RemoveAnswerType<Bike>>> =
  {
    postalCode: removeQuote,
    dateOfBirth: removeQuote,
    type: removeQuote,
    isBikeAgeOverOneYear: removeQuote,
    purchasePrice: removeQuote,
    quote: removeReview,
    name: removeReview,
    model: removeReview,
    brand: removeReview,
    frameNumber: removeReview,
    receipts: removeReview,
  };

const prefillNameAndBirthDate = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<Bike> = {};
    const { firstName, lastName } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    return toUpdate;
  }

  return null;
};

export const SignupPage = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.genericQuestionnaire.bike) || {};
  const dispatch = useDispatch();
  const account = useSelector(getAccountInfo);

  const { t } = useSafeTranslation();

  useEffect(() => {
    return () => {
      dispatch(flushGenericQuestionnaire('bike'));
    };
  }, [dispatch]);

  const onAnswer = <QuestionId extends keyof Bike>(
    questionId: QuestionId,
    answer: Bike[QuestionId]
  ) => {
    dispatch(storeGenericQuestionnaireAnswer('bike', { [questionId]: answer }));

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(removeGenericQuestionnaireAnswer('bike', answersToRemove));

    const toUpdate = prefillNameAndBirthDate(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );
    if (toUpdate) {
      dispatch(storeGenericQuestionnaireAnswer('bike', toUpdate));
    }
  };

  const quote = getQuote(questionnaireAnswers);
  const questions = getTranslatedQuestionnaire(t, quote);

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, routes.policies.bike.path),
    rules,
  };

  return (
    <Switch>
      <Route path={routes.policies.bike.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: BikeComponents,
          }}
          basePath={routes.policies.bike.path}
          questionId="intro"
          featureName="Bike"
          share={{
            verticalId: 'bike',
          }}
        />
      </Route>
    </Switch>
  );
};
