import { PartialQuestionnaire } from 'models/questionnaireFramework';

/**
 *
 * TODO: check if we can replace `getNextUnansweredQuestionId` with `getNextQuestionId`
 * If we can replace the two in `QuestionnaireWrapper`, then `getNextUnansweredQuestionId` is obsolete
 *
 */
export const getNextUnansweredQuestionId = <
  // eslint-disable-next-line @typescript-eslint/ban-types
  Questionnaire extends Object,
  GroupId
>(
  questions: PartialQuestionnaire<Questionnaire, GroupId>,
  answers: Partial<Questionnaire>
): keyof Questionnaire | null => {
  // eslint-disable-next-line guard-for-in
  for (const answerId in questions) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const requiredParam = questions?.[answerId]!.required || false;
    const isRequired =
      typeof requiredParam === 'function'
        ? requiredParam(answers)
        : requiredParam;

    if (isRequired && answers[answerId as keyof Questionnaire] === undefined) {
      return answerId as keyof Questionnaire;
    }
  }

  return null;
};
