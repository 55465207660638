import { TFunction } from '@getpopsure/i18n-react';
import { calendly, faq, website } from '@getpopsure/private-constants';
import { breeds } from '@getpopsure/public-models';
import { images } from '@popsure/dirty-swan';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';
import { coverageOneYearInTheFutureValidator } from 'SignupQuestionnaire/shared/customValidators';

import { createPostQuote } from '../actions';
import DogLiabilityIcon from '../assets/dogLiabilityIcon.png';
import { PolicyDetails } from '../components/PolicyDetails';
import { Dog, DogGroupIds } from '../models';

export const DogComponents = {} as const;

export type DogQuestionnaire = SignupQuestionnaireType<
  Dog,
  DogGroupIds,
  typeof DogComponents
>;

export const getTranslatedQuestionnaire = (t: TFunction): DogQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'INTRO',
    groupId: 'preQuote',
    props: {
      title: "Hey! Let's get you a quote in minutes",
      subTitle:
        'We need a few pieces of information about you and we can figure out the rest.',
    },
    screen: {
      continueButtonText: 'Get started',
    },
  },
  {
    id: 'isLivingInGermany',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: 'Are you currently living in Germany?',
    },
    groupId: 'preQuote',
  },
  {
    id: 'nonGermanResidentBlocker',
    type: 'BLOCKER',
    props: {
      title: 'You need to be a resident in Germany',
      description:
        "We're working hard to make our policies cover all use cases, but still have certain limitations.\n\nOur dog liability insurance policy is only available for German residents.",
      buttonProps: [
        {
          type: 'href',
          href: website.base,
          caption: 'Back to homepage',
          variant: 'SECONDARY',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'numberOfPreviousClaims',
    required: true,
    props: {
      mapValue: { NONE: '0', ONE: '1', TWO_OR_MORE: '2 or more' },
    },
    screen: {
      question:
        'How many dog liability insurance claims have you made in the last 3 years?',
    },
    type: 'RADIO',
    groupId: 'preQuote',
  },
  {
    id: 'previousClaimsTotalAmount',
    type: 'NUMBER',
    props: {
      placeholder: 'Amount',
      prefix: '€',
    },
    screen: {
      question: "What's the total amount you claimed in the last 3 years?",
    },
    groupId: 'preQuote',
  },
  {
    id: 'pastClaimsBlocker',
    type: 'BLOCKER',
    props: {
      title: "Unfortunately, we can't sign you up",
      description:
        "We're working hard to make our policies cover all use cases, but still have certain limitations.",
      buttonProps: [
        {
          type: 'href',
          href: website.base,
          caption: 'Back to homepage',
          variant: 'SECONDARY',
        },
      ],
      iconType: 'SHIELD',
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'isMixedBread',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: { YES: 'Mixed breed', NO: 'Purebred' },
    },
    screen: {
      question: 'Is your dog mixed or purebred?',
    },
    groupId: 'signup',
  },
  {
    id: 'breed',
    type: 'AUTOSUGGEST',
    props: {
      options: [...breeds],
    },
    screen: {
      question: 'What breed is your dog?',
      skipDetails: {
        title: "It's not listed",
        skipQuestion: { value: '' },
        path: '',
      },
    },
    groupId: 'preQuote',
  },
  {
    id: 'dangerousBreedsBlocker',
    type: 'BLOCKER',
    props: {
      title: "We can't cover this breed",
      description:
        'This breed is considered to be dangerous and currently cannot be covered under our dog liability insurance.',
      buttonProps: [
        {
          type: 'href',
          href: website.base,
          caption: 'Back to homepage',
          variant: 'SECONDARY',
        },
      ],
      iconType: 'SHIELD',
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'quote',
    required: true,
    type: 'QUOTEPAGE',
    groupId: 'signup',
    props: {
      config: {
        title: 'Here is your quote',
        headerImage: {
          src: DogLiabilityIcon,
          alt: 'dog',
        },
        reviewBadge: true,
        coveredSection: {
          title: "What's covered?",
          items: [
            {
              title: 'Medical injuries to other people',
              description:
                'If your dog gets excited and runs into someone, causing them to fall and hurt themselves, your policy will cover the expenses.',
              icon: {
                src: images.aid,
                alt: 'Aid',
              },
            },
            {
              title: 'Damage to rented apartments',
              description:
                'Did your dog decide to chew up your rented furniture? Relax and keep your security deposit when you move out!',
              icon: {
                src: images.bed,
                alt: 'Bed',
              },
            },
            {
              title: 'Property damage',
              description:
                "You'll be covered if your dog damages someone's belongings (E,g, your friends carpet or laptop)",
              icon: {
                src: images.damagedLaptop,
                alt: 'Damaged laptop',
              },
            },
            {
              title: 'Financial loss',
              description:
                "If your dog bites the mailperson and can't work after that, dog liability insurance will cover you.",
              icon: {
                src: images.moneyIncome,
                alt: 'Money Income',
              },
            },
          ],
        },
        priceHeader: {
          title: '{{value}}',
          subTitle: 'per month',
        },
        actions: {
          items: [
            { type: 'SubHeader', text: 'Deductible' },
            {
              type: 'Description',
              text: 'The deductible is the amount you pay out of pocket per insured event before the insurance starts to pay. For instance, if you choose a deductible of €150 and submit a claim for a damage of €2,000, you will pay €150 and we will pay the remaining €1,850.',
            },
            {
              type: 'Toggle',
              mapValues: {
                0: '€0',
                1: '€150',
              },
            },
          ],
          valuesMap: {
            0: '6.90',
            1: '5.58',
          },
          submitMap: [
            {
              id: '1',
              price: 6.9,
              plan: 'NON_DEDUCTIBLE',
              isDeductible: false,
              deductibleAmount: 0,
            },
            {
              id: '2',
              price: 5.58,
              plan: 'DEDUCTIBLE',
              isDeductible: true,
              deductibleAmount: 150,
            },
          ],
          button: {
            text: 'Get covered',
          },
        },
        notCoveredSection: {
          title: "What's not covered?",
          items: [
            {
              title: 'Damage to your own property',
              description:
                'Replacement or repair of things you own is not covered by dog liability.',
              icon: {
                src: images.brokenGlass,
                alt: '',
              },
            },
            {
              title: 'Rented electronics',
              description:
                "If your dog damages an electronic device you rented from someone else, it's not covered.",
              icon: {
                src: images.washingMachine,
                alt: '',
              },
            },
            {
              title: 'Broken glass in your own home',
              description:
                "If you're renting a flat, glass breakage isn't covered. You can get [household insurance]() to cover glass.",
              icon: {
                src: images.brokenAquarium,
                alt: 'Broken glass',
              },
            },
            {
              title: 'Dangerous breeds',
              description:
                "Breeds that might be considered to be dangerous can't be covered. [Check the complete list]().",
              icon: {
                src: images.bigDog,
                alt: '',
              },
            },
          ],
        },
        ctaBox: {
          header: 'Dog liability insurance',
          title: '{{value}}',
          subTitle: 'per month',
          buttonText: 'Get covered',
        },
        faq: {
          title: 'Frequently asked questions',
          data: [
            {
              questionId: 1,
              question:
                'My dog is considered to be a “dangerous” breed can I still be covered?',
              answer:
                'Some dog breeds which are considered “dangerous” can’t be covered through Feather but other insurers may still cover them. Policies for such dog breeds are typically a bit more expensive.',
            },
            {
              questionId: 2,
              question:
                'Do I need dog liability insurance if I already have personal liability insurance?',
              answer:
                'Personal liability insurance covers small animals like cats or birds but dogs usually aren’t covered. This is why we recommend getting dog liability insurance in addition to personal liability insurance.',
            },
            {
              questionId: 3,
              question: 'What happens if my dog has puppies?',
              answer:
                'Your dog’s puppies are automatically covered by dog liability insurance until they turn 1 year old or when they permanently leave your household, whichever happens sooner. ',
            },
            {
              questionId: 4,
              question: 'Can I get dog insurance for a mixed-breed dog?',
              answer:
                'Yes! Our dog liability insurance includes mixed-breed dogs, as well as pedigree and cross-breed dogs. Unfortunately, we cannot cover (whether pedigree, cross-breed, or mixed breed) some breeds that are considered to be dangerous can not be covered.',
            },
            {
              questionId: 5,
              question: 'Is dog liability insurance mandatory?',
              answer:
                'Yes. It’s mandatory in some regions of Germany (Berlin, Niedersachsen, Hamburg, Schleswig-Holstein, Thüringen, Sachsen-Anhalt) as well as other countries.',
            },
            {
              questionId: 6,
              question:
                'Does dog liability insurance pay if I leave my dog with someone else?',
              answer:
                'Your dog is also covered when watched by someone else such as your spouse, children up to the age of 18, or partner (living at your address). When your dog is watched by a non-professional dog sitter it is also covered. However, when hiring a professional dog sitter the dog sitter needs to have liability insurance for this.',
            },
            {
              questionId: 7,
              question: 'How am I covered outside of Germany?',
              answer:
                'Dog liability insurance coverage is valid worldwide. If you permanently move away from Germany you can keep your dog liability insurance coverage if you want. ',
            },
            {
              questionId: 8,
              question: 'Is my dog still covered when walking without a leash?',
              answer:
                'Yes. Your dog is covered when walking without a leash. Your dog is even covered when walking without a leash in places where leashes are mandatory. ',
            },
          ],
          footer: `See our [FAQ page](${faq.base}) for more answers.`,
        },
        moreQuestionsSection: {
          bookACallGACategory: 'Dog',
          title: 'Have more questions?',
          bookACallUrl: calendly.dogLiabilityInsurance,
          secondaryActionLink: faq.base,
          secondaryAction: 'Visit our FAQ',
        },
      },
    },
    screen: {
      layout: 'Standalone',
    },
  },
  {
    id: 'dogName',
    required: true,
    type: 'INPUT',
    props: {
      placeholder: "Dog's name",
    },
    screen: {
      question: "What's your dog's name?",
    },
    groupId: 'signup',
  },
  {
    id: 'email',
    required: true,
    type: 'EMAIL',
    props: {
      verticalId: 'dog',
    },
    screen: {
      question: 'What’s your email address?',
      description: 'This policy will be accessible in your Feather account.',
    },
    groupId: 'signup',
  },
  {
    id: 'name',
    required: true,
    type: 'NAME',
    props: {},
    screen: {
      question: "What's your name?",
    },
    groupId: 'signup',
  },
  {
    id: 'dateOfBirth',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'subtract', type: 'years', value: 18 },
      },
    },
    screen: {
      question: 'When were you born?',
    },
    groupId: 'signup',
  },
  {
    id: 'ageBlocker',
    type: 'BLOCKER',
    props: {
      iconType: 'SHIELD',
      title: "We can't sign you up...",
      description:
        'We currently only cover people who are over 18 years old.\n\nYou can speak to one of our insurance experts if you have any questions.',
      buttonProps: [
        { type: 'href', href: website.support, caption: 'Speak to us' },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'address',
    required: true,
    type: 'ADDRESS',
    props: {},
    screen: {
      question: "What's your address?",
      noMaxWidth: true,
    },
    groupId: 'signup',
  },
  {
    id: 'startDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 0 },
        max: { op: 'add', type: 'years', value: 1 },
      },
    },
    screen: {
      question: 'When would you like to start your coverage?',
    },
    validations: [
      {
        op: 'DateIsInFuture',
        msg: {
          type: 'Error',
          value: 'Earliest start date is {{value}}.',
        },
      },
      coverageOneYearInTheFutureValidator(),
    ],
    groupId: 'signup',
  },
  {
    id: 'review',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'DOG_SUBMIT_INFO',
      reviewValues: [
        {
          title: "Dog's name",
          value: { type: 'String', key: 'dogName' },
          path: 'dogName',
        },
        {
          title: 'Email',
          value: { type: 'String', key: 'email' },
          path: 'email',
        },
        {
          title: "Policy holder's name",
          value: {
            type: 'Strings',
            key: 'name',
            keys: ['firstName', 'lastName'],
          },
          path: 'name',
        },
        {
          title: 'Date of birth',
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'dateOfBirth' },
          path: 'dateOfBirth',
        },
        {
          title: 'Address',
          value: {
            type: 'Strings',
            key: 'address',
            keys: ['street', 'houseNumber', 'postcode', 'city'],
          },
          path: 'address',
        },
        {
          title: 'Coverage start date',
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'startDate' },
          path: 'startDate',
        },
      ],
      verticalId: 'dog',
      insuranceType: 'DOG_LIABILITY',
      createPostQuote: () => createPostQuote(t),
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'reviewCheckout',
    required: true,
    type: 'CHECKOUT',
    props: {
      type: 'DOG_LIABILITY',
      PolicyDetails,
      checkoutIcon: DogLiabilityIcon,
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
];
