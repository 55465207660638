const nonce = (document.querySelector('[data-id="csp-nonce"]') as HTMLElement)
  ?.nonce;

const fontAwesomeStyleSheetImport = document.createElement('link');
fontAwesomeStyleSheetImport.nonce = nonce;
fontAwesomeStyleSheetImport.rel = 'stylesheet';
fontAwesomeStyleSheetImport.href =
  'https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css';

const calendlyStyleSheetImport = document.createElement('link');
calendlyStyleSheetImport.nonce = nonce;
calendlyStyleSheetImport.rel = 'stylesheet';
calendlyStyleSheetImport.href =
  'https://assets.calendly.com/assets/external/widget.css';

const calendlyImportScript = document.createElement('script');
calendlyImportScript.nonce = nonce;
calendlyImportScript.type = 'text/javascript';
calendlyImportScript.src =
  'https://assets.calendly.com/assets/external/widget.js';

const stripeImportScript = document.createElement('script');
stripeImportScript.nonce = nonce;
stripeImportScript.id = 'stripe-js';
stripeImportScript.src = 'https://js.stripe.com/v3/';
stripeImportScript.async = true;

const urlSearchParamsPolyfill = document.createElement('script');
urlSearchParamsPolyfill.nonce = nonce;
urlSearchParamsPolyfill.src = 'https://unpkg.com/@ungap/url-search-params';

const allHeadScripts = [
  fontAwesomeStyleSheetImport,
  calendlyStyleSheetImport,
  calendlyImportScript,
  stripeImportScript,
  urlSearchParamsPolyfill,
];

export default (() => {
  allHeadScripts.forEach((script) => {
    document.head.appendChild(script);
  });
})();
