import { useRequestStatus } from 'hooks/useRequestStatus';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import { retrieveBillingHistory, retrieveDisputeInvoices } from './actions';
import { BillingHistoryView } from './BillingHistory.view';
import { getBillingHistory } from './selectors';
import styles from './styles.module.scss';

export const BillingHistory = () => {
  const { t } = useSafeTranslation();
  const [selectedStripeId, setSelectedStripeId] = useState('');
  const { billings, hasMorePages, fetchAfter, disputeInvoices } =
    useSelector(getBillingHistory);

  const dispatch = useDispatch();
  const { loading: loadingBillingHistory } = useRequestStatus(
    'GET_BILLING_HISTORY'
  );
  const { loading: loadingDisputeInvoices } = useRequestStatus(
    'GET_BILLING_HISTORY_DISPUTE_INVOICES'
  );

  useEffect(() => {
    dispatch(retrieveBillingHistory());
    dispatch(retrieveDisputeInvoices());
  }, [dispatch]);

  if (loadingBillingHistory || loadingDisputeInvoices) {
    return (
      <div className={styles.loaderContainer}>
        <div className="ds-spinner ds-spinner__l" />
      </div>
    );
  }

  const selectedBillingInfo = billings?.find(
    ({ stripeId }) => stripeId === selectedStripeId
  );

  return (
    <BillingHistoryView
      t={t}
      billings={billings}
      disputeInvoices={disputeInvoices}
      hasMorePages={hasMorePages}
      fetchAfter={fetchAfter}
      setSelectedBillingId={setSelectedStripeId}
      selectedBillingInfo={selectedBillingInfo}
    />
  );
};
