import { useGetEmailFromUrl } from 'hooks/useGetEmailFromUrl';
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

/**
 * @deprecated - Use RouteWithAuthentication component instead (components/RouteWithAuthentication)
 */
const AuthenticatedRoute = ({
  path,
  component,
  isAuthenticated,
  exact,
  userEmail,
  onSignOut,
}) => {
  const { emailParam } = useGetEmailFromUrl();

  useEffect(() => {
    /**
     * If the url has an authenticate param
     * and the email value is different to the current authenticated user
     * log out user and redirect them to the sign-in page
     */
    if (emailParam && userEmail && emailParam !== userEmail) {
      onSignOut();
    }
  }, [emailParam, userEmail, onSignOut]);

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/account/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
