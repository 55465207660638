import { TFunction } from '@getpopsure/i18n-react';

import { questionPropLookup } from '../lookups';
import { DisabilityV2Questionnaire } from '.';

export const getInsuranceHistoryQuestionnaire = (
  t: TFunction
): DisabilityV2Questionnaire => [
  {
    id: 'hasPreviousInsurance',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasPreviousInsurance,
    },
  },
  {
    id: 'previousInsuranceDetails',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).previousInsuranceDetails,
    },
  },
  {
    id: 'hasActiveOrPendingInsurance',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasActiveOrPendingInsurance,
    },
  },
  {
    id: 'activeOrPendingInsuranceDetails',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).activeOrPendingInsuranceDetails,
    },
  },
  {
    id: 'dynamicPremiums',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).dynamicPremiums,
    },
  },
];
