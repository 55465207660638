export const removeKeysFromObject = (
  object: Record<string, unknown>,
  keys: Array<string>
): Record<string, unknown> =>
  keys.reduce(
    (result, currentKey) => {
      if (currentKey in object) {
        const { [currentKey]: remove, ...rest } = result;
        return rest;
      }
      return result;
    },
    { ...object }
  );
