import { faq } from '@getpopsure/private-constants';
import { Input } from '@popsure/dirty-swan';
import QuestionnaireWrapper from 'components/questionnaireForms/QuestionnaireWrapper';
import { FormEvent } from 'react';
import { TFunction } from 'shared/i18n';

interface Props {
  handleSubmit: (e: FormEvent) => void;
  t: TFunction;
  validForSubmission: boolean;
  frameNumber: string;
  setFrameNumber: (frameNumber: string) => void;
  blockerRoute: string;
  loading?: boolean;
  error?: string;
}

export const BikeClaimsFrameNumberView = ({
  handleSubmit,
  t,
  validForSubmission,
  setFrameNumber,
  frameNumber,
  blockerRoute,
  error,
  loading,
}: Props) => (
  <QuestionnaireWrapper
    question={t(
      'claims.bike.frameNumber.question',
      'What is your bike’s frame number?'
    )}
    additionalInfo={{
      title: t(
        'claims.bike.questionnaire.frameNumber.additionalInfo.title',
        'Where can I find the frame number?'
      ),
      description: t(
        'claims.bike.questionnaire.frameNumber.additionalInfo.description',
        {
          defaultValue:
            'Most bicycles have a frame number stamped into the metal under the bottom bracket. Occasionally, the frame number will be on the inside of the chain stay or on the down tube or top tube.\n\nIf your bike did not have a frame number, please provide the identification number that you received from the police or the official dealer, Allgemeine Deutscher Fahrrad-Club eV (ADFC) when you had the bike coded. [Learn more]({{link}})',
          link: faq.bikeFrameSubmitClaim,
        }
      ),
    }}
    onSubmit={handleSubmit}
    validForSubmission={validForSubmission}
    skipDetails={{
      title: t(
        'claims.bike.questionnaire.frameNumber.skipDetails.title',
        "I don't have it"
      ),
      path: blockerRoute,
    }}
    loading={loading}
    error={error}
  >
    <Input
      className="mt8"
      value={frameNumber}
      onChange={(e) => setFrameNumber(e.target.value)}
      placeholder={t(
        'claims.bike.questionnaire.frameNumber.placeholder',
        'Frame number'
      )}
      required
      data-cy="input-frame-number"
    />
  </QuestionnaireWrapper>
);
