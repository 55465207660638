import Email from 'components/signupAuthentication/email';
import { UserRegistrationInfo } from 'models/genericQuestionnaire';
import { CustomComponentProps } from 'models/questionnaireFramework';

export const SignupEmail = (
  props: CustomComponentProps<UserRegistrationInfo>
) => {
  const {
    routes: {
      name: { path: redirectPath },
      redirectPath: redirectExistingUserPath,
    },
    value,
  } = props;
  return (
    <Email
      verticalId="userCreation"
      redirectPath={redirectPath}
      redirectExistingUserPath={redirectExistingUserPath?.path}
      storedAnswer={value as string}
    />
  );
};
