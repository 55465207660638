import Session from '@getpopsure/session';
import { submitAccount } from 'actions/account';
import { fetchAccountInfo } from 'actions/user';
import View from 'components/signupAuthentication/account/view';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { UserWithBrokerMandate } from 'models/user';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import { getAccountInfo } from 'selectors/user';

interface Props {
  verticalId: keyof GenericQuestionnaireState;
  newAccountPath?: string;
  existingAccountPath?: string;
  /**
   * @deprecated use {@link onContinueWithCurrentAccount} or {@link onCreateNewAccount} instead
   */
  onContinue?: () => void;
  onContinueWithCurrentAccount?: (
    account: UserWithBrokerMandate | null
  ) => void;
  onCreateNewAccount?: () => void;
}

const Account = ({
  verticalId,
  newAccountPath,
  existingAccountPath,
  onContinue,
  onCreateNewAccount,
  onContinueWithCurrentAccount,
}: Props) => {
  const account = useSelector(getAccountInfo);
  const { loading, error } = useRequestStatus('ACCOUNT');

  const dispatch = useDispatch();

  const { isAuthenticated } = Session;

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchAccountInfo());
    }
  }, [dispatch, isAuthenticated]);

  const handleContinue = () => {
    onContinue?.();
    onContinueWithCurrentAccount?.(account || null);
    dispatch(submitAccount(verticalId, existingAccountPath, account));
  };

  const handleCreateNewAccount = () => {
    onContinue?.();
    onCreateNewAccount?.();
  };

  return (
    <View
      newAccountPath={newAccountPath}
      loading={loading}
      error={error}
      account={account}
      handleContinue={handleContinue}
      handleCreateNewAccount={handleCreateNewAccount}
    />
  );
};

export default Account;
