import PageNotFound from 'components/pageNotFound';
import {
  HealthWelcomePage,
  WelcomePage,
} from 'components/recommendationTool/WelcomePage';
import { RequestType } from 'constants/requestTypes';
import routes from 'constants/routes';
import {
  createRecommendationPersona,
  updateShowIntroPage,
} from 'features/recommendationTool/actions';
import { AllowedWelcomeParams } from 'features/recommendationTool/models/recommendation';
import { isValidInsuranceType } from 'features/recommendationTool/utils/isValidInsuranceType';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { RecommendationToolDispatch } from 'models/recommendationTool';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom';
import {
  getCurrentRecommendation,
  getPersonaId,
} from 'selectors/recommendationTool';

import { categoryLookup } from '../lookups';
import { getOverviewVerticalRoute } from '../utils/getOverviewVerticalRoute';

const REQUEST_TYPE: RequestType = 'RECOMMENDATION_TOOL';

interface MatchParams {
  insuranceType: AllowedWelcomeParams;
}
export type WelcomeProps = RouteComponentProps<MatchParams>;

/**
 * We always need a persona in order to get recommendations.
 * Currently,there are three places where the persona gets created:
 *
 * 1. ContinueWithAccountContainer (recomendationTool/questionnaires/account)
 * 2. LoginContainer (recomendationTool/questionnaires/account)
 * 3. WelcomeScreen page (this file)
 *
 * The first two components are shown when an anonymous user goes into the questionnaire,
 * this component is currently used to create personas for authenticated users
 * and redirect them to the correct page (either the overview or a specific vertical).
 *
 */
export const WelcomeScreen = ({
  match: {
    params: { insuranceType },
  },
}: WelcomeProps) => {
  const history = useHistory();
  const personaId = useSelector(getPersonaId);
  const { loading, error } = useRequestStatus(REQUEST_TYPE);
  const dispatch = useDispatch<RecommendationToolDispatch>();
  const recommendation = useSelector(getCurrentRecommendation);
  const introRoute = generatePath(routes.insuranceTool.questionnaire.path, {
    insuranceType: 'quickRecommendation',
  });

  const callbackRoute =
    insuranceType === 'intro' || insuranceType === 'quickRecommendation'
      ? introRoute
      : getOverviewVerticalRoute(insuranceType, recommendation);

  const handleStartQuestionnaire = async () => {
    /* Create persona when it doesn't exist  */
    if (!personaId) {
      /* Wait for the persona to be created before redirecting */
      const status = await dispatch(createRecommendationPersona);

      /* Do not redirect if there was an error with the request */
      if (status === 'ERROR') return;
    }

    await dispatch(updateShowIntroPage(false));

    /* If the user is not authenticated, we can proceed without a persona */
    history.push(callbackRoute);
  };

  if (insuranceType !== 'intro' && !isValidInsuranceType(insuranceType)) {
    return <PageNotFound />;
  }

  if (insuranceType === 'health') {
    return (
      <HealthWelcomePage
        onClick={handleStartQuestionnaire}
        loading={loading}
        error={error}
      />
    );
  }

  return (
    <WelcomePage
      insuranceType={insuranceType}
      onClick={handleStartQuestionnaire}
      loading={loading}
      error={error}
      icons={[
        {
          id: 0,
          ...categoryLookup.HEALTH_INSURANCE,
        },
        {
          id: 1,
          ...categoryLookup.LIABILITY,
        },
        {
          id: 2,
          transparent: true,
          hiddenOnMobile: true,
          ...categoryLookup.LIFE,
        },
        {
          id: 3,
          ...categoryLookup.DENTAL,
        },
        {
          id: 4,
          transparent: true,
          hiddenOnMobile: true,
          ...categoryLookup.LEGAL,
        },
        {
          id: 5,
          transparent: true,
          hiddenOnMobile: true,
          ...categoryLookup.CAR,
        },
      ]}
    />
  );
};
