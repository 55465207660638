import { fetchUserClaims } from 'actions/claims';
import { fetchPolicyDetail } from 'actions/policies';
import { associatedPathForClaimStep, Claim, ClaimStep } from 'models/claims';
import { InsuranceTypes } from 'models/insurances/types';
import { Policy } from 'models/policies';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AppState } from 'reducers';
import { getClaims } from 'selectors/claims';
import { getPolicyDetail } from 'selectors/policies';
import {
  getRequestErrorMessage,
  getRequestInProcess,
} from 'selectors/requests';
import { urlLastPathComponent } from 'shared/util/urlLastPathComponent';

export const useCurrentClaimStep = (): ClaimStep | undefined => {
  const location = useLocation();
  const result = Object.entries(associatedPathForClaimStep).find(
    ([_, path]) =>
      urlLastPathComponent(path) === urlLastPathComponent(location.pathname)
  ) as [ClaimStep, string] | undefined;

  if (result) {
    return result[0];
  }

  return undefined;
};

export const useClaimSteps = (): ClaimStep[] => {
  return ['SELECT_CLAIM_TYPE', 'CLAIM_DETAIL', 'PAYOUT_DETAIL'];
};

export const useLoadPolicyDetail = (
  policyId: string
): { loading: boolean; error?: string; policy?: Policy } => {
  const dispatch = useDispatch();
  const policy = useSelector((state: AppState) =>
    getPolicyDetail(state, policyId)
  );
  const loading =
    useSelector((state: AppState) =>
      getRequestInProcess(state, 'LOAD_MY_POLICY_DETAIL')
    ) ?? policy === undefined;
  const error = useSelector((state: AppState) =>
    getRequestErrorMessage(state, 'LOAD_MY_POLICY_DETAIL')
  );

  useEffect(() => {
    if (policy === undefined) {
      dispatch(fetchPolicyDetail(policyId));
    }
  }, [policy, policyId, dispatch]);

  return { loading, error, policy };
};

export const useLoadUserClaims = (
  insuranceType?: InsuranceTypes
): { loading: boolean; error?: string; claims?: Claim[] } => {
  const dispatch = useDispatch();
  const claims = useSelector((state: AppState) => getClaims(state));
  const loading =
    (useSelector((state: AppState) =>
      getRequestInProcess(state, 'GET_CLAIMS')
    ) ??
      claims === undefined) &&
    claims === undefined;
  const error = useSelector((state: AppState) =>
    getRequestErrorMessage(state, 'GET_CLAIMS')
  );
  useEffect(() => {
    if (insuranceType) {
      dispatch(fetchUserClaims(insuranceType));
    }
  }, [insuranceType, dispatch]);

  return { loading, error, claims };
};
