import { getAccountInfo } from 'features/recommendationTool/questionnaires/account';
import { HealthQuestionnaire } from 'models/recommendationTool';

import { createProcessingQuestion } from '../processing';
import { dependentsInfo } from './dependentsInfo';
import { financialHistory } from './financialInfo';
import { insuranceHistory } from './insuranceHistory';
import { personalInfo } from './personalInfo';

const accountInfo = getAccountInfo<HealthQuestionnaire>('nationalities');

const healthQuestionnaire: HealthQuestionnaire = {
  ...accountInfo,
  ...personalInfo,
  ...insuranceHistory,
  ...financialHistory,
  ...dependentsInfo,
  ...createProcessingQuestion('health'),
};

export default healthQuestionnaire;
