import { PartialQuestionnaire } from 'models/questionnaireFramework';
import { z } from 'zod';

export type Disability = {
  dateOfBirth: string;
  ageBlocker: boolean;

  occupation: Occupation;
  studentBlocker: boolean;
  unemployedBlocker: boolean;

  monthlyNetIncome: number;
  monthlyNetIncomeBlocker: number;

  officeWork: boolean;
  degree: Degree;
  height: {
    inCm: number;
    inFt: number;
  };
  weight: {
    inKg: number;
    inLbs: number;
  };

  monthlyPayout: number;

  calculatorQuote: {
    basicPrice: number;
    premiumPrice: number;
  };

  addOns: {
    premiumCoverage: boolean;
  };

  quote: {
    questionnaireId: string;
    quoteId: string;
    price: number;
  };

  nextSteps: never;

  form: never;
};

const OCCUPATIONS = [
  'EMPLOYED',
  'SELF_EMPLOYED',
  'STUDENT',
  'UNEMPLOYED',
  'OTHER',
] as const;

export type Occupation = typeof OCCUPATIONS[number];

const DEGREES = [
  'NO_DEGREE',
  'HIGH_SCHOOL',
  'UNIVERSITY_SHORT_TERM',
  'UNIVERSITY_LONG_TERM',
] as const;

export type Degree = typeof DEGREES[number];

export const JOB_COLLARS = [
  'white_collar_graduate',
  'white_collar_other',
  'blue_collar_graduate',
  'blue_collar',
] as const;

export type CollarClassification = typeof JOB_COLLARS[number];

const SQUARELIFE_TARIFFS = ['featherBuBasic', 'featherBuPremium'] as const;
export type SquarelifeTariff = typeof SQUARELIFE_TARIFFS[number];

export type DisabilityGroupId = 'signup';

export type DisabilityQuestionnaire = PartialQuestionnaire<
  Disability,
  DisabilityGroupId
>;

type DisabilityBaseQuotesDto = {
  dateOfBirth: string;
  officeWork: boolean;
  degree: Degree;
  monthlyPayout: number;
  heightInCm: number;
  weightInKg: number;
};

export type DisabilityNotPersistedQuotesDto = DisabilityBaseQuotesDto & {
  persistQuote?: false;
};

export type DisabilityPersistedQuotesDto = DisabilityBaseQuotesDto & {
  persistQuote: true;
  premiumCoverage: boolean;
  questionnaireId: string;
};

export type DisabilityQuotesResponse = {
  id: string;
  basicPrice: number;
  premiumPrice: number;
  jobCollar: CollarClassification;
};

export const DisabilityRequiredAnswersForCalculatorSchema = z.object({
  dateOfBirth: z.string(),
  officeWork: z.boolean(),
  degree: z.enum(DEGREES),
  height: z.object({
    inCm: z.number(),
    inFt: z.number(),
  }),
  weight: z.object({
    inKg: z.number(),
    inLbs: z.number(),
  }),
  monthlyPayout: z.number(),
});

export const DisabilityRequiredAnswersForQuoteSchema = z.object({
  dateOfBirth: z.string(),
  occupation: z.enum(OCCUPATIONS),
  monthlyNetIncome: z.number(),
  officeWork: z.boolean(),
  degree: z.enum(DEGREES),
  height: z.object({
    inCm: z.number(),
    inFt: z.number(),
  }),
  weight: z.object({
    inKg: z.number(),
    inLbs: z.number(),
  }),
  monthlyPayout: z.number(),
  addOns: z.object({
    premiumCoverage: z.boolean(),
  }),
});

export type DisabilityTariffInfo = {
  monthlyPayout: number;
  addOns: {
    premiumCoverage: boolean;
  };
};

export type DisabilityPersonalInfo = {
  dateOfBirth: string;
  occupation: Occupation;
  monthlyNetIncome: number;
  officeWork: boolean;
  degree: Degree;
  height: {
    inCm: number;
    inFt: number;
  };
  weight: {
    inKg: number;
    inLbs: number;
  };
  jobCollar?: CollarClassification;
};

export type DisabilityQuestionnaireAnswers = {
  personalInfo: DisabilityPersonalInfo;
  tariffInfo: DisabilityTariffInfo;
};

export type DisabilityQuestionnaireLabels = {
  labels: {
    title: {
      personalInfo: Record<keyof DisabilityPersonalInfo, string>;
      tariffInfo: Record<keyof DisabilityTariffInfo, string>;
    };
  };
  questionOrder: {
    personalInfo: (keyof DisabilityPersonalInfo)[];
    tariffInfo: (keyof DisabilityTariffInfo)[];
  };
};

export type DisabilitySquarelifePersonalInfo = {
  origin: number;
  tariff: SquarelifeTariff;
  bmi: {
    weight: number;
    height: number;
  };
  coverage: number;
  end_age: number;
  job_collar: CollarClassification;
};
