import { toast } from '@popsure/dirty-swan';
import {
  fetchReferralUserData,
  updateReferralUserData,
} from 'features/referralEngine/actions';
import {
  REFERRAL_USER_DATA_REQUEST_TYPE,
  REFERRAL_USER_DATA_UPDATE_TYPE,
} from 'features/referralEngine/constants';
import {
  ReferralEngineDispatch,
  ReferralUserData,
} from 'features/referralEngine/models';
import { getReferralUserData } from 'features/referralEngine/selectors';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReferralPayoutDetailsView } from './ReferralPayoutDetails.view';

export const ReferralPayoutDetailsContainer = () => {
  const dispatch = useDispatch<ReferralEngineDispatch>();
  const referralUserData = useSelector(getReferralUserData);
  const { loading: fetchLoading, error: fetchError } = useRequestStatus(
    REFERRAL_USER_DATA_REQUEST_TYPE
  );
  const { loading: updateLoading, error: updateError } = useRequestStatus(
    REFERRAL_USER_DATA_UPDATE_TYPE
  );

  useEffect(() => {
    dispatch(fetchReferralUserData);
  }, [dispatch]);

  const handleFormSubmit = async (newReferralData: ReferralUserData) => {
    const status = await dispatch(updateReferralUserData(newReferralData));
    if (status === 'SUCCESS') {
      toast('Details saved', {
        type: 'success',
      });
    }
  };

  return (
    <ReferralPayoutDetailsView
      referralData={referralUserData}
      onSubmit={handleFormSubmit}
      fetchLoading={fetchLoading}
      fetchError={fetchError}
      updateLoading={updateLoading}
      updateError={updateError}
    />
  );
};
