import LoadingSpinner from 'components/loadingSpinner';
import React from 'react';

const withLoadingSpinner = function (
  WrappedComponent,
  { isLoading, loadData },
  LoadingView = LoadingSpinner
) {
  class WithLoadingSpinner extends React.Component {
    public componentDidMount() {
      loadData(this.props);
    }

    public render() {
      const loading = isLoading(this.props);

      if (loading) {
        return <LoadingView />;
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return WithLoadingSpinner;
};

export default withLoadingSpinner;
