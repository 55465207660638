import type {
  BikeClaims,
  Questionnaire as BikeQuestionnaire,
} from 'features/bikeClaims/models';
import Questionnaire from 'features/questionnaireFramework';

interface ViewProps {
  questionnaire: BikeQuestionnaire;
  questionnaireAnswers: Partial<BikeClaims>;
  handleAnswerQuestion: (answer: Partial<BikeClaims>) => void;
}

export const BikeClaimsView = ({
  questionnaireAnswers,
  handleAnswerQuestion,
  questionnaire,
}: ViewProps) => {
  return (
    <Questionnaire
      featureName="Bike Claims"
      questionnaire={questionnaire}
      questionnaireAnswers={questionnaireAnswers}
      handleAnswerQuestion={handleAnswerQuestion}
    />
  );
};
