import { TFunction } from '@getpopsure/i18n-react';
import { isDateFuture } from '@getpopsure/public-utility';
import dayjs from 'dayjs';

import { DateOfEventError } from '../models';

export const getDateOfEventRange = (
  policyStartDate: string
): { min: number; max: number } => ({
  min: dayjs(policyStartDate).isValid()
    ? dayjs(policyStartDate).year()
    : dayjs().year(),
  max: dayjs().year(),
});

export const validateDateOfEvent = (
  t: TFunction,
  answer: string,
  policyStartDate: string
): string | null => {
  const error = retrieveDateOfEventError(answer, policyStartDate);

  if (error === 'FUTURE_DATE') {
    return t(
      'claims.liability.questionnaire.dateofevent.error.1',
      'This date cannot be in the future.'
    );
  }

  if (error === 'AFTER_POLICY_START_DATE') {
    return t('claims.liability.questionnaire.dateofevent.error.2', {
      defaultValue:
        'The earliest date is the policy start date ({{policyStartDate}}).',
      policyStartDate: dayjs(policyStartDate).isValid()
        ? dayjs(policyStartDate).format('DD MMM YYYY')
        : '',
    });
  }

  return null;
};

export const retrieveDateOfEventError = (
  answer: string,
  policyStartDate: string
): DateOfEventError | null => {
  if (isDateFuture(answer)) {
    return 'FUTURE_DATE';
  }

  if (isDateFuture(policyStartDate, answer)) {
    return 'AFTER_POLICY_START_DATE';
  }

  return null;
};
