import { Card, CardProps } from '@popsure/dirty-swan';
import { wavingHand } from 'features/referralEngine/assets';
import { ReferralModal } from 'features/referralEngine/components';
import { APIResponseError } from 'models/error';
import { ReactNode } from 'react';
import { useSafeTranslation } from 'shared/i18n';

interface ReferralCodeGeneratorViewProps {
  cardProps?: CardProps;
  onButtonClick: () => void;
  openModal?: boolean;
  referralCode?: string;
  handleCloseModal(): void;
  loading?: boolean;
  error?: APIResponseError;
  icon?: ReactNode;
}

export const ReferralCodeGeneratorView = ({
  cardProps,
  handleCloseModal,
  onButtonClick,
  openModal,
  referralCode,
  loading,
  error,
  icon,
}: ReferralCodeGeneratorViewProps) => {
  const { t } = useSafeTranslation();

  return (
    <div>
      <Card
        density="compact"
        title={t('referralengine.generator.button.title', 'Refer a friend')}
        titleVariant="small"
        icon={icon || <img width="24" height="24" src={wavingHand} alt="" />}
        actionIcon={null}
        onClick={() => onButtonClick()}
        {...cardProps}
      />
      {openModal && (
        <ReferralModal
          error={error}
          loading={loading}
          referralCode={referralCode}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};
