import { NavButton } from 'components/NavButton';

const Navigation = ({
  title,
  previousScreen,
  previousUrl,
}: {
  title?: string;
  previousScreen: string;
  previousUrl: string;
}) => (
  <div>
    <NavButton
      path={previousUrl}
      data-cy="navigation-back"
      title={previousScreen}
      renderInPortal
    />
    {title && <h1 className="p-h1">{title}</h1>}
  </div>
);

export default Navigation;
