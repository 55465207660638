import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

export const Unsubscribed = () => {
  const { t } = useSafeTranslation();

  return (
    <div className={`ta-center w100 ${styles.container}`}>
      <div className="p-body">
        <div className="d-flex fd-column ai-center">
          <h1 className="p-h1">
            {t(
              'subscription.unsubscribed.header',
              'You have successfully unsubscribed'
            )}
          </h1>
          <p className="p-p mt8">
            {t(
              'subscription.unsubscribed.description',
              'You will no longer receive these kinds of messages'
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
