import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { ClaimsAction } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import {
  DentalClaims,
  DentalClaimsDispatch,
  SubmitInfo,
} from 'features/dentalClaims/models';
import { APIResponseError } from 'models/error';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';

export const storeDentalClaimsAnswers = (
  answer: Partial<DentalClaims>
): ClaimsAction => ({
  type: 'STORE_DENTAL_CLAIM',
  dental: answer,
});

export const flushDentalClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_DENTAL_CLAIM',
});

export const retrievePolicyDetails =
  (policyId: string) =>
  async (
    dispatch: DentalClaimsDispatch
  ): Promise<{ planId: string | null } | null> => {
    const requestType: RequestType = 'LOAD_MY_POLICY_DETAIL';
    dispatch(setRequestInProcess(true, requestType));

    try {
      const {
        data: {
          attributes: { planId },
        },
      } = await endpoint.getPolicyDetail(policyId);
      dispatch(setRequestInProcess(false, requestType));

      return {
        planId: planId ?? null,
      };
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      return null;
    }
  };

export const submitClaim =
  (answers: SubmitInfo) =>
  async (dispatch: DentalClaimsDispatch): Promise<ActionResponse> => {
    const requestType: RequestType = 'CREATE_CLAIM';
    dispatch(setRequestInProcess(true, requestType));

    try {
      await endpoint.submitClaim(answers);
      dispatch(setRequestInProcess(false, requestType));
      return 'SUCCESS';
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      return 'ERROR';
    }
  };
