import classNames from 'classnames';
import { ReactNode } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import styles from './style.module.scss';

interface DropdownProps {
  onOutsideClick: () => void;
  children: ReactNode;
}

interface DropdownListProps {
  children: ReactNode;
  isExpanded: boolean;
}

export interface DropdownActionProps {
  text: string;
  isDangerous: boolean;
  onClick: () => void;
}

export const DropdownAction = ({
  text,
  isDangerous,
  onClick,
  ...props
}: DropdownActionProps) => {
  return (
    <button
      type="button"
      className={classNames('ds-interactive-component', {
        [styles['dropdown-item']]: true,
        [styles['dropdown-item--danger']]: isDangerous,
      })}
      onClick={onClick}
      {...props}
    >
      {text}
    </button>
  );
};

export const DropdownList = ({ isExpanded, children }: DropdownListProps) => {
  return (
    <div
      className={classNames({
        [styles.dropdown]: true,
        [styles['dropdown--expanded']]: isExpanded,
      })}
    >
      {children}
    </div>
  );
};

export const Dropdown = ({ onOutsideClick, children }: DropdownProps) => {
  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      {children}
    </OutsideClickHandler>
  );
};
