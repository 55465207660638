import { trackStructuredEvent } from '@getpopsure/analytics';
import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import {
  StudentChecklistDispatch,
  uploadStudentChecklistDocument,
} from 'features/studentChecklist/actions';
import { InlineButton } from 'features/studentChecklist/components';
import {
  EStudentChecklistRequestTypes,
  StepProps,
} from 'features/studentChecklist/models';
import { getStudentChecklist } from 'features/studentChecklist/selectors';
import {
  getUploadedFilesPreview,
  handleDownloadDocument,
} from 'features/studentChecklist/utils';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'reducers';
import { getRequestError, getRequestInProcess } from 'selectors/requests';
import { useSafeTranslation } from 'shared/i18n';

import { ProofOfEnrollmentModal } from './components';
import style from './ProofOfEnrollment.module.scss';

export const ProofOfEnrollment = ({
  completed,
  submittedChecklist,
}: StepProps) => {
  const { t } = useSafeTranslation();
  const { policyId } = useParams<{ policyId: string }>();
  const loading = useSelector((state: AppState) =>
    getRequestInProcess(state, EStudentChecklistRequestTypes.UPLOAD_DOCUMENT)
  );
  const error = useSelector((state: AppState) =>
    getRequestError(state, EStudentChecklistRequestTypes.UPLOAD_DOCUMENT)
  );
  const proofOfUniURL =
    useSelector(getStudentChecklist(policyId))?.proofOfUniEnrollmentUrl ?? null;
  const dispatch = useDispatch<StudentChecklistDispatch>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [file, setFile] = useState<File | string | null>(proofOfUniURL);

  useEffect(() => {
    if (proofOfUniURL) setFile(proofOfUniURL);
  }, [openModal, proofOfUniURL]);

  /**
   * Component clean-up
   */
  useEffect(() => {
    return () => {
      setFile(null);
      setOpenModal(false);
    };
  }, []);

  const onCloseModal = () => {
    setFile(null);
    setOpenModal((modal) => !modal);
  };

  const onOpenModal = () => setOpenModal(true);

  const handleFileSelect = (files: File[]) => setFile(files[0]);

  const filePreview = getUploadedFilesPreview(file);
  const handleRemoveFile = () => setFile(null);

  const validFile = !!(file && typeof file !== 'string');

  const onSubmit = async () => {
    trackStructuredEvent({
      category: 'STUDENT_CHECKLIST',
      action: 'SUBMITED_PROOF_OF_ENROLLMENT',
    });
    if (!file || typeof file === 'string') return;

    const status = await dispatch(
      uploadStudentChecklistDocument(policyId, 'proofOfUniEnrollmentId', file)
    );

    if (status === 'SUCCESS') {
      onCloseModal();
    }
  };

  const handleDownloadLetter = () => {
    if (proofOfUniURL) handleDownloadDocument(proofOfUniURL);
  };

  if (submittedChecklist)
    return (
      <InlineButton onClick={handleDownloadLetter}>
        Download letter
      </InlineButton>
    );

  /**
   * Student Checklist does not allow edition of submitted data
   */
  if (completed) {
    return (
      <InlineButton onClick={handleDownloadLetter}>
        {t(
          'studentChecklist.proofOfEnrollment.downloadButton.title',
          'Download letter'
        )}
      </InlineButton>
    );
  }

  return (
    <>
      <p className="p-p p-p--small tc-grey-500">
        {t(
          'studentChecklist.proofOfEnrollment.description',
          'This proof of enrollment is provided by your university.'
        )}
      </p>
      <Button
        className={classNames('w100 wmx3 mt8', style.buttonContainer)}
        onClick={onOpenModal}
        data-testid="proof-of-enrollment"
      >
        {t(
          'studentChecklist.proofOfEnrollment.uploadButton.title',
          'Upload letter'
        )}
      </Button>
      {openModal &&
        createPortal(
          <ProofOfEnrollmentModal
            handleFileSelect={handleFileSelect}
            onSubmit={onSubmit}
            uploadedFile={filePreview}
            handleRemoveFile={handleRemoveFile}
            loading={loading}
            error={error?.message}
            validFile={validFile}
            onClose={onCloseModal}
          />,
          document.body
        )}
    </>
  );
};
