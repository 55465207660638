import { Claim } from 'models/claims';
import { axiosClient } from 'shared/api/axiosClient';

export const getClaims = async (): Promise<Claim[]> => {
  try {
    const { data } = await axiosClient.get<Claim[]>('/me/claims');

    return data;
  } catch (error) {
    throw new Error('[Claims dashboard] Something went wrong');
  }
};
