import Navigation from 'components/common/navigation';
import PhoneInput from 'components/phoneInput';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import routes from 'constants/routes';
import type { ViewProps } from 'features/claims/containers/legalClaim/confirmInfo';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { APIResponseError } from 'models/error';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { generatePath, useHistory } from 'react-router-dom';
import { formatErrorMessageFromError } from 'selectors/requests';
import api from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';
import { checkPhoneNumber } from 'shared/util/checkPhoneNumber';

import styles from './style.module.scss';

const ConfirmInfo = ({
  policyInfo: { policyId, firstName, lastName },
  loading,
  initialValue,
  onSubmitInfo,
}: ViewProps) => {
  const [policyNumber, setPolicyNumber] = useState<string | undefined>();
  const [requestInProcess, setRequestInProcess] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(initialValue || '');
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const history = useHistory();

  const { t } = useSafeTranslation();

  useEffect(() => {
    const retrievePolicyNumber = async () => {
      setRequestInProcess(true);

      try {
        const {
          data: {
            attributes: { policyNumber: currentPolicyNumber },
          },
        } = await api.getPolicyDetail(policyId);
        setPolicyNumber(currentPolicyNumber);
      } catch (error) {
        throw new Error(formatErrorMessageFromError(error as APIResponseError));
      } finally {
        setRequestInProcess(false);
      }
    };

    retrievePolicyNumber();
  }, [policyId]);

  const handlePhoneNumberSubmission = () => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`
    );

    const phoneNumberValidationError = checkPhoneNumber(parsedPhoneNumber);

    if (phoneNumberValidationError) {
      setPhoneNumberError(phoneNumberValidationError);
      return;
    }

    onSubmitInfo({ phoneNumber: parsedPhoneNumber?.format('E.164') });

    history.push(
      generatePath(routes.me.claims.legalClaim.describeClaim.path, {
        policyId,
      })
    );
  };

  if (loading || requestInProcess) {
    return <TimedLoadSpinner />;
  }

  return (
    <div className={`wmx11 w100 ${styles.container}`}>
      <form
        className="p-body"
        onSubmit={(e) => {
          e.preventDefault();
          handlePhoneNumberSubmission();
        }}
      >
        <Navigation
          previousScreen={t(
            'claims.legal.previousScreen.myPolicy',
            'My policy'
          )}
          previousUrl={generatePath(routes.me.policies.detail.path, {
            policyId,
          })}
        />
        <div className={styles['header-container']}>
          <div className="p-p tc-primary-500 mt40">
            {t('claims.legal.step.1', 'Step 1')}
          </div>
          <h1 className="p-h2 mt8">
            {t('claims.legal.confirmInformation.title', 'Confirm information')}
          </h1>
          <div className="p-p mt8">
            {t(
              'claims.legal.confirmInformation.description',
              'You can use this form to submit a claim for your legal insurance policy.'
            )}
          </div>
        </div>
        <div className={styles['form-container']}>
          <div className={`mt24 ${styles['user-info-container']} wmx7`}>
            <div className={styles['user-info-content']}>
              <div className="p-p">
                {t('claims.legal.confirmInformation.name', 'Name:')}
              </div>
              <div className="p-p tc-primary-500">
                {firstName &&
                  lastName &&
                  capitalizeName({ firstName, lastName })}
              </div>
            </div>
            <div className={styles['user-info-content']}>
              <div className="p-p">
                {t(
                  'claims.legal.confirmInformation.policyNumber',
                  'Policy No:'
                )}
              </div>
              <div className="p-p tc-primary-500">{policyNumber}</div>
            </div>
          </div>
          <div className={`mt16 ${styles['user-info-container']} wmx7`}>
            <div className="p-p">
              {t(
                'claims.legal.confirmInformation.telephoneNumber.description',
                'Please enter a telephone number where you can be reached regarding this claim:'
              )}
            </div>
            <div className="mt16 tc-primary-500 wmx5">
              <PhoneInput
                value={phoneNumber}
                onChange={setPhoneNumber}
                placeholder="Telephone number"
              />
            </div>
            <AnimateHeight
              duration={300}
              height={phoneNumberError.length > 0 ? 'auto' : 0}
            >
              <div className="p-notice--danger mt16 wmx4">
                {phoneNumberError}
              </div>
            </AnimateHeight>
          </div>
          <button
            className={`p-btn--primary mt32 wmn3 ${styles.button}`}
            disabled={!phoneNumber}
            type="submit"
          >
            {t(
              'claims.legal.confirmInformation.continueButton.caption',
              'Continue'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ConfirmInfo;
