import { Plan } from '@getpopsure/legal-insurance-pricing-engine-v2';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';

import { LegalQuotePlan } from '../models';

export const CTACell = ({
  value,
  showPrice,
  planMapping,
}: {
  value: Plan;
  showPrice: boolean;
  planMapping: LegalQuotePlan;
}) => {
  return (
    <div className="wmn3">
      <p className="p-h3">
        {planMapping[value].shortName}{' '}
        {showPrice && (
          <span className="tc-purple-500 ml4 p--serif">
            {englishFormattedEuroCurrency(planMapping[value].price, true)}
          </span>
        )}
      </p>
    </div>
  );
};
