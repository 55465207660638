import { Region } from '@getpopsure/public-models';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { mapCheckoutDeclineCodeError } from 'features/checkout/models';
import { CheckoutDeclinedError } from 'models/error';
import {
  InsuranceTypes,
  ValidVerticalUrlParams,
} from 'models/insurances/types';
import { PaymentMethod, StripeBillingAddress } from 'models/paymentMethods';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import browserHistory from 'shared/browserHistory';
import { paramsSetUrl } from 'shared/util/paramsSetUrl';

const DATE_FORMAT = 'DD MMM YYYY';
const history = browserHistory;

export const getPaymentOptionById = (
  id: string,
  paymentOptions: PaymentMethod[]
): PaymentMethod | undefined => paymentOptions.find((item) => item.id === id);

export const formatProratedPeriod = (period: {
  start: string;
  end: string;
}): string => {
  const startDate = dayjs(period?.start).format(DATE_FORMAT);
  const endDate = dayjs(period?.end).format(DATE_FORMAT);

  if (startDate === endDate) {
    return `${startDate}`;
  }

  return `${startDate} - ${endDate}`;
};

export const redirectSuccessfulCheckout = (
  verticalStateId?: keyof GenericQuestionnaireState,
  mainPolicyId?: string
) => {
  window.scrollTo(0, 0);
  history.push(
    paramsSetUrl<
      'signupSuccess' | 'mainPolicyId',
      ValidVerticalUrlParams | string
    >(routes.me.policies.path, [
      { key: 'signupSuccess', value: verticalStateId ?? '' },
      { key: 'mainPolicyId', value: mainPolicyId ?? '' },
    ])
  );
};

const isCheckoutDeclineError = (error: Error): error is CheckoutDeclinedError =>
  'decline_code' in error || 'code' in error;

export const retrieveCheckoutErrorMessage = (error: Error): string => {
  if (isCheckoutDeclineError(error)) {
    return (
      mapCheckoutDeclineCodeError[error.decline_code] ||
      mapCheckoutDeclineCodeError[error.code] ||
      mapCheckoutDeclineCodeError.generic
    );
  }

  return mapCheckoutDeclineCodeError.generic;
};

export const isStripeBillingAddress = (
  /* eslint-disable @typescript-eslint/no-explicit-any */
  value: any,
  includeCountryValidation = true
): value is StripeBillingAddress =>
  Boolean(
    value?.line1 &&
      value?.postal_code &&
      value?.state &&
      (includeCountryValidation ? value?.country : true) &&
      value?.city
  );

export const getRegionFromType = (
  type: InsuranceTypes,
  regionOfPurchase?: Region
): Region => {
  switch (type) {
    case 'HOUSEHOLD':
    case 'PUBLIC_HEALTH':
    case 'PRIVATE_HEALTH':
    case 'LIABILITY':
    case 'CAR':
    case 'JOB':
    case 'LIFE':
    case 'LIFE_V2':
    case 'INCOMING':
    case 'EXPAT_V2':
    case 'LEGAL':
    case 'LEGAL_V2':
    case 'DENTAL':
    case 'BIKE':
    case 'DOG_LIABILITY':
    case 'PET_HEALTH':
    case 'TRAVEL':
    case 'DISABILITY':
    case 'GENERIC':
    case 'COMPANY_LIFE':
    case 'COMPANY_HEALTH':
    case 'PENSION':
    case 'DENTAL_V2':
    case 'BASIS_PENSION':
    case 'TRAVEL_HEALTH_V1':
    case 'BIKE_V2':
      return 'de';
    case 'INCOMING_ES':
      return 'es';
    case 'TRAVEL_FR':
      return 'fr';
    case 'INCOMING_EU':
      return regionOfPurchase ?? 'de';
    case 'INCOMING_LT':
      return regionOfPurchase ?? 'de';
    case 'DOG_LIABILITY_ES':
      return 'es';
    default:
      return 'de';
  }
};
