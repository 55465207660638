import { useTranslation } from '@getpopsure/i18n-react';
import { Button } from '@popsure/dirty-swan';
import routes from 'constants/routes';
import { generatePath, useHistory } from 'react-router-dom';

export const EditAnswersButton = ({ className }: { className: string }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Button
      variant="filledWhite"
      data-cy="button-edit-answers"
      className={className}
      onClick={() =>
        history.push(
          generatePath(routes.insuranceTool.questionnaire.path, {
            insuranceType: 'quickRecommendation',
          })
        )
      }
    >
      {t('recommendationtool.overview.editanswers', 'Edit my answers')}
    </Button>
  );
};
