export type SrcSet = (string | undefined)[];
/**
 * Get a srcset string from an array of sources
 *
 * @example
 * getSrcSet(['./img.jpg', './img2x.jpg', './img3x.jpg'])
 * // returns './img.jpg 1x, ./img2x.jpg 2x, ./img3x.jpg 3x'
 *
 * @param srcSet
 */
export const getSrcSet = (srcSet?: SrcSet): string | undefined => {
  if (!srcSet) return undefined;

  /** Remove any undefined elements */
  const cleanArray = srcSet.filter((s) => s !== undefined) as string[];

  /** Return early if there are no items */
  if (cleanArray.length === 0) return undefined;

  let itemNumber = 0;

  return cleanArray
    .reduce((acc: string, current: string, index, array: string[]) => {
      const lastItem = index + 1 === array.length;
      if (current) {
        itemNumber++;
        return `${acc} ${current} ${itemNumber}x${lastItem ? '' : ','}`;
      }

      return acc;
    }, '')
    ?.trim();
};
