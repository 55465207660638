import { AxiosInstance } from 'axios';

// Successful, pending and failed charges
// ----------------------------------------------------------------------------
export const getBillingHistoryCharges = (
  network: AxiosInstance,
  fetchAfter?: string
) => {
  return network.get('/billing-history/charges', { params: { fetchAfter } });
};

// Receipts
// ----------------------------------------------------------------------------

export const createBillingHistoryReceipt = (
  network: AxiosInstance,
  stripeId: string
) => {
  return network.post(`/billing-history/receipts`, { stripeId });
};

export const getBillingHistoryReceipt = (
  network: AxiosInstance,
  stripeId: string
) => {
  return network.get(`/billing-history/receipts/${stripeId}`);
};

// Dispute invoices
// ----------------------------------------------------------------------------
export const getBillingHistoryDisputeInvoices = (network: AxiosInstance) => {
  return network.get('/billing-history/disputes/invoices');
};
