import { IconWrapperProps } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { ComponentType } from 'react';

import styles from './styles.module.scss';

export interface DetailsSectionItem {
  icon?: ComponentType<IconWrapperProps>;
  title: string;
  description?: string;
}

export interface DetailsSectionProps {
  items?: DetailsSectionItem[];
  title: string;
}

const DetailsSection = ({
  items = [],
  title: sectionTitle,
}: DetailsSectionProps) => {
  if (!items || !items.length) {
    return null;
  }

  return (
    <>
      <h3 className="p-h2 mt40 mb16">{sectionTitle}</h3>

      {items.map(({ icon: Icon, title, description }) => (
        <div key={title} className="d-flex ai-center gap16 my16">
          {Icon && (
            <div>
              <div
                className={classNames(
                  'd-inline-flex ai-center jc-center br-circle bg-primary-100',
                  styles.iconWrapper
                )}
              >
                <Icon size={20} />
              </div>
            </div>
          )}
          <div>
            <h4 className="p-h4">{title}</h4>
            {description && (
              <p className="p-p p-p--small tc-grey-600">{description}</p>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export { DetailsSection };
