import { paramsSetUrl } from '@getpopsure/public-utility';
import * as Sentry from '@sentry/react';
import { fetchPolicyDetail } from 'actions/policies';
import ErrorView from 'components/error';
import LoadingSpinner from 'components/loadingSpinner';
import { useWindowSize } from 'hooks/useWindowSize';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AppState } from 'reducers';
import { getPolicyDetail } from 'selectors/policies';

export const DogLiabilityClaims = () => {
  const size = useWindowSize();
  const { policyId = '' } = useParams<{ policyId?: string }>();
  const dispatch = useDispatch();

  const policy = useSelector((state: AppState) =>
    getPolicyDetail(state, policyId)
  );

  useEffect(() => {
    dispatch(fetchPolicyDetail(policyId));
  }, [dispatch, policyId]);

  if (!policyId) {
    Sentry.captureException(
      `[Dog Liability claims] Trying to initiate a claim without a policy id.`
    );
    return <ErrorView error="Missing policy id" />;
  }

  if (!policy) {
    return <LoadingSpinner />;
  }

  const { insuredPerson, policyNumber = '-' } = policy.attributes;

  const params = [
    {
      key: 'transparentBackground',
      value: '1',
    },
    {
      key: 'policyId',
      value: policyId,
    },
    {
      key: 'policyHolderName',
      value: `${insuredPerson?.name?.firstName} ${insuredPerson?.name?.lastName}`,
    },
    {
      key: 'policyNumber',
      value: policyNumber,
    },
  ];

  return (
    <>
      <Helmet>
        <script async src="https://tally.so/widgets/embed.js" />
      </Helmet>
      <iframe
        width={size.width ?? '100%'}
        height={size.height ?? '100%'}
        data-tally-src={paramsSetUrl('https://tally.so/r/3qdld9', params)}
        title="Submit a claim"
      />
    </>
  );
};
