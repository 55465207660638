import dayjs from 'dayjs';
import type { Policy } from 'models/policies';
import { RoutesObject } from 'models/questionnaireFramework';
import { QuestionReviewValue } from 'models/review';
import { TFunction } from 'shared/i18n';
import { isUnder18 } from 'shared/util/isUnder18';

import {
  contentMapping,
  ExpatDependentQuestionnaire,
  ExpatQuestionnaire,
  genderMapping,
  Occupation,
  occupationMapping,
} from './expatModel';

const getOccupation = (occupation: Occupation | undefined, t: TFunction) => {
  if (!occupation) {
    return null;
  }
  const mappedOccupation = occupationMapping(t)[occupation];
  return typeof mappedOccupation === 'string'
    ? mappedOccupation
    : mappedOccupation.title;
};

const isExpat = (
  answers: Partial<ExpatQuestionnaire | ExpatDependentQuestionnaire>
): answers is ExpatQuestionnaire => {
  return 'email' in answers;
};

const isExpatDependent = (
  answers: Partial<ExpatQuestionnaire | ExpatDependentQuestionnaire>
): answers is ExpatDependentQuestionnaire => {
  return 'isMainPolicyTheLegalGuardian' in answers;
};

const isExpatRoute = (
  answers:
    | RoutesObject<ExpatQuestionnaire>
    | RoutesObject<ExpatDependentQuestionnaire>
): answers is RoutesObject<ExpatQuestionnaire> => {
  return 'email' in answers;
};

const isExpatDependentRoute = (
  answers:
    | RoutesObject<ExpatQuestionnaire>
    | RoutesObject<ExpatDependentQuestionnaire>
): answers is RoutesObject<ExpatDependentQuestionnaire> => {
  return 'isMainPolicyTheLegalGuardian' in answers;
};

export const answersToReviewValues = (
  t: TFunction,
  answers: Partial<ExpatQuestionnaire | ExpatDependentQuestionnaire>,
  routes: RoutesObject<ExpatQuestionnaire | ExpatDependentQuestionnaire>,
  policy?: Policy
): QuestionReviewValue[] => [
  {
    title: t('expat.qnr.review.dateOfBirth.title', 'Date of birth'),
    value: dayjs(answers.dateOfBirth || '').format('DD MMM YYYY'),
    path: routes.dateOfBirth.path || '',
  },
  {
    title: t('expat.qnr.review.arrivalDate.title', 'Arrival date to Germany'),
    value: dayjs(answers.arrivalDate || '').format('DD MMM YYYY'),
    path: routes.arrivalDate.path || '',
  },
  {
    title: t('expat.qnr.review.occupation.title', 'Occupation'),
    value: getOccupation(answers.occupation, t),
    path: routes.occupation.path || '',
  },
  {
    title: t('expat.qnr.review.employedAnnualIncome.title', 'Annual income'),
    value: answers.employedAnnualIncome
      ? String(answers.employedAnnualIncome)
      : null,
    path: routes.employedAnnualIncome.path || '',
  },
  {
    title: t('expat.qnr.review.studentType.title', 'Type of student'),
    value: answers.typeOfStudent ? String(answers.typeOfStudent) : null,
    path: routes.typeOfStudent.path || '',
  },
  {
    title: t('expat.qnr.review.universityName.title', 'University name'),
    value: answers.universityName ? String(answers.universityName) : null,
    path: routes.universityName.path || '',
  },
  {
    title: t(
      'expat.qnr.review.jobOfferStartDate.title',
      'Employment start date'
    ),
    value: answers.employmentStartDate
      ? String(answers.employmentStartDate)
      : null,
    path: routes.employmentStartDate.path || '',
  },
  {
    title: t('expat.qnr.review.plan.title', 'Plan'),
    value: answers.quote?.selectedQuote
      ? contentMapping[answers.quote.selectedQuote.planId].name
      : null,
    path: routes.quote.path || '',
  },
  {
    title: t('expat.qnr.review.name.title', 'Full name'),
    value: `${answers.name?.firstName} ${answers.name?.lastName}`,
    path: routes.name.path || '',
  },
  {
    title: t('expat.qnr.review.email.title', 'Email'),
    value: isExpat(answers) ? String(answers.email) : null,
    path: isExpatRoute(routes) ? routes.email.path : '',
  },
  {
    title: t('expat.qnr.review.gender.title', 'Gender'),
    value: answers.gender ? genderMapping(t)[answers.gender] : null,
    path: routes.gender.path || '',
  },
  {
    title: t(
      'expat.qnr.review.publicGermanyInsurance.title',
      'Currently on German public health insurance?'
    ),
    value: answers.hasPublicInsurance
      ? t('expat.qnr.review.publicPrivateGermanyInsurance.yes', 'Yes')
      : t('expat.qnr.review.publicPrivateGermanyInsurance.no', 'No'),
    path: routes.hasPublicInsurance.path || '',
  },
  {
    title: t('expat.qnr.review.citizenship.title', 'Citizenship'),
    value: (answers.citizenship || [])
      .map((country) => country.name)
      .join(', '),
    path: routes.citizenship.path || '',
  },
  {
    title: t(
      'expat.qnr.review.lastPermanentResidency.title',
      'Last country of residence'
    ),
    value: String(answers.lastPermanentResidency?.name),
    path: routes.lastPermanentResidency.path || '',
  },
  {
    title: t('expat.qnr.review.startDate.title', 'Coverage start date'),
    value: dayjs(answers.startDate || '').format('DD MMM YYYY'),
    path: routes.startDate.path || '',
  },
  {
    title: t(
      'expat.qnr.review.dependentsToCover.title',
      'Have loved ones to cover?'
    ),
    value:
      isExpat(answers) && !isUnder18(answers.dateOfBirth)
        ? answers.addDependents
          ? t('expat.qnr.review.dependentsToCover.yes', 'Yes')
          : t('expat.qnr.review.dependentsToCover.no', 'No')
        : null,
    path: isExpatRoute(routes) ? routes.addDependents.path : '',
  },
  {
    title: t('expat.qnr.review.mainPolicyTheLegalGuardian.title', {
      defaultValue: '{{policyHolder}} parent / legal guardian?',
      policyHolder: `${policy?.attributes.insuredPerson?.name?.firstName} ${policy?.attributes.insuredPerson?.name?.lastName}`,
    }),
    value:
      isExpatDependent(answers) && isUnder18(answers.dateOfBirth)
        ? answers.isMainPolicyTheLegalGuardian
          ? t('expat.qnr.review.mainPolicyTheLegalGuardian.yes', 'Yes')
          : t('expat.qnr.review.mainPolicyTheLegalGuardian.no', 'No')
        : null,
    path: isExpatDependentRoute(routes)
      ? routes.isMainPolicyTheLegalGuardian.path
      : '',
  },
  {
    title: t(
      'expat.qnr.review.parentGuardianName.title',
      "Parent / legal guardian's name"
    ),
    value:
      isExpatDependent(answers) &&
      isUnder18(answers.dateOfBirth) &&
      !answers.isMainPolicyTheLegalGuardian
        ? `${answers.parentGuardianName.firstName} ${answers.parentGuardianName.lastName}`
        : null,
    path: isExpatDependentRoute(routes) ? routes.parentGuardianName.path : '',
  },
  {
    title: t(
      'expat.qnr.review.parentGuardianDateOfBirth.title',
      "Parent / legal guardian's date of birth"
    ),
    value:
      isExpatDependent(answers) &&
      isUnder18(answers.dateOfBirth) &&
      !answers.isMainPolicyTheLegalGuardian
        ? dayjs(answers.parentGuardianDateOfBirth || '').format('DD MMM YYYY')
        : null,
    path: isExpatDependentRoute(routes)
      ? routes.parentGuardianDateOfBirth.path
      : '',
  },
  {
    title: t(
      'expat.qnr.review.parentGuardianEmail.title',
      "Parent / legal guardian's email address"
    ),
    value:
      isExpatDependent(answers) &&
      isUnder18(answers.dateOfBirth) &&
      !answers.isMainPolicyTheLegalGuardian
        ? String(answers.parentGuardianEmail)
        : null,
    path: isExpatDependentRoute(routes) ? routes.parentGuardianEmail.path : '',
  },
];
