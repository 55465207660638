import { IconTile } from 'components/IconTile';
import SectionHeader from 'components/sectionHeader';
import { useSafeTranslation } from 'shared/i18n';

import adjustableSumIcon from './assets/adjustableSum.svg';
import mentalIcon from './assets/mental.svg';
import purseIcon from './assets/purse.svg';
import scanIcon from './assets/scan.svg';
import wheelChairIcon from './assets/wheelChair.svg';
import styles from './WhatsCoveredSection.module.scss';

export const WhatsCoveredSection = () => {
  const { t } = useSafeTranslation();

  return (
    <section className="bg-white py80">
      <div
        className={`p-container d-flex ai-center jc-between gap8 ${styles.titleContainer}`}
      >
        <div className={`d-flex fd-column ${styles.sectionHeaderContainer}`}>
          <SectionHeader
            title={t('disability.quote.whatscovered.title', "What's included?")}
          />
        </div>
      </div>
      <div
        className={`p-container d-grid c-gap24 r-gap32 mt32 ${styles.tileContainer}`}
      >
        <IconTile
          className={styles.tile}
          title={t(
            'disability.quote.whatscovered.tile.1.title',
            'Physical disabilities'
          )}
          iconSrc={wheelChairIcon}
          iconAlt="wheelchair"
        >
          {t(
            'disability.quote.whatscovered.tile.1.description',
            'Occupational disabilities that interrupt your work performance by more than 50% due to an illness, bodily injury or deterioration.'
          )}
        </IconTile>
        <IconTile
          className={styles.tile}
          title={t(
            'disability.quote.whatscovered.tile.2.title',
            'Mental disabilities'
          )}
          iconSrc={mentalIcon}
          iconAlt="mental disability"
          badge={{ title: 'Add-on' }}
        >
          {t(
            'disability.quote.whatscovered.tile.2.description',
            'Occupational disabilities that interrupt your work performance by more than 50% due to a mental or psychosomatic illness.'
          )}
        </IconTile>
        <IconTile
          className={styles.tile}
          title={t(
            'disability.quote.whatscovered.tile.3.title',
            'No abstract referral'
          )}
          iconSrc={scanIcon}
          iconAlt="scanning documents"
        >
          {t(
            'disability.quote.whatscovered.tile.3.description',
            'We only check whether you can perform a similar job that corresponds to your skills, experience and previous salary.'
          )}
        </IconTile>
        <IconTile
          className={styles.tile}
          title={t(
            'disability.quote.whatscovered.tile.4.title',
            'Adjustable sum'
          )}
          iconSrc={adjustableSumIcon}
          iconAlt="slider below money bill"
        >
          {t(
            'disability.quote.whatscovered.tile.4.description',
            'Depending on your age and health, you’re able to raise your monthly payout sum with a few additional documents.'
          )}
        </IconTile>
        <IconTile
          className={styles.tile}
          title={t(
            'disability.quote.whatscovered.tile.5.title',
            'Coverage pause'
          )}
          iconSrc={purseIcon}
          iconAlt="purse"
        >
          {t(
            'disability.quote.whatscovered.tile.5.description',
            "If you have been insured for more than 1 year, in the event of unemployment, you can pause your coverage until you're back on your feet."
          )}
        </IconTile>
      </div>
    </section>
  );
};
