import { AppState } from 'reducers';

export const getUserReferralCode = (state: AppState) =>
  state.user.userReferralInfo?.userReferralCode;

export const getValidReferrerCode = (state: AppState) =>
  state.user.userReferralInfo?.validReferrerCode;

export const getReferralUserData = (state: AppState) =>
  state.user.userReferralInfo?.referralUserData;

export const getReferrerCodeError = (state: AppState) =>
  state.user.userReferralInfo?.referrerCodeError;
