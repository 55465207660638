import { calendly, insurance } from '@getpopsure/private-constants';
import MoreQuestionsSection from 'components/moreQuestions';
import ReviewBadge from 'components/reviewBadge';
import { Household } from 'features/household/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';

import { AddonsSection } from './AddonsSection';
import giftBoxImage from './assets/giftBox.svg';
import { CalculatorSection } from './CalculatorSection';
import { FAQSection } from './FAQSection';
import styles from './QuotePage.module.scss';
import { WhatsCoveredSection } from './WhatsCoveredSection';
import { WhatsNotCoveredSection } from './WhatsNotCoveredSection';

export const QuotePage = ({
  onSubmitValue,
  routes,
}: CustomComponentProps<Household>) => {
  const { t } = useSafeTranslation();

  return (
    <div className={styles.container}>
      <div className="d-flex fd-column ai-center jc-center pt40 pb24">
        <img
          src={giftBoxImage}
          alt={t('page.household.quote.image.alt', 'opened gift box')}
        />
        <h1 className="p-h1 p--serif mt24">
          {t('page.household.quote.title', "Here's your quote")}
        </h1>
      </div>
      <CalculatorSection
        onSubmitValue={onSubmitValue}
        questionnaireRoutes={routes}
      />
      <WhatsCoveredSection />
      <AddonsSection />
      <WhatsNotCoveredSection />
      <div className="p-container d-flex ai-center jc-center mb56">
        <ReviewBadge />
      </div>
      <FAQSection />
      <MoreQuestionsSection
        bookACallUrl={calendly.householdContents}
        bookACallGACategory="HOUSEHOLD_INSURANCE"
        openInNewTab
        secondaryActionLink={insurance.household.policyStatement}
        showSecondaryIcon
      />
    </div>
  );
};
