import dayjs from 'dayjs';
import { mapBikeType } from 'models/bike';
import { Policy } from 'models/policies';
import { TFunction } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';

import { DATE_FORMAT } from '../../constants';
import { PolicyDetailsInfo } from '../../sections/PolicyDetails/PolicyDetails.models';

export const getBikeInfo = (policy: Policy, t: TFunction) => {
  const { planId, bikeType, brand, model, frameNumber } = policy.attributes;
  const infoArray: Array<PolicyDetailsInfo> = [];

  if (policy.attributes.policyNumber) {
    infoArray.push({
      id: 'policyNumber',
      title: t('myPolicies.policyDetails.policyNumber', 'Policy number'),
      value: policy.attributes.policyNumber,
    });
  }

  if (policy.attributes.insuredPerson) {
    infoArray.push({
      title: t('myPolicies.policyDetails.insuredPerson', 'Insured person'),
      value: capitalizeName(policy.attributes.insuredPerson.name),
    });
  }

  if (policy.attributes.startDate) {
    infoArray.push({
      id: 'startDate',
      title: t('myPolicies.policyDetails.startDate', 'Start date'),
      value: dayjs(policy.attributes.startDate).format(DATE_FORMAT),
    });
  }

  if (planId) {
    infoArray.push({
      title: t('myPolicies.policyDetails.bike.plan.title', 'Plan'),
      value:
        planId === 'COMFORT'
          ? t('myPolicies.policyDetails.bike.plan.comfort', 'Comfort')
          : t('myPolicies.policyDetails.bike.plan.basic', 'Basic'),
    });
  }

  if (bikeType) {
    infoArray.push({
      id: 'bikeType',
      title: t('myPolicies.policyDetails.bike.bikeType', 'Bike type'),
      value: mapBikeType[bikeType],
    });
  }

  if (brand) {
    infoArray.push({
      id: 'brand',
      title: t('myPolicies.policyDetails.bike.brand', 'Brand'),
      value: brand,
    });
  }

  if (model) {
    infoArray.push({
      id: 'model',
      title: t('myPolicies.policyDetails.bike.model', 'Model'),
      value: model,
    });
  }

  infoArray.push({
    id: 'frameNumber',
    title: t('myPolicies.policyDetails.bike.frameNumber', 'Frame number'),
    value: frameNumber,
  });

  infoArray.push({
    id: 'purchaseReceipts',
    title: t(
      'myPolicies.policyDetails.bike.purchaseReceipts',
      'Purchase receipts'
    ),
    value: policy.userUploads,
  });

  return infoArray;
};
