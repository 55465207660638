import { trackStructuredEvent } from '@getpopsure/analytics';
import { calendly } from '@getpopsure/private-constants';
import {
  createDisabilityQuoteAndQuestionnaire,
  CreateQuoteDispatch,
} from 'features/disability/actions';
import {
  Disability,
  DisabilityQuestionnaireAnswers,
  DisabilityRequiredAnswersForQuoteSchema,
} from 'features/disability/models';
import { createLabelTitles } from 'features/disability/utils';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import styles from './AddonsPage.module.scss';
import { CoverageSection } from './CoverageSection';
import { CustomizationSection } from './CustomizationSection';
import { PriceDetailsSection } from './PriceDetailsSection';

export const AddonsPage = ({
  onSubmitValue,
  questionnaireAnswers: answers,
}: CustomComponentProps<Disability>) => {
  const { t } = useSafeTranslation();

  const { loading } = useRequestStatus('CREATE_QUOTE');

  const dispatch = useDispatch<CreateQuoteDispatch>();

  const {
    calculatorQuote: { basicPrice = 0, premiumPrice = 0 } = {},
    addOns: { premiumCoverage: premiumSelectedFromReduxState = false } = {},
    monthlyPayout = 0,
  } = answers;

  const [premiumSelected, setPremiumSelected] = useState(
    !!premiumSelectedFromReduxState
  );

  const onContinueClick = async () => {
    const validationResult = DisabilityRequiredAnswersForQuoteSchema.safeParse({
      ...answers,
      addOns: { premiumCoverage: premiumSelected },
    });

    if (!validationResult.success) {
      throw new Error(
        `[Disability] Questionnaire answer validation errors:${validationResult.error.toString()}`
      );
    }

    const validatedAnswers = validationResult.data;

    const backendAnswers: DisabilityQuestionnaireAnswers = {
      personalInfo: {
        dateOfBirth: validatedAnswers.dateOfBirth,
        occupation: validatedAnswers.occupation,
        monthlyNetIncome: validatedAnswers.monthlyNetIncome,
        officeWork: validatedAnswers.officeWork,
        degree: validatedAnswers.degree,
        height: validatedAnswers.height,
        weight: validatedAnswers.weight,
      },
      tariffInfo: {
        monthlyPayout: validatedAnswers.monthlyPayout,
        addOns: validatedAnswers.addOns,
      },
    };

    const labelTitles = createLabelTitles(backendAnswers, t);

    const { status } = await dispatch(
      createDisabilityQuoteAndQuestionnaire(backendAnswers, {
        labels: {
          title: labelTitles,
        },
        questionOrder: {
          personalInfo: [
            'dateOfBirth',
            'occupation',
            'monthlyNetIncome',
            'officeWork',
            'degree',
            'height',
            'weight',
          ],
          tariffInfo: ['monthlyPayout', 'addOns'],
        },
      })
    );

    if (status === 'SUCCESS') {
      onSubmitValue(premiumSelected);
    }
  };

  const onScheduleCallClick = () => {
    trackStructuredEvent({
      category: 'DISABILITY_INSURANCE',
      action: 'CLICKED_BOOK_AN_APPOINTMENT',
    });
    window.Calendly.initPopupWidget({
      url: `${calendly.base}/occupational-disability`,
    });
  };

  return (
    <div className={`p-container ${styles.container}`}>
      <CoverageSection monthlyPayout={monthlyPayout} basicPrice={basicPrice} />
      <CustomizationSection
        premiumSelected={premiumSelected}
        setPremiumSelected={setPremiumSelected}
        price={premiumPrice - basicPrice}
      />
      <PriceDetailsSection
        premiumSelected={premiumSelected}
        basicPrice={basicPrice}
        premiumPrice={premiumPrice}
      />
      <div className="d-flex fd-column ai-center gap16 pt48 pb48">
        <button
          className={`p-btn--primary wmn4 ${loading ? 'p-btn--loading' : ''}`}
          data-cy="button-continue"
          onClick={onContinueClick}
          type="submit"
        >
          {t('disability.addons.continueButton.caption', 'Continue')}
        </button>
        <button
          className="p-btn--secondary bg-white wmn4"
          onClick={onScheduleCallClick}
          type="button"
        >
          {t('disability.addons.scheduleCallButton.caption', 'Schedule a call')}
        </button>
      </div>
    </div>
  );
};
