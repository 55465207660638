import {
  Disability,
  DisabilityQuestionnaire,
} from 'features/disability/models';

import { AddonsPage } from './AddonsPage';
import { NextSteps } from './NextSteps';
import { QuotePage } from './QuotePage';

export const quoteAndAddonsQNR = (): DisabilityQuestionnaire => ({
  monthlyPayout: {
    required: true,
    type: 'CUSTOM',
    retrieveAnswerObject: (monthlyPayout: number): Partial<Disability> => ({
      monthlyPayout,
    }),
    groupId: 'signup',
    component: QuotePage,
    retrieveNextPageId: () => 'addOns',
  },
  addOns: {
    required: true,
    type: 'CUSTOM',
    retrieveAnswerObject: (premiumSelected: boolean): Partial<Disability> => ({
      addOns: {
        premiumCoverage: premiumSelected,
      },
    }),
    groupId: 'signup',
    component: AddonsPage,
    retrieveNextPageId: () => 'nextSteps',
  },
  nextSteps: {
    required: false,
    type: 'CUSTOM',
    groupId: 'signup',
    component: NextSteps,
    retrieveNextPageId: () => 'form',
  },
});
