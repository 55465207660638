/* eslint-disable import/no-cycle */
import { Address } from '@getpopsure/public-models';
import * as Sentry from '@sentry/react';
import GermanAddress from 'features/healthCard/address';
import { MarkHealthCardAsReceived } from 'features/healthCard/MarkHealthCardAsReceived';
import PhotoUpload from 'features/healthCard/photoUpload';
import { RequestHealthCard } from 'features/healthCard/requestHealthCard';
import SendHealthCard from 'features/healthCard/sendHealthCard';
import { HealthCard } from 'models/healthCard';
import React from 'react';
import { AppState } from 'reducers';
import { getPolicyDetail } from 'selectors/policies/getPolicyDetail';

type HealthCardStepType =
  | 'COVERED'
  | 'REQUEST_HEALTH_CARD'
  | 'PHOTO_UPLOAD'
  | 'GERMAN_ADDRESS'
  | 'SEND_HEALTH_CARD'
  | 'MARK_HEALTH_CARD_AS_RECEIVED';

export type HealthCardStep = {
  id: HealthCardStepType;
  number?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactElement<HealthCardStepType>;
  completed?: boolean;
  active?: boolean;
  submittedChecklist?: boolean;
};

export const getHealthCardId =
  (policyId: string) =>
  (state: AppState): string | null => {
    const policy = getPolicyDetail(state, policyId);
    if (!policy) return null;
    return policy.attributes?.healthCardId || null;
  };

export const getHealthCardDetail =
  (healthCardId: string | null) =>
  (state: AppState): HealthCard | null => {
    const { healthCard } = state;
    return healthCardId ? healthCard?.[healthCardId] : null;
  };

export const getHealthCardAddress =
  (policyId: string) =>
  (state: AppState): Address | null => {
    const policy = getPolicyDetail(state, policyId);

    if (!policy || !policy.attributes?.healthCardId) return null;

    return (
      getHealthCardDetail(policy.attributes?.healthCardId)(state)?.address ||
      null
    );
  };

interface HealthCardChecklist {
  hasUploadedAndFinalizedPhoto: boolean;
  address: Address | undefined;
  isFinalized: boolean;
  hasReceivedHealthCard: boolean;
}

export const getHealthCardChecklist =
  (policyId: string) =>
  (state: AppState): null | HealthCardChecklist => {
    const policy = getPolicyDetail(state, policyId);
    if (!policy) return null;
    const healthCardId = policy.attributes?.healthCardId;
    if (!healthCardId) return null;

    const healthCard = getHealthCardDetail(healthCardId)(state);

    return {
      hasUploadedAndFinalizedPhoto: !!healthCard?.approvedPhoto?.id,
      address: healthCard?.address,
      isFinalized: healthCard?.isFinalized || false,
      hasReceivedHealthCard: policy.attributes?.hasReceivedHealthCard || false,
    };
  };

export const getTitleForHealthCardState =
  (policyId: string) =>
  (state: AppState): string => {
    const policyDetails = getPolicyDetail(state, policyId);
    const policyStatus = policyDetails?.status;
    const hasReceivedHealthCard =
      policyDetails?.attributes?.hasReceivedHealthCard;
    const covered = policyStatus === 'COVERED';

    if (
      policyStatus === 'CANCELED' ||
      policyStatus === 'DROPPED_OUT' ||
      (covered && hasReceivedHealthCard)
    )
      return '';

    if (covered) return 'Get your health card';

    return '';
  };

export const getHealthCardSteps =
  (policyId: string) =>
  (state: AppState): { steps: Array<HealthCardStep>; title: string } => {
    const healthCardChecklist = getHealthCardChecklist(policyId)(state);
    const policyDetails = getPolicyDetail(state, policyId);
    const title = getTitleForHealthCardState(policyId)(state);
    const policyStatus = policyDetails?.status;
    const covered = policyStatus === 'COVERED';
    const cancelled =
      policyStatus === 'CANCELED' || policyStatus === 'DROPPED_OUT';
    const showHealthCardConfirmationMessage = getPolicyDetail(state, policyId)
      ?.attributes?.showHealthCardConfirmation;

    let steps: Array<HealthCardStep> = [];

    if (showHealthCardConfirmationMessage)
      return {
        steps: [
          {
            id: 'COVERED',
            number: (
              <p className="p-p" data-testid="confirmation-message">
                🎉
              </p>
            ),
            title: 'You’re all set',
            active: true,
          },
        ],
        title,
      };

    if (
      cancelled ||
      (covered && policyDetails?.attributes?.hasReceivedHealthCard)
    )
      return { steps: [], title };

    if (covered && !healthCardChecklist) {
      steps = [
        {
          id: 'REQUEST_HEALTH_CARD',
          active: true,
          children: <RequestHealthCard />,
        },
      ];
    }

    if (covered && healthCardChecklist) {
      steps = [
        {
          id: 'PHOTO_UPLOAD',
          number: 1,
          title: 'Upload your photo',
          description: 'That photo will go on your ID',
          active: true,
          children: <PhotoUpload />,
        },
        {
          id: 'GERMAN_ADDRESS',
          number: 2,
          title: 'Confirm your german address',
          description:
            'This is where you will receive your health insurance card.',
          active: true,
          children: <GermanAddress />,
        },
        ...(healthCardChecklist.isFinalized
          ? [
              {
                id: 'MARK_HEALTH_CARD_AS_RECEIVED' as HealthCardStepType,
                number: <p className="p-p">✨</p>,
                title: 'Your health card is sent out',
                description: 'It generally takes 2-3 weeks to arrive',
                active: false,
              },
            ]
          : [
              {
                id: 'SEND_HEALTH_CARD' as HealthCardStepType,
                number: 3,
                title: 'Send your health card',
                active: false,
              },
            ]),
      ];
    }

    const stepStatusMapping: Partial<
      Record<HealthCardStepType, Partial<HealthCardStep>>
    > = {
      PHOTO_UPLOAD: {
        active: !healthCardChecklist?.hasUploadedAndFinalizedPhoto,
        completed: healthCardChecklist?.hasUploadedAndFinalizedPhoto,
      },
      GERMAN_ADDRESS: {
        active: !healthCardChecklist?.address,
        completed: Boolean(healthCardChecklist?.address),
      },
      SEND_HEALTH_CARD: {
        active:
          healthCardChecklist?.hasUploadedAndFinalizedPhoto &&
          healthCardChecklist.address &&
          !healthCardChecklist.isFinalized,
        children: <SendHealthCard />,
        completed: healthCardChecklist?.isFinalized,
      },
      MARK_HEALTH_CARD_AS_RECEIVED: {
        active: healthCardChecklist?.isFinalized,
        children: <MarkHealthCardAsReceived />,
      },
    };

    const stepsWithStatus = steps.map((step) => {
      if (stepStatusMapping[step.id]) {
        return { ...step, ...stepStatusMapping[step.id] };
      }

      return step;
    });

    return { steps: stepsWithStatus, title };
  };

export const getApplicationChecklist =
  (policyId: string) => (state: AppState) => {
    const policy = getPolicyDetail(state, policyId);

    if (!policy) {
      Sentry.captureException('Trying to access unknown policy ID');
      return;
    }

    return policy.type === 'PUBLIC_HEALTH';
  };
