import { BottomOrRegularModal } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { DeleteAccountModalProps } from 'models/account';
import { Trans } from 'shared/i18n';

import styles from './style.module.scss';

export const NoPoliciesModal = ({
  t,
  showDeleteAccountModal,
  setShowDeleteAccountModal,
  deleteUserAccount,
  activeDeleteRequest,
}: DeleteAccountModalProps) => (
  <BottomOrRegularModal
    title={t(
      'account.deleteAccountModal.noPolicesNoClaims.title',
      'Delete my account'
    )}
    isOpen={showDeleteAccountModal}
    onClose={() => setShowDeleteAccountModal(false)}
  >
    <div
      className={classNames('pt16 px24 pb24', styles.deleteAccountModal)}
      data-testid="no-polices-no-claims-delete-modal"
    >
      <p className="p-p wmx6">
        {t(
          'account.deleteAccountModal.noPolicesNoClaims.description.1',
          'After you send the request, your account and data will be deleted within one week. If we have any questions, we will reach out to you by email.'
        )}
      </p>
      <p className="p-p wmx6 mt24">
        <Trans i18nKey="account.deleteAccountModal.noPolicesNoClaims.description.2">
          <span className="fw-bold">
            You will not be able to file any claims
          </span>{' '}
          via Feather after an account deletion has been requested
        </Trans>
      </p>

      <div className={styles.buttonContainer}>
        <button
          type="button"
          className="p-btn--primary mt24"
          onClick={(e) => {
            e.preventDefault();
            deleteUserAccount();
            setShowDeleteAccountModal(false);
          }}
          disabled={activeDeleteRequest}
        >
          {activeDeleteRequest
            ? t(
                'account.deleteAccountModal.pendingAccountDeletionButton.caption',
                'Request already sent'
              )
            : t(
                'account.deleteAccountModal.requestAccountDeletionButton.caption',
                'Request account deletion'
              )}
        </button>
      </div>
    </div>
  </BottomOrRegularModal>
);
