import { toast } from '@popsure/dirty-swan';
import { useStripe } from '@stripe/react-stripe-js';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import {
  finalizeSetupIntent,
  PaymentMethodSelectorThunkDispatch,
} from 'features/paymentMethodSelector/paymentMethodSelector.thunks';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

export const StripeRedirectGateway = () => {
  const { t } = useSafeTranslation();
  const clientSecret = useQueryParamValue('setup_intent_client_secret');
  const redirectStatus = useQueryParamValue('redirect_status');
  const makeDefault = useQueryParamValue('makeDefault') === 'true';

  const dispatch = useDispatch<PaymentMethodSelectorThunkDispatch>();

  const stripeClient = useStripe();
  const history = useHistory();

  useEffect(() => {
    if (clientSecret && stripeClient) {
      if (redirectStatus === 'succeeded') {
        dispatch(
          finalizeSetupIntent({
            stripeClient,
            clientSecret,
            makeDefault,
          })
        ).then(() => {
          toast(
            t(
              'paymentMethods.addNew.stripeRedirectGateway.successNotification',
              'Your payment information was updated successfully'
            ),
            { type: 'success' }
          );
          history.replace(routes.me.paymentMethods.path);
        });
      } else {
        toast(
          t(
            'paymentMethods.addNew.stripeRedirectGateway.errorNotification',
            'There was an error during the authorization of your payment method'
          ),
          {
            type: 'error',
            description: t(
              'paymentMethods.addNew.stripeRedirectGateway.errorNotificationDescription',
              'Please, try adding a new one, or reach out to customer support.'
            ),
            duration: 5000,
          }
        );
        history.replace(routes.me.paymentMethods.add.path);
      }
    }
  }, [
    t,
    clientSecret,
    dispatch,
    stripeClient,
    history,
    redirectStatus,
    makeDefault,
  ]);

  return <LoadingSpinner />;
};

export default StripeRedirectGateway;
