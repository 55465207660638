import { StudentChecklistResponse } from 'features/studentChecklist/models';

export const isStudentChecklistCompleted = (
  checklist: Partial<StudentChecklistResponse>
): boolean => {
  if (!checklist) return false;

  return (
    Boolean(checklist.letterOfAcceptanceId) &&
    Boolean(checklist.iban) &&
    Boolean(checklist.proofOfUniEnrollmentId) &&
    Boolean(checklist.address)
  );
};
