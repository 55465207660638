import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import creditCardIcon from 'features/privateHealthClaims/icons/credit-card.svg';
import documentIcon from 'features/privateHealthClaims/icons/document.svg';
import { generatePath, useHistory } from 'react-router';
import { TFunction } from 'shared/i18n';

import {
  GettingStartedCard,
  Props as GettingStartedCardProps,
} from './components/GettingStartedCard';
import styles from './styles.module.scss';

interface Props {
  handleGetStarted: () => void;
  t: TFunction;
  policyId: string;
}

export const PrivateHealthClaimsGettingStartedView = ({
  handleGetStarted,
  t,
  policyId,
}: Props) => {
  const { location } = useHistory<{ from?: string }>();
  const cardData: GettingStartedCardProps[] = [
    {
      title: t(
        'claims.privateHealth.gettingStarted.cardInfo.1.title',
        'Pay your invoice as soon as possible'
      ),
      description: t(
        'claims.privateHealth.gettingStarted.cardInfo.1.description',
        'We recommend that you pay the invoice quickly to avoid late fees while we review your claim. You can request an extension from your medical professional if needed.'
      ),
      icon: {
        src: creditCardIcon,
        alt: t(
          'claims.privateHealth.gettingStarted.cardInfo.1.iconalt',
          'purple credit card'
        ),
      },
    },

    {
      title: t(
        'claims.privateHealth.gettingStarted.cardInfo.2.title',
        'Have a cost plan?'
      ),
      description: t(
        'claims.privateHealth.gettingStarted.cardInfo.2.description',
        'For certain major treatments, your doctor or dentist will provide you a cost plan (Heil- und Kostenplan). You can submit it to us before any expensive treatment is done.'
      ),
      icon: {
        src: documentIcon,
        alt: t(
          'claims.privateHealth.gettingStarted.cardInfo.2.iconalt',
          'purple document'
        ),
      },
    },
  ];

  return (
    <div className={`mb80 ${styles.container}`}>
      <div className="p-body">
        <NavButton
          title={t(
            'claims.privateHealth.gettingStarted.navButton.title',
            'Back'
          )}
          path={
            location.state?.from !== 'policy'
              ? routes.claims.create.path
              : generatePath(routes.me.policies.detail.path, { policyId })
          }
        />
        <h1 className="p-h1">
          {t('claims.privateHealth.gettingStarted.title', 'Getting started')}
        </h1>
        <p className="p-p mt8 mb24">
          {t(
            'claims.privateHealth.gettingStarted.description',
            'Here are important things to know when making a claim:'
          )}
        </p>
        {cardData.map(({ title, description, icon }) => (
          <GettingStartedCard
            className="mt16"
            title={title}
            icon={icon}
            description={description}
            key={title}
          />
        ))}
        <button
          type="button"
          onClick={handleGetStarted}
          className={`p-btn--primary mt32 wmn3 ${styles.button}`}
        >
          {t('claims.privateHealth.gettingStarted.cta', 'Get started')}
        </button>
      </div>
    </div>
  );
};
