import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import PhoneInputComponent from 'components/phoneInput';
import { useEffect, useState } from 'react';

export const PhoneInput = <Questionnaire extends QuestionnaireQuestions>({
  value,
  onSubmitValue,
  featureName,
  question,
  setValidForSubmission,
}: QuestionnaireFormProps<Questionnaire, string>) => {
  const [phoneNumber, setPhoneNumber] = useState<string>(value ?? '');

  useEffect(() => {
    setValidForSubmission(phoneNumber?.length > 0);
  }, [phoneNumber, setValidForSubmission]);

  const onChange = (updatedValue: string) => {
    setPhoneNumber(updatedValue);
  };

  const handleSubmit = () => {
    if (!phoneNumber) {
      throw new Error(
        `[${featureName}] Input phone number value is missing for submission for question: ${question}`
      );
    }
    onSubmitValue(phoneNumber);
  };
  return (
    <Header onSubmit={handleSubmit}>
      <PhoneInputComponent
        value={phoneNumber}
        onChange={onChange}
        placeholder="+49 111 111 1111"
      />
    </Header>
  );
};
