import * as Sentry from '@sentry/react';
import { Policy, PolicyDocument } from 'models/policies';

export const getPolicyDocuments = (policy: Policy): PolicyDocument[] => {
  if (!policy) {
    Sentry.captureException(
      '[POLICY_SINGLE]: getPolicyDocuments - trying to access unknown policy ID'
    );

    return [];
  }

  const {
    documents,
    attributes: { studentChecklist },
    userUploads,
  } = policy;

  const allAvailableDocuments: PolicyDocument[] = [
    ...documents,
    ...(userUploads || []),
  ];

  if (studentChecklist) {
    const { proofOfHealthInsuranceCoverageUrl } = studentChecklist;

    if (proofOfHealthInsuranceCoverageUrl) {
      allAvailableDocuments.push({
        title: 'Proof of health insurance coverage',
        downloadURL: proofOfHealthInsuranceCoverageUrl,
      });
    }
  }

  return allAvailableDocuments;
};
