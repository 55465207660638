import { Region } from '@getpopsure/public-models';
import { usePolicyModal } from 'features/policiesDashboard/hooks/usePolicyModal';
import {
  OverviewCard,
  OverviewFooter,
  OverviewHeader,
  OverviewModal,
} from 'features/recommendationTool/components/Overview';
import { groupRecommendationVerticals } from 'features/recommendationTool/utils/groupRecommendationVerticals';
import { InsuranceTypes } from 'models/insurances/types';
import { RecommendationDetails } from 'models/recommendationTool';
import { useCallback, useMemo, useState } from 'react';

import {
  categoryByInsuranceType,
  VerticalCategory,
} from '../models/recommendation';

interface QuickAssessmentPageProps {
  policiesList: (VerticalCategory | undefined)[];
  recommendation: RecommendationDetails;
  region: Region;
}

export const QuickAssessmentPage = ({
  policiesList = [],
  recommendation,
  region,
}: QuickAssessmentPageProps) => {
  const [selectedPolicy, setPolicy] = useState<VerticalCategory>();
  const { openModal, PolicyModal } = usePolicyModal();
  const policiesWithoutVerticalModal = [
    'CAR',
    'HEALTH_INSURANCE',
    'QUICK_RECOMMENDATION',
  ];

  /**
   * Organize the vertical categories into groups
   */
  const groups = useMemo(
    () => groupRecommendationVerticals(recommendation.data),
    [recommendation.data]
  );

  const getSelectedPolicyData = useCallback(
    () =>
      !selectedPolicy
        ? undefined
        : groups
            .flatMap(({ verticals }) => verticals)
            .find(({ key }) => selectedPolicy === key),
    [groups, selectedPolicy]
  );

  return (
    <div className="p-body wmx10">
      <OverviewHeader />

      {groups.map(
        ({ verticals = [], id, title: groupTitle }) =>
          !!verticals.length && (
            <div className="bg-white bs-xs br8 mb32 p24" key={id}>
              <h2 className="ai-center p-h2 tc-grey-900 m8 mb16">
                {groupTitle}
              </h2>

              {verticals.map(
                ({
                  key,
                  price,
                  showFromLabel,
                  shortTitle,
                  insuranceType,
                  title,
                  excerpt,
                  icon = '',
                  iconSrcSet,
                }) => (
                  <OverviewCard
                    vertical={key}
                    key={key}
                    img={{
                      src: icon,
                      srcSet: iconSrcSet,
                      alt: title,
                    }}
                    title={shortTitle ?? title}
                    price={price}
                    showFromLabel={showFromLabel}
                    subtitle={excerpt}
                    isCompleted={policiesList.includes(key)}
                    isNotRelevant={id === 'NOT_RELEVANT'}
                    onClick={() =>
                      policiesWithoutVerticalModal.includes(key)
                        ? setPolicy(key)
                        : openModal(
                            categoryByInsuranceType?.[
                              insuranceType
                            ] as InsuranceTypes,
                            region
                          )
                    }
                  />
                )
              )}
            </div>
          )
      )}

      <OverviewFooter />

      <OverviewModal
        vertical={selectedPolicy}
        verticalData={getSelectedPolicyData()}
        recommendation={recommendation}
        closeModal={() => setPolicy(undefined)}
      />

      <PolicyModal />
    </div>
  );
};
