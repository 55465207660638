import { retrieveNextBillingHistoryPage } from 'features/billingHistory/actions';
import { Billing } from 'features/billingHistory/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import { BillingItem } from '../BillingItem';
import styles from './styles.module.scss';

export interface Props {
  className?: string;
  billings?: Billing[];
  hasMorePages: boolean;
  fetchAfter: string | undefined;
  setSelectedBillingId: (id: string) => void;
}

export const BillingList = ({
  className = '',
  billings,
  hasMorePages,
  fetchAfter,
  setSelectedBillingId,
}: Props) => {
  const { t } = useSafeTranslation();
  const { loading } = useRequestStatus('GET_NEXT_BILLING_HISTORY_PAGE');
  const dispatch = useDispatch();

  const loadMore = useCallback(() => {
    if (!billings) return;
    if (billings.length > 0 && hasMorePages && fetchAfter) {
      dispatch(retrieveNextBillingHistoryPage(fetchAfter));
    }
  }, [dispatch, billings, hasMorePages, fetchAfter]);

  const isBillingListEmpty = billings ? billings.length === 0 : true;

  if (!billings || isBillingListEmpty) {
    return (
      <div className="py32 d-flex jc-center bg-white br8 mt48">
        <p className="p-p tc-grey-500">
          {t(
            'account.billinghistory.nopayment.description',
            "You haven't made any payments yet."
          )}
        </p>
      </div>
    );
  }

  return (
    <div className={`d-flex fd-column ai-center w100 ${className}`}>
      <div className="bg-white br8 w100">
        {billings.map((billingInfo, idx) => {
          const isLastBilling = billings.length - 1 === idx;
          return (
            <BillingItem
              key={billingInfo.stripeId}
              isLastBilling={isLastBilling}
              billingInfo={billingInfo}
              setSelectedBillingId={setSelectedBillingId}
              t={t}
            />
          );
        })}
      </div>
      {hasMorePages && !loading && (
        <button
          className={`p-h4 ds-interactive-component mt16 ${styles.showMoreButton}`}
          type="button"
          onClick={loadMore}
        >
          {t('account.billinghistory.showMoreButton.title', 'Load More')}
        </button>
      )}
      {hasMorePages && loading && (
        <div className="ds-spinner ds-spinner__m mt8" />
      )}
    </div>
  );
};
