import { TFunction } from '@getpopsure/i18n-react';
import {
  Disability,
  DisabilityPersonalInfo,
  DisabilityQuestionnaireAnswers,
  DisabilityTariffInfo,
} from 'features/disability/models';
import { questionPropLookup } from 'features/disability/questionnaire/lookups';

export const createLabelTitles = (
  answers: DisabilityQuestionnaireAnswers,
  t: TFunction
): Record<keyof DisabilityQuestionnaireAnswers, Record<string, string>> => {
  const labelTitles = {
    personalInfo: {} as Record<keyof DisabilityPersonalInfo, string>,
    tariffInfo: {} as Record<keyof DisabilityTariffInfo, string>,
  };

  Object.keys(answers.personalInfo).forEach((key) => {
    labelTitles.personalInfo[key as keyof DisabilityPersonalInfo] =
      questionPropLookup(t)[key as keyof Disability]
        ? questionPropLookup(t)[key as keyof Disability].question
        : '';
  });

  Object.keys(answers.tariffInfo).forEach((key) => {
    labelTitles.tariffInfo[key as keyof DisabilityTariffInfo] =
      questionPropLookup(t)[key as keyof Disability]
        ? questionPropLookup(t)[key as keyof Disability].question
        : '';
  });

  return labelTitles;
};
