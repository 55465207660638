import { calculateLiabilityPricing } from '@getpopsure/liability-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import heartIcon from 'features/liability/icons/heart.svg';
import { getInclusiveMembersInfo } from 'features/liability/pages/quote/content';
import { InclusiveMember } from 'models/liability';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

interface Props {
  inclusiveMembers?: InclusiveMember[];
  storeInclusiveMembers: (inclusiveMembers: InclusiveMember[]) => void;
}

const PricingCalculator = ({
  inclusiveMembers,
  storeInclusiveMembers,
}: Props) => {
  const { t } = useSafeTranslation();

  const [checkboxValues, setCheckboxValues] = useState<InclusiveMember[]>(
    inclusiveMembers ?? []
  );

  const price = calculateLiabilityPricing(checkboxValues);

  const handleOnChange = (value: InclusiveMember) => {
    if (checkboxValues?.includes(value)) {
      const filteredCheckboxValues = checkboxValues.filter(
        (selectedValue) => selectedValue !== value
      );
      setCheckboxValues(filteredCheckboxValues);
      return;
    }

    setCheckboxValues([...checkboxValues, value]);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    storeInclusiveMembers(checkboxValues);
  };

  return (
    <div className={`bg-grey-100 w100 ${styles.container}`}>
      <div className={`w100 ${styles['content-container']}`}>
        <div className={`w100 wmx4 ${styles['pricing-container']}`}>
          <div className={styles['cta-container']}>
            <div className="p--serif p-h1--xl tc-primary-500">
              {englishFormattedEuroCurrency(price, 2)}
            </div>
            <div className="tc-primary-500 mt8">
              {t('page.liability.quote.calculator.permonth', 'per month')}
            </div>
          </div>
          <button
            className="mt24 p-btn--primary w100"
            data-cy="button-quote"
            onClick={handleSubmit}
            type="submit"
          >
            {t('page.liability.quote.calculator.cta', 'Get covered')}
          </button>
        </div>
        <div className={`w100 ${styles['radio-container']}`}>
          <div className="d-flex ai-center">
            <img src={heartIcon} alt="purple heart" className="mr8" />
            <div className="tc-grey-700 p-h3">
              {t(
                'page.liability.quote.calculator.title',
                'Add household member(s)'
              )}
            </div>
          </div>
          <div className="mt8 p-p tc-grey-600">
            {t(
              'page.liability.quote.calculator.description',
              'Peace of mind for your loved ones, for a little extra.'
            )}
          </div>
          <div className={`p-label-container mt8 ${styles['label-container']}`}>
            {getInclusiveMembersInfo(t).map(({ title, value }) => (
              <div className="mt8" key={value}>
                <input
                  className="p-checkbox"
                  id={value}
                  type="checkbox"
                  value={value}
                  onChange={() => handleOnChange(value)}
                  checked={checkboxValues?.includes(value)}
                />
                <label
                  htmlFor={value}
                  className="p-label p-label--bordered wmn2 mr8"
                  data-cy={`checkbox-${value}`}
                >
                  {title}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingCalculator;
