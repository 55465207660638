import { clearReducerState } from 'actions/session';
import { store } from 'reducers';

import packageJson from '../../package.json';

const { version } = packageJson;

const featherInternalAppVersionKey = '_fav';

export function isMajorVersionBumpBetween({
  previousVersion,
  currentVersion,
}: {
  previousVersion: string;
  currentVersion: string;
}) {
  const previousMajorVersion = previousVersion.split('.')?.[0] ?? 0;
  const currentMajorVersion = currentVersion.split('.')?.[0] ?? 0;

  const castedPreviousMajorVersion = Number.parseInt(previousMajorVersion, 10);
  const castedCurrentMajorVersion = Number.parseInt(currentMajorVersion, 10);

  if (
    Number.isNaN(castedPreviousMajorVersion) ||
    Number.isNaN(castedCurrentMajorVersion)
  ) {
    return true;
  }

  return castedCurrentMajorVersion > castedPreviousMajorVersion;
}

export const autoPurgeReducerState = () => {
  const previousAppVersion = localStorage.getItem(featherInternalAppVersionKey);

  if (
    previousAppVersion &&
    isMajorVersionBumpBetween({
      previousVersion: previousAppVersion,
      currentVersion: version,
    })
  ) {
    store.dispatch(clearReducerState([]));
  }

  localStorage.setItem(featherInternalAppVersionKey, version);
};
