import { getTrackingObject } from '@getpopsure/tracker';
import {
  Disability,
  DisabilityQuestionnaire,
} from 'features/disability/models';
import { useWindowSize } from 'hooks/useWindowSize';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { Helmet } from 'react-helmet';
import { paramsSetUrl } from 'shared/util/paramsSetUrl';
import { v4 as uuidv4 } from 'uuid';

export const DisabilityTallyForm = ({
  questionnaireAnswers: answers,
}: CustomComponentProps<Disability>) => {
  const source = getTrackingObject();
  const size = useWindowSize();

  const qnrParams = [
    { key: 'formId', value: uuidv4() },
    { key: 'occupation', value: answers.occupation ?? '' },
    { key: 'dateOfBirth', value: answers.dateOfBirth ?? '' },
    { key: 'monthlyNetIncome', value: String(answers.monthlyNetIncome) ?? '' },
    { key: 'officeWork', value: String(answers.officeWork) ?? '' },
    { key: 'degree', value: answers.degree ?? '' },
    {
      key: 'height',
      value: String(answers.height?.inCm) ?? String(answers.height?.inFt) ?? '',
    },
    {
      key: 'weight',
      value:
        String(answers.weight?.inKg) ?? String(answers.weight?.inLbs) ?? '',
    },
    {
      key: 'premiumCoverage',
      value: String(answers.addOns?.premiumCoverage) ?? '',
    },
    { key: 'questionnaire', value: answers.quote?.questionnaireId ?? '' },
    { key: 'quote', value: answers.quote?.quoteId ?? '' },
    { key: 'price', value: String(answers.quote?.price) ?? '' },
    { key: 'monthlyPayout', value: String(answers.monthlyPayout) ?? '' },
  ];

  const utmParams = [
    { key: 'utmSource', value: source?.utm_source ?? '' },
    { key: 'utmMedium', value: source?.utm_medium ?? '' },
    { key: 'utmCampaign', value: source?.utm_campaign ?? '' },
    { key: 'utmTerm', value: source?.utm_term ?? '' },
    { key: 'utmContent', value: source?.utm_content ?? '' },
  ];

  return (
    <>
      <Helmet>
        <script async src="https://tally.so/widgets/embed.js" />
      </Helmet>
      <iframe
        width={size.width ?? '100%'}
        height={size.height ?? '100%'}
        data-tally-src={paramsSetUrl(
          `https://tally.so/r/3XD1eP`,
          qnrParams.concat(utmParams)
        )}
        title="Disability insurance signup"
        data-cy="tally-iframe"
      />
    </>
  );
};

export const tallyFormQuestionnaire = (): DisabilityQuestionnaire => ({
  form: {
    required: true,
    type: 'CUSTOM',
    groupId: 'signup',
    component: DisabilityTallyForm,
  },
});
