import Session from '@getpopsure/session';
import classNames from 'classnames';
import routes from 'constants/routes';
import { Redirect } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import { featherIcon as logo } from '../../icons/index';
import Buttons from './components/Buttons';
import styles from './style.module.scss';

const MainScreen = () => {
  const { t } = useSafeTranslation();

  const { isAuthenticated } = Session;

  if (isAuthenticated) {
    return <Redirect to={routes.me.policies.path} />;
  }

  return (
    <div className="root-container">
      <div className={classNames('wmx12 w100 px16 py0', styles.innerContainer)}>
        <img src={logo} alt="logo" className={classNames('mx8', styles.logo)} />
        <h1 className="p-h1 mt16 p-h1--xl p--serif tc-grey-900">
          {t('main.welcomeScreen.title', 'Honest, simple insurance.')}
        </h1>
        <p className="p-p mt16 tc-grey-600">
          {t(
            'main.welcomeScreen.subtitle',
            'Welcome to your insurance companion'
          )}
        </p>
        <Buttons className={classNames('mt24 gap8', styles.btnContDesktop)} />
      </div>
      <Buttons
        className={classNames(
          'my0 mx-auto pt0 py0 px16 w100 gap8',
          styles.btnContMobile
        )}
      />
    </div>
  );
};

export default MainScreen;
