import { PolicyInfo } from 'features/checkout/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import { DownloadPolicyView } from './DownloadPolicy.view';
import {
  CheckStatus,
  PythonBackendInsuranceType,
  SignupsBackendInsuranceType,
} from './models';
import { useDownloadStatus } from './useDownloadStatus';

interface Props {
  checkList: CheckStatus[];
  hasCompletedDownload?: boolean;
  policyInfo: PolicyInfo;
  handleContinue: () => void;
  insuranceType: PythonBackendInsuranceType | SignupsBackendInsuranceType;
}

export const DownloadPolicy = ({
  checkList,
  hasCompletedDownload,
  policyInfo,
  handleContinue,
  insuranceType,
}: Props) => {
  const { t } = useSafeTranslation();
  const [downloadStatus, handleDownload] = useDownloadStatus(
    insuranceType,
    hasCompletedDownload
  );
  const downloaded = downloadStatus === 'COMPLETED';
  const [checkListStatus, setCheckListStatus] = useState<
    Record<string, boolean>
  >(
    downloaded
      ? checkList.reduce((obj, item) => {
          return {
            ...obj,
            [item.id]: true,
          };
        }, {})
      : {}
  );

  const { loading, error } = useRequestStatus('CHECKOUT_REVIEW');

  const onDownload = () => {
    if (
      (!policyInfo.questionnaireId || !policyInfo.quoteId) &&
      !policyInfo.dependents
    ) {
      throw new Error(
        `[${insuranceType} download policy] Questionnaire id and / or quote id is missing for creating policy.\nData:${JSON.stringify(
          policyInfo
        )}`
      );
    }

    handleDownload(policyInfo);
  };

  return (
    <DownloadPolicyView
      checkList={checkList}
      downloadStatus={downloadStatus}
      loading={loading}
      error={error?.message}
      onDownload={onDownload}
      onContinue={handleContinue}
      checkListStatus={checkListStatus}
      setCheckListStatus={setCheckListStatus}
      t={t}
    />
  );
};
