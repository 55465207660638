import Session from '@getpopsure/session';
import Error from 'components/error';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { APIResponseError } from 'models/error';
import { User } from 'models/user';
import { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';
import {
  capitalizeName,
  capitalizeNameSplit,
} from 'shared/util/capitalizeName';

import styles from './style.module.scss';

interface Props {
  newAccountPath?: string;
  loading?: boolean;
  error?: APIResponseError;
  account?: Partial<User>;
  handleContinue: () => void;
  handleCreateNewAccount?: () => void;
}

const Account = ({
  newAccountPath,
  loading,
  error,
  account,
  handleContinue,
  handleCreateNewAccount,
}: Props) => {
  const { t } = useSafeTranslation();

  const history = useHistory();

  const { isAuthenticated } = Session;

  useEffect(() => {
    if (!isAuthenticated) {
      handleCreateNewAccount?.();
    }
  }, [isAuthenticated, handleCreateNewAccount]);

  if (loading) {
    return <TimedLoadSpinner />;
  }

  if (error) {
    return <Error error={error.message} />;
  }

  if (!isAuthenticated) {
    if (newAccountPath) {
      return <Redirect to={newAccountPath} />;
    }
  }

  const onCreateNewAccount = () => {
    handleCreateNewAccount?.();

    if (newAccountPath) {
      history.push(newAccountPath);
    }
  };

  if (!account?.firstName || !account?.lastName) {
    return (
      <div className={styles.container}>
        <div className="p-body">
          <div className={styles.thumb}>
            <span role="img" aria-label="">
              👍
            </span>
          </div>
          <h1 className="p-h1 mt16 wmx8">
            {t(
              'components.account.title.emailonly',
              'Great! Do you want to continue with'
            )}{' '}
            <span className="tc-primary-500">{account?.email}</span>?
          </h1>
          <div className={`mt8 ${styles['buttons-container']}`}>
            <button
              className={`p-btn--primary wmn3 mt16 ${styles.button}`}
              onClick={handleContinue}
              type="button"
            >
              {t(
                'components.account.continuewithaccount',
                'Continue with account'
              )}
            </button>
            <button
              className={`p-btn--secondary wmn3 mt16 ${styles.button}`}
              onClick={onCreateNewAccount}
              type="button"
            >
              {t('components.account.createnewaccount', 'Create new account')}
            </button>
          </div>
        </div>
      </div>
    );
  }
  const name = {
    firstName: account.firstName,
    lastName: account.lastName,
  };

  return (
    <div className={styles.container}>
      <div className="p-body">
        <h1 className="p-h1 mt16">
          {t('components.account.title', 'Do you want to continue as')}{' '}
          <span className="tc-primary-500">{capitalizeName(name)}</span>?
        </h1>
        <div className={`mt8 ${styles['buttons-container']}`}>
          <button
            className={`p-btn--primary wmn3 mr8 mt16 ${styles.button}`}
            onClick={handleContinue}
            data-cy="button-account-continue"
            type="button"
          >
            {t('components.account.continueas', 'Continue as')}
            {` ${capitalizeNameSplit(name)[0]}`}
          </button>
          <button
            className={`p-btn--secondary wmn3 mt16 ${styles.button}`}
            onClick={onCreateNewAccount}
            type="button"
          >
            {t('components.account.createnewaccount')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Account;
