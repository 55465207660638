import { RegularModal } from '@popsure/dirty-swan';
import { useCallback, useRef, useState } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { ArrowButton } from './ArrowButton';
import { CoverageAccordion } from './CoverageAccordion';
import type { CoverageModalVariantProps } from './CoverageModal';
import styles from './CoverageModal.module.scss';

export const CoverageModalTabbed = ({
  coverageTableData,
  coverageTableHeaders,
  firstItemOpenByDefault,
  isOpen,
  onClose,
  renderDataCell,
  title,
}: CoverageModalVariantProps) => {
  const coverageTableHeadersWithoutNull = coverageTableHeaders?.filter(Boolean);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [headerWidth, setHeaderWidth] = useState(0);

  const headerRef = useRef<HTMLDivElement | null>(null);

  const scrollContainerCallbackRef = useCallback((node) => {
    if (node) {
      setHeaderWidth(node.clientWidth);
    }

    headerRef.current = node;
  }, []);

  return (
    <div className={styles.modalWidthOverride}>
      <RegularModal isOpen={isOpen} title={title} onClose={onClose}>
        <ScrollSync>
          <div
            className={`d-flex fd-column mt16 pb24 px24 ${styles.modalContent}`}
          >
            {coverageTableHeadersWithoutNull && (
              <div
                className={`d-flex ai-center jc-center fd-column py32 bg-white ${styles.header}`}
              >
                <ArrowButton
                  isActive={selectedTabIndex > 0}
                  onClick={() => {
                    if (headerRef.current && selectedTabIndex > 0) {
                      headerRef.current.scroll({
                        top: 0,
                        left: headerRef.current.scrollLeft - headerWidth,
                        behavior: 'smooth',
                      });

                      setSelectedTabIndex(
                        (currentTabIndex) => currentTabIndex - 1
                      );
                    }
                  }}
                  variant="ARROW_LEFT"
                />
                <ScrollSyncPane>
                  <div
                    className={styles.scrollContainer}
                    ref={scrollContainerCallbackRef}
                  >
                    <table className={`p-p w100 ${styles.tabbedHeaderTable}`}>
                      <tbody>
                        <tr>
                          {coverageTableHeadersWithoutNull?.map(
                            (tableHeaderContent, index) => (
                              <th
                                className="tc-purple-500 fw-bold"
                                style={{ width: `${headerWidth}px` }}
                                key={String(`tableHeaderContent ${index}`)}
                              >
                                {tableHeaderContent}
                              </th>
                            )
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ScrollSyncPane>
                <ArrowButton
                  isActive={
                    selectedTabIndex <
                    coverageTableHeadersWithoutNull.length - 1
                  }
                  onClick={() => {
                    if (
                      headerRef.current &&
                      selectedTabIndex <
                        coverageTableHeadersWithoutNull.length - 1
                    ) {
                      headerRef.current.scroll({
                        top: 0,
                        left: headerRef.current.scrollLeft + headerWidth,
                        behavior: 'smooth',
                      });

                      setSelectedTabIndex(
                        (currentTabIndex) => currentTabIndex + 1
                      );
                    }
                  }}
                  variant="ARROW_RIGHT"
                />
              </div>
            )}
            <CoverageAccordion
              coverageTableData={coverageTableData}
              headerWidth={headerWidth}
              renderDataCell={renderDataCell}
              tabbed
              firstItemOpenByDefault={firstItemOpenByDefault}
            />
          </div>
        </ScrollSync>
      </RegularModal>
    </div>
  );
};
