import { XCircleIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import { useSelector } from 'react-redux';
import { isDelinquentUser } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';

import { PolicyDetailsButtonProps } from '../../sections/PolicyDetails/PolicyDetails.models';

export const CancelPolicyButton = (props: PolicyDetailsButtonProps) => {
  const { onOpenModal } = props;
  const { t } = useSafeTranslation();
  const isDelinquent = useSelector(isDelinquentUser);

  return (
    <ButtonCell
      title={t(
        'myPolicies.policyDetails.cancelPolicyButton.caption',
        'Cancel this policy'
      )}
      icon={<XCircleIcon noMargin size={24} className="ml16" />}
      onClick={() => {
        if (isDelinquent) {
          onOpenModal('DELINQUENT_BLOCKER_CANCEL_POLICY');
        } else {
          onOpenModal('CANCEL_POLICY');
        }
      }}
    />
  );
};
