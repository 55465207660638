import {
  Step,
  StepType,
  StepWithStatus,
  StudentChecklistAttribute,
} from 'features/studentChecklist/models';
import { InsuranceStatus } from 'models/insurances/status';

import { isNotifiedUniversity } from '../isNotifiedUniversity';

export const getChecklistStatus = (
  studentChecklist: StudentChecklistAttribute | null,
  policyStatus?: InsuranceStatus
) => {
  const cancelled =
    policyStatus === 'CANCELED' || policyStatus === 'DROPPED_OUT';
  const completedAndCovered =
    studentChecklist?.isCompleted && policyStatus === 'COVERED';

  if (!studentChecklist || cancelled || completedAndCovered) {
    return { currentSteps: [] };
  }

  const providerNotifiedUniversity = isNotifiedUniversity(
    studentChecklist.letterOfAcceptanceCreatedAt
  );
  const customerUploadedLetterOfAcceptance = Boolean(
    studentChecklist.letterOfAcceptanceUrl
  );
  const studentChecklistCompleted =
    customerUploadedLetterOfAcceptance &&
    Boolean(studentChecklist.address) &&
    Boolean(studentChecklist.iban) &&
    Boolean(studentChecklist.proofOfUniEnrollmentUrl);

  let steps: Array<Step> = [
    {
      id: 'LETTER_OF_ACCEPTANCE',
      active: true,
    },
    {
      id: 'PROVIDER_NOTIFIES_UNIVERSITY',
      active: false,
    },
    {
      id: 'GERMAN_ADDRESS',
      active: false,
    },
    {
      id: 'BANK_ACCOUNT',
      active: false,
    },
    {
      id: 'PROOF_OF_ENROLLMENT',
      active: false,
    },
    {
      id: 'SUBMIT_APPLICATION',
      active: false,
    },
  ];

  if (studentChecklist.isCompleted) {
    steps = [{ id: 'PROCESSING_APPLICATION', active: true }];
  }

  const stepStatusMapping: Record<StepType, Partial<StepWithStatus>> = {
    LETTER_OF_ACCEPTANCE: {
      active: !studentChecklist.letterOfAcceptanceUrl,
      completed: customerUploadedLetterOfAcceptance,
    },
    PROVIDER_NOTIFIES_UNIVERSITY: {
      active: customerUploadedLetterOfAcceptance && !providerNotifiedUniversity,
      completed: providerNotifiedUniversity,
    },
    GERMAN_ADDRESS: {
      active: customerUploadedLetterOfAcceptance && !studentChecklist.address,
      completed: Boolean(studentChecklist.address),
    },
    BANK_ACCOUNT: {
      active: customerUploadedLetterOfAcceptance && !studentChecklist.iban,
      completed: Boolean(studentChecklist.iban),
    },
    PROOF_OF_ENROLLMENT: {
      active:
        customerUploadedLetterOfAcceptance &&
        !studentChecklist.proofOfUniEnrollmentUrl,
      completed: Boolean(studentChecklist.proofOfUniEnrollmentUrl),
    },
    SUBMIT_APPLICATION: {
      active: false,
    },
    PROCESSING_APPLICATION: {
      active: studentChecklistCompleted,
    },
  };

  const stepsWithStatus: StepWithStatus[] | Step[] = steps.map((step) => {
    if (stepStatusMapping[step.id]) {
      return { ...step, ...stepStatusMapping[step.id] };
    }

    return step;
  });

  return { currentSteps: stepsWithStatus };
};
