import { Input } from '@popsure/dirty-swan';
import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { Name } from 'models/user';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import { isName } from 'shared/validators';

import QuestionnaireWrapper from '../QuestionnaireWrapper';

interface Props
  extends QuestionnaireFormProps<Name>,
    QuestionnaireWrapperProps {}

export const NameForm = ({
  value,
  onSubmitValue,
  question,
  description,
  buttonText,
  header,
  featureName,
  additionalInfo,
  loading,
  error,
}: Props) => {
  const { t } = useSafeTranslation();

  const [name, setName] = useState<Name>(
    value || { firstName: '', lastName: '' }
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!name) {
      throw new Error(
        `[${featureName}] Name is missing for submission for question: ${question}`
      );
    }
    onSubmitValue(name);
  };
  return (
    <QuestionnaireWrapper
      header={header}
      question={question}
      description={description}
      onSubmit={handleSubmit}
      error={error}
      loading={loading}
      buttonText={buttonText}
      validForSubmission={isName(name)}
      additionalInfo={additionalInfo}
    >
      <Input
        placeholder={t('qnf.forms.name.firstname.placeholder', 'First name')}
        type="text"
        required={true}
        value={name?.firstName}
        onChange={(e) =>
          setName({
            firstName: e.target.value,
            lastName: name?.lastName ?? '',
          })
        }
        data-cy="input-name-first"
      />
      <Input
        placeholder={t('qnf.forms.name.lastname.placeholder', 'Last name')}
        className="mt8"
        type="text"
        required={true}
        value={name?.lastName}
        onChange={(e) =>
          setName({
            firstName: name?.firstName ?? '',
            lastName: e.target.value,
          })
        }
        data-cy="input-name-last"
      />
    </QuestionnaireWrapper>
  );
};
