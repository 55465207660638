import {
  getReceipt,
  requestReceipt,
  retrieveBillingHistory,
  retrieveDisputeInvoices,
  updateCharge,
} from 'features/billingHistory/actions';
import { Billing, DisputeInvoice } from 'features/billingHistory/models';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import client from 'shared/api';

const downloadReceipt = (url: string): void => {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.download = 'receipt.pdf';
  link.target = '_blank';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const useAsyncReceiptGeneration = (billingInfo: Billing) => {
  const dispatch = useDispatch();
  const [generatingReceipt, setGeneratingReceipt] = useState(
    billingInfo.receipt && !billingInfo.receipt.downloadUrl
  );
  const [pollInterval, setPollInterval]: [
    number | null | undefined,
    React.Dispatch<React.SetStateAction<number | null | undefined>>
  ] = useState();

  const onDownloadClick = useCallback(
    (ev) => {
      ev.stopPropagation();
      if (billingInfo?.receipt?.downloadUrl) {
        downloadReceipt(billingInfo.receipt.downloadUrl);
      } else {
        dispatch(requestReceipt(billingInfo.stripeId));
        setGeneratingReceipt(true);
      }
    },
    [dispatch, billingInfo.stripeId, billingInfo.receipt?.downloadUrl]
  );

  useEffect(() => {
    if (generatingReceipt && !pollInterval) {
      const intervalId = window.setInterval(() => {
        dispatch(getReceipt(billingInfo.stripeId));
      }, 3000);

      setPollInterval(intervalId);
    }

    if (generatingReceipt && pollInterval && billingInfo.receipt?.downloadUrl) {
      window.clearInterval(pollInterval);
      setPollInterval(null);
      setGeneratingReceipt(false);

      // automatically download pdf after polling
      downloadReceipt(billingInfo.receipt.downloadUrl);
    }
  }, [generatingReceipt, billingInfo, dispatch, pollInterval, setPollInterval]);

  return { generatingReceipt, onDownloadClick };
};

export const useAsyncPaymentRetry = (billingInfo: Billing) => {
  const dispatch = useDispatch();
  const [retryingPayment, setRetryingPayment] = useState(false);
  const [paymentError, setPaymentError] = useState<string | null>(null);

  const onRetryPaymentClick = useCallback(
    async (ev) => {
      ev.stopPropagation();
      setRetryingPayment(true);
      try {
        const { data } = await client.network.post(
          `/billing-history/charges/${billingInfo.invoiceId}/pay`
        );
        dispatch(updateCharge(data));
        setPaymentError(null);
      } catch (e) {
        // TODO: process error messages from Stripe, use metadata with number of retries
        setPaymentError(
          'Retrying payment failed. Please confirm payment method or contact your bank.'
        );
      }
      setRetryingPayment(false);
    },
    [billingInfo, dispatch, setRetryingPayment]
  );

  return {
    retryingPayment,
    onRetryPaymentClick,
    paymentError,
  };
};

// TODO: refactor and merge this method and useAsyncPaymentRetry
export const useDraftInvoicePayAttempt = (disputeInvoice: DisputeInvoice) => {
  const dispatch = useDispatch();
  const [attemptingPayment, setAttemptingPayment] = useState(false);

  const onPayClick = useCallback(
    async (ev) => {
      ev.stopPropagation();
      setAttemptingPayment(true);

      try {
        await client.network.post(
          `/billing-history/charges/${disputeInvoice.stripeId}/pay`
        );
        dispatch(retrieveBillingHistory());
        dispatch(retrieveDisputeInvoices());
      } catch (e) {
        // TODO: process error messages from Stripe, use metadata with number of retries
      }
      setAttemptingPayment(false);
    },
    [dispatch, disputeInvoice]
  );

  return { attemptingPayment, onPayClick };
};
