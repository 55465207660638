import { capitalizeName } from '@getpopsure/public-utility';
import { getPoliciesListAction } from 'features/policiesDashboard/utils/getPoliciesListAction';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import {
  CancelPolicyButton,
  ContactUsButton,
} from 'features/policySingle/components/buttons';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';

import type { PolicySingleModal } from '../../components/modals';
import {
  CancelPolicyModal,
  DelinquencyBlockerCancelPolicyModal,
} from '../../components/modals';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from '../../PolicySingle.models';
import { getPolicyDocuments } from '../../sections/PolicyDocuments/utils/getPolicyDocuments';
import { getGenericInfo } from '../generic/getGenericInfo';
import { TravelHealthFranceSubmitClaimButton } from './buttons/TravelHealthFranceClaim';
import { TravelHealthFranceSubmitClaimModal } from './modals/TravelHealthFranceSubmitClaimModal';

export const getTravelFranceConfig = (
  args: GetPolicyConfigArgs
): PolicyConfig => {
  const [policy, t] = args;

  const action = getPoliciesListAction(policy);
  const policyStatus =
    action && action !== 'DETAIL'
      ? 'ACTION_NEEDED'
      : getPolicyStatus(policy, true);
  const title = getTitleMapping(t)[policy.type];
  const capitalizedName = policy.attributes.insuredPerson?.name
    ? capitalizeName(policy.attributes.insuredPerson?.name)
    : '';
  const subtitle = capitalizedName || '';

  const header: PolicyConfig['header'] = {
    title,
    backgroundColor: bannerColorMapping.TRAVEL_FR,
    insuranceType: policy.type,
    subtitle,
    policyStatus,
    startDate: policy.attributes.startDate,
  };

  if (policy.attributes.price) {
    header.price = {
      amount: parseFloat(policy.attributes.price),
      billingType: policy.attributes.billingType as BillingType,
    };
  }

  const sections: PolicySingleSection[] = [
    {
      id: 'POLICY_DETAILS',
      props: {
        policyType: policy.type,
        info: getGenericInfo(policy, t),
        buttons: [
          ...(policy.status !== 'CANCELED'
            ? [
                {
                  id: 'CONTACT_US',
                  component: ContactUsButton,
                },
                {
                  id: 'TRAVEL_FRANCE_SUBMIT_CLAIM',
                  component: TravelHealthFranceSubmitClaimButton,
                },
                {
                  id: 'CANCEL_POLICY',
                  component: CancelPolicyButton,
                },
              ]
            : []),
        ],
      },
    },
    {
      id: 'POLICY_DOCUMENTS',
      props: {
        documents: getPolicyDocuments(policy),
      },
    },
  ];

  const modals: PolicySingleModal[] = [
    {
      id: 'CANCEL_POLICY',
      component: CancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
    {
      id: 'TRAVEL_FR_SUBMIT_CLAIM',
      component: TravelHealthFranceSubmitClaimModal,
    },
  ];

  return { header, modals, sections };
};
