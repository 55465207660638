import { blog, calendly, website } from '@getpopsure/private-constants';
import { FAQInfo } from 'components/faq';

export const longTermFAQContent: FAQInfo[] = [
  {
    questionId: 1,
    question: 'How does Feather help me?',
    answer:
      'What you can expect from our English speaking brokerage team:\n* Fully digital sign-up\n* All necessary insurance documents for your visa, employer, and tax declaration\n* Support for changing your coverage or type of insurance\n* Fully managing your private insurance cover: changing the coverage, start date, address, bank details, cancellation & more\n* Step-by-step help for adding family members to your plan\n* Support during the claims process\n* Help with payment issues\n* Help with mail from provider\n*  Individual personal support through chat or video call for any issue related to your private insurance coverage',
  },
  {
    questionId: 2,
    question: 'Is there a cancellation period for private insurance?',
    answer:
      "You can cancel right away if you are moving within the EU and you are now part of a mandatory insurance system. To do so, simply hand in your de-registration from Germany and proof of the new insurance. If you're leaving the EU, the de-registration letter and any proof that you’re leaving permanently will do (e.g. a rental contract).\n\nWant to cancel for a different reason? You’ll have to wait 24 months to cancel.",
  },
  {
    questionId: 3,
    question: 'How does the monthly payment work?',
    answer:
      'The total monthly cost will be deducted from your bank account. Employers pay approximately 50% of your cost by transferring their monthly share to your bank account (usually as part of your salary).',
  },
  {
    questionId: 4,
    question: 'When will I know more about my application?',
    answer: `We are always checking each individual application to make sure that you’re going to be covered or not. We will keep you informed throughout the process. If you have any further questions, please feel free to [contact us](${website.support}).`,
  },
  {
    questionId: 5,
    question: 'Can private health insurance companies deny coverage?',
    answer:
      'Yes, you can be denied coverage by private health insurance companies. The most common reasons for a denial are the following pre-existing health conditions: ADHD, diabetes, arthritis, autoimmune diseases, asthma, cancer and mental health conditions. Other health conditions can lead to risk surcharges or exclusions of coverage.\n\nIf you cannot be covered or if your coverage needs to be modified, our team will reach out to you with step by step guidance, so you can take the decision that is right for you.',
  },
  {
    questionId: 6,
    question: 'How will the price change over time?',
    answer: `There is no annual price increase based on age. The price can only change, if the medical cost increases across Germany, if there are changes in interest rates or if statistics show that people are living longer than previously expected (and thus also need health insurance for longer). In short: you can expect a price increase comparable to general inflation. Good to know: the cost for public health insurance increases in the same way.\n\nCheck out [our article](${`${blog.base}private-premium-increase`}) to see how price increases work in more detail or [schedule a call with an expert](${
      calendly.healthInsuranceAdvicePriority
    }).`,
  },
  {
    questionId: 7,
    question: 'How can I switch between public and private health insurance?',
    answer: `To cancel public health insurance, you will go through 3 steps:\n\n 1. Hand in a [cancelation form](https://docs.google.com/document/d/1i8-vRkDsEMxPWmNvfDiFCTaX5Riq61bFzT5_-Uu2_wI) to your public provider. This will kick off the cancellation period, which is typically 2 months plus the ongoing month. _Example: You hand in the cancelation form on January 15th, so your private insurance can start earliest on April 1st._\n2. Apply for private health insurance.\n3. Forward the confirmation of private health coverage to your public provider. This confirmation document is uploaded automatically to your Feather account after your coverage is confirmed.\n\nCheck out our [article](${blog.base}switching-private-public-mythbusters) to see how you can switch from private to public insurance!`,
  },
  {
    questionId: 8,
    question: 'Can I insure my family with private insurance?',
    answer:
      'Every family is different, so we are happy to have a quick chat about your needs. Since every additional family member in private insurance means additional cost we want to help you figure out the best option. Keep in mind that pre-existing medical conditions might influence whether they can access private health insurance.\n\nYour parents can be co-insured if they have no income and meet all health requirements.\n\nYour spouse can be co-insured if they do not earn more than €470 per month and meet all health requirements. If you are not married to your partner, you cannot get them covered through your private insurance plan.\n\nYour kids can be co-insured. Tip: if you are also eligible for public insurance and have several kids, public insurance might be a better idea since you can insure them cost-free there.',
  },
];
