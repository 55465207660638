import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import ErrorView from 'components/error';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { submitDisabilityQuestionnaire } from 'features/disabilityV2/actions';
import {
  DisabilityV2,
  ZSubmittableDisabilityV2Questionnaire,
} from 'features/disabilityV2/models';
import { createLabelTitles } from 'features/disabilityV2/util';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

export const Processing = ({
  onSubmitValue,
  questionnaireAnswers,
}: QuestionnaireFormProps<DisabilityV2>) => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);

  const answers =
    ZSubmittableDisabilityV2Questionnaire.safeParse(questionnaireAnswers);

  if (!answers.success) {
    setError(answers.error.toString());
    throw new Error(
      `[Disability] Questionnaire answer validation errors:${answers.error.toString()}`
    );
  }
  const labels = createLabelTitles(answers.data, t);

  const submitQuestionnaire = async () =>
    dispatch(submitDisabilityQuestionnaire(t, labels));

  useEffect(() => {
    submitQuestionnaire().then(() => onSubmitValue(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    <ErrorView error={error} />;
  }

  return (
    <TimedLoadSpinner
      title="Submitting your application details"
      delayInMilliseconds={1500}
    />
  );
};
