import { language } from '@getpopsure/i18n-react';
import { email } from '@getpopsure/private-constants';
import { BottomOrRegularModal } from '@popsure/dirty-swan';
import * as Sentry from '@sentry/browser';
import classnames from 'classnames';
import { useRegion } from 'hooks/useRegion';
import { createPortal } from 'react-dom';
import { useChat } from 'react-live-chat-loader';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AppState } from 'reducers';
import { getPolicyDetail } from 'selectors/policies';
import { getAccountInfo } from 'selectors/user';
import { Trans, useSafeTranslation } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';
import { paramsSetUrl } from 'shared/util/paramsSetUrl';

import { useGetPolicies } from '../../hooks/useGetPolicies';
import styles from './IntercomLoaderWrapper.module.scss';
import { bootIntercomWithUserData, shutdownIntercom } from './utils';
import { getInsuranceParamFromPolicies } from './utils/getInsuranceParamFromPolicies';
import { useWidgetTimeout } from './utils/useWidgetTimeout';

const onError = () => {
  Sentry.captureException('Chat widget timed out and failed to load', {
    tags: {
      feature: 'INTERCOM',
    },
  });
};

interface RenderArgs {
  error: boolean;
  loading: boolean;
  handleClick: () => void;
}

interface IntercomLoaderWrapperProps {
  render: (args: RenderArgs) => JSX.Element;
}

export const IntercomLoaderWrapper = ({
  render,
}: IntercomLoaderWrapperProps) => {
  const [chat, loadChat] = useChat({ loadWhenIdle: true });
  const user = useSelector(getAccountInfo);
  const { t } = useSafeTranslation();
  const { region } = useRegion();
  const history = useHistory();
  const location = useLocation();
  const { policyId, locale: urlLocale } = useParams<{
    locale: string;
    policyId: string;
  }>();
  const policy = useSelector((state: AppState) =>
    getPolicyDetail(state, policyId)
  );

  const { isLoading, policies } = useGetPolicies();

  /**
   * Intercom does not allow to pass data
   * to the Messenger using the API, so we need to append
   * all additional information as URL params and query params,
   * so the Messenger can pick them up.
   *
   * The IntercomRegionFallbackRoutes.tsx file provides route fallbacks
   * that include the locale in the path
   *
   * E.g. The normal route for account is `/account`,
   * the appendAdditionalParams function will add the locale
   * to the beginning of the path, so the route will become `/en-gr/account`
   */
  const appendAdditionalParams = () => {
    const searchParams = [
      {
        key: 'delinquent',
        value: user?.isDelinquent ? String(user.isDelinquent) : '',
      },
    ];

    if (policy) {
      searchParams.unshift({
        key: 'insurance',
        value: policy.type,
      });
    }

    if (location.pathname.includes('/account')) {
      searchParams.unshift({
        key: 'insurance',
        value: getInsuranceParamFromPolicies(policies),
      });
    }

    const pathname = paramsSetUrl(location.pathname, searchParams);
    const locale = urlLocale
      ? ''
      : `/${language()}-${policy?.region || region}`;

    history.replace(`${locale}${pathname}`);
  };

  /**
   * Stop the timeout when the messenger successfully loads.
   * onShow is triggered when the messenger is opened.
   */
  const onLoadStarted = async (stopTimeout: () => void) => {
    await bootIntercomWithUserData(user);
    window.Intercom &&
      window.Intercom('onShow', () => {
        stopTimeout();

        if (isMobileApp) {
          window?.webkit?.messageHandlers?.toggleIntercomHandler?.postMessage({
            intercomStatus: 'SHOW',
          });

          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({ intercomStatus: 'SHOW' })
          );
        }
      });

    if (isMobileApp) {
      window?.Intercom?.('onHide', () => {
        window?.webkit?.messageHandlers?.toggleIntercomHandler?.postMessage({
          intercomStatus: 'HIDE',
        });

        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ intercomStatus: 'HIDE' })
        );
      });
    }
  };

  const {
    setWidgetTimeout,
    resetTimeout,
    status: timeoutStatus,
  } = useWidgetTimeout({
    onLoadStarted,
    onError,
  });

  const handleClick = async () => {
    shutdownIntercom();
    appendAdditionalParams();

    if (chat === 'initial' || !window.intercomSettings) {
      loadChat({ open: true });
      await setWidgetTimeout();
    }
  };

  const handleResetIntercom = async () => {
    resetTimeout();
    /**
     * Clean Intercom settings to force a new boot.
     */
    shutdownIntercom();
  };

  if (timeoutStatus === 'ERROR') {
    return (
      <>
        {createPortal(
          <BottomOrRegularModal
            isOpen
            onClose={handleResetIntercom}
            title={t('intercomLauncher.error.modal.title', 'Contact us')}
          >
            <p className="p-p pt16 pr24 pl24 pb24">
              <Trans i18nKey="intercomLauncher.error.modal.content">
                Have questions about your insurance or need help?
                <br />
                Reach out to us at{' '}
                <a href={`mailto:${email.hello}`} className="p-a">
                  {{ email: email.hello }}
                </a>
              </Trans>
            </p>
            <p className="p-p pb24 pl24 pr24">
              <a
                href={`mailto:${email.hello}`}
                className="p-btn p-btn--primary"
              >
                {t(
                  'intercomLauncher.error.modal.button.title',
                  'Send us an email'
                )}
              </a>
            </p>
          </BottomOrRegularModal>,
          document.body
        )}
        <div className={classnames(styles.container, styles.disabled)}>
          {render({ error: true, loading: false, handleClick })}
        </div>
      </>
    );
  }

  if (isLoading || timeoutStatus === 'LOADING')
    return (
      <div className={classnames(styles.container, styles.disabled)}>
        {render({ error: false, loading: true, handleClick })}
      </div>
    );

  return (
    <div className={styles.container} id="intercom_custom_button">
      {render({ error: false, loading: false, handleClick })}
    </div>
  );
};
