import { QuestionnaireTypes } from '../models/recommendation';
import bikeQuestionnaire from './bike';
import dentalQuestionnaire from './dental';
import healthQuestionnaire from './healthQuestionnaire';
import householdQuestionnaire from './household';
import legalQuestionnaire from './legal';
import liabilityQuestionnaire from './liability';
import lifeQuestionnaire from './life';
import quickRecommendationQuestionnaire from './quickRecommendation';

type AllowedQuestionnaires =
  | typeof dentalQuestionnaire
  | typeof healthQuestionnaire
  | typeof householdQuestionnaire
  | typeof liabilityQuestionnaire
  | typeof lifeQuestionnaire
  | typeof legalQuestionnaire
  | typeof bikeQuestionnaire
  | typeof quickRecommendationQuestionnaire;

type AllQuestionnaires = Record<QuestionnaireTypes, AllowedQuestionnaires>;

const questionnaires: AllQuestionnaires = {
  health: healthQuestionnaire,
  liability: liabilityQuestionnaire,
  life: lifeQuestionnaire,
  dental: dentalQuestionnaire,
  household: householdQuestionnaire,
  legal: legalQuestionnaire,
  bike: bikeQuestionnaire,
  quickRecommendation: quickRecommendationQuestionnaire,
};

export default questionnaires;
