import dayjs from 'dayjs';
import type {
  DentalQuestionnaire,
  IDentalCleaning,
} from 'features/recommendationTool/models/dental';
import { dentalCleaningMapping } from 'features/recommendationTool/models/dental';
import { getAccountInfo } from 'features/recommendationTool/questionnaires/account';

import { createProcessingQuestion, submitQuestionId } from '../processing';

const getDateOfBirthYearRange = () => {
  const now = dayjs();
  return {
    min: now.subtract(100, 'years').year(),
    max: now.year(),
  };
};

const dentalQuestionnaire: DentalQuestionnaire = {
  dateOfBirth: {
    required: true,
    type: 'DATE',
    props: {
      question: 'What’s your date of birth?',
      yearRange: getDateOfBirthYearRange(),
    },
    retrieveAnswerObject: (dateOfBirth: string) => ({ dateOfBirth }),
    retrieveNextPageId: () =>
      'isCurrentlyCoveredWithGermanPublicHealthProvider',
    groupId: 'personalInfo',
  },
  isCurrentlyCoveredWithGermanPublicHealthProvider: {
    required: (answers) =>
      !answers.isCurrentlyCoveredWithGermanPublicHealthProvider,
    type: 'BOOLEAN',
    props: {
      question:
        'Are you currently covered with a German public health provider?',
    },
    retrieveAnswerObject: (
      isCurrentlyCoveredWithGermanPublicHealthProvider: boolean
    ) => ({
      isCurrentlyCoveredWithGermanPublicHealthProvider,
    }),
    retrieveNextPageId: () => 'dentalCleaning',
    groupId: 'personalInfo',
  },
  dentalCleaning: {
    required: (answers) => !answers.dentalCleaning,
    type: 'RADIO',
    props: {
      question: 'How often would you like to get dental cleanings?',
      mapValue: dentalCleaningMapping,
    },
    retrieveAnswerObject: (dentalCleaning: IDentalCleaning) => ({
      dentalCleaning,
    }),
    retrieveNextPageId: () => submitQuestionId,
    groupId: 'personalInfo',
  },
  ...createProcessingQuestion('dental'),
};

const accountInfo = getAccountInfo<DentalQuestionnaire>('dateOfBirth');

const dentalQuestionnaireWithAccount: DentalQuestionnaire = {
  ...accountInfo,
  ...dentalQuestionnaire,
};

export default dentalQuestionnaireWithAccount;
