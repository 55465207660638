import { Card, CardProps } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import styles from './ActionsList.module.scss';

type ExtendedCardProps =
  | CardProps<'a'>
  | CardProps<'section'>
  | CardProps<typeof Link>;

export type ActionListItem = ExtendedCardProps & {
  key?: string;
  shouldRender?: boolean;
  customRender?: (props: CardProps) => ReactNode;
};

export interface ActionsListProps {
  title?: string;
  items: ActionListItem[];
}

export const ActionsList = ({ items, title }: ActionsListProps) => {
  const cardProps: CardProps = {
    titleVariant: 'small',
    density: 'compact',
    dropShadow: false,
    showActionIcon: true,
    classNames: {
      wrapper: 'bg-transparent tc-grey-900',
      buttonWrapper: styles.card,
      icon: 'tc-grey-900',
    },
  };

  return (
    <section className="mt32">
      {title && <h3 className="p-h3 mb16">{title}</h3>}

      <div className={classNames(styles.container, 'bg-white bs-xs br8')}>
        {items.map(({ key, customRender, shouldRender = true, ...rest }) => {
          if (!shouldRender) {
            return null;
          }

          if (customRender) {
            return <Fragment key={key}>{customRender(cardProps)}</Fragment>;
          }

          return (
            <Card<'a' | 'section' | typeof Link>
              key={key}
              {...cardProps}
              {...rest}
            />
          );
        })}
      </div>
    </section>
  );
};
