import { BottomOrRegularModal } from '@popsure/dirty-swan';
import classNames from 'classnames';
import routes from 'constants/routes';
import { DeleteAccountModalProps } from 'models/account';
import { Link } from 'react-router-dom';

import styles from './style.module.scss';

export const ActivePoliciesModal = ({
  t,
  showDeleteAccountModal,
  setShowDeleteAccountModal,
}: DeleteAccountModalProps) => (
  <BottomOrRegularModal
    title={t(
      'account.deleteAccountModal.activePolicies.title',
      'You still have active policies'
    )}
    isOpen={showDeleteAccountModal}
    onClose={() => setShowDeleteAccountModal(false)}
  >
    <div
      className={classNames('pt16 px24 pb24', styles.deleteAccountModal)}
      data-testid="active-policies-delete-modal"
    >
      <p className="p-p wmx6">
        {t(
          'account.deleteAccountModal.activePolicies.description',
          'Please cancel your active policies before requesting an account deletion.'
        )}
      </p>
      <div className={styles.buttonContainer}>
        <Link to={routes.me.policies.path} className="p-btn--primary mt24">
          {t(
            'account.deleteAccountModal.requestAccountDeletionButton.activePolicies.caption',
            'Go to policies'
          )}
        </Link>
      </div>
    </div>
  </BottomOrRegularModal>
);
