import { Address as AddressModel } from '@getpopsure/public-models';
import {
  AddressForm,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

export const LegalAddress = <Questionnaire extends QuestionnaireQuestions>({
  value,
  onSubmitValue,
  setValidForSubmission,
  questionnaireAnswers,
  ...props
}: QuestionnaireFormProps<Questionnaire>) => {
  const [state, setState] = useState({
    type: 'INITIAL',
  });
  const { t } = useSafeTranslation();

  const handleSubmit = (address: Partial<AddressModel>) => {
    if (address.postcode === questionnaireAnswers.postcode) {
      onSubmitValue(address);
    } else {
      setValidForSubmission(false);
      setState({
        type: 'ERROR',
      });
    }
  };

  return (
    <>
      <AddressForm
        onSubmitValue={handleSubmit}
        setValidForSubmission={setValidForSubmission}
        questionnaireAnswers={questionnaireAnswers}
        {...props}
      />
      <AnimateHeight
        duration={300}
        height={state.type === 'ERROR' ? 'auto' : 0}
      >
        <div className="wmx8 p-notice p-notice--warning mt16 p-p">
          {t('legal.qnr.form.address.error', "Your postcode doesn't match!")}
        </div>
      </AnimateHeight>
    </>
  );
};
