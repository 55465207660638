import { TFunction } from '@getpopsure/i18n-react';

import { Step } from '../models';

export const studentChecklistTitleLookup = (
  currentSteps: Step[],
  t: TFunction
): string => {
  /** Do not return a title if the checklist is completed */
  if (
    currentSteps.findIndex((step) => step.id === 'PROCESSING_APPLICATION') > -1
  ) {
    return '';
  }

  return t('studentChecklist.title.active', 'Steps to get you covered');
};
