import { Checklist } from 'features/studentChecklist/components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getApplicationChecklist,
  getHealthCardChecklist,
  getHealthCardSteps,
} from 'selectors/healthCard';

export const HealthCardChecklist = () => {
  const { policyId } = useParams<{ policyId: string }>();
  const showChecklist = useSelector(getApplicationChecklist(policyId));
  const isCompleted = useSelector(
    getHealthCardChecklist(policyId)
  )?.isFinalized;
  const { steps, title } = useSelector(getHealthCardSteps(policyId));

  if (!showChecklist || steps.length === 0) return null;

  return (
    <Checklist title={title} steps={steps} submittedChecklist={isCompleted} />
  );
};
