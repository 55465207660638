import { DentalClaims } from 'features/dentalClaims/models';
import { useUploadFiles } from 'hooks/useUploadFiles';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import { UploadedFile } from 'shared/models/types';

import { DentalClaimsUploadDocumentsView } from './UploadDocuments.view';

export const DentalClaimsUploadDocuments = ({
  value,
  onSubmitValue,
}: CustomComponentProps<DentalClaims>) => {
  const { t } = useSafeTranslation();
  const [validationError, setValidationError] = useState('');

  const {
    uploadFile,
    uploadedFiles,
    loading: uploadLoading,
    removeFile,
    isSubmitValid,
  } = useUploadFiles({
    initialValue: (value as UploadedFile[]) ?? [],
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (uploadedFiles.length < 1) {
      setValidationError('Document token(s) is missing for submission');
      return;
    }

    onSubmitValue(uploadedFiles);
  };

  return (
    <DentalClaimsUploadDocumentsView
      uploadedFiles={uploadedFiles}
      uploadLoading={uploadLoading}
      onFileSelect={uploadFile}
      onRemoveFile={removeFile}
      handleSubmit={handleSubmit}
      t={t}
      isSubmitValid={isSubmitValid}
      error={validationError}
    />
  );
};
