export default function englishFormattedEuroCurrency(
  amount: number,
  minimumFractionDigits?: number
) {
  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits,
  }).format(amount);
}
