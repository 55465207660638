import type { FallbackOption, TranslationSource } from 'features/i18n/reducers';

export const setTranslationSource = (translationSource: TranslationSource) => ({
  type: 'SET_TRANSLATION_SOURCE',
  translationSource,
});

export const setUntranslatedFallback = (fallbackOption: FallbackOption) => ({
  type: 'SET_UNTRANSLATED_FALLBACK',
  fallbackOption,
});
