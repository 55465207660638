import { Card, toast } from '@popsure/dirty-swan';
import DelinquencyBlocker from 'components/DelinquencyBlocker';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import { DeleteAccountModal } from 'features/accountV2/components/DeleteAccountModal';
import { PoliciesStatus } from 'features/accountV2/util';
import formatUserInfo from 'features/accountV2/util/normalizeAccountInfoForFrontend';
import { GoogleConnectButton } from 'features/GoogleOAuth';
import { IntercomLoaderWrapper } from 'features/intercomLauncher';
import { User } from 'models/user';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import formatSvnr from 'shared/util/svnrFormatter';

import SvnrModal from '../../components/SvnrModal';
import styles from './AccountSettings.module.scss';

interface AccountSettingsProps {
  user: User;
  isDelinquent: boolean;
  policyStatus: PoliciesStatus;
  activeDeleteRequest: boolean;
  deleteUserAccount: (successCb: () => void, failureCb: () => void) => void;
}

const AccountSettings = ({
  user,
  activeDeleteRequest,
  deleteUserAccount,
  isDelinquent,
  policyStatus,
}: AccountSettingsProps) => {
  const { t } = useSafeTranslation();
  const [showDeleteAccountModal, setShowDeleteAccountModal] =
    useState<boolean>(false);
  const [openDelinquencyBlocker, setOpenDelinquencyBlocker] = useState(false);
  const [showSvnrModal, setShowSvnrModal] = useState(false);

  const deleteUserAccountAction = () =>
    deleteUserAccount(
      () => {
        toast('Your request has been sent.', {
          type: 'success',
        });
      },
      () => {
        toast('Your request could not be sent.', {
          type: 'error',
        });
      }
    );

  const basicInfo = formatUserInfo(t, user);

  return (
    <>
      <DelinquencyBlocker
        onClose={() => setOpenDelinquencyBlocker(false)}
        isOpen={openDelinquencyBlocker}
        description={t(
          'account.delinquencyBlocker.accountDeletion.description',
          'You can request an account deletion once the outstanding insurance premiums are successfully collected.'
        )}
      />

      <DeleteAccountModal
        showDeleteAccountModal={showDeleteAccountModal}
        setShowDeleteAccountModal={setShowDeleteAccountModal}
        t={t}
        status={policyStatus}
        deleteUserAccount={deleteUserAccountAction}
        activeDeleteRequest={activeDeleteRequest}
      />

      <div className="p0 pb48">
        <div className={`p-body wmx8 ${styles.body}`}>
          <NavButton
            path={routes.account.path}
            title={t('account.backButton.caption', 'Account')}
          />
          <h1 className="p-h1 mb24">
            {t('account.settings.accountSettings.title', 'Account settings')}
          </h1>
          <div>
            <div className="bg-white br8 bs-xs p16">
              {Object.entries(basicInfo).map(([label, value]) => (
                <div className="p16" key={label}>
                  <h4 className="p-h4 tc-grey-900 mb8">{label}</h4>
                  <div className="p-p tc-grey-600">{String(value)}</div>
                </div>
              ))}

              <GoogleConnectButton />
            </div>
            <div className="p-p tc-grey-500 p8 mt8 d-inline-block">
              {t(
                'account.personalInfo.reachOutInfo',
                'If you want to edit any of this information '
              )}{' '}
              <IntercomLoaderWrapper
                render={({ handleClick }) => (
                  <button
                    type="button"
                    className="p-a bg-transparent c-pointer"
                    onClick={handleClick}
                  >
                    {t(
                      'account.personalInfo.reachOut.button',
                      'reach out to us'
                    )}
                  </button>
                )}
              />
              .
            </div>
          </div>

          {user.svnr && (
            <div className="mt16">
              <h3 className="p-h3 p8 mb8">
                {t(
                  'account.personalInfo.additionalInfo.title',
                  'Additional information'
                )}
              </h3>
              <div className="bg-white br8 bs-xs p24">
                <div className="d-flex fd-row p16">
                  <span className={`tc-grey-600 ${styles.column}`}>
                    {t(
                      'account.personalInfo.socialSecurityNumber.label',
                      'Social Security'
                    )}
                  </span>{' '}
                  {formatSvnr(user.svnr)}
                </div>
              </div>
              <div className={styles.hint}>
                <a
                  href="/#"
                  role="button"
                  className="p-a d-block p8 mt8"
                  onClick={(event) => {
                    event.preventDefault();
                    setShowSvnrModal(true);
                  }}
                >
                  {t(
                    'account.personalInfo.socialSecurityNumber.learnMoreLink',
                    'Learn more about Social Security Numbers'
                  )}
                </a>
              </div>
            </div>
          )}

          <Card
            classNames={{ buttonWrapper: 'mt40 bs-xs' }}
            title={t(
              'account.deleteAccountButton.caption',
              'Delete my account'
            )}
            titleVariant="small"
            density="compact"
            dropShadow={false}
            onClick={() => {
              if (isDelinquent) {
                setOpenDelinquencyBlocker(true);
              } else {
                setShowDeleteAccountModal(true);
              }
            }}
          />
        </div>

        <SvnrModal
          isOpen={showSvnrModal}
          onClose={() => setShowSvnrModal(false)}
        />
      </div>
    </>
  );
};

export default AccountSettings;
