import {
  getPriceBreakdown,
  tariffLongNameMapping,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import {
  deductibleLabelsMapping,
  TariffsWithPublic,
} from 'features/privateHealthPreSignup/models';
import { getPublicHealthContribution } from 'features/privateHealthPreSignup/util';

import styles from './ContributionModal.module.scss';

interface Props {
  priceData: PrivateOrPublicPriceData;
}

interface MappedValues {
  totalContribution: number;
  totalTariff: number;
  longTermCare: number;
  additionalContribution?: number;
  employerContribution: number | null;
  statutorySurcharge?: number;
}

export type PrivateOrPublicPriceData =
  | ReturnType<typeof getPriceBreakdown>
  | ReturnType<typeof getPublicHealthContribution>;

const getTariffLabel = (tariff: TariffsWithPublic): string => {
  const tariffs: { [k in TariffsWithPublic]: string } = {
    NKSelectS: `${tariffLongNameMapping.NKSelectS} coverage`,
    NKSelectL: `${tariffLongNameMapping.NKSelectL} coverage`,
    NKSelectXL: `${tariffLongNameMapping.NKSelectXL} coverage`,
    Public: 'Health insurance',
    HiMedical: `${tariffLongNameMapping.HiMedical} coverage`,
    HiMedicalPlus: `${tariffLongNameMapping.HiMedicalPlus} coverage`,
  };
  return tariffs[tariff];
};

const getMappedValues = (
  priceData: NonNullable<PrivateOrPublicPriceData>
): MappedValues => {
  if ('healthInsurance' in priceData) {
    return {
      totalContribution: priceData.total,
      totalTariff: priceData.tariffTotal,
      additionalContribution: priceData.additional.amount,
      longTermCare: priceData.longTermCare.amount,
      employerContribution: priceData.employerContribution,
    };
  }

  return {
    totalContribution: priceData.contributions.own,
    employerContribution: priceData.contributions.employer,
    longTermCare: priceData.tariff.longTermCare,
    totalTariff: priceData.tariff.totalPrice,
  };
};

const privateHealthContent = (
  <>
    <p className="p-p">
      Cost is calculated based on{' '}
      <span className="fw-bold">age at sign-up</span> and does not increase
      based on your age as you get older.{' '}
      <span className="fw-bold">
        There are no price increases due to newly developed medical conditions.
      </span>
    </p>
    <p className="p-p mt24">
      The only time insurance public and private health prices change is when
      there’s an overall increase in healthcare costs. Historically, the costs
      have increased 2% per year.
    </p>
    <p className="p-p mt24">
      With the prices you see, we try and be as accurate as possible. They are
      an estimate and are likely to change, only slightly, after you complete
      your application
    </p>
  </>
);

const publicHealthContent = (
  <p className="p-p">
    On public health insurance, the price depends on your employment status,
    your monthly income and your age. Other factors will influence your final
    contributions, for example other income or pension benefits, etc.
  </p>
);

export const ContributionModal = (props: Props) => {
  const { priceData } = props;

  if (!priceData) {
    return <p>We cannot provide a calculation at this moment.</p>;
  }

  const tariff = priceData.tariffName as TariffsWithPublic;

  const cashbackObject =
    'cashback' in priceData ? priceData.cashback : undefined;

  const isPublicHealth = 'healthInsurance' in priceData;

  const mappedValues = getMappedValues(priceData);

  const content = isPublicHealth ? publicHealthContent : privateHealthContent;

  return (
    <div className="wmx6">
      {content}
      <div className="mt32">
        <div
          className={`d-flex mb24 ${styles['space-between']} ${styles['align-baseline']}`}
        >
          <p className="p-h3">Your total contribution</p>
          <p className="p-h2">
            {englishFormattedEuroCurrency(mappedValues.totalContribution)}
          </p>
        </div>
        <div className={`d-flex mb16 ${styles['space-between']}`}>
          <p className="p-p">
            🛡 {getTariffLabel(tariff)}{' '}
            <span className="tc-grey-500">
              {deductibleLabelsMapping[tariff]}
            </span>
          </p>
          <p className="p-p ta-right">
            {englishFormattedEuroCurrency(mappedValues.totalTariff)}
          </p>
        </div>
        <div className={`d-flex mb16 ml24 ${styles['space-between']}`}>
          <p className="p-p">
            <span className="tc-grey-500">—</span> Long term care
          </p>
          <p className="p-p ta-right">
            {englishFormattedEuroCurrency(mappedValues.longTermCare)}
          </p>
        </div>
        {mappedValues.additionalContribution && (
          <div className={`d-flex mb16 ml24 ${styles['space-between']}`}>
            <p className="p-p">
              <span className="tc-grey-500">—</span> Provider’s additional
              contribution
            </p>
            <p className="p-p ta-right">
              {englishFormattedEuroCurrency(
                mappedValues.additionalContribution
              )}
            </p>
          </div>
        )}
        {cashbackObject && (
          <div className={`d-flex mb24 ${styles['space-between']}`}>
            <p className="p-p">🎉 Cashback</p>
            <p className="p-p ta-right tc-grey-500">
              Up to{' '}
              {englishFormattedEuroCurrency(
                cashbackObject.totalPotentialCashback,
                true
              )}{' '}
              / year
            </p>
          </div>
        )}
        {mappedValues.employerContribution ? (
          <div
            className={`d-flex mb16 ${styles['space-between']} ${styles.employer}`}
          >
            <p className="p-p">🏢 Employer pays</p>
            <p className="p-p ta-right tc-grey-500">
              {englishFormattedEuroCurrency(mappedValues.employerContribution)}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};
