import { isValidPostcode } from '@getpopsure/household-insurance-pricing-engine';
import { TFunction } from '@getpopsure/i18n-react';
import { Address } from '@getpopsure/public-models';
import {
  Household,
  HouseholdQuestionnaire,
  questionPropLookup,
} from 'features/household/models';

import { AddonsPage } from './AddonsPage';
import { QuotePage } from './QuotePage';

const metersToFeet = (m: number) => 3.28084 * m;
const feetToMeters = (f: number) => f / 3.28084;

export const policyOptionsQuestionnaire = (
  t: TFunction
): HouseholdQuestionnaire => ({
  address: {
    required: true,
    type: 'ADDRESS',
    props: {
      ...questionPropLookup(t).address,
    },
    retrieveAnswerObject: (address: Address): Partial<Household> => ({
      address,
    }),
    groupId: 'signup',
    validateAnswer: (address: Household['address']) => {
      if (!isValidPostcode(address.postcode)) {
        return t(
          'page.household.questionnaire.address.error.invalidpostcode',
          'The postcode you entered is invalid. Please check your postcode and try again.'
        );
      }
      return null;
    },
    retrieveQuestionsToRemove: () => ['insuredSum', 'review'],
  },

  livingSpace: {
    required: true,
    type: 'NUMERIC_CONVERSION',
    props: {
      ...questionPropLookup(t).livingSpace,
      unitLabels: [
        t('page.household.questionnaire.livingspace.unit.sqmeters', 'm²'),
        t('page.household.questionnaire.livingspace.unit.sqfeet', 'sq. ft'),
      ],
      conversionFunctions: [metersToFeet, feetToMeters],
    },
    retrieveAnswerObject: ([meters, feet, selectedUnit]: [
      number,
      number,
      number
    ]): Partial<Household> => ({
      livingSpace: {
        propertyInSqMeters: meters,
        propertyInSqFeet: feet,
        selectedUnitSize: selectedUnit === 0 ? 'SQUARE_METERS' : 'SQUARE_FEET',
      },
    }),
    transformValue: (livingSpace?: Household['livingSpace']) => [
      livingSpace?.propertyInSqMeters,
      livingSpace?.propertyInSqFeet,
      livingSpace?.selectedUnitSize === 'SQUARE_FEET' ? 1 : 0,
    ],
    groupId: 'signup',
    retrieveQuestionsToRemove: () => ['review'],
  },

  securityDevices: {
    required: true,
    type: 'CHECKBOX',
    props: {
      mapValue: {},
      ...questionPropLookup(t).securityDevices,
    },
    retrieveAnswerObject: (
      ownedSecurityDevices: Household['securityDevices']['ownedSecurityDevices']
    ): Partial<Household> => ({
      securityDevices: {
        hasSecurityDevice: !ownedSecurityDevices.some((s) => s === 'NONE'),
        ownedSecurityDevices,
      },
    }),
    transformValue: (securityDevices?: Household['securityDevices']) =>
      securityDevices?.ownedSecurityDevices,
    groupId: 'signup',
    retrieveQuestionsToRemove: () => ['review'],
  },

  insuredSum: {
    required: true,
    type: 'CUSTOM',
    retrieveAnswerObject: (insuredSum: number): Partial<Household> => ({
      insuredSum,
    }),
    groupId: 'signup',
    component: QuotePage,
    retrieveQuestionsToRemove: () => ['review'],
  },

  addonOptions: {
    required: true,
    type: 'CUSTOM',
    retrieveAnswerObject: (
      addonOptions: Household['addonOptions']
    ): Partial<Household> => ({
      addonOptions,
    }),
    groupId: 'signup',
    component: AddonsPage,
    retrieveQuestionsToRemove: () => ['review'],
  },
});
