import React from 'react';

import styles from './InlineButton.module.scss';

interface InlineButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

export const InlineButton = ({ onClick, children }: InlineButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    className={`p-p p-p--small tc-primary-500 wmx3 c-pointer ${styles.button}`}
  >
    {children}
  </button>
);
