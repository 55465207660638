import { BottomOrRegularModal, Button } from '@popsure/dirty-swan';
import { APIResponseError } from 'models/error';
import AnimateHeight from 'react-animate-height';

interface ConfirmReceivedHealthCardModalProps {
  onMarkAsReceived(): void;
  onCancel(): void;
  loading?: boolean;
  error?: APIResponseError;
}

export const ConfirmReceivedHealthCardModal = (
  props: ConfirmReceivedHealthCardModalProps
) => {
  const { loading, onMarkAsReceived, onCancel, error } = props;
  return (
    <BottomOrRegularModal title="Are you sure" isOpen onClose={onCancel}>
      <div className="p24">
        <p className="p-p mb24">Confirm that you received your health card.</p>
        <Button loading={loading} onClick={onMarkAsReceived} className="mr8">
          Yes, I received it
        </Button>
        <Button variant="textColor" onClick={onCancel}>
          Cancel
        </Button>
      </div>
      <AnimateHeight duration={150} height={error ? 'auto' : 0}>
        <div className="p-notice p-notice--danger mt8 p-p">
          {error?.message}
        </div>
      </AnimateHeight>
    </BottomOrRegularModal>
  );
};
