import { fetchHealthCard } from 'actions/healthCard';
import axios from 'axios';
import { HealthCardDispatch, HealthCardPhoto } from 'models/healthCard';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHealthCardDetail } from 'selectors/healthCard';
import endpoint from 'shared/api';

interface usePollHealthCardDetailsReturnValues {
  loading: boolean;
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  photo: HealthCardPhoto | null;
  setPhoto: React.Dispatch<React.SetStateAction<HealthCardPhoto | null>>;
  startPolling: () => void;
  stopPolling: () => void;
  qrToken: string | null;
  setQRToken: React.Dispatch<React.SetStateAction<string | null>>;
}

type PollHealthCardDetailsResponse =
  | { status: 'FETCHING'; payload: null }
  | { status: 'LOADING_PHOTO'; payload: null }
  | { status: 'COMPLETED'; payload: HealthCardPhoto }
  | { status: 'ERROR'; payload: string };

const POLLING_INTERVAL = 2_000;
const TIMEOUT_VALUE = 25_000;

export const pollHealthCardDetails = async ({
  healthCardId,
  healthCardDetails,
}): Promise<PollHealthCardDetailsResponse> => {
  try {
    if (!healthCardId)
      return { status: 'ERROR', payload: 'The Health Card ID is required' };

    const response = await endpoint.fetchHealthCardDetails(healthCardId);

    if (!response || !response.data)
      return {
        status: 'ERROR',
        payload: 'Error fetching the health card details',
      };

    const { temporaryPhoto } = response.data;
    const newPhoto = Boolean(
      temporaryPhoto &&
        temporaryPhoto.id !== healthCardDetails?.temporaryPhoto?.id
    );
    const loaded = Boolean(
      newPhoto && temporaryPhoto && temporaryPhoto.markedUploadedAt
    );

    if (loaded && newPhoto && temporaryPhoto) {
      return { status: 'COMPLETED', payload: temporaryPhoto };
    }

    if (newPhoto && !loaded) {
      return { status: 'LOADING_PHOTO', payload: null };
    }

    return { status: 'FETCHING', payload: null };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { status: 'ERROR', payload: error.message };
    }
    if (typeof error === 'string') {
      return { status: 'ERROR', payload: error };
    }

    return { status: 'ERROR', payload: 'unhandled error' };
  }
};

export const usePollHealthCardDetails = (
  healthCardId: string | null
): usePollHealthCardDetailsReturnValues => {
  const dispatch = useDispatch<HealthCardDispatch>();
  const healthCardDetails = useSelector(getHealthCardDetail(healthCardId));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [photo, setPhoto] = useState<HealthCardPhoto | null>(
    healthCardDetails?.approvedPhoto || null
  );
  const [qrToken, setQRToken] = useState<string | null>(null);
  const interval = useRef<NodeJS.Timeout>();
  const timeout = useRef<NodeJS.Timeout>();

  const refreshHealthCard = useCallback(
    async () => healthCardId && (await dispatch(fetchHealthCard(healthCardId))),
    [dispatch, healthCardId]
  );

  const stopPolling = useCallback(async () => {
    clearInterval(interval.current as NodeJS.Timeout);
    clearTimeout(timeout.current as NodeJS.Timeout);
    setLoading(false);
    setQRToken(null);
    await refreshHealthCard();
  }, [refreshHealthCard]);

  useEffect(() => {
    return () => {
      clearInterval(interval.current as NodeJS.Timeout);
      clearTimeout(timeout.current as NodeJS.Timeout);
    };
  }, []);

  useEffect(() => {
    const handleTimeout = () => {
      stopPolling().then(() => {
        setError("That didn't work. Please try again.");
      });
    };

    if (loading) {
      timeout.current = setTimeout(() => handleTimeout(), TIMEOUT_VALUE);
    }
  }, [stopPolling, loading]);

  const startPolling = () => {
    interval.current = setInterval(async () => {
      const result = await pollHealthCardDetails({
        healthCardId,
        healthCardDetails,
      });

      if (result.status === 'LOADING_PHOTO') {
        setLoading(true);
      }

      if (result.status === 'COMPLETED') {
        stopPolling().then(() => setPhoto(result.payload));
      }

      if (result.status === 'ERROR') {
        stopPolling().then(() => setError(result.payload));
      }
    }, POLLING_INTERVAL);
  };

  return {
    loading,
    error,
    setError,
    photo,
    setPhoto,
    startPolling,
    stopPolling,
    qrToken,
    setQRToken,
  };
};
