import { language, Locale, TFunction } from '@getpopsure/i18n-react';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import {
  createHouseholdQuestionnaire,
  createHouseholdQuote,
} from 'features/household/api';
import {
  HouseholdAnswersBackendSchema,
  HouseholdQuestionnaireLabels,
  HouseholdQuotesDto,
} from 'features/household/models';
import { APIResponseError } from 'models/error';
import { UserNewWithRequiredInfo } from 'models/user';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import endpoint, { Client } from 'shared/api';
import { ActionResponse } from 'shared/models/types';

// Augment the shared api with our endpoint functions
Client.prototype.createHouseholdQuestionnaire = createHouseholdQuestionnaire;
Client.prototype.createHouseholdQuote = createHouseholdQuote;

export type CreateQuoteAction<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  Record<string, unknown>,
  GenericQuestionnaireAction<'household'> | RequestAction
>;

export type CreateQuoteDispatch = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  GenericQuestionnaireAction<'household'> | RequestAction
>;

export type CreateQuoteReturnType = {
  status: ActionResponse;
};

export const createQuote =
  (
    answers: HouseholdAnswersBackendSchema,
    labels: HouseholdQuestionnaireLabels,
    quotesDto: HouseholdQuotesDto,
    createNewAccount: boolean,
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'household'> | RequestAction
  > =>
  async (dispatch) => {
    const requestType = 'CREATE_QUOTE';
    dispatch(setRequestInProcess(true, requestType));

    try {
      if (createNewAccount) {
        const {
          personalInfo: { email, name, gender, dateOfBirth },
        } = answers;
        const user: UserNewWithRequiredInfo = {
          email,
          ...name,
          gender,
          dateOfBirth,
          language: language() as Locale,
        };

        await dispatch(createAccountV2(user));
      }

      const {
        data: { id: questionnaireId },
      } = await endpoint.createHouseholdQuestionnaire(answers, labels);

      const {
        data: { id: quoteId, price: totalPrice },
      } = await endpoint.createHouseholdQuote(quotesDto);

      await generateCheckoutDocuments(questionnaireId, quoteId, 'HOUSEHOLD', t);

      dispatch(setRequestInProcess(false, requestType));

      dispatch(
        storeGenericQuestionnaireAnswer('household', {
          quote: {
            questionnaireId,
            quoteId,
            totalPrice,
          },
        })
      );

      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      // eslint-disable-next-line no-console
      console.error(error);
      return { status: 'ERROR' };
    }
  };
