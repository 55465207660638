import { TableInfoButton } from '@popsure/dirty-swan';

import styles from './WithModalWrapper.module.scss';

interface Props<T> {
  children: React.ReactNode;
  row: T;
  tableKey: keyof T;
  onClick: () => void;
}

export const WithModalWrapper = <T,>(props: Props<T>) => {
  const { children, row, tableKey, onClick } = props;
  return (
    <div className={styles.container}>
      {children}
      {row[tableKey] !== undefined && (
        <div className={styles.button}>
          <TableInfoButton onClick={onClick} />
        </div>
      )}
    </div>
  );
};
