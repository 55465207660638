import { trackFaqItemOpened } from '@getpopsure/analytics';
import { useTranslation } from '@getpopsure/i18n-react';
import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import {
  Accordion,
  Card,
  Table,
  TableProps,
  Toggle,
} from '@popsure/dirty-swan';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import ReviewBadge from 'components/reviewBadge';
import SectionHeader from 'components/sectionHeader';
import { useEffect, useState } from 'react';
import { Processing } from 'shared/pages/Processing';
import { isMobileApp } from 'shared/util/isMobileApp';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import { QuoteFooter } from './components/Footer/Footer';
import { QuoteHeader } from './components/Header/Header';
import { QuoteCustomization } from './components/QuoteCustomization/QuoteCustomization';
import { QuoteConfig, QuotePlan } from './Quote.models';
import styles from './Quote.module.scss';

interface QuoteViewProps extends QuoteConfig {
  onBookACall: () => void;
  plans: QuotePlan[];
  hasWaitingTime?: boolean;
  onOptionsChange?: (
    addons: string[],
    options: Record<string, unknown>
  ) => void;
  headerImage: { src: string; alt: string };
  headerColor: string;
}

export const QuotePage = <Questionnaire extends QuestionnaireQuestions>({
  addons,
  covered,
  faq,
  footerLinks,
  headerImage,
  headerColor,
  notCovered,
  onBookACall,
  onOptionsChange,
  onSubmitValue,
  plans,
  quoteCustomization,
  table,
  title,
  hasWaitingTime = true,
}: QuestionnaireFormProps<Questionnaire> & QuoteViewProps) => {
  const { t } = useTranslation();
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);
  const [hasLoaded, setHasLoaded] = useState(!hasWaitingTime);
  const [addonsValues, setAddonsValues] = useState<string[]>([]);
  const [quoteOptions, setQuoteOptions] = useState<Record<string, unknown>>({});

  const toggleAddons = (value: string) => {
    if (addonsValues.includes(value)) {
      setAddonsValues(
        addonsValues.filter((currentKey) => currentKey !== value)
      );
    } else {
      setAddonsValues((prevState) => [...prevState, value]);
    }
  };

  useEffect(() => {
    onOptionsChange?.(addonsValues, quoteOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addonsValues, quoteOptions]);

  if (!hasLoaded) {
    return (
      <Processing
        onLoadComplete={() => setHasLoaded(true)}
        textList={[
          t('components.quote.processing.loadingText', 'Processing quote...'),
        ]}
      />
    );
  }

  return (
    <div className="w100 bg-white">
      <QuoteHeader
        title={title}
        headerImage={headerImage}
        headerColor={headerColor}
      />
      {plans.length === 1 && (
        <div className="ta-center pt24 mx-auto mb56">
          <div className="p-h1--xl p--serif tc-primary-500">
            {englishFormattedEuroCurrency(plans[selectedPlanIndex].price)}
          </div>
          <div className="p-p tc-grey-600">
            {t('components.quote.perMonth', '/per month')}
          </div>
        </div>
      )}
      <div className="p-body">
        {table.data && (
          <section className="mb80">
            <Table
              hideDetails
              textOverrides={{
                hideDetails: t(
                  'expatLongTerm.qnr.quote.table.hideDetails',
                  'Hide coverage details'
                ),
                showDetails: t(
                  'expatLongTerm.qnr.quote.table.showDetails',
                  'Coverage details'
                ),
              }}
              title=""
              tableData={table.data as TableProps['tableData']}
              // cellReplacements={cellReplacements}
              onSelectionChanged={setSelectedPlanIndex}
              stickyHeaderTopOffset={isMobileApp ? 88 : 0}
            />
          </section>
        )}

        <QuoteCustomization
          quoteCustomization={quoteCustomization}
          onOptionsChange={setQuoteOptions}
        />

        {addons?.addonItem.length > 0 && (
          <section className="mb80">
            <SectionHeader
              centeredOnMobile
              title={t(
                'components.quote.extendedCoverage.title',
                'Extend your coverage'
              )}
            />
            {addons.addonItem.map(
              ({ title: addonTitle, description, addonKey }) => (
                <Card
                  key={addonKey}
                  title={addonTitle}
                  description={description}
                  actionIcon={
                    <Toggle
                      classNames={{
                        container: classNames(styles.toggle, 'ml32'),
                      }}
                      bordered={false}
                      options={{ [addonKey]: '' }}
                      value={addonsValues}
                      onChange={() => toggleAddons(addonKey)}
                    />
                  }
                  onClick={() => toggleAddons(addonKey)}
                  dropShadow={false}
                  density="spacious"
                  showActionIcon
                  data-testid={`addon-${addonKey}`}
                  classNames={{
                    buttonWrapper: classNames('mt24', styles.card),
                    children: 'd-flex',
                  }}
                />
              )
            )}
          </section>
        )}

        {covered.card.length > 0 && (
          <section className="mb80">
            <SectionHeader centeredOnMobile title={covered.title} />

            <div
              className={classNames(styles.cardWrapper, 'd-grid gap16 mt24')}
            >
              {covered.card.map(
                ({ title: coverageTitle, description, icon, id }) => (
                  <Card
                    key={`coverage-card-${id}`}
                    title={coverageTitle}
                    description={
                      <Markdown paragraphClassName="tc-grey-600">
                        {description}
                      </Markdown>
                    }
                    icon={
                      <img
                        src={icon.src}
                        alt={icon.alt}
                        className={classNames(styles.coveredIcon)}
                      />
                    }
                    dropShadow={false}
                    verticalAlignment="top"
                    classNames={{ wrapper: styles.card }}
                  />
                )
              )}
            </div>
          </section>
        )}

        {notCovered.card.length > 0 && (
          <section className="mb80">
            <SectionHeader centeredOnMobile title={notCovered.title} />

            <div
              className={classNames(styles.cardWrapper, 'd-grid gap16 mt24')}
            >
              {notCovered.card.map(
                ({ title: coverageTitle, description, icon, id }) => (
                  <Card
                    key={`coverage-card-${id}`}
                    title={coverageTitle}
                    description={
                      <Markdown paragraphClassName="tc-grey-600">
                        {description}
                      </Markdown>
                    }
                    icon={
                      <img
                        src={icon.src}
                        alt={icon.alt}
                        className={classNames(styles.notCoveredIcon)}
                      />
                    }
                    dropShadow={false}
                    verticalAlignment="top"
                    classNames={{ wrapper: styles.card }}
                  />
                )
              )}
            </div>
          </section>
        )}

        <section className="p-body mb80">
          <SectionHeader
            centeredOnMobile
            title={t('components.faq.title', 'Frequently asked questions')}
          />
          <div className="mt32">
            <Accordion
              items={faq}
              onClick={({ question }) => trackFaqItemOpened({ question })}
            />
          </div>
        </section>

        <section>
          <ReviewBadge className="w100 mb80 d-flex jc-center" />
        </section>
      </div>
      <QuoteFooter
        links={footerLinks}
        onSubmit={() => onSubmitValue(plans[selectedPlanIndex])}
        bookACall={onBookACall}
        price={plans[selectedPlanIndex]?.price ?? 0}
      />
    </div>
  );
};
