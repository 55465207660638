import { RecommendationEntry } from 'features/recommendationTool/models/recommendation';

export const recommendationToNumber = (entry: RecommendationEntry): number => {
  if (entry.key === 'EHIC_HEALTH') {
    return 3;
  }

  switch (entry.eligibilityStatus) {
    case 'ELIGIBLE':
      return 1;
    case 'PARTIAL':
      return 2;
    case 'MORE_INFO_REQUIRED':
      return 4;
    case 'INELIGIBLE':
      return 5;
  }
};

export const recommendationCompareFn = (
  a: RecommendationEntry,
  b: RecommendationEntry
): number => recommendationToNumber(a) - recommendationToNumber(b);
