import { BottomOrRegularModal } from '@popsure/dirty-swan';
import Markdown from 'components/markdown';

import { Modal } from '../models';
import styles from './style.module.scss';

interface TableModalProps {
  modal: Modal;
  setModal: (value: Modal | null) => void;
}

export const TableModal = ({ modal, setModal }: TableModalProps) => {
  const handleClose = () => setModal(null);

  return (
    <BottomOrRegularModal isOpen title={modal.title} onClose={handleClose}>
      <div className={`${modal.wide ? 'wmx8' : 'wmx6'} ${styles.container}`}>
        {typeof modal.children === 'string' ? (
          <Markdown>{modal.children}</Markdown>
        ) : (
          modal.children
        )}
      </div>
    </BottomOrRegularModal>
  );
};
