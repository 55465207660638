import dayjs from 'dayjs';
import type { LifeQuestionnaire } from 'features/recommendationTool/models/life';
import { DependentsTypes } from 'features/recommendationTool/models/shared';
import { getAccountInfo } from 'features/recommendationTool/questionnaires/account';
import {
  createProcessingQuestion,
  submitQuestionId,
} from 'features/recommendationTool/questionnaires/processing';
import { dependentsMapping } from 'features/recommendationTool/questionnaires/shared';

const getDateOfBirthYearRange = () => {
  const now = dayjs();
  return {
    min: now.subtract(100, 'years').year(),
    max: now.year(),
  };
};

const getNextPageForSupport = (
  dependents: DependentsTypes[],
  skipTo: DependentsTypes[] = []
) => {
  return dependents.includes('SPOUSE') && !skipTo.includes('SPOUSE')
    ? 'partnerSupport'
    : dependents.includes('CHILDREN') && !skipTo.includes('CHILDREN')
    ? 'childrenSupport'
    : dependents.includes('PARENTS') && !skipTo.includes('PARENTS')
    ? 'parentsSupport'
    : dependents.includes('OTHER')
    ? 'otherSupport'
    : 'debt';
};

const lifeQuestionnaire: LifeQuestionnaire = {
  dateOfBirth: {
    required: true,
    type: 'DATE',
    props: {
      question: 'What’s your date of birth?',
      yearRange: getDateOfBirthYearRange(),
    },
    retrieveAnswerObject: (dateOfBirth: string) => ({ dateOfBirth }),
    retrieveNextPageId: () => 'dependents',
    groupId: 'personalInfo',
  },
  dependents: {
    required: (answers) => !answers.dependents,
    type: 'CHECKBOX',
    props: {
      question: 'Do you have any financial responsibilities?',
      mapValue: dependentsMapping,
      optionClassName: 'w30',
      inlineLayout: true,
    },
    retrieveAnswerObject: (dependents: DependentsTypes[]) => ({
      dependents: dependents.includes('NONE') ? [] : dependents,
    }),
    groupId: 'dependentsInfo',
    retrieveNextPageId: (dependents: DependentsTypes[]) =>
      getNextPageForSupport(dependents),
  },
  partnerSupport: {
    required: (answers) =>
      !!answers.dependents &&
      answers.dependents.includes('SPOUSE') &&
      !answers.partnerSupport,
    type: 'CURRENCY',
    props: {
      question: 'How much would you want to support your partner with?',
      description:
        'Consider how much they would need in order to be financially stable.',
    },
    groupId: 'dependentsInfo',
    retrieveAnswerObject: (partnerSupport: number) => ({
      partnerSupport,
    }),
    retrieveNextPageId: (_, answers) =>
      getNextPageForSupport(answers?.dependents || [], ['SPOUSE']),
  },
  childrenSupport: {
    required: (answers) =>
      !!answers.dependents &&
      answers.dependents.includes('CHILDREN') &&
      !answers.childrenSupport,
    type: 'CURRENCY',
    props: {
      question: 'How much would you want to support your children with?',
      description:
        'We recommend a starting point of €10,000 per year per child until the end of their studies.',
    },
    groupId: 'dependentsInfo',
    retrieveAnswerObject: (childrenSupport: number) => ({
      childrenSupport,
    }),
    retrieveNextPageId: (_, answers) =>
      getNextPageForSupport(answers?.dependents || [], ['SPOUSE', 'CHILDREN']),
  },
  parentsSupport: {
    required: (answers) =>
      !!answers.dependents &&
      answers.dependents.includes('PARENTS') &&
      !answers.parentsSupport,
    type: 'CURRENCY',
    props: {
      question: 'How much would you want to support your parents with?',
      description:
        'Consider how much they would need in order to be financially stable.',
    },
    groupId: 'dependentsInfo',
    retrieveAnswerObject: (parentsSupport: number) => ({
      parentsSupport,
    }),
    retrieveNextPageId: (_, answers) =>
      getNextPageForSupport(answers?.dependents || [], [
        'SPOUSE',
        'CHILDREN',
        'PARENTS',
      ]),
  },
  otherSupport: {
    required: (answers) =>
      !!answers.dependents &&
      answers.dependents.includes('OTHER') &&
      !answers.otherSupport,
    type: 'CURRENCY',
    props: {
      question:
        'How much would you want to support your other dependents with?',
      description:
        'This can be your siblings, grandparents, or friends. Consider how much they would need in order to be financially stable.',
    },
    groupId: 'dependentsInfo',
    retrieveAnswerObject: (otherSupport: number) => ({
      otherSupport,
    }),
    retrieveNextPageId: () => 'debt',
  },
  debt: {
    required: (answers) => !answers.debt,
    type: 'CURRENCY',
    props: {
      question: 'Do you have any debt?',
      description: 'If you have a mortgage, include the remaining amount.',
      placeholder: '0',
      hideLabel: true,
      label: 'Do you have savings?',
      validate: () => true, // Accept empty values
    },
    retrieveAnswerObject: (debt: number) => ({ debt }),
    retrieveNextPageId: () => 'savings',
    groupId: 'financialHistory',
  },
  savings: {
    required: (answers) => !answers.savings,
    type: 'CURRENCY',
    props: {
      question: 'Do you have savings?',
      description:
        'Include savings in your account, ETFs and stocks, but exclude any property you live in.',
      placeholder: '0',
      hideLabel: true,
      label: 'Do you have savings?',
      validate: () => true, // Accept empty values
    },
    retrieveAnswerObject: (savings: number) => ({ savings }),
    groupId: 'financialHistory',
    retrieveNextPageId: () => submitQuestionId,
  },
  ...createProcessingQuestion('life'),
};

const accountInfo = getAccountInfo<LifeQuestionnaire>('dateOfBirth');

const lifeQuestionnaireWithAccount: LifeQuestionnaire = {
  ...accountInfo,
  ...lifeQuestionnaire,
};

export default lifeQuestionnaireWithAccount;
