import { Spinner } from '@popsure/dirty-swan';

import styles from './style.module.scss';

const PhotoLoader = () => (
  <div className={`d-flex jc-between ${styles.container}`}>
    <div className={styles.placeholder} />
    <p className="p-p ml16">Syncing photo…</p>
    <div className={styles.spinner}>
      <Spinner size="m" />
    </div>
  </div>
);

export default PhotoLoader;
