import { CheckoutInfo } from './paymentScreen.models';

export type PaymentScreenAction = {
  type: 'PAYMENT_SCREEN_MERGE_CHECKOUT';
  checkoutInfo: CheckoutInfo;
};

export const mergeCheckoutInfo = (
  checkoutInfo: CheckoutInfo
): PaymentScreenAction => ({
  type: 'PAYMENT_SCREEN_MERGE_CHECKOUT',
  checkoutInfo,
});
