import RadioForm from 'components/questionnaireForms/RadioForm';
import {
  ViewProps,
  withContainer,
} from 'features/liability/containers/withContainer';
import {
  getPastClaimsMapping,
  getQuestionsMapping,
  PastClaims,
  QuestionId,
} from 'models/liability';
import { useSafeTranslation } from 'shared/i18n';

const questionId: QuestionId = 'numberOfPreviousClaims';

const LiabilityPastClaims = ({
  initialValue,
  onSubmitValue,
}: ViewProps<typeof questionId>) => {
  const { t } = useSafeTranslation();
  return (
    <RadioForm<PastClaims, ReturnType<typeof getPastClaimsMapping>>
      value={initialValue}
      onSubmitValue={(value: PastClaims) => {
        onSubmitValue({ questionId, value });
      }}
      question={getQuestionsMapping(t)[questionId]}
      mapValue={getPastClaimsMapping(t)}
      featureName="Liability"
    />
  );
};

export default withContainer(LiabilityPastClaims, questionId);
