import { TFunction } from '@getpopsure/i18n-react';
import {
  ArthritisIcon,
  CalendarIcon,
  ClockTimeIcon,
  DentalToothTeethIcon,
  DoctorStethoscopeIcon,
  GlobeNetworkEarthIcon,
  HeartIcon,
  HospitalBuildingIcon,
  MedicationPillsIcon,
  PawPetIcon,
  SearchIcon,
  VideoDoctorIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const petHealth = (t: TFunction): AdditionalInfoType => ({
  description: t(
    'policyModal.petHealth.description',
    'Coverage for veterinarians and pet clinics worldwide. No more worrying about expensive bills; the insurance has your back when your pet needs you most.'
  ),
  accordions: [
    {
      title: t('policyModal.petHealth.title1', "What's covered?"),
      items: [
        {
          id: 'covered-1',
          icon: <DoctorStethoscopeIcon size={24} />,
          question: t('policyModal.petHealth.covered1.question', 'Surgeries'),
          answer: t(
            'policyModal.petHealth.covered1.answer',
            'Necessary operations and related expenses such as x-rays and anesthesia.'
          ),
        },
        {
          id: 'covered-2',
          icon: <HeartIcon size={24} />,
          question: t(
            'policyModal.petHealth.covered2.question',
            'Post-surgery recovery'
          ),
          answer: t(
            'policyModal.petHealth.covered2.answer',
            'If your pet needs to heal at the clinic, they’ll be covered up to 30 days following the operation.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.petHealth.title2', 'Additional coverage'),
      items: [
        {
          id: 'additional-1',
          icon: <HospitalBuildingIcon size={24} />,
          question: t(
            'policyModal.petHealth.additional1.question',
            'Veterinary treatments'
          ),
          answer: t(
            'policyModal.petHealth.additional1.answer',
            'Up to €800 per year for non-emergency treatments, vet visits, diagnostics, and physiotherapy.'
          ),
        },
        {
          id: 'additional-2',
          icon: <MedicationPillsIcon size={24} />,
          question: t(
            'policyModal.petHealth.additional2.question',
            'Medication'
          ),
          answer: t(
            'policyModal.petHealth.additional2.answer',
            'Medications prescribed by your vet are covered up to €800 per year (counts toward the vet treatment limit).'
          ),
        },
        {
          id: 'additional-3',
          icon: <DoctorStethoscopeIcon size={24} />,
          question: t(
            'policyModal.petHealth.additional3.question',
            'Preventive treatments'
          ),
          answer: t(
            'policyModal.petHealth.additional3.answer',
            'Up to €70 per year for professional dental cleanings, vaccinations, flea and tick repellents, and deworming.'
          ),
        },
        {
          id: 'additional-4',
          icon: <DentalToothTeethIcon size={24} />,
          question: t(
            'policyModal.petHealth.additional4.question',
            'Dental procedures'
          ),
          answer: t(
            'policyModal.petHealth.additional4.answer',
            'Up to €150 per year for dental procedures such as fillings, tooth replacement, and extractions. This counts toward the general vet treatments limit.'
          ),
        },
        {
          id: 'additional-5',
          icon: <VideoDoctorIcon size={24} />,
          question: t(
            'policyModal.petHealth.additional5.question',
            'Video vet'
          ),
          answer: t(
            'policyModal.petHealth.additional5.answer',
            'Have a video consultation for your sick pet quickly from the comfort of home, and without the stress of a trip to the vet.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.petHealth.title3', "What's not covered"),
      items: [
        {
          id: 'not-covered-1',
          icon: <PawPetIcon size={24} />,
          question: t(
            'policyModal.petHealth.notCovered1.question',
            'Pets younger than 8 weeks or older than 8 years.'
          ),
          answer: t(
            'policyModal.petHealth.notCovered1.answer',
            'Your pet must be at least 8 weeks old and no older than 9 years on the start date of the pet health insurance.'
          ),
        },
        {
          id: 'not-covered-2',
          icon: <ArthritisIcon size={24} />,
          question: t(
            'policyModal.petHealth.notCovered2.question',
            'Pre-existing conditions'
          ),
          answer: t(
            'policyModal.petHealth.notCovered2.answer',
            '-Treatment for illnesses, accidents, congenital, genetic or acquired abnormalities of which you were aware prior to the start of the policy.'
          ),
        },
        {
          id: 'not-covered-3',
          icon: <SearchIcon size={24} />,
          question: t(
            'policyModal.petHealth.notCovered3.question',
            'Breed standardization procedures'
          ),
          answer: t(
            'policyModal.petHealth.notCovered3.answer',
            'Treatments and operations that serve to establish the respective breed standard that are not deemed medically necessary.'
          ),
        },
        {
          id: 'not-covered-4',
          icon: <MedicationPillsIcon size={24} />,
          question: t(
            'policyModal.petHealth.notCovered4.question',
            'Brachycephalic syndrome treatment'
          ),
          answer: t(
            'policyModal.petHealth.notCovered4.answer',
            'Treatments and operations required due to brachycephalic syndrome, such as the operation on a soft palate that is too long.'
          ),
        },
      ],
    },
  ],
  details: [
    {
      icon: CalendarIcon,
      title: t(
        'policyModal.petHealth.details.title1',
        '1 year contract, then monthly cancellation'
      ),
      description: t(
        'policyModal.petHealth.details.description1',
        'After the first year, you can cancel monthly. If you leave Germany, you can always cancel the insurance immediately.'
      ),
    },
    {
      icon: ClockTimeIcon,
      title: t('policyModal.petHealth.details.title2', '30-day waiting period'),
      description: t(
        'policyModal.petHealth.details.description2',
        'You’ll need to wait before you can begin using the policy. Accidents are covered right away.'
      ),
    },
    {
      icon: HospitalBuildingIcon,
      title: t(
        'policyModal.petHealth.details.title3',
        'Choice of any vet or clinic'
      ),
      description: t(
        'policyModal.petHealth.details.description3',
        "You're free to choose any licensed vet or clinic, without any restrictions"
      ),
    },
    {
      icon: GlobeNetworkEarthIcon,
      title: t('policyModal.petHealth.details.title4', 'Worldwide coverage'),
      description: t(
        'policyModal.petHealth.details.description4',
        'Coverage for uninterrupted trips up to 12 months when you travel with your pet'
      ),
    },
  ],
  buttonText: t('policyModal.petHealth.actionButton', 'Continue to quote'),
});
