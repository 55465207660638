import { BottomOrRegularModal } from '@popsure/dirty-swan';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import styles from './InfoBox.module.scss';

export const InfoBox = () => {
  const { t } = useSafeTranslation();

  const [showInfoModal, setShowInfoModal] = useState(false);
  return (
    <>
      <BottomOrRegularModal
        isOpen={showInfoModal}
        title={t(
          'page.household.quote.calculator.infobox.modal.title',
          'Why you might be underinsured'
        )}
        onClose={() => setShowInfoModal(false)}
      >
        <div className="wmx6 mt16 pb24 pl24 pr24">
          <p className="p-p">
            {t(
              'page.household.quote.calculator.infobox.modal.description',
              'Because of the different coverage options that are offered, including the percentages for each insured event, you might need additional coverage. Most insurances recommend €650 per square meter to be safe.'
            )}
          </p>
        </div>
      </BottomOrRegularModal>
      <div className={`bg-white br8 mt16 ${styles.container}`}>
        <p className="p-p--small tc-grey-600">
          {t(
            'page.household.quote.calculator.infobox.youmightbeunderinsured',
            'You might be underinsured when choosing this sum.'
          )}{' '}
          <button
            className={`p-a fw-bold ${styles.linkButton}`}
            onClick={() => setShowInfoModal(true)}
            type="button"
          >
            {t(
              'page.household.quote.calculator.infobox.learnmorewhy',
              'Learn more'
            )}
          </button>
        </p>
      </div>
    </>
  );
};
