import { DateSelector, Input } from '@popsure/dirty-swan';
import type { DateSelectorProps } from '@popsure/dirty-swan/dist/cjs/lib/components/dateSelector';
import dayjs from 'dayjs';
import { useState } from 'react';

export type DateInputProps = {
  onValidate(valid: boolean): void;
  name: string;
} & Omit<DateSelectorProps, 'onChange'>;

export const DateInput = (props: DateInputProps) => {
  const { value, onValidate, name } = props;
  const [date, setDate] = useState<string>(value || '');

  return (
    <>
      <Input type="hidden" name={name} value={date} />
      <DateSelector
        {...props}
        value={date}
        onChange={(newDate) => {
          setDate(newDate);
          onValidate(dayjs(newDate).isValid());
        }}
      />
    </>
  );
};
