import { XCircleIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { useSafeTranslation } from 'shared/i18n';

export const PublicHealthCancelApplicationButton = (
  props: PolicyDetailsButtonProps
) => {
  const { onOpenModal } = props;
  const { t } = useSafeTranslation();
  const handleCancelApplication = () =>
    onOpenModal('PUBLIC_HEALTH_CANCEL_APPLICATION');

  return (
    <ButtonCell
      key="CANCEL_APPLICATION"
      title={t(
        'myPolicies.policyDetails.cancelApplicationButton.caption',
        'Cancel application'
      )}
      icon={
        <XCircleIcon color="primary-500" noMargin size={24} className="ml16" />
      }
      onClick={handleCancelApplication}
    />
  );
};
