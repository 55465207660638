import { Region } from '@getpopsure/public-models';
import Session from '@getpopsure/session';
import classNames from 'classnames';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import {
  PolicyListCard,
  RecommendationCard,
} from 'features/policiesDashboard/components';
import { usePolicyModal } from 'features/policiesDashboard/hooks/usePolicyModal';
import { InsuranceTypes } from 'models/insurances/types';
import { useEffect } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import styles from './PoliciesList.module.scss';

export interface ProductGroup {
  key: 'health' | 'basics' | 'life';
  title: string;
  icon: string;
  policies: InsuranceTypes[];
}

interface PoliciesListViewProps {
  productsList: ProductGroup[];
  region?: Region;
}

export const PoliciesListView = ({
  productsList,
  region = 'de',
}: PoliciesListViewProps) => {
  const { t } = useSafeTranslation();
  const { openModal, PolicyModal } = usePolicyModal();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classNames('bg-white', styles.wrapper)}>
      <div className="wmx12 mx-auto pb40">
        <div
          className={classNames('p-body', {
            pt24: !Session.isAuthenticated,
          })}
        >
          <NavButton
            title={
              Session.isAuthenticated
                ? t('policies.list.backButtonPolicies', 'Policies')
                : t('policies.list.backButton', 'Back')
            }
            path={
              Session.isAuthenticated
                ? routes.me.policies.path
                : routes.base.path
            }
          />

          <h1 className="p-h1 mb8">
            {t('policies.list.title', 'Our policies')}
          </h1>

          {productsList.map(({ key, title, icon, policies }) => (
            <div key={key}>
              <h3 className="p-h4 d-flex ai-center tc-grey-600 mb16 pt40">
                <img className="mr8 pr4" src={icon} alt={title} />
                {title}
              </h3>

              <div className={classNames('d-grid gap24', styles.cardGrid)}>
                {policies.map((type) => (
                  <PolicyListCard
                    key={type}
                    type={type}
                    region={region}
                    onClick={() => openModal(type, region)}
                  />
                ))}
              </div>
            </div>
          ))}

          {region === 'de' && (
            <div className="mt56">
              <RecommendationCard />
            </div>
          )}
        </div>
      </div>
      <PolicyModal />
    </div>
  );
};
