import { TFunction } from '@getpopsure/i18n-react';
import {
  ArthritisIcon,
  CalendarIcon,
  ClockTimeIcon,
  DentalToothTeethIcon,
  DoctorStethoscopeIcon,
  GlobeNetworkEarthIcon,
  MedicationPillsIcon,
  MentalHealthIcon,
  PlaneIcon,
  SyringeVaccinationIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const incomingEu = (t: TFunction): AdditionalInfoType => ({
  description: t(
    'policyModal.incomingEu.description',
    'Expat health insurance (sometimes referred to as “incoming insurance”) is a great solution for your first five years in Europe.'
  ),
  accordions: [
    {
      title: t('policyModal.incomingEu.title1', "What's covered"),
      items: [
        {
          id: 'covered-1',
          icon: <ArthritisIcon size={24} />,
          question: t(
            'policyModal.incomingEu.covered1.question',
            'Sudden illness and hospitalization'
          ),
          answer: t(
            'policyModal.incomingEu.covered1.answer',
            'Coverage for sudden non pre-existing illness that requires doctor’s visits, treatment or hospitalization.'
          ),
        },
        {
          id: 'covered-2',
          icon: <MedicationPillsIcon size={24} />,
          question: t(
            'policyModal.incomingEu.covered2.question',
            'Prescription drugs'
          ),
          answer: t(
            'policyModal.incomingEu.covered2.answer',
            'Covered if related to an emergency or a condition that is diagnosed after the policy is purchased.'
          ),
        },
        {
          id: 'covered-3',
          icon: <PlaneIcon size={24} />,
          question: t(
            'policyModal.incomingEu.covered3.question',
            'Repatriation'
          ),
          answer: t(
            'policyModal.incomingEu.covered3.answer',
            'In severe cases, you may need to return to your home country for treatment. Our expat policy will cover the travel costs.'
          ),
        },
        {
          id: 'covered-4',
          icon: <DentalToothTeethIcon size={24} />,
          question: t(
            'policyModal.incomingEu.covered4.question',
            'Dental pain relief'
          ),
          answer: t(
            'policyModal.incomingEu.covered4.answer',
            'Pain relief treatments are covered up to €1,000 per year, including simple fillings if prescribed by a dentist.'
          ),
        },
        {
          id: 'covered-5',
          icon: <GlobeNetworkEarthIcon size={24} />,
          question: t(
            'policyModal.incomingEu.covered5.question',
            'Coverage across the EU & Schengen area'
          ),
          answer: t(
            'policyModal.incomingEu.covered5.answer',
            'Additional coverage in your home country for 6 weeks per year after 1 year on the policy.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.incomingEu.title2', "What's not covered"),
      items: [
        {
          id: 'not-1',
          icon: <ArthritisIcon size={24} />,
          question: t(
            'policyModal.incomingEu.not1.question',
            'Pre-existing conditions'
          ),
          answer: t(
            'policyModal.incomingEu.not1.answer',
            'Treatments related to pre-existing conditions are not covered.'
          ),
        },
        {
          id: 'not-2',
          icon: <DoctorStethoscopeIcon size={24} />,
          question: t(
            'policyModal.incomingEu.not2.question',
            'Routine check-ups'
          ),
          answer: t(
            'policyModal.incomingEu.not2.answer',
            'The policy does not cover check-ups and any new conditions discovered during check-ups.'
          ),
        },
        {
          id: 'not-3',
          icon: <SyringeVaccinationIcon size={24} />,
          question: t(
            'policyModal.incomingEu.not3.question',
            'Regular vaccinations'
          ),
          answer: t(
            'policyModal.incomingEu.not3.answer',
            'Travel vaccinations are only covered on the premium plan.'
          ),
        },
        {
          id: 'not-4',
          icon: <MentalHealthIcon size={24} />,
          question: t(
            'policyModal.incomingEu.not4.question',
            'Mental health therapy'
          ),
          answer: t(
            'policyModal.incomingEu.not4.answer',
            'If you need to get psychotherapy appointments or treatments, those cannot be covered by the policy.'
          ),
        },
      ],
    },
  ],
  details: [
    {
      icon: CalendarIcon,
      title: t(
        'policyModal.incomingEu.details.title1',
        'Fulfills the visa requirements'
      ),
      description: t(
        'policyModal.incomingEu.details.description1',
        'Valid for the first visa or residence permit. Some visa exclusions apply'
      ),
    },
    {
      icon: ClockTimeIcon,
      title: t('policyModal.incomingEu.details.title2', 'Monthly contract'),
      description: t(
        'policyModal.incomingEu.details.description2',
        'Simply cancel before the renewal date'
      ),
    },
  ],
  buttonText: t('policyModal.incomingEu.buttonText', 'Continue to quote'),
});
