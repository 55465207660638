import { CheckboxWithDescription } from 'components/questionnaireForms/CheckboxForm/CheckboxForm';
import InputForm from 'components/questionnaireForms/InputForm';
import {
  bike,
  bunny,
  car,
  cat,
  dog,
  none,
} from 'features/recommendationTool/assets/icons';
import type {
  PetTypes,
  QuestionnaireAnswers,
  QuickRecommendationQuestionnaire,
  TransportationTypes,
} from 'features/recommendationTool/models/quickRecommendation';
import {
  DependentsTypes,
  MainOccupation,
  OtherOccupation,
} from 'features/recommendationTool/models/shared';
import { getAccountInfo } from 'features/recommendationTool/questionnaires/account';

import { createProcessingQuestion, submitQuestionId } from '../processing';
import {
  dependentsMapping,
  mainOcuppationMapping,
  otherOccupationMapping,
  renderIcon,
} from '../shared';

const incomeThresholdMapping: Record<number, string> = {
  6456: '€6,456 or less',
  30000: '€6,457 to €30,000',
  45000: '€30,001 to €45,000',
  60000: '€45,001 to €60,000',
  66599: '€60,001 to €69,299',
  69300: '€69,300 or more',
};

const petsMapping: Record<PetTypes, CheckboxWithDescription> = {
  DOG: {
    title: 'Dog',
    icon: () => renderIcon(dog),
  },
  CAT: {
    title: 'Cat',
    icon: () => renderIcon(cat),
  },
  OTHER: {
    title: 'Other',
    icon: () => renderIcon(bunny),
  },
  NONE: {
    title: 'None',
    icon: () => renderIcon(none),
  },
};

const ownsTransportationMapping: Record<
  TransportationTypes,
  CheckboxWithDescription
> = {
  CAR: {
    title: 'Car',
    icon: () => renderIcon(car),
  },
  BIKE: {
    title: 'Bike',
    icon: () => renderIcon(bike),
  },
  NONE: {
    title: 'None',
    icon: () => renderIcon(none),
  },
};

const quickRecommendationQuestionnaire: QuickRecommendationQuestionnaire = {
  quickFinancialResponsibilities: {
    required: (answers) => !answers.quickFinancialResponsibilities,
    type: 'CHECKBOX',
    props: {
      question: 'Do you have any financial responsibilities?',
      mapValue: dependentsMapping,
      optionClassName: 'w30',
      inlineLayout: true,
    },
    retrieveAnswerObject: (
      quickFinancialResponsibilities: DependentsTypes[]
    ) => ({
      quickFinancialResponsibilities,
    }),
    retrieveNextPageId: () => 'quickYearOfBirth',
    groupId: 'personalInfo',
  },
  quickYearOfBirth: {
    required: true,
    type: 'NUMBER',
    props: {
      question: 'What year were you born in?',
      placeholder: 'Year of birth',
      validate: (yearOfBirth = 0) => {
        const min = (new Date().getFullYear() - 100) as number;
        const max = new Date().getFullYear() as number;

        return yearOfBirth > min && yearOfBirth < max;
      },
    },
    groupId: 'personalInfo',
    retrieveAnswerObject: (quickYearOfBirth: number) => ({
      quickYearOfBirth,
    }),
    retrieveNextPageId: () => 'quickOccupation',
  },
  quickOccupation: {
    required: true,
    type: 'RADIO',
    props: {
      question: 'What’s your main occupation in Germany?',
      mapValue: mainOcuppationMapping,
      additionalInfo: {
        title: 'Working for an international company?',
        description:
          'If your company has an office in Germany or has a German tax accountant, you are considered to be employed in Germany.',
      },
    },
    retrieveAnswerObject: (quickOccupation: MainOccupation) => ({
      quickOccupation,
    }),
    retrieveNextPageId: (answer: MainOccupation) =>
      answer === 'OTHER' ? 'quickOtherOccupation' : 'quickYearlyIncome',
    groupId: 'personalInfo',
  },
  quickOtherOccupation: {
    required: false,
    type: 'RADIO',
    props: {
      question: 'What’s your main occupation in Germany?',
      mapValue: otherOccupationMapping,
    },
    retrieveAnswerObject: (quickOtherOccupation: OtherOccupation) => ({
      quickOtherOccupation,
    }),
    retrieveNextPageId: () => 'quickYearlyIncome',
    groupId: 'personalInfo',
  },
  quickYearlyIncome: {
    required: true,
    type: 'RADIO',
    props: {
      question: 'What’s your annual income before taxes?',
      description: 'This is your total compensation including any bonuses.',
      mapValue: incomeThresholdMapping,
    },
    transformValue: (quickYearlyIncome: number) => String(quickYearlyIncome),
    retrieveAnswerObject: (quickYearlyIncome: string) => ({
      quickYearlyIncome: Number(quickYearlyIncome),
    }),
    retrieveNextPageId: () => 'quickPostalCode',
    groupId: 'personalInfo',
  },
  quickPostalCode: {
    required: false,
    type: 'CUSTOM',
    component: (props) =>
      InputForm({
        ...props,
        value: typeof props.value === 'string' ? props.value : undefined,
        question: "What's your postal code?",
        placeholder: 'Postal code',
        type: 'text',
        skipDetails: {
          title: 'Skip',
          onClick: () => {
            props.onSubmitValue(undefined);
          },
          path: '',
        },
      }),
    retrieveAnswerObject: (
      quickPostalCode: QuestionnaireAnswers['quickPostalCode']
    ) => ({
      quickPostalCode,
    }),
    retrieveNextPageId: () => 'quickPets',
    groupId: 'personalInfo',
  },
  quickPets: {
    required: (answers) => !answers.quickPets,
    type: 'CHECKBOX',
    props: {
      question: 'Do you have pets?',
      mapValue: petsMapping,
      optionClassName: 'w30',
      inlineLayout: true,
    },
    retrieveAnswerObject: (quickPets: PetTypes[]) => ({
      quickPets,
    }),
    retrieveNextPageId: () => 'quickOwnsTransportation',
    groupId: 'personalInfo',
  },
  quickOwnsTransportation: {
    required: (answers) => !answers.quickOwnsTransportation,
    type: 'CHECKBOX',
    props: {
      question: 'Do you own a bike or car?',
      mapValue: ownsTransportationMapping,
      optionClassName: 'w30',
      inlineLayout: true,
    },
    retrieveAnswerObject: (quickOwnsTransportation: TransportationTypes[]) => ({
      quickOwnsTransportation,
    }),
    retrieveNextPageId: () => submitQuestionId,
    groupId: 'personalInfo',
  },
  ...createProcessingQuestion('quickRecommendation'),
};

const accountInfo = getAccountInfo<QuickRecommendationQuestionnaire>(
  'quickFinancialResponsibilities'
);

const quickRecommendationQuestionnaireWithAccount: QuickRecommendationQuestionnaire =
  {
    ...accountInfo,
    ...quickRecommendationQuestionnaire,
  };

export default quickRecommendationQuestionnaireWithAccount;
