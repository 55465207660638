import { insurance } from '@getpopsure/private-constants';
import { ClaimStatuses, StepStatuses } from 'models/claims';
import { InsuranceTypes } from 'models/insurances/types';

interface AdditionalInfo {
  processingTime: string;
  deductible: number;
}

export function additionalClaimInfoForInsuranceType(
  insuranceType: InsuranceTypes
): AdditionalInfo | undefined {
  if (insuranceType === 'INCOMING') {
    return {
      deductible: insurance.expathHealth.deductible,
      processingTime: '3-6 weeks',
    };
  }

  return undefined;
}

export function getClaimStepsForInsuranceType({
  insuranceType,
  currentStatus,
}: {
  insuranceType: InsuranceTypes;
  currentStatus: ClaimStatuses;
}): {
  status: ClaimStatuses;
  stepStatus: StepStatuses;
}[] {
  switch (insuranceType) {
    case 'PRIVATE_HEALTH':
      return [{ status: 'SUBMITTED', stepStatus: 'completed' }];
    case 'INCOMING': {
      const statuses: ClaimStatuses[] =
        currentStatus === 'PROCESSED'
          ? ['SUBMITTED', 'PROCESSING', 'PROCESSED']
          : ['SUBMITTED', 'PROCESSING', 'PAID_OUT'];
      const currentStepIndex = statuses.indexOf(currentStatus);

      return statuses.map((status, i) => {
        const stepStatus = (() => {
          if (i < currentStepIndex + 1) {
            return status === 'PROCESSED' ? 'error' : 'completed';
          }
          return 'uncompleted';
        })();

        return {
          status,
          stepStatus,
        };
      });
    }
    default:
      return [];
  }
}
