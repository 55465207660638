import {
  blog,
  calendly,
  insurance,
  website,
} from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { bannerColorMapping } from 'models/insurances/types/mapping';
import { getSrcSet } from 'shared/util/getSrcSet';

import * as img from '../../../models/insurances/assets/img';
import { CardText, CategoryLookup, Section } from '../models/lookups';
import { typeformLinkWithVertical } from './shared';

export const healthCategory: CategoryLookup = {
  title: 'Health insurance',
  shortTitle: 'Health',
  excerpt: 'Medical coverage for treatments and medications',
  description: `Health insurance is mandatory in Germany, and for many newcomers, it's hard to know what is available and what plan fits their lifestyle. There are three main types of health insurance: public, private, and expat. The government regulates each one, so you'll have your basic medical needs covered no matter which one you choose. You can read more about how each one of them works in our [Insurance Guide](${blog.healthInsuranceGuide}).`,
  icon: img.health,
  iconSrcSet: getSrcSet([img.health, img.health2x, img.health3x]),
  bannerColor: bannerColorMapping.PUBLIC_HEALTH,
  enabled: true,
};

export const ehicHealthSection: Section = {
  title: 'EHIC card',
  description: `The European Health Insurance Card (EHIC) was created in 2006 and implemented by 30 countries. The EHIC provides temporary insurance coverage from the country the card is from. You’ll need to speak to your current health insurance provider to determine if and for how long you’d be covered in Germany and under which conditions.`,
  icon: img.ehic,
  icon2x: img.ehic2x,
  icon3x: img.ehic3x,
  status: {
    ELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: blog.ehicGuide,
      },
    },
    PARTIAL: { cta: { caption: '', href: '' } },
    MORE_INFO_REQUIRED: { cta: { caption: '', href: '' } },
    INELIGIBLE: { cta: { caption: '', href: '' } },
  },
};

export const expatHealthSection: Section = {
  title: 'Expat',
  description:
    'Expat health insurance, also known as incoming insurance, covers the basics until you can get on a private or public plan. You’re only eligible for expat insurance for five years after entering the country, so you’ll need to find a more permanent solution if you plan on renewing your visa. This insurance also won’t work if your visa’s validity will last longer than the plan. ',
  icon: img.expat,
  icon2x: img.expat2x,
  icon3x: img.expat3x,
  status: {
    ELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.expathHealth.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: calendly.expatHealthHelp,
      },
    },
    PARTIAL: {
      cta: {
        caption: 'Learn more',
        href: insurance.expathHealth.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('expat'),
      },
    },
    MORE_INFO_REQUIRED: {
      cta: {
        caption: 'Learn more',
        href: insurance.expathHealth.landingPage,
      },
    },
    INELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.expathHealth.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('expat'),
      },
    },
  },
};

export const publicHealthSection: Section = {
  title: 'Public',
  description:
    'Public health insurance is state insurance where members along with the government contribute to more than 100 individual not-for-profit public health funds. Each provider / public fund offers comprehensive coverage to all of their members. This includes an extensive range of preventative services, acute care, and full or partial reimbursement for medications.',
  icon: img.publicHealth,
  icon2x: img.publicHealth2x,
  icon3x: img.publicHealth3x,
  status: {
    ELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.publicHealth.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: calendly.customerSupport,
      },
    },
    PARTIAL: {
      cta: {
        caption: 'Learn more',
        href: insurance.publicHealth.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: calendly.customerSupport,
      },
    },
    MORE_INFO_REQUIRED: {
      cta: {
        caption: 'Learn more',
        href: insurance.publicHealth.landingPage,
      },
    },
    INELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.publicHealth.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: calendly.customerSupport,
      },
    },
  },
};

export const privateHealthSection: Section = {
  title: 'Private',
  description:
    'Private health insurance provides the best coverage in Germany. This includes coveted fertility treatments, a budget for chiropractors, vision insurance, private rooms when staying overnight at a hospital, and many other perks that public insurance cannot offer.',
  icon: img.privateHealth,
  icon2x: img.privateHealth2x,
  icon3x: img.privateHealth3x,
  status: {
    ELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.privateHealth.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: calendly.healthInsuranceAdvicePriority,
      },
    },
    PARTIAL: {
      cta: {
        caption: 'Learn more',
        href: insurance.privateHealth.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: calendly.healthInsuranceAdvice,
      },
    },
    MORE_INFO_REQUIRED: {
      cta: {
        caption: 'Learn more',
        href: insurance.privateHealth.landingPage,
      },
    },
    INELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.privateHealth.landingPage,
      },
    },
  },
};

export const healthCardText: CardText = {
  EHIC_HEALTH_KEEP_PRO: {
    title: 'You get to keep your health insurance',
    description:
      'It is simple and immediate 🎉 You can keep using your EHIC as long as you are not employed in Germany.',
  },
  EHIC_HEALTH_MIGHT_DIFFER_CON: {
    title: 'The coverage might differ with Germany',
    description:
      'Depending on your home country, the coverage might not be the same as in Germany.',
  },
  EXPAT_HEALTH_ARTIST_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'As an artist registered with the KSK, you need to be on public health insurance.',
  },
  EXPAT_HEALTH_CIVIL_SERVANT_INELIGIBLE: {
    title: 'You are not eligible',
    description: 'Civil servants cannot be insured under expat insurance.',
  },
  EXPAT_HEALTH_COULD_BECOME_INELIGIBLE_CON: {
    title: 'You could become ineligible for public health insurance',
    description:
      'If you plan on being self-employed, public health insurance can require you to have been publicly insured in the EU/EEA within the last 3 months to be eligible. Staying on expat insurance for longer could make you ineligible.',
  },
  EXPAT_HEALTH_COVERS_REPATRIATION_COST_PRO: {
    title: 'Covers repatriation costs',
    description:
      'If you get into a challenging health situation, the insurance covers the emergency transportation costs to your home country. This generally happens when you’d receive better medical coverage in your home country compared to the country you’ve had the medical emergency in.',
  },
  EXPAT_HEALTH_DEPENDENTS_NOT_FREE_CON: {
    title: 'Dependents coverage is not free',
    description:
      'Unlike public health insurance, dependents cannot be covered for free, but they can still get a discount on some of our plans.',
  },
  EXPAT_HEALTH_DE_CITIZEN_INELIGIBLE: {
    title: 'You are not eligible',
    description: 'Expat insurance is not available for German citizens.',
  },
  EXPAT_HEALTH_EASY_SIGN_UP_PRO: {
    title: 'Easy sign-up',
    description:
      "Registering for expat insurance is easy and doesn't require you to fill out any health questionnaires.",
  },
  EXPAT_HEALTH_EMPLOYED_IN_DE_HIGH_INCOME_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Because you are employed, you cannot take on expat insurance.',
  },
  EXPAT_HEALTH_IS_CURRENTLY_ON_PRIVATE_IN_DE_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Since you are currently insured with a private health insurance in Germany, your health provider will not allow you to switch to a less comprehensive insurance. You will be eligible for expat insurance if you are forced to give up your private insurance by relocating outside of Germany and then decide to come back afterwards.',
  },
  EXPAT_HEALTH_IS_CURRENTLY_ON_PUBLIC_IN_DE_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Since you are currently insured with a public health insurance in Germany, your health provider will not allow you to switch to a less comprehensive insurance. You will be eligible for expat insurance if you are forced to give up your private insurance by relocating outside of Germany and then decide to come back afterwards.',
  },
  EXPAT_HEALTH_IS_OVER_75_INELIGIBLE: {
    title: 'You are not eligible',
    description: 'We do not offer expat insurance for those over 75 years old.',
  },
  EXPAT_HEALTH_IS_UNDER_18_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      "Because you are younger than 18, you can only be covered as a dependent on someone else's expat insurance policy.",
  },
  EXPAT_HEALTH_LIMITED_COVERAGE_CON: {
    title: 'Limited coverage',
    description:
      "Some things aren't covered like dental procedures (other than anaesthetics, cleanings and simple fillings), appointments at the gynaecologist, cancer treatments, and mental health therapy.",
  },
  EXPAT_HEALTH_MORE_THAN_5_YEARS_IN_DE_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      "Expat insurance is only available for the first 5 years of one's stay in Germany. You can become eligible again if you relocate to another country and come back to Germany.",
  },
  EXPAT_HEALTH_ONLY_VALID_FOR_FIRST_VISA_CON: {
    title: 'Only valid for your first visa and first 5 years in Germany',
    description:
      'Expat insurance is not valid for visa renewals or for periods longer than 5 years in Germany.',
  },
  EXPAT_HEALTH_PREEXISTING_CONDITIONS_NOT_COVERED_CON: {
    title: 'Does not cover pre-existing conditions',
    description:
      'Unlike public and private health insurance, any health conditions that you already had before taking on the expat insurance will not be covered.',
  },
  EXPAT_HEALTH_PREVENT_YOU_FROM_SWITCHING_CON: {
    title: 'Your health might prevent you from switching to private',
    description:
      "If you develop a health condition that isn't covered by private health insurance, it could be much harder and more expensive to switch. That's why we recommend taking on public or private insurance as early as possible.",
  },
  EXPAT_HEALTH_SHORT_CANCELLATION_PRO: {
    title: 'Short cancellation period',
    description:
      "Expat insurance is a month to month policy, meaning you can cancel with one month's notice, or immediately if you are switching to public or private health insurance.",
  },
  EXPAT_HEALTH_STUDENT_RECOGNIZED_UNI_OVER_30_PARTIAL: {
    title: 'Check with your university',
    description:
      'You are eligible for expat insurance given that you are a student over 30 years old, but we recommend checking with your university to make sure that expat (incoming) insurance is sufficient.',
  },
  /**
   * @deprecated since version 0.3.3 of recommendation tool
   */
  EXPAT_HEALTH_STUDENT_RECOGNIZED_UNI_UNDER_30_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Because you are studying at a recognized German university, you need be on public or private health insurance.',
  },
  EXPAT_HEALTH_STUDENT_RECOGNIZED_UNI_UNDER_30_PARTIAL: {
    title: 'We recommend public insurance instead',
    description:
      "Because you're studying at a recognized German university, we strongly recommend getting public health insurance, which offers comprehensive coverage. If you decide to go with Expat health insurance, you'll need an exemption letter from a public health provider, and it will be harder to get public health again unless you become employed or change your university or study program to an unrelated area.",
  },
  EXPAT_HEALTH_UNEMPLOYMENT_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Because you are receiving unemployment benefits under ALG1, you cannot be on expat insurance.',
  },
  EXPAT_HEALTH_WAITING_PERIODS_CON: {
    title: 'Some waiting periods apply',
    description:
      'There is a waiting period of 31 days starting from the day you sign up, during which you will not be covered. This waiting period does not apply in situations where you are in critical condition and need medical intervention immediately. The waiting period is waived if you had a comparable insurance up to the start date of this insurance.',
  },
  PRIVATE_HEALTH_ARTIST_LESS_THAN_30K_TOTAL_INCOME_PARTIAL: {
    title: 'You are likely not eligible',
    description: `Because you are an artist, you need to make more than €30k of total income per year to be eligible for private insurance with Feather. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany.`,
  },
  PRIVATE_HEALTH_ARTIST_LESS_THAN_45K_TOTAL_INCOME_PARTIAL: {
    title: 'You are likely not eligible',
    description: `Because you are an artist over 50 years old, you need to make more than €45k of total income per year to be eligible for private insurance with Feather. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany.`,
  },
  PRIVATE_HEALTH_CIVIL_SERVANT_PARTIAL: {
    title: 'Some providers offer it',
    description: `We currently don't offer private health insurance for civil servants. [Contact us](${website.support}) if you'd like to know more about what options are available to you.`,
  },
  PRIVATE_HEALTH_DENTAL_PRO: {
    title: 'Great dental coverage',
    description:
      'Unlike public health insurance, private insurance usually comes with comprehensive dental coverage, and you will likely not need to purchase an additional policy.',
  },
  PRIVATE_HEALTH_DEPENDENTS_COVERAGE_NOT_FREE_CON: {
    title: 'Dependents coverage is not free',
    description:
      'Unlike public health insurance, dependents cannot be covered for free.',
  },
  PRIVATE_HEALTH_DEPENDS_ON_HEALTH_CON: {
    title: 'Your eligibility depends on your health',
    description:
      'Unlike public health insurance, your general health will be assessed at sign-up and can affect both your eligibility and your price.',
  },
  PRIVATE_HEALTH_EMPLOYED_IN_DE_CURRENTLY_ON_PRIVATE_IN_DE_OVER_55_PARTIAL: {
    title: 'You may have to switch to public',
    description:
      "Since you're over 55 years old, you can only stay on private if you did not have public insurance at any point in the last 5 years (in Germany or within the EU/EEA).",
  },
  PRIVATE_HEALTH_EMPLOYED_IN_DE_CURRENTLY_ON_PRIVATE_IN_DE_UNDER_55_PARTIAL: {
    title: 'You may have to switch to public',
    description: `If you had private insurance but are now earning less than ${englishFormattedEuroCurrency(
      insurance.privateHealth.employeeThreshold,
      true
    )} per year, you need to switch to public insurance. If the salary threshold went up above your salary, you have the choice of going on public or staying on private.`,
  },
  PRIVATE_HEALTH_EMPLOYED_IN_DE_CURRENTLY_ON_PRIVATE_IN_EU_OVER_55_PARTIAL: {
    title: 'You might be eligible',
    description:
      'Because you are over 55 years old, you would only be eligible if you did not have public insurance at any point in the last 5 years (in Germany or within the EU/EEA).',
  },
  PRIVATE_HEALTH_EMPLOYED_IN_DE_CURRENTLY_ON_PRIVATE_IN_EU_UNDER_55_INELIGIBLE:
    {
      title: 'You are not eligible',
      description: `Because your employment income is less than ${englishFormattedEuroCurrency(
        insurance.privateHealth.employeeThreshold,
        true
      )}, you are not eligible for private insurance.`,
    },
  PRIVATE_HEALTH_EMPLOYED_IN_DE_INELIGIBLE: {
    title: 'You are not eligible',
    description: `Because your employment income is less than ${englishFormattedEuroCurrency(
      insurance.privateHealth.employeeThreshold,
      true
    )}, you are not eligible for private insurance.`,
  },
  PRIVATE_HEALTH_EMPLOYED_IN_DE_LAST_INSURANCE_IN_DE_WAS_PRIVATE_UNDER_55_INELIGIBLE:
    {
      title: 'You are not eligible',
      description: `Because your employment income is less than ${englishFormattedEuroCurrency(
        insurance.privateHealth.employeeThreshold,
        true
      )}, you are not eligible for private insurance.`,
    },
  PRIVATE_HEALTH_EMPLOYED_IN_DE_LAST_ON_PRIVATE_IN_EU_OVER_55_PARTIAL: {
    title: 'You may be eligible',
    description:
      'Because you are over 55 years old, you would only be eligible if you did not have public insurance at any point in the last 5 years (in Germany or within the EU/EEA).',
  },
  PRIVATE_HEALTH_EMPLOYED_IN_DE_LAST_ON_PRIVATE_IN_EU_UNDER_55_INELIGIBLE: {
    title: 'You are not eligible',
    description: `Because your employment income is less than ${englishFormattedEuroCurrency(
      insurance.privateHealth.employeeThreshold,
      true
    )}, you are not eligible for private insurance.`,
  },
  PRIVATE_HEALTH_EMPLOYED_IN_DE_MINI_JOB_OVER_50_PARTIAL: {
    title: 'You are likely not eligible',
    description: `Because you are over 50 years old, you need to make more than €45k of total income per year to be eligible for private insurance with Feather. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany. If you have a spouse that is eligible for private insurance, you can be insured under the same insurance provider.`,
  },
  PRIVATE_HEALTH_EMPLOYED_IN_DE_MINI_JOB_UNDER_50_PARTIAL: {
    title: 'You are likely not eligible',
    description: `You need to earn more than €30k of total income per year to be eligible for private insurance with Feather. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany. If you have a spouse that is eligible for private insurance, you can be insured under the same insurance provider.`,
  },
  PRIVATE_HEALTH_EMPLOYED_IN_DE_WITH_EMPLOYMENT_INCOME_HIGHER_THAN_UPPER_THRESHOLD_ELIGIBLE:
    {
      title: 'Eligible',
      description:
        'Internal note: If they got a raise they can apply for private, but have to wait until the 1st of next year to become eligible',
    },
  PRIVATE_HEALTH_EMPLOYED_OUTSIDE_DE_LESS_THAN_30K_TOTAL_INCOME_PARTIAL: {
    title: 'You are likely not eligible',
    description: `Because you are employed outside of Germany, you need to make more than €30k of total income per year to be eligible for private insurance with Feather. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany.`,
  },
  PRIVATE_HEALTH_EMPLOYED_OUTSIDE_DE_LESS_THAN_45K_TOTAL_INCOME_PARTIAL: {
    title: 'You are likely not eligible',
    description: `Because you are employed outside of Germany and over 50 years old, you need to make more than €45k of total income per year to be eligible for private insurance with Feather. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany.`,
  },
  PRIVATE_HEALTH_HARD_TO_SWITCH_CON: {
    title: "It's difficult to switch to public insurance afterward",
    description:
      'You will only be able to switch to public health insurance if your occupation or income change and you become ineligible for private.',
  },
  PRIVATE_HEALTH_LANGUAGE_STUDENT_LESS_THAN_30K_TOTAL_INCOME_PARTIAL: {
    title: 'You are likely not eligible',
    description: `Because you are a language student, you need to make more than €30k of total income per year to be eligible for private insurance with Feather. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany.`,
  },
  PRIVATE_HEALTH_LANGUAGE_STUDENT_LESS_THAN_45K_TOTAL_INCOME_PARTIAL: {
    title: 'You are likely not eligible',
    description: `Because you are a language student over 50 years old, you need to make more than €45k of total income per year to be eligible for private insurance with Feather. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany.`,
  },
  PRIVATE_HEALTH_NOT_ENOUGH_TIME_TO_ACCUMULATE_CON: {
    title: 'You don’t have much time to accumulate old age savings',
    description:
      "If you plan on staying in Germany for more than 5 years, a portion of your insurance payments will include old age savings. Given your age, you don't have much time to accumulate those savings, making your monthly insurance cost higher.",
  },
  PRIVATE_HEALTH_PRICE_PRO: {
    title: 'The price is based on your age and health at sign-up',
    description:
      'The price of insurance is calculated based on your age and health when you sign up, but will not increase as you get older, if your health declines, or if your income changes. Similar to public health, the price will still increase slightly every year due to inflation and cost of medical services.',
  },
  PRIVATE_HEALTH_RECEIVE_CASHBACK_PRO: {
    title: "Receive cashback if you don't claim during the year",
    description:
      "In addition to the guaranteed cashback, a bonus is payed to those who don't make claims during in a calendar year.",
  },
  PRIVATE_HEALTH_RETIRED_PARTIAL: {
    title: 'You are likely not eligible',
    description: `You are only eligible for private insurance through Feather if your additional income or pension is above €60k per year. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany.`,
  },
  PRIVATE_HEALTH_SCOPE_OF_COVERAGE_STAYS_SAME_PRO: {
    title: 'Fixed scope of coverage over time',
    description:
      'While the price can slightly change over time based on factors like inflation, your coverage will remain the same.',
  },
  PRIVATE_HEALTH_SELF_EMPLOYED_LESS_THAN_30K_TOTAL_INCOME_PARTIAL: {
    title: 'You are likely not eligible',
    description: `Because you are self-employed, you need to make more than €30k of total income per year to be eligible for private insurance with Feather. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany.`,
  },
  PRIVATE_HEALTH_SELF_EMPLOYED_LESS_THAN_45K_TOTAL_INCOME_PARTIAL: {
    title: 'You are likely not eligible',
    description: `Because you are over 50 years old and self-employed, you need to make more than €45k of total income per year to be eligible for private insurance with Feather. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany.`,
  },
  PRIVATE_HEALTH_SHORT_WAITING_TIME_PRO: {
    title: 'Shorter waiting times on average',
    description:
      'Many experience shorter waiting times for appointments after switching from public health insurance to private health insurance, but it depends on the doctor’s office you’re going to.',
  },
  PRIVATE_HEALTH_STUDENT_NON_RECOGNIZED_UNI_LESS_THAN_30K_TOTAL_INCOME_PARTIAL:
    {
      title: 'You are likely not eligible',
      description: `Because you are studying at a university that isn't recognized, you need to make more than €30k of total income per year to be eligible for private insurance with Feather. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany.`,
    },
  PRIVATE_HEALTH_STUDENT_NON_RECOGNIZED_UNI_LESS_THAN_45K_TOTAL_INCOME_PARTIAL:
    {
      title: 'You are likely not eligible',
      description: `Because you are over 50 years old and are studying at a university that isn't recognized, you need to make more than €45k of total income per year to be eligible for private insurance with Feather. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany.`,
    },
  PRIVATE_HEALTH_STUDENT_RECOGNIZED_UNI_CURRENTLY_ON_PRIVATE_INELIGIBLE: {
    title: 'You are not eligible with us',
    description:
      "We currently don't offer private health insurance for students, but since you already have it, you might be eligible with other providers.",
  },
  PRIVATE_HEALTH_STUDENT_RECOGNIZED_UNI_CURRENTLY_ON_PUBLIC_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'As a student, you can only opt out of public health insurance during the first 3 months of your studies. If you did not opt out, you have to stay on private for the remainder of your studies.',
  },
  PRIVATE_HEALTH_STUDENT_RECOGNIZED_UNI_OVER_30_INELIGIBLE: {
    title: 'You are not eligible with us',
    description:
      "We currently don't offer private health insurance for students, but other insurance providers do. However, we recommend that you sign up for public health insurance since you would likely pay less and get the same benefits.",
  },
  PRIVATE_HEALTH_STUDENT_RECOGNIZED_UNI_INELIGIBLE: {
    title: 'You are not eligible with us',
    description:
      "We don't currently offer private insurance for students, but some other providers do. We recommend you sign up for public since you get the same benefits and pay less. Note that the choice between the public and private system can only be made within the first 3 months of your studies.",
  },
  PUBLIC_HEALTH_OVER_55_RETIRED_INELIGIBLE: {
    title: 'You are likely not eligible',
    description: `Because you are retired and did not recently have public insurance within the EU/EEA, you are likely not eligible. However, some exceptions may apply. [Feel free to contact us](${website.support}) if you need help understanding your eligibility.`,
  },
  PRIVATE_HEALTH_STUDENT_RECOGNIZED_UNI_UNDER_30_PREVIOUS_INSURANCE_INELIGIBLE:
    {
      title: 'You are not eligible',
      description:
        "As a student, you need to be on public health insurance if you haven't opted out of public in the first 3 months of your studies.",
    },
  PRIVATE_HEALTH_TRAVEL_PRO: {
    title: 'Great coverage for those who travel',
    description:
      'You are covered outside the EU for up to 8 weeks (or more depending on the plan). There is also a possibility of keeping your health insurance if you relocate outside of Germany, though the price might change.',
  },
  PRIVATE_HEALTH_UNDER_18_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'We only offer private insurance for those over 18 years old. If you have a parent or spouse who is eligible for private insurance, you can be insured under the same provider.',
  },
  PRIVATE_HEALTH_UNEMPLOYED_PARTIAL: {
    title: 'You are likely not eligible',
    description: `Because you are unemployed, you need to have more than €60k of income per year to be eligible for private insurance with Feather. You might be eligible with other providers or if you have a [strong financial history](${blog.schufaGuide}) in Germany.`,
  },
  PRIVATE_HEALTH_WAITING_PERIODS_APPLY_CON: {
    title: 'Some waiting periods may apply',
    description:
      'Some waiting periods may apply for dental and mental health coverage unless you already had comprehensive health coverage before taking on private insurance.',
  },
  PUBLIC_HEALTH_ADDITIONAL_DENTAL_COVERAGE_NEEDED: {
    title: 'Extra dental coverage is often needed',
    description: `Some necessary treatments are covered, but most people purchase an [add-on dental](${insurance.dental.landingPage}) policy to cover professional dental cleanings and more serious treatments.`,
  },
  PUBLIC_HEALTH_ARTIST_NOT_REGISTERED_WITH_KSK_PARTIAL: {
    title: 'You could be eligible',
    description:
      'You need to be registered as a professional artist with the Social Insurance for Artists (Künstlersozialkasse or KSK) to be eligible for public health insurance. You can become eligible if your main occupation changes of if you have been on public health insurance anywhere within the EU/EEA for at least 2 years out of the last 5 years.',
  },
  PUBLIC_HEALTH_CHILDREN_COVERED_FOR_FREE: {
    title: 'Your children could be covered without additional costs',
    description:
      'Children under 24 years old earning less than €7k per year can be covered as dependents for free. The age threshold is higher in some cases.',
  },
  PUBLIC_HEALTH_CIVIL_SERVANT_PARTIAL: {
    title: 'You could be eligible',
    description:
      'Because you are a civil servant, you are only eligible if you have been on public health insurance anywhere within the EU/EEA for at least 2 years out of the last 5 years. Most civil servants decide to take on private health insurance because of special tariffs.',
  },
  PUBLIC_HEALTH_CIVIL_SERVANT_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Because you have not been recently insured on public health insurance in the EU/EEA, you are currently not eligible. You can become eligible if your main occupation changes.',
  },
  PUBLIC_HEALTH_COVERAGE_CAN_CHANGE_OVER_TIME: {
    title: 'The coverage can change over time',
    description:
      'Unlike private insurance where  the covered conditions are fixed in the policy, on the public system the scope of your coverage can slightly vary over the years.',
  },
  PUBLIC_HEALTH_EMPLOYED_IN_DE_OVER_55_INSURANCE_HISTORY_PARTIAL: {
    title: 'You could be eligible',
    description:
      'You are eligible if you have been covered publicly within the EU/EEA for at least 24 months in the last 5 years.',
  },
  PUBLIC_HEALTH_EMPLOYED_IN_DE_OVER_55_NO_INSURANCE_HISTORY_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Because you are over 55 years old and have never been insured on public health insurance in the EU/EEA, you are currently not eligible.',
  },
  PUBLIC_HEALTH_EMPLOYED_LAST_INSURANCE_WAS_PRIVATE: {
    title: 'You are not eligible',
    description:
      'Because you are earning more than €65k per year and decided to take on private insurance previously, you are currently not eligible for public health insurance. You can become eligible with a lower salary, or if the salary threshold to be on private increases and surpasses your current salary, or if your main occupation changes.',
  },
  PUBLIC_HEALTH_EMPLOYED_OUTSIDE_DE_PARTIAL: {
    title: 'You could be eligible',
    description:
      'Because you are employed outside Germany, you are only eligible if you have been on public health insurance anywhere within the EU/EEA for at least 2 years out of the last 5 years.',
  },
  PUBLIC_HEALTH_EMPLOYED_OUTSIDE_DE_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Because you have not been recently insured on public health insurance in the EU/EEA, you are currently not eligible. You can become eligible if your main occupation changes.',
  },
  PUBLIC_HEALTH_EMPLOYED_OVER_55_CURRENTLY_ON_PRIVATE: {
    title: 'You are not eligible',
    description: `Because you are over 55 years old and are on private insurance, you are currently not eligible for public health insurance in Germany. You may be eligible if your salary goes under ${englishFormattedEuroCurrency(
      insurance.privateHealth.employeeThreshold,
      true
    )}.`,
  },
  PUBLIC_HEALTH_EMPLOYED_OVER_55_HIGH_INCOME_LAST_ON_PRIVATE_PARTIAL: {
    title: 'You could be eligible',
    description:
      'You are eligible if you have been covered publicly within the EU/EEA for at least 24 months in the last 5 years.',
  },
  PUBLIC_HEALTH_EMPLOYED_OVER_55_HIGH_INCOME_LAST_ON_PUBLIC_PARTIAL: {
    title: 'You could be eligible',
    description:
      'Although more exceptions can apply, as a general rule you can be eligible if: A. your last insurance within the EU/EEA was in a public healthcare system, and you had it continuously for at least 12 months.  B. You have been covered publicly within the EU/EEA for at least 24 months in the last 5 years.',
  },
  PUBLIC_HEALTH_EMPLOYED_OVER_55_HIGH_INCOME_NO_INSURANCE_HISTORY_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Because you are over 55 years old and have never been insured on public health insurance in the EU/EEA, you are currently not eligible.',
  },
  PUBLIC_HEALTH_OVER_55_SPECIAL_OCCUPATION_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Because you have not been recently insured on public health insurance in the EU/EEA, you are currently not eligible. You can become eligible if your main occupation changes.',
  },
  PUBLIC_HEALTH_LANGUAGE_STUDENT_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Because you do not study at a recognized German university, your main occupation and insurance history make you ineligible for public health insurance. You could be eligible if you become employed, or as a dependent if you have a spouse or parent that is publicly insured.',
  },
  PUBLIC_HEALTH_MINI_JOB_INELIGIBLE: {
    title: 'You are not eligible',
    description: `Because you are making less than ${englishFormattedEuroCurrency(
      12 * insurance.healthInsurance.miniJobThreshold,
      true
    )} per year (considered as a mini-job) and have not been recently insured on public insurance within the EU/EEA, you are not currently eligible.`,
  },
  PUBLIC_HEALTH_NO_WAITING_PERIOD: {
    title: 'No waiting periods',
    description:
      'Unlike private insurance, you are immediately covered up to the full amount specified by the policy.',
  },
  PUBLIC_HEALTH_OTHER_OCCUPATION_INELIGIBLE: {
    title: 'You are likely not eligible',
    description:
      'Your main occupation and insurance history make you ineligible for public health insurance, though some exceptions may apply. You can become eligible if you become employed, or as a dependent if you have a spouse or parent that is publicly insured.',
  },
  PUBLIC_HEALTH_OVER_55_NOT_EMPLOYED_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Because you are older than 55, have not recently been on public health in any EU/EEA country, and are not currently employed, you are not eligible for public health insurance.',
  },
  PUBLIC_HEALTH_OVER_55_SPECIAL_OCCUPATION_PARTIAL: {
    title: 'You could be eligible',
    description:
      'You are only eligible if you have been on public health insurance anywhere within the EU/EEA for at least 2 years out of the last 5 years.',
  },
  PUBLIC_HEALTH_PREEXISTING_CONDITIONS: {
    title: 'Pre-existing conditions are covered without extra costs',
    description:
      'Unlike private insurance, pre-existing conditions do not play a role in your eligibility and do not increase the cost of your insurance.',
  },
  PUBLIC_HEALTH_SELF_EMPLOYED_PARTIAL: {
    title: 'You could be eligible',
    description:
      'Because you are self-employed, you are only eligible if you have been on public health insurance anywhere within the EU/EEA for at least 2 years out of the last 5 years.',
  },
  PUBLIC_HEALTH_SELF_EMPLOYED_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Because you have not been recently insured on public health insurance in the EU/EEA, you are currently not eligible. You can become eligible if your main occupation changes.',
  },
  PUBLIC_HEALTH_SPOUSE_COVERED_FOR_FREE: {
    title: 'Your spouse could be covered without additional costs',
    description:
      'Your spouse can be covered as a dependent without additional costs as long as they are earning less than €7k per year.',
  },
  PUBLIC_HEALTH_STUDENT_AT_NON_RECOGNIZED_UNIVERSITY_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Because you do not study at a recognized German university, your main occupation and insurance history make you ineligible for public health insurance. You could be eligible if you become employed, or as a dependent if you have a spouse or parent that is publicly insured.',
  },
  PUBLIC_HEALTH_STUDENT_OLDER_THAN_30: {
    title: 'You are not eligible',
    description:
      'Because you are over 30 years old, you are not eligible for public health insurance as a student. You could become eligible if your main occupation changes.',
  },
  PUBLIC_HEALTH_STUDENT_ON_PRIVATE_INSURANCE: {
    title: 'You are not eligible',
    description:
      'If you have already opted out of public health insurance during the first three months of your studies, you need to stay on private until the end of your studies.',
  },
  PUBLIC_HEALTH_STUDENT_PARTIAL_BEFORE_STARTING_UNIVERSITY: {
    title: 'You will be eligible',
    description:
      'You can sign up for public health insurance and be covered starting from the first day of your studies.',
  },
  PUBLIC_HEALTH_STUDENT_PARTIAL_UNLESS_OPTED_OUT: {
    title: 'You can be eligible',
    description:
      'If you have not yet opted out of public health insurance when you began your studies, you are eligible. If you opted out but have since changed your field of study, you may be eligible again.',
  },
  PUBLIC_HEALTH_UNDER_18: {
    title: 'You are not eligible',
    description: `Because you are younger than 18, you are currently not eligible for public health insurance. You can be eligible to sign up as a dependent if you are earning less than ${englishFormattedEuroCurrency(
      12 * insurance.healthInsurance.miniJobThreshold,
      true
    )} per year.`,
  },
  PUBLIC_HEALTH_UNDER_55_RETIRED_INELIGIBLE: {
    title: 'You are likely not eligible',
    description: `Because you are retired and did not recently have public insurance within the EU/EEA, you are likely not eligible. However, some exceptions may apply. [Feel free to contact us](${website.support}) if you need help understanding your eligibility.`,
  },
  PUBLIC_HEALTH_UNEMPLOYMENT_BENEFITS_INELIGIBLE: {
    title: 'You are not eligible',
    description:
      'Because you are not employed and have not been insured within the public system in the EU/EEA immediately before moving to Germany, you are not eligible for public health insurance. You can become eligible if you start receiving AGL1 unemployment benefits.',
  },
  PUBLIC_HEALTH_UNEMPLOYMENT_BENEFITS_PARTIAL: {
    title: 'You could be eligible',
    description:
      'You are eligible if you are currently on ALG1 and did not switch to private health insurance when you registered for unemployment benefits.',
  },
  PUBLIC_HEALTH_FIRST_TIME_EMPLOYED_IN_DE_HIGH_INCOME_PARTIAL: {
    title: 'You may be eligible',
    description: `Because you are already insured on private insurance in Germany, you are not eligible for public health insurance unless your income goes below ${englishFormattedEuroCurrency(
      insurance.privateHealth.employeeThreshold,
      true
    )}. If you wish to switch to public, you can try to negotiate a lower starting salary with your employer. Should your salary be increased later, you will remain eligible for public health insurance.\nBecause you are already insured on private insurance in Germany, you are not eligible for public health insurance unless your income goes below ${englishFormattedEuroCurrency(
      insurance.privateHealth.employeeThreshold,
      true
    )}. If you wish to switch to public, you can try to negotiate a lower starting salary with your employer. Should your salary be increased later, you will remain eligible for public health insurance.`,
  },
};
