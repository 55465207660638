import { RemoveAnswerType, Rule } from '@getpopsure/qnr-framework';
import { isUnder18 } from 'shared/util/isUnder18';

import { Dental } from '../models';

export const getRules = (): Rule<Dental>[] => [
  {
    id: 'publicInsurer',
    if: {
      op: 'equals',
      variable: { type: 'static', value: '' },
    },
    then: { goTo: 'noPublicInsuranceBlocker' },
    else: { goTo: 'dateOfBirth' },
  },
  {
    id: 'signupIntro',
    if: (_, answers) => {
      return answers.quote?.plan === 'ADVANCED';
    },
    then: {
      goTo: 'advancedNoticeScreen',
    },
    else: {
      goTo: 'hasTreatment',
    },
  },
  {
    id: 'advancedNoticeScreen',
    if: { op: 'always' },
    then: { goTo: 'missingTeeth' },
  },
  {
    id: 'missingTeeth',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'TWO' },
    },
    then: {
      goTo: 'missingTwoTeethNotice',
    },
  },
  {
    id: 'missingTeeth',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'THREE' },
    },
    then: {
      goTo: 'missingThreeTeethNotice',
    },
  },
  {
    id: 'missingTeeth',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'MORE_THAN_THREE' },
    },
    then: {
      goTo: 'missingTeethBlocker',
    },
  },
  {
    id: 'hasTreatment',
    if: (value, answers) => {
      return value === true && answers.quote?.plan === 'ADVANCED';
    },
    then: {
      goTo: 'hasTreatmentBlocker',
    },
  },
  {
    id: 'hasTreatment',
    if: (value, answers) => {
      return value === true && answers.quote?.plan === 'BASIC';
    },
    then: {
      goTo: 'hasTreatmentBasic',
    },
  },
  {
    id: 'hasTreatment',
    if: {
      op: 'equals',
      variable: { type: 'static', value: false },
    },
    then: {
      goTo: 'email',
    },
  },
  {
    id: 'gender',
    if: (_, answers) => {
      return isUnder18(answers.dateOfBirth);
    },
    then: {
      goTo: 'legalGuardianName',
    },
  },
  {
    id: 'legalGuardianName',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianDateOfBirth',
    },
  },
];

const removeReview: RemoveAnswerType<Dental> = {
  op: 'always',
  questions: ['reviewCheckout'],
};

export const removeAnswersLogic: Partial<
  Record<keyof Dental, RemoveAnswerType<Dental>>
> = {
  dateOfBirth: {
    op: 'always',
    questions: [
      'legalGuardianName',
      'legalGuardianDateOfBirth',
      'reviewCheckout',
    ],
  },
  quote: removeReview,
  legalGuardianName: removeReview,
  legalGuardianDateOfBirth: removeReview,
  startDate: removeReview,
  email: removeReview,
  name: removeReview,
};
