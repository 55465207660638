import type {
  HouseholdClaims,
  Questionnaire as HouseholdQuestionnaire,
} from 'features/householdClaims/models';
import Questionnaire from 'features/questionnaireFramework';

interface ViewProps {
  questionnaire: HouseholdQuestionnaire;
  questionnaireAnswers: Partial<HouseholdClaims>;
  handleAnswerQuestion: (answer: Partial<HouseholdClaims>) => void;
}

export const HouseholdClaimsView = ({
  questionnaireAnswers,
  handleAnswerQuestion,
  questionnaire,
}: ViewProps) => {
  return (
    <Questionnaire
      featureName="Household Claims"
      questionnaire={questionnaire}
      questionnaireAnswers={questionnaireAnswers}
      handleAnswerQuestion={handleAnswerQuestion}
    />
  );
};
