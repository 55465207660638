import { Address, Gender } from '@getpopsure/public-models';
import { Name } from 'models/user';
import { UploadedFile } from 'shared/models/types';

const genders: Gender[] = ['MALE', 'FEMALE', 'OTHER'];

// [KONG] Below validators pass any as argument type and need to be disabled for eslint
/* eslint-disable */
export const isGender = (value: any): value is Gender =>
  genders.includes(value);

export const isName = (value: any): value is Name =>
  Boolean(
    value &&
      typeof value.firstName === 'string' &&
      value.firstName.trim() !== '' &&
      typeof value.lastName === 'string' &&
      value.lastName.trim() !== ''
  );

export const isAddress = (value: any): value is Address =>
  Boolean(
    value?.street !== undefined &&
      value?.postcode !== undefined &&
      value?.houseNumber !== undefined &&
      value?.country !== undefined &&
      value?.city !== undefined
  );

export const isUploadedFiles = (value: any): value is UploadedFile[] =>
  value.every((item: any) =>
    Boolean(
      item.name !== undefined &&
        item.id !== undefined &&
        item.type !== undefined &&
        item.progress !== undefined
    )
  );
/* eslint-disable */
