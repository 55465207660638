// From private-signup repo
export function camelCaseToKebabCase(input: string): string {
  // 🐪 to 🥙
  return input
    .replace(/[\w]([A-Z])/g, (m) => {
      return `${m[0]}-${m[1]}`;
    })
    .toLowerCase();
}

// From https://gist.github.com/dtomasi/9327f704398be8d8ff5d0ab560b95641
export function kebabCaseToCamelCase(input: string): string {
  // 🥙 to 🐪
  return input.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}
