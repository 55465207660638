import StepBar from 'components/stepBar';
import routes from 'constants/routes';
import { useClaimSteps } from 'features/claimsProcess/hooks';
import { ViewProps } from 'features/claimsProcess/processing';
import { useEffect } from 'react';
import { generatePath } from 'react-router-dom';

import styles from './style.module.scss';

const TIMEOUT_TIME = 4_000;

const Processing = ({ policyId, history }: ViewProps) => {
  const steps = useClaimSteps();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      history.push(
        generatePath(routes.me.claims.confirmation.path, { policyId })
      );
    }, TIMEOUT_TIME);

    return () => clearTimeout(timeoutId);
  }, [policyId, history]);

  return (
    <div className="p-body">
      <div className="mt40">
        <StepBar currentStep={steps.length + 1} steps={steps.length} />
      </div>
      <div className={styles.container}>
        <div className={`ds-spinner ds-spinner__m ${styles.spinner}`} />
        <div className={`p-h2 mt24 ${styles['text-appear']}`}>
          Processing claim...
        </div>
      </div>
    </div>
  );
};

export default Processing;
