import { TallyModal } from 'components/TallyModal';
import dayjs from 'dayjs';
import { PolicySingleModalProps } from 'features/policySingle/components/modals';
import { useSelector } from 'react-redux';
import { getAccountInfo } from 'selectors/user';

export type DisabilityCancelPolicyModalType = 'DISABILITY_CANCEL_POLICY';

export const DisabilityCancelPolicyModal = (props: PolicySingleModalProps) => {
  const {
    onClose,
    policyData: {
      id,
      attributes: { insuredPerson, policyNumber },
    },
  } = props;

  const account = useSelector(getAccountInfo);

  const URLparams = {
    policy_id: id,
    first_name: insuredPerson?.name?.firstName,
    last_name: insuredPerson?.name?.lastName,
    customer_email: account?.email,
    policy_number: policyNumber,
    date_of_birth: account?.dateOfBirth
      ? dayjs(account?.dateOfBirth).format('DD.MM.YYYY')
      : '',
  };

  return (
    <TallyModal
      isVisible
      onClose={onClose}
      tallyId="nPE4bP"
      title="Disability Policy Cancellation"
      URLparams={URLparams}
    />
  );
};
