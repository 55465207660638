import Session from '@getpopsure/session';
import { signOut } from 'actions/session';
import { useGetEmailFromUrl } from 'hooks/useGetEmailFromUrl';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmail } from 'selectors/user';

import { RouteWithAuthenticationView } from './RouteWithAuthentication.view';

interface Props {
  path: string;
  component: JSX.Element;
  exact?: boolean;
}

export const RouteWithAuthentication = ({ path, component, exact }: Props) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = Session;
  const userEmail = useSelector(getEmail);
  const { emailParam } = useGetEmailFromUrl();

  useEffect(() => {
    if (emailParam && userEmail && emailParam !== userEmail) {
      dispatch(signOut());
    }
  }, [emailParam, userEmail, dispatch]);

  return (
    <RouteWithAuthenticationView
      path={path}
      component={component}
      exact={exact}
      isAuthenticated={isAuthenticated}
    />
  );
};
