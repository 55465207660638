import { captureException } from '@sentry/react';
import { RequestType } from 'constants/requestTypes';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';

export const formatErrorMessageFromError = (
  error: APIResponseError
): string => {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  }
  return error.message || 'Something went wrong';
};

export const getRequestInProcess = (
  state: AppState,
  requestType: RequestType
): boolean =>
  state.request[requestType] && state.request[requestType].inProcess;

export const getRequestError = (
  state: AppState,
  requestType: RequestType
): APIResponseError | undefined =>
  state.request[requestType] && state.request[requestType].error;

export const getRequestErrorMessage = (
  state: AppState,
  requestType: RequestType
): string | undefined => {
  const error = getRequestError(state, requestType);

  if (error) {
    const errorMessage = formatErrorMessageFromError(error);

    if (error instanceof Error) {
      captureException(error);
    } else {
      captureException(new Error(errorMessage));
    }

    return errorMessage;
  }
};
