import { paramsSetUrl } from '@getpopsure/public-utility';
import { Button, toast } from '@popsure/dirty-swan';
import classNames from 'classnames';
import routes from 'constants/routes';
import lz from 'lz-string';
import { useState } from 'react';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import { useSafeTranslation } from 'shared/i18n';

import styles from './PolicyShareButton.module.scss';

type PolicyShareButtonProps = {
  questionnaireAnswers: Record<string, unknown>;
  verticalId: keyof GenericQuestionnaireState;
};

export const PolicyShareButton = ({
  questionnaireAnswers,
  verticalId,
}: PolicyShareButtonProps) => {
  const { t } = useSafeTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const path = window.location.pathname;

  const shareURL = path
    ? paramsSetUrl(
        `https://app.feather-insurance.com${routes.policies.seed.path}`,
        [
          {
            // p = Path
            key: 'p',
            // eslint-disable-next-line react/prop-types
            value: path,
          },
          // v = Values
          {
            key: 'v',
            value: lz.compressToEncodedURIComponent(
              JSON.stringify(questionnaireAnswers)
            ),
          },
          // t = Type
          // eslint-disable-next-line react/prop-types
          { key: 't', value: verticalId },
          // ver = Version
          { key: 'ver', value: '1' },
        ]
      )
    : undefined;

  const handleCopyToClipboard = async () => {
    if (!shareURL) return;

    navigator.clipboard.writeText(shareURL);

    toast(
      t(
        'policyShareButton.modal.toast.message',
        'Share link copied to clipboard'
      ),
      { type: 'success' }
    );
  };

  return (
    <div className={classNames('ws4', styles.buttonBox)}>
      {isOpen && (
        <>
          <input
            className="p-p--small p8 w100 bg-grey-100 br8"
            value={shareURL}
            disabled
          />

          <div className={`mt8 mb32 d-flex ${styles.buttonContainer}`}>
            <Button onClick={handleCopyToClipboard}>
              {t('policyShareButton.button.copy', 'Copy share link')}
            </Button>
          </div>
        </>
      )}
      <div className="w100 d-flex jc-center">
        <button
          type="button"
          className="p-a c-pointer pt8 bg-transparent ta-center mx-auto"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {isOpen
            ? t('policyShareButton.button.close', 'Close share link')
            : t('policyShareButton.button.open', 'Open share link')}
        </button>
      </div>
    </div>
  );
};
