import styles from './style.module.scss';

interface Props {
  className: string;
}

export const CalculatingCompleteIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="136"
    height="112"
    viewBox="0 0 136 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M134 43.9995C137.669 90.9163 99.4457 112 69.3092 112C39.1727 112 0 86.6587 0 59.6692C0 32.6796 7.19355 15.8729 37.5085 5.46562C67.8235 -4.94168 130.331 -2.91726 134 43.9995Z"
      fill="#F9F9FF"
    />
    <path
      d="M25.4688 33.3486C25.4688 29.4826 28.6028 26.3486 32.4687 26.3486H106.614C110.48 26.3486 113.614 29.4826 113.614 33.3486V78.6508C113.614 82.5168 110.48 85.6508 106.614 85.6508H32.4687C28.6027 85.6508 25.4688 82.5168 25.4688 78.6508V33.3486Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.952 30.1233H31.1307C30.0884 30.1233 29.2434 30.9683 29.2434 32.0106V79.9888C29.2434 81.0312 30.0884 81.8761 31.1307 81.8761H107.952C108.995 81.8761 109.84 81.0312 109.84 79.9888V32.0106C109.84 30.9683 108.995 30.1233 107.952 30.1233ZM31.1307 26.3486C28.0037 26.3486 25.4688 28.8836 25.4688 32.0106V79.9888C25.4688 83.1158 28.0037 85.6508 31.1307 85.6508H107.952C111.079 85.6508 113.614 83.1158 113.614 79.9888V32.0106C113.614 28.8836 111.079 26.3486 107.952 26.3486H31.1307Z"
      className={styles['fill-primary-100']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.687 44.7207C112.729 44.7207 113.574 45.5657 113.574 46.608V79.9951C113.574 83.1221 111.039 85.6571 107.912 85.6571H31.1717C28.0447 85.6571 25.5098 83.1221 25.5098 79.9951V58.0084C25.5098 56.9661 26.3547 56.1211 27.3971 56.1211C28.4394 56.1211 29.2844 56.9661 29.2844 58.0084V79.9951C29.2844 81.0374 30.1294 81.8824 31.1717 81.8824H107.912C108.955 81.8824 109.8 81.0374 109.8 79.9951V46.608C109.8 45.5657 110.645 44.7207 111.687 44.7207Z"
      fill="#F7F7FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.2578 38.8598C39.2578 37.6363 40.2496 36.6445 41.473 36.6445V38.8598V41.075C40.2496 41.075 39.2578 40.0832 39.2578 38.8598ZM75.6605 49.3422L75.6604 51.5575H66.632C64.3154 51.5575 62.2247 50.6959 60.3989 49.6069C58.5835 48.5242 56.8506 47.1039 55.28 45.8117L55.1983 45.7445C53.6035 44.4324 52.1736 43.256 50.7574 42.3839C49.3239 41.5011 48.1262 41.075 47.0541 41.075H41.473V38.8598V36.6445H47.0541C49.2962 36.6445 51.3224 37.5287 53.0806 38.6114C54.7604 39.6458 56.3976 40.9934 57.9031 42.2326C57.9672 42.2853 58.0311 42.3379 58.0947 42.3903C59.7095 43.7188 61.1881 44.919 62.6682 45.8018C64.1379 46.6783 65.4268 47.127 66.632 47.127H75.6604L75.6605 49.3422ZM75.6605 49.3422L75.6604 51.5575C76.8838 51.5575 77.8757 50.5657 77.8757 49.3422C77.8757 48.1188 76.8838 47.127 75.6604 47.127L75.6605 49.3422Z"
      className={styles['fill-primary-100']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.3027 49.4213C39.3027 48.1978 40.2945 47.2061 41.518 47.2061L75.5809 47.2061C76.8044 47.2061 77.7961 48.1979 77.7961 49.4213C77.7961 50.6447 76.8044 51.6365 75.5809 51.6365L41.518 51.6365C40.2945 51.6365 39.3027 50.6447 39.3027 49.4213Z"
      className={styles['fill-primary-500']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.516 57.1311L41.516 59.3463L41.516 61.5615H45.7256C47.9541 61.5615 50.0872 60.7609 52.0195 59.7641C53.7935 58.8489 55.5907 57.6621 57.297 56.5354C57.4505 56.434 57.6032 56.3332 57.7551 56.2331C59.6475 54.9857 61.4476 53.8266 63.2406 52.9706C65.0272 52.1176 66.668 51.6359 68.2185 51.6359H75.424C76.6474 51.6359 77.6392 50.6441 77.6392 49.4206C77.6392 48.1972 76.6474 47.2054 75.424 47.2054H68.2185C65.7614 47.2054 63.4434 47.9643 61.3318 48.9724C59.2267 49.9775 57.1893 51.2998 55.3169 52.5339C55.1761 52.6266 55.0364 52.7188 54.8977 52.8104C53.1401 53.9702 51.5396 55.0264 49.9882 55.8267C48.313 56.6909 46.9233 57.1311 45.7256 57.1311H41.516ZM39.3008 59.3463C39.3008 60.5697 40.2926 61.5615 41.516 61.5615L41.516 59.3463L41.516 57.1311C40.2926 57.1311 39.3008 58.1229 39.3008 59.3463Z"
      className={styles['fill-primary-500']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.3792 44.7976C100.209 45.5262 100.292 46.7899 99.5631 47.6201L93.8945 54.0789C93.2331 54.8325 92.116 54.9799 91.2819 54.4237L87.84 52.129C86.9209 51.5163 86.6726 50.2746 87.2854 49.3555C87.8981 48.4365 89.1398 48.1882 90.0589 48.8009L92.0434 50.124L96.5568 44.9815C97.2854 44.1514 98.549 44.069 99.3792 44.7976Z"
      className={styles['fill-primary-500']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.2578 69.915C39.2578 68.653 40.2809 67.6299 41.543 67.6299H75.8518C77.1139 67.6299 78.137 68.653 78.137 69.915C78.137 71.1771 77.1139 72.2002 75.8518 72.2002H41.543C40.2809 72.2002 39.2578 71.1771 39.2578 69.915Z"
      className={styles['fill-primary-100']}
    />
  </svg>
);

export const CalculatingIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="136"
    height="112"
    viewBox="0 0 136 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M134 43.9995C137.669 90.9163 99.4457 112 69.3092 112C39.1727 112 0 86.6587 0 59.6692C0 32.6796 7.19355 15.8729 37.5085 5.46562C67.8235 -4.94168 130.331 -2.91726 134 43.9995Z"
      fill="#F9F9FF"
    />
    <path
      d="M25.4688 33.3486C25.4688 29.4826 28.6028 26.3486 32.4687 26.3486H106.614C110.48 26.3486 113.614 29.4826 113.614 33.3486V78.6508C113.614 82.5168 110.48 85.6508 106.614 85.6508H32.4687C28.6027 85.6508 25.4688 82.5168 25.4688 78.6508V33.3486Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.952 30.1233H31.1307C30.0884 30.1233 29.2434 30.9683 29.2434 32.0106V79.9888C29.2434 81.0312 30.0884 81.8761 31.1307 81.8761H107.952C108.995 81.8761 109.84 81.0312 109.84 79.9888V32.0106C109.84 30.9683 108.995 30.1233 107.952 30.1233ZM31.1307 26.3486C28.0037 26.3486 25.4688 28.8836 25.4688 32.0106V79.9888C25.4688 83.1158 28.0037 85.6508 31.1307 85.6508H107.952C111.079 85.6508 113.614 83.1158 113.614 79.9888V32.0106C113.614 28.8836 111.079 26.3486 107.952 26.3486H31.1307Z"
      className={styles['fill-primary-100']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.687 44.7207C112.729 44.7207 113.574 45.5657 113.574 46.608V79.9951C113.574 83.1221 111.039 85.6571 107.912 85.6571H31.1717C28.0447 85.6571 25.5098 83.1221 25.5098 79.9951V58.0084C25.5098 56.9661 26.3547 56.1211 27.3971 56.1211C28.4394 56.1211 29.2844 56.9661 29.2844 58.0084V79.9951C29.2844 81.0374 30.1294 81.8824 31.1717 81.8824H107.912C108.955 81.8824 109.8 81.0374 109.8 79.9951V46.608C109.8 45.5657 110.645 44.7207 111.687 44.7207Z"
      fill="#F7F7FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.2578 38.8598C39.2578 37.6363 40.2496 36.6445 41.473 36.6445V38.8598V41.075C40.2496 41.075 39.2578 40.0832 39.2578 38.8598ZM75.6605 49.3422L75.6604 51.5575H66.632C64.3154 51.5575 62.2247 50.6959 60.3989 49.6069C58.5835 48.5242 56.8506 47.1039 55.28 45.8117L55.1983 45.7445C53.6035 44.4324 52.1736 43.256 50.7574 42.3839C49.3239 41.5011 48.1262 41.075 47.0541 41.075H41.473V38.8598V36.6445H47.0541C49.2962 36.6445 51.3224 37.5287 53.0806 38.6114C54.7604 39.6458 56.3976 40.9934 57.9031 42.2326C57.9672 42.2853 58.0311 42.3379 58.0947 42.3903C59.7095 43.7188 61.1881 44.919 62.6682 45.8018C64.1379 46.6783 65.4268 47.127 66.632 47.127H75.6604L75.6605 49.3422ZM75.6605 49.3422L75.6604 51.5575C76.8838 51.5575 77.8757 50.5657 77.8757 49.3422C77.8757 48.1188 76.8838 47.127 75.6604 47.127L75.6605 49.3422Z"
      className={styles['fill-primary-100']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.3027 49.4213C39.3027 48.1978 40.2945 47.2061 41.518 47.2061L75.5809 47.2061C76.8044 47.2061 77.7961 48.1979 77.7961 49.4213C77.7961 50.6447 76.8043 51.6365 75.5809 51.6365L41.518 51.6365C40.2945 51.6365 39.3027 50.6447 39.3027 49.4213Z"
      className={styles['fill-primary-100']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.516 57.1311L41.516 59.3463L41.516 61.5615H45.7256C47.9541 61.5615 50.0872 60.7609 52.0195 59.7641C53.7935 58.8489 55.5907 57.6621 57.297 56.5354C57.4505 56.434 57.6032 56.3332 57.7551 56.2331C59.6475 54.9857 61.4476 53.8266 63.2406 52.9706C65.0272 52.1176 66.668 51.6359 68.2185 51.6359H75.424C76.6474 51.6359 77.6392 50.6441 77.6392 49.4206C77.6392 48.1972 76.6474 47.2054 75.424 47.2054H68.2185C65.7615 47.2054 63.4434 47.9643 61.3318 48.9724C59.2267 49.9775 57.1893 51.2998 55.3169 52.5339C55.1761 52.6266 55.0364 52.7188 54.8977 52.8104C53.1401 53.9702 51.5396 55.0264 49.9882 55.8267C48.313 56.6909 46.9233 57.1311 45.7256 57.1311H41.516ZM39.3008 59.3463C39.3008 60.5697 40.2926 61.5615 41.516 61.5615L41.516 59.3463L41.516 57.1311C40.2926 57.1311 39.3008 58.1229 39.3008 59.3463Z"
      className={styles['fill-primary-500']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.3691 39.016C86.3691 37.7926 87.3609 36.8008 88.5844 36.8008L98.3532 36.8008C99.5766 36.8008 100.568 37.7926 100.568 39.016C100.568 40.2394 99.5766 41.2312 98.3532 41.2312L88.5844 41.2312C87.3609 41.2312 86.3691 40.2394 86.3691 39.016Z"
      className={styles['fill-primary-100']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.3691 59.6918C86.3691 58.4684 87.3609 57.4766 88.5844 57.4766L98.3532 57.4766C99.5766 57.4766 100.568 58.4684 100.568 59.6918C100.568 60.9152 99.5766 61.907 98.3532 61.907L88.5844 61.907C87.3609 61.907 86.3691 60.9152 86.3691 59.6918Z"
      className={styles['fill-primary-100']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.3691 49.3539C86.3691 48.1305 87.3609 47.1387 88.5844 47.1387L98.3532 47.1387C99.5766 47.1387 100.568 48.1305 100.568 49.3539C100.568 50.5773 99.5766 51.5691 98.3532 51.5691L88.5844 51.5691C87.3609 51.5691 86.3691 50.5773 86.3691 49.3539Z"
      className={styles['fill-primary-500']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.3691 70.0297C86.3691 68.8062 87.3609 67.8145 88.5844 67.8145L98.3532 67.8145C99.5766 67.8145 100.568 68.8062 100.568 70.0297C100.568 71.2531 99.5766 72.2449 98.3532 72.2449L88.5844 72.2449C87.3609 72.2449 86.3691 71.2531 86.3691 70.0297Z"
      className={styles['fill-primary-100']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.2578 69.915C39.2578 68.653 40.2809 67.6299 41.543 67.6299H75.8518C77.1139 67.6299 78.137 68.653 78.137 69.915C78.137 71.1771 77.1139 72.2002 75.8518 72.2002H41.543C40.2809 72.2002 39.2578 71.1771 39.2578 69.915Z"
      className={styles['fill-primary-100']}
    />
  </svg>
);
