import { Question, QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import { InsuranceTypes } from 'models/insurances/types';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import { ActionResponse } from 'shared/models/types';
import { Review } from 'SignupQuestionnaire/components/Review';
import {
  QuestionReviewValueType,
  ReviewProps,
} from 'SignupQuestionnaire/components/Review/Review';

import { getReviewValue } from './getReviewValues';

type OmittedReviewProps =
  | 'createPostQuote'
  | 'insuranceType'
  | 'verticalId'
  | 'requestType';

export const PreviewReview = <
  Questionnaire extends QuestionnaireQuestions = QuestionnaireQuestions
>(
  props: Omit<ReviewProps<Questionnaire>, OmittedReviewProps> & {
    apiPath: string;
    meta: Record<string, unknown>;
    transform?: {
      personalInfo: string[];
      tariffInfo: string[];
    };
    configuration: {
      questions: Question<Questionnaire>[];
    };
  }
) => {
  const { featureName, reviewValues, configuration } = props;

  const createPost = () => async () =>
    ({
      status: 'SUCCESS' as ActionResponse,
      result: {
        quoteId: 'preview-quote-id',
        questionnaireId: 'preview-questionnaire-id',
        quote: { id: 'preview-quote-id', price: 100 },
      },
    } as { status: ActionResponse });

  return (
    <Review
      requestType="REVIEW_SUBMIT_INFO"
      verticalId={featureName as keyof GenericQuestionnaireState}
      insuranceType={featureName as InsuranceTypes}
      {...props}
      createPostQuote={createPost}
      reviewValues={
        reviewValues
          .map((reviewEntry) => {
            return {
              ...reviewEntry,
              value: getReviewValue(
                configuration?.questions ?? [],
                reviewEntry.path ?? ''
              ),
            };
          })
          .filter(
            (reviewEntry) => reviewEntry.value !== null
          ) as QuestionReviewValueType<Questionnaire>[]
      }
    />
  );
};
