import { illustrations } from '@popsure/dirty-swan';

import hallescheIcon from './assets/hallesche.svg';

const checkDocIcon = illustrations.checkDoc;
const creditCardIcon = illustrations.creditCard;
const fastIcon = illustrations.fastEasy;
const passportIcon = illustrations.passport;
const phoneIcon = illustrations.phone2Fa;

export type CardData = {
  id: number;
  icon: string;
  title: string;
  description: string;
};

export const whyFeatherContent: CardData[] = [
  {
    id: 1,
    icon: passportIcon,
    title: 'Meets all visa requirements',
    description:
      'Fulfills all legal requirements for health insurance & long-term care insurance in Germany.',
  },
  {
    id: 2,
    icon: creditCardIcon,
    title: 'Digital insurance card',
    description:
      'Add it to your Apple or Google wallet and present it whenever you’re receiving medical services.',
  },
  {
    id: 3,
    icon: checkDocIcon,
    title: 'We do the paperwork',
    description:
      'We’ll provide the documents for your visa, employer, and tax office.',
  },
  {
    id: 4,
    icon: fastIcon,
    title: 'No doctor check-up',
    description:
      'No doctor visits required if you’re under 55, have no medical conditions, and have had continuous health insurance.',
  },
  {
    id: 5,
    icon: hallescheIcon,
    title: 'Substantial financial backing',
    description:
      'Feather’s insurance coverage is offered through different risk carriers based in Germany with excellent solvency ratios.',
  },
  {
    id: 6,
    icon: phoneIcon,
    title: 'On call doctors',
    description:
      'We offer video calls with English-speaking doctors based in Germany.',
  },
];
