import {
  EMPLOYMENT_STATUSES,
  EmploymentStatus,
} from '@getpopsure/private-health-insurance-pricing-engine';
import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import { PrivatePreSignup } from 'features/privateHealthPreSignup/models';
import { getTranslatedQuestionnaire } from 'features/privateHealthPreSignup/questionnaire';
import { getPrivatePreSignupAnswers } from 'features/privateHealthPreSignup/selectors';
import Questionnaire from 'features/questionnaireFramework';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

export const Signup = () => {
  const { t } = useSafeTranslation();

  const employmentStatusFromUrl = useQueryParamValue('employmentStatus');
  const birthYearFromUrl = useQueryParamValue('birthYear');
  const incomeFromUrl = useQueryParamValue('income');

  const answers = useSelector(getPrivatePreSignupAnswers) || {};
  const dispatch = useDispatch();

  const handleAnswerQuestion = (answer: Partial<PrivatePreSignup>) => {
    dispatch(storeGenericQuestionnaireAnswer('privateHealthPreSignup', answer));
  };

  const handleRemoveAnswer = (questionIds: (keyof PrivatePreSignup)[]) => {
    dispatch(
      removeGenericQuestionnaireAnswer('privateHealthPreSignup', questionIds)
    );
  };

  useEffect(() => {
    dispatch(
      storeGenericQuestionnaireAnswer('privateHealthPreSignup', {
        ...(incomeFromUrl ? { income: Number(incomeFromUrl) } : {}),
        ...(birthYearFromUrl ? { birthYear: Number(birthYearFromUrl) } : {}),
        ...(employmentStatusFromUrl &&
        EMPLOYMENT_STATUSES.includes(
          employmentStatusFromUrl as EmploymentStatus
        )
          ? { employmentStatus: employmentStatusFromUrl }
          : {}),
      } as Partial<PrivatePreSignup>)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Questionnaire
      featureName="Private Pre-signup"
      questionnaire={getTranslatedQuestionnaire(t, answers)}
      questionnaireAnswers={answers}
      handleAnswerQuestion={handleAnswerQuestion}
      handleRemoveAnswer={handleRemoveAnswer}
      share={{
        verticalId: 'privateHealthPreSignup',
      }}
    />
  );
};
