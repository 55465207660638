import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { BillingType } from 'features/policiesDashboard/models';
import { useSafeTranslation } from 'shared/i18n';

export interface PriceProps {
  amount: number;
  billingType?: BillingType;
  className?: string;
}

export const Price = ({ amount, billingType, className }: PriceProps) => {
  const { t } = useSafeTranslation();

  const formattedAmount = englishFormattedEuroCurrency(amount);
  const formattedPrice = billingType
    ? billingType === 'YEAR'
      ? t('myPolicies.policyDetails.price.year', {
          defaultValue: '{{formattedAmount}}/year',
          formattedAmount,
        })
      : t('myPolicies.policyDetails.price.month', {
          defaultValue: '{{formattedAmount}}/month',
          formattedAmount,
        })
    : formattedAmount;

  return (
    <div
      className={`p-p ${className}`}
      data-testid="policy-details-formatted-price"
    >
      {formattedPrice}
    </div>
  );
};
