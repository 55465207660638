import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { ClaimsAction } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import { APIResponseError } from 'models/error';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';

import {
  PrivateHealthClaims,
  PrivateHealthClaimsDispatch,
  PrivateHealthClaimsSubmitInfo,
} from '../models/PrivateHealthClaims.model';

export const storePrivateHealthClaimsAnswers = (
  answer: Partial<PrivateHealthClaims>
): ClaimsAction => ({
  type: 'STORE_PRIVATE_HEALTH_CLAIM',
  privateHealth: answer,
});

export const flushPrivateHealthClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_PRIVATE_HEALTH_CLAIM',
});

export const submitClaim =
  (answers: PrivateHealthClaimsSubmitInfo) =>
  async (dispatch: PrivateHealthClaimsDispatch): Promise<ActionResponse> => {
    const requestType: RequestType = 'CREATE_CLAIM';
    dispatch(setRequestInProcess(true, requestType));

    try {
      await endpoint.submitClaim(answers);
      dispatch(setRequestInProcess(false, requestType));
      return 'SUCCESS';
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      return 'ERROR';
    }
  };
