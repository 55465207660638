import { ageByDate, isDateFuture } from '@getpopsure/public-utility';
import DateForm from 'components/questionnaireForms/DateForm';
import {
  ViewProps,
  withContainer,
} from 'features/liability/containers/withContainer';
import {
  getQuestionsMapping,
  MIN_ELIGIBLE_AGE,
  QuestionId,
} from 'models/liability';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

const questionId: QuestionId = 'dateOfBirth';

const LiabilityDateOfBirth = ({
  initialValue,
  onSubmitValue,
}: ViewProps<typeof questionId>) => {
  const { t } = useSafeTranslation();

  const [error, setError] = useState('');

  const validate = (date: string) => {
    setError('');

    if (isDateFuture(date) === true) {
      setError('Oops, looks like that date is in the future');
      return;
    }

    if (ageByDate(date) < 18) {
      setError('You must be older than 18 to sign up');
      return;
    }

    onSubmitValue({ questionId, value: date });
  };

  return (
    <DateForm
      value={initialValue}
      onSubmitValue={validate}
      yearRange={{
        min: new Date().getFullYear() - 100,
        max: new Date().getFullYear() - MIN_ELIGIBLE_AGE,
      }}
      error={error}
      displayCalendar
      featureName="Liability"
      question={getQuestionsMapping(t)[questionId]}
    />
  );
};

export default withContainer(LiabilityDateOfBirth, questionId);
