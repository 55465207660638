import { ExpatDependentQuestionnaire } from 'features/expat/models';
import { PartialQuestionnaire } from 'models/questionnaireFramework';

import { DependentsReview } from '../../components/Review';

export const reviewInfoDependentQNR = (): PartialQuestionnaire<
  ExpatDependentQuestionnaire,
  'signup'
> => ({
  review: {
    required: true,
    type: 'CUSTOM',
    groupId: 'signup',
    component: DependentsReview,
    retrieveAnswerObject: (
      review: ExpatDependentQuestionnaire['review']
    ): Partial<ExpatDependentQuestionnaire> => ({
      review,
    }),
  },
});
