import { captureException as sentryCaptureException } from '@sentry/react';
import { ErrorWithAction } from 'components/ErrorWithAction';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import routes from 'constants/routes';
import { Plan } from 'features/dental/models';
import {
  flushDentalClaimsAnswers,
  retrievePolicyDetails,
  storeDentalClaimsAnswers,
} from 'features/dentalClaims/actions';
import { DentalClaimsView } from 'features/dentalClaims/DentalClaims.view';
import type {
  DentalClaims as DentalClaimsModel,
  DentalClaimsDispatch,
  DentalClaimsMetaData,
} from 'features/dentalClaims/models';
import { translatedQuestionnaire } from 'features/dentalClaims/questionnaire';
import { getDentalClaims } from 'features/dentalClaims/selectors';
import { QuestionnaireError } from 'features/questionnaireFramework/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

export const DentalClaims = () => {
  const [policyPlanId, setPolicyPlanId] = useState<string | null>('');
  const [questionnaireError, setQuestionnaireError] =
    useState<QuestionnaireError | null>(null);

  const { t } = useSafeTranslation();
  const dispatch = useDispatch<DentalClaimsDispatch>();
  const history = useHistory();

  const answers = useSelector(getDentalClaims) ?? {};
  const { policyId }: { policyId: string } = useParams();

  const { loading: fetchPolicyLoading, error: fetchPolicyError } =
    useRequestStatus('LOAD_MY_POLICY_DETAIL');

  const flushAnswers = useCallback(() => {
    dispatch(flushDentalClaimsAnswers());
  }, [dispatch]);

  useEffect(() => {
    const handleRetrievePolicyStartDate = async () => {
      const response = await dispatch(retrievePolicyDetails(policyId));

      setPolicyPlanId(response?.planId ?? null);
    };

    handleRetrievePolicyStartDate();

    return flushAnswers;
  }, [policyId, dispatch, flushAnswers]);

  const handleSubmitAnswer = (answer: Partial<DentalClaimsModel>) => {
    dispatch(storeDentalClaimsAnswers(answer));
  };

  if (fetchPolicyLoading) return <TimedLoadSpinner />;

  if (fetchPolicyError || policyPlanId === null || questionnaireError) {
    const handleResetFlow = async () => {
      const startPath = generatePath(routes.me.policies.detail.path, {
        policyId,
      });
      setQuestionnaireError(null);
      await flushAnswers();
      history.push(startPath);
    };

    if (questionnaireError?.type === 'CRITICAL') {
      sentryCaptureException(questionnaireError.message);
    }

    return (
      <ErrorWithAction
        title={t('claims.dental.mainError.title', 'You are missing some info')}
        description={t(
          'claims.dental.mainError.description',
          'It seems like you are missing some info to submit the claim. Click here to go back to policy page and try again.'
        )}
        cta={{
          title: t('claims.dental.mainError.cta', 'Go back to policy'),
          onClick: handleResetFlow,
        }}
      />
    );
  }

  const metaData: DentalClaimsMetaData = {
    policyId,
    planId: policyPlanId as Plan,
  };

  return (
    <DentalClaimsView
      questionnaireAnswers={answers}
      questionnaire={translatedQuestionnaire(t, metaData)}
      handleAnswerQuestion={handleSubmitAnswer}
      sendQuestionnaireError={setQuestionnaireError}
    />
  );
};
