import { website } from '@getpopsure/private-constants';
import type { PageId as LiabilityPageId } from 'models/liability';

const base = {
  path: '/',
  insuranceOptions: {
    path: '/insurance-options',
    link: `${website.base}#insurances`,
  },
  insuranceTools: {
    path: '/insurance-tools',
  },
};

const checkup = {
  path: '/questionnaire',
  question: {
    path: '/questionnaire/:questionId',
  },
};

const account = {
  path: '/account',
  signIn: {
    path: '/account/signin',
  },
  signInV2: {
    path: '/account/signin-v2',
    questionnaire: {
      path: '/account/signin-v2/:groupId?/:questionId?',
    },
  },
  billingAndPayment: {
    path: '/account/billing-payments',
  },
  billingHistory: {
    path: '/account/billing-history',
  },
  privacyAndTerms: {
    path: '/account/privacy-terms-security',
  },
  taxStatements: {
    path: '/account/tax-statements',
  },
  signUp: {
    path: '/account/signup',
  },
};

const subscriptions = {
  unsubscribed: {
    path: '/unsubscribed',
  },
};

const policies = {
  path: '/policies',
  job: {
    path: 'https://feather-insurance.typeform.com/to/CL68poNK?typeform-source=feather-insurance.com',
  },
  checkoutSetupConfirmation: {
    path: '/policies/checkout-setup-confirmation',
  },
  checkoutPaymentConfirmation: {
    path: '/policies/checkout-payment-confirmation',
  },
  household: {
    path: '/policies/household',
  },
  disability: {
    path: '/policies/disability',
    success: {
      path: '/policies/disability/success',
    },
  },
  dogLiability: {
    path: '/policies/dog-liability',
    questionnaire: {
      path: '/policies/dog-liability/:groupId?/:questionId?',
    },
  },
  petHealth: {
    path: '/policies/pet-health',
    questionnaire: {
      path: '/policies/pet-health/:groupId?/:questionId?',
    },
    tally: {
      path: '/policies/pet-health/questions',
    },
  },
  life: {
    path: '/policies/life',
    questionnaire: {
      path: '/policies/life/:groupId?/:questionId?',
    },
  },
  travelHealth: {
    path: '/policies/travel-health',
    questionnaire: {
      path: '/policies/travel-health/:groupId?/:questionId?',
    },
    success: {
      path: '/policies/travel-health/success',
    },
  },
  travelHealthFrance: {
    path: '/policies/travel-health-france',
  },
  liability: {
    path: '/policies/liability',
    quote: {
      path: '/policies/liability/quote',
    },
    blocker: {
      path: '/policies/liability/not-eligible',
    },
    questionnaire: {
      numberOfPreviousClaims: {
        path: '/policies/liability/questionnaire/past-claims',
      },
      name: {
        path: '/policies/liability/questionnaire/name',
      },
      dateOfBirth: {
        path: '/policies/liability/questionnaire/date-of-birth',
      },
      gender: {
        path: '/policies/liability/questionnaire/gender',
      },
      currentlyLivingInGermany: {
        path: '/policies/liability/questionnaire/living-in-germany',
      },
      relocationDate: {
        path: '/policies/liability/questionnaire/relocation-date',
      },
      address: {
        path: '/policies/liability/questionnaire/address',
      },
      startDate: {
        path: '/policies/liability/questionnaire/start-date',
      },
    },
    account: {
      path: '/policies/liability/account',
    },
    email: {
      path: '/policies/liability/email',
    },
    review: {
      path: '/policies/liability/review',
    },
    lastSteps: {
      path: '/policies/liability/last-steps',
    },
    download: {
      path: '/policies/liability/download',
    },
    checkout: {
      path: '/policies/liability/checkout',
    },
  },
  legal: {
    path: '/policies/legal',
    questionnaire: {
      path: '/policies/legal/:groupId?/:questionId?',
    },
  },
  dental: {
    path: '/policies/dental',
    questionnaire: {
      path: '/policies/dental/:groupId?/:questionId?',
    },
  },
  privateHealthLight: {
    path: '/policies/private-health-light',
    quote: {
      path: '/policies/private-health-light/quote',
    },
    addOns: {
      path: '/policies/private-health-light/select-add-ons',
    },
  },
  privateHealth: {
    path: '/policies/private-health',
    questionnaire: {
      path: '/policies/private-health/questionnaire',
      dateOfBirth: {
        path: '/policies/private-health/questionnaire/date-of-birth',
      },
      citizenship: {
        path: '/policies/private-health/questionnaire/citizenship',
      },
      arrivalDateToGermany: {
        path: '/policies/private-health/questionnaire/arrival-date-to-germany',
      },
      employmentStatus: {
        path: '/policies/private-health/questionnaire/employment-status',
      },
      employmentStatusOther: {
        path: '/policies/private-health/questionnaire/employment-status-other',
      },
      stayingMoreThanTenYears: {
        path: '/policies/private-health/questionnaire/staying-more-than-ten-years',
      },
      studentType: {
        path: '/policies/private-health/questionnaire/student-type',
      },
      income: {
        path: '/policies/private-health/questionnaire/income',
      },
      employmentOutsideGermany: {
        path: '/policies/private-health/questionnaire/employed-outside-germany',
      },
      healthStatus: {
        path: '/policies/private-health/questionnaire/health-status',
      },
      healthCondition: {
        path: '/policies/private-health/questionnaire/health-condition',
      },
      familyCoverage: {
        path: '/policies/private-health/questionnaire/family-coverage',
      },
    },
    blocker: {
      path: '/policies/private-health/blocker/:blockerId',
    },
    loading: {
      path: '/policies/private-health/loading',
    },
    quote: {
      path: '/policies/private-health/quote',
    },
    fetchQuote: {
      path: '/policies/private-health/quote/:quoteId',
    },
    deductible: {
      path: '/policies/private-health/select-deductible',
    },
    addOns: {
      path: '/policies/private-health/select-add-ons',
    },
    bookCall: {
      path: '/policies/private-health/talk-with-us',
    },
    starting: {
      path: '/policies/private-health/starting',
    },
  },
  privateHealthV2: {
    path: '/policies/private-health',
    quote: {
      path: '/policies/private-health/signup/quote',
    },
    fetchQuote: {
      path: '/policies/private-health/quote/:quoteId',
    },
  },
  expat: {
    path: '/policies/expat',
    dependents: {
      path: '/policies/expat/dependents/:mainPolicyId',
    },
    videoDoctorAppointment: {
      path: '/policies/expat/video-doctor-appointment/:policyId',
    },
  },
  delinquency: {
    path: '/policies/delinquent',
  },
  expatSpain: {
    path: '/policies/expat-spain',
    questionnaire: {
      path: '/policies/expat-spain/:groupId?/:questionId?',
    },
    dependents: {
      path: '/policies/expat-spain/dependents/:mainPolicyId',
      questionnaire: {
        path: '/policies/expat-spain/dependents/:mainPolicyId/:groupId?/:questionId?',
      },
    },
  },
  incomeProtection: {
    path: '/policies/income-protection',
    questionnaire: {
      path: '/policies/income-protection/:groupId?/:questionId?',
    },
  },
  builder: {
    path: '/policies/builder',
    preview: {
      path: '/policies/builder/:groupId?/:questionId?',
    },
  },
  bike: {
    path: '/policies/bike',
    questionnaire: {
      path: '/policies/bike/:groupId?/:questionId?',
    },
  },
  expatEu: {
    path: '/policies/expat-eu/:localeId',
    noRegion: {
      path: '/policies/expat-eu',
    },
    questionnaire: {
      path: '/policies/expat-eu/:localeId/:groupId?/:questionId?',
    },
    dependents: {
      path: '/policies/expat-eu/:localeId/dependents/:mainPolicyId',
      questionnaire: {
        path: '/policies/expat-eu/:localeId/dependents/:mainPolicyId/:groupId?/:questionId?',
      },
    },
    videoDoctorAppointment: {
      path: '/policies/expat-eu/:localeId/video-doctor-appointment/:policyId',
    },
  },
  expatLongTerm: {
    path: '/policies/expat-long-term/:localeId',
    noRegion: {
      path: '/policies/expat-long-term',
    },
    questionnaire: {
      path: '/policies/expat-long-term/:localeId/:groupId?/:questionId?',
    },
    dependents: {
      path: '/policies/expat-long-term/:localeId/dependents/:mainPolicyId',
      questionnaire: {
        path: '/policies/expat-long-term/:localeId/dependents/:mainPolicyId/:groupId?/:questionId?',
      },
    },
    videoDoctorAppointment: {
      path: '/policies/expat-long-term/:localeId/video-doctor-appointment/:policyId',
    },
  },
  disabilityV2: {
    path: '/policies/disability',
    questionnaire: {
      path: '/policies/disability/:groupId?/:questionId?',
    },
  },
  seed: {
    path: '/policies/setup',
  },
  pension: {
    path: '/policies/pension',
    questionnaire: {
      path: '/policies/pension/:groupId?/:questionId?',
    },
  },
  dogLiabilitySpain: {
    path: '/policies/dog-liability-spain',
    questionnaire: {
      path: '/policies/dog-liability-spain/:groupId?/:questionId?',
    },
  },
  general: {
    path: '/policies/signup/:verticalId',
    questionnaire: {
      path: '/policies/signup/:verticalId/:groupId?/:questionId?',
    },
    preview: {
      path: '/policies/preview/:verticalId',
      questionnaire: {
        path: '/policies/preview/:verticalId/:groupId?/:questionId?',
      },
    },
  },
};

const claims = {
  path: '/claims',
  liability: {
    path: '/claims/liability/policy/:policyId',
  },
  dental: {
    path: '/claims/dental/policy/:policyId',
  },
  household: {
    path: '/claims/household/policy/:policyId',
  },
  privateHealth: {
    path: '/claims/private-health/policy/:policyId',
  },
  bike: {
    path: '/claims/bike/policy/:policyId',
  },
  create: { path: '/claims/create' },
  detail: {
    path: '/claims/:claimId',
  },
  companyHealth: {
    path: '/claims/company-health/policy/:policyId',
  },
  legal: {
    path: '/claims/legal/policy/:policyId',
    submitClaim: {
      path: '/claims/legal/policy/:policyId/submit-claim/',
      questionnaire: {
        path: '/claims/legal/policy/:policyId/submit-claim/:groupId?/:questionId?',
      },
    },
    // TODO: [KONG] Remove when removing legal claims v1
    bookConsultation: {
      path: '/claims/legal/policy/:policyId/book-consultation',
      questionnaire: {
        path: '/claims/legal/policy/:policyId/book-consultation/:groupId?/:questionId?',
      },
    },
    phoneConsultation: {
      path: '/claims/legal/policy/:policyId/phone-consultation',
    },
    claimsAndConsultations: {
      path: '/claims/legal/policy/:policyId/claims-and-consultations',
      questionnaire: {
        path: '/claims/legal/policy/:policyId/claims-and-consultations/:groupId?/:questionId?',
      },
    },
  },
  expat: {
    path: '/claims/expat/policy/:policyId',
    questionnaire: {
      path: '/claims/expat/policy/:policyId/:groupId?/:questionId?',
    },
  },
  expatEu: {
    path: '/claims/expat-eu/policy/:policyId',
    questionnaire: {
      path: '/claims/expat-eu/policy/:policyId/:groupId?/:questionId?',
    },
  },
  expatLongTerm: {
    path: '/claims/expat-long-term/policy/:policyId',
    questionnaire: {
      path: '/claims/expat-long-term/policy/:policyId/:groupId?/:questionId?',
    },
  },
  expatSpain: {
    path: '/claims/expat-es/policy/:policyId',
    questionnaire: {
      path: '/claims/expat-es/policy/:policyId/:groupId?/:questionId?',
    },
  },
  dogLiability: {
    path: '/claims/dog-liability/policy/:policyId',
  },
  common: {
    path: '/claims/common/policy/:policyId',
    questionnaire: {
      path: '/claims/common/policy/:policyId/:groupId?/:questionId?',
    },
  },
};

const me = {
  path: '/me',
  policies: {
    path: '/me/policies',
    list: {
      path: '/me/policies/all',
    },
    detail: {
      path: '/me/policies/:policyId',
      studentChecklist: {
        path: '/me/policies/:policyId/student-checklist',
      },
    },
    life: {
      beneficiaries: {
        path: '/me/policies/life/:policyId/add-beneficiaries',
        questionnaire: {
          path: '/me/policies/life/:policyId/add-beneficiaries/:groupId?/:questionId?',
        },
        edit: {
          path: '/me/policies/life/:policyId/edit-beneficiaries',
        },
      },
    },
  },
  claims: {
    path: '/me/claims/generic/:policyId',
    confirmationGeneric: {
      path: '/me/claims/confirmation',
    },
    legalClaim: {
      confirmInfo: {
        path: '/me/claims/legal/confirm-info/:policyId',
      },
      describeClaim: {
        path: '/me/claims/legal/describe-claim/:policyId',
      },
      processing: {
        path: '/me/claims/legal/processing/:policyId',
      },
      confirmation: {
        path: '/me/claims/legal/confirmation/:policyId',
      },
    },
    expatClaim: {
      myClaims: {
        path: '/me/claims/expat/:policyId/my-claims',
      },
      claimStatus: {
        path: '/me/claims/expat/:policyId/claim-status/:claimId',
      },
      gettingStarted: {
        path: '/me/claims/expat/:policyId/getting-started',
      },
      selectType: {
        path: '/me/claims/expat/:policyId/select-type',
      },
      uploadDocs: {
        path: '/me/claims/expat/:policyId/upload-docs',
      },
      payoutDetails: {
        path: '/me/claims/expat/:policyId/payout-details',
      },
      processing: {
        path: '/me/claims/expat/:policyId/processing',
      },
      confirmation: {
        path: '/me/claims/expat/:policyId/confirmation',
      },
    },
    myClaims: {
      path: '/me/claims/:policyId/my-claims',
    },
    claimStatus: {
      path: '/me/claims/:policyId/claim-status/:claimId',
    },
    claimSteps: {
      path: '/me/claims/:policyId/making-a-claim',
    },
    claimTypes: {
      path: '/me/claims/:policyId/select-type',
    },
    uploadDocuments: {
      path: '/me/claims/:policyId/upload-docs',
    },
    paymentInfo: {
      path: '/me/claims/:policyId/payment-info',
    },
    processing: {
      path: '/me/claims/:policyId/processing',
    },
    confirmation: {
      path: '/me/claims/:policyId/confirmation',
    },
  },
  brokerMandate: {
    sign: {
      path: '/me/broker-mandate/sign',
    },
  },
  paymentMethods: {
    path: '/me/payment-methods',
    show: {
      path: '/me/payment-methods/:paymentMethodId',
    },
    confirmSetup: {
      path: '/me/payment-methods/confirm-setup',
    },
    add: {
      path: '/me/payment-methods/add',
    },
  },
  personalInfo: {
    path: '/me/personal-info',
  },
  accountSettings: {
    path: '/me/account-settings',
  },
  communicationPreferences: {
    path: '/me/communication-preferences',
  },
  referralPayoutDetails: {
    path: '/me/referral-payout-details',
  },
};
// TODO RV: remove paths and code related to old health recommendation tool
/**
 * @deprecated use insuranceTool routes instead
 */
const healthInsuranceTool = {
  path: '/health-insurance-tool',
  recommendation: {
    path: '/health-insurance-tool/recommendation',
    detail: {
      path: '/health-insurance-tool/recommendation/:recommendationId?',
    },
  },
  questionnaire: { path: '/health-insurance-tool/questionnaire/:questionId' },
  v2: {
    questionnaire: { path: '/health-insurance-tool/questionnaire' },
  },
};

const insuranceTool = {
  path: '/recommendation-tool',
  intro: {
    path: '/recommendation-tool/:insuranceType',
  },
  questionnaire: {
    path: '/recommendation-tool/:insuranceType/questionnaire',
  },
  recommendation: {
    path: '/recommendation-tool/:insuranceType/recommendation/:recommendationId',
  },
};

const healthCardPhoto = {
  path: '/health-card-photo',
};

const paymentScreen = {
  path: '/checkouts',
  confirmSetup: {
    path: '/checkouts/:checkoutId/confirm-setup',
  },
  pay: {
    path: '/checkouts/:checkoutId/pay',
  },
  confirmPayment: {
    // This path is hardcoded in the checkout service codebase, make sure to update it there too
    path: '/checkouts/:checkoutId/confirm-payment',
  },
};

const paymentScreenRegionalised = {
  path: '/:localeId/checkouts',
  confirmSetup: {
    path: '/:localeId/checkouts/:checkoutId/confirm-setup',
  },
  pay: {
    path: '/:localeId/checkouts/:checkoutId/pay',
  },
  confirmPayment: {
    // This path is hardcoded in the checkout service codebase, make sure to update it there too
    path: '/:localeId/checkouts/:checkoutId/confirm-payment',
  },
};

const routes = {
  base,
  policies,
  claims,
  me,
  checkup,
  account,
  paymentScreen,
  paymentScreenRegionalised,
  healthInsuranceTool,
  insuranceTool,
  healthCardPhoto,
  subscriptions,
};

export const associatedPathForLiabilityRoutes: {
  [k in LiabilityPageId]: string;
} = {
  inclusiveMembers: policies.liability.quote.path,
  numberOfPreviousClaims:
    policies.liability.questionnaire.numberOfPreviousClaims.path,
  blocker: policies.liability.blocker.path,
  account: policies.liability.account.path,
  email: policies.liability.email.path,
  name: policies.liability.questionnaire.name.path,
  dateOfBirth: policies.liability.questionnaire.dateOfBirth.path,
  gender: policies.liability.questionnaire.gender.path,
  currentlyLivingInGermany:
    policies.liability.questionnaire.currentlyLivingInGermany.path,
  relocationDate: policies.liability.questionnaire.relocationDate.path,
  address: policies.liability.questionnaire.address.path,
  startDate: policies.liability.questionnaire.startDate.path,
  quote: policies.liability.review.path,
  checkoutInfo: policies.liability.checkout.path,
};

export default routes;
