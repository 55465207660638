import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Button, DownloadIcon } from '@popsure/dirty-swan';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { StatusBadge } from 'features/billingHistory/components/StatusBadge';
import { useAsyncReceiptGeneration } from 'features/billingHistory/hooks';
import { Billing } from 'features/billingHistory/models';
import {
  getPaymentMethodIcon,
  mapBillingStatus,
  mapBillingType,
  mapStatusBadgeColors,
  shouldGenerateReceipt,
} from 'features/billingHistory/utils';
import { TFunction } from 'shared/i18n';
import { formatCardLastDigits } from 'shared/util/cardFormatters';

import styles from './styles.module.scss';

export interface Props {
  isLastBilling: boolean;
  billingInfo: Billing;
  setSelectedBillingId: (id: string) => void;
  t: TFunction;
}

// TODO: [KONG] Fix mobile responsiveness
export const BillingItem = ({
  isLastBilling,
  billingInfo,
  setSelectedBillingId,
  t,
}: Props) => {
  const { generatingReceipt, onDownloadClick } =
    useAsyncReceiptGeneration(billingInfo);

  const nextTryAtText = `Next try on ${dayjs(
    billingInfo.latestChargeDetails.nextRetryAt
  ).format('D MMMM YYYY')}`;

  return (
    <div
      role="button"
      tabIndex={0}
      className={classnames(
        `w100 py16 px16 ds-interactive-component ${styles.container}`,
        {
          [styles.bottomBorder]: !isLastBilling,
        }
      )}
      key={billingInfo.stripeId}
      onClick={() => setSelectedBillingId(billingInfo.stripeId)}
      onKeyUp={(ev) => ev.preventDefault()}
      data-testid="billing-item"
    >
      <div
        className={classnames(
          `mr24 ${styles.leftColumn} ${styles.noMarginMobile}`,
          {
            [styles.minHeightMobile]: true,
          }
        )}
      >
        <h4 className="p-h4 tc-grey-700">
          {mapBillingType[billingInfo.paymentType]}
        </h4>
        <div
          className={`p-p--small tc-grey-500 ${styles.topMarginMobile} ${styles.paymentDate}`}
        >
          {dayjs(billingInfo.latestChargeDetails.paidAt).format('D MMMM YYYY')}
        </div>
        {billingInfo.latestChargeDetails.nextRetryAt && (
          <p
            className={`p-p--small fw-bold tc-grey-500 mr16 ${styles.showOnMobile}`}
          >
            {nextTryAtText}
          </p>
        )}
      </div>

      <div
        className={classnames(`${styles.rightColumn}`, {
          [styles.minHeightMobile]: true,
        })}
      >
        {billingInfo.latestChargeDetails.nextRetryAt && (
          <p
            className={`p-p--small fw-bold tc-grey-500 mr16 ${styles.hideOnMobile}`}
          >
            {nextTryAtText}
          </p>
        )}
        {billingInfo.status !== 'SUCCEEDED' && (
          <div
            className={`mr32 ${styles.noMarginMobile} ${styles.topMarginMobile}`}
          >
            <StatusBadge
              title={mapBillingStatus(t)[billingInfo.status]}
              badgeColor={mapStatusBadgeColors[billingInfo.status]}
            />
          </div>
        )}
        <div
          className={`p-h4 mr24 tc-grey-700 ${styles.noMarginMobile} ${styles.fixedWidthAmount}`}
        >
          {englishFormattedEuroCurrency(billingInfo.paidAmount)}
        </div>

        <img
          className={`mr16 bg-grey-200 ${styles.brandIcon} ${styles.hideOnMobile}`}
          src={getPaymentMethodIcon(billingInfo)}
          alt="payment method logo"
        />

        <div
          className={`p-p--small tc-grey-600 ${styles.hideOnMobile} ${styles.paypalEmail}`}
        >
          {billingInfo.paymentMethodDetails.type === 'paypal'
            ? billingInfo.paymentMethodDetails.identifier
            : formatCardLastDigits(billingInfo.paymentMethodDetails.lastDigits)}
        </div>

        {/* empty div to maintain alignment */}
        {!shouldGenerateReceipt(billingInfo) && (
          <div
            className={`d-flex jc-center ai-center ml32 mr8 ${styles.receiptSpinner}`}
          />
        )}

        {shouldGenerateReceipt(billingInfo) && (
          <Button
            hideLabel
            loading={Boolean(generatingReceipt)}
            variant="textColor"
            leftIcon={
              <DownloadIcon
                color={
                  billingInfo.receipt?.downloadUrl ? 'purple-500' : 'grey-500'
                }
                size={20}
                noMargin
              />
            }
            onClick={onDownloadClick}
            className={`ml24 ${styles.hideOnMobile}`}
          >
            Download receipt
          </Button>
        )}
      </div>
    </div>
  );
};
