import { useQuery } from '@tanstack/react-query';
import LoadingSpinner from 'components/loadingSpinner';
import {
  getCheckoutQuestionnaire,
  getCheckoutQuote,
} from 'features/paymentScreen/paymentScreen.api';
import { CheckoutInfo } from 'features/paymentScreen/paymentScreen.models';
import { ReferrerCodeVerify } from 'features/referralEngine';
import api from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';
import { formatCurrencyWithSpace } from 'shared/util/formatCurrencyWithSpace';

import { Cell } from '../Cell/Cell.view';
import styles from './style.module.scss';
import { getPolicyDetails } from './utils';

export type PolicyDetailsProps = {
  checkoutInfo: CheckoutInfo;
};

export const getQuestionnaire = async (
  questionnaireId?: string
): Promise<Record<string, unknown> | undefined> => {
  if (!questionnaireId) return undefined;
  const { data } = await getCheckoutQuestionnaire(api.network, questionnaireId);
  return data;
};

export const getQuote = async (
  quoteId?: string
): Promise<Record<string, unknown> | undefined> => {
  if (!quoteId) return undefined;
  const { data } = await getCheckoutQuote(api.network, quoteId);
  return data;
};

export const PolicyDetails = ({ checkoutInfo }: PolicyDetailsProps) => {
  const { t } = useSafeTranslation();

  const { isLoading: isLoadingQuestionnaire, data: questionnaire } = useQuery({
    queryKey: ['genericCheckoutQuestionnaire'],
    queryFn: () => getQuestionnaire(checkoutInfo?.metaData?.questionnaireId),
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const { isLoading: isLoadingQuote, data: quote } = useQuery({
    queryKey: ['genericCheckoutQuote'],
    queryFn: () => getQuote(checkoutInfo?.metaData?.quoteId),
    enabled: true,
    refetchOnWindowFocus: false,
  });

  if (isLoadingQuestionnaire || isLoadingQuote) {
    return <LoadingSpinner />;
  }

  const policyDetailsConfig = getPolicyDetails(
    checkoutInfo,
    t,
    questionnaire,
    quote
  );

  return (
    <div className="p24 br8 bg-white bs-xs">
      <div className="p-h2 mb16">
        {t('paymentScreen.policyDetails.title', 'Policy details')}
      </div>

      {policyDetailsConfig.policyDetails.map((cell) => (
        <Cell
          title={cell.title}
          value={cell.value}
          noBreak={cell.noBreak}
          additionalInfo={cell.additionalInfo}
        />
      ))}

      <div>
        {(policyDetailsConfig.otherSections || []).map((section) => (
          <>
            <div className="p-h3 mt24">{section.title}</div>
            {section.cells.map((cell) => (
              <Cell
                title={cell.title}
                value={cell.value}
                noBreak={cell.noBreak}
                additionalInfo={cell.additionalInfo}
              />
            ))}
          </>
        ))}
      </div>

      <div className={`bg-grey-200 mt32 mb32 ${styles.divider}`} />

      <div className="d-flex">
        <div className="p-h3">
          {t('paymentScreen.policyDetails.total', 'Total per month')}
        </div>
        <div className="p-h3 ml-auto">
          {formatCurrencyWithSpace(policyDetailsConfig.totalPrice, 2)}
        </div>
      </div>

      <ReferrerCodeVerify />
    </div>
  );
};
