import { illustrations } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import styles from './CoverageDetails.module.scss';
import { CoverageModal, CoverageTableDataEntry } from './CoverageModal';

type CoverageDetailsProps = {
  buttonCaption?: string;
  coverageTableData: CoverageTableDataEntry[];
  coverageTableHeaders?: React.ReactNode[];
  grey?: boolean;
  small?: boolean;
  useAlternateDefaultCaption?: boolean;
};

export const CoverageDetails = ({
  buttonCaption,
  coverageTableData,
  coverageTableHeaders,
  grey = false,
  small = false,
  useAlternateDefaultCaption = false,
}: CoverageDetailsProps) => {
  const { t } = useSafeTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <CoverageModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        firstItemOpenByDefault
        coverageTableData={coverageTableData}
        coverageTableHeaders={coverageTableHeaders}
      />
      <button
        className={`d-inline-flex c-gap8 ai-center p-a fw-bold bg-transparent ${
          styles.coverageDetails
        } ${grey ? 'tc-grey-500' : ''} ${small ? 'p-p--small' : ''}`}
        onClick={() => setModalIsOpen(true)}
        type="button"
      >
        <img
          className={classNames({
            [styles.iconGrey]: grey,
            [styles.smallIcon]: small,
          })}
          src={illustrations.document}
          alt="document icon"
        />
        {buttonCaption || useAlternateDefaultCaption
          ? t(
              'components.coveragedetails.buttoncaption.default2',
              'Coverage details'
            )
          : t('components.coveragedetails.buttoncaption.default', 'Coverage')}
      </button>
    </>
  );
};
