import dayjs from 'dayjs';

/**
 * isNotifiedUniversity checks the date when the letter of acceptance was created,
 * and returns true when three or more days have passed.
 * @param letterOfAcceptanceCreatedAt string The date when the letter of acceptance was created
 * @param now dayjs.Dayjs The current date
 *
 * @returns boolean
 */

export const isNotifiedUniversity = (
  letterOfAcceptanceCreatedAt: string | null,
  now: dayjs.Dayjs = dayjs()
): boolean => {
  const date = dayjs(letterOfAcceptanceCreatedAt);
  const isValidDate = date.isValid();

  if (!isValidDate) return false;

  return now.diff(date, 'day') >= 3;
};
