import { RequestAction } from 'constants/actions';
import { APIResponseError } from 'models/error';
import { Reducer } from 'redux';

interface RequestState {
  [id: string]: {
    inProcess: boolean;
    error?: APIResponseError;
    skipped?: boolean;
  };
}

export const defaultState: RequestState = {};

const request: Reducer<RequestState, RequestAction> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case 'REQUEST_IN_PROCESS':
      return {
        ...state,
        [action.requestType]: {
          inProcess: action.inProcess,
          error: undefined,
          skipped: undefined,
        },
      };
    case 'REQUEST_ERRORED':
      return {
        ...state,
        [action.requestType]: {
          inProcess: false,
          error: action.error,
          skipped: undefined,
        },
      };
    case 'REQUEST_ERROR_FLUSH':
      return {
        ...state,
        [action.requestType]: {
          ...state[action.requestType],
          error: undefined,
          skipped: undefined,
        },
      };
    case 'REQUEST_SKIPPED':
      return {
        ...state,
        [action.requestType]: {
          inProcess: false,
          error: undefined,
          skipped: true,
        },
      };
    default:
      return state;
  }
};

export default request;
