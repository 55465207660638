import { BottomOrRegularModal } from '@popsure/dirty-swan';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { getTitleMapping } from 'models/insurances/types/mapping';
import { Trans, useSafeTranslation } from 'shared/i18n';

import { ViewProps } from '../../../cancelModal/types';
import styles from '../style.module.scss';

export const CancelAutomatedModal = ({
  type,
  activeUntil,
  onCloseModal,
  onCancelPolicy,
  isCancelling,
  cancellingError,
  isOpen,
}: ViewProps) => {
  const { t } = useSafeTranslation();

  const policyTitle = getTitleMapping(t)[type];

  const handleCancelPolicy = () => {
    onCancelPolicy();
  };

  return (
    <BottomOrRegularModal
      title={t(
        'myPolicies.cancelModal.automatedCancellation.title',
        'Cancel policy'
      )}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <div
        className={`mt24 ${styles.container}`}
        data-testid="cancel-modal-auto"
      >
        <p>
          {t('myPolicies.cancelModal.automatedCancellation.description', {
            defaultValue:
              'Are you sure you wish to cancel your {{policyTitle}} Insurance policy?',
            policyTitle,
          })}
        </p>
        {activeUntil && (
          <Trans i18nKey="myPolicies.cancelModal.automatedCancellation.activeUntil.description">
            <p className="p-notice--warning mt24 p-p">
              The cancellation will be effective at the end of your next billing
              period on{' '}
              <b className="fw-bold">
                {{ endDate: dayjs(activeUntil).format('DD MMM YYYY') }}.
              </b>
            </p>
          </Trans>
        )}
        <div className={`d-flex f-wrap mt24 ${styles['button-container']}`}>
          <button
            className={classNames(`p-btn--primary wmn3`, {
              'p-btn--loading': isCancelling,
            })}
            disabled={isCancelling}
            onClick={handleCancelPolicy}
            type="button"
          >
            {t(
              'myPolicies.cancelModal.automatedCancellation.cancelButton.caption',
              'Cancel Policy'
            )}
          </button>
          <button
            className={`p-btn--secondary wmn2 ${styles['secondary-button']}`}
            onClick={() => onCloseModal()}
            type="button"
          >
            {t(
              'myPolicies.cancelModal.automatedCancellation.closeButton.caption',
              'Close'
            )}
          </button>
        </div>
        {cancellingError && (
          <p className="p-notice--danger p-p mt24">{cancellingError}</p>
        )}
      </div>
    </BottomOrRegularModal>
  );
};
