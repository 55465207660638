import { computePensionReturn } from '@getpopsure/pension-insurance-calculator';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import * as Sentry from '@sentry/react';
import {
  DEFAULT_MONTHLY_PAYMENT,
  DEFAULT_PENSION_AGE,
  FIXED_MONTHLY_FEE,
  YEARLY_FEE_PERCENTAGE,
  YEARLY_YIELD_PERCENTAGE,
} from 'features/pension/constants';
import { CalculatorError, Pension, Quote } from 'features/pension/models';
import { useState } from 'react';

import { CalculatorView } from './Calculator.view';
import { validateCalculatorInput } from './validation';

export const Calculator = ({
  onSubmitValue,
  value,
}: QuestionnaireFormProps<Pension, Quote>) => {
  const [quote, setQuote] = useState<Quote>({
    age: (value as Quote)?.age ?? 0,
    initialAmount: (value as Quote)?.initialAmount ?? 0,
    monthlyPayment: (value as Quote)?.monthlyPayment ?? DEFAULT_MONTHLY_PAYMENT,
  });
  const [errorType, setErrorType] = useState<CalculatorError | null>(null);

  const { age, initialAmount, monthlyPayment } = quote;
  const canCalculatePension = age && monthlyPayment;

  const handlePensionCalculation = () => {
    if (!canCalculatePension) {
      return '€ —';
    }

    const computedPensionReturn = computePensionReturn({
      duration: { years: DEFAULT_PENSION_AGE - age },
      initialAmount: initialAmount ?? 0,
      monthlyPayment,
      fixedMonthlyFee: FIXED_MONTHLY_FEE,
      yearlyFeePercentage: YEARLY_FEE_PERCENTAGE,
      yearlyYieldPercentage: YEARLY_YIELD_PERCENTAGE,
    });

    if (!computedPensionReturn.ok) {
      Sentry.captureException(computedPensionReturn.error, {
        tags: {
          feature: 'SIGNUP',
          vertical: 'PENSION',
        },
        extra: {
          description:
            'Quote screen error: no computed pension returned pension-insurance-calculator',
        },
      });
      setErrorType('SUBMISSION_ERROR');
      return null;
    }

    return englishFormattedEuroCurrency(
      Math.floor(computedPensionReturn.value),
      true
    );
  };

  const calculatedPension = handlePensionCalculation();

  const handleSubmitQuote = () => {
    if (!canCalculatePension) {
      setErrorType('MISSING_VALUES');
      return;
    }

    const validationError = validateCalculatorInput(age, initialAmount);

    if (validationError) {
      setErrorType(validationError);
      return;
    }

    onSubmitValue({ ...quote });
  };

  return (
    <CalculatorView
      setQuote={setQuote}
      setErrorType={setErrorType}
      age={age}
      initialAmount={initialAmount}
      monthlyPayment={monthlyPayment}
      errorType={errorType}
      calculatedPension={calculatedPension}
      handleSubmitQuote={handleSubmitQuote}
    />
  );
};
