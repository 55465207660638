import { TFunction } from '@getpopsure/i18n-react';
import { Rule } from '@getpopsure/qnr-framework';
import { InsuranceTypes } from 'models/insurances/types';

import type { ClaimQuestionnaire } from '../CommonClaims';
import type { CommonClaimAnswers } from './models';
import {
  getPetHealthRules,
  getTranslatedPetHealthQuestionnaire,
} from './petHealth/petHealthConfig';

type TranslatedQuestionnaireProps = {
  t: TFunction;
  insuranceType: InsuranceTypes;
};

export const getTranslatedQuestionnaire = (
  args: TranslatedQuestionnaireProps
): ClaimQuestionnaire => {
  switch (args.insuranceType) {
    case 'PET_HEALTH':
      return getTranslatedPetHealthQuestionnaire(args.t);
    default:
      return [];
  }
};

export const rules = (
  insuranceType: InsuranceTypes
): Rule<CommonClaimAnswers>[] => {
  switch (insuranceType) {
    case 'PET_HEALTH':
      return getPetHealthRules();
    default:
      return [];
  }
};
