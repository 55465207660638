import { Button, ImagePictureIcon } from '@popsure/dirty-swan';
import {
  approveHealthCardPhoto,
  fetchHealthCard,
  fetchPhotoUploadToken,
  uploadHealthCardPhoto,
} from 'actions/healthCard';
import { InlineButton } from 'features/studentChecklist/components';
import { StepProps } from 'features/studentChecklist/models';
import {
  FileExtension,
  HealthCard,
  HealthCardDispatch,
} from 'models/healthCard';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line
import { getHealthCardDetail, getHealthCardId } from 'selectors/healthCard';
import { getFileType } from 'shared/util/getFileType';

import PhotoUploadModal from '../photoUploadModal';
import { isValidPhoto } from '../util/isValidPhoto';
// eslint-disable-next-line
import { usePollHealthCardDetails } from '../util/usePollHealthCardDetails';

const PhotoUpload = ({ completed, submittedChecklist }: StepProps) => {
  const dispatch = useDispatch<HealthCardDispatch>();
  const { policyId } = useParams<{ policyId: string }>();
  const healthCardId = useSelector(getHealthCardId(policyId));
  const healthCardDetails = useSelector(getHealthCardDetail(healthCardId));
  const [modal, setModal] = useState<boolean>(false);
  const {
    photo,
    setPhoto,
    error,
    setError,
    startPolling,
    stopPolling,
    loading,
    qrToken,
    setQRToken,
  } = usePollHealthCardDetails(healthCardId);

  if (!healthCardId)
    throw new Error(
      '[HEALTH_CARD_CHECKLIST] trying to upload a photo to a non existing health card'
    );

  const refreshHealthCard = async () => dispatch(fetchHealthCard(healthCardId));

  const toggleModal = async () => {
    setError(null);
    stopPolling();
    await setModal((modalState) => !modalState);
    setPhoto(healthCardDetails?.approvedPhoto || null);
  };

  const onFileSelect = async (files: File[]) => {
    const fileToUpload = files[0];

    setError(null);

    try {
      const isValid = await isValidPhoto(fileToUpload);

      if (!isValid) {
        setError(
          'Error: the photo should be at least 300px x 400px, and less than 15MB'
        );
        return;
      }

      const token = await dispatch(fetchPhotoUploadToken(healthCardId));

      if (!token) return null;

      await dispatch(
        uploadHealthCardPhoto({
          id: healthCardId,
          file: fileToUpload,
          fileExtension: getFileType(fileToUpload.name) as FileExtension,
          token,
        })
      );

      const data = await dispatch<Promise<HealthCard | void>>(
        fetchHealthCard(healthCardId)
      );

      if (data) setPhoto(data.temporaryPhoto);
    } catch {
      setError('Error uploading the photo, try again.');
    } finally {
      stopPolling();
      setQRToken(null);
    }
  };

  const handleSubmit = async () => {
    if (!photo) return;

    await dispatch(approveHealthCardPhoto(photo.id));
    await refreshHealthCard();
    setModal(false);
  };

  const handleDeletePhoto = async () => {
    setPhoto(null);
    setQRToken(null);
    await refreshHealthCard();
  };

  const toggleDisplayQR = async () => {
    setError(null);
    const token = await dispatch(fetchPhotoUploadToken(healthCardId));
    if (token) {
      setQRToken(token);
      startPolling();
    }
  };

  if (submittedChecklist) return null;

  return (
    <>
      {completed ? (
        <InlineButton onClick={toggleModal}>Upload new photo</InlineButton>
      ) : (
        <Button
          data-testid="photo-upload-button"
          className="w100 wmx3 mt8"
          onClick={toggleModal}
          leftIcon={<ImagePictureIcon />}
        >
          Upload photo
        </Button>
      )}
      {modal &&
        createPortal(
          <PhotoUploadModal
            onDeletePhoto={handleDeletePhoto}
            onSubmit={handleSubmit}
            onSelectPhoto={onFileSelect}
            currentPhoto={photo}
            approvedPhoto={healthCardDetails?.approvedPhoto || null}
            onClose={toggleModal}
            toggleDisplayQR={toggleDisplayQR}
            error={error}
            qrToken={qrToken}
            loading={loading}
          />,
          document.body
        )}
    </>
  );
};

export default PhotoUpload;
