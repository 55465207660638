import { Gender } from '@getpopsure/public-models';
import { createAccountV2 } from 'actions/account';
import RadioForm from 'components/questionnaireForms/RadioForm';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { UserRegistrationInfo } from 'models/genericQuestionnaire';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { UserNewWithRequiredInfo } from 'models/user';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { z } from 'zod';

export const GenderQuestion = ({
  onSubmitValue,
  questionnaireAnswers,
}: CustomComponentProps<UserRegistrationInfo>) => {
  const { loading, error } = useRequestStatus('CREATE_USER');
  const dispatch = useDispatch();
  const { t } = useSafeTranslation();

  const handleSubmit = async (gender: Gender) => {
    const deserializer = z.object({
      firstName: z.string(),
      lastName: z.string(),
      email: z.string().email(),
      dateOfBirth: z.string(),
      gender: z.enum(['MALE', 'FEMALE', 'OTHER']),
    });

    const validationResult = deserializer.safeParse({
      firstName: questionnaireAnswers.name?.firstName,
      lastName: questionnaireAnswers.name?.lastName,
      email: questionnaireAnswers.email,
      dateOfBirth: questionnaireAnswers.dateOfBirth,
      gender,
    });

    if (!validationResult.success) {
      throw new Error(
        `[SIGNUP] UserNewWithRequiredInfo validation errors:${validationResult.error.toString()} `
      );
    }

    const user: UserNewWithRequiredInfo = validationResult.data;

    const onSuccess = () => {
      onSubmitValue(gender);
    };

    dispatch(createAccountV2(user, onSuccess));
  };

  return (
    <RadioForm
      question={t(
        'signup.qnr.gender.question',
        'Which gender do you identify with?'
      )}
      mapValue={{
        MALE: t('signup.qnr.gender.question.male', 'Male'),
        FEMALE: t('signup.qnr.gender.question.female', 'Female'),
        OTHER: t(
          'signup.qnr.gender.question.nonconforming',
          'Gender Nonconforming'
        ),
      }}
      loading={loading}
      featureName="Signup"
      onSubmitValue={handleSubmit}
      error={error?.message}
    />
  );
};
