import { TFunction } from '@getpopsure/i18n-react';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import lockIcon from 'features/liabilityClaims/icons/lock.svg';
import magnifyingGlassIcon from 'features/liabilityClaims/icons/magnifying-glass.svg';
import phoneIcon from 'features/liabilityClaims/icons/phone.svg';
import { generatePath, useHistory } from 'react-router';

import {
  GettingStartedCard,
  Props as GettingStartedCardProps,
} from './components/GettingStartedCard';
import styles from './styles.module.scss';

interface Props {
  handleGetStarted: () => void;
  t: TFunction;
  policyId: string;
}

const cardData = (t: TFunction): GettingStartedCardProps[] => [
  {
    title: t(
      'claims.liability.gettingstarted.cardinfo.1.title',
      'Don’t pay the third party out of pocket'
    ),
    description: t(
      'claims.liability.gettingstarted.cardinfo.1.description',
      'We advise you not to pay money to the third party until we examine the incident first. It may turn out that the current value of object is much lower than you think or you might not be liable at all.'
    ),
    icon: {
      src: magnifyingGlassIcon,
      alt: t(
        'claims.liability.gettingstarted.cardinfo.1.iconalt',
        'purple magnifying glass'
      ),
    },
  },
  {
    title: t(
      'claims.liability.gettingstarted.cardinfo.2.title',
      'Record the damage as carefully as possible'
    ),
    description: t(
      'claims.liability.gettingstarted.cardinfo.2.description',
      'We recommend you record the damage thoroughly. Take pictures from different angles, write down what happened, and include the contact details of the third party.'
    ),
    icon: {
      src: phoneIcon,
      alt: t(
        'claims.liability.gettingstarted.cardinfo.2.iconalt',
        'shiny purple phone'
      ),
    },
  },
  {
    title: t(
      'claims.liability.gettingstarted.cardinfo.3.title',
      'What isn’t covered?'
    ),
    description: t(
      'claims.liability.gettingstarted.cardinfo.3.description',
      'Keep in mind that personal liability insurance does not cover damage to your own property, locksmith fees, and fines / penalties.'
    ),
    icon: {
      src: lockIcon,
      alt: t(
        'claims.liability.gettingstarted.cardinfo.3.iconalt',
        'purple broken glass'
      ),
    },
  },
];

export const LiabilityClaimsGettingStartedView = ({
  handleGetStarted,
  t,
  policyId,
}: Props) => {
  const { location } = useHistory<{ from?: string }>();

  return (
    <div className={`mb80 ${styles.container}`}>
      <div className="p-body">
        <NavButton
          title={t('claims.liability.gettingstarted.navbutton.title', 'Back')}
          path={
            location.state?.from !== 'policy'
              ? routes.claims.create.path
              : generatePath(routes.me.policies.detail.path, { policyId })
          }
        />
        <h1 className="p-h1">
          {t('claims.liability.gettingstarted.title', 'Getting started')}
        </h1>
        <p className="p-p mt8 mb24">
          {t(
            'claims.liability.gettingstarted.description',
            'Here are a few valuable tips to consider when making a claim:'
          )}
        </p>
        {cardData(t).map(({ title, description, icon }) => (
          <GettingStartedCard
            className="mt16"
            title={title}
            icon={icon}
            description={description}
            key={title}
          />
        ))}
        <button
          type="button"
          onClick={handleGetStarted}
          className={`p-btn--primary mt32 wmn3 ${styles.button}`}
          data-cy="policies-liability-claim-button-get-started"
        >
          {t('claims.liability.gettingstarted.cta', 'Get Started')}
        </button>
      </div>
    </div>
  );
};
