import * as Sentry from '@sentry/react';
import ErrorView from 'components/error';
import { useWindowSize } from 'hooks/useWindowSize';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { isMobileApp } from 'shared/util/isMobileApp';

export const CommpanyHealthClaims = () => {
  const size = useWindowSize();
  const params = useParams() as { policyId?: string };

  if (!params.policyId) {
    Sentry.captureException(
      `[Company health claims] Trying to initiate a claim without a policy id.`
    );
    return <ErrorView error="Missing policy id" />;
  }

  return (
    <>
      <Helmet>
        <script async src="https://tally.so/widgets/embed.js" />
      </Helmet>
      <iframe
        width={size.width ?? '100%'}
        height={size.height ?? '100%'}
        className={isMobileApp ? 'pt32' : ''}
        data-tally-src={`https://tally.so/r/wbqQq6?transparentBackground=1&policyId=${params.policyId}`}
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
        title="Submit a claim"
      />
    </>
  );
};
