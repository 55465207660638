import dayjs from 'dayjs';
import Cell from 'features/checkout/components/Cell';
import { CheckoutPolicyHolder } from 'features/checkout/models';
import { inlinedFormattedAddress } from 'models/address';
import { useSafeTranslation } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';

import styles from './style.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

const PolicyHolder = ({
  address,
  name,
  email,
  dateOfBirth,
}: Partial<CheckoutPolicyHolder>) => {
  const { t } = useSafeTranslation();

  if (!address && !name && !email && !dateOfBirth) {
    return <></>;
  }

  return (
    <div className={`mb16 ${styles.container}`}>
      <div className="p-h2 mb16">
        {t('components.policyholder.title', 'Policy holder')}
      </div>
      {name && (
        <Cell
          title={t('components.policyholder.name', 'Name')}
          value={capitalizeName(name)}
        />
      )}
      {dateOfBirth && (
        <Cell
          title={t('components.policyholder.dateofbirth', 'Date of birth')}
          value={dayjs(dateOfBirth).format(DATE_FORMAT)}
        />
      )}
      {address && (
        <Cell
          title={t('components.policyholder.address', 'Address')}
          value={inlinedFormattedAddress(address)}
        />
      )}
      {email && (
        <Cell
          title={t('components.policyholder.email', 'Email')}
          value={email}
        />
      )}
    </div>
  );
};

export default PolicyHolder;
