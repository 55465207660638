import { Policy } from 'models/policies';

import { CancelPolicyButton, ContactUsButton } from '../../components/buttons';
import { PolicyDetailsButton } from '../../sections/PolicyDetails/PolicyDetails.models';
import { ExpatSpainAddDependentsButton } from './buttons/ExpatSpainAddDependentsButton';

export type ExpatSpainModalType = 'INCOMING_ES_CLAIM';

export const getExpatSpainButtons = (policy: Policy): PolicyDetailsButton[] => {
  let buttons: PolicyDetailsButton[] = [
    { id: 'CONTACT_US', component: ContactUsButton },
  ];

  if (policy.status !== 'CANCELED') {
    buttons = [
      ...buttons,
      { id: 'CANCEL_POLICY', component: CancelPolicyButton },
    ];
  }

  if (policy.status === 'ACTIVE') {
    const activePolicyButtons = [
      {
        id: 'ADD_DEPENDENTS',
        component: ExpatSpainAddDependentsButton,
      },
    ];

    buttons = [...buttons, ...activePolicyButtons];
  }

  return buttons;
};
