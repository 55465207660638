import classNames from 'classnames';
import routes from 'constants/routes';
import PaymentMethodPageHeader from 'features/paymentMethods/components/PaymentMethodPageHeader/PaymentMethodPageHeader.view';
import { PaymentMethodSelector } from 'features/paymentMethodSelector/containers';
import { PaymentOption } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import { FormEvent } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

export const AddPaymentMethodView = ({
  handleSubmit,
  setPaymentOption,
  isSubmitting,
  isReadyForSubmit,
  errorMessage,
}: {
  handleSubmit: () => Promise<void>;
  setPaymentOption: (paymentOption: PaymentOption) => void;
  isSubmitting: boolean;
  isReadyForSubmit: boolean;
  errorMessage?: string;
}) => {
  const { t } = useSafeTranslation();

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await handleSubmit();
  };

  return (
    <div className="p-body">
      <form className="mb80 py24 br8 bs-xs" onSubmit={onSubmit}>
        <PaymentMethodPageHeader
          title={t('paymentMethods.addNew.title', 'Add a payment method')}
          backText={t('paymentMethods.addNew.backButton.text', 'Billing')}
          backUrl={routes.me.paymentMethods.path}
        />
        <div className="p24 mt24 br8 bg-white bs-xs">
          <PaymentMethodSelector
            hideDropdown={true}
            handlePaymentOptionUpdate={setPaymentOption}
            onStripePaymentMethodTypeChange={() => {}}
          />
        </div>
        <AnimateHeight duration={300} height={errorMessage ? 'auto' : 0}>
          <div className="p-notice p-notice--danger mt16">{errorMessage}</div>
        </AnimateHeight>
        <button
          type="submit"
          className={classNames('p-btn--primary mt24 wmn3 mb80', {
            'p-btn--loading': isSubmitting,
          })}
          disabled={!isReadyForSubmit}
          data-cy="payment-method-submit-button"
        >
          {t('paymentMethods.addNew.confirmButton.text', 'Confirm')}
        </button>
      </form>
    </div>
  );
};
