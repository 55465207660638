import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';

export const formatNumber = (
  n: number,
  shorten = false,
  nFractionDigits = 0
): string => {
  let retVal = n;

  if (shorten && n >= 1000) {
    retVal = n / 1000;

    return `${englishFormattedEuroCurrency(retVal, nFractionDigits)}k`;
  }
  return englishFormattedEuroCurrency(retVal, nFractionDigits);
};
