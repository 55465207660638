import type {
  BikeQuestionnaire,
  QuestionnaireAnswers,
} from 'features/recommendationTool/models/bike';
import { sixMonthsOldOrNot } from 'features/recommendationTool/models/bike';
import { getAccountInfo } from 'features/recommendationTool/questionnaires/account';

import { createProcessingQuestion, submitQuestionId } from '../processing';

export const newOrSecondHand = {
  new: 'new',
  secondhand: 'secondhand',
} as const;

export const newOrSecondHandLabels: Record<
  keyof typeof newOrSecondHand,
  string
> = {
  new: 'Yes',
  secondhand: "No, it's a second-hand bike",
};

export const normalOrEbike = {
  normal: 'normal',
  ebike: 'ebike',
} as const;

export const normalOrEbikeLabels: Record<keyof typeof normalOrEbike, string> = {
  normal: 'Bicycle',
  ebike: 'E-Bike',
};

const bikeQuestionnaire: BikeQuestionnaire = {
  bikePurchasePrice: {
    required: (answers) => !answers.bikePurchasePrice,
    type: 'CURRENCY',
    props: {
      question: 'How much did you purchase your bike for?',
      placeholder: 'Purchase Price',
    },
    groupId: 'personalInfo',
    retrieveAnswerObject: (
      bikePurchasePrice: QuestionnaireAnswers['bikePurchasePrice']
    ) => ({
      bikePurchasePrice,
    }),
    retrieveNextPageId: () => 'bikePurchaseDate',
  },
  bikePurchaseDate: {
    required: (answers) => !answers.bikePurchaseDate,
    type: 'RADIO',
    props: {
      question: 'When did you buy it?',
      mapValue: sixMonthsOldOrNot,
    },
    retrieveAnswerObject: (
      bikePurchaseDate: QuestionnaireAnswers['bikePurchaseDate']
    ) => ({ bikePurchaseDate }),
    retrieveNextPageId: () => 'isVintageBike',
    groupId: 'personalInfo',
  },
  isVintageBike: {
    required: (answers) => !answers.isVintageBike,
    type: 'RADIO',
    props: {
      question: 'Is it a new bike?',
      mapValue: newOrSecondHandLabels,
    },
    retrieveAnswerObject: (isVintageBike: string) => ({
      isVintageBike: isVintageBike === newOrSecondHand.secondhand,
    }),
    transformValue: (isVintageBike?: string) => {
      if (isVintageBike === undefined) {
        return undefined;
      }
      return isVintageBike ? newOrSecondHand.secondhand : newOrSecondHand.new;
    },
    retrieveNextPageId: () => 'hasCarbonFrameBike',
    groupId: 'personalInfo',
  },
  hasCarbonFrameBike: {
    required: (answers) => !answers.hasCarbonFrameBike,
    type: 'BOOLEAN',
    props: {
      question: 'Does your bike have a carbon frame?',
    },
    retrieveAnswerObject: (
      hasCarbonFrameBike: QuestionnaireAnswers['hasCarbonFrameBike']
    ) => ({
      hasCarbonFrameBike,
    }),
    retrieveNextPageId: () => 'isElectricBike',
    groupId: 'personalInfo',
  },
  isElectricBike: {
    required: (answers) => !answers.isElectricBike,
    type: 'RADIO',
    props: {
      question: 'What kind of bike is it?',
      mapValue: normalOrEbikeLabels,
    },
    retrieveAnswerObject: (isElectricBike: string) => ({
      isElectricBike: isElectricBike === normalOrEbike.ebike,
    }),
    transformValue: (isElectricBike?: string) => {
      if (isElectricBike === undefined) {
        return undefined;
      }
      return isElectricBike ? normalOrEbike.ebike : normalOrEbike.normal;
    },
    retrieveNextPageId: () => 'postalCode',
    groupId: 'personalInfo',
  },
  postalCode: {
    required: (answers) => !answers.postalCode,
    type: 'INPUT',
    props: {
      question: 'What is your postal code?',
      placeholder: 'Postal code',
      additionalInfo: {
        title: 'Why do you need my postal code?',
        description:
          'We use the postal code to estimate the level of risk in your area for vandalism and theft. Knowing your own risk can help you decide if bike insurance is right for you or not.',
      },
    },
    retrieveAnswerObject: (postalCode: QuestionnaireAnswers['postalCode']) => ({
      postalCode,
      hasBike: true,
    }),
    retrieveNextPageId: () => submitQuestionId,
    groupId: 'personalInfo',
  },
  ...createProcessingQuestion('bike'),
};

const accountInfo = getAccountInfo<BikeQuestionnaire>('bikePurchasePrice');

const bikeQuestionnaireWithAuth: BikeQuestionnaire = {
  ...accountInfo,
  ...bikeQuestionnaire,
};

export default bikeQuestionnaireWithAuth;
