import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { ExpatEu, QuotesAnswers } from 'features/expatEu/models';
import { useEffect, useRef } from 'react';
import endpoint from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';
import { Processing } from 'shared/pages/Processing';

const TIME_OUT = 1_000;

const createExpatEuQuotes = async (answers: QuotesAnswers) => {
  return endpoint.network.post<{
    id: string;
    answers: QuotesAnswers;
  }>('/signups/expat-eu/quotes/', answers);
};

export const QuoteProcessing = ({
  onSubmitValue,
  questionnaireAnswers,
}: QuestionnaireFormProps<ExpatEu>) => {
  const { t } = useSafeTranslation();
  const loaded = useRef(false);

  useEffect(() => {
    const submitInfo = async (loadedRef: { current: boolean }) => {
      if (loadedRef.current) {
        return;
      }
      const { dateOfBirth, region } = questionnaireAnswers;
      if (!dateOfBirth || !region) {
        return;
      }

      try {
        const { data } = await createExpatEuQuotes({
          dateOfBirth,
          region,
        });

        onSubmitValue(data);
      } catch (e) {
        return null;
      }
      loadedRef.current = true;
    };

    let id: ReturnType<typeof setTimeout>;
    if (loaded.current === false) {
      id = setTimeout(() => {
        submitInfo(loaded);
      }, TIME_OUT);
    }
    return () => {
      clearTimeout(id);
    };
  }, [loaded, onSubmitValue, questionnaireAnswers]);

  const textList: string[] = [
    t('expatEu.qnr.quote.processing.loadingText', 'Processing quote...'),
  ];

  return <Processing onLoadComplete={onSubmitValue} textList={textList} />;
};
