import { DateSelector } from '@popsure/dirty-swan';
import de from 'dayjs/locale/de';
import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, useState } from 'react';
import {
  getCurrentLocale,
  Locale,
  useLocales,
  useSafeTranslation,
} from 'shared/i18n';

import QuestionnaireWrapper from '../QuestionnaireWrapper';

type Overrides = {
  firstDayOfWeek?: number;
  dayjsLocale?: ILocale;
};

const localeOverridesMapping: Partial<Record<Locale | 'xx', Overrides>> = {
  en: {},
  de: {
    firstDayOfWeek: 1,
    dayjsLocale: de,
  },
  xx: {},
};

interface Props
  extends QuestionnaireFormProps<string>,
    QuestionnaireWrapperProps {
  yearRange: { min: number; max: number };
  displayCalendar?: boolean;
  placeholders?: {
    day?: string;
    month?: string;
    year?: string;
  };
  dayjsLocale?: ILocale;
  firstDayOfWeek?: number;
}

export const DateForm = ({
  value,
  yearRange,
  onSubmitValue,
  question,
  description,
  buttonText,
  error,
  header,
  additionalInfo,
  displayCalendar = true,
  featureName,
  questionClassName = '',
  placeholders,
  dayjsLocale,
  firstDayOfWeek = 0,
  skipDetails,
}: Props) => {
  const { t } = useSafeTranslation();

  const { allowedLocales } = useLocales();

  const [date, setDate] = useState<string | undefined>(value);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!date) {
      throw new Error(
        `[${featureName}] Date value is missing for submission for question: ${question}`
      );
    }
    onSubmitValue(date);
  };

  return (
    <QuestionnaireWrapper
      header={header}
      question={question}
      description={description}
      questionClassName={questionClassName}
      onSubmit={handleSubmit}
      error={error}
      validForSubmission={!!date}
      buttonText={buttonText}
      additionalInfo={additionalInfo}
      skipDetails={skipDetails}
    >
      <DateSelector
        value={date}
        yearBoundaries={yearRange}
        onChange={setDate}
        displayCalendar={displayCalendar}
        placeholders={
          placeholders || {
            day: t('qnf.forms.date.day.placeholder', 'Day'),
            month: t('qnf.forms.date.month.placeholder', 'Month'),
            year: t('qnf.forms.date.year.placeholder', 'Year'),
          }
        }
        dayjsLocale={dayjsLocale}
        firstDayOfWeek={firstDayOfWeek}
        {...(allowedLocales.includes(getCurrentLocale())
          ? localeOverridesMapping[getCurrentLocale()]
          : {})}
      />
    </QuestionnaireWrapper>
  );
};
