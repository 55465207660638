import './style.scss';

import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';

const GERMAN_PHONE_NO_MASK = '... ... ..........';

const PhoneInputComponent = (props: PhoneInputProps) => (
  <PhoneInput
    {...props}
    defaultMask={GERMAN_PHONE_NO_MASK}
    alwaysDefaultMask
    placeholder="49 211 5684962"
    enableLongNumbers={true}
  />
);

export default PhoneInputComponent;
