import { Question, QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import { InsuranceTypes } from 'models/insurances/types';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import { useSafeTranslation } from 'shared/i18n';
import { Review } from 'SignupQuestionnaire/components/Review';
import {
  QuestionReviewValueType,
  ReviewProps,
} from 'SignupQuestionnaire/components/Review/Review';

import { createPostQuote } from '../../actions';
import { getReviewValue } from './getReviewValues';

export const GeneralReview = <
  Questionnaire extends QuestionnaireQuestions = QuestionnaireQuestions
>(
  props: Omit<
    ReviewProps<Questionnaire>,
    'createPostQuote' | 'insuranceType' | 'verticalId' | 'requestType'
  > & {
    apiPath: string;
    meta: Record<string, unknown>;
    transform?: {
      personalInfo: string[];
      tariffInfo: string[];
    };
    configuration: {
      questions: Question<Questionnaire>[];
    };
  }
) => {
  const {
    featureName,
    reviewValues,
    questionnaireAnswers,
    apiPath,
    transform,
    meta,
    configuration,
  } = props;
  const { t } = useSafeTranslation();

  const createPost = () =>
    createPostQuote({
      insuranceType: featureName,
      t,
      questionnaireAnswers,
      apiPath,
      transform,
      meta,
    });

  return (
    <Review
      requestType="REVIEW_SUBMIT_INFO"
      verticalId={featureName as keyof GenericQuestionnaireState}
      insuranceType={featureName as InsuranceTypes}
      {...props}
      createPostQuote={createPost}
      reviewValues={
        reviewValues
          .map((reviewEntry) => {
            return {
              ...reviewEntry,
              value: getReviewValue(
                configuration?.questions ?? [],
                reviewEntry.path ?? ''
              ),
            };
          })
          .filter(
            (reviewEntry) => reviewEntry.value !== null
          ) as QuestionReviewValueType<Questionnaire>[]
      }
    />
  );
};
