export const AGE_UPPER_LIMIT = 50;
export const AGE_LOWER_LIMIT = 18;
export const INITIAL_AMOUNT_LIMIT = 1_000_000;
export const MINIMUM_MONTHLY_PAYMENT = 50;
export const MAXIMUM_MONTHLY_PAYMENT = 1_000;
export const DEFAULT_MONTHLY_PAYMENT = 150;
export const DEFAULT_PENSION_AGE = 67;
export const FIXED_MONTHLY_FEE = 1.5;
export const YEARLY_FEE_PERCENTAGE = 0.5;
export const YEARLY_YIELD_PERCENTAGE = 6;
export const SLIDER_STEP_SIZE = 10;
