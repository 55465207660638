import * as Sentry from '@sentry/react';
import Navigation from 'components/common/navigation';
import ErrorView from 'components/error';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { ViewProps } from 'features/claimsProcess/claimStatus';
import { useLoadPolicyDetail as _useLoadPolicyDetail } from 'features/claimsProcess/hooks';
import { mapClaimTypes } from 'models/claims';
import { generatePath } from 'react-router-dom';
import { limitTextLength } from 'shared/util/limitTextLength';

import documentIcon from './icons/document.svg';
import StatusTimeline from './statusTimeline';
import styles from './style.module.scss';

const DocumentCell = ({
  title,
  downloadUrl,
}: {
  title: string;
  downloadUrl: string;
}) => (
  <a
    href={downloadUrl}
    className={`mt16 ${styles.document}`}
    target="_blank"
    rel="noopener noreferrer"
    key={downloadUrl}
  >
    <div className="d-flex">
      <img src={documentIcon} alt={title} />
      <div className={`p-p ${styles['document-name']}`}>
        {title && limitTextLength(title, 18)}
      </div>
    </div>
  </a>
);

const ClaimStatusView = ({
  policyId,
  currentClaim,
  useLoadPolicyDetail = _useLoadPolicyDetail,
}: ViewProps & { useLoadPolicyDetail?: typeof _useLoadPolicyDetail }) => {
  const { loading, error, policy } = useLoadPolicyDetail(policyId);

  if (loading === true) {
    return <LoadingSpinner />;
  }

  if (error !== undefined) {
    return <ErrorView error={error} />;
  }

  if (!currentClaim || !policy) {
    throw new Error(
      '[CLAIMS] Trying to continue without Claim details or Policy detail'
    );
  }

  const { amount, claimDate, claimTypes, documents, status } = currentClaim;

  if (claimTypes.length === 0) {
    Sentry.captureException(
      '[CLAIMS] Claim type does not exist on claim details'
    );
  }

  return (
    <div className="p-body">
      <div className={`wmx11 w100 ${styles.container}`}>
        <Navigation
          title={
            (claimTypes[0] && mapClaimTypes[claimTypes[0].claimType]) || ''
          }
          previousScreen="My Claims"
          previousUrl={generatePath(routes.me.claims.myClaims.path, {
            policyId,
          })}
        />
        <div className={`mt16 ${styles['section-container']}`}>
          <div className={`mt16 wmx6 ws6 ${styles['status-container']}`}>
            <StatusTimeline
              date={claimDate}
              title={
                (claimTypes[0] && mapClaimTypes[claimTypes[0].claimType]) || ''
              }
              price={amount}
              currentStatus={status}
              insuranceType={policy.type}
            />
          </div>
          {documents.length > 0 && (
            <div className={`mt16 ${styles['documents-container']}`}>
              <div className="p-p">Submitted documents</div>
              {documents.map(({ filename, publicUrl }) => (
                <DocumentCell
                  title={filename}
                  downloadUrl={publicUrl}
                  key={publicUrl}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClaimStatusView;
