import { capitalizeName } from '@getpopsure/public-utility';
import { getPoliciesListAction } from 'features/policiesDashboard/utils/getPoliciesListAction';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';

import type { PolicySingleModal } from '../../components/modals';
import {
  CancelPolicyModal,
  DelinquencyBlockerCancelPolicyModal,
} from '../../components/modals';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from '../../PolicySingle.models';
import { getPolicyDocuments } from '../../sections/PolicyDocuments/utils/getPolicyDocuments';
import { getGenericButtons } from './getGenericButtons';
import { getGenericInfo } from './getGenericInfo';

export const getBaseConfig = (args: GetPolicyConfigArgs): PolicyConfig => {
  const [policy, t] = args;

  const action = getPoliciesListAction(policy);
  const policyStatus =
    action && action !== 'DETAIL'
      ? 'ACTION_NEEDED'
      : getPolicyStatus(policy, true);
  const title = getTitleMapping(t)[policy.type];
  const capitalizedName = policy.attributes?.insuredPerson?.name
    ? capitalizeName(policy.attributes?.insuredPerson.name)
    : '';
  const subtitle = capitalizedName || '';

  const header: PolicyConfig['header'] = {
    title,
    subtitle,
    backgroundColor: bannerColorMapping[policy.type],
    insuranceType: policy.type,
    policyStatus,
    startDate: policy.attributes.startDate,
  };

  if (policy.attributes.price) {
    header.price = {
      amount: parseFloat(policy.attributes.price),
      billingType: policy.attributes.billingType as BillingType,
    };
  }

  const sections: PolicySingleSection[] = [
    {
      id: 'POLICY_DETAILS',
      props: {
        policyType: policy.type,
        info: getGenericInfo(policy, t),
        buttons: getGenericButtons(policy),
      },
    },
    {
      id: 'POLICY_DOCUMENTS',
      props: {
        documents: getPolicyDocuments(policy),
      },
    },
  ];

  const modals: PolicySingleModal[] = [
    {
      id: 'CANCEL_POLICY',
      component: CancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
  ];

  return { header, modals, sections };
};
