import type {
  LegalQuestionnaire,
  QuestionnaireAnswers,
} from 'features/recommendationTool/models/legal';
import { legalSystemFamiliarity } from 'features/recommendationTool/models/legal';
import { getAccountInfo } from 'features/recommendationTool/questionnaires/account';

import { createProcessingQuestion, submitQuestionId } from '../processing';

const legalQuestionnaire: LegalQuestionnaire = {
  familiarityWithGermanLegalSystem: {
    required: (answers) => !answers.familiarityWithGermanLegalSystem,
    type: 'RADIO',
    props: {
      question: 'How familiar are you with the German legal system?',
      mapValue: legalSystemFamiliarity,
    },
    retrieveAnswerObject: (
      familiarityWithGermanLegalSystem: QuestionnaireAnswers['familiarityWithGermanLegalSystem']
    ) => ({
      familiarityWithGermanLegalSystem,
    }),
    retrieveNextPageId: () => submitQuestionId,
    groupId: 'personalInfo',
  },
  ...createProcessingQuestion('legal'),
};

const accountInfo = getAccountInfo<LegalQuestionnaire>(
  'familiarityWithGermanLegalSystem'
);

const legalQuestionnaireWithAccount: LegalQuestionnaire = {
  ...accountInfo,
  ...legalQuestionnaire,
};

export default legalQuestionnaireWithAccount;
