import { TFunction, Trans } from '@getpopsure/i18n-react';
import { legal } from '@getpopsure/private-constants';
import { MultiDropzone } from '@popsure/dirty-swan';
import classnames from 'classnames';
import { NavButton } from 'components/NavButton';
import { FormEvent } from 'react';
import AnimateHeight from 'react-animate-height';
import { UploadedFile } from 'shared/models/types';
import { isMobileApp } from 'shared/util/isMobileApp';

import styles from './style.module.scss';

interface Props {
  handleSubmit: (e: FormEvent) => void;
  t: TFunction;
  error?: string;
  submissionLoading?: boolean;
  onFileSelect: (files: File[]) => void;
  onRemoveFile: (id: string) => void;
  uploadedFiles: UploadedFile[];
  uploadLoading: boolean;
  isSubmitValid: boolean;
}

export const UploadDocumentsView = ({
  handleSubmit,
  onFileSelect,
  onRemoveFile,
  uploadedFiles,
  t,
  error,
  submissionLoading,
  uploadLoading,
  isSubmitValid,
}: Props) => (
  <form className={styles.container} onSubmit={handleSubmit}>
    <div className="p-body">
      {isMobileApp && <NavButton title="Previous question" renderInPortal />}
      <h1 className="p-h1">
        {t('claims.liability.uploaddocuments.question', 'Upload documents')}
      </h1>
      <p className="p-p mt8">
        {t(
          'claims.liability.uploaddocuments.description',
          'Provide all documents relevant for this claim (photos of the damage, invoices, etc.).'
        )}
      </p>
      <div className="mt16 wmx6">
        <MultiDropzone
          onFileSelect={onFileSelect}
          uploadedFiles={uploadedFiles}
          uploading={uploadLoading}
          onRemoveFile={onRemoveFile}
          textOverrides={{
            instructionsText: t(
              'claims.liability.uploaddocuments.dropzone.instructions',
              'Choose file or drag & drop'
            ),
            supportsText: t(
              'claims.liability.uploaddocuments.dropzone.fileSupport',
              'Supports JPEG, PNG, PDF'
            ),
            currentlyUploadingText: t(
              'claims.liability.uploaddocuments.dropzone.currentlyUploading',
              'Please wait while uploading file...'
            ),
          }}
        />
      </div>
      <Trans i18nKey="claims.liability.uploaddocuments.agreement">
        <p className="mt24 p-p">
          By uploading any documents you agree to our{' '}
          <a
            className="p-a"
            href={legal.declarationDataProtection}
            target="_blank"
            rel="noopener noreferrer"
          >
            data processing agreement
          </a>
          .
        </p>
      </Trans>
      <AnimateHeight duration={300} height={error ? 'auto' : 0}>
        <div className="p-notice p-notice--warning mt24 p-p wmx6">{error}</div>
      </AnimateHeight>
      <button
        className={classnames(
          `p-btn--primary mt24 wmx5 wmn3 mb8 ${styles.button}`,
          {
            'p-btn--loading': submissionLoading,
          }
        )}
        type="submit"
        disabled={!isSubmitValid}
        data-cy="button-continue"
      >
        {t('claims.liability.uploaddocuments.cta', 'Continue')}
      </button>
    </div>
  </form>
);
