import {
  AGE_LOWER_LIMIT,
  AGE_UPPER_LIMIT,
  INITIAL_AMOUNT_LIMIT,
} from 'features/pension/constants';
import { ValidationError } from 'features/pension/models';

export const validateCalculatorInput = (
  age: number | null,
  initialAmount: number | null
): ValidationError | null => {
  if (age) {
    if (age > AGE_UPPER_LIMIT) {
      return 'AGE_ABOVE_LIMIT';
    }

    if (age < AGE_LOWER_LIMIT) {
      return 'AGE_BELOW_LIMIT';
    }
  }

  if (initialAmount && initialAmount > INITIAL_AMOUNT_LIMIT) {
    return 'INITIAL_AMOUNT_ABOVE_LIMIT';
  }

  return null;
};
