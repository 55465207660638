import {
  DateForm,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import dayjs, { ManipulateType } from 'dayjs';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';

export type DateOperation =
  | {
      op: 'add';
      value: number;
      type: ManipulateType;
    }
  | {
      op: 'subtract';
      value: number;
      type: ManipulateType;
    };

export const TravelDateForm = <Questionnaire extends QuestionnaireQuestions>({
  featureName,
  question,
  questionnaireAnswers,
  setValidForSubmission,
  onSubmitValue,
  value,
  yearRange,
}: {
  yearRange:
    | {
        min: number;
        max: number;
      }
    | {
        min: DateOperation;
        max: DateOperation;
      };
} & QuestionnaireFormProps<Questionnaire>) => {
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const onSubmit = (submitValue: string) => {
    if (dayjs(submitValue).diff(dayjs(), 'day') >= 3) {
      onSubmitValue(submitValue);
    } else {
      setShowErrorMsg(true);
    }
  };
  return (
    <>
      <DateForm
        value={value as string}
        yearRange={yearRange}
        onSubmitValue={onSubmit}
        setValidForSubmission={setValidForSubmission}
        featureName={featureName}
        question={question}
        questionnaireAnswers={questionnaireAnswers}
      />
      <AnimateHeight duration={300} height={showErrorMsg ? 'auto' : 0}>
        <div className="p-notice p-notice--warning mt16 p-p">
          The earliest start date is{' '}
          {dayjs().add(4, 'days').format('DD MMM YYYY')}
        </div>
      </AnimateHeight>
    </>
  );
};
