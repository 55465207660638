import { Checkbox, IbanInput, Input } from '@popsure/dirty-swan';
import { CardElement, useElements } from '@stripe/react-stripe-js';
import { AddressInput } from 'components/AddressInput';
import { PaymentOptionSetupData } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import { toStripeBillingAddress } from 'features/paymentMethodSelector/paymentMethodSelector.utils';
import { isValidIBAN } from 'ibantools';
import {
  CollectableBillingAddress,
  PaymentMethod,
  PaymentType,
} from 'models/paymentMethods';
import { useEffect, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

export interface PaymentFormProps {
  accountHolderEmail: string;
  paymentType?: PaymentType;
  selectedPaymentMethod?: PaymentMethod;
  forceMakeDefault: boolean;
  onSetupDataChange: (setupData: PaymentOptionSetupData | null) => void;
}

export const StripeCardElementOptions = {
  style: {
    base: {
      '::placeholder': {
        color: '#d2d2d8',
      },
      fontSize: '16px',
      lineHeight: '48px',
      fontFamily: 'Lato, sans-serif',
    },
  },
};

export const PaymentForm = ({
  accountHolderEmail,
  paymentType,
  selectedPaymentMethod,
  forceMakeDefault,
  onSetupDataChange,
}: PaymentFormProps) => {
  const { t } = useSafeTranslation();

  const [iban, setIban] = useState('');
  const [hasEnteredCardPaymentInfo, setHasEnteredPaymentInfo] = useState(false);
  const [paymentOptionHolderName, setPaymentOptionHolderName] = useState('');
  const [sepaAssociatedAddress, setSepaAssociatedAddress] = useState<
    Partial<CollectableBillingAddress>
  >({});
  const [makeDefault, setMakeDefault] = useState<boolean>(forceMakeDefault);

  const elements = useElements();

  useEffect(() => {
    setSepaAssociatedAddress({});
  }, [selectedPaymentMethod]);

  useEffect(() => {
    setHasEnteredPaymentInfo(false);
    setPaymentOptionHolderName('');
    setIban('');
    // eslint-disable-next-line
  }, [paymentType]);

  useEffect(() => {
    let setupData: PaymentOptionSetupData | null;

    switch (paymentType) {
      case 'CARD': {
        const cardElement = elements?.getElement('card'); // TODO: wait for elements to load and show spinner

        const cardFormIsReady =
          cardElement &&
          hasEnteredCardPaymentInfo &&
          paymentOptionHolderName.length > 0;

        setupData = cardFormIsReady
          ? {
              type: 'CARD',
              cardHolderName: paymentOptionHolderName,
              makeDefault,
              cardElement,
            }
          : null;
        break;
      }
      case 'SEPA': {
        const stripeBillingAddress = toStripeBillingAddress(
          sepaAssociatedAddress
        );

        const sepaFormIsReady =
          iban.length > 0 &&
          isValidIBAN(iban) &&
          paymentOptionHolderName.length > 0 &&
          stripeBillingAddress !== undefined;

        setupData = sepaFormIsReady
          ? {
              type: 'SEPA',
              accountHolderName: paymentOptionHolderName,
              accountHolderEmail,
              address: stripeBillingAddress,
              iban,
              makeDefault,
            }
          : null;
        break;
      }
      case 'PAYPAL':
        // TODO: make sure PaymentForm doesn't get rendered for PayPal
        //       This option is not supposed to be available outside of the feature flag,
        //       but the parent component renders and hides the form based on the type of the currently
        //       selected payment option
        setupData = null;
        break;
      default:
        throw Error(
          `[PaymentForm] Rendering the PaymentMethodSelector with an unsupported payment type ${paymentType}`
        );
    }

    onSetupDataChange(setupData);
  }, [
    accountHolderEmail,
    elements,
    hasEnteredCardPaymentInfo,
    iban,
    forceMakeDefault,
    makeDefault,
    onSetupDataChange,
    paymentOptionHolderName,
    paymentType,
    sepaAssociatedAddress,
  ]);

  return (
    <div className="mt16">
      {paymentType === 'SEPA' && (
        <div className="mt8">
          <IbanInput
            value={iban}
            onChange={setIban}
            placeholder={t(
              'components.paymentMethodSelector.iban.placeholder',
              'IBAN'
            )}
            data-cy="payment-info-input-sepa"
          />
        </div>
      )}{' '}
      {paymentType === 'CARD' && (
        <div className={`mt8 py0 px16 br8 ${styles['payment-info-input']}`}>
          <CardElement
            onChange={({ complete }) => setHasEnteredPaymentInfo(complete)}
            options={StripeCardElementOptions}
          />
        </div>
      )}
      <Input
        className="mt8"
        value={paymentOptionHolderName}
        placeholder={t(
          'components.paymentMethodSelector.accountHolder.placeholder',
          {
            defaultValue: "{{paymentMethod}} holder's name",
            paymentMethod: paymentType === 'SEPA' ? 'Account' : 'Card',
          }
        )}
        onChange={(e) => setPaymentOptionHolderName(e.target.value)}
        data-cy="payment-info-input-holder"
      />
      {paymentType === 'SEPA' && (
        <AddressInput
          address={sepaAssociatedAddress}
          setAddress={setSepaAssociatedAddress}
          t={t}
        />
      )}
      {!forceMakeDefault && (
        <>
          <Checkbox
            wide={true}
            bordered={false}
            options={{
              SET_AS_DEFAULT: t(
                'paymentMethodSelector.paymentForm.makeDefault.label',
                'Set as default payment method'
              ),
            }}
            classNames={{ container: 'mt16' }}
            value={makeDefault ? ['SET_AS_DEFAULT'] : []}
            onChange={(values) =>
              setMakeDefault(values.includes('SET_AS_DEFAULT'))
            }
          />
          <p className="p-p tc-grey-600 mt8">
            {t(
              'paymentMethodSelector.paymentForm.makeDefault.hint',
              'Recurring payments are always taken from the default payment method in your account.'
            )}
          </p>
        </>
      )}
      <p className="p-p tc-grey-600 mt8">
        {t(
          'paymentMethodSelector.paymentForm.makeDefault.hint',
          'Recurring payments are always taken from the default payment method in your account.'
        )}
      </p>
    </div>
  );
};
