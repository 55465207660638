import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import routes from 'constants/routes';
import { recommendation } from 'features/policiesDashboard/assets';
import { useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import styles from './RecommendationCard.module.scss';

const RecommendationCard = () => {
  const { t } = useSafeTranslation();
  const history = useHistory();

  return (
    <div
      className={classNames(
        'd-flex jc-between gap32 br8 bg-grey-100',
        styles.card
      )}
    >
      <div className="wmx5">
        <h2 className="p-h2 mb8">
          {t('policies.recommendationCard.title', 'Get a recommendation')}
        </h2>
        <p className="p-p mb24 tc-grey-700">
          {t(
            'policies.recommendationCard.body',
            'Not sure what coverage you need? Let’s look at your lifestyle and figure out what’s needed, what’s worth it and when.'
          )}
        </p>

        <Button
          onClick={() => history.push(routes.insuranceTool.path)}
          data-cy="start-recommendation-tool"
        >
          {t('policies.recommendationCard.action', 'Get a recommendation')}
        </Button>
      </div>

      <div className="wmx4">
        <img aria-hidden className="w100" alt="" src={recommendation} />
      </div>
    </div>
  );
};

export { RecommendationCard };
