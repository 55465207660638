import { Address } from '@getpopsure/public-models';
import { Policy } from 'models/policies';
import type { AppState } from 'reducers';

import type { Step } from '../models';
import { getChecklistStatus } from '../utils';

export const getStudentChecklist =
  (policyId: string) =>
  ({ policies }: AppState) => {
    const policy = policies.items?.[policyId];
    if (!policy) return null;

    return policy.attributes?.studentChecklist || null;
  };

export const getQuestionnaireAddress =
  (policyId: string) =>
  (state: AppState): Address | null => {
    const studentChecklist = getStudentChecklist(policyId)(state);
    if (!studentChecklist) return null;

    return studentChecklist.questionnaireAddress || null;
  };

export const getChecklistAddress = (policyId: string) => (state: AppState) => {
  const studentChecklist = getStudentChecklist(policyId)(state);
  if (!studentChecklist) return null;

  return studentChecklist.address || null;
};

export const getChecklistStepsStatus = (
  policy?: Policy
): { currentSteps: Array<Step> } => {
  if (!policy) return { currentSteps: [] };

  return getChecklistStatus(
    policy.attributes?.studentChecklist || null,
    policy.status
  );
};
