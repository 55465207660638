import {
  ExpatDependentQuestionnaire,
  ExpatQuestionnaire,
} from 'features/expat/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useEffect, useRef } from 'react';
import endpoint from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';
import { Processing } from 'shared/pages/Processing';

const TIME_OUT = 1_000;

export const QuoteProcessing = ({
  onSubmitValue,
  questionnaireAnswers,
}: CustomComponentProps<ExpatQuestionnaire | ExpatDependentQuestionnaire>) => {
  const { t } = useSafeTranslation();
  const loaded = useRef(false);

  useEffect(() => {
    const submitInfo = async (loadedRef: { current: boolean }) => {
      if (loadedRef.current) {
        return;
      }
      const { dateOfBirth } = questionnaireAnswers;
      if (!dateOfBirth) {
        return;
      }

      try {
        const { data: quotes } = await endpoint.createExpatQuotes(dateOfBirth);
        onSubmitValue({ options: quotes });
      } catch (e) {
        return null;
      }
      loadedRef.current = true;
    };

    let id: ReturnType<typeof setTimeout>;
    if (loaded.current === false) {
      id = setTimeout(() => {
        submitInfo(loaded);
      }, TIME_OUT);
    }
    return () => {
      clearTimeout(id);
    };
  }, [loaded, onSubmitValue, questionnaireAnswers]);

  const textList: string[] = [
    t('expat.qnr.quote.processing.loadingText', 'Processing quote...'),
  ];

  return <Processing onLoadComplete={onSubmitValue} textList={textList} />;
};
