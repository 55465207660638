import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { useSafeTranslation } from 'shared/i18n';

import styles from './CoverageSection.module.scss';

type CoverageSectionProps = {
  monthlyPayout: number;
  basicPrice: number;
};

export const CoverageSection = ({
  monthlyPayout,
  basicPrice,
}: CoverageSectionProps) => {
  const { t } = useSafeTranslation();

  const basicPriceStr = englishFormattedEuroCurrency(basicPrice);
  const monthlyPayoutStr = englishFormattedEuroCurrency(monthlyPayout, true);

  return (
    <section className="pt64">
      <h2 className="p-h2 mb16">
        {t('disability.addons.coverage.title', 'Plan')}
      </h2>
      <div
        className={`d-flex p24 pl48 jc-between gap16 bg-white br8 ${styles.content}`}
      >
        <div>
          <h3 className={`p-h3 ${styles.title}`}>
            {t(
              'disability.addons.coverage.disabilityInsurance',
              '💼 Disability insurance'
            )}
          </h3>
          <p className="p-p tc-grey-500">
            {t('disability.addons.coverage.monthlyPayout', {
              defaultValue: '{{monthlyPayoutStr}} monthly payout',
              monthlyPayoutStr,
            })}
          </p>
        </div>
        <div className="d-flex fd-column ai-center">
          <h2 className="p-h2">{basicPriceStr}</h2>
        </div>
      </div>
    </section>
  );
};
