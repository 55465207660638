import { faq } from '@getpopsure/private-constants';
import { IconTile } from 'components/IconTile';
import { ImprovementBox } from 'components/ImprovementBox/ImprovementBox';
import routes from 'constants/routes';
import { checkWhite } from 'icons';
import { Link } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import styles from './ClaimSubmitted.module.scss';
import confirmationIcon1 from './icons/confirmation1.svg';
import confirmationIcon2 from './icons/confirmation2.svg';
import confirmationIcon3 from './icons/confirmation3.svg';

export const ClaimSubmitted = () => {
  const { t } = useSafeTranslation();
  return (
    <div className={`d-flex p-body ai-center jc-center ${styles.container}`}>
      <div className="p-body d-flex fd-column jc-center mt80 mb80 ai-center">
        <img
          src={checkWhite}
          className={styles.image}
          alt={t('legal.qnr.claims.submitted.checkAlt', 'check circle')}
        />
        <h1 className="p-h2 mt32">
          {t('legal.qnr.claims.submitted.title', 'Claim submitted!')}
        </h1>
        <p className="p-h3 fw-bold mt24 tc-grey-700 ta-center wmx8">
          {t('legal.qnr.claims.submitted.description', 'What happens next?')}
        </p>
        <div className={`d-grid gap16 mt16 w100 ${styles.grid}`}>
          <IconTile
            title="Claim review"
            paragraphClassName="p-p--small"
            iconSrc={confirmationIcon1}
            iconAlt="magnifying glass and a piece of paper"
          >
            {t(
              'legal.qnr.claims.submitted.iconTile.1',
              'It is common for the risk carrier (Roland) to request additional documents or information related to your claim.'
            )}
          </IconTile>
          <IconTile
            title="Promptly respond to requests"
            paragraphClassName="p-p--small"
            iconSrc={confirmationIcon2}
            iconAlt="stop watch"
          >
            {t(
              'legal.qnr.claims.submitted.iconTile.2',
              'To avoid any unnecessary delays, it is essential for you to provide the requested documents as soon as possible.'
            )}
          </IconTile>
          <IconTile
            title="Claim decision"
            paragraphClassName="p-p--small"
            iconSrc={confirmationIcon3}
            iconAlt="wood hammer"
          >
            {t('legal.qnr.claims.submitted.iconTile.3', {
              defaultValue:
                "If approved, you'll be directed to a lawyer or a mediator to proceed further with your legal case. [Learn more]({{link}}) about what happens after claim decision is made.",
              link: faq.legalClaimSubmit,
            })}
          </IconTile>
        </div>
        <Link
          className={`p-btn--primary wmn4 mt24 ${styles.button}`}
          to={routes.claims.path}
        >
          {t('legal.qnr.claims.submitted.cta', 'View all claims')}
        </Link>
        <ImprovementBox vertical="legal" />
      </div>
    </div>
  );
};
