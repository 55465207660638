const getImageDimensions = (
  src: string
): Promise<{ width: number; height: number }> =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () =>
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    img.onerror = (error) => reject(new Error(`error loading image: ${error}`));
    img.src = src;
  });

const loadImage = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    if (!file) reject(new Error('file parameter is required'));

    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => resolve(reader.result as string), // readAsDataURL returns a string
      false
    );
    reader.readAsDataURL(file);
  });

export const getImageSize = async (
  file: File
): Promise<{ width: number; height: number }> => {
  try {
    const imageUrl = await loadImage(file);
    return await getImageDimensions(imageUrl);
  } catch (e) {
    throw new Error(`getImageSize error: ${e}`);
  }
};
