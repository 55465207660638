import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import MoreQuestionsSection from 'components/moreQuestions';
import ReviewBadge from 'components/reviewBadge';
import { FC, useState } from 'react';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import { Addon, AddonSection } from './AddonSection/AddonSection';
import { CTABox, CTAProps } from './CTABox';
import { FAQ, FAQProps } from './FAQ';
import { Header } from './Header';
import { PriceCard } from './PriceCard';
import styles from './QuotePage.module.scss';
import { SegmentedControl } from './SegmentedControl';
import { WhatsCoveredSection } from './WhatsCoveredSection';
import { CoveredSection } from './WhatsCoveredSection/WhatsCoveredSection';
import { WhatsNotCoveredSection } from './WhatsNotCoveredSection';
import { NotCoveredSection } from './WhatsNotCoveredSection/WhatsNotCoveredSection';

type Actions<Quote> = {
  items: ActionElementType[];
  button:
    | {
        text: string;
      }
    | {
        title: string;
        subTitle: string;
        perText?: string;
        buttonText?: string;
      }[];
  valuesMap: Record<number, string>;
  submitMap: Quote[];
  classNames?: string;
};

export type QuotePageConfiguration<Quote> = {
  title: string;
  headerImage: { src: string; alt: string };
  priceHeader?: {
    title: string;
    subTitle: string;
  };
  actions?: Actions<Quote>;
  coveredSection?: CoveredSection;
  notCoveredSection?: NotCoveredSection;
  addons?: Addon[];
  addonSingleSelect?: boolean;

  faq?: FAQProps;
  ctaBox?: CTAProps;
  reviewBadge: boolean;

  moreQuestionsSection?: {
    bookACallGACategory?: string;
    title?: string;
    description?: string;
    secondaryAction?: string;
    secondaryActionLink?: string;
    showSecondaryIcon?: boolean;
    openInNewTab?: boolean;
    bookACallUrl?: string;
    bookACallActionTitle?: string;
    showBookCallAction?: boolean;
  };
};

type ActionElementType =
  | { type: 'SubHeader'; text: string }
  | { type: 'Description'; text: string }
  | { type: 'Toggle'; mapValues: Record<number, string> }
  | {
      type: 'Cards';
      items: {
        title: string;
        subTitle: string;
        perText?: string;
        price?: number;
        buttonText?: string;
      }[];
    };

type CreateActionElementsProps<T> = {
  items: ActionElementType[];
  value: T;
  displayValue: string;
  setValue: (value: T) => void;
};

export const replacePlaceholder = (msg: string, value: string) => {
  const replacements: Record<string, string> = {
    '{{value}}': value,
  };

  return msg.replace(/{{\w+}}/g, (key) => {
    return `${replacements[key]}` || key;
  });
};

const createActionElements = ({
  items,
  value,
  displayValue,
  setValue,
}: CreateActionElementsProps<number>) => {
  const elements: React.ReactNode[] = [];

  for (const item of items) {
    if (item.type === 'SubHeader') {
      elements.push(
        <div key={item.text} className="p-h3 tc-grey-900">
          {replacePlaceholder(item.text, displayValue)}
        </div>
      );
    }

    if (item.type === 'Description') {
      elements.push(
        <div key={item.text} className="p-p ta-center fw-normal tc-grey-500">
          {replacePlaceholder(item.text, displayValue)}
        </div>
      );
    }

    if (item.type === 'Toggle') {
      elements.push(
        <SegmentedControl
          key={item.type}
          selectedValue={value}
          mapValues={item.mapValues}
          onChange={setValue}
        />
      );
    }

    if (item.type === 'Cards') {
      elements.push(
        <div
          className={classNames(
            'p-container mt32 d-flex',
            styles.chipContainer
          )}
        >
          {item.items.map(({ title, subTitle, price, perText, buttonText }) => {
            return (
              <PriceCard
                key={title}
                title={title}
                subTitle={subTitle}
                perText={perText}
                buttonText={buttonText}
                price={price ?? 0}
              />
            );
          })}
        </div>
      );
    }
  }

  return elements;
};

export const Wrapper: FC = ({ children }) => {
  return (
    <div className={classNames('bg-white', styles.container)}>{children}</div>
  );
};

export const QuotePage = <
  Questionnaire extends QuestionnaireQuestions,
  T extends { id: string; addons?: string[] } & Record<string, unknown>
>({
  onSubmitValue,
  config,
  children,
  value: initialValue,
}: {
  config: QuotePageConfiguration<Questionnaire['quote'] & T>;
  children?: React.ReactNode;
} & QuestionnaireFormProps<Questionnaire, Questionnaire['quote'] & T>) => {
  const [value, setValue] = useState<number>(() => {
    const idx = config.actions?.submitMap.findIndex(
      (val) => val.id === initialValue?.id
    );
    return idx && idx !== -1 ? idx : 0;
  });

  const [selectedAddons, setSelectedAddons] = useState<string[]>(
    initialValue?.addons ?? []
  );
  const toggleAddon = (id: string) => {
    setSelectedAddons((addons) => {
      const isSelected = addons.includes(id);
      if (isSelected) {
        return addons.filter((addon) => addon !== id);
      }
      return config.addonSingleSelect ? [id] : [...addons, id];
    });
  };

  const additionalValue = config.addons
    ? config.addons
        .filter((addon) => selectedAddons.includes(addon.id))
        .reduce((acc, addon) => acc + addon.price, 0)
    : 0;

  const displayValue = config.actions
    ? englishFormattedEuroCurrency(
        Number(config.actions.valuesMap[value]) + additionalValue
      )
    : '';

  const onSubmit = (val: Questionnaire['quote'] & T) => {
    onSubmitValue(
      val,
      config.addons
        ? ({ addons: selectedAddons } as unknown as Partial<Questionnaire>)
        : undefined
    );
  };

  return (
    <div className={classNames('bg-white', styles.container)}>
      <div className="d-flex fd-column ai-center jc-center bg-grey-200 py72">
        {(config.title || config.priceHeader) && (
          <Header
            title={config.title}
            headerImage={config.headerImage}
            priceHeader={{
              ...(config.priceHeader || {}),
              title: replacePlaceholder(
                config.priceHeader?.title ?? '',
                displayValue
              ),
              subTitle: config.priceHeader?.subTitle ?? '',
            }}
          />
        )}

        {config.actions &&
          (children || (
            <ActionsComponent
              value={value}
              displayValue={displayValue}
              setValue={setValue}
              onSubmitValue={onSubmit}
              {...config.actions}
            />
          ))}
      </div>

      {config.addons && (
        <AddonSection
          addons={config.addons}
          toggleAddon={toggleAddon}
          selectedAddons={selectedAddons}
        />
      )}

      {config.coveredSection && (
        <WhatsCoveredSection {...config.coveredSection} />
      )}
      {config.notCoveredSection && (
        <WhatsNotCoveredSection {...config.notCoveredSection} />
      )}

      {config.ctaBox && (
        <CTABox
          {...config.ctaBox}
          title={replacePlaceholder(config.ctaBox.title, displayValue)}
          onSubmit={() => {
            if (config.actions?.submitMap[value]) {
              onSubmit(config.actions?.submitMap[value]);
            }
          }}
        />
      )}
      {config.reviewBadge && (
        <div className="p-container d-flex ai-center jc-center mb56">
          <ReviewBadge />
        </div>
      )}
      {config.faq && <FAQ {...config.faq} />}
      {config.moreQuestionsSection && (
        <MoreQuestionsSection {...config.moreQuestionsSection} />
      )}
    </div>
  );
};

const ActionsComponent = <Quote extends Record<string, unknown>>({
  button,
  items,
  submitMap,
  value,
  onSubmitValue,
  setValue,
  displayValue,
}: {
  value: number;
  onSubmitValue: (value: Quote) => void;
  setValue: (value: number) => void;
  displayValue: string;
} & Omit<Actions<Quote>, 'valuesMap'>) => {
  return (
    <div className="jc-center ai-center d-flex fd-column w100">
      {items.length > 0 && (
        <div className="p-container bg-grey-100 d-flex fd-column jc-center ai-center c-gap16 r-gap24 mt40 px32 py24">
          {createActionElements({
            items,
            displayValue,
            value,
            setValue,
          }).map((element) => {
            return element;
          })}
        </div>
      )}
      {!Array.isArray(button) && button.text && (
        <Button
          className="wmn4 mt32"
          onClick={() => onSubmitValue(submitMap[value])}
          data-cy="quote-continue"
        >
          {button.text}
        </Button>
      )}

      {Array.isArray(button) && (
        <div
          className={classNames(
            'p-container mt32 d-flex',
            styles.chipContainer
          )}
        >
          {button.map(({ title, subTitle, perText, buttonText }, idx) => {
            const quote = submitMap[idx];
            return (
              <PriceCard
                key={quote.id as string}
                title={title}
                subTitle={subTitle}
                perText={perText}
                buttonText={buttonText}
                price={quote.price as number}
                onSelect={() => onSubmitValue(quote)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export { MoreQuestionsSection, ReviewBadge };
