import { AxiosError } from 'axios';
import endpoint from 'shared/api';

type GetVerificationHashPayload =
  | { status: 'NOTFOUND' }
  | { status: 'UNAUTHORIZED' }
  | { status: 'OK'; hash: string };

export const getVerificationHash =
  async (): Promise<GetVerificationHashPayload> => {
    try {
      const {
        data: { hash },
      } = await endpoint.getIntercomIdentityHash();
      return { status: 'OK', hash };
    } catch (e) {
      const error = e as AxiosError;

      if (error.code === '401') {
        return { status: 'UNAUTHORIZED' };
      }

      return { status: 'NOTFOUND' };
    }
  };
