import dayjs from 'dayjs';
import {
  MainOccupation,
  OtherOccupation,
} from 'features/recommendationTool/models/shared';
import { HealthQuestionnaireAnswers } from 'models/recommendationTool';
import {
  Occupation,
  StudentSituation,
  University,
  WorkingMoreThanTwentyHours,
} from 'models/recommendationTool/healthQuestionnaire';

const occupationAnswerObject = (answer: Occupation) => ({
  occupation: answer,
  ...(answer !== 'EMPLOYED' ? { employmentIncome: 0 } : {}),
});

const occupationRetrieveNextPageId = (
  answer: MainOccupation
): keyof HealthQuestionnaireAnswers => {
  switch (answer) {
    case 'EMPLOYED':
      return 'isEmployedForTheFirstTime';
    case 'EMPLOYED_OUTSIDE_DE':
    case 'SELF_EMPLOYED':
      return 'totalIncome';
    case 'STUDENT':
      return 'studentSituation';
    case 'UNEMPLOYED':
      return 'unemploymentBenefits';
    case 'OTHER':
      return 'otherOccupation';
  }
};

const otherOccupationRetrieveNextPageId = (answer: OtherOccupation) => {
  switch (answer) {
    case 'ARTIST':
      return 'isRegisteredWithKsk';
    case 'RETIRED':
    case 'CIVIL_SERVANT':
      return 'totalIncome';
  }
};

export const getUniversityStartDateYearRange = (): {
  min: number;
  max: number;
} => {
  const now = dayjs();
  return {
    min: now.subtract(5, 'year').year(),
    max: now.add(5, 'year').year(),
  };
};

const studentSituationAnswerObject = (
  answer: StudentSituation
): Partial<HealthQuestionnaireAnswers> => {
  switch (answer) {
    case 'RECOGNIZED_UNIVERSITY':
      return { studentSituation: answer, occupation: 'STUDENT' };
    case 'LANGUAGE_STUDENT':
      return {
        studentSituation: answer,
        occupation: answer,
        isListedUniversity: false,
      };
    case 'OTHER':
      return {
        studentSituation: answer,
        isListedUniversity: false,
      };
  }
};

const studentSituationRetrieveNextPageId = (answer: StudentSituation) => {
  switch (answer) {
    case 'RECOGNIZED_UNIVERSITY':
      return 'workingMoreThanTwentyHours';
    case 'LANGUAGE_STUDENT':
    case 'OTHER':
      return 'totalIncome';
  }
};

const universityNameAnswerObject = (answer?: University) => ({
  universityName: answer ?? '',
  isListedUniversity: !!answer,
});

const workingMoreThanTwentyHoursAnswerObject = (
  answer: WorkingMoreThanTwentyHours
): Partial<HealthQuestionnaireAnswers> => ({
  workingMoreThanTwentyHours: answer,
  ...(answer === 'AS_EMPLOYEE' ? { occupation: 'EMPLOYED' } : {}),
  ...(answer === 'AS_FREELANCER' ? { occupation: 'SELF_EMPLOYED' } : {}),
  ...(answer === 'NO' ? { occupation: 'STUDENT' } : {}),
});

const workingMoreThanTwentyHoursRetrieveNextPageId = (
  answer: WorkingMoreThanTwentyHours
) => {
  switch (answer) {
    case 'AS_EMPLOYEE':
      return 'isEmployedForTheFirstTime';
    case 'AS_FREELANCER':
      return 'totalIncome';
    case 'NO':
      return 'universityName';
  }
};

const additionalIncomeAnswerObject = (
  answer: number,
  questionnaireAnswers?: Partial<HealthQuestionnaireAnswers>
) => ({
  additionalIncome: answer,
  totalIncome: answer + (questionnaireAnswers?.employmentIncome ?? 0),
});

export const retrieveAnswerObject = {
  studentSituationAnswerObject,
  universityNameAnswerObject,
  workingMoreThanTwentyHoursAnswerObject,
  additionalIncomeAnswerObject,
  occupationAnswerObject,
};

export const retrieveNextPageId = {
  occupationRetrieveNextPageId,
  otherOccupationRetrieveNextPageId,
  studentSituationRetrieveNextPageId,
  workingMoreThanTwentyHoursRetrieveNextPageId,
};
