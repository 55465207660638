import { UploadedFile } from 'shared/models/types';
import { getFileType } from 'shared/util/getFileType';
import { urlLastPathComponent } from 'shared/util/urlLastPathComponent';
import { v4 as uuidv4 } from 'uuid';

export const getUploadedFilesPreview = (
  file: File | string | null
): UploadedFile[] => {
  if (!file) return [];

  if (typeof file === 'string') {
    return [
      {
        id: uuidv4(),
        name: urlLastPathComponent(file),
        type: getFileType(file),
        progress: 100,
        previewUrl: file,
      },
    ];
  }

  return [
    {
      id: uuidv4(),
      progress: 100,
      name: file.name,
      type: getFileType(file.name),
      previewUrl: URL.createObjectURL(file),
    },
  ];
};
