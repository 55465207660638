import dayjs from 'dayjs';
import { CheckoutPolicyDetails } from 'features/bike/models';
import Cell from 'features/checkout/components/Cell';
import { getCheckoutInfo } from 'features/checkout/selectors';
import { ReferrerCodeVerify } from 'features/referralEngine';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { formatCurrencyWithSpace } from 'shared/util/formatCurrencyWithSpace';

import styles from './PolicyDetails.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

export const PolicyDetails = () => {
  const { t } = useSafeTranslation();

  const checkoutInfo = useSelector(
    getCheckoutInfo<CheckoutPolicyDetails>('BIKE_V2')
  );

  return (
    <div className={styles.container}>
      <div className="p-h2 mb16">
        {t('bikev2.qnr.checkout.policyDetails .title', 'Policy details')}
      </div>
      <Cell
        title={t(
          'bikev2.qnr.checkout.policyDetails.startDate',
          'Requested start date'
        )}
        value={dayjs(checkoutInfo?.mainPolicy.startDate).format(DATE_FORMAT)}
        additionalInfo={{
          title: t(
            'bikev2.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
            'Requested start date'
          ),
          description: t(
            'bikev2.qnr.checkout.policydetails.requestedStartDate.additionalInfo.description',
            'Your coverage should begin on {{coverageDate}}, but only after you have received the official confirmation.',
            {
              coverageDate: dayjs(checkoutInfo?.mainPolicy.startDate).format(
                DATE_FORMAT
              ),
            }
          ),
        }}
      />
      <div className={`bg-grey-200 mt32 mb32 ${styles.divider}`} />
      {checkoutInfo?.mainPolicy.monthlyPrice && (
        <div className="d-flex">
          <div className="p-h3">
            {t('bikev2.qnr.checkout.policyDetails.total', 'Total per month')}
          </div>
          <div className="p-h3 ml-auto">
            {formatCurrencyWithSpace(checkoutInfo?.mainPolicy.monthlyPrice, 2)}
          </div>
        </div>
      )}
      <ReferrerCodeVerify />
    </div>
  );
};
