import { trackStructuredEvent } from '@getpopsure/analytics';
import { calendly } from '@getpopsure/private-constants';
import profileIcon from 'features/liability/icons/profile.svg';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

const handleBookAnAppointment = () => {
  trackStructuredEvent({
    category: 'LIABILITY_INSURANCE',
    action: 'CLICKED_BOOK_AN_APPOINTMENT',
  });
  window.Calendly.initPopupWidget({ url: calendly.liabilityInsurance });
};

const LiabilityBlocker = () => {
  const { t } = useSafeTranslation();
  return (
    <div className="p-body">
      <div className={`ta-center w100 wmx8 ${styles.container}`}>
        <img src={profileIcon} alt="purple person profile" />
        <header className="p-h1 mt8">
          {t('page.liability.questionnaire.blocker.title', "We're sorry...")}
        </header>
        <p className="p-p mt16">
          {t(
            'page.liability.questionnaire.blocker.description.1',
            "Due to your situation, you won't be able to sign up for our liability insurance plan. Still, we might have some other options for you."
          )}
        </p>
        <p className="p-p mt24">
          {t(
            'page.liability.questionnaire.blocker.description.2',
            'You can speak to one of our experts who can help guide you through the next steps.'
          )}
        </p>
        <button
          className="p-btn--primary mt24 wmn3"
          onClick={handleBookAnAppointment}
          type="submit"
        >
          {t(
            'page.liability.questionnaire.blocker.buttoncaption',
            'Schedule a call'
          )}
        </button>
      </div>
    </div>
  );
};

export default LiabilityBlocker;
