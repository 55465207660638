import { useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';

import type { CoverageTableDataEntry } from '../CoverageModal';
import { AccordionItem } from './AccordionItem';
import styles from './CoverageAccordion.module.scss';

type CoverageAccordionProps = {
  coverageTableData: CoverageTableDataEntry[];
  firstItemOpenByDefault?: boolean;
  headerWidth?: number;
  renderDataCell: (
    value: string | React.ReactNode | boolean,
    cellWidth?: number,
    key?: string
  ) => JSX.Element;
  tabbed?: boolean;
};

export const CoverageAccordion = ({
  coverageTableData,
  firstItemOpenByDefault = false,
  headerWidth,
  renderDataCell,
  tabbed = false,
}: CoverageAccordionProps) => {
  const [selectedSection, setSelectedSection] = useState(
    firstItemOpenByDefault ? `${coverageTableData[0].title}${0}` : ''
  );

  const cellWidth = tabbed ? (headerWidth || 0) / 2 : undefined;

  const getContent = ({
    rows,
    title,
  }: Pick<CoverageTableDataEntry, 'rows' | 'title'>) =>
    tabbed ? (
      <ScrollSyncPane>
        <div className={styles.scrollContainer}>
          <table className={`p-p w100 ${styles.tabbedTable}`}>
            <tbody>
              {rows.map(
                ({ cells, noSeparator, smallText, smallPadding }, rowIndex) => (
                  <tr
                    key={String(`${title}${rowIndex}`)}
                    className={`${noSeparator ? styles.noSeparator : ''} ${
                      smallText ? styles.smallText : ''
                    } ${smallPadding ? styles.smallPadding : ''}`}
                  >
                    {cells?.map((cell, cellIndex) =>
                      renderDataCell(cell, cellWidth, `${cellIndex}`)
                    )}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </ScrollSyncPane>
    ) : (
      <table className={`p-p w100 ${styles.table}`}>
        <tbody>
          {rows.map(
            ({ cells, noSeparator, smallText, smallPadding }, rowIndex) => (
              <tr
                key={String(`${title}${rowIndex}`)}
                className={`${noSeparator ? styles.noSeparator : ''} ${
                  smallText ? styles.smallText : ''
                } ${smallPadding ? styles.smallPadding : ''}`}
              >
                {cells?.map((cell, cellIndex) =>
                  renderDataCell(cell, cellWidth, `${cellIndex}`)
                )}
              </tr>
            )
          )}
        </tbody>
      </table>
    );
  return (
    <>
      {coverageTableData.map(
        ({ title: sectionTitle, iconSrc, rows }, index) => {
          const sectionId = `${sectionTitle}${index}`;

          return (
            <AccordionItem
              title={sectionTitle}
              iconSrc={iconSrc}
              headerClassName="p24"
              isOpen={selectedSection === sectionId}
              onOpen={() => setSelectedSection(sectionId)}
              onClose={() => setSelectedSection('')}
              key={sectionId}
            >
              {getContent({ rows, title: sectionTitle })}
            </AccordionItem>
          );
        }
      )}
    </>
  );
};
