import {
  IConfig,
  InMemoryStorageProvider,
  LocalStorageProvider,
} from '@unleash/proxy-client-react';

export const unleashConfig: IConfig = {
  url: process.env.REACT_APP_UNLEASH_API_URL ?? '',
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY ?? '',
  refreshInterval: 0,
  appName: 'feather-app',
  environment: process.env.REACT_APP_ENVIRONMENT,
  storageProvider: process.env.TEST
    ? new InMemoryStorageProvider()
    : new LocalStorageProvider(),
};
