import { CheckoutInfo, mapVerticalId } from 'features/checkout/models';
import { InsuranceTypes } from 'models/insurances/types';
import { AppState } from 'reducers';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';

type QuestionnaireWithOptionalCheckoutInfo<T> =
  GenericQuestionnaireState[keyof GenericQuestionnaireState] & {
    checkoutInfo?: CheckoutInfo<T>;
  };

export const getCheckoutInfo =
  <T>(insuranceType: InsuranceTypes) =>
  (state: AppState) => {
    const questionnaireKey = mapVerticalId[insuranceType];

    if (!questionnaireKey) {
      return undefined;
    }

    const questionnaireState = state.genericQuestionnaire[
      questionnaireKey
    ] as QuestionnaireWithOptionalCheckoutInfo<T>;
    // eslint-disable-next-line no-prototype-builtins
    return questionnaireState?.hasOwnProperty('checkoutInfo')
      ? questionnaireState.checkoutInfo
      : undefined;
  };
