import { Badge, Edit3Icon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { useSafeTranslation } from 'shared/i18n';

import styles from './EditableSlider.module.scss';
import { formatNumber } from './utils';

export type SliderOptions = {
  darkBG?: boolean;
  hideKnobAndValue?: boolean;
  shortenNumbers?: boolean;
  bubbleVariant?: boolean;
  noFormatting?: boolean;
  showRecommendationBubble?: boolean;
  boldValue?: boolean;
  boldLabels?: boolean;
  noEdit?: boolean;
};

type EditableSliderProps = {
  className?: string;
  disabled?: boolean;
  label: string;
  sublabel?: string;
  valueSuffix?: string;
  max: number;
  min: number;
  onEditClick: () => void;
  recommendedValue?: number;
  setValue: (value: number) => void;
  step: number;
  value: number;
  onChangeStart?: () => void;
  onChangeEnd?: () => void;
  options?: SliderOptions;
};

export const EditableSlider = ({
  options: optionsParam,
  className = '',
  disabled = false,
  label,
  sublabel,
  max,
  min,
  onEditClick,
  onChangeStart,
  onChangeEnd,
  recommendedValue,
  setValue,
  step,
  valueSuffix,
  value,
}: EditableSliderProps) => {
  const { t } = useSafeTranslation();

  const options = {
    boldLabels: true,
    ...optionsParam,
  };

  const recommendedValuePercentage = recommendedValue
    ? ((recommendedValue - min) / (max - min)) * 100
    : 0;

  const currentValuePercentage = value
    ? ((value - min) / (max - min)) * 100
    : 0;

  const nDigitsShown = Number.isInteger(value) ? 0 : 2;

  const valueStr = `${getValueStr(value, nDigitsShown, options)}${
    valueSuffix ?? ''
  }`;

  const minStr = options?.noFormatting
    ? min
    : formatNumber(min, options?.shortenNumbers);
  const maxStr = options?.noFormatting
    ? max
    : formatNumber(max, options?.shortenNumbers);

  return (
    <div
      className={`w100 ${className} ${styles.container} ${
        disabled ? styles.disabled : ''
      }`}
    >
      {!options?.bubbleVariant && (
        <>
          <div className="d-flex ai-center c-gap8 f-wrap">
            <p
              className={classNames('p-p tc-grey-700', {
                'fw-bold': options?.boldLabels,
              })}
            >
              {label}
            </p>
            <span
              className={classNames('tc-grey-700', {
                'fw-bold': options?.boldValue,
              })}
            >
              {valueStr}
            </span>
            {sublabel && (
              <p
                className={classNames('p-p tc-grey-700', {
                  'fw-bold': options?.boldLabels,
                })}
              >
                {sublabel}
              </p>
            )}
            {!options?.noEdit && (
              <button
                className={styles.editButton}
                onClick={onEditClick}
                disabled={disabled}
                type="button"
                aria-label="edit"
              >
                <Edit3Icon size={16} color="primary-500" />
              </button>
            )}
          </div>
          {recommendedValue && !options?.showRecommendationBubble && (
            <p
              className={`p-p--small fw-bold tc-grey-500 ${styles.weRecommend}`}
            >
              {`${t(
                'components.editableslider.werecommend.text',
                'We recommend'
              )} ${formatNumber(recommendedValue, options?.shortenNumbers)}`}
            </p>
          )}
        </>
      )}
      <div className={styles.sliderContainer}>
        <div
          className={`${styles.sliderBackground} ${
            options?.darkBG ? styles.darkBG : ''
          }`}
        />
        <input
          className={`${styles.slider} ${
            options?.hideKnobAndValue ? styles.hideKnob : ''
          }`}
          type="range"
          min={min}
          max={max}
          value={value}
          onChange={(e) => setValue(Number(e.target.value))}
          step={step}
          disabled={disabled}
          onMouseDown={() => onChangeStart?.()}
          onTouchStart={() => onChangeStart?.()}
          onMouseUp={() => onChangeEnd?.()}
          onTouchEnd={() => onChangeEnd?.()}
        />
        {options?.bubbleVariant && !options?.hideKnobAndValue && (
          <div
            className={`${styles.valueBubbleContainer}`}
            style={{
              left: `calc(${currentValuePercentage}% `,
              transform: `translateX(${
                -(currentValuePercentage / 100) * 20 + 10
              }px)`,
            }}
          >
            <div className={`d-flex c-gap8 br8 ${styles.valueBubble}`}>
              <p className="p-p tc-grey-700">{valueStr}</p>
              <button
                className={styles.editButton}
                onClick={onEditClick}
                disabled={disabled}
                type="button"
                aria-label="edit"
              >
                <Edit3Icon size={20} color="primary-500" />
              </button>
            </div>
          </div>
        )}
        {recommendedValue && options.showRecommendationBubble && (
          <div
            className={styles.recommendedDot}
            style={{
              left: `${recommendedValuePercentage}%`,
              transform: `translateX(${
                (recommendedValuePercentage / 100) * -18 + 4
              }px)`,
            }}
          >
            <Badge
              className={`bg-spearmint-300 fw-bold ${styles.recommendedBubble}`}
              size="small"
            >
              {t(
                'components.editableslider.recommendedBubble.text',
                'Recommended'
              )}
            </Badge>
          </div>
        )}
      </div>
      <p className={`p-p tc-grey-600 ${styles.legend}`}>
        <span>{minStr}</span>
        <span>{maxStr}</span>
      </p>
    </div>
  );
};

const getValueStr = (
  value: number,
  nDigitsShown: number,
  options?: SliderOptions
) => {
  if (options?.hideKnobAndValue) {
    return '€—';
  }

  return options?.noFormatting
    ? value
    : formatNumber(value, options?.shortenNumbers, nDigitsShown);
};
