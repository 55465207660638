import {
  calculateInsuredSumFromPrice,
  getHouseholdPrices,
  getRecommendedInsuredSum,
} from '@getpopsure/household-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { LinkBadge } from 'components/LinkBadge';
import { SliderWithModal } from 'components/SliderWithModal';
import { HouseholdQuestionnaireRoutes } from 'features/household/models';
import { getPolicyOptionsOrDefaults } from 'features/household/selectors';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import houseIcon from './assets/house.svg';
import infoIcon from './assets/info.svg';
import pinIcon from './assets/pin.svg';
import shieldIcon from './assets/shieldHollow.svg';
import styles from './CalculatorSection.module.scss';
import { InfoBox } from './InfoBox';

const MINIMUM_INSURED_SUM = 10_000;
const MAXIMUM_INSURED_SUM = 250_000;
const SLIDER_STEP_SIZE = 1000;
const MINIMUM_MONTHLY_PREMIUM = 2.33;

type CalculatorSectionProps = {
  onSubmitValue: (answer: unknown) => void;
  questionnaireRoutes: HouseholdQuestionnaireRoutes | undefined;
};

export const CalculatorSection = ({
  onSubmitValue,
  questionnaireRoutes,
}: CalculatorSectionProps) => {
  const { t } = useSafeTranslation();

  const {
    city,
    hasSecurityDevice,
    insuredSum: insuredSumFromReduxState,
    postcode,
    propertyInSqMeters,
  } = useSelector(getPolicyOptionsOrDefaults);

  // Calculate the minimum insured sum rounded to full thousands
  const minimumInsuredSum =
    Math.round(
      Math.max(
        MINIMUM_INSURED_SUM,
        calculateInsuredSumFromPrice(
          MINIMUM_MONTHLY_PREMIUM,
          postcode,
          hasSecurityDevice
        ) || 0
      ) / 1000
    ) * 1000;

  // Calculate the recommended insured sum rounded to full thousands
  const recommendedInsuredSum = Math.max(
    Math.ceil(
      Math.min(
        getRecommendedInsuredSum(propertyInSqMeters),
        MAXIMUM_INSURED_SUM
      ) / 250
    ) * 250,
    minimumInsuredSum
  );

  const [insuredSum, setInsuredSum] = useState(
    insuredSumFromReduxState || recommendedInsuredSum
  );

  const householdPrices = getHouseholdPrices({
    postcode,
    propertyInSqMeters,
    insuredSum,
    hasSecurityDevice,
    bikeInsuredSum: 0,
  });

  const price = householdPrices?.basicPremium || 0;
  const showBelowMinimumFootNote =
    price > 0 && price <= MINIMUM_MONTHLY_PREMIUM;

  const priceStr = householdPrices?.basicPremium
    ? englishFormattedEuroCurrency(
        Math.max(MINIMUM_MONTHLY_PREMIUM, householdPrices.basicPremium),
        2
      )
    : '€ —';

  return (
    <section className={`ml-auto mr-auto bg-grey-100 ${styles.container}`}>
      <div className="d-flex f-wrap ai-center jc-center c-gap72 r-gap40">
        <div
          className={`d-flex fd-column ai-center w100 ${styles.calculatorOutput}`}
        >
          <h3
            className={`p-h1--xl p--serif tc-primary-500 ta-center ${styles.price}`}
          >
            {priceStr}
          </h3>
          <p className="p-p fw-bold tc-primary-500 ta-center">
            {t('page.household.quote.calculator.permonth', 'per month')}
          </p>
          {showBelowMinimumFootNote && (
            <p
              className={`d-inline-flex gap8 ai-center p-p--small tc-grey-600 ${styles.footnote}`}
            >
              <img src={infoIcon} alt="info" />
              {t(
                'page.household.section.calculator.minimummonthlyplan',
                'Minimum monthly plan'
              )}
            </p>
          )}
        </div>
        <div className={`w100 ${styles.calculatorInputs}`}>
          <p className="p-p fw-bold tc-grey-600">
            {t(
              'page.household.quote.calculator.yourhousehold',
              'Your household'
            )}
          </p>
          <div
            className={`d-flex f-wrap ai-center gap8 mb48 ${styles.badgeContainer}`}
          >
            <LinkBadge
              iconSrc={pinIcon}
              iconAlt={t(
                'page.household.quote.calculator.badge.pin.alt',
                'map pin icon'
              )}
              to={questionnaireRoutes?.address?.path || ''}
            >{`${postcode} ${city}`}</LinkBadge>
            <LinkBadge
              iconSrc={houseIcon}
              iconAlt={t(
                'page.household.quote.calculator.badge.house.alt',
                'house icon'
              )}
              to={questionnaireRoutes?.livingSpace?.path || ''}
            >{`${propertyInSqMeters} ${t(
              'page.household.questionnaire.livingspace.unit.sqmeters'
            )}`}</LinkBadge>
            <LinkBadge
              iconSrc={shieldIcon}
              iconAlt={t(
                'page.household.quote.calculator.badge.shield.alt',
                'shield icon'
              )}
              to={questionnaireRoutes?.securityDevices?.path || ''}
            >
              {hasSecurityDevice
                ? t(
                    'page.household.quote.calculator.hassecuritydevices',
                    'Home security devices'
                  )
                : t(
                    'page.household.quote.calculator.hasnosecuritydevices',
                    'No home security devices'
                  )}
            </LinkBadge>
          </div>
          <SliderWithModal
            options={{ darkBG: true, shortenNumbers: true }}
            min={minimumInsuredSum}
            max={MAXIMUM_INSURED_SUM}
            value={insuredSum}
            setValue={setInsuredSum}
            step={SLIDER_STEP_SIZE}
            recommendedValue={recommendedInsuredSum}
            label={t(
              'page.household.quote.calculator.slider.label',
              'Insured sum'
            )}
            modalTextOverrides={{
              title: t(
                'page.household.section.calculator.inputmodal.title',
                'Insured sum'
              ),
              description: t(
                'page.household.section.calculator.inputmodal.description',
                {
                  defaultValue:
                    'Please enter your desired insured sum ranging from {{from}} to {{to}}.',
                  from: englishFormattedEuroCurrency(minimumInsuredSum, true),
                  to: englishFormattedEuroCurrency(MAXIMUM_INSURED_SUM, true),
                }
              ),
              placeholder: t(
                'page.household.section.calculator.inputmodal.placeholder',
                'Coverage'
              ),
              belowMinErrorMessage: t(
                'page.household.section.calculator.inputmodal.error.belowmin',
                {
                  defaultValue:
                    'The smallest insured sum we can offer is {{min}}',
                  min: englishFormattedEuroCurrency(minimumInsuredSum, true),
                }
              ),
              aboveMaxErrorMessage: t(
                'page.household.section.calculator.inputmodal.error.abovemax',
                {
                  defaultValue:
                    'The insured sum exceeds the maximum we can offer ({{max}})',
                  max: englishFormattedEuroCurrency(MAXIMUM_INSURED_SUM, true),
                }
              ),
              buttonCaption: t(
                'page.household.section.calculator.inputmodal.button.caption',
                'Apply'
              ),
            }}
          />
          {insuredSum < recommendedInsuredSum && <InfoBox />}
        </div>
      </div>
      <div className="d-flex fd-column ai-center jc-center mt32">
        <button
          className={`p-btn p-btn--primary ${styles.quoteButton}`}
          onClick={() => onSubmitValue(insuredSum)}
          type="button"
          data-cy="button-continue"
        >
          {t('page.household.quote.calculator.cta.title', 'Customize coverage')}
        </button>
      </div>
    </section>
  );
};
