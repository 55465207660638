import { TFunction } from '@getpopsure/i18n-react';
import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';

import {
  ExpatDependentQuestionnaire,
  ZExpatDependentRequiredAnswersForReviewSchema,
  ZExpatRequiredAnswersForReviewSchema,
} from '../models';

export type ExpatDispatch<T extends Action = never> = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  GenericQuestionnaireAction<'expat'> | T
>;

export const storeDependentGenericQuestionnaireAnswer =
  (answer: Partial<ExpatDependentQuestionnaire>) =>
  (dispatch: ExpatDispatch, getState: () => AppState) => {
    const state = getState();
    const dependent = state.genericQuestionnaire.expat?.dependent;

    dispatch(
      storeGenericQuestionnaireAnswer('expat', {
        dependent: { ...dependent, ...answer },
      })
    );
  };

export const removeDependentGenericQuestionnaireAnswer =
  (questionIds: Array<keyof ExpatDependentQuestionnaire>) =>
  (dispatch: ExpatDispatch, getState: () => AppState) => {
    const dependents = getGenericQuestionnaire(getState()).expat?.dependent;

    const nextState: Record<string, unknown> = {
      ...dependents,
    };
    for (const id of questionIds) {
      if (nextState[id] !== undefined) {
        delete nextState[id];
      }
    }

    dispatch(
      storeGenericQuestionnaireAnswer('expat', {
        dependent: nextState,
      })
    );
  };

export type CreateQuoteDispatch = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  GenericQuestionnaireAction<'household'> | RequestAction
>;

export type CreateQuoteReturnType = {
  status: ActionResponse;
};

export const createPostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'expat'> | RequestAction
  > =>
  async (dispatch: ExpatDispatch<RequestAction>, getState: () => AppState) => {
    const questionnaire = getGenericQuestionnaire(getState()).expat;

    const validationResult =
      ZExpatRequiredAnswersForReviewSchema.safeParse(questionnaire);

    try {
      if (!validationResult.success) {
        throw new Error(
          `[Expat - Submission] Questionnaire answer validation errors:${validationResult.error.toString()}`
        );
      }

      const validatedAnswers = validationResult.data;

      dispatch(setRequestInProcess(true, 'EXPAT_SUBMIT_INFO'));

      if (!questionnaire?.account) {
        await dispatch(
          createAccountV2({
            firstName: validatedAnswers.name.firstName,
            lastName: validatedAnswers.name.lastName,
            gender: validatedAnswers.gender,
            dateOfBirth: validatedAnswers.dateOfBirth,
            email: validatedAnswers.email,
          })
        );
      }

      const {
        data: { id: questionnaireId },
      } = await endpoint.submitExpatQuoteInformation(
        {
          personalInfo: {
            email: validatedAnswers.email,
            name: validatedAnswers.name,
            gender: validatedAnswers.gender,
            dateOfBirth: validatedAnswers.dateOfBirth,
            citizenship: validatedAnswers.citizenship,
            occupation: validatedAnswers.occupation,
            lastPermanentResidency: validatedAnswers.lastPermanentResidency,
            employedAnnualIncome: validatedAnswers.employedAnnualIncome,
          },
          tariffInfo: {
            startDate: validatedAnswers.startDate,
            arrivalDate: validatedAnswers.arrivalDate,
            addDependents: validatedAnswers.addDependents,
            legalGuardian:
              validatedAnswers.parentGuardianName &&
              validatedAnswers.parentGuardianEmail &&
              validatedAnswers.parentGuardianDateOfBirth
                ? {
                    name: validatedAnswers.parentGuardianName,
                    email: validatedAnswers.parentGuardianEmail,
                    dateOfBirth: validatedAnswers.parentGuardianDateOfBirth,
                  }
                : undefined,
          },
        },
        'EXPAT_V2'
      );
      dispatch(
        storeGenericQuestionnaireAnswer('expat', {
          questionnaireId,
        })
      );

      const quoteId = validatedAnswers.quote.selectedQuote.id;

      await generateCheckoutDocuments(questionnaireId, quoteId, 'EXPAT_V2', t);

      dispatch(setRequestInProcess(false, 'EXPAT_SUBMIT_INFO'));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'EXPAT_SUBMIT_INFO')
      );
      Sentry.captureException(
        `[Expat Review] Failed to submit with following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };

export const createDependentPostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'expat'> | RequestAction
  > =>
  async (dispatch: ExpatDispatch<RequestAction>, getState: () => AppState) => {
    const questionnaire = getGenericQuestionnaire(getState()).expat?.dependent;

    const validationResult =
      ZExpatDependentRequiredAnswersForReviewSchema.safeParse(questionnaire);

    try {
      if (!validationResult.success) {
        throw new Error(
          `[Expat - Submission] Questionnaire answer validation errors:${validationResult.error.toString()}`
        );
      }

      const validatedAnswers = validationResult.data;

      dispatch(setRequestInProcess(true, 'EXPAT_SUBMIT_INFO'));

      const {
        data: { id: questionnaireId },
      } = await endpoint.submitExpatQuoteInformation(
        {
          personalInfo: {
            name: validatedAnswers.name,
            gender: validatedAnswers.gender,
            dateOfBirth: validatedAnswers.dateOfBirth,
            citizenship: validatedAnswers.citizenship,
            occupation: validatedAnswers.occupation,
            lastPermanentResidency: validatedAnswers.lastPermanentResidency,
            employedAnnualIncome: validatedAnswers.employedAnnualIncome,
          },
          tariffInfo: {
            arrivalDate: validatedAnswers.arrivalDate,
            startDate: validatedAnswers.startDate,
            isMainPolicyTheLegalGuardian:
              validatedAnswers.isMainPolicyTheLegalGuardian,
            legalGuardian:
              !validatedAnswers.isMainPolicyTheLegalGuardian &&
              validatedAnswers.parentGuardianName &&
              validatedAnswers.parentGuardianEmail &&
              validatedAnswers.parentGuardianDateOfBirth
                ? {
                    name: validatedAnswers.parentGuardianName,
                    email: validatedAnswers.parentGuardianEmail,
                    dateOfBirth: validatedAnswers.parentGuardianDateOfBirth,
                  }
                : undefined,
          },
          mainPolicyId: validatedAnswers.mainPolicyId,
        },
        'EXPAT_V2_DEPENDENT'
      );
      dispatch(
        storeDependentGenericQuestionnaireAnswer({
          questionnaireId,
        })
      );

      const quoteId = validatedAnswers.quote.selectedQuote.id;

      await generateCheckoutDocuments(questionnaireId, quoteId, 'EXPAT_V2', t);

      dispatch(setRequestInProcess(false, 'EXPAT_SUBMIT_INFO'));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'EXPAT_SUBMIT_INFO')
      );
      Sentry.captureException(
        `[Expat Dependent Review] Failed to submit with the following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };
