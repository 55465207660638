import { trackStructuredEvent } from '@getpopsure/analytics';
import { calendly } from '@getpopsure/private-constants';
import classNames from 'classnames';
import Markdown from 'components/markdown';

import styles from './Blocker.module.scss';
import personIcon from './icons/blocker.svg';
import shieldIcon from './icons/blocker2.svg';

type BlockerIconType = 'PERSON' | 'SHIELD' | 'NONE';

type BlockerButtonVariant = 'PRIMARY' | 'SECONDARY';
type BlockerButtonSize = 'REGULAR' | 'WIDE';

const blockerButtonVariantMapping: Record<BlockerButtonVariant, string> = {
  PRIMARY: 'p-btn--primary',
  SECONDARY: 'p-btn--secondary bg-white',
};

const blockerButtonSizeMapping: Record<BlockerButtonSize, string> = {
  REGULAR: 'wmn3',
  WIDE: 'wmn4',
};

const blockerIconMapping: Record<BlockerIconType, React.ReactNode> = {
  PERSON: <img src={personIcon} alt="person icon" />,
  SHIELD: <img src={shieldIcon} alt="shield icon" />,
  NONE: null,
};

type BlockerProps = {
  title: string;
  description: string;
  additionalInfo?: string;
  paragraphClassName?: string;
  iconType?: BlockerIconType;
  buttonProps: BlockerButtonProps[];
};

type BlockerButtonBaseProps = {
  caption: string;
  variant?: BlockerButtonVariant;
  size?: BlockerButtonSize;
};

type CalendlyLinkButtonProps = {
  type: 'calendly';
  trackingCategory: string;
  trackingAction?: string;
  calendlyLink?: string;
};

type RegularLinkButtonProps = {
  type: 'href';
  href: string;
};

type RegularButtonProps = {
  type: 'button';
  onClick: () => void;
};

type BlockerButtonProps = BlockerButtonBaseProps &
  (CalendlyLinkButtonProps | RegularLinkButtonProps | RegularButtonProps);

export const Blocker = ({
  title,
  description,
  additionalInfo,
  paragraphClassName = '',
  iconType = 'PERSON',
  buttonProps,
}: BlockerProps) => (
  <div
    className={`p-container d-flex fd-column ai-center jc-center ${styles.container}`}
  >
    {blockerIconMapping[iconType]}
    <h1 className="p-h1 mt16 mb8 ta-center">{title}</h1>
    <Markdown
      paragraphClassName={`p-p ta-center ${paragraphClassName}`}
      openLinksInNewTab
    >
      {description}
    </Markdown>
    <div
      className={classNames(
        'd-flex fd-column gap16 mt24 ai-center jc-center',
        styles.buttonContainer
      )}
    >
      {buttonProps.map((buttonBlockerProps, index) =>
        getButton({ index, ...buttonBlockerProps })
      )}
    </div>
    {!!additionalInfo && (
      <Markdown
        className="mt24"
        paragraphClassName={`p-p ta-center ${paragraphClassName}`}
        openLinksInNewTab
      >
        {additionalInfo}
      </Markdown>
    )}
  </div>
);

const getButton = ({
  index,
  caption,
  variant = 'PRIMARY',
  size = 'REGULAR',
  ...specificProps
}: BlockerButtonProps & { index: number }) => {
  const className = classNames(
    blockerButtonVariantMapping[variant],
    blockerButtonSizeMapping[size],
    'w100'
  );
  const key = String(`btn-${index}-${caption}`);
  switch (specificProps.type) {
    case 'calendly':
      return (
        <button
          className={className}
          onClick={() => {
            trackStructuredEvent({
              category: specificProps.trackingCategory,
              action:
                specificProps.trackingAction || 'CLICKED_BOOK_AN_APPOINTMENT',
            });
            window.Calendly.initPopupWidget({
              url: specificProps.calendlyLink || calendly.genericHelp,
            });
          }}
          type="button"
          key={key}
        >
          {caption}
        </button>
      );
    case 'href':
      return (
        <a
          href={specificProps.href}
          className={className}
          key={key}
          target="_blank"
          rel="noreferrer"
        >
          {caption}
        </a>
      );
    default:
      return (
        <button
          onClick={specificProps.onClick}
          className={className}
          key={key}
          type="button"
        >
          {caption}
        </button>
      );
  }
};
