import styles from './SegmentedControl.module.scss';

export const SegmentedControl = ({
  onChange,
  selectedValue,
  mapValues,
}: {
  onChange: (value: number) => void;
  selectedValue: number;
  mapValues: Record<number, string>;
}) => {
  return (
    <div className={styles.container}>
      <div
        key="selected"
        className={`${styles.selectedBackground} ${
          styles[`selectedBackground--position-${selectedValue}`]
        }`}
      />
      <button
        key={0}
        className={`ds-interactive-component ${styles.item} ${
          selectedValue === 0 ? styles['item--selected'] : ''
        }`}
        onClick={() => onChange(0)}
        type="button"
      >
        {mapValues[0]}
      </button>
      <button
        key={1}
        className={`ds-interactive-component ${styles.item} ${
          selectedValue === 1 ? styles['item--selected'] : ''
        }`}
        onClick={() => onChange(1)}
        type="button"
      >
        {mapValues[1]}
      </button>
    </div>
  );
};
