import { universities } from '@getpopsure/public-models';
import { AutoSuggestInput, Button } from '@popsure/dirty-swan';
import { Option } from '@popsure/dirty-swan/dist/esm/lib/models/autoSuggestInput';
import {
  ExpatDependentQuestionnaire,
  ExpatQuestionnaire,
} from 'features/expat/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { University } from 'models/recommendationTool/healthQuestionnaire';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import styles from './UniversityName.module.scss';

const isDependentQuestionnaire = (
  answers: Partial<ExpatQuestionnaire | ExpatDependentQuestionnaire>
): answers is ExpatDependentQuestionnaire => {
  return 'mainPolicyId' in answers;
};

export const UniversityName = ({
  value: defaultValue,
  questionnaireAnswers,
  onSubmitValue,
}: CustomComponentProps<ExpatQuestionnaire | ExpatDependentQuestionnaire>) => {
  const [university, setUniversity] = useState<University>(
    (defaultValue as University) ?? ''
  );
  const [selectedValues, setSelectedValues] = useState<Array<Option>>([]);
  const [suggestions, setSuggestions] = useState<Array<Option>>([]);
  const { t } = useSafeTranslation();

  const handleSkipClick = () => onSubmitValue();

  const handleSelectUniversity = () => {
    if (university) onSubmitValue(university);
  };

  const isDependent = isDependentQuestionnaire(questionnaireAnswers);

  return (
    <div className={styles.container}>
      <div className="p-body">
        <h1 className="p-h1">
          {t(
            'expat.qnr.preQuote.university.title',
            "What's the university's name?"
          )}
        </h1>
        <div className="mt16 w100 wmx6">
          <AutoSuggestInput
            placeholder={t(
              'expat.qnr.preQuote.university.autoSuggest.placeholder',
              'University name'
            )}
            currentOption={university ?? ''}
            suggestions={suggestions}
            onChange={(value) => {
              setUniversity(value);
            }}
            handleSuggestionSelected={(value) => {
              setSelectedValues([...selectedValues, value]);
              setUniversity(value.value);
            }}
            handleSuggestionFetchRequest={({ value }) => {
              const filteredOptions = universities
                .map((option) => ({ value: option }))
                .filter((option) =>
                  option.value.toLowerCase().includes(value.toLowerCase())
                );
              setSuggestions(filteredOptions);
            }}
            handleSuggestionClearRequest={() => setSuggestions([])}
            wrapText
          />
        </div>
        <div className={`d-flex f-wrap mt24 ${styles['button-container']}`}>
          <Button
            className="w100"
            disabled={!university}
            onClick={handleSelectUniversity}
          >
            {t('expat.qnr.preQuote.university.button.continue', 'Continue')}
          </Button>
          <Button
            className="w100"
            variant="textColor"
            onClick={handleSkipClick}
          >
            {isDependent
              ? t(
                  'expat.qnr.dependentPreQuote.university.button.skip',
                  'Their university is not listed'
                )
              : t(
                  'expat.qnr.preQuote.university.button.skip',
                  'My university is not listed'
                )}
          </Button>
        </div>
      </div>
    </div>
  );
};
