import { flushRequestError } from 'actions/request';
import {
  flushReferrerCodeError,
  getIsValidReferrerCode,
  mergeUserReferralInfo,
} from 'features/referralEngine/actions';
import { REFERRAL_ENGINE_REQUEST_TYPE } from 'features/referralEngine/constants';
import { ReferralEngineDispatch } from 'features/referralEngine/models';
import {
  getReferrerCodeError,
  getValidReferrerCode,
} from 'features/referralEngine/selectors';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReferrerCodeVerifyView } from './ReferrerCodeVerifyView';

export const ReferrerCodeVerifyContainer = () => {
  const validReferrerCode = useSelector(getValidReferrerCode);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const dispatch = useDispatch<ReferralEngineDispatch>();
  const { loading, error } = useRequestStatus(REFERRAL_ENGINE_REQUEST_TYPE);
  const referrerCodeError = useSelector(getReferrerCodeError);

  const handleSubmitCode = async (newCode: string) => {
    const upperCaseCode = newCode.toUpperCase();
    dispatch(getIsValidReferrerCode(upperCaseCode));
  };

  const handleRemoveValidReferrerCode = async () => {
    setOpenModal(false);
    await dispatch(mergeUserReferralInfo({ validReferrerCode: undefined }));
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => {
    if (error || referrerCodeError) {
      dispatch(flushReferrerCodeError());
      dispatch(flushRequestError(REFERRAL_ENGINE_REQUEST_TYPE));
    }

    setOpenModal(false);
  };

  return (
    <ReferrerCodeVerifyView
      validReferrerCode={validReferrerCode}
      openModal={openModal}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      onSubmitValidReferrerCode={handleSubmitCode}
      onRemoveValidReferrerCode={handleRemoveValidReferrerCode}
      loading={loading}
      error={error?.message || referrerCodeError}
    />
  );
};
