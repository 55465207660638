import { Stripe, StripeElements } from '@stripe/stripe-js';
import classnames from 'classnames';
import Markdown from 'components/markdown';
import PaymentInfo from 'features/checkout/components/PaymentInfo';
import PolicyHolder from 'features/checkout/components/PolicyHolder';
import { CheckoutProps } from 'features/checkout/models';
import { areDocumentsValid } from 'features/checkoutDocuments/actions';
import { DocumentsReview } from 'features/checkoutDocuments/components/DocumentsReview';
import { CheckoutDocument } from 'features/checkoutDocuments/models';
import { PaymentOption } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import AnimateHeight from 'react-animate-height';
import { Link } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

interface Props {
  policyInfo: Partial<CheckoutProps>;
  policyDetailsComponent: JSX.Element;
  icon?: string | null;

  paymentOption: PaymentOption;
  setPaymentOption: (paymentOption: PaymentOption) => void;
  onStripePaymentMethodTypeChange: (type: string) => void;
  isBeforePaypalRedirect: boolean;

  stripe: Stripe | null;
  stripeElements: StripeElements | null;

  loading: boolean;
  error?: string;

  handleCheckout: () => void;

  isValidStartDate?: boolean;
  changeStartDatePath?: string;
  validForSubmission: boolean;
  showPaymentSelector?: boolean;

  documents?: CheckoutDocument[];
}

const CheckoutView = ({
  policyInfo,
  policyDetailsComponent,
  paymentOption,
  setPaymentOption,
  loading,
  error,
  icon,
  handleCheckout,
  isValidStartDate,
  changeStartDatePath,
  validForSubmission,
  onStripePaymentMethodTypeChange,
  isBeforePaypalRedirect,
  showPaymentSelector = true,
  documents,
}: Props) => {
  const { t } = useSafeTranslation();

  const { totalMonthlyPrice, mainPolicy } = policyInfo;

  return (
    <div className="p-body">
      <div
        className={`mt80 mb80 ${styles.container}`}
        data-cy="checkout-container"
      >
        {icon && (
          <img
            className={`${styles.icon} br8`}
            src={icon}
            alt="insurance icon"
          />
        )}
        <h1 className="p-h1 mt16">
          {t('components.checkout.title', 'Review and purchase')}
        </h1>
        <div className={`mt16 ${styles['checkout-info-container']}`}>
          <div className="ml16 mt16">{policyDetailsComponent}</div>
          <div className="ml16 mt16">
            <PolicyHolder {...mainPolicy?.policyHolder} />
            {totalMonthlyPrice && (
              <PaymentInfo
                setPaymentOption={setPaymentOption}
                policyInfo={policyInfo}
                paymentOption={paymentOption}
                onStripePaymentMethodTypeChange={
                  onStripePaymentMethodTypeChange
                }
                showPaymentSelector={showPaymentSelector}
              />
            )}
          </div>
        </div>
        <div className={`mt32 w100 ${styles['button-container']}`}>
          <AnimateHeight duration={300} height={error ? 'auto' : 0}>
            <div className="p-notice p-notice--warning mb16 wmx8">{error}</div>
          </AnimateHeight>
          <AnimateHeight duration={300} height={!isValidStartDate ? 'auto' : 0}>
            <div className="p-notice p-notice--warning mb16 wmx8">
              {t(
                'components.checkout.startDateNotInFuture.errorMessage',
                'The coverage start date must be in the future.'
              )}
            </div>
            {!!changeStartDatePath && (
              <div className="d-flex ai-center jc-center mb32">
                <Link className="p-btn--primary wmn3" to={changeStartDatePath}>
                  {t(
                    'components.checkout.startDateNotInFuture.buttonCaption',
                    'Change start date'
                  )}
                </Link>
              </div>
            )}
          </AnimateHeight>
          {isValidStartDate && (
            <>
              <div
                className={classnames(
                  'd-flex fd-column gap32',
                  styles.buttonDocsContainer
                )}
              >
                {documents && areDocumentsValid(documents) && (
                  <DocumentsReview documents={documents} />
                )}
                <button
                  className={classnames(
                    `p-btn--primary wmn3 ${styles.button}`,
                    {
                      'p-btn--loading': loading,
                    }
                  )}
                  disabled={!validForSubmission}
                  onClick={handleCheckout}
                  data-cy="button-checkout"
                  type="button"
                >
                  {isBeforePaypalRedirect
                    ? t(
                        'components.checkout.button.paypal.caption',
                        'Continue with Paypal'
                      )
                    : t('components.checkout.buttoncaption', 'Buy now')}
                </button>
              </div>
              {!isBeforePaypalRedirect && (
                <Markdown paragraphClassName="p-p--small ta-center wmx8 mt24">
                  {t(
                    'components.checkout.fineprint',
                    'The cover should begin on the chosen start date once you click "Buy now", but only after you have received the official confirmation.'
                  )}
                </Markdown>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckoutView;
