import { Card, Checkbox, SegmentedControl } from '@popsure/dirty-swan';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import SectionHeader from 'components/sectionHeader';
import { SliderWithModal } from 'components/SliderWithModal';
import { useEffect, useState } from 'react';

import { QuoteCustomization as QuoteCustomizationType } from '../../Quote.models';
import styles from './QuoteCustomization.module.scss';

export const QuoteCustomization = ({
  quoteCustomization,
  onOptionsChange,
}: {
  quoteCustomization: QuoteCustomizationType[];
  onOptionsChange: (options: Record<string, unknown>) => void;
}) => {
  const [quoteOptions, setQuoteOptions] = useState<Record<string, unknown>>({});

  useEffect(() => {
    onOptionsChange(quoteOptions);
  }, [quoteOptions, onOptionsChange]);

  return (
    <>
      {quoteCustomization.map(
        ({
          __component,
          title: customizationTitle,
          customizationOptions,
          description,
          optionKey,
          sliderLabel = '',
          sliderSubLabel = '',
          minimumValue = 0,
          maximumValue = 0,
          type = '',
        }) => {
          return (
            <section key={optionKey} className="mt56 mb80">
              <SectionHeader centeredOnMobile title={customizationTitle} />

              <Card
                dropShadow={false}
                classNames={{
                  wrapper: classNames('mt24', styles.card),
                }}
              >
                <Markdown>{description}</Markdown>

                {__component === 'quote-page.quote-customization-slider' && (
                  <SliderWithModal
                    className="my24"
                    label={sliderLabel}
                    sublabel={sliderSubLabel}
                    max={maximumValue}
                    min={minimumValue}
                    setValue={(value) =>
                      setQuoteOptions((prevState) => ({
                        ...prevState,
                        [optionKey]: value,
                      }))
                    }
                    value={Number(quoteOptions?.[optionKey] || 0)}
                  />
                )}

                {type === 'switch' && customizationOptions && (
                  <SegmentedControl
                    className="my24"
                    values={customizationOptions.map(({ label }) => label)}
                    onChange={(index) =>
                      setQuoteOptions((prevState) => ({
                        ...prevState,
                        [optionKey]: customizationOptions?.[index].value,
                      }))
                    }
                    selectedIndex={
                      quoteOptions?.[optionKey]
                        ? customizationOptions.findIndex(
                            ({ value }) =>
                              value === quoteOptions?.[optionKey] || ''
                          )
                        : 0
                    }
                  />
                )}

                {type === 'checkbox' && customizationOptions && (
                  <Checkbox
                    classNames={{ container: 'my24' }}
                    wide
                    inlineLayout
                    value={(quoteOptions?.[optionKey] as string[]) || []}
                    onChange={(value) =>
                      setQuoteOptions((prevState) => ({
                        ...prevState,
                        [optionKey]: value,
                      }))
                    }
                    options={customizationOptions.reduce(
                      (acc: Record<string, string>, { label, value }) => {
                        acc[value] = label;
                        return acc;
                      },
                      {}
                    )}
                  />
                )}
              </Card>
            </section>
          );
        }
      )}
    </>
  );
};
