import { captureException } from '@sentry/react';
import { Bike } from 'features/bike/models';
import { getAgeFromDob } from 'shared/util/getAgeFromDob';

import {
  bikeToQuoteLookup,
  COMMISSION,
  TAX,
  zoneLookup,
  zoneMapping,
} from './models';
import { getInsuredAgeGroup, getPremiumFromBikePrice } from './utils';

export const getQuote = (questionnaireAnswers: Partial<Bike>) => {
  try {
    const {
      type,
      postalCode,
      dateOfBirth,
      isBikeAgeOverOneYear,
      purchasePrice,
    } = questionnaireAnswers;

    if (
      !(
        type &&
        postalCode &&
        dateOfBirth &&
        isBikeAgeOverOneYear &&
        purchasePrice
      )
    )
      return;

    const bikeAgeCategory = isBikeAgeOverOneYear === 'YES' ? 'USED' : 'NEW';
    const zone = zoneMapping[zoneLookup[postalCode]];
    const insuredAgeGroup = getInsuredAgeGroup(getAgeFromDob(dateOfBirth));

    if (!(zone && insuredAgeGroup)) return; // couldn't find matching category

    const premiumByBikePrice =
      bikeToQuoteLookup[type][bikeAgeCategory][zone][insuredAgeGroup];

    const premium = getPremiumFromBikePrice(purchasePrice, premiumByBikePrice);
    if (!premium) return; // couldn't find matching category

    const monthlyPrice = Number(
      ((premium * (1 + COMMISSION) * (1 + TAX)) / 12).toFixed(2)
    );
    return monthlyPrice;
  } catch (error) {
    const message = `Failed to get quote: ${error}`;
    captureException(error, {
      tags: {
        feature: 'CREATE_QUOTE',
        vertical: 'BIKE_V2',
      },
      extra: {
        description: 'Failed to get quote',
      },
    });
    throw new Error(message);
  }
};
