import { getTrackingObject } from '@getpopsure/tracker';
import {
  HouseholdAnswersBackendSchema,
  HouseholdQuestionnaireLabels,
  HouseholdQuotesDto,
  HouseholdQuotesResponse,
} from 'features/household/models';
import { Client } from 'shared/api';

export function createHouseholdQuestionnaire(
  this: Client,
  answers: HouseholdAnswersBackendSchema,
  labels: HouseholdQuestionnaireLabels
) {
  const source = getTrackingObject();

  return this.network.post<{ id: string }>('/questionnaires/', {
    answers,
    questionnaireType: 'HOUSEHOLD',
    metadata: { source, ...labels },
  });
}

export function createHouseholdQuote(
  this: Client,
  quotesDto: HouseholdQuotesDto
) {
  return this.network.post<HouseholdQuotesResponse>(
    '/signups/household/quotes',
    quotesDto
  );
}
