import hashSum from 'hash-sum';
import { UserWithBrokerMandate } from 'models/user';

import { INTERCOM_BASE_SETTINGS } from '../../constants';
import { IntercomSettings } from '../../models';
import { getVerificationHash } from '../getVerificationHash';

const updateIntercomSettings = (
  settings: Partial<IntercomSettings>,
  openAfterReboot = true
) => {
  const newSettings = {
    ...INTERCOM_BASE_SETTINGS,
    ...settings,
  };
  const isEqual = hashSum(window.intercomSettings) === hashSum(newSettings);

  if (isEqual) {
    window.Intercom('show');
    return;
  }

  window.intercomSettings = newSettings;

  if (!window.Intercom) {
    window.Intercom('boot', window.intercomSettings);
    window.Intercom('show');
    return;
  }

  /**
   * If settings have changed,
   * restart Intercom with new settings
   */
  window.Intercom('shutdown');
  window.Intercom('boot', window.intercomSettings);

  if (openAfterReboot) {
    window.Intercom('show');
  }
};

export const bootIntercomWithUserData = async (
  user: UserWithBrokerMandate | undefined
) => {
  const clearSettings: Partial<IntercomSettings> = {
    name: undefined,
    email: undefined,
    user_id: undefined,
    user_hash: undefined,
  };

  if (!user) {
    updateIntercomSettings(clearSettings);
    return;
  }

  const payload = await getVerificationHash();

  if (payload.status === 'UNAUTHORIZED') {
    /**
     * If the hash is not valid
     * clear the settings and prevent Intercom to show after rebooting
     */
    updateIntercomSettings(clearSettings, false);
    return;
  }

  const settingsWithUserData = {
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    user_id: user.id,
    ...(payload.status === 'OK' ? { user_hash: payload.hash } : {}),
  };

  updateIntercomSettings(settingsWithUserData);
};
