import dayjs from 'dayjs';
import Cell from 'features/checkout/components/Cell';
import { getCheckoutInfo } from 'features/checkout/selectors';
import { CheckoutPolicyDetails } from 'features/dogLiability/models';
import { ReferrerCodeVerify } from 'features/referralEngine';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { formatCurrencyWithSpace } from 'shared/util/formatCurrencyWithSpace';

import styles from './PolicyDetails.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

export const PolicyDetails = () => {
  const { t } = useSafeTranslation();

  const checkoutInfo = useSelector(
    getCheckoutInfo<CheckoutPolicyDetails>('DOG_LIABILITY')
  );

  const deductibleAmount =
    checkoutInfo?.mainPolicy.policyDetails.deductibleAmount;
  const dogName = checkoutInfo?.mainPolicy.policyDetails.dogName;

  return (
    <div className={styles.container}>
      <div className="p-h2 mb16">
        {t('dog.qnr.checkout.policydetails.title', 'Policy details')}
      </div>
      <Cell
        title={t(
          'dog.qnr.checkout.policydetails.requestedStartDate',
          'Requested start date'
        )}
        value={dayjs(checkoutInfo?.mainPolicy.startDate).format(DATE_FORMAT)}
        additionalInfo={{
          title: t(
            'dog.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
            'Requested start date'
          ),
          description: t(
            'dog.qnr.checkout.policydetails.requestedStartDate.additionalInfo.description',
            'Your coverage should begin on {{coverageDate}}, but only after you have received the official confirmation.',
            {
              coverageDate: dayjs(checkoutInfo?.mainPolicy.startDate).format(
                DATE_FORMAT
              ),
            }
          ),
        }}
      />
      {deductibleAmount !== undefined && (
        <Cell
          title={t('dog.qnr.checkout.policydetails.deductible', 'Deductible')}
          value={formatCurrencyWithSpace(deductibleAmount, 2)}
        />
      )}
      {dogName && (
        <Cell
          title={t('dog.qnr.checkout.policydetails.dogName', "Dog's name")}
          value={dogName}
        />
      )}
      <div className={`bg-grey-200 mt32 mb32 ${styles.divider}`} />
      {checkoutInfo?.mainPolicy.monthlyPrice && (
        <div className="d-flex">
          <div className="p-h3">
            {t(
              'page.household.questionnaire.checkout.policydetails.total',
              'Total per month'
            )}
          </div>
          <div className="p-h3 ml-auto">
            {formatCurrencyWithSpace(checkoutInfo?.mainPolicy.monthlyPrice, 2)}
          </div>
        </div>
      )}
      <ReferrerCodeVerify />
    </div>
  );
};
