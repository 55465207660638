import { CoverageId, QuotePlan } from 'models/expat';

type CardState = 'actionable' | 'static' | 'muted';

export const isCoveredInPlan = (
  coveredInBasic: boolean,
  selectedPlan: QuotePlan
): boolean => selectedPlan === 'PREMIUM' || coveredInBasic === true;

export const retrieveCardState = (
  coveredInBasic: boolean,
  selectedPlan: QuotePlan,
  id?: CoverageId
): CardState => {
  if (isCoveredInPlan(coveredInBasic, selectedPlan) === true) {
    if (id !== undefined) {
      return 'actionable';
    }
    return 'static';
  }

  return 'muted';
};
