import {
  BottomOrRegularModal,
  Button,
  MultiDropzone,
  UploadedFile,
} from '@popsure/dirty-swan';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

import styles from './UploadLetterModal.module.scss';

interface UploadLetterModalProps {
  error?: string;
  loading: boolean;
  uploadedFiles: Array<UploadedFile>;
  validFile: boolean;

  onClose(): void;

  onSubmit(): void;

  onSelectFile(files: File[]): void;

  onRemoveFile(): void;
}

export const UploadLetterModal = (props: UploadLetterModalProps) => {
  const { t } = useSafeTranslation();
  const {
    error,
    loading,
    uploadedFiles,
    validFile,
    onClose,
    onRemoveFile,
    onSelectFile,
    onSubmit,
  } = props;
  return (
    <BottomOrRegularModal
      className={styles.modal}
      title={t(
        'studentChecklist.letterOfAcceptance.uploadModal.title',
        'Upload your letter of acceptance'
      )}
      onClose={onClose}
      isOpen
    >
      <div className={classNames('w100 mx8', styles.container)}>
        <MultiDropzone
          maxFiles={1}
          maxSize={10 * 1024 * 1024}
          onFileSelect={onSelectFile}
          uploadedFiles={uploadedFiles}
          uploading={false}
          onRemoveFile={onRemoveFile}
        />
        <AnimateHeight duration={300} height={error ? 'auto' : 0}>
          <div className="p-notice p-notice--danger mt16 w100">{error}</div>
        </AnimateHeight>
        <Button
          data-testid="submit-proof-of-enrollment"
          className="w100 wmx3 mt16"
          onClick={onSubmit}
          disabled={!validFile}
          loading={loading}
        >
          {t(
            'studentChecklist.letterOfAcceptance.uploadModal.submitButton.title',
            'Submit'
          )}
        </Button>
      </div>
    </BottomOrRegularModal>
  );
};
