import { CheckIcon } from '@popsure/dirty-swan';
import classnames from 'classnames';
import { Fragment } from 'react';

import styles from './style.module.scss';

const StepBar = ({
  currentStep,
  steps,
}: {
  steps: number;
  currentStep: number;
}) => {
  const totalSteps = [...Array(steps).keys()].map((step) => step + 1);
  return (
    <div className={styles.container}>
      {totalSteps.map((step) => {
        const isStepPassedOrCurrent = currentStep >= step;
        return (
          <Fragment key={step}>
            {step !== 1 && (
              <div
                className={classnames(styles.bar, {
                  [styles['current-bar']]: isStepPassedOrCurrent,
                })}
              />
            )}
            <div
              className={classnames(styles.step, {
                [styles['current-step']]: isStepPassedOrCurrent,
              })}
            >
              {currentStep > step ? (
                <CheckIcon size={20} />
              ) : (
                <span>{step}</span>
              )}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default StepBar;
