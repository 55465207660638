import { HouseholdClaims } from 'features/householdClaims/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';

import { HouseholdClaimsGettingStartedView } from './GettingStarted.view';

export const HouseholdClaimsGettingStarted = ({
  onSubmitValue,
  metaData: { policyId },
}: CustomComponentProps<HouseholdClaims>) => {
  const { t } = useSafeTranslation();

  const handleSubmit = () => {
    onSubmitValue(true);
  };

  return (
    <HouseholdClaimsGettingStartedView
      handleGetStarted={handleSubmit}
      t={t}
      policyId={policyId}
    />
  );
};
