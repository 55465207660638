import { InsuranceTypes } from 'models/insurances/types';

import { CommonClaimAnswers } from './verticals/models';

const prefix = 'COMMON_CLAIMS';

export const storeCommonClaimAnswers = ({
  insuranceType,
  answers,
}: {
  insuranceType: InsuranceTypes;
  answers: Partial<CommonClaimAnswers>;
}) => {
  const sessionKey = `${prefix}_${insuranceType}`;
  localStorage.setItem(sessionKey, JSON.stringify(answers));
};

export const flushCommonClaimAnswers = ({
  insuranceType,
}: {
  insuranceType: InsuranceTypes;
}) => {
  const sessionKey = `${prefix}_${insuranceType}`;
  localStorage.removeItem(sessionKey);
};

export const loadCommonClaimAnswers = ({
  insuranceType,
}: {
  insuranceType: InsuranceTypes;
}): Partial<CommonClaimAnswers> => {
  try {
    const sessionKey = `${prefix}_${insuranceType}`;
    const answersStr = localStorage.getItem(sessionKey);

    if (answersStr) {
      return JSON.parse(answersStr as string);
    }
    return {};
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
    return {};
  }
};
