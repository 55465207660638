import { trackTaskEngineCardClicked } from '@getpopsure/analytics';
import { toast } from '@popsure/dirty-swan';
import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import routes from 'constants/routes';
import { TASK_ENGINE_SENTRY_CATEGORY } from 'features/taskEngine/constants';
import { FormEvent, useContext, useState } from 'react';
import { useLocation } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import { updateTask } from '../../api/taskEngine.api';
import { CustomTask } from '../../components/CustomTask';
import type { UploadedFile } from '../../hooks/useFileUpload';
import {
  PrivateHealthWorkContractUploadDataType,
  PrivateHealthWorkContractUploadDescriptionSchema,
  Task,
  TaskEngineOptions,
} from '../../taskEngine.models';
import { TaskEngineContext } from '../../utils/taskEngine.context';
import { updateTaskMutationErrorHandler } from '../../utils/updateTaskMutationErrorHandler';

export const PrivateHealthWorkContractUpload = ({
  task,
  options,
}: {
  task: Task;
  options?: TaskEngineOptions;
}) => {
  const { t } = useSafeTranslation();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const {
    files: { uploadedFiles, resetUploadedFiles },
  } = useContext(TaskEngineContext);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (payload: PrivateHealthWorkContractUploadDataType) => {
      return updateTask({
        taskId: task.id,
        payload,
      });
    },
    onSuccess: async () => {
      toast(
        t(
          'taskEngine.privateHealthWorkContractUpload.toast.success',
          'Task completed'
        ),
        { type: 'success' }
      );
      await queryClient.invalidateQueries({ queryKey: ['tasks'] });
      if (location.pathname === routes.me.policies.detail.path)
        window.location.reload();
    },
  });

  const parsedTask = PrivateHealthWorkContractUploadDescriptionSchema.safeParse(
    task.description
  );

  if (!parsedTask.success) {
    Sentry.captureMessage(
      `${TASK_ENGINE_SENTRY_CATEGORY} Failed to parse private health work contract upload task`,
      {
        level: 'error',
        extra: {
          task,
          validationErrors: parsedTask.error,
        },
      }
    );
    return null;
  }

  const onOpen = () => {
    trackTaskEngineCardClicked({
      task_type: task.description.type,
      task_id: task.id,
    });

    if (options?.onTaskClick) {
      options.onTaskClick(task);
    }

    setIsOpen(true);
  };

  const onClose = () => {
    resetUploadedFiles();
    setIsOpen(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      uploadIds: uploadedFiles.map(
        (uploadedFile: UploadedFile) => uploadedFile.id
      ),
    };

    mutation.mutate(data, {
      onSuccess: () => {
        resetUploadedFiles();
        setIsOpen(false);
      },
      onError: (error) => updateTaskMutationErrorHandler(error),
    });
  };

  const subtitle = `${t(
    'taskEngine.privateHealthWorkContractUpload.subtitle',
    'Private'
  )}`;

  return (
    <div>
      <CustomTask
        metadata={{
          title: t(
            'taskEngine.privateHealthWorkContractUpload.title',
            'Upload your work contract'
          ),
          subtitle,
          description: t(
            'taskEngine.privateHealthWorkContractUpload.description',
            'Please upload your work contract to your account as soon as possible, so we can confirm your coverage. It must be signed by you and your employer, and show your salary. The contract must be valid for at least 12 more months. Alternatively, you can provide a letter from your HR that meets these criteria.'
          ),
        }}
        modal={{
          isOpen,
          onOpen,
          onClose,
        }}
        onSubmit={handleSubmit}
        actions={[
          {
            id: '1',
            type: 'FILE_QUESTIONNAIRE',
            attributes: {
              onValidate: () => uploadedFiles.length > 0,
              questionId: 'workContract',
              maxFiles: 1,
            },
          },
          {
            id: '2',
            type: 'SUBMIT',
            attributes: {
              value: t(
                'taskEngine.privateHealthWorkContractUpload.submit.title',
                'Submit'
              ),
            },
          },
        ]}
      />
    </div>
  );
};
