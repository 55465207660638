import { NavButton } from 'components/NavButton';
import PageNotFound from 'components/pageNotFound';
import { associatedPathForLiabilityRoutes } from 'constants/routes';
import AuthenticatedRoute from 'containers/authenticatedRoute';
import Account from 'features/liability/pages/account';
import Blocker from 'features/liability/pages/blocker';
import Checkout from 'features/liability/pages/checkout';
import Email from 'features/liability/pages/email';
import Address from 'features/liability/pages/questionnaire/address';
import CurrentlyLivingInGermany from 'features/liability/pages/questionnaire/currentlyLivingInGermany';
import DateOfBirth from 'features/liability/pages/questionnaire/dateOfBirth';
import Gender from 'features/liability/pages/questionnaire/gender';
import Name from 'features/liability/pages/questionnaire/name';
import NumberOfPreviousClaims from 'features/liability/pages/questionnaire/numberOfPreviousClaims';
import RelocationDate from 'features/liability/pages/questionnaire/relocationDate';
import StartDate from 'features/liability/pages/questionnaire/startDate';
import Quote from 'features/liability/pages/quote';
import Review from 'features/liability/pages/review';
import { useCheckoutStarted } from 'hooks/useCheckoutStarted';
import { PageId } from 'models/liability';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import { isMobileApp } from 'shared/util/isMobileApp';

import { withRedirect } from './containers/withRedirect';

interface RouteProps {
  id: PageId;
  component: // eslint-disable-next-line
  React.ComponentType<any>;
  exact?: boolean;
  requiresAuthentication?: boolean;
}

const allRoutes: RouteProps[] = [
  {
    id: 'inclusiveMembers',
    component: Quote,
    exact: true,
  },
  {
    id: 'numberOfPreviousClaims',
    component: NumberOfPreviousClaims,
    exact: true,
  },
  {
    id: 'blocker',
    component: Blocker,
    exact: true,
  },
  {
    id: 'account',
    component: Account,
    exact: true,
  },
  {
    id: 'email',
    component: Email,
    exact: true,
  },
  {
    id: 'name',
    component: Name,
    exact: true,
  },
  {
    id: 'address',
    component: Address,
    exact: true,
  },
  {
    id: 'dateOfBirth',
    component: DateOfBirth,
    exact: true,
  },
  {
    id: 'gender',
    component: Gender,
    exact: true,
  },
  {
    id: 'currentlyLivingInGermany',
    component: CurrentlyLivingInGermany,
    exact: true,
  },
  {
    id: 'relocationDate',
    component: RelocationDate,
    exact: true,
  },
  {
    id: 'startDate',
    component: StartDate,
    exact: true,
  },
  {
    id: 'quote',
    component: Review,
    exact: true,
  },
  {
    id: 'checkoutInfo',
    component: Checkout,
    exact: true,
    requiresAuthentication: true,
  },
];

const routes = allRoutes.map(
  ({ exact, component, id, requiresAuthentication }) => {
    const componentWithRedirect = withRedirect(component, id);
    const path = associatedPathForLiabilityRoutes[id];

    if (requiresAuthentication) {
      return (
        <AuthenticatedRoute
          key={id}
          exact={exact}
          component={componentWithRedirect}
          path={path}
        />
      );
    }

    return (
      <Route
        key={id}
        exact={exact}
        component={componentWithRedirect}
        path={path}
      />
    );
  }
);

const LiabilityRoutes = () => {
  const email = useSelector(getGenericQuestionnaire)?.liability?.email;

  useCheckoutStarted('LIABILITY', email);

  return (
    <>
      {isMobileApp && <NavButton title="Previous question" />}
      <Switch>
        {routes}
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default LiabilityRoutes;
