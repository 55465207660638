import dayjs from 'dayjs';
import { Policy } from 'models/policies';

export const isPolicyExpired = ({ attributes, status }: Policy): boolean => {
  const activeUntil = attributes?.activeUntil;
  const hasExpiredStatus = ['CANCELED', 'DROPPED_OUT'].includes(status);

  if (activeUntil) {
    const isActiveUntilInTheFuture = dayjs().isAfter(dayjs(activeUntil));

    return hasExpiredStatus && isActiveUntilInTheFuture;
  }

  return hasExpiredStatus;
};
