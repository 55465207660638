import { featherIcon } from 'icons';

const ErrorView = () => (
  <div className="d-flex fd-column ai-center w100 wmx4 m-auto ta-center py24 px16">
    <img src={featherIcon} alt="Feather logo" className="mb32" />
    <h1 className="p-h1 mb8">That didn’t work.</h1>
    <p className="p-p fw-bold">Your photo could not be uploaded.</p>
    <p className="p-p">
      Please return to your main device to continue the process.
    </p>
  </div>
);

export default ErrorView;
