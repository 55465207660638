import { captureException } from '@sentry/browser';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { ErrorWithAction } from 'components/ErrorWithAction';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { flushPrivatePreSignupAnswers } from 'features/privateHealthPreSignup/actions';
import { PrivatePreSignup } from 'features/privateHealthPreSignup/models';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import api from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';

export const FetchQuote = () => {
  const { t } = useSafeTranslation();
  const history = useHistory();

  const { quoteId } = useParams<{ quoteId: string }>();
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState<
    Partial<PrivatePreSignup> | undefined
  >(undefined);
  const dispatch = useDispatch();

  const fetchQuote = async () => {
    try {
      const {
        data: { answers },
      } = await api.getQuestionnaire(quoteId);

      setQuestionnaireAnswers(answers);
    } catch (e) {
      // In case of an error, we notify sentry
      // we still redirect to the quote page (but the routing logic should take care of the rest)
      captureException(e);
    }
  };

  useEffect(() => {
    fetchQuote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetNewQuote = async () => {
    dispatch(flushPrivatePreSignupAnswers);
    history.push(routes.policies.privateHealthV2.path);
  };

  if (questionnaireAnswers) {
    const isLegacyQuestionnaire = !(
      'eligibleForShortTerm' in questionnaireAnswers
    );

    if (isLegacyQuestionnaire) {
      return (
        <ErrorWithAction
          title={t(
            'private.fetchQuote.oldQuote.title',
            "We've updated our plans"
          )}
          description={t(
            'private.fetchQuote.oldQuote.explanation',
            'As we strive to offer you the best coverage, we have recently updated our plans so, therefore, your quote is no longer valid. To benefit from our latest and greatest tariffs, please use the button below to get a new quote.'
          )}
          cta={{
            title: t(
              'private.fetchQuote.oldQuote.ctaCaption',
              'Get a new quote'
            ),
            onClick: handleGetNewQuote,
          }}
        />
      );
    }

    dispatch(
      storeGenericQuestionnaireAnswer('privateHealthPreSignup', {
        welcome: true,
        calculatePrice: false,
        ...questionnaireAnswers,
        quote: undefined,
        showLongTerm: undefined,
      } as Partial<PrivatePreSignup>)
    );

    const quotePageUrlParams = questionnaireAnswers.showLongTerm
      ? '?showLongTerm=true'
      : '';

    return (
      <Redirect
        to={`${routes.policies.privateHealthV2.quote.path}${quotePageUrlParams}`}
      />
    );
  }

  return <LoadingSpinner />;
};
