import { TFunction } from '@getpopsure/i18n-react';
import { Address, allCountryCodes, Country } from '@getpopsure/public-models';
import dayjs from 'dayjs';
import { Name } from 'models/user';
import { ActionResponse, UploadedFile } from 'shared/models/types';
import {
  ZAddressSchema,
  ZNameSchema,
  ZUploadedDocumentsSchema,
} from 'shared/models/validations';
import { BLOCKER, SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

export const ZQuoteSchema = z.object({
  id: z.string().optional(),
  monthlyPayment: z.number(),
  initialAmount: z.number(),
  age: z.number(),
});

export type Quote = z.TypeOf<typeof ZQuoteSchema>;

export type ValidationError =
  | 'INITIAL_AMOUNT_ABOVE_LIMIT'
  | 'AGE_ABOVE_LIMIT'
  | 'AGE_BELOW_LIMIT';

export type CalculatorError =
  | ValidationError
  | 'SUBMISSION_ERROR'
  | 'MISSING_VALUES';

export const ZSubmittablePensionQuote = z.object({
  monthlyPayment: z.number(),
  initialAmount: z.number(),
});

export type SubmittablePensionQuote = z.TypeOf<typeof ZSubmittablePensionQuote>;

export type QuoteResponse = {
  id: string;
  price: number;
  attributes: {
    initialAmount: number;
  };
};

export type CreateQuoteReturnType = {
  status: ActionResponse;
};

export type SubmitQuestionnaireReturnType = {
  status: ActionResponse;
};

export type UpdateQuestionnaireReturnType = {
  status: ActionResponse;
};

// TODO: define the checkout policy details
export type CheckoutPolicyDetails = unknown;

type Review = boolean;

type SepaDetails = {
  iban: string;
  accountHolder: string;
};

export type Pension = {
  quote: Quote;
  intro: SCREEN;

  // investment strategy
  investmentSustainability: string;
  investmentStrategy: InvestmentStrategy;
  savingForRetirement: boolean;
  privatePensionInfo: SCREEN;
  privatePensionLongerThan5Years: boolean;
  privatePensionLongerThan5YearsInfo: SCREEN;
  hasReviewedInvestmentStrategyInfo: Review;

  // personal details
  name: Name;
  email: string;
  dateOfBirth: string;
  under18Blocker: BLOCKER;
  over50Blocker: BLOCKER;
  townOfBirth: string;
  address: Address;
  taxResidency: Country[];
  citizenship: Country;
  identificationDocument: IdentificationDocument;
  uploadPassport: UploadedFile[];
  passportIssueDate: string;
  passportExpiryDate: string;
  uploadIdCard: UploadedFile[];
  idCardIssueDate: string;
  idCardExpiryDate: string;
  isSmoker: boolean;
  hasReviewedPersonalInfo: Review;

  // financial information
  occupation: Occupation;
  paysTaxesInGermany: 'YES' | 'NO';
  paysTaxesInGermanyBlocker: BLOCKER;
  jobTitle: string;
  income: number;
  taxId: number;
  incomeSource: IncomeSource;
  sepa: SepaDetails;
  hasReviewedFinancialHistoryInfo: Review;

  legalDisclaimer: boolean;
  riskConsent?: boolean;
  shortTermLossConsent?: boolean;

  submitApplication: boolean;
  reviewCheckout: boolean;
  review: boolean;

  quoteId: string;
  questionnaireId: string;
};

export const investmentSustainability = [
  'NO_PREFERENCE',
  'FEATHER_ESG',
  'GREEN_INVESTING',
] as const;

export type InvestmentSustainability = typeof investmentSustainability[number];

export const InvestmentSustainabilityMap = (
  t: TFunction
): Record<InvestmentSustainability, string> => ({
  NO_PREFERENCE: t(
    'pension.qnr.investmentSustainiability.option.no_preference',
    "Don't have a preference"
  ),
  FEATHER_ESG: t(
    'pension.qnr.investmentSustainability.option.feather_esg',
    'Follow the Feather ESG best practices'
  ),
  GREEN_INVESTING: t(
    'pension.qnr.investmentSustainability.option.green_investing',
    '100% green investment'
  ),
});

export const investmentStrategy = ['AGGRESSIVE', 'ALL_IN'] as const;

export type InvestmentStrategy = typeof investmentStrategy[number];

export const InvestmentStrategyMap = (
  t: TFunction
): Record<InvestmentStrategy, { title: string; description: string }> => ({
  AGGRESSIVE: {
    title: t(
      'pension.qnr.investmentStrategy.option.agressive.title',
      'Aggressive'
    ),
    description: t(
      'pension.qnr.investmentStrategy.option.aggressive.description',
      '75% ETF investment and 25% bonds'
    ),
  },
  ALL_IN: {
    title: t('pension.qnr.investmentStrategy.option.all_in.title', 'All-in'),
    description: t(
      'pension.qnr.investmentStrategy.option.all_in.description',
      '100% ETF investment, 0% bonds'
    ),
  },
});

const ZCountrySchema = z.object({
  code: z.enum(allCountryCodes),
  name: z.string(),
});

const identificationDocument = ['PASSPORT', 'NATIONAL_ID'] as const;

export type IdentificationDocument = typeof identificationDocument[number];

export type ObjectOrStringOptionType =
  | {
      title: string;
      description: string;
    }
  | string;

export const identificationDocumentMapping = (
  t: TFunction
): Record<IdentificationDocument, ObjectOrStringOptionType> => ({
  PASSPORT: t(
    'pension.qnr.identificationDocument.option.passport.title',
    'Passport'
  ),
  NATIONAL_ID: {
    title: t(
      'pension.qnr.identificationDocument.option.national_id.title',
      'National ID'
    ),
    description: t(
      'pension.qnr.identificationDocument.option.national_id.description',
      'EU, EEA or Switzerland'
    ),
  },
});

const occupation = [
  'EMPLOYED',
  'SELF_EMPLOYED',
  'UNEMPLOYED',
  'APPRENTICE',
  'CIVIL_SERVANT',
  'STUDENT',
  'PENSIONER',
  'OTHER',
] as const;

export type Occupation = typeof occupation[number];

export const occupationMapping = (
  t: TFunction
): Record<Occupation, string> => ({
  EMPLOYED: t('pension.qnr.occupation.option.employed', 'Employed'),
  SELF_EMPLOYED: t(
    'pension.qnr.occupation.option.self_employed',
    'Self-employed'
  ),
  UNEMPLOYED: t('pension.qnr.occupation.option.unemployed', 'Unemployed'),
  APPRENTICE: t('pension.qnr.occupation.option.apprentice', 'Apprentice'),
  CIVIL_SERVANT: t(
    'pension.qnr.occupation.option.civil_servant',
    'Civil servant'
  ),
  STUDENT: t('pension.qnr.occupation.option.student', 'Student'),
  PENSIONER: t('pension.qnr.occupation.option.pensioner', 'Pensioner'),
  OTHER: t('pension.qnr.occupation.option.other_opt', 'Other'),
});

const incomeSource = ['INCOME', 'SAVINGS'] as const;

export type IncomeSource = typeof incomeSource[number];

export const incomeSourceMapping = (
  t: TFunction
): Record<IncomeSource, ObjectOrStringOptionType> => ({
  INCOME: {
    title: t('pension.qnr.incomeSource.option.income.title', 'My income'),
    description: t(
      'pension.qnr.incomeSource.option.income.description',
      'Savings/earned income, rental income, capital gains'
    ),
  },
  SAVINGS: t(
    'pension.qnr.incomeSource.option.savings',
    'Savings in my bank account'
  ),
});

const paysTaxesInGermany = ['YES', 'NO'] as const;

export type PaysTaxesInGermany = typeof paysTaxesInGermany[number];

export const paysTaxesInGermanyMapping = (
  t: TFunction
): Record<PaysTaxesInGermany, string> => ({
  YES: t('pension.qnr.paysTaxesInGermany.yes', 'Yes'),
  NO: t('pension.qnr.paysTaxesInGermany.no', 'No'),
});

export const ZPensionQuestionnaire = z.object({
  // Quote/calculator
  quote: ZQuoteSchema,

  // Investment strategy
  investmentSustainability: z.enum(investmentSustainability),
  investmentStrategy: z.enum(investmentStrategy),
  savingForRetirement: z.boolean(),
  privatePensionInfo: z.optional(z.boolean()),
  privatePensionLongerThan5Years: z.boolean(),
  privatePensionLongerThan5YearsInfo: z.optional(z.boolean()),
  hasReviewedInvestmentStrategyInfo: z.boolean(),

  // Personal info
  name: ZNameSchema,
  email: z.string(),
  dateOfBirth: z.string(),
  townOfBirth: z.string(),
  address: ZAddressSchema,
  taxResidency: z.array(ZCountrySchema),
  citizenship: ZCountrySchema,
  identificationDocument: z.optional(z.enum(identificationDocument)),
  uploadPassport: z.optional(z.array(ZUploadedDocumentsSchema)),
  passportIssueDate: z.optional(z.string()),
  passportExpiryDate: z.optional(z.string()),
  uploadIdCard: z.optional(z.array(ZUploadedDocumentsSchema)),
  idCardIssueDate: z.optional(z.string()),
  idCardExpiryDate: z.optional(z.string()),
  isSmoker: z.boolean(),
  hasReviewedPersonalInfo: z.boolean(),

  // Financial history
  occupation: z.enum(occupation),
  paysTaxesInGermany: z.enum(['YES', 'NO']).optional(),
  jobTitle: z.string().optional(),
  income: z.number(),
  taxId: z.number(),
  incomeSource: z.enum(incomeSource),
  sepa: z.object({
    iban: z.string(),
    accountHolder: z.string(),
  }),
  hasReviewedFinancialHistoryInfo: z.boolean().default(true),
});

export const ZPensionQuestionnaireGrouping = ZPensionQuestionnaire.transform(
  (data) => {
    return {
      tariffInfo: {
        age: data.quote.age,
        monthlyPayment: data.quote.monthlyPayment,
        initialAmount: data.quote.initialAmount,
        startDate: dayjs()
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
      },
      investmentStrategy: {
        investmentSustainability: data.investmentSustainability,
        investmentStrategy: data.investmentStrategy,
        savingForRetirement: data.savingForRetirement,
        privatePensionInfo: data.privatePensionInfo,
        privatePensionLongerThan5YearsInfo:
          data.privatePensionLongerThan5YearsInfo,
        privatePensionLongerThan5Years: data.privatePensionLongerThan5Years,
      },
      personalInfo: {
        name: data.name,
        email: data.email,
        dateOfBirth: data.dateOfBirth,
        townOfBirth: data.townOfBirth,
        address: data.address,
        citizenship: data.citizenship,
        identificationDocument: data.identificationDocument,
        uploadPassport: data.uploadPassport,
        passportIssueDate: data.passportIssueDate,
        passportExpiryDate: data.passportExpiryDate,
        taxResidency: data.taxResidency,
        uploadIdCard: data.uploadIdCard,
        idCardIssueDate: data.idCardIssueDate,
        idCardExpiryDate: data.idCardExpiryDate,
        isSmoker: data.isSmoker,
      },
      financialHistory: {
        occupation: data.occupation,
        paysTaxesInGermany: data.paysTaxesInGermany,
        jobTitle: data.jobTitle,
        income: data.income,
        taxId: data.taxId,
        incomeSource: data.incomeSource,
        sepa: {
          iban: data.sepa.iban.replace(/\s+/g, ''),
          accountHolder: data.sepa.accountHolder,
        },
      },
    };
  }
);

export const ZSubmittablePensionQuestionnaire =
  ZPensionQuestionnaireGrouping.transform((data) => {
    const { personalInfo, ...rest } = data;
    const { uploadIdCard, uploadPassport, ...personalInfoData } = personalInfo;

    return {
      ...rest,
      personalInfo: {
        ...personalInfoData,
      },
    };
  });

const ZPensionQuestionnaireDisclaimer = z
  .object({
    riskConsent: z.boolean(),
    shortTermLossConsent: z.boolean(),
    hasReviewedDocuments: z.boolean(),
  })
  .transform((data) => {
    return {
      ...data,
      legalDisclaimer: {
        riskConsent: data.riskConsent,
        shortTermLossConsent: data.shortTermLossConsent,
        hasReviewedDocuments: data.hasReviewedDocuments,
      },
    };
  });

const ZPensionQuestionnaireWithLegalDisclaimer =
  ZPensionQuestionnaireDisclaimer.transform((data) => {
    return {
      legalDisclaimer: {
        riskConsent: data.riskConsent,
        shortTermLossConsent: data.shortTermLossConsent,
        hasReviewedDocuments: data.hasReviewedDocuments,
      },
    };
  });

export const ZUpdateablePensionQuestionnaire =
  ZSubmittablePensionQuestionnaire.and(
    ZPensionQuestionnaireWithLegalDisclaimer
  );

// export const ZPensionQuestionnaire
export type PensionGroupIds = 'signup';

export type PensionQuestionnaire = z.infer<typeof ZPensionQuestionnaire>;

export type GroupedPensionQuestionnaire = z.infer<
  typeof ZPensionQuestionnaireGrouping
>;
export type SubmittablePensionQuestionnaire = z.infer<
  typeof ZSubmittablePensionQuestionnaire
>;

type QuestionId = keyof PensionQuestionnaire;
type GroupedQuestionId<T extends keyof GroupedPensionQuestionnaire> =
  keyof GroupedPensionQuestionnaire[T];

export type UpdateableQuestionnaire = z.infer<
  typeof ZUpdateablePensionQuestionnaire
>;
type UpdateableQuestionId = keyof UpdateableQuestionnaire['legalDisclaimer'];

export const questionOrderTariffInfo: GroupedQuestionId<'tariffInfo'>[] = [
  'age',
  'monthlyPayment',
  'initialAmount',
];

export const questionOrderInvestmentStrategy: GroupedQuestionId<'investmentStrategy'>[] =
  [
    'investmentSustainability',
    'investmentStrategy',
    'savingForRetirement',
    'privatePensionInfo',
    'privatePensionLongerThan5YearsInfo',
    'privatePensionLongerThan5Years',
  ];

export const questionOrderPersonalInfo: GroupedQuestionId<'personalInfo'>[] = [
  'email',
  'name',
  'dateOfBirth',
  'townOfBirth',
  'address',
  'taxResidency',
  'citizenship',
  'identificationDocument',
  'uploadPassport',
  'passportIssueDate',
  'passportExpiryDate',
  'uploadIdCard',
  'idCardIssueDate',
  'idCardExpiryDate',
  'isSmoker',
];

export const questionOrderFinancialHistory: GroupedQuestionId<'financialHistory'>[] =
  [
    'occupation',
    'paysTaxesInGermany',
    'jobTitle',
    'income',
    'taxId',
    'incomeSource',
    'sepa',
  ];

export const questionOrderLegalDisclaimer: UpdateableQuestionId[] = [
  'riskConsent',
  'shortTermLossConsent',
  'hasReviewedDocuments',
];

export const questionOrder = {
  tariffInfo: questionOrderTariffInfo,
  investmentStrategy: questionOrderInvestmentStrategy,
  personalInfo: questionOrderPersonalInfo,
  financialHistory: questionOrderFinancialHistory,
  legalDisclaimer: questionOrderLegalDisclaimer,
};

export const TitleSchema = z.object({
  tariffInfo: z.record(z.string()),
  investmentStrategy: z.record(z.string()),
  personalInfo: z.record(z.string()),
  financialHistory: z.record(z.string()),
  legalDisclaimer: z.record(z.string()),
});

export const AnswersLabelSchema = z.object({
  personalInfo: z.record(z.record(z.string())),
  investmentStrategy: z.record(z.record(z.string())),
  financialHistory: z.record(z.record(z.string())),
});

export const LabelsSchema = z.object({
  title: TitleSchema,
  answers: AnswersLabelSchema,
});

export type QuestionnaireTitles = z.infer<typeof TitleSchema>;
export type QuestionnaireLabels = z.infer<typeof LabelsSchema>;

export const questionOrderInvestmentStrategyQnr: QuestionId[] = [
  ...questionOrderInvestmentStrategy,
  'hasReviewedInvestmentStrategyInfo',
];

export const questionOrderPersonalInfoQnr: QuestionId[] = [
  ...questionOrderPersonalInfo,
  'hasReviewedPersonalInfo',
];

export const questionOrderFinancialHistoryQnr: QuestionId[] = [
  ...questionOrderFinancialHistory,
  'hasReviewedFinancialHistoryInfo',
];

export const questionGroupsMapping: Partial<Record<QuestionId, QuestionId[]>> =
  {
    hasReviewedInvestmentStrategyInfo: questionOrderInvestmentStrategyQnr,
    hasReviewedPersonalInfo: questionOrderPersonalInfoQnr,
    hasReviewedFinancialHistoryInfo: questionOrderFinancialHistoryQnr,
  };

export const questionnaireIdMapping: Partial<Record<QuestionId, string>> = {
  hasReviewedInvestmentStrategyInfo: 'Investment strategy',
  hasReviewedPersonalInfo: 'Personal information',
  hasReviewedFinancialHistoryInfo: 'Financial history',
};

export type QuestionnaireType = 'PENSION';

const QuestionOrderSchema = z.object({
  tariffInfo: z.array(z.string()),
  personalInfo: z.array(z.string()),
  financialHistory: z.array(z.string()),
  investmentStrategy: z.array(z.string()),
  legalDisclaimer: z.array(z.string()),
});

export const MetadataSchema = z.object({
  labels: LabelsSchema,
  questionOrder: QuestionOrderSchema,
  source: z.record(z.string(), z.unknown()).optional(),
});

export type MetaData = z.infer<typeof MetadataSchema>;
