import { Header, QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { Input } from '@popsure/dirty-swan';
import { ExpatSpain } from 'features/expatSpain/models';
import { useEffect, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

export const Email = ({
  value,
  onSubmitValue,
  setValidForSubmission,
}: QuestionnaireFormProps<ExpatSpain>) => {
  const [email, setEmail] = useState<string>((value as string) ?? '');

  const { t } = useSafeTranslation();

  useEffect(() => {
    setValidForSubmission(email !== '');
  }, [setValidForSubmission, email]);

  return (
    <Header onSubmit={() => onSubmitValue(email)}>
      <Input
        className="mt24"
        type="email"
        autoComplete="email"
        placeholder={t('expatSpain.qnr.email.title', 'Email address')}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        data-cy="dependent-guardian-email"
      />
    </Header>
  );
};
