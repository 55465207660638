import { getTrackingObject } from '@getpopsure/tracker';
import { captureException } from '@sentry/react';
import ErrorView from 'components/error';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { APIResponseError } from 'models/error';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatErrorMessageFromError } from 'selectors/requests';
import endpoint from 'shared/api';
import { trackConversions } from 'shared/trackers';

export const TravelHealthSuccessScreen = () => {
  const formId = useQueryParamValue('formId');
  const [error, setError] = useState<string | undefined>(undefined);
  const history = useHistory();

  useEffect(() => {
    if (formId) {
      const generatePolicy = async () => {
        try {
          const {
            data: { id },
          } = await endpoint.createGenericPolicy({
            formId,
            providerId: 'HANSE_MERKUR',
            insuranceType: 'TRAVEL',
            source: getTrackingObject(),
          });

          await trackConversions({
            verticalId: 'TRAVEL',
            policyId: id,
            regionOfPurchase: 'de',
          });

          history.push(routes.me.policies.path);
        } catch (e) {
          const errorMessage = formatErrorMessageFromError(
            e as APIResponseError
          );
          setError(errorMessage);
          captureException(e, {
            tags: {
              feature: 'TRAVEL_HEALTH',
            },
          });
        }
      };
      generatePolicy();
    } else {
      throw new Error('[Travel Health] Missing formId for generating policy\n');
    }
  }, [formId, history]);

  if (error) {
    return <ErrorView error={error} />;
  }

  return <LoadingSpinner />;
};
