import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { Button } from '@popsure/dirty-swan';
import { ExpatSpain } from 'features/expatSpain/models';
import { useSafeTranslation } from 'shared/i18n';

import styles from './AddDependents.module.scss';
import familyIcon from './assets/family.svg';

export const AddDependents = ({
  onSubmitValue,
}: QuestionnaireFormProps<ExpatSpain>) => {
  const { t } = useSafeTranslation();
  return (
    <div
      className={`p-container d-flex fd-column ai-start jc-center wmx10 ${styles.container}`}
    >
      <img src={familyIcon} alt="family icon" />
      <h1 className="p-h1 mt16 mb8 ta-center">
        {t(
          'expatSpain.qnr.addFamilyMembers.title',
          'Have loved ones to cover?'
        )}
      </h1>
      <div className="p-p ai-start">
        {t(
          'expatSpain.qnr.addFamilyMembers.description',
          'After purchasing this policy, you can buy and manage additional policies for your loved ones.'
        )}
      </div>
      <div className="d-flex fd-row gap16 mt24 ai-center jc-center">
        <Button
          onClick={() => onSubmitValue(false)}
          data-cy="dependents-cover-no"
        >
          {t('expatSpain.qnr.addFamilyMembers.cta.noLabel', "No, it's just me")}
        </Button>
        <Button
          onClick={() => onSubmitValue(true)}
          variant="filledWhite"
          data-cy="dependents-cover-yes"
        >
          {t(
            'expatSpain.qnr.addFamilyMembers.cta.yesLabel',
            'Yes, ask me later'
          )}
        </Button>
      </div>
    </div>
  );
};
