import routes from 'constants/routes';
import { checkWhite } from 'icons';
import { generatePath, Link, useParams } from 'react-router-dom';

import styles from './Submitted.module.scss';

export const Submitted = () => {
  const { policyId } = useParams<{ policyId: string }>();
  return (
    <div className={`d-flex p-body ai-center jc-center ${styles.container}`}>
      <div className="p-body d-flex fd-column jc-center mt80 mb80 ai-center">
        <img src={checkWhite} className={styles.image} alt="check circle" />
        <h1 className="p-h2 mt32">Beneficiaries added!</h1>
        <p className="p-p mt8 tc-grey-700 ta-center wmx8">
          You can view the list of your beneficiaries in your policy.
        </p>
        <Link
          className={`p-btn--secondary wmn3 mt16 ${styles.button}`}
          to={generatePath(routes.me.policies.detail.path, {
            policyId,
          })}
        >
          Back to policy
        </Link>
      </div>
    </div>
  );
};
