import { Policy } from 'models/policies';

import { CancelPolicyButton, ContactUsButton } from '../../components/buttons';
import { PolicyDetailsButton } from '../../sections/PolicyDetails/PolicyDetails.models';
import { DentalCreateClaimButton } from './buttons/DentalCreateClaimButton';

export const getDentalButtons = (policy: Policy): PolicyDetailsButton[] => {
  const buttons: PolicyDetailsButton[] = [
    { id: 'CONTACT_US', component: ContactUsButton },
  ];

  if (policy.status === 'ACTIVE') {
    buttons.push({
      id: 'MAKE_CLAIM_LEGAL',
      component: DentalCreateClaimButton,
    });
  }

  if (policy.status !== 'CANCELED') {
    buttons.push({ id: 'CANCEL_POLICY', component: CancelPolicyButton });
  }

  return buttons;
};
