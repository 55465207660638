import Navigation from 'components/common/navigation';
import ErrorView from 'components/error';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import {
  useLoadPolicyDetail as _useLoadPolicyDetail,
  useLoadUserClaims as _useLoadUserClaims,
} from 'features/claimsProcess/hooks';
import { ClaimStatuses } from 'models/claims';
import { getTitleMapping } from 'models/insurances/types/mapping';
import { useParams as _useParams } from 'react-router';
import { generatePath, Link } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import ClaimCell from './claimCell';
import claimsEmptyIcon from './icons/claims-empty.svg';
import plusIcon from './icons/plus.svg';
import styles from './style.module.scss';

const MyClaims = ({
  useLoadPolicyDetail = _useLoadPolicyDetail,
  useParams = _useParams,
  useLoadUserClaims = _useLoadUserClaims,
}: {
  useLoadPolicyDetail?: typeof _useLoadPolicyDetail;
  useParams?: typeof _useParams;
  useLoadUserClaims?: typeof _useLoadUserClaims;
}) => {
  const { t } = useSafeTranslation();

  const { policyId } = useParams<{ policyId: string }>();
  const {
    loading: loadingPolicyDetail,
    error: errorPolicyDetail,
    policy,
  } = useLoadPolicyDetail(policyId);
  const {
    loading: loadClaims,
    error: errorClaims,
    claims,
  } = useLoadUserClaims(policy?.type);

  const makeAClaimPath =
    policy?.type === 'INCOMING'
      ? routes.claims.expat.path
      : routes.me.claims.claimSteps.path;

  if (loadingPolicyDetail === true || loadClaims === true) {
    return <LoadingSpinner />;
  }

  if (errorPolicyDetail !== undefined) {
    return <ErrorView error={errorPolicyDetail} />;
  }

  if (errorClaims !== undefined) {
    return <ErrorView error={errorClaims} />;
  }

  if (policy === undefined) {
    throw new Error(`Can’t fetch policy detail for policy id ${policyId}`);
  }

  if (claims && claims.length > 0) {
    return (
      <div className="p-body">
        <div className={`wmx8 w100 ${styles.container}`}>
          <Navigation
            title="My Claims"
            previousScreen="My Policy"
            previousUrl={generatePath(routes.me.policies.detail.path, {
              policyId,
            })}
          />
          <div className="mt32">
            <div className="p-p">
              To use your insurance and get money back, start here.
            </div>
            <Link
              className={`mt16 w100 ${styles['claims-button']}`}
              to={generatePath(makeAClaimPath, {
                policyId,
              })}
            >
              <img src={plusIcon} alt="cross" />
              <span
                className={`p-p tc-purple-500 ${styles['claims-button-text']}`}
              >
                Make a claim
              </span>
            </Link>
          </div>
          <div className="mt24 mb96">
            <div className="p-h2">Previous claims</div>
            {claims
              .sort((a, b) => {
                return dayjs(b.claimDate).unix() - dayjs(a.claimDate).unix();
              })
              .map(({ amount, status, claimTypes, claimDate, id }) => (
                <div className="mt16" key={id}>
                  <ClaimCell
                    // TODO: Should display multiple claim types
                    type={(claimTypes[0] && claimTypes[0].claimType) || ''}
                    price={amount}
                    dateOfClaim={claimDate}
                    status={status as ClaimStatuses}
                    policyId={policyId}
                    claimId={id}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-body">
      <div className={`wmx8 w100 ${styles.container}`}>
        <Navigation
          title="My Claims"
          previousScreen="My Policy"
          previousUrl={generatePath(routes.me.policies.detail.path, {
            policyId,
          })}
        />
        <div className={`mt96 ${styles['empty-state-container']}`}>
          <span className={styles['empty-state-icon']} role="img" aria-label="">
            <img src={claimsEmptyIcon} alt="No claims" />
          </span>
          <div className="mt32 p-p wmx5 wmn4 ta-center">
            You have made no claims on your {getTitleMapping(t)[policy.type]}{' '}
            insurance.
          </div>
          <Link
            className={`p-btn p-btn--secondary mt24 wmn4 ${styles['empty-state-link']}`}
            to={generatePath(makeAClaimPath, {
              policyId,
            })}
          >
            Make a claim
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyClaims;
