import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useFlag } from '@unleash/proxy-client-react';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { CheckoutPolicyRequestPayload } from 'features/checkout/models';
import View from 'features/checkout/view';
import { getDocuments } from 'features/checkoutDocuments/checkoutDocuments.selectors';
import { getPaymentMethods } from 'features/paymentMethods/paymentMethods.selectors';
import { PaymentOption } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import { isReadyForPayment } from 'features/paymentMethodSelector/paymentMethodSelector.utils';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { ZFutureDateString } from 'shared/models/validations';
import { isMobileApp } from 'shared/util/isMobileApp';

import { getCheckoutInfo } from './data';

interface Props {
  checkoutPolicyRequestPayload: CheckoutPolicyRequestPayload;
  policyDetailsComponent: JSX.Element;
  changeStartDatePath?: string;
  checkoutIcon?: string;
  questionnaireAnswers?: QuestionnaireQuestions;
}

const MockedCheckout = ({
  checkoutPolicyRequestPayload,
  policyDetailsComponent,
  changeStartDatePath,
  checkoutIcon,
  questionnaireAnswers,
}: Props) => {
  const { t } = useSafeTranslation();
  const account = useSelector(getAccountInfo);
  const paymentOptions = useSelector(getPaymentMethods);
  const verticalId = checkoutPolicyRequestPayload.type;
  const { questionnaireId } = checkoutPolicyRequestPayload.policyInfo;
  const documents = useSelector(getDocuments(questionnaireId));
  const isPayPalAvailable = useFlag('app_payment_method_paypal');

  const [backendStartDateValidationHasFailed] = useState<boolean | undefined>();

  const hasPayPal = paymentOptions.find((method) => method.type === 'PAYPAL');

  const stripe = useStripe();
  const elements = useElements();
  if (!isMobileApp && (isPayPalAvailable || hasPayPal)) {
    elements?.update({
      paymentMethodTypes: ['sepa_debit', 'card', 'paypal'],
    });
  }
  const [paymentOption, setPaymentOption] = useState<PaymentOption>({
    type: 'NOT_STARTED',
  });
  const [isProcessingPayment] = useState<boolean>(false);
  const [errorMessage] = useState<string | undefined>();
  const [paymentTypeSelected, setPaymentTypeSelected] = useState<
    string | undefined
  >();

  const validForSubmission =
    !isProcessingPayment && isReadyForPayment(paymentOption);
  const isBeforePaypalRedirect =
    paymentOption.type === 'READY_FOR_SETUP_WITH_REDIRECT' &&
    paymentTypeSelected === 'paypal';

  const checkoutInfo = getCheckoutInfo(questionnaireAnswers ?? {});

  const icon =
    checkoutIcon || imageTypeMapping[verticalId] || imageTypeMapping.GENERIC;

  const handleCheckout = async () => {};

  if (!checkoutInfo || account?.isDelinquent || !elements) {
    return (
      <TimedLoadSpinner
        title={t('checkout.loading.title', 'Preparing your policy')}
        description={t(
          'checkout.loading.description',
          "We're gathering your documents and policy details so you can review them before purchasing."
        )}
        delayInMilliseconds={0}
      />
    );
  }

  let isValidStartDate = !backendStartDateValidationHasFailed;

  if (checkoutPolicyRequestPayload.policyInfo.startDate) {
    const startDateValidationResult = ZFutureDateString.safeParse(
      checkoutPolicyRequestPayload.policyInfo.startDate
    );
    isValidStartDate =
      startDateValidationResult?.success &&
      !backendStartDateValidationHasFailed;
  }

  const onStripePaymentMethodTypeChange = (type: string) => {
    setPaymentTypeSelected(type);
  };

  return (
    <View
      policyInfo={checkoutInfo}
      error={errorMessage}
      loading={isProcessingPayment}
      icon={icon}
      paymentOption={paymentOption}
      setPaymentOption={setPaymentOption}
      stripe={stripe}
      stripeElements={elements}
      policyDetailsComponent={policyDetailsComponent}
      handleCheckout={handleCheckout}
      isValidStartDate={isValidStartDate}
      changeStartDatePath={changeStartDatePath}
      validForSubmission={validForSubmission}
      onStripePaymentMethodTypeChange={onStripePaymentMethodTypeChange}
      isBeforePaypalRedirect={isBeforePaypalRedirect}
      documents={documents}
    />
  );
};

export default MockedCheckout;
