import { RegularModal } from '@popsure/dirty-swan';

import { CoverageAccordion } from './CoverageAccordion';
import type { CoverageModalVariantProps } from './CoverageModal';
import styles from './CoverageModal.module.scss';

export const CoverageModalRegular = ({
  coverageTableData,
  coverageTableHeaders,
  firstItemOpenByDefault,
  isOpen,
  onClose,
  renderDataCell,
  title,
}: CoverageModalVariantProps) => (
  <div className={styles.modalWidthOverride}>
    <RegularModal isOpen={isOpen} title={title} onClose={onClose}>
      <div
        className={`wmn8 d-flex fd-column mt16 pb24 pl24 pr24 ${styles.modalContent}`}
      >
        {coverageTableHeaders && (
          <table className={`p-p w100 py32 ${styles.headerTable}`}>
            <tbody>
              <tr>
                {coverageTableHeaders.map((tableHeaderContent, index) => (
                  <th
                    className="tc-purple-500 fw-bold"
                    key={String(`tableHeaderContent ${index}`)}
                  >
                    {tableHeaderContent}
                  </th>
                ))}
              </tr>
            </tbody>
          </table>
        )}
        <CoverageAccordion
          coverageTableData={coverageTableData}
          firstItemOpenByDefault={firstItemOpenByDefault}
          renderDataCell={renderDataCell}
        />
      </div>
    </RegularModal>
  </div>
);
