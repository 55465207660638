import {
  clearRecommendationToolRecommendation,
  getRecommendationById,
} from 'actions/recommendationTool';
import PageNotFound from 'components/pageNotFound';
import routes from 'constants/routes';
import { LoadingSkeleton } from 'features/recommendationTool/components/LoadingSkeleton';
import { RecommendationErrorPage } from 'features/recommendationTool/components/RecommendationErrorPage';
import { RecommendationPage } from 'features/recommendationTool/components/RecommendationPage';
import { categoryLookup } from 'features/recommendationTool/lookups';
import type { InsuranceTypeRouteProps } from 'features/recommendationTool/newRoutes';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { generatePath, Redirect, useParams } from 'react-router-dom';
import {
  getCurrentRecommendation,
  getPersonaId,
} from 'selectors/recommendationTool';

import { resetQuestionnaire } from '../actions';
import { categoryByInsuranceType } from '../models/recommendation';
import { hasMoreInfoRequired } from '../utils/hasMoreInfoRequired';
import { isValidInsuranceType } from '../utils/isValidInsuranceType';

const REQUEST_TYPE = 'GET_RECOMMENDATION_BY_ID';

export const ResultPage = ({
  match: {
    params: { insuranceType },
  },
}: InsuranceTypeRouteProps) => {
  const history = useHistory();
  const { recommendationId } = useParams<{ recommendationId: string }>();
  const [firstRender, setFirstRender] = useState(true);
  const recommendation = useSelector(getCurrentRecommendation);
  const { loading, error } = useRequestStatus(REQUEST_TYPE);
  const dispatch = useDispatch();
  const personaId = useSelector(getPersonaId);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }

    if (
      !firstRender &&
      recommendationId &&
      recommendationId !== recommendation?.id
    ) {
      dispatch(getRecommendationById(recommendationId));
    }
  }, [recommendation, recommendationId, dispatch, firstRender]);

  const handleResetQuestionnaire = async () => {
    history.push(
      generatePath(routes.insuranceTool.intro.path, { insuranceType })
    );

    /**
     * Clear the recommendation after the redirect
     * to prevent the useEffect to fetch another recommendation
     * using the ID on the URL param
     */
    await dispatch(resetQuestionnaire(insuranceType));
    await dispatch(clearRecommendationToolRecommendation());
  };

  const handleEditQuestionnaire = async () => {
    history.push(
      generatePath(routes.insuranceTool.questionnaire.path, { insuranceType })
    );
  };

  if (!isValidInsuranceType(insuranceType)) {
    return <PageNotFound />;
  }

  if (firstRender || loading) {
    return <LoadingSkeleton />;
  }

  if (
    !recommendationId ||
    !recommendation ||
    !personaId ||
    insuranceType === 'quickRecommendation'
  ) {
    return <Redirect to={routes.insuranceTool.path} />;
  }

  if (!recommendationId || error) {
    return (
      <RecommendationErrorPage
        title="This link doesn't seem right"
        description="Make sure that the link was copied correctly and try again."
      />
    );
  }

  if (hasMoreInfoRequired(recommendation?.data, insuranceType))
    return (
      <RecommendationErrorPage
        title="Some information is missing"
        description="Sorry, some of the information required to give you a recommendation seems to be missing. Restarting the questionnaire should fix this issue."
        onCtaClick={handleResetQuestionnaire}
      />
    );

  return (
    <RecommendationPage
      category={categoryByInsuranceType[insuranceType]}
      lookup={categoryLookup[categoryByInsuranceType[insuranceType]]}
      recommendationData={recommendation?.data || []}
      onEditQuestionnaire={handleEditQuestionnaire}
    />
  );
};
