import { Address, isPartialAddressValid } from '@getpopsure/public-models';
import {
  AutocompleteAddress,
  BottomOrRegularModal,
  Button,
} from '@popsure/dirty-swan';
import { FormEvent, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

import styles from './ConfirmAddressModal.module.scss';

interface AddressModalProps {
  error?: string;
  loading?: boolean;
  questionnaireAddress: Address | null;

  onClose(): void;

  onSave(address: Address): void;
}

export const ConfirmAddressModal = (props: AddressModalProps) => {
  const { t } = useSafeTranslation();
  const { error, loading, questionnaireAddress, onClose, onSave } = props;
  const [address, setAddress] = useState<Partial<Address> | undefined>(
    questionnaireAddress || undefined
  );
  const isValidAddress = address && isPartialAddressValid(address);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isValidAddress) {
      onSave(address as Address);
    }
  };

  return (
    <BottomOrRegularModal
      title={t(
        'studentChecklist.germanAddress.modal.title',
        'Confirm your address'
      )}
      isOpen
      onClose={onClose}
    >
      <form onSubmit={handleSubmit} className={styles.container}>
        <AutocompleteAddress
          address={address}
          onAddressChange={setAddress}
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
        />
        <p className="mt16 p-notice--info">
          {t(
            'studentChecklist.germanAddress.modal.additionalInfo',
            'Make sure to fill the C/O field if your name is not on the front door and mailbox.'
          )}
        </p>
        <Button
          type="submit"
          className="mt16 w100 wmx3"
          disabled={!isValidAddress}
          loading={loading}
        >
          {t(
            'studentChecklist.germanAddress.modal.button.title',
            'Submit address'
          )}
        </Button>
        <AnimateHeight duration={300} height={error ? 'auto' : 0}>
          <div className="p-notice p-notice--danger mt16 wmx8">{error}</div>
        </AnimateHeight>
      </form>
    </BottomOrRegularModal>
  );
};
