import { isDateFuture } from '@getpopsure/public-utility';
import DateForm from 'components/questionnaireForms/DateForm';
import dayjs from 'dayjs';
import {
  ViewProps,
  withContainer,
} from 'features/liability/containers/withContainer';
import { getQuestionsMapping, QuestionId } from 'models/liability';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import { useSafeTranslation } from 'shared/i18n';

const DATE_FORMAT = 'YYYY-MM-DD';

const questionId: QuestionId = 'startDate';

const LiabilityStartDate = ({
  initialValue,
  onSubmitValue,
}: ViewProps<typeof questionId>) => {
  const { t } = useSafeTranslation();

  const relocationDate = useSelector(getGenericQuestionnaire).liability
    ?.relocationDate;
  const [error, setError] = useState('');

  const tomorrowDate = dayjs().add(1, 'day').format(DATE_FORMAT);
  const dayAfterRelocationDate = dayjs(relocationDate).add(1, 'day');

  const dateIsWithinOneYear = dayjs().add(1, 'year').subtract(1, 'days');

  const defaultStartDate = relocationDate
    ? dayAfterRelocationDate.format(DATE_FORMAT)
    : tomorrowDate;

  const value = initialValue ?? defaultStartDate;

  const validate = (date: string) => {
    setError('');

    if (relocationDate && !isDateFuture(date, relocationDate)) {
      setError(
        `We're only able to offer this coverage starting from ${dayAfterRelocationDate.format(
          'DD MMM YYYY'
        )} (the day after your relocation date). Please select another date.`
      );
      return;
    }

    if (!isDateFuture(date)) {
      setError('Earliest start date is tomorrow.');
      return;
    }

    if (dateIsWithinOneYear.isBefore(date)) {
      setError(
        'Coverage start date should not be more than 1 year in the future. Please adjust the start date.'
      );
      return;
    }

    onSubmitValue({ questionId, value: date });
  };

  return (
    <DateForm
      value={value}
      onSubmitValue={validate}
      yearRange={{
        min: new Date().getFullYear(),
        max: new Date().getFullYear() + 1,
      }}
      error={error}
      displayCalendar
      featureName="Liability"
      question={getQuestionsMapping(t)[questionId]}
    />
  );
};

export default withContainer(LiabilityStartDate, questionId);
