import { Policy } from 'models/policies';

import { CancelPolicyButton, ContactUsButton } from '../../components/buttons';
import { PolicyDetailsButton } from '../../sections/PolicyDetails/PolicyDetails.models';
import { ExpatAddDependentsButton } from './buttons/ExpatAddDependentsButton';
import { ExpatUpgradeLongTermHealth } from './buttons/ExpatUpgradeLongTermHealth';
import { ExpatUpgradePublicPrivateHealthButton } from './buttons/ExpatUpgradePublicPrivateHealthButton';

export const getExpatButtons = (policy: Policy): PolicyDetailsButton[] => {
  const buttonArray: PolicyDetailsButton[] = [
    { id: 'CONTACT_US', component: ContactUsButton },
    {
      id: 'UPGRADE_PUBLIC_PRIVATE_HEALTH',
      component: ExpatUpgradePublicPrivateHealthButton,
    },
    {
      id: 'UPGRADE_LONG_TERM_HEALTH',
      component: ExpatUpgradeLongTermHealth,
    },
  ];

  if (policy.status !== 'CANCELED') {
    buttonArray.push({ id: 'CANCEL_POLICY', component: CancelPolicyButton });
  }

  if (policy.status === 'ACTIVE') {
    buttonArray.push({
      id: 'ADD_DEPENDENTS',
      component: ExpatAddDependentsButton,
    });
  }

  return buttonArray;
};
