import styles from './Processing.module.scss';

interface Props {
  text: string[];
  elementIndex: number;
  fadeInAnimation: boolean;
  description?: string;
}

export const ProcessingView = ({
  text,
  elementIndex,
  fadeInAnimation,
  description,
}: Props) => (
  <div className="p-body">
    <div className={`d-flex ai-center fd-column ${styles.container}`}>
      <div className={`ds-spinner ds-spinner__l ${styles.spinner}`} />
      <h1
        className={`p-h2 mt24 ta-center ${
          fadeInAnimation ? styles['text-disappear'] : styles['text-appear']
        }`}
      >
        {text[elementIndex]}
      </h1>
      {description && (
        <div className={`p-p mt16 wmx7 ${styles.description}`}>
          {description}
        </div>
      )}
    </div>
  </div>
);
