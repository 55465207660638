import { RequestAction } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import { APIResponseError } from 'models/error';

export function setRequestInProcess(
  inProcess: boolean,
  requestType: RequestType
): RequestAction {
  return {
    type: 'REQUEST_IN_PROCESS',
    requestType,
    inProcess,
  };
}

export function setRequestErrored(
  error: APIResponseError,
  requestType: RequestType
): RequestAction {
  return {
    type: 'REQUEST_ERRORED',
    requestType,
    error,
  };
}

export function setRequestSkipped(requestType: RequestType): RequestAction {
  return {
    type: 'REQUEST_SKIPPED',
    requestType,
  };
}

export const flushRequestError = (requestType: RequestType): RequestAction => ({
  type: 'REQUEST_ERROR_FLUSH',
  requestType,
});
