import {
  AdviceRecommendationIcon,
  AssistantFilledIcon,
  EuroClaimFilledIcon,
  EuroClaimIcon,
  LogInIcon,
  PoliciesShieldFilledIcon,
  PoliciesShieldIcon,
  UserFilledIcon,
  UserIcon,
} from '@popsure/dirty-swan';
import classNames from 'classnames';
import { NavigationBarCategory } from 'features/navigationBar/models';
import { Link } from 'react-router-dom';

import styles from './style.module.scss';

const NavigationBarButton = ({
  title,
  category,
  link,
  selected,
  'data-cy': dataCy,
}: {
  title: string;
  category: NavigationBarCategory;
  link: string;
  selected: boolean;
  'data-cy'?: string;
}) => {
  const Icon = {
    account: selected ? UserFilledIcon : UserIcon,
    policies: selected ? PoliciesShieldFilledIcon : PoliciesShieldIcon,
    advice: selected ? AssistantFilledIcon : AdviceRecommendationIcon,
    claim: selected ? EuroClaimFilledIcon : EuroClaimIcon,
    login: LogInIcon,
  }[category];

  const isLoginButton = category === 'login';

  return (
    <Link
      className={classNames(
        `${selected ? styles['button--selected'] : styles.button}`,
        { [styles.buttonLogin]: isLoginButton },
        'ds-interactive-component d-flex ai-center'
      )}
      to={link}
      data-cy={dataCy}
      tabIndex={0}
    >
      <span
        className={classNames(styles.iconWrapper, {
          [styles.iconLogin]: isLoginButton,
        })}
      >
        <Icon className={styles.icon} />
      </span>

      {title}
    </Link>
  );
};

export default NavigationBarButton;
