import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, useState } from 'react';
import { RadioOption } from 'shared/models/types';

import QuestionnaireWrapper from '../QuestionnaireWrapper';

interface Props<
  ValueType extends string,
  ValueTypeMapping extends {
    [k in ValueType]: RadioOption;
  }
> extends QuestionnaireFormProps<ValueType>,
    QuestionnaireWrapperProps {
  mapValue: ValueTypeMapping;
}

export const RadioForm = <
  ValueType extends string,
  ValueTypeMapping extends {
    [k in ValueType]: RadioOption;
  }
>({
  value,
  question,
  description,
  mapValue,
  onSubmitValue,
  buttonText,
  header,
  loading,
  featureName,
  error,
  additionalInfo,
}: Props<ValueType, ValueTypeMapping>) => {
  const [radioValue, setRadioValue] = useState<ValueType | undefined>(value);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!radioValue) {
      throw new Error(
        `[${featureName}] Radio value is missing for submission from following options: ${Object.keys(
          mapValue
        ).join(', ')} for question: ${question}`
      );
    }
    onSubmitValue(radioValue);
  };

  const entries = Object.entries(mapValue) as [ValueType, RadioOption][];

  return (
    <QuestionnaireWrapper
      header={header}
      question={question}
      description={description}
      onSubmit={handleSubmit}
      error={error}
      loading={loading}
      buttonText={buttonText}
      validForSubmission={!!radioValue}
      additionalInfo={additionalInfo}
    >
      <div className="p-label-container">
        {entries.map(([v, label], index) => (
          <div className={index !== 0 ? 'mt8' : ''} key={v}>
            <input
              id={v}
              className="p-radio"
              type="radio"
              value={v}
              onChange={() => setRadioValue(v as ValueType)}
              checked={radioValue === v}
            />
            <label
              htmlFor={v}
              className="p-label p-label--bordered"
              data-cy={`radio-${v}`}
            >
              {typeof label === 'string' ? (
                label
              ) : (
                <div>
                  <p className="p-p">{label.title}</p>
                  <span className="d-block p-p p-p--small tc-grey-600">
                    {label.description}
                  </span>
                </div>
              )}
            </label>
          </div>
        ))}
      </div>
    </QuestionnaireWrapper>
  );
};
