import routes from 'constants/routes';
import { NavigationBarCategory } from 'features/navigationBar/models';

export const selectedCategoryForPath = (
  path: string
): NavigationBarCategory | undefined => {
  if (path.startsWith(routes.me.policies.path)) {
    return 'policies';
  }

  if (
    path.startsWith(routes.checkup.path) ||
    path.startsWith(routes.healthInsuranceTool.path)
  ) {
    return 'advice';
  }

  if (path.startsWith(routes.claims.path)) {
    return 'claim';
  }

  if (path.startsWith(routes.account.signIn.path)) {
    return 'login';
  }

  if (path.startsWith(routes.account.path)) {
    return 'account';
  }

  if (path.startsWith(routes.insuranceTool.path)) {
    return 'advice';
  }

  return undefined;
};
