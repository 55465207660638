import { ShallowFlattened } from 'shared/models/util';

export const shallowFlatten = <T extends object>(
  obj: T
): ShallowFlattened<T> | {} => {
  const flattenedObject = {} as object;

  if (typeof obj !== 'object') {
    return flattenedObject;
  }

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      Object.assign(flattenedObject, { ...value });
      return;
    }
    if (value !== undefined) {
      Object.assign(flattenedObject, { [key]: value });
    }
  });

  return flattenedObject;
};
