import { RequestType } from 'constants/requestTypes';
import { connect } from 'react-redux';
import { AppState } from 'reducers';
import { getRequestErrorMessage } from 'selectors/requests';

export interface ErrorProps {
  error?: string;
}

function mapStateToProps(requestType: RequestType) {
  return (state: AppState) => {
    return {
      error: getRequestErrorMessage(state, requestType),
    };
  };
}

const ConnectedErrorView = (requestType: RequestType) =>
  connect<ErrorProps, {}, {}, AppState>(mapStateToProps(requestType));

export default ConnectedErrorView;
