import routes from 'constants/routes';
import { CheckoutPolicyRequestPayload } from 'features/checkout/models';
import PolicyDetails from 'features/liability/pages/checkout/components/policyDetails';
import CheckoutView from 'features/liability/pages/checkout/view';
import { useSelector } from 'react-redux';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';

const LiabilityCheckout = () => {
  const questionaireQuoteInfo = useSelector(getGenericQuestionnaire).liability
    ?.quote;
  const startDate = useSelector(getGenericQuestionnaire).liability?.startDate;
  const questionnaireId = questionaireQuoteInfo?.questionnaireId;
  const quoteId = questionaireQuoteInfo?.quoteId;

  const checkoutPolicyRequestPayload: CheckoutPolicyRequestPayload = {
    type: 'LIABILITY',
    policyInfo: {
      quoteId,
      questionnaireId,
      startDate,
    },
  };

  return (
    <CheckoutView
      checkoutPolicyRequestPayload={checkoutPolicyRequestPayload}
      policyDetailsComponent={<PolicyDetails />}
      changeStartDatePath={
        routes.policies.liability.questionnaire.startDate.path
      }
    />
  );
};

export default LiabilityCheckout;
