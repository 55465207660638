import { trackPaymentMethodAdded } from '@getpopsure/analytics';
import { captureException } from '@sentry/browser';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { useFlag } from '@unleash/proxy-client-react';
import axios, { AxiosError } from 'axios';
import routes from 'constants/routes';
import { mergePaymentMethod } from 'features/paymentMethods/paymentMethods.actions';
import { getPaymentMethods } from 'features/paymentMethods/paymentMethods.selectors';
import { PaymentOption } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import {
  PaymentMethodSelectorThunkDispatch,
  prepareForPayment,
} from 'features/paymentMethodSelector/paymentMethodSelector.thunks';
import {
  isCustomerFacingError,
  isStripeError,
} from 'features/paymentMethodSelector/paymentMethodSelector.utils';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getUserId } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';

import { AddPaymentMethodView } from './AddPaymentMethod.view';

export const AddPaymentMethod = () => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch<PaymentMethodSelectorThunkDispatch>();
  const isPayPalAvailable = useFlag('app_payment_method_paypal');

  const userId = useSelector(getUserId);
  const paymentOptions = useSelector(getPaymentMethods);

  const [paymentOption, setPaymentOption] = useState<PaymentOption>({
    type: 'NOT_STARTED',
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const hasPayPal = paymentOptions.find((method) => method.type === 'PAYPAL');

  const stripeClient = useStripe();
  const elements = useElements();
  if (!isMobileApp && (isPayPalAvailable || hasPayPal)) {
    elements?.update({
      paymentMethodTypes: ['sepa_debit', 'card', 'paypal'],
    });
  }
  const history = useHistory();

  const isReadyForSubmit =
    !isSubmitting &&
    (paymentOption.type === 'READY_FOR_SETUP' ||
      paymentOption.type === 'READY_FOR_SETUP_WITH_REDIRECT');

  const showNetworkError = (_error: AxiosError) => {
    setErrorMessage(
      t(
        'paymentMethods.addNew.errors.genericError',
        'We could not add your payment method because of an error on our side, please contact support.'
      )
    );
  };

  const showStripeError = (error: StripeError) => {
    setErrorMessage(
      error.message ??
        t(
          'paymentMethods.addNew.genericStripeError',
          'There was an issue with your payment method, please contact support.'
        )
    );
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage(undefined);

    try {
      if (!stripeClient || !elements) {
        throw Error(
          '[Add payment method screen] The stripe client was not ready in time'
        );
      }

      const paymentMethod = await dispatch(
        prepareForPayment({
          elements,
          paymentOption,
          stripeClient,
          returnUrl: `${window.location.origin}${routes.me.paymentMethods.confirmSetup.path}`,
        })
      );

      trackPaymentMethodAdded({
        variant: 'stripe',
        insurance_type: null,
        user_id: userId ?? null,
        medium: 'billing',
        payment_type: paymentMethod.type,
        is_default_payment: paymentMethod.isDefault,
      });

      dispatch(mergePaymentMethod(paymentMethod));
    } catch (error: unknown) {
      setIsSubmitting(false);

      if (axios.isAxiosError(error)) {
        showNetworkError(error);
      } else if (isStripeError(error)) {
        showStripeError(error);

        if (!isCustomerFacingError(error)) {
          // This error should be reported to Sentry very rarely. If you this shows up as
          // a false-positive, please update the list of reported error types in isCustomerFacingError,
          // or remove this captureException entirely
          captureException(error);
        }

        return;
      }

      throw error;
    }

    history.push(routes.me.paymentMethods.path);
  };

  return (
    <AddPaymentMethodView
      errorMessage={errorMessage}
      handleSubmit={handleSubmit}
      setPaymentOption={setPaymentOption}
      isSubmitting={isSubmitting}
      isReadyForSubmit={isReadyForSubmit}
    />
  );
};

export default AddPaymentMethod;
