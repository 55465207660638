import { Button, toast } from '@popsure/dirty-swan';
import { APIResponseError } from 'models/error';
import { useRef } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import { copyToClipboard } from 'shared/util/copyToClipboard';

import styles from './ReferralCodeCta.module.scss';

interface ReferralModalCtaProps {
  referralCode?: string;
  loading?: boolean;
  error?: APIResponseError;
}

export const ReferralCodeCta = (props: ReferralModalCtaProps) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const { referralCode, loading, error } = props;
  const { t } = useSafeTranslation();

  const mailMessage = `mailto:?subject=Get%2015€%20when%20you%20sign%20up%20for%20Feather&body=Use%20this%20code%20when%20purchasing%20your%20first%20Feather%20policy%20and%20receive%20€15%20after%203%20months:%20${referralCode}`;

  const handleCopyToClipboard = async () => {
    if (!referralCode || !ref.current) return;

    const copyStatus = await copyToClipboard(ref.current);
    if (copyStatus === 'SUCCESS') {
      toast(
        t(
          'referralengine.generator.modal.toast.message',
          'Promo code copied to clipboard'
        ),
        { type: 'success' }
      );
    }
  };

  if (error)
    return (
      <div className="p-notice p-notice--danger mt16 mb24">
        {t(
          'referralengine.generator.modal.error',
          'Error generating promo code: {{error}}',
          { error: error.message }
        )}
      </div>
    );

  if (loading)
    return (
      <div className="w100 d-flex jc-center py32">
        <div
          data-testid="referral-engine-loading"
          className="ds-spinner ds-spinner__m"
        />
      </div>
    );

  return (
    <>
      <p
        ref={ref}
        className="p-h2 p16 d-flex ai-center jc-center bg-grey-100 br8"
      >
        {referralCode}
      </p>

      <div className={`mt8 mb32 d-flex ${styles.buttonContainer}`}>
        <Button onClick={handleCopyToClipboard}>
          {t('referralengine.generator.modal.button.copy', 'Copy code')}
        </Button>
        <a
          href={mailMessage}
          className="p-btn--secondary"
          target="_blank"
          rel="nooppener noreferrer"
        >
          {t('referralengine.generator.modal.button.email', 'Send by email')}
        </a>
      </div>
    </>
  );
};
