import GenericEmail from 'components/signupAuthentication/email';
import { Household } from 'features/household/models';
import { CustomComponentProps } from 'models/questionnaireFramework';

export const Email = ({ routes }: CustomComponentProps<Household>) => (
  <GenericEmail
    verticalId="household"
    redirectPath={routes.name.path}
    redirectExistingUserPath={routes.account.path}
  />
);
