export type StatusBadgeColorClass =
  | 'bg-grey-200'
  | 'bg-blue-100'
  | 'bg-pink-100'
  | 'bg-yellow-100'
  | 'bg-green-100'
  | 'bg-primary-900';

export interface Props {
  title: string;
  badgeColor: StatusBadgeColorClass;
}

export const StatusBadge = ({ title, badgeColor }: Props) => {
  const textColor =
    badgeColor === 'bg-primary-900' ? 'tc-white' : 'tc-grey-900';

  return (
    <div className={`py8 px16 br8 d-flex ai-center ${badgeColor}`}>
      <div className={`fw-bold p-p--small ${textColor}`}>{title}</div>
    </div>
  );
};
