import { Country, Gender } from '@getpopsure/public-models';
import { CheckoutInfo } from 'features/checkout/models';
import { Name } from 'models/user';
import { ActionResponse } from 'shared/models/types';
import {
  ZCountrySchema,
  ZGenderSchema,
  ZNameSchema,
} from 'shared/models/validations';
import { BLOCKER, SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

const QuotePlan = ['BASIC'] as const;

export type Plan = typeof QuotePlan[number];

export type DetailedPlan = 'BASIC_YOUNG' | 'BASIC_STUDENT' | 'BASIC_SENIOR';

export type PlanId = '608975' | '612489';

export type QuoteResponse = {
  id: string;
  price: number;
  planId: Plan;
  attributes: {
    advigonPlanId: PlanId;
    detailedPlan: DetailedPlan;
  };
};

export const ZQuote = z.object({
  id: z.string(),
  price: z.number(),
  planId: z.enum(QuotePlan),
});

export type Quote = z.TypeOf<typeof ZQuote>;

export type LegalGuardian = {
  name: Name;
  dateOfBirth: string;
  email: string;
};

export type CheckoutPolicyDetails = {
  legalGuardian?: LegalGuardian;
  isMainPolicyTheLegalGuardian?: boolean;
};

export type CreateQuoteReturnType = {
  status: ActionResponse;
};

const Occupations = [
  'HAVE_A_JOB_OFFER',
  'EMPLOYED',
  'SELF_EMPLOYED',
  'STUDENT',
  'RETIRED',
  'OTHER',
] as const;

export type Occupation = typeof Occupations[number];

const OtherOccupations = [
  'AU_PAIR',
  'JOB_SEEKER',
  'LANGUAGE_STUDENT',
  'PHD_STUDENT',
  'SOMETHING_ELSE',
] as const;

export type OtherOccupation = typeof OtherOccupations[number];

export const OccupationMapping: Record<Occupation, string> = {
  HAVE_A_JOB_OFFER: 'Have a job lined up',
  EMPLOYED: 'Employed',
  SELF_EMPLOYED: 'Self-employed',
  STUDENT: 'Student',
  RETIRED: 'Retired',
  OTHER: 'Other',
};

export const OtherOccupationMapping: Record<OtherOccupation, string> = {
  AU_PAIR: 'Au pair',
  JOB_SEEKER: 'Job seeker',
  LANGUAGE_STUDENT: 'Language student',
  PHD_STUDENT: 'PhD student',
  SOMETHING_ELSE: 'Something else',
};

export type ExpatSpain = {
  intro: SCREEN;
  occupation: Occupation;

  employedInSpain?: 'YES' | 'NO';
  employedInSpainBlocker?: BLOCKER;

  registeredFreelancer?: boolean;
  registeredFreelancerOccupation?: unknown;

  otherOccupation?: OtherOccupation;

  dateOfBirth: string;
  ageIs75AndAboveBlocker?: BLOCKER;

  quoteProcessing: QuoteResponse[];
  quote: Quote;

  legalGuardianName?: Name;
  legalGuardianDateOfBirth?: string;
  legalGuardianEmail?: string;

  addFamilyMembers?: boolean;
  arrivalDate: string;
  lastPermanentResidency: Country;
  postalCode: string;
  startDate: string;

  name: Name;
  email: string;
  gender: Gender;

  review: boolean;

  checkoutInfo: CheckoutInfo<CheckoutPolicyDetails>;
  reviewCheckout: boolean;
  checkout: boolean;

  quoteId: string;
  questionnaireId: string;

  dependent: Partial<ExpatSpainDependent>;
};

export type ExpatSpainDependent = Omit<
  ExpatSpain,
  'addFamilyMembers' | 'dependent' | 'email'
> & {
  mainPolicyId: string;
  isPolicyHolder: boolean;
  isMainPolicyTheLegalGuardian?: boolean;
};

export const ZSubmittableExpatSpainQuote = z.object({
  dateOfBirth: z.string(),
  occupation: z.enum(Occupations),
  otherOccupation: z.optional(z.enum(OtherOccupations)),
});

export type SubmittableExpatSpainQuote = z.TypeOf<
  typeof ZSubmittableExpatSpainQuote
>;

export const ZSubmittableExpatSpainQuestionnaireBase = z.object({
  occupation: z.enum(Occupations),
  employedInSpain: z.optional(z.enum(['YES', 'NO'])),
  registeredFreelancer: z.optional(z.boolean()),
  otherOccupation: z.optional(z.enum(OtherOccupations)),
  lastPermanentResidency: ZCountrySchema,
  arrivalDate: z.string(),
  startDate: z.string(),
  postalCode: z.string(),
  name: ZNameSchema,
  dateOfBirth: z.string(),
  gender: ZGenderSchema,
  legalGuardianName: z.optional(ZNameSchema),
  legalGuardianDateOfBirth: z.optional(z.string()),
  legalGuardianEmail: z.optional(z.string()),
});

export const ZSubmittableExpatSpainQuestionnaire =
  ZSubmittableExpatSpainQuestionnaireBase.extend({
    addFamilyMembers: z.optional(z.boolean()),
    email: z.string(),
  }).transform(
    ({
      arrivalDate,
      startDate,
      postalCode,
      addFamilyMembers,
      legalGuardianName,
      legalGuardianDateOfBirth,
      legalGuardianEmail,
      ...personalInfo
    }) => ({
      personalInfo: {
        ...personalInfo,
        employedInSpain: personalInfo.employedInSpain === 'YES',
      },
      tariffInfo: {
        arrivalDate,
        startDate,
        postalCode,
        addFamilyMembers,
        legalGuardian:
          legalGuardianName && legalGuardianDateOfBirth && legalGuardianEmail
            ? {
                name: legalGuardianName,
                dateOfBirth: legalGuardianDateOfBirth,
                email: legalGuardianEmail,
              }
            : undefined,
      },
    })
  );

export type SubmittableExpatSpainQuestionnaire = z.infer<
  typeof ZSubmittableExpatSpainQuestionnaire
>;

export const ZSubmittableExpatSpainDependentQuestionnaire =
  ZSubmittableExpatSpainQuestionnaireBase.extend({
    mainPolicyId: z.string(),
    isMainPolicyTheLegalGuardian: z.optional(z.boolean()),
  }).transform(
    ({
      mainPolicyId,
      arrivalDate,
      startDate,
      postalCode,
      legalGuardianName,
      legalGuardianDateOfBirth,
      legalGuardianEmail,
      isMainPolicyTheLegalGuardian,
      ...personalInfo
    }) => ({
      personalInfo: {
        ...personalInfo,
        employedInSpain: personalInfo.employedInSpain === 'YES',
      },
      tariffInfo: {
        arrivalDate,
        startDate,
        postalCode,
        isMainPolicyTheLegalGuardian,
        legalGuardian:
          !isMainPolicyTheLegalGuardian &&
          legalGuardianName &&
          legalGuardianDateOfBirth &&
          legalGuardianEmail
            ? {
                name: legalGuardianName,
                dateOfBirth: legalGuardianDateOfBirth,
                email: legalGuardianEmail,
              }
            : undefined,
      },
      mainPolicyId,
    })
  );

export type SubmittableExpatSpainDependentQuestionnaire = z.infer<
  typeof ZSubmittableExpatSpainDependentQuestionnaire
>;

export type SubmittableQuestionnaire =
  | SubmittableExpatSpainQuestionnaire
  | SubmittableExpatSpainDependentQuestionnaire;

export type ExpatSpainGroupIds = 'preQuote' | 'signup';

export type QuestionnaireType = 'INCOMING_ES' | 'INCOMING_ES_DEPENDENT';

export type ExpatSpainCancellationReasonsId =
  | 'RIGHT_OF_WITHDRAWAL'
  | 'FOUND_A_JOB'
  | 'LEFT_SPAIN'
  | 'FOUND_ANOTHER_INSURANCE';

export const FOUND_ANOTHER_INSURANCE: ExpatSpainCancellationReasonsId =
  'FOUND_ANOTHER_INSURANCE';
