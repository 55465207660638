import { submitQuestionnaireAnswer } from 'actions/liability';
import View from 'features/liability/pages/quote/components/pricingCalculator/view';
import { validMembersList } from 'features/liability/utils';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { InclusiveMember } from 'models/liability';
import { useDispatch, useSelector } from 'react-redux';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';

const PricingCalculatorView = () => {
  const inclusiveMembersParams = useQueryParamValue('inclusiveMembers')?.split(
    ','
  ) as InclusiveMember[];
  const inclusiveMembers = useSelector(getGenericQuestionnaire).liability
    ?.inclusiveMembers;

  const dispatch = useDispatch();

  const storeInclusiveMembers = (inclusiveMembers: InclusiveMember[]) => {
    if (inclusiveMembers && validMembersList(inclusiveMembers)) {
      dispatch(
        submitQuestionnaireAnswer({
          questionId: 'inclusiveMembers',
          value: inclusiveMembers,
        })
      );
    }
  };

  return (
    <View
      inclusiveMembers={inclusiveMembers ?? inclusiveMembersParams}
      storeInclusiveMembers={storeInclusiveMembers}
    />
  );
};

export default PricingCalculatorView;
