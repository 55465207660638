import { PaymentMethodsAction } from 'constants/actions';
import { PaymentMethod } from 'models/paymentMethods';
import { Reducer } from 'redux';
import { flatten } from 'shared/util/flatten';

export interface PaymentMethodsState {
  items?: { [id: string]: PaymentMethod };
}

export const defaultState: PaymentMethodsState = {};

const paymentMethods: Reducer<PaymentMethodsState, PaymentMethodsAction> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case 'MERGE_PAYMENT_METHODS': {
      return { ...state, items: flatten(action.paymentMethods) };
    }
    case 'MERGE_PAYMENT_METHOD': {
      const { paymentMethod } = action;
      return {
        ...state,
        items: { ...state.items, [paymentMethod.id]: paymentMethod },
      };
    }
    case 'DETACH_PAYMENT_METHOD': {
      const items = Object.values(state.items || {});
      return {
        ...state,
        items: flatten(items, action.paymentMethodId),
      };
    }
    default:
      return state;
  }
};

export default paymentMethods;
