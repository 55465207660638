import { TFunction } from '@getpopsure/i18n-react';
import { Plan } from '@getpopsure/legal-insurance-pricing-engine-v2';
import dayjs from 'dayjs';
import { Policy } from 'models/policies';

const LEGAL_V2_RELEASE_DATE = '2024/02/01';

export const getLegalCoverageDetails = (t: TFunction, policy: Policy) => {
  const { planId, addons } = policy.attributes;

  const coverageDetailsByPlan = {
    BASIC: [
      {
        id: 'basic-private-life',
        title: t(
          'myPolicies.policyDetails.legal.covered.privateLife.title',
          'Private life'
        ),
        description: t(
          'myPolicies.policyDetails.legal.covered.privateLife.description',
          'e.g. disputes with public authorities, neighbors, etc.'
        ),
        covered: true,
      },
      {
        id: 'basic-professional-life',
        title: t(
          'myPolicies.policyDetails.legal.covered.professionalLife.title',
          'Professional life'
        ),
        description: t(
          'myPolicies.policyDetails.legal.covered.professionalLife.description',
          'e.g. disputes with the employer'
        ),
        covered: true,
      },
      {
        id: 'basic-traffic',
        title: t(
          'myPolicies.policyDetails.legal.covered.traffic.title',
          'Traffic'
        ),
        description: t(
          'myPolicies.policyDetails.legal.covered.traffic.description',
          'e.g. unjustified parking fines, victim of a hit&run'
        ),
        covered: true,
      },
      {
        id: 'basic-immigration',
        title: t(
          'myPolicies.policyDetails.legal.covered.immigration.title',
          'Immigration matters'
        ),
        description: t(
          'myPolicies.policyDetails.legal.covered.immigration.description',
          'e.g. disputes about immigration, visas, and residence permits'
        ),
        covered: false,
      },
      {
        id: 'basic-self-employment',
        title: t(
          'myPolicies.policyDetails.legal.covered.selfEmployment.title',
          'Disputes related to self-employment'
        ),
        covered: false,
      },
      {
        id: 'basic-anticipated-ongoing-problems',
        title: t(
          'myPolicies.policyDetails.legal.covered.anticipatedOngoingProblems.title',
          'Anticipated, ongoing, or occurred legal problems'
        ),
        covered: false,
      },
      {
        id: 'basic-review-contracts',
        title: t(
          'myPolicies.policyDetails.legal.covered.reviewContracts.title',
          'Reviewing contracts as a preventive measure'
        ),
        covered: false,
      },
    ],
    ADVANCED: [
      {
        id: 'premium-private-life',
        title: t(
          'myPolicies.policyDetails.legal.covered.privateLife.title',
          'Private life'
        ),
        description: t(
          'myPolicies.policyDetails.legal.covered.privateLife.description',
          'e.g. disputes with public authorities, neighbors, etc.'
        ),
        covered: true,
      },
      {
        id: 'premium-professional-life',
        title: t(
          'myPolicies.policyDetails.legal.covered.professionalLife.title',
          'Professional life'
        ),
        description: t(
          'myPolicies.policyDetails.legal.covered.professionalLife.description',
          'e.g. disputes with the employer'
        ),
        covered: true,
      },
      {
        id: 'premium-traffic',
        title: t(
          'myPolicies.policyDetails.legal.covered.traffic.title',
          'Traffic'
        ),
        description: t(
          'myPolicies.policyDetails.legal.covered.traffic.description',
          'e.g. unjustified parking fines, victim of a hit&run'
        ),
        covered: true,
      },
      {
        id: 'premium-immigration',
        title: t(
          'myPolicies.policyDetails.legal.covered.immigration.title',
          'Immigration matters'
        ),
        description: t(
          'myPolicies.policyDetails.legal.covered.immigration.description',
          'e.g. disputes about immigration, visas, and residence permits'
        ),
        covered: false,
      },
      {
        id: 'premium-self-employment',
        title: t(
          'myPolicies.policyDetails.legal.covered.selfEmployment.title',
          'Disputes related to self-employment'
        ),
        covered: false,
      },
      {
        id: 'premium-anticipated-ongoing-problems',
        title: t(
          'myPolicies.policyDetails.legal.covered.anticipatedOngoingProblems.title',
          'Anticipated, ongoing, or occurred legal problems'
        ),
        covered: false,
      },
      {
        id: 'premium-review-contracts',
        title: t(
          'myPolicies.policyDetails.legal.covered.reviewContracts.title',
          'Reviewing contracts as a preventive measure'
        ),
        covered: false,
      },
    ],
  };

  const isLegalPlan = (plan?: string): plan is Plan =>
    typeof plan === 'string' && ['BASIC', 'ADVANCED'].includes(plan);

  const coverageDetails = isLegalPlan(planId)
    ? coverageDetailsByPlan[planId]
    : coverageDetailsByPlan.BASIC;

  const isPolicyCreatedBeforeV2 = dayjs(policy.createdAt).isBefore(
    dayjs(LEGAL_V2_RELEASE_DATE)
  );

  // The coverage overview partly changes based on whether the policy was created before or after the signup flow V2, that introduced addons.

  if (isPolicyCreatedBeforeV2) {
    coverageDetails.push({
      id: 'property',
      title: t(
        'myPolicies.policyDetails.legal.covered.property.title',
        'Property'
      ),
      description: t(
        'myPolicies.policyDetails.legal.covered.property.description',
        'e.g. disputes about rental contracts or rent increases'
      ),
      covered: true,
    });
  } else if (addons) {
    if (addons.includes('PROPERTY')) {
      coverageDetails.push({
        id: 'home-addon',
        title: t('myPolicies.policyDetails.legal.covered.home.title', 'Home'),
        description: t(
          'myPolicies.policyDetails.legal.covered.home.description',
          'e.g. disputes about rental contracts or rent increases'
        ),
        covered: true,
      });
    }
    if (addons.includes('CRIMINAL')) {
      coverageDetails.push({
        id: 'criminal-addon',
        title: t(
          'myPolicies.policyDetails.legal.covered.criminalProtection.title',
          'Criminal protection'
        ),
        description: t(
          'myPolicies.policyDetails.legal.covered.criminalProtection.description',
          'e.g. false accusations of intentional offences'
        ),
        covered: true,
      });
    }
  }

  return coverageDetails.sort((firstItem, secondItem) => {
    return Number(secondItem.covered) - Number(firstItem.covered);
  });
};
