import classNames from 'classnames';
import { useEffect } from 'react';

import styles from './LoadingSkeleton.module.scss';

export const LoadingSkeleton = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div data-testid="policy-single-loading-skeleton">
      <div className={classNames('px16', styles.wrapper)}>
        <div className={styles.background} />
        <div
          className={classNames('w100 wmx12 m-auto br8 bg-white', styles.card)}
        >
          <div className={classNames('br8', styles.icon)} />
          <div>
            <div className={classNames('br8', styles.title)} />
            <div className={classNames('br8', styles.subtitle)} />
          </div>
        </div>
      </div>
      <div
        className={classNames('w100 wmx12 m-auto br8 bg-white', styles.section)}
      />
    </div>
  );
};
