import { ToggleCard } from './ToggleCard';

export type Addon = {
  id: string;
  title: string;
  description: string;
  price: number;
  link?: string;
};

export type AddonSectionProps = {
  addons: Addon[];
  toggleAddon: (id: string) => void;
  selectedAddons: string[];
};

export const AddonSection = ({
  addons,
  toggleAddon,
  selectedAddons,
}: AddonSectionProps) => {
  return (
    <div className="p-container mt64">
      {addons.map(({ id, ...addon }) => {
        return (
          <ToggleCard
            key={id}
            {...addon}
            id={id}
            onChange={() => toggleAddon(id)}
            isSelected={selectedAddons.includes(id)}
          />
        );
      })}
    </div>
  );
};
