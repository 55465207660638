import { Household } from 'features/household/models';
import { AppState } from 'reducers';
import { createSelector } from 'reselect';
import { ShallowFlattened } from 'shared/models/util';
import { shallowFlatten } from 'shared/util/shallowFlatten';

export const policyOptionDefaults: ShallowFlattened<
  Pick<
    Household,
    | 'address'
    | 'livingSpace'
    | 'securityDevices'
    | 'insuredSum'
    | 'addonOptions'
  >
> = {
  city: '',
  street: '',
  postcode: '',
  houseNumber: '',
  country: '',
  bikeInsuredSum: 0,
  hasSecurityDevice: false,
  insuredSum: 0,
  propertyInSqMeters: 0,
  propertyInSqFeet: 0,
  selectedUnitSize: 'SQUARE_METERS',
  ownedSecurityDevices: [],
  selectedAddons: [],
};

export const getHouseholdAnswers = (state: AppState) =>
  state.genericQuestionnaire.household;

export const getPolicyOptionsOrDefaults = createSelector(
  [
    (state: AppState) => state.genericQuestionnaire.household?.address,
    (state: AppState) => state.genericQuestionnaire.household?.livingSpace,
    (state: AppState) => state.genericQuestionnaire.household?.securityDevices,
    (state: AppState) => state.genericQuestionnaire.household?.insuredSum,
    (state: AppState) => state.genericQuestionnaire.household?.addonOptions,
  ],

  (address, livingSpace, securityDevices, insuredSum, addonOptions) => ({
    ...policyOptionDefaults,
    ...shallowFlatten({
      address,
      livingSpace,
      securityDevices,
      insuredSum,
      addonOptions,
    }),
  })
);
