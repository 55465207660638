import { TFunction } from '@getpopsure/i18n-react';
import dayjs from 'dayjs';
import {
  Household,
  HouseholdAnswersBackendSchema,
  HouseholdInfoBackendSchema,
  PersonalInfoBackendSchema,
  questionPropLookup,
} from 'features/household/models';

export const createTitles = (
  answers: HouseholdAnswersBackendSchema,
  t: TFunction
): Record<keyof HouseholdAnswersBackendSchema, Record<string, string>> => {
  const labels = {
    personalInfo: {} as Record<keyof PersonalInfoBackendSchema, string>,
    householdInfo: {} as Record<keyof HouseholdInfoBackendSchema, string>,
  };

  Object.keys(answers.personalInfo).forEach((key) => {
    labels.personalInfo[key as keyof PersonalInfoBackendSchema] =
      questionPropLookup(t)[key as keyof Household]
        ? questionPropLookup(t)[key as keyof Household].question
        : '';
  });

  Object.keys(answers.householdInfo).forEach((key) => {
    labels.householdInfo[key as keyof HouseholdInfoBackendSchema] =
      questionPropLookup(t)[key as keyof Household]
        ? questionPropLookup(t)[key as keyof Household].question
        : '';
  });

  return labels;
};

export const isAnchorDateOver2Years = (
  policyStartDate: string,
  _todayDate?: string
): boolean => {
  const startDate = dayjs(policyStartDate);
  const endOfMonth = startDate.endOf('month');
  const anchorDate = endOfMonth.add(1, 'day').startOf('day');
  const todayDate = _todayDate || dayjs();

  return anchorDate.diff(todayDate, 'day') > 730;
};

export const getLatestStartDate = (todayDate?: string) => {
  const startDate = dayjs(todayDate);

  return startDate.add(1, 'year').subtract(2, 'days').format('DD MMM YYYY');
};
