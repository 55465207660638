import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { useEffect } from 'react';

import { IconCard } from '../../../../components/IconCard';
import {
  addBeneficiariesWelcomeItems,
  editBeneficiariesWelcomeItems,
} from './content';
import familyIconWithBackground from './icons/family-with-background.svg';
import styles from './style.module.scss';

export const WelcomeScreen = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  setValidForSubmission,
  questionnaireType,
}: QuestionnaireFormProps<Questionnaire, boolean> & {
  questionnaireType: 'add' | 'edit';
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setValidForSubmission(true);
  }, [setValidForSubmission]);

  const handleSubmit = () => {
    onSubmitValue(true);
  };

  return (
    <div
      className={classNames(
        `p-body ai-center d-flex`,
        styles.container,
        questionnaireType === 'add' ? 'mt40' : 'mt80'
      )}
    >
      <div className={`${styles.animatedContainer}`}>
        <Header onSubmit={handleSubmit}>
          <div>
            <div className="mb16">
              {questionnaireType === 'add' && (
                <img
                  src={familyIconWithBackground}
                  alt="Illustration of two adults and a child hugging"
                  className={`${styles.familyIcon}`}
                />
              )}
              <h1 className="p-h1">
                {questionnaireType === 'add' ? 'Add' : 'Edit'} your
                beneficiaries with ease{' '}
              </h1>
            </div>
            <div className={styles.cardContainer}>
              {(questionnaireType === 'add'
                ? addBeneficiariesWelcomeItems
                : editBeneficiariesWelcomeItems
              ).map((item) => (
                <IconCard
                  key={item.title}
                  title={item.title}
                  iconSrc={item.icon}
                  iconAlt={item.alt}
                  className="bs-sm bg-white px24 py24 br8 wmx8"
                >
                  {item.description}
                </IconCard>
              ))}
            </div>
          </div>
          <Button
            className="wmx5 wmn3 mt24 mr16 mb8"
            onClick={() => setValidForSubmission(true)}
            data-cy="button-continue"
          >
            Get Started
          </Button>
        </Header>
      </div>
    </div>
  );
};
