import { website } from '@getpopsure/private-constants';
import { Blocker } from 'components/Blocker';
import routes from 'constants/routes';
import { useSafeTranslation } from 'shared/i18n';

export const DelinquencyBlockerPage = () => {
  const { t } = useSafeTranslation();

  return (
    <Blocker
      title={t('account.delinquent.blocker.title', "We can't sign you up...")}
      description={t(
        'account.delinquent.blocker.description',
        'It looks like your monthly insurance payments failed. You can purchase a new policy once the outstanding insurance premiums are successfully collected.\n\nPlease contact us to resolve the issue and provide the preferred payment method for collecting the missing funds. You can also check the [billing history]({{link}}) in your Feather account to see which payments failed.',
        {
          link: routes.account.billingHistory.path,
        }
      )}
      buttonProps={[
        {
          type: 'href',
          href: website.support,
          caption: t(
            'account.delinquent.blocker.primaryButton.cta',
            'Speak to us'
          ),
        },
      ]}
      iconType="SHIELD"
    />
  );
};
