import { CurrencyInput } from '@popsure/dirty-swan';
import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, useState } from 'react';

import QuestionnaireWrapper from '../QuestionnaireWrapper';

interface Props
  extends QuestionnaireFormProps<number>,
    QuestionnaireWrapperProps {
  validate?: (value?: number) => boolean;
}

export const CurrencyInputForm = ({
  onSubmitValue,
  value,
  question,
  description,
  buttonText,
  header,
  hideLabel,
  label,
  placeholder,
  featureName,
  error,
  questionClassName = '',
  additionalInfo,
  validate,
}: Props) => {
  const [currency, setCurrency] = useState<number | undefined>(value);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (typeof currency === 'undefined') {
      throw new Error(
        `[${featureName}] Currency value is missing for submission for question: ${question}`
      );
    }
    onSubmitValue(currency);
  };

  const validInput = validate ? validate(currency) : currency;

  return (
    <QuestionnaireWrapper
      header={header}
      question={question}
      questionClassName={questionClassName}
      description={description}
      onSubmit={handleSubmit}
      error={error}
      buttonText={buttonText}
      validForSubmission={!!validInput}
      additionalInfo={additionalInfo}
    >
      <CurrencyInput
        placeholder={placeholder}
        label={label}
        hideLabel={hideLabel}
        value={currency}
        onChange={setCurrency}
        data-cy="input"
      />
    </QuestionnaireWrapper>
  );
};
