import CancelModal from '../legacy/cancelModal';
import { PolicySingleModalProps } from './PolicySingleModal.models';

export const CancelPolicyModal = (props: PolicySingleModalProps) => {
  const {
    onClose,
    policyData: { id },
  } = props;
  return <CancelModal isOpen id={id} onCloseModal={onClose} />;
};
