import { PrivateHealthClaims } from 'features/privateHealthClaims/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';

import { PrivateHealthClaimsGettingStartedView } from './GettingStarted.view';

export const PrivateHealthClaimsGettingStarted = ({
  onSubmitValue,
  metaData: { policyId },
}: CustomComponentProps<PrivateHealthClaims>) => {
  const { t } = useSafeTranslation();

  const handleSubmit = () => {
    onSubmitValue(true);
  };

  return (
    <PrivateHealthClaimsGettingStartedView
      handleGetStarted={handleSubmit}
      t={t}
      policyId={policyId}
    />
  );
};
