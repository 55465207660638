import Session from '@getpopsure/session';
import { signInWithLoginCode, submitSignInEmail } from 'actions/session';
import connectedLoader, { LoaderProps } from 'containers/loader';
import View from 'features/signIn/components';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from 'reducers';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  formatErrorMessageFromError,
  getRequestError,
} from 'selectors/requests';

interface StateProps {
  isUserSignedIn: boolean;
  error?: string;
}

interface DispatchProps {
  submitSignInEmail: (
    email: string,
    setEmailSubmitted?: (value: boolean) => void
  ) => void;
  signInWithLoginCode: (email: string, loginCode: string) => void;
}

type OwnProps = RouteComponentProps & LoaderProps;

const mapStateToProps = (state: AppState): StateProps => {
  const submitEmailError = getRequestError(state, 'SIGN_IN_EMAIL');
  const signInWithLoginCodeError = getRequestError(
    state,
    'SIGN_IN_WITH_LOGIN_CODE'
  );

  let error: string | undefined;

  if (submitEmailError) {
    error = formatErrorMessageFromError(submitEmailError);
  }

  if (signInWithLoginCodeError) {
    error = formatErrorMessageFromError(signInWithLoginCodeError);
  }

  if (signInWithLoginCodeError?.response?.status === 422) {
    error = '422';
  }

  return {
    isUserSignedIn: Session.isAuthenticated,
    error,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>
): DispatchProps => ({
  submitSignInEmail: (
    email: string,
    setEmailSubmitted?: (value: boolean) => void
  ) => dispatch(submitSignInEmail(email, setEmailSubmitted)),
  signInWithLoginCode: (email: string, loginCode: string) =>
    dispatch(signInWithLoginCode(email, loginCode)),
});

export type ViewProps = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(
  connectedLoader('SIGN_IN_WITH_LOGIN_CODE')(
    connectedLoader('SIGN_IN_EMAIL')(View)
  )
);
