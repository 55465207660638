import { createBrowserHistory } from 'history';

/**
 * @deprecated use useHistory hook instead
 *
 * We should avoid accessing the history object outside react components,
 * if you want to access the history object after dispatching actions
 * you can use the following pattern:
 *
 *
 * const MyFeatureContainer = () => {
 *  const dispatch = useDispatch();
 *  const history = useHistory()
 *  ...
 *  const handleUserEvent = () => {
 *    await dispatch(myAction);
 *    history.push(nextPage);
 *  }
 * }
 *
 *
 *
 */
const browserHistory = createBrowserHistory();

export default browserHistory;
