import { trackTaskEngineCardClicked } from '@getpopsure/analytics';
import { CustomTask } from 'features/taskEngine/components/CustomTask';
import { TASK_ENGINE_REQUEST_TYPE } from 'features/taskEngine/constants';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { getTitleMapping } from 'models/insurances/types/mapping';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import {
  ClaimMissingInfoRedirectTaskSchema,
  Task,
  TaskEngineOptions,
} from '../../taskEngine.models';

export const ClaimAddMissingInfo = ({
  task,
  options,
}: {
  task: Task;
  options?: TaskEngineOptions;
}) => {
  const [showModal, setShowModal] = useState(false);
  const { loading, error } = useRequestStatus(TASK_ENGINE_REQUEST_TYPE);
  const history = useHistory();
  const { t } = useSafeTranslation();

  const parsedTask = ClaimMissingInfoRedirectTaskSchema.safeParse(task);

  if (!parsedTask.success) {
    return null;
  }

  const {
    description: {
      type,
      attributes: { claimId, insuranceType },
    },
  } = parsedTask.data;

  const mappedInsurance = getTitleMapping(t)[insuranceType];

  const subtitle = `${mappedInsurance} ${t(
    'taskEngine.claimMissingInfo.subtitle',
    'claim'
  )}`;

  const handleClick = () => {
    trackTaskEngineCardClicked({
      task_type: type,
      task_id: task.id,
    });

    history.push(`/claims/${claimId}`);
  };

  const openModal = () => {
    trackTaskEngineCardClicked({
      task_type: type,
      task_id: task.id,
    });

    if (options?.onTaskClick) {
      options.onTaskClick(task);
    }

    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  return (
    <CustomTask
      metadata={{
        title: t(
          'taskEngine.claimMissingInfo.title',
          'We need extra details for your claim'
        ),
        subtitle,
        description: t(
          'taskEngine.claimMissingInfo.description',
          'Please use the following link to provide the additional information'
        ),
      }}
      modal={{
        isOpen: showModal,
        loading,
        error: error?.response?.data?.message,
        onOpen: openModal,
        onClose: closeModal,
      }}
      onSubmit={handleClick}
      actions={[
        {
          id: '1',
          type: 'LINK',
          attributes: {
            href: `${window.location.origin}/claims/${claimId}`,
          },
        },
        {
          id: '2',
          type: 'SUBMIT',
          attributes: {
            value: 'Complete',
          },
        },
      ]}
    />
  );
};
