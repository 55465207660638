import dayjs from 'dayjs';
import type { LiabilityQuestionnaire } from 'features/recommendationTool/models/liability';
import { getAccountInfo } from 'features/recommendationTool/questionnaires/account';

import { createProcessingQuestion, submitQuestionId } from '../processing';

const getDateOfBirthYearRange = () => {
  const now = dayjs();
  return {
    min: now.subtract(100, 'years').year(),
    max: now.year(),
  };
};

const liabilityQuestionnaire: LiabilityQuestionnaire = {
  dateOfBirth: {
    required: true,
    type: 'DATE',
    props: {
      question: 'What’s your date of birth?',
      yearRange: getDateOfBirthYearRange(),
    },
    retrieveAnswerObject: (dateOfBirth: string) => ({ dateOfBirth }),
    retrieveNextPageId: () => submitQuestionId,
    groupId: 'personalInfo',
  },
  ...createProcessingQuestion('liability'),
};

const accountInfo = getAccountInfo<LiabilityQuestionnaire>('dateOfBirth');

const liabilityQuestionnaireWithAccount: LiabilityQuestionnaire = {
  ...accountInfo,
  ...liabilityQuestionnaire,
};

export default liabilityQuestionnaireWithAccount;
