import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { Button } from '@popsure/dirty-swan';
import ball from 'features/life/icons/ball.svg';
import heart from 'features/life/icons/heart.svg';
import shield from 'features/life/icons/shield.svg';
import woman from 'features/life/icons/woman.svg';
import { Life } from 'features/life/models';

import styles from './style.module.scss';

export const cardContent = [
  {
    title: 'Personal Info',
    icon: { src: woman, alt: 'purple woman' },
  },
  {
    title: 'Insurance History',
    icon: { src: shield, alt: 'purple shield' },
  },
  {
    title: 'Medical History',
    icon: { src: heart, alt: 'purple heart' },
  },
  {
    title: 'Lifestyle',
    icon: { src: ball, alt: 'purple basketball' },
  },
];

export const Intro = ({
  onSubmitValue,
}: QuestionnaireFormProps<Life, boolean>) => (
  <div className={styles.container}>
    <div className="p-body">
      <h1 className="p-h1 mt24">Let's get you insured!</h1>
      <p className="p-p mt16">
        To check if you are eligible, we'll ask you questions about the
        following:
      </p>
      <div className={`mt8 d-flex f-wrap ${styles.cardsContainer}`}>
        {cardContent.map(({ title, icon }) => (
          <div
            key={title}
            className={`ws3 wmn2 ta-center mt16 mr16 bg-white br8 p24 ${styles.card} ${styles.cardContainer}`}
          >
            <img src={icon.src} alt={icon.alt} />
            <h4 className="p-h4 mt16">{title}</h4>
          </div>
        ))}
      </div>
      <Button
        onClick={() => onSubmitValue(true)}
        type="button"
        className={`wmn3 mt24 ${styles.nextButton}`}
        data-cy="button-continue"
      >
        Continue
      </Button>
    </div>
  </div>
);
