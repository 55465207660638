const cdnBaseUrl =
  'https://assets.cdn.feather-insurance.com/assets/images/insurances';

export const basisPension = `${cdnBaseUrl}/basisPension.png`;
export const basisPension2x = `${cdnBaseUrl}/basisPension2x.png`;
export const basisPension3x = `${cdnBaseUrl}/basisPension3x.png`;
export const bike = `${cdnBaseUrl}/bike.png`;
export const bike2x = `${cdnBaseUrl}/bike2x.png`;
export const bike3x = `${cdnBaseUrl}/bike3x.png`;
export const car = `${cdnBaseUrl}/car.png`;
export const car2x = `${cdnBaseUrl}/car2x.png`;
export const car3x = `${cdnBaseUrl}/car3x.png`;
export const dental = `${cdnBaseUrl}/dental.png`;
export const dental2x = `${cdnBaseUrl}/dental2x.png`;
export const dental3x = `${cdnBaseUrl}/dental3x.png`;
export const disability = `${cdnBaseUrl}/disability.png`;
export const disability2x = `${cdnBaseUrl}/disability2x.png`;
export const disability3x = `${cdnBaseUrl}/disability3x.png`;
export const dogLiability = `${cdnBaseUrl}/dogLiability.png`;
export const dogLiability2x = `${cdnBaseUrl}/dogLiability2x.png`;
export const dogLiability3x = `${cdnBaseUrl}/dogLiability3x.png`;
export const ehic = `${cdnBaseUrl}/ehic.png`;
export const ehic2x = `${cdnBaseUrl}/ehic2x.png`;
export const ehic3x = `${cdnBaseUrl}/ehic3x.png`;
export const expat = `${cdnBaseUrl}/expat.png`;
export const expat2x = `${cdnBaseUrl}/expat2x.png`;
export const expat3x = `${cdnBaseUrl}/expat3x.png`;
export const generic = `${cdnBaseUrl}/generic.png`;
export const generic2x = `${cdnBaseUrl}/generic2x.png`;
export const generic3x = `${cdnBaseUrl}/generic3x.png`;
export const health = `${cdnBaseUrl}/health.png`;
export const health2x = `${cdnBaseUrl}/health2x.png`;
export const health3x = `${cdnBaseUrl}/health3x.png`;
export const household = `${cdnBaseUrl}/household.png`;
export const household2x = `${cdnBaseUrl}/household2x.png`;
export const household3x = `${cdnBaseUrl}/household3x.png`;
export const job = `${cdnBaseUrl}/job.png`;
export const job2x = `${cdnBaseUrl}/job2x.png`;
export const job3x = `${cdnBaseUrl}/job3x.png`;
export const legal = `${cdnBaseUrl}/legal.png`;
export const legal2x = `${cdnBaseUrl}/legal2x.png`;
export const legal3x = `${cdnBaseUrl}/legal3x.png`;
export const liability = `${cdnBaseUrl}/liability.png`;
export const liability2x = `${cdnBaseUrl}/liability2x.png`;
export const liability3x = `${cdnBaseUrl}/liability3x.png`;
export const life = `${cdnBaseUrl}/life.png`;
export const life2x = `${cdnBaseUrl}/life2x.png`;
export const life3x = `${cdnBaseUrl}/life3x.png`;
export const pension = `${cdnBaseUrl}/pension.png`;
export const pension2x = `${cdnBaseUrl}/pension2x.png`;
export const pension3x = `${cdnBaseUrl}/pension3x.png`;
export const petHealth = `${cdnBaseUrl}/petHealth.png`;
export const petHealth2x = `${cdnBaseUrl}/petHealth2x.png`;
export const petHealth3x = `${cdnBaseUrl}/petHealth3x.png`;
export const privateHealth = `${cdnBaseUrl}/private.png`;
export const privateHealth2x = `${cdnBaseUrl}/private2x.png`;
export const privateHealth3x = `${cdnBaseUrl}/private3x.png`;
export const publicHealth = `${cdnBaseUrl}/public.png`;
export const publicHealth2x = `${cdnBaseUrl}/public2x.png`;
export const publicHealth3x = `${cdnBaseUrl}/public3x.png`;
export const travel = `${cdnBaseUrl}/travel.png`;
export const travel2x = `${cdnBaseUrl}/travel2x.png`;
export const travel3x = `${cdnBaseUrl}/travel3x.png`;
