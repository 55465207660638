import { User } from 'models/user';
import { TFunction } from 'shared/i18n';

const normalizeAccountInfoForFrontend = (t: TFunction, accountInfo?: User) => {
  if (!accountInfo) {
    return {};
  }

  const { firstName, lastName, dateOfBirth, email } = accountInfo;
  let normalizedAccountInfo = {};

  if (firstName && lastName) {
    normalizedAccountInfo = {
      ...normalizedAccountInfo,
      [t('personalInfo.name.label', 'Name')]: `${firstName} ${lastName}`,
    };
  }

  if (dateOfBirth) {
    normalizedAccountInfo = {
      ...normalizedAccountInfo,
      [t('personalInfo.dob.label', 'Date of birth')]: dateOfBirth,
    };
  }

  if (email) {
    normalizedAccountInfo = {
      ...normalizedAccountInfo,
      [t('personalInfo.email.label', 'Email')]: email,
    };
  }

  return normalizedAccountInfo;
};

export default normalizeAccountInfoForFrontend;
