import { getHouseholdPrices } from '@getpopsure/household-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { CollapsibleCard } from 'components/CollapsibleCard';
import { SliderWithModal } from 'components/SliderWithModal';
import { HouseholdAddon } from 'features/household/models';
import { getPolicyOptionsOrDefaults } from 'features/household/selectors';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

import styles from './CustomizationSection.module.scss';
import { ToggleCard } from './ToggleCard';

type CustomizationSectionProps = {
  bikeInsuredSum: number;
  selectedAddons: HouseholdAddon[];
  setBikeInsuredSum: React.Dispatch<React.SetStateAction<number>>;
  setSelectedAddons: React.Dispatch<React.SetStateAction<HouseholdAddon[]>>;
  sliderConfig: {
    BASIC_BIKE_COVERAGE: number;
    MINIMUM_BIKE_INSURED_SUM: number;
    MAXIMUM_BIKE_INSURED_SUM: number;
    SLIDER_STEP_SIZE_IN_EURO: number;
  };
};

export const CustomizationSection = ({
  bikeInsuredSum,
  selectedAddons,
  setBikeInsuredSum,
  setSelectedAddons,
  sliderConfig,
}: CustomizationSectionProps) => {
  const isDesktop = useMediaQuery('ABOVE_TABLET');

  const { hasSecurityDevice, insuredSum, postcode, propertyInSqMeters } =
    useSelector(getPolicyOptionsOrDefaults);

  const {
    BASIC_BIKE_COVERAGE,
    MINIMUM_BIKE_INSURED_SUM,
    MAXIMUM_BIKE_INSURED_SUM,
    SLIDER_STEP_SIZE_IN_EURO,
  } = sliderConfig;

  const { t } = useSafeTranslation();

  const householdPrices = getHouseholdPrices({
    postcode,
    propertyInSqMeters,
    insuredSum,
    hasSecurityDevice,
    bikeInsuredSum,
  });

  const extendedCoveragePrice = householdPrices
    ? householdPrices.extendedPremium - householdPrices.basicPremium
    : 0;

  const brokenGlassPrice = householdPrices ? householdPrices.glassPrice : 0;

  const additionalBikeCoveragePrice = householdPrices
    ? householdPrices.bikePrice
    : 0;

  const toggle = (value: HouseholdAddon) => () => {
    setSelectedAddons((currentOptions) => {
      const index = currentOptions.indexOf(value);

      if (index !== -1) {
        return currentOptions.filter((option) => option !== value);
      }

      return currentOptions.concat(value);
    });
  };

  const extendedCoverageSelected = selectedAddons.includes('EXTENDED_COVERAGE');
  const brokenGlassCoverageSelected = selectedAddons.includes(
    'BROKEN_GLASS_COVERAGE'
  );
  const additionalBikeCoverageSelected = selectedAddons.includes(
    'ADDITIONAL_BIKE_COVERAGE'
  );

  return (
    <section className="pt64">
      <h2 className="p-h2 mb16">
        {t('page.household.addons.customization.title', 'Add-ons')}
      </h2>
      <ToggleCard
        isSelected={extendedCoverageSelected}
        onChange={toggle('EXTENDED_COVERAGE')}
        toggleKey="EXTENDED_COVERAGE"
        price={extendedCoveragePrice}
        title={t(
          'page.household.addons.customization.extendedcoverage.title',
          '💯 Extended coverage'
        )}
        description={t(
          'page.household.addons.customization.extendedcoverage.description',
          'Coverage for your belongings during robberies, fires, water damage from pipes, and other situations. Need something extra? Let add-ons cover the rest.'
        )}
      >
        <>
          <p className="p-p mt32">
            {t(
              'page.household.addons.extendedcoverage.whatadditionalcoverage',
              'What additional coverage do I get?'
            )}
          </p>
          <div className={`d-grid gap16 mt16 ${styles.grid}`}>
            <div className="d-flex fd-column r-gap16">
              <CollapsibleCard
                openByDefault
                title={t(
                  'page.household.addons.customization.extendedcoverage.card.1.title',
                  'Bicycle theft'
                )}
              >
                {t(
                  'page.household.addons.customization.extendedcoverage.card.1.description',
                  {
                    defaultValue:
                      "If your bike is stolen, it's covered up to 1% of the insured sum ({{basicBikeCoverage}}). For more coverage, you can get the add-on **comprehensive bike theft coverage**.",
                    basicBikeCoverage: englishFormattedEuroCurrency(
                      BASIC_BIKE_COVERAGE,
                      true
                    ),
                  }
                )}
              </CollapsibleCard>
              <CollapsibleCard
                title={t(
                  'page.household.addons.customization.extendedcoverage.card.2.title',
                  'Moving expenses'
                )}
              >
                {t(
                  'page.household.addons.customization.extendedcoverage.card.2.description',
                  {
                    defaultValue:
                      'If you’re no longer able to live inside of your apartment due to an insured event, you can get {{coverage}} of support. ',
                    coverage: englishFormattedEuroCurrency(5000, true),
                  }
                )}
              </CollapsibleCard>
              <CollapsibleCard
                title={t(
                  'page.household.addons.customization.extendedcoverage.card.3.title',
                  'Transporting belongings'
                )}
              >
                {t(
                  'page.household.addons.customization.extendedcoverage.card.3.description',
                  'Insured belongings damaged during a transportation accident are covered (as long as there is a police report).'
                )}
              </CollapsibleCard>
            </div>
            <div className="d-flex fd-column r-gap16">
              <CollapsibleCard
                openByDefault={isDesktop}
                title={t(
                  'page.household.addons.customization.extendedcoverage.card.4.title',
                  'Negligence'
                )}
              >
                {t(
                  'page.household.addons.customization.extendedcoverage.card.4.description',
                  'An unlocked door that led to a burglary? Accidentally left a candle burning? Even when you’re careless, you’ll be covered.'
                )}
              </CollapsibleCard>
              <CollapsibleCard
                title={t(
                  'page.household.addons.customization.extendedcoverage.card.5.title',
                  'Frozen Goods'
                )}
              >
                {t(
                  'page.household.addons.customization.extendedcoverage.card.5.description',
                  'Medications and food stored in a freezer that are no longer useable after a power outage are covered.'
                )}
              </CollapsibleCard>
              <CollapsibleCard
                title={t(
                  'page.household.addons.customization.extendedcoverage.card.6.title',
                  'Internal conflict'
                )}
              >
                {t(
                  'page.household.addons.customization.extendedcoverage.card.6.description',
                  "If your belongings are damaged during internal conflicts like a riot or strike, you're covered."
                )}
              </CollapsibleCard>
            </div>
          </div>
        </>
      </ToggleCard>
      <ToggleCard
        className="mt16"
        isSelected={brokenGlassCoverageSelected}
        onChange={toggle('BROKEN_GLASS_COVERAGE')}
        toggleKey="BROKEN_GLASS_COVERAGE"
        price={brokenGlassPrice}
        title={t(
          'page.household.addons.customization.brokenglasscoverage.title',
          '🔨 Broken glass'
        )}
        description={t(
          'page.household.addons.customization.brokenglasscoverage.description',
          'Repair costs and replacements are covered for your belongings, windows, aquariums, induction stovetops, shower walls, glass furniture, and glass doors inside your home.'
        )}
      />
      <ToggleCard
        disabled={!extendedCoverageSelected}
        className="mt16"
        isSelected={additionalBikeCoverageSelected}
        toggleKey="ADDITIONAL_BIKE_COVERAGE"
        onChange={toggle('ADDITIONAL_BIKE_COVERAGE')}
        price={!extendedCoverageSelected ? '€—' : additionalBikeCoveragePrice}
        title={t(
          'page.household.addons.customization.additionalbikecoverage.title',
          '🚲 Comprehensive bike theft coverage'
        )}
        subtitle={
          !extendedCoverageSelected
            ? t(
                'page.household.addons.customization.additionalbikecoverage.subtitle',
                'Only available with our extended coverage'
              )
            : ''
        }
        description={t(
          'page.household.addons.customization.additionalbikecoverage.description',
          {
            defaultValue:
              'Comprehensive bicycle theft coverage will cover anything after the initial {{basicBikeCoverage}}, which also applies to most electric bikes, batteries, and accessories attached to the bike. For bikes less than 6 months old and over {{maxBikeCoverage}}, we recommend Feather bike insurance.',
            basicBikeCoverage: englishFormattedEuroCurrency(
              BASIC_BIKE_COVERAGE,
              true
            ),
            maxBikeCoverage: englishFormattedEuroCurrency(
              MAXIMUM_BIKE_INSURED_SUM,
              true
            ),
          }
        )}
      >
        <div className="mt24">
          <SliderWithModal
            className="wmx6"
            disabled={
              !extendedCoverageSelected || !additionalBikeCoverageSelected
            }
            options={{ hideKnobAndValue: !extendedCoverageSelected }}
            label={t(
              'page.household.addons.customization.additionalbikecoverage.slider.label',
              'Bike coverage'
            )}
            min={MINIMUM_BIKE_INSURED_SUM}
            max={MAXIMUM_BIKE_INSURED_SUM}
            value={bikeInsuredSum}
            // When setting a value via the slider, map value to a multiple of sliderStepSize to simulate a step size of sliderStepSize
            setValue={(value) => {
              return setBikeInsuredSum(
                Math.max(
                  MINIMUM_BIKE_INSURED_SUM,
                  value - (value % SLIDER_STEP_SIZE_IN_EURO)
                )
              );
            }}
            // When manually entering a value in the modal no adjustment is needed
            modalSetValue={(value) => setBikeInsuredSum(value)}
            modalTextOverrides={{
              title: t(
                'page.household.addons.customization.additionalbikecoverage.inputmodal.title',
                'Bike coverage'
              ),
            }}
          />
        </div>
      </ToggleCard>
    </section>
  );
};
