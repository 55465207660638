import { selectI18nOptions } from 'features/i18n/selectors';
import { store } from 'reducers';
import { isStagingOrDev } from 'shared/util/isStagingOrDev';

import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';

export const cookieDomainOptions =
  process.env.NODE_ENV === 'development'
    ? // {} can be changed to { cookieDomain: '.localhost.com' } to test cross subdomain cookies locally in conjunction with
      // adding aliases for localhost to your /etc/hosts file like this:
      // 127.0.0.1      localhost.com
      // 127.0.0.1      app.localhost.com
      // ... more subdomains of .localhost.com

      // NOTE: Multiple _fili cookies will break the language switching etc, since they will "shadow" more / less specific cookies and there's no way to pick a specific one.
      // This means that after playing around with these settings make sure to delete excess _fili cookies in your browser to ensure proper function.

      // UPDATE: detectLocale() and changeLanguage() from the feather i18n package will remove such duplicate cookies and should fix aforementioned
      {}
    : { cookieDomain: '.feather-insurance.com' };

export const getI18nOptions = () => {
  return selectI18nOptions(store.getState());
};

const PHRASE_API_KEY = process.env.REACT_APP_PHRASE_API_KEY ?? '';

const getTranslationFromPhrase = async (translationId: string) => {
  try {
    const { Configuration, LocalesApi } = await import('phrase-js');
    const configuration = new Configuration({
      apiKey: `Bearer ${PHRASE_API_KEY}`,
    });

    const localesApi = new LocalesApi(configuration);

    const response = await localesApi.localeDownload({
      projectId: 'd65143b60b56ebec0b18595d2957fc8b',
      id: translationId,
      fileFormat: 'simple_json',
    });
    const fileReader = new FileReader();

    const data = await new Promise<ProgressEvent<FileReader>>(
      (resolve, reject) => {
        fileReader.onload = resolve;
        fileReader.onerror = reject;
        fileReader.readAsText(response);
      }
    );
    if (typeof data.target?.result === 'string') {
      return JSON.parse(data.target.result);
    }
    return {};
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

// Return placeholder text for everything
const xxProxy = new Proxy(
  {},
  {
    get: () => {
      return 'XXXXXXXXXX';
    },
  }
);

// Create a proxy to be able to dynamically change the translation source and fallback
const createLocaleProxy = ({
  translationFromCode,
  translationFromPhrase,
  enFallbackFromCode,
  enFallbackFromPhrase,
  placeholder,
}: {
  translationFromCode: Record<string, string>;
  translationFromPhrase: Record<string, string>;
  enFallbackFromCode: Record<string, string>;
  enFallbackFromPhrase: Record<string, string>;
  placeholder: string;
}) =>
  new Proxy(
    {},
    {
      get: (_, property, __) => {
        const { translationSource, fallbackOption } = getI18nOptions();

        const translation =
          translationSource === 'PHRASE'
            ? translationFromPhrase
            : translationFromCode;

        const enFallbackTranslation =
          translationSource === 'PHRASE'
            ? enFallbackFromPhrase
            : enFallbackFromCode;

        // If the requested translation key (= property) has a value => return that value
        if (translation[property as string]) {
          return translation[property as string];
        }

        // Requested key has no value => return fallback
        switch (fallbackOption) {
          case 'PLACEHOLDER':
            return placeholder;
          case 'KEY':
            return property;
          case 'ENGLISH':
          default:
            return enFallbackTranslation[property as string];
        }
      },
    }
  );

export const englishOnlyTranslations = {
  en: { translation: en },
};

export const getTranslations = async () => {
  const enFromPhrase = isStagingOrDev
    ? await getTranslationFromPhrase('en')
    : {};
  const deFromPhrase = isStagingOrDev
    ? await getTranslationFromPhrase('de')
    : {};
  const esFromPhrase = isStagingOrDev
    ? await getTranslationFromPhrase('es')
    : {};

  return {
    en: {
      translation: isStagingOrDev
        ? createLocaleProxy({
            translationFromCode: en,
            translationFromPhrase: enFromPhrase,
            enFallbackFromCode: en,
            enFallbackFromPhrase: enFromPhrase,
            placeholder: 'ENENENENEN',
          })
        : en,
    },
    de: {
      translation: isStagingOrDev
        ? createLocaleProxy({
            translationFromCode: de,
            translationFromPhrase: deFromPhrase,
            enFallbackFromCode: en,
            enFallbackFromPhrase: enFromPhrase,
            placeholder: 'DEDEDEDEDE',
          })
        : de,
    },
    es: {
      translation: isStagingOrDev
        ? createLocaleProxy({
            translationFromCode: es,
            translationFromPhrase: esFromPhrase,
            enFallbackFromCode: en,
            enFallbackFromPhrase: enFromPhrase,
            placeholder: 'ESESESESES',
          })
        : es,
    },
    fr: {
      translation: fr,
    },
    xx: { translation: xxProxy },
  };
};
