import { BottomOrRegularModal, Button } from '@popsure/dirty-swan';
import { PaymentType } from 'models/paymentMethods';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

export const AgreementButton = ({
  paymentType,
}: {
  paymentType: PaymentType;
}) => {
  const { t } = useSafeTranslation();
  const [viewAgreement, setViewAgreement] = useState(false);

  const agreementContent: { [k in PaymentType]: string } = {
    SEPA: t(
      'components.paymentMethodSelector.agreement.sepa',
      'By providing your IBAN and confirming this payment, you authorise (A) Popsure Deutschland GmbH and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited.'
    ),
    CARD: t(
      'components.paymentMethodSelector.agreement.card',
      'I authorise Popsure Deutschland GmbH to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.'
    ),
    PAYPAL: t(
      'components.paymentMethodSelector.agreement.paypal',
      'By confirming your payment with PayPal, you allow Popsure Deutschland GmbH to charge your PayPal account for future payments in accordance with their terms.'
    ),
  };

  return (
    <>
      <div className="mt16 d-flex jc-center">
        <Button
          variant="textColor"
          data-testid="agreement-button"
          type="button"
          onClick={() => setViewAgreement(true)}
        >
          {t(
            'components.paymentMethodSelector.agreement.view',
            'View agreement'
          )}
        </Button>
      </div>

      <BottomOrRegularModal
        title={t('components.paymentMethodSelector.agreement.modalTitle', {
          defaultValue: '{{paymentMethod}} Mandate',
          paymentMethod: paymentType === 'SEPA' ? 'SEPA' : 'Card',
        })}
        onClose={() => setViewAgreement(false)}
        isOpen={viewAgreement}
      >
        <p className="p-p mt16 p24 pt0" data-testid="agreement-modal">
          {paymentType && agreementContent[paymentType]}
        </p>
      </BottomOrRegularModal>
    </>
  );
};
