import { TFunction } from '@getpopsure/i18n-react';
import { calendly, website } from '@getpopsure/private-constants';
import routes from 'constants/routes';
import {
  centimetersToFeet,
  feetToCentimeters,
  kgToLbs,
  lbsToKg,
} from 'shared/util/converters';

import { getProfessionOptions } from '../constants/profession';
import { questionPropLookup } from '../lookups';
import { DisabilityV2Questionnaire } from '.';

export const getPreQuoteQuestionnaire = (
  t: TFunction
): DisabilityV2Questionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'INTRO',
    props: {
      title: t(
        'disabilityV2.qnr.preQuote.intro.title',
        "Hey! Let's get you a quote in minutes"
      ),
      subTitle: t(
        'disabilityV2.qnr.preQuote.intro.subTitle',
        'It takes less than a minute, no email needed.'
      ),
    },
    screen: {
      continueButtonText: 'Get started',
    },
    groupId: 'preQuote',
  },
  {
    id: 'dateOfBirth',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'add', type: 'years', value: 0 },
      },
    },
    screen: {
      ...questionPropLookup(t).dateOfBirth,
    },
    groupId: 'preQuote',
  },
  {
    id: 'ageYoungerThan18Blocker',
    type: 'BLOCKER',
    props: {
      iconType: 'SHIELD',
      title: t('disabilityV2.qnr.ageBlocker.title', "We can't cover you"),
      description: t('disabilityV2.qnr.ageYoungerThan18Blocker.description.1', {
        defaultValue:
          'We currently only cover people who are over 18 years old. \n\n You can speak to one of our insurance experts if you have any questions.',
        url: routes.insuranceTool.path,
      }),
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: t(
            'disabilityV2.qnr.ageBlocker.speakToExpert.caption',
            'Speak to us'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'ageOlderThan50Blocker',
    type: 'BLOCKER',
    props: {
      title: t('disabilityV2.qnr.ageBlocker.title', "We can't cover you"),
      description: t('disabilityV2.qnr.ageOlderThan50Blocker.description.1', {
        defaultValue:
          'We currently only cover people who are under 50 years old. \n\n You can speak to one of our insurance experts if you have any questions.',
        url: routes.insuranceTool.path,
      }),
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: t(
            'disabilityV2.qnr.ageBlocker.speakToExpert.caption',
            'Speak to us'
          ),
        },
      ],
      iconType: 'SHIELD',
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'occupation',
    required: true,
    groupId: 'preQuote',
    type: 'RADIO',
    props: {
      mapValue: {},
      ...questionPropLookup(t).occupation,
    },
    screen: {
      question: questionPropLookup(t).occupation.question,
    },
  },
  {
    id: 'occupationBlocker',
    type: 'BLOCKER',
    groupId: 'preQuote',
    props: {
      title: t(
        'disabilityV2.qnr.occupationBlocker.title',
        "We can't sign you up"
      ),
      description: t(
        'disabilityV2.qnr.occupationBlocker.description',
        "It looks like we can't offer you this occupational disability insurance.\n\n We might still have another option for you. You can speak to one of our insurance experts."
      ),
      buttonProps: [
        {
          type: 'calendly',
          trackingCategory: 'DISABILITY_INSURANCE',
          caption: t(
            'disabilityV2.qnr.occupationBlocker.bookACallButton.caption',
            'Speak to us'
          ),
          calendlyLink: `${calendly.base}/occupational-disability`,
        },
      ],
      iconType: 'SHIELD',
    },
    screen: {
      layout: 'Standalone',
    },
  },
  {
    id: 'profession',
    required: true,
    groupId: 'preQuote',
    type: 'DROPDOWN_SINGLE',
    props: {
      ...questionPropLookup(t).profession,
      options: getProfessionOptions,
    },
    screen: {
      question: questionPropLookup(t).profession.question,
    },
  },
  {
    id: 'monthlyNetIncome',
    required: true,
    groupId: 'preQuote',
    type: 'CURRENCY',
    props: {
      ...questionPropLookup(t).monthlyNetIncome,
    },
    screen: {
      question: questionPropLookup(t).monthlyNetIncome.question,
    },
  },
  {
    id: 'monthlyNetIncomeBlocker',
    type: 'BLOCKER',
    groupId: 'preQuote',
    props: {
      title: t(
        'disabilityV2.qnr.monthlyNetIncomeBlocker.title',
        "We can't sign you up"
      ),
      description: t(
        'disabilityV2.qnr.monthlyNetIncomeBlocker.description',
        "It looks like we can't offer you this occupational disability insurance.\n\n We might still have another option for you. You can speak to one of our insurance experts."
      ),
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: t(
            'disabilityV2.qnr.monthlyNetIncomeBlocker.contactUs.caption',
            'Speak to us'
          ),
        },
      ],
      iconType: 'SHIELD',
    },
    screen: {
      layout: 'Standalone',
    },
  },
  {
    id: 'degree',
    required: true,
    groupId: 'preQuote',
    type: 'RADIO',
    props: {
      mapValue: {},
      ...questionPropLookup(t).degree,
    },
    screen: {
      question: questionPropLookup(t).degree.question,
    },
  },
  {
    id: 'height',
    groupId: 'preQuote',
    required: true,
    type: 'NUMERIC_CONVERSION',
    props: {
      ...questionPropLookup(t).height,
      unitLabels: [
        t('disabilityV2.qnr.height.unit.cm', 'cm'),
        t('disabilityV2.qnr.height.unit.feet', 'feet'),
      ],
      placeholderPerUnit: ['Cm', 'Feet', 'Inches'],
      conversion: {
        type: 'Double',
        fns: [centimetersToFeet, feetToCentimeters],
      },
    },
    screen: {
      ...questionPropLookup(t).height,
    },
  },
  {
    id: 'weight',
    groupId: 'preQuote',
    required: true,
    type: 'NUMERIC_CONVERSION',
    props: {
      ...questionPropLookup(t).weight,
      unitLabels: [
        t('disabilityV2.qnr.weight.unit.kg', 'kg'),
        t('disabilityV2.qnr.weight.unit.lbs', 'lbs'),
      ],
      conversion: {
        type: 'Single',
        fns: [kgToLbs, lbsToKg],
      },
    },
    screen: {
      ...questionPropLookup(t).weight,
    },
  },
];
