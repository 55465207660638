import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { useSafeTranslation } from 'shared/i18n';

import styles from './CTASection.module.scss';

type CTASectionProps = {
  price: number;
  onContinueClick: () => void;
  isLoading: boolean;
};

export const CTASection = ({
  isLoading,
  onContinueClick,
  price,
}: CTASectionProps) => {
  const { t } = useSafeTranslation();

  const priceStr = englishFormattedEuroCurrency(price, true);

  return (
    <section className="bg-white">
      <div className="p-container bg-grey-100 br8">
        <h1 className="p-h1 p--serif pt40 pb8 ta-center">
          {t('disability.quote.title', 'Disability insurance')}
        </h1>
        <div className="d-flex fd-column ai-center jc-center w100">
          {isLoading ? (
            <span
              className={`ds-spinner ds-spinner__l ${styles.loadingSpinner}`}
            />
          ) : null}
          <h3 className="p-h1--xl p--serif tc-primary-500 ta-center">
            {priceStr}
          </h3>
        </div>
        <p className="p-p--small ta-center tc-grey-500">
          {t('disability.quote.calculator.perMonth', 'per month')}
        </p>
        <div className="d-flex r-gap16 fd-column ai-center jc-center mt16 pb40">
          <button
            className="w100 p-btn p-btn--primary wmx4"
            onClick={onContinueClick}
            type="button"
            data-cy="button-continue"
            disabled={isLoading}
          >
            {t(
              'disability.quote.continueButton2.caption',
              'Continue to add-ons'
            )}
          </button>
        </div>
      </div>
    </section>
  );
};
