import { website } from '@getpopsure/private-constants';
import { useSafeTranslation } from 'shared/i18n';

import feathers from './assets/feathers.png';
import feathers2x from './assets/feathers2x.png';
import feathers3x from './assets/feathers3x.png';
import styles from './style.module.scss';

const PageNotFound = () => {
  const { t } = useSafeTranslation();

  return (
    <div
      className={`d-flex ai-center jc-center fd-column ${styles.container}`}
      data-cy="404-page"
    >
      <img
        className={styles.img}
        src={feathers}
        srcSet={`${feathers} 1x, ${feathers2x} 2x, ${feathers3x} 3x`}
        alt=""
        aria-hidden
        height={96}
        width={156}
      />

      <h2 className="tc-grey-900 mt32 p-h2">
        {t('page.404.title', 'This page could not be found')}
      </h2>
      <p className="p-p mt8 ta-center wmx5">
        {t(
          'page.404.description',
          'Try checking the link to make sure it is correct.'
        )}
      </p>

      <a
        href={website.base}
        className={`p-btn--secondary mt32 wmn3 ${styles.button}`}
      >
        {t('page.404.return.action', 'Go to homepage')}
      </a>
    </div>
  );
};

export default PageNotFound;
