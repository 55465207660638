import { captureException } from '@sentry/react';

type CopyToClipboardReturnType = 'SUCCESS' | 'ERROR';

export const copyToClipboard = async (
  element: HTMLElement
): Promise<CopyToClipboardReturnType | void> => {
  /** Try to use the newest ClipboardItem API */
  if (window.ClipboardItem) {
    const blobInput = new Blob([element.innerHTML], { type: 'text/plain' });

    const clipboardItemInput = new window.ClipboardItem({
      'text/plain': blobInput,
    });

    await navigator.clipboard.write([clipboardItemInput]);
    return 'SUCCESS';
  }

  /** Else use the execCommand fallback */
  const selection = window.getSelection();

  if (!selection) {
    captureException(
      new Error(
        '[REFERRAL_ENGINE]: copyToClipboard cannot get selection from HTML element'
      )
    );
    return 'ERROR';
  }

  selection.removeAllRanges();

  const range = document.createRange();
  range.selectNodeContents(element);
  selection.addRange(range);

  document.execCommand('copy');

  selection.removeAllRanges();
  return 'SUCCESS';
};
