import { website } from '@getpopsure/private-constants';
import { captureException } from '@sentry/react';
import axios from 'axios';
import { useEffect } from 'react';
import { Trans } from 'shared/i18n';

import { TASK_ENGINE_SENTRY_CATEGORY } from '../../constants';

export const TaskError = ({
  error,
  className,
}: {
  error?: unknown;
  className?: string;
}) => {
  const refreshPage = () => window.location.reload();

  useEffect(() => {
    if (axios.isAxiosError(error)) {
      /**
       * Capture error if it's not an unauthorized error
       */
      if (error.response?.status && error.response?.status !== 401) {
        captureException(error, {
          tags: {
            category: TASK_ENGINE_SENTRY_CATEGORY,
          },
          extra: {
            description: 'Failed to load tasks',
          },
        });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={className}>
      <h1 className="p-h3 mb16">Tasks</h1>
      <p className="p-p">
        <Trans i18nKey="taskEngine.container.error">
          Something went wrong loading the tasks.
          <button
            type="button"
            className="p-a c-pointer bg-transparent"
            onClick={refreshPage}
          >
            Try refreshing the page.
          </button>
          <br />
          If the problem persists,{' '}
          <a className="p-a c-pointer bg-transparent" href={website.support}>
            please contact support.
          </a>
        </Trans>
      </p>
    </div>
  );
};
