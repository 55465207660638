import { uploadHealthCardPhoto } from 'actions/healthCard';
import LoadingSpinner from 'components/loadingSpinner';
import { isValidPhoto } from 'features/healthCard/util/isValidPhoto';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { featherIcon } from 'icons';
import { FileExtension, HealthCardDispatch } from 'models/healthCard';
import { ChangeEvent, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { getFileType } from 'shared/util/getFileType';

import CompletedView from './completed';
import ErrorView from './error';
import photoIcon from './img/photo-icon.svg';
import style from './style.module.scss';

const getPhotoFromEvent = (event: ChangeEvent<HTMLInputElement>): File | null =>
  event.target.files && event.target.files.length > 0
    ? event.target.files[0]
    : null;

const REQUEST_TYPE = 'UPDATE_HEALTH_CARD_DETAILS';

const HealthCardPhoto = () => {
  const { t } = useSafeTranslation();
  const token = useQueryParamValue('hctoken');
  const [photoUrl, setPhotoUrl] = useState<string>('');
  const [completed, setCompleted] = useState<boolean>(false);
  const dispatch = useDispatch<HealthCardDispatch>();
  const { loading: updateLoading, error: updateError } =
    useRequestStatus(REQUEST_TYPE);
  const [error, setError] = useState<string>('');

  if (!token) {
    throw new Error(
      '[HEALTH_CARD_CHECKLIST] trying to upload photo without a valid token'
    );
  }

  if (updateError) return <ErrorView />;

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setError('');
    const photo = getPhotoFromEvent(event);
    const {
      sub: { healthCardId },
      // TODO: [KONG] Define token type
      // eslint-disable-next-line
    } = token as any;

    if (!photo) return;

    try {
      const isValid = await isValidPhoto(photo);

      if (isValid) {
        const downloadUrl = await dispatch(
          uploadHealthCardPhoto({
            token,
            id: healthCardId,
            file: photo,
            fileExtension: getFileType(photo.name) as FileExtension,
          })
        );
        setPhotoUrl(downloadUrl ?? '');
        setCompleted(true);
      } else {
        setError(
          'Error: the photo should be at least 300px x 400px, and less than 15MB'
        );
      }
    } catch {
      setError('Error uploading the photo, try again.');
    }
  };

  if (completed && photoUrl) return <CompletedView photo={photoUrl} />;

  return (
    <div className="d-flex fd-column ai-center w100 wmx4 m-auto ta-center py24 px16">
      <img src={featherIcon} alt="Feather logo" className="mb32" />
      <h1 className="p-h1 mb8">
        {t('publichealth.policydetails.photoupload.title', 'Upload a photo')}
      </h1>
      <p className="p-p mb16">
        {t(
          'publichealth.policydetails.photoupload.description',
          'Use a neutral background and don’t forget to smile 😄'
        )}
      </p>
      {updateLoading ? (
        <div
          className={`tc-primary-500 bg-primary-100 ${style['spinner-container']}`}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <img width="132" height="178" src={photoIcon} alt="Portrait icon" />
      )}
      <AnimateHeight duration={100} height={error ? 'auto' : 0}>
        <div className="p-notice p-notice--danger mt16 p-p">{error}</div>
      </AnimateHeight>
      {!photoUrl && (
        <label
          className={`${
            photoUrl ? 'p-btn--secondary mt8' : 'p-btn--primary mt24'
          } w100`}
          htmlFor="health-card-photo-file-input"
        >
          {t('publichealth.policydetails.photoupload.cta', 'Choose file')}
          <input
            id="health-card-photo-file-input"
            type="file"
            accept="image/*"
            className={style.hidden}
            onChange={handleInputChange}
          />
        </label>
      )}
    </div>
  );
};

export default HealthCardPhoto;
