import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import Checkout from 'features/checkout';
import { CheckoutPolicyRequestPayload } from 'features/checkout/models';
import { InsuranceTypes } from 'models/insurances/types';

export const ReviewCheckout = <Questionnaire extends QuestionnaireQuestions>({
  questionnaireAnswers,
  type,
  mainPolicyId,
  PolicyDetails,
  checkoutIcon,
  additionalPolicyData = {},
  additionalData = {},
}: QuestionnaireFormProps<Questionnaire> & {
  type: InsuranceTypes;
  PolicyDetails: React.ComponentType<{
    additionalData?: Record<string, unknown>;
  }>;
  mainPolicyId?: string;
  checkoutIcon?: string;
  additionalPolicyData?: Record<string, unknown>;
  additionalData?: Record<string, unknown>;
}) => {
  const checkoutPolicyRequestPayload: CheckoutPolicyRequestPayload = {
    type,
    policyInfo: {
      quoteId: String(questionnaireAnswers.quoteId),
      questionnaireId: String(questionnaireAnswers.questionnaireId),
      mainPolicyId,
      ...additionalPolicyData,
    },
  };

  return (
    <Checkout
      checkoutPolicyRequestPayload={checkoutPolicyRequestPayload}
      policyDetailsComponent={<PolicyDetails additionalData={additionalData} />}
      mainPolicyId={mainPolicyId}
      checkoutIcon={checkoutIcon}
    />
  );
};
