import { TFunction } from '@getpopsure/i18n-react';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import type { CoverageTableDataEntry } from 'components/CoverageDetails/CoverageModal';

import transportIcon from './assets/bike.svg';
import fireIcon from './assets/fire.svg';
import heartIcon from './assets/heart.svg';
import helmetIcon from './assets/helmet.svg';
import otherIcon from './assets/other.svg';
import weatherIcon from './assets/storm.svg';
import theftIcon from './assets/theft.svg';
import outdoorIcon from './assets/tree.svg';
import waterIcon from './assets/water.svg';

export const getCoverageTableHeaders = (t: TFunction): React.ReactNode[] => [
  null,
  t('page.household.quote.coveragedetails.tableheader.basic', 'Basic coverage'),
  <>
    {t(
      'page.household.quote.coveragedetails.tableheader.extended',
      'Extended coverage'
    )}
    <span className="fw-normal">
      {' '}
      {t('page.household.quote.coveragedetails.tableheader.addon', 'add-on')}
    </span>
  </>,
];

const getValuablesTableData = (t: TFunction): CoverageTableDataEntry => ({
  title: t(
    'page.household.quote.coveragedetails.section.valuables.title',
    'Valuables'
  ),
  iconSrc: heartIcon,
  rows: [
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.valuables.1.label',
          'Valuables'
        ),
        t(
          'page.household.quote.coveragedetails.section.valuables.1.basic',
          '20% of the insured sum'
        ),
        t(
          'page.household.quote.coveragedetails.section.valuables.1.extended',
          '30% of the insured sum'
        ),
      ],
    },
    {
      cells: [
        <>
          {t(
            'page.household.quote.coveragedetails.section.valuables.2.label',
            'Valuables not in a safe'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'page.household.quote.coveragedetails.section.valuables.2.sublabel',
              '(only includes primary residence)'
            )}
          </p>
        </>,
        null,
        null,
      ],
      noSeparator: true,
    },
    {
      cells: [
        <li>
          {t(
            'page.household.quote.coveragedetails.section.valuables.3.label',
            'Cash'
          )}
        </li>,
        t('page.household.quote.coveragedetails.section.valuables.3.basic', {
          defaultValue: '{{limit}}',
          limit: englishFormattedEuroCurrency(1500, true),
        }),
        t('page.household.quote.coveragedetails.section.valuables.3.extended', {
          defaultValue: '{{limit}}',
          limit: englishFormattedEuroCurrency(1500, true),
        }),
      ],
      noSeparator: true,
      smallText: true,
      smallPadding: true,
    },
    {
      cells: [
        <li>
          {t(
            'page.household.quote.coveragedetails.section.valuables.4.label',
            'Documents'
          )}
        </li>,
        t('page.household.quote.coveragedetails.section.valuables.4.basic', {
          defaultValue: '{{limit}}',
          limit: englishFormattedEuroCurrency(3000, true),
        }),
        t('page.household.quote.coveragedetails.section.valuables.4.extended', {
          defaultValue: '{{limit}}',
          limit: englishFormattedEuroCurrency(5000, true),
        }),
      ],
      noSeparator: true,
      smallText: true,
      smallPadding: true,
    },
    {
      cells: [
        <li>
          {t(
            'page.household.quote.coveragedetails.section.valuables.5.label',
            'Jewelry, stamps, coins, and other valuables'
          )}
        </li>,
        t('page.household.quote.coveragedetails.section.valuables.5.basic', {
          defaultValue: '{{limit}}',
          limit: englishFormattedEuroCurrency(25_000, true),
        }),
        t('page.household.quote.coveragedetails.section.valuables.5.extended', {
          defaultValue: '{{limit}}',
          limit: englishFormattedEuroCurrency(25_000, true),
        }),
      ],
      smallText: true,
      smallPadding: true,
    },
  ],
});

const getFireDamageTableData = (t: TFunction): CoverageTableDataEntry => ({
  title: t(
    'page.household.quote.coveragedetails.section.firedamage.title',
    'Fire damage'
  ),
  iconSrc: fireIcon,
  rows: [
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.firedamage.1.label',
          'Fire, Lightning, Power surge, Explosion, Implosion'
        ),
        true,
        true,
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.firedamage.2.label',
          'Scorching, braising and utility heat damage'
        ),
        true,
        true,
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.firedamage.3.label',
          'Damage from smoke and soot'
        ),
        false,
        t(
          'page.household.quote.coveragedetails.section.firedamage.3.extended',
          {
            defaultValue: '20% of the insured sum until a max of {{max}}',
            max: englishFormattedEuroCurrency(10_000, true),
          }
        ),
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.firedamage.4.label',
          'Costs for hotel or similar accommodation if you are unable to live in your apartment'
        ),
        <>
          {t(
            'page.household.quote.coveragedetails.section.firedamage.4.basic',
            {
              defaultValue: '180 days at {{perNight}} per night',
              perNight: englishFormattedEuroCurrency(100, true),
            }
          )}
          <p className="p-p--small tc-grey-500 mt32">
            {t(
              'page.household.quote.coveragedetails.section.firedamage.4.sublabel',
              '(limited to 2% of the insured sum)'
            )}
          </p>
        </>,

        <>
          {t(
            'page.household.quote.coveragedetails.section.firedamage.4.extended',
            {
              defaultValue: '1 year at {{perNight}} per night',
              perNight: englishFormattedEuroCurrency(125, true),
            }
          )}
          <p className="p-p--small tc-grey-500 mt32">
            {t(
              'page.household.quote.coveragedetails.section.firedamage.4.sublabel',
              '(limited to 2% of the insured sum)'
            )}
          </p>
        </>,
      ],
    },
    {
      cells: [
        <>
          {t(
            'page.household.quote.coveragedetails.section.firedamage.5.label',
            'Additional travel costs for premature return from vacation because of an insured event'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'page.household.quote.coveragedetails.section.firedamage.5.sublabel',
              'Without minimum travel duration'
            )}
          </p>
        </>,
        englishFormattedEuroCurrency(5000, true),
        englishFormattedEuroCurrency(5000, true),
      ],
    },
  ],
});

const getTheftTableData = (t: TFunction): CoverageTableDataEntry => ({
  title: t(
    'page.household.quote.coveragedetails.section.theft.title',
    'Theft, fraud or robberies'
  ),
  iconSrc: theftIcon,
  rows: [
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.theft.1.label',
          'Burglary, vandalism after a burglary as well as robbery'
        ),
        true,
        true,
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.theft.2.label',
          'Simple theft from ship cabins and sleeper compartments'
        ),
        true,
        true,
      ],
    },
    {
      cells: [
        <>
          {t(
            'page.household.quote.coveragedetails.section.theft.3.label',
            'Simple theft from motor and water sports vehicles'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'page.household.quote.coveragedetails.section.theft.3.sublabel',
              '(Also from luggage boxes)'
            )}
          </p>
        </>,
        false,
        t('page.household.quote.coveragedetails.section.theft.3.extended', {
          defaultValue: '{{limit}}',
          limit: englishFormattedEuroCurrency(5000, true),
        }),
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.theft.4.label',
          'Simple theft of property in hospitals and nursing/elderly homes'
        ),
        false,
        <>
          <p className="mb24">
            {t(
              'page.household.quote.coveragedetails.section.theft.3.extended',
              {
                defaultValue: '{{limit}}',
                limit: englishFormattedEuroCurrency(5000, true),
              }
            )}
          </p>
          <p>
            {t(
              'page.household.quote.coveragedetails.section.theft.3.extended.highvalue',
              {
                defaultValue: '{{coverage}} for high-value items',
                coverage: englishFormattedEuroCurrency(600, true),
              }
            )}
          </p>
        </>,
      ],
    },
    {
      cells: [
        <>
          {t(
            'page.household.quote.coveragedetails.section.theft.5.label',
            'Trick theft'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'page.household.quote.coveragedetails.section.theft.5.sublabel',
              '(Through deception)'
            )}
          </p>
        </>,
        false,
        t('page.household.quote.coveragedetails.section.theft.5.extended', {
          defaultValue: '{{limit}}',
          limit: englishFormattedEuroCurrency(5000, true),
        }),
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.theft.6.label',
          'Customer, check or credit card fraud from a robbery or burglary'
        ),
        t('page.household.quote.coveragedetails.section.theft.6.basic', {
          defaultValue: '{{limit}}',
          limit: englishFormattedEuroCurrency(2500, true),
        }),
        t('page.household.quote.coveragedetails.section.theft.6.extended', {
          defaultValue: '{{limit}}',
          limit: englishFormattedEuroCurrency(5000, true),
        }),
      ],
    },
  ],
});

const getWeatherTableData = (t: TFunction): CoverageTableDataEntry => ({
  title: t(
    'page.household.quote.coveragedetails.section.weather.title',
    'Weather'
  ),
  iconSrc: weatherIcon,
  rows: [
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.weather.1.label',
          'Storm and hail damage'
        ),
        null,
        null,
      ],
      noSeparator: true,
    },
    {
      cells: [
        <p className="p-p--small tc-grey-500">
          {t(
            'page.household.quote.coveragedetails.section.weather.1.sublabel',
            'Items temporarily outside'
          )}
        </p>,
        true,
        true,
      ],
      noSeparator: true,
      smallPadding: true,
    },
    {
      cells: [
        <p className="p-p--small tc-grey-500">
          {t(
            'page.household.quote.coveragedetails.section.weather.1.sublabel2',
            'Through open windows'
          )}
        </p>,
        false,
        true,
      ],
      smallPadding: true,
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.weather.2.label',
          'Rain, snow and hail that has come directly into the apartment and has damaged belongings'
        ),
        false,
        t('page.household.quote.coveragedetails.section.weather.2.extended', {
          defaultValue: '{{coverage}} with a co-pay of {{copay}}',
          coverage: englishFormattedEuroCurrency(5000, true),
          copay: englishFormattedEuroCurrency(500, true),
        }),
      ],
    },
  ],
});

const getAdditionalCoverageTableDataEntry = (
  t: TFunction
): CoverageTableDataEntry => ({
  title: t(
    'page.household.quote.coveragedetails.section.force.title',
    'Additional coverages'
  ),
  iconSrc: helmetIcon,
  rows: [
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.force.1.label',
          'Supersonic pressure waves (sonic boom)'
        ),
        false,
        true,
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.force.2.label',
          'Civil unrest, strike, lockout'
        ),
        false,
        true,
      ],
    },
  ],
});

const getWaterTableData = (t: TFunction): CoverageTableDataEntry => ({
  title: t(
    'page.household.quote.coveragedetails.section.water.title',
    'Utility water and plumbing'
  ),
  iconSrc: waterIcon,
  rows: [
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.water.1.label',
          'Utility water damage'
        ),
        true,
        true,
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.water.2.label',
          'Damage caused by water leaking from waterbeds or aquariums'
        ),
        true,
        true,
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.water.3.label',
          'Damage from rainwater downpipes that run inside the building, and have leaked'
        ),
        false,
        true,
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.water.4.label',
          'Repair costs for utility water damage to floor coverings, wallpaper or interior painting in apartments'
        ),
        true,
        true,
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.water.5.label',
          'Utility water and gas leakage'
        ),
        false,
        t('page.household.quote.coveragedetails.section.weather.2.extended', {
          defaultValue: '{{coverage}} with a co-pay of {{copay}}',
          coverage: englishFormattedEuroCurrency(5000, true),
          copay: englishFormattedEuroCurrency(100, true),
        }),
      ],
    },
  ],
});

const getItemsOutdoorsTableData = (t: TFunction): CoverageTableDataEntry => ({
  title: t(
    'page.household.quote.coveragedetails.section.itemsoutdoors.title',
    'Secured items outdoors'
  ),
  iconSrc: outdoorIcon,
  rows: [
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.itemsoutdoors.1.label',
          'Your secured belongings while in a hotel or another temporary location are insured up to'
        ),
        t(
          'page.household.quote.coveragedetails.section.itemsoutdoors.1.basic.coveredsum',
          '25% of insured sum'
        ),
        t(
          'page.household.quote.coveragedetails.section.itemsoutdoors.1.extended.coveredsum',
          '40% of insured sum'
        ),
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.itemsoutdoors.1.sublabel',
          'For a period of'
        ),
        t(
          'page.household.quote.coveragedetails.section.itemsoutdoors.1.basic.period',
          '3 months'
        ),
        <>
          <p className="mb24">
            {t(
              'page.household.quote.coveragedetails.section.itemsoutdoors.1.extended.period',
              '12 months'
            )}
          </p>
          <p className="p-p--small">
            {t(
              'page.household.quote.coveragedetails.section.itemsoutdoors.1.extended.extrainfo',
              {
                defaultValue:
                  'For sports equipment (such as diving and golfing equipment) which is usually kept outside the home on a permanent basis, this time limit does not apply, but instead is limited to {{limit}}.',
                limit: englishFormattedEuroCurrency(5000, true),
              }
            )}
          </p>
        </>,
      ],
    },
  ],
});

const getTransportTableData = (t: TFunction): CoverageTableDataEntry => ({
  title: t(
    'page.household.quote.coveragedetails.section.transport.title',
    'Transport'
  ),
  iconSrc: transportIcon,
  rows: [
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.transport.1.label',
          'Damage to insured property caused by an accident involving a motor vehicle with which the items were transported'
        ),
        false,
        true,
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.transport.2.label',
          'Damage to belongings caused by a road or rail vehicle'
        ),
        false,
        true,
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.transport.3.label',
          'Transport and storage costs if you cannot store your belongings in your apartment'
        ),
        t(
          'page.household.quote.coveragedetails.section.transport.3.basic',
          '180 days'
        ),
        t(
          'page.household.quote.coveragedetails.section.transport.3.extended',
          '1 year'
        ),
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.transport.4.label',
          'Moving expenses'
        ),
        false,
        t('page.household.quote.coveragedetails.section.transport.4.extended', {
          defaultValue: '{{limit}} per insured event',
          limit: englishFormattedEuroCurrency(5000, true),
        }),
      ],
    },
  ],
});

const getOtherDamagesTableData = (t: TFunction): CoverageTableDataEntry => ({
  title: t(
    'page.household.quote.coveragedetails.section.other.title',
    'Other damages'
  ),
  iconSrc: otherIcon,
  rows: [
    {
      cells: [
        <>
          {t(
            'page.household.quote.coveragedetails.section.other.1.label',
            'Damage to frozen goods'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'page.household.quote.coveragedetails.section.other.1.sublabel',
              'Only food and medication are covered after the freezer loses power due to lightning blowing a fuse or a loss of power for the entire apartment.'
            )}
          </p>
        </>,
        false,
        true,
      ],
    },
    {
      cells: [
        t(
          'page.household.quote.coveragedetails.section.other.2.label',
          'Security costs'
        ),
        t(
          'page.household.quote.coveragedetails.section.other.2.basic',
          '72 hours'
        ),
        t(
          'page.household.quote.coveragedetails.section.other.2.extended',
          '144 hours'
        ),
      ],
    },
    {
      cells: [
        <>
          {t(
            'page.household.quote.coveragedetails.section.other.3.label',
            'Changing your lock and keys'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'page.household.quote.coveragedetails.section.other.3.sublabel',
              'Only after an insured event.'
            )}
          </p>
        </>,
        true,
        true,
      ],
    },
    {
      cells: [
        <>
          {t(
            'page.household.quote.coveragedetails.section.other.5.label',
            'Upgrade for water/energy-saving appliances of the highest efficiency class after an insured event'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'page.household.quote.coveragedetails.section.other.5.sublabel',
              '(Refrigerators/freezers, dishwashers, washing machines and dryers)'
            )}
          </p>
        </>,
        false,
        t('page.household.quote.coveragedetails.section.other.5.extended', {
          defaultValue: '{{coverage}} per appliance ',
          coverage: englishFormattedEuroCurrency(500, true),
        }),
      ],
    },
    {
      cells: [
        <>
          {t(
            'page.household.quote.coveragedetails.section.other.6.label',
            'Data recovery costs'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'page.household.quote.coveragedetails.section.other.6.sublabel',
              '(Some exclusions apply please refer to the policy document)'
            )}
          </p>
        </>,
        false,
        true,
      ],
    },
    {
      cells: [
        <>
          {t(
            'page.household.quote.coveragedetails.section.other.7.label',
            'Costs incurred from an unauthorized use of the telephone'
          )}
          <p className="p-p--small tc-grey-500">
            {t(
              'page.household.quote.coveragedetails.section.other.7.sublabel',
              '(Landline and mobile)'
            )}
          </p>
        </>,
        false,
        true,
      ],
    },
  ],
});

export const getCoverageTableData = (
  t: TFunction
): CoverageTableDataEntry[] => [
  getValuablesTableData(t),
  getFireDamageTableData(t),
  getTheftTableData(t),
  getWeatherTableData(t),
  getAdditionalCoverageTableDataEntry(t),
  getWaterTableData(t),
  getItemsOutdoorsTableData(t),
  getTransportTableData(t),
  getOtherDamagesTableData(t),
];
