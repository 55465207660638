import { Button } from '@popsure/dirty-swan';

import styles from './ErrorWithAction.module.scss';
import feathersSvg from './icons/feathers.svg';

interface ErrorWithActionProps {
  title: string;
  description: string;
  cta?: {
    title: string;
    onClick: () => void;
  };
}

export const ErrorWithAction = ({
  title,
  description,
  cta,
}: ErrorWithActionProps) => (
  <div
    className={`p-body d-flex ai-center jc-center ${styles.container}`}
    data-testid="recommendation-error-page"
  >
    <div className="d-flex fd-column ai-center jc-center wmx6">
      <img className="mb32" src={feathersSvg} alt="feathers" />
      <h2 className="p-h2 tc-grey-900 mb8">{title}</h2>
      <p className="p-p tc-grey-600 mb40 ta-center">{description}</p>
      {cta && (
        <Button className="wmn3" onClick={cta.onClick}>
          {cta.title}
        </Button>
      )}
    </div>
  </div>
);
