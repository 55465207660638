import { Household, HouseholdQuestionnaire } from 'features/household/models';

import { ReviewCheckout } from './ReviewCheckout';

export const checkoutQuestionnaire = (): HouseholdQuestionnaire => ({
  reviewCheckout: {
    required: true,
    type: 'CUSTOM',
    groupId: 'signup',
    component: ReviewCheckout,
    retrieveAnswerObject: (
      reviewCheckout: Household['reviewCheckout']
    ): Partial<Household> => ({
      reviewCheckout,
    }),
  },
});
