import { TallyModal } from 'components/TallyModal';
import dayjs from 'dayjs';
import { PolicySingleModalProps } from 'features/policySingle/components/modals';
import { useSelector } from 'react-redux';
import { getAccountInfo } from 'selectors/user';

export type PrivatePensionType = 'PRIVATE_PENSION_MANAGE_POLICY';

export const PrivatePensionManagePolicyModal = (
  props: PolicySingleModalProps
) => {
  const {
    onClose,
    policyData: {
      attributes: { insuredPerson, policyNumber },
      id: policyId,
    },
  } = props;
  const account = useSelector(getAccountInfo);

  const URLparams = {
    policy_number: policyNumber,
    policy_id: policyId,
    customer_email: account?.email,
    first_name: insuredPerson?.name?.firstName,
    last_name: insuredPerson?.name?.lastName,
    date_of_birth: account?.dateOfBirth
      ? dayjs(account?.dateOfBirth).format('DD.MM.YYYY')
      : '',
  };

  return (
    <TallyModal
      isVisible
      onClose={onClose}
      tallyId="nW8R6R"
      title="Private Pension Insurance Management"
      URLparams={URLparams}
    />
  );
};
