import { AddOns } from '@getpopsure/legal-insurance-pricing-engine-v2';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Toggle } from '@popsure/dirty-swan';
import classnames from 'classnames';
import { ReactNode } from 'react';

import CoverageDetails from '../CoverageDetails/CoverageDetails';
import styles from './style.module.scss';

type ToggleCardProps = {
  description: string;
  title: string;
  disabled?: boolean;
  isSelected: boolean;
  onChange: (value: boolean) => void;
  toggleKey: AddOns;
  price: number;
  className?: string;
};

export const ToggleCard = ({
  description,
  disabled = false,
  isSelected,
  onChange,
  toggleKey,
  price,
  title,
  className,
}: ToggleCardProps) => {
  return (
    <div
      className={`p32 bg-white br8 d-flex jc-between gap16 ${className} ${
        styles.content
      } ${isSelected ? styles.selected : ''}`}
    >
      <div>
        <h4 className={classnames('p-h4 d-flex f-wrap', styles.heading)}>
          {title}
        </h4>
        <p className={classnames('p-p mt8 mb16', styles.description)}>
          {description}
        </p>
        <CoverageDetails addOnName={toggleKey} />
      </div>
      <div className={classnames('d-flex', styles.toggleContainer)}>
        <Toggle
          bordered={false}
          classNames={{ label: styles.toggleLabel }}
          disabled={disabled}
          onChange={(value: string[]) => onChange(value.includes(toggleKey))}
          options={{ [toggleKey]: '' } as Record<AddOns, ReactNode>}
          value={isSelected ? [toggleKey] : []}
        />
        <h3 className={classnames('p-h3', styles.transition)}>
          + {englishFormattedEuroCurrency(price)}
        </h3>
      </div>
    </div>
  );
};
