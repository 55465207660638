import { useQueryParamValue } from 'hooks/useQueryParamValue';
import {
  isValidProvider,
  Provider,
  providers,
} from 'models/insurances/providers';
import { InsuranceTypes, isValidInsuranceType } from 'models/insurances/types';

import { isValidPlanId, PlanId, planIds } from '../paymentScreen.models';

export const useStartDateFromQuery = (queryParam: string): string => {
  const startDate = useQueryParamValue(queryParam);

  if (!startDate) {
    throw Error('[Generic checkout] The startDate query param is missing');
  }

  return startDate;
};

export const useInsuranceTypeFromQuery = (
  queryParam: string
): InsuranceTypes => {
  const type = useQueryParamValue(queryParam);

  if (!type || !isValidInsuranceType(type)) {
    throw Error(
      `[Generic checkout] The ${queryParam} query param contains a wrong value`
    );
  }

  return type;
};

export const useMonthlyPriceFromQuery = (queryParam: string): number => {
  const monthlyPrice = parseFloat(useQueryParamValue(queryParam) || '');

  if (monthlyPrice < 0 || monthlyPrice > 1000) {
    throw Error(
      `[Generic checkout] The ${queryParam} query param is out of range or invalid`
    );
  }

  return monthlyPrice;
};

export const usePlanIdFromQuery = (queryParam: string): PlanId | undefined => {
  const planId = useQueryParamValue(queryParam);

  if (!planId) {
    return;
  }

  if (!isValidPlanId(planId)) {
    throw Error(
      `[Generic checkout] The ${queryParam} is not one of ${planIds}`
    );
  }

  return planId;
};

export const useFormIdFromQuery = (queryParam: string): string => {
  const formId = useQueryParamValue(queryParam);

  if (!formId) {
    throw Error(`[Generic checkout] The ${queryParam} query param is missing`);
  }

  return formId;
};

export const useProviderIdFromQuery = (
  queryParam: string
): Provider | undefined => {
  const providerId = useQueryParamValue(queryParam);

  if (!providerId) {
    return;
  }

  if (!isValidProvider(providerId)) {
    throw Error(
      `[Generic checkout] The ${queryParam} is not one of ${providers}`
    );
  }

  return providerId;
};
