import * as Sentry from '@sentry/react';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { UserAction } from 'constants/actions';
import {
  generateReferralCode,
  getReferralUserData,
  isEligibleUser,
  patchReferralUserData,
  postReferralUserData,
} from 'features/referralEngine/api';
import {
  REFERRAL_ENGINE_REQUEST_TYPE,
  REFERRAL_USER_DATA_REQUEST_TYPE,
  REFERRAL_USER_DATA_UPDATE_TYPE,
} from 'features/referralEngine/constants';
import {
  ReferralEngineAction,
  ReferralUserData,
  UserReferralInfo,
} from 'features/referralEngine/models';
import { getApiErrors } from 'features/referralEngine/utils';
import { APIResponseError } from 'models/error';
import endpoint, { Client } from 'shared/api';
import { ActionResponse } from 'shared/models/types';

Client.prototype.generateReferralCode = generateReferralCode;
Client.prototype.isEligibleUser = isEligibleUser;
Client.prototype.getReferralUserData = getReferralUserData;
Client.prototype.postReferralUserData = postReferralUserData;
Client.prototype.patchReferralUserData = patchReferralUserData;

export const mergeUserReferralInfo = (
  userReferralInfo: UserReferralInfo
): UserAction => ({
  type: 'MERGE_USER_REFERRAL_CODE_INFO',
  userReferralInfo,
});

export const clearReferrerCode = (): UserAction => ({
  type: 'MERGE_USER_REFERRAL_CODE_INFO',
  userReferralInfo: {
    validReferrerCode: undefined,
  },
});

export const flushReferrerCodeError = (): UserAction => ({
  type: 'MERGE_USER_REFERRAL_CODE_INFO',
  userReferralInfo: {
    referrerCodeError: undefined,
  },
});

export const getReferralCode: ReferralEngineAction = async (dispatch) => {
  dispatch(setRequestInProcess(true, REFERRAL_ENGINE_REQUEST_TYPE));
  try {
    const response = await endpoint.generateReferralCode();
    if ('data' in response) {
      const { referralCode } = response.data;
      dispatch(mergeUserReferralInfo({ userReferralCode: referralCode }));
    }

    dispatch(setRequestInProcess(false, REFERRAL_ENGINE_REQUEST_TYPE));
  } catch (err) {
    dispatch(
      setRequestErrored(err as APIResponseError, REFERRAL_ENGINE_REQUEST_TYPE)
    );
  }
};

export const getIsValidReferrerCode =
  (referralCode: string): ReferralEngineAction =>
  async (dispatch) => {
    dispatch(setRequestInProcess(true, REFERRAL_ENGINE_REQUEST_TYPE));

    try {
      const { data } = await endpoint.isEligibleUser({ referralCode });

      if (data.isEligibleUser) {
        dispatch(flushReferrerCodeError());
        dispatch(mergeUserReferralInfo({ validReferrerCode: referralCode }));
      } else {
        dispatch(clearReferrerCode());
        dispatch(
          mergeUserReferralInfo({
            referrerCodeError: data.reason,
          })
        );
      }

      dispatch(setRequestInProcess(false, REFERRAL_ENGINE_REQUEST_TYPE));
    } catch (err) {
      dispatch(
        setRequestErrored(err as APIResponseError, REFERRAL_ENGINE_REQUEST_TYPE)
      );
    }
  };

export const fetchReferralUserData: ReferralEngineAction = async (dispatch) => {
  try {
    dispatch(setRequestInProcess(true, REFERRAL_USER_DATA_REQUEST_TYPE));
    const response = await endpoint.getReferralUserData();
    if ('data' in response) {
      const { id, ...referralUserData } = response.data;
      dispatch(mergeUserReferralInfo({ referralUserData }));
    }
    dispatch(setRequestInProcess(false, REFERRAL_USER_DATA_REQUEST_TYPE));
  } catch (err) {
    const error = err as APIResponseError;

    /* Do not display 404 errors */
    if (error.response?.status === 404) {
      dispatch(setRequestInProcess(false, REFERRAL_USER_DATA_REQUEST_TYPE));
      return;
    }

    dispatch(setRequestErrored(error, REFERRAL_USER_DATA_REQUEST_TYPE));
  }
};

export const updateReferralUserData =
  (
    referralUserData: ReferralUserData
  ): ReferralEngineAction<Promise<ActionResponse>> =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(setRequestInProcess(true, REFERRAL_USER_DATA_UPDATE_TYPE));

      /* If referralUserData exists use PATCH instead of POST to update the data */
      const response =
        state && state.user?.userReferralInfo?.referralUserData?.accountHolder
          ? await endpoint.patchReferralUserData(referralUserData)
          : await endpoint.postReferralUserData(referralUserData);

      if ('data' in response) {
        const { id, ...updatedUserData } = response.data;
        dispatch(mergeUserReferralInfo({ referralUserData: updatedUserData }));
        dispatch(setRequestInProcess(false, REFERRAL_USER_DATA_UPDATE_TYPE));
        return 'SUCCESS';
      }

      dispatch(setRequestInProcess(false, REFERRAL_USER_DATA_UPDATE_TYPE));
      return 'ERROR';
    } catch (err) {
      const error = err as APIResponseError;
      const apiErrors = getApiErrors(error);
      Sentry.captureException(
        `[REFERRAL_ENGINE]: failed to update referral user data - ${apiErrors}`
      );
      dispatch(setRequestErrored(error, REFERRAL_USER_DATA_UPDATE_TYPE));
      return 'ERROR';
    }
  };
