/* global Tally */

import { XIcon } from '@popsure/dirty-swan';
import { useWindowSize } from 'hooks/useWindowSize';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import styles from './TallyModal.module.scss';
import { createTallyURLParamsString, LookupObject } from './util';

export type TallyModalProps = {
  tallyId?: string;
  URLparams?: LookupObject;
  isVisible: boolean;
  onClose?: () => void;
  title?: string;
};

declare global {
  interface Window {
    onTallyLoad?: (() => void) | null;
  }
  const Tally: {
    loadEmbeds: () => void;
  };
}

/**
 * To dynamically load and display a Tally form inside a modal, we need to invoke Tally.loadEmbeds().
 * For this to work however, the embed.js script must have completed loading and we need to have a tallyId to actually have a complete URL.
 *
 * This is why we,
 * 1) need to know when the script has finished loading
 * 2) need to defer running Tally.loadEmbeds() until we both have a tallyId and have finished loading the script
 *
 * React Helmet's script tag allows to run a function on the "onload" event, which means we use this to set the state variable
 * "scriptFinishedLoading" to force rerendering. The problem is however, that it cannot directly run functions like "onScriptLoad", but can only
 * run inline scripts as a string. It also cannot access the component functions, which is why we have to save a reference to our
 * "onScriptLoad" function to the global window object's onTallyLoad() in order for the script to run it.
 */

export const TallyModal = ({
  tallyId,
  isVisible,
  onClose,
  title,
  URLparams = {},
}: TallyModalProps) => {
  const [scriptFinishedLoading, setScriptFinishedLoading] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const size = useWindowSize();

  const handleOnClose = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      onClose?.();
      setIsClosing(false);
    }, 300);
  }, [setIsClosing, onClose]);

  const onScriptLoad = () => {
    setScriptFinishedLoading(true);
  };

  window.onTallyLoad = onScriptLoad;

  useEffect(() => {
    if (scriptFinishedLoading && tallyId) {
      Tally.loadEmbeds();
    }

    // In the cleanup function we assign a function to window.onTallyLoad (instead of null) to fix the TypeError "window.onTallyLoad is not a function"
    return () => {
      window.onTallyLoad = () => {
        // eslint-disable-next-line no-console
        console.warn('window.onTallyLoad has been removed');
      };
    };
  }, [tallyId, scriptFinishedLoading]);

  const isOpen = isVisible && !isClosing;

  const urlParamsStr = createTallyURLParamsString(URLparams);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://tally.so/widgets/embed.js"
          // @ts-expect-error - Helmet will transform the onload function to inline script
          onLoad="window.onTallyLoad()"
        />
      </Helmet>
      <div
        className={`${styles.overlay} ${
          isOpen ? styles.fadeIn : styles.fadeOut
        }`}
      >
        {tallyId && (
          <div
            className={`${styles.modalBackground} ${
              isOpen ? styles.moveIn : styles.moveOut
            }`}
          >
            <button
              className={styles.closeButton}
              onClick={handleOnClose}
              type="button"
            >
              <XIcon size={24} noMargin />
            </button>
            <iframe
              className={`${styles.tallyEmbed} ${
                isOpen ? styles.moveIn : styles.moveOut
              }`}
              data-tally-src={`https://tally.so/r/${tallyId}?transparentBackground=1${urlParamsStr}`}
              width={size.width ?? '100%'}
              height={size.height ?? '100%'}
              title={title}
              data-cy="tally-iframe"
            />
          </div>
        )}
      </div>
    </>
  );
};
