import { legal } from '@getpopsure/private-constants';
import { MultiDropzone } from '@popsure/dirty-swan';
import { Trans } from 'shared/i18n';
import { UploadedFile } from 'shared/models/types';

interface Props {
  onFileSelect: (files: File[]) => void;
  onRemoveFile: (id: string) => void;
  uploadedFiles: UploadedFile[];
  uploadLoading: boolean;
  showLegalNotice?: boolean;
  maxFiles?: number;
}

export const UploadDocumentsView = ({
  onFileSelect,
  onRemoveFile,
  uploadedFiles,
  uploadLoading,
  showLegalNotice,
  maxFiles,
}: Props) => {
  return (
    <>
      <div className="wmx6">
        <MultiDropzone
          onFileSelect={onFileSelect}
          uploadedFiles={uploadedFiles}
          uploading={uploadLoading}
          onRemoveFile={onRemoveFile}
          maxFiles={maxFiles}
        />
      </div>
      {showLegalNotice && (
        <Trans i18nKey="generic.qnr.uploadDocuments.agreement">
          <p className="mt24 p-p">
            By uploading any documents you agree to our{' '}
            <a
              className="p-a"
              href={legal.declarationDataProtection}
              target="_blank"
              rel="noopener noreferrer"
            >
              data processing agreement
            </a>
            .
          </p>
        </Trans>
      )}
    </>
  );
};
