import { Region } from '@getpopsure/public-models';
import { Provider } from 'models/insurances/providers';
import { InsuranceTypes } from 'models/insurances/types';

export const planIds = ['BASIC', 'PREMIUM', 'SURGERY', 'STANDARD'] as const;

export type PlanId = typeof planIds[number];

export const isValidPlanId = (value: unknown): value is PlanId => {
  return typeof value === 'string' && planIds.includes(value as PlanId);
};

export interface PolicyDetails {
  type: InsuranceTypes;
  planId?: PlanId;
  providerId?: Provider;
  regionOfPurchase?: Region;
}

export interface CheckoutInfoRequest {
  startDate: string;
  monthlyPriceInEuros: number;
  insuranceType: InsuranceTypes;
  formId?: string;
  policyDetails: PolicyDetails;
  // TODO: update policyInfo type once we add properties to the corresponding object (now empty)
  policyInfo:
    | { questionnaireId: string; quoteId: string }
    | Record<string, unknown>;
  policyInfoKind?: string;
}

export interface CheckoutInfo {
  id: string;
  totalMonthlyPrice: number;
  totalProratedPrice: number;
  mainPolicy: {
    id: string;
    startDate: string;
    proratedPrice: number;
    proratedPeriod: {
      start: string;
      end: string;
    };
    monthlyPrice: number;
    policyDetails: PolicyDetails;
    policyHolder: {
      userId: string;
    };
  };
  metaData?: {
    formId?: string;
    questionnaireId?: string;
    quoteId?: string;
    regionOfPurchase?: string;
  };
  region?: string;
  providerId?: string;
}

export interface Checkout {
  id: string;
  policies: {
    id: string;
    policyDetails: PolicyDetails;
    monthlyPriceInCents: number;
  }[];
  policyInfo: {
    formId: string;
  };
}

export interface PriceInfo {
  totalMonthlyPrice: number;
  totalProratedPrice: number;
  proratedPeriod: {
    start: string;
    end: string;
  };
}

export type CheckoutResult = {
  customerActionRequired: boolean;
  confirmationRequired: boolean;
  isDuplicatePaymentAttempt: boolean;
  clientSecret: string;
};
