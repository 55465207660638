import { insurance } from '@getpopsure/private-constants';
import { Region } from '@getpopsure/public-models';
import routes from 'constants/routes';
import { InsuranceTypes } from 'models/insurances/types';

const { policies } = routes;

const regionalLandingPageLinks: Partial<Record<InsuranceTypes, string>> = {
  HOUSEHOLD: insurance.household.regionalLandingPage,
  INCOMING: policies.expatEu.path,
  INCOMING_EU: policies.expatEu.path,
  INCOMING_ES: policies.expatSpain.path,
  INCOMING_LT: policies.expatLongTerm.path,
  LIFE: insurance.life.regionalLandingPage,
  TRAVEL: insurance.travelHealth.regionalLandingPage,
};

const deUrlByVertical: Partial<Record<InsuranceTypes, string>> = {
  PUBLIC_HEALTH: `${insurance.publicHealth.landingPage}#public-calculator`,
  PRIVATE_HEALTH: policies.privateHealthV2.path,
  INCOMING: policies.expat.path,
  TRAVEL: policies.travelHealth.path,
  DENTAL: policies.dental.path,
  LIABILITY: policies.liability.quote.path,
  HOUSEHOLD: policies.household.path,
  BIKE: policies.bike.path,
  DOG_LIABILITY: policies.dogLiability.path,
  LIFE: policies.life.path,
  DISABILITY: policies.disability.path,
  LEGAL: policies.legal.path,
  PENSION: insurance.pension.landingPage,
  PET_HEALTH: policies.petHealth.path,
};

const getRegionalLink = (type: InsuranceTypes, region: Region | null) => {
  return (regionalLandingPageLinks?.[type] ?? '')
    .replace(':region', `en-${region}`)
    .replace(':localeId', region || '');
};

export const getUrl = (
  type: InsuranceTypes,
  region: Region | null = 'de'
): string | undefined => {
  const isNotDe = region !== 'de';

  const landingPageURL = isNotDe
    ? getRegionalLink(type, region)
    : deUrlByVertical?.[type];

  return landingPageURL;
};
