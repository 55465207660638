import { Button, UploadedFile } from '@popsure/dirty-swan';
import { InlineButton } from 'features/studentChecklist/components';
import { createPortal } from 'react-dom';
import { useSafeTranslation } from 'shared/i18n';

import { UploadLetterModal } from './components';

interface LetterOfAcceptanceViewProps {
  completed?: boolean;
  error?: Error | null;
  isUploadModalOpen: boolean;
  validFile: boolean;
  letterOfAcceptanceUrl?: string | null;
  loading: boolean;
  uploadedFiles: UploadedFile[];

  handleCloseUploadModal(): void;

  handleDownloadLetter(): void;

  handleOpenUploadModal(): void;

  handleRemoveFile(): void;

  handleSelectFile(selectedFiles: File[]): void;

  handleSubmit(): void;
}

export const LetterOfAcceptanceView = (props: LetterOfAcceptanceViewProps) => {
  const { t } = useSafeTranslation();
  const {
    completed,
    error,
    isUploadModalOpen,
    validFile,
    letterOfAcceptanceUrl,
    loading,
    uploadedFiles,
    handleCloseUploadModal,
    handleDownloadLetter,
    handleOpenUploadModal,
    handleRemoveFile,
    handleSelectFile,
    handleSubmit,
  } = props;

  if (completed && letterOfAcceptanceUrl) {
    return (
      <InlineButton onClick={handleDownloadLetter}>
        {t(
          'studentChecklist.letterOfAcceptance.downloadButton.title',
          'Download letter'
        )}
      </InlineButton>
    );
  }

  return (
    <>
      <div className="mt8">
        <Button onClick={handleOpenUploadModal}>
          {t(
            'studentChecklist.letterOfAcceptance.uploadButton.title',
            'Upload letter'
          )}
        </Button>
      </div>
      {isUploadModalOpen &&
        createPortal(
          <UploadLetterModal
            error={error?.message}
            loading={loading}
            uploadedFiles={uploadedFiles}
            validFile={validFile}
            onRemoveFile={handleRemoveFile}
            onSelectFile={handleSelectFile}
            onSubmit={handleSubmit}
            onClose={handleCloseUploadModal}
          />,
          document.body
        )}
    </>
  );
};
