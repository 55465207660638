import { Link } from 'react-router-dom';

import styles from './LinkBadge.module.scss';

export const LinkBadge = ({
  children,
  className = '',
  iconAlt,
  iconSrc,
  to,
}: {
  children: string;
  className?: string;
  iconAlt: string;
  iconSrc: string;
  to: string;
}) => {
  return (
    <Link
      className={`d-inline-flex c-gap8 p-p--small ${styles.badge} ${className}`}
      to={to}
    >
      <img src={iconSrc} alt={iconAlt} />
      {children}
    </Link>
  );
};
