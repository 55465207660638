import Session from '@getpopsure/session';
import { useQuery } from '@tanstack/react-query';
import LoadingSpinner from 'components/loadingSpinner';
import dayjs from 'dayjs';
import Cell from 'features/checkout/components/Cell';
import { UserWithBrokerMandate } from 'models/user';
import endpoint from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';

import styles from './style.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

const getPolicyHolder = async () => {
  // const { isAuthenticated } = Session;
  // if (!isAuthenticated) return;

  const { data } = await endpoint.getAccountInfo();
  return data;
};

const PolicyHolder = () => {
  const { isAuthenticated } = Session;
  const { t } = useSafeTranslation();

  const { isFetching, data } = useQuery<UserWithBrokerMandate | undefined>({
    queryKey: ['genericCheckoutAccountInfo'],
    queryFn: () => getPolicyHolder(),
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (
    !data?.firstName &&
    !data?.lastName &&
    !data?.email &&
    !data?.dateOfBirth
  ) {
    return null;
  }

  const { firstName, lastName, dateOfBirth, email } = data;

  return (
    <div className={`mb16 ${styles.container}`}>
      <div className="p-h2 mb16">
        {t('components.policyholder.title', 'Policy holder')}
      </div>
      {firstName && lastName && (
        <Cell
          title={t('components.policyholder.name', 'Name')}
          value={capitalizeName({ firstName, lastName })}
        />
      )}
      {dateOfBirth && (
        <Cell
          title={t('components.policyholder.dateofbirth', 'Date of birth')}
          value={dayjs(dateOfBirth).format(DATE_FORMAT)}
        />
      )}
      {email && (
        <Cell
          title={t('components.policyholder.email', 'Email')}
          value={email}
        />
      )}
    </div>
  );
};

export default PolicyHolder;
