import Checkout from 'features/checkout';
import {
  CheckoutPolicyRequestPayload,
  PolicyMembers,
} from 'features/checkout/models';
import {
  ExpatDependentQuestionnaire,
  ExpatQuestionnaire,
} from 'features/expat/models';
import { CustomComponentProps } from 'models/questionnaireFramework';

import { PolicyDetails } from '../PolicyDetails';

const isDependentQuestionnaire = (
  qnr: Partial<ExpatQuestionnaire | ExpatDependentQuestionnaire>
): qnr is Partial<ExpatDependentQuestionnaire> => {
  return 'mainPolicyId' in qnr;
};

const getMainPolicyId = (
  questionnaireAnswers: Partial<
    ExpatQuestionnaire | ExpatDependentQuestionnaire
  >
) => {
  if (isDependentQuestionnaire(questionnaireAnswers)) {
    return questionnaireAnswers.mainPolicyId;
  }
  return undefined;
};

export const ReviewCheckout = ({
  questionnaireAnswers,
  policyMemeberStatus = 'OWNER_ONLY',
  routes,
}: CustomComponentProps<ExpatQuestionnaire | ExpatDependentQuestionnaire> & {
  policyMemeberStatus?: PolicyMembers;
}) => {
  const checkoutPolicyRequestPayload: CheckoutPolicyRequestPayload = {
    type: 'EXPAT_V2',
    policyInfo: {
      quoteId: questionnaireAnswers.quote?.selectedQuote?.id,
      questionnaireId: questionnaireAnswers.questionnaireId,
      mainPolicyId: isDependentQuestionnaire(questionnaireAnswers)
        ? questionnaireAnswers.mainPolicyId
        : undefined,
      startDate: questionnaireAnswers.startDate,
    },
  };

  const mainPolicyId = getMainPolicyId(questionnaireAnswers);

  return (
    <Checkout
      checkoutPolicyRequestPayload={checkoutPolicyRequestPayload}
      policyDetailsComponent={
        <PolicyDetails policyMembersStatus={policyMemeberStatus} />
      }
      mainPolicyId={mainPolicyId}
      changeStartDatePath={routes.startDate.path}
    />
  );
};
