import { TFunction } from '@getpopsure/i18n-react';
import { isDateFuture } from '@getpopsure/public-utility';
import dayjs from 'dayjs';
import { HouseholdClaimsSubmitted as ClaimSubmitted } from 'features/householdClaims/components/ClaimSubmitted';
import { HouseholdClaimsGettingStarted as GettingStarted } from 'features/householdClaims/components/GettingStarted';
import { HouseholdClaimsPayoutDetails as PayoutDetails } from 'features/householdClaims/components/PayoutDetails';
import { HouseholdClaimsProcessing as Processing } from 'features/householdClaims/components/Processing';
import {
  claimTypeMapping,
  HouseholdClaims,
  QuestionId,
  Questionnaire,
} from 'features/householdClaims/models';
import omit from 'lodash/omit';
import { Policy } from 'models/policies';

import { HouseholdClaimsUploadDocuments as UploadDocuments } from '../components/UploadDocuments';

export const getDateOfEventRange = (
  policyStartDate: string
): { min: number; max: number } => ({
  min: dayjs(policyStartDate).isValid()
    ? dayjs(policyStartDate).year()
    : dayjs().year(),
  max: dayjs().year(),
});

export const validateDateOfEvent = (
  t: TFunction,
  answer: string,
  policyStartDate: string
): string | null => {
  if (isDateFuture(answer)) {
    return t(
      'claims.household.questionnaire.eventDate.error.1',
      "You can't select a date in the future. Please make sure the date is correct."
    );
  }

  if (isDateFuture(policyStartDate, answer)) {
    return t('claims.household.questionnaire.eventDate.error.2', {
      defaultValue:
        "Your policy started on {{ policyStartDate }}. You can't choose a date before that.",
      policyStartDate: dayjs(policyStartDate).isValid()
        ? dayjs(policyStartDate).format('DD MMM YYYY')
        : '',
    });
  }

  return null;
};

export const translatedQuestionnaire = (
  t: TFunction,
  policy: Policy
): Questionnaire => {
  const addOns =
    policy.attributes.householdAttributes?.personalInfo?.addOns ?? [];
  return {
    intro: {
      required: true,
      type: 'CUSTOM',
      component: GettingStarted,
      metaData: {
        policyId: policy.id,
      },
      retrieveAnswerObject: (
        answer: HouseholdClaims['intro']
      ): Partial<HouseholdClaims> => ({
        intro: answer,
      }),
      retrieveNextPageId: (): QuestionId => 'claimType',
      groupId: 'questionnaire',
      questionId: 'intro',
      requiresAuth: true,
    },
    dateOfEvent: {
      required: true,
      type: 'DATE',
      props: {
        question: t(
          'claims.household.questionnaire.eventDate.question',
          'When did the event happen?'
        ),
        yearRange: getDateOfEventRange(policy.attributes.startDate ?? ''),
      },
      retrieveAnswerObject: (
        answer: HouseholdClaims['dateOfEvent']
      ): Partial<HouseholdClaims> => ({
        dateOfEvent: answer,
      }),
      retrieveNextPageId: (): QuestionId => 'claimType',
      groupId: 'questionnaire',
      questionId: 'dateOfEvent',
      validateAnswer: (answer: HouseholdClaims['dateOfEvent']) =>
        validateDateOfEvent(t, answer, policy.attributes.startDate ?? ''),
      requiresAuth: true,
    },
    claimType: {
      required: true,
      type: 'RADIO',
      props: {
        question: t(
          'claims.household.questionnaire.claimType.question',
          'Select a claim type'
        ),
        mapValue: addOns.includes('BROKEN_GLASS_COVERAGE')
          ? claimTypeMapping(t)
          : omit(claimTypeMapping(t), 'GLASS_BREAKAGE'),
      },
      retrieveAnswerObject: (
        answer: HouseholdClaims['claimType']
      ): Partial<HouseholdClaims> => ({
        claimType: answer,
      }),
      retrieveNextPageId: (): QuestionId => 'details',
      groupId: 'questionnaire',
      questionId: 'claimType',
      requiresAuth: true,
    },

    details: {
      required: true,
      type: 'TEXT',
      props: {
        question: t(
          'claims.household.questionnaire.details.question',
          'Briefly describe what happened'
        ),
        placeholder: t(
          'claims.household.questionnaire.details.placeholder',
          'Explain what happened, who caused it, when and how.'
        ),
        additionalInfo: {
          title: t(
            'claims.household.questionnaire.details.additionalInfo.title',
            'Describing the event'
          ),
          description: t(
            'claims.household.questionnaire.details.additionalInfo.description',
            'Help us understand the nature of the damage, including when the event happened, how the damage happened and what caused the damage.\n\nE.g. your bike was stolen from the cellar/bike room/apartment courtyard, a candle tipped over and burned the bookcase.'
          ),
        },
      },
      retrieveAnswerObject: (
        answer: HouseholdClaims['details']
      ): Partial<HouseholdClaims> => ({
        details: answer,
      }),
      retrieveNextPageId: (): QuestionId => 'uploadedDocumentTokens',
      groupId: 'questionnaire',
      questionId: 'details',
      requiresAuth: true,
    },

    uploadedDocumentTokens: {
      required: true,
      type: 'CUSTOM',
      component: UploadDocuments,
      metaData: { policyId: policy.id },
      retrieveAnswerObject: (
        answer: HouseholdClaims['uploadedDocumentTokens']
      ): Partial<HouseholdClaims> => ({
        uploadedDocumentTokens: answer,
      }),
      retrieveNextPageId: () => 'payoutDetails',
      groupId: 'questionnaire',
      questionId: 'uploadedDocumentTokens',
      requiresAuth: true,
    },

    payoutDetails: {
      required: true,
      type: 'CUSTOM',
      component: PayoutDetails,
      metaData: { policyId: policy.id },
      retrieveAnswerObject: (
        answer: HouseholdClaims['payoutDetails']
      ): Partial<HouseholdClaims> => ({
        payoutDetails: answer,
      }),
      retrieveNextPageId: () => 'processing',
      groupId: 'questionnaire',
      questionId: 'payoutDetails',
      requiresAuth: true,
    },

    processing: {
      required: true,
      type: 'CUSTOM',
      component: Processing,
      retrieveAnswerObject: (
        answer: HouseholdClaims['processing']
      ): Partial<HouseholdClaims> => ({
        processing: answer,
      }),
      retrieveNextPageId: (): QuestionId => 'submitted',
      groupId: 'questionnaire',
      questionId: 'processing',
      requiresAuth: true,
    },

    submitted: {
      required: true,
      type: 'CUSTOM',
      component: ClaimSubmitted,
      groupId: 'questionnaire',
      questionId: 'submitted',
      requiresAuth: true,
    },
  };
};
