import { PartialQuestionnaire } from 'models/questionnaireFramework';

export interface QuestionOrder<Questionnaire> {
  id: keyof Questionnaire;
  required?: boolean | ((answers: Partial<Questionnaire>) => boolean);
}

export const createQuestionOrder = <Questionnaire extends Object, GroupId>(
  questions: PartialQuestionnaire<Questionnaire, GroupId>
): Array<QuestionOrder<Questionnaire>> => {
  let questionOrder: Array<QuestionOrder<Questionnaire>> = [];

  for (const answerId in questions) {
    const question = {
      id: answerId,
      ...(questions[answerId]!.required
        ? { required: questions[answerId]!.required }
        : {}),
    };
    questionOrder = [...questionOrder, question] as Array<
      QuestionOrder<Questionnaire>
    >;
  }

  return questionOrder;
};
