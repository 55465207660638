import { TFunction } from '@getpopsure/i18n-react';
import dayjs from 'dayjs';
import { UserRegistrationInfo } from 'models/genericQuestionnaire';
import { PartialQuestionnaire } from 'models/questionnaireFramework';

import { EndQuestionnaireRedirect } from '../components/EndQuestionnaireRedirect';
import { GenderQuestion } from '../components/GenderQuestion';
import { SignupEmail } from '../components/SignupEmail';

type Questionnaire = PartialQuestionnaire<UserRegistrationInfo, 'question'>;

export const getTranslatedQuestionnaire = ({
  loggedIn,
  t,
}: {
  loggedIn: boolean;
  t: TFunction;
}): Questionnaire => ({
  email: {
    required: true,
    type: 'CUSTOM',
    groupId: 'question',
    component: SignupEmail,
    retrieveAnswerObject: (email: UserRegistrationInfo['email']) => ({
      email,
    }),
    retrieveNextPageId: () => (loggedIn ? 'redirectPath' : 'name'),
  },
  name: {
    required: !loggedIn,
    type: 'NAME',
    groupId: 'question',
    props: { question: t('signup.qnr.name.question', 'What’s your name?') },
    retrieveAnswerObject: (name: UserRegistrationInfo['name']) => ({ name }),
    retrieveNextPageId: () => 'dateOfBirth',
  },
  dateOfBirth: {
    required: !loggedIn,
    type: 'DATE',
    groupId: 'question',
    props: {
      question: t('signup.qnr.dateofbirth.question', 'When were you born?'),
      yearRange: {
        min: dayjs().subtract(100, 'years').year(),
        max: dayjs().subtract(18, 'years').year(),
      },
    },
    retrieveAnswerObject: (
      dateOfBirth: UserRegistrationInfo['dateOfBirth']
    ) => ({ dateOfBirth }),
  },
  gender: {
    required: !loggedIn,
    type: 'CUSTOM',
    groupId: 'question',
    component: GenderQuestion,
    retrieveAnswerObject: (gender: UserRegistrationInfo['gender']) => ({
      gender,
    }),
    retrieveNextPageId: () => 'redirectPath',
  },
  redirectPath: {
    type: 'CUSTOM',
    groupId: 'question',
    component: EndQuestionnaireRedirect,
    retrieveAnswerObject: (
      redirectPath: UserRegistrationInfo['redirectPath']
    ) => ({
      redirectPath,
    }),
  },
});
