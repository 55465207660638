import { TFunction } from '@getpopsure/i18n-react';
import { DependentsFamilyAddIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import type { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { generatePath, useHistory } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

export type LifeModalType = 'LIFE_ADD_BENEFICIARIES';

const getBeneficiariesButtonContent = (
  hasBeneficiaries: boolean,
  t: TFunction
) => {
  if (hasBeneficiaries) {
    return {
      title: t(
        'myPolicies.policyDetails.editBeneficiaries.caption',
        'Edit beneficiaries'
      ),
      path: '/me/policies/life/:policyId/edit-beneficiaries',
      cyDataAttribute: 'button-edit-beneficiaries',
    };
  }

  return {
    title: t(
      'myPolicies.policyDetails.addBeneficiaries.caption',
      'Add beneficiaries'
    ),
    path: '/me/policies/life/:policyId/add-beneficiaries',
    cyDataAttribute: 'button-add-beneficiaries',
  };
};

export const LifeAddBeneficiariesButton = (props: PolicyDetailsButtonProps) => {
  const {
    policyData: { id, attributes },
  } = props;

  const { t } = useSafeTranslation();
  const history = useHistory();
  const hasBeneficiaries = Boolean(attributes?.beneficiaries?.length);

  const { title, path, cyDataAttribute } = getBeneficiariesButtonContent(
    hasBeneficiaries,
    t
  );

  const handleBeneficiariesButton = () => {
    history.push(
      generatePath(path, {
        policyId: id,
      })
    );
  };

  return (
    <ButtonCell
      title={title}
      icon={<DependentsFamilyAddIcon noMargin size={24} className="ml16" />}
      onClick={handleBeneficiariesButton}
      data-cy={cyDataAttribute}
    />
  );
};
