import {
  allLocales,
  getFixedT,
  language,
  Locale,
  Trans as TransComponent,
  useTranslation,
} from '@getpopsure/i18n-react';
import { ComponentProps, createContext, ReactNode, useContext } from 'react';
import { matchPath, RouteProps, useLocation } from 'react-router';
import { getRouteLocaleConfig } from 'routeLocales';
import { isStagingOrDev } from 'shared/util/isStagingOrDev';

const isTestingEnv = process.env.NODE_ENV === 'test';

// The default value is only returned when there is actually no provider
const SafeTranslationContext = createContext<Locale[]>([]);

export const SafeTranslationProvider = ({
  children,
}: {
  children: ReactNode;
} & RouteProps) => {
  const defaultLocales: Locale[] = ['en'];
  const location = useLocation();
  const config = getRouteLocaleConfig();

  const match = config.find(({ path, exact }) =>
    matchPath(location.pathname, {
      path,
      exact: exact !== undefined ? exact : true,
      strict: false,
    })
  );

  const allowedLocales = match
    ? [...match.nonEnglishLocales, ...defaultLocales]
    : defaultLocales;

  return (
    <SafeTranslationContext.Provider value={allowedLocales}>
      {children}
    </SafeTranslationContext.Provider>
  );
};

const fixedT = getFixedT('en');

export const useSafeTranslation = () => {
  const allowedLocalesFromContext = useContext(SafeTranslationContext);
  const { t } = useTranslation();

  if (isTestingEnv) {
    return { t };
  }

  const nonEnglishLanguageAllowed = allowedLocalesFromContext.some(
    (locale) => locale !== 'en'
  );
  // Allow debug languages, but only if there's at least one regular language is allowed other than "en"
  const allowedLocales = [
    ...allowedLocalesFromContext,
    ...(isStagingOrDev && nonEnglishLanguageAllowed ? ['xx', 'cimode'] : []),
  ];

  const currentLanguageIsAllowed = allowedLocales.includes(
    language() as Locale
  );

  if (!currentLanguageIsAllowed) {
    return { t: fixedT };
  }

  return { t };
};

type TransProps = ComponentProps<typeof TransComponent> &
  Record<string, unknown>;

export const Trans = ({ children, ...props }: TransProps) => {
  const { t } = useSafeTranslation();

  return (
    <TransComponent t={t} {...props}>
      {children}
    </TransComponent>
  );
};

export const useLocales = () => {
  const allowedLocales = useContext(SafeTranslationContext);

  const hasNonEnglishLocale = allowedLocales.some((locale) => locale !== 'en');

  return {
    allowedLocales,
    hasNonEnglishLocale,
  };
};

export const getCurrentLocale = () => language() as Locale;

export type { Locale, TFunction } from '@getpopsure/i18n-react';

// utils for regionalisation
export const getLocaleAndRegionFromLocaleId = (input: string) => {
  const [locale, region] = input.split('-');
  return { locale, region };
};

export const isValidLocale = (value: string): value is Locale =>
  allLocales.includes(value as Locale);

export const validateLocale = (value: string): Locale =>
  isValidLocale(value) ? value : 'en';
