/* eslint-disable import/no-cycle */
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RootReducerAction } from 'constants/actions';
import { shutdownIntercom } from 'features/intercomLauncher/utils/shutdownIntercom';
import { APIResponseError } from 'models/error';
import { AppDispatch, AppState } from 'reducers';
import endpoint from 'shared/api';
import history from 'shared/browserHistory';

import {
  clearRecommendationPersona,
  clearRecommendationTool,
} from '../recommendationTool';

export function clearReducerState(
  preserving: Array<keyof AppState>
): RootReducerAction {
  return {
    type: 'CLEAR_REDUCER_STATE',
    preserving,
  };
}

export const signOut = () => async (dispatch: AppDispatch) => {
  const requestType = 'SIGN_OUT';
  dispatch(setRequestInProcess(true, requestType));
  try {
    await endpoint.signOutUser();
    dispatch(clearReducerState([]));
    shutdownIntercom();
    history.push('/account/signin');
    dispatch(setRequestInProcess(false, requestType));
  } catch (error) {
    dispatch(setRequestErrored(error as APIResponseError, requestType));
  }
};

export const submitSignInEmail =
  (email: string, setEmailSubmitted?: (value: boolean) => void) =>
  async (dispatch: AppDispatch) => {
    const requestType = 'SIGN_IN_EMAIL';
    dispatch(setRequestInProcess(true, requestType));

    try {
      await endpoint.sendSignInEmail(email);
      if (setEmailSubmitted) {
        setEmailSubmitted(true);
      }
      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

export const signInWithLoginCode =
  (email: string, loginCode: string) => async (dispatch: AppDispatch) => {
    const requestType = 'SIGN_IN_WITH_LOGIN_CODE';
    dispatch(setRequestInProcess(true, requestType));

    try {
      dispatch(clearRecommendationPersona());
      dispatch(clearRecommendationTool());
      await endpoint.signInWithTemporaryLoginCode(loginCode, email);
      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };
