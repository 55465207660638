import Cookies from 'js-cookie';
import ReactMarkdown from 'react-markdown';
import { isStagingOrDev } from 'shared/util/isStagingOrDev';

const Strong = ({ children }: { children: string }) => {
  return <strong className="fw-bold">{children}</strong>;
};

const Emphasis = ({ children }: { children: string }) => {
  return <em className="fs-italic">{children}</em>;
};

const QuestionnaireMarkdown = ({ children }: { children: string }) => {
  const phraseInContextEditorIsEnabled = !!Cookies.get('enableInContextEditor');

  if (isStagingOrDev && phraseInContextEditorIsEnabled) {
    return <>{children}</>;
  }

  return (
    <ReactMarkdown
      renderers={{
        root: 'span',
        paragraph: 'span',
        strong: Strong,
        emphasis: Emphasis,
      }}
    >
      {children}
    </ReactMarkdown>
  );
};

export default QuestionnaireMarkdown;
