import { capitalizeName } from '@getpopsure/public-utility';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';

import { CancelPolicyButton, ContactUsButton } from '../../components/buttons';
import type { PolicySingleModal } from '../../components/modals';
import {
  CancelPolicyModal,
  DelinquencyBlockerCancelPolicyModal,
} from '../../components/modals';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from '../../PolicySingle.models';
import { getPolicyDocuments } from '../../sections/PolicyDocuments/utils/getPolicyDocuments';
import { getGenericInfo } from '../generic/getGenericInfo';
import { CompanyLifeAddBeneficiary } from './buttons/CompanyLifeAddBeneficiary';
import { CompanyLifeAddBeneficiariesModal } from './modals/CompanyLifeAddBeneficiariesModal';

export const getCompanyLifeConfig = (
  args: GetPolicyConfigArgs
): PolicyConfig => {
  const [policy, t] = args;

  const policyStatus = getPolicyStatus(policy, true);
  const title = getTitleMapping(t).COMPANY_LIFE;
  const capitalizedName = policy.attributes?.insuredPerson?.name
    ? capitalizeName(policy.attributes?.insuredPerson.name)
    : '';
  const subtitle = capitalizedName || '';
  const companyName = policy.attributes.policyData?.['Company name'] || '';

  const header: PolicyConfig['header'] = {
    title,
    subtitle,
    companyName,
    backgroundColor: bannerColorMapping.COMPANY_LIFE,
    insuranceType: policy.type,
    policyStatus,
    startDate: policy.attributes.startDate,
  };

  if (policy.attributes.price) {
    header.price = {
      amount: parseFloat(policy.attributes.price),
      billingType: policy.attributes.billingType as BillingType,
    };
  }

  const sections: PolicySingleSection[] = [
    {
      id: 'POLICY_DETAILS',
      props: {
        policyType: policy.type,
        info: getGenericInfo(policy, t),
        buttons: [
          { id: 'ADD_BENEFICIARY', component: CompanyLifeAddBeneficiary },
          { id: 'CONTACT_US', component: ContactUsButton },
          ...(policy.status !== 'CANCELED'
            ? [{ id: 'CANCEL_POLICY', component: CancelPolicyButton }]
            : []),
        ],
      },
    },
    {
      id: 'POLICY_DOCUMENTS',
      props: {
        documents: getPolicyDocuments(policy),
      },
    },
  ];

  const modals: PolicySingleModal[] = [
    {
      id: 'CANCEL_POLICY',
      component: CancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
    {
      id: 'COMPANY_LIFE_ADD_BENEFICIARIES',
      component: CompanyLifeAddBeneficiariesModal,
    },
  ];

  return { header, modals, sections };
};
