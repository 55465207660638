import { insurance } from '@getpopsure/private-constants';
import routes from 'constants/routes';
import {
  bannerColorMapping,
  imageSrcSetTypeMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';

import { CategoryLookup, Section } from '../models/lookups';

export const travelHealthCategory: CategoryLookup = {
  shortTitle: 'Travel Health',
  title: 'Travel Health Insurance',
  excerpt: 'Health coverage when traveling abroad',
  description:
    "Travel health insurance covers medical expenses abroad for an unlimited number of trips per year, up to 56 days each. It is visa-compliant and covers dental care, hospital treatments, rescue and repatriation, doctor's visits, medical prescriptions, and pregnancy complications.",
  icon: imageTypeMapping.TRAVEL,
  iconSrcSet: imageSrcSetTypeMapping.TRAVEL,
  bannerColor: bannerColorMapping.TRAVEL_HEALTH_V1,
  enabled: false,
  landingPage: insurance.travelHealth.landingPage,
  quotePage: routes.policies.travelHealth.path,
};

export const travelHealthSection: Section = {
  title: '',
  description: 'travel health description',
  icon: '',
};
