import {
  capitalizeName,
  englishFormattedEuroCurrency,
} from '@getpopsure/public-utility';
import { CheckoutProps } from 'features/checkout/models';
import { PaymentMethodSelector } from 'features/paymentMethodSelector/containers';
import { PaymentOption } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

import { formatProratedPeriod } from '../../utils';
import styles from './style.module.scss';

export interface PaymentInfoProps {
  policyInfo: Partial<CheckoutProps>;
  paymentOption: PaymentOption;
  setPaymentOption: (paymentOption: PaymentOption) => void;
  onStripePaymentMethodTypeChange: (type: string) => void;
  showPaymentSelector?: boolean;
}

export const StripeCardElementOptions = {
  style: {
    base: {
      '::placeholder': {
        color: '#d2d2d8',
      },
      fontSize: '16px',
      lineHeight: '48px',
      fontFamily: 'Lato, sans-serif',
    },
  },
};

const PaymentInfo = ({
  policyInfo,
  paymentOption,
  setPaymentOption,
  onStripePaymentMethodTypeChange,
  showPaymentSelector = true,
}: PaymentInfoProps) => {
  const { t } = useSafeTranslation();

  const [viewProrationInfo, setViewProrationInfo] = useState(false);

  const title =
    paymentOption.type === 'EXISTING_PAYMENT_METHOD'
      ? t(
          'components.paymentinfo.confirmpaymentmethod.title',
          'Confirm your payment method'
        )
      : t(
          'components.paymentinfo.addpaymentmethod.title',
          'Add payment method'
        );

  const description = t(
    'components.paymentinfo.addpaymentmethod.description',
    'Please select the account that you wish to pay with. This will be charged on the 1st day of each month.'
  );

  const includesDependents =
    policyInfo.subPolicies && policyInfo.subPolicies.length > 0;

  const { totalMonthlyPrice, totalProratedPrice } = policyInfo;
  const proratedPeriod = policyInfo.mainPolicy?.proratedPeriod;
  const { subPolicies } = policyInfo;

  const isProratedPriceSameAsMonthly = totalProratedPrice === totalMonthlyPrice;

  const partialStr = isProratedPriceSameAsMonthly
    ? ''
    : `${t('components.paymentinfo.firstpayment.partial', 'partial')} `;

  return (
    <div className={styles.container}>
      <h1 className="p-h2">{title}</h1>
      <div className="mt24 p-p">{description}</div>
      <div className="mt16 p-p tc-grey-600">
        {t('components.paymentinfo.firstpayment.description', {
          defaultValue:
            'Your first {{partial}} payment {{proratedPeriod}} of {{price}} will be taken today.',
          partial: partialStr,
          proratedPeriod:
            !includesDependents && proratedPeriod
              ? `(${formatProratedPeriod(proratedPeriod)})`
              : '',
          price: totalProratedPrice
            ? englishFormattedEuroCurrency(totalProratedPrice)
            : englishFormattedEuroCurrency(totalMonthlyPrice || 0),
        })}{' '}
        {includesDependents && (
          <button
            className="ds-interactive-component fw-bold p-a d-inline"
            onClick={() => setViewProrationInfo(!viewProrationInfo)}
            type="button"
          >
            {t('components.paymentinfo.firstpayment.moreinfo', 'More info')}
          </button>
        )}
      </div>
      {subPolicies && (
        <AnimateHeight duration={300} height={viewProrationInfo ? 'auto' : 0}>
          <div className="mt16 p-p tc-grey-600">
            <div className="fw-bold">
              {t(
                'components.paymentinfo.firstpayment.partialpayment',
                'Partial payment'
              )}
            </div>
            {subPolicies.map(
              ({
                id,
                policyHolder: { name },
                proratedPeriod: subPolicyProratedPeriod,
              }) => (
                <p key={id}>
                  {`${capitalizeName(name)}: ${formatProratedPeriod(
                    subPolicyProratedPeriod
                  )}`}
                </p>
              )
            )}
          </div>
        </AnimateHeight>
      )}
      <div className="mt16">
        {showPaymentSelector && (
          <PaymentMethodSelector
            handlePaymentOptionUpdate={setPaymentOption}
            onStripePaymentMethodTypeChange={onStripePaymentMethodTypeChange}
          />
        )}
      </div>
    </div>
  );
};

export default PaymentInfo;
