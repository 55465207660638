import Navigation from 'components/common/navigation';
import routes from 'constants/routes';
import { associatedPathForClaimStep, ClaimStep } from 'models/claims';
import { useParams as _useParams } from 'react-router';
import { generatePath, Link } from 'react-router-dom';
import { urlLastPathComponent } from 'shared/util/urlLastPathComponent';

import { useClaimSteps } from '../hooks';
import oneIcon from './icons/one.svg';
import threeIcon from './icons/three.svg';
import twoIcon from './icons/two.svg';
import styles from './style.module.scss';

const stepDescription: { [K in ClaimStep]: string } = {
  SELECT_CLAIM_TYPE: 'Select your claim type',
  CLAIM_DETAIL: 'Provide details of claim',
  PAYOUT_DETAIL: 'Provide payout details',
};

const iconMapping = { 1: oneIcon, 2: twoIcon, 3: threeIcon };

const ClaimSteps = ({
  useParams = _useParams,
}: {
  useParams?: typeof _useParams;
}) => {
  const { policyId } = useParams<{ policyId: string }>();
  const steps = useClaimSteps();

  const nextStep = steps[0];

  return (
    <div className="p-body">
      <div className={`wmx8 w100 ${styles.container}`}>
        <Navigation
          previousScreen="My Claims"
          previousUrl={generatePath(routes.me.claims.myClaims.path, {
            policyId,
          })}
        />
        <div className={styles['inner-container']}>
          <div className="mt40">
            <div className="p-h2">Making a claim</div>
            <div className="p-p wmx7 mt8">
              With only {steps.length} steps, here’s what to expect when
              claiming on your insurance policy
            </div>
          </div>
          <div className={`mt16 ${styles['step-container']}`}>
            {steps.map((step, i) => {
              const adjustedIndex = i + 1;
              return (
                <div
                  className={`ta-center mt8 ${styles['step-card']}`}
                  key={`${adjustedIndex}`}
                >
                  <img
                    className={styles['step-card-img']}
                    src={iconMapping[adjustedIndex]}
                    alt={`order-${adjustedIndex}`}
                  />
                  <h4
                    className={`mt16 p-h4 ${styles['step-card-description']}`}
                  >
                    {stepDescription[step]}
                  </h4>
                </div>
              );
            })}
          </div>
          <Link
            className={`p-btn p-btn--primary mt16 p-p wmn3 mt32 ${styles.button}`}
            to={urlLastPathComponent(associatedPathForClaimStep[nextStep])}
          >
            Get Started
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ClaimSteps;
