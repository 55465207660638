import { Question, QuestionnaireQuestions } from '@getpopsure/qnr-framework';

export const getReviewValue = <Questionnaire extends QuestionnaireQuestions>(
  questions: Question<Questionnaire>[],
  questionId: keyof Questionnaire
) => {
  const question = questions.find((q) => q.id === questionId);
  if (!question) {
    return null;
  }

  switch (question.type) {
    case 'ADDRESS':
      return {
        type: 'Strings',
        key: 'address',
        keys: ['street', 'houseNumber', 'postcode', 'city'],
      };
    case 'NAME':
      return {
        type: 'Strings',
        key: 'name',
        keys: ['firstName', 'lastName'],
      };
    case 'DATE':
      return {
        type: 'Date',
        format: 'DD MMM YYYY',
        key: question.id,
      };
    default:
      return { type: 'String', key: questionId };
  }
};
