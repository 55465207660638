import { paramsSetUrl } from '@getpopsure/public-utility';
import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { flushGenericQuestionnaire } from 'actions/genericQuestionnaire';
import { useWindowSize } from 'hooks/useWindowSize';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';

const prepareValue = (input: unknown) => {
  if (!input) {
    return String(input);
  }

  if (typeof input === 'string') {
    return input;
  }

  if (Array.isArray(input)) {
    return input.join(',');
  }

  if (typeof input === 'object') {
    return Object.entries(input).reduce((acc, [_, v]) => {
      return `${acc} ${v}`;
    }, '');
  }

  return String(input);
};

const getSrc = <Questionnaire extends QuestionnaireQuestions>(
  tallyUrl: string,
  questionnaireAnswers: Questionnaire
) => {
  const preparedParams = Object.entries(questionnaireAnswers)
    .filter(([_key, value]) => value !== null)
    .flatMap(([key, value]) => {
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        return Object.entries(value).reduce(
          (acc, [k, v]) => {
            acc.push({
              key: `${key}.${k}`,
              value: prepareValue(v),
            });

            return acc;
          },
          [] as {
            key: string;
            value: string;
          }[]
        );
      }
      return [
        {
          key,
          value: prepareValue(value),
        },
      ];
    });

  return paramsSetUrl(tallyUrl, preparedParams);
};

export const Tally = <Questionnaire extends QuestionnaireQuestions>({
  featureName,
  questionnaireAnswers,
  tallyUrl,
}: QuestionnaireFormProps<Questionnaire> & {
  tallyUrl: string;
}) => {
  const size = useWindowSize();
  const dispatch = useDispatch();

  const src = getSrc(tallyUrl, questionnaireAnswers);

  useEffect(() => {
    return () => {
      dispatch(
        flushGenericQuestionnaire(
          featureName as keyof GenericQuestionnaireState
        )
      );
    };
  }, [dispatch, featureName]);

  return (
    <>
      <Helmet>
        <script async src="https://tally.so/widgets/embed.js" />
      </Helmet>
      <iframe
        width={size.width ?? '100%'}
        height={size.height ?? '100%'}
        data-tally-src={src}
        title="Sign up"
      />
    </>
  );
};
