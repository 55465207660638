import dayjs from 'dayjs';
import Cell from 'features/checkout/components/Cell';
import { getCheckoutInfo } from 'features/checkout/selectors';
import { HouseholdPolicyDetails } from 'features/household/models';
import { ReferrerCodeVerify } from 'features/referralEngine';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { formatCurrencyWithSpace } from 'shared/util/formatCurrencyWithSpace';

import styles from './PolicyDetails.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

export const PolicyDetails = () => {
  const { t } = useSafeTranslation();

  const checkoutInfo = useSelector(
    getCheckoutInfo<HouseholdPolicyDetails>('HOUSEHOLD')
  );

  const hasSecurityDevice =
    checkoutInfo?.mainPolicy.policyDetails.homeSecurityDevice;
  const basicPremium =
    checkoutInfo?.mainPolicy.policyDetails.priceDetails?.basicPremium || 0;

  const basicPrice = hasSecurityDevice ? basicPremium / 0.95 : basicPremium;
  const securityDeviceDiscount = hasSecurityDevice ? basicPrice * 0.05 : 0;

  const address = checkoutInfo?.mainPolicy.policyDetails.address;

  return (
    <div className={styles.container}>
      <div className="p-h2 mb16">
        {t(
          'page.household.questionnaire.checkout.policydetails.title',
          'Policy details'
        )}
      </div>
      <Cell
        title={t(
          'page.household.questionnaire.checkout.policydetails.insuredsum',
          'Insured sum'
        )}
        value={formatCurrencyWithSpace(
          checkoutInfo?.mainPolicy.policyDetails.insuredSum || 0,
          2
        ).replace(/\.00$/, '')}
      />
      {checkoutInfo?.mainPolicy.policyDetails.addOns?.includes(
        'EXTENDED_COVERAGE'
      ) &&
        checkoutInfo?.mainPolicy.policyDetails.addOns?.includes(
          'ADDITIONAL_BIKE_COVERAGE'
        ) && (
          <Cell
            title={t(
              'page.household.questionnaire.checkout.policydetails.bikeinsuredsum',
              'Bike insured sum'
            )}
            value={formatCurrencyWithSpace(
              checkoutInfo?.mainPolicy.policyDetails.bikeInsuredSum || 0,
              2
            ).replace(/\.00$/, '')}
          />
        )}
      {checkoutInfo?.mainPolicy.policyDetails.address && (
        <Cell
          title={t(
            'page.household.questionnaire.checkout.policydetails.address',
            'Address'
          )}
          value={[
            `${address?.street} ${address?.houseNumber},`,
            `${address?.postcode} ${address?.city}`,
          ]}
          noBreak
        />
      )}
      {checkoutInfo?.mainPolicy.policyDetails.homeSecurityDevice && (
        <Cell
          title={t(
            'page.household.questionnaire.checkout.policydetails.homesecuritydevice',
            'Home security device'
          )}
          value={
            checkoutInfo.mainPolicy.policyDetails.homeSecurityDevice
              ? t('qnf.forms.boolean.yes')
              : t('qnf.forms.boolean.no')
          }
        />
      )}
      {checkoutInfo?.mainPolicy.startDate && (
        <Cell
          title={t(
            'page.household.qnr.checkout.policydetails.requestedStartDate',
            'Requested start date'
          )}
          value={dayjs(checkoutInfo.mainPolicy.startDate).format(DATE_FORMAT)}
          additionalInfo={{
            title: t(
              'page.household.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
              'Requested start date'
            ),
            description: t(
              'page.household.qnr.checkout.policydetails.requestedStartDate.additionalInfo.description',
              'Your coverage should begin on {{coverageDate}}, but only after you have received the official confirmation.',
              {
                coverageDate: dayjs(checkoutInfo.mainPolicy.startDate).format(
                  DATE_FORMAT
                ),
              }
            ),
          }}
        />
      )}
      {checkoutInfo?.mainPolicy.policyDetails.provider && (
        <Cell
          title={t(
            'page.household.questionnaire.checkout.policydetails.provider',
            'Provider'
          )}
          value={checkoutInfo.mainPolicy.policyDetails.provider}
        />
      )}
      <div className={`bg-grey-200 mt32 mb32 ${styles.divider}`} />
      {checkoutInfo?.mainPolicy.policyDetails.priceDetails && (
        <>
          <Cell
            title={t(
              'page.household.questionnaire.checkout.policydetails.basiccoverage',
              'Basic coverage'
            )}
            value={formatCurrencyWithSpace(basicPrice, 2)}
            noBreak
          />
          {hasSecurityDevice && (
            <Cell
              title={t(
                'page.household.questionnaire.checkout.policydetails.discount',
                '5% home security devices discount'
              )}
              value={formatCurrencyWithSpace(-securityDeviceDiscount, 2)}
              noBreak
            />
          )}
          {checkoutInfo.mainPolicy.policyDetails.addOns?.includes(
            'EXTENDED_COVERAGE'
          ) && (
            <Cell
              title={t(
                'page.household.questionnaire.checkout.policydetails.extendendcoverage',
                'Extended coverage add-on'
              )}
              value={formatCurrencyWithSpace(
                checkoutInfo.mainPolicy.policyDetails.priceDetails
                  .extendedPremium -
                  checkoutInfo.mainPolicy.policyDetails.priceDetails
                    .basicPremium,
                2
              )}
              noBreak
            />
          )}
          {checkoutInfo.mainPolicy.policyDetails.addOns?.includes(
            'BROKEN_GLASS_COVERAGE'
          ) && (
            <Cell
              title={t(
                'page.household.questionnaire.checkout.policydetails.brokenglasscoverage',
                'Broken glass coverage add-on'
              )}
              value={formatCurrencyWithSpace(
                checkoutInfo.mainPolicy.policyDetails.priceDetails.glassPrice,
                2
              )}
              noBreak
            />
          )}
          {checkoutInfo.mainPolicy.policyDetails.addOns?.includes(
            'EXTENDED_COVERAGE'
          ) &&
            checkoutInfo.mainPolicy.policyDetails.addOns?.includes(
              'ADDITIONAL_BIKE_COVERAGE'
            ) && (
              <Cell
                title={t(
                  'page.household.questionnaire.checkout.policydetails.additionalbikecoverage',
                  'Additional bike coverage add-on'
                )}
                value={formatCurrencyWithSpace(
                  checkoutInfo.mainPolicy.policyDetails.priceDetails.bikePrice,
                  2
                )}
                noBreak
              />
            )}
        </>
      )}
      <div className={`bg-grey-200 mt32 mb32 ${styles.divider}`} />
      {checkoutInfo?.mainPolicy.monthlyPrice && (
        <div className="d-flex">
          <div className="p-h3">
            {t(
              'page.household.questionnaire.checkout.policydetails.total',
              'Total per month'
            )}
          </div>
          <div className="p-h3 ml-auto">
            {formatCurrencyWithSpace(checkoutInfo?.mainPolicy.monthlyPrice, 2)}
          </div>
        </div>
      )}
      <ReferrerCodeVerify />
    </div>
  );
};
