import { CoverageDetails } from 'components/CoverageDetails';
import { useSafeTranslation } from 'shared/i18n';

import {
  getCoverageTableData,
  getCoverageTableHeaders,
} from './coverageTableData';

type HouseholdCoverageDetailsProps = {
  buttonCaption?: string;
  grey?: boolean;
  small?: boolean;
  useAlternateDefaultCaption?: boolean;
};

export const HouseholdCoverageDetails = ({
  buttonCaption,
  grey,
  small,
  useAlternateDefaultCaption = false,
}: HouseholdCoverageDetailsProps) => {
  const { t } = useSafeTranslation();

  const coverageTableData = getCoverageTableData(t);
  const coverageTableHeaders = getCoverageTableHeaders(t);

  return (
    <CoverageDetails
      grey={grey}
      small={small}
      buttonCaption={buttonCaption}
      coverageTableData={coverageTableData}
      coverageTableHeaders={coverageTableHeaders}
      useAlternateDefaultCaption={useAlternateDefaultCaption}
    />
  );
};
