import Session from '@getpopsure/session';
import { Account } from 'features/recommendationTool/models/account';
import { ContinueWithAccount } from 'features/recommendationTool/questionnaires/account/components/ContinueWithAccount';
import { FullName } from 'features/recommendationTool/questionnaires/account/components/FullName';
import { Loading } from 'features/recommendationTool/questionnaires/account/components/Loading';
import { Login } from 'features/recommendationTool/questionnaires/account/components/Login';
import { PartialQuestionnaire } from 'models/questionnaireFramework';
import { HealthQuestionnaireAnswers } from 'models/recommendationTool';

export const getAccountInfo = <Questionnaire>(
  nextPageId: keyof Questionnaire
): PartialQuestionnaire<Account, 'account'> => {
  if (Session.isAuthenticated) return {};

  return {
    loading: {
      type: 'CUSTOM',
      component: Loading,
      metaData: {
        nextPageId,
      },
      groupId: 'account',
    },
    continueWithAccount: {
      required: (answers) =>
        !Session.isAuthenticated && !answers.continueWithAccount,
      type: 'CUSTOM',
      component: ContinueWithAccount,
      groupId: 'account',
      retrieveAnswerObject: (
        continueWithAccount: Account['continueWithAccount']
      ): Partial<HealthQuestionnaireAnswers> => ({
        continueWithAccount,
        forceQuestionnaireChanges: Date.now(),
      }),
      retrieveNextPageId: (
        continueWithAccount: Account['continueWithAccount']
      ) => {
        if (continueWithAccount) return 'signIn';

        return nextPageId as keyof Account;
      },
    },
    signIn: {
      required: (answers) =>
        !Session.isAuthenticated && !!answers.continueWithAccount,
      type: 'CUSTOM',
      component: Login,
      groupId: 'account',
      retrieveAnswerObject: (signIn: Account['signIn']): Partial<Account> => ({
        signIn,
      }),
    },
    fullName: {
      required: (answers) =>
        !Session.isAuthenticated && !!answers.continueWithAccount,
      type: 'CUSTOM',
      groupId: 'account',
      component: FullName,
      retrieveAnswerObject: (
        fullName: Account['fullName']
      ): Partial<Account> => ({ fullName }),
      retrieveNextPageId: () => nextPageId as keyof Account,
    },
  };
};
