import dayjs from 'dayjs';

import { PriceMapping } from './models';

export const getBikeAgeCategory = (purchaseDate: string) => {
  const now = new Date();
  const bikeAgeInMonth = dayjs(now).diff(purchaseDate, 'months', true);
  return bikeAgeInMonth >= 12 ? 'USED' : 'NEW';
};

export const getInsuredAgeGroup = (age: number) => {
  if (age < 18 || age > 80) return;
  if (age >= 18 && age < 30) return 'age1';
  if (age >= 30 && age < 40) return 'age2';
  if (age >= 40 && age <= 80) return 'age3';
};

export const getPremiumFromBikePrice = (
  bikePrice: number,
  premiumByBikePrice: PriceMapping
) => {
  if (bikePrice > 12000) return;

  const [, priceGroupValue] =
    Object.entries(premiumByBikePrice)
      .sort(([keyA], [keyB]) => Number(keyA) - Number(keyB))
      .find(([key]) => Number(key) >= bikePrice) ?? [];

  return priceGroupValue;
};
