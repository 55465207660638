import { useTranslation } from '@getpopsure/i18n-react';
import { faq } from '@getpopsure/private-constants';
import { Badge, Card, Radio } from '@popsure/dirty-swan';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import {
  Plan,
  QuoteCustomization,
  TOOTH_REPLACEMENT_COVERAGE,
  ToothReplacementCoverage as ToothReplacementType,
} from 'features/dental/models';

import styles from './styles.module.scss';

type ToothReplacementProps = {
  toothReplacementCoverage: ToothReplacementType;
  setQuoteCustomization: React.Dispatch<
    React.SetStateAction<QuoteCustomization>
  >;
  plan: Plan;
};

export const ToothReplacementCoverage = ({
  toothReplacementCoverage,
  setQuoteCustomization,
  plan,
}: ToothReplacementProps) => {
  const { t } = useTranslation();

  type ToothReplacementRadio = Record<
    ToothReplacementType,
    Record<'title', ToothReplacementType>
  >;

  const radioOptions = TOOTH_REPLACEMENT_COVERAGE.reduce<ToothReplacementRadio>(
    (options, amount) => {
      return {
        ...options,
        [amount]: { title: amount },
      };
    },
    {} as ToothReplacementRadio
  );

  return (
    <>
      <header
        className={classNames(
          'fd-column ai-center pb24',
          styles.coverageHeader
        )}
      >
        <h2 className="p-h2 p--serif ta-center">
          {t(
            'dental.qnr.preQuote.quote.toothReplacement.title',
            'Tooth replacement coverage'
          )}
        </h2>
        <div className={`ws2 ${styles.headerUnderline}`} />
      </header>
      <Card
        density="spacious"
        label={
          <Badge size="small" variant="primary" className="mb16">
            {t(
              'dental.qnr.preQuote.quote.toothReplacement.label',
              'Advanced plan only'
            )}
          </Badge>
        }
      >
        <Markdown
          className={classNames('p-p mb24', styles.cardContent)}
          openLinksInNewTab
        >
          {t(
            'dental.qnr.preQuote.quote.toothReplacement.description',
            'On the advanced plan, you can select the percentage the insurance should cover for tooth replacement procedures like crowns, bridges, inlays, and implants.\n\nFor example, if you select 80% and submit a claim for €1,000, you will be reimbursed up to €800. [Learn more]({{toothReplacementLink}})',
            {
              toothReplacementLink: faq.dentalToothReplacementCoverage,
            }
          )}
        </Markdown>
        <Radio
          options={radioOptions}
          wide
          value={plan === 'ADVANCED' ? toothReplacementCoverage : undefined}
          onChange={(value) =>
            setQuoteCustomization((prevState) => ({
              ...prevState,
              toothReplacementCoverage: value,
            }))
          }
          classNames={{
            container: classNames('d-flex jc-start', styles.radioContainer),
            label: classNames('w100', styles.radioLabel),
          }}
          disabled={plan === 'BASIC'}
        />
      </Card>
    </>
  );
};
