import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { ClaimsAction, RequestAction } from 'constants/actions';
import routes from 'constants/routes';
import type {
  Claim,
  ClaimStatuses,
  ClaimSubmitInfo,
  ClaimSubmittableInfo,
  InternalClaimStatus,
  LegalClaimInfo,
} from 'models/claims';
import { APIResponseError } from 'models/error';
import { InsuranceTypes } from 'models/insurances/types';
import { generatePath } from 'react-router-dom';
import { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import api from 'shared/api';
import browserHistory from 'shared/browserHistory';

export type ClaimActionDispatch = ThunkDispatch<
  AppState,
  unknown,
  ClaimsAction | RequestAction
>;

export const storeClaims = (claims: Claim[]): ClaimsAction => ({
  type: 'GET_CLAIMS',
  claims,
});

export const fetchAllClaims = () => async (dispatch: ClaimActionDispatch) => {
  const requestType = 'GET_CLAIMS';
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { data } = await api.getClaims();
    dispatch(storeClaims(data));
    dispatch(setRequestInProcess(false, requestType));
  } catch (error) {
    dispatch(setRequestErrored(error as APIResponseError, requestType));
  }
};

export const fetchUserClaims =
  (insuranceType: InsuranceTypes) => async (dispatch: ClaimActionDispatch) => {
    const requestType = 'GET_CLAIMS';
    dispatch(setRequestInProcess(true, requestType));

    try {
      const { data } = await api.getClaimsByInsurance(insuranceType);
      dispatch(storeClaims(data));
      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

export const storeCurrentClaim = (currentClaim: Claim): ClaimsAction => ({
  type: 'GET_CURRENT_CLAIM',
  currentClaim,
});

export const getCurrentClaim =
  (claimId: string) => async (dispatch: ClaimActionDispatch) => {
    const requestType = 'GET_CURRENT_CLAIM';
    dispatch(setRequestInProcess(true, requestType));

    try {
      const { data } = await api.getCurrentClaim(claimId);
      dispatch(storeCurrentClaim(data));
      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

export const storeSubmittableClaimInfo = (
  submittableClaimInfo: Partial<ClaimSubmittableInfo>
): ClaimsAction => ({
  type: 'STORE_CLAIM_INFO',
  submittableClaimInfo,
});

export const flushClaimType = (): ClaimsAction => ({
  type: 'FLUSH_CLAIM_TYPE',
});

export const submitGenericClaim =
  (claimInfo: ClaimSubmitInfo) => async (dispatch: ClaimActionDispatch) => {
    const requestType = 'CREATE_CLAIM';
    dispatch(setRequestInProcess(true, requestType));

    try {
      await api.submitClaim(claimInfo);
      dispatch(setRequestInProcess(false, requestType));
      browserHistory.push(routes.me.claims.confirmationGeneric.path);
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

// Function shares functionality for submission of contacting lawyer and claims
export const submitLegalClaim =
  ({
    claimInfo,
    setSubmissionStatus,
  }: {
    claimInfo: ClaimSubmitInfo;
    setSubmissionStatus?: (submissionStatus: boolean) => void;
  }) =>
  async (dispatch: ClaimActionDispatch) => {
    const requestType = 'CREATE_CLAIM';
    dispatch(setRequestInProcess(true, requestType));

    try {
      await api.submitClaim(claimInfo);
      dispatch(setRequestInProcess(false, requestType));

      if (setSubmissionStatus !== undefined) {
        setSubmissionStatus(true);
      } else {
        browserHistory.push(
          generatePath(routes.me.claims.legalClaim.processing.path, {
            policyId: claimInfo.userPolicyId,
          })
        );
        dispatch(flushLegalClaimDetails());
      }
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

export const submitPrivateClaim =
  (claimInfo: ClaimSubmitInfo) => async (dispatch: ClaimActionDispatch) => {
    const requestType = 'CREATE_CLAIM';
    dispatch(setRequestInProcess(true, requestType));

    try {
      await api.submitClaim(claimInfo);
      dispatch(setRequestInProcess(false, requestType));

      browserHistory.push(
        generatePath(routes.me.claims.processing.path, {
          policyId: claimInfo.userPolicyId,
        })
      );
      dispatch(flushClaimType());
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

export const storeLegalClaimInfo = (
  claimInfo: Partial<LegalClaimInfo>
): ClaimsAction => ({
  type: 'STORE_LEGAL_CLAIM_INFO',
  legalClaimInfo: claimInfo,
});

export const flushLegalClaimDetails = (): ClaimsAction => ({
  type: 'FLUSH_LEGAL_CLAIM_DETAILS',
});

export const updateClaimDocuments =
  (claimId: string, uploadedDocumentTokens: string[]) =>
  async (dispatch: ClaimActionDispatch) => {
    const requestType = 'UPDATE_CLAIM_DOCUMENTS';
    dispatch(setRequestInProcess(true, requestType));

    try {
      const {
        data: { documents, status, internalStatus },
      } = await api.updateClaimDocuments(claimId, uploadedDocumentTokens);
      dispatch(mergeClaimDocuments(documents, status, internalStatus));
      dispatch(setRequestInProcess(false, requestType));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      return { status: 'ERROR' };
    }
  };

const mergeClaimDocuments = (
  documents: Claim['documents'],
  status: ClaimStatuses,
  internalStatus: InternalClaimStatus
): ClaimsAction => ({
  type: 'MERGE_CLAIM_DOCUMENT',
  documents,
  status,
  internalStatus,
});
