import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';

import styles from './SpouseInfo.module.scss';

export const SpouseInfo = ({
  onSubmitValue,
}: {
  onSubmitValue: CustomComponentProps<Record<string, unknown>>['onSubmitValue'];
}) => {
  const { t } = useSafeTranslation();

  const handleGetStarted = () => {
    onSubmitValue(true);
  };

  return (
    <div className={styles.container}>
      <div className="p-body">
        <span
          className={styles.welcomeIcon}
          role="img"
          aria-label="waving hand icon"
        >
          🧐
        </span>
        <h1 className="p-h1 mt16">
          {t(
            'private.qnr.spouseHasOwnIncomeInfo.title',
            'Your spouse will need their own health insurance policy'
          )}
        </h1>
        <p className="p-p mt16 fw-bold">
          {t(
            'private.qnr.spouseHasOwnIncomeInfo.description.a',
            'By law in Germany, any adult who is employed and earning over €520 per month cannot be a dependent under another health insurance policy.'
          )}
        </p>
        <p className="p-p mt16">
          {t(
            'private.qnr.spouseHasOwnIncomeInfo.description.b',
            'This means that your spouse cannot be covered under your health insurance policy and will need their own policy.'
          )}
        </p>
        <button
          className={`p-btn p-btn--primary mt24 ${styles.nextButton}`}
          data-cy="button-continue"
          onClick={handleGetStarted}
          type="button"
        >
          {t(
            'private.qnr.spouseHasOwnIncomeInfo.continueButton.caption',
            'Continue without spouse'
          )}
        </button>
      </div>
    </div>
  );
};
