import NameForm from 'components/questionnaireForms/NameForm';
import {
  ViewProps,
  withContainer,
} from 'features/liability/containers/withContainer';
import { getQuestionsMapping, QuestionId } from 'models/liability';
import { Name } from 'models/user';
import { useSafeTranslation } from 'shared/i18n';

const questionId: QuestionId = 'name';

const LiabilityName = ({
  initialValue,
  onSubmitValue,
}: ViewProps<typeof questionId>) => {
  const { t } = useSafeTranslation();

  return (
    <NameForm
      value={initialValue}
      question={getQuestionsMapping(t)[questionId]}
      onSubmitValue={(value: Name) => onSubmitValue({ questionId, value })}
      featureName="Liability"
    />
  );
};

export default withContainer(LiabilityName, questionId);
