export const jobsList = [
  {
    OccupationCode: 622401,
    OccDE: 'BMSR-Techniker/in',
    Occupation: '(Automation Technology) Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 981193,
    OccDE: 'Abiturient/in',
    Occupation: 'Abitur Graduate',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997102,
    OccDE: 'ABM-Kraft',
    Occupation: 'ABM Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 880003,
    OccDE: 'Wissenschaftler/in',
    Occupation: 'Academic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 868308,
    OccDE: 'Studienberater/in',
    Occupation: 'Academic Advisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 871212,
    OccDE: 'Wissenschaftliche(r) Assistent/in (Hochschule)',
    Occupation: 'Academic Assistant (University College)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 822201,
    OccDE: 'Akademisch geprüfte(r) Übersetzer/in',
    Occupation: 'Academically Certified Translator',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 522405,
    OccDE: 'Abnehmer/in (Abfüller/in)',
    Occupation: 'Acceptance Inspection Agent (Filling)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521303,
    OccDE: 'Abnehmer/in (Endkontrolleur/in)',
    Occupation: 'Acceptance Inspection Agent (Final Checker)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521302,
    OccDE: 'Abnahmekontrolleur/in',
    Occupation: 'Acceptance Inspection Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764901,
    OccDE: 'Aufnahmeleiter/in (Verwaltung)',
    Occupation: 'Acceptance Inspection Director (Administration)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787318,
    OccDE: 'Unfallsachbearbeiter/in (Berufsgenossenschaft)',
    Occupation: 'Accident Officer (Trade Association)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703206,
    OccDE: 'Kontakter/in',
    Occupation: 'Account Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703207,
    OccDE: 'Kontaktmann/Kontaktfrau (Werbung)',
    Occupation: 'Account Agent (Marketing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751398,
    OccDE: 'Account Manager/in',
    Occupation: 'Account Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788612,
    OccDE: 'Buchführer/in',
    Occupation: 'Accountant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771302,
    OccDE: 'Rechnungsprüfer/in',
    Occupation: 'Accounting Auditor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 772001,
    OccDE: 'Accounting Clerk',
    Occupation: 'Accounting Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 771407,
    OccDE: 'Rechnungssachbearbeiter/in',
    Occupation: 'Accounting Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771206,
    OccDE: 'Rechnungswesensachbearbeiter/in',
    Occupation: 'Accounting Department Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 772601,
    OccDE: 'Accounting Manager',
    Occupation: 'Accounting Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 772602,
    OccDE: 'Buchhaltungsleiter/in',
    Occupation: 'Accounting Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 771203,
    OccDE: 'Fachkraft für Rechnungswesen',
    Occupation: 'Accounting Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771201,
    OccDE: 'Fachkaufmann/-kauffrau für Rechnungswesen',
    Occupation: 'Accounting Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771202,
    OccDE: 'Fachkaufmann/-kauffrau innerbetriebliches Rechnungswesen',
    Occupation: 'Accounting Specialist for Management Accounting',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 772204,
    OccDE: 'Kreditorenbuchhalter/in',
    Occupation: 'Accounts Payable Accountant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 772201,
    OccDE: 'Debitorenbuchhalter/in',
    Occupation: 'Accounts Receivable Accountant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 771609,
    OccDE: 'Bezügerechner/in',
    Occupation: 'Accounts Receivables Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 612903,
    OccDE: 'Akustiker/in',
    Occupation: 'Acoustician',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 482402,
    OccDE: 'Akustikbauer/in',
    Occupation: 'Acoustics Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482401,
    OccDE: 'Akustik-Monteur/in',
    Occupation: 'Acoustics Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705825,
    OccDE: 'Versteiger(er/in)',
    Occupation: 'Action Seller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879201,
    OccDE: 'Animateur/in (Freizeitlehrer/in)',
    Occupation: 'Activity Coordinator (Camp Instructor)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861501,
    OccDE: 'Animateur/in (Sozialarbeit/er)',
    Occupation: 'Activity Coordinator (Social Work)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705511,
    OccDE: 'Schausteller/in',
    Occupation: 'Actor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832611,
    OccDE: 'Schauspieler/in',
    Occupation: 'Actor/Actress',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787901,
    OccDE: 'Aktuar/in',
    Occupation: 'Actuary',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 612307,
    OccDE: 'Versicherungsmathematiker/in',
    Occupation: 'Actuary',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841901,
    OccDE: 'Akupunkteur/in (Arzt/Ärztin)',
    Occupation: 'Acupuncturist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 705316,
    OccDE: 'Anzeigenvermittler/in',
    Occupation: 'Ad Broker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683202,
    OccDE: 'Anzeigenberater/in (Verlag)',
    Occupation: 'Ad Consultant (Publishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683301,
    OccDE: 'Anzeigenleiter/in (Verlag)',
    Occupation: 'Ad Director (Publishing)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 705317,
    OccDE: 'Anzeigenvertreter/in',
    Occupation: 'Ad Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683203,
    OccDE: 'Anzeigenfachberater/in (Verlag)',
    Occupation: 'Ad Specialist Consultant (Publishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281201,
    OccDE: 'ADAC-Straßenwachtfahrer/in',
    Occupation: 'ADAC Roadway Safety Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861330,
    OccDE: 'Suchtkrankenberater/in',
    Occupation: 'Addiction Counsellor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191001,
    OccDE: 'Adjustierer/in (Metallerzeugung)',
    Occupation: 'Adjuster (Metal Production)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 763110,
    OccDE: 'Verwaltungsleiter/in (Zweckverband)',
    Occupation: 'Administration Manager (Special Purpose Group)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 881801,
    OccDE: 'Verwaltungswissenschaftler/in',
    Occupation: 'Administration Sciences Scholar',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 788655,
    OccDE: 'Verwaltungs- und Rechnungsführer/in',
    Occupation: 'Administrative and Accounting Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787938,
    OccDE: 'Verwaltungsangestellte/r (Industrie- und Handelskammer)',
    Occupation: 'Administrative Assistant (Industry and Commerce)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787940,
    OccDE: 'Verwaltungsangestellte/r in der staatlichen Innenverwaltung',
    Occupation: 'Administrative Assistant in Municipal Administration',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787032,
    OccDE: 'Verwaltungskaufmann/-kauffrau',
    Occupation: 'Administrative Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814249,
    OccDE: 'Wachtmeister/in (Justiz-, Strafvollzugsdienst)',
    Occupation: 'Administrative Clerk (Correctional Services, Prison)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801499,
    OccDE: 'Verwaltungsangestellter (Bundespolizei)',
    Occupation: 'Administrative Clerk (Federal Police)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787322,
    OccDE: 'Verwaltungsassistent/in',
    Occupation: 'Administrative Department Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787028,
    OccDE: 'Verwaltungsbeamt(er/in)',
    Occupation: 'Administrative Department Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765312,
    OccDE: 'Verwaltungs(amtmann/amtfrau) (Arbeits-, Sozialverwaltung)',
    Occupation:
      'Administrative Department Deputy Director (Employment, Social Administration)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 764051,
    OccDE: 'Verwaltungsleiter/in (öffentliche Verwaltung)',
    Occupation: 'Administrative Department Director (Public Administration)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787027,
    OccDE: 'Verwaltungsangestellte/r',
    Occupation: 'Administrative Department Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764048,
    OccDE: 'Verwaltungsangestellte/r (höherer Dienst)',
    Occupation: 'Administrative Department Employee (highest civil service)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787026,
    OccDE: 'Verwaltungsangestellte/r (mittlerer Dienst)',
    Occupation: 'Administrative Department Employee (mid-level service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787811,
    OccDE: 'Verwaltungsangestellte/r im kirchlichen Dienst',
    Occupation: 'Administrative Department Employee for the Church',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787647,
    OccDE: 'Verwaltungsangestellte/r in der Kommunalverwaltung',
    Occupation:
      'Administrative Department Employee in Municipal Administration',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787321,
    OccDE: 'Verwaltungsamtsinspektor/in',
    Occupation: 'Administrative Department Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787324,
    OccDE: 'Verwaltungsobersekretär/in',
    Occupation: 'Administrative Department Higher Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765314,
    OccDE: 'Verwaltungsinspektor/in (Arbeits-, Sozialverwaltung)',
    Occupation:
      'Administrative Department Higher Executive Officer (Employment, Social Administration)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787325,
    OccDE: 'Verwaltungspraktikant/in',
    Occupation: 'Administrative Department Intern',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787326,
    OccDE: 'Verwaltungssekretär/in',
    Occupation: 'Administrative Department Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765313,
    OccDE: 'Verwaltungsamts(rat/rätin) (Arbeits-, Sozialverwaltung)',
    Occupation:
      'Administrative Department Senior Deputy Director (Employment, Social Administration)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787323,
    OccDE: 'Verwaltungshauptsekretär/in',
    Occupation: 'Administrative Department Senior Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765316,
    OccDE: 'Verwaltungsoberinspektor/in (Arbeits-, Sozialverwaltung)',
    Occupation:
      'Administrative Department Senior Executive Officer (Employment, Social Administration)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787029,
    OccDE: 'Verwaltungsfachangestellte/r',
    Occupation: 'Administrative Department Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 784442,
    OccDE: 'Verwaltungsarbeiter/in',
    Occupation: 'Administrative Department Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751111,
    OccDE: 'Verwaltungsleiter/in (nicht öffentliche Verwaltung)',
    Occupation: 'Administrative Director (not Public Administration)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 765618,
    OccDE: 'Kreisoberinspektor/in',
    Occupation: 'Administrative District Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 765021,
    OccDE: 'Verwaltungsangestellte/r (gehobener Dienst)',
    Occupation: 'Administrative Employee (higher civil service)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 872115,
    OccDE: 'Oberstudien(rat/rätin) an Gymnasien',
    Occupation: 'Administrative Executive School Officer at secondary schools',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 765616,
    OccDE: 'Kreisinspektor/in',
    Occupation: 'Administrative Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787033,
    OccDE: 'Verwaltungsinspektor/in',
    Occupation: 'Administrative Higher Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 811166,
    OccDE: 'Verwaltungsrichter/in',
    Occupation: 'Administrative judge',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 813409,
    OccDE: 'Verwaltungsjurist/in',
    Occupation: 'Administrative Lawyer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 801498,
    OccDE: 'Verwaltungsbeamter (Bundespolizei)',
    Occupation: 'Administrative Officer (Federal Police)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801489,
    OccDE: 'Verwaltungsbeamter (Polizei)',
    Occupation: 'Administrative Officer (Police)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751110,
    OccDE: 'Verwalter/in',
    Occupation: 'Administrator',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 764005,
    OccDE: 'Administrator/in (Verwaltung)',
    Occupation: 'Administrator (Administrative department)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: '031101',
    OccDE: 'Administrator/in (Landwirtschaft)',
    Occupation: 'Administrator (Agriculture)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695508,
    OccDE: 'Innendienstsachbearbeiter/in (Versicherung)',
    Occupation: 'Administrator (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879606,
    OccDE: 'Erwachsenenbildner/in',
    Occupation: 'Adult Education Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879609,
    OccDE: 'Lehrer/in in der Erwachsenenbildung',
    Occupation: 'Adult Education Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703217,
    OccDE: 'Werbemittler/in',
    Occupation: 'Advertising Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703218,
    OccDE: 'Werbungsmittler/in',
    Occupation: 'Advertising Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172096,
    OccDE: 'Werbe- und Medienvorlagenhersteller/in',
    Occupation: 'Advertising and Media Print Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172096,
    OccDE: 'Werbe- und                           Medienvorlagenhersteller/in',
    Occupation: 'Advertising and Media Print Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703003,
    OccDE: 'Werbeassistent/in',
    Occupation: 'Advertising assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 623004,
    OccDE: 'Fachberater/in (Bau)',
    Occupation: 'Adviser (Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695410,
    OccDE: 'Fachberater/in (Versicherungswesen)',
    Occupation: 'Adviser (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726123,
    OccDE: 'Reklameflieger/in',
    Occupation: 'Aerial Advertisement Pilot',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876598,
    OccDE: 'Aerobictrainer/in',
    Occupation: 'Aerobics Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876599,
    OccDE: 'Aerobiclehrer/in',
    Occupation: 'Aerobics Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601313,
    OccDE: 'Luftfahrtingenieur/in',
    Occupation: 'Aeronautical Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 863106,
    OccDE: 'Hortleiter/in',
    Occupation: 'After-School Centre Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863105,
    OccDE: 'Horterzieher/in',
    Occupation: 'After-School Centre Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863107,
    OccDE: 'Hortner/in',
    Occupation: 'After-School Centre Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705307,
    OccDE: 'Agenturleiter/in',
    Occupation: 'Agency Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705305,
    OccDE: 'Agenturinhaber/in',
    Occupation: 'Agency Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705303,
    OccDE: 'Agent/in',
    Occupation: 'Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705344,
    OccDE: 'Vermittler/in',
    Occupation: 'Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 868110,
    OccDE: 'Vermittler/in (Arbeitsvermittlung)',
    Occupation: 'Agent (Employment Agency)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702404,
    OccDE: 'Fachkraft für Fluggastabfertigung',
    Occupation: 'Agent at Check-in Desk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '031118',
    OccDE: 'Landwirtschaftliche(r) Verwalter/in',
    Occupation: 'Agricultural Administrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011042',
    OccDE: 'Landwirtschaftslehrling',
    Occupation: 'Agricultural Apprentice',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013011',
    OccDE: 'Landwirtschaftliche Hilfe',
    Occupation: 'Agricultural Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013016',
    OccDE: 'Landwirtschaftsgehilf(e/in)',
    Occupation: 'Agricultural Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013733',
    OccDE: 'Landwirtschaftshilfe',
    Occupation: 'Agricultural Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883201,
    OccDE: 'Agrarbiolog(e/in)',
    Occupation: 'Agricultural Biologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 671240,
    OccDE: 'Landhandelskaufmann/-kauffrau',
    Occupation: 'Agricultural Commercial Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '032506',
    OccDE: 'Landwirtschaftliche(r) Berater/in',
    Occupation: 'Agricultural Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '032509',
    OccDE: 'Landwirtschaftsberater/in',
    Occupation: 'Agricultural Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '032508',
    OccDE: 'Landwirtschaftliche(r) Fachberater/in',
    Occupation: 'Agricultural Consultant Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013207',
    OccDE: 'Landwirtschaftliche(r) Lohnunternehmer/in',
    Occupation: 'Agricultural Contractor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011005',
    OccDE: 'Agrarökonom/in',
    Occupation: 'Agricultural Economist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '032307',
    OccDE: 'Landwirtschaftliche(r) Angestellte/r',
    Occupation: 'Agricultural Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '032001',
    OccDE: 'Agraringenieur/in',
    Occupation: 'Agricultural Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '032101',
    OccDE: 'Agraringenieur/in (administrativ/beratend tätig)',
    Occupation: 'Agricultural Engineer (Administration/Consulting Role)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '011004',
    OccDE:
      'Agraringenieur/in (landwirtschaftl. Betriebsinhaber/in oder -leiter/in)',
    Occupation:
      'Agricultural Engineer (Agricultural Land Owner or Farm Manager)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '032104',
    OccDE: 'Agraringenieur/in (Mechanisierung der Landwirtschaft)',
    Occupation: 'Agricultural Engineer (Agricultural Mechanization)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '032108',
    OccDE: 'Agraringenieur/in (Tierproduktion, Tierzucht)',
    Occupation: 'Agricultural Engineer (Animal Production, Breeding)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '032105',
    OccDE: 'Agraringenieur/in (Pflanzenproduktion)',
    Occupation: 'Agricultural Engineer (Crop Production)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '052101',
    OccDE: 'Agraringenieur/in (Gartenbau)',
    Occupation: 'Agricultural Engineer (Crop Production)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '032099',
    OccDE: 'Diplom-Agraringenieur/in',
    Occupation: 'Agricultural Engineer with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 883202,
    OccDE: 'Agraringenieurökonom/in',
    Occupation: 'Agricultural Engineering Economist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '013206',
    OccDE: 'Landmaschinenführer/in',
    Occupation: 'Agricultural Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921212,
    OccDE: 'Landwirtschaftliche(r) Hauswirtschafter/in',
    Occupation: 'Agricultural Housekeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 282107,
    OccDE: 'Landmaschinen- und Traktorenschlosser/in',
    Occupation: 'Agricultural Machinery and Tractor Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671241,
    OccDE: 'Landmaschinenhändler/in',
    Occupation: 'Agricultural Machinery Dealer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 282201,
    OccDE: 'Landmaschinenbauer/in',
    Occupation: 'Agricultural Machinery Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 282111,
    OccDE: 'Landmaschinenmechaniker/in',
    Occupation: 'Agricultural Machinery Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 282120,
    OccDE: 'Landmaschinentechniker/in',
    Occupation: 'Agricultural Machinery Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 282114,
    OccDE: 'Landmaschinenschlosser/in',
    Occupation: 'Agricultural Machinery Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '031119',
    OccDE: 'Maschinenringgeschäftsführer/in',
    Occupation: 'Agricultural Materials Cooperative Managing Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011041',
    OccDE: 'Landwirtschaftliche(r) Betriebshelfer/in',
    Occupation: 'Agricultural Operations Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981106,
    OccDE: 'Student/in Agrarwissenschaften',
    Occupation: 'Agricultural Science Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '032303',
    OccDE: 'Fachagrarwirt/in',
    Occupation: 'Agricultural Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013015',
    OccDE: 'Landwirtschaftsfachwerker/in',
    Occupation: 'Agricultural Technical Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '032301',
    OccDE: 'Agrartechniker/in',
    Occupation: 'Agricultural Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '032308',
    OccDE: 'Landwirtschaftstechniker/in',
    Occupation: 'Agricultural Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 631203,
    OccDE: 'Landwirtschaftlich-technische(r) Gehilf(e/in)',
    Occupation: 'Agricultural Technology Aid',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 631202,
    OccDE: 'Landwirtschaftlich-technische(r) Assistent/in',
    Occupation: 'Agricultural Technology Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 631204,
    OccDE: 'Landwirtschaftlich-technische(r) Laborant/in',
    Occupation: 'Agricultural Technology Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013012',
    OccDE: 'Landwirtschaftliche(r) Arbeiter/in',
    Occupation: 'Agricultural Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013731',
    OccDE: 'Landarbeiter/in',
    Occupation: 'Agricultural Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011040',
    OccDE: 'Landwirt/in',
    Occupation: 'Agriculturalist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011605',
    OccDE: 'Landwirt/in und Kaufmann/Kauffrau',
    Occupation: 'Agriculturalist and Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011801',
    OccDE: 'Landwirt/in und Winzer/in',
    Occupation: 'Agriculturalist and Winemaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '032002',
    OccDE: 'Diplom-Landwirt/in',
    Occupation: 'Agriculturalist with Degree',
    'Feather RC': 'B',
  },
  {
    OccupationCode: '011602',
    OccDE: 'Landwirt/in und Betriebskaufmann/-kauffrau',
    Occupation: 'Agriculturalist with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011015',
    OccDE:
      'Diplom-Landwirt/in (landwirtschaftl. Betriebsinhaber/in oder -leiter/in)',
    Occupation: 'Agriculturalist with Degree (Farmer or Farm Manager)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: '032119',
    OccDE: 'Landwirtschafts(rat/rätin)',
    Occupation: 'Agriculture (Director General)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: '032202',
    OccDE: 'Landwirtschaftsassistent/in',
    Occupation: 'Agriculture Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689903,
    OccDE: 'Landmaschinenvertreter/in',
    Occupation: 'Agriculture Equipment Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '032123',
    OccDE: 'Landwirtschaftsinspektor/in',
    Occupation: 'Agriculture Higher Executive Officer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: '032127',
    OccDE: 'Landwirtschaftsreferendar/in',
    Occupation: 'Agriculture Referendary',
    'Feather RC': 'B',
  },
  {
    OccupationCode: '032204',
    OccDE: 'Landwirtschaftsobersekretär/in',
    Occupation: 'Agriculture Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '032126',
    OccDE: 'Landwirtschaftsoberinspektor/in',
    Occupation: 'Agriculture Senior Executive Officer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: '011008',
    OccDE: 'Agrotechniker/in-Mechanisator/in',
    Occupation: 'Agritechnologist Machinery Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011007',
    OccDE: 'Agrotechniker/in-Mechanisator/in (Bodenbearbeitung)',
    Occupation: 'Agritechnologist Machinery Engineer (Soil Cultivation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883203,
    OccDE: 'Agrarwissenschaftler/in',
    Occupation: 'Agronomist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '011006',
    OccDE: 'Agronom/in (Landwirt/in)',
    Occupation: 'Agronomist (Farmer)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 631307,
    OccDE: 'Saatzuchttechniker/in',
    Occupation: 'Agronomist Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863702,
    OccDE: 'Helfer/in im freiwilligen sozialen Jahr (Erziehungshelfer/in)',
    Occupation: 'Aid worker during a voluntary year of social work',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869707,
    OccDE: 'Helfer/in im freiwilligen sozialen Jahr',
    Occupation: 'Aid worker during a voluntary year of social work',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854104,
    OccDE: 'Helfer/in im freiwilligen sozialen Jahr (Krankenpflegehelfer/in)',
    Occupation:
      'Aid worker during a voluntary year of social work (Nursing Assistant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 864002,
    OccDE: 'Altenhelfer/in',
    Occupation: 'Aid Worker for Seniors',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268201,
    OccDE: 'Klimaanlagenbauer/in',
    Occupation: 'Air Conditioning Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268205,
    OccDE: 'Klimaanlagenmonteur/in',
    Occupation: 'Air Conditioning Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268206,
    OccDE: 'Klimamonteur/in',
    Occupation: 'Air Conditioning Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268204,
    OccDE: 'Klimaanlagenmechaniker/in',
    Occupation: 'Air Conditioning Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621510,
    OccDE: 'Klimatechniker/in',
    Occupation: 'Air Conditioning Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701602,
    OccDE: 'Luftfrachtagent/in',
    Occupation: 'Air Freight Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701604,
    OccDE: 'Luftfrachtkaufmann/-kauffrau',
    Occupation: 'Air Freight Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602506,
    OccDE: 'Flugsicherungs-Meßingenieur/in',
    Occupation: 'Air Traffic Control Measuring Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 726602,
    OccDE: 'Flugsicherungstechniker/in',
    Occupation: 'Air Traffic Control Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726404,
    OccDE: 'Fluglots(e/in)',
    Occupation: 'Air Traffic Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726410,
    OccDE: 'Flugsicherungslots(e/in)',
    Occupation: 'Air Traffic Safety Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726405,
    OccDE: 'Flugsicherungsassistent/in',
    Occupation: 'Air traffic Safety Control Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726119,
    OccDE: 'Verkehrsflugzeugführer/in',
    Occupation: 'Air Transport Pilot',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 936901,
    OccDE: 'Flugzeugreiniger/in',
    Occupation: 'Aircraft Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601304,
    OccDE: 'Flugzeugbauingenieur/in',
    Occupation: 'Aircraft Construction Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 311209,
    OccDE: 'Flugzeugelektriker/in',
    Occupation: 'Aircraft Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311210,
    OccDE: 'Flugzeugelektroniker/in',
    Occupation: 'Aircraft Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316398,
    OccDE: 'Fluggeräteelektroniker/in',
    Occupation: 'Aircraft Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283303,
    OccDE: 'Flugtriebwerkmechaniker/in',
    Occupation: 'Aircraft Engine Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601306,
    OccDE: 'Flugzeugingenieur/in',
    Occupation: 'Aircraft Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 283003,
    OccDE: 'Flugzeugbaumechaniker/in',
    Occupation: 'Aircraft Engineering Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 686202,
    OccDE: 'Flugzeugtankwart/in',
    Occupation: 'Aircraft Filling Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512304,
    OccDE: 'Fahrzeuglackierer/in',
    Occupation: 'Aircraft Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512306,
    OccDE: 'Flugzeuglackierer/in',
    Occupation: 'Aircraft Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726518,
    OccDE: 'Flugzeugabfertiger/in',
    Occupation: 'Aircraft Ground Handler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283010,
    OccDE: 'Flugzeugwartungsmechaniker/in',
    Occupation: 'Aircraft Maintenance Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621204,
    OccDE: 'Flugzeuginstandhaltungstechniker/in',
    Occupation: 'Aircraft Maintenance Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283502,
    OccDE: 'Fluggerätbauer/in',
    Occupation: 'Aircraft Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283503,
    OccDE: 'Flugzeugbauer/in',
    Occupation: 'Aircraft Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283001,
    OccDE: 'Fluggerätemechaniker/in',
    Occupation: 'Aircraft Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283007,
    OccDE: 'Flugzeugmechaniker/in',
    Occupation: 'Aircraft Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311211,
    OccDE: 'Flugzeugmechaniker/in (Elektroausrüstung)',
    Occupation: 'Aircraft Mechanic (Electrical Systems)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283304,
    OccDE: 'Flugzeugmechaniker/in (Triebwerke)',
    Occupation: 'Aircraft Mechanic (Engines)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283005,
    OccDE: 'Flugzeugmechaniker/in (Geräteausrüstung)',
    Occupation: 'Aircraft Mechanic (Instrumentation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283006,
    OccDE: 'Flugzeugmechaniker/in (Instandhaltung)',
    Occupation: 'Aircraft Mechanic (Maintenance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283704,
    OccDE: 'Flugzeugmechanikerhelfer/in',
    Occupation: 'Aircraft Mechanic Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283599,
    OccDE:
      'Fluggerätemechaniker/in -            Fachrichtung Fertigungstechnik',
    Occupation: 'Aircraft Mechanic specialising in Manufacturing Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283008,
    OccDE: 'Flugzeugmonteur/in',
    Occupation: 'Aircraft Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621202,
    OccDE: 'Flugzeugbautechniker/in',
    Occupation: 'Aircraft Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621205,
    OccDE: 'Flugzeugtechniker/in',
    Occupation: 'Aircraft Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726506,
    OccDE: 'Flugbetriebsassistent/in',
    Occupation: 'Airport Administration Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791903,
    OccDE: 'Flughafenschutzbedienstete/r',
    Occupation: 'Airport Security Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791902,
    OccDE: 'Fluggastkontrolleur/in',
    Occupation: 'Airport Security Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744911,
    OccDE: 'Flughafenarbeiter/in',
    Occupation: 'Airport Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312902,
    OccDE: 'Alarmanlagenmonteur/in',
    Occupation: 'Alarm System Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841902,
    OccDE: 'Allergolog(e/in)',
    Occupation: 'Allergist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 351604,
    OccDE: 'Änderungsschneider/in',
    Occupation: 'Alterations Tailor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 352602,
    OccDE: 'Änderungsnäher/in',
    Occupation: 'Alternations Tailor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 851113,
    OccDE: 'Heilpraktiker/in',
    Occupation: 'Alternative Medicine Practitioner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201601,
    OccDE: 'Aluminiumgießer',
    Occupation: 'Aluminium Foundry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 265101,
    OccDE: 'Aluminiumfacharbeiter/in',
    Occupation: 'Aluminium Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 761501,
    OccDE: 'Botschafter/in',
    Occupation: 'Ambassador',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 714209,
    OccDE: 'Krankenfahrer/in',
    Occupation: 'Ambulance Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714210,
    OccDE: 'Krankenwagenfahrer/in',
    Occupation: 'Ambulance Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854299,
    OccDE: 'Rettungsfahrer/in',
    Occupation: 'Ambulance Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713515,
    OccDE: 'Krankentransportleiter/in',
    Occupation: 'Ambulance Transport Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853901,
    OccDE: 'Ambulanzpfleger',
    Occupation: 'Ambulatory Care Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853501,
    OccDE: 'Anästhesiepfleger',
    Occupation: 'Anaesthetic Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853502,
    OccDE: 'Anästhesieschwester',
    Occupation: 'Anaesthetic Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841903,
    OccDE: 'Anästhesist/in',
    Occupation: 'Anaesthetist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 882902,
    OccDE: 'Analyst/in',
    Occupation: 'Analyst',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 886601,
    OccDE: 'Analytische(r) Kinder- und Jugendlichenpsychotherapeut/in',
    Occupation: 'Analytical Psychotherapist for Children and Adolescents',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 857908,
    OccDE: 'Medizinische(r) Sektions- und Präparationsassistent/in',
    Occupation: 'Anatomical Pathology Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023019',
    OccDE: 'Tierzüchter/in',
    Occupation: 'Animal Breeder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023017',
    OccDE: 'Tierzuchtleiter/in',
    Occupation: 'Animal Breeding Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673917,
    OccDE: 'Tierhändler/in (Einzelhandel)',
    Occupation: 'Animal Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 431118,
    OccDE: 'Zootechniker/in-Mechanisator/in (Milchproduktion)',
    Occupation: 'Animal Equipment Technician (Milk Products)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023010',
    OccDE: 'Tierwirt/in',
    Occupation: 'Animal Farmer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023133',
    OccDE: 'Tierwirt/in (Rinderhaltung)',
    Occupation: 'Animal Farmer (Cattle)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023427',
    OccDE: 'Tierwirt/in (Geflügelhaltung)',
    Occupation: 'Animal Farmer (Hatchery)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '024006',
    OccDE: 'Tierpfleger/in',
    Occupation: 'Animal Keeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '024101',
    OccDE: 'Tierpfleger/in (Haus- und Versuchstierpflege)',
    Occupation: 'Animal Keeper (Pets and Laboratory Animals)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '024004',
    OccDE: 'Tierheimleiter/in',
    Occupation: 'Animal Shelter Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401108,
    OccDE: 'Kopfschlächter/in',
    Occupation: 'Animal Slaughterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 233308,
    OccDE: 'Glüher/in (Metallvergüter/in)',
    Occupation: 'Annealer (Quench Hardener)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315204,
    OccDE: 'Antennenmonteur/in',
    Occupation: 'Antenna Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315201,
    OccDE: 'Antennenbauer/in',
    Occupation: 'Antenna Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315202,
    OccDE: 'Antennenbautechniker/in',
    Occupation: 'Antenna Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 674301,
    OccDE: 'Antiquar/in',
    Occupation: 'Antiquarian',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673901,
    OccDE: 'Antiquitätenhändler/in (Einzelhandel)',
    Occupation: 'Antiques Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671203,
    OccDE: 'Antiquitätenhändler/in (Großhandel)',
    Occupation: 'Antiques Dealer (Wholesale)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673902,
    OccDE: 'Antiquitätensachverständige/r',
    Occupation: 'Antiques Expert',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 704537,
    OccDE: 'Wohnungsverwalter/in',
    Occupation: 'Apartment Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273001,
    OccDE: 'Apparate- und Maschinenschlosser/in',
    Occupation: 'Apparatus and Machinist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141708,
    OccDE: 'Apparatearbeiter/in (Chemiebetrieb)',
    Occupation: 'Apparatus Worker (Chemical Plant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 352104,
    OccDE: 'Bekleidungsteilenäher/in',
    Occupation: 'Apparel Component Sewer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606301,
    OccDE: 'Bekleidungsingenieur/in',
    Occupation: 'Apparel Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 352203,
    OccDE: 'Bekleidungsfertiger/in',
    Occupation: 'Apparel Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 352204,
    OccDE: 'Bekleidungsnäher/in',
    Occupation: 'Apparel Sewer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 352299,
    OccDE: 'Modenäher/in',
    Occupation: 'Apparel Sewer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 351107,
    OccDE: 'Kleidungsfacharbeiter/in',
    Occupation: 'Apparel Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 351099,
    OccDE: 'Modeschneider/in',
    Occupation: 'Apparel Tailor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 351101,
    OccDE: 'Bekleidungsschneider/in (Textil)',
    Occupation: 'Apparel Tailor (Textile)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981109,
    OccDE: 'Student/in Bekleidungstechnik',
    Occupation: 'Apparel Technology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 777102,
    OccDE: 'Anwendungssoftwareberater/in',
    Occupation: 'Application Software Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 777197,
    OccDE: 'Anwendungsberater/in',
    Occupation: 'Applications Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775103,
    OccDE: 'Anwendungsentwickler/in (EDV)',
    Occupation: 'Applications Developer (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 600902,
    OccDE: 'Applikationsingenieur/in',
    Occupation: 'Applications Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600901,
    OccDE: 'Anwendungsingenieur/in',
    Occupation: 'Applications Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 626101,
    OccDE: 'Anwendungstechniker/in (Chemie)',
    Occupation: 'Applications Engineer (Chemistry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 775105,
    OccDE: 'Applications Engineer (EDV)',
    Occupation: 'Applications Engineer (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 627901,
    OccDE: 'Anwendungstechniker/in (Fertigung)',
    Occupation: 'Applications Engineer (Production)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 776101,
    OccDE: 'Anwendungsorganisator/in (EDV)',
    Occupation: 'Applications Organiser (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775104,
    OccDE: 'Anwendungsprogrammierer/in',
    Occupation: 'Applications Programmer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775106,
    OccDE: 'Applikationsprogrammierer/in',
    Occupation: 'Applications Programmer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 774197,
    OccDE: 'Anwendungsbetreuer/in',
    Occupation: 'Applications service specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 620901,
    OccDE: 'Anwendungstechniker/in',
    Occupation: 'Applications Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 620902,
    OccDE: 'Applikationstechniker/in',
    Occupation: 'Applications Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621001,
    OccDE: 'Anwendungstechniker/in (Maschinenbau)',
    Occupation: 'Applications Technician (Mechanical Engineering)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 600804,
    OccDE: 'Gutachteningenieur/in',
    Occupation: 'Appraisal Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 608215,
    OccDE: 'Gutachter/in (technische/r)',
    Occupation: 'Appraiser (Technical)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 981102,
    OccDE: 'Auszubildende/r',
    Occupation: 'Apprentice',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981199,
    OccDE: 'Azubi',
    Occupation: 'Apprentice',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 652002,
    OccDE: 'Lehrlingsausbilder/in',
    Occupation: 'Apprentice Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 652104,
    OccDE: 'Lehrlingsausbilder/in (gewerblich-technisch)',
    Occupation: 'Apprentice Trainer (Commercial/Technical)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879514,
    OccDE: 'Lehrlingsausbilder/in (nicht gewerblich-technisch)',
    Occupation: 'Apprentice Trainer (not Commercial/Technical)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011505',
    OccDE: 'Baumpfleger/in',
    Occupation: 'Arborist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792804,
    OccDE: 'Spielhallenaufsicht',
    Occupation: 'Arcade Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882504,
    OccDE: 'Altertumsforscher/in',
    Occupation: 'Archaeologist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882505,
    OccDE: 'Archäolog(e/in)',
    Occupation: 'Archaeologist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 609001,
    OccDE: 'Architekt/in',
    Occupation: 'Architect',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 609901,
    OccDE: 'Architekt/in für Bauinformatik',
    Occupation: 'Architect for Civil Engineering and Computer Science',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 609101,
    OccDE: 'Architekt/in für Objektplanung und Entwurf',
    Occupation: 'Architect for Project Planning and Design',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 609301,
    OccDE: 'Architekt/in für Städtebau',
    Occupation: 'Architect for Urban Development',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 642001,
    OccDE: 'Architekturzeichner/in',
    Occupation: 'Architectural Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 502802,
    OccDE: 'Architekturmodellbauer/in',
    Occupation: 'Architectural Model Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981107,
    OccDE: 'Student/in Architektur',
    Occupation: 'Architecture Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 823403,
    OccDE: 'Archivassistent/in',
    Occupation: 'Archive Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 823402,
    OccDE: 'Archivangestellte/r',
    Occupation: 'Archive Employee',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823309,
    OccDE: 'Archivinspektoranwärter/in',
    Occupation: 'Archive Inspector Referendary',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823305,
    OccDE: 'Archivar/in',
    Occupation: 'Archivist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823399,
    OccDE: 'Diplom-Archivar/in',
    Occupation: 'Archivist with Degree',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 751014,
    OccDE: 'Sachbereichsleiter/in',
    Occupation: 'Area Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 765020,
    OccDE: 'Sachgebietsleiter/in (öffentliche Verwaltung)',
    Occupation: 'Area Director (Public Administration)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751015,
    OccDE: 'Sachgebietsleiter/in',
    Occupation: 'Area Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751306,
    OccDE: 'Gebietsleiter/in',
    Occupation: 'Area Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751307,
    OccDE: 'Gebietsverkaufsleiter/in',
    Occupation: 'Area Sales Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751321,
    OccDE: 'Vertriebsgebietsleiter/in',
    Occupation: 'Area Sales Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997188,
    OccDE: 'Bundeswehr',
    Occupation: 'Armed Forces',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801199,
    OccDE: 'Bundeswehrangehöriger',
    Occupation: 'Armed Forces Member',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744908,
    OccDE: 'Bundeswehrarbeiter/in',
    Occupation: 'Armed Forces Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744299,
    OccDE: 'Geld- und Wertpapiertransporteur',
    Occupation: 'Armoured Vehicle Transport Provider',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300315,
    OccDE: 'Waffenmechaniker/in',
    Occupation: 'Armourer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673913,
    OccDE: 'Kunsthändler/in (Einzelhandel)',
    Occupation: 'Art Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703502,
    OccDE: 'Art-Direktor/in',
    Occupation: 'Art Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882405,
    OccDE: 'Kunsthistoriker/in',
    Occupation: 'Art Historian',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 981136,
    OccDE: 'Student/in Kunstwissenschaften',
    Occupation: 'Art History Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875109,
    OccDE: 'Kunsterzieher/in',
    Occupation: 'Art Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 859504,
    OccDE: 'Kunsttherapeut/in',
    Occupation: 'Art Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 859599,
    OccDE: 'Diplom-Kunsttherapeut/in',
    Occupation: 'Art Therapist with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 309804,
    OccDE: 'Kunsthandwerker/in',
    Occupation: 'Artisan',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 833001,
    OccDE: 'Künstler/in',
    Occupation: 'Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705210,
    OccDE: 'Künstlervermittler/in (nicht beim Arbeitamt)',
    Occupation: 'Artist Agent (not Employment Office)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981189,
    OccDE: 'Student/in Kunst (auch darstellende, bildende)',
    Occupation: 'Arts (Performing, Visual)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764109,
    OccDE: 'Kulturreferent/in',
    Occupation: 'Arts and Culture Referendary',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 482602,
    OccDE: 'Asphaltbauer',
    Occupation: 'Asphalt Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461303,
    OccDE: 'Asphaltierer',
    Occupation: 'Asphalt Layer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '080602',
    OccDE: 'Asphaltmüller',
    Occupation: 'Asphalt Miller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501414,
    OccDE: 'Montageschreiner/in',
    Occupation: 'Assembly Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501415,
    OccDE: 'Montagetischler/in',
    Occupation: 'Assembly Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521223,
    OccDE: 'Montageprüfer/in',
    Occupation: 'Assembly Checker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651023,
    OccDE: 'Montagemeister/in',
    Occupation: 'Assembly Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651022,
    OccDE: 'Montageinspektor/in',
    Occupation: 'Assembly Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549304,
    OccDE: 'Fließbandmaschinist/in',
    Occupation: 'Assembly Line Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 742206,
    OccDE: 'Fließbandbediener/in',
    Occupation: 'Assembly Line Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531402,
    OccDE: 'Fließbandarbeiter/in',
    Occupation: 'Assembly Line Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269017,
    OccDE: 'Montageleiter/in',
    Occupation: 'Assembly Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505016,
    OccDE: 'Montagefacharbeiter/in (Holzverarbeitung)',
    Occupation: 'Assembly Specialist (Wood Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 322102,
    OccDE: 'Montageaufsicht',
    Occupation: 'Assembly Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321949,
    OccDE: 'Montagebetreuer/in (Elektromontage)',
    Occupation: 'Assembly Supervisor (Electronics Assembly)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621809,
    OccDE: 'Montagetechniker/in (Maschinenbau)',
    Occupation: 'Assembly Technician (Mechanical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 322103,
    OccDE: 'Montierer/in',
    Occupation: 'Assembly Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 322999,
    OccDE: 'Montagewerker/in',
    Occupation: 'Assembly Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269016,
    OccDE: 'Montagearbeiter/in',
    Occupation: 'Assembly Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321913,
    OccDE: 'Bestücker/in (Elektromontage)',
    Occupation: 'Assembly Worker (Electronics Assembly)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321950,
    OccDE: 'Montierer/in (Elektromontage)',
    Occupation: 'Assembly Worker (Electronics Assembly)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 322901,
    OccDE: 'Bestücker/in (an Montagebändern)',
    Occupation: 'Assembly Worker (on Assembly Lines)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 813401,
    OccDE: 'Assessor/in (Wirtschaftsjurist/in)',
    Occupation: 'Assessor (Legal Graduate before last stage of qualification)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 704904,
    OccDE: 'Vermögensverwalter/in',
    Occupation: 'Asset Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780602,
    OccDE: 'Assistent/in',
    Occupation: 'Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531908,
    OccDE: 'Fachhelfer/in',
    Occupation: 'Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751801,
    OccDE: 'Assistent/in (betriebswirtschaftliche/r)',
    Occupation: 'Assistant (Business)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915202,
    OccDE: 'Helfer/in im Hotel- und Gaststättengewerbe',
    Occupation: 'Assistant (Hotels and Restaurants)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744003,
    OccDE: 'Hilfsarbeiter/in (Lager, Transport)',
    Occupation: 'Assistant (Storage, Transport)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391011,
    OccDE: 'Bäckergehilf(e/in)',
    Occupation: 'Assistant Baker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837301,
    OccDE: 'Assistenz-Kameramann/Kamerafrau',
    Occupation: 'Assistant Cameraman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869701,
    OccDE: 'Betreuungshelfer/in',
    Occupation: 'Assistant Care Giver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778101,
    OccDE: 'Assistant Computer Operator',
    Occupation: 'Assistant Computer Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 255703,
    OccDE: 'Konstruktionsmechanikerhelfer/in (Metall- und Schiffbautechnik)',
    Occupation:
      'Assistant Construction Mechanic (specialising in Metal and Shipbuilding Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756201,
    OccDE: 'Assistant Controller',
    Occupation: 'Assistant Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411017,
    OccDE: 'Hilfskoch/-köchin',
    Occupation: 'Assistant Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411602,
    OccDE: 'Beikoch/-köchin',
    Occupation: 'Assistant Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531909,
    OccDE: 'Handwerkerhelfer/in',
    Occupation: 'Assistant Craftsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832211,
    OccDE: 'Regieassistent/in',
    Occupation: 'Assistant Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802311,
    OccDE: 'Oberbrandmeister/in',
    Occupation: 'Assistant Divisional Officer (Fire Service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620001,
    OccDE: 'Ingenieurassistent/in',
    Occupation: 'Assistant engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 269010,
    OccDE: 'Hilfsmonteur/in',
    Occupation: 'Assistant Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622302,
    OccDE: 'Assistent/in für Automatisierungs- und Computertechnik',
    Occupation: 'Assistant for Automation and Computer Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622303,
    OccDE: 'Assistent/in für Computertechnik',
    Occupation: 'Assistant for Computer Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774403,
    OccDE: 'Assistent/in für Informatik',
    Occupation: 'Assistant for Information Technology',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775002,
    OccDE: 'Assistent/in für Programmierung',
    Occupation: 'Assistant for Programming',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 914501,
    OccDE: 'Assistant Front Office Manager',
    Occupation: 'Assistant Front Office Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 267701,
    OccDE: 'Gas- und Wasserinstallateurhelfer/in',
    Occupation: 'Assistant Gas and Water Works Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914203,
    OccDE: 'Hoteldirektionsassistent/in',
    Occupation: 'Assistant Hotel Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751802,
    OccDE: 'Assistent/in der Geschäftsleitung',
    Occupation: 'Assistant in Management',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915201,
    OccDE: 'Helfer/in im Gastgewerbe',
    Occupation: 'Assistant in the Hospitality Industry',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 823202,
    OccDE: 'Bibliothekarassistent/in',
    Occupation: 'Assistant Librarian',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 914201,
    OccDE: 'Assistant Manager (Hotel)',
    Occupation: 'Assistant Manager (Hotel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621802,
    OccDE: 'Betriebsleiterassistent/in (Metallverarbeitung)',
    Occupation: 'Assistant Manager (Metalworking)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 270702,
    OccDE: 'Mechanikerhelfer/in',
    Occupation: 'Assistant Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 844101,
    OccDE: 'Apothekenassistent/in',
    Occupation: 'Assistant Pharmacist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 844107,
    OccDE: 'Apothekerassistent/in',
    Occupation: 'Assistant Pharmacist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 755101,
    OccDE: 'Assistant Product Manager',
    Occupation: 'Assistant Product Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 835224,
    OccDE: 'Produktionsleiterassistent/in',
    Occupation: 'Assistant Production Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914506,
    OccDE: 'Empfangsassistent/in (Hotel)',
    Occupation: 'Assistant Receptionist (Hotel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787100,
    OccDE: 'Assistentenanwärter/in',
    Occupation: 'Assistant Referendary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869710,
    OccDE: 'Sozialhelfer/in',
    Occupation: 'Assistant Social Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531911,
    OccDE: 'Holzverarbeitungshilfsarbeiter/in',
    Occupation: 'Assistant Woodworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 753109,
    OccDE: 'Verbandsprüfer/in',
    Occupation: 'Association Auditor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 763108,
    OccDE: 'Verbandsvorsitzende/r',
    Occupation: 'Association Chairman',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 763107,
    OccDE: 'Verbandsleiter/in',
    Occupation: 'Association Director',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 763106,
    OccDE: 'Verbandsgeschäftsführer/in',
    Occupation: 'Association Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 601316,
    OccDE: 'Raumfahrtingenieur/in',
    Occupation: 'Astronautical Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 621208,
    OccDE: 'Raumfahrttechniker/in',
    Occupation: 'Astronautics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 612601,
    OccDE: 'Astronom/in',
    Occupation: 'Astronomer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 612602,
    OccDE: 'Astrophysiker/in',
    Occupation: 'Astrophysicist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 838499,
    OccDE: 'Sportler/in',
    Occupation: 'Athletes',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764101,
    OccDE: 'Attache',
    Occupation: 'Attaché',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 813101,
    OccDE: 'Anwalt/Anwältin',
    Occupation: 'Attorney',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 813105,
    OccDE: 'Rechtsanwalt/Rechtsanwältin',
    Occupation: 'Attorney',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 786101,
    OccDE: 'Anwalts- und Notargehilf(e/in)',
    Occupation: 'Attorney and Notary Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 786105,
    OccDE: 'Rechtsanwalts- und Notargehilf(e/in)',
    Occupation: 'Attorney and Notary Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 813302,
    OccDE: 'Rechtsanwalt/Rechtsanwältin und Notar/in',
    Occupation: 'Attorney and Notary Public',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 786198,
    OccDE: 'Rechtsanwalts- und                   Notarfachangestellte/r',
    Occupation: 'Attorney and Notary Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 786405,
    OccDE: 'Rechtsanwalts- und Patentanwaltsgehilf(e/in)',
    Occupation: 'Attorney and Patent Attorney Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 786202,
    OccDE: 'Anwaltsgehilf(e/in)',
    Occupation: 'Attorney Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 923703,
    OccDE: 'Au-pair-mädchen',
    Occupation: 'Au Pair',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705802,
    OccDE: 'Auktionator/in',
    Occupation: 'Auctioneer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315302,
    OccDE: 'Gehörgeräteakustiker/in',
    Occupation: 'Audiologist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315303,
    OccDE: 'Hörakustiker/in',
    Occupation: 'Audiologist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315304,
    OccDE: 'Hörgeräteakustiker/in',
    Occupation: 'Audiologist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 754113,
    OccDE: 'Wirtschaftsprüfungsassistent/in',
    Occupation: 'Audit Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 754108,
    OccDE: 'Revisionsassistent/in (Wirtschaftsprüfung)',
    Occupation: 'Audit Assistant (Auditing)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 754106,
    OccDE: 'Prüfungsassistent/in (bei Wirtschaftsprüfern/-prüferinnen)',
    Occupation: 'Audit Assistant (for Auditors)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 788936,
    OccDE: 'Revisionsangestellte(r)',
    Occupation: 'Audit Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 753110,
    OccDE: 'Wirtschaftsprüfer/in',
    Occupation: 'Auditor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 753503,
    OccDE: 'Betriebsprüfer/in',
    Occupation: 'Auditor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 753102,
    OccDE: 'Buchprüfer/in (vereidigte/r)',
    Occupation: 'Auditor (Chartered)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 821101,
    OccDE: 'Autor/in',
    Occupation: 'Author',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750220,
    OccDE: 'Prokurist/in',
    Occupation: 'Authorised Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281002,
    OccDE: 'Automechaniker/in',
    Occupation: 'Auto Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281004,
    OccDE: 'Autoreparaturschlosser/in',
    Occupation: 'Auto Repair Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287216,
    OccDE: 'Karosserieschlosser/in',
    Occupation: 'Autobody Repair Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287218,
    OccDE: 'Karosseriewerker/in',
    Occupation: 'Autobody Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300403,
    OccDE: 'Automatenmechaniker/in',
    Occupation: 'Automated Machine Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321908,
    OccDE: 'Automatenbestücker/in (Mikroelektronik)',
    Occupation: 'Automated Machinery Assembly Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705904,
    OccDE: 'Automatenkaufmann/-kauffrau',
    Occupation: 'Automated Machinery Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323101,
    OccDE: 'Automatenbediener/in (Metallverarbeitung)',
    Occupation: 'Automated Machinery Operator (Metalworking)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522306,
    OccDE: 'Automatenbetreuer/in',
    Occupation: 'Automated Machinery Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 278201,
    OccDE: 'Automatenmonteur/in',
    Occupation: 'Automatic Machine Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621903,
    OccDE: 'Automatentechniker/in',
    Occupation: 'Automatic Machine Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522409,
    OccDE: 'Automatenfüller/in',
    Occupation: 'Automatic Machinery Filler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550201,
    OccDE: 'Automateneinrichter/in',
    Occupation: 'Automatic Machinery Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550202,
    OccDE: 'Automateneinsteller/in',
    Occupation: 'Automatic Machinery Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602605,
    OccDE: 'Automatisierungsingenieur/in',
    Occupation: 'Automation Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 622902,
    OccDE: 'Automatisierungstechniker/in',
    Occupation: 'Automation Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 622901,
    OccDE: 'Automationstechniker/in (Elektrotechnik)',
    Occupation: 'Automation Technician (Electrical Engineering)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 608299,
    OccDE: 'KFZ-Gutachter/in',
    Occupation: 'Automotive Appraiser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281097,
    OccDE: 'Automobilmonteur/in',
    Occupation: 'Automotive Assemblyman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673502,
    OccDE: 'Autokaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Automotive Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673503,
    OccDE: 'Automobilkaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Automotive Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673506,
    OccDE: 'Kfz-Kaufmann/-Kauffrau (Einzelhandel)',
    Occupation: 'Automotive Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608226,
    OccDE: 'Kfz-Vertragssachverständige/r',
    Occupation: 'Automotive Contract Expert',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834102,
    OccDE: 'Automobildesigner/in',
    Occupation: 'Automotive Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287198,
    OccDE: 'Automobilaufbereiter/in',
    Occupation: 'Automotive Detailer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 936106,
    OccDE: 'Wagenpfleger/in',
    Occupation: 'Automotive Detailer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714005,
    OccDE: 'Kfz-Fahrer/in',
    Occupation: 'Automotive Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 318101,
    OccDE: 'Autoelektriker/in',
    Occupation: 'Automotive Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 318103,
    OccDE: 'Autoelektromechaniker/in',
    Occupation: 'Automotive Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601108,
    OccDE: 'Kraftfahrzeugbauingenieur/in',
    Occupation: 'Automotive Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601109,
    OccDE: 'Kraftfahrzeugingenieur/in',
    Occupation: 'Automotive Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 608212,
    OccDE: 'Fahrzeugsachverständige/r',
    Occupation: 'Automotive Expert',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608225,
    OccDE: 'Kfz-Sachverständige/r',
    Occupation: 'Automotive Expert',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281003,
    OccDE: 'Automobilmechaniker/in',
    Occupation: 'Automotive Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281703,
    OccDE: 'Kraftfahrzeugmechanikerhelfer/in',
    Occupation: 'Automotive Mechanic Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834401,
    OccDE: 'Automobilmodelleur/in',
    Occupation: 'Automotive Model Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713513,
    OccDE: 'Kraftfahrzeugbetriebsleiter/in',
    Occupation: 'Automotive Operations Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287217,
    OccDE: 'Karosseriespengler/in',
    Occupation: 'Automotive Panel Beater',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287222,
    OccDE: 'Kraftfahrzeugspengler/in',
    Occupation: 'Automotive Panel Beater',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512303,
    OccDE: 'Autoreparaturlackierer/in',
    Occupation: 'Automotive Repair Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281018,
    OccDE: 'Kraftfahrzeugreparateur/in',
    Occupation: 'Automotive Repair Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671206,
    OccDE: 'Automobilkaufmann/-kauffrau (Großhandel)',
    Occupation: 'Automotive Salesperson (Wholesale)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 281102,
    OccDE: 'Autoservicemann/-servicefrau',
    Occupation: 'Automotive Servicer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281001,
    OccDE: 'Autofachwerker/in',
    Occupation: 'Automotive Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281005,
    OccDE: 'Autoschlosser/in',
    Occupation: 'Automotive Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281012,
    OccDE: 'Kraftfahrzeugfacharbeiter/in',
    Occupation: 'Automotive Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281019,
    OccDE: 'Kraftfahrzeugschlosser/in',
    Occupation: 'Automotive Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981118,
    OccDE: 'Student/in Fahrzeugtechnik',
    Occupation: 'Automotive Technology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608224,
    OccDE: 'Kfz-Prüfungsingenieur/in',
    Occupation: 'Automotive Testing Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 281011,
    OccDE: 'Kraftfahrzeugarbeiter/in',
    Occupation: 'Automotive Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281013,
    OccDE: 'Kraftfahrzeughandwerker/in',
    Occupation: 'Automotive Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981141,
    OccDE: 'Student/in Luft- und Raumfahrtechnik',
    Occupation: 'Aviation and Aerospace Technology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701601,
    OccDE: 'Luftfahrtkaufmann/-kauffrau',
    Occupation: 'Aviation Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701607,
    OccDE: 'Luftverkehrskaufmann/-kauffrau',
    Occupation: 'Aviation Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701605,
    OccDE: 'Luftverkehrsangestellte/r',
    Occupation: 'Aviation Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621407,
    OccDE: 'Luftfahrtgeräteprüfer/in',
    Occupation: 'Aviation Equipment Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621207,
    OccDE: 'Luftfahrttechniker/in',
    Occupation: 'Aviation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 259111,
    OccDE: 'Markisenmonteur/in',
    Occupation: 'Awning Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 259108,
    OccDE: 'Markisenbauer/in',
    Occupation: 'Awning Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 880099,
    OccDE: 'Bachelor of Arts',
    Occupation: 'Bachelor of Arts',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788919,
    OccDE: 'Innendienstsachbearbeiter/in (nicht Versicherung)',
    Occupation: 'Back Office Clerk (not Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788998,
    OccDE: 'Mitarbeiter/in im Innendienst',
    Occupation: 'Back Office Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744319,
    OccDE: 'Gepäckarbeiter/in',
    Occupation: 'Baggage Handlers',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814103,
    OccDE: 'Gerichtsvollzieher/in',
    Occupation: 'Bailiff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391009,
    OccDE: 'Bäcker/in',
    Occupation: 'Baker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 392102,
    OccDE: 'Bäcker/in und Konditor/in',
    Occupation: 'Baker and Confectioner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391012,
    OccDE: 'Bäckergesell(e/in)',
    Occupation: "Baker's Assistant",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391702,
    OccDE: 'Bäckerhelfer/in',
    Occupation: "Baker's Helper",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391008,
    OccDE: 'Backwarenhersteller/in',
    Occupation: 'Bakery Goods Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391804,
    OccDE: 'Bäckereimaschinenführer/in',
    Occupation: 'Bakery Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391004,
    OccDE: 'Backstubenleiter/in',
    Occupation: 'Bakery Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661201,
    OccDE: 'Bäckerei(fach)verkäufer/in',
    Occupation: 'Bakery Salesperson (Consultant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391006,
    OccDE: 'Backwarenfacharbeiter/in',
    Occupation: 'Bakery Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391005,
    OccDE: 'Backwarenfacharbeiter/in (Bäcker/in)',
    Occupation: 'Bakery Specialist (Baker)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627101,
    OccDE: 'Bäckereitechniker/in',
    Occupation: 'Bakery Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391014,
    OccDE: 'Bäckerwerker/in',
    Occupation: 'Bakery Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391802,
    OccDE: 'Backmaschinenführer/in',
    Occupation: 'Baking Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832402,
    OccDE: 'Ballettänzer/in',
    Occupation: 'Ballet Dancer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876401,
    OccDE: 'Ballettlehrer/in',
    Occupation: 'Ballet Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691010,
    OccDE: 'Banklehrling',
    Occupation: 'Bank Apprentice',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691501,
    OccDE: 'Bankrevisor/in',
    Occupation: 'Bank Auditor',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 691615,
    OccDE: 'Bankprokurist/in',
    Occupation: 'Bank Authorised Representative',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 773202,
    OccDE: 'Bankkassierer/in',
    Occupation: 'Bank Cashier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691002,
    OccDE: 'Bankangestellte/r',
    Occupation: 'Bank Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691609,
    OccDE: 'Bankinspektor/in',
    Occupation: 'Bank Inspector',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 691607,
    OccDE: 'Bankdirektor/in',
    Occupation: 'Bank Manager',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 691004,
    OccDE: 'Bankbeamt(er/in)',
    Occupation: 'Bank Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691608,
    OccDE: 'Bankier',
    Occupation: 'Banker',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 691003,
    OccDE: 'Bankassistent/in',
    Occupation: 'Banking Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691616,
    OccDE: 'Bankvorstand',
    Occupation: 'Banking Board Member',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 691602,
    OccDE: 'Bankabteilungsdirektor/in',
    Occupation: 'Banking Department Manager',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 691605,
    OccDE: 'Bankbereichsleiter/in',
    Occupation: 'Banking Division Manager',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 813402,
    OccDE: 'Bankjurist/in',
    Occupation: 'Banking Lawyer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 691005,
    OccDE: 'Bankbetriebswirt/in',
    Occupation: 'Banking Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 691006,
    OccDE: 'Bankfachmann/-fachfrau',
    Occupation: 'Banking Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914901,
    OccDE: 'Bankettassistent/in',
    Occupation: 'Banquet Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914903,
    OccDE: 'Bankettleiter/in',
    Occupation: 'Banquet Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914905,
    OccDE: 'Bankettmanager',
    Occupation: 'Banquet Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901003,
    OccDE: 'Coiffeur/Coiffeuse',
    Occupation: 'Barber/Hairdresser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 796214,
    OccDE: 'Kasernenwärter/in',
    Occupation: 'Barracks Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915404,
    OccDE: 'Barkeeper',
    Occupation: 'Bartender',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915407,
    OccDE: 'Barmixer/in',
    Occupation: 'Bartender',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 185819,
    OccDE: 'Korbmacher/in',
    Occupation: 'Basket Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 185817,
    OccDE: 'Korbflechter/in',
    Occupation: 'Basket Weaver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831430,
    OccDE: 'Kontrabassist/in',
    Occupation: 'Bassist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831411,
    OccDE: 'Fagottist/in',
    Occupation: 'Bassoonist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321911,
    OccDE: 'Batteriehersteller/in',
    Occupation: 'Battery Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 894202,
    OccDE: 'Kirchendiener/in',
    Occupation: 'Beadle',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902011,
    OccDE: 'Schönheitspfleger/in',
    Occupation: 'Beautician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902501,
    OccDE: 'Kosmetikberater/in',
    Occupation: 'Beauty Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902502,
    OccDE: 'Kosmetikfachberater/in',
    Occupation: 'Beauty Consultant Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023507',
    OccDE: 'Imker/in',
    Occupation: 'Beekeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522418,
    OccDE: 'Bierabfüller/in',
    Occupation: 'Beer Bottler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 421201,
    OccDE: 'Bierbrauer/in',
    Occupation: 'Beer Brewer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714406,
    OccDE: 'Bierfahrer/in',
    Occupation: 'Beer Delivery Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 872127,
    OccDE: 'Studienreferendar/in',
    Occupation: 'Beginning Secondary School Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879527,
    OccDE: 'Verhaltenstrainer/in',
    Occupation: 'Behaviour Coach',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201613,
    OccDE: 'Glockengießer',
    Occupation: 'Bell Foundry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915904,
    OccDE: 'Gästebetreuer/in',
    Occupation: 'Bellman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695515,
    OccDE: 'Leistungssachbearbeiter/in (nicht Sozialversicherung)',
    Occupation: 'Benefits Administrator (not Social Security)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787310,
    OccDE: 'Leistungssachbearbeiter/in (Sozialversicherung)',
    Occupation: 'Benefits Administrator (Social Security)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673101,
    OccDE: 'Getränkehändler/in (Einzelhandel)',
    Occupation: 'Beverage Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671224,
    OccDE: 'Getränkehändler/in (Großhandel)',
    Occupation: 'Beverage Dealer (Wholesale)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714412,
    OccDE: 'Getränkefahrer/in',
    Occupation: 'Beverage Deliver Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 266201,
    OccDE: 'Getränkeanlagenmonteur/in',
    Occupation: 'Beverage Equipment Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 423005,
    OccDE: 'Getränkehersteller/in',
    Occupation: 'Beverage Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 423004,
    OccDE: 'Getränkefachkraft',
    Occupation: 'Beverage Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627105,
    OccDE: 'Getränketechniker/in',
    Occupation: 'Beverage Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 423702,
    OccDE: 'Getränkearbeiter/in',
    Occupation: 'Beverage Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673308,
    OccDE: 'Fahrradhändler',
    Occupation: 'Bicycle Dealer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281302,
    OccDE: 'Fahrradmechaniker/in',
    Occupation: 'Bicycle Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281311,
    OccDE: 'Zweiradmechaniker/in',
    Occupation: 'Bicycle Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281399,
    OccDE: 'Fahrradmonteur/in',
    Occupation: 'Bicycle Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744925,
    OccDE: 'Plakatier(er/in) (Afficheur/in)',
    Occupation: 'Bill Poster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744926,
    OccDE: 'Plakatkleber/in',
    Occupation: 'Bill Poster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935719,
    OccDE: 'Müllmann/Müllfrau',
    Occupation: 'Bin Man',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883402,
    OccDE: 'Biogeograph/in',
    Occupation: 'Bio-geographer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 611101,
    OccDE: 'Biochemiker/in',
    Occupation: 'Biochemist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 981112,
    OccDE: 'Student/in Biochemie',
    Occupation: 'Biochemistry Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608302,
    OccDE: 'Bioingenieur/in',
    Occupation: 'Bioengineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 883105,
    OccDE: 'Biolog(e/in)',
    Occupation: 'Biologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 883199,
    OccDE: 'Diplom-Biolog(e/in)',
    Occupation: 'Biologist with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 631002,
    OccDE: 'Biologisch-chemische(r) Assistent/in',
    Occupation: 'Biology Chemistry Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 631102,
    OccDE: 'Biologisch-chemische(r) Laborant/in',
    Occupation: 'Biology Chemistry Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 631001,
    OccDE: 'Biologisch-chemisch-technische(r) Assistent/in',
    Occupation: 'Biology Chemistry Technical Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 631101,
    OccDE: 'Biologielaborant/in',
    Occupation: 'Biology Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 631199,
    OccDE: 'Biolaborant/in',
    Occupation: 'Biology Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981113,
    OccDE: 'Student/in Biologie',
    Occupation: 'Biology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 631003,
    OccDE: 'Biologisch-technische(r) Assistent/in',
    Occupation: 'Biology Technical Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841942,
    OccDE: 'Biomediziner/in',
    Occupation: 'Biomedical Physician',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 887103,
    OccDE: 'Biometriker/in',
    Occupation: 'Biostatistician',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 631004,
    OccDE: 'Biotechniker/in',
    Occupation: 'Biotechnician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 611805,
    OccDE: 'Biotechnologe(e/in)',
    Occupation: 'Biotechnologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 981114,
    OccDE: 'Student/in Biotechnologie, Bioingenieurswesen',
    Occupation: 'Biotechnology, Bioengineering Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 256102,
    OccDE: 'Kunstschmied/in',
    Occupation: 'Blacksmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 256203,
    OccDE: 'Kunstschlosser/in',
    Occupation: 'Blacksmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191315,
    OccDE: 'Hochofenarbeiter/in',
    Occupation: 'Blast Furnace Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201832,
    OccDE: 'Strahler',
    Occupation: 'Blaster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803814,
    OccDE: 'Sprengtaucher',
    Occupation: 'Blasting Diver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780801,
    OccDE: 'Blinde Bürokraft',
    Occupation: 'Blind Office Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712103,
    OccDE: 'Blockwärter/in (Eisenbahnbetrieb)',
    Occupation: 'Block Leader (Railway Services)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695118,
    OccDE: 'Vorstandsmitglied (Versicherung)',
    Occupation: 'Board Member (Insurance)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 695117,
    OccDE: 'Vorstandsassistent/in (Versicherung)',
    Occupation: 'Board Member Assistant (Insurance)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 863308,
    OccDE: 'Internatsleiter/in',
    Occupation: 'Boarding School Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705108,
    OccDE: 'Bootsvermieter/in',
    Occupation: 'Boat Hire Provider',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 506304,
    OccDE: 'Bootsbauer/in',
    Occupation: 'Boatmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 506302,
    OccDE: 'Bootbauer/in',
    Occupation: 'Boatmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 724001,
    OccDE: 'Binnenschiffer/in',
    Occupation: 'Boatmen',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287001,
    OccDE: 'Karosserie- und Fahrzeugbauer/in',
    Occupation: 'Body and Automotive Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287206,
    OccDE: 'Karosserie- und Fahrzeugbauer/in (Fachrichtung Karosseriebau)',
    Occupation:
      'Body and Automotive Builder (specialising in Body Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287104,
    OccDE: 'Karosserie- und Fahrzeugbauer/in (Fachrichtung Fahrzeugbau)',
    Occupation:
      'Body and Automotive Builder (specialising in Vehicle Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287208,
    OccDE: 'Karosseriebauer/in',
    Occupation: 'Body Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287207,
    OccDE: 'Karosseriebauer/in (Metall)',
    Occupation: 'Body Builder (Metal)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601107,
    OccDE: 'Karosserieingenieur/in',
    Occupation: 'Body Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 512307,
    OccDE: 'Karosserielackierer/in',
    Occupation: 'Body Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287205,
    OccDE: 'Blechkarosseriebauer/in',
    Occupation: 'Body Panel Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621105,
    OccDE: 'Karosseriebautechniker/in',
    Occupation: 'Body Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621107,
    OccDE: 'Karosserietechniker/in',
    Occupation: 'Body Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287210,
    OccDE: 'Karosseriebaufacharbeiter/in',
    Occupation: 'Body Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287213,
    OccDE: 'Karosseriefacharbeiter/in (Metall)',
    Occupation: 'Body Technician (Metal)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287212,
    OccDE: 'Karosseriebearbeiter/in',
    Occupation: 'Body Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 492205,
    OccDE: 'Karosserieausstatter/in',
    Occupation: 'Body Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791299,
    OccDE: 'Personenschutz',
    Occupation: 'Bodyguard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791205,
    OccDE: 'Personenschützer/in',
    Occupation: 'Bodyguard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 252108,
    OccDE: 'Kessel- und Behälterbauer/in',
    Occupation: 'Boiler and Container Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549834,
    OccDE: 'Kesselwärter/in',
    Occupation: 'Boiler Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 937219,
    OccDE: 'Kesselreiniger/in',
    Occupation: 'Boiler Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 178003,
    OccDE: 'Buchbinder/in',
    Occupation: 'Book Binder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 179508,
    OccDE: 'Buchbinderhelfer/in',
    Occupation: 'Book Bindery Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 179506,
    OccDE: 'Buchbindereiarbeiter/in',
    Occupation: 'Book Bindery Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 178202,
    OccDE: 'Buchbindemaschinenführer/in',
    Occupation: 'Book Binding Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 674003,
    OccDE: 'Buchhändler/in',
    Occupation: 'Book Dealer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683403,
    OccDE: 'Buchverleger/in',
    Occupation: 'Book Publisher',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 683102,
    OccDE: 'Buchverlagskaufmann/-kauffrau',
    Occupation: 'Book Publishing Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 772005,
    OccDE: 'Buchhalter/in',
    Occupation: 'Bookkeeper',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 772007,
    OccDE: 'Buchhaltungsgehilf(e/in)',
    Occupation: 'Bookkeeping Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 772006,
    OccDE: 'Buchhaltungsfachkraft',
    Occupation: 'Bookkeeping Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 674103,
    OccDE: 'Buchhändler/in (Schwerpunkt Sortiment)',
    Occupation: 'Bookseller (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 674005,
    OccDE: 'Buchhandlungsgehilf(e/in)',
    Occupation: 'Bookseller Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 674006,
    OccDE: 'Buchhandlungskaufmann/-kauffrau',
    Occupation: 'Bookseller Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835202,
    OccDE: 'Aufnahmeleiterassistent/in',
    Occupation: 'Boom Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801103,
    OccDE: 'Bootsmann (Bundeswehr)',
    Occupation:
      'Bootsmann (Highest Ranking Petty Officer in the German Armed Forces)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801495,
    OccDE: 'Grenzschutzbeamter (Flugdienst)',
    Occupation: 'Border Guard (Air Patrol)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801494,
    OccDE: 'Grenzschutzgruppe 9',
    Occupation: 'Border Guard Group 9',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801496,
    OccDE: 'Grenzschutzbeamter (Seedienst)',
    Occupation: 'Border Guards (Naval Patrol)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801304,
    OccDE: 'Grenzschutz-Offizieranwärter/in',
    Occupation: 'Border Patrol Officer Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801412,
    OccDE: 'Grenzschutzvollzugsbeamt(er/in)',
    Occupation: 'Border Patrol Official',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 224702,
    OccDE: 'Bohrerhelfer/in',
    Occupation: 'Boring Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 224101,
    OccDE: 'Bohrwerkdreher/in',
    Occupation: 'Boring Mill Lathe Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 224102,
    OccDE: 'Bohrwerkfacharbeiter/in',
    Occupation: 'Boring Mill Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141701,
    OccDE: 'Abfüller/in (Chemiebetrieb)',
    Occupation: 'Bottler (Chemical Plant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522447,
    OccDE: 'Getränkeabfüller/in',
    Occupation: 'Bottlers',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 676302,
    OccDE: 'Filialleiter/in',
    Occupation: 'Branch Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750212,
    OccDE: 'Geschäftsstellenleiter/in',
    Occupation: 'Branch Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750218,
    OccDE: 'Niederlassungsleiter/in',
    Occupation: 'Branch Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691622,
    OccDE: 'Filialleiter/in (Bank)',
    Occupation: 'Branch Manager (Bank)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 750206,
    OccDE: 'Filialleiter/in (nicht Handel, Bank, Versicherung)',
    Occupation: 'Branch Manager (not Commercial, Banking, Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 676301,
    OccDE: 'Filialleiter/in (Handel)',
    Occupation: 'Branch Manager (Trade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 676309,
    OccDE: 'Zweigstellenleiter/in (Handel)',
    Occupation: 'Branch Manager (Trade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695207,
    OccDE: 'Geschäftsstellenleiter/in (Versicherungswesen)',
    Occupation: 'Branch Office Manager (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660003,
    OccDE: 'Filialverkäufer/in',
    Occupation: 'Branch Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 305311,
    OccDE: 'Metallblasinstrumentenmacher/in',
    Occupation: 'Brass Instrument Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831406,
    OccDE: 'Blechbläser/in',
    Occupation: 'Brass Player',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714501,
    OccDE: 'Abschleppwagenfahrer/in',
    Occupation: 'Breakdown Lorry Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 715099,
    OccDE: 'Abschleppdienst',
    Occupation: 'Breakdown Service Person',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912910,
    OccDE: 'Frühstücksleiter/in',
    Occupation: 'Breakfast Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879301,
    OccDE: 'Atem- und Stimmlehrer/in',
    Occupation: 'Breathing and Vocal Coach',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 421207,
    OccDE: 'Brauer/in',
    Occupation: 'Brewer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 421002,
    OccDE: 'Brauer/in und Mälzer/in',
    Occupation: 'Brewer and Maltster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 421208,
    OccDE: 'Brauer/in und Mälzer/in (Brauerei)',
    Occupation: 'Brewer and Maltster (Brewery)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 421701,
    OccDE: 'Brauereihilfsarbeiter/in',
    Occupation: 'Brewery Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606101,
    OccDE: 'Brauereiingenieur/in',
    Occupation: 'Brewery Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 421003,
    OccDE: 'Brauereifacharbeiter/in',
    Occupation: 'Brewery Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 421212,
    OccDE: 'Braumeister/in',
    Occupation: 'Brewmaster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441006,
    OccDE: 'Maurer',
    Occupation: 'Bricklayer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441007,
    OccDE: 'Maurer und Zimmerer',
    Occupation: 'Bricklayer and Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441099,
    OccDE: 'Maurer und Putzer',
    Occupation: 'Bricklayer and Plasterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441008,
    OccDE: 'Maurergeselle',
    Occupation: 'Bricklayer Journeyman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441702,
    OccDE: 'Maurerhelfer',
    Occupation: "Bricklayer's Assistant",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441106,
    OccDE: 'Maurerpolier',
    Occupation: 'Bricklaying Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121775,
    OccDE: 'Ziegeleiarbeiter/in',
    Occupation: 'Brickworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121822,
    OccDE: 'Ziegler/in',
    Occupation: 'Brickworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 716403,
    OccDE: 'Brückenwärter/in',
    Occupation: 'Bridge Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603204,
    OccDE: 'Brückenbauingenieur/in',
    Occupation: 'Bridge Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 255102,
    OccDE: 'Brückenbaumonteur/in',
    Occupation: 'Bridge Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821602,
    OccDE: 'Fernsehsprecher/in',
    Occupation: 'Broadcaster',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 691408,
    OccDE: 'Effektenhändler/in',
    Occupation: 'Broker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704212,
    OccDE: 'Broker/in',
    Occupation: 'Broker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704002,
    OccDE: 'Makler/in',
    Occupation: 'Broker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695427,
    OccDE: 'Vermittlungsagent/in (Versicherungswesen)',
    Occupation: 'Broker (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 185311,
    OccDE: 'Bürstenmacher/in',
    Occupation: 'Brush Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771101,
    OccDE: 'Budget Analyst',
    Occupation: 'Budget Analyst',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661601,
    OccDE: 'Büfettverkäufer/in',
    Occupation: 'Buffet Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915314,
    OccDE: 'Büfettier',
    Occupation: 'Buffet Services Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915310,
    OccDE: 'Büfettfachkraft',
    Occupation: 'Buffet Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915316,
    OccDE: 'Büfettkraft',
    Occupation: 'Buffet Staff Member',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831420,
    OccDE: 'Hornist/in',
    Occupation: 'Bugler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442501,
    OccDE: 'Baufacharbeiter (Montagearbeiten, Montagebau)',
    Occupation: 'Builder (Assembly work, Fitting)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 510301,
    OccDE: 'Bau- und Metallmaler/in',
    Occupation: 'Building and Metal Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642204,
    OccDE: 'Hochbauzeichner/in',
    Occupation: 'Building Construction Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603302,
    OccDE: 'Hochbauingenieur/in',
    Occupation: 'Building Construction Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 623302,
    OccDE: 'Hochbautechniker/in',
    Occupation: 'Building Construction Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440407,
    OccDE: 'Hochbaufachwerker/in',
    Occupation: 'Building Construction Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442009,
    OccDE: 'Hochbaufacharbeiter (Beton-, Stahlbetonbauarbeiten)',
    Occupation:
      'Building Construction Specialist (Concrete, Reinforced Concrete Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441005,
    OccDE: 'Hochbaufacharbeiter (Maurerarbeiten)',
    Occupation: 'Building Construction Specialist (Masonry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440103,
    OccDE: 'Hochbaufacharbeiter',
    Occupation: 'Building Construction Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441103,
    OccDE: 'Bauunternehmer (Maurermeister)',
    Occupation: 'Building Contractor (Certified Bricklayer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511002,
    OccDE: 'Baudekorateur/in (Maler/in und Lackierer/in)',
    Occupation: 'Building Decorator (Painter and Varnisher)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 796401,
    OccDE: 'Gebäudetechniker/in',
    Occupation: 'Building Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440307,
    OccDE: 'Hochbaupolier',
    Occupation: 'Building Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 485202,
    OccDE: 'Bauglaser/in',
    Occupation: 'Building Glazier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792311,
    OccDE: 'Gebäudewächter/in',
    Occupation: 'Building Guard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 796402,
    OccDE: 'Haushandwerker/in',
    Occupation: 'Building Handyman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803303,
    OccDE: 'Baukontrolleur/in (Sicherheitskontrolleur/in)',
    Occupation: 'Building Inspector (Safety Inspector)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934319,
    OccDE: 'Gebäudeinnenreiniger/in',
    Occupation: 'Building Interior Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 796207,
    OccDE: 'Hausverwalter/in (Hauswart/in)',
    Occupation: 'Building Manager (Caretaker)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704514,
    OccDE: 'Hausverwalter/in (Grundstücksverwalter/in)',
    Occupation: 'Building Manager (Property Manager)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511003,
    OccDE: 'Baumaler/in',
    Occupation: 'Building Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481004,
    OccDE: 'Baustukkateur/in',
    Occupation: 'Building Plasterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482901,
    OccDE: 'Bautenschutzwerker/in',
    Occupation: 'Building Protection Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440302,
    OccDE: 'Baupolier',
    Occupation: 'Building Site Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 692002,
    OccDE: 'Bausparkassenberater/in',
    Occupation: 'Building Society Adviser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 692003,
    OccDE: 'Bausparkassenfachmann/-fachfrau',
    Occupation: 'Building Society Banking Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 692004,
    OccDE: 'Bausparkassenkaufmann/-kauffrau',
    Occupation: 'Building Society Certified Banker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 692001,
    OccDE: 'Bausparkassenangestellte/r',
    Occupation: 'Building Society Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 692102,
    OccDE: 'Bausparkassenvertreter/in',
    Occupation: 'Building Society Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 796403,
    OccDE: 'Haustechniker/in',
    Occupation: 'Building Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440102,
    OccDE: 'Baufacharbeiter',
    Occupation: 'Building Trade Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440101,
    OccDE: 'Baufacharbeiter (Hochbau)',
    Occupation: 'Building Trade Worker (Building Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511101,
    OccDE: 'Baulackierer/in',
    Occupation: 'Building Varnisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603806,
    OccDE: 'Bauassessor/in',
    Occupation: 'Buildings Clerk',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 603802,
    OccDE: 'Bau(rat/rätin)',
    Occupation: 'Buildings Director General',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 603810,
    OccDE: 'Bauinspektor/in',
    Occupation: 'Buildings Higher Executive Officer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 603815,
    OccDE: 'Baureferendar/in',
    Occupation: 'Buildings Referendary',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 603811,
    OccDE: 'Bauober(rat/rätin)',
    Occupation: 'Buildings Senior Director General',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 603813,
    OccDE: 'Bauoberinspektor/in',
    Occupation: 'Buildings Senior Executive Officer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 545105,
    OccDE: 'Planierraupenführer/in',
    Occupation: 'Bulldozer Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714906,
    OccDE: 'Geldtransportfahrer/in',
    Occupation: 'Bullion Van Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744208,
    OccDE: 'Geldtransportbegleiter/in',
    Occupation: 'Bullion Van Guard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 423104,
    OccDE: 'Brenner/in',
    Occupation: 'Burner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714304,
    OccDE: 'Busfahrer/in',
    Occupation: 'Bus Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714309,
    OccDE: 'Omnibusfahrer/in',
    Occupation: 'Bus Drivers',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 715201,
    OccDE: 'Omnibusverkehrsunternehmer/in',
    Occupation: 'Bus Transportation Provider',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 881401,
    OccDE: 'Beratende(r) Betriebswirt/in',
    Occupation: 'Business Administration Consultant',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 881404,
    OccDE: 'Betriebswirtschaftler/in',
    Occupation: 'Business Administrator',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 881501,
    OccDE: 'Betriebswirt/in',
    Occupation: 'Business Administrator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 772003,
    OccDE: 'Betriebswirt/in (Buchhaltung)',
    Occupation: 'Business Administrator (Accounting)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 753101,
    OccDE: 'Betriebswirt/in (Wirtschaftsprüfung)',
    Occupation: 'Business Administrator (Auditing)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 691014,
    OccDE: 'Betriebswirt/in (Bankfach)',
    Occupation: 'Business Administrator (Banking)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 692005,
    OccDE: 'Betriebswirt/in (Bausparkasse)',
    Occupation: 'Business Administrator (Building Society)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 757002,
    OccDE: 'Betriebswirt/in (Unternehmensberatung)',
    Occupation: 'Business Administrator (Business Advisory)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 771508,
    OccDE: 'Betriebswirt/in (Kalkulation)',
    Occupation: 'Business Administrator (Calculation)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 773001,
    OccDE: 'Betriebswirt/in (Kassenwesen)',
    Occupation: 'Business Administrator (Cash Management)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 756204,
    OccDE: 'Betriebswirt/in (Controlling)',
    Occupation: 'Business Administrator (Controlling)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 701003,
    OccDE: 'Betriebswirt/in (Güterverkehr)',
    Occupation: 'Business Administrator (Goods Transport)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 881403,
    OccDE: 'Betriebswirt/in (grad.)',
    Occupation: 'Business Administrator (grad)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 695001,
    OccDE: 'Betriebswirt/in (Versicherungswesen)',
    Occupation: 'Business Administrator (Insurance)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 750301,
    OccDE: 'Betriebswirt/in (Management)',
    Occupation: 'Business Administrator (Management)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 703101,
    OccDE: 'Betriebswirt/in (Werbung)',
    Occupation: 'Business Administrator (Marketing)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 756112,
    OccDE: 'Betriebswirt/in (Organisation)',
    Occupation: 'Business Administrator (Organisation)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 702001,
    OccDE: 'Betriebswirt/in (Personen-, Fremdenverkehr)',
    Occupation: 'Business Administrator (Passenger, Tourism)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 765005,
    OccDE: 'Betriebswirt/in (öffentliche Verwaltung)',
    Occupation: 'Business Administrator (Public Administration)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 683001,
    OccDE: 'Betriebswirt/in (Verlag)',
    Occupation: 'Business Administrator (Publishing)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 704502,
    OccDE: 'Betriebswirt/in (Grundstücks- und Wohnungswirtschaft)',
    Occupation:
      'Business Administrator (Real Estate and Residential Housing Sector)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 753201,
    OccDE: 'Betriebswirt/in (Steuerberatung)',
    Occupation: 'Business Administrator (Tax Consulting)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 670201,
    OccDE: 'Betriebswirt/in (Handel)',
    Occupation: 'Business Administrator (Trade)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 628301,
    OccDE: 'Betriebsfachwirt/in des Handwerks',
    Occupation: 'Business Administrator of Handicraft',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 628302,
    OccDE: 'Betriebswirt/in des Handwerks',
    Occupation: 'Business Administrator of Handicraft',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 881301,
    OccDE: 'Diplom-Kaufmann/Kauffrau',
    Occupation: 'Business Administrator with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 881405,
    OccDE: 'Diplom-Betriebswirt/in',
    Occupation: 'Business Administrator with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 774416,
    OccDE: 'Wirtschaftsinformationsassistent/in (EDV)',
    Occupation: 'Business and Information Management Assistant (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 754104,
    OccDE: 'Gehilf(e/in) in wirtschafts- und steuerberatenden Berufen',
    Occupation: 'Business and Tax Advisory Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 788407,
    OccDE: 'Kaufmännische(r) Assistent/in (fremdsprachliche Korrespondenz)',
    Occupation: 'Business Assistant (Foreign Language Correspondence)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789405,
    OccDE: 'Kaufmännische(r) Assistent/in (fremdsprachliches Sekretariat)',
    Occupation: "Business Assistant (Foreign Language Secretary's Office)",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751813,
    OccDE: 'Wirtschaftsassistent/in (Managementassistent/in)',
    Occupation: 'Business assistant (Management Assistant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789005,
    OccDE: 'Kaufmännische(r) Assistent/in (Sekretariat)',
    Occupation: "Business Assistant (Secretary's Office)",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780405,
    OccDE: 'Wirtschaftskaufmann/-kauffrau',
    Occupation: 'Business Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774410,
    OccDE: 'Kaufmännische(r) Assistent/in (EDV)',
    Occupation: 'Business Clerk (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 780403,
    OccDE: 'Kaufmann/Kauffrau (Angestellte/r)',
    Occupation: 'Business Clerk (Employee)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 757008,
    OccDE: 'Unternehmensberater/in',
    Occupation: 'Business Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 757001,
    OccDE: 'Betriebsberater/in',
    Occupation: 'Business Consultant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 757003,
    OccDE: 'Firmenberater/in',
    Occupation: 'Business Consultant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: '032510',
    OccDE: 'Wirtschaftsberater/in (landw.)',
    Occupation: 'Business Consultant (Agra)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 757009,
    OccDE: 'Wirtschaftsberater/in (nicht landw.)',
    Occupation: 'Business Consultant (not Agra)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 788411,
    OccDE: 'Wirtschaftskorrespondent/in',
    Occupation: 'Business Correspondent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751102,
    OccDE: 'Kaufmännische(r) Direktor/in',
    Occupation: 'Business Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 921110,
    OccDE: 'Wirtschaftsleiter/in (Hauswirtschaft)',
    Occupation: 'Business Director (Housekeeping)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821419,
    OccDE: 'Wirtschaftsredakteur/in',
    Occupation: 'Business Editor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780401,
    OccDE: 'Kaufmännische(r) Angestellte/r',
    Occupation: 'Business Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628107,
    OccDE: 'Wirtschaftsingenieurassistent/in',
    Occupation: 'Business Engineering Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 779208,
    OccDE: 'Wirtschaftler/in (Wirtschaftsinformatik)',
    Occupation: 'Business Information Technologist (Business IT)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 779209,
    OccDE: 'Wirtschaftsinformatiker/in (EDV)',
    Occupation: 'Business Information Technologist (EDP)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 779299,
    OccDE: 'Diplom-Wirtschaftsinformatiker/in',
    Occupation: 'Business Information Technologist with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 981178,
    OccDE: 'Student/in Wirtschaftsinformatik',
    Occupation: 'Business Information Technology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774414,
    OccDE: 'Wirtschaftsassistent/in (EDV)',
    Occupation: 'Business Management Assistant (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 774415,
    OccDE: 'Wirtschaftsassistent/in (Informatik)',
    Occupation: 'Business Management Assistant (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 785110,
    OccDE: 'Wirtschaftskaufmann/-kauffrau (Industrie)',
    Occupation: 'Business Management Clerk (Commercial)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751103,
    OccDE: 'Kaufmännische(r) Leiter/in',
    Occupation: 'Business Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 911111,
    OccDE: 'Kaufmännische/r Direktor/in (Hotel)',
    Occupation: 'Business Manager (Hotel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 612309,
    OccDE: 'Wirtschaftsmathematiker/in',
    Occupation: 'Business Mathematician',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 670002,
    OccDE: 'Geschäftsinhaber/in',
    Occupation: 'Business Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756901,
    OccDE: 'Kaufmännische(r) Planer/in',
    Occupation: 'Business Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874205,
    OccDE: 'Handelsschullehrer/in',
    Occupation: 'Business School Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981181,
    OccDE: 'Student/in Wirtschaftswissenschaften, Ökonomie',
    Occupation: 'Business Sciences, Economics Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780404,
    OccDE: 'Kaufmannsgehilf(e/in)',
    Occupation: 'Business Support Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785204,
    OccDE: 'Kaufmännisch-technische(r) Assistent/in',
    Occupation: 'Business Technical Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785205,
    OccDE: 'Kaufmännisch-technische(r) Berater/in',
    Occupation: 'Business Technical Consultant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 785203,
    OccDE: 'Kaufmännisch-technische(r) Angestellte/r',
    Occupation: 'Business Technical Employee',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 628108,
    OccDE: 'Wirtschaftstechniker/in',
    Occupation: 'Business Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 780402,
    OccDE: 'Kaufmännische(r) Auszubildende/r',
    Occupation: 'Business Trainee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 822208,
    OccDE: 'Wirtschaftsübersetzer/in',
    Occupation: 'Business Translator',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751008,
    OccDE: 'Geschäftsbereichsleiter/in',
    Occupation: 'Business Unit Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 671102,
    OccDE: 'Kaufmann/Kauffrau im Groß- und Außenhandel',
    Occupation: 'Businessman in Wholesale and Exports',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 671233,
    OccDE: 'Kaufmann/Kauffrau (Landwirtschaft, Nahrungsgüterwirtschaft)',
    Occupation: 'Businessperson (Agriculture, Food Industry)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 670903,
    OccDE: 'Kaufmann/Kauffrau und Gastwirt/in',
    Occupation: 'Businessperson and Publican',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671315,
    OccDE:
      'Kaufmann/Kauffrau im Groß- und Außenhandel (Fachrichtung Außenhandel)',
    Occupation:
      'Businessperson in Wholesale and Exports (specialising in Exports)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 671234,
    OccDE:
      'Kaufmann/Kauffrau im Groß- und Außenhandel (Fachrichtung Großhandel)',
    Occupation:
      'Businessperson in Wholesale and Exports (specialising in Wholesale)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 401003,
    OccDE: 'Fleischer/in',
    Occupation: 'Butcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401017,
    OccDE: 'Metzger/in',
    Occupation: 'Butcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401020,
    OccDE: 'Schlachter/in',
    Occupation: 'Butcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401209,
    OccDE: 'Fleischzerleger/in',
    Occupation: 'Butcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401306,
    OccDE: 'Fleischer/in (Herstellung von Feinkost und Konserven)',
    Occupation: 'Butcher (Fine Foods and Preserves Manufacturing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401402,
    OccDE: 'Fleischer/in (Verkauf)',
    Occupation: 'Butcher (Sales)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401103,
    OccDE: 'Fleischer/in (Schlachten und Zerlegen)',
    Occupation: 'Butcher (Slaughtering and Cutting)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401206,
    OccDE: 'Fleischer/in (Zerlegen und Verarbeiten)',
    Occupation: 'Butcher (Slaughtering and Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661302,
    OccDE: 'Fleischerei(fach)verkäufer/in',
    Occupation: 'Butchery Salesperson (Consultant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661306,
    OccDE: 'Metzgerei(fach)verkäufer/in',
    Occupation: 'Butchery Salesperson (Consultant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661307,
    OccDE: 'Schlachterei(fach)verkäufer/in',
    Occupation: 'Butchery Salesperson (Consultant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627102,
    OccDE: 'Fleischereitechniker/in',
    Occupation: 'Butchery technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401018,
    OccDE: 'Metzgereifacharbeiter/in',
    Occupation: 'Butchery Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921101,
    OccDE: 'Butler',
    Occupation: 'Butler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912502,
    OccDE: 'Cabin Attendant (Flugbegleiter/in)',
    Occupation: 'Cabin Attendant (Flight Attendants)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283909,
    OccDE: 'Kabinenmechaniker/in (Fluggerät)',
    Occupation: 'Cabin Mechanic (Aircraft)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501308,
    OccDE: 'Möbeltischler/in',
    Occupation: 'Cabinetmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549315,
    OccDE: 'Seilbahnmaschinist/in',
    Occupation: 'Cable Car Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311410,
    OccDE: 'Kabelbauarbeiter/in',
    Occupation: 'Cable Construction Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 212534,
    OccDE: 'Kabelkonfektionierer/in',
    Occupation: 'Cable Fabricator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 212523,
    OccDE: 'Kabelbinder/in',
    Occupation: 'Cable Fastener',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311413,
    OccDE: 'Kabelmonteur/in',
    Occupation: 'Cable Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311411,
    OccDE: 'Kabelleger/in',
    Occupation: 'Cable Layer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 212528,
    OccDE: 'Kabelfertigungsmechaniker/in',
    Occupation: 'Cable Manufacturing Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622904,
    OccDE: 'Kabeltechniker/in',
    Occupation: 'Cable Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 212542,
    OccDE: 'Kabelwerker/in',
    Occupation: 'Cable Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623301,
    OccDE: 'CAD-Konstrukteur/in (Hochbautechniker/in)',
    Occupation: 'CAD Construction Engineer (Building Construction Engineer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834001,
    OccDE: 'CAD-Designer/in',
    Occupation: 'CAD Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600203,
    OccDE: 'CAD-Konstrukteur/in (Ingenieur/in)',
    Occupation: 'CAD Designer (Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600205,
    OccDE: 'Entwicklungskonstrukteur/in (Ingenieur/in)',
    Occupation: 'CAD Development Designer (Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 641109,
    OccDE: 'CAD-Zeichner/in',
    Occupation: 'CAD Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642101,
    OccDE: 'CAD-Bauzeichner/in',
    Occupation: 'CAD Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642104,
    OccDE: 'CAD-Zeichner/in (Bauzeichner/in)',
    Occupation: 'CAD Draughtsman (Draughting)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600202,
    OccDE: 'CAD-Ingenieur/in',
    Occupation: 'CAD Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 641105,
    OccDE: 'CAD-Layouter/in',
    Occupation: 'CAD Layout Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 641107,
    OccDE: 'CAD-Operator/in',
    Occupation: 'CAD Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 607206,
    OccDE: 'CAD-Programmierer/in (Ingenieur/in)',
    Occupation: 'CAD Programmer (Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 628221,
    OccDE: 'CAD-Programmierer/in (Techniker/in)',
    Occupation: 'CAD Programmer (Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 641104,
    OccDE: 'CAD-Fachkraft',
    Occupation: 'CAD Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778401,
    OccDE: 'CAD-System-Manager/in',
    Occupation: 'CAD Systems Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778302,
    OccDE: 'CAD-Systembetreuer/in',
    Occupation: 'CAD Systems Technician',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 620201,
    OccDE: 'CAD-Techniker/in',
    Occupation: 'CAD Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 620202,
    OccDE: 'CAD/CAM-Planer/in',
    Occupation: 'CAD/CAM Planner',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 624303,
    OccDE: 'Katastertechniker/in',
    Occupation: 'Cadastral Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911406,
    OccDE: 'Cafehauspächter/in',
    Occupation: 'Café Lessee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911405,
    OccDE: 'Cafebesitzer/in',
    Occupation: 'Café Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912906,
    OccDE: 'Cafe-Servierer/in',
    Occupation: 'Café Server',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915318,
    OccDE: 'Cafeteria-Angestellte/r',
    Occupation: 'Cafeteria Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703299,
    OccDE: 'Call-Center-Agent/in',
    Occupation: 'Call Centre Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915911,
    OccDE: 'Callgirl',
    Occupation: 'Call Girl',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837312,
    OccDE: 'Kamera-Assistent/in',
    Occupation: 'Camera Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837314,
    OccDE: 'Kameramann/Kamerafrau',
    Occupation: 'Cameraman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 794506,
    OccDE: 'Campingplatzwart/in',
    Occupation: 'Camping Groundskeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705110,
    OccDE: 'Campingplatzvermieter/in',
    Occupation: 'Camping Place Hire Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441305,
    OccDE: 'Kanalmaurer',
    Occupation: 'Canal Bricklayer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466304,
    OccDE: 'Kanalbauer',
    Occupation: 'Canal Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623507,
    OccDE: 'Kanalbautechniker/in',
    Occupation: 'Canal Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466305,
    OccDE: 'Kanalbaufacharbeiter',
    Occupation: 'Canal Engineering Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466308,
    OccDE: 'Kanalfacharbeiter (Kanalbau)',
    Occupation: 'Canal Specialist (Canal Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911506,
    OccDE: 'Kantinenverwalter/in',
    Occupation: 'Canteen Administrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912709,
    OccDE: 'Kantinengehilf(e/in)',
    Occupation: 'Canteen Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411404,
    OccDE: 'Kantinenkoch/-köchin',
    Occupation: 'Canteen Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911504,
    OccDE: 'Kantinenleiter/in',
    Occupation: 'Canteen Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915322,
    OccDE: 'Kantinenangestellte/r',
    Occupation: 'Canteen Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911505,
    OccDE: 'Kantinenpächter/in',
    Occupation: 'Canteen Lessee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911502,
    OccDE: 'Kantinenbetriebsleiter/in',
    Occupation: 'Canteen Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911507,
    OccDE: 'Kantinenwirt/in',
    Occupation: 'Canteen Proprietor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661604,
    OccDE: 'Kantinenverkäufer/in',
    Occupation: 'Canteen Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915341,
    OccDE: 'Kantinenkraft',
    Occupation: 'Canteen Staff Member',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831602,
    OccDE: 'Kantor/in',
    Occupation: 'Cantor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705308,
    OccDE: 'Akquisiteur/in',
    Occupation: 'Canvasser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721109,
    OccDE: 'Kapitän/in',
    Occupation: 'Captain',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721103,
    OccDE: 'Kapitän/in (Seeschiffahrt)',
    Occupation: 'Captain (Maritime)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671236,
    OccDE: 'Kfz-Kaufmann/-kauffrau (Großhandel)',
    Occupation: 'Car Businessperson (Wholesale)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 673510,
    OccDE: 'Autohändler/in',
    Occupation: 'Car Dealer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673501,
    OccDE: 'Autohändler/in (Einzelhandel)',
    Occupation: 'Car Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673505,
    OccDE: 'Kfz-Händler/in (Einzelhandel)',
    Occupation: 'Car Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671204,
    OccDE: 'Autohändler/in (Großhandel)',
    Occupation: 'Car Dealer (Wholesale)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 671235,
    OccDE: 'Kfz-Händler/in (Großhandel)',
    Occupation: 'Car Dealer (Wholesale)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 834101,
    OccDE: 'Autodesigner/in',
    Occupation: 'Car Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287199,
    OccDE: 'KFZ-Aufbereiter/in',
    Occupation: 'Car Detailer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 936101,
    OccDE: 'Autopfleger/in',
    Occupation: 'Car Detailer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714907,
    OccDE: 'Kfz-Überführer/in',
    Occupation: 'Car Ferrying Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512301,
    OccDE: 'Autolackierer/in',
    Occupation: 'Car Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 485601,
    OccDE: 'Autoglaser/in',
    Occupation: 'Car Glass Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 485602,
    OccDE: 'Fahrzeugglaser/in',
    Occupation: 'Car Glass Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662401,
    OccDE: 'Autoverkäufer/in',
    Occupation: 'Car Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662404,
    OccDE: 'Kfz-Verkäufer/in',
    Occupation: 'Car Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671205,
    OccDE: 'Autokaufmann/-kauffrau (Großhandel)',
    Occupation: 'Car Salesperson (Wholesale)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621108,
    OccDE: 'Kfz-Fachwirt/in',
    Occupation: 'Car Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273916,
    OccDE: 'Fahrzeugschlosser/in (Triebfahrzeuge)',
    Occupation: 'Car Technician (Locomotives)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 374301,
    OccDE: 'Autosattler/in',
    Occupation: 'Car Upholsterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 492201,
    OccDE: 'Autopolster(er/in)',
    Occupation: 'Car Upholsterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 492203,
    OccDE: 'Fahrzeugpolster(er/in)',
    Occupation: 'Car Upholsterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 936011,
    OccDE: 'Waschstraßenbetreuer/in',
    Occupation: 'Car Wash Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 936007,
    OccDE: 'Fahrzeugwaschanlagenbediener/in',
    Occupation: 'Car Wash Systems Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 936099,
    OccDE: 'Waschanlagenarbeiter/in',
    Occupation: 'Car Wash Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 936102,
    OccDE: 'Autowäscher/in',
    Occupation: 'Car Washer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 544501,
    OccDE: 'Autokranfahrer/in',
    Occupation: 'Car-Mounted Crane Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 544502,
    OccDE: 'Autokranführer/in',
    Occupation: 'Car-Mounted Crane Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287902,
    OccDE: 'Wohnwagenbauer/in',
    Occupation: 'Caravan Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 162310,
    OccDE: 'Kartonagenmaschinenführer/in',
    Occupation: 'Cardboard Packaging Machinery Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 162711,
    OccDE: 'Kartonagenmaschinenarbeiter/in',
    Occupation: 'Cardboard Packaging Machinery Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 162309,
    OccDE: 'Kartonagenmacher/in',
    Occupation: 'Cardboard Packaging Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841112,
    OccDE: 'Kardiolog(e/in)',
    Occupation: 'Cardiologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 864103,
    OccDE: 'Pflegedienstleiter/in (Altenpflege)',
    Occupation: 'Care Director (Geriatric Care)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 715102,
    OccDE: 'Mietautounternehmer/in',
    Occupation: 'Care Hire Businessman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705106,
    OccDE: 'Autovermieter/in',
    Occupation: 'Care Hire Provider',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853910,
    OccDE: 'Betreuungspfleger (Krankenpflege)',
    Occupation: 'Care Nurse (Nursing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869012,
    OccDE: 'Pfleger/in (Sozialpfleger/in)',
    Occupation: 'Care Provider (Social Care)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854117,
    OccDE: 'Pflegehilfe (Krankenpflege)',
    Occupation: 'Care Provider Assistant (Nursing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 864001,
    OccDE: 'Altenbetreuer/in',
    Occupation: 'Care Provider for Seniors',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 864004,
    OccDE: 'Pflegefachkraft (Altenpflege)',
    Occupation: 'Care Specialist (Geriatric Care)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 868103,
    OccDE: 'Arbeitsberater/in',
    Occupation: 'Career Advisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 868202,
    OccDE: 'Berufsberater/in',
    Occupation: 'Career Advisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 993198,
    OccDE: 'Berufsbetreuer/in',
    Occupation: 'Careers Advisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 864005,
    OccDE: 'Pflegekraft (Altenpflege)',
    Occupation: 'Caregiver (Geriatric Care)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 796209,
    OccDE: 'Hauswart/in',
    Occupation: 'Caretaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '024002',
    OccDE: 'Pfleger/in (Tierpfleger/in)',
    Occupation: 'Caretaker (Animal Keeper)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 796206,
    OccDE: 'Hausmann (Hauswart)',
    Occupation: 'Caretaker (Caretaker)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501005,
    OccDE: 'Schreiner/in',
    Occupation: 'Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501007,
    OccDE: 'Tischler/in',
    Occupation: 'Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487003,
    OccDE: 'Bauzimmerer',
    Occupation: 'Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487011,
    OccDE: 'Zimmerer',
    Occupation: 'Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487015,
    OccDE: 'Zimmermann',
    Occupation: 'Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501107,
    OccDE: 'Tischler/in (Bau- und Möbeltischlerei)',
    Occupation: 'Carpenter (Construction and Furniture Making)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487424,
    OccDE: 'Zimmerer (Bau-, Baumaterialienindustrie)',
    Occupation: 'Carpenter (Construction, Building Materials Industry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501313,
    OccDE: 'Tischler/in (Möbeltischlerei)',
    Occupation: 'Carpenter (Furniture Carpentry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501224,
    OccDE: 'Tischler/in (Bautischlerei)',
    Occupation: 'Carpenter (Joinery)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487010,
    OccDE: 'Zimmerer (Holzbau)',
    Occupation: 'Carpenter (Timber Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501108,
    OccDE: 'Tischler/in und Glaser/in',
    Occupation: 'Carpenter and Glazier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501106,
    OccDE: 'Schreiner/in und Glaser/in',
    Occupation: 'Carpenter and Glazier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501810,
    OccDE: 'Tischlerhelfer/in (Reha)',
    Occupation: 'Carpenter Helper (for people with disabilities)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487704,
    OccDE: 'Zimmererhelfer',
    Occupation: 'Carpenters Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501402,
    OccDE: 'Auslieferungstischler/in',
    Occupation: 'Carpentry Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501407,
    OccDE: 'Lieferschreiner/in',
    Occupation: 'Carpentry Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501801,
    OccDE: 'Fachwerker/in im Tischlerhandwerk',
    Occupation: 'Carpentry Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501707,
    OccDE: 'Schreinereiarbeiter/in',
    Occupation: 'Carpentry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931913,
    OccDE: 'Teppichreiniger/in',
    Occupation: 'Carpet Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673406,
    OccDE: 'Teppichhändler/in (Einzelhandel)',
    Occupation: 'Carpet Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491313,
    OccDE: 'Teppichbodenverleger/in',
    Occupation: 'Carpet Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491314,
    OccDE: 'Teppichleger/in',
    Occupation: 'Carpet Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 715001,
    OccDE: 'Fuhrunternehmer/in',
    Occupation: 'Carrier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701012,
    OccDE: 'Frachtführer/in',
    Occupation: 'Carrier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642508,
    OccDE: 'Kartograph/in',
    Occupation: 'Cartographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 604809,
    OccDE: 'Kartographie-Ingenieur/in',
    Occupation: 'Cartography Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 981133,
    OccDE: 'Student/in Kartographie',
    Occupation: 'Cartography Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 786205,
    OccDE: 'Prozeß(sach)bearbeiter/in',
    Occupation: 'Case Administration Agent',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 863103,
    OccDE: 'Einzelfallbetreuer/in (Kindergarten)',
    Occupation: 'Case Worker (Kindergarten)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 773003,
    OccDE: 'Kassenangestellte/r',
    Occupation: 'Cashier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 773005,
    OccDE: 'Kassierer/in',
    Occupation: 'Cashier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 706116,
    OccDE: 'Kassierer/in (Beitrags-, Kino-, Prämien-, Raten- u.ä.)',
    Occupation: 'Cashier (Entry, Cinema, Premium, Instalment)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 773102,
    OccDE: 'Kassierer/in (Handel)',
    Occupation: 'Cashier (Trade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 773305,
    OccDE: 'Kassenverwalter/in',
    Occupation: 'Cashier Administrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 773304,
    OccDE: 'Kassenleiter/in',
    Occupation: 'Cashier Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 773101,
    OccDE: 'Kassenaufsicht (Handel)',
    Occupation: 'Cashier Supervisor (Trade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511211,
    OccDE: 'Weißbinder/in (Maler/in)',
    Occupation: 'Cask Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201007,
    OccDE: 'Formgießer',
    Occupation: 'Caster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152001,
    OccDE: 'Former/in (Kunststoff)',
    Occupation: 'Caster (Plastics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705204,
    OccDE: 'Casting Director',
    Occupation: 'Casting Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 891303,
    OccDE: 'Katechet/in',
    Occupation: 'Catechist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 911805,
    OccDE: 'Caterer',
    Occupation: 'Caterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705320,
    OccDE: 'Cateringagent/in',
    Occupation: 'Catering Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911807,
    OccDE: 'Cateringleiter/in',
    Occupation: 'Catering Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911806,
    OccDE: 'Catering-Manager',
    Occupation: 'Catering Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911099,
    OccDE: 'Gastronomiegeschäftsführer/in',
    Occupation: 'Catering Managing Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915204,
    OccDE: 'Gastronomie-Aushilfe',
    Occupation: 'Catering Temporary Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023128',
    OccDE: 'Rinderzüchter/in',
    Occupation: 'Cattle Breeder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013748',
    OccDE: 'Viehpfleger/in',
    Occupation: 'Cattle Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482003,
    OccDE: 'Abdichter/in',
    Occupation: 'Caulker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 423307,
    OccDE: 'Kellermeister/in (Weinküfer/in)',
    Occupation: 'Cellarmaster (Cooper)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831408,
    OccDE: 'Cellist/in',
    Occupation: 'Cellist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '080697',
    OccDE: 'Zementwerker',
    Occupation: 'Cement Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051802',
    OccDE: 'Friedhofsgärtner/in',
    Occupation: 'Cemetery Gardner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787606,
    OccDE: 'Friedhofsverwalter/in',
    Occupation: 'Cemetery Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 471101,
    OccDE: 'Friedhofsarbeiter',
    Occupation: 'Cemetery Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691809,
    OccDE: 'Bundesbankinspektor/in',
    Occupation: 'Central Bank Inspector',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 691806,
    OccDE: 'Bundesbankbeamt(er/in)',
    Occupation: 'Central Bank Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 691814,
    OccDE: 'Bundesbanksekretär/in',
    Occupation: 'Central Bank Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691813,
    OccDE: 'Bundesbankobersekretär/in',
    Occupation: 'Central Bank Senior Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268005,
    OccDE: 'Zentralheizungs- und Lüftungsbauer/in',
    Occupation: 'Central Heating and Ventilation Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268116,
    OccDE: 'Zentralheizungsbauer/in',
    Occupation: 'Central Heating Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268119,
    OccDE: 'Zentralheizungsmonteur/in',
    Occupation: 'Central Heating Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268118,
    OccDE: 'Zentralheizungsinstallateur/in',
    Occupation: 'Central Heating Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 735403,
    OccDE: 'Zentralist/in (Auskunftszentrale)',
    Occupation: 'Central Office Worker (Information Centre)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 677116,
    OccDE: 'Zentraleinkäufer/in',
    Occupation: 'Central Purchaser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121211,
    OccDE: 'Kerammodelleinrichter/in',
    Occupation: 'Ceramic Model Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121008,
    OccDE: 'Keramiker/in',
    Occupation: 'Ceramicist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121437,
    OccDE: 'Keramgießer/in',
    Occupation: 'Ceramics Caster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606512,
    OccDE: 'Keramikingenieur/in',
    Occupation: 'Ceramics Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 121433,
    OccDE: 'Keramformer/in',
    Occupation: 'Ceramics Mould Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 514109,
    OccDE: 'Kerammaler/in',
    Occupation: 'Ceramics Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 514106,
    OccDE: 'Keramikmaler/in',
    Occupation: 'Ceramics Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627506,
    OccDE: 'Keramiktechniker/in',
    Occupation: 'Ceramics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121009,
    OccDE: 'Keramikfacharbeiter/in',
    Occupation: 'Ceramics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121742,
    OccDE: 'Keramikarbeiter/in',
    Occupation: 'Ceramics Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310041,
    OccDE: 'Meister/in für Elektrotechnik',
    Occupation: 'Certified  Electrical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 772102,
    OccDE: 'Bilanzbuchhalter/in',
    Occupation: 'Certified Accountant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787030,
    OccDE: 'Verwaltungsfachwirt/in',
    Occupation: 'Certified Administrative Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 282112,
    OccDE: 'Landmaschinenmechanikermeister/in',
    Occupation: 'Certified Agricultural Machinery Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011043',
    OccDE: 'Landwirtschaftsmeister/in',
    Occupation: 'Certified Agriculturalist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023011',
    OccDE: 'Tierwirtschaftsmeister/in',
    Occupation: 'Certified Animal Farmer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023642',
    OccDE: 'Fischwirtschaftsmeister/in',
    Occupation: 'Certified Aquaculturalist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315305,
    OccDE: 'Hörgeräteakustikermeister/in',
    Occupation: 'Certified Audiologist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512308,
    OccDE: 'Kfz-Lackierermeister/in',
    Occupation: 'Certified Autobody Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281020,
    OccDE: 'Kraftfahrzeugschlossermeister/in',
    Occupation: 'Certified Automotive Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391013,
    OccDE: 'Bäckermeister/in',
    Occupation: 'Certified Baker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 392101,
    OccDE: 'Bäcker- und Konditormeister/in',
    Occupation: 'Certified Baker and Confectioner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691009,
    OccDE: 'Bankkaufmann/-kauffrau',
    Occupation: 'Certified Banker',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 691007,
    OccDE: 'Bankfachwirt/in',
    Occupation: 'Certified Banking Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 691015,
    OccDE: 'Diplom-Bankwirt/in',
    Occupation: 'Certified Banking Specialist with Degree',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 281301,
    OccDE: 'Fahrrad- und Mopedmechanikermeister/in',
    Occupation: 'Certified Bicycle and Moped Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 506303,
    OccDE: 'Bootsbau(er)meister/in',
    Occupation: 'Certified Boatmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287209,
    OccDE: 'Karosseriebauermeister/in (Metall)',
    Occupation: 'Certified Body Builder (Metal)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 178004,
    OccDE: 'Buchbindermeister/in',
    Occupation: 'Certified Book Binder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441105,
    OccDE: 'Maurermeister',
    Occupation: 'Certified Bricklayer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441102,
    OccDE: 'Baumeister (Maurermeister)',
    Occupation: 'Certified Builder (Certified Bricklayer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934002,
    OccDE: 'Gebäude- und Fahrzeugreinigungsmeister/in',
    Occupation: 'Certified Building and Vehicle Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934004,
    OccDE: 'Gebäudereinigermeister/in',
    Occupation: 'Certified Building Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934008,
    OccDE: 'Meister/in für Gebäudereinigung',
    Occupation: 'Certified Building Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651203,
    OccDE: 'Betriebsmeister/in (Chemie)',
    Occupation: 'Certified Business Administrator (Chemistry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401005,
    OccDE: 'Fleischermeister/in',
    Occupation: 'Certified Butcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401099,
    OccDE: 'Metzgermeister/in',
    Occupation: 'Certified Butcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512302,
    OccDE: 'Autolackierermeister/in',
    Occupation: 'Certified Car Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501006,
    OccDE: 'Schreinermeister/in',
    Occupation: 'Certified Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501008,
    OccDE: 'Tischlermeister/in',
    Occupation: 'Certified Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487113,
    OccDE: 'Zimmerermeister',
    Occupation: 'Certified Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487115,
    OccDE: 'Zimmermeister',
    Occupation: 'Certified Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651115,
    OccDE: 'Meister/in für Gießereitechnik',
    Occupation: 'Certified Casting Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268006,
    OccDE: 'Zentralheizungs- und Lüftungsbauermeister/in',
    Occupation: 'Certified Central Heating and Ventilation Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268117,
    OccDE: 'Zentralheizungsbauermeister/in',
    Occupation: 'Certified Central Heating Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121007,
    OccDE: 'Keramik(er)meister/in',
    Occupation: 'Certified Ceramicist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411034,
    OccDE: 'Küchenmeister/in',
    Occupation: 'Certified Chef',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 804204,
    OccDE: 'Schornsteinfegermeister/in',
    Occupation: 'Certified Chimney Sweep',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689311,
    OccDE: 'Klinikreferent/in',
    Occupation: 'Certified Clinical Sales Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 772401,
    OccDE: 'Fachkaufmann/-kauffrau Geschäfts- und Finanzbuchführung',
    Occupation: 'Certified Commercial and Financial Accountant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 785105,
    OccDE: 'Industriefachwirt/in',
    Occupation: 'Certified Commercial Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 442101,
    OccDE: 'Beton- und Stahlbetonbauermeister',
    Occupation:
      'Certified Concrete and Reinforced Concrete Construction Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442102,
    OccDE: 'Betonbaumeister',
    Occupation: 'Certified Concrete Construction Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 392010,
    OccDE: 'Konditormeister/in',
    Occupation: 'Certified Confectioner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501103,
    OccDE: 'Bau- und Möbeltischlermeister/in',
    Occupation: 'Certified Construction and Furniture Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441101,
    OccDE: 'Baugewerksmeister (Maurermeister)',
    Occupation: 'Certified Construction Specialist  (Certified Bricklayer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902007,
    OccDE: 'Kosmetikmeister/in',
    Occupation: 'Certified Cosmetician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750501,
    OccDE: 'Handwerksmeister/in',
    Occupation: 'Certified Craftsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281106,
    OccDE: 'Kundendienstmeister/in (Kfz)',
    Occupation: 'Certified Customer Service Specialist (Auto)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 431013,
    OccDE: 'Molkereimeister/in',
    Occupation: 'Certified Dairy Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 303113,
    OccDE: 'Zahntechnikermeister/in',
    Occupation: 'Certified Dental Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310027,
    OccDE: 'Elektrotechnikmeister/in',
    Occupation: 'Certified Electrical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310018,
    OccDE: 'Elektroinstallateurmeister/in',
    Occupation: 'Certified Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310019,
    OccDE: 'Elektroinstallationsmeister/in',
    Occupation: 'Certified Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310098,
    OccDE: 'Elektrohandwerksmeister/in',
    Occupation: 'Certified Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310099,
    OccDE: 'Elektromeister/in',
    Occupation: 'Certified Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316105,
    OccDE: 'Elektromechanikermeister/in',
    Occupation: 'Certified Electromechanical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 313003,
    OccDE: 'Elektromaschinenbauermeister/in',
    Occupation: 'Certified Electromechanical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316006,
    OccDE: 'Elektronikmeister/in',
    Occupation: 'Certified Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316019,
    OccDE: 'Meister/in für Elektronik',
    Occupation: 'Certified Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 234009,
    OccDE: 'Galvaniseurmeister/in',
    Occupation: 'Certified Electroplater',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 255115,
    OccDE: 'Meister/in für Metalleicht- und Stahlbau',
    Occupation: 'Certified Engineer in Lightweight Steel Construction',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 294005,
    OccDE: 'Graveurmeister/in',
    Occupation: 'Certified Engraver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 772105,
    OccDE:
      'Fachkaufmann/-kauffrau Buchhaltung und Finanzwesen (Bilanzbuchhalter/in)',
    Occupation: 'Certified Financial Accountant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 771121,
    OccDE: 'Finanzwirt',
    Occupation: 'Certified Financial Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 765205,
    OccDE: 'Finanzwirt/in (Steuerverwaltungsdienst)',
    Occupation: 'Certified Financial Manager (Tax Administration Service)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: '053007',
    OccDE: 'Floristmeister/in',
    Occupation: 'Certified Florist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061107',
    OccDE: 'Forstmeister/in',
    Occupation: 'Certified Forestry Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062107',
    OccDE: 'Forstwirtschaftsmeister/in',
    Occupation: 'Certified Forestry Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 484103,
    OccDE: 'Ofenbaumeister/in',
    Occupation: 'Certified Furnace Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501309,
    OccDE: 'Möbeltischlermeister/in',
    Occupation: 'Certified Furniture Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 378305,
    OccDE: 'Kürschnermeister/in',
    Occupation: 'Certified Furrier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051020',
    OccDE: 'Gartenmeister/in',
    Occupation: 'Certified Gardener',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 267102,
    OccDE: 'Gas- und Wasserinstallateurmeister/in',
    Occupation: 'Certified Gas and Water Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 485101,
    OccDE: 'Glasermeister/in',
    Occupation: 'Certified Glazier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 302107,
    OccDE: 'Goldschmiedemeister/in',
    Occupation: 'Certified Goldsmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651508,
    OccDE: 'Netzmeister/in (Stromversorgung)',
    Occupation: 'Certified Grid Specialist (Power Supply)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300302,
    OccDE: 'Büchsenmachermeister/in',
    Occupation: 'Certified Gunsmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901015,
    OccDE: 'Meister/in des Friseurhandwerks',
    Occupation: 'Certified Hairdresser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901005,
    OccDE: 'Friseurmeister/in',
    Occupation: 'Certified Hairstylist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695906,
    OccDE: 'Krankenversicherungskaufmann/-kauffrau',
    Occupation: 'Certified Health Insurance Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268002,
    OccDE: 'Heizungs-, Lüftungs- und Sanitärtechnikmeister/in',
    Occupation: 'Certified Heating, Ventilation and Sanitary Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268004,
    OccDE: 'Meister/in für Heizungs-, Lüftungs- und Sanitärtechnik',
    Occupation: 'Certified Heating, Ventilation, and Sanitary Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921006,
    OccDE: 'Ökotropholog(e/in)',
    Occupation: 'Certified Home Economics and Food Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023840',
    OccDE: 'Pferdewirtschaftsmeister/in',
    Occupation: 'Certified Horse Trainer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023839',
    OccDE: 'Pferdewirtschaftsmeister/in (Teilbereich Pferdezucht, -haltung)',
    Occupation: 'Certified Horse Trainer (sub-area horse breeding, rearing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051011',
    OccDE: 'Gärtnermeister/in',
    Occupation: 'Certified Horticulturalist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914301,
    OccDE: 'Hotelmeister/in',
    Occupation: 'Certified Hotelier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921105,
    OccDE: 'Hauswirtschaftsmeister/in',
    Occupation: 'Certified Housekeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788309,
    OccDE: 'Personalfachwirt/in',
    Occupation: 'Certified HR Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651018,
    OccDE: 'Industriemeister/in',
    Occupation: 'Certified Industrial Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651215,
    OccDE: 'Industriemeister/in - Fachrichtung Chemie',
    Occupation: 'Certified Industrial Specialist specialising in Chemistry',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651505,
    OccDE: 'Industriemeister/in - Fachrichtung Elektrotechnik',
    Occupation:
      'Certified Industrial Specialist specialising in Electrical Engineering',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651506,
    OccDE: 'Industriemeister/in - Fachrichtung Energieanlagenelektronik',
    Occupation:
      'Certified Industrial Specialist specialising in Electrical Equipment Engineering',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651017,
    OccDE: 'Industriemeister/in - Fachrichtung Allgemeiner Betrieb',
    Occupation:
      'Certified Industrial Specialist specialising in General Operations',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651309,
    OccDE: 'Industriemeister/in - Fachrichtung Glas',
    Occupation: 'Certified Industrial Specialist specialising in Glass',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651406,
    OccDE: 'Industriemeister/in - Fachrichtung Maschinen- und Gerätebau',
    Occupation:
      'Certified Industrial Specialist specialising in Machine and Apparatus Engineering',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651407,
    OccDE: 'Industriemeister/in - Fachrichtung Mechanik',
    Occupation: 'Certified Industrial Specialist specialising in Mechanics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651408,
    OccDE: 'Industriemeister/in - Fachrichtung Metall',
    Occupation: 'Certified Industrial Specialist specialising in Metal',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651223,
    OccDE: 'Industriemeister/in - Fachrichtung Pharma',
    Occupation:
      'Certified Industrial Specialist specialising in Pharmaceuticals',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651219,
    OccDE: 'Industriemeister/in - Fachrichtung Kunststoff und Kautschuk',
    Occupation:
      'Certified Industrial Specialist specialising in Plastic and Rubber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651220,
    OccDE: 'Industriemeister/in - Fachrichtung Kunststoffverarbeitung',
    Occupation:
      'Certified Industrial Specialist specialising in Plastics Processing',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651809,
    OccDE: 'Industriemeister/in - Fachrichtung Textil',
    Occupation: 'Certified Industrial Specialist specialising in Textiles',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487109,
    OccDE: 'Montagebaumeister',
    Occupation: 'Certified Installation Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 267199,
    OccDE: 'Installationsmeister/in',
    Occupation: 'Certified Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 267107,
    OccDE: 'Installateurmeister/in (Gas/Wasser)',
    Occupation: 'Certified Installation Technician (Gas/Water)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482102,
    OccDE: 'Isolierermeister/in',
    Occupation: 'Certified Insulation Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695006,
    OccDE: 'Versicherungsfachmann/-fachfrau',
    Occupation: 'Certified Insurance Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695007,
    OccDE: 'Versicherungsfachwirt/in',
    Occupation: 'Certified Insurance Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 491101,
    OccDE: 'Raumausstattermeister/in',
    Occupation: 'Certified Interior Decorator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 142113,
    OccDE: 'Labormeister/in',
    Occupation: 'Certified Laboratory Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 351305,
    OccDE: 'Damenschneidermeister/in',
    Occupation: 'Certified Ladies Tailor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273015,
    OccDE: 'Maschinenbauermeister/in',
    Occupation: 'Certified Machinery Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 540018,
    OccDE: 'Maschinenmeister/in',
    Occupation: 'Certified Machinist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 270004,
    OccDE: 'Mechanikermeister/in',
    Occupation: 'Certified Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651412,
    OccDE: 'Maschinenbaumeister/in',
    Occupation: 'Certified Mechanical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651416,
    OccDE: 'Meister/in für Maschinenbau',
    Occupation: 'Certified Mechanical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254099,
    OccDE: 'Metallbaumeister/in',
    Occupation: 'Certified Metal Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201619,
    OccDE: 'Metallformer- und Metallgießermeister',
    Occupation: 'Certified Metal Mould Maker and Foundry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 256006,
    OccDE: 'Schmiedemeister/in',
    Occupation: 'Certified Metalsmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254008,
    OccDE: 'Schlossermeister/in',
    Occupation: 'Certified Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254005,
    OccDE: 'Schlosser- und Schmiedemeister/in',
    Occupation: 'Certified Metalworker and Blacksmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 435132,
    OccDE: 'Müllermeister/in',
    Occupation: 'Certified Miller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 502103,
    OccDE: 'Modellbauermeister/in',
    Occupation: 'Certified Model Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 318114,
    OccDE: 'Kraftfahrzeugelektrikermeister/in',
    Occupation: 'Certified Motor Vehicle Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 318115,
    OccDE: 'Kraftfahrzeugelektromechanikermeister/in',
    Occupation: 'Certified Motor Vehicle Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281014,
    OccDE: 'Kraftfahrzeuginstandhaltungsmeister/in',
    Occupation: 'Certified Motor Vehicle Maintenance Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281017,
    OccDE: 'Kraftfahrzeugmechanikermeister/in',
    Occupation: 'Certified Motor Vehicle Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281026,
    OccDE: 'Meister/in des Kraftfahrzeughandwerks',
    Occupation: 'Certified Motor Vehicle Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281099,
    OccDE: 'Kfz-Meister/in',
    Occupation: 'Certified Motor Vehicle Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 317502,
    OccDE: 'Büroinformationselektronikermeister/in',
    Occupation: 'Certified Office Information Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174221,
    OccDE: 'Offsetdruckermeister/in',
    Occupation: 'Certified Offset Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 304103,
    OccDE: 'Augenoptikermeister/in',
    Occupation: 'Certified Optometrist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052699',
    OccDE: 'Obstbaumeister/in',
    Occupation: 'Certified Orchardist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 305211,
    OccDE: 'Orgelbau(er)meister/in',
    Occupation: 'Certified Organ Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 372203,
    OccDE: 'Orthopädieschuhmachermeister/in',
    Occupation: 'Certified Orthopaedic Shoemaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511012,
    OccDE: 'Malermeister/in',
    Occupation: 'Certified Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 510201,
    OccDE: 'Maler- und Lackierermeister/in',
    Occupation: 'Certified Painter and Varnisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689313,
    OccDE: 'Pharmareferent/in',
    Occupation: 'Certified Pharmaceutical Sales Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837004,
    OccDE: 'Fotografenmeister/in',
    Occupation: 'Certified Photographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 305108,
    OccDE: 'Klavierbaumeister/in',
    Occupation: 'Certified Piano Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261007,
    OccDE: 'Flaschnermeister/in',
    Occupation: 'Certified Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261010,
    OccDE: 'Klempnermeister/in',
    Occupation: 'Certified Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261013,
    OccDE: 'Spenglermeister/in',
    Occupation: 'Certified Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269107,
    OccDE: 'Klempner- und Installateurmeister/in',
    Occupation: 'Certified Plumber and Installation Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121018,
    OccDE: 'Töpfermeister/in',
    Occupation: 'Certified Potter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 302012,
    OccDE: 'Meister/in für Edelmetallverarbeitung',
    Occupation: 'Certified Precious Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300010,
    OccDE: 'Feinmechanikermeister/in',
    Occupation: 'Certified Precision Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300013,
    OccDE: 'Feinwerktechnikmeister/in',
    Occupation: 'Certified Precision Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300021,
    OccDE: 'Meister/in für Feinwerktechnik',
    Occupation: 'Certified Precision Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135606,
    OccDE: 'Feinoptikermeister/in',
    Occupation: 'Certified Precision Optometrist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174007,
    OccDE: 'Druckermeister/in',
    Occupation: 'Certified Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 307105,
    OccDE: 'Orthopädiemechanikermeister/in',
    Occupation: 'Certified Prosthetist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 307101,
    OccDE: 'Meister/in des Orthopädiemechaniker- und Bandagistenhandwerks',
    Occupation: 'Certified Prosthetist and Surgical Truss Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 307102,
    OccDE: 'Orthopädiemechaniker- und Bandagistenmeister/in',
    Occupation: 'Certified Prosthetist and Surgical Truss Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765022,
    OccDE: 'Verwaltungswirt/in',
    Occupation: 'Certified Public Administrator',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 315111,
    OccDE: 'Radio- und Fernsehtechnikermeister/in',
    Occupation: 'Certified Radio and Television Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315116,
    OccDE: 'Rundfunk- und Fernsehmechanikermeister/in',
    Occupation: 'Certified Radio and Television Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801112,
    OccDE: 'Funker (Soldat)',
    Occupation: 'Certified Radio Operator (Soldier)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704515,
    OccDE: 'Immobilien-Fachwirt/in',
    Occupation: 'Certified Real Estate Specialist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 266103,
    OccDE: 'Kälteanlagenbauermeister/in',
    Occupation: 'Certified Refrigeration Systems Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 804201,
    OccDE: 'Bezirkskaminkehrermeister/in',
    Occupation: 'Certified Regional Chimney Sweep',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 804202,
    OccDE: 'Bezirksschornsteinfegermeister/in',
    Occupation: 'Certified Regional Chimney Sweep',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 259115,
    OccDE: 'Rolladen- und Jalousiebauermeister/in',
    Occupation: 'Certified Roller Shutter and Window Blind Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 488101,
    OccDE: 'Dachdeckermeister',
    Occupation: 'Certified Roofer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 374121,
    OccDE: 'Sattlermeister/in',
    Occupation: 'Certified Saddler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 267110,
    OccDE: 'Sanitärinstallateurmeister/in',
    Occupation: 'Certified Sanitary Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 486101,
    OccDE: 'Estrichlegermeister/in',
    Occupation: 'Certified Screed Layer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 175120,
    OccDE: 'Siebdruckermeister/in',
    Occupation: 'Certified Screen Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935403,
    OccDE: 'Abwassermeister/in',
    Occupation: 'Certified Sewage Treatment Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 372005,
    OccDE: 'Schuhmachermeister/in',
    Occupation: 'Certified Shoemaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712119,
    OccDE: 'Stellwerksmeister/in',
    Occupation: 'Certified Shunter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712208,
    OccDE: 'Rangiermeister/in',
    Occupation: 'Certified Shunter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 839101,
    OccDE: 'Schilder- und Lichtreklameherstellermeister/in',
    Occupation: 'Certified Signage and Neon Sign Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787316,
    OccDE: 'Sozialversicherungsfachwirt/in',
    Occupation: 'Certified Social Security Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 173612,
    OccDE: 'Stempelmachermeister/in',
    Occupation: 'Certified Stampmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101209,
    OccDE: 'Meister/in des Steinmetzhandwerks',
    Occupation: 'Certified Stonemason',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101213,
    OccDE: 'Steinmetzmeister/in',
    Occupation: 'Certified Stonemason',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 295104,
    OccDE: 'Chirurgiemechanikermeister/in',
    Occupation: 'Certified Surgical Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 624001,
    OccDE: 'Behördlich geprüfte(r) Vermessungstechniker/in',
    Occupation: 'Certified Surveying Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 351008,
    OccDE: 'Maßschneidermeister/in',
    Occupation: 'Certified Tailor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 351098,
    OccDE: 'Schneidermeister/in',
    Occupation: 'Certified Tailor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 753202,
    OccDE: 'Steuerberater/in',
    Occupation: 'Certified Tax Adviser',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 753001,
    OccDE: 'Steuerberater/in und Wirtschaftsprüfer/in',
    Occupation: 'Certified Tax Advisor and Auditor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 754109,
    OccDE: 'Steuerberatergehilf(e/in)',
    Occupation: 'Certified Tax Advisor Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 754110,
    OccDE: 'Steuerberatungsgehilf(e/in)',
    Occupation: 'Certified Tax Advisor Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 754504,
    OccDE: 'Steuerbetriebswirt/in',
    Occupation: 'Certified Tax Specialist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 312502,
    OccDE: 'Fernmeldeanlagenelektronikermeister/in',
    Occupation: 'Certified Telecommunications Systems Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315105,
    OccDE: 'Fernsehtechnikermeister/in',
    Occupation: 'Certified Television Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 484101,
    OccDE: 'Kachelofen- und Luftheizungsbauermeister/in',
    Occupation: 'Certified Tiled Stove and Air Heater Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 483103,
    OccDE: 'Fliesenlegermeister/in',
    Occupation: 'Certified Tiler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 483101,
    OccDE: 'Fliesen-, Platten- und Mosaiklegermeister/in',
    Occupation: 'Certified Tiler and Mosaic Layer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 181285,
    OccDE: 'Trockenmeister/in',
    Occupation: 'Certified Timber Drying Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 290013,
    OccDE: 'Werkzeugmachermeister/in',
    Occupation: 'Certified Toolmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 652201,
    OccDE: 'Ausbildungsmeister/in',
    Occupation: 'Certified Trainer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 221009,
    OccDE: 'Drehermeister/in',
    Occupation: 'Certified Turner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 171008,
    OccDE: 'Schriftsetzermeister/in',
    Occupation: 'Certified Typesetter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145841,
    OccDE: 'Vulkaniseurmeister/in',
    Occupation: 'Certified Tyre Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 492006,
    OccDE: 'Polster(er)meister/in',
    Occupation: 'Certified Upholsterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512009,
    OccDE: 'Lackierermeister/in',
    Occupation: 'Certified Varnisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287102,
    OccDE: 'Fahrzeugbauermeister/in',
    Occupation: 'Certified Vehicle Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 305409,
    OccDE: 'Geigenbau(er)meister/in',
    Occupation: 'Certified Violin Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 308011,
    OccDE: 'Uhrmachermeister/in',
    Occupation: 'Certified Watchmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 341012,
    OccDE: 'Webmeister/in',
    Occupation: 'Certified Weaver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 423316,
    OccDE: 'Weinküfermeister/in',
    Occupation: 'Certified Wine Cooper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '012017',
    OccDE: 'Winzermeister/in',
    Occupation: 'Certified Winemaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 185110,
    OccDE: 'Drechslermeister/in (Elfenbeinschnitzer/in)',
    Occupation: 'Certified Wood Turner (Wood Carver)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750225,
    OccDE: 'Vorstandsvorsitzende/r',
    Occupation: 'Chairman of the Management Board',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 141832,
    OccDE: 'Wachszieher/in',
    Occupation: 'Chandler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 294411,
    OccDE: 'Ziseleur/in',
    Occupation: 'Chaser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714203,
    OccDE: 'Chauffeur/in',
    Occupation: 'Chauffeur',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702403,
    OccDE: 'Check-in-Agent/in',
    Occupation: 'Check-in Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702406,
    OccDE: 'Fluggastabfertiger/in',
    Occupation: 'Check-in Desk Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702402,
    OccDE: 'Bodensteward/eß',
    Occupation: 'Check-in Desk Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661902,
    OccDE: 'Käseverkäufer/in',
    Occupation: 'Cheese Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 431312,
    OccDE: 'Käser/in',
    Occupation: 'Cheesemaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 431710,
    OccDE: 'Käsereiarbeiter/in',
    Occupation: 'Cheesemaking Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411008,
    OccDE: 'Chefkoch/-köchin',
    Occupation: 'Chef',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411030,
    OccDE: 'Küchenchef/in',
    Occupation: 'Chef de Cuisine',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912204,
    OccDE: 'Chef de rang (Abteilungskellner/in)',
    Occupation: 'Chef de Rang (Department Server)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912105,
    OccDE: 'Chefsteward/eß',
    Occupation: 'Chef Steward',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 611808,
    OccDE: 'Chemieingenieur/in',
    Occupation: 'Chemical Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 611809,
    OccDE: 'Chemiker-Ingenieur/in',
    Occupation: 'Chemical Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 626107,
    OccDE: 'Chemotechniker/in',
    Occupation: 'Chemical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626108,
    OccDE: 'Chemotechnische(r) Assistent/in',
    Occupation: 'Chemical Engineering Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 142101,
    OccDE: 'Chemielaborfacharbeiter/in',
    Occupation: 'Chemical Laboratory Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 142104,
    OccDE: 'Chemielaborwerker/in',
    Occupation: 'Chemical Laboratory Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 612906,
    OccDE: 'Chemische(r) Physiker/in',
    Occupation: 'Chemical Physicist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 141004,
    OccDE: 'Chemiebetriebsfachwerker/in',
    Occupation: 'Chemical Plant Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141006,
    OccDE: 'Chemiebetriebswerker/in',
    Occupation: 'Chemical Plant Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141013,
    OccDE: 'Facharbeiter/in für chemische Produktion',
    Occupation: 'Chemical Production Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141010,
    OccDE: 'Chemiewerker/in',
    Occupation: 'Chemical Production Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671211,
    OccDE: 'Chemiekaufmann/-kauffrau',
    Occupation: 'Chemical Salesperson',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 141007,
    OccDE: 'Chemiefacharbeiter/in',
    Occupation: 'Chemical Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141715,
    OccDE: 'Chemiearbeiter/in',
    Occupation: 'Chemical Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 611002,
    OccDE: 'Chemiker/in',
    Occupation: 'Chemist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 141011,
    OccDE: 'Chemikant/in',
    Occupation: 'Chemist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 611099,
    OccDE: 'Diplom-Chemiker/in',
    Occupation: 'Chemist with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 626103,
    OccDE: 'Chemieassistent/in',
    Occupation: 'Chemistry Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626106,
    OccDE: 'Chemische(r) Assistent/in',
    Occupation: 'Chemistry Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626105,
    OccDE: 'Chemisch-technische(r) Assistent/in',
    Occupation: 'Chemistry Engineering Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626104,
    OccDE: 'Chemisch-technische(r) Angestellte(r)',
    Occupation: 'Chemistry Engineering Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633001,
    OccDE: 'Chemielaborant/in',
    Occupation: 'Chemistry Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633002,
    OccDE: 'Chemolaborant/in',
    Occupation: 'Chemistry Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981115,
    OccDE: 'Student/in Chemie',
    Occupation: 'Chemistry Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141008,
    OccDE: 'Chemiefachwerker/in',
    Occupation: 'Chemistry Technical Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626102,
    OccDE: 'Chemie-Techniker/in',
    Occupation: 'Chemistry Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 772009,
    OccDE: 'Hauptbuchhalter/in',
    Occupation: 'Chief Accountant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 841014,
    OccDE: 'Chefarzt/-ärztin',
    Occupation: 'Chief Doctor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 856406,
    OccDE: 'Chefarztsekretär/in',
    Occupation: "Chief Doctor's Secretary",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801314,
    OccDE: 'Kriminaloberkommissar/in',
    Occupation: 'Chief Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 811169,
    OccDE: 'Vorsitzende(r) Richter/in am (...)gericht',
    Occupation: 'Chief Judge of the (...) Court',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 721214,
    OccDE: 'Nautische(r) Schiffsoffizier/in',
    Occupation: 'Chief Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721207,
    OccDE: 'Nautische(r) Offizier/in (Schiffahrt)',
    Occupation: 'Chief Officer (Maritime)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721209,
    OccDE: 'Nautische(r) Schiffsoffizier/in auf Großer Fahrt',
    Occupation: 'Chief Officer on Long Haul Voyages',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801141,
    OccDE: 'Obermaat (Bundeswehr)',
    Occupation: 'Chief Petty Officer (German Armed Forces)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061323',
    OccDE: 'Revierleiter/in (Förster/in)',
    Occupation: 'Chief Ranger (Forestry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801310,
    OccDE: 'Kriminalhauptkommissar/in',
    Occupation: 'Chief Superintendent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 867701,
    OccDE: 'Kinderpflegehelfer/in',
    Occupation: 'Child Care Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 867005,
    OccDE: 'Kinderpfleger/in',
    Occupation: 'Child Care Provider',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923803,
    OccDE: 'Kindermädchen',
    Occupation: 'Child Care Provider',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 886303,
    OccDE: 'Kinderpsycholog(e/in)',
    Occupation: 'Child Psychologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 863007,
    OccDE: 'Kinderpfleger/in (Erzieher/in)',
    Occupation: 'Childcare Worker (Educator)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 867002,
    OccDE: 'Kinderbetreuer/in',
    Occupation: 'Childminder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853208,
    OccDE: 'Kinderschwester',
    Occupation: "Children's Nurse",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853207,
    OccDE: 'Kinderpfleger/in (Krankenpfleger/in)',
    Occupation: "Children's Nurse (Nursing)",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441436,
    OccDE: 'Schornsteinbauer',
    Occupation: 'Chimney Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441438,
    OccDE: 'Schornsteinmaurer',
    Occupation: 'Chimney Mason',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 804104,
    OccDE: 'Kaminfeger/in',
    Occupation: 'Chimney Sweep',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 804105,
    OccDE: 'Kaminkehrer/in',
    Occupation: 'Chimney Sweep',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 804109,
    OccDE: 'Schornsteinfeger/in',
    Occupation: 'Chimney Sweep',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321916,
    OccDE: 'Chipbestücker/in',
    Occupation: 'Chip Assembly Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 220099,
    OccDE: 'Zerspaner/in',
    Occupation: 'Chipper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902301,
    OccDE: 'Fußpfleger/in',
    Occupation: 'Chiropodist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 851106,
    OccDE: 'Chiropraktiker/in',
    Occupation: 'Chiropractor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 393310,
    OccDE: 'Pralinenmacher/in',
    Occupation: 'Chocolates Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831208,
    OccDE: 'Chorleiter/in',
    Occupation: 'Choir Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832511,
    OccDE: 'Chorsänger/in',
    Occupation: 'Choir Singer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832308,
    OccDE: 'Choreograph/in',
    Occupation: 'Choreographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 894103,
    OccDE: 'Gemeindediakon/in',
    Occupation: 'Church Deacon',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765916,
    OccDE: 'Kirchenpfleger/in (Verwaltungsbedienstete/r)',
    Occupation: 'Church Deputy Director (Public Administration)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 765915,
    OccDE: 'Kircheninspektor/in',
    Occupation: 'Church Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 831604,
    OccDE: 'Kirchenmusiker/in',
    Occupation: 'Church Musician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511903,
    OccDE: 'Kirchenmaler/in',
    Occupation: 'Church Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 424212,
    OccDE: 'Zigarrenmacher/in',
    Occupation: 'Cigar Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 706117,
    OccDE: 'Kinokassierer/in',
    Occupation: 'Cinema Cashier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835403,
    OccDE: 'Bildgestalter/in (Filmproduktion)',
    Occupation: 'Cinematographer (Film Production)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321951,
    OccDE: 'Platinenbestücker/in',
    Occupation: 'Circuit Board Assembly Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787632,
    OccDE: 'Stadtassistent/in',
    Occupation: 'City Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 784344,
    OccDE: 'Stadtkurier/in',
    Occupation: 'City Courier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765622,
    OccDE: 'Stadt(amtmann/amtfrau)',
    Occupation: 'City Deputy Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 764622,
    OccDE: 'Stadtverwaltungs(rat/rätin)',
    Occupation: 'City Director General',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 780199,
    OccDE: 'Städtische Angestellte/r',
    Occupation: 'City Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787631,
    OccDE: 'Stadtamtsinspektor/in',
    Occupation: 'City Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787634,
    OccDE: 'Stadtobersekretär/in',
    Occupation: 'City Higher Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765624,
    OccDE: 'Stadtinspektor/in',
    Occupation: 'City Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 609311,
    OccDE: 'Stadtplaner/in',
    Occupation: 'City Planner',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 609315,
    OccDE: 'Städteplaner/in',
    Occupation: 'City Planner',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787635,
    OccDE: 'Stadtsekretär/in',
    Occupation: 'City Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787633,
    OccDE: 'Stadthauptsekretär/in',
    Occupation: 'City Senior Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765626,
    OccDE: 'Stadtoberinspektor/in',
    Occupation: 'City Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 764620,
    OccDE: 'Stadtkämmer(er/in)',
    Occupation: 'City Treasurer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 472102,
    OccDE: 'Stadtarbeiter',
    Occupation: 'City Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600006,
    OccDE: 'Zivilingenieur/in',
    Occupation: 'Civil Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603001,
    OccDE: 'Bauingenieur/in',
    Occupation: 'Civil Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603202,
    OccDE: 'Bauingenieur/in (Brücken- und Tunnelbau)',
    Occupation: 'Civil Engineer (Bridge and Tunnel Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603201,
    OccDE: 'Bauingenieur/in (Baukonstruktion)',
    Occupation: 'Civil Engineer (Building Construction)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603301,
    OccDE: 'Bauingenieur/in (Hochbau)',
    Occupation: 'Civil Engineer (Building construction)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603203,
    OccDE: 'Bauingenieur/in (Ingenieurbau)',
    Occupation: 'Civil Engineer (Civil Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603904,
    OccDE: 'Bauingenieur/in (Technologie der Bauproduktion)',
    Occupation: 'Civil Engineer (Construction Technology)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603905,
    OccDE: 'Bauingenieur/in (Umwelttechnik)',
    Occupation: 'Civil Engineer (Environmental Technology)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603903,
    OccDE: 'Bauingenieur/in (Informationsverarbeitung im Bauwesen)',
    Occupation: 'Civil Engineer (Information Processing in Construction)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '052901',
    OccDE: 'Bauingenieur/in (Landschafts- und Grünanlagenbau)',
    Occupation: 'Civil Engineer (Landscape and Green Space)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603809,
    OccDE: 'Bauingenieur/in (kommunaler Tiefbau)',
    Occupation: 'Civil Engineer (Municipal Subterranean Projects)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606501,
    OccDE: 'Bauingenieur/in (Vorfertigung im Bauwesen)',
    Occupation: 'Civil Engineer (Prefabrication in Construction)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603902,
    OccDE:
      'Bauingenieur/in (Herstellung von Bauerzeugnissen und Konstruktionen)',
    Occupation:
      'Civil Engineer (Production of Building Products and Structures)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603901,
    OccDE: 'Bauingenieur/in (Gesellschafts-, Industrie-, Landwirtschaftsbau)',
    Occupation: 'Civil Engineer (Public, Industrial, Agricultural Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603402,
    OccDE: 'Bauingenieur/in (Eisenbahnbau, Streckenwirtschaft)',
    Occupation: 'Civil Engineer (Railway, Route Management)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 609302,
    OccDE: 'Bauingenieur/in (Gebiets-, Stadtplanung)',
    Occupation: 'Civil Engineer (Regional, Urban Planning)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603408,
    OccDE: 'Tiefbauingenieur/in (Straßenbau)',
    Occupation: 'Civil Engineer (Road Construction)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 623405,
    OccDE: 'Tiefbautechniker/in (Straßenbau)',
    Occupation: 'Civil Engineer (Road Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603403,
    OccDE: 'Bauingenieur/in (Straßenbau, -verkehr, Verkehrsbau)',
    Occupation: 'Civil Engineer (Roads, Traffic, Road Construction)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603906,
    OccDE: 'Bauingenieur/in (Unterbau, Fundamente, Tiefbau)',
    Occupation: 'Civil Engineer (Substructure, Foundations, Civil Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 609303,
    OccDE: 'Bauingenieur/in (Städtebau und Wirtschaft)',
    Occupation: 'Civil Engineer (Urban Planning and Economy)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603502,
    OccDE: 'Bauingenieur/in (Wasserbau, -wirtschaft)',
    Occupation: 'Civil Engineer (Water Engineering, Management)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603099,
    OccDE: 'Diplom-Bauingenieur/in',
    Occupation: 'Civil Engineer with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 460203,
    OccDE: 'Tiefbauvorarbeiter',
    Occupation: 'Civil Engineering Construction Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 460308,
    OccDE: 'Tiefbaupolier/in',
    Occupation: 'Civil Engineering Construction Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 460307,
    OccDE: 'Tiefbaumeister',
    Occupation: 'Civil Engineering Construction Site Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 460105,
    OccDE: 'Tiefbaufacharbeiter',
    Occupation: 'Civil Engineering Construction Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 460106,
    OccDE: 'Tiefbauspezialfacharbeiter',
    Occupation: 'Civil Engineering Construction Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 460103,
    OccDE: 'Tiefbauarbeiter',
    Occupation: 'Civil Engineering Construction Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 460104,
    OccDE: 'Tiefbauer',
    Occupation: 'Civil Engineering Construction worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750112,
    OccDE: 'Tiefbauunternehmer/in',
    Occupation: 'Civil Engineering Contractor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 546611,
    OccDE: 'Tiefbaumaschinist/in',
    Occupation: 'Civil Engineering Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466411,
    OccDE: 'Tiefbaufacharbeiter (Rohrleitungsbau)',
    Occupation: 'Civil Engineering Specialist (Pipeline Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981108,
    OccDE: 'Student/in Bauingenieurswesen',
    Occupation: 'Civil Engineering Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461009,
    OccDE: 'Tiefbaufacharbeiter (Straßenbau)',
    Occupation: 'Civil Engineering Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 460404,
    OccDE: 'Tiefbaufachwerker/in',
    Occupation: 'Civil Engineering Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787099,
    OccDE: 'Beamt(er/in)',
    Occupation: 'Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '031102',
    OccDE: 'Beamt(er/in) (landw. Verwaltung)',
    Occupation: 'Civil Servant (Agricultural Administration)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765004,
    OccDE: 'Beamt(er/in) (gehobener nichttechnischer Dienst)',
    Occupation: 'Civil Servant (higher non-technical civil service grade)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 608101,
    OccDE: 'Beamt(er/in) (gehobener technischer Dienst)',
    Occupation: 'Civil Servant (higher technical grade)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 764014,
    OccDE: 'Beamt(er/in) (höherer nichttechnischer Dienst)',
    Occupation: 'Civil Servant (highest non-technical civil service grade)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 608102,
    OccDE: 'Beamt(er/in) (höherer technischer Dienst)',
    Occupation: 'Civil Servant (highest technical grade)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787006,
    OccDE: 'Beamt(er/in) (mittlerer nichttechnischer Dienst)',
    Occupation: 'Civil Servant (mid-level grade non-technical service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861015,
    OccDE: 'Sozial(amtmann/amtfrau)',
    Occupation: 'Civil Servant (Social Work)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 784403,
    OccDE: 'Beamt(er/in) (einfacher nichttechnischer Dienst)',
    Occupation: 'Civil Servant (standard non-technical civil service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629101,
    OccDE: 'Beamt(er/in) (mittlerer technischer Dienst)',
    Occupation: 'Civil Service Officer (mid-level technical grade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787098,
    OccDE: 'Beamtenanwärter/in',
    Occupation: 'Civil Service Referendary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744940,
    OccDE: 'Zivilarbeiter/in (Bundeswehr)',
    Occupation: 'Civilian Workers (Armed Forces)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695522,
    OccDE: 'Schadenregulierer/in (Versicherung)',
    Occupation: 'Claims Adjuster (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695523,
    OccDE: 'Schadensachbearbeiter/in (Versicherung)',
    Occupation: 'Claims Adjuster (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934334,
    OccDE: 'Reinemachemann/Reinemachefrau',
    Occupation: 'Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 937499,
    OccDE: 'Reiniger/in',
    Occupation: 'Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934330,
    OccDE: 'Putzhilfe',
    Occupation: 'Cleaning Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934099,
    OccDE: 'Reinigungsunternehmer/in',
    Occupation: 'Cleaning Contractor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 937001,
    OccDE: 'Reinigungsarbeiter/in',
    Occupation: 'Cleaning Crew Member',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 937002,
    OccDE: 'Reinigungsfachkraft',
    Occupation: 'Cleaning Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934335,
    OccDE: 'Reinigungsmann/Reinigungsfrau',
    Occupation: 'Cleaning Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 937003,
    OccDE: 'Reinigungskraft',
    Occupation: 'Cleaning Staff Member',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 937904,
    OccDE: 'Dekontfacharbeiter/in',
    Occupation: 'Cleaning Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 670003,
    OccDE: 'Kaufmann/Kauffrau',
    Occupation: 'Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701005,
    OccDE: 'Expedient/in',
    Occupation: 'Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780303,
    OccDE: 'Kontorist/in',
    Occupation: 'Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788002,
    OccDE: 'Sachbearbeiter/in',
    Occupation: 'Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701101,
    OccDE: 'Expedient/in (Speditionskaufmann/-kauffrau)',
    Occupation: 'Clerk (Forwarding Employee)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765019,
    OccDE: 'Sachbearbeiter/in (gehobener nichttechnischer Verwaltungsdienst)',
    Occupation: 'Clerk (higher non-technical administrative service)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 672999,
    OccDE: 'Kauf(mann/frau)(Ladengeschäft)',
    Occupation: 'Clerk (Shop)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683206,
    OccDE: 'Kaufmann/Kauffrau im Zeitungsverlag',
    Occupation: 'Clerk at a Newspaper Publisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683205,
    OccDE: 'Kaufmann/Kauffrau im Zeitungs- und Zeitschriftenverlag',
    Occupation: 'Clerk for a Newspaper and Magazine Publisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701506,
    OccDE: 'Kaufmann/Kauffrau im Eisenbahn- und Straßenverkehr',
    Occupation: 'Clerk for a Rail Transport and Road Haulage',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701305,
    OccDE: 'Kaufmann/Kauffrau im Reederei- und Schiffsmaklergewerbe',
    Occupation: 'Clerk for a Shipping and Ship Brokering Company',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 779196,
    OccDE: 'Kaufmann/Kauffrau für                EDV-Systemtechnik',
    Occupation: 'Clerk for EDP Systems Technology',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776119,
    OccDE: 'Sachbearbeiter/in Hardware/Software-Planung',
    Occupation: 'Clerk for Hardware Software Planning',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 780302,
    OccDE: 'Kaufmann/Kauffrau für Bürokommunikation',
    Occupation: 'Clerk for Office Communications',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 776120,
    OccDE: 'Sachbearbeiter/in Projektsteuerung (EDV)',
    Occupation: 'Clerk for Project Management (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 677109,
    OccDE: 'Fachkaufmann/-kauffrau für Einkauf/Materialwirtschaft',
    Occupation: 'Clerk for Purchasing/Materials Logistics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704504,
    OccDE: 'Fachwirt/in für Immobilien',
    Occupation: 'Clerk for Real Estate',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 702002,
    OccDE: 'Fachwirt/in für Tourismus',
    Occupation: 'Clerk for Tourism',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 702999,
    OccDE: 'Kaufmann/Kauffrau für Verkehsservice',
    Occupation: 'Clerk for Transport Services',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 755003,
    OccDE: 'Fachkaufmann/-kauffrau für Marketing',
    Occupation: 'Clerk in Marketing',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 672005,
    OccDE: 'Kaufmann/Kauffrau im Einzelhandel',
    Occupation: 'Clerk in Retail',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704503,
    OccDE: 'Fachwirt/in für die Grundstücks- und Wohnungswirtschaft',
    Occupation: 'Clerk in the Real Estate and Residential Housing Sector',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 704525,
    OccDE: 'Kaufmann/Kauffrau in der Grundstücks- und Wohnungswirtschaft',
    Occupation: 'Clerk in the Real Estate and Residential Housing Sector',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 691017,
    OccDE: 'Kundenberater/in (Bank, Sparkasse)',
    Occupation: 'Client Advisor (Bank, Savings Bank)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841957,
    OccDE: 'Laborarzt/-ärztin',
    Occupation: 'Clinical Pathologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 441212,
    OccDE: 'Klinkermaurer',
    Occupation: 'Clinker Mason',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931211,
    OccDE: 'Kleiderreiniger/in',
    Occupation: 'Clothes Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550301,
    OccDE: 'CNC-Automateneinsteller/in',
    Occupation: 'CNC Automatic Machinery Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550302,
    OccDE: 'CNC-Einrichter/in',
    Occupation: 'CNC Automatic Machinery Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550303,
    OccDE: 'CNC-Maschineneinrichter/in',
    Occupation: 'CNC Automatic Machinery Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550304,
    OccDE: 'CNC-Maschineneinsteller/in',
    Occupation: 'CNC Automatic Machinery Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 224106,
    OccDE: 'CNC-Bohrwerkdreher/in',
    Occupation: 'CNC Boring Mill Lathe Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 224107,
    OccDE: 'CNC-Bohrwerker/in',
    Occupation: 'CNC Boring Mill Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 224013,
    OccDE: 'CNC-Bohrer/in',
    Occupation: 'CNC Drill Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549507,
    OccDE: 'CNC-Maschinenführer/in',
    Occupation: 'CNC Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620403,
    OccDE: 'CNC-Anlagentechniker/in',
    Occupation: 'CNC Equipment Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 225004,
    OccDE: 'CNC-Schleifer/in',
    Occupation: 'CNC Grinding Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549506,
    OccDE: 'CNC-Maschinenbediener/in',
    Occupation: 'CNC Machinery Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323102,
    OccDE: 'CNC-Maschinenbediener/in (Metallverarbeitung)',
    Occupation: 'CNC Machinery Operator (Metalworking)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620404,
    OccDE: 'CNC-Maschinentechniker/in',
    Occupation: 'CNC Machinery Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 276902,
    OccDE: 'CNC-Mechaniker/in',
    Occupation: 'CNC Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 222004,
    OccDE: 'CNC-Fräser/in',
    Occupation: 'CNC Milling Cutter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549502,
    OccDE: 'CNC-Bediener/in',
    Occupation: 'CNC Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549508,
    OccDE: 'CNC-Operator/in',
    Occupation: 'CNC Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549501,
    OccDE: 'CNC-Bearbeitungsmaschinenbediener/in',
    Occupation: 'CNC Processing Machinery Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628222,
    OccDE: 'CNC-Programmgestalter/in (Techniker/in)',
    Occupation: 'CNC Programme Designer (Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 628223,
    OccDE: 'CNC-Programmierer/in (Techniker/in)',
    Occupation: 'CNC Programmer (Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 549504,
    OccDE: 'CNC-Fachkraft',
    Occupation: 'CNC Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549503,
    OccDE: 'CNC-Facharbeiter/in',
    Occupation: 'CNC Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620405,
    OccDE: 'CNC-Techniker/in',
    Occupation: 'CNC Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 221004,
    OccDE: 'CNC-Dreher/in',
    Occupation: 'CNC Turner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 607208,
    OccDE: 'CNC-Programmierer/in (Ingenieur/in)',
    Occupation: 'CND Programmer (Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 726104,
    OccDE: 'Co-Pilot/in',
    Occupation: 'Co-Pilot',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876011,
    OccDE: 'Coach (Trainer/in)',
    Occupation: 'Coach (Trainer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626201,
    OccDE: 'Beschichtungstechniker/in',
    Occupation: 'Coating Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 372099,
    OccDE: 'Schuster/in',
    Occupation: 'Cobbler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 775005,
    OccDE: 'Codierer/in (EDV)',
    Occupation: 'Coder (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775008,
    OccDE: 'Kodierer/in (EDV)',
    Occupation: 'Coder (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 211112,
    OccDE: 'Kaltpresser/in',
    Occupation: 'Cold Compression Moulding',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 213204,
    OccDE: 'Metallkaltverformer/in',
    Occupation: 'Cold Forming Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 194333,
    OccDE: 'Kaltwalzer/in',
    Occupation: 'Cold Rolling Machine Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 786902,
    OccDE:
      'Mahn- und Vollstreckungssachbearbeiter/in (Anwaltskanzlei, Notariat)',
    Occupation: 'Collection Officer (Law Firm, Notary Office)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 706123,
    OccDE: 'Sperrkassierer/in',
    Occupation: 'Collections Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834107,
    OccDE: 'Farb- und Oberflächengestalter/in',
    Occupation: 'Colour and Surface Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141210,
    OccDE: 'Farbmischer/in',
    Occupation: 'Colour Mixer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626202,
    OccDE: 'Farbentechniker/in',
    Occupation: 'Colour Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801192,
    OccDE: 'Kommando-Spezial-Kraft',
    Occupation: 'Command Special Force',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801306,
    OccDE: 'Hauptkommissar/in',
    Occupation: 'Commander',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821511,
    OccDE: 'Kommentator/in (Rundfunk, Fernsehen)',
    Occupation: 'Commentator (Radio, Television)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 881901,
    OccDE: 'Diplom-Handelswissenschaftler/in',
    Occupation: 'Commerce and Trade Specialist with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 772002,
    OccDE: 'Betriebsbuchhalter/in',
    Occupation: 'Commercial Accountant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 687104,
    OccDE: 'Handelsagent/in',
    Occupation: 'Commercial Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834601,
    OccDE: 'Gebrauchsgrafiker/in',
    Occupation: 'Commercial Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785106,
    OccDE: 'Industriekaufmann/-kauffrau',
    Occupation: 'Commercial Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 757004,
    OccDE: 'Industrieberater/in',
    Occupation: 'Commercial Consultant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 981180,
    OccDE: 'Student/in Wirtschaftsrecht',
    Occupation: 'Commercial Law Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 813410,
    OccDE: 'Wirtschaftsjurist/in',
    Occupation: 'Commercial Lawyer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 522104,
    OccDE: 'Handelsfachpacker/in',
    Occupation: 'Commercial Packer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511004,
    OccDE: 'Betriebsmaler/in',
    Occupation: 'Commercial Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837106,
    OccDE: 'Werbefotograf/in',
    Occupation: 'Commercial photographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726121,
    OccDE: 'Berufspilot',
    Occupation: 'Commercial Pilot',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 687105,
    OccDE: 'Handelsvertreter/in',
    Occupation: 'Commercial Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689023,
    OccDE: 'Handelsreisende/r',
    Occupation: 'Commercial Salesman (Field Work)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689011,
    OccDE: 'Handlungsreisende/r',
    Occupation: 'Commercial Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 492002,
    OccDE: 'Industriepolster(er/in)',
    Occupation: 'Commercial Upholsterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912004,
    OccDE: 'Commis de rang (Jungkellner/in)',
    Occupation: 'Commis de Rang (Waiter)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705335,
    OccDE: 'Kommissionär/in',
    Occupation: 'Commissioner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522916,
    OccDE: 'Kommissionierer/in',
    Occupation: 'Commissioning Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600405,
    OccDE: 'Inbetriebnahmeingenieur/in',
    Occupation: 'Commissioning Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602805,
    OccDE: 'Inbetriebnahmeingenieur/in (Elektrotechnik)',
    Occupation: 'commissioning Engineer (Electrical Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600406,
    OccDE: 'Inbetriebnahmeleiter/in',
    Occupation: 'Commissioning Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 620407,
    OccDE: 'Inbetriebnahmetechniker/in',
    Occupation: 'Commissioning Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 622802,
    OccDE: 'Inbetriebsetzer/in (Elektrotechniker/in)',
    Occupation: 'Commissioning Technician (Electrical Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 879306,
    OccDE: 'Kommunikationstrainer/in',
    Occupation: 'Communication Coach',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 886201,
    OccDE: 'Kommunikationspsycholog(e/in)',
    Occupation: 'Communication Psychologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 834318,
    OccDE: 'Kommunikationsdesigner/in',
    Occupation: 'Communications Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 317108,
    OccDE: 'Kommunikationselektroniker/in (Fachrichtung Informationstechnik)',
    Occupation:
      'Communications Electrician (specialising in Information Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312101,
    OccDE:
      'Kommunikationselektroniker/in (Fachrichtung Telekommunikationstechnik)',
    Occupation:
      'Communications Electrician (specialising in Telecommunications Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312209,
    OccDE: 'Kommunikationselektroniker/in (Post)',
    Occupation: 'Communications Electronics (Post)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 317001,
    OccDE: 'Kommunikationselektroniker/in',
    Occupation: 'Communications Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 317215,
    OccDE: 'Kommunikationselektroniker/in (Fachrichtung Funktechnik)',
    Occupation:
      'Communications Electronics Technician (specialising in Radio Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879398,
    OccDE: 'Kommunikationsmanager/in',
    Occupation: 'Communications Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778105,
    OccDE: 'Communications Operator',
    Occupation: 'Communications Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756120,
    OccDE: 'Kommunikationsorganisator/in',
    Occupation: 'Communications Organiser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 775109,
    OccDE: 'Kommunikationsprogrammierer/in',
    Occupation: 'Communications Programmer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 882399,
    OccDE: 'Kommunikationswissenschaftler/in',
    Occupation: 'Communications Scholar',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 755099,
    OccDE: 'Kommunikationswirt/in',
    Occupation: 'Communications Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 317099,
    OccDE: 'Diplom-Kommunikationswirt/in',
    Occupation: 'Communications Specialist with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622211,
    OccDE: 'Nachrichtentechniker/in',
    Occupation: 'Communications Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 622905,
    OccDE: 'Kommunikationstechniker/in',
    Occupation: 'Communications Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 317109,
    OccDE: 'Kommunikationstechniker/in (Informationselektroniker/in)',
    Occupation:
      'Communications Technician (Information Electronics Technician)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 865004,
    OccDE: 'Dorfhelfer/in',
    Occupation: 'Community Helper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765610,
    OccDE: 'Gemeindeoberinspektor/in',
    Occupation: 'Community Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 472101,
    OccDE: 'Gemeindearbeiter',
    Occupation: 'Community Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921507,
    OccDE: 'Gesellschafterin',
    Occupation: 'Companion',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756229,
    OccDE: 'Unternehmens-Controller/in',
    Occupation: 'Company Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756231,
    OccDE: 'Werk(s)-Controller/in',
    Occupation: 'Company Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841941,
    OccDE: 'Betriebsarzt/-ärztin',
    Occupation: 'Company Doctor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 714913,
    OccDE: 'Werksfahrer/in',
    Occupation: 'Company Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 813406,
    OccDE: 'Syndikus',
    Occupation: 'Company Lawyer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 756150,
    OccDE: 'Unternehmensplaner/in',
    Occupation: 'Company Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791104,
    OccDE: 'Werkschutz',
    Occupation: 'Company Security',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791112,
    OccDE: 'Werkschutzmitarbeiter/in',
    Occupation: 'Company Security Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791109,
    OccDE: 'Werkschutzleiter/in',
    Occupation: 'Company Security Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791111,
    OccDE: 'Werkschutzmeister/in',
    Occupation: 'Company Security Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791110,
    OccDE: 'Werkschutzmann/Werkschutzfrau',
    Occupation: 'Company Security Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791105,
    OccDE: 'Werkschutz-Fachmann/Fachfrau',
    Occupation: 'Company Security Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791108,
    OccDE: 'Werkschutzkraft',
    Occupation: 'Company Security Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791113,
    OccDE: 'Werksicherheitsdienst-Mitarbeiter/in',
    Occupation: 'Company Security Staff Member',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791107,
    OccDE: 'Werkschutzfachkraft',
    Occupation: 'Company Skilled Security Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765919,
    OccDE: 'Kontrollbeamt(er/in)',
    Occupation: 'Compliance Civil Servant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 831102,
    OccDE: 'Komponist/in',
    Occupation: 'Composer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873404,
    OccDE: 'Gesamtschullehrer/in',
    Occupation: 'Comprehensive School Teacher',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621904,
    OccDE: 'Drucklufttechniker/in',
    Occupation: 'Compressed Air Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 211120,
    OccDE: 'Pressmaschinenführer/in',
    Occupation: 'Compression Mould Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 211105,
    OccDE: 'Blechpresser/in',
    Occupation: 'Compression Moulder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152426,
    OccDE: 'Presser/in (Kunststoffverarbeitung)',
    Occupation: 'Compression Moulder (Plastics Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774201,
    OccDE: 'Computerbearbeiter/in',
    Occupation: 'Computer Administrator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 673203,
    OccDE: 'Computer-Händler/in (Einzelhandel)',
    Occupation: 'Computer Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671212,
    OccDE: 'Computer-Händler/in (Großhandel)',
    Occupation: 'Computer Dealer (Wholesale)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 641110,
    OccDE: 'Computerzeichner/in',
    Occupation: 'Computer Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 317101,
    OccDE: 'Computerelektroniker/in',
    Occupation: 'Computer Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602413,
    OccDE: 'Ingenieurinformatiker/in',
    Occupation: 'Computer Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 834901,
    OccDE: 'Computergrafiker/in',
    Occupation: 'Computer Graphic Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778303,
    OccDE: 'Computereinsteller/in',
    Occupation: 'Computer Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774404,
    OccDE: 'Computer Management Assistent/in',
    Occupation: 'Computer Management Assistant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778107,
    OccDE: 'Computersteuer(er/in)',
    Occupation: 'Computer Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778106,
    OccDE: 'Computer-Operator/in',
    Occupation: 'Computer Operator/in',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774408,
    OccDE: 'Informatikassistent/in',
    Occupation: 'Computer Science Assistant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 779195,
    OccDE: 'Informatikkaufmann/-kauffrau',
    Occupation: 'Computer Science Clerk',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 622312,
    OccDE: 'Informatiktechniker/in',
    Occupation: 'Computer Science Engineer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 602412,
    OccDE: 'Informatikingenieur/in',
    Occupation: 'Computer Science Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 622323,
    OccDE: 'Technikinformatiker/in',
    Occupation: 'Computer Scientist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 774205,
    OccDE: 'Informatiker/in',
    Occupation: 'Computer Scientist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 774204,
    OccDE: 'Informatiker/in (EDV)',
    Occupation: 'Computer Scientist (EDP)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 774202,
    OccDE: 'Diplom-Informatiker/in (EDV)',
    Occupation: 'Computer Scientist (EDP) with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 612401,
    OccDE: 'Informatiker/in (nicht EDV)',
    Occupation: 'Computer Scientist (not EDP)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 774203,
    OccDE: 'Diplom-Informatiker/in',
    Occupation: 'Computer Scientist with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602403,
    OccDE: 'Computer-Service-Ingenieur/in',
    Occupation: 'Computer Service Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 774101,
    OccDE: 'Computerfachmann/-fachfrau',
    Occupation: 'Computer Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774102,
    OccDE: 'Computerspezialist/in',
    Occupation: 'Computer Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673210,
    OccDE: 'Computerkauf(mann/frau)',
    Occupation: 'Computer Supply Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673202,
    OccDE: 'Computer-Fachhändler/in',
    Occupation: 'Computer Supply Specialist Dealer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622326,
    OccDE: 'Computertechniker/in',
    Occupation: 'Computer Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 622305,
    OccDE: 'Computertechniker/in (Elektroniktechniker/in)',
    Occupation: 'Computer Technician (Electronics Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 317102,
    OccDE: 'Computertechniker/in (Mechaniker/in)',
    Occupation: 'Computer Technician (Mechanic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703601,
    OccDE: 'Konzeptionstexter/in (Werbung)',
    Occupation: 'Concept Copywriter (Marketing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832519,
    OccDE: 'Konzertsänger/in',
    Occupation: 'Concert Singer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915603,
    OccDE: 'Concierge (Hotel)',
    Occupation: 'Concierge (Hotel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914518,
    OccDE: 'Hotelportier (Empfangsbüro)',
    Occupation: 'Concierge (Reception)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442003,
    OccDE: 'Beton- und Stahlbetonbauer',
    Occupation: 'Concrete and Reinforced Concrete Construction Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 112105,
    OccDE: 'Betonstein- und Terrazzohersteller/in',
    Occupation: 'Concrete and Terrazzo Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603910,
    OccDE: 'Betonbauingenieur/in',
    Occupation: 'Concrete Civil Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 442703,
    OccDE: 'Betonbauerhelfer',
    Occupation: 'Concrete Construction Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442103,
    OccDE: 'Betonbaupolier',
    Occupation: 'Concrete Construction Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442005,
    OccDE: 'Betonbaufacharbeiter',
    Occupation: 'Concrete Construction Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442007,
    OccDE: 'Betonfacharbeiter',
    Occupation: 'Concrete Construction Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442004,
    OccDE: 'Betonbauer',
    Occupation: 'Concrete Construction Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101402,
    OccDE: 'Betonbohrer/in',
    Occupation: 'Concrete Drill Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623903,
    OccDE: 'Betonbautechniker/in',
    Occupation: 'Concrete Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 112103,
    OccDE: 'Betonhersteller/in',
    Occupation: 'Concrete Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 546407,
    OccDE: 'Betonmischmaschinenführer/in (Bau)',
    Occupation: 'Concrete Mixer Operator (Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442901,
    OccDE: 'Betonsanierer',
    Occupation: 'Concrete renovators',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626504,
    OccDE: 'Betontechnolog(e/in) (Physikotechniker/in)',
    Occupation: 'Concrete Technician (Physical Sciences Technician)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633602,
    OccDE: 'Betonprüfer/in',
    Occupation: 'Concrete Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 112109,
    OccDE: 'Betonwerker/in',
    Occupation: 'Concrete Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442702,
    OccDE: 'Betonarbeiter',
    Occupation: 'Concrete Workers',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712409,
    OccDE: 'Schaffner/in',
    Occupation: 'Conductor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831211,
    OccDE: 'Dirigent/in',
    Occupation: 'Conductor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311406,
    OccDE: 'Fahrleitungsmonteur/in',
    Occupation: 'Conductor Rail Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 392007,
    OccDE: 'Konditor/in',
    Occupation: 'Confectioner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 392005,
    OccDE: 'Konditor/in und Cafeinhaber/in',
    Occupation: 'Confectioner and Café Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 392206,
    OccDE: 'Konditor-Patissier',
    Occupation: 'Confectioner and Pastry Chef',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 392703,
    OccDE: 'Konditorhelfer/in',
    Occupation: 'Confectioner Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 392009,
    OccDE: 'Konditorgesell(e/in)',
    Occupation: 'Confectioner Journeyman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 393005,
    OccDE: 'Süßwarenhersteller/in',
    Occupation: 'Confectionery Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661202,
    OccDE: 'Confiserieverkäufer/in',
    Occupation: 'Confectionery Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661205,
    OccDE: 'Konditorei(fach)verkäufer/in',
    Occupation: 'Confectionery Salesperson (Consultant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 393004,
    OccDE: 'Süßwarenfacharbeiter/in',
    Occupation: 'Confectionery Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 822104,
    OccDE: 'Konferenzdolmetscher/in',
    Occupation: 'Conference Interpreter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 309404,
    OccDE: 'Konservator/in (Präparator/in)',
    Occupation: 'Conservator (Taxidermist)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778114,
    OccDE: 'Konsoloperator/in',
    Occupation: 'Console Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501101,
    OccDE: 'Bau- und Möbelschreiner/in',
    Occupation: 'Construction and Furniture Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501102,
    OccDE: 'Bau- und Möbeltischler/in',
    Occupation: 'Construction and Furniture Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803309,
    OccDE: 'Bauwart/Bauwartin',
    Occupation: 'Construction Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771506,
    OccDE: 'Baukalkulator/in',
    Occupation: 'Construction Calculator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785201,
    OccDE: 'Baukaufmann/-kauffrau',
    Occupation: 'Construction Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 544601,
    OccDE: 'Baukranführer/in',
    Occupation: 'Construction Crane Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310001,
    OccDE: 'Bauelektriker/in',
    Occupation: 'Construction Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 609002,
    OccDE: 'Hochbauingenieur/in (Architekt/in)',
    Occupation: 'Construction Engineer (Architect)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 609201,
    OccDE: 'Baubetriebsingenieur/in (Architekt/in)',
    Occupation: 'Construction Engineer (Architect)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601502,
    OccDE: 'Bauingenieur/in (Wärme-, Gasversorgung, Ventilation)',
    Occupation: 'Construction Engineer (Heat, Gas Supply, Ventilation)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 250007,
    OccDE: 'Aufbaumonteur/in (Metallbau)',
    Occupation: 'Construction Engineer (Metal Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241998,
    OccDE: 'Konstruktionsmechaniker/in -         Fachrichtung Schweißtechnik',
    Occupation: 'Construction Engineer specialising in Welding',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623003,
    OccDE: 'Bautechnische(r) Berater/in',
    Occupation: 'Construction Engineering Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549909,
    OccDE: 'Baumaschinenwart/in',
    Occupation: 'Construction Equipment Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273910,
    OccDE: 'Baumaschinenmonteur/in',
    Occupation: 'Construction Equipment Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273909,
    OccDE: 'Baumaschinenmechaniker/in',
    Occupation: 'Construction Equipment Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 546001,
    OccDE: 'Baugeräteführer/in',
    Occupation: 'Construction Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 546002,
    OccDE: 'Baumaschinenführer/in',
    Occupation: 'Construction Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 545206,
    OccDE: 'Baumaschinist/in (Baggerfahrer/in)',
    Occupation: 'Construction Equipment Operator (Excavator Operator)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 546601,
    OccDE: 'Baugeräteführer/in (Straßen-, Tiefbau)',
    Occupation: 'Construction Equipment Operator (Road, Civil Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 544604,
    OccDE: 'Baumaschinist/in (Turmdrehkranführer/in)',
    Occupation: 'Construction Equipment Operator (Tower Crane Operator)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 545001,
    OccDE: 'Baumaschinenführer/in im Erd- und Tiefbau',
    Occupation:
      'Construction Equipment Operator in Earthworks and Civil Engineering',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273911,
    OccDE: 'Baumaschinenschlosser/in',
    Occupation: 'Construction Equipment Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273907,
    OccDE: 'Baugerätehandwerker/in',
    Occupation: 'Construction Equipment Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608204,
    OccDE: 'Bausachverständige/r',
    Occupation: 'Construction Expert',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 480101,
    OccDE: 'Ausbaufacharbeiter/in',
    Occupation: 'Construction Finishing Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440202,
    OccDE: 'Bauvorarbeiter',
    Occupation: 'Construction Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440204,
    OccDE: 'Hochbauvorarbeiter',
    Occupation: 'Construction Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603002,
    OccDE: 'Baumeister/in',
    Occupation: 'Construction Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 546103,
    OccDE: 'Baumaschinenmeister/in',
    Occupation: 'Construction Machinery Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 546004,
    OccDE: 'Baumaschinist/in',
    Occupation: 'Construction Machinery Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623199,
    OccDE: 'Bauleiter/in',
    Occupation: 'Construction Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 609202,
    OccDE: 'Bauleiter/in (Architekt/in)',
    Occupation: 'Construction Manager (Architect)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 602807,
    OccDE: 'Konstruktionsleiter/in (Elektrotechnik)',
    Occupation: 'Construction Manager (Electrical Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 673905,
    OccDE: 'Baustoffkaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Construction Materials Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673904,
    OccDE: 'Baustoffhändler/in (Einzelhandel)',
    Occupation: 'Construction Materials Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671207,
    OccDE: 'Baustoffhändler/in (Großhandel)',
    Occupation: 'Construction Materials Dealer (Wholesale)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606502,
    OccDE: 'Baustoffingenieur/in',
    Occupation: 'Construction Materials Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 671208,
    OccDE: 'Baustoffkaufmann/-kauffrau (Großhandel)',
    Occupation: 'Construction Materials Salesperson (Wholesale)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633601,
    OccDE: 'Baustoffprüfer/in',
    Occupation: 'Construction Materials Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250013,
    OccDE: 'Konstruktionsmechaniker/in',
    Occupation: 'Construction Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254106,
    OccDE: 'Konstruktionsmechaniker/in (Fachrichtung Ausrüstungstechnik)',
    Occupation:
      'Construction Mechanic (specialising in Electromechanical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 255001,
    OccDE:
      'Konstruktionsmechaniker/in (Fachrichtung Metall- und Schiffbautechnik)',
    Occupation:
      'Construction Mechanic (specialising in Metal and Shipbuilding technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 265012,
    OccDE: 'Konstruktionsmechaniker/in (Fachrichtung Feinblechbautechnik)',
    Occupation:
      'Construction Mechanic (specialising in Precision Sheet Metal Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250703,
    OccDE: 'Konstruktionsmechanikerhelfer/in',
    Occupation: 'Construction Mechanic Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254703,
    OccDE: 'Konstruktionsmechanikerhelfer/in (Ausrüstungstechnik)',
    Occupation: 'Construction Mechanic Assistant (Technical Equipment)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 255112,
    OccDE: 'Konstruktionsschlosser/in',
    Occupation: 'Construction Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254102,
    OccDE: 'Bauschlosser/in',
    Occupation: 'Construction Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 488503,
    OccDE: 'Bauklempner und Dachdecker',
    Occupation: 'Construction Plumber and Roofer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771604,
    OccDE: 'Bauabrechner/in',
    Occupation: 'Construction Settlement Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803307,
    OccDE: 'Baustellenkontrolleur/in',
    Occupation: 'Construction Site inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803306,
    OccDE: 'Baustellenaufseher/in',
    Occupation: 'Construction Site Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441001,
    OccDE: 'Baufacharbeiter (Maurerarbeiten, Mauerwerksbau)',
    Occupation: 'Construction Specialist (Bricklaying, Masonry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 460101,
    OccDE: 'Baufacharbeiter (Tiefbau)',
    Occupation: 'Construction Specialist (Civil Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442001,
    OccDE: 'Baufacharbeiter (Beton-, Stahlbetonbau)',
    Occupation: 'Construction Specialist (concrete, Reinforced Concrete)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 480103,
    OccDE: 'Baufacharbeiter/in (Ausbau)',
    Occupation: 'Construction Specialist (Expansion)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 480402,
    OccDE: 'Baufachwerker/in (Ausbau)',
    Occupation: 'Construction Specialist (Expansion)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481303,
    OccDE: 'Baufacharbeiter/in (Putzen)',
    Occupation: 'Construction Specialist (Plasterer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623102,
    OccDE: 'Baubetriebsüberwacher/in',
    Occupation: 'Construction Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803301,
    OccDE: 'Bauaufseher/in (Baukontrolle)',
    Occupation: 'Construction Supervisor (Building Inspection)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623002,
    OccDE: 'Bautechniker/in',
    Occupation: 'Construction Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440402,
    OccDE: 'Baufachwerker',
    Occupation: 'Construction Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440404,
    OccDE: 'Bauwerker',
    Occupation: 'Construction Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 472904,
    OccDE: 'Bauarbeiter',
    Occupation: 'Construction Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440403,
    OccDE: 'Bauwerker (Hochbau)',
    Occupation: 'Construction Worker (Building Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 480403,
    OccDE: 'Bauwerker/in (Ausbau)',
    Occupation: 'Construction Worker (Expansion)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 472906,
    OccDE: 'Bauhelfer',
    Occupation: 'Construction Worker Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 472907,
    OccDE: 'Bauhilfsarbeiter',
    Occupation: 'Construction Worker Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440701,
    OccDE: 'Bauhelfer (Hochbau)',
    Occupation: 'Construction Worker Assistant (Building Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765105,
    OccDE: 'Konsulatssekretär/in',
    Occupation: 'Consular Senior Administrative Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 996101,
    OccDE: 'Berater/in',
    Occupation: 'Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 996102,
    OccDE: 'Consulter',
    Occupation: 'Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662399,
    OccDE: 'Fachberater/in (Möbel)',
    Occupation: 'Consultant (Furniture)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703202,
    OccDE: 'Fachberater/in (Werbung)',
    Occupation: 'Consultant (Marketing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660807,
    OccDE: 'Fachberater/in (Einzelhandel)',
    Occupation: 'Consultant (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620801,
    OccDE: 'Berater/in (technische Beratung)',
    Occupation: 'Consultant (Technical Advice)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856112,
    OccDE: 'Sprechstundenschwester',
    Occupation: 'Consultation Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600802,
    OccDE: 'Beratende(r) Ingenieur/in',
    Occupation: 'Consulting Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600803,
    OccDE: 'Beratungsingenieur/in',
    Occupation: 'Consulting Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '061102',
    OccDE: 'Beratende(r) Forstwirt/in',
    Occupation: 'Consulting Forester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921815,
    OccDE: 'Verbraucherberater/in',
    Occupation: 'Consumption Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 252003,
    OccDE: 'Behälter- und Apparatebauer',
    Occupation: 'Container and Equipment Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 937202,
    OccDE: 'Behälterreiniger/in',
    Occupation: 'Container Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 252102,
    OccDE: 'Behälterbauer/in',
    Occupation: 'Container Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787025,
    OccDE: 'Vertragsangestellte/r (im öffentlichen Dienst)',
    Occupation: 'Contract Employee (public service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750113,
    OccDE: 'Unternehmer/in',
    Occupation: 'Contractor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750101,
    OccDE: 'Bauunternehmer/in',
    Occupation: 'Contractor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013211',
    OccDE: 'Lohnunternehmer/in (landw.)',
    Occupation: 'Contractor (Agricultural)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915101,
    OccDE: 'Fachgehilf(e/in) im Gastgewerbe',
    Occupation: 'Contractor in the Hospitality Industry',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603003,
    OccDE: 'Bauunternehmer/in (Bauingenieur/in)',
    Occupation: 'Contractors (Civil Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600905,
    OccDE: 'Kontrollingenieur/in',
    Occupation: 'Control Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 632913,
    OccDE: 'Meßwartenfahrer/in',
    Occupation: 'Control Room Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 541401,
    OccDE: 'Leitstandsfahrer/in (Energieerzeugung)',
    Occupation: 'Control Room Operator (Energy Generation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549401,
    OccDE: 'Leitstandführer/in (Schaltanlage)',
    Occupation: 'Control Room Operator (Switchboard)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281908,
    OccDE: 'Leitstanddisponent/in (Kfz)',
    Occupation: 'Control Station Dispatcher (Auto)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622415,
    OccDE: 'Regelungstechniker/in',
    Occupation: 'Control Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 756209,
    OccDE: 'Controller/in',
    Occupation: 'Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521003,
    OccDE: 'Kontrolleur/in',
    Occupation: 'Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771102,
    OccDE: 'Controller/in (Finanzwesen)',
    Occupation: 'Controller (Finance)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 276005,
    OccDE: 'Kontrolleur/in (Mechaniker/in)',
    Occupation: 'Controller (Mechanic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756208,
    OccDE: 'Controller-Assistent/in',
    Occupation: 'Controller-Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756210,
    OccDE: 'Controlling-Assistent/in',
    Occupation: 'Controlling Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756211,
    OccDE: 'Controlling-Manager',
    Occupation: 'Controlling Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411022,
    OccDE: 'Koch',
    Occupation: 'Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411029,
    OccDE: 'Köchin',
    Occupation: 'Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411406,
    OccDE: 'Koch/Köchin (Gemeinschaftsverpflegung)',
    Occupation: 'Cook (Group Catering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411021,
    OccDE: 'Koch/Köchin (Gaststätten- und Hotelwesen)',
    Occupation: 'Cook (Restaurants and Hotels)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411313,
    OccDE: 'Koch/Köchin und Konditor/in',
    Occupation: 'Cook and Confectioner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411026,
    OccDE: 'Kochgehilf(e/in)',
    Occupation: "Cook's Assistant",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411710,
    OccDE: 'Küchengehilf(e/in)',
    Occupation: "Cook's Assistant",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411705,
    OccDE: 'Kochhelfer/in',
    Occupation: "Cook's Helper",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 423308,
    OccDE: 'Küfer/in (Weinküfer/in)',
    Occupation: 'Cooper (Wine Cooper)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756121,
    OccDE: 'Koordinator/in',
    Occupation: 'Coordinator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 252210,
    OccDE: 'Kupferschmied/in',
    Occupation: 'Coppersmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703605,
    OccDE: 'Werbetexter/in',
    Occupation: 'Copywriter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703603,
    OccDE: 'Texter/in (Werbung)',
    Occupation: 'Copywriter (Marketing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549606,
    OccDE: 'Kernbohrer/in',
    Occupation: 'Core Driller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201407,
    OccDE: 'Kernmacher',
    Occupation: 'Core Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801113,
    OccDE: 'Gefreiter',
    Occupation: 'Corporal',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801138,
    OccDE: 'Obergefreiter',
    Occupation: 'Corporal (Higher Grade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689507,
    OccDE: 'Firmenbetreuer/in',
    Occupation: 'Corporate Client Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691907,
    OccDE: 'Firmenkundenbetreuer/in (Bank)',
    Occupation: 'Corporate Client Relationship Manager (Bank)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603103,
    OccDE: 'Firmenbauleiter/in',
    Occupation: 'Corporate Construction Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 814220,
    OccDE: 'Justizvollzugsassistent/in',
    Occupation: 'Correctional Services Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814221,
    OccDE: 'Justizvollzugsbeamt(er/in)',
    Occupation: 'Correctional Services Official',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814224,
    OccDE: 'Justizvollzugssekretär/in',
    Occupation: 'Correctional Services Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814223,
    OccDE: 'Justizvollzugsobersekretär/in',
    Occupation: 'Correctional Services Senior Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814219,
    OccDE: 'Justizvollzugsangestellte/r',
    Occupation: 'Correctional Services Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788409,
    OccDE: 'Korrespondent/in',
    Occupation: 'Correspondent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821512,
    OccDE: 'Korrespondent/in (Journalist/in)',
    Occupation: 'Correspondent (Journalist)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511405,
    OccDE: 'Korrosionsschutzfachkraft',
    Occupation: 'Corrosion Protection Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511404,
    OccDE: 'Korrosionsschutzfacharbeiter/in',
    Occupation: 'Corrosion Protection Technicians',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902006,
    OccDE: 'Kosmetiker/in',
    Occupation: 'Cosmetician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902005,
    OccDE: 'Kosmetiker/in (Schönheitspfleger/in)',
    Occupation: 'Cosmetician (Beautician)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141916,
    OccDE: 'Kosmetikhersteller/in',
    Occupation: 'Cosmetics Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662502,
    OccDE: 'Kosmetikverkäufer/in',
    Occupation: 'Cosmetics Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771112,
    OccDE: 'Kostenanalytiker/in',
    Occupation: 'Cost Analyst',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771113,
    OccDE: 'Kostenplaner/in',
    Occupation: 'Cost Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771614,
    OccDE: 'Kostenrechner/in',
    Occupation: 'Costs Calculator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835804,
    OccDE: 'Kostümbildner/in',
    Occupation: 'Costume Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 332219,
    OccDE: 'Zwirner/in',
    Occupation: 'Cotton-spinning Mill Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787610,
    OccDE: 'Gemeindeassistent/in',
    Occupation: 'Council Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787613,
    OccDE: 'Gemeindehelfer/in',
    Occupation: 'Council Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787611,
    OccDE: 'Gemeindebeamt(er/in)',
    Occupation: 'Council Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787609,
    OccDE: 'Gemeindeangestellte/r',
    Occupation: 'Council Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787614,
    OccDE: 'Gemeindeobersekretär/in',
    Occupation: 'Council Higher Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765608,
    OccDE: 'Gemeindeinspektor/in',
    Occupation: 'Council Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787616,
    OccDE: 'Gemeindesekretär/in',
    Occupation: 'Council Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787652,
    OccDE: 'Gemeindebedienstete/r',
    Occupation: 'Council Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787532,
    OccDE: 'Schalterbeamt(er/in) (Post)',
    Occupation: 'Counter Civil Servant (Post)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915336,
    OccDE: 'Thekenkraft',
    Occupation: 'Counter Staff Member',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691908,
    OccDE: 'Länderreferent/in (Bank)',
    Occupation: 'Country Manager (Bank)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787620,
    OccDE: 'Kreisassistent/in',
    Occupation: 'County Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765613,
    OccDE: 'Kreis(amtmann/amtfrau)',
    Occupation: 'County Deputy Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787623,
    OccDE: 'Kreisobersekretär/in',
    Occupation: 'County Higher Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787619,
    OccDE: 'Kreisamtsinspektor/in',
    Occupation: 'County Higher Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 811140,
    OccDE: 'Landrichter/in',
    Occupation: 'County Judge',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 787624,
    OccDE: 'Kreissekretär/in',
    Occupation: 'County Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787622,
    OccDE: 'Kreishauptsekretär/in',
    Occupation: 'County Senior Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712701,
    OccDE: 'Ankuppler/in (Eisenbahn)',
    Occupation: 'Coupler (Railway)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714903,
    OccDE: 'Botenfahrer/in',
    Occupation: 'Courier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714910,
    OccDE: 'Kurierfahrer/in',
    Occupation: 'Courier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714909,
    OccDE: 'Kurierdienstfahrer/in',
    Occupation: 'Courier Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879607,
    OccDE: 'Kursleiter/in (Erwachsenen-, Fortbildung)',
    Occupation: 'Course Leader (Adult and Continuing Education)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 811123,
    OccDE: 'Gerichtsreferendar/in',
    Occupation: 'Court Referendary',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 787109,
    OccDE: 'Gerichtssekretär/in',
    Occupation: 'Court Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 632308,
    OccDE: 'Rißprüfer/in',
    Occupation: 'Crack Detector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997111,
    OccDE: 'Handwerker/in',
    Occupation: 'Craftsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 255302,
    OccDE: 'Kranmonteur/in',
    Occupation: 'Crane Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 255303,
    OccDE: 'Kranschlosser/in',
    Occupation: 'Crane Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 544002,
    OccDE: 'Kranfahrer/in',
    Occupation: 'Crane Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191510,
    OccDE: 'Kranführer/in (Gießkran)',
    Occupation: 'Crane Operator (Casting Crane)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 544003,
    OccDE: 'Kranführer/in (nicht Gießkran)',
    Occupation: 'Crane Operator (not Casting Crane)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703504,
    OccDE: 'Kreativ-Direktor/in',
    Occupation: 'Creative Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863116,
    OccDE: 'Krippenerzieher/in',
    Occupation: 'Crèche Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691627,
    OccDE: 'Kreditmanager/in',
    Occupation: 'Credit Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801420,
    OccDE: 'Kriminalanwärter/in',
    Occupation: 'Criminal Police Candidate',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801422,
    OccDE: 'Kriminalbeamt(er/in)',
    Occupation: 'Criminal Police Official',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 884901,
    OccDE: 'Kriminalist/in',
    Occupation: 'Criminologist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011001',
    OccDE: 'Ackerbauer/-bäuerin',
    Occupation: 'Crop Farmer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705602,
    OccDE: 'Croupier',
    Occupation: 'Croupier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814299,
    OccDE: 'JVA-Beamt(er/in)',
    Occupation: 'CS Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603518,
    OccDE: 'Kulturbauingenieur/in',
    Occupation: 'Cultural Buildings Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 885301,
    OccDE: 'Kulturpädagog(e/in)',
    Occupation: 'Cultural Pedagogue',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882907,
    OccDE: 'Kulturwissenschaftler/in',
    Occupation: 'Cultural Scholar',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 981135,
    OccDE: 'Student/in Kulturwissenschaften',
    Occupation: 'Culture Studies Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764410,
    OccDE: 'Kurator/in',
    Occupation: 'Curator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 695421,
    OccDE: 'Kundenberater/in (Versicherungsaußendienst)',
    Occupation: 'Customer Adviser (Insurance Field Sales)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683903,
    OccDE: 'Kundenberater/in (Verlag)',
    Occupation: 'Customer Consultant (Publishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629309,
    OccDE: 'Kundenberater/in (Entsorgung, Umweltschutz)',
    Occupation:
      'Customer Consultant (Waste Disposal, Environmental Protection)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695422,
    OccDE: 'Kundenbetreuer/in (Versicherungsaußendienst)',
    Occupation: 'Customer Representative (Insurance Field Sales)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703208,
    OccDE: 'Kundendienstberater/in',
    Occupation: 'Customer Service Adviser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788924,
    OccDE: 'Kundendienstsachbearbeiter/in',
    Occupation: 'Customer Service Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600503,
    OccDE: 'Kundendienstingenieur/in',
    Occupation: 'Customer service engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 623906,
    OccDE: 'Kundendienstleiter/in (Baugewerbe)',
    Occupation: 'Customer Service Manager (Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621909,
    OccDE: 'Kundendienstleiter/in (Maschinenbau)',
    Occupation: 'Customer Service Manager (Mechanical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751308,
    OccDE: 'Kundendienstleiter/in (Verkauf, Vertrieb)',
    Occupation: 'Customer Service Manager (Sales and Distribution)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316016,
    OccDE: 'Kundendienstmechaniker/in (Elektronik)',
    Occupation: 'Customer Service Mechanic (Electronics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683904,
    OccDE: 'Kundenbetreuer/in (Verlag)',
    Occupation: 'Customer Service Representative (Publishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788923,
    OccDE: 'Kundendienstfacharbeiter/in',
    Occupation: 'Customer Service Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620505,
    OccDE: 'Kundendiensttechniker/in',
    Occupation: 'Customer Service Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622315,
    OccDE: 'Kundendiensttechniker/in (EDV)',
    Occupation: 'Customer Service Technician (EDP)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316341,
    OccDE: 'Kundendienstmonteur/in (Elektrogeräte)',
    Occupation: 'Customer Service Technician (Electronic Equipment)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622805,
    OccDE: 'Kundendiensttechniker/in (Elektro)',
    Occupation: 'Customer Service Technician (Electronics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145817,
    OccDE: 'Kundendienstmonteur/in (Reifenservice)',
    Occupation: 'Customer Service Technician (Tyre Service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 784443,
    OccDE: 'Zollhauptwachtmeister/in',
    Occupation: 'Customs Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 784445,
    OccDE: 'Zollwachtmeister/in',
    Occupation: 'Customs Administrative Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701903,
    OccDE: 'Zolldeklarant/in',
    Occupation: 'Customs Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787229,
    OccDE: 'Zollsachbearbeiter/in',
    Occupation: 'Customs Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701205,
    OccDE: 'Zollspediteur/in',
    Occupation: 'Customs Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787223,
    OccDE: 'Zollassistent/in',
    Occupation: 'Customs Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787226,
    OccDE: 'Zollgrenzschutzbeamt(er/in)',
    Occupation: 'Customs Border Guard Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787224,
    OccDE: 'Zollbeamt(er/in)',
    Occupation: 'Customs Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787228,
    OccDE: 'Zollobersekretär/in',
    Occupation: 'Customs Higher Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765221,
    OccDE: 'Zollinspektor/in',
    Occupation: 'Customs Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 753511,
    OccDE: 'Zollfahnder/in',
    Occupation: 'Customs Investigation Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 753512,
    OccDE: 'Zollfahndungsbeamt(er/in)',
    Occupation: 'Customs Investigation Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 784444,
    OccDE: 'Zolloberwachtmeister/in',
    Occupation: 'Customs Junior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787222,
    OccDE: 'Zollanwärter/in',
    Occupation: 'Customs Referendary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787230,
    OccDE: 'Zollsekretär/in',
    Occupation: 'Customs Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787227,
    OccDE: 'Zollhauptsekretär/in',
    Occupation: 'Customs Senior Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765224,
    OccDE: 'Zolloberinspektor/in',
    Occupation: 'Customs Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 359777,
    OccDE: 'Zuschneider/in',
    Occupation: 'Cutter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135124,
    OccDE: 'Zuschneider/in (Glas)',
    Occupation: 'Cutter (Glass)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857605,
    OccDE: 'Zytologie-Assistent/in',
    Occupation: 'Cytology Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857606,
    OccDE: 'Zytologische(r) Assistent/in',
    Occupation: 'Cytology Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 631502,
    OccDE: 'Milchwirtschaftliche(r) Laborant/in',
    Occupation: 'Dairy Industry Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 631503,
    OccDE: 'Milchwirtschaftslaborant/in',
    Occupation: 'Dairy Industry Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 431009,
    OccDE: 'Molkereifacharbeiter/in',
    Occupation: 'Dairy Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 431010,
    OccDE: 'Molkereifachmann/-fachfrau',
    Occupation: 'Dairy Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627109,
    OccDE: 'Molkereitechniker/in',
    Occupation: 'Dairy Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 431713,
    OccDE: 'Milcharbeiter/in',
    Occupation: 'Dairy Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 431715,
    OccDE: 'Molkereiarbeiter/in',
    Occupation: 'Dairy Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023129',
    OccDE: 'Schweizer/in',
    Occupation: 'Dairyman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482513,
    OccDE: 'Mauertrockenleger/in',
    Occupation: 'Damp Proofer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876404,
    OccDE: 'Tanzpädagog(e/in)',
    Occupation: 'Dance Pedagogue',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876403,
    OccDE: 'Tanzlehrer/in',
    Occupation: 'Dance Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 852505,
    OccDE: 'Tanztherapeut/in',
    Occupation: 'Dance Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832417,
    OccDE: 'Tänzer/in',
    Occupation: 'Dancer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778304,
    OccDE: 'Datenadministrator/in (EDV)',
    Occupation: 'Data Administrator (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778416,
    OccDE: 'Rechenzentrumsleiter/in',
    Occupation: 'Data Centre Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 779106,
    OccDE: 'Datenkauf(mann/frau)',
    Occupation: 'Data Clerk',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 783205,
    OccDE: 'Datenerfasser/in (EDV)',
    Occupation: 'Data Collector (EDP)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778308,
    OccDE: 'Datennetzbetreuer/in',
    Occupation: 'Data Network Supervisor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 779101,
    OccDE: 'Datenverarbeitungskaufmann/-kauffrau',
    Occupation: 'Data Processing Clerk',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 774104,
    OccDE: 'Datenverarbeitungsfachmann/-fachfrau',
    Occupation: 'Data Processing Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 774103,
    OccDE: 'Datenverarbeiter/in',
    Occupation: 'Data Processor',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 813802,
    OccDE: 'Datenschutzbeauftragte/r',
    Occupation: 'Data Protection Officer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 622306,
    OccDE: 'Datentechniker/in (EDV)',
    Occupation: 'Data Technician (EDP)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 774405,
    OccDE: 'Datentechnische(r) Assistent/in',
    Occupation: 'Data Technology Assistant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 783208,
    OccDE: 'Datentypist/in',
    Occupation: 'Data Typist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 783203,
    OccDE: 'Datatypist/in',
    Occupation: 'Data Typist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778305,
    OccDE: 'Datenbankadministrator/in',
    Occupation: 'Database Administrator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778306,
    OccDE: 'Datenbankverwalter/in',
    Occupation: 'Database Administrator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776103,
    OccDE: 'Datenbankdesigner/in (DV-Organisator/in)',
    Occupation: 'Database Designer (DP Organiser)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776105,
    OccDE: 'Datenbankspezialist/in',
    Occupation: 'Database Specialist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775107,
    OccDE: 'Datenbank-Anwendungsprogrammierer/in',
    Occupation: 'Database User Programmer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 863123,
    OccDE: 'Tagesheim(stätten)leiter/in (Erzieher/in)',
    Occupation: 'Day Care Director (Teacher)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401203,
    OccDE: 'Ausbeiner/in',
    Occupation: 'De-boner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 891301,
    OccDE: 'Diakon/in (Seelsorger/in)',
    Occupation: 'Deacon (Pastor)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 869003,
    OccDE: 'Diakon (Sozialarbeit)',
    Occupation: 'Deacon (Social Work)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853008,
    OccDE: 'Diakonisse (Krankenschwester)',
    Occupation: 'Deaconess (Nurse)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 670101,
    OccDE: 'Händler/in',
    Occupation: 'Dealer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 706113,
    OccDE: 'Inkassobeauftragte/r',
    Occupation: 'Debt Collector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861413,
    OccDE: 'Schuldnerberater/in',
    Occupation: 'Debt Counsellor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 229401,
    OccDE: 'Entgrater/in (Metall, nicht Gußputz)',
    Occupation: 'Deburring Worker (Metal, not Fettling)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601201,
    OccDE: 'Decksingenieur/in',
    Occupation: 'Deck Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 839901,
    OccDE: 'Dekorfoliengestalter/in',
    Occupation: 'Decorative Film Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300805,
    OccDE: 'Gürtler/in',
    Occupation: 'Decorative Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 492601,
    OccDE: 'Dekorationsnäher/in',
    Occupation: 'Decorative Seamstress',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491001,
    OccDE: 'Dekorateur/in',
    Occupation: 'Decorator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 839202,
    OccDE: 'Dekorationenmaler/in',
    Occupation: 'Decorator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836104,
    OccDE: 'Dekorateur/in (Schauwerbegestalter/in)',
    Occupation: 'Decorator (Display Designer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 625210,
    OccDE: 'Tiefbohrtechniker/in',
    Occupation: 'Deep Drilling Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023655',
    OccDE: 'Hochseefischer/in',
    Occupation: 'Deep Sea Fisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714402,
    OccDE: 'Ausfahrer/in (Auslieferungsfahrer/in)',
    Occupation: 'Delivery Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714498,
    OccDE: 'Lieferfahrer/in',
    Occupation: 'Delivery Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714403,
    OccDE: 'Auslieferungsfahrer/in (nicht Verkaufsfahrer/in)',
    Occupation: 'Delivery Driver (not Sales Driver)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741703,
    OccDE: 'Ausliefer(er/in)',
    Occupation: 'Delivery Person',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744103,
    OccDE: 'Ausfahrer/in',
    Occupation: 'Delivery Person',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744119,
    OccDE: 'Liefer(er/in)',
    Occupation: 'Delivery Person',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744199,
    OccDE: 'Zusteller',
    Occupation: 'Delivery Person',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 663007,
    OccDE: 'Verkaufsfahrer/in',
    Occupation: 'Delivery Sales Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 663002,
    OccDE: 'Fahrverkäufer/in',
    Occupation: 'Delivery Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714418,
    OccDE: 'Lieferwagenfahrer/in',
    Occupation: 'Delivery Van Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 842001,
    OccDE: 'Assistenzzahnarzt/-ärztin',
    Occupation: 'Dental Assistant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 856206,
    OccDE: 'Zahnarzthelfer/in',
    Occupation: 'Dental Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856207,
    OccDE: 'Zahnarzthilfe',
    Occupation: 'Dental Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856209,
    OccDE: 'Zahnmedizinische(r) Assistent/in',
    Occupation: 'Dental Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856299,
    OccDE: 'Zahnarztassistent/in',
    Occupation: 'Dental Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856499,
    OccDE: 'Zahnmedizinische/r Fachangestellte/r',
    Occupation: 'Dental Assistant Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121143,
    OccDE: 'Zahnkeramiker/in',
    Occupation: 'Dental Ceramicist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671213,
    OccDE: 'Dentalkaufmann/-kauffrau',
    Occupation: 'Dental Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 689307,
    OccDE: 'Dentalberater/in',
    Occupation: 'Dental Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 303201,
    OccDE: 'Dentallaborant/in',
    Occupation: 'Dental Lab Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 303203,
    OccDE: 'Zahnlaborant/in',
    Occupation: 'Dental Lab Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853948,
    OccDE: 'Stomatologische Schwester',
    Occupation: 'Dental Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856211,
    OccDE: 'zahnmedizinische Prophylaxehelfer/in',
    Occupation: 'Dental Oral Hygienist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856418,
    OccDE: 'Zahnarztsekretär/in',
    Occupation: 'Dental Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 842102,
    OccDE: 'Fachzahnarzt/-ärztin',
    Occupation: 'Dental Specialist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 857501,
    OccDE: 'Zahnmedizinisch-technische(r) Assistent/in',
    Occupation: 'Dental Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629302,
    OccDE: 'Dentaltechniker/in',
    Occupation: 'Dental Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 303112,
    OccDE: 'Zahntechniker/in',
    Occupation: 'Dental Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 303703,
    OccDE: 'Zahntechnikerassistent/in',
    Occupation: 'Dental Technician Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 303704,
    OccDE: 'Zahntechnikerhelfer/in',
    Occupation: 'Dental Technician Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 303706,
    OccDE: 'Zahntechnische(r) Fachhelfer/in',
    Occupation: 'Dental Technician Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 303707,
    OccDE: 'Zahntechnische(r) Helfer/in',
    Occupation: 'Dental Technician Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 303705,
    OccDE: 'Zahntechnische Hilfskraft (Dentallabor)',
    Occupation: 'Dental Technician Assistant (Dental Laboratory)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 842002,
    OccDE: 'Zahnarzt/-ärztin',
    Occupation: 'Dentist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 842104,
    OccDE: 'Zahnarzt/-ärztin für Mundchirurgie',
    Occupation: 'Dentist of Oral Surgery',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 981182,
    OccDE: 'Student/in Zahnmedizin',
    Occupation: 'Dentistry Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764007,
    OccDE: 'Amtsleiter/in',
    Occupation: 'Department Director',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 751002,
    OccDE: 'Abteilungsdirektor/in',
    Occupation: 'Department Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 764002,
    OccDE: 'Abteilungsleiter/in (Verwaltung, höherer Dienst)',
    Occupation:
      'Department Director (Administrative Department, highest civil service)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 780601,
    OccDE: 'Abteilungsleiter-Assistent/in',
    Occupation: 'Department Director Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651001,
    OccDE: 'Abteilungsmeister/in',
    Occupation: 'Department Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 864101,
    OccDE: 'Abteilungsaltenpfleger/in',
    Occupation: 'Department Geriatric Care Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764016,
    OccDE: 'Dezernent/in',
    Occupation: 'Department Head',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 751006,
    OccDE: 'Fachbereichsleiter/in',
    Occupation: 'Department Head',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 864102,
    OccDE: 'Abteilungsleiter/in (Altenpflege)',
    Occupation: 'Department Head (Geriatric Care)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695102,
    OccDE: 'Abteilungsleiter/in (Versicherung)',
    Occupation: 'Department Head (Insurance)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 676101,
    OccDE: 'Abteilungsleiter/in (Handel)',
    Occupation: 'Department Head (Trade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789201,
    OccDE: 'Abteilungssekretär/in',
    Occupation: 'Department Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791403,
    OccDE: 'Kaufhausdetektiv/in',
    Occupation: 'Department Store Detective',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791405,
    OccDE: 'Warenhausdetektiv/in',
    Occupation: 'Department Store Detective',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756116,
    OccDE: 'Fachbereichsorganisator/in',
    Occupation: 'Departmental Organiser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 463101,
    OccDE: 'Arbeitszugführer (Bahnmeisterei)',
    Occupation:
      'Departmental Train Driver Work Train Drivers (Train Supervisor)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713902,
    OccDE: 'Einsatzbearbeiter/in (Feuerwehrleitstelle)',
    Occupation: 'Deployment Worker (Fire Control Centre)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765001,
    OccDE: 'Amtmann/Amtfrau',
    Occupation: 'Deputy Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 765601,
    OccDE: 'Betriebs(amtmann/amtfrau) (Verwaltung, gehobener Dienst)',
    Occupation:
      'Deputy Director (Administrative Department, higher civil service)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 870802,
    OccDE: 'Konrektor/in',
    Occupation: 'Deputy Rector',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 873805,
    OccDE: 'Konrektor/in (an Grund- und Hauptschulen)',
    Occupation: 'Deputy Rector (Primary to Secondary Education)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 841944,
    OccDE: 'Dermatolog(e/in)',
    Occupation: 'Dermatologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841950,
    OccDE: 'Hautarzt/-ärztin',
    Occupation: 'Dermatologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 502901,
    OccDE: 'Formentischler/in',
    Occupation: 'Design Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600208,
    OccDE: 'Konstruktionsingenieur/in',
    Occupation: 'Design Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 622604,
    OccDE: 'Gestaltungstechniker/in (Elektrofach)',
    Occupation: 'Design Engineer (Electrical)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621604,
    OccDE: 'Gestaltungstechniker/in (nicht Elektrofach)',
    Occupation: 'Design Engineer (not Electrical)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600209,
    OccDE: 'Konstruktionsleiter/in',
    Occupation: 'Design Manager',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601606,
    OccDE: 'Konstruktionsleiter/in (Maschinenbau)',
    Occupation: 'Design Manager (Mechanical Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 981126,
    OccDE: 'Student/in Gestaltung, Design',
    Occupation: 'Design Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620206,
    OccDE: 'Konstruktionstechniker/in',
    Occupation: 'Design Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 834002,
    OccDE: 'Designer/in',
    Occupation: 'Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620205,
    OccDE: 'Konstrukteur/in',
    Occupation: 'Designer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 834304,
    OccDE: 'Designer/in (Werbung)',
    Occupation: 'Designer (Advertising)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834314,
    OccDE: 'Gestalter/in (Werbung)',
    Occupation: 'Designer (Advertising)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600207,
    OccDE: 'Konstrukteur/in (Ingenieur/in)',
    Occupation: 'Designer (Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 834303,
    OccDE: 'Designer/in (Grafik-, Kommunikationsdesign)',
    Occupation: 'Designer (Graphics, Communication Design)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834104,
    OccDE: 'Designer/in (Industrie-, Produktdesign)',
    Occupation: 'Designer (Industrial, Product Design)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834099,
    OccDE: 'Diplom-Designer/in',
    Occupation: 'Designer with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620203,
    OccDE: 'Detailkonstrukteur/in',
    Occupation: 'Detail Designer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 620207,
    OccDE: 'Teilkonstrukteur/in',
    Occupation: 'Detail Designer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621610,
    OccDE: 'Teilkonstrukteur/in (Maschinenbau und Feinwerktechnik)',
    Occupation: 'Detail Designer (Mechanical and Precision Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791304,
    OccDE: 'Detektiv/in',
    Occupation: 'Detective',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801488,
    OccDE: 'Kriminalbeamter (Ermittlungstätigkeit)',
    Occupation: 'Detective (Investigation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801313,
    OccDE: 'Kriminalkommissaranwärter/in',
    Occupation: 'Detective Superintendent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 634902,
    OccDE: 'Entwickler/in',
    Occupation: 'Developer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981158,
    OccDE: 'Student/in Raumplanung, Regionalplanung',
    Occupation: 'Development and Regional Planning Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622603,
    OccDE: 'Entwicklungssachbearbeiter/in (Elektrotechniker/in)',
    Occupation: 'Development Clerk (Electrical Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 600101,
    OccDE: 'Entwicklungsingenieur/in',
    Occupation: 'Development Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 620101,
    OccDE: 'Entwicklungsleiter/in',
    Occupation: 'Development Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 600102,
    OccDE: 'Entwicklungsleiter/in (Ingenieur/in)',
    Occupation: 'Development Manager (Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 273006,
    OccDE: 'Entwicklungsmechaniker/in',
    Occupation: 'Development Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 881902,
    OccDE: 'Entwicklungskaufmann/-kauffrau',
    Occupation: 'Development Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 620102,
    OccDE: 'Entwicklungstechniker/in',
    Occupation: 'Development Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 855201,
    OccDE: 'Diabetesberater/in',
    Occupation: 'Diabetes Adviser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281104,
    OccDE: 'Diagnosetechniker/in (Kfz-Instandsetzung)',
    Occupation: 'Diagnostic Technician (Motor Vehicle Maintenance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853915,
    OccDE: 'Dialyse-Schwester',
    Occupation: 'Dialysis Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853918,
    OccDE: 'Dialysepfleger',
    Occupation: 'Dialysis Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853917,
    OccDE: 'Dialysefachschwester',
    Occupation: 'Dialysis Nursing Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101812,
    OccDE: 'Diamantschleifer/in',
    Occupation: 'Diamond Grinder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201302,
    OccDE: 'Druckgießer',
    Occupation: 'Die Caster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411501,
    OccDE: 'Diätkoch/-köchin',
    Occupation: 'Diet Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 855101,
    OccDE: 'Diätassistent/in',
    Occupation: 'Dietician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 855202,
    OccDE: 'Diätberater/in',
    Occupation: 'Dietician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883805,
    OccDE: 'Ökotropholog(e/in) (wissenschaftliche/r)',
    Occupation: 'Dietician (Nutritional Science)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 602405,
    OccDE: 'Digitalelektroniker/in',
    Occupation: 'Digital electronics Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 465401,
    OccDE: 'Deicharbeiter',
    Occupation: 'Dike Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764104,
    OccDE: 'Diplomat/in',
    Occupation: 'Diplomat',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 627311,
    OccDE: 'Direktrice',
    Occupation: 'Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832212,
    OccDE: 'Regisseur/in',
    Occupation: 'Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750205,
    OccDE: 'Direktor/in',
    Occupation: 'Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750211,
    OccDE: 'Geschäftsleiter/in',
    Occupation: 'Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765013,
    OccDE: 'Oberinspektor/in (Verwaltung)',
    Occupation: 'Director (Administration)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 868307,
    OccDE: 'Fachleiter/in (Bildungsplanung, -beratung)',
    Occupation: 'Director (Education Planning and Advising)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764311,
    OccDE: 'Verwaltungsdirektor/in (Arbeits-, Sozialverwaltung)',
    Occupation: 'Director (Employment, Social Administration)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 774507,
    OccDE: 'Leiter/in Informatik',
    Occupation: 'Director Computer Science',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 774506,
    OccDE: 'Leiter/in DV-Abteilung',
    Occupation: 'Director DP Department',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 764028,
    OccDE: 'Oberregierungs(rat/rätin)',
    Occupation: 'Director General',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 764309,
    OccDE: 'Verwaltungs(rat/rätin) (Arbeits-, Sozialverwaltung)',
    Occupation: 'Director General (Employment, Social Administration)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 774505,
    OccDE: 'Leiter/in der EDV',
    Occupation: 'Director in EDP',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 774508,
    OccDE: 'Leiter/in Informationsmanagement',
    Occupation: 'Director Information Management',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 914507,
    OccDE: 'Empfangschef/in (Hotel)',
    Occupation: 'Director of Reception (Hotel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861507,
    OccDE: 'Studienleiter/in',
    Occupation: 'Director of Studies',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778408,
    OccDE: 'Leiter/in der Arbeitsvorbereitung (EDV)',
    Occupation: 'Director Work Planning EDP',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789103,
    OccDE: 'Direktionssekretär/in',
    Occupation: "Director's Secretary",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 862002,
    OccDE: 'Behindertenpädagog(e/in)',
    Occupation: 'Disabilities Education Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861302,
    OccDE: 'Behindertensozialarbeiter/in',
    Occupation: 'Disabilities Social Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 866701,
    OccDE: 'Behindertenhilfe',
    Occupation: 'Disability Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 866704,
    OccDE: 'Heilerziehungshelfer/in',
    Occupation: 'Disability Care Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 866705,
    OccDE: 'Heilerziehungspflegehelfer/in',
    Occupation: 'Disability Care Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 866702,
    OccDE: 'Behindertenpfleger/in',
    Occupation: 'Disability Care Provider',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 308201,
    OccDE: 'Demonteur/in',
    Occupation: 'Disassembly Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832806,
    OccDE: 'Diskjockey',
    Occupation: 'Disc Jockey',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 805104,
    OccDE: 'Desinfektor/in',
    Occupation: 'Disinfecting Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713506,
    OccDE: 'Einsatzleiter/in',
    Occupation: 'Dispatch Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713505,
    OccDE: 'Einsatzleiter/in (Rettungsdienst)',
    Occupation: 'Dispatch Director (Emergency Services)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701001,
    OccDE: 'Abfertigungsangestellte/r',
    Occupation: 'Dispatch Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802206,
    OccDE: 'Einsatzleiter/in (Feuerwehr)',
    Occupation: 'Dispatch Manager (Fire Services)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522008,
    OccDE: 'Versandfertigmacher/in',
    Occupation: 'Dispatch Readiness Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713504,
    OccDE: 'Dispatcher',
    Occupation: 'Dispatcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712106,
    OccDE: 'Fahrdienstleiter/in',
    Occupation: 'Dispatcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780504,
    OccDE: 'Disponent/in',
    Occupation: 'Dispatcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701004,
    OccDE: 'Disponent/in (Frachtverkehr)',
    Occupation: 'Dispatcher (freight transport)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713503,
    OccDE: 'Dispatcher (Landverkehr)',
    Occupation: 'Dispatcher (Land Transport)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 266212,
    OccDE: 'Schankanlagenfacharbeiter/in',
    Occupation: 'Dispensing Equipment Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836102,
    OccDE: 'Ausstellungsberater/in',
    Occupation: 'Display Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834305,
    OccDE: 'Display-Designer/in',
    Occupation: 'Display Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935003,
    OccDE: 'Entsorgungsfachmann/-fachfrau',
    Occupation: 'Disposal expert',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935703,
    OccDE: 'Entsorgungsarbeiter/in',
    Occupation: 'Disposal Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935001,
    OccDE: 'Entsorger/in',
    Occupation: 'Disposer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935005,
    OccDE: 'Ver- und Entsorger/in',
    Occupation: 'Distribution and Disposal Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935211,
    OccDE: 'Ver- und Entsorger/in (Fachrichtung Abfall)',
    Occupation: 'Distribution and Disposal Worker (Rubbish)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935421,
    OccDE: 'Ver- und Entsorger/in (Fachrichtung Abwasser)',
    Occupation: 'Distribution and Disposal Worker (Sewage)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695202,
    OccDE: 'Bezirksdirektor/in (Versicherung)',
    Occupation: 'District Head (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264204,
    OccDE: 'Fernwärmemonteur/in',
    Occupation: 'District Heating Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751303,
    OccDE: 'Bezirksleiter/in',
    Occupation: 'District Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751305,
    OccDE: 'Distriktleiter/in',
    Occupation: 'District Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 692103,
    OccDE: 'Bezirksbeauftragte/r (Bausparkasse)',
    Occupation: 'District Manager (Building Society)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695203,
    OccDE: 'Bezirksleiter/in (Versicherung)',
    Occupation: 'District Manager (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 812201,
    OccDE: 'Bezirksnotar/in',
    Occupation: 'District Notary',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 853809,
    OccDE: 'Gemeindeschwester (Krankenpflege)',
    Occupation: 'District Nurse (Nursing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695408,
    OccDE: 'Bezirksvertreter/in (Versicherungen)',
    Occupation: 'District Representative (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689004,
    OccDE: 'Bezirksreisende/r',
    Occupation: 'District Sales Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689008,
    OccDE: 'Gebietsreisende/r',
    Occupation: 'District Sales Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751304,
    OccDE: 'Bezirksverkaufsleiter/in',
    Occupation: 'District Sales Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695413,
    OccDE: 'Gebietsbeauftragte/r (Versicherungswesen)',
    Occupation: 'District Supervisor (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861132,
    OccDE: 'Kreisjugendpfleger/in',
    Occupation: 'District Youth Welfare Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803807,
    OccDE: 'Taucher/in',
    Occupation: 'Diver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876216,
    OccDE: 'Tauchsportlehrer/in',
    Occupation: 'Diving Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803809,
    OccDE: 'Taucheraufseher/in',
    Occupation: 'Diving Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751013,
    OccDE: 'Ressortleiter/in',
    Occupation: 'Division Head',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751005,
    OccDE: 'Bereichsleiter/in',
    Occupation: 'Division Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751007,
    OccDE: 'Fachgebietsleiter/in',
    Occupation: 'Division Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751009,
    OccDE: 'Hauptabteilungsleiter/in',
    Occupation: 'Division Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 726521,
    OccDE: 'Sektionsleiter/in (Flughafen)',
    Occupation: 'Division Manager (Airport)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 695204,
    OccDE: 'Direktionsleiter/in (Versicherung)',
    Occupation: 'Division Manager (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802307,
    OccDE: 'Hauptbrandmeister/in',
    Occupation: 'Divisional Officer (Fire Service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802308,
    OccDE: 'Hauptfeuerwehrmann/-feuerwehrfrau',
    Occupation: 'Divisional Officer (Fire Service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744805,
    OccDE: 'Hafenarbeiter/in',
    Occupation: 'Dock Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 255701,
    OccDE: 'Dockarbeiter/in',
    Occupation: 'Dock Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841012,
    OccDE: 'Arzt/Ärztin',
    Occupation: 'Doctor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841938,
    OccDE: 'Arzt/Ärztin in Weiterbildung zum Facharzt/zur Fachärztin',
    Occupation: 'Doctor in training to become a Specialist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841906,
    OccDE: 'Arzt/Ärztin für Anästhesiologie und Intensivtherapie',
    Occupation: 'Doctor of Anaesthesiology and Intensive Care',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841801,
    OccDE: 'Arzt/Ärztin für Kinder- und Jugendpsychiatrie',
    Occupation: 'Doctor of Child and Adolescent Psychiatry',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841914,
    OccDE: 'Arzt/Ärztin für Haut- und Geschlechtskrankheiten',
    Occupation: 'Doctor of Dermatology and Sexually Transmitted Diseases',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841102,
    OccDE: 'Arzt/Ärztin für Allgemeinmedizin',
    Occupation: 'Doctor of General Medicine',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841103,
    OccDE: 'Arzt/Ärztin für Innere Medizin',
    Occupation: 'Doctor of Internal Medicine',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841802,
    OccDE: 'Arzt/Ärztin für Neurologie',
    Occupation: 'Doctor of Neurology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841803,
    OccDE: 'Arzt/Ärztin für Neurologie und Psychiatrie',
    Occupation: 'Doctor of Neurology and Psychiatry',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841205,
    OccDE: 'Arzt/Ärztin für Neurochirurgie',
    Occupation: 'Doctor of Neurosurgery',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841501,
    OccDE: 'Arzt/Ärztin für Frauenheilkunde und Geburtshilfe',
    Occupation: 'Doctor of Obstetrics and Gynaecology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841502,
    OccDE: 'Arzt/Ärztin für Gynäkologie und Geburtshilfe',
    Occupation: 'Doctor of Obstetrics and Gynaecology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841907,
    OccDE: 'Arzt/Ärztin für Arbeitsmedizin',
    Occupation: 'Doctor of Occupational Health Medicine',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841401,
    OccDE: 'Arzt/Ärztin für Augenheilkunde',
    Occupation: 'Doctor of Ophthalmology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841206,
    OccDE: 'Arzt/Ärztin für Orthopädie',
    Occupation: 'Doctor of Orthopaedics',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841104,
    OccDE: 'Arzt/Ärztin für Kinderheilkunde',
    Occupation: 'Doctor of Paediatric Medicine',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841804,
    OccDE: 'Arzt/Ärztin für Psychiatrie',
    Occupation: 'Doctor of Psychiatry',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841805,
    OccDE: 'Arzt/Ärztin für Psychotherapie',
    Occupation: 'Doctor of Psychotherapy',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841603,
    OccDE: 'Arzt/Ärztin für Radiologie',
    Occupation: 'Doctor of Radiology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841201,
    OccDE: 'Arzt/Ärztin für Chirurgie',
    Occupation: 'Doctor of Surgery',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841301,
    OccDE: 'Arzt/Ärztin für Hals-, Nasen-, Ohrenheilkunde',
    Occupation: 'Doctor of the Ear, Nose and Throat',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841937,
    OccDE: 'Arzt/Ärztin für Urologie',
    Occupation: 'Doctor of Urology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841939,
    OccDE: 'Arzt/Ärztin mit Weiterbildung zum Facharzt/zur Fachärztin',
    Occupation: 'Doctor with Specialist Training',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 856102,
    OccDE: 'Arzt-Fachhelfer/in',
    Occupation: "Doctor's Assistant",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856104,
    OccDE: 'Arzthelfer/in',
    Occupation: "Doctor's Assistant",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856003,
    OccDE: 'Sprechstundenhelfer/in',
    Occupation: "Doctor's Receptionist",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856004,
    OccDE: 'Sprechstundenhilfe',
    Occupation: "Doctor's Receptionist",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856405,
    OccDE: 'Arztsekretär/in',
    Occupation: "Doctor's Secretary",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856415,
    OccDE: 'Praxissekretär/in (Arztpraxis)',
    Occupation: "Doctor's Secretary (Doctor's Office)",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 880301,
    OccDE: 'Doktorand/in',
    Occupation: 'Doctoral Candidate',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823319,
    OccDE: 'Dokumentar/in',
    Occupation: 'Documentalist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823326,
    OccDE: 'Mediendokumentar/in',
    Occupation: 'Documentalist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823398,
    OccDE: 'Diplom-Dokumentar/in',
    Occupation: 'Documentalist with Degree',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 691904,
    OccDE: 'Dokumentensachbearbeiter/in',
    Occupation: 'Documentation Administrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 823410,
    OccDE: 'Dokumentationsassistent/in',
    Occupation: 'Documentation Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 823411,
    OccDE: 'Dokumentationssachbearbeiter/in',
    Occupation: 'Documentation Clerk',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787201,
    OccDE: 'Abfertigungsbeamt(er/in) (Zoll)',
    Occupation: 'Documentation Inspection Civil Servant (Customs)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788604,
    OccDE: 'Aktenverwalter/in',
    Occupation: 'Documentation Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620904,
    OccDE: 'Dokumentationstechniker/in',
    Occupation: 'Documentation Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: '024402',
    OccDE: 'Hundepfleger/in',
    Occupation: 'Dog Caregiver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '024401',
    OccDE: 'Hundefriseur/in',
    Occupation: 'Dog Groomer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792907,
    OccDE: 'Hundeführer/in',
    Occupation: 'Dog Walker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923001,
    OccDE: 'Hausangestellte/r',
    Occupation: 'Domestic Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923103,
    OccDE: 'Hausgehilf(e/in) (Hauswirtschaftsgehilfe/-gehilfin)',
    Occupation: 'Domestic Worker (housekeeping assistant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 793099,
    OccDE: 'Empfangspförtner/in',
    Occupation: 'Doorman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 776301,
    OccDE: 'DV-Anwendungskoordinator/in',
    Occupation: 'DP Applications Coordinator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 779102,
    OccDE: 'DV-Kaufmann/Kauffrau',
    Occupation: 'DP Clerk',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 777105,
    OccDE: 'DV-Berater/in',
    Occupation: 'DP Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776303,
    OccDE: 'DV-Koordinator/in',
    Occupation: 'DP Coordinator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776306,
    OccDE: 'EDV-Koordinator/in',
    Occupation: 'DP Coordinator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 774501,
    OccDE: 'DV-Leiter/in',
    Occupation: 'DP Director',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 774901,
    OccDE: 'DV-Beauftragte/r',
    Occupation: 'DP Officer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776107,
    OccDE: 'DV-Organisator/in',
    Occupation: 'DP Organiser',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778402,
    OccDE: 'DV-Produktionsleiter/in',
    Occupation: 'DP Production Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 776401,
    OccDE: 'DV-Projektleiter/in',
    Occupation: 'DP Project Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776302,
    OccDE: 'DV-Betreuer/in',
    Occupation: 'DP Services Technician',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776305,
    OccDE: 'EDV-Betreuer/in',
    Occupation: 'DP Services Technician',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775201,
    OccDE: 'DV-Systemingenieur/in',
    Occupation: 'DP Systems Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 465202,
    OccDE: 'Drainagearbeiter',
    Occupation: 'Drainage Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821202,
    OccDE: 'Dramaturg/in',
    Occupation: 'Dramaturge',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 641602,
    OccDE: 'Konstruktionszeichner/in',
    Occupation: 'Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642002,
    OccDE: 'Bauzeichner/in',
    Occupation: 'Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642202,
    OccDE: 'Bauzeichner/in (Hochbau)',
    Occupation: 'Draughtsman (Building Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642203,
    OccDE: 'Bauzeichner/in (technische Gebäudeausrüstung)',
    Occupation: 'Draughtsman (Building Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642301,
    OccDE: 'Bauzeichner/in (Ingenieurbau)',
    Occupation: 'Draughtsman (Civil Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642404,
    OccDE: 'Bauzeichner/in (Tiefbau)',
    Occupation: 'Draughtsman (Civil Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642201,
    OccDE: 'Bauzeichner/in (Ausbau)',
    Occupation: 'Draughtsman (Expansion)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201823,
    OccDE: 'Putzer (Gußputz)',
    Occupation: 'Dresser (Fettling)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 224009,
    OccDE: 'Bohrer/in',
    Occupation: 'Drill Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '080708',
    OccDE: 'Bohrarbeiter (Erdöl-, Erdgasgewinnung)',
    Occupation: 'Drill Operator (Petroleum, Natural Gas)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 625203,
    OccDE: 'Bohrtechniker/in',
    Occupation: 'Drilling Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466902,
    OccDE: 'Bohrarbeiter (Tiefbau)',
    Occupation: 'Drilling Worker (Civil Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714006,
    OccDE: 'Kraftfahrer/in',
    Occupation: 'Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714003,
    OccDE: 'Fahrer/in',
    Occupation: 'Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 711203,
    OccDE: 'Triebwagenführer/in (Schienenfahrzeugführer/in)',
    Occupation: 'Driver (Train Driver)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714202,
    OccDE: 'Behindertenfahrer/in',
    Occupation: 'Driver for the Disabled',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 878101,
    OccDE: 'Fahrlehrer/in',
    Occupation: 'Driving Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 878104,
    OccDE: 'Kraftfahrlehrer/in',
    Occupation: 'Driving Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 878102,
    OccDE: 'Fahrschullehrer/in',
    Occupation: 'Driving School Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 878301,
    OccDE: 'Fahrschulinhaber/in',
    Occupation: 'Driving School Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 675103,
    OccDE: 'Drogist/in',
    Occupation: 'Druggist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831456,
    OccDE: 'Schlagzeuger/in',
    Occupation: 'Drummer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931202,
    OccDE: 'Chemischreiniger/in',
    Occupation: 'Dry Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 331703,
    OccDE: 'Putzereiarbeiter/in',
    Occupation: 'Dry Cleaner Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481206,
    OccDE: 'Trockenausbauer/in',
    Occupation: 'Dry Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482412,
    OccDE: 'Trockenbaumonteur/in',
    Occupation: 'Drywall Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482413,
    OccDE: 'Trockenbauvorarbeiter/in',
    Occupation: 'Drywall Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482411,
    OccDE: 'Trockenbauer/in',
    Occupation: 'Drywaller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778198,
    OccDE: 'DTP-Operator/in',
    Occupation: 'DTP Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935733,
    OccDE: 'Kanalreiniger/in',
    Occupation: 'Duct Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 361707,
    OccDE: 'Färbereiarbeiter/in',
    Occupation: 'Dyeing Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841302,
    OccDE: 'Hals-, Nasen-, Ohrenarzt/-ärztin',
    Occupation: 'Ear, Nose and Throat Doctor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 863124,
    OccDE: 'Unterstufenlehrer/in',
    Occupation: 'Early Education Teacher',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 315308,
    OccDE: 'Otoplastiker/in',
    Occupation: 'Earmould Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 471908,
    OccDE: 'Erdarbeiter',
    Occupation: 'Earth Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 545002,
    OccDE: 'Erdbaumaschinenführer/in',
    Occupation: 'Earthworks Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 777190,
    OccDE: 'eBusiness Consultant',
    Occupation: 'eBusiness Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 883305,
    OccDE: 'Ökolog(e/in)',
    Occupation: 'Ecologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 887110,
    OccDE: 'Wirtschafts- und Sozialstatistiker/in',
    Occupation: 'Economic and Social Statistician',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 883408,
    OccDE: 'Wirtschaftsgeograph/in',
    Occupation: 'Economic Geographer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 874203,
    OccDE: 'Handelslehrer/in',
    Occupation: 'Economics Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874209,
    OccDE: 'Wirtschaftspädagog(e/in)',
    Occupation: 'Economics Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874202,
    OccDE: 'Diplomökonompädagog(e/in)',
    Occupation: 'Economics Teacher with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 881001,
    OccDE: 'Wirtschaftswissenschaftler/in',
    Occupation: 'Economist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 881104,
    OccDE: 'Volkswirt/in',
    Occupation: 'Economist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 881105,
    OccDE: 'Volkswirtschaftler/in',
    Occupation: 'Economist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 881203,
    OccDE: 'Ökonom/in',
    Occupation: 'Economist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '031133',
    OccDE: 'Wirtschafter/in (landw. Verwalter/in)',
    Occupation: 'Economist (Agricultural Manager)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785604,
    OccDE: 'Wirtschaftler/in (kulturelle und soziale Bereiche)',
    Occupation: 'Economist (Cultural and Social)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 785603,
    OccDE: 'Ökonom/in (Betriebswirtschaft des Gesundheits- und Sozialwesens)',
    Occupation: 'Economist (Economics of Health and Social Services)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 691018,
    OccDE: 'Ökonom/in (Finanzwirtschaft)',
    Occupation: 'Economist (Finance)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 771120,
    OccDE: 'Wirtschaftler/in (Finanzwirtschaft)',
    Occupation: 'Economist (Finance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 881102,
    OccDE: 'Diplom-Volkswirt/in',
    Occupation: 'Economist with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 881201,
    OccDE: 'Diplom-Ökonom/in',
    Occupation: 'Economist with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 821411,
    OccDE: 'Redakteur/in',
    Occupation: 'Editor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835602,
    OccDE: 'Cutter/in',
    Occupation: 'Editor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821301,
    OccDE: 'Lektor/in (Verlag)',
    Occupation: 'Editor (Publisher)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 821403,
    OccDE: 'Chefredakteur/in',
    Occupation: 'Editor in Chief',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 821302,
    OccDE: 'Lektorassistent/in (Verlag)',
    Occupation: 'Editorial Assistant (Publisher)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 683005,
    OccDE: 'Redaktionsassistent/in (Verlag)',
    Occupation: 'Editorial Assistant (Publishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821412,
    OccDE: 'Redaktionsassistent/in (Rundfunk, Fernsehen)',
    Occupation: 'Editorial Assistant (Radio, Television)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789906,
    OccDE: 'Redaktionssekretär/in (nicht Verlag)',
    Occupation: 'Editorial Secretary (not Publishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683006,
    OccDE: 'Redaktionssekretär/in (Verlag)',
    Occupation: 'Editorial Secretary (Publishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 229504,
    OccDE: 'Erodierer/in',
    Occupation: 'EDM Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 776203,
    OccDE: 'EDV-Analytiker/in',
    Occupation: 'EDP Analyst',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 774407,
    OccDE: 'EDV-Assistent/in',
    Occupation: 'EDP Assistant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 779803,
    OccDE: 'EDV-Auditor/in',
    Occupation: 'EDP Auditor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 779807,
    OccDE: 'EDV-Revisor/in',
    Occupation: 'EDP Auditor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 779199,
    OccDE: 'EDV-Kaufmann/Kauffrau',
    Occupation: 'EDP Clerk',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 777191,
    OccDE: 'IT-Consultant',
    Occupation: 'EDP Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 777192,
    OccDE: 'IT-Berater/in',
    Occupation: 'EDP Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 777108,
    OccDE: 'EDV-Berater/in',
    Occupation: 'EDP Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 779804,
    OccDE: 'EDV-Controller/in',
    Occupation: 'EDP Controller',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776397,
    OccDE: 'IT-Koordinator/in',
    Occupation: 'EDP Coordinator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 774502,
    OccDE: 'EDV-Leiter/in',
    Occupation: 'EDP Director',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 778208,
    OccDE: 'EDV-Disponent/in',
    Occupation: 'EDP Dispatcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602406,
    OccDE: 'EDV-Ingenieur/in',
    Occupation: 'EDP Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 777106,
    OccDE: 'EDV-Anlagenberater/in',
    Occupation: 'EDP Equipment Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 879408,
    OccDE: 'EDV-Referent/in',
    Occupation: 'EDP Expert',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 879404,
    OccDE: 'EDV-Dozent/in',
    Occupation: 'EDP Lecturer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 774503,
    OccDE: 'EDV-Manager/in',
    Occupation: 'EDP Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778309,
    OccDE: 'EDV-Netzadministrator/in',
    Occupation: 'EDP Network Administrator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778108,
    OccDE: 'EDV-Operator/in',
    Occupation: 'EDP Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 776108,
    OccDE: 'EDV-Organisator/in',
    Occupation: 'EDP Organiser',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776109,
    OccDE: 'EDV-Planer/in',
    Occupation: 'EDP Planer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778209,
    OccDE: 'EDV-Nachbearbeiter/in',
    Occupation: 'EDP Post Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 776402,
    OccDE: 'EDV-Projektleiter/in',
    Occupation: 'EDP Project Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 774598,
    OccDE: 'IT-Security-Manager/in',
    Occupation: 'EDP Security Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778403,
    OccDE: 'EDV-Supervisor/in',
    Occupation: 'EDP Supervisor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 777109,
    OccDE: 'EDV-Support-Spezialist/in',
    Occupation: 'EDP Support Specialist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776110,
    OccDE: 'EDV-Systemorganisator/in',
    Occupation: 'EDP Systems Organiser',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 879406,
    OccDE: 'EDV-Lehrer/in',
    Occupation: 'EDP Teacher',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 622307,
    OccDE: 'EDV-Techniker/in',
    Occupation: 'EDP Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 879410,
    OccDE: 'EDV-Trainer/in',
    Occupation: 'EDP Trainer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 879403,
    OccDE: 'EDV-Ausbilder/in',
    Occupation: 'EDP Trainer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 777107,
    OccDE: 'EDV-Anwendungsberater/in',
    Occupation: 'EDP User Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 861503,
    OccDE: 'Bildungsreferent/in (Sozialarbeit)',
    Occupation: 'Education Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 868302,
    OccDE: 'Bildungsberater/in',
    Occupation: 'Education Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 868305,
    OccDE: 'Bildungsreferent/in (nicht Sozialarbeit)',
    Occupation: 'Education Consultant (not Social Work)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863003,
    OccDE: 'Erziehungsdiakon',
    Occupation: 'Education Deacon',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 868303,
    OccDE: 'Bildungsmanager/in',
    Occupation: 'Education Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863004,
    OccDE: 'Erziehungspfleger/in',
    Occupation: 'Education Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873904,
    OccDE: 'Pädagogische(r) Assistent/in',
    Occupation: 'Educational Aid',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787927,
    OccDE: 'Pädagogische(r) Assistent/in (Verwaltung)',
    Occupation: 'Educational Assistant (Administration)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863708,
    OccDE: 'Pädagogische Hilfskraft (Kindergarten)',
    Occupation: 'Educational Assistant (Kindergarten)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 885001,
    OccDE: 'Erziehungswissenschaftler/in',
    Occupation: 'Educational Scientist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873901,
    OccDE: 'Pädagogische Fachkraft (Sonderschule)',
    Occupation: 'Educational Specialist (Special Education)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863002,
    OccDE: 'Erzieher/in',
    Occupation: 'Educator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879203,
    OccDE: 'Erzieher/in (Freizeitpädagoge/-pädagogin)',
    Occupation: 'Educator (Camp Pedagogue)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863001,
    OccDE: 'Erzieher/in (nicht Freizeitpädagogik)',
    Occupation: 'Educator (not leisure-time pedagogy)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774105,
    OccDE: 'EDV-Angestellte/r',
    Occupation: 'EDV Employee',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 779203,
    OccDE: 'EDV-Betriebswirt/in',
    Occupation: 'EDV Management Administrator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 774106,
    OccDE: 'EDV-Fachmann/Fachfrau',
    Occupation: 'EDV Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 882501,
    OccDE: 'Ägyptolog(e/in)',
    Occupation: 'Egyptologist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 313202,
    OccDE: 'Elektromotorenbauer/in',
    Occupation: 'Electric Engine Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311107,
    OccDE: 'Elektromonteur/in (Anlagenmontage)',
    Occupation: 'Electric Installation Technician (Systems Installation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 313406,
    OccDE: 'Elektromotorenwickler/in',
    Occupation: 'Electric Motor Winder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241403,
    OccDE: 'Elektroschweißer/in',
    Occupation: 'Electric Welder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622001,
    OccDE: 'Elektroassistent/in (Elektrotechniker/in)',
    Occupation: 'Electrical Assistant (Electrical Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 673205,
    OccDE: 'Elektrokaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Electrical Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622602,
    OccDE: 'Elektrokonstrukteur/in',
    Occupation: 'Electrical Construction Engineer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 622601,
    OccDE: 'Elektro-Konstruktionstechniker/in',
    Occupation: 'Electrical Construction Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 921802,
    OccDE: 'Elektroberater/in',
    Occupation: 'Electrical Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602802,
    OccDE: 'Elektro-Konstruktionsingenieur/in',
    Occupation: 'Electrical Design Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 641301,
    OccDE: 'Elektrozeichner/in',
    Occupation: 'Electrical Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602001,
    OccDE: 'Elektroingenieur/in',
    Occupation: 'Electrical Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602606,
    OccDE: 'Elektroingenieur/in (Automatisierungstechnik)',
    Occupation: 'Electrical Engineer (Automation Technology)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602903,
    OccDE: 'Elektroingenieur/in (Kabel-, Hochspannungstechnik)',
    Occupation: 'Electrical Engineer (Cable, High-Voltage Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602104,
    OccDE: 'Elektroingenieur/in (Elektromaschinen, -apparate)',
    Occupation: 'Electrical Engineer (Electrical Machinery, Equipment)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602103,
    OccDE: 'Elektroingenieur/in (Elektroenergieanlagen)',
    Occupation: 'Electrical Engineer (Electrical Power Systems)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 310026,
    OccDE: 'Elektrotechniker/in (Elektroinstallateur/in)',
    Occupation: 'Electrical Engineer (Electrician)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602407,
    OccDE: 'Elektroingenieur/in (elektronische Datenverarbeitung)',
    Occupation: 'Electrical Engineer (Electronic Data Processing)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602302,
    OccDE: 'Elektroingenieur/in (elektronische Geräte und Bauelemente)',
    Occupation: 'Electrical Engineer (Electronic Devices and Components)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602607,
    OccDE: 'Elektroingenieur/in (elektrische Meßtechnik)',
    Occupation: 'Electrical Engineer (Electronic Measuring Technology)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602301,
    OccDE: 'Elektroingenieur/in (Elektronik-Technologie, Mikroelektronik)',
    Occupation:
      'Electrical Engineer (Electronics Technology, Microelectronics)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602502,
    OccDE: 'Elektroingenieur/in (Hochfrequenztechnik)',
    Occupation: 'Electrical Engineer (High Frequency Technology)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602106,
    OccDE: 'Elektroingenieur/in (Starkstromanlagen)',
    Occupation: 'Electrical Engineer (High-Voltage Systems)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602303,
    OccDE: 'Elektroingenieur/in (industrielle Elektronik)',
    Occupation: 'Electrical Engineer (Industrial Electronics)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602408,
    OccDE: 'Elektroingenieur/in (Informationstechnik)',
    Occupation: 'Electrical Engineer (Information Technology)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602203,
    OccDE: 'Elektroingenieur/in (Geräte und Anlagen der Nachrichtentechnik)',
    Occupation: 'Electrical Engineer (Telecommunications Equipment)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 316001,
    OccDE: 'Elektromontierer/in',
    Occupation: 'Electrical Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310703,
    OccDE: 'Elektroinstallateurhelfer/in',
    Occupation: 'Electrical Installation Technician Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310704,
    OccDE: 'Elektromonteurhelfer/in',
    Occupation: 'Electrical Installation Technician Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673204,
    OccDE: 'Elektrofachhändler/in',
    Occupation: 'Electrical Specialist Dealer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311302,
    OccDE: 'Elektroschaltanlagenbauer/in',
    Occupation: 'Electrical Switchgear Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311106,
    OccDE: 'Elektroanlagenmonteur/in',
    Occupation: 'Electrical System Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311104,
    OccDE: 'Elektroanlagenelektroniker/in',
    Occupation: 'Electrical Systems Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311105,
    OccDE: 'Elektroanlageninstallateur/in',
    Occupation: 'Electrical Systems Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622003,
    OccDE: 'Elektrotechniker/in',
    Occupation: 'Electrical Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 310009,
    OccDE: 'Elektrofacharbeiter/in (nicht unter Tage)',
    Occupation: 'Electrical Technician (not underground)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622004,
    OccDE: 'Elektrotechnische(r) Angestellte/r',
    Occupation: 'Electrical Technology Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622005,
    OccDE: 'Elektrotechnische(r) Assistent/in',
    Occupation: 'Electrical Technology Employee Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310008,
    OccDE: 'Elektroarbeiter/in',
    Occupation: 'Electrical Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 313006,
    OccDE: 'Elektroschlosser/in',
    Occupation: 'Electrical Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310004,
    OccDE: 'Elektriker/in',
    Occupation: 'Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310010,
    OccDE: 'Elektrofachkraft',
    Occupation: 'Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310011,
    OccDE: 'Elektrohandwerker/in',
    Occupation: 'Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310012,
    OccDE: 'Elektroinstallateur/in',
    Occupation: 'Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310022,
    OccDE: 'Elektromonteur/in (Installation)',
    Occupation: 'Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310025,
    OccDE: 'Elektromonteur/in',
    Occupation: 'Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310042,
    OccDE: 'Montageelektriker/in',
    Occupation: 'Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311405,
    OccDE: 'Elektromonteur/in (Kabel)',
    Occupation: 'Electrician (Cable)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316312,
    OccDE: 'Elektroniker/in (Elektrogerätebauer/in)',
    Occupation: 'Electrician (Electronic Equipment Maker)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311404,
    OccDE: 'Elektromonteur/in (Freileitungs-, Erdungsanlagen)',
    Occupation: 'Electrician (Overhead Power Lines, Grounding Systems)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311403,
    OccDE: 'Elektromonteur/in (Freileitungen)',
    Occupation: 'Electrician (Overhead Power Lines)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310024,
    OccDE: 'Elektromonteur/in (Wartung, Instandhaltung)',
    Occupation: 'Electrician (Servicing, Maintenance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311301,
    OccDE: 'Elektromonteur/in (Schalt- und Verteilungsanlagen)',
    Occupation: 'Electrician (Switchboards and Distribution Systems)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '072202',
    OccDE: 'Elektriker (unter Tage)',
    Occupation: 'Electrician (underground)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310015,
    OccDE: 'Elektroinstallateur/in und Radiomechaniker/in',
    Occupation: 'Electrician and Radio Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310016,
    OccDE: 'Elektroinstallateur/in und Rundfunkmechaniker/in',
    Occupation: 'Electrician and Radio Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310701,
    OccDE: 'Elektrikerhelfer/in',
    Occupation: 'Electrician Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310702,
    OccDE: 'Elektrohelfer/in',
    Occupation: 'Electrician Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316104,
    OccDE: 'Elektromechaniker/in',
    Occupation: 'Electromechanical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 313002,
    OccDE: 'Elektromaschinenbauer/in',
    Occupation: 'Electromechanical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 313101,
    OccDE: 'Elektromaschinenmonteur/in',
    Occupation: 'Electromechanical Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 313004,
    OccDE: 'Elektromaschinenmechaniker/in',
    Occupation: 'Electromechanical Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316301,
    OccDE: 'Elektroapparatebauer/in',
    Occupation: 'Electronic Appliance Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316302,
    OccDE: 'Elektroapparatemechaniker/in',
    Occupation: 'Electronic Appliance Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321922,
    OccDE: 'Elektronikteilemontierer/in',
    Occupation: 'Electronic Components Assembly Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321918,
    OccDE: 'Elektrogerätemontierer/in',
    Occupation: 'Electronic Equipment Assembly Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316304,
    OccDE: 'Elektrogerätebauer/in',
    Occupation: 'Electronic Equipment Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316305,
    OccDE: 'Elektrogerätemechaniker/in',
    Occupation: 'Electronic Equipment Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316107,
    OccDE: 'Elektrosignalmechaniker/in',
    Occupation: 'Electronic Signal Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622202,
    OccDE: 'Elektrotechnische(r) Assistent/in in der Nachrichtentechnik',
    Occupation: 'Electronic Technology Assistant in Telecommunications',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981117,
    OccDE: 'Student/in Elektro- und Informationstechnik',
    Occupation: 'Electronics and Information Technology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316309,
    OccDE: 'Elektromontierer/in (Bauelemente der Elektroindustrie)',
    Occupation: 'Electronics Assembler (Electronics Sector Components)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321919,
    OccDE: 'Elektromontierer/in (Fließband-, Taktarbeit)',
    Occupation:
      'Electronics Assembly Worker (Assembly Line, Repetitive Labour)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622308,
    OccDE: 'Elektronik-Assistent/in',
    Occupation: 'Electronics Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622309,
    OccDE: 'Elektronikentwickler/in',
    Occupation: 'Electronics Developer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602306,
    OccDE: 'Elektronikingenieur/in',
    Occupation: 'Electronics Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 316010,
    OccDE: 'Elektronikwartungsmechaniker/in',
    Occupation: 'Electronics Maintenance Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316005,
    OccDE: 'Elektronikmechaniker/in',
    Occupation: 'Electronics Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316004,
    OccDE: 'Elektronikfacharbeiter/in',
    Occupation: 'Electronics Qualified Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316003,
    OccDE: 'Elektronikfacharbeiter/in (Instandhaltung)',
    Occupation: 'Electronics Qualified Worker (Maintenance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316008,
    OccDE: 'Elektronikreparateur/in',
    Occupation: 'Electronics Repairman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671218,
    OccDE: 'Elektrokaufmann/-kauffrau (Großhandel)',
    Occupation: 'Electronics Salesperson (Wholesale)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 316022,
    OccDE: 'Serviceelektroniker/in',
    Occupation: 'Electronics Servicer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622311,
    OccDE: 'Elektroniktechniker/in',
    Occupation: 'Electronics Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 316007,
    OccDE: 'Elektronikmonteur/in',
    Occupation: 'Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622310,
    OccDE: 'Elektroniker/in',
    Occupation: 'Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316703,
    OccDE: 'Elektronikhelfer/in',
    Occupation: 'Electronics Technician Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316504,
    OccDE: 'Mechaniker/in für BMSR-Technik',
    Occupation: 'Electronics Technician for Automation Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316802,
    OccDE: 'Elektroprüfer/in',
    Occupation: 'Electronics Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316002,
    OccDE: 'Elektronikarbeiter/in',
    Occupation: 'Electronics Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 234008,
    OccDE: 'Galvaniseur/in',
    Occupation: 'Electroplater',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 234010,
    OccDE: 'Galvanisierer/in',
    Occupation: 'Electroplater',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 234703,
    OccDE: 'Galvaniseurhelfer/in',
    Occupation: 'Electroplater Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626301,
    OccDE: 'Galvanotechniker/in',
    Occupation: 'Electroplating Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 234006,
    OccDE: 'Galvanikarbeiter/in',
    Occupation: 'Electroplating Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 359139,
    OccDE: 'Sticker/in',
    Occupation: 'Embroiderer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854210,
    OccDE: 'Rettungssanitäter/in',
    Occupation: 'Emergency Medical Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780001,
    OccDE: 'Angestellte/r',
    Occupation: 'Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765003,
    OccDE: 'Angestellte/r (gehobener nichttechnischer Verwaltungsdienst)',
    Occupation: 'Employee (higher non-technical administrative service)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 764010,
    OccDE: 'Angestellte/r (höherer nichttechnischer Verwaltungsdienst)',
    Occupation: 'Employee (highest non-technical administrative civil service)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 780101,
    OccDE: 'Angestellte/r (Bürofachkraft)',
    Occupation: 'Employee (Office Specialist)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 652903,
    OccDE: 'Mitarbeiter/in für Aus- und Fortbildung',
    Occupation: 'Employee for Education and Professional Development',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628232,
    OccDE: 'Mitarbeiter/in für technische Organisation (MTO)',
    Occupation: 'Employee for Technical Organisation (ETO)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 689013,
    OccDE: 'Mitarbeiter/in im Außendienst',
    Occupation: 'Employee in Field Sales',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787301,
    OccDE: 'Angestellte/r in der Bundesanstalt für Arbeit',
    Occupation: 'Employee in the Federal Department of Labour',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791005,
    OccDE: 'Angestellter im Sicherheitsdienst',
    Occupation: 'Employee in the Security Service',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787302,
    OccDE: 'Angestellte/r in der Versorgungsverwaltung',
    Occupation: 'Employee in the Supply Management Department',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 763304,
    OccDE: 'Personalrat/-rätin',
    Occupation: 'Employee Representative',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 868104,
    OccDE: 'Arbeitsvermittler/in',
    Occupation: 'Employment Agency Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 235108,
    OccDE: 'Emaillierer/in',
    Occupation: 'Enameller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853919,
    OccDE: 'Endoskopieschwester',
    Occupation: 'Endoscopy Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921803,
    OccDE: 'Energieberater/in',
    Occupation: 'Energy Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602114,
    OccDE: 'Energieingenieur/in',
    Occupation: 'Energy Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602112,
    OccDE: 'Energieingenieur/in (Energetik, Energietechnik)',
    Occupation: 'Energy Engineer (Energy Engineering and Technology)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 541003,
    OccDE: 'Energieanlagenwart/in',
    Occupation: 'Energy Equipment Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316325,
    OccDE: 'Energiegeräteelektroniker/in',
    Occupation: 'Energy Equipment Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316326,
    OccDE: 'Energiegerätemechaniker/in',
    Occupation: 'Energy Equipment Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 541004,
    OccDE: 'Energiegeräteüberwacher/in',
    Occupation: 'Energy Equipment Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622103,
    OccDE: 'Energieanlagentechniker/in',
    Occupation: 'Energy Installations Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 311109,
    OccDE: 'Energieanlagenbauer/in',
    Occupation: 'Energy Systems Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311110,
    OccDE: 'Energieanlagenelektroniker/in',
    Occupation: 'Energy Systems Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311111,
    OccDE: 'Energieanlageninstallateur/in',
    Occupation: 'Energy Systems Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921804,
    OccDE: 'Energiefachberater/in',
    Occupation: 'Energy Technical Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622104,
    OccDE: 'Energietechniker/in',
    Occupation: 'Energy Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 814244,
    OccDE: 'Vollzugsassistent/in',
    Occupation: 'Enforcement Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814245,
    OccDE: 'Vollzugsbeamt(er/in)',
    Occupation: 'Enforcement Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814112,
    OccDE: 'Vollstreckungsbeamt(er/in)',
    Occupation: 'Enforcement Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814118,
    OccDE: 'Vollziehungsbeamt(er/in)',
    Occupation: 'Enforcement Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 313206,
    OccDE: 'Motorenbauer/in (Elektromotorenbauer/in)',
    Occupation: 'Engine Builder (Electric Engine builder)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 711202,
    OccDE: 'Triebfahrzeugführer/in',
    Occupation: 'Engine Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714525,
    OccDE: 'Triebwagenführer/in (K)',
    Occupation: 'Engine Driver (Automotive)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283309,
    OccDE: 'Triebwerkmechaniker/in',
    Occupation: 'Engine Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600002,
    OccDE: 'Ingenieur/in',
    Occupation: 'Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061204',
    OccDE: 'Ingenieur/in (Forstwirtschaft)',
    Occupation: 'Engineer (Forestry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600001,
    OccDE: 'Ingenieur/in (grad.)',
    Occupation: 'Engineer (grad)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801156,
    OccDE: 'Pionier (Soldat)',
    Occupation: 'Engineer (Soldier)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601098,
    OccDE: 'Diplom-Ingenieur/in - (FH)           Mechatronik',
    Occupation: 'Engineer (Technical Institute) with Degree in Mechatronics',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 611820,
    OccDE: 'Ingenieur/in für chemische Verfahrenstechnik',
    Occupation: 'Engineer Chemical Process Engineering',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602308,
    OccDE: 'Ingenieur/in für elektronische Bauelemente',
    Occupation: 'Engineer Electronic Components',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601310,
    OccDE: 'Ingenieur/in für Luftfahrttechnik',
    Occupation: 'Engineer for Aeronautical Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602608,
    OccDE: 'Ingenieur/in für Automatisierungstechnik',
    Occupation: 'Engineer for Automation Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601512,
    OccDE: 'Ingenieur/in für Gebäudetechnik',
    Occupation: 'Engineer for Building Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601513,
    OccDE: 'Ingenieur/in für Haustechnik',
    Occupation: 'Engineer for Building Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603104,
    OccDE: 'Ingenieur/in für Baubetrieb',
    Occupation: 'Engineer for Construction',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 609203,
    OccDE: 'Ingenieur/in für Baubetrieb (Architekt/in)',
    Occupation: 'Engineer for Construction (Architect)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602002,
    OccDE: 'Ingenieur/in für Elektrotechnik',
    Occupation: 'Engineer for Electrical engineering',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601511,
    OccDE: 'Ingenieur/in für Energieanlagentechnik',
    Occupation: 'Engineer for Energy Plant Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 608314,
    OccDE: 'Ingenieur/in für Umwelt- und Hygienetechnik',
    Occupation: 'Engineer for Environmental and Hygiene Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602906,
    OccDE: 'Ingenieur/in für Gerätetechnik (Elektro)',
    Occupation: 'Engineer for Equipment Technology (Electronics)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601517,
    OccDE:
      'Ingenieur/in für techn. Gebäudeausrüstung (Heizungs-,Lüftungstechnik)',
    Occupation: 'Engineer for Etch. Building Services (Heating, Ventilation)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 802516,
    OccDE: 'Ingenieur/in für Brandschutz',
    Occupation: 'Engineer for Fire Safety',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606113,
    OccDE: 'Ingenieur/in für Obst- und Gemüseverwertung',
    Occupation: 'Engineer for Fruit and Vegetable Processing',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601514,
    OccDE: 'Ingenieur/in für Heizungs-, Lüftungs- und Sanitärtechnik',
    Occupation: 'Engineer for Heating, Ventilation, and Sanitary Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 609402,
    OccDE: 'Ingenieur/in für Raumplanung',
    Occupation: 'Engineer for Land-Use Planning',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '052303',
    OccDE: 'Ingenieur/in für Landschaftsarchitektur',
    Occupation: 'Engineer for Landscape Architecture',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '052202',
    OccDE: 'Ingenieur/in für Landespflege (nicht Raumplanung)',
    Occupation: 'Engineer for Landscape Planning (not Land-Use Planning)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 721506,
    OccDE: 'Ingenieur/in für Schiffsbetriebstechnik',
    Occupation: 'Engineer for Maritime Engineering',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602609,
    OccDE: 'Ingenieur/in für Meß- und Regeltechnik',
    Occupation: 'Engineer for Measurement and Control Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602610,
    OccDE: 'Ingenieur/in für Meß-, Steuerungs- und Regeltechnik',
    Occupation: 'Engineer for Measurement, Open Loop and Control Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600904,
    OccDE: 'Ingenieur/in für Meßtechnik',
    Occupation: 'Engineer for Measuring Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601904,
    OccDE: 'Ingenieur/in für Anlagenbau/-technik',
    Occupation: 'Engineer for Mechanical Engineering and Construction',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 835306,
    OccDE: 'Ingenieur/in für Medientechnik',
    Occupation: 'Engineer for Media Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 612201,
    OccDE: 'Ingenieur/in für physikalische Technik',
    Occupation: 'Engineer for Physical Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606603,
    OccDE: 'Ingenieur/in für Kunststofftechnik',
    Occupation: 'Engineer for Plastics Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601404,
    OccDE: 'Ingenieur/in für Feinwerktechnik',
    Occupation: 'Engineer for Precision Engineering',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 611825,
    OccDE: 'Ingenieur/in für Verfahrens- und Umwelttechnik',
    Occupation: 'Engineer for Process and Environmental Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 609305,
    OccDE: 'Ingenieur/in für Gebiets- und Stadtplanung',
    Occupation: 'Engineer for Regional and Urban Planning',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603406,
    OccDE: 'Ingenieur/in für Straßenbau/-verkehr',
    Occupation: 'Engineer for Road Construction/Traffic',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 608312,
    OccDE: 'Ingenieur/in für technischen Umweltschutz',
    Occupation: 'Engineer for technical Environmental Protection',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601104,
    OccDE: 'Ingenieur/in für Kraftfahrzeugtechnik',
    Occupation: 'Engineer for Vehicle Engineering',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603512,
    OccDE: 'Ingenieur/in für Wasserwirtschaft und Kulturtechnik',
    Occupation: 'Engineer for Water Management and Culture Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601309,
    OccDE: 'Ingenieur/in für Luft- und Raumfahrttechnik',
    Occupation: 'Engineer in Aeronautics and Astronautics',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '032003',
    OccDE: 'Ingenieur/in der Landwirtschaft',
    Occupation: 'Engineer in Agriculture',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '032004',
    OccDE: 'Ingenieur/in für Landbau',
    Occupation: 'Engineer in Agriculture',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601516,
    OccDE: 'Ingenieur/in für Klima- und Trocknungstechnik',
    Occupation: 'Engineer in Air Conditioning and Drying Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606304,
    OccDE: 'Ingenieur/in für Bekleidungstechnologie',
    Occupation: 'Engineer in Apparel Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 609003,
    OccDE: 'Ingenieur/in für Architektur',
    Occupation: 'Engineer in Architecture',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 608307,
    OccDE: 'Ingenieur/in für biomedizinische Technik',
    Occupation: 'Engineer in Biomedical Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 611818,
    OccDE: 'Ingenieur/in für Biotechnologie',
    Occupation: 'Engineer in Biotechnology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 604801,
    OccDE: 'Ingenieur/in für Kartographie',
    Occupation: 'Engineer in Cartography',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 611819,
    OccDE: 'Ingenieur/in für Chemietechnik',
    Occupation: 'Engineer in Chemistry Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601907,
    OccDE: 'Ingenieur/in für Baumaschinen',
    Occupation: 'Engineer in Construction Equipment',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601605,
    OccDE: 'Ingenieur/in für Konstruktionstechnik (Maschinenwesen)',
    Occupation: 'Engineer in Construction Technology (Mechanical Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602415,
    OccDE: 'Ingenieur/in für Informationsverarbeitung',
    Occupation: 'Engineer in Data Processing',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 605801,
    OccDE: 'Ingenieur/in für Entwicklung metallischer Werkstoffe',
    Occupation: 'Engineer in Development in Metallic Materials',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606806,
    OccDE:
      'Ingenieur/in für Wirtschafts- und Betriebstechnik im grafischen Gewerbe',
    Occupation:
      'Engineer in Economic and Industrial Engineering in the Graphics Industry',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602307,
    OccDE: 'Ingenieur/in für Elektroniktechnologie',
    Occupation: 'Engineer in Electronic Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602115,
    OccDE: 'Ingenieur/in für Energieanwendung',
    Occupation: 'Engineer in Energy Applications',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 608221,
    OccDE: 'Ingenieur/in für Umweltsicherung',
    Occupation: 'Engineer in Environmental Safety',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606112,
    OccDE: 'Ingenieur/in für Nahrungsmitteltechnologie',
    Occupation: 'Engineer in Food Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601403,
    OccDE: 'Ingenieur/in für Feingerätetechnik und Optik',
    Occupation: 'Engineer in for Precision Engineering and Optics',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 605602,
    OccDE: 'Ingenieur/in für Gießereitechnik',
    Occupation: 'Engineer in Foundry Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 607109,
    OccDE: 'Ingenieur/in für Wirtschaftsingenieurwesen',
    Occupation: 'Engineer in Industrial Engineering',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602414,
    OccDE: 'Ingenieur/in für Informationstechnik',
    Occupation: 'Engineer in Information Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 609401,
    OccDE: 'Ingenieur/in für Raum- und Umweltplanung',
    Occupation: 'Engineer in Land-Use and Environmental Planning',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601804,
    OccDE: 'Ingenieur/in für Fertigungsprozeßgestaltung (Maschinenwesen)',
    Occupation:
      'Engineer in Manufacturing Process Engineering (Mechanical Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 605805,
    OccDE: 'Ingenieur/in für Werkstofftechnik',
    Occupation: 'Engineer in Materials Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 608309,
    OccDE: 'Ingenieur/in für medizinische Laboratoriumstechnik',
    Occupation: 'Engineer in Medical Laboratory Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 605804,
    OccDE: 'Ingenieur/in für Metallveredlung und Werkstoffkunde',
    Occupation: 'Engineer in Metal Refining and Materials Science',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 608217,
    OccDE: 'Ingenieur/in für Arbeitssicherheit',
    Occupation: 'Engineer in Occupational Safety',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601510,
    OccDE: 'Ingenieur/in für Betriebs- und Versorgungstechnik',
    Occupation: 'Engineer in Operations and Supply Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606905,
    OccDE: 'Ingenieur/in für Verpackungstechnik',
    Occupation: 'Engineer in Packaging Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606511,
    OccDE: 'Ingenieur/in für Vorfertigung im Bauwesen',
    Occupation: 'Engineer in Prefabrication in Construction',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606803,
    OccDE: 'Ingenieur/in für Drucktechnik',
    Occupation: 'Engineer in Printing Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 611822,
    OccDE: 'Ingenieur/in für Prozeßverfahrenstechnik',
    Occupation: 'Engineer in Process Engineering',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 611826,
    OccDE: 'Ingenieur/in für Verfahrenstechnik',
    Occupation: 'Engineer in Process Engineering',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601914,
    OccDE: 'Ingenieur/in für Verarbeitungsmaschinen',
    Occupation: 'Engineer in Processing Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600407,
    OccDE: 'Ingenieur/in für Fertigungstechnik',
    Occupation: 'Engineer in Production Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600408,
    OccDE: 'Ingenieur/in für Produktionstechnik',
    Occupation: 'Engineer in Production Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 607105,
    OccDE: 'Ingenieur/in für Produktionstechnik (Wirtschaftsingenieur/in)',
    Occupation: 'Engineer in Production Technology (Industrial Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601806,
    OccDE: 'Ingenieur/in für Produktionstechnik (Maschinenbau)',
    Occupation: 'Engineer in Production Technology (Mechanical Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601905,
    OccDE: 'Ingenieur/in für Antriebstechnik',
    Occupation: 'Engineer in Propulsion Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600105,
    OccDE: 'Ingenieur/in für Qualitätssicherung',
    Occupation: 'Engineer in Quality Assurance',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601807,
    OccDE:
      'Ingenieur/in für Qualitätssicherung u.Fertigungsmeßtechnik(Maschinen)',
    Occupation:
      'Engineer in Quality Assurance and Projection Measurement Technology (Machinery)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 612505,
    OccDE: 'Ingenieur/in für Strahlenschutz',
    Occupation: 'Engineer in Radiation Protection',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 608218,
    OccDE: 'Ingenieur/in für Eisenbahnsicherungstechnik',
    Occupation: 'Engineer in Railway Safety Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 608219,
    OccDE: 'Ingenieur/in für Sicherheitstechnik',
    Occupation: 'Engineer in Safety Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601518,
    OccDE: 'Ingenieur/in für Versorgungstechnik',
    Occupation: 'Engineer in Supply Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 604104,
    OccDE: 'Ingenieur/in für Vermessungswesen',
    Occupation: 'Engineer in Surveying',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 604103,
    OccDE: 'Ingenieur/in für Vermessungstechnik',
    Occupation: 'Engineer in Surveying Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 611823,
    OccDE: 'Ingenieur/in für Systemverfahrenstechnik',
    Occupation: 'Engineer in Systems Process Engineering',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602207,
    OccDE: 'Ingenieur/in für Nachrichtentechnik',
    Occupation: 'Engineer in Telecommunications',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606111,
    OccDE: 'Ingenieur/in für Milchwirtschaft',
    Occupation: 'Engineer in the Dairy Sector',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606108,
    OccDE: 'Ingenieur/in für Lebensmittelindustrie',
    Occupation: 'Engineer in the Food Industry',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606110,
    OccDE: 'Ingenieur/in für Lebensmittelwirtschaft',
    Occupation: 'Engineer in the Food Sector',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606109,
    OccDE: 'Ingenieur/in für Lebensmitteltechnologie',
    Occupation: 'Engineer in the Food Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606405,
    OccDE: 'Ingenieur/in für Holztechnik',
    Occupation: 'Engineer in Wood Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603914,
    OccDE: 'Ingenieur/in für Informationsverarbeitung im Bauwesen',
    Occupation: 'Engineer Information Processing in Construction',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603511,
    OccDE: 'Ingenieur/in für Wasserbau/-wirtschaft',
    Occupation: 'Engineer Waterways Water Management',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600099,
    OccDE: 'Diplom-Ingenieur/in',
    Occupation: 'Engineer with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601999,
    OccDE: 'Diplom-Ingenieur/in Landmaschinentechnik',
    Occupation: 'Engineer with Degree in Agricultural Machinery Engineering',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 611899,
    OccDE: 'Diplom-Ingenieur/in Chemie',
    Occupation: 'Engineer with Degree in Chemistry',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602099,
    OccDE: 'Diplom-Ingenieur/in Elektrotechnik',
    Occupation: 'Engineer with Degree in Electrical engineering',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602199,
    OccDE: 'Diplom-Ingenieur/in Energietechnik',
    Occupation: 'Engineer with Degree in Energy Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 608399,
    OccDE: 'Diplom-Ingenieur/in Umwelt- und Hygienetechnik',
    Occupation: 'Engineer with Degree in Environmental and Hygiene Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601099,
    OccDE: 'Diplom-Ingenieur/in Maschinenbau',
    Occupation: 'Engineer with Degree in Mechanical Engineering',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601499,
    OccDE: 'Diplom-Ingenieur/in Augenoptik',
    Occupation: 'Engineer with Degree in Optics',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606899,
    OccDE: 'Diplom-Ingenieur/in Drucktechnik',
    Occupation: 'Engineer with Degree in Printing Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602599,
    OccDE: 'Diplom-Ingenieur/in Fernsehtechnik',
    Occupation: 'Engineer with Degree in Television Technology',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606399,
    OccDE: 'Diplom-Ingenieur/in Textil',
    Occupation: 'Engineer with Degree in Textiles',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 621004,
    OccDE: 'Ingenieurassistent/in (Maschinenbau)',
    Occupation: 'Engineering Assistant (Mechanical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 607110,
    OccDE: 'Ingenieurökonom/in',
    Occupation: 'Engineering Economist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 313007,
    OccDE: 'Maschinenbauelektriker/in',
    Occupation: 'Engineering Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 313009,
    OccDE: 'Maschinenelektroniker/in',
    Occupation: 'Engineering Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608315,
    OccDE: 'Ingenieur/in für Umweltschutz',
    Occupation: 'Engineering for Environmental Protection',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606904,
    OccDE: 'Ingenieur/in für Verfahrenstechnik Papier und Kunststoff',
    Occupation: 'Engineering for Process Engineering in Paper and Plastic',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606115,
    OccDE: 'Ingenieur/in für Technologie der Gärungs- und Getränkeindustrie',
    Occupation:
      'Engineering for Technology in the Fermentation and Beverage industry',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 621806,
    OccDE: 'Maschinenbauführer/in',
    Occupation: 'Engineering Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602416,
    OccDE: 'Ingenieur/in für technische Informatik',
    Occupation: 'Engineering in Technical Computer Science',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 273801,
    OccDE: 'Maschinenbaumechaniker/in (Schwerpunkt Erzeugende Mechanik)',
    Occupation: 'Engineering Mechanic (specialising in Production Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 612099,
    OccDE: 'Diplom-Ingenieur/in Physik',
    Occupation: 'Engineering Physicist with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 278104,
    OccDE: 'Teilezurichter/in',
    Occupation: 'Engineering Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621005,
    OccDE: 'Maschinenbautechniker/in',
    Occupation: 'Engineering Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 882202,
    OccDE: 'Anglist/in',
    Occupation: 'English Studies Scholar',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 294004,
    OccDE: 'Graveur/in',
    Occupation: 'Engraver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 294701,
    OccDE: 'Graveurhelfer/in',
    Occupation: 'Engraving Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801105,
    OccDE: 'Fähnrich',
    Occupation: 'Ensign',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841399,
    OccDE: 'HNO-Arzt',
    Occupation: 'ENT Doctor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 856108,
    OccDE: 'HNO-Arzthelfer/in',
    Occupation: "ENT Doctor's Assistant",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629319,
    OccDE: 'Umweltschutzassistent/in',
    Occupation: 'Environmental Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921814,
    OccDE: 'Umweltberater/in',
    Occupation: 'Environmental Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608318,
    OccDE: 'Umweltberater/in (Umweltschutzingenieur/in)',
    Occupation: 'Environmental Consultant (Environmental Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 531917,
    OccDE: 'Umweltschutzarbeiter/in',
    Occupation: 'Environmental Preservation Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803514,
    OccDE: 'Umweltsachbearbeiter/in',
    Occupation: 'Environmental Protection Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608319,
    OccDE: 'Umweltschutzingenieur/in',
    Occupation: 'Environmental Protection Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 803509,
    OccDE: 'Naturschutzbeauftragte/r',
    Occupation: 'Environmental Protection Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803515,
    OccDE: 'Umweltschutzbeauftragte/r',
    Occupation: 'Environmental Protection Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803516,
    OccDE: 'Umweltschutzsachbearbeiter/in',
    Occupation: 'Environmental protection Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803513,
    OccDE: 'Umweltreferent/in',
    Occupation: 'Environmental Protection Referendary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981173,
    OccDE: 'Student/in Umweltschutz',
    Occupation: 'Environmental Protection Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883307,
    OccDE: 'Umweltwissenschaftler/in',
    Occupation: 'Environmental Scientist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 629321,
    OccDE: 'Umweltschutztechniker/in',
    Occupation: 'Environmental Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882507,
    OccDE: 'Epigraphiker/in',
    Occupation: 'Epigraphist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861907,
    OccDE: 'Gleichstellungsbeauftragte',
    Occupation: 'Equality Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 278204,
    OccDE: 'Gerätezusammensetzer/in',
    Occupation: 'Equipment Assembly Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321926,
    OccDE: 'Gerätemontierer/in (Elektrogeräte)',
    Occupation: 'Equipment Assembly Worker (Electronic Equipment)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 161409,
    OccDE: 'Maschinengehilf(e/in) (Papiermaschine)',
    Occupation: 'Equipment Assistant (paper machinery)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621611,
    OccDE: 'Vorrichtungskonstrukteur/in',
    Occupation: 'Equipment Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316338,
    OccDE: 'Geräteelektroniker/in',
    Occupation: 'Equipment Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601901,
    OccDE: 'Apparatebauingenieur/in',
    Occupation: 'Equipment Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602901,
    OccDE: 'Anlageningenieur/in (Elektro)',
    Occupation: 'Equipment Engineer (Electronics)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 241999,
    OccDE: 'Anlagenmechaniker/in - Fachrichtung  Schweißtechnik',
    Occupation: 'Equipment Engineer specialising in Welding Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264101,
    OccDE: 'Anlagenmonteur/in (Rohrinstallation)',
    Occupation: 'Equipment Fitter (Pipe Installation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549998,
    OccDE: 'Anlagenmonteur/in',
    Occupation: 'Equipment Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621801,
    OccDE: 'Anlageninstandhaltungstechniker/in',
    Occupation: 'Equipment Maintenance Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250003,
    OccDE: 'Anlagenbauer/in',
    Occupation: 'Equipment Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 252002,
    OccDE: 'Apparatebauer/in',
    Occupation: 'Equipment Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300401,
    OccDE: 'Apparatemechaniker/in',
    Occupation: 'Equipment Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 276001,
    OccDE: 'Betriebsmechaniker/in',
    Occupation: 'Equipment Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250004,
    OccDE: 'Anlagenmechaniker/in',
    Occupation: 'Equipment Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300408,
    OccDE: 'Gerätemechaniker/in (Feinmechanik)',
    Occupation: 'Equipment Mechanic (Precision Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 252001,
    OccDE: 'Anlagenmechaniker/in (Fachrichtung Apparatetechnik)',
    Occupation: 'Equipment Mechanic (specialising in Manufacturing Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264001,
    OccDE: 'Anlagenmechaniker/in (Fachrichtung Versorgungstechnik)',
    Occupation: 'Equipment Mechanic (specialising in Supply Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 274001,
    OccDE: 'Betriebshandwerker/in',
    Occupation: 'Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 742001,
    OccDE: 'Gerätefahrer/in',
    Occupation: 'Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 742002,
    OccDE: 'Geräteführer/in',
    Occupation: 'Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141001,
    OccDE: 'Anlagenfahrer/in (Chemiebetrieb)',
    Occupation: 'Equipment Operator (Chemical Plant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141018,
    OccDE: 'Maschinenoperator/in (Chemiebetrieb)',
    Occupation: 'Equipment Operator (Chemical Plant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778115,
    OccDE: 'Maschinenbediener/in (EDV)',
    Occupation: 'Equipment Operator (EDP)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191002,
    OccDE: 'Anlagensteuer(er/in) (Metallerzeugung)',
    Occupation: 'Equipment Operator (Metal Production)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549999,
    OccDE: 'Anlagenbetreuer/in',
    Occupation: 'Equipment Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 274002,
    OccDE: 'Betriebsschlosser/in',
    Occupation: 'Equipment Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621405,
    OccDE: 'Gerätetechniker/in',
    Occupation: 'Equipment Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 282101,
    OccDE: 'Betriebsschlosser/in (Landtechnik)',
    Occupation: 'Equipment Technician (Agricultural Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622301,
    OccDE: 'Anlagentechniker/in (EDV-Wartungstechniker/in)',
    Occupation: 'Equipment Technician (IT Maintenance Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 691301,
    OccDE: 'Aktienanalyst/in',
    Occupation: 'Equities Analyst',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 859404,
    OccDE: 'Ergotherapeut/in',
    Occupation: 'Ergotherapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 859405,
    OccDE: 'Ergotherapist/in',
    Occupation: 'Ergotherapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '031112',
    OccDE: 'Gutsverwalter/in',
    Occupation: 'Estate Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882509,
    OccDE: 'Ethnolog(e/in)',
    Occupation: 'Ethnologist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997199,
    OccDE: 'EU-Rentner',
    Occupation: 'EU Pensioner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 894101,
    OccDE: 'Bestattungsredner/in',
    Occupation: 'Eulogist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785199,
    OccDE: 'Eurokaufmann/-kauffrau',
    Occupation: 'European Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751806,
    OccDE: 'Europa-Direktionsassistent/in',
    Occupation: 'European Management Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789401,
    OccDE: 'Europa-Sekretär/in',
    Occupation: 'European Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789402,
    OccDE: 'Eurosekretär/in',
    Occupation: 'European Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 859502,
    OccDE: 'Heileurythmist/in',
    Occupation: 'Eurythmy Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911810,
    OccDE: 'Veranstaltungscaterer',
    Occupation: 'Event Caterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788203,
    OccDE: 'Terminkoordinator/in',
    Occupation: 'Event Coordinator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703199,
    OccDE: 'Veranstaltungskaufmann/-kauffrau',
    Occupation: 'Event Management Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 703198,
    OccDE: 'Event-Manager/in',
    Occupation: 'Event Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 750311,
    OccDE: 'Veranstaltungsmanager',
    Occupation: 'Event Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 914917,
    OccDE: 'Veranstaltungsleiter/in (Gastronomie)',
    Occupation: 'Event Manager (Catering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750312,
    OccDE: 'Veranstaltungsorganisator/in',
    Occupation: 'Event Organiser',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 788204,
    OccDE: 'Terminplaner/in',
    Occupation: 'Event Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835298,
    OccDE: 'Veranstaltungstechniker/in',
    Occupation: 'Event Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 545202,
    OccDE: 'Baggerfahrer/in',
    Occupation: 'Excavator Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 545203,
    OccDE: 'Baggerführer/in',
    Occupation: 'Excavator Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789101,
    OccDE: 'Chefassistent/in',
    Occupation: 'Executive Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751807,
    OccDE: 'Geschäftsführerassistent/in',
    Occupation: 'Executive Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764020,
    OccDE: 'Geschäftsführendes Vorstandsmitglied (Verwaltung)',
    Occupation: 'Executive Board Member (Administration)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 411031,
    OccDE: 'Küchendirektor/in',
    Occupation: 'Executive Chef',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750209,
    OccDE: 'Geschäftsführende(r) Gesellschafter/in',
    Occupation: 'Executive Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714204,
    OccDE: 'Cheffahrer/in',
    Occupation: 'Executive Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750297,
    OccDE: 'Leitende(r) Geschäftsführer/in',
    Occupation: 'Executive Managing Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765602,
    OccDE: 'Betriebsinspektor/in (Verwaltung, gehobener Dienst)',
    Occupation:
      'Executive Officer (Administrative Department, higher civil service)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 789102,
    OccDE: 'Chefsekretär/in',
    Occupation: 'Executive Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 872802,
    OccDE: 'Oberstudiendirektor/in an Gymnasien',
    Occupation: 'Executive Tenured Secondary School Teacher (Gymnasium)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 480102,
    OccDE: 'Ausbaumonteur/in',
    Occupation: 'Expansion Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441902,
    OccDE: 'Ausbaumaurer',
    Occupation: 'Expansion Mason',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482405,
    OccDE: 'Ausbaufacharbeiter/in (Trockenbauarbeiten)',
    Occupation: 'Expansion Specialist (Drywall)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511001,
    OccDE: 'Ausbaufacharbeiter/in (Malerarbeiten)',
    Occupation: 'Expansion Specialist (Painting Work)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 483001,
    OccDE: 'Ausbaufacharbeiter/in (Fliesen-, Platten- und Mosaiklegearbeiten)',
    Occupation: 'Expansion Specialist (Tiles and Mosaics Layer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997115,
    OccDE: 'Sachverständige/r',
    Occupation: 'Expert',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695113,
    OccDE: 'Referent/in (Versicherungswesen)',
    Occupation: 'Expert (Insurance)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 608237,
    OccDE: 'Sachverständige/r für das Kraftfahr(zeug)wesen',
    Occupation: 'Expert in Automobiles',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803904,
    OccDE: 'Kampfmittelräumer/in',
    Occupation: 'Explosives Disposal Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 460510,
    OccDE: 'Sprengtechniker',
    Occupation: 'Explosives technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671302,
    OccDE: 'Außenhandelskaufmann/-kauffrau',
    Occupation: 'Export Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 671307,
    OccDE: 'Exportkaufmann/-kauffrau',
    Occupation: 'Export Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 671308,
    OccDE: 'Exportleiter/in',
    Occupation: 'Export Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 671309,
    OccDE: 'Exportmanager',
    Occupation: 'Export Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 671310,
    OccDE: 'Exportsachbearbeiter/in',
    Occupation: 'Export Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671311,
    OccDE: 'Fachkaufmann/-kauffrau für Außenwirtschaft',
    Occupation: 'Export Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 805105,
    OccDE: 'Kammerjäger/in',
    Occupation: 'Exterminator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 753502,
    OccDE: 'Außenprüfer/in (Zoll, Steuer)',
    Occupation: 'External Auditor (Customs, Tax)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 605106,
    OccDE: 'Förderingenieur/in (Erdöl-, Erdgasgewinnung)',
    Occupation: 'Extraction Engineer (Petroleum, Natural Gas)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152201,
    OccDE: 'Extruderführer/in',
    Occupation: 'Extruder Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 153701,
    OccDE: 'Konfektionsarbeiter/in (Kunststoffverarbeitung)',
    Occupation: 'Fabrication Worker (Plastics Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441204,
    OccDE: 'Fassadenbauer',
    Occupation: 'Façade Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934204,
    OccDE: 'Fassadenreiniger/in',
    Occupation: 'Façade Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441206,
    OccDE: 'Fassadenmonteur',
    Occupation: 'Façade Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482504,
    OccDE: 'Fassadenisolierer/in',
    Occupation: 'Façade Insulator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481305,
    OccDE: 'Fassadenputzer/in (Verputzer/in)',
    Occupation: 'Façade Plasterer (Plasterer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934003,
    OccDE: 'Gebäudereiniger/in',
    Occupation: 'Facilities Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934333,
    OccDE: 'Raumreiniger/in',
    Occupation: 'Facilities Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051101',
    OccDE: 'Anlagengärtner/in',
    Occupation: 'Facilities Gardener',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792305,
    OccDE: 'Betriebswächter/in',
    Occupation: 'Facilities Guard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051102',
    OccDE: 'Anlagenleiter/in (Garten-, Landschafts- und Sportplatzbau)',
    Occupation:
      'Facilities Manager (Gardening, Landscaping and Sports Facilities)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934332,
    OccDE: 'Raumpfleger/in',
    Occupation: 'Facilities Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689022,
    OccDE: 'Werkvertreter/in',
    Occupation: 'Factory Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 687204,
    OccDE: 'Werksbeauftragte/r',
    Occupation: 'Factory Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531201,
    OccDE: 'Fabrikarbeiter/in',
    Occupation: 'Factory Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531205,
    OccDE: 'Werkarbeiter/in',
    Occupation: 'Factory Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861117,
    OccDE: 'Familienhelfer/in',
    Occupation: 'Family Aid Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 865005,
    OccDE: 'Familienpfleger/in',
    Occupation: 'Family Care Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861114,
    OccDE: 'Familienberater/in',
    Occupation: 'Family Counsellor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861118,
    OccDE: 'Familientherapeut/in',
    Occupation: 'Family Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023004',
    OccDE: 'Großtierzüchter/in',
    Occupation: 'Farm Animal Breeder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 282701,
    OccDE: 'Landmaschinenmechanikerhelfer/in',
    Occupation: 'Farm Equipment Mechanic Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011034',
    OccDE: 'Hofbesitzer/in',
    Occupation: 'Farm Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011012',
    OccDE: 'Bauer/Bäuerin',
    Occupation: 'Farmer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '032309',
    OccDE: 'Techniker/in für Landbau',
    Occupation: 'Farming Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '032306',
    OccDE: 'Landbautechniker/in',
    Occupation: 'Farming Technicians',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 256302,
    OccDE: 'Hufbeschlagschmied/in',
    Occupation: 'Farrier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 256304,
    OccDE: 'Hufschmied/in',
    Occupation: 'Farrier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662205,
    OccDE: 'Modeberater/in',
    Occupation: 'Fashion Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834211,
    OccDE: 'Modedesigner/in',
    Occupation: 'Fashion Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834213,
    OccDE: 'Modegraphiker/in (Künstler/in)',
    Occupation: 'Fashion Graphic Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661605,
    OccDE: 'Schnellimbißverkäufer/in',
    Occupation: 'Fast food Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821528,
    OccDE: 'Wort- und Bildjournalist/in',
    Occupation: 'Feature and Photojournalist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801490,
    OccDE: 'Bundespolizist/in',
    Occupation: 'Federal Police Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 811201,
    OccDE: 'Bundesanwalt/Bundesanwältin',
    Occupation: 'Federal Prosecutor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 712610,
    OccDE: 'Bundesbahnaufsichtsbeamt(er/in)',
    Occupation: 'Federal Rail Supervisory Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787506,
    OccDE: 'Bundesbahnbeamt(er/in)',
    Occupation: 'Federal Railroad Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787504,
    OccDE: 'Bundesbahnangestellte/r',
    Occupation: 'Federal Railroad Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787510,
    OccDE: 'Bundesbahnobersekretär/in',
    Occupation: 'Federal Railroad Higher Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 435105,
    OccDE: 'Futtermittelmüller/in',
    Occupation: 'Feed Miller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 724605,
    OccDE: 'Fährmann/Fährfrau',
    Occupation: 'Ferryman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 724607,
    OccDE: 'Fährschiffer/in',
    Occupation: 'Ferryman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201820,
    OccDE: 'Gußputzer',
    Occupation: 'Fettler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703403,
    OccDE: 'FFF-Producer',
    Occupation: 'FFF Producer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 764013,
    OccDE: 'Außenstellenleiter/in',
    Occupation: 'Field Office Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 689002,
    OccDE: 'Außendienstmitarbeiter/in',
    Occupation: 'Field Sales Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689910,
    OccDE: 'Außendienstverkäufer',
    Occupation: 'Field Sales Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695407,
    OccDE: 'Außendienstmitarbeiter/in (Versicherungwesen)',
    Occupation: 'Field Sales Agent (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689501,
    OccDE: 'Außendienstberater/in',
    Occupation: 'Field Sales Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683901,
    OccDE: 'Außendienstmitarbeiter/in (Verlag)',
    Occupation: 'Field Sales Employee (Publishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751302,
    OccDE: 'Außendienstleiter/in',
    Occupation: 'Field Sales Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689017,
    OccDE: 'Reiseleiter/in (Außendienst)',
    Occupation: 'Field Sales Manager (Field Sales)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695201,
    OccDE: 'Außendienstleiter/in (Versicherungswesen)',
    Occupation: 'Field Sales Manager (Insurance)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 689502,
    OccDE: 'Außendienstfachberater/in',
    Occupation: 'Field Sales Specialist Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689001,
    OccDE: 'Außendienstangestellte/r',
    Occupation: 'Field Sales Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620903,
    OccDE: 'Außendiensttechniker/in',
    Occupation: 'Field Sales Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 269001,
    OccDE: 'Außendienstmonteur/in',
    Occupation: 'Field Service Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522301,
    OccDE: 'Abfüllmaschinenbediener/in',
    Occupation: 'Filling Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835214,
    OccDE: 'Film- und Fernsehproduktionsleiter/in',
    Occupation: 'Film and Television Production Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835215,
    OccDE: 'Film- und Videoproduzent/in',
    Occupation: 'Film and Video Production Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705112,
    OccDE: 'Filmkaufmann/-kauffrau',
    Occupation: 'Film Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 832206,
    OccDE: 'Filmregisseur/in',
    Occupation: 'Film Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835607,
    OccDE: 'Filmcutter/in',
    Occupation: 'Film Editor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835513,
    OccDE: 'Filmbeleuchter/in',
    Occupation: 'Film Lighting Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832205,
    OccDE: 'Filmemacher/in',
    Occupation: 'Film Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835216,
    OccDE: 'Filmproduzent/in',
    Occupation: 'Film Producer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521307,
    OccDE: 'Endkontrolleur/in',
    Occupation: 'Final Checker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521309,
    OccDE: 'Endproduktprüfer/in',
    Occupation: 'Final Product Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751201,
    OccDE: 'Finanzdirektor/in (nicht Finanzverwaltung)',
    Occupation: 'Finance Director (not Financial Management)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 772402,
    OccDE: 'Finanzbuchhalter/in',
    Occupation: 'Financial Accountant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 691201,
    OccDE: 'Finanzierungsberater/in',
    Occupation: 'Financial Adviser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 757201,
    OccDE: 'Finanzberater/in',
    Occupation: 'Financial Advisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691016,
    OccDE: 'Finanzkaufmann/-kauffrau (Geld und Kredit)',
    Occupation: 'Financial Advisor (Investment and Lending)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771110,
    OccDE: 'Finanzanalytiker/in',
    Occupation: 'Financial Analyst',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704104,
    OccDE: 'Finanzmakler/in',
    Occupation: 'Financial Broker',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 788914,
    OccDE: 'Finanzkaufmann/-kauffrau (Wirtschaft)',
    Occupation: 'Financial Clerk (Business)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 757202,
    OccDE: 'Finanzkaufmann/-kauffrau (Finanz-, Wirtschaftsberatung)',
    Occupation: 'Financial Clerk (Financial, Business Consulting)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771107,
    OccDE: 'Financial Controller',
    Occupation: 'Financial Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771109,
    OccDE: 'Finanz-Controller/in',
    Occupation: 'Financial Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764203,
    OccDE: 'Finanzdirektor/in (Finanzverwaltung)',
    Occupation: 'Financial Director (Financial Administration)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 765202,
    OccDE: 'Finanzangestellte/r (gehobener Dienst)',
    Occupation: 'Financial Employee (higher civil service)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 765204,
    OccDE: 'Finanzinspektor/in',
    Occupation: 'Financial Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 765201,
    OccDE: 'Diplom-Finanzwirt/in',
    Occupation: 'Financial Manager with Degree',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 765203,
    OccDE: 'Finanzbeamt(er/in) (gehobener Dienst)',
    Occupation: 'Financial Officer (higher civil service)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 771199,
    OccDE: 'Financial Planing Consultant',
    Occupation: 'Financial Planning Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764202,
    OccDE: 'Finanzassessor/in',
    Occupation: 'Financial Referendary',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 757203,
    OccDE: 'Finanzdienstleister/in',
    Occupation: 'Financial Services Provider',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 757299,
    OccDE: 'Finanzfach(mann/frau)',
    Occupation: 'Financial Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695002,
    OccDE: 'Finanzkaufmann/-kauffrau (Versicherungen)',
    Occupation: 'Financial Specialist (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661002,
    OccDE: 'Genußmittelverkäufer/in',
    Occupation: 'Fine Food Retailer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 231103,
    OccDE: 'Feinpolierer/in',
    Occupation: 'Fine Polisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902203,
    OccDE: 'Fingernagelmodellist/in',
    Occupation: 'Fingernail Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902202,
    OccDE: 'Fingernagelkosmetiker/in',
    Occupation: 'Fingernail Cosmetician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902201,
    OccDE: 'Fingernagel-Stylist/in',
    Occupation: 'Fingernail Stylist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802205,
    OccDE: 'Brandoberinspektor/in',
    Occupation: 'Fire Chief Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802514,
    OccDE: 'Feuerlöscherprüfer/in',
    Occupation: 'Fire Extinguisher Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802515,
    OccDE: 'Feuerlöschersachkundige/r',
    Occupation: 'Fire Extinguisher Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802203,
    OccDE: 'Brandinspektor/in',
    Occupation: 'Fire Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 484215,
    OccDE: 'Kaminbauer/in',
    Occupation: 'Fire Place Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 484208,
    OccDE: 'Kaminbauer/in (offene Zimmerkamine)',
    Occupation: 'Fire Place Maker (open fire place)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482903,
    OccDE: 'Brandschutzfachmonteur/in',
    Occupation: 'Fire Protection Specialist Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802506,
    OccDE: 'Brandschutzberater/in',
    Occupation: 'Fire Safety Consultants',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802508,
    OccDE: 'Brandschutzingenieur/in',
    Occupation: 'Fire Safety Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802511,
    OccDE: 'Brandschutzprüfer/in',
    Occupation: 'Fire Safety Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802505,
    OccDE: 'Brandschutzbeauftragte/r',
    Occupation: 'Fire Safety Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482902,
    OccDE: 'Brandschutzfachkraft',
    Occupation: 'Fire Safety Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802512,
    OccDE: 'Brandschutztechniker/in',
    Occupation: 'Fire Safety Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802507,
    OccDE: 'Brandschutzfacharbeiter/in',
    Occupation: 'Fire Safety Technicians',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802106,
    OccDE: 'Feuerwehroffizier/in',
    Occupation: 'Fire Service Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802004,
    OccDE: 'Feuerwehrbeamt(er/in)',
    Occupation: 'Fire Service Official',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741609,
    OccDE: 'Feuerwehrgeräteverwalter/in',
    Occupation: 'Fire Services Equipment Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802405,
    OccDE: 'Werkfeuerwehrmann/-feuerwehrfrau',
    Occupation: 'Firefighter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802306,
    OccDE: 'Feuerwehrmann/Feuerwehrfrau',
    Occupation: 'Firefighter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802001,
    OccDE: 'Anwärter/in der Feuerwehr',
    Occupation: 'Firefighter Candidate',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441422,
    OccDE: 'Kaminbauer (Schornsteinbau)',
    Occupation: 'Fireplace Builder (Chimney Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441416,
    OccDE: 'Feuerungsmaurer',
    Occupation: 'Firing Mason',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441414,
    OccDE: 'Feuerungsbauer',
    Occupation: 'Firing Oven Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721007,
    OccDE: 'Wirtschaftsingenieur/in (Seeverkehr)',
    Occupation: 'First Engineer (Maritime Transport)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: '023648',
    OccDE: 'Fischzüchter/in',
    Occupation: 'Fish Breeder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023645',
    OccDE: 'Fischzuchtgehilf(e/in)',
    Occupation: 'Fish Breeder Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023682',
    OccDE: 'Teichwirt/in',
    Occupation: 'Fish Farmer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 435221,
    OccDE: 'Fischverarbeiter/in',
    Occupation: 'Fish Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 435209,
    OccDE: 'Fischarbeiter/in',
    Occupation: 'Fish Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023619',
    OccDE: 'Fischer/in',
    Occupation: 'Fisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023631',
    OccDE: 'Fischereiverwalter/in',
    Occupation: 'Fishery Administrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023632',
    OccDE: 'Fischereiwart/in',
    Occupation: 'Fishery Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023641',
    OccDE: 'Fischwirt/in',
    Occupation: 'Fishery Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023625',
    OccDE: 'Fischereileiter/in',
    Occupation: 'Fishery Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023701',
    OccDE: 'Fischereiarbeiter/in (Fischerhelfer/in)',
    Occupation: 'Fishery Worker (Fisher Assistant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876907,
    OccDE: 'Fitneßcenterleiter/in',
    Occupation: 'Fitness Centre Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876909,
    OccDE: 'Fitneßtrainer/in',
    Occupation: 'Fitness Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780497,
    OccDE: 'Fitness-Fachwirt/in',
    Occupation: 'Fitness Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250019,
    OccDE: 'Montageschlosser/in',
    Occupation: 'Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269018,
    OccDE: 'Monteur/in',
    Occupation: 'Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482414,
    OccDE: 'Verleger/in (Akustik- und Trockenbaumonteur/in)',
    Occupation: 'Fitter (Acoustic and Drywall Installer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505205,
    OccDE: 'Monteur/in (Bauzubehörindustrie)',
    Occupation: 'Fitter (Construction Supply Industry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310037,
    OccDE: 'Installateur/in (Elektroinstallation)',
    Occupation: 'Fitter (Electrical Installation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482304,
    OccDE: 'Monteur/in (Wärme-, Kälteisolierung)',
    Occupation: 'Fitter (Heating Cooling Insulation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250020,
    OccDE: 'Monteur/in (Metallbau)',
    Occupation: 'Fitter (Metal Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264104,
    OccDE: 'Installateur/in (Rohrinstallation)',
    Occupation: 'Fitter (Pipe Installation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264909,
    OccDE: 'Vorrichter/in (Rohrnetz-, Rohrleitungsbau)',
    Occupation: 'Fitter (Pipe Network, Pipeline Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264007,
    OccDE: 'Monteur/in (Rohrnetzbau, Rohrinstallation)',
    Occupation: 'Fitter (Pipe Networks and Installation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491316,
    OccDE: 'Verleger/in von Fußbodenbelägen',
    Occupation: 'Fitter of Flooring',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487420,
    OccDE: 'Montierer von Holzfertigteilen',
    Occupation: 'Fitter of Prefabricated Timber Component',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 278207,
    OccDE: 'Montierer/in (Geräte, Maschinen)',
    Occupation: 'Fitting Technician (Equipment, Machinery)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662302,
    OccDE: 'Einrichtungsfachmann/-fachfrau (Verkaufsberater/in)',
    Occupation: 'Fittings Specialist (Sales Consultant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273903,
    OccDE: 'Armaturenschlosser/in',
    Occupation: 'Fittings Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 131503,
    OccDE: 'Flachglasmacher/in',
    Occupation: 'Flat Glass Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135004,
    OccDE: 'Flachglasmechaniker/in',
    Occupation: 'Flat Glass Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135005,
    OccDE: 'Flachglasveredler/in',
    Occupation: 'Flat Glass Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174213,
    OccDE: 'Flachdrucker/in',
    Occupation: 'Flat Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831412,
    OccDE: 'Flötist/in',
    Occupation: 'Flautist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713210,
    OccDE: 'Fuhrparkverwalter/in',
    Occupation: 'Fleet Administrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713206,
    OccDE: 'Fuhrparkdisponent/in',
    Occupation: 'Fleet Dispatcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713207,
    OccDE: 'Fuhrparkleiter/in',
    Occupation: 'Fleet Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174112,
    OccDE: 'Flexodrucker/in',
    Occupation: 'Flexographic Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912506,
    OccDE: 'Flugbegleiter/in',
    Occupation: 'Flight Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726106,
    OccDE: 'Flugkapitän/in',
    Occupation: 'Flight Captain',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726510,
    OccDE: 'Flugdisponent/in',
    Occupation: 'Flight Coordinator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726403,
    OccDE: 'Flugdatenbearbeiter/in',
    Occupation: 'Flight Data Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879102,
    OccDE: 'Fluglehrer/in',
    Occupation: 'Flight Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726508,
    OccDE: 'Flugdienstberater/in',
    Occupation: 'Flight Operation Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601301,
    OccDE: 'Flugerprobungsingenieur/in',
    Occupation: 'Flight Test Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491301,
    OccDE: 'Bodenbelagleger/in',
    Occupation: 'Floor Covering Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491303,
    OccDE: 'Fußbodenbelagsverleger/in',
    Occupation: 'Floor Covering Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491304,
    OccDE: 'Fußbodenbeleger/in',
    Occupation: 'Floor Covering Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461502,
    OccDE: 'Bodenleger (Steine, Pflaster)',
    Occupation: 'Floor Layer (Stone, Pavement)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491302,
    OccDE: 'Bodenleger/in (Fußboden)',
    Occupation: 'Flooring Fitter (Floor)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 486099,
    OccDE: 'Fußbodenleger/in',
    Occupation: 'Flooring Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491305,
    OccDE: 'Fußbodenleger/in (Kunststoff)',
    Occupation: 'Flooring Installer (Synthetics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051318',
    OccDE: 'Zierpflanzengärtner/in',
    Occupation: 'Floriculturist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '053004',
    OccDE: 'Blumenbinder/in',
    Occupation: 'Florist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '053006',
    OccDE: 'Florist/in',
    Occupation: 'Florist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '053102',
    OccDE: 'Blumenhändler/in',
    Occupation: 'Florist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '053101',
    OccDE: 'Blumengeschäftsinhaber/in',
    Occupation: 'Flower Shop Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '053702',
    OccDE: 'Blumenverkäufer/in',
    Occupation: 'Flower Vendor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152605,
    OccDE: 'Schäumer/in (Kunststoff-Formgeber/in)',
    Occupation: 'Foam Material Engineer (Plastics Moulder)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 153908,
    OccDE: 'Schaumstoffverarbeiter/in',
    Occupation: 'Foam Material Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914602,
    OccDE: 'Food and Beverage Assistant Manager',
    Occupation: 'Food and Beverage Assistant Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914605,
    OccDE: 'Food and Beverage Manager',
    Occupation: 'Food and Beverage Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 611202,
    OccDE: 'Lebensmittelchemiker/in',
    Occupation: 'Food Chemist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 633805,
    OccDE: 'Lebensmittelchemielaborant/in',
    Occupation: 'Food Chemistry Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981138,
    OccDE: 'Student/in Lebensmittelchemie, Lebensmitteltechnologie',
    Occupation: 'Food Chemistry, Food Technology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673103,
    OccDE: 'Lebensmittelkaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Food Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671243,
    OccDE: 'Lebensmittelkaufmann/-kauffrau (Großhandel)',
    Occupation: 'Food Clerk (Wholesale)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673102,
    OccDE: 'Lebensmittelhändler/in (Einzelhandel)',
    Occupation: 'Food Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671242,
    OccDE: 'Lebensmittelhändler/in (Großhandel)',
    Occupation: 'Food Dealer (Wholesale)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714408,
    OccDE: 'Essenausfahrer/in',
    Occupation: 'Food Deliver Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606120,
    OccDE: 'Lebensmittelingenieur/in',
    Occupation: 'Food Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606123,
    OccDE: 'Nahrungsmittelingenieur/in',
    Occupation: 'Food Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 803204,
    OccDE: 'Lebensmittelkontrolleur/in',
    Occupation: 'Food Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633806,
    OccDE: 'Lebensmittellaborant/in',
    Occupation: 'Food Lab Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 435004,
    OccDE: 'Lebensmittelverarbeiter/in',
    Occupation: 'Food Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627107,
    OccDE: 'Lebensmittelproduktionstechniker/in',
    Occupation: 'Food Production Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661003,
    OccDE: 'Nahrungsmittelverkäufer/in',
    Occupation: 'Food Retailer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803205,
    OccDE: 'Lebensmittelüberwachungsbeamt(er/in)',
    Occupation: 'Food Safety Supervisory Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883806,
    OccDE: 'Tropholog(e/in)',
    Occupation: 'Food Scientist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 914099,
    OccDE: 'Fachmann/Fachfrau für                Systemgastronomie',
    Occupation: 'Food Service Equipment Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 523109,
    OccDE: 'Lebensmittelsortierer/in',
    Occupation: 'Food Sorter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661698,
    OccDE: 'Imbißbudenpächter/in',
    Occupation: 'Food Stand Lease Holder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661697,
    OccDE: 'Imbißbesitzer/in',
    Occupation: 'Food Stand Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661699,
    OccDE: 'Imbißbudenbesitzer/in',
    Occupation: 'Food Stand Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661696,
    OccDE: 'Imbißbudenverkäufer/in',
    Occupation: 'Food Stand Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606121,
    OccDE: 'Lebensmitteltechnolog(e/in)',
    Occupation: 'Food Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 627108,
    OccDE: 'Lebensmitteltechniker/in',
    Occupation: 'Food Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 435003,
    OccDE: 'Fachkraft für Lebensmitteltechnik',
    Occupation: 'Food Technology Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 435002,
    OccDE: 'Facharbeiter/in für Lebensmitteltechnik',
    Occupation: 'Food Technology Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876102,
    OccDE: 'Fußballtrainer/in',
    Occupation: 'Football Coach',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 838301,
    OccDE: 'Fußballspieler/in',
    Occupation: 'Football Player',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 838399,
    OccDE: 'Fußballer/in',
    Occupation: 'Football Player',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788401,
    OccDE: 'Auslandskorrespondent/in (Fremdsprachenkorrespondent/in)',
    Occupation: 'Foreign Correspondent (Foreign Language Correspondent)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788403,
    OccDE: 'Fremdsprachenassistent/in',
    Occupation: 'Foreign Language Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789403,
    OccDE: 'Fremdsprachenassistent/in (Fremdsprachensekretär/in)',
    Occupation: 'Foreign Language Assistant (Foreign Language Secretary)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788404,
    OccDE: 'Fremdsprachenkaufmann/-kauffrau',
    Occupation: 'Foreign Language Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788405,
    OccDE: 'Fremdsprachenkorrespondent/in',
    Occupation: 'Foreign Language Correspondent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 782402,
    OccDE: 'Fremdsprachenkorrespondent/in (Schreibkraft)',
    Occupation: 'Foreign Language Correspondent (Typist)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 171505,
    OccDE: 'Fremdsprachenkorrektor/in',
    Occupation: 'Foreign language proofreader',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789404,
    OccDE: 'Fremdsprachensekretär/in',
    Occupation: 'Foreign Language Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764110,
    OccDE: 'Legations(rat/rätin)',
    Occupation: 'Foreign Service Officer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 625003,
    OccDE: 'Steiger/in',
    Occupation: 'Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629401,
    OccDE: 'Werkführer/in',
    Occupation: 'Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651005,
    OccDE: 'Betriebsmeister/in',
    Occupation: 'Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651036,
    OccDE: 'Werkmeister/in',
    Occupation: 'Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250021,
    OccDE: 'Richtmeister/in',
    Occupation: 'Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440313,
    OccDE: 'Polier',
    Occupation: 'Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440315,
    OccDE: 'Werkpolier',
    Occupation: 'Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 993108,
    OccDE: 'Vorarbeiter/in',
    Occupation: 'Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 460306,
    OccDE: 'Schachtmeister',
    Occupation: 'Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713501,
    OccDE: 'Betriebsmeister/in (Kfz)',
    Occupation: 'Foreman (Automotive)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603102,
    OccDE: 'Bauleiter/in (Bauingenieur/in)',
    Occupation: 'Foreman (Civil Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 623103,
    OccDE: 'Bauführer/in (Bautechniker/in)',
    Occupation: 'Foreman (Construction Engineer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623105,
    OccDE: 'Bauleiter/in (Bautechniker/in)',
    Occupation: 'Foreman (Construction Engineer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778121,
    OccDE: 'Schichtleiter/in (EDV)',
    Occupation: 'Foreman (EDP)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778120,
    OccDE: 'Schichtführer/in (EDV)',
    Occupation: 'Foreman (EDP)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622201,
    OccDE: 'Bauführer/in (Fernmeldetechniker/in)',
    Occupation: 'Foreman (Telecommunications Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 312201,
    OccDE: 'Bauführer/in (Fernmeldehandwerker/in)',
    Occupation: 'Foreman (Telecommunications Technician)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841970,
    OccDE: 'Rechtsmediziner/in',
    Occupation: 'Forensic Pathologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 629904,
    OccDE: 'Kriminaltechniker/in',
    Occupation: 'Forensic Scientist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062312',
    OccDE: 'Waldkulturarbeiter/in',
    Occupation: 'Forest and Conservation Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061302',
    OccDE: 'Förster/in',
    Occupation: 'Forest Ranger',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061321',
    OccDE: 'Revierförster/in',
    Occupation: 'Forest Ranger',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061422',
    OccDE: 'Forstwart/in',
    Occupation: 'Forester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061103',
    OccDE: 'Diplom-Forstwirt/in',
    Occupation: 'Forester with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061421',
    OccDE: 'Forstverwalter/in',
    Occupation: 'Forestry Administrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061415',
    OccDE: 'Forstlehrling',
    Occupation: 'Forestry Apprentice',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061407',
    OccDE: 'Forstbeamt(er/in)',
    Occupation: 'Forestry Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061404',
    OccDE: 'Forstanwärter/in',
    Occupation: 'Forestry Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062106',
    OccDE: 'Forstwirtschaftliche(r) Lohnunternehmer/in',
    Occupation: 'Forestry Contractor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061305',
    OccDE: 'Forstamtsleiter/in',
    Occupation: 'Forestry Department Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061303',
    OccDE: 'Forst(amtmann/amtfrau)',
    Occupation: 'Forestry Deputy Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061104',
    OccDE: 'Forst(rat/rätin)',
    Occupation: 'Forestry Director General',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061202',
    OccDE: 'Forstingenieur/in',
    Occupation: 'Forestry Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061203',
    OccDE: 'Forstwirtschaftsingenieur/in',
    Occupation: 'Forestry Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061201',
    OccDE: 'Diplom-Forstingenieur/in',
    Occupation: 'Forestry Engineer with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062104',
    OccDE: 'Forstmaschinenführer/in',
    Occupation: 'Forestry Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061306',
    OccDE: 'Forstinspektor/in',
    Occupation: 'Forestry Higher Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061106',
    OccDE: 'Forstdirektor/in',
    Occupation: 'Forestry Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061105',
    OccDE: 'Forstassessor/in',
    Occupation: 'Forestry Referendary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061110',
    OccDE: 'Forstreferendar/in',
    Occupation: 'Forestry Referendary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981120,
    OccDE: 'Student/in Forstwissenschaften, Forstwirtschaft',
    Occupation: 'Forestry Sciences, Forestry Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883208,
    OccDE: 'Forstwissenschaftler/in',
    Occupation: 'Forestry Scientist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '061308',
    OccDE: 'Forstoberinspektor/in',
    Occupation: 'Forestry Senior Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062102',
    OccDE: 'Forstfacharbeiter/in',
    Occupation: 'Forestry Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062105',
    OccDE: 'Forstwirt/in',
    Occupation: 'Forestry Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061420',
    OccDE: 'Forsttechniker/in',
    Occupation: 'Forestry Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061403',
    OccDE: 'Forstangestellte/r',
    Occupation: 'Forestry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062114',
    OccDE: 'Waldarbeiter/in',
    Occupation: 'Forestry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062303',
    OccDE: 'Forstarbeiter/in',
    Occupation: 'Forestry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704204,
    OccDE: 'Devisenmakler/in',
    Occupation: 'Forex Brokers',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 691102,
    OccDE: 'Devisenhändler/in',
    Occupation: 'Forex Trader',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 742107,
    OccDE: 'Gabelstaplerfahrer/in',
    Occupation: 'Forklift Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 742134,
    OccDE: 'Staplerfahrer/in',
    Occupation: 'Forklift Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 742113,
    OccDE: 'Hubstaplerfahrer/in',
    Occupation: 'Forklift Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273937,
    OccDE: 'Staplermonteur/in',
    Occupation: 'Forklift Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442205,
    OccDE: 'Einschaler',
    Occupation: 'Formwork Setter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442299,
    OccDE: 'Schalungsbauer',
    Occupation: 'Formworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487207,
    OccDE: 'Schalungszimmerer',
    Occupation: 'Formworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701204,
    OccDE: 'Spediteur/in',
    Occupation: 'Forwarder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744468,
    OccDE: 'Verlader/in',
    Occupation: 'Forwarder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701104,
    OccDE: 'Speditionskaufmann/-kauffrau',
    Occupation: 'Forwarding Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714421,
    OccDE: 'Speditionsfahrer/in',
    Occupation: 'Forwarding Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701103,
    OccDE: 'Speditionsangestellte/r',
    Occupation: 'Forwarding Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744580,
    OccDE: 'Speditionsarbeiter/in',
    Occupation: 'Forwarding Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121419,
    OccDE: 'Gießer/in (Keramik)',
    Occupation: 'Founder (Ceramics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201711,
    OccDE: 'Gießerhelfer',
    Occupation: 'Foundry Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 605601,
    OccDE: 'Gießereiingenieur/in',
    Occupation: 'Foundry Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 625601,
    OccDE: 'Gießereileiter/in (Gießereitechniker/in)',
    Occupation: 'Foundry Manager (Foundry Technician)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201013,
    OccDE: 'Gießereimechaniker',
    Occupation: 'Foundry Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201102,
    OccDE: 'Gießereimechaniker (Fachrichtung Handformguß)',
    Occupation: 'Foundry Mechanic (specialising in Hand-made Castings)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201205,
    OccDE: 'Gießereimechaniker (Fachrichtung Maschinenformguß)',
    Occupation: 'Foundry Mechanic (specialising in Machine-made Castings)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 502501,
    OccDE: 'Gießereimodellbauer/in',
    Occupation: 'Foundry Model Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 625602,
    OccDE: 'Gießereitechniker/in',
    Occupation: 'Foundry Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201011,
    OccDE: 'Gießereifacharbeiter',
    Occupation: 'Foundry Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201001,
    OccDE: 'Facharbeiter für Gießereitechnik',
    Occupation: 'Foundry Technology Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201010,
    OccDE: 'Gießereiarbeiter',
    Occupation: 'Foundry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201014,
    OccDE: 'Gießereiwerker',
    Occupation: 'Foundry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201620,
    OccDE: 'Metallgießer',
    Occupation: 'Foundry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201008,
    OccDE: 'Gießer (Formgießerei)',
    Occupation: 'Foundry Worker (Foundry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 485307,
    OccDE: 'Rahmenglaser/in',
    Occupation: 'Frame Glazier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703204,
    OccDE: 'Franchise-Fachkraft',
    Occupation: 'Franchise Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 687108,
    OccDE: 'freier Handelsvertreter',
    Occupation: 'Freelance Commercial Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 995102,
    OccDE: 'Freiberufliche(r) Mitarbeiter/in',
    Occupation: 'Freelance contractor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 833301,
    OccDE: 'Freie(r) Grafiker/in',
    Occupation: 'Freelance Graphic Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 995101,
    OccDE: 'Freiberufler/in',
    Occupation: 'Freelancer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701015,
    OccDE: 'Frachtsachbearbeiter/in',
    Occupation: 'Freight Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701016,
    OccDE: 'Frachtwegdisponent/in',
    Occupation: 'Freight Dispatcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714411,
    OccDE: 'Frachtfahrer/in',
    Occupation: 'Freight Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701006,
    OccDE: 'Frachtabfertiger/in',
    Occupation: 'Freight Handler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 663004,
    OccDE: 'Frischdienstverkäufer/in',
    Occupation: 'Fresh Food Delivery Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914515,
    OccDE: 'Front Office Manager',
    Occupation: 'Front Office Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 663006,
    OccDE: 'Tiefkühldienst-Fahrverkäufer/in',
    Occupation: 'Frozen Food Delivery Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673105,
    OccDE: 'Obst- und Gemüsehändler/in (Einzelhandel)',
    Occupation: 'Fruit and Vegetable Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671252,
    OccDE: 'Obst- und Gemüsehändler/in (Großhandel)',
    Occupation: 'Fruit and Vegetable Dealer (Wholesale)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673106,
    OccDE: 'Obsthändler/in (Einzelhandel)',
    Occupation: 'Fruit Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671253,
    OccDE: 'Obsthändler/in (Großhandel)',
    Occupation: 'Fruit Dealer (Wholesale)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051609',
    OccDE: 'Obstgärtner/in',
    Occupation: 'Fruit Gardener',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 423401,
    OccDE: 'Fachkraft für Fruchtsafttechnik',
    Occupation: 'Fruit Juice Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627104,
    OccDE: 'Fruchtsafttechniker/in',
    Occupation: 'Fruit Juice Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661402,
    OccDE: 'Obstverkäufer/in',
    Occupation: 'Fruit Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673907,
    OccDE: 'Brennstoffhändler/in (Einzelhandel)',
    Occupation: 'Fuel Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521216,
    OccDE: 'Funktionsprüfer/in',
    Occupation: 'Function Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704206,
    OccDE: 'Fondsmakler/in',
    Occupation: 'Fund Broker',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 805503,
    OccDE: 'Bestatter/in',
    Occupation: 'Funeral Arranger',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 805504,
    OccDE: 'Bestattungs-Unternehmer/in',
    Occupation: 'Funeral Arranger',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 805505,
    OccDE: 'Bestattungsgehilf(e/in)',
    Occupation: 'Funeral Arranger Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501105,
    OccDE: 'Möbel- und Bautischler/in',
    Occupation: 'Furniture and Joinery Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501409,
    OccDE: 'Möbel- und Küchentischler/in (Aufbau)',
    Occupation: 'Furniture and Kitchen Builder (Assembly)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501408,
    OccDE: 'Möbel- und Küchenaufbauer/in',
    Occupation: 'Furniture and Kitchen Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501410,
    OccDE: 'Möbelaufsteller/in (Schreiner/in)',
    Occupation: 'Furniture Assembler (Carpenter)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505122,
    OccDE: 'Möbelmontierer/in (Möbelherstellung)',
    Occupation: 'Furniture Assembler (Furniture Manufacturing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501412,
    OccDE: 'Möbelmonteur/in (nicht Möbelherstellung)',
    Occupation: 'Furniture Assembler (not Furniture Manufacturing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501307,
    OccDE: 'Möbelschreiner/in',
    Occupation: 'Furniture Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673404,
    OccDE: 'Möbelkaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Furniture Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671249,
    OccDE: 'Möbelkaufmann/-kauffrau (Großhandel)',
    Occupation: 'Furniture Clerk (Wholesale)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 673403,
    OccDE: 'Möbelhändler/in (Einzelhandel)',
    Occupation: 'Furniture Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714420,
    OccDE: 'Möbelfahrer/in',
    Occupation: 'Furniture Delivery Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501411,
    OccDE: 'Möbelausliefer(er/in)',
    Occupation: 'Furniture Delivery Person',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834122,
    OccDE: 'Möbeldesigner/in',
    Occupation: 'Furniture Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512405,
    OccDE: 'Möbellackierer/in',
    Occupation: 'Furniture Finishers',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701203,
    OccDE: 'Möbelspediteur/in',
    Occupation: 'Furniture Forwarder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501413,
    OccDE: 'Möbelpacker/in (Tischler/in)',
    Occupation: 'Furniture Packer (Carpenter)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501605,
    OccDE: 'Möbelrestaurator/in',
    Occupation: 'Furniture Restorer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662311,
    OccDE: 'Möbelverkäufer/in',
    Occupation: 'Furniture Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627408,
    OccDE: 'Möbeltechniker/in',
    Occupation: 'Furniture Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505121,
    OccDE: 'Möbelfacharbeiter/in',
    Occupation: 'Furniture Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 743208,
    OccDE: 'Möbeltransportpacker/in',
    Occupation: 'Furniture Transport Packer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 743207,
    OccDE: 'Möbeltransporteur/in',
    Occupation: 'Furniture Transporter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 715302,
    OccDE: 'Möbeltransportunternehmer/in',
    Occupation: 'Furniture Transporter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 492104,
    OccDE: 'Möbelpolster(er/in)',
    Occupation: 'Furniture Upholsterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505709,
    OccDE: 'Möbelarbeiter/in',
    Occupation: 'Furniture Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 378302,
    OccDE: 'Kürschner/in',
    Occupation: 'Furrier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264205,
    OccDE: 'Gas- und Wasserwerksmonteur/in',
    Occupation: 'Gall and Water Works Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673911,
    OccDE: 'Galerist/in',
    Occupation: 'Gallerist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792402,
    OccDE: 'Galerieaufseher/in',
    Occupation: 'Gallery Monitor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 235203,
    OccDE: 'Feuerverzinker/in',
    Occupation: 'Galvanizer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 544203,
    OccDE: 'Containerbrücken-Fahrer/in',
    Occupation: 'Gantry Crane Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 523224,
    OccDE: 'Müllsortierer/in',
    Occupation: 'Garbage Sorter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052301',
    OccDE: 'Garten- und Landschaftsarchitekt/in',
    Occupation: 'Garden and Landscape Architecture',
    'Feather RC': 'C',
  },
  {
    OccupationCode: '052302',
    OccDE: 'Gartenarchitekt/in',
    Occupation: 'Garden Architect',
    'Feather RC': 'C',
  },
  {
    OccupationCode: '052908',
    OccDE: 'Gartengestalter/in',
    Occupation: 'Garden Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052632',
    OccDE: 'Gartenverwalter/in',
    Occupation: 'Garden Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052402',
    OccDE: 'Gartentechniker/in',
    Occupation: 'Garden Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051709',
    OccDE: 'Gartenarbeiter/in (allgemein)',
    Occupation: 'Garden Workers (general)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051804',
    OccDE: 'Gärtner/in (Friedhofsgärtnerei)',
    Occupation: 'Gardener (Cemetery Landscaping)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051305',
    OccDE: 'Gärtner/in (Zierpflanzenbau, -produktion)',
    Occupation: 'Gardener (Floriculture)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '053008',
    OccDE: 'Gärtner/in (Blumenbinden)',
    Occupation: 'Gardener (Floristry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051106',
    OccDE: 'Gärtner/in (Garten-, Landschafts- und Sportplatzbau)',
    Occupation: 'Gardener (Gardening, Landscaping and Sports Facilities)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051213',
    OccDE: 'Gärtner/in (Baumschulen)',
    Occupation: 'Gardener (Tree Nursery)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051504',
    OccDE: 'Gärtner/in (Gemüse, Zierpflanzen)',
    Occupation: 'Gardener (Vegetables, Floriculture)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052902',
    OccDE: 'Diplom-Gärtner/in',
    Occupation: 'Gardener with Degree',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 662903,
    OccDE: 'Gartenbedarfsverkäufer/in',
    Occupation: 'Gardening Supply Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627305,
    OccDE: 'Bekleidungstechniker/in',
    Occupation: 'Garment Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627306,
    OccDE: 'Bekleidungstechnische(r) Assistent/in',
    Occupation: 'Garment Technology Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 267101,
    OccDE: 'Gas- und Wasserinstallateur/in',
    Occupation: 'Gas and Water Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921806,
    OccDE: 'Gasberater/in',
    Occupation: 'Gas Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268103,
    OccDE: 'Gasheizungsmonteur/in',
    Occupation: 'Gas Heating Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 267104,
    OccDE: 'Gasinstallateur/in',
    Occupation: 'Gas Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 267105,
    OccDE: 'Gasmonteur/in',
    Occupation: 'Gas Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626904,
    OccDE: 'Gastechniker/in',
    Occupation: 'Gas Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841109,
    OccDE: 'Gastroenterolog(e/in)',
    Occupation: 'Gastroenterologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 712306,
    OccDE: 'Schrankenwärter/in',
    Occupation: 'Gatekeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273201,
    OccDE: 'Getriebebauer/in',
    Occupation: 'Gearbox Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101816,
    OccDE: 'Edelsteinbearbeiter/in',
    Occupation: 'Gem Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 302301,
    OccDE: 'Edelsteinfasser/in',
    Occupation: 'Gemsetter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 302306,
    OccDE: 'Schmucksteinfasser/in',
    Occupation: 'Gemsetter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801397,
    OccDE: 'Gendarm',
    Occupation: 'Gendarme',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695415,
    OccDE: 'Generalagent/in (Versicherungen)',
    Occupation: 'General Agent (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411020,
    OccDE: 'Koch-Tournant',
    Occupation: 'General Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 672202,
    OccDE: 'Gemischtwarenhändler/in',
    Occupation: 'General Dealer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750214,
    OccDE: 'Hauptgeschäftsführer/in',
    Occupation: 'General Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911003,
    OccDE: 'General Manager (Hotel-, Gaststättengewerbe)',
    Occupation: 'General Manager (Hotels, Restaurants)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841101,
    OccDE: 'Allgemeinarzt/-ärztin',
    Occupation: 'General Practitioner',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841048,
    OccDE: 'Praktische(r) Arzt/Ärztin',
    Occupation: 'General Practitioner',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 789002,
    OccDE: 'Allroundsekretär/in',
    Occupation: 'General Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 843112,
    OccDE: 'Praktische(r) Tierarzt/-ärztin',
    Occupation: 'General Veterinarian',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 883302,
    OccDE: 'Geoökolog(e/in)',
    Occupation: 'Geo-ecologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 883501,
    OccDE: 'Geoingenieur/in',
    Occupation: 'Geo-engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 883404,
    OccDE: 'Geograph/in',
    Occupation: 'Geographer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 981122,
    OccDE: 'Student/in Geographie',
    Occupation: 'Geography Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883502,
    OccDE: 'Geolog(e/in)',
    Occupation: 'Geologist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 883528,
    OccDE: 'Diplom-Geologe',
    Occupation: 'Geologist with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 883505,
    OccDE: 'Geologieingenieur/in',
    Occupation: 'Geology Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 883508,
    OccDE: 'Geophysiker/in',
    Occupation: 'Geophysicist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 981123,
    OccDE: 'Student/in Geowissenschaften',
    Occupation: 'Geosciences Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883513,
    OccDE: 'Ingenieur/in für Geotechnik',
    Occupation: 'Geotechnology Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 864701,
    OccDE: 'Altenpflegehelfer/in',
    Occupation: 'Geriatric Care Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 864901,
    OccDE: 'Altenpflegeassistent/in',
    Occupation: 'Geriatric Care Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 864301,
    OccDE: 'Altenpflegelehrer/in',
    Occupation: 'Geriatric Care Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853931,
    OccDE: 'Geriatrieschwester',
    Occupation: 'Geriatric Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 864003,
    OccDE: 'Altenpfleger/in',
    Occupation: 'Geriatric Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853930,
    OccDE: 'Geriatriepfleger (Krankenpflege)',
    Occupation: 'Geriatric Nurse (Nursing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 864201,
    OccDE: 'Altentherapeut/in',
    Occupation: 'Geriatric Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801406,
    OccDE: 'Bundespolizeibeamt(er/in)',
    Occupation: 'German Federal Police Official',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882101,
    OccDE: 'Germanist/in',
    Occupation: 'German Studies Scholar',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 981124,
    OccDE: 'Student/in Germanistik',
    Occupation: 'German Studies Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841949,
    OccDE: 'Gerontolog(e/in)',
    Occupation: 'Gerontologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 512614,
    OccDE: 'Vergolder/in',
    Occupation: 'Gilder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934005,
    OccDE: 'Glas- und Gebäudereiniger/in',
    Occupation: 'Glass and Building Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 514006,
    OccDE: 'Glas- und Kerammaler/in',
    Occupation: 'Glass and Ceramics Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 131207,
    OccDE: 'Glasbläser/in',
    Occupation: 'Glass Blower',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934103,
    OccDE: 'Glasreiniger/in',
    Occupation: 'Glass Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135116,
    OccDE: 'Glasschneider/in',
    Occupation: 'Glass Cutter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834115,
    OccDE: 'Glasgestalter/in',
    Occupation: 'Glass Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 131617,
    OccDE: 'Glasapparatebauer/in',
    Occupation: 'Glass Device Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135401,
    OccDE: 'Glasgraveur/in',
    Occupation: 'Glass Engraver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 485210,
    OccDE: 'Glasbaumonteur/in',
    Occupation: 'Glass Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135222,
    OccDE: 'Glasschleifer/in',
    Occupation: 'Glass Grinder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 131637,
    OccDE: 'Glasinstrumentenmacher/in',
    Occupation: 'Glass Instrument Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 131212,
    OccDE: 'Glasmacher/in',
    Occupation: 'Glass Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 514304,
    OccDE: 'Glasmaler/in',
    Occupation: 'Glass painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135006,
    OccDE: 'Glasbearbeiter/in',
    Occupation: 'Glass Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135009,
    OccDE: 'Glasveredler/in',
    Occupation: 'Glass Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627505,
    OccDE: 'Glastechniker/in',
    Occupation: 'Glass Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 131004,
    OccDE: 'Glasfacharbeiter/in',
    Occupation: 'Glass Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 131005,
    OccDE: 'Glaswerker/in',
    Occupation: 'Glass Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 131720,
    OccDE: 'Glasarbeiter/in',
    Occupation: 'Glass Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121609,
    OccDE: 'Glasierer/in (Keramiker/in)',
    Occupation: 'Glazer (Ceramics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 485001,
    OccDE: 'Glaser/in',
    Occupation: 'Glazier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512703,
    OccDE: 'Glasierer/in',
    Occupation: 'Glazier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 485305,
    OccDE: 'Glaser/in und Fensterbauer/in',
    Occupation: 'Glazier and Window Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 485304,
    OccDE: 'Glaser/in (Fachrichtung Fensterbau)',
    Occupation: 'Glaziers (specialising in windows)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 302101,
    OccDE: 'Gold- und Silberschmied/in',
    Occupation: 'Gold and Silversmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 302104,
    OccDE: 'Goldschmied/in',
    Occupation: 'Goldsmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876910,
    OccDE: 'Golflehrer/in',
    Occupation: 'Golf Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521103,
    OccDE: 'Warenannehmer/in',
    Occupation: 'Goods Accepter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 678109,
    OccDE: 'Warensachbearbeiter/in',
    Occupation: 'Goods Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521017,
    OccDE: 'Warenprüfer/in',
    Occupation: 'Goods Checker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521511,
    OccDE: 'Warenkontrolleur/in in Häfen',
    Occupation: 'Goods Inspector in Ports',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 677115,
    OccDE: 'Warendisponent/in',
    Occupation: 'Goods Junior Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522637,
    OccDE: 'Warenauszeichner/in',
    Occupation: 'Goods Labeller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522489,
    OccDE: 'Warenverpacker/in',
    Occupation: 'Goods Packer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512023,
    OccDE: 'Warenmaler/in',
    Occupation: 'Goods Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 523009,
    OccDE: 'Warensortierer/in',
    Occupation: 'Goods Sorter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512022,
    OccDE: 'Warenlackierer/in',
    Occupation: 'Goods Varnisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 435901,
    OccDE: 'Feinkostmeister/in',
    Occupation: 'Gourmet Foods Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787020,
    OccDE: 'Regierungsassistent/in',
    Occupation: 'Government Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787021,
    OccDE: 'Regierungsbeamt(er/in)',
    Occupation: 'Government Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765014,
    OccDE: 'Regierungs(amtmann/amtfrau)',
    Occupation: 'Government Deputy Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 764040,
    OccDE: 'Regierungsdirektor/in',
    Occupation: 'Government Director',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 764037,
    OccDE: 'Regierungs(rat/rätin)',
    Occupation: 'Government Director General',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787019,
    OccDE: 'Regierungsangestellte/r',
    Occupation: 'Government Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787023,
    OccDE: 'Regierungsobersekretär/in',
    Occupation: 'Government Higher Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765016,
    OccDE: 'Regierungsinspektor/in',
    Occupation: 'Government Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787018,
    OccDE: 'Regierungsamtsinspektor/in',
    Occupation: 'Government Higher Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764039,
    OccDE: 'Regierungsassessor/in',
    Occupation: 'Government Permanent Secretary',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787024,
    OccDE: 'Regierungssekretär/in',
    Occupation: 'Government Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787022,
    OccDE: 'Regierungshauptsekretär/in',
    Occupation: 'Government Senior Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765015,
    OccDE: 'Regierungsamts(rat/rätin)',
    Occupation: 'Government Senior Deputy Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 765018,
    OccDE: 'Regierungsoberinspektor/in',
    Occupation: 'Government Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 305102,
    OccDE: 'Flügelbauer/in',
    Occupation: 'Grand Piano Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834007,
    OccDE: 'Grafiker/in',
    Occupation: 'Graphic Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834609,
    OccDE: 'Werbegrafiker/in',
    Occupation: 'Graphic Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834602,
    OccDE: 'Grafiker/in (Gebrauchsgrafik)',
    Occupation: 'Graphic Artist (Commercial Art)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172108,
    OccDE: 'Grafische(r) Assistent/in (Druckerei)',
    Occupation: 'Graphic Assistant (Printing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834315,
    OccDE: 'Grafikdesign-Assistent/in',
    Occupation: 'Graphic Design Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834316,
    OccDE: 'Grafikdesigner/in',
    Occupation: 'Graphic Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834320,
    OccDE: 'Layoutgrafiker/in',
    Occupation: 'Graphic Layout Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 471102,
    OccDE: 'Grabarbeiter',
    Occupation: 'Grave Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '080210',
    OccDE: 'Kiesgewinner',
    Occupation: 'Gravel Miner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '080731',
    OccDE: 'Kiesgrubenarbeiter',
    Occupation: 'Gravel Pit Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174318,
    OccDE: 'Tiefdrucker/in',
    Occupation: 'Gravure Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744349,
    OccDE: 'Platzarbeiter/in',
    Occupation: 'Green Space Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 794513,
    OccDE: 'Greenkeeper (Golf)',
    Occupation: 'Greenskeeper (Golf)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 225015,
    OccDE: 'Schleifer/in',
    Occupation: 'Grinding Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023834',
    OccDE: 'Pferdepfleger/in',
    Occupation: 'Groom',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 794517,
    OccDE: 'Platzwart/in',
    Occupation: 'Groundskeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 862701,
    OccDE: 'Gruppenhelfer/in (Lebenshilfe)',
    Occupation: 'Group Aid (Life Skills)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756220,
    OccDE: 'Konzern-Controller/in',
    Occupation: 'Group Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 862004,
    OccDE: 'Gruppenleiter/in (Behindertenwerkstatt)',
    Occupation: 'Group Director (Disabilities Centre)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651014,
    OccDE: 'Gruppenmeister',
    Occupation: 'Group Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 993105,
    OccDE: 'Gruppenleiter/in',
    Occupation: 'Group Head',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695107,
    OccDE: 'Gruppenleiter/in (Versicherung)',
    Occupation: 'Group Head (Insurance)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 993104,
    OccDE: 'Gruppenführer/in',
    Occupation: 'Group Leader',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 993106,
    OccDE: 'Kolonnenführer/in',
    Occupation: 'Group Leader',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861009,
    OccDE: 'Gruppenleiter/in (Sozialarbeit)',
    Occupation: 'Group Leader (Social Work)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 993103,
    OccDE: 'Gruppenbetreuer/in',
    Occupation: 'Group Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792023,
    OccDE: 'Wachmann/Wachfrau',
    Occupation: 'Guard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792028,
    OccDE: 'Wächter/in',
    Occupation: 'Guard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791103,
    OccDE: 'Wach- und Werkschutzmann/-schutzfrau',
    Occupation: 'Guard and Company Security Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792021,
    OccDE: 'Wachführer/in',
    Occupation: 'Guard Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861106,
    OccDE: 'Berufsvormund',
    Occupation: 'Guardian',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 255901,
    OccDE: 'Leitplankenmonteur/in',
    Occupation: 'Guardrail Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914914,
    OccDE: 'Guest Relations Manager',
    Occupation: 'Guest Relations Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911206,
    OccDE: 'Pensionsleiter/in',
    Occupation: 'Guesthouse Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911204,
    OccDE: 'Pensionsbesitzer/in',
    Occupation: 'Guesthouse Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911205,
    OccDE: 'Pensionsinhaber/in',
    Occupation: 'Guesthouse Proprietor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792903,
    OccDE: 'Diensthundführer/in',
    Occupation: 'Guide Dog Walker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702304,
    OccDE: 'Reiseführer/in',
    Occupation: 'Guides',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 305502,
    OccDE: 'Gitarrenbauer/in',
    Occupation: 'Guitar Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875310,
    OccDE: 'Gitarrenlehrer/in',
    Occupation: 'Guitar Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300301,
    OccDE: 'Büchsenmacher/in',
    Occupation: 'Gunsmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876908,
    OccDE: 'Fitneßstudioleiter/in',
    Occupation: 'Gym Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841503,
    OccDE: 'Frauenarzt/-ärztin',
    Occupation: 'Gynaecologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841504,
    OccDE: 'Gynäkolog(e/in)',
    Occupation: 'Gynaecologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 901501,
    OccDE: 'Haararbeiter/in (Perückenmacher/in)',
    Occupation: 'Hair Worker (Wig Maker)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901004,
    OccDE: 'Friseur/in',
    Occupation: 'Hairdresser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901007,
    OccDE: 'Frisör/in',
    Occupation: 'Hairdresser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901401,
    OccDE: 'Friseur/in und Fußpfleger/in',
    Occupation: 'Hairdresser and Chiropodist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901403,
    OccDE: 'Friseur/in und Kosmetiker/in',
    Occupation: 'Hairdresser and Cosmetician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901404,
    OccDE: 'Friseur/in und Visagist/in',
    Occupation: 'Hairdresser and Make-up Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901402,
    OccDE: 'Friseur/in und Handpfleger/in',
    Occupation: 'Hairdresser and Manicurist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901405,
    OccDE: 'Friseurin und Maniküre',
    Occupation: 'Hairdresser and Manicurist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901406,
    OccDE: 'Friseurkosmetiker/in',
    Occupation: 'Hairdresser Beautician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901805,
    OccDE: 'Hairstylist/in',
    Occupation: 'Hairstylist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792315,
    OccDE: 'Hallenwart/in',
    Occupation: 'Hall Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201104,
    OccDE: 'Handformer',
    Occupation: 'Hand-Moulded Caster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744465,
    OccDE: 'Umschlagarbeiter/in',
    Occupation: 'Handler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 465107,
    OccDE: 'Hafenaufseher (Tiefbau)',
    Occupation: 'Harbour Supervisor (Civil Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744806,
    OccDE: 'Hafenfacharbeiter/in',
    Occupation: 'Harbour Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 724801,
    OccDE: 'Hafenaufseher/in (Wasserverkehr)',
    Occupation: 'Harbour Warden (Water Transport)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 233101,
    OccDE: 'Härter/in',
    Occupation: 'Hardener',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 233701,
    OccDE: 'Härtereihelfer/in',
    Occupation: 'Hardening Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602409,
    OccDE: 'Hardware-Berater/in',
    Occupation: 'Hardware Consultant',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602410,
    OccDE: 'Hardware-Entwickler/in',
    Occupation: 'Hardware Developer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602411,
    OccDE: 'Hardware-Spezialist/in',
    Occupation: 'Hardware Specialist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 831415,
    OccDE: 'Harfenist/in',
    Occupation: 'Harpist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 715301,
    OccDE: 'Güterkraftverkehrsunternehmer/in',
    Occupation: 'Haulier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 753105,
    OccDE: 'Prüfungsleiter/in (Wirtschaftsprüfung)',
    Occupation: 'Head Auditor (Auditing)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 753107,
    OccDE: 'Revisionsleiter/in (Wirtschaftsprüfung)',
    Occupation: 'Head Auditor (Auditing)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 751104,
    OccDE: 'Leitende(r) kaufmännische(r) Angestellte/r',
    Occupation: 'Head Business Employee',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 411002,
    OccDE: 'Alleinkoch/-köchin',
    Occupation: 'Head Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269019,
    OccDE: 'Obermonteur/in',
    Occupation: 'Head Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 775301,
    OccDE: 'Leiter/in Anwendungsentwicklung (EDV)',
    Occupation: 'Head of Applications Development (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775302,
    OccDE: 'Leiter/in Anwendungsprogrammierung',
    Occupation: 'Head of Applications Programming',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 751105,
    OccDE: 'Leiter/in Controlling',
    Occupation: 'Head of Controlling',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 778411,
    OccDE: 'Leiter/in des Rechenzentrums',
    Occupation: 'Head of Data Centre',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751003,
    OccDE: 'Abteilungsleiter/in',
    Occupation: 'Head of Department',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 764033,
    OccDE: 'Referatsleiter/in',
    Occupation: 'Head of Division',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 751202,
    OccDE: 'Leiter/in des Finanz- und Rechnungswesens',
    Occupation: 'Head of Finance and Accounting',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751506,
    OccDE: 'Personalleiter/in',
    Occupation: 'Head of HR',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751903,
    OccDE: 'Logistikleiter/in',
    Occupation: 'Head of Logistics',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 741053,
    OccDE: 'Materialwirtschaftsleiter/in',
    Occupation: 'Head of Materials Management',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 880102,
    OccDE: 'Forschungsleiter/in',
    Occupation: 'Head of Research',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789106,
    OccDE: 'Sekretariatsleiter/in',
    Occupation: "Head of Secretary's Office",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764017,
    OccDE: 'Dienststellenleiter/in',
    Occupation: 'Head of Service',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 861012,
    OccDE: 'Leiter/in der sozialen Dienste',
    Occupation: 'Head of Social Services',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 775304,
    OccDE: 'Leiter/in Softwareentwicklung',
    Occupation: 'Head of Software Development',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 773002,
    OccDE: 'Hauptkassierer/in',
    Occupation: 'Head Treasurer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 870806,
    OccDE: 'Schulleiter/in',
    Occupation: 'Headmaster',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 861309,
    OccDE: 'Gesundheitsberater/in',
    Occupation: 'Health Adviser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 805004,
    OccDE: 'Gesundheitsassistent/in',
    Occupation: 'Health Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981187,
    OccDE: 'Student/in Gesundheitswesen (nichtärztliche Therapien)',
    Occupation: 'Health Care (non-medical therapies) Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695999,
    OccDE: 'Kaufmann/Kauffrau im Gesundheitswesen',
    Occupation: 'Health Care Business Administration Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861312,
    OccDE: 'Gesundheitspfleger/in',
    Occupation: 'Health Care Professional',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661904,
    OccDE: 'Reformwarenverkäufer/in',
    Occupation: 'Health Food Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787307,
    OccDE: 'Krankenkassenbeamt(er/in)',
    Occupation: 'Health Insurance Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787313,
    OccDE: 'Sozialversicherungsbeamt(er/in)',
    Occupation: 'Health Insurance Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787312,
    OccDE: 'Sozialversicherungsangestellte/r',
    Occupation: 'Health Insurance Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695904,
    OccDE: 'Krankenversicherungsangestellte/r',
    Occupation: 'Health Insurance Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695903,
    OccDE: 'Krankenkassenangestellte/r (nicht Sozialversicherung)',
    Occupation: 'Health Insurance Employee (not Social Security)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787306,
    OccDE: 'Krankenkassenangestellte/r (Sozialversicherung)',
    Occupation: 'Health Insurance Employee (Social Security)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695905,
    OccDE: 'Krankenversicherungsfachmann/-fachfrau',
    Occupation: 'Health Insurance Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 675202,
    OccDE: 'Reformhauskaufmann/-kauffrau',
    Occupation: 'Health Store Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 675201,
    OccDE: 'Reformfachberater/in',
    Occupation: 'Health Store Consultant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 805005,
    OccDE: 'Gesundheitsaufseher/in',
    Occupation: 'Health Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629304,
    OccDE: 'Gesundheitstechniker/in',
    Occupation: 'Health Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315306,
    OccDE: 'Hörgerätetechniker/in (Mechaniker/in)',
    Occupation: 'Hearing Aid Technician (Mechanic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268105,
    OccDE: 'Heizungsbauer/in',
    Occupation: 'Heating Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268199,
    OccDE: 'Heizungsbaumeister/in',
    Occupation: 'Heating Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601509,
    OccDE: 'Heizungsingenieur/in',
    Occupation: 'Heating Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 268702,
    OccDE: 'Heizungsbauerhelfer/in',
    Occupation: 'Heating Engineer Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268107,
    OccDE: 'Heizungsmonteur/in',
    Occupation: 'Heating Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268106,
    OccDE: 'Heizungsinstallateur/in',
    Occupation: 'Heating Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 706402,
    OccDE: 'Heizkostenableser/in',
    Occupation: 'Heating Metre Reader',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621518,
    OccDE: 'Techniker/in für Heizungs-, Lüftungs- und Sanitärtechnik',
    Occupation:
      'Heating Supply for Heating, Ventilation, and Sanitary Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268104,
    OccDE: 'Heizungsanlagenbauer/in',
    Occupation: 'Heating Systems Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621506,
    OccDE: 'Heizungstechniker/in',
    Occupation: 'Heating Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621520,
    OccDE: 'Wärmetechniker/in',
    Occupation: 'Heating Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549817,
    OccDE: 'Heizer/in',
    Occupation: 'Heating Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482306,
    OccDE: 'Wärme-, Kälte- und Schallschutzisolierer/in',
    Occupation: 'Heating, Cooling, Acoustic Insulation',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 283907,
    OccDE: 'Hubschraubermechaniker/in',
    Occupation: 'Helicopter Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726122,
    OccDE: 'Hubschrauberpilot',
    Occupation: 'Helicopter Pilot',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726110,
    OccDE: 'Hubschrauberführer/in',
    Occupation: 'Helicopter Pilot',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621206,
    OccDE: 'Hubschraubertechniker/in',
    Occupation: 'Helicopter Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814107,
    OccDE: 'Obergerichtsvollzieher/in',
    Occupation: 'High Court Bailiff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310035,
    OccDE: 'Hochspannungsmonteur/in',
    Occupation: 'High Voltage Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310047,
    OccDE: 'Starkstromelektriker/in',
    Occupation: 'High-Voltage Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310048,
    OccDE: 'Starkstrommonteur/in',
    Occupation: 'High-Voltage Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787017,
    OccDE: 'Obersekretär/in (Verwaltung)',
    Occupation: 'Higher Administrative Officer (Administration)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765008,
    OccDE: 'Inspektor/in',
    Occupation: 'Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787003,
    OccDE: 'Amtsinspektor/in',
    Occupation: 'Higher Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765007,
    OccDE: 'Inspektor/in (allgemeine Verwaltung, gehobener Dienst)',
    Occupation:
      'Higher Executive Officer (general administration, higher civil service)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 312216,
    OccDE: 'Technische(r) Fernmeldeobersekretär/in',
    Occupation: 'Higher Telecommunications Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 872130,
    OccDE: 'Oberstudien(rat/rätin)',
    Occupation: 'Higher Tenured Secondary School Teacher',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 705140,
    OccDE: 'Verleiher/in',
    Occupation: 'Hire Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714211,
    OccDE: 'Mietwagenfahrer/in',
    Occupation: 'Hire Car Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882404,
    OccDE: 'Historiker/in',
    Occupation: 'Historian',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 981125,
    OccDE: 'Student/in Geschichtswissenschaften',
    Occupation: 'History Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 838405,
    OccDE: 'Eishockeyspieler',
    Occupation: 'Hockey Player',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 131217,
    OccDE: 'Hohlglasmacher/in',
    Occupation: 'Hollow Glass Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316111,
    OccDE: 'Hausgerätetechniker/in (Elektromechaniker/in)',
    Occupation: 'Home Appliance Technician (Electromechanical Engineer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853810,
    OccDE: 'Hauspfleger (Krankenpflege)',
    Occupation: 'Home Care Provider (Nursing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 865008,
    OccDE: 'Hauspfleger/in (Familienpflege)',
    Occupation: 'Home Care Worker (Family Care)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981127,
    OccDE: 'Student/in Haushalts- und Ernährungswissenschaften',
    Occupation: 'Home Economics and Nutrition Science Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921002,
    OccDE: 'Haus- und Ernährungswirtschaftler/in',
    Occupation: 'Home Economics and Nutrition Scientist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874305,
    OccDE: 'Hauswirtschaftslehrer/in',
    Occupation: 'Home Economics Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923708,
    OccDE: 'Haushaltshilfe',
    Occupation: 'Home Help',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 992101,
    OccDE: 'Heimarbeiter/in',
    Occupation: 'Homeworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '024702',
    OccDE: 'Klauenpfleger/in',
    Occupation: 'Hoof Trimmer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 224111,
    OccDE: 'Horizontalbohrer/in',
    Occupation: 'Horizontal Boring Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023842',
    OccDE: 'Pferdezüchter/in',
    Occupation: 'Horse Breeder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023801',
    OccDE: 'Bereiter/in',
    Occupation: 'Horse Keeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023832',
    OccDE: 'Pferdehalter/in (Pferdeführer, Tierp fleger, Pferdewirt)',
    Occupation: 'Horse Trainer (Wrangler, Animal Keeper)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052907',
    OccDE: 'Gartenbauunternehmer/in',
    Occupation: 'Horticultural Businessman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052501',
    OccDE: 'Gartenbauberater/in',
    Occupation: 'Horticultural Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052106',
    OccDE: 'Gartenbauingenieur/in',
    Occupation: 'Horticultural Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '052105',
    OccDE: 'Gartenbauingenieur/in (gärtnerische Produktion)',
    Occupation: 'Horticultural Engineer (Horticultural Production)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '051014',
    OccDE: 'Gartenbaufachwerker/in',
    Occupation: 'Horticultural Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051107',
    OccDE: 'Gartenbaufacharbeiter/in',
    Occupation: 'Horticultural Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052401',
    OccDE: 'Gartenbautechniker/in',
    Occupation: 'Horticultural Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051017',
    OccDE: 'Gartenbauwerker/in',
    Occupation: 'Horticultural Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051008',
    OccDE: 'Gärtner/in',
    Occupation: 'Horticulturalist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051013',
    OccDE: 'Gartenbauer/in',
    Occupation: 'Horticulturalist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981121,
    OccDE: 'Student/in Gartenbau und Landespflege',
    Occupation: 'Horticulture and Landscape Planning Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051010',
    OccDE: 'Gärtnergehilf(e/in)',
    Occupation: 'Horticulture Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051015',
    OccDE: 'Gartenbaugehilf(e/in)',
    Occupation: 'Horticulture Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051710',
    OccDE: 'Gartenbauhelfer/in',
    Occupation: 'Horticulture Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052905',
    OccDE: 'Gartenbauingenieur/in (Garten- und Landschaftsgestaltung)',
    Occupation: 'Horticulture Engineer (Garden and Landscape Design)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '052906',
    OccDE: 'Gartenbauleiter/in',
    Occupation: 'Horticulture Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052904',
    OccDE: 'Gartenbaubetriebsleiter/in',
    Occupation: 'Horticulture Operations Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051006',
    OccDE: 'Fachwerker/in im Gartenbau',
    Occupation: 'Horticulture Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854108,
    OccDE: 'Krankenhausgehilf(e/in)',
    Occupation: 'Hospital Ancillary Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854109,
    OccDE: 'Krankenhaushelfer/in',
    Occupation: 'Hospital Ancillary Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785601,
    OccDE: 'Krankenhausbetriebswirt/in',
    Occupation: 'Hospital Business Administrator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 702409,
    OccDE: 'Hosteß',
    Occupation: 'Hostess',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 194380,
    OccDE: 'Warmwalzenführer/in',
    Occupation: 'Hot Rolling Machine Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879210,
    OccDE: 'Hotelanimateur/in',
    Occupation: 'Hotel Activity Coordinator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915104,
    OccDE: 'Hotel- und Gaststättengehilf(e/in)',
    Occupation: 'Hotel and Restaurant Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915203,
    OccDE: 'Hotel- und Gaststättenfachhelfer/in',
    Occupation: 'Hotel and Restaurant Contractor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914101,
    OccDE: 'Hotelassistent/in',
    Occupation: 'Hotel Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915105,
    OccDE: 'Hotelgehilf(e/in)',
    Occupation: 'Hotel Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411018,
    OccDE: 'Hotelkoch/-köchin',
    Occupation: 'Hotel Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915001,
    OccDE: 'Hotelangestellte/r',
    Occupation: 'Hotel Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911104,
    OccDE: 'Hoteldirektor/in',
    Occupation: 'Hotel Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911108,
    OccDE: 'Hotelmanager',
    Occupation: 'Hotel Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914004,
    OccDE: 'Hotelbetriebswirt/in',
    Occupation: 'Hotel Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911105,
    OccDE: 'Hotelgeschäftsführer/in',
    Occupation: 'Hotel Managing Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911103,
    OccDE: 'Hotelbetriebsleiter/in',
    Occupation: 'Hotel Operations Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 794107,
    OccDE: 'Hoteldiener/in',
    Occupation: 'Hotel Porter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912006,
    OccDE: 'Hotelrestaurantkellner/in',
    Occupation: 'Hotel Restaurant Waiter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914005,
    OccDE: 'Hotelkaufmann/-kauffrau',
    Occupation: 'Hotel Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914103,
    OccDE: 'Hotelfachkraft',
    Occupation: 'Hotel Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914102,
    OccDE: 'Hotelfachassistent/in',
    Occupation: 'Hotel Special Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914104,
    OccDE: 'Hotelfachmann/Hotelfachfrau',
    Occupation: 'Hotel Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915099,
    OccDE: 'Hotelarbeiter/in',
    Occupation: 'Hotel Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911106,
    OccDE: 'Hotelier',
    Occupation: 'Hotelier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316339,
    OccDE: 'Haushaltsgerätemonteur/in',
    Occupation: 'Household Appliance Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923707,
    OccDE: 'Haushaltshelfer/in',
    Occupation: 'Household Helper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921508,
    OccDE: 'Hausdame',
    Occupation: 'Housekeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921202,
    OccDE: 'Haushälterin',
    Occupation: 'Housekeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921208,
    OccDE: 'Hauswirtschafter/in',
    Occupation: 'Housekeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921206,
    OccDE: 'Hauswirtschafter/in im ländlichen Bereich',
    Occupation: 'Housekeeper (rural)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921207,
    OccDE: 'Hauswirtschafter/in im städtischen Bereich',
    Occupation: 'Housekeeper (urban)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923202,
    OccDE: 'Hauswirtschaftshelfer/in',
    Occupation: 'Housekeeping Aid',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923107,
    OccDE: 'Hauswirtschaftslehrling',
    Occupation: 'Housekeeping Apprentice',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921509,
    OccDE: 'Hausdamenassistentin',
    Occupation: 'Housekeeping Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921210,
    OccDE: 'Hauswirtschaftsassistent/in',
    Occupation: 'Housekeeping Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923104,
    OccDE: 'Haushaltsgehilf(e/in)',
    Occupation: 'Housekeeping Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923106,
    OccDE: 'Hauswirtschaftsgehilf(e/in)',
    Occupation: 'Housekeeping Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923204,
    OccDE: 'Hauswirtschaftstechnische(r) Helfer/in',
    Occupation: 'Housekeeping Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921810,
    OccDE: 'Hauswirtschaftsberater/in',
    Occupation: 'Housekeeping Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921104,
    OccDE: 'Hauswirtschaftsleiter/in',
    Occupation: 'Housekeeping Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921004,
    OccDE: 'Hauswirtschaftsverwalter/in',
    Occupation: 'Housekeeping Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921103,
    OccDE: 'Hauswirtschaftliche(r) Betriebsleiter/in',
    Occupation: 'Housekeeping Operations Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921219,
    OccDE: 'Wirtschafter/in (Hauswirtschafter/in)',
    Occupation: 'Housekeeping Staff Member (Housekeeping)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921209,
    OccDE: 'Hauswirtschaftlich-technische(r) Assistent/in',
    Occupation: 'Housekeeping Technology Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923110,
    OccDE: 'Wirtschaftspfleger/in',
    Occupation: 'Housekeeping Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923109,
    OccDE: 'Wirtschaftsgehilf(e/in) (Hauswirtschaftsgehilfe/-gehilfin)',
    Occupation: 'Housekeeping Worker (Housekeeping Assistant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011029',
    OccDE: 'Hausmann/-frau (Landwirt/in)',
    Occupation: 'Houseman (Agriculturalist)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863203,
    OccDE: 'Herbergsvater',
    Occupation: 'Housemaster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863307,
    OccDE: 'Heimerzieher/in',
    Occupation: 'Housemaster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863399,
    OccDE: 'Erzieher/in (Jugend- und             Heimerziehung)',
    Occupation: 'Housemaster (Youth and Residential Education Institution)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863701,
    OccDE: 'Erziehungshelfer/in',
    Occupation: 'Housemaster Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863303,
    OccDE: 'Erzieher/in für Jugendheime',
    Occupation: 'Housemaster at Youth Residential Education Institutions',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863304,
    OccDE: 'Erzieher/in in Heimen',
    Occupation: 'Housemaster in Residential Institutions',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921299,
    OccDE: 'Hausfrau/Hausmann',
    Occupation: 'Housewife/Househusband',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704536,
    OccDE: 'Wohnungsvermittler/in',
    Occupation: 'Housing Broker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751502,
    OccDE: 'Personal- und Ausbildungsleiter/in',
    Occupation: 'HR and Professional Development Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 788310,
    OccDE: 'Personalkaufmann/-kauffrau',
    Occupation: 'HR Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751508,
    OccDE: 'Personalreferent/in (nicht öffentliche Verwaltung)',
    Occupation: 'HR Clerk (not Public Administration)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 756222,
    OccDE: 'Personal-Controller/in',
    Occupation: 'HR Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756138,
    OccDE: 'Personalentwickler/in (Personalplaner/in)',
    Occupation: 'HR Developer (HR Planner)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751505,
    OccDE: 'Personaldirektor/in',
    Occupation: 'HR Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751507,
    OccDE: 'Personalmanager',
    Occupation: 'HR Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751503,
    OccDE: 'Personalabteilungsleiter/in',
    Occupation: 'HR manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751504,
    OccDE: 'Personalchef/in',
    Occupation: 'HR Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 788311,
    OccDE: 'Personalsachbearbeiter/in',
    Occupation: 'HR Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756140,
    OccDE: 'Personalplaner/in',
    Occupation: 'HR Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789206,
    OccDE: 'Personalsekretär/in',
    Occupation: 'HR Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788308,
    OccDE: 'Personalfachkaufmann/-kauffrau',
    Occupation: 'HR Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883126,
    OccDE: 'Humanbiolog(e/in)',
    Occupation: 'Human Biologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 788304,
    OccDE: 'Personalassistent/in',
    Occupation: 'Human Resources Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788307,
    OccDE: 'Personaldisponent/in',
    Occupation: 'Human Resources Coordinator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788306,
    OccDE: 'Personalbetreuer/in',
    Occupation: 'Human Resources Services Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623524,
    OccDE: 'Wasserbautechniker/in',
    Occupation: 'Hydraulic Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 465118,
    OccDE: 'Wasserbaumeister',
    Occupation: 'Hydraulic Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 465308,
    OccDE: 'Wasserbauer/in',
    Occupation: 'Hydraulic Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 465315,
    OccDE: 'Wasserwerker',
    Occupation: 'Hydraulic Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 465313,
    OccDE: 'Wasserbauwerker',
    Occupation: 'Hydraulic Engineering Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273308,
    OccDE: 'Hydraulikschlosser/in',
    Occupation: 'Hydraulic Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621908,
    OccDE: 'Hydrauliktechniker/in',
    Occupation: 'Hydraulic Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 465307,
    OccDE: 'Wasserbauarbeiter',
    Occupation: 'Hydraulics Engineering Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273306,
    OccDE: 'Hydraulikmonteur/in',
    Occupation: 'Hydraulics Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273303,
    OccDE: 'Hydrauliker/in',
    Occupation: 'Hydraulics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883512,
    OccDE: 'Hydrolog(e/in)',
    Occupation: 'Hydrologist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 852303,
    OccDE: 'Medizinische(r) Bademeister/in',
    Occupation: 'Hydrotherapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 805902,
    OccDE: 'Hygienefachberater/in',
    Occupation: 'Hygiene Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 805011,
    OccDE: 'Hygieneinspektor/in',
    Occupation: 'Hygiene inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629305,
    OccDE: 'Hygienetechniker/in',
    Occupation: 'Hygiene Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841954,
    OccDE: 'Hygieniker/in',
    Occupation: 'Hygienist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 393502,
    OccDE: 'Eiskonditor/in',
    Occupation: 'Ice Cream Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 393503,
    OccDE: 'Eiskremhersteller/in',
    Occupation: 'Ice Cream Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 392301,
    OccDE: 'Eiscafebesitzer/in',
    Occupation: 'Ice Cream Parlour Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661602,
    OccDE: 'Eisverkäufer/in',
    Occupation: 'Ice Cream Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834810,
    OccDE: 'Reinzeichner/in',
    Occupation: 'Illustrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834899,
    OccDE: 'Zeichner/in',
    Occupation: 'Illustrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 833303,
    OccDE: 'Illustrator/in',
    Occupation: 'Illustrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671314,
    OccDE: 'Importkaufmann/-kauffrau',
    Occupation: 'Import Businessperson',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 671313,
    OccDE: 'Importeur/in',
    Occupation: 'Importer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 813403,
    OccDE: 'Justitiar/in',
    Occupation: 'In-house Counsel',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 310032,
    OccDE: 'Hauselektriker/in',
    Occupation: 'In-house Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854202,
    OccDE: 'Betriebssanitäter/in',
    Occupation: 'In-house Paramedic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321930,
    OccDE: 'Glühlampenarbeiter/in',
    Occupation: 'Incandescent Bulb Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788930,
    OccDE: 'Reklamations(sach)bearbeiter/in',
    Occupation: 'Incident Management Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 754105,
    OccDE: 'Lohnsteuerhilfe',
    Occupation: 'Income Tax Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 754503,
    OccDE: 'Lohnsteuersachbearbeiter/in',
    Occupation: 'Income Tax Clerk',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 521104,
    OccDE: 'Wareneingangskontrolleur/in',
    Occupation: 'Incoming Goods Checker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521105,
    OccDE: 'Wareneingangsleiter/in',
    Occupation: 'Incoming Goods Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788656,
    OccDE: 'Wareneingangsbearbeiter/in',
    Occupation: 'Incoming Goods Processing Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660001,
    OccDE: 'Alleinverkäufer/in',
    Occupation: 'Individual Retailer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323201,
    OccDE: 'Industriefachhelfer/in',
    Occupation: 'Industrial Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531299,
    OccDE: 'Industriehelfer/in',
    Occupation: 'Industrial Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 194519,
    OccDE: 'Industrieschmied/in',
    Occupation: 'Industrial Blacksmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 178204,
    OccDE: 'Industriebuchbinder/in',
    Occupation: 'Industrial Book Binder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121116,
    OccDE: 'Industriekeramiker/in',
    Occupation: 'Industrial Ceramicist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121902,
    OccDE: 'Industriekeramiker/in (Fachrichtung Mechanik)',
    Occupation: 'Industrial Ceramicist (specialising in Mechanics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 937121,
    OccDE: 'Industriereiniger/in',
    Occupation: 'Industrial Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 779206,
    OccDE: 'Industrieinformatiker/in',
    Occupation: 'Industrial Computer Scientist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 834116,
    OccDE: 'Industrial Designer',
    Occupation: 'Industrial Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834117,
    OccDE: 'Industriedesigner/in',
    Occupation: 'Industrial Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311202,
    OccDE: 'Betriebselektroinstallateur/in',
    Occupation: 'Industrial Electrical Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311201,
    OccDE: 'Betriebselektriker/in',
    Occupation: 'Industrial Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310036,
    OccDE: 'Industrieelektriker/in',
    Occupation: 'Industrial Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316340,
    OccDE: 'Industrieelektroniker/in (Fachrichtung Gerätetechnik)',
    Occupation: 'Industrial Electrician (specialising in Equipment Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316101,
    OccDE: 'Betriebselektromechaniker/in',
    Occupation: 'Industrial Electromechanical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316015,
    OccDE: 'Industrieelektroniker/in',
    Occupation: 'Industrial Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316203,
    OccDE: 'Industrieelektroniker/in (Fachrichtung Produktionstechnik)',
    Occupation:
      'Industrial Electronics Technician (specialising in Production Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997110,
    OccDE: 'Gewerbliche(r) Arbeitnehmer/in',
    Occupation: 'Industrial Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 607120,
    OccDE: 'Wirtschaftsingenieur/in',
    Occupation: 'Industrial Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 607212,
    OccDE: 'Industrieingenieur/in (Industrial Engineer)',
    Occupation: 'Industrial Engineer (Industrial Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 607119,
    OccDE: 'Wirtschaftsingenieur/in (Transportwesen)',
    Occupation: 'Industrial Engineer (Transportation)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 981179,
    OccDE: 'Student/in Wirtschaftsingenieurwesen',
    Occupation: 'Industrial Engineering Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250011,
    OccDE: 'Industrieanlagenmechaniker/in',
    Occupation: 'Industrial Equipment Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269011,
    OccDE: 'Industriemonteur/in',
    Occupation: 'Industrial Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 131008,
    OccDE: 'Industrieglasfertiger/in',
    Occupation: 'Industrial Glazer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 485219,
    OccDE: 'Industrieglaser/in',
    Occupation: 'Industrial Glazier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482203,
    OccDE: 'Industrieisolierer/in',
    Occupation: 'Industrial Insulation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482204,
    OccDE: 'Isolierer/in im Bereich der Industrie',
    Occupation: 'Industrial Insulation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 270002,
    OccDE: 'Industriemechaniker/in',
    Occupation: 'Industrial Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 274004,
    OccDE: 'Industriemechaniker/in (Fachrichtung Betriebstechnik)',
    Occupation: 'Industrial Mechanic (specialising in Industrial Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300014,
    OccDE: 'Industriemechaniker/in (Fachrichtung Geräte- und Feinwerktechnik)',
    Occupation:
      'Industrial Mechanic (specialising in Instrument and Precision Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273008,
    OccDE: 'Industriemechaniker/in (Fachrichtung Maschinen- und Systemtechnik)',
    Occupation:
      'Industrial Mechanic (specialising in Mechanical and Systems Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 276004,
    OccDE: 'Industriemechaniker/in (Fachrichtung Produktionstechnik)',
    Occupation: 'Industrial Mechanic (specialising in Production Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 270701,
    OccDE: 'Industriemechanikerhelfer/in',
    Occupation: 'Industrial Mechanic Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 274702,
    OccDE: 'Industriemechanikerhelfer/in (Betriebstechnik)',
    Occupation: 'Industrial Mechanic Assistant (Industrial Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273701,
    OccDE: 'Industriemechanikerhelfer/in (Maschinen- und Systemtechnik)',
    Occupation:
      'Industrial Mechanic Assistant (Mechanical and Systems Engineering',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 276702,
    OccDE: 'Industriemechanikerhelfer/in (Produktionstechnik)',
    Occupation: 'Industrial Mechanic Assistant (Production Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522105,
    OccDE: 'Industriefachpacker/in',
    Occupation: 'Industrial Packer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511007,
    OccDE: 'Industriemaler/in',
    Occupation: 'Industrial Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511206,
    OccDE: 'Industrieanstreicher/in',
    Occupation: 'Industrial Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837101,
    OccDE: 'Industriefotograf/in',
    Occupation: 'Industrial Photographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261102,
    OccDE: 'Baublechner/in',
    Occupation: 'Industrial Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261103,
    OccDE: 'Bauflaschner/in',
    Occupation: 'Industrial Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261104,
    OccDE: 'Bauklempner/in',
    Occupation: 'Industrial Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261105,
    OccDE: 'Bauspengler/in',
    Occupation: 'Industrial Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689012,
    OccDE: 'Industrievertreter/in',
    Occupation: 'Industrial Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 358008,
    OccDE: 'Industrienäher/in',
    Occupation: 'Industrial Sewer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311113,
    OccDE: 'Industrieanlagenelektroniker/in',
    Occupation: 'Industrial Systems Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531298,
    OccDE: 'Industrietechniker/in',
    Occupation: 'Industrial Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 991102,
    OccDE: 'Industriefacharbeiter/in',
    Occupation: 'Industrial Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512006,
    OccDE: 'Industrielackierer/in',
    Occupation: 'Industrial Varnisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250012,
    OccDE: 'Industrieschlosser/in',
    Occupation: 'Industrial Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323202,
    OccDE: 'Industriewerker/in',
    Occupation: 'Industrial Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531203,
    OccDE: 'Industriearbeiter/in',
    Occupation: 'Industrial Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505011,
    OccDE: 'Industrieschreiner/in',
    Occupation: 'Industry Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 779194,
    OccDE: 'Informations- und Telekommunikations -System-Kaufmann/Kauffrau',
    Occupation: 'Information and Telecommunications Systems Clerk',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 317198,
    OccDE: 'Informations- und Telekommunikations--System-Elektroniker/in',
    Occupation:
      'Information and Telecommunications Systems Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 776308,
    OccDE: 'Informations-Koordinator/in',
    Occupation: 'Information Coordinator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 834317,
    OccDE: 'Informationsdesigner/in',
    Occupation: 'Information Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 317106,
    OccDE: 'Informationselektriker/in',
    Occupation: 'Information Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 317107,
    OccDE: 'Informationselektroniker/in',
    Occupation: 'Information Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774504,
    OccDE: 'Informationsmanager/in (EDV)',
    Occupation: 'Information Manager (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776309,
    OccDE: 'Informationsorganisator/in',
    Occupation: 'Information Organiser',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 622313,
    OccDE: 'Informationstechniker/in',
    Occupation: 'Information technology Engineer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 981130,
    OccDE: 'Student/in Informatik',
    Occupation: 'Information Technology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201713,
    OccDE: 'Gießmaschinenbediener',
    Occupation: 'Injection Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152431,
    OccDE: 'Spritzgußmaschinenführer/in (Kunststoff)',
    Occupation: 'Injection Moulding Machine Operator (Plastics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152424,
    OccDE: 'Plastikspritzer/in',
    Occupation: 'Injection Moulding Technician in Plastics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152428,
    OccDE: 'Spritzer/in (Kunststoffverarbeiter/in)',
    Occupation: 'Injection Technician (Plastics Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 794103,
    OccDE: 'Gasthofgehilf(e/in)',
    Occupation: 'Inn Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629306,
    OccDE: 'Innovationsassistent/in (technischer Umweltschutz)',
    Occupation: 'Innovation Assistant (Technical Environment Protection)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 631401,
    OccDE: 'Besamungstechniker/in',
    Occupation: 'Insemination Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608233,
    OccDE: 'Revisionsingenieur/in',
    Occupation: 'Inspection Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 281105,
    OccDE: 'Inspektionsmechaniker/in (Kfz)',
    Occupation: 'Inspection Mechanic (Auto)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521701,
    OccDE: 'Kontrollarbeiter/in',
    Occupation: 'Inspection Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608235,
    OccDE: 'Revisor/in',
    Occupation: 'Inspector',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 812202,
    OccDE: 'Inspektor/in im Notardienst',
    Occupation: 'Inspector in the Notary Service',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 269702,
    OccDE: 'Montagehelfer/in',
    Occupation: 'Installation Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310707,
    OccDE: 'Monteurhelfer/in (Elektroinstallation)',
    Occupation: 'Installation Assistant (Electrical Installation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250704,
    OccDE: 'Montagehelfer/in (Metallbau)',
    Occupation: 'Installation Assistant (Metal Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600501,
    OccDE: 'Installationsingenieur/in',
    Occupation: 'Installation Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600504,
    OccDE: 'Montageingenieur/in',
    Occupation: 'Installation Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 269002,
    OccDE: 'Bauleitende(r) Monteur/in',
    Occupation: 'Installation Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 282117,
    OccDE: 'Montagemechaniker/in (Landtechnik)',
    Occupation: 'Installation Mechanic (Agricultural Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250018,
    OccDE: 'Montageschlosser/in (Metallbau)',
    Occupation: 'Installation Metal Worker (Metal Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620506,
    OccDE: 'Montageplaner/in',
    Occupation: 'Installation Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250010,
    OccDE: 'Einrichter/in (Konstruktionmechaniker/in)',
    Occupation: 'Installation Specialist (Construction Mechanic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620501,
    OccDE: 'Installationstechniker/in',
    Occupation: 'Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620507,
    OccDE: 'Montagetechniker/in',
    Occupation: 'Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550103,
    OccDE: 'Einrichter/in',
    Occupation: 'Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550104,
    OccDE: 'Einsteller/in',
    Occupation: 'Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269012,
    OccDE: 'Installateur/in',
    Occupation: 'Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622803,
    OccDE: 'Installationstechniker/in (Elektrotechnik)',
    Occupation: 'Installation Technician (Electrical Engineering)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 267106,
    OccDE: 'Installateur/in (Gas, Wasser)',
    Occupation: 'Installation Technician (Gas, Water)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268001,
    OccDE: 'Einstellmonteur/in (Heizungs-, Lüftungs-, Klimaanlagen)',
    Occupation:
      'Installation Technician (Heating, Ventilation, Air Conditioning)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268003,
    OccDE: 'Installateur/in (Heizung, Lüftung)',
    Occupation: 'Installation Technician (Heating, Ventilation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 278206,
    OccDE: 'Montageschlosser/in (Maschinenbau)',
    Occupation: 'Installation Technician (Mechanical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269105,
    OccDE: 'Installateur/in und Klempner/in',
    Occupation: 'Installation Technician and Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269106,
    OccDE: 'Installateur/in und Spengler/in',
    Occupation: 'Installation Technician and Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 252114,
    OccDE: 'Monteur/in für chemische Anlagen und Behälter',
    Occupation: 'Installation Technician for Chemical Equipment and Containers',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550102,
    OccDE: 'Einrichter/in von Mehrzweckmaschinen',
    Occupation: 'Installation Technician for Multi-purpose Machinery',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874001,
    OccDE: 'Ausbildungsleiter/in (Lehrer/in)',
    Occupation: 'Instructional Supervisors (Teacher)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 652001,
    OccDE: 'Ausbilder/in',
    Occupation: 'Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879509,
    OccDE: 'Instruktor/in',
    Occupation: 'Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879501,
    OccDE: 'Arbeitsanleiter/in',
    Occupation: 'Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 652102,
    OccDE: 'Ausbilder/in (gewerblich-technisch)',
    Occupation: 'Instructor (Commercial/Technical)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879504,
    OccDE: 'Ausbilder/in (nicht gewerblich-technisch)',
    Occupation: 'Instructor (not Commercial/Technical)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874523,
    OccDE: 'Lehrkraft für den berufspraktischen Unterricht',
    Occupation: 'Instructor of Applied Vocational Training',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 305005,
    OccDE: 'Instrumentenmacher/in (Musikinstrumentenbauer/in)',
    Occupation: 'Instrument Maker (Musical Instrument Maker)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875315,
    OccDE: 'Instrumentalpädagog(e/in)',
    Occupation: 'Instrumental Music Pedagogue',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875313,
    OccDE: 'Instrumentallehrer/in',
    Occupation: 'Instrumental Music Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482301,
    OccDE: 'Isoliermonteur/in',
    Occupation: 'Insulation Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482006,
    OccDE: 'Isolierer/in',
    Occupation: 'Insulation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482005,
    OccDE: 'Facharbeiter/in für Isoliertechnik',
    Occupation: 'Insulation Technology Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695528,
    OccDE: 'Versicherungssachbearbeiter/in',
    Occupation: 'Insurance Adjuster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695005,
    OccDE: 'Versicherungsbetriebswirt/in',
    Occupation: 'Insurance Administration Specialist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 695430,
    OccDE: 'Versicherungsberater/in',
    Occupation: 'Insurance Adviser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695429,
    OccDE: 'Versicherungsagent/in',
    Occupation: 'Insurance Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695432,
    OccDE: 'Versicherungsvermittler/in',
    Occupation: 'Insurance Broker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704801,
    OccDE: 'Assekuranzmakler/in',
    Occupation: 'Insurance Broker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704803,
    OccDE: 'Versicherungsmakler/in',
    Occupation: 'Insurance Broker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695428,
    OccDE: 'Versicherungsabschlußvertreter/in',
    Occupation: 'Insurance Company Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695499,
    OccDE: 'Versicherungsaußendienstler/in',
    Occupation: 'Insurance Field Sales Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695431,
    OccDE: 'Versicherungsfinanzberater/in',
    Occupation: 'Insurance Financial advisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695310,
    OccDE: 'Versicherungsinspektor/in',
    Occupation: 'Insurance Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 813407,
    OccDE: 'Versicherungsjurist/in',
    Occupation: 'Insurance Lawyer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 695524,
    OccDE: 'Versicherungsantragsbearbeiter/in',
    Occupation: 'Insurance Policy Administrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695004,
    OccDE: 'Versicherungsangestellte/r',
    Occupation: 'Insurance Provider Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695433,
    OccDE: 'Versicherungsvertreter/in',
    Occupation: 'Insurance Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695008,
    OccDE: 'Versicherungskaufmann/-kauffrau',
    Occupation: 'Insurance Specialist with Vocational Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853934,
    OccDE: 'Intensivpfleger',
    Occupation: 'Intensive Care Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853935,
    OccDE: 'Intensivschwester',
    Occupation: 'Intensive Care Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836404,
    OccDE: 'Innenausstattungsberater/in',
    Occupation: 'Interior Decorating consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491005,
    OccDE: 'Raumausstattergesell(e/in)',
    Occupation: 'Interior Decorating Journeyman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836405,
    OccDE: 'Inneneinrichter/in',
    Occupation: 'Interior Decorator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491003,
    OccDE: 'Raumausstatter/in',
    Occupation: 'Interior Decorator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836402,
    OccDE: 'Einrichtungsberater/in',
    Occupation: 'Interior Design Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836301,
    OccDE: 'Ingenieur/in für Innenarchitektur',
    Occupation: 'Interior Design Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 836403,
    OccDE: 'Einrichtungsplaner/in',
    Occupation: 'Interior Design Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981131,
    OccDE: 'Student/in Innenarchitektur',
    Occupation: 'Interior Design Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836302,
    OccDE: 'Innenarchitekt/in',
    Occupation: 'Interior Designer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 836004,
    OccDE: 'Raumgestalter/in',
    Occupation: 'Interior Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836003,
    OccDE: 'Innenraumgestalter/in',
    Occupation: 'Interior Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501229,
    OccDE: 'Innenausbauer/in',
    Occupation: 'Interior Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505117,
    OccDE: 'Innenausbauer/in (Holzmechaniker/in)',
    Occupation: 'Interior Finisher (Wood Mechanic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501221,
    OccDE: 'Innenausbautischler/in',
    Occupation: 'Interior Finishing Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511212,
    OccDE: 'Zimmeranstreicher/in',
    Occupation: 'Interior Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 982108,
    OccDE: 'Praktikant/in',
    Occupation: 'Intern',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 753903,
    OccDE: 'Innenrevisor/in',
    Occupation: 'Internal Auditor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 777189,
    OccDE: 'Internet Application Consultant',
    Occupation: 'Internet Application Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775191,
    OccDE: 'Internet-Entwickler/in',
    Occupation: 'Internet Developer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 775199,
    OccDE: 'Internet-Programmierer/in',
    Occupation: 'Internet Programmer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 841111,
    OccDE: 'Internist/in',
    Occupation: 'Internist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 822101,
    OccDE: 'Dolmetscher/in',
    Occupation: 'Interpreter',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 822001,
    OccDE: 'Dolmetscher/in und Übersetzer/in',
    Occupation: 'Interpreter and Translator',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 822199,
    OccDE: 'Diplom-Dolmetscher/in',
    Occupation: 'Interpreter with Degree',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 788920,
    OccDE: 'Interviewer/in',
    Occupation: 'Interviewer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 772914,
    OccDE: 'Lagerbuchhalter/in',
    Occupation: 'Inventory Accountant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 788631,
    OccDE: 'Lagerlistenführer/in',
    Occupation: 'Inventory Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787909,
    OccDE: 'Ermittlungsbeamt(er/in)',
    Occupation: 'Investigative Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691303,
    OccDE: 'Anlageberater/in',
    Occupation: 'Investment Adviser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691306,
    OccDE: 'Investmentberater/in',
    Occupation: 'Investment Adviser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691312,
    OccDE: 'Investitionsberater',
    Occupation: 'Investment Adviser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691307,
    OccDE: 'Investmentkaufmann/-kauffrau',
    Occupation: 'Investment Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201608,
    OccDE: 'Eisengießer',
    Occupation: 'Iron Foundry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931504,
    OccDE: 'Bügler/in',
    Occupation: 'Ironer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673401,
    OccDE: 'Eisenwarenkaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Ironmongery Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191505,
    OccDE: 'Eisenschläger/in',
    Occupation: 'Ironsmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774297,
    OccDE: 'Fachinformatiker/in',
    Occupation: 'IT Specialist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 774298,
    OccDE: 'Fachinformatiker/in (Anwendungsentwicklung)',
    Occupation: 'IT Specialist (Applications Development)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 774299,
    OccDE: 'Fachinformatiker/in (Systemintegration)',
    Occupation: 'IT Specialist (Systems Integration)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 779197,
    OccDE: 'IT-System-Kaufmann/-kauffrau',
    Occupation: 'IT Systems Clerk',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 317199,
    OccDE: 'IT-Systemelektroniker/in',
    Occupation: 'IT Systems Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778404,
    OccDE: 'EDV-Systemmanager/in',
    Occupation: 'IT Systems Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934331,
    OccDE: 'Putzmann/Putzfrau',
    Occupation: 'Janitor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 302114,
    OccDE: 'Juwelier/in',
    Occupation: 'Jeweller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 302304,
    OccDE: 'Juwelier/in (Edelsteinfasser/in)',
    Occupation: 'Jeweller (Gemsetter)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 302110,
    OccDE: 'Juwelier/in (Goldschmied/in)',
    Occupation: 'Jeweller (Goldsmith)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673802,
    OccDE: 'Schmuckwarenhändler/in (Einzelhandel)',
    Occupation: 'Jewellery Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834128,
    OccDE: 'Schmuckdesigner/in',
    Occupation: 'Jewellery Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834129,
    OccDE: 'Schmuckgestalter/in',
    Occupation: 'Jewellery Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 302113,
    OccDE: 'Schmuckwarenhersteller/in (Goldschmied/in)',
    Occupation: 'Jewellery Manufacturer (Goldsmith)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 231912,
    OccDE: 'Schmuckpolierer/in',
    Occupation: 'Jewellery Polisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 838206,
    OccDE: 'Jockey',
    Occupation: 'Jockey',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501206,
    OccDE: 'Bauschreiner/in',
    Occupation: 'Joiner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501208,
    OccDE: 'Bautischler/in',
    Occupation: 'Joiner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 761301,
    OccDE: 'Beigeordnete/r',
    Occupation: 'Joint Office Holder',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 981132,
    OccDE: 'Student/in Journalistik',
    Occupation: 'Journalism Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981156,
    OccDE: 'Student/in Publizistik',
    Occupation: 'Journalism Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821509,
    OccDE: 'Journalist/in',
    Occupation: 'Journalist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 811170,
    OccDE: 'Richter/in',
    Occupation: 'Judge',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 811154,
    OccDE: 'Richter/in (am beliebigen Gericht)',
    Occupation: 'Judge (at any Court)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 811153,
    OccDE: 'Richter/in (am Gericht)',
    Occupation: 'Judge (in Court)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 812119,
    OccDE: 'Rechtspfleger/in',
    Occupation: 'Judicial Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787129,
    OccDE: 'Juristenanwärter/in',
    Occupation: 'Judicial Referendary',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 212533,
    OccDE: 'Kabeljungwerker/in',
    Occupation: 'Junior Cable Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141005,
    OccDE: 'Chemiebetriebsjungwerker/in',
    Occupation: 'Junior Chemical Plant Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141009,
    OccDE: 'Chemiejungwerker/in',
    Occupation: 'Junior Chemical Production Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756219,
    OccDE: 'Junior-Controller/in',
    Occupation: 'Junior Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411019,
    OccDE: 'Jungkoch/-köchin',
    Occupation: 'Junior Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841013,
    OccDE: 'Assistenzarzt/-ärztin',
    Occupation: 'Junior Doctor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 813299,
    OccDE: 'Notaranwärter/in',
    Occupation: 'Junior Notary Public',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 778113,
    OccDE: 'Junioroperator/in',
    Occupation: 'Junior Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756133,
    OccDE: 'Organisationsreferent/in',
    Occupation: 'Junior Organisation Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 755107,
    OccDE: 'Produktreferent/in',
    Occupation: 'Junior Product Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 801163,
    OccDE: 'Stabsgefreiter',
    Occupation: 'Junior Staff Officer (OR-2)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 754508,
    OccDE: 'Steuerreferent/in',
    Occupation: 'Junior Tax Specialist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 981159,
    OccDE: 'Student/in Rechtswissenschaft',
    Occupation: 'Jurisprudence Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751399,
    OccDE: 'Key Account Manager/in',
    Occupation: 'Key Account Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300605,
    OccDE: 'Schlüsselmacher/in',
    Occupation: 'Key Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863705,
    OccDE: 'Kindergartenhelfer/in',
    Occupation: 'Kindergarten Aid',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863112,
    OccDE: 'Kindergartenleiter/in',
    Occupation: 'Kindergarten Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863110,
    OccDE: 'Kindergärtner/in',
    Occupation: 'Kindergarten Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863111,
    OccDE: 'Kindergärtner/in-Anwärter/in (Vorpraktikant/in)',
    Occupation: 'Kindergarten Teacher Candidate (Pre-Intern)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911499,
    OccDE: 'Kioskbesitzer/in',
    Occupation: 'Kiosk Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911489,
    OccDE: 'Kioskinhaber/in',
    Occupation: 'Kiosk Proprietor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662905,
    OccDE: 'Kioskverkäufer/in',
    Occupation: 'Kiosk Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501404,
    OccDE: 'Küchenmonteur/in',
    Occupation: 'Kitchen Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411711,
    OccDE: 'Küchenhilfe',
    Occupation: 'Kitchen Help',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411033,
    OccDE: 'Küchenleiter/in',
    Occupation: 'Kitchen Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627902,
    OccDE: 'Küchenplaner/in (Fertigungstechniker/in)',
    Occupation: 'Kitchen Planner (Production Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662309,
    OccDE: 'Küchenplaner/in (Verkaufsberater/in)',
    Occupation: 'Kitchen Planner (Sales Consultant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662310,
    OccDE: 'Küchenspezialist/in (Verkaufsberater/in)',
    Occupation: 'Kitchen Specialist (Sales Consultant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411032,
    OccDE: 'Küchenfachkraft',
    Occupation: 'Kitchen Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411799,
    OccDE: 'Küchenarbeiter/in',
    Occupation: 'Kitchen Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 344010,
    OccDE: 'Stricker/in',
    Occupation: 'Knitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 344004,
    OccDE: 'Maschenwarenfertiger/in',
    Occupation: 'Knitwear Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633011,
    OccDE: 'Laborant/in',
    Occupation: 'Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626109,
    OccDE: 'Laboratoriumsassistent/in (chemisch-technische/r)',
    Occupation: 'Lab Assistant (Chemical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316017,
    OccDE: 'Laborelektroniker/in',
    Occupation: 'Lab Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 611828,
    OccDE: 'Laboringenieur/in (Chemie)',
    Occupation: 'Lab Engineer (Chemistry)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 741705,
    OccDE: 'Auszeichner/in',
    Occupation: 'Labeller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857122,
    OccDE: 'Medizinallaborant/in',
    Occupation: 'Laboratory Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 142106,
    OccDE: 'Laboratoriumsgehilf(e/in)',
    Occupation: 'Laboratory Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 142703,
    OccDE: 'Laborhelfer/in',
    Occupation: 'Laboratory Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857118,
    OccDE: 'Laborant/in (Medizinlabor)',
    Occupation: 'Laboratory Assistant (Medical Laboratory)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 858002,
    OccDE: 'Laborassistent/in in Apotheken',
    Occupation: 'Laboratory Assistant in Pharmacies',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 633008,
    OccDE: 'Laborangestellte/r',
    Occupation: 'Laboratory Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633803,
    OccDE: 'Laborangestellte/r (Lebensmittelkontrolle)',
    Occupation: 'Laboratory Employee (Food Testing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600106,
    OccDE: 'Laboringenieur/in',
    Occupation: 'Laboratory Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 626110,
    OccDE: 'Laborleiter/in (Chemotechniker/in)',
    Occupation: 'Laboratory Manager (Chemical Engineer)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 611005,
    OccDE: 'Laborleiter/in (Chemiker/in)',
    Occupation: 'Laboratory Manager (Chemist)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600107,
    OccDE: 'Laborleiter/in (Ingenieur/in)',
    Occupation: 'Laboratory Manager (Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 300019,
    OccDE: 'Labormechaniker/in (Geräte- und Feinwerktechnik)',
    Occupation: 'Laboratory Mechanic (Instrument and Precision Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626002,
    OccDE: 'Labortechniker/in',
    Occupation: 'Laboratory Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 142109,
    OccDE: 'Laborfacharbeiter/in',
    Occupation: 'Laboratory Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 142110,
    OccDE: 'Laborfachwerker/in',
    Occupation: 'Laboratory Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626199,
    OccDE: 'Labortechnische Assistentin',
    Occupation: 'Laboratory Technology Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 142115,
    OccDE: 'Laborwerker/in',
    Occupation: 'Laboratory Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 142702,
    OccDE: 'Laborarbeiter/in',
    Occupation: 'Laboratory Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791501,
    OccDE: 'Arbeitsamtermittler/in',
    Occupation: 'Labour Department Investigator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628205,
    OccDE: 'Arbeitsorganisations- und Arbeitsgestaltungstechniker/in',
    Occupation: 'Labour Organisation and Design Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 628206,
    OccDE: 'Arbeitsplaner/in',
    Occupation: 'Labour Planner',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 531104,
    OccDE: 'Hilfsarbeiter/in',
    Occupation: 'Labourer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023608',
    OccDE: 'Binnenfischer/in',
    Occupation: 'Lacustrian Fisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901101,
    OccDE: 'Damen- und Herrenfriseur/in',
    Occupation: 'Ladies and Gentlemen Hairstylist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901201,
    OccDE: 'Damenfriseur/in',
    Occupation: 'Ladies Hairdresser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 351302,
    OccDE: 'Damenmaßschneider/in',
    Occupation: 'Ladies Tailor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 351304,
    OccDE: 'Damenschneider/in',
    Occupation: 'Ladies Tailor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152505,
    OccDE: 'Laminierer/in (Kunststoffverarbeitung)',
    Occupation: 'Laminator (Plastics Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 506706,
    OccDE: 'Laminierer/in (Holz-, Kunststoffkonstruktionsbau)',
    Occupation: 'Laminator (Wood, Plastics Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778312,
    OccDE: 'LAN-Administrator/in',
    Occupation: 'LAN Administrator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 801120,
    OccDE: 'Hauptgefreiter',
    Occupation: 'Lance Corporal',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 624301,
    OccDE: 'Flurbereinigungstechniker/in',
    Occupation: 'Land Consolidation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 604203,
    OccDE: 'Landvermessungsingenieur/in',
    Occupation: 'Land Surveying Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 609407,
    OccDE: 'Raumplaner/in',
    Occupation: 'Land-Use Planner',
    'Feather RC': 'B',
  },
  {
    OccupationCode: '052304',
    OccDE: 'Landschaftsarchitekt/in',
    Occupation: 'Landscape Architect',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 981137,
    OccDE: 'Student/in Landschaftsarchitektur',
    Occupation: 'Landscape Architecture Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052910',
    OccDE: 'Landschaftsgestalter/in',
    Occupation: 'Landscape Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051115',
    OccDE: 'Landschaftsgestalter/in (Gärtner/in)',
    Occupation: 'Landscape Designer (Gardener)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051114',
    OccDE: 'Landschaftsgärtner/in',
    Occupation: 'Landscape Gardener',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061602',
    OccDE: 'Landschaftspfleger/in (Forstbedienstete/r)',
    Occupation: 'Landscape Maintenance Agent (Forestry Staff)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061603',
    OccDE: 'Natur- und Landschaftspfleger/in',
    Occupation: 'Landscape Maintenance and Conservation Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 833215,
    OccDE: 'Landschaftsmaler/in',
    Occupation: 'Landscape Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052203',
    OccDE: 'Landespfleger/in',
    Occupation: 'Landscape Planner',
    'Feather RC': 'C',
  },
  {
    OccupationCode: '052305',
    OccDE: 'Landschaftsplaner/in',
    Occupation: 'Landscape Planner',
    'Feather RC': 'C',
  },
  {
    OccupationCode: '051711',
    OccDE: 'Landschaftsarbeiter/in',
    Occupation: 'Landscape Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051116',
    OccDE: 'Landschaftspfleger/in (Gärtner/in)',
    Occupation: 'Landscape Worker (Gardener)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051113',
    OccDE: 'Landschaftsbaugehilf(e/in)',
    Occupation: 'Landscaper Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051112',
    OccDE: 'Landschaftsbauer/in',
    Occupation: 'Landscapers',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052404',
    OccDE: 'Landschaftsbautechniker/in',
    Occupation: 'Landscaping Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 716301,
    OccDE: 'Fahrbahnmarkierer/in',
    Occupation: 'Lane Marker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879312,
    OccDE: 'Sprecherzieher/in',
    Occupation: 'Language Coach',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 822002,
    OccDE: 'Sprachmittler/in (Dolmetscher/in)',
    Occupation: 'Language Mediator (Interpreter)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 874921,
    OccDE: 'Sprachlehrer/in',
    Occupation: 'Language Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879525,
    OccDE: 'Sprachausbilder/in',
    Occupation: 'Language Trainer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981165,
    OccDE: 'Student/in Sprach-, Sprechwissenschaften',
    Occupation: 'Language, Linguistics Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101823,
    OccDE: 'Edelsteinschleifer/in',
    Occupation: 'Lapidary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 294911,
    OccDE: 'Lasergraveur/in',
    Occupation: 'Laser Engraver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626401,
    OccDE: 'Lasertechniker/in',
    Occupation: 'Laser Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 221204,
    OccDE: 'Drehautomateneinrichter/in',
    Occupation: 'Lathe Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 221209,
    OccDE: 'Maschinendreher/in',
    Occupation: 'Lathe Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 221201,
    OccDE: 'Automatendreher/in (Metall)',
    Occupation: 'Lathe Operator (Metal)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931417,
    OccDE: 'Waschmann/Waschfrau',
    Occupation: 'Launderer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931414,
    OccDE: 'Wäschereigehilf(e/in)',
    Occupation: 'Laundry Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931415,
    OccDE: 'Wäschereileiter/in',
    Occupation: 'Laundry Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931413,
    OccDE: 'Wäschereifacharbeiter/in',
    Occupation: 'Laundry Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931720,
    OccDE: 'Wäschereiarbeiter/in',
    Occupation: 'Laundry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931915,
    OccDE: 'Wäschepfleger/in',
    Occupation: 'Laundry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801497,
    OccDE: 'Vollzugsbeamter (Bundespolizei)',
    Occupation: 'Law Enforcement Officer (Federal Police)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801403,
    OccDE: 'Beamt(er/in) im Polizeivollzugsdienst',
    Occupation: 'Law Enforcement Official',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801699,
    OccDE: 'Polizeivollzugsbeamt(er/in)',
    Occupation: 'Law Enforcement Official',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801467,
    OccDE: 'Polizeivollzugsbeamt(er/in) (Vollzugsdienst)',
    Occupation: 'Law Enforcement Official (Correctional Service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801468,
    OccDE: 'Polizeivollzugsbeamt(er/in) in der Bundespolizei',
    Occupation: 'Law Enforcement Official in the Federal Police',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 786207,
    OccDE: 'Rechtsanwaltsgehilf(e/in)',
    Occupation: 'Law Office Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 786103,
    OccDE: 'Kanzleiangestellte/r (Anwaltskanzlei, Notariat)',
    Occupation: 'Law Office Employee (Attorney, Notary)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 786206,
    OccDE: 'Rechtsanwaltsbürovorsteher/in',
    Occupation: 'Law Office Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 813001,
    OccDE: 'Jurist/in',
    Occupation: 'Lawyer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 813099,
    OccDE: 'Diplom-Jurist/in',
    Occupation: 'Lawyer with Degree',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 171003,
    OccDE: 'Layoutsetzer/in',
    Occupation: 'Layout Setter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834319,
    OccDE: 'Layouter/in (Grafikdesigner/in)',
    Occupation: 'Layout Technician (Graphic Design)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 171002,
    OccDE: 'Layouter/in (Schriftsetzer/in)',
    Occupation: 'Layout Technician (Typesetter)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802302,
    OccDE: 'Brandmeister/in',
    Occupation: 'Leading Firefighter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705132,
    OccDE: 'Mietkoordinator/in (Baumaschinenvermietung)',
    Occupation: 'Lease Coordinator (Hire Equipment)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705141,
    OccDE: 'Vermietassistent/in',
    Occupation: 'Leasing Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705125,
    OccDE: 'Leasing-Berater/in',
    Occupation: 'Leasing Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705126,
    OccDE: 'Leasing-Fachwirt/in',
    Occupation: 'Leasing Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997181,
    OccDE: 'Leasingarbeiter/in',
    Occupation: 'Leasing Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 374508,
    OccDE: 'Feintäschner/in',
    Occupation: 'Leather Craftsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 371004,
    OccDE: 'Lederhersteller/in',
    Occupation: 'Leather Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627320,
    OccDE: 'Ledertechniker/in',
    Occupation: 'Leather Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 374518,
    OccDE: 'Täschner/in',
    Occupation: 'Leatherworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 376915,
    OccDE: 'Lederverarbeiter/in',
    Occupation: 'Leatherworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 871204,
    OccDE: 'Dozent/in',
    Occupation: 'Lecturer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 879605,
    OccDE: 'Dozent/in (an Volkshochschulen)',
    Occupation: 'Lecturer (at Continuing Education Centres)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 871203,
    OccDE: 'Dozent/in (an Hochschulen und Akademien)',
    Occupation: 'Lecturer (Universities and Academies)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 871207,
    OccDE: 'Hochschulassistent/in',
    Occupation: 'Lecturer (University College)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 871912,
    OccDE: 'Privatdozent/in',
    Occupation: 'Lecturer with University-Level Teaching Qualification',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 813603,
    OccDE: 'Rechtsbeistand',
    Occupation: 'Legal Adviser',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 813604,
    OccDE: 'Rechtsberater/in',
    Occupation: 'Legal Adviser',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 786299,
    OccDE: 'RA-Gehilf(e/in)',
    Occupation: 'Legal Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 813009,
    OccDE: 'Rechtsanwaltsanwärter',
    Occupation: 'Legal Associate',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 764614,
    OccDE: 'Rechts(rat/rätin)',
    Occupation: 'Legal Officer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 813008,
    OccDE: 'Rechtsreferendar/in',
    Occupation: 'Legal Referendary',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 811151,
    OccDE: 'Referendar/in (am Gericht)',
    Occupation: 'Legal Referendary (in Court)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 786203,
    OccDE: 'Anwaltssekretär/in',
    Occupation: 'Legal Secretary',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 786208,
    OccDE: 'Rechtsanwaltssekretär/in',
    Occupation: 'Legal Secretary',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 911008,
    OccDE: 'Pächter/in (Hotel-, Gaststättengewerbe)',
    Occupation: 'Lessee (Hotels, Restaurants)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705142,
    OccDE: 'Vermieter/in',
    Occupation: 'Lessor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732701,
    OccDE: 'Briefsortierer/in',
    Occupation: 'Letter Sorter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174106,
    OccDE: 'Buchdrucker/in',
    Occupation: 'Letterpress Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 823103,
    OccDE: 'Bibliothekar/in',
    Occupation: 'Librarian',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823101,
    OccDE: 'Bibliothekar/in an allgemeinbildenden Bibliotheken',
    Occupation: 'Librarian at General Libraries',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823122,
    OccDE: 'Diplom-Bibliothekar/in',
    Occupation: 'Librarian with Degree',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823201,
    OccDE: 'Assistent/in an Bibliotheken',
    Occupation: 'Library Aid',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 981111,
    OccDE: 'Student/in Bibliotheks- und Dokumentationswesen',
    Occupation: 'Library and Documentation Science Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 823205,
    OccDE: 'Bibliotheksassistent/in',
    Occupation: 'Library Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 823707,
    OccDE: 'Bibliothekshelfer/in',
    Occupation: 'Library Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 823204,
    OccDE: 'Bibliotheksangestellte/r',
    Occupation: 'Library Employee',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823213,
    OccDE: 'Büchereiangestellte/r',
    Occupation: 'Library Employee',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823210,
    OccDE: 'Bibliothekssekretär/in',
    Occupation: 'Library Secretary',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823214,
    OccDE: 'Büchereifachmann/-fachfrau',
    Occupation: 'Library Specialist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 801128,
    OccDE: 'Leutnant',
    Occupation: 'Lieutenant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801139,
    OccDE: 'Oberleutnant',
    Occupation: 'Lieutenant (Higher Grade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801145,
    OccDE: 'Oberstleutnant',
    Occupation: 'Lieutenant Colonel',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869102,
    OccDE: 'Lebensberater/in',
    Occupation: 'Life Coach',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 795204,
    OccDE: 'Schwimmeister/in',
    Occupation: 'Lifeguard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 795401,
    OccDE: 'Badeaufsicht',
    Occupation: 'Lifeguard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 795202,
    OccDE: 'Bademeister/in',
    Occupation: 'Lifeguard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 795404,
    OccDE: 'Rettungsschwimmer/in',
    Occupation: 'Lifeguard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 795201,
    OccDE: 'Bademeister/in(nicht medizinische/r)',
    Occupation: 'Lifeguard (not Medical)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 795102,
    OccDE: 'Schwimmeistergehilf(e/in)',
    Occupation: 'Lifeguard Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 795403,
    OccDE: 'Badewärter/in',
    Occupation: 'Lifeguard on Duty',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 795402,
    OccDE: 'Badewärter/in (Schwimmbad)',
    Occupation: 'Lifeguard on Duty (Swimming Pool)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254202,
    OccDE: 'Aufzugmonteur/in',
    Occupation: 'Lift Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254201,
    OccDE: 'Aufzugbauer/in',
    Occupation: 'Lift Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254203,
    OccDE: 'Aufzugschlosser/in',
    Occupation: 'Lift Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621902,
    OccDE: 'Aufzugstechniker/in',
    Occupation: 'Lift Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 506603,
    OccDE: 'Leichtflugzeugbauer/in',
    Occupation: 'Light Aircraft Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801122,
    OccDE: 'Jäger (Soldat)',
    Occupation: 'Light Infantry (Soldier)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 265105,
    OccDE: 'Leichtmetallbauer/in',
    Occupation: 'Light Metal Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 265106,
    OccDE: 'Leichtmetallschlosser/in',
    Occupation: 'Light Metal Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835502,
    OccDE: 'Beleuchtungsassistent/in',
    Occupation: 'Lighting Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834321,
    OccDE: 'Lichtdesigner/in',
    Occupation: 'Lighting Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602120,
    OccDE: 'Lichtingenieur/in',
    Occupation: 'Lighting Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 835505,
    OccDE: 'Beleuchtungsmeister/in',
    Occupation: 'Lighting Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622106,
    OccDE: 'Lichttechniker/in',
    Occupation: 'Lighting Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 835501,
    OccDE: 'Beleuchter/in',
    Occupation: 'Lighting Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835506,
    OccDE: 'Beleuchtungstechniker/in',
    Occupation: 'Lighting Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261404,
    OccDE: 'Blitzschutzanlagenmonteur/in (Klempner/in)',
    Occupation: 'Lightning Protection Equipment Fitter (Plumber)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741722,
    OccDE: 'Linienführer/in (Materialausgeber/in)',
    Occupation: 'Line Foreman (Issuing Clerk)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651003,
    OccDE: 'Bandleiter/in (Fließband)',
    Occupation: 'Line Manager (Assembly Line)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391810,
    OccDE: 'Linienführer/in (Bäckereimaschinenführer/in)',
    Occupation: 'Line Operator (Bakery Machine Operator)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531401,
    OccDE: 'Bandarbeiter/in (Fließband)',
    Occupation: 'Line Workers (Assembly Line)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321910,
    OccDE: 'Bandarbeiter/in (Elektromontage)',
    Occupation: 'Line Workers (Electronics Assembly)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712312,
    OccDE: 'Streckenwärter/in',
    Occupation: 'Linesman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882319,
    OccDE: 'Linguist/in',
    Occupation: 'Linguist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 882333,
    OccDE: 'Sprachforscher/in',
    Occupation: 'Linguistics Researcher',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 882320,
    OccDE: 'Literaturwissenschaftler/in',
    Occupation: 'Literary Scholar',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 172318,
    OccDE: 'Lithograf/in',
    Occupation: 'Lithographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673919,
    OccDE: 'Viehkaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Livestock Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671271,
    OccDE: 'Viehkaufmann/-kauffrau (Großhandel)',
    Occupation: 'Livestock Clerk (Wholesale)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673918,
    OccDE: 'Viehhändler/in (Einzelhandel)',
    Occupation: 'Livestock Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671270,
    OccDE: 'Viehhändler/in (Großhandel)',
    Occupation: 'Livestock Dealer (Wholesale)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713314,
    OccDE: 'Verlademeister/in',
    Occupation: 'Load Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713306,
    OccDE: 'Lademeister/in (nicht Eisenbahn)',
    Occupation: 'Load Agent (not Railway)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744412,
    OccDE: 'Belader/in',
    Occupation: 'Loader',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744439,
    OccDE: 'Lader/in',
    Occupation: 'Loader',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 545220,
    OccDE: 'Radladerfahrer/in',
    Occupation: 'Loader Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744466,
    OccDE: 'Verladearbeiter/in',
    Occupation: 'Loading Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691204,
    OccDE: 'Kreditberater/in',
    Occupation: 'Loan Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691206,
    OccDE: 'Kreditreferent/in',
    Occupation: 'Loan Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691205,
    OccDE: 'Kreditkaufmann/-kauffrau',
    Occupation: 'Loan Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691207,
    OccDE: 'Kreditsachbearbeiter/in',
    Occupation: 'Loan Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300601,
    OccDE: 'Schloß- und Schlüsselmacher/in',
    Occupation: 'Lock and Key Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 465612,
    OccDE: 'Schleusenwärter',
    Occupation: 'Lockkeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 465613,
    OccDE: 'Schleusenwart',
    Occupation: 'Lockkeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '072109',
    OccDE: 'Schlosser für Anlagen und Geräte (Bergbau)',
    Occupation: 'Locksmith for Facilities and Equipment (Mining)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 711104,
    OccDE: 'Hauptlokomotivführer/in',
    Occupation: 'Locomotive Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 711106,
    OccDE: 'Lokführer/in',
    Occupation: 'Locomotive Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 711110,
    OccDE: 'Lokomotivführer/in (Dampf-, Diesel-, Elektrolokomotive)',
    Occupation: 'Locomotive Engineer (Steam, Diesel and Electric locomotive)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 541109,
    OccDE: 'Lokomobilenführer/in',
    Occupation: 'Locomotive Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273923,
    OccDE: 'Lokomotivschlosser/in',
    Occupation: 'Locomotive Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981140,
    OccDE: 'Student/in Logistik- und Transportwesen',
    Occupation: 'Logistics and Transportation Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 607113,
    OccDE: 'Logistik-Ingenieur/in',
    Occupation: 'Logistics Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 751901,
    OccDE: 'Leiter/in der Logistik',
    Occupation: 'Logistics Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751902,
    OccDE: 'Logistik-Manager',
    Occupation: 'Logistics Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 756401,
    OccDE: 'Logistiker/in',
    Occupation: 'Logistics Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628103,
    OccDE: 'Logistik-Techniker/in',
    Occupation: 'Logistics Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 714409,
    OccDE: 'Fernfahrer/in',
    Occupation: 'Long-Haul Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 761308,
    OccDE: 'Oberbürgermeister/in',
    Occupation: 'Lord Mayor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 714414,
    OccDE: 'Lastkraftwagenfahrer/in',
    Occupation: 'Lorry Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714499,
    OccDE: 'LKW-Fahrer/in',
    Occupation: 'Lorry Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281023,
    OccDE: 'Lkw-Mechaniker/in',
    Occupation: 'Lorry Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281025,
    OccDE: 'Lkw-Schlosser/in',
    Occupation: 'Lorry Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622212,
    OccDE: 'Schwachstromtechniker/in',
    Occupation: 'Low-Voltage Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 873202,
    OccDE: 'Hauptschullehrer/in',
    Occupation: 'Lower Secondary School Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 742108,
    OccDE: 'Gepäckfahrer/in (Flughafen)',
    Occupation: 'Luggage Cart Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062202',
    OccDE: 'Baumfäller/in',
    Occupation: 'Lumberjack',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744327,
    OccDE: 'Holzlagerarbeiter/in',
    Occupation: 'Lumberyard Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801130,
    OccDE: 'Maat (Bundeswehr)',
    Occupation: 'Maat (Lower Ranking Petty Officer in the German Armed Forces)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 255113,
    OccDE: 'Maschinen- und Anlagenmonteur/in (Metalleicht-, Stahlbau)',
    Occupation: 'Machine and Equipment Fitter (Lightweight Steel Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264005,
    OccDE: 'Maschinen- und Anlagenmonteur/in (Rohrleitungs-, Behälterbau)',
    Occupation: 'Machine and Equipment Fitter (Pipe and Container Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250014,
    OccDE: 'Maschinen- und Anlagenmonteur/in (Anlagenbau)',
    Occupation: 'Machine and Systems Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 250015,
    OccDE: 'Maschinen- und Anlagenmonteur/in',
    Occupation: 'Machine and Systems Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316118,
    OccDE: 'Maschinen- und Anlagenmonteur/in (Haushaltsgroßgeräteservice)',
    Occupation:
      'Machine and Systems Fitter (Major Household Appliance Service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273013,
    OccDE: 'Maschinen- und Anlagenmonteur/in (Maschinenbau)',
    Occupation: 'Machine and Systems Installation Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201207,
    OccDE: 'Maschineneinrichter (Formmaschinen, Gießanlagen)',
    Occupation: 'Machine Calibrator (Moulding machines, Casting Equipment)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 540017,
    OccDE: 'Maschinenkontrolleur/in',
    Occupation: 'Machine Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601611,
    OccDE: 'Maschinenkonstrukteur/in (Ingenieur/in)',
    Occupation: 'Machine Designer (Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 273016,
    OccDE: 'Maschinenbaumechaniker/in (Schwerpunkt Allgemeiner Maschinenbau)',
    Occupation:
      'Machine Engineer Mechanic (specialising in General Mechanical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621808,
    OccDE: 'Maschineninstandhaltungstechniker/in',
    Occupation: 'Machine Maintenance Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 540021,
    OccDE: 'Maschinenwart/in',
    Occupation: 'Machine Maintenance Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 540019,
    OccDE: 'Maschinenüberwacher/in',
    Occupation: 'Machine Monitor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201209,
    OccDE: 'Maschinenformer',
    Occupation: 'Machine Moulder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201208,
    OccDE: 'Maschinenformer (Metall)',
    Occupation: 'Machine Moulder (Metal)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 540013,
    OccDE: 'Maschinenbediener/in',
    Occupation: 'Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 540016,
    OccDE: 'Maschinenführer/in',
    Occupation: 'Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 234001,
    OccDE: 'Automatenführer/in (Galvanik)',
    Occupation: 'Machine Operator (Electroplating)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 541018,
    OccDE: 'Maschinenführer/in (Energiemaschinen)',
    Occupation: 'Machine Operator (Energy-Generating Machines)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323105,
    OccDE: 'Maschinenfahrer/in (Metallverarbeitung)',
    Occupation: 'Machine Operator (Metalworking)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 431803,
    OccDE: 'Maschinenfahrer/in (Milchverarbeitung)',
    Occupation: 'Machine Operator (Milk Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 393723,
    OccDE: 'Maschinenbediener/in (Zucker-, Süßwaren-, Speiseeisherstellung)',
    Occupation: 'Machine Operator (Sugar, Confectionery, Ice Cream Production)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505013,
    OccDE: 'Maschinenführer/in (Holzverarbeitung)',
    Occupation: 'Machine Operator (Wood Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550401,
    OccDE: 'Presseneinrichter/in',
    Occupation: 'Machine Press Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549936,
    OccDE: 'Pressenführer/in',
    Occupation: 'Machine Press Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 175004,
    OccDE: 'Maschinendrucker/in (Spezialdruck)',
    Occupation: 'Machine Printer (special printer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 540014,
    OccDE: 'Maschinenbetreuer/in',
    Occupation: 'Machine Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 220003,
    OccDE: 'Werkzeugmaschinenspaner/in',
    Occupation: 'Machine Tool Chipper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601934,
    OccDE: 'Werkzeugingenieur/in',
    Occupation: 'Machine Tool Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 220004,
    OccDE: 'Werkzeugmaschinenwerker/in',
    Occupation: 'Machine tool Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549705,
    OccDE: 'Maschinenhelfer/in',
    Occupation: 'Machinery Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 937129,
    OccDE: 'Maschinenreiniger/in',
    Occupation: 'Machinery Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621605,
    OccDE: 'Maschinenkonstrukteur/in',
    Occupation: 'Machinery Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273018,
    OccDE: 'Maschinenbaumonteur/in',
    Occupation: 'Machinery Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273019,
    OccDE: 'Maschinenbauschlosser/in',
    Occupation: 'Machinery Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273024,
    OccDE: 'Maschinenmonteur/in',
    Occupation: 'Machinery Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550107,
    OccDE: 'Maschineneinrichter/in',
    Occupation: 'Machinery Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550108,
    OccDE: 'Maschineneinsteller/in',
    Occupation: 'Machinery Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550109,
    OccDE: 'Maschinensteller/in',
    Occupation: 'Machinery Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 313102,
    OccDE: 'Maschinenmonteur/in (Elektromaschinen)',
    Occupation: 'Machinery Installation Technician (Electrical Machinery)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531301,
    OccDE: 'Maschinenarbeiter/in',
    Occupation: 'Machinery Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323106,
    OccDE: 'Maschinenwerker/in (an Metallbearbeitungsmaschinen)',
    Occupation: 'Machinery Operator (with Metalworking Machinery)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 162718,
    OccDE: 'Maschinenführergehilf(e/in) (Verpackungsmittelherstellung)',
    Occupation:
      'Machinery Operator Assistant (Packaging Materials Manufacturing Industry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 220005,
    OccDE: 'Zerspanungsfacharbeiter/in (automatische Werkzeugmaschinen)',
    Occupation: 'Machinery Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 541017,
    OccDE: 'Maschinenaufseher/in (an Energiemaschinen)',
    Occupation: 'Machinery Supervisor (for Energy-Generating Machines)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273021,
    OccDE: 'Maschinenfacharbeiter/in',
    Occupation: 'Machinery Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323703,
    OccDE: 'Maschinenarbeiter/in (an Metallbearbeitungsmaschinen)',
    Occupation: 'Machinery Worker (with Metalworking Machinery)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 220008,
    OccDE: 'Zerspanungstechniker/in',
    Occupation: 'Machining Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 220007,
    OccDE: 'Zerspanungsmechaniker/in',
    Occupation: 'Machinist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273025,
    OccDE: 'Maschinenschlosser/in',
    Occupation: 'Machinist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 540022,
    OccDE: 'Maschinist/in',
    Occupation: 'Machinist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549509,
    OccDE: 'Maschinist/in (CNC)',
    Occupation: 'Machinist (CNC)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 222026,
    OccDE: 'Zerspanungsfacharbeiter/in (Fräsmaschinen)',
    Occupation: 'Machinist (Milling Cutter)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 225104,
    OccDE: 'Zerspanungsmechaniker/in (Fachrichtung Schleiftechnik)',
    Occupation: 'Machinist (specialising in Grinding Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 221217,
    OccDE: 'Zerspanungsmechaniker/in (Fachrichtung Automatendrehtechnik)',
    Occupation: 'Machinist (specialising in Lathe Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 222102,
    OccDE: 'Zerspanungsmechaniker/in (Fachrichtung Frästechnik)',
    Occupation: 'Machinist (specialising in Milling Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 221111,
    OccDE: 'Zerspanungsmechaniker/in (Fachrichtung Drehtechnik)',
    Occupation: 'Machinist (specialising in Turning Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 221026,
    OccDE: 'Zerspanungsfacharbeiter/in (Drehmaschinen)',
    Occupation: 'Machinist (Turning Machine)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689107,
    OccDE: 'Zeitschriftenwerber/in',
    Occupation: 'Magazine Subscription Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 813005,
    OccDE: 'Magister Iuris',
    Occupation: 'Magister Juris',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 813006,
    OccDE: 'Magister Legum',
    Occupation: 'Magister Legum',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 811105,
    OccDE: 'Amtsrichter/in',
    Occupation: 'Magistrate',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 811101,
    OccDE: 'Amts- und Landrichter/in',
    Occupation: 'Magistrate and County Judges',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 921203,
    OccDE: 'Haushaltspfleger/in',
    Occupation: 'Maid',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923304,
    OccDE: 'Zimmermädchen',
    Occupation: 'Maid',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 672901,
    OccDE: 'Versandgeschäftsinhaber/in',
    Occupation: 'Mail-Order Business Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732101,
    OccDE: 'Briefbot(e/in)',
    Occupation: 'Mailcarrier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732118,
    OccDE: 'Postzusteller/in',
    Occupation: 'Mailman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695208,
    OccDE: 'Hauptagenturleiter/in (Versicherungswesen)',
    Occupation: 'Main Agency Manager (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316025,
    OccDE: 'Wartungselektroniker/in',
    Occupation: 'Maintenance Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600502,
    OccDE: 'Instandhaltungsingenieur/in',
    Occupation: 'Maintenance Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600506,
    OccDE: 'Wartungsingenieur/in',
    Occupation: 'Maintenance Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600508,
    OccDE: 'Werkinstandhaltungsleiter/in (Ingenieur/in)',
    Occupation: 'Maintenance Engineer (Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 651019,
    OccDE: 'Instandhaltungsmeister/in',
    Occupation: 'Maintenance Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 274005,
    OccDE: 'Instandhaltungsmechaniker/in',
    Occupation: 'Maintenance Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 274014,
    OccDE: 'Wartungsmechaniker/in',
    Occupation: 'Maintenance Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281107,
    OccDE: 'Wartungsmechaniker/in (Kfz)',
    Occupation: 'Maintenance Mechanic (Auto)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264004,
    OccDE: 'Instandhaltungsmechaniker/in (Rohrleitungen, Apparate)',
    Occupation: 'Maintenance Mechanic (Pipework, Equipment)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620502,
    OccDE: 'Instandhaltungsplaner/in',
    Occupation: 'Maintenance Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 796001,
    OccDE: 'Hausmeister/in',
    Occupation: 'Maintenance Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 796101,
    OccDE: 'Hausmeister/in und Pförtner/in',
    Occupation: 'Maintenance Supervisor and Porter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620509,
    OccDE: 'Wartungstechniker',
    Occupation: 'Maintenance Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 274016,
    OccDE: 'Wartungsschlosser/in',
    Occupation: 'Maintenance Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269013,
    OccDE: 'Instandsetzungsmonteur/in',
    Occupation: 'Maintenance Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269022,
    OccDE: 'Wartungsmonteur/in',
    Occupation: 'Maintenance Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620503,
    OccDE: 'Instandhaltungstechniker/in',
    Occupation: 'Maintenance Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622324,
    OccDE: 'Wartungstechniker/in (EDV-Anlagen)',
    Occupation: 'Maintenance Technician (EDP Equipment)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 621805,
    OccDE: 'Instandhaltungstechniker/in für Maschinen und Anlagen',
    Occupation: 'Maintenance Technician in Machinery and Equipment',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997118,
    OccDE: 'Wartungsmann/Wartungsfrau',
    Occupation: 'Maintenance Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 794906,
    OccDE: 'Instandhaltungsarbeiter/in',
    Occupation: 'Maintenance Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 274013,
    OccDE: 'Wartungsarbeiter/in (Metallverarbeitung)',
    Occupation: 'Maintenance Worker (Metalworking)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912110,
    OccDE: 'Oberkellner/in',
    Occupation: "Maître d'",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801131,
    OccDE: 'Major',
    Occupation: 'Major',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835808,
    OccDE: 'Maskenbildner/in',
    Occupation: 'Make-up Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902404,
    OccDE: 'Visagist/in',
    Occupation: 'Make-up Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902405,
    OccDE: 'Visagist/in und Stylist/in',
    Occupation: 'Make-up Artist and Stylist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902403,
    OccDE: 'Make up Stylist/in',
    Occupation: 'Make-up Stylist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 838802,
    OccDE: 'Dressman',
    Occupation: 'Male Model',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 421106,
    OccDE: 'Mälzer/in',
    Occupation: 'Maltster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751808,
    OccDE: 'Managementassistent/in',
    Occupation: 'Management Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787327,
    OccDE: 'Wirtschaftskaufmann/-kauffrau (Gesundheits-, Sozialwesen)',
    Occupation: 'Management Assistant (Health Care, Social Services)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751804,
    OccDE: 'Direktionsassistent/in (nicht Hotel)',
    Occupation: 'Management Assistant (not Hotel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704533,
    OccDE: 'Wirtschaftskaufmann/-kauffrau (Wohnungswirtschaft)',
    Occupation: 'Management Assistant (Residential Housing Sector)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 701032,
    OccDE: 'Wirtschaftskaufmann/-kauffrau (Verkehrswesen)',
    Occupation: 'Management Assistant (Transportation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750224,
    OccDE: 'Vorstandsmitglied',
    Occupation: 'Management Board Member',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 751812,
    OccDE: 'Vorstandsassistent/in',
    Occupation: 'Management Board Member Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789107,
    OccDE: 'Vorstandssekretär/in',
    Occupation: 'Management Board Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 757006,
    OccDE: 'Managementberater/in',
    Occupation: 'Management Consultant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 608142,
    OccDE: 'Verwaltungsingenieur/in',
    Occupation: 'Management Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 789105,
    OccDE: 'Managementsekretär/in',
    Occupation: 'Management Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879516,
    OccDE: 'Managementtrainer/in',
    Occupation: 'Management Trainer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750304,
    OccDE: 'Manager',
    Occupation: 'Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011014',
    OccDE: 'Betriebsleiter/in (Landwirtschaft)',
    Occupation: 'Manager (Agriculture)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 911001,
    OccDE: 'Betriebsleiter/in (Hotel- und Gaststättengewerbe)',
    Occupation: 'Manager (Hotels and Restaurants)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914002,
    OccDE: 'Betriebswirt/in (Hotel- und Gaststättengewerbe)',
    Occupation: 'Manager (Hotels and Restaurants)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764021,
    OccDE: 'Geschäftsführer/in (öffentliche Verwaltung)',
    Occupation: 'Manager (Public Administration)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 704531,
    OccDE: 'Verwalter/in (Immobilien)',
    Occupation: 'Manager (real estate)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 776404,
    OccDE: 'Leiter/in EDV-Organisation',
    Occupation: 'Manager EDP Organisation',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 705212,
    OccDE: 'Manager für Künstler/innen, Berufssportler/innen u.ä.',
    Occupation: 'Manager for Artists, Professional Athletes, etc.',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600108,
    OccDE: 'Leiter/in der Entwicklung',
    Occupation: 'Manager in Development',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 676201,
    OccDE: 'Leiter/in einer Verkaufseinrichtung',
    Occupation: 'Manager of a Sales Outlet',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751803,
    OccDE: 'Betriebswirtschaftliche(r) Assistent/in (nicht Versicherungswesen)',
    Occupation: 'Managerial assistant (not Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756113,
    OccDE: 'Betriebswirtschaftliche(r) Organisator/in',
    Occupation: 'Managerial Organiser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287002,
    OccDE: 'Karosserie- und Fahrzeugbauermeister/in',
    Occupation: 'Managing Body and Automotive Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750210,
    OccDE: 'Geschäftsführer/in',
    Occupation: 'Managing Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '031302',
    OccDE: 'Geschäftsführer/in (bei Herdbuchgesellschaften usw.)',
    Occupation: 'Managing Director (Herdbook Businesses, etc.)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911005,
    OccDE: 'Geschäftsführende(r) Direktor/in (Hotel-, Gaststättengewerbe)',
    Occupation: 'Managing Director (Hotel, Restaurants)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911006,
    OccDE: 'Geschäftsführer/in (Hotel-, Gaststättengewerbe)',
    Occupation: 'Managing Director (Hotels, Restaurants)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695106,
    OccDE: 'Geschäftsführer/in (Versicherung)',
    Occupation: 'Managing Director (Insurance)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 763103,
    OccDE: 'Geschäftsführer/in (Partei, Verband)',
    Occupation: 'Managing Director (Party, Association)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 764019,
    OccDE: 'Geschäftsführende(r) Direktor/in (öffentliche Verwaltung)',
    Occupation: 'Managing Director (Public Administration)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 750226,
    OccDE: 'Geschäftsführer GmbH',
    Occupation: 'Managing Director of GmbH',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789104,
    OccDE: 'Geschäftsführungssekretär/in',
    Occupation: "Managing Director's Secretary",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287221,
    OccDE: 'Kraftfahrzeugklempnermeister/in',
    Occupation: 'Managing Motor Vehicle Panel Beater',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751011,
    OccDE: 'Leitende(r) Angestellte/r',
    Occupation: 'Managing Staff Member',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 361127,
    OccDE: 'Mangler/in (Textveredlung)',
    Occupation: 'Mangler (Textile Finishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902204,
    OccDE: 'Handpfleger/in',
    Occupation: 'Manicurist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902205,
    OccDE: 'Maniküre',
    Occupation: 'Manicurist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 352106,
    OccDE: 'Konfektionär/in (Textilnäher/in)',
    Occupation: 'Manufacturer (Textile Sewer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145315,
    OccDE: 'Konfektionierer/in (Gummiwerker/in)',
    Occupation: 'Manufacturing Worker (Rubber Worker)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642517,
    OccDE: 'Landkartentechniker/in (Kartograph/in)',
    Occupation: 'Maps Technician (Cartographer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101510,
    OccDE: 'Marmorpolierer/in',
    Occupation: 'Marble Polisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883132,
    OccDE: 'Meeresbiolog(e/in)',
    Occupation: 'Marine Biologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 521508,
    OccDE: 'Seegüterkontrolleur/in',
    Occupation: 'Maritime Freight Checker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721405,
    OccDE: 'Hafenlotse/-lotsin',
    Occupation: 'Maritime Pilot',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721407,
    OccDE: 'Lotse/Lotsin',
    Occupation: 'Maritime Pilot',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721416,
    OccDE: 'Seelotse/-lotsin',
    Occupation: 'Maritime Pilot',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701312,
    OccDE: 'Schiffahrtskaufmann/-kauffrau',
    Occupation: 'Maritime Shipping Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981161,
    OccDE: 'Student/in Seeverkehr',
    Occupation: 'Maritime Transport Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 887206,
    OccDE: 'Marktanalytiker/in',
    Occupation: 'Market Analyst',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 676303,
    OccDE: 'Marktleiter/in',
    Occupation: 'Market Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 755801,
    OccDE: 'Marktforschungsassistent/in',
    Occupation: 'Market Research Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 757102,
    OccDE: 'Marktforschungsberater/in',
    Occupation: 'Market Research Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 887208,
    OccDE: 'Marktforscher/in',
    Occupation: 'Market Researcher',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 661903,
    OccDE: 'Marktverkäufer/in (Nahrungs-, Genußmittel)',
    Occupation: 'Market Salesperson (Food, Semi-luxury Products)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 670199,
    OccDE: 'Markthändler/in',
    Occupation: 'Market Trader',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703007,
    OccDE: 'Werbesachbearbeiter/in',
    Occupation: 'Marketing Agent',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 755004,
    OccDE: 'Marketingassistent/in',
    Occupation: 'Marketing Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 703005,
    OccDE: 'Werbefachwirt/in',
    Occupation: 'Marketing Assistant Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 703008,
    OccDE: 'Werbewirt/in',
    Occupation: 'Marketing Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 703103,
    OccDE: 'Werbekaufmann/-kauffrau',
    Occupation: 'Marketing Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 755006,
    OccDE: 'Marketingkaufmann/-kauffrau',
    Occupation: 'Marketing Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 703004,
    OccDE: 'Werbefachmann/-fachfrau',
    Occupation: 'Marketing Clerk/Assistant Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 703216,
    OccDE: 'Werbeberater/in',
    Occupation: 'Marketing Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 757101,
    OccDE: 'Marketingberater/in',
    Occupation: 'Marketing Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 755201,
    OccDE: 'Marketing-Controller/in',
    Occupation: 'Marketing Controller',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 755007,
    OccDE: 'Marketingkoordinator/in',
    Occupation: 'Marketing Coordinator',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751310,
    OccDE: 'Marketingdirektor/in',
    Occupation: 'Marketing Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788999,
    OccDE: 'Marketingmitarbeiter/in',
    Occupation: 'Marketing Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703806,
    OccDE: 'Werbeleiter/in',
    Occupation: 'Marketing Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751312,
    OccDE: 'Marketingmanager',
    Occupation: 'Marketing Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703807,
    OccDE: 'Werbemanager/in',
    Occupation: 'Marketing Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751311,
    OccDE: 'Marketingleiter/in',
    Occupation: 'Marketing Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 887205,
    OccDE: 'Marketing Researcher',
    Occupation: 'Marketing Researcher',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 789205,
    OccDE: 'Marketingsekretär/in',
    Occupation: 'Marketing Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751309,
    OccDE: 'Marketing-Service-Manager',
    Occupation: 'Marketing Services Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 755005,
    OccDE: 'Marketingfachmann/-fachfrau',
    Occupation: 'Marketing Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 689199,
    OccDE: 'Werbemitarbeiter/in',
    Occupation: 'Marketing Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861108,
    OccDE: 'Eheberater/in',
    Occupation: 'Marriage Counsellor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101201,
    OccDE: 'Bausteinmetz/in',
    Occupation: 'Mason',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441002,
    OccDE: 'Betriebsmaurer',
    Occupation: 'Mason',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441909,
    OccDE: 'Maurer für Restaurierungsarbeiten',
    Occupation: 'Mason for Restoration Work',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101523,
    OccDE: 'Steinschleifer/in',
    Occupation: 'Masonry Grinder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101418,
    OccDE: 'Steinsäger/in',
    Occupation: 'Masonry Saw Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 852205,
    OccDE: 'Masseur/in',
    Occupation: 'Massage Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 852102,
    OccDE: 'Masseur/in und medizinische(r) Bademeister/in',
    Occupation: 'Massage Therapist and Hydrotherapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 993199,
    OccDE: 'Meister/in',
    Occupation: 'Master',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606199,
    OccDE: 'Diplom-Braumeister/in',
    Occupation: 'Master Brewer with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 813004,
    OccDE: 'Magister der Rechtsvergleichung',
    Occupation: 'Master of Comparative Law',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 705339,
    OccDE: 'Partnervermittler/in',
    Occupation: 'Matchmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 213412,
    OccDE: 'Materialzuschneider/in (Metall)',
    Occupation: 'Material Cutter (Metal)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741051,
    OccDE: 'Materialverwalter/in',
    Occupation: 'Materials Administrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626501,
    OccDE: 'Baustoff-Fachberater/in',
    Occupation: 'Materials Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741054,
    OccDE: 'Materialzuteiler/in',
    Occupation: 'Materials Distribution Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608406,
    OccDE: 'Werkstoffingenieur/in',
    Occupation: 'Materials Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 606515,
    OccDE: 'Werkstoffingenieur/in (Steine und Erden, Glas, Keramik)',
    Occupation: 'Materials Engineer (Stone and Earth, Glass, Ceramics)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 741725,
    OccDE: 'Materialbereitsteller/in',
    Occupation: 'Materials Issuer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741052,
    OccDE: 'Materialwirtschaftler/in',
    Occupation: 'Materials Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628230,
    OccDE: 'Materialplaner/in',
    Occupation: 'Materials Planner',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 677112,
    OccDE: 'Materialeinkäufer/in',
    Occupation: 'Materials Purchaser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981177,
    OccDE: 'Student/in Werkstoffwissenschaften, Werkstofftechnik',
    Occupation: 'Materials Science, Materials Engineering Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626515,
    OccDE: 'Werkstofftechniker/in',
    Occupation: 'Materials Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606516,
    OccDE: 'Werkstofftechnikingenieur/in (Steine und Erden, Glas, Keramik)',
    Occupation:
      'Materials Technology Engineer (Stone and Earth, Glass, Ceramics)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 632313,
    OccDE: 'Werkstoffprüfer/in',
    Occupation: 'Materials Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633407,
    OccDE: 'Stoffprüfer/in',
    Occupation: 'Materials Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633604,
    OccDE: 'Werkstoffprüfer/in (Baustoffe)',
    Occupation: 'Materials Tester (Building Materials)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633409,
    OccDE: 'Werkstoffprüfer/in (Chemie)',
    Occupation: 'Materials Tester (Chemistry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633405,
    OccDE:
      'Stoffprüfer/in (Chemie) (Glas-, Keramische Industrie sowie Steine, Erde)',
    Occupation:
      'Materials Tester (Chemistry) (Glass, Ceramics Sector and Stones, Earth)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 632312,
    OccDE: 'Werkstoffprüfer/in (Metall)',
    Occupation: 'Materials Tester (Metal)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 632304,
    OccDE: 'Materialprüfer/in (Physik)',
    Occupation: 'Materials Tester (Physics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626514,
    OccDE: 'Werkstoffprüftechniker/in',
    Occupation: 'Materials Testing Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774301,
    OccDE: 'Mathematisch-technische(r) Assistent/in',
    Occupation: 'Mathematical-Technologist Assistant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 612304,
    OccDE: 'Mathematiker/in',
    Occupation: 'Mathematician',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 612399,
    OccDE: 'Diplom-Mathematiker/in',
    Occupation: 'Mathematician with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 981143,
    OccDE: 'Student/in Mathematik',
    Occupation: 'Mathematics Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863202,
    OccDE: 'Herbergsmutter',
    Occupation: 'Matron',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 761304,
    OccDE: 'Bürgermeister/in',
    Occupation: 'Mayor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 622404,
    OccDE: 'Meß- und Regeltechniker/in',
    Occupation: 'Measurement and Control Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 316506,
    OccDE: 'Meß- und Regelmechaniker/in',
    Occupation: 'Measurement and Control Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316507,
    OccDE: 'Meß- und Regelungsmonteur/in',
    Occupation: 'Measurement and Control Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622403,
    OccDE: 'Meß- und Prüftechniker/in',
    Occupation: 'Measurement and Test Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 632911,
    OccDE: 'Meßprüfer/in',
    Occupation: 'Measurement checker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300207,
    OccDE: 'Meßgerätemechaniker/in',
    Occupation: 'Measurement Instrument Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316344,
    OccDE: 'Meßgeräteelektroniker/in',
    Occupation: 'Measurement Instrumentation Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621409,
    OccDE: 'Meßtechniker/in',
    Occupation: 'Measuring Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661301,
    OccDE: 'Fleisch- und Wurstwarenverkäufer/in',
    Occupation: 'Meat and Sausage Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 805202,
    OccDE: 'Fleischbeschauer/in',
    Occupation: 'Meat Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 805203,
    OccDE: 'Fleischkontrolleur/in',
    Occupation: 'Meat Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401312,
    OccDE: 'Fleischwarenhersteller/in',
    Occupation: 'Meat Product Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401722,
    OccDE: 'Fleischwarenarbeiter/in',
    Occupation: 'Meat Product Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401002,
    OccDE: 'Facharbeiter/in für Fleischerzeugnisse',
    Occupation: 'Meat Products Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661303,
    OccDE: 'Fleischfachverkäufer/in',
    Occupation: 'Meat Sales Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661304,
    OccDE: 'Fleischverkäufer/in',
    Occupation: 'Meat Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627103,
    OccDE: 'Fleischtechniker/in',
    Occupation: 'Meat Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401719,
    OccDE: 'Fleischarbeiter/in',
    Occupation: 'Meat Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273022,
    OccDE: 'Maschinenmechaniker/in',
    Occupation: 'Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 270003,
    OccDE: 'Mechaniker/in',
    Occupation: 'Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316018,
    OccDE: 'Mechaniker/in für elektronische Bauelemente',
    Occupation: 'Mechanic for Electronic Components',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 255301,
    OccDE: 'Kranbauschlosser/in',
    Occupation: 'Mechanical Crane Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 641201,
    OccDE: 'Maschinenbauzeichner/in',
    Occupation: 'Mechanical Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601002,
    OccDE: 'Ingenieur/in für Mechanik',
    Occupation: 'Mechanical Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601004,
    OccDE: 'Maschinenbauingenieur/in',
    Occupation: 'Mechanical Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601006,
    OccDE: 'Maschineningenieur/in',
    Occupation: 'Mechanical Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 273014,
    OccDE: 'Maschinenbauer/in',
    Occupation: 'Mechanical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601110,
    OccDE: 'Maschineningenieur/in (Fahrzeugtechnik)',
    Occupation: 'Mechanical Engineer (Automotive Technology)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601110,
    OccDE: 'Maschineningenieur/in (Fahrzeugtechnik)',
    Occupation: 'Mechanical Engineer (Automotive Technology)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601111,
    OccDE: 'Maschineningenieur/in (Kraftfahrzeugtechnik)',
    Occupation: 'Mechanical Engineer (Automotive technology)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601525,
    OccDE: 'Maschineningenieur/in (Energieanlagentechnik)',
    Occupation: 'Mechanical Engineer (Energy Systems Technology)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601926,
    OccDE: 'Maschineningenieur/in (Werkzeugmaschinenbau)',
    Occupation: 'Mechanical Engineer (Machine Tool Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601920,
    OccDE: 'Maschineningenieur/in (Kraft- und Arbeitsmaschinenbau)',
    Occupation: 'Mechanical Engineer (Mechanical Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601111,
    OccDE: 'Maschineningenieur/in (Kraftfahrzeugtechnik)',
    Occupation: 'Mechanical Engineer (Motor Vehicle Technology)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601005,
    OccDE: 'Maschineningenieur/in (allgemeiner Maschinenbau)',
    Occupation: 'Mechanical Engineering (general mechanical engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 621901,
    OccDE: 'Anlagenbautechniker/in',
    Occupation: 'Mechanical Engineering and Construction Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981142,
    OccDE: 'Student/in Maschinenbau',
    Occupation: 'Mechanical Engineering Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273017,
    OccDE: 'Maschinenbaumechaniker/in',
    Occupation: 'Mechanical Engineering Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621006,
    OccDE: 'Maschinentechniker/in',
    Occupation: 'Mechanical Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 300020,
    OccDE: 'Mechanikmacher/in (Geräte- und Feinwerktechnik)',
    Occupation: 'Mechanics Engineer (Instrument and Precision Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981144,
    OccDE: 'Student/in Mechatronik',
    Occupation: 'Mechatronics Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316099,
    OccDE: 'Mechatroniker/in',
    Occupation: 'Mechatronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703406,
    OccDE: 'Media-Sachbearbeiter/in',
    Occupation: 'Media Agent',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 703409,
    OccDE: 'Medienassistent/in',
    Occupation: 'Media Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 834323,
    OccDE: 'Medienassistent/in (Design)',
    Occupation: 'Media Assistant (Design)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703408,
    OccDE: 'Medienassistent/in (Werbung)',
    Occupation: 'Media Assistant (Marketing)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 835418,
    OccDE: 'Medienassisten/in (Technik)',
    Occupation: 'Media Assistant (Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703410,
    OccDE: 'Medienberater/in (Werbung)',
    Occupation: 'Media Consultant (Marketing)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 821904,
    OccDE: 'Medienberater/in (Publizistik)',
    Occupation: 'Media Consultant (Publicist)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 834398,
    OccDE: 'Mediengestalter',
    Occupation: 'Media Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172097,
    OccDE: 'Mediengestalter/in für Digital- und  Printmedien',
    Occupation: 'Media Designer for Digital and Print Media',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172098,
    OccDE:
      'Mediengestalter/in für Digital- und  Printmedien - FR Medientechnik',
    Occupation:
      'Media Designer for Digital and Print Media - sp. in Media Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703497,
    OccDE:
      'Mediengestalter/in für Digital- und  Printmedien - Fachr. Medienberatung',
    Occupation:
      'Media Designer for Digital and Print Media - spec. Media Consulting',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 172099,
    OccDE:
      'Mediengestalter/in für Digital- und  Printmedien - Fachr. Medienoperating',
    Occupation:
      'Media Designer for Digital and Print Media - spec. Media Operating',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 171099,
    OccDE:
      'Mediengestalter/in für Digital- und  Printmedien - Fachricht. Mediendesign',
    Occupation:
      'Media Designer for Digital and Print Media specialising in Media Design',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835499,
    OccDE: 'Mediengestalter/in Bild und Ton',
    Occupation: 'Media Designer Picture and Sound',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835307,
    OccDE: 'Medieningenieur/in',
    Occupation: 'Media Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 835399,
    OccDE: 'Diplom-Ingenieur/in Medienbetriebstechnik',
    Occupation: 'Media Management Engineer with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 703412,
    OccDE: 'Medienreferent/in',
    Occupation: 'Media Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 885902,
    OccDE: 'Medienpädagog(e/in)',
    Occupation: 'Media Pedagogue',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703405,
    OccDE: 'Media-Planer/in',
    Occupation: 'Media Planer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 703407,
    OccDE: 'Mediadisponent/in',
    Occupation: 'Media Planner',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 882604,
    OccDE: 'Medienforscher/in',
    Occupation: 'Media Researcher',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 882605,
    OccDE: 'Medienwirt/in',
    Occupation: 'Media Sciences Scholar',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 703404,
    OccDE: 'Media-Fachkraft',
    Occupation: 'Media Skilled Worker',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 981145,
    OccDE: 'Student/in Medien',
    Occupation: 'Media Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835419,
    OccDE: 'Medientechniker/in',
    Occupation: 'Media Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835398,
    OccDE: 'Diplom-Ingenieur/in Medientechnik',
    Occupation: 'Media Technology Engineer with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 801157,
    OccDE: 'Sanitätssoldat',
    Occupation: 'Medic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857124,
    OccDE: 'Medizinisch-klinische(r) Laborant/in',
    Occupation: 'Medical and Clinical Laboratory Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857005,
    OccDE: 'Medizinische(r) Assistent/in',
    Occupation: 'Medical Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902304,
    OccDE: 'Medizinische(r) Fußpfleger/in',
    Occupation: 'Medical Chiropodist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673602,
    OccDE: 'Medizinkaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Medical Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671244,
    OccDE: 'Medizinkaufmann/-kauffrau (Großhandel)',
    Occupation: 'Medical Clerk (Wholesale)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 841037,
    OccDE: 'Medizinische(r) Berater/in (Arzt/Ärztin)',
    Occupation: 'Medical Consultant (Doctor)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 316119,
    OccDE: 'Medizingeräteelektromechaniker/in',
    Occupation: 'Medical Device Electromechanical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316120,
    OccDE: 'Medizingerätetechniker/in (Elektromechaniker/in)',
    Occupation: 'Medical Device Technician (Electromechanical Engineer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 823413,
    OccDE: 'Medizinische(r) Dokumentationsassistent/in',
    Occupation: 'Medical Documentation Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 841096,
    OccDE: 'Medizinische(r) Gutachter/in',
    Occupation: 'Medical Examiner',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 779401,
    OccDE: 'Medizinische(r) Informatiker/in',
    Occupation: 'Medical Information Technologist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 857130,
    OccDE: 'Medizinische(r) Laborant/in',
    Occupation: 'Medical Laboratory Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854205,
    OccDE: 'Krankenträger/in',
    Occupation: 'Medical Orderly',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874901,
    OccDE: 'Diplommedizinpädagog(e/in)',
    Occupation: 'Medical Pedagogue with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 612916,
    OccDE: 'Medizin-Physiker/in',
    Occupation: 'Medical Physicist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 856199,
    OccDE: 'Medizinische/r Fachangestellte/r',
    Occupation: 'Medical Specialist Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673603,
    OccDE: 'Sanitärkaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Medical Supply Clerk Retail',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857001,
    OccDE: 'Medizinisch-technische(r) Assistent/in',
    Occupation: 'Medical Technical Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857003,
    OccDE: 'Medizinisch-technische(r) Gehilf(e/in)',
    Occupation: 'Medical Technical Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857125,
    OccDE: 'Medizinisch-technische(r) Assistent für Funktionsdiagnostik',
    Occupation: 'Medical Technical Assistant in Functional Diagnostics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857002,
    OccDE: 'Medizinisch-technische(r) Fachassistent/in',
    Occupation: 'Medical Technical Assistant Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857126,
    OccDE: 'Medizinisch-technische(r) Laborassistent/in',
    Occupation: 'Medical Technical Laboratory Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857127,
    OccDE: 'Medizinisch-technische(r) Laboratoriumsassistent/in',
    Occupation: 'Medical Technical Laboratory Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857205,
    OccDE:
      'Medizinisch-technische(r) Laboratoriumsassistent/in für Nuklearmedizin',
    Occupation: 'Medical Technical Laboratory Assistant for Nuclear Medicine',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857207,
    OccDE: 'Medizinisch-technische(r) Radiologieassistent/in',
    Occupation: 'Medical Technical Radiology Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857208,
    OccDE:
      'Medizinisch-technische(r) Radiologieassistent/in für Nuklearmedizin',
    Occupation: 'Medical Technical Radiology Assistant for Nuclear Medicine',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857209,
    OccDE: 'Medizinisch-technische(r) Röntgenassistent/in',
    Occupation: 'Medical Technical X-Ray Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629310,
    OccDE: 'Medizintechniker/in',
    Occupation: 'Medical Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673601,
    OccDE: 'Medizinisch-technische(r) Einzelhandelskaufmann/-kauffrau',
    Occupation: 'Medical Technology Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856404,
    OccDE: 'Arztschreibkraft',
    Occupation: 'Medical Transcriptionist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981188,
    OccDE: 'Student/in Humanmedizin, Gesundheitswesen',
    Occupation: 'Medicine, Healthcare Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191433,
    OccDE: 'Verschmelzer/in',
    Occupation: 'Melter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 351206,
    OccDE: 'Herrenschneider/in',
    Occupation: 'Menswear Tailor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 864303,
    OccDE: 'Praxisanleiter/in (Altenpflege)',
    Occupation: 'Mentor (Geriatric Care)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801186,
    OccDE: 'Söldner/in',
    Occupation: 'Mercenary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703209,
    OccDE: 'Merchandiser',
    Occupation: 'Merchandiser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 349510,
    OccDE: 'Netzmacher/in',
    Occupation: 'Mesh Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 245902,
    OccDE: 'Metallkleber/in',
    Occupation: 'Metal Adhesive worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323704,
    OccDE: 'Metallhilfsarbeiter/in',
    Occupation: 'Metal Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 212546,
    OccDE: 'Metallkabelverarbeiter/in',
    Occupation: 'Metal Cable Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785206,
    OccDE: 'Metallkaufmann/-kauffrau',
    Occupation: 'Metal Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 623205,
    OccDE: 'Metallbautechniker/in',
    Occupation: 'Metal Construction Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 213415,
    OccDE: 'Metallschneider/in',
    Occupation: 'Metal Cutter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 256004,
    OccDE: 'Metallbauer/in (Fachrichtung Metallgestaltung)',
    Occupation: 'Metal Engineer (specialising in Metal Design)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642304,
    OccDE: 'Metallbauzeichner/in',
    Occupation: 'Metal Engineering Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 213916,
    OccDE: 'Metallverformer/in',
    Occupation: 'Metal Former',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 225102,
    OccDE: 'Metallschleifer/in und -polierer/in',
    Occupation: 'Metal Grinding and Polishing Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 225010,
    OccDE: 'Metallschleifer/in',
    Occupation: 'Metal Grinding Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 233103,
    OccDE: 'Metallhärter/in',
    Occupation: 'Metal Hardener',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323108,
    OccDE: 'Metallmaschinenbediener/in',
    Occupation: 'Metal Machinery Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 222018,
    OccDE: 'Metallfräser/in',
    Occupation: 'Metal Milling Cutter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201618,
    OccDE: 'Metallformer und Metallgießer',
    Occupation: 'Metal Mould Maker and Foundry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 231004,
    OccDE: 'Metallpolierer/in',
    Occupation: 'Metal Polisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 231702,
    OccDE: 'Metallpoliererhelfer/in',
    Occupation: 'Metal Polishing Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323904,
    OccDE: 'Metallfeinbearbeiter/in (an Metallbearbeitungsmaschinen)',
    Occupation:
      'Metal Precision Processing Worker (with Metalworking Machinery)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 213108,
    OccDE: 'Metalldrücker/in',
    Occupation: 'Metal Presser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323903,
    OccDE: 'Metallbearbeiter/in (an Metallbearbeitungsmaschinen)',
    Occupation: 'Metal Processing Worker (with Metalworking Machinery)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191011,
    OccDE: 'Metallerzeuger/in',
    Occupation: 'Metal Production Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 229205,
    OccDE: 'Metallsäger/in',
    Occupation: 'Metal Saw Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191418,
    OccDE: 'Metallschmelzer/in',
    Occupation: 'Metal Smelter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '072107',
    OccDE: 'Metallfacharbeiter (unter Tage)',
    Occupation: 'Metal Specialist (underground)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 234217,
    OccDE: 'Metallfärber/in',
    Occupation: 'Metal Stainer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 234303,
    OccDE: 'Metallbeizer/in',
    Occupation: 'Metal Stainer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 235002,
    OccDE: 'Metalloberflächenbeschichter/in',
    Occupation: 'Metal Surface Coater',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 235003,
    OccDE: 'Metalloberflächenveredler/in',
    Occupation: 'Metal Surface Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 231911,
    OccDE: 'Metalloberflächenbearbeiter/in',
    Occupation: 'Metal Surface Polisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 233199,
    OccDE: 'Metallfacharbeiter/in',
    Occupation: 'Metal Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 221105,
    OccDE: 'Metalldreher/in',
    Occupation: 'Metal Turner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241913,
    OccDE: 'Metallschweißer/in',
    Occupation: 'Metal Welder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323902,
    OccDE: 'Metallarbeiter/in',
    Occupation: 'Metal Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287305,
    OccDE: 'Metallbauer/in (Fachrichtung Fahrzeugbau)',
    Occupation: 'Metal Worker (specialising in Automotive Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 632306,
    OccDE: 'Metallograph/in',
    Occupation: 'Metallographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 605501,
    OccDE: 'Eisenhütteningenieur/in',
    Occupation: 'Metallurgical Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 605809,
    OccDE: 'Metallurg(e/in)',
    Occupation: 'Metallurgist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191014,
    OccDE: 'Metallurg(e/in) (Facharbeiter/in)',
    Occupation: 'Metallurgist (Technician)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981129,
    OccDE: 'Student/in Hüttenwesen, Gießereitechnik',
    Occupation: 'Metallurgy, Foundry Technology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626507,
    OccDE: 'Metallprüftechniker/in',
    Occupation: 'Metals Testing Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 256005,
    OccDE: 'Schmied/in',
    Occupation: 'Metalsmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300099,
    OccDE: 'Metallbildner/in',
    Occupation: 'Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254002,
    OccDE: 'Metallbauer/in',
    Occupation: 'Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254007,
    OccDE: 'Schlosser/in',
    Occupation: 'Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254009,
    OccDE: 'Schlosserwerker/in',
    Occupation: 'Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191005,
    OccDE: 'Hüttenarbeiter/in',
    Occupation: 'Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191009,
    OccDE: 'Hüttenwerker/in',
    Occupation: 'Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323107,
    OccDE: 'Metallfachwerker/in',
    Occupation: 'Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323109,
    OccDE: 'Metallwerker/in',
    Occupation: 'Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254301,
    OccDE: 'Metallbauer/in (Fachrichtung Konstruktionstechnik)',
    Occupation: 'Metalworker (specialising in Construction Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254205,
    OccDE: 'Metallbauer/in (Fachrichtung Anlagen- und Fördertechnik)',
    Occupation:
      'Metalworker (specialising in Equipment and Conveyance Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254705,
    OccDE: 'Schlosserhelfer/in',
    Occupation: 'Metalworking Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254006,
    OccDE: 'Schlosser-Fachwerker/in',
    Occupation: 'Metalworking Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254003,
    OccDE: 'Metallbauschlosser/in',
    Occupation: 'Metalworking Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191007,
    OccDE: 'Hüttenfacharbeiter/in',
    Occupation: 'Metalworking Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191023,
    OccDE: 'Hüttenwerkschlosser/in',
    Occupation: 'Metalworking Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883606,
    OccDE: 'Meteorolog(e/in)',
    Occupation: 'Meteorologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 310909,
    OccDE: 'Zählermonteur/in',
    Occupation: 'Metre Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 706401,
    OccDE: 'Ableser/in (Gas, Strom, Wasser)',
    Occupation: 'Metre Reader (Gas, Electricity, Water)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 711304,
    OccDE: 'Stadtbahnwagenführer/in (S-Bahn)',
    Occupation: 'Metropolitan Railway Driver (Trams)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883133,
    OccDE: 'Mikrobiolog(e/in)',
    Occupation: 'Microbiologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 316904,
    OccDE: 'Mikroelektroniker/in',
    Occupation: 'Microelectronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602309,
    OccDE: 'Mikroelektroniker/in (Elektronikingenieur/in)',
    Occupation: 'Microelectronics Technician (Electrical Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 622317,
    OccDE: 'Mikroelektroniker/in (Elektroniktechniker/in)',
    Occupation: 'Microelectronics Technician (Electronics Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 316299,
    OccDE: 'Mikrotechnolog(e/in)',
    Occupation: 'Microtechnologist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853613,
    OccDE: 'Hebamme',
    Occupation: 'Midwife',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853614,
    OccDE: 'Hebamme und Krankenschwester',
    Occupation: 'Midwife and Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801185,
    OccDE: 'Militärattache/eurin',
    Occupation: 'Military Attaché',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831436,
    OccDE: 'Militärmusiker',
    Occupation: 'Military Musician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801172,
    OccDE: 'Feldjägertruppe (Heer)',
    Occupation: 'Military Police Officer (Army)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714419,
    OccDE: 'Milchfahrer/in (nicht Milchverkaufsfahrer/in)',
    Occupation: 'Milk Float Driver (not Milk Sales Driver)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 431113,
    OccDE: 'Milchverarbeiter/in',
    Occupation: 'Milk Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 631617,
    OccDE: 'Milchleistungsprüfer/in',
    Occupation: 'Milk Production Evaluator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 431002,
    OccDE: 'Facharbeiter/in für Milchwirtschaft',
    Occupation: 'Milk Products Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 431320,
    OccDE: 'Molker/in',
    Occupation: 'Milker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023120',
    OccDE: 'Melker/in',
    Occupation: 'Milker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 435130,
    OccDE: 'Müller/in',
    Occupation: 'Miller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 435129,
    OccDE: 'Mühlenfacharbeiter/in',
    Occupation: 'Miller Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 229112,
    OccDE: 'Hobler/in',
    Occupation: 'Milling Cutter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 222011,
    OccDE: 'Fräser/in',
    Occupation: 'Milling Cutter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 173007,
    OccDE: 'Fräser/in (Druckformherstellung)',
    Occupation: 'Milling Cutter (Platemaking)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 222010,
    OccDE: 'Fräser/in (Metallbearbeitung)',
    Occupation: 'Milling Cutter Operator (Metalworking)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627110,
    OccDE: 'Müllereitechniker/in',
    Occupation: 'Milling Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 295232,
    OccDE: 'Schneidwerkzeugmechaniker/in',
    Occupation: 'Milling Tool Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621912,
    OccDE: 'Mühlenbautechniker/in',
    Occupation: 'Millwrighting Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 838606,
    OccDE: 'Pantomimist/in',
    Occupation: 'Mime',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '070003',
    OccDE: 'Bergmann',
    Occupation: 'Miner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466102,
    OccDE: 'Mineur',
    Occupation: 'Miner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '070201',
    OccDE: 'Berg- und Maschinenmann',
    Occupation: 'Miner and Mining Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '071403',
    OccDE: 'Berg- und Maschinenmann (Fachrichtung Vortrieb und Gewinnung)',
    Occupation:
      'Miner and Mining Machine Operator (specialising in Propulsion and Excavation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671247,
    OccDE: 'Mineralölkaufmann/-kauffrau',
    Occupation: 'Mineral Oil Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662908,
    OccDE: 'Mineralölverkäufer/in',
    Occupation: 'Mineral Oil Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '080651',
    OccDE: 'Mineralaufbereiter',
    Occupation: 'Mineral Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883516,
    OccDE: 'Mineralog(e/in)',
    Occupation: 'Mineralogist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 605002,
    OccDE: 'Bergbauingenieur/in',
    Occupation: 'Mining Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 605003,
    OccDE: 'Bergingenieur/in',
    Occupation: 'Mining Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 625001,
    OccDE: 'Bergbautechniker/in',
    Occupation: 'Mining Engineering Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '070102',
    OccDE: 'Bergmechaniker',
    Occupation: 'Mining Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981110,
    OccDE: 'Student/in Bergbau',
    Occupation: 'Mining Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 624607,
    OccDE: 'Bergvermessungstechniker/in',
    Occupation: 'Mining Surveying Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 761205,
    OccDE: 'Minister/in',
    Occupation: 'Minister',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787016,
    OccDE: 'Ministerialbeamt(er/in)',
    Occupation: 'Ministerial Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764026,
    OccDE: 'Ministerialbeamt(er/in) (höherer Dienst)',
    Occupation: 'Ministerial Civil Servant (highest civil service)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787128,
    OccDE: 'Justizverwaltungssekretär/in',
    Occupation:
      'Ministry of Justice Administration Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787126,
    OccDE: 'Justizverwaltungshauptsekretär/in',
    Occupation:
      'Ministry of Justice Administration Senior Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 812118,
    OccDE: 'Justizverwaltungsoberinspektor/in',
    Occupation: 'Ministry of Justice Administration Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 784415,
    OccDE: 'Justizhauptwachtmeister/in',
    Occupation: 'Ministry of Justice Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 784414,
    OccDE: 'Justizaushelfer/in',
    Occupation: 'Ministry of Justice Administrative Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787112,
    OccDE: 'Justizassistent/in',
    Occupation: 'Ministry of Justice Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787113,
    OccDE: 'Justizbeamt(er/in)',
    Occupation: 'Ministry of Justice Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814216,
    OccDE: 'Justizbeamt(er/in) (Justizvollzugsdienst)',
    Occupation: 'Ministry of Justice Civil Servant (Correctional Service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 813003,
    OccDE: 'Justizreferendar/in',
    Occupation: 'Ministry of Justice Clerk',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787118,
    OccDE: 'Justizfachangestellte/r',
    Occupation: 'Ministry of Justice Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 812108,
    OccDE: 'Justiz(amtmann/amtfrau)',
    Occupation: 'Ministry of Justice Deputy Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787111,
    OccDE: 'Justizangestellte/r',
    Occupation: 'Ministry of Justice Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 812116,
    OccDE: 'Justizverwaltungsinspektor/in',
    Occupation: 'Ministry of Justice Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787121,
    OccDE: 'Justizobersekretär/in',
    Occupation: 'Ministry of Justice Higher Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787127,
    OccDE: 'Justizverwaltungsobersekretär/in',
    Occupation: 'Ministry of Justice Higher Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 812110,
    OccDE: 'Justizinspektor/in',
    Occupation: 'Ministry of Justice Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787116,
    OccDE: 'Justizbetriebsobersekretär/in',
    Occupation: 'Ministry of Justice Operations Senior Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787123,
    OccDE: 'Justizsekretär/in',
    Occupation: 'Ministry of Justice Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787120,
    OccDE: 'Justizhauptsekretär/in',
    Occupation: 'Ministry of Justice Senior Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 812112,
    OccDE: 'Justizoberinspektor/in',
    Occupation: 'Ministry of Justice Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787119,
    OccDE: 'Justizfachkraft',
    Occupation: 'Ministry of Justice Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 784417,
    OccDE: 'Justizwachtmeister/in',
    Occupation: 'Ministry of Justices Administrative Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 784416,
    OccDE: 'Justizoberwachtmeister/in',
    Occupation: 'Ministry of Justices Higher Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 894302,
    OccDE: 'Missionshelfer/in',
    Occupation: 'Mission Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 891404,
    OccDE: 'Missionar/in',
    Occupation: 'Missionary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997194,
    OccDE: 'Mischer/in',
    Occupation: 'Mixer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152104,
    OccDE: 'Mischer/in (Kunststoffverarbeitung)',
    Occupation: 'Mixer (Plastics Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461110,
    OccDE: 'Mischmeister (Straßenbau)',
    Occupation: 'Mixing Engineer (Road Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861315,
    OccDE: 'Körperbehindertenfürsorger/in',
    Occupation: 'Mobility Care Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 838803,
    OccDE: 'Fotomodell',
    Occupation: 'Model',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 502202,
    OccDE: 'Modellschreiner/in',
    Occupation: 'Model Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 502203,
    OccDE: 'Modelltischler/in',
    Occupation: 'Model Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 502199,
    OccDE: 'Modellbaumechaniker/in',
    Occupation: 'Model Construction Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627329,
    OccDE: 'Modellmacher/in',
    Occupation: 'Model Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 502102,
    OccDE: 'Modellbauer/in',
    Occupation: 'Model Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 502508,
    OccDE: 'Modellbauer/in (Fachrichtung Produktionsmodellbau)',
    Occupation: 'Model Maker (specialising in Production Models)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 502301,
    OccDE: 'Modellschlosser/in',
    Occupation: 'Model Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821603,
    OccDE: 'Moderator/in (Rundfunk, Fernsehen)',
    Occupation: 'Moderator (Radio, Television)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 354314,
    OccDE: 'Modist/in',
    Occupation: 'Modist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883134,
    OccDE: 'Molekularbiolog(e/in)',
    Occupation: 'Molecular Biologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 704208,
    OccDE: 'Geld- und Wertpapierhändler/in (Makler/in)',
    Occupation: 'Money and securities dealers (Broker)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 792097,
    OccDE: 'Beförderung von Geld- und Werttransporten',
    Occupation: 'Money and Valuable Transport Guard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608254,
    OccDE: 'Überwachungsingenieur/in',
    Occupation: 'Monitoring Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 483305,
    OccDE: 'Mosaikleger/in',
    Occupation: 'Mosaic Tiler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601927,
    OccDE: 'Motorenbauingenieur/in',
    Occupation: 'Motor Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 273107,
    OccDE: 'Motorenmechaniker/in',
    Occupation: 'Motor Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713514,
    OccDE: 'Kraftverkehrsmeister/in',
    Occupation: 'Motor Traffic Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621109,
    OccDE: 'Kraftfahrzeugbautechniker/in',
    Occupation: 'Motor Vehicle Construction Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714007,
    OccDE: 'Kraftfahrzeugführer/in',
    Occupation: 'Motor Vehicle Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714008,
    OccDE: 'Kraftwagenfahrer/in',
    Occupation: 'Motor Vehicle Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 318112,
    OccDE: 'Kraftfahrzeug-Elektromechaniker/in',
    Occupation: 'Motor Vehicle Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 318113,
    OccDE: 'Kraftfahrzeugelektriker/in',
    Occupation: 'Motor Vehicle Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 318116,
    OccDE: 'Kraftfahrzeugelektroniker/in',
    Occupation: 'Motor Vehicle Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281907,
    OccDE: 'Kraftfahrzeugmotorenmechaniker/in',
    Occupation: 'Motor Vehicle Engine Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608227,
    OccDE: 'Kraftfahrsachverständige/r',
    Occupation: 'Motor Vehicle Expert',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512309,
    OccDE: 'Kraftfahrzeuglackierer/in',
    Occupation: 'Motor Vehicle Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281903,
    OccDE: 'Fahrzeugprüfer/in (Kfz-Mechaniker/in)',
    Occupation: 'Motor Vehicle Inspector (Auto Mechanic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281096,
    OccDE: 'Kraftfahrzeugbauer/in',
    Occupation: 'Motor Vehicle Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281016,
    OccDE: 'Kraftfahrzeugmechaniker/in',
    Occupation: 'Motor Vehicle Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287220,
    OccDE: 'Kraftfahrzeugklempner/in',
    Occupation: 'Motor vehicle panel beater',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281015,
    OccDE: 'Kraftfahrzeuginstandsetzer/in',
    Occupation: 'Motor Vehicle Repair Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281010,
    OccDE: 'Fahrzeugschlosser/in',
    Occupation: 'Motor Vehicle Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621110,
    OccDE: 'Kraftfahrzeugtechniker/in',
    Occupation: 'Motor Vehicle Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281009,
    OccDE: 'Fahrzeugschlosser/in (Kraftfahrzeugmontage)',
    Occupation: 'Motor Vehicle Technician (Motor Vehicle Assembly)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281008,
    OccDE: 'Fahrzeugschlosser/in (Kraftfahrzeuge)',
    Occupation: 'Motor Vehicle Technician (Motor Vehicles)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281704,
    OccDE: 'Kraftfahrzeugschlosserhelfer/in',
    Occupation: 'Motor Vehicle Technician Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629202,
    OccDE: 'Kfz-Prüfer/in (TÜV)',
    Occupation: 'Motor Vehicle Tester (TÜV)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608229,
    OccDE: 'Kraftfahrzeugverkehrssachverständige/r',
    Occupation: 'Motor Vehicle Traffic Expert',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 313409,
    OccDE: 'Motorenwickler/in',
    Occupation: 'Motor Winder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273109,
    OccDE: 'Motorenschlosser/in',
    Occupation: 'Motor Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281029,
    OccDE: 'Pkw-Mechaniker/in',
    Occupation: 'Motorcar Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673508,
    OccDE: 'Motorradhändler/in (Einzelhandel)',
    Occupation: 'Motorcycle Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281305,
    OccDE: 'Motorradmechaniker/in',
    Occupation: 'Motorcycle Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 293105,
    OccDE: 'Formenbauermeister/in',
    Occupation: 'Mould Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201526,
    OccDE: 'Metallformer (Gießerei)',
    Occupation: 'Mould Maker (Foundry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 293104,
    OccDE: 'Formenbauer/in (Werkzeugmacher/in)',
    Occupation: 'Mould Maker (Toolmaker)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 293107,
    OccDE: 'Formenschlosser/in',
    Occupation: 'Mould Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152408,
    OccDE: 'Formteilpresser/in (Kunststoff)',
    Occupation: 'Moulding Press Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801167,
    OccDE: 'Gebirgsjäger/Bergrettungsdienst',
    Occupation: 'Mountain Rescue Service Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857006,
    OccDE: 'MTA',
    Occupation: 'MTA',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857136,
    OccDE: 'MTA (Labor)',
    Occupation: 'MTA (Laboratory)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857210,
    OccDE: 'MTA (Röntgen, Nuklearmedizin)',
    Occupation: 'MTA (X-Ray, Nuclear Medicine)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857137,
    OccDE: 'MTLA',
    Occupation: 'MTLA',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857211,
    OccDE: 'MTRA',
    Occupation: 'MTRA',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703496,
    OccDE: 'Multimediaspezialist/in',
    Occupation: 'Multimedia Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787645,
    OccDE: 'Verbandsgemeindesekretär/in',
    Occupation: 'Municipal Administration Unit Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787618,
    OccDE: 'Kommunalbeamt(er/in)',
    Occupation: 'Municipal Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765612,
    OccDE: 'Kommunalbeamt(er/in) (gehobener Dienst)',
    Occupation: 'Municipal Civil Servant (higher civil service)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787617,
    OccDE: 'Kommunalbeamt(er/in) (mittlerer Dienst)',
    Occupation: 'Municipal Civil Servant (mid-level service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689510,
    OccDE: 'Kommunalberater/in',
    Occupation: 'Municipal Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051108',
    OccDE: 'Gemeindegärtner/in',
    Occupation: 'Municipal Gardener',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 823510,
    OccDE: 'Museologe/in',
    Occupation: 'Museologist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 792407,
    OccDE: 'Museumswärter/in',
    Occupation: 'Museum Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 823514,
    OccDE: 'Museumsdirektor/in',
    Occupation: 'Museum Director',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823602,
    OccDE: 'Museumsangestellte/r',
    Occupation: 'Museum Employee',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 702303,
    OccDE: 'Museumsführer/in',
    Occupation: 'Museum Guide',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792406,
    OccDE: 'Museumsaufseher/in',
    Occupation: 'Museum Monitor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 885302,
    OccDE: 'Museumspädagog(e/in)',
    Occupation: 'Museum Pedagogue',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 674502,
    OccDE: 'Musikalienhändler/in',
    Occupation: 'Music Dealer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821407,
    OccDE: 'Musikredakteur/in',
    Occupation: 'Music Editor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875211,
    OccDE: 'Musikpädagog(e/in)',
    Occupation: 'Music Pedagogue',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875324,
    OccDE: 'Musikpädagog(e/in) für Musikschulen (instrumental)',
    Occupation: 'Music Pedagogue at Music Schools (Instrumental)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835222,
    OccDE: 'Musikproduzent/in',
    Occupation: 'Music Producer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683406,
    OccDE: 'Musikverleger/in',
    Occupation: 'Music Publisher',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 875216,
    OccDE: 'Musikschulleiter/in',
    Occupation: 'Music School Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875215,
    OccDE: 'Musikschullehrer/in',
    Occupation: 'Music School Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981190,
    OccDE: 'Student/in Musik',
    Occupation: 'Music Studies Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875209,
    OccDE: 'Musikerzieher/in',
    Occupation: 'Music Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875210,
    OccDE: 'Musiklehrer/in',
    Occupation: 'Music Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875403,
    OccDE: 'Musiklehrer/in (vokal)',
    Occupation: 'Music Teacher (Vocal)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875298,
    OccDE: 'Diplom-Musiklehrer/in',
    Occupation: 'Music Teacher with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 859505,
    OccDE: 'Musiktherapeut/in',
    Occupation: 'Music Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 859598,
    OccDE: 'Diplom-Musiktherapeut/in',
    Occupation: 'Music Therapist with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832904,
    OccDE: 'Musical-Darsteller/in',
    Occupation: 'Musical Actor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 305013,
    OccDE: 'Musikinstrumentenbauer/in',
    Occupation: 'Musical Instrument Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832521,
    OccDE: 'Musical-Sänger/in',
    Occupation: 'Musical Singer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831003,
    OccDE: 'Musiker/in',
    Occupation: 'Musician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831002,
    OccDE: 'Diplom-Musiker/in',
    Occupation: 'Musician with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882609,
    OccDE: 'Musikwissenschaftler/in',
    Occupation: 'Musicologist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 981146,
    OccDE: 'Student/in Musikwissenschaften',
    Occupation: 'Musicology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902207,
    OccDE: 'Nagelmodellist/in',
    Occupation: 'Nail Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902206,
    OccDE: 'Nagel-Kosmetiker/in',
    Occupation: 'Nail Cosmetician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902208,
    OccDE: 'Nagelstudio-Stylist/in',
    Occupation: 'Nail Studio Stylist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869401,
    OccDE: 'Tagesmutter',
    Occupation: 'Nanny',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 923802,
    OccDE: 'Kinderfrau',
    Occupation: 'Nanny',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695210,
    OccDE: 'Landesdirektor/in (Versicherung)',
    Occupation: 'National Manager (Insurance)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 883001,
    OccDE: 'Naturwissenschaftler/in',
    Occupation: 'Natural Scientist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 101513,
    OccDE: 'Natursteinschleifer/in',
    Occupation: 'Natural Stone Grinder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 483208,
    OccDE: 'Natursteinverleger/in',
    Occupation: 'Natural Stone Tiler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101002,
    OccDE: 'Natursteinbearbeiter/in',
    Occupation: 'Natural Stone Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981147,
    OccDE: 'Student/in Nautik',
    Occupation: 'Nautical Science Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601207,
    OccDE: 'Schiffbauingenieur/in',
    Occupation: 'Naval Architect',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 721205,
    OccDE: 'Nautiker/in',
    Occupation: 'Navigator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316905,
    OccDE: 'NC-Elektroniker/in',
    Occupation: 'NC Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549513,
    OccDE: 'NC-Maschinenbediener/in',
    Occupation: 'NC Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 222020,
    OccDE: 'NC-Fräser/in',
    Occupation: 'NC Milling Cutter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549514,
    OccDE: 'NC-Operator/in',
    Occupation: 'NC Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 607215,
    OccDE: 'NC-Programmierer/in (Ingenieur/in)',
    Occupation: 'NC Programmer (Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 628235,
    OccDE: 'NC-Programmierer/in (Techniker/in)',
    Occupation: 'NC Programmer (Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 221015,
    OccDE: 'NC-Dreher/in',
    Occupation: 'NC Turner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 212408,
    OccDE: 'Nadler/in (Drahtverformung, -verarbeitung)',
    Occupation: 'Needler (Wire Shaping and Working)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 839001,
    OccDE: 'Lichtreklamehersteller/in',
    Occupation: 'Neon Sign Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853213,
    OccDE: 'Säuglings- und Kinderkrankenschwester',
    Occupation: 'Neonatal and Paediatric Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841115,
    OccDE: 'Nephrolog(e/in)',
    Occupation: 'Nephrologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 778314,
    OccDE: 'Network Administrator',
    Occupation: 'Network Administrator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778397,
    OccDE: 'Netzwerkadministrator/in',
    Occupation: 'Network Administrator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 777111,
    OccDE: 'Netzwerkberater/in (EDV)',
    Occupation: 'Network Consultant (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778116,
    OccDE: 'Network Control Operator',
    Occupation: 'Network Control Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778318,
    OccDE: 'Netzwerkkoordinator/in (EDV)',
    Occupation: 'Network Coordinator (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 622907,
    OccDE: 'Netztechniker/in',
    Occupation: 'Network Engineer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 778199,
    OccDE: 'Netzwerkoperator/in',
    Occupation: 'Network operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 776114,
    OccDE: 'Netzwerkplaner/in (EDV)',
    Occupation: 'Network Planner (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 317110,
    OccDE: 'Netzwerkspezialist/in',
    Occupation: 'Network Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778316,
    OccDE: 'Netzbetreuer/in (EDV)',
    Occupation: 'Network Technician (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 841808,
    OccDE: 'Nervenarzt/-ärztin',
    Occupation: 'Neurologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841809,
    OccDE: 'Neurolog(e/in)',
    Occupation: 'Neurologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841212,
    OccDE: 'Neurochirurg/in',
    Occupation: 'Neurosurgeon',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 821604,
    OccDE: 'Nachrichtensprecher/in',
    Occupation: 'News Presenter',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 744137,
    OccDE: 'Zeitungszusteller/in',
    Occupation: 'Newspaper Delivery Person',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683411,
    OccDE: 'Zeitungsverleger/in',
    Occupation: 'Newspaper Publisher',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 683209,
    OccDE: 'Zeitungsverlagskaufmann/-kauffrau',
    Occupation: 'Newspaper Publishing Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689109,
    OccDE: 'Zeitungswerber/in',
    Occupation: 'Newspaper Subscription Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853030,
    OccDE: 'Nachtwachenpfleger (Krankenpflege)',
    Occupation: 'Night Nurse (Nursing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915608,
    OccDE: 'Nachtportier (Gaststätte, Hotel)',
    Occupation: 'Night Porter (Restaurant, Hotel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792006,
    OccDE: 'Nachtwächter/in',
    Occupation: 'Night Watchman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911497,
    OccDE: 'Diskothekenbesitzer/in',
    Occupation: 'Nightclub Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801165,
    OccDE: 'Unteroffizier',
    Occupation: 'Non-Commissioned Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 872124,
    OccDE: 'Studienassessor/in',
    Occupation: 'Non-Tenured Secondary School Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 786301,
    OccDE: 'Notargehilf(e/in)',
    Occupation: 'Notary Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 786305,
    OccDE: 'Notariatsgehilf(e/in)',
    Occupation: 'Notary Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 786303,
    OccDE: 'Notariatsassistent/in',
    Occupation: 'Notary Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 812205,
    OccDE: 'Notariatskandidat/in',
    Occupation: 'Notary Candidate',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 786302,
    OccDE: 'Notariatsangestellte/r',
    Occupation: 'Notary Employee',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 812204,
    OccDE: 'Notariatsinspektor/in',
    Occupation: 'Notary Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 813202,
    OccDE: 'Notar/in',
    Occupation: 'Notary Public',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 813203,
    OccDE: 'Notarassessor/in',
    Occupation:
      'Notary Public (prior to appointment for life in the civil service)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 812206,
    OccDE: 'Notarvertreter/in',
    Occupation: 'Notary Public Representative',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 812206,
    OccDE: 'Notarvertreter/in',
    Occupation: 'Notary Public Representative',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 786399,
    OccDE: 'Notarfachangestellte/r',
    Occupation: 'Notary Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 841605,
    OccDE: 'Nuklearmediziner/in',
    Occupation: 'Nuclear Medicine Practitioner',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 981134,
    OccDE: 'Student/in Kerntechnik',
    Occupation: 'Nuclear Technology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 894405,
    OccDE: 'Nonne',
    Occupation: 'Nun',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853018,
    OccDE: 'Krankenpfleger/in',
    Occupation: 'Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853021,
    OccDE: 'Krankenschwester',
    Occupation: 'Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869013,
    OccDE: 'Pflegeschwester',
    Occupation: 'Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854128,
    OccDE: 'Pfleger/in',
    Occupation: 'Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853036,
    OccDE: 'Pfleger (Krankenpfleger)',
    Occupation: 'Nurse (Nursing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853209,
    OccDE: 'Krankenpfleger und Kinderkrankenpfleger',
    Occupation: 'Nurse and Paediatric Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853210,
    OccDE: 'Krankenschwester und Kinderkrankenschwester',
    Occupation: 'Nurse and Paediatric Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853099,
    OccDE: 'Diplom-Pflegewirt/in',
    Occupation: 'Nurse with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853009,
    OccDE: 'Diplomkrankenschwester',
    Occupation: 'Nurse with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853010,
    OccDE: 'Diplompfleger (Krankenpflege)',
    Occupation: 'Nurse with Degree (Nursing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863115,
    OccDE: 'Kindertagesstättenleiter/in',
    Occupation: 'Nursery School Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051707',
    OccDE: 'Gärtnereiarbeiter/in',
    Occupation: 'Nursery Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854106,
    OccDE: 'Hilfspfleger/in',
    Occupation: 'Nursing Aid',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854105,
    OccDE: 'Helfer/in in der Krankenpflege',
    Occupation: 'Nursing Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854110,
    OccDE: 'Krankenpflegehelfer/in',
    Occupation: 'Nursing Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854116,
    OccDE: 'Pflegehelfer/in',
    Occupation: 'Nursing Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854120,
    OccDE: 'Schwesternhelfer/in',
    Occupation: 'Nursing Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861202,
    OccDE: 'Altenheimleiter/in',
    Occupation: 'Nursing Home Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853017,
    OccDE: 'Krankenpflegeleiter/in',
    Occupation: 'Nursing Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853035,
    OccDE: 'Pflegedienstleiter/in (Krankenpflege)',
    Occupation: 'Nursing Manager (Nursing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981148,
    OccDE: 'Student/in Pflegewissenschaften',
    Occupation: 'Nursing Sciences Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853013,
    OccDE: 'Facharbeiter/in für Krankenpflege',
    Occupation: 'Nursing Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853925,
    OccDE: 'Fachschwester',
    Occupation: 'Nursing Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 855204,
    OccDE: 'Ernährungsmedizinische(r) Berater/in',
    Occupation: 'Nutrition Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883802,
    OccDE: 'Ernährungswissenschaftler/in',
    Occupation: 'Nutritionist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 855203,
    OccDE: 'Ernährungsberater/in',
    Occupation: 'Nutritionist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831437,
    OccDE: 'Oboist/in',
    Occupation: 'Oboist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 868001,
    OccDE: 'Arbeits- und Berufsberater/in',
    Occupation: 'Occupational and Career Counsellor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803113,
    OccDE: 'Gewerbeaufsichtsbeamt(er/in)',
    Occupation: 'Occupational Health and Safety Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803109,
    OccDE: 'Gewerbeaufseher/in',
    Occupation: 'Occupational Health and Safety Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841905,
    OccDE: 'Arbeitsmediziner/in',
    Occupation: 'Occupational Health Physician',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 857801,
    OccDE: 'Arbeitsmedizinische(r) Assistent/in',
    Occupation: 'Occupational Medicine Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 885501,
    OccDE: 'Berufspädagog(e/in)',
    Occupation: 'Occupational Pedagogue',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861602,
    OccDE: 'Betriebliche(r) Sozialpädagoge(e/in)',
    Occupation: 'Occupational Social Education Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 862001,
    OccDE: 'Arbeitspädagog(e/in) für Behinderte',
    Occupation: 'Occupational Specialist for the Disabled',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 859401,
    OccDE: 'Arbeitstherapeut/in',
    Occupation: 'Occupational Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 859402,
    OccDE: 'Beschäftigungs- und Arbeitstherapeut/in',
    Occupation: 'Occupational Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 859403,
    OccDE: 'Beschäftigungstherapeut/in',
    Occupation: 'Occupational Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 884505,
    OccDE: 'Berufsbildungsreferent/in',
    Occupation: 'Occupational Training Consultant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 780502,
    OccDE: 'Büroverwalter/in',
    Occupation: 'Office Administrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780802,
    OccDE: 'Büro- und Verwaltungsfachpraktiker/in',
    Occupation: 'Office and Administrative Specialist Practitioner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785804,
    OccDE: 'Werkgehilf(e/in)',
    Occupation: 'Office Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 784003,
    OccDE: 'Bürohilfskraft',
    Occupation: 'Office Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780603,
    OccDE: 'Büroassistent/in',
    Occupation: 'Office Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 784002,
    OccDE: 'Bürohelfer/in',
    Occupation: 'Office Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789004,
    OccDE: 'Büroassistent/in (Stenosekretär/in)',
    Occupation: 'Office Assistant (Stenographer Secretary)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 784316,
    OccDE: 'Bürobot(e/in)',
    Occupation: 'Office Boy',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780301,
    OccDE: 'Bürokaufmann/Bürokauffrau',
    Occupation: 'Office Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780503,
    OccDE: 'Bürovorsteher/in',
    Occupation: 'Office Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780104,
    OccDE: 'Büroangestellte/r',
    Occupation: 'Office Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300404,
    OccDE: 'Büromaschinenmechaniker/in',
    Occupation: 'Office Equipment Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300406,
    OccDE: 'Büromaschinentechniker/in (Büromaschinenmechaniker/in)',
    Occupation: 'Office Equipment Technician (Office Equipment Mechanic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695109,
    OccDE: 'Innendienstleiter/in (Versicherung)',
    Occupation: 'Office Head (Insurance)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 780299,
    OccDE: 'Bürohilfe',
    Occupation: 'Office Help',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 317501,
    OccDE: 'Büroinformationselektroniker/in',
    Occupation: 'Office Information Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780501,
    OccDE: 'Büroleiter/in',
    Occupation: 'Office Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651006,
    OccDE: 'Betriebsstellenleiter/in (Werkmeister/in)',
    Occupation: 'Office Manager (Foreman)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695105,
    OccDE: 'Büroleiter/in (Versicherung)',
    Occupation: 'Office Manager (Insurance)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 756114,
    OccDE: 'Büroorganisator/in',
    Occupation: 'Office Organiser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780105,
    OccDE: 'Bürofachkraft',
    Occupation: 'Office Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673201,
    OccDE: 'Bürofachhändler/in',
    Occupation: 'Office Supply Specialist Dealer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780201,
    OccDE: 'Bürogehilf(e/in)',
    Occupation: 'Office Support Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780106,
    OccDE: 'Bürokraft',
    Occupation: 'Office Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801146,
    OccDE: 'Offizier (Bundeswehr)',
    Occupation: 'Officer (Armed Forces)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721219,
    OccDE: 'Schiffsoffizier/in (Nautische/r Schiffsoffizier/in)',
    Occupation: 'Officer (Chief Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721517,
    OccDE: 'Schiffsoffizier/in (Schiffsingenieur/in)',
    Occupation: 'Officer (Marine Engineer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721216,
    OccDE: 'Offizier/in (Schiffahrt)',
    Occupation: 'Officer (Maritime)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 753501,
    OccDE: 'Amtsprüfer/in (Finanzamt)',
    Occupation: 'Official Auditor (Revenue Office)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 801402,
    OccDE: 'Beamt(er/in) in der Bundespolizei',
    Occupation: 'Official in the German Federal Police',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765903,
    OccDE: 'Aufsichtsbeamt(er/in)',
    Occupation: 'Official Inspector',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 174220,
    OccDE: 'Offsetdrucker/in',
    Occupation: 'Offset Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174219,
    OccDE: 'Offsetandrucker/in (Flachdrucker/in)',
    Occupation: 'Offset Printer (Flat Printer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 173209,
    OccDE: 'Offsetmontierer/in',
    Occupation: 'Offset Printing Assembly Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821499,
    OccDE: 'Online-Radakteur/in',
    Occupation: 'Online Editor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622417,
    OccDE: 'Steuerungstechniker/in (Elektrotechnik)',
    Occupation: 'Open Loop Technician (Electrical Engineering)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: '070705',
    OccDE: 'Tagebauwerker/in',
    Occupation: 'Open Pit Miner (or Underground Site Worker?)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832524,
    OccDE: 'Opernsänger/in',
    Occupation: 'Opera Singer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602801,
    OccDE: 'Betriebsingenieur/in des Elektrofaches',
    Occupation: 'Operating Engineer Electricity',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 622304,
    OccDE: 'Betriebstechniker/in für Elektronik',
    Occupation: 'Operating Engineer for Electronics',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 778415,
    OccDE: 'Operating-Manager',
    Occupation: 'Operating Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 779201,
    OccDE: 'Betriebsinformatiker/in (EDV)',
    Occupation: 'Operational Computer Scientist (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 600601,
    OccDE: 'Betriebsingenieur/in',
    Occupation: 'Operational Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 607101,
    OccDE: 'Betriebsingenieur/in (Wirtschaftsingenieur/in)',
    Occupation: 'Operational Engineer (Industrial Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 269004,
    OccDE: 'Betriebsinstallateur/in',
    Occupation: 'Operational Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621601,
    OccDE: 'Betriebsmittelkonstrukteur/in',
    Occupation: 'Operational Resource Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 607204,
    OccDE: 'Betriebsmittelplaner/in',
    Occupation: 'Operational Resource Planner',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 607204,
    OccDE: 'Betriebsmittelplaner/in',
    Occupation: 'Operational Resource Planner',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778301,
    OccDE: 'Betriebssystemspezialist/in (EDV)',
    Occupation: 'Operational Systems Specialist (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 701608,
    OccDE: 'Operationsagent/in (Luftverkehr)',
    Occupation: 'Operations Agent (Aviation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620401,
    OccDE: 'Betriebsassistent/in',
    Occupation: 'Operations Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608208,
    OccDE: 'Betriebsrevisor/in',
    Occupation: 'Operations Auditor',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 785101,
    OccDE: 'Betriebskaufmann/-kauffrau',
    Occupation: 'Operations Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 756109,
    OccDE: 'Betriebskoordinator/in',
    Occupation: 'Operations Coordinator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 540009,
    OccDE: 'Betriebswart/in',
    Occupation: 'Operations Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608207,
    OccDE: 'Betriebsinspektor/in',
    Occupation: 'Operations Inspector',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 750203,
    OccDE: 'Betriebsleiter/in',
    Occupation: 'Operations Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756110,
    OccDE: 'Betriebsorganisator/in (nicht EDV)',
    Occupation: 'Operations Organiser (not EDP)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774411,
    OccDE: 'Operations Support Assistent/in',
    Occupation: 'Operations Support Assistant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 620402,
    OccDE: 'Betriebstechniker/in',
    Occupation: 'Operations Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 540010,
    OccDE: 'Betriebswerker/in',
    Occupation: 'Operations Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141019,
    OccDE: 'Operateur/in (chemische Technik)',
    Occupation: 'Operator (Chemical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316349,
    OccDE: 'Operator/in (Elektronikapparatebau)',
    Occupation: 'Operator (Electronic Device Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323905,
    OccDE: 'Operator/in (Metallverarbeitung)',
    Occupation: 'Operator (Metalworking)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841402,
    OccDE: 'Augenarzt/-ärztin',
    Occupation: 'Ophthalmologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 856106,
    OccDE: 'Augenarzthelfer/in',
    Occupation: 'Ophthalmologist Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300106,
    OccDE: 'Optikgerätemechaniker/in',
    Occupation: 'Optical Equipment Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981183,
    OccDE: 'Student/in optische Technologien',
    Occupation: 'Optical Technologies Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 304108,
    OccDE: 'Optiker/in',
    Occupation: 'Optician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135624,
    OccDE: 'Optikarbeiter/in',
    Occupation: 'Optician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 304101,
    OccDE: 'Augenoptiker/in',
    Occupation: 'Optometrist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 304102,
    OccDE: 'Augenoptikergehilf(e/in)',
    Occupation: 'Optometrist Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854114,
    OccDE: 'OP-Helfer/in',
    Occupation: 'OR Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853404,
    OccDE: 'OP-Pfleger',
    Occupation: 'OR Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853405,
    OccDE: 'OP-Schwester',
    Occupation: 'OR Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841211,
    OccDE: 'Mund-, Kiefer-, Gesichtschirurg/in',
    Occupation: 'Oral and Maxillofacial Surgeon',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 856203,
    OccDE: 'Prophylaxe-Helfer/in (Zahnarzthelfer/in)',
    Occupation: 'Oral Hygienist (Dental Assistant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841210,
    OccDE: 'Kieferchirurg/in',
    Occupation: 'Oral Surgeon',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '051606',
    OccDE: 'Obstbauer/in',
    Occupation: 'Orchardist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 794413,
    OccDE: 'Orchesterwart/in',
    Occupation: 'Orchestra Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831439,
    OccDE: 'Orchestermusiker/in',
    Occupation: 'Orchestra Musician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788102,
    OccDE: 'Auftragsbearbeiter/in',
    Occupation: 'Order Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788105,
    OccDE: 'Auftragssachbearbeiter/in',
    Occupation: 'Order Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788103,
    OccDE: 'Auftragskoodinierer/in',
    Occupation: 'Order Coordinator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621002,
    OccDE: 'Auftragsabwickler/in (Maschinenbautechniker/in)',
    Occupation: 'Order Processor (Mechanical Engineering Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 305209,
    OccDE: 'Orgel- und Harmoniumbauer/in',
    Occupation: 'Organ and Harmonium Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 305212,
    OccDE: 'Orgelbauer/in',
    Occupation: 'Organ Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756126,
    OccDE: 'Organisationsassistent/in',
    Occupation: 'Organisation Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756130,
    OccDE: 'Organisationskaufmann/-kauffrau',
    Occupation: 'Organisation Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 757301,
    OccDE: 'Organisationsberater/in (nicht Sozialarbeit)',
    Occupation: 'Organisation Consultant (not Social Work)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751107,
    OccDE: 'Organisationsleiter/in',
    Occupation: 'Organisation Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 756132,
    OccDE: 'Organisationsmitarbeiter/in',
    Occupation: 'Organisation Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751108,
    OccDE: 'Organisationsmanager',
    Occupation: 'Organisation Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 775110,
    OccDE: 'Organisationsprogrammierer/in',
    Occupation: 'Organisation Programmer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 756117,
    OccDE: 'Fachkaufmann/-kauffrau für Organisation',
    Occupation: 'Organisation Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756127,
    OccDE: 'Organisationsfachmann/-fachfrau',
    Occupation: 'Organisation Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756137,
    OccDE: 'Organisator/in',
    Occupation: 'Organiser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756136,
    OccDE: 'Organisator/in für Verwaltungsrationalisierung und Bürotechnik',
    Occupation: 'Organiser for Rationalisation and Office Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831440,
    OccDE: 'Organist/in',
    Occupation: 'Organist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 842103,
    OccDE: 'Kieferorthopäd(e/in)',
    Occupation: 'Orthodontist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 856202,
    OccDE: 'Kieferorthopädische(r) Helfer/in',
    Occupation: "Orthodontist's Assistant",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 372202,
    OccDE: 'Orthopädieschuhmacher/in',
    Occupation: 'Orthopaedic Shoemaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841213,
    OccDE: 'Orthopäd(e/in)',
    Occupation: 'Orthopaedist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 859202,
    OccDE: 'Orthoptist/in',
    Occupation: 'Orthoptist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 274901,
    OccDE: 'andere Instandhaltungsmechaniker/in',
    Occupation: 'Other Maintenance Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441201,
    OccDE: 'Außenwand- und Fassadenverkleider',
    Occupation: 'Outer Wall and Façade Coverer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521320,
    OccDE: 'Warenausgangskontrolleur/in',
    Occupation: 'Outgoing Goods Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441431,
    OccDE: 'Ofenmaurer',
    Occupation: 'Oven Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441440,
    OccDE: 'Ofenbauer/in',
    Occupation: 'Oven Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441430,
    OccDE: 'Ofenbauer (Ofenmaurer)',
    Occupation: 'Oven Builder (Oven Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 484213,
    OccDE: 'Ofensetzer/in (Ofenbauer/in)',
    Occupation: 'Oven Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441401,
    OccDE: 'Backofenbauer',
    Occupation: 'Oven Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 484209,
    OccDE: 'Ofenbauer/in (Kachelofenbauer/in)',
    Occupation: 'Oven Maker (Tiled Stove Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549215,
    OccDE: 'Ofenführer/in',
    Occupation: 'Oven Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311408,
    OccDE: 'Freileitungsmonteur/in',
    Occupation: 'Overhead Power Line Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750299,
    OccDE: 'Gesellschafter-Geschäftsführer',
    Occupation: 'Owner-Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522406,
    OccDE: 'Abpacker/in',
    Occupation: 'Packager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606907,
    OccDE: 'Verpackungsingenieur/in',
    Occupation: 'Packaging Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 162323,
    OccDE: 'Verpackungsmittelmaschinenführer/in',
    Occupation: 'Packaging Machinery Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 162204,
    OccDE: 'Verpackungsmittelmaschineneinrichter/in',
    Occupation: 'Packaging Machinery Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627909,
    OccDE: 'Verpackungsleiter/in (Verpackungstechniker/in)',
    Occupation: 'Packaging Manager (Packaging Technician)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 162002,
    OccDE: 'Verpackungsmittelhersteller/in',
    Occupation: 'Packaging Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 162003,
    OccDE: 'Verpackungsmittelmechaniker/in',
    Occupation: 'Packaging Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627910,
    OccDE: 'Verpackungsplaner/in',
    Occupation: 'Packaging Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627911,
    OccDE: 'Verpackungstechniker/in',
    Occupation: 'Packaging Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 162099,
    OccDE: 'Verpackungsarbeiter/in',
    Occupation: 'Packaging Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522918,
    OccDE: 'Konfektionierer/in (Warenaufmacher/in)',
    Occupation: 'Packaging Worker (Product Finisher)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522432,
    OccDE: 'Einpacker/in',
    Occupation: 'Packer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522463,
    OccDE: 'Packer/in',
    Occupation: 'Packer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522483,
    OccDE: 'Verpacker/in',
    Occupation: 'Packers',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853202,
    OccDE: 'Fachkinderkrankenschwester',
    Occupation: 'Paediatric Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853204,
    OccDE: 'Kinderkrankenpfleger',
    Occupation: 'Paediatric Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853205,
    OccDE: 'Kinderkrankenschwester',
    Occupation: 'Paediatric Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853206,
    OccDE: 'Kinderkrankenschwesterschülerin',
    Occupation: 'Paediatric Student Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841113,
    OccDE: 'Kinderarzt/-ärztin',
    Occupation: 'Paediatrician',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 856110,
    OccDE: 'Kinderarzthelfer/in',
    Occupation: "Paediatrician's Assistant",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 185321,
    OccDE: 'Pinselmacher/in',
    Occupation: 'Paint Brush maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633103,
    OccDE: 'Lacklaborant/in',
    Occupation: 'Paint Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 510904,
    OccDE: 'Spritzlackierer/in',
    Occupation: 'Paint Sprayer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512702,
    OccDE: 'Entlacker/in',
    Occupation: 'Paint Stripper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511011,
    OccDE: 'Maler/in',
    Occupation: 'Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 833213,
    OccDE: 'Kunstmaler/in',
    Occupation: 'Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511201,
    OccDE: 'Anstreicher/in (Maler/in)',
    Occupation: 'Painter (Painter)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511905,
    OccDE: 'Maler/in und Verputzer/in',
    Occupation: 'Painter and Plasterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 510101,
    OccDE: 'Maler/in und Lackierer/in',
    Occupation: 'Painter and Varnisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512012,
    OccDE: 'Maler/in und Lackierer/in (Waren)',
    Occupation: 'Painter and Varnisher (Goods)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512310,
    OccDE: 'Maler/in und Lackierer/in (Schwerpunkt Fahrzeuglackierer/in)',
    Occupation: 'Painter and Varnisher (specialising in Automotive Finishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511009,
    OccDE: 'Maler/in und Lackierer/in (Schwerpunkt Maler/in)',
    Occupation: 'Painter and Varnisher (specialising in Painting)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511301,
    OccDE: 'Maler/in und Tapetenkleber/in',
    Occupation: 'Painter and Wallpaperer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511302,
    OccDE: 'Maler/in und Tapezierer/in',
    Occupation: 'Painter and Wallpaperer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511702,
    OccDE: 'Malerhelfer/in',
    Occupation: 'Painter Helper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 510702,
    OccDE: 'Maler- und Lackiererhelfer/in',
    Occupation: 'Painting and Varnish Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511701,
    OccDE: 'Maler- und Lackiererhelfer/in (Ausbau)',
    Occupation: 'Painting and Varnish Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 510304,
    OccDE: 'Maler- und Lackiererfachwerker/in',
    Occupation: 'Painting and Varnish Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 510305,
    OccDE: 'Maler- und Lackiererwerker/in',
    Occupation: 'Painting and Varnish Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 510199,
    OccDE: 'Malergesell(e/in)',
    Occupation: 'Painting Journeyman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505406,
    OccDE: 'Palettenhersteller/in',
    Occupation: 'Pallet Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287214,
    OccDE: 'Karosserieflaschner/in',
    Occupation: 'Panel Beater',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 287215,
    OccDE: 'Karosserieklempner/in',
    Occupation: 'Panel Beater',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 164109,
    OccDE: 'Papierschneider/in',
    Occupation: 'Paper Cutter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606410,
    OccDE: 'Papieringenieur/in',
    Occupation: 'Paper Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 161413,
    OccDE: 'Papiermaschinengehilf(e/in)',
    Occupation: 'Paper Equipment Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 161412,
    OccDE: 'Papiermaschinenführer/in',
    Occupation: 'Paper Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 161003,
    OccDE: 'Papierhersteller/in',
    Occupation: 'Paper Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 164002,
    OccDE: 'Papierverarbeiter/in',
    Occupation: 'Paper Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 161006,
    OccDE: 'Papierverarbeiter/in (Papierherstellung)',
    Occupation: 'Paper Processor (Paper Manufacturing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 161002,
    OccDE: 'Papierfacharbeiter/in',
    Occupation: 'Paper Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627409,
    OccDE: 'Papiertechniker/in',
    Occupation: 'Paper Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 161720,
    OccDE: 'Papierarbeiter/in',
    Occupation: 'Paper Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744134,
    OccDE: 'Zeitungsausträger/in',
    Occupation: 'Paperboy',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 161005,
    OccDE: 'Papiermacher/in',
    Occupation: 'Papermaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651618,
    OccDE: 'Papiermachermeister/in',
    Occupation: 'Papermaking Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 786298,
    OccDE: 'Rechtsanwaltsfachangestellte/r',
    Occupation: 'Paralegal',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 854207,
    OccDE: 'Rettungsassistent/in',
    Occupation: 'Paramedic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854213,
    OccDE: 'Sanitäter/in',
    Occupation: 'Paramedic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732112,
    OccDE: 'Paketbesteller/in (Postverteiler/in)',
    Occupation: 'Parcel Carrier (Postal Distribution Agent)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744125,
    OccDE: 'Paketbesteller/in',
    Occupation: 'Parcel Delivery Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744126,
    OccDE: 'Paketbot(e/in)',
    Occupation: 'Parcel Delivery Person',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732113,
    OccDE: 'Paketbot(e/in) (Postverteiler/in)',
    Occupation: 'Parcel Delivery Person (Postal Distribution Agent)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732702,
    OccDE: 'Paketsortierer/in',
    Occupation: 'Parcel Sorter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997187,
    OccDE: 'Erziehungsurlaub/Karenz',
    Occupation: 'Parental Leave',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861110,
    OccDE: 'Erziehungsberater/in',
    Occupation: 'Parenting Counsellor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861112,
    OccDE: 'Erziehungsleiter/in',
    Occupation: 'Parenting Counsellor Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 891302,
    OccDE: 'Gemeindereferent/in',
    Occupation: 'Parish Assistant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 891304,
    OccDE: 'Pastoralassistent/in',
    Occupation: 'Parish Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 891306,
    OccDE: 'Pfarrdiakon/in',
    Occupation: 'Parish Deacon',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787808,
    OccDE: 'Pfarramtssekretär/in',
    Occupation: 'Parish Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 891305,
    OccDE: 'Pastoralreferent/in',
    Occupation: 'Parish Referendary',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787810,
    OccDE: 'Pfarrsekretär/in',
    Occupation: 'Parish Senior Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 891223,
    OccDE: 'Pfarrvikar/in',
    Occupation: 'Parish Vicar',
    'Feather RC': 'B',
  },
  {
    OccupationCode: '051110',
    OccDE: 'Grünanlagenpfleger/in',
    Occupation: 'Park Maintenance Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792207,
    OccDE: 'Parkplatzwächter/in',
    Occupation: 'Parking Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792121,
    OccDE: 'Parkanlagenwächter/in',
    Occupation: 'Parking Facilities Guard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792123,
    OccDE: 'Parkwächter/in',
    Occupation: 'Parking Guard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 782208,
    OccDE: 'Parlamentsstenograph/in',
    Occupation: 'Parliamentary Stenographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491507,
    OccDE: 'Parkettbodenleger/in',
    Occupation: 'Parquet Floor Layer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491510,
    OccDE: 'Parkettfußbodenleger/in',
    Occupation: 'Parquet Flooring Layer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491512,
    OccDE: 'Parkettleger/in',
    Occupation: 'Parquet Layer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491601,
    OccDE: 'Parkettlegermeister/in',
    Occupation: 'Parquet Laying Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 641804,
    OccDE: 'Teilzeichner/in',
    Occupation: 'Part Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997117,
    OccDE: 'Teilzeitkraft',
    Occupation: 'Part-Time Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411904,
    OccDE: 'Party(service)koch/-köchin',
    Occupation: 'Party (Service) Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744205,
    OccDE: 'Beifahrer/in',
    Occupation: 'Passenger',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702405,
    OccDE: 'Fahrgastbetreuer/in',
    Occupation: 'Passenger Services Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 891003,
    OccDE: 'Seelsorger/in',
    Occupation: 'Pastor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 891215,
    OccDE: 'Pastor/in',
    Occupation: 'Pastor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 891219,
    OccDE: 'Pfarrer/in',
    Occupation: 'Pastor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 392209,
    OccDE: 'Patissier',
    Occupation: 'Pastry Chef',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 813504,
    OccDE: 'Patentassessor/in',
    Occupation: 'Patent Assessor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 813503,
    OccDE: 'Patentanwalt/-anwältin',
    Occupation: 'Patent Attorney',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 786403,
    OccDE: 'Patentanwaltsgehilf(e/in)',
    Occupation: 'Patent Attorney Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 786404,
    OccDE: 'Patentanwaltssekretär/in',
    Occupation: 'Patent Attorney Secretary',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 813508,
    OccDE: 'Patentsachbearbeiter/in',
    Occupation: 'Patent Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 813506,
    OccDE: 'Patentingenieur/in',
    Occupation: 'Patent Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 813507,
    OccDE: 'Patentprüfer/in',
    Occupation: 'Patent Examiner',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 786499,
    OccDE: 'Patentanwaltsfachangestellte/r',
    Occupation: 'Patent Paralegal',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 841962,
    OccDE: 'Patholog(e/in)',
    Occupation: 'Pathologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 801474,
    OccDE: 'Streifenbeamt(er/in)',
    Occupation: 'Patrol Officers',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 153411,
    OccDE: 'Zuschneider/in (Kunststoff)',
    Occupation: 'Pattern Maker (Plastics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627333,
    OccDE: 'Schnittdirektrice',
    Occupation: 'Pattern Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627334,
    OccDE: 'Schnittechniker/in',
    Occupation: 'Pattern Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461518,
    OccDE: 'Pflasterleger',
    Occupation: 'Pavement Layer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461721,
    OccDE: 'Pflastererhelfer',
    Occupation: 'Pavement Laying Hand',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461516,
    OccDE: 'Pflasterer',
    Occupation: 'Paver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 772303,
    OccDE: 'Lohnbuchhalter/in',
    Occupation: 'Payroll accountant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 772304,
    OccDE: 'Personalbuchhalter/in',
    Occupation: 'Payroll Accountant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 788303,
    OccDE: 'Lohnsachbearbeiter/in',
    Occupation: 'Payroll Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 777112,
    OccDE: 'PC-Berater/in',
    Occupation: 'PC Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776117,
    OccDE: 'PC-Organisator/in',
    Occupation: 'PC Organiser',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 774108,
    OccDE: 'PC-Betreuer/in',
    Occupation: 'PC Service Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 622318,
    OccDE: 'PC-Techniker/in',
    Occupation: 'PC Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: '080764',
    OccDE: 'Torfarbeiter',
    Occupation: 'Peat Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 886304,
    OccDE: 'Pädagogische(r) Psycholog(e/in)',
    Occupation: 'Pedagogical Psychologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 873905,
    OccDE: 'Pädagogische(r) Mitarbeiter/in (Sonderschule)',
    Occupation: 'Pedagogical Staff (Special Education)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 885201,
    OccDE: 'Pädagog(e/in)',
    Occupation: 'Pedagogue',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861014,
    OccDE: 'Pädagog(e/in) (Sozialarbeit)',
    Occupation: 'Pedagogue (Social Work)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 885101,
    OccDE: 'Diplom-Pädagog(e/in)',
    Occupation: 'Pedagogue with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861002,
    OccDE: 'Diplom-Pädagog(e/in) (Sozialarbeit)',
    Occupation: 'Pedagogue with Degree (Social Work)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981157,
    OccDE: 'Student/in Pädagogik, Erziehungswissenschaften',
    Occupation: 'Pedagogy, Educational Sciences Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 813901,
    OccDE: 'Rentenberater/in (Rechtsberater/in)',
    Occupation: 'Pension Adviser (Legal Adviser)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 997197,
    OccDE: 'Pensionär/in',
    Occupation: 'Pensioner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997198,
    OccDE: 'Rentner/in',
    Occupation: 'Pensioner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051313',
    OccDE: 'Staudengärtner/in',
    Occupation: 'Perennial Gardener',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629308,
    OccDE: 'Kardiotechniker/in',
    Occupation: 'Perfusionist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201307,
    OccDE: 'Kokillengießer',
    Occupation: 'Permanent Mould Caster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606999,
    OccDE: 'Diplom-Oecotropholog(e/in)',
    Occupation: 'Person with Degree in Food and Home Economics',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 902004,
    OccDE: 'Körperpfleger/in',
    Occupation: 'Personal Groomer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879519,
    OccDE: 'Personaltrainer/in',
    Occupation: 'Personal Trainer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 805108,
    OccDE: 'Schädlingsbekämpfer/in',
    Occupation: 'Pest Control Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 686106,
    OccDE: 'Tankstellenverwalter/in',
    Occupation: 'Petrol Station Administrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 686005,
    OccDE: 'Tankstellenwart/in',
    Occupation: 'Petrol Station Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 936010,
    OccDE: 'Tankstellenhelfer/in',
    Occupation: 'Petrol Station Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 686004,
    OccDE: 'Tankstellenkaufmann/-kauffrau',
    Occupation: 'Petrol Station Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 686105,
    OccDE: 'Tankstellenpächter/in',
    Occupation: 'Petrol Station Leaseholder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 686104,
    OccDE: 'Tankstellenleiter/in',
    Occupation: 'Petrol Station Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 686102,
    OccDE: 'Tankstellenbesitzer/in',
    Occupation: 'Petrol Station Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 686103,
    OccDE: 'Tankstelleninhaber/in',
    Occupation: 'Petrol Station Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 625204,
    OccDE: 'Erdöltechniker/in',
    Occupation: 'Petroleum Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 605105,
    OccDE: 'Erdölingenieur/in',
    Occupation: 'Petroleum Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 675107,
    OccDE: 'Pharmakaufmann/-kauffrau',
    Occupation: 'Pharmaceutical Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 780498,
    OccDE: 'pharmazeutisch/e kaufmännische/r Assistent/in',
    Occupation: 'Pharmaceutical Commercial Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780406,
    OccDE: 'pharmazeutisch-kaufmännischer        Angestellter',
    Occupation: 'Pharmaceutical Commercial Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689312,
    OccDE: 'Pharmaberater/in',
    Occupation: 'Pharmaceutical Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689314,
    OccDE: 'Pharmazeutische(r) Berater/in',
    Occupation: 'Pharmaceutical Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608317,
    OccDE: 'Pharmazieingenieur/in',
    Occupation: 'Pharmaceutical Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 141106,
    OccDE: 'Facharbeiter/in für pharmazeutische Industrie',
    Occupation: 'Pharmaceutical Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 858003,
    OccDE: 'Pharmazeutisch-technische(r) Assistent/in',
    Occupation: 'Pharmaceutical Technical Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 141107,
    OccDE: 'Pharmakant/in',
    Occupation: 'Pharmaceutical Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141108,
    OccDE: 'Pharmawerker/in',
    Occupation: 'Pharmaceutical Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141109,
    OccDE: 'Pharmazeutische(r) Arbeiter/in',
    Occupation: 'Pharmaceutical Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981149,
    OccDE: 'Student/in Pharmazie',
    Occupation: 'Pharmaceutics Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 844106,
    OccDE: 'Apotheker/in',
    Occupation: 'Pharmacist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 844126,
    OccDE: 'Pharmazeut/in',
    Occupation: 'Pharmacist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 685102,
    OccDE: 'Apothekenhelfer/in',
    Occupation: 'Pharmacy Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 858001,
    OccDE: 'Apothekenassistent/in, PTA',
    Occupation: 'Pharmacy Assistant PTA',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 633201,
    OccDE: 'Apothekenlaborant/in',
    Occupation: 'Pharmacy Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633205,
    OccDE: 'Pharmalaborant/in',
    Occupation: 'Pharmacy Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 844103,
    OccDE: 'Apothekenleiter/in',
    Occupation: 'Pharmacy Manager',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 685101,
    OccDE: 'Apothekenfacharbeiter/in',
    Occupation: 'Pharmacy Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 872117,
    OccDE: 'Philolog(e/in) (Studienrat/rätin))',
    Occupation: 'Philologist (School Officer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 981150,
    OccDE: 'Student/in Philologie (klassische, neuere)',
    Occupation: 'Philology (Classical, Modern) Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882326,
    OccDE: 'Philolog(e/in)',
    Occupation: 'Philology Scholar',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 882407,
    OccDE: 'Philosoph/in',
    Occupation: 'Philosopher',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 981151,
    OccDE: 'Student/in Philosophie',
    Occupation: 'Philosophy Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 735310,
    OccDE: 'Telefonauskunft',
    Occupation: 'Phone Assistance',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622217,
    OccDE: 'Telefontechniker/in',
    Occupation: 'Phone Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 782515,
    OccDE: 'Phonotypist/in',
    Occupation: 'Phonotypist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834312,
    OccDE: 'Fotodesigner/in',
    Occupation: 'Photo Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 612910,
    OccDE: 'Fotoingenieur/in',
    Occupation: 'Photo Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 634111,
    OccDE: 'Fotolaborhelfer/in',
    Occupation: 'Photo Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 634110,
    OccDE: 'Fotolaborant/in',
    Occupation: 'Photo Lab Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 171104,
    OccDE: 'Fotosetzer/in',
    Occupation: 'Photo Layout Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 634199,
    OccDE: 'Fotomedienlaborant/in',
    Occupation: 'Photo Media Lab Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662104,
    OccDE: 'Foto(fach)verkäufer/in',
    Occupation: 'Photo Salesperson (Consultant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673208,
    OccDE: 'Fotokaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Photo Supply Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673207,
    OccDE: 'Fotohändler/in (Einzelhandel)',
    Occupation: 'Photo Supply Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 634504,
    OccDE: 'Fototechniker/in',
    Occupation: 'Photo technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 634505,
    OccDE: 'Fototechnische(r) Assistent/in',
    Occupation: 'Photo Technology Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837003,
    OccDE: 'Fotograf/in',
    Occupation: 'Photographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837006,
    OccDE: 'Photograph/in',
    Occupation: 'Photographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837802,
    OccDE: 'Fotograf/in (Schwerpunkt Fotolabortechnik)',
    Occupation: 'Photographer (Darkroom Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837002,
    OccDE: 'Fotograf/in (Schwerpunkt Fotografie)',
    Occupation: 'Photographer (Photography)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821505,
    OccDE: 'Bildjournalist/in',
    Occupation: 'Photojournalist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837402,
    OccDE: 'Bildberichterstatter/in',
    Occupation: 'Photojournalist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837405,
    OccDE: 'Fotojournalist/in',
    Occupation: 'Photojournalist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 611303,
    OccDE: 'Physikalische(r) Chemiker/in',
    Occupation: 'Physical Chemist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 876502,
    OccDE: 'Gymnastiklehrer/in',
    Occupation: 'Physical Education Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876035,
    OccDE: 'Sportlehrer/in',
    Occupation: 'Physical Education Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876501,
    OccDE: 'Gymnastik- und Sportlehrer/in',
    Occupation: 'Physical Education Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876030,
    OccDE: 'Sportlehrer/in (an Gesamtschulen)',
    Occupation: 'Physical Education Teacher (Comprehensive Schools)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876031,
    OccDE: 'Sportlehrer/in (an Grund- und Hauptschulen)',
    Occupation: 'Physical Education Teacher (Primary to Secondary Schools)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876032,
    OccDE: 'Sportlehrer/in (an Gymnasien)',
    Occupation: 'Physical Education Teacher (Secondary Schools Gymnasium)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876033,
    OccDE: 'Sportlehrer/in (an Realschulen)',
    Occupation: 'Physical Education Teacher (Secondary Schools Realschule)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876012,
    OccDE: 'Diplom-Sportlehrer/in',
    Occupation: 'Physical Education Teacher with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626402,
    OccDE: 'Physikalisch-technische(r) Assistent/in',
    Occupation: 'Physical Technology Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841097,
    OccDE: 'Diplom-Mediziner/in',
    Occupation: 'Physician with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 612002,
    OccDE: 'Physiker/in',
    Occupation: 'Physicist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 612001,
    OccDE: 'Physiker/in (nicht Geophysiker/in)',
    Occupation: 'Physicist (not Geophysicist)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 612098,
    OccDE: 'Diplom-Physiker/in',
    Occupation: 'Physicist with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 612202,
    OccDE: 'Physikingenieur/in',
    Occupation: 'Physics Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 632102,
    OccDE: 'Physiklaborant/in',
    Occupation: 'Physics Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981152,
    OccDE: 'Student/in Physik',
    Occupation: 'Physics Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883145,
    OccDE: 'Physiolog(e/in) (Biologe/-in)',
    Occupation: 'Physiologist (Biologist)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 852002,
    OccDE: 'Physiotherapeut/in',
    Occupation: 'Physiotherapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 852404,
    OccDE: 'Krankengymnast/in',
    Occupation: 'Physiotherapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 852501,
    OccDE: 'Bewegungstherapeut/in',
    Occupation: 'Physiotherapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 852503,
    OccDE: 'Motopäd(e/in) (Bewegungstherapeut/in)',
    Occupation: 'Physiotherapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841966,
    OccDE: 'Physiotherapeut/in (Arzt/Ärztin)',
    Occupation: 'Physiotherapy',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 831447,
    OccDE: 'Pianist/in',
    Occupation: 'Pianist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 305107,
    OccDE: 'Klavierbauer/in',
    Occupation: 'Piano Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875318,
    OccDE: 'Klavierlehrer/in',
    Occupation: 'Piano Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 305114,
    OccDE: 'Klavierstimmer/in',
    Occupation: 'Piano Tuner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821401,
    OccDE: 'Bildredakteur/in',
    Occupation: 'Picture Editor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531902,
    OccDE: 'Akkordarbeiter/in',
    Occupation: 'Pieceworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023212',
    OccDE: 'Schweinezüchter/in',
    Occupation: 'Pig Breeder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726109,
    OccDE: 'Flugzeugführer/in',
    Occupation: 'Pilot',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726116,
    OccDE: 'Pilot/in',
    Occupation: 'Pilot',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 937223,
    OccDE: 'Rohrreiniger/in',
    Occupation: 'Pipe Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264105,
    OccDE: 'Rohrinstallateur/in',
    Occupation: 'Pipe Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264305,
    OccDE: 'Rohrschlosser/in',
    Occupation: 'Pipe Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264904,
    OccDE: 'Rohrvorrichter/in',
    Occupation: 'Pipe Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 194352,
    OccDE: 'Rohrwerker/in',
    Occupation: 'Pipe Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264213,
    OccDE: 'Rohrnetzbauer/in',
    Occupation: 'Pipe Network Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651910,
    OccDE: 'Rohrnetzmeister/in',
    Occupation: 'Pipe Network Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241918,
    OccDE: 'Rohrleitungsschweißer/in',
    Occupation: 'Pipe Welder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241919,
    OccDE: 'Rohrschweißer/in',
    Occupation: 'Pipe Welder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466406,
    OccDE: 'Rohrleger',
    Occupation: 'Pipelayer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466408,
    OccDE: 'Rohrleitungsbauvorarbeiter',
    Occupation: 'Pipeline Construction Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623518,
    OccDE: 'Rohrnetztechniker/in',
    Occupation: 'Pipeline Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466407,
    OccDE: 'Rohrleitungsbauer',
    Occupation: 'Pipeline Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466409,
    OccDE: 'Rohrleitungsmonteur',
    Occupation: 'Pipeline fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623599,
    OccDE: 'Rohrleitungstechniker/in',
    Occupation: 'Pipeline Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264214,
    OccDE: 'Rohrnetzmonteur/in',
    Occupation: 'Piping Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '072103',
    OccDE: 'Grubenschlosser',
    Occupation: 'Pit Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 391406,
    OccDE: 'Pizzabäcker/in',
    Occupation: 'Pizza Baker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911299,
    OccDE: 'Pizzeria-Inhaber/in',
    Occupation: 'Pizzeria Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 641002,
    OccDE: 'Planzeichner/in',
    Occupation: 'Plan Illustrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441604,
    OccDE: 'Verfuger',
    Occupation: 'Planer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 441603,
    OccDE: 'Fuger (Maurer)',
    Occupation: 'Planer (Mason)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 996103,
    OccDE: 'Planer/in',
    Occupation: 'Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 996104,
    OccDE: 'Planungsassistent/in',
    Occupation: 'Planning Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628215,
    OccDE: 'Arbeitsvorbereiter/in',
    Occupation: 'Planning Engineer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 600306,
    OccDE: 'Planungsingenieur/in',
    Occupation: 'Planning Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 607203,
    OccDE: 'Arbeitsvorbereiter/in (Ingenieur/in, nicht EDV)',
    Occupation: 'Planning Engineer (Engineer not EDP)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 628214,
    OccDE: 'Arbeitsvorbereiter/in (Techniker/in, nicht EDV)',
    Occupation: 'Planning Engineer (Technician, not EDP)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751904,
    OccDE: 'Planungsmanager',
    Occupation: 'Planning Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621916,
    OccDE: 'Planungssachbearbeiter/in (Anlagenbau)',
    Occupation: 'Planning Officer (Mechanical Engineering and Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 777201,
    OccDE: 'Planungsspezialist/in (EDV-Vertrieb)',
    Occupation: 'Planning Specialist (EDP Sales)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 620305,
    OccDE: 'Planungstechniker/in',
    Occupation: 'Planning Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 276011,
    OccDE: 'Werkmechaniker/in',
    Occupation: 'Plant Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662909,
    OccDE: 'Pflanzenverkäufer/in',
    Occupation: 'Plant Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481011,
    OccDE: 'Stuckner/in',
    Occupation: 'Plasterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481316,
    OccDE: 'Putzer/in',
    Occupation: 'Plasterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481322,
    OccDE: 'Verputzer/in',
    Occupation: 'Plasterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481012,
    OccDE: 'Stukkateur/in',
    Occupation: 'Plasterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481007,
    OccDE: 'Gipser/in (Stukkateur/in)',
    Occupation: 'Plasterer (Plasterer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481321,
    OccDE: 'Verputzer/in (Stukkateur/in)',
    Occupation: 'Plasterer (Plasterer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481407,
    OccDE: 'Stukkateur/in für Restaurierungsarbeiten',
    Occupation: 'Plasterer for Restoration Work',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481710,
    OccDE: 'Verputzerhelfer/in',
    Occupation: 'Plastering Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481102,
    OccDE: 'Gipser-Polier/in',
    Occupation: 'Plastering Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481103,
    OccDE: 'Gipsermeister/in',
    Occupation: 'Plastering Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481105,
    OccDE: 'Stuckpolier/in',
    Occupation: 'Plastering Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481106,
    OccDE: 'Stukkateurmeister/in',
    Occupation: 'Plastering Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152208,
    OccDE: 'Kunststoffstrangmaschinenführer/in',
    Occupation: 'Plastic Strand Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 150703,
    OccDE: 'Plastikarbeiter/in',
    Occupation: 'Plastic Workers',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 322908,
    OccDE: 'Plastikmontagearbeiter/in',
    Occupation: 'Plastics Assembly Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152002,
    OccDE: 'Kunststoff-Formgeber/in',
    Occupation: 'Plastics Caster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152416,
    OccDE: 'Kunststoff-Formgeber/in (Fachrichtung Spritzgießen)',
    Occupation: 'Plastics Caster (specialising in injection moulding)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145012,
    OccDE: 'Kunststoff-Formgeber/in (Fachrichtung Verarbeiten von Kautschuken)',
    Occupation: 'Plastics Caster (specialising in Rubber Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152310,
    OccDE: 'Kunststoffbeschichter/in',
    Occupation: 'Plastics Coater',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 153002,
    OccDE: 'Kunststoffbearbeiter/in',
    Occupation: 'Plastics Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 153004,
    OccDE: 'Kunststoffverformer/in',
    Occupation: 'Plastics Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 150101,
    OccDE: 'Kunststoffmaschinenbediener/in',
    Occupation: 'Plastics Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501002,
    OccDE: 'Kunststoffschreiner/in',
    Occupation: 'Plastics Furniture Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152418,
    OccDE: 'Kunststofformengießer/in',
    Occupation: 'Plastics Injection Moulder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152420,
    OccDE: 'Kunststoffspritzer/in',
    Occupation: 'Plastics Injection Moulder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152421,
    OccDE: 'Kunststoffspritzgießer/in',
    Occupation: 'Plastics Injection Moulder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152003,
    OccDE: 'Kunststoffmaschineneinrichter/in',
    Occupation: 'Plastics Machine Calibrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152703,
    OccDE: 'Kunststoffmaschinenarbeiter/in',
    Occupation: 'Plastics Machinery Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 502506,
    OccDE: 'Kunststoff-Modellbauer/in',
    Occupation: 'Plastics Model Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 150701,
    OccDE: 'Kunststoffverarbeitungshelfer/in',
    Occupation: 'Plastics Processing Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627603,
    OccDE: 'Kunststoffverarbeitungstechniker/in',
    Occupation: 'Plastics Processing Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 150003,
    OccDE: 'Kunststoffverarbeiter/in',
    Occupation: 'Plastics Processing Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 153003,
    OccDE: 'Kunststoffschlosser/in',
    Occupation: 'Plastics Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627602,
    OccDE: 'Kunststofftechniker/in',
    Occupation: 'Plastics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 150002,
    OccDE: 'Kunststoff-Facharbeiter/in',
    Occupation: 'Plastics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152702,
    OccDE: 'Kunststoffarbeiter/in',
    Occupation: 'Plastics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 153207,
    OccDE: 'Kunststoffschweißer/in',
    Occupation: 'Plastics Welder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 150103,
    OccDE: 'Plastikwerker/in',
    Occupation: 'Plastics Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141430,
    OccDE: 'Kunststoffwerker/in (Kunststoffhersteller/in)',
    Occupation: 'Plastics Worker (Plastics Manufacturing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 150102,
    OccDE: 'Kunststoffwerker/in (Kunststoffverarbeiter/in)',
    Occupation: 'Plastics Worker (Plastics Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261003,
    OccDE: 'Blechner/in',
    Occupation: 'Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261006,
    OccDE: 'Flaschner/in',
    Occupation: 'Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261009,
    OccDE: 'Klempner/in',
    Occupation: 'Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261012,
    OccDE: 'Spengler/in',
    Occupation: 'Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269108,
    OccDE: 'Klempner/in und Elektroinstallateur/in',
    Occupation: 'Plumber and Electrical Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269116,
    OccDE: 'Spengler/in und Elektroinstallateur/in',
    Occupation: 'Plumber and Electrical Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269102,
    OccDE: 'Flaschner/in und Installateur/in',
    Occupation: 'Plumber and Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269110,
    OccDE: 'Klempner/in und Rohrinstallateur/in',
    Occupation: 'Plumber and Pipe Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261109,
    OccDE: 'Isolierklempner/in',
    Occupation: 'Plumbing Insulation Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801465,
    OccDE: 'Polizeiverwaltungsassistent/in',
    Occupation: 'Police Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801331,
    OccDE: 'Polizeiverwaltungs(amtmann/amtfrau)',
    Occupation: 'Police Administrative Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801464,
    OccDE: 'Polizeiverwaltungsangestellte/r',
    Occupation: 'Police Administrative Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801466,
    OccDE: 'Polizeiverwaltungssekretär/in',
    Occupation: 'Police Administrative Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801215,
    OccDE: 'Polizei(rat/rätin)',
    Occupation: 'Police Assistant Commissioner',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 801320,
    OccDE: 'Polizeihauptkommissar/in',
    Occupation: 'Police Chief Superintendent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801328,
    OccDE: 'Polizeioberkommissar/in',
    Occupation: 'Police Chief Superintendent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801329,
    OccDE: 'Polizeioberkommissar/in in der Bundespolizei',
    Occupation: 'Police Chief Superintendent in the Federal Police',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801443,
    OccDE: 'Polizeibeamt(er/in)',
    Occupation: 'Police Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801425,
    OccDE: 'Kriminalmeister/in',
    Occupation: 'Police Constable',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801457,
    OccDE: 'Polizeimeister/in',
    Occupation: 'Police Constable',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801458,
    OccDE: 'Polizeimeister/in in der Bundespolizei',
    Occupation: 'Police Constable in the Federal Police',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801440,
    OccDE: 'Polizeiangestellte/r',
    Occupation: 'Police Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801322,
    OccDE: 'Polizeiinspektor/in',
    Occupation: 'Police Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801449,
    OccDE: 'Polizeihauptmeister/in',
    Occupation: 'Police Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801450,
    OccDE: 'Polizeihauptmeister/in in der Bundespolizei',
    Occupation: 'Police Inspector in the Federal Police',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801399,
    OccDE: 'Polizeivollzugsbeamter (SEK/MEK)',
    Occupation: 'Police Law Enforcement Officer (Task Force)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801470,
    OccDE: 'Polizist/in',
    Occupation: 'Police Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801441,
    OccDE: 'Polizeianwärter/in',
    Occupation: 'Police Officer Candidate',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801463,
    OccDE: 'Polizeisekretär/in',
    Occupation: 'Police Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801426,
    OccDE: 'Kriminalobermeister/in',
    Occupation: 'Police Sergeant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801459,
    OccDE: 'Polizeiobermeister/in',
    Occupation: 'Police Sergeant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801462,
    OccDE: 'Polizeioberwachtmeister/in',
    Occupation: 'Police Sergeant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801469,
    OccDE: 'Polizeiwachtmeister/in',
    Occupation: 'Police Sergeant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801460,
    OccDE: 'Polizeiobermeister/in in der Bundespolizei',
    Occupation: 'Police Sergeant in the Federal Police',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801452,
    OccDE: 'Polizeihauptwachtmeister/in',
    Occupation: 'Police Sergeant Major',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801323,
    OccDE: 'Polizeikommissär/in',
    Occupation: 'Police Superintendent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801324,
    OccDE: 'Polizeikommissar/in',
    Occupation: 'Police Superintendent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801325,
    OccDE: 'Polizeikommissar/in in der Bundespolizei',
    Occupation: 'Police Superintendent in the Federal Police',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801471,
    OccDE: 'Schutzmann/Schutzfrau (Polizist/in)',
    Occupation: 'Policeman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801477,
    OccDE: 'Wachpolizist/in',
    Occupation: 'Policeman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981153,
    OccDE: 'Student/in Politikwissenschaften',
    Occupation: 'Political Science Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 884302,
    OccDE: 'Politikwissenschaftler/in',
    Occupation: 'Political Scientist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 884303,
    OccDE: 'Politolog(e/in)',
    Occupation: 'Political Scientist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 887303,
    OccDE: 'Meinungsforscher/in',
    Occupation: 'Pollster',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 871105,
    OccDE: 'Fachhochschullehrer/in',
    Occupation: 'Polytechnic Teacher',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 514205,
    OccDE: 'Porzellanmaler/in',
    Occupation: 'Porcelain Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 514410,
    OccDE: 'Porzellandrucker/in',
    Occupation: 'Porcelain Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121753,
    OccDE: 'Porzellanarbeiter/in',
    Occupation: 'Porcelain Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603507,
    OccDE: 'Hafenbauingenieur/in',
    Occupation: 'Port engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 793001,
    OccDE: 'Pförtner/in',
    Occupation: 'Porter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915609,
    OccDE: 'Portier (Gaststätte, Hotel)',
    Occupation: 'Porter (Restaurant, Hotel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837603,
    OccDE: 'Porträtfotograf/in',
    Occupation: 'Portrait Photographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 731103,
    OccDE: 'Postagent/in',
    Occupation: 'Post Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732104,
    OccDE: 'Briefzusteller/in',
    Occupation: 'Post Carrier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732707,
    OccDE: 'Postsortierer/in',
    Occupation: 'Post Sorter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732202,
    OccDE: 'Posthauptschaffner/in',
    Occupation: 'Postal Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732301,
    OccDE: 'Postbetriebsassistent/in',
    Occupation: 'Postal Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732705,
    OccDE: 'Posthelfer/in',
    Occupation: 'Postal Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787520,
    OccDE: 'Postassistent/in',
    Occupation: 'Postal Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787521,
    OccDE: 'Postbeamt(er/in)',
    Occupation: 'Postal Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787598,
    OccDE: 'Postverkehrskaufmann/-kauffrau',
    Occupation: 'Postal Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788504,
    OccDE: 'Postsachbearbeiter/in',
    Occupation: 'Postal Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765512,
    OccDE: 'Post(amtmann/amtfrau)',
    Occupation: 'Postal Deputy Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732117,
    OccDE: 'Postverteiler/in',
    Occupation: 'Postal Distribution Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732103,
    OccDE: 'Briefverteiler/in',
    Occupation: 'Postal Distribution Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787522,
    OccDE: 'Postbetriebsinspektor/in',
    Occupation: 'Postal Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787525,
    OccDE: 'Postobersekretär/in',
    Occupation: 'Postal Higher Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787526,
    OccDE: 'Postschalterangestellte/r',
    Occupation: 'Postal Officer Counter Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732006,
    OccDE: 'Postbetriebsfacharbeiter/in',
    Occupation: 'Postal Operations Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787528,
    OccDE: 'Postsekretär/in',
    Occupation: 'Postal Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787524,
    OccDE: 'Posthauptsekretär/in',
    Occupation: 'Postal Senior Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765517,
    OccDE: 'Postoberinspektor/in',
    Occupation: 'Postal Senior Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732008,
    OccDE: 'Postfachbedienstete/r',
    Occupation: 'Postal Services Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732007,
    OccDE: 'Postfacharbeiter/in',
    Occupation: 'Postal Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787519,
    OccDE: 'Postangestellte/r',
    Occupation: 'Postal Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732703,
    OccDE: 'Postarbeiter/in',
    Occupation: 'Postal Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312212,
    OccDE: 'Posthandwerker/in (Fernmeldemonteur/in)',
    Occupation: 'Postal Worker (Telecommunications Technician)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732102,
    OccDE: 'Briefträger/in',
    Occupation: 'Postman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732114,
    OccDE: 'Postbot(e/in)',
    Occupation: 'Postman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 121017,
    OccDE: 'Töpfer/in',
    Occupation: 'Potter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 484202,
    OccDE: 'Hafner/in (Ofensetzer/in)',
    Occupation: 'Potter (Stove Builder)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023420',
    OccDE: 'Geflügelzüchter/in',
    Occupation: 'Poultry Breeder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023417',
    OccDE: 'Geflügelzuchtgehilf(e/in)',
    Occupation: 'Poultry Breeder Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023410',
    OccDE: 'Geflügelfarmer/in',
    Occupation: 'Poultry Farmer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191506,
    OccDE: 'Gießer/in (Metallerzeugung)',
    Occupation: 'Pourer (Metal Manufacturing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311001,
    OccDE: 'Energieelektroniker/in',
    Occupation: 'Power Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311208,
    OccDE: 'Energieelektroniker/in (Fachrichtung Betriebstechnik)',
    Occupation: 'Power Electrician (specialising in Industrial Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311112,
    OccDE: 'Energieelektroniker/in (Fachrichtung Anlagentechnik)',
    Occupation: 'Power Electrician (specialising in Systems Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311002,
    OccDE: 'Energiewartungselektriker/in',
    Occupation: 'Power Maintenance Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 541014,
    OccDE: 'Kraftwerkmaschinist/in',
    Occupation: 'Power Plant Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 541016,
    OccDE: 'Kraftwerksmeister/in',
    Occupation: 'Power Plant Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621511,
    OccDE: 'Kraftwerkstechniker/in',
    Occupation: 'Power Plant Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 541012,
    OccDE: 'Kraftwerker/in',
    Occupation: 'Power Plant Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622109,
    OccDE: 'Versorgungstechniker/in (Stromversorgung)',
    Occupation: 'Power Supply Technician (Power Supply)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 622107,
    OccDE: 'Starkstromtechniker/in',
    Occupation: 'Power Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 757099,
    OccDE: 'PR-Berater/in',
    Occupation: 'PR Consultant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 891805,
    OccDE: 'Prediger/in',
    Occupation: 'Preacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 112102,
    OccDE: 'Betonfertigteilbauer/in',
    Occupation: 'Precast Concrete Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442502,
    OccDE: 'Betonfertigteilbauer (Montage)',
    Occupation: 'Precast Concrete Builder (assembly)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 112101,
    OccDE: 'Betonfertigteilbauer/in (Herstellung)',
    Occupation: 'Precast Concrete Builder (Manufacturing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442509,
    OccDE: 'Fertigteilemonteur (Beton)',
    Occupation: 'Precast Fitter (Concrete)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 302002,
    OccDE: 'Edelmetallfacharbeiter/in',
    Occupation: 'Precious Metalworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981119,
    OccDE: 'Student/in Feinwerk- und Mikrotechnik',
    Occupation: 'Precision and Microtechnology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316103,
    OccDE: 'Elektrofeinmechaniker/in',
    Occupation: 'Precision Electromechanical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601402,
    OccDE: 'Feinwerkingenieur/in',
    Occupation: 'Precision Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 300702,
    OccDE: 'Feinmechanikerhelfer/in',
    Occupation: 'Precision Engineering Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300012,
    OccDE: 'Feinwerkmechaniker/in',
    Occupation: 'Precision Engineering Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621403,
    OccDE: 'Feinwerktechniker/in',
    Occupation: 'Precision Engineering Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316329,
    OccDE: 'Feingeräteelektroniker/in',
    Occupation: 'Precision Equipment Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 225505,
    OccDE: 'Präzisionsschleifer/in',
    Occupation: 'Precision Grinder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316109,
    OccDE: 'Feinmechaniker/in (Elektromechanik)',
    Occupation: 'Precision Mechanic (Electromechanical Engineer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300006,
    OccDE: 'Feinmechaniker/in (Feinmechanik, Optik)',
    Occupation: 'Precision Mechanic (Precision Engineering, Optics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300005,
    OccDE: 'Feinmechaniker/in (Feingerätebau)',
    Occupation: 'Precision Mechanic (Precision Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300007,
    OccDE: 'Feinmechaniker/in (Präzisionsmechanik)',
    Occupation: 'Precision Mechanic (Precision Mechanics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300009,
    OccDE: 'Feinmechaniker/in',
    Occupation: 'Precision Mechanics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 295403,
    OccDE: 'Metallfeinbauer/in',
    Occupation: 'Precision Metal Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 295703,
    OccDE: 'Metallfeinbauerhelfer/in',
    Occupation: 'Precision Metal Engineer Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135605,
    OccDE: 'Feinoptiker/in',
    Occupation: 'Precision Optometrist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300022,
    OccDE: 'Modellfeinmechaniker/in',
    Occupation: 'Precision Scale Model Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 265099,
    OccDE: 'Feinblechmechaniker/in',
    Occupation: 'Precision Sheet Metal Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 265009,
    OccDE: 'Feinblechner/in',
    Occupation: 'Precision Sheet Metal Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 265010,
    OccDE: 'Feinblechschlosser/in',
    Occupation: 'Precision Sheet Metal Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321103,
    OccDE: 'Feinwerker/in (Elektronik)',
    Occupation: 'Precision Technician (Electronics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 221016,
    OccDE: 'Präzisionsdreher/in',
    Occupation: 'Precision Turner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487407,
    OccDE: 'Fertighausmonteur (Holz)',
    Occupation: 'Prefabricated House Fitter (Timber)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487410,
    OccDE: 'Fertigteil(e)monteur (Holz, Kunststoff)',
    Occupation: 'Prefabricated Part Fitter (Timber, Plastic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487416,
    OccDE: 'Holzfertigteil(e)monteur',
    Occupation: 'Prefabricated Timber Component Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 270007,
    OccDE: 'Vorfertigungsmechaniker/in',
    Occupation: 'Prefabrication Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '080503',
    OccDE: 'Aufbereitungsmechaniker/in',
    Occupation: 'Preparation Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 435733,
    OccDE: 'Konservenarbeiter/in',
    Occupation: 'Preserved Foods Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 811122,
    OccDE: 'Gerichtspräsident/in',
    Occupation: 'President of the Court',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 837407,
    OccDE: 'Pressefotograf/in',
    Occupation: 'Press Photographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821906,
    OccDE: 'Pressesprecher/in',
    Occupation: 'Press Speaker',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 821409,
    OccDE: 'Pressereferent/in',
    Occupation: 'Press Speaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997180,
    OccDE: 'Presser/in',
    Occupation: 'Presser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 891111,
    OccDE: 'Priester',
    Occupation: 'Priest',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 873101,
    OccDE: 'Grundschullehrer/in',
    Occupation: 'Primary School Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873205,
    OccDE: 'Volksschullehrer/in',
    Occupation: 'Primary School Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172119,
    OccDE: 'Vorlagenhersteller/in',
    Occupation: 'Print Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174905,
    OccDE: 'Druckfachwerker/in',
    Occupation: 'Print Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 321944,
    OccDE: 'Leiterplattenbestücker/in',
    Occupation: 'Printed Circuit Board Assembly Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316903,
    OccDE: 'Leiterplattenfertiger/in',
    Occupation: 'Printed Circuit Board Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174003,
    OccDE: 'Drucker/in',
    Occupation: 'Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174206,
    OccDE: 'Drucker/in (Flachdruck)',
    Occupation: 'Printer (Flat Printing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174303,
    OccDE: 'Drucker/in (Tiefdruck)',
    Occupation: 'Printer (Gravure Printing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 175102,
    OccDE: 'Drucker/in (Siebdruck)',
    Occupation: 'Printer (Screen Printer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174098,
    OccDE: 'Drucker/in - Fachrichtung            Digitaltechnik',
    Occupation: 'Printer specialising in Digital Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 179004,
    OccDE: 'Druckereihelfer/in',
    Occupation: 'Printing Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785202,
    OccDE: 'Druckereikaufmann/-kauffrau',
    Occupation: 'Printing Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 788912,
    OccDE: 'Druckereiangestellte/r',
    Occupation: 'Printing Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606801,
    OccDE: 'Druckingenieur/in',
    Occupation: 'Printing Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 273913,
    OccDE: 'Druckmaschinenmonteur/in',
    Occupation: 'Printing Machinery Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 173003,
    OccDE: 'Druckformhersteller/in',
    Occupation: 'Printing Plate Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 179005,
    OccDE: 'Druckereihilfsarbeiter/in',
    Occupation: 'Printing Press Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 179313,
    OccDE: 'Druckmaschinenbediener/in',
    Occupation: 'Printing Press Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174005,
    OccDE: 'Druckereifachkraft',
    Occupation: 'Printing Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627803,
    OccDE: 'Drucktechniker/in',
    Occupation: 'Printing Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174004,
    OccDE: 'Druckereifacharbeiter/in',
    Occupation: 'Printing Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 179003,
    OccDE: 'Druckereiarbeiter/in',
    Occupation: 'Printing Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172001,
    OccDE: 'Druckvorlagenhersteller/in',
    Occupation: 'Printmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172102,
    OccDE: 'Druckvorlagenhersteller/in (Fachrichtung Reprovorbereitung)',
    Occupation: 'Printmaker (specialising in Reproduction Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172305,
    OccDE: 'Druckvorlagenhersteller/in (Fachrichtung Reproretusche)',
    Occupation: 'Printmaker (specialising in Reproduction Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814234,
    OccDE: 'Strafanstaltsbeamt(er/in)',
    Occupation: 'Prison Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814205,
    OccDE: 'Gefängnisbeamt(er/in)',
    Occupation: 'Prison Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814241,
    OccDE: 'Strafvollzugsbeamt(er/in) (nicht Sanitätsdienst)',
    Occupation: 'Prison Officer (not Medical Staff)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 814209,
    OccDE: 'Gefängniswachtmeister/in',
    Occupation: 'Prison Sergeant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791308,
    OccDE: 'Privatdetektiv/in',
    Occupation: 'Private Detective',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875219,
    OccDE: 'Privatmusiklehrer/in',
    Occupation: 'Private Music Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869014,
    OccDE: 'Privatpfleger/in',
    Occupation: 'Private Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 872118,
    OccDE: 'Privatschullehrer/in (wissenschaftliche/r)',
    Occupation: 'Private School Teacher (academic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879804,
    OccDE: 'Privatlehrer/in',
    Occupation: 'Private Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861403,
    OccDE: 'Bewährungshelfer/in',
    Occupation: 'Probation Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316508,
    OccDE: 'Prozeßleitelektroniker/in',
    Occupation: 'Process Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626003,
    OccDE: 'Verfahrenstechniker/in',
    Occupation: 'Process Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191022,
    OccDE: 'Verfahrensmechaniker/in',
    Occupation: 'Process Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602612,
    OccDE: 'Prozeßingenieur/in',
    Occupation: 'Process Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 611829,
    OccDE: 'Verfahrensingenieur/in',
    Occupation: 'Process Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 194004,
    OccDE: 'Verfahrensmechaniker/in (Metallumformung)',
    Occupation: 'Process Engineer (Metalworking)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 152099,
    OccDE:
      'Verfahrensmechaniker/in für          Kunststoff- und Kautschuktechnik',
    Occupation: 'Process Engineer for Plastics and Rubber Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626299,
    OccDE: 'Verfahrensmechaniker/in für          Beschichtungstechnik',
    Occupation: 'Process Engineer for Varnish, Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135096,
    OccDE: 'Verfahrensmechaniker/in Glastechnik',
    Occupation: 'Process Engineer in Glass Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191021,
    OccDE: 'Verfahrensmechaniker/in in der Hütten- und Halbzeugindustrie',
    Occupation:
      'Process Engineer in the Metalworking and Semi-Finished Goods Industry',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191207,
    OccDE:
      'Verfahrensmechaniker/in in der Hütten-/Halbzeugindustrie (Nichteisen-ur)',
    Occupation:
      'Process Engineer in the Metalworking and Semi-Finished Goods Industry (non-ferrous)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 194201,
    OccDE:
      'Verfahrensmechaniker/in in der Hütten-/Halbzeugindustrie (Nichteisen-um)',
    Occupation:
      'Process Engineer in the Metalworking and Semi-Finished Goods Industry (non-ferrous)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 112008,
    OccDE: 'Verfahrensmechaniker/in in der Steine- und Erdenindustrie',
    Occupation: 'Process Engineer in the Mining and Quarrying Sector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981174,
    OccDE: 'Student/in Verfahrenstechnik, Chemieingieurswesen',
    Occupation: 'Process Engineering, Chemical Engineering Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774599,
    OccDE: 'Prozessmanager/in',
    Occupation: 'Process Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 756101,
    OccDE: 'Ablauforganisator/in',
    Occupation: 'Process Organiser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756905,
    OccDE: 'Verfahrensbetreuer/in',
    Occupation: 'Process Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 435417,
    OccDE: 'Nährmittelhersteller/in',
    Occupation: 'Processed Foodstuffs Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756203,
    OccDE: 'Beschaffungs-Controller/in',
    Occupation: 'Procurement Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835226,
    OccDE: 'Produzent/in',
    Occupation: 'Producer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835225,
    OccDE: 'Produzent/in (Film, Fernsehen, Rundfunk)',
    Occupation: 'Producer (Film, Television, Radio)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 777113,
    OccDE: 'Produktberater/in (EDV)',
    Occupation: 'Product Consultant (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 834125,
    OccDE: 'Produktdesigner/in',
    Occupation: 'Product Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834126,
    OccDE: 'Produktgestalter/in',
    Occupation: 'Product Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 755104,
    OccDE: 'Produktentwickler/in',
    Occupation: 'Product Developer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 522010,
    OccDE: 'Warenaufmacher/in',
    Occupation: 'Product Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 676105,
    OccDE: 'Warengruppenleiter/in',
    Occupation: 'Product Group Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 611408,
    OccDE: 'Produktgruppenleiter/in (Pharma)',
    Occupation: 'Product Group Manager (pharmaceutical)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 755105,
    OccDE: 'Produktmanager',
    Occupation: 'Product Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 755199,
    OccDE: 'Product manager/in',
    Occupation: 'Product Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 755106,
    OccDE: 'Produktplaner/in',
    Occupation: 'Product Planner',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 755103,
    OccDE: 'Produktbetreuer/in',
    Occupation: 'Product Service Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 755198,
    OccDE: 'Produktspezialist/in',
    Occupation: 'Product Specialist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 521007,
    OccDE: 'Produktprüfer/in',
    Occupation: 'Product Testers',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521212,
    OccDE: 'Fertigungs- und Funktionskontrolleur/in',
    Occupation: 'Production and Function Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 322101,
    OccDE: 'Fertigungsmonteur/in',
    Occupation: 'Production Assembly Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531297,
    OccDE: 'Produktionshelfer/in',
    Occupation: 'Production Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683004,
    OccDE: 'Produktionsassistent/in (Verlag)',
    Occupation: 'Production Assistant (Publishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 424715,
    OccDE: 'Produktionshelfer/in (Tabakwarenherstellung)',
    Occupation: 'Production Assistant (Tobacco Production)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785103,
    OccDE: 'Fertigungskaufmann/-kauffrau',
    Occupation: 'Production Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 521213,
    OccDE: 'Fertigungskontrolleur/in',
    Occupation: 'Production Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756141,
    OccDE: 'Produktionskoordinator/in',
    Occupation: 'Production Coordinator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683304,
    OccDE: 'Herstellungsleiter/in (Verlag)',
    Occupation: 'Production Director (Publishing)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 600403,
    OccDE: 'Fertigungsingenieur/in',
    Occupation: 'Production Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600409,
    OccDE: 'Produktionsingenieur/in',
    Occupation: 'Production Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601802,
    OccDE: 'Fertigungsingenieur/in des Maschinenbaues',
    Occupation: 'Production Engineer Mechanical Engineering',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 651012,
    OccDE: 'Fertigungsmeister/in',
    Occupation: 'Production Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628226,
    OccDE: 'Fertigungssteuer(er/in)',
    Occupation: 'Production Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 628239,
    OccDE: 'Produktionssteuer(er/in)',
    Occupation: 'Production Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 651027,
    OccDE: 'Produktionsmeister/in',
    Occupation: 'Production Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751602,
    OccDE: 'Produktionsleiter/in',
    Occupation: 'Production Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 751603,
    OccDE: 'Werkleiter/in',
    Occupation: 'Production Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 602804,
    OccDE: 'Fertigungsleiter/in (Elektroingenieur/in)',
    Occupation: 'Production Manager (Electrical Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601803,
    OccDE: 'Fertigungsleiter/in (Maschinenbauingenieur/in)',
    Occupation: 'Production Manager (Mechanical Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600404,
    OccDE: 'Fertigungsleiter/in (Fertigung)',
    Occupation: 'Production Manager (Production)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 751601,
    OccDE: 'Fertigungsleiter/in (Produktion)',
    Occupation: 'Production Manager (Production)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 835223,
    OccDE: 'Produktionsleiter/in (Bühnen-, Bild-, Tontechnik)',
    Occupation: 'Production Manager (Stage, Screen, Sound Equipment)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 607209,
    OccDE: 'Fertigungsdisponent/in (Ingenieur/in)',
    Occupation: 'Production Materials Requirement Planner',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 276008,
    OccDE: 'Produktionsmechaniker/in',
    Occupation: 'Production Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 278299,
    OccDE: 'Fertigungsmechaniker/in',
    Occupation: 'Production Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 276703,
    OccDE: 'Produktionsmechanikerhelfer/in',
    Occupation: 'Production Mechanic Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628238,
    OccDE: 'Produktionsplaner/in',
    Occupation: 'Production Planner',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 756119,
    OccDE: 'Fertigungsplaner/in',
    Occupation: 'Production Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 607210,
    OccDE: 'Fertigungsplaner/in (Ingenieur)',
    Occupation: 'Production Planner (Engineering)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 628225,
    OccDE: 'Fertigungsplaner/in (Techniker/in)',
    Occupation: 'Production Planner (Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 628227,
    OccDE: 'Fertigungsvorbereiter/in',
    Occupation: 'Production Rigger',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 835201,
    OccDE: 'Aufnahmeleiter/in (Film, Fernsehen, Rundfunk)',
    Occupation: 'Production Sound Mixer (Film, television, radio)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620409,
    OccDE: 'Produktionsspezialist/in',
    Occupation: 'Production Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620406,
    OccDE: 'Fertigungstechniker/in',
    Occupation: 'Production Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 620410,
    OccDE: 'Produktionstechniker/in',
    Occupation: 'Production Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 435005,
    OccDE: 'Produktionsfacharbeiter/in (Lebensmittelherstellung)',
    Occupation: 'Production Technician (Food Production)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621804,
    OccDE: 'Fertigungstechniker/in des Maschinenbaues',
    Occupation: 'Production Technician in Mechanical Engineering',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981154,
    OccDE: 'Student/in Produktionstechnik',
    Occupation: 'Production Technology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521214,
    OccDE: 'Fertigungsprüfer/in',
    Occupation: 'Production Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531202,
    OccDE: 'Fertigungsarbeiter/in',
    Occupation: 'Production Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531204,
    OccDE: 'Produktionsarbeiter/in',
    Occupation: 'Production worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323110,
    OccDE: 'Produktionsfacharbeiter/in (an Metallbearbeitungsmaschinen)',
    Occupation: 'Production Worker (with Metalworking Machinery)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 838403,
    OccDE: 'Berufssportler/in',
    Occupation: 'Professional Athlete',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902003,
    OccDE: 'Fachkosmetiker/in',
    Occupation: 'Professional Beautician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023501',
    OccDE: 'Berufsimker/in',
    Occupation: 'Professional Beekeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803899,
    OccDE: 'Berufstaucher',
    Occupation: 'Professional Diver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714002,
    OccDE: 'Berufskraftfahrer/in',
    Occupation: 'Professional Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714404,
    OccDE: 'Berufskraftfahrer/in (Güterverkehr)',
    Occupation: 'Professional Driver (Goods Transport)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714502,
    OccDE: 'Berufskraftfahrer/in (Stadtreinigung)',
    Occupation: 'Professional Driver (Municipal Cleaning)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714303,
    OccDE: 'Berufskraftfahrer/in (Personenverkehr)',
    Occupation: 'Professional Driver (Passenger Transport)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714405,
    OccDE: 'Berufskraftfahrer/in (Kraftverkehr)',
    Occupation: 'Professional Driver (Road Transport)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 802002,
    OccDE: 'Berufsfeuerwehrmann/-feuerwehrfrau',
    Occupation: 'Professional Firefighter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 838302,
    OccDE: 'Lizenzfußballspieler/in',
    Occupation: 'Professional football Player',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 838398,
    OccDE: 'Fußballprofi',
    Occupation: 'Professional Football Player',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831001,
    OccDE: 'Berufsmusiker/in',
    Occupation: 'Professional Musician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837001,
    OccDE: 'Berufsfotograf/in',
    Occupation: 'Professional Photographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801182,
    OccDE: 'Berufssoldat',
    Occupation: 'Professional Soldier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801188,
    OccDE: 'Berufssoldat (Stabsdienst)',
    Occupation: 'Professional Soldier (Staff Officer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 871113,
    OccDE: 'Professor/in',
    Occupation: 'Professor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 871112,
    OccDE: 'Professor/in (an wissenschaftlichen Hochschulen)',
    Occupation: 'Professor (at academic University Colleges)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 775010,
    OccDE: 'Programmgestalter/in (EDV)',
    Occupation: 'Program Designer (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 821410,
    OccDE: 'Programmgestalter/in (Rundfunk, Fernsehen)',
    Occupation: 'Programme Coordinator (Radio, Television)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620306,
    OccDE: 'Programmplaner/in',
    Occupation: 'Programme Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 775011,
    OccDE: 'Programmierer/in',
    Occupation: 'Programmer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 620307,
    OccDE: 'Projektassistent/in',
    Occupation: 'Project assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785108,
    OccDE: 'Projektkaufmann/-kauffrau',
    Occupation: 'Project Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 620803,
    OccDE: 'Projektberater/in',
    Occupation: 'Project Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756224,
    OccDE: 'Projekt-Controller/in',
    Occupation: 'Project Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600399,
    OccDE: 'Projektentwickler/in',
    Occupation: 'Project Developer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 600307,
    OccDE: 'Projektingenieur/in',
    Occupation: 'Project Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 623109,
    OccDE: 'Projektbauleiter/in (Bautechniker/in)',
    Occupation: 'Project Foreman (Construction Engineer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750307,
    OccDE: 'Projektleiter/in',
    Occupation: 'Project Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 750308,
    OccDE: 'Projektmanager',
    Occupation: 'Project Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 602910,
    OccDE: 'Projektleiter/in (Elektro)',
    Occupation: 'Project Manager (Electronics)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 887210,
    OccDE: 'Projektleiter/in (Marktforschung)',
    Occupation: 'Project Manager (Market Research)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 703801,
    OccDE: 'Projektleiter/in (Werbung)',
    Occupation: 'Project Manager (Marketing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756902,
    OccDE: 'Objektplaner/in',
    Occupation: 'Project Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620311,
    OccDE: 'Projektplaner/in',
    Occupation: 'Project Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620309,
    OccDE: 'Projektbetreuer/in',
    Occupation: 'Project Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620312,
    OccDE: 'Projekttechniker/in',
    Occupation: 'Project Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 620308,
    OccDE: 'Projektbearbeiter/in',
    Occupation: 'Project Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620310,
    OccDE: 'Projektierer/in',
    Occupation: 'Project Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835903,
    OccDE: 'Filmvorführer/in',
    Occupation: 'Projectionist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750309,
    OccDE: 'Promoter',
    Occupation: 'Promoter',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 689216,
    OccDE: 'Werbedame (Verkauf)',
    Occupation: 'Promotional Agent (Sales)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 171506,
    OccDE: 'Korrektor/in',
    Occupation: 'Proofreader',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741409,
    OccDE: 'Requisiteur/in',
    Occupation: 'Prop Master',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689218,
    OccDE: 'Propagandist/in',
    Occupation: 'Propagandist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522466,
    OccDE: 'Propangasabfüller/in',
    Occupation: 'Propane Filler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704510,
    OccDE: 'Grundstücksverwalter/in',
    Occupation: 'Property Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704526,
    OccDE: 'Liegenschaftsverwalter/in',
    Occupation: 'Property Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997113,
    OccDE: 'Objektleiter/in',
    Occupation: 'Property Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934009,
    OccDE: 'Objektleiter/in (Gebäudereinigung)',
    Occupation: 'Property manager (Building Cleaning)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792324,
    OccDE: 'Objektschutzangestellte/r',
    Occupation: 'Property Security Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 604204,
    OccDE: 'Liegenschaftsvermesser/in',
    Occupation: 'Property Surveyor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 794909,
    OccDE: 'Liegenschaftsarbeiter/in',
    Occupation: 'Property Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750102,
    OccDE: 'Betriebsinhaber/in',
    Occupation: 'Proprietor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911439,
    OccDE: 'Wirt/in (Gastwirt/in)',
    Occupation: 'Proprietor (Publican)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 870201,
    OccDE: 'Lehramtsanwärter/in',
    Occupation: 'Prospective Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 307103,
    OccDE: 'Orthopädiemechaniker/in',
    Occupation: 'Prosthetist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 307106,
    OccDE: 'Orthopädietechniker/in (Mechaniker/in)',
    Occupation: 'Prosthetist (Technician)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 307104,
    OccDE: 'Orthopädiemechaniker/in und Bandagist/in',
    Occupation: 'Prosthetist and Surgical Truss Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915907,
    OccDE: 'Prostituierte',
    Occupation: 'Prostitute',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788927,
    OccDE: 'Protokollführer/in',
    Occupation: 'Protocol Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 309906,
    OccDE: 'Musterbauer/in',
    Occupation: 'Prototype Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 502403,
    OccDE: 'Versuchsmodellmechaniker/in',
    Occupation: 'Prototype Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316135,
    OccDE: 'Versuchsmechaniker/in (Elektromechanik)',
    Occupation: 'Prototype Mechanic (Electromechanical Engineer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300026,
    OccDE: 'Versuchsmonteur/in (Geräte- und Feinwerktechnik)',
    Occupation:
      'Prototype Testing Technician (Equipment and Precision Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841810,
    OccDE: 'Psychiater/in',
    Occupation: 'Psychiatrist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 886905,
    OccDE: 'Psychoanalytiker/in',
    Occupation: 'Psychoanalyst',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 886699,
    OccDE: 'Psychologische(r) Psychotherapeut(/in)',
    Occupation: 'Psychological Psychotherapist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 857402,
    OccDE: 'Psychologisch-technische(r) Assistent/in',
    Occupation: 'Psychological Technical Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 886001,
    OccDE: 'Psycholog(e/in)',
    Occupation: 'Psychologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 886099,
    OccDE: 'Diplom-Psycholog(e/in)',
    Occupation: 'Psychologist with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 981155,
    OccDE: 'Student/in Psychologie',
    Occupation: 'Psychology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 886605,
    OccDE: 'Psychotherapeut/in',
    Occupation: 'Psychotherapist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 841811,
    OccDE: 'Psychotherapeut/in (ärztliche/r)',
    Occupation: 'Psychotherapist (Medical)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 886602,
    OccDE: 'Kinder- und Jugendlichenpsychotherapeut/in',
    Occupation: 'Psychotherapist for Children and Adolescents',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 765006,
    OccDE: 'Diplom-Verwaltungswirt/in',
    Occupation: 'Public Administrator with Degree',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 811301,
    OccDE: 'Amtsanwalt/Amtsanwältin',
    Occupation: 'Public Attorney',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 787007,
    OccDE: 'Behördenangestellte/r',
    Occupation: 'Public Authority Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787008,
    OccDE: 'Behördenbedienstete/r',
    Occupation: 'Public Authority Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841006,
    OccDE: 'Amtsarzt/-ärztin',
    Occupation: 'Public Medical Officer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 811211,
    OccDE: 'Staatsanwalt/Staatsanwältin',
    Occupation: 'Public Prosecutor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 703304,
    OccDE: 'Public-Relations-Berater/in',
    Occupation: 'Public Relations Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703305,
    OccDE: 'Public-Relations-Fachkraft',
    Occupation: 'Public Relations Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911415,
    OccDE: 'Gastwirt/in',
    Occupation: 'Publican',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821518,
    OccDE: 'Publizist/in',
    Occupation: 'Publicist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683409,
    OccDE: 'Verleger/in (Verlagskaufmann/-kauffrau)',
    Occupation: 'Publisher (Publishing Clerk)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683008,
    OccDE: 'Verlagsassistent/in',
    Occupation: 'Publishing Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 674203,
    OccDE: 'Verlagsbuchhändler/in',
    Occupation: 'Publishing Bookseller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683009,
    OccDE: 'Verlagsfachwirt/in',
    Occupation: 'Publishing Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 683011,
    OccDE: 'Verlagskaufmann/-kauffrau',
    Occupation: 'Publishing Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683103,
    OccDE: 'Verlagskaufmann/-kauffrau (Schwerpunkt Buchverlag)',
    Occupation: 'Publishing Clerk (Book Publishing)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 683207,
    OccDE:
      'Verlagskaufmann/-kauffrau (Schwerpunkt Zeitungs- und Zeitschriftenverlag)',
    Occupation: 'Publishing Clerk (Newspaper and Magazine Publisher)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683307,
    OccDE: 'Verlagsleiter/in',
    Occupation: 'Publishing Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 821303,
    OccDE: 'Verlagslektor/in',
    Occupation: 'Publishing Editor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 821418,
    OccDE: 'Verlagsredakteur/in',
    Occupation: 'Publishing Editor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683007,
    OccDE: 'Verlagsangestellte/r',
    Occupation: 'Publishing Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683907,
    OccDE: 'Verlagsvertreter/in',
    Occupation: 'Publishing Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683099,
    OccDE: 'Verlagsmitarbeiter/in',
    Occupation: 'Publishing Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841114,
    OccDE: 'Lungenarzt/-ärztin',
    Occupation: 'Pulmonologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 273410,
    OccDE: 'Pumpenschlosser/in',
    Occupation: 'Pump Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531399,
    OccDE: 'Stanzer/in',
    Occupation: 'Punch Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832905,
    OccDE: 'Puppenspieler/in',
    Occupation: 'Puppeteer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 677103,
    OccDE: 'Ein- und Verkäufer/in',
    Occupation: 'Purchase and Sales Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 677104,
    OccDE: 'Einkäufer/in',
    Occupation: 'Purchaser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 677108,
    OccDE: 'Einkaufssachbearbeiter/in',
    Occupation: 'Purchasing Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 677106,
    OccDE: 'Einkaufsassistent/in',
    Occupation: 'Purchasing Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 677201,
    OccDE: 'Einkaufskoordinator/in',
    Occupation: 'Purchasing Coordinator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 677202,
    OccDE: 'Einkaufsleiter/in',
    Occupation: 'Purchasing Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 607102,
    OccDE: 'Einkaufsingenieur/in',
    Occupation: 'Purchasing Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 677107,
    OccDE: 'Einkaufsdisponent/in',
    Occupation: 'Purchasing Junior Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912510,
    OccDE: 'Purser/ette (Luftfahrt)',
    Occupation: 'Purser (Aviation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491310,
    OccDE: 'PVC-Fußbodenleger/in',
    Occupation: 'PVC Flooring Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491312,
    OccDE: 'PVC-Verleger/in',
    Occupation: 'PVC Layer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141604,
    OccDE: 'Feuerwerker/in',
    Occupation: 'Pyrotechnic Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141611,
    OccDE: 'Pyrotechniker/in',
    Occupation: 'Pyrotechnician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 317104,
    OccDE: 'Elektronikfacharbeiter/in (EDV-Anlagen)',
    Occupation: 'Qualified Electronics Worker (EDP Systems)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316314,
    OccDE: 'Elektronikfacharbeiter/in (Geräte, Bau- und Funktionsgruppen)',
    Occupation:
      'Qualified Electronics Worker (Equipment, Construction and Function Groups)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 317201,
    OccDE: 'Elektronikfacharbeiter/in (Bild- und Tonelektronik)',
    Occupation: 'Qualified Electronics Worker (Imaging and Audio Electronics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316315,
    OccDE: 'Elektronikfacharbeiter/in (industrielle Elektronik)',
    Occupation: 'Qualified Electronics Worker (Industrial Electronics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316316,
    OccDE: 'Elektronikfacharbeiter/in (Nachrichtenelektronik)',
    Occupation: 'Qualified Electronics Worker (Maintenance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 317105,
    OccDE: 'Elektronikfacharbeiter/in (Halbleiter, Mikroelektronik)',
    Occupation:
      'Qualified Electronics Worker (Semiconductors, Microelectronics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023838',
    OccDE: 'Pferdewirt/in',
    Occupation: 'Qualified Horse Trainer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023836',
    OccDE: 'Pferdewirt/in (Schwerpunkt Pferdezucht, -haltung)',
    Occupation:
      'Qualified Horse Trainer (specialising in Breeding and Rearing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023837',
    OccDE: 'Pferdewirt/in (Schwerpunkt Reiten)',
    Occupation: 'Qualified Horse Trainer (specialising in Riding)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 754505,
    OccDE: 'Steuerfachmann/-fachfrau',
    Occupation: 'Qualified Tax Specialist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 359002,
    OccDE: 'Facharbeiter/in für Textiltechnik',
    Occupation: 'Qualified Textile Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316327,
    OccDE: 'Facharbeiter/in für elektronische Bauelemente',
    Occupation: 'Qualified Worker in Electronic Components',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316502,
    OccDE: 'Facharbeiter/in für Meß- und Regelungstechnik',
    Occupation: 'Qualified Worker in Measurement and Control Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620109,
    OccDE: 'Qualitätssicherungsangestellte/r',
    Occupation: 'Quality Assurance Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521222,
    OccDE: 'Mitarbeiter/in in der Qualitätskontrolle',
    Occupation: 'Quality Assurance Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600117,
    OccDE: 'Qualitätssicherungsingenieur/in',
    Occupation: 'Quality assurance engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 627605,
    OccDE: 'Qualitätssicher(er/in) (Kunststoffverarbeitung)',
    Occupation: 'Quality Assurance Officer (Plastics Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620110,
    OccDE: 'Qualitätssicherungstechniker/in',
    Occupation: 'Quality assurance Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 521231,
    OccDE: 'Qualitätssicherungsarbeiter/in',
    Occupation: 'Quality Assurance Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521218,
    OccDE: 'Güteprüfer/in',
    Occupation: 'Quality Auditor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521230,
    OccDE: 'Qualitätsprüfer/in',
    Occupation: 'Quality Checker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521217,
    OccDE: 'Gütekontrollmeister/in',
    Occupation: 'Quality Control Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521228,
    OccDE: 'Qualitätskontrolleur/in',
    Occupation: 'Quality Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600114,
    OccDE: 'Qualitätsfachingenieur/in',
    Occupation: 'Quality Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600115,
    OccDE: 'Qualitätsingenieur/in',
    Occupation: 'Quality Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 521229,
    OccDE: 'Qualitätsmeister/in',
    Occupation: 'Quality Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600116,
    OccDE: 'Qualitätsprüfingenieur/in',
    Occupation: 'Quality inspection engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 620108,
    OccDE: 'Qualitätsprüfplaner/in',
    Occupation: 'Quality Inspection Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521227,
    OccDE: 'Qualitätsinspekteur/in',
    Occupation: 'Quality Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997190,
    OccDE: 'Qualitätsmanager/in',
    Occupation: 'Quality Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620107,
    OccDE: 'Qualitätsplaner/in',
    Occupation: 'Quality Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620111,
    OccDE: 'Qualitätstechniker/in',
    Occupation: 'Quality Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: '080761',
    OccDE: 'Steinbrucharbeiter',
    Occupation: 'Quarry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 233002,
    OccDE: 'Metallvergüter/in',
    Occupation: 'Quench Hardener',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771501,
    OccDE: 'Angebotsbearbeiter/in (Kalkulation)',
    Occupation: 'Quote Agent (Calculation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771502,
    OccDE: 'Angebotskalkulator/in',
    Occupation: 'Quote Calculator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788101,
    OccDE: 'Angebotssachbearbeiter/in',
    Occupation: 'Quote Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771504,
    OccDE: 'Angebotssachbearbeiter/in (Kalkulator/in)',
    Occupation: 'Quote Clerk (Calculator)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771503,
    OccDE: 'Angebotsplaner/in',
    Occupation: 'Quote Planner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622509,
    OccDE: 'Radartechniker/in',
    Occupation: 'Radar Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 626616,
    OccDE: 'Strahlenschutzassistent/in',
    Occupation: 'Radiation Protection Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 612515,
    OccDE: 'Strahlenschutzingenieur/in',
    Occupation: 'Radiation Protection Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 803512,
    OccDE: 'Strahlenschutzbeauftragte/r',
    Occupation: 'Radiation Protection Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626617,
    OccDE: 'Strahlenschutztechniker/in',
    Occupation: 'Radiation Protection Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841609,
    OccDE: 'Strahlentherapeut/in (Radiologe/-in)',
    Occupation: 'Radiation Therapist (Radiologist)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 315109,
    OccDE: 'Radio- und Fernsehmechaniker/in',
    Occupation: 'Radio and Television Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315115,
    OccDE: 'Rundfunk- und Fernsehmechaniker/in',
    Occupation: 'Radio and Television Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315702,
    OccDE: 'Radio- und Fernsehtechnikerhelfer/in',
    Occupation: 'Radio and Television Mechanic Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315110,
    OccDE: 'Radio- und Fernsehtechniker/in (Mechaniker/in)',
    Occupation: 'Radio and Television Technician (Mechanic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315117,
    OccDE: 'Rundfunk- und Fernsehtechniker/in (Mechaniker/in)',
    Occupation: 'Radio and Television Technician (Mechanic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821605,
    OccDE: 'Rundfunksprecher/in',
    Occupation: 'Radio Announcer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 317207,
    OccDE: 'Funkelektroniker/in',
    Occupation: 'Radio Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602508,
    OccDE: 'Funkingenieur/in',
    Occupation: 'Radio Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 315120,
    OccDE: 'Rundfunkmechaniker/in',
    Occupation: 'Radio Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622505,
    OccDE: 'Funktechniker/in',
    Occupation: 'Radio Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 622510,
    OccDE: 'Radiotechniker/in',
    Occupation: 'Radio Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 622511,
    OccDE: 'Rundfunktechniker/in',
    Occupation: 'Radio Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 317213,
    OccDE: 'Funkmechaniker/in',
    Occupation: 'Radio Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841606,
    OccDE: 'Radiolog(e/in) (Arzt/Ärztin)',
    Occupation: 'Radiologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 612510,
    OccDE: 'Radiolog(e/in)',
    Occupation: 'Radiologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 857215,
    OccDE: 'Radiologieassistent/in',
    Occupation: 'Radiology Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744601,
    OccDE: 'Bahnarbeiter/in (Eisenbahn)',
    Occupation: 'Railroad Worker (Railway)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712011,
    OccDE: 'Eisenbahner/in',
    Occupation: 'Railroader',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712010,
    OccDE: 'Eisenbahner/in im Betriebsdienst',
    Occupation: 'Railroader in Service',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 711199,
    OccDE: 'Eisenbahner/in im Betriebsdienst -   Lokführer und Transport',
    Occupation: 'Railroader in Service - Locomotive Engineers and Transport',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603404,
    OccDE: 'Eisenbahnbauingenieur/in',
    Occupation: 'Railway Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 274102,
    OccDE: 'Bahnbetriebsschlosser/in',
    Occupation: 'Railway Equipment Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521507,
    OccDE: 'Rampenmanager/in',
    Occupation: 'Ramp Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '061429',
    OccDE: 'Waldhüter/in',
    Occupation: 'Ranger',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626613,
    OccDE: 'Reaktorfahrer/in',
    Occupation: 'Reactor Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704520,
    OccDE: 'Immobilienkaufmann/-kauffrau',
    Occupation: 'Real estate Agent',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 704524,
    OccDE: 'Immobilienwirt/in',
    Occupation: 'Real Estate Agent',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 704523,
    OccDE: 'Immobilienvermittler/in',
    Occupation: 'Real estate Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704507,
    OccDE: 'Grundstücks- und Wohnungswirtschaftskaufmann/-kauffrau',
    Occupation: 'Real Estate and Residential Housing Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 704521,
    OccDE: 'Immobilienmakler/in',
    Occupation: 'Real Estate Broker',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 704519,
    OccDE: 'Immobilienhändler/in',
    Occupation: 'Real Estate Broker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704517,
    OccDE: 'Immobilienberater/in',
    Occupation: 'Real Estate Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704522,
    OccDE: 'Immobilienverkäufer/in',
    Occupation: 'Real Estate Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705814,
    OccDE: 'Immobilientaxator/in',
    Occupation: 'Real Estate Valuation Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704508,
    OccDE: 'Grundstücksmakler/in',
    Occupation: 'Realtor',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 914505,
    OccDE: 'Empfangsangestellte/r (Hotel)',
    Occupation: 'Reception Employee (Hotel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914512,
    OccDE: 'Empfangssekretär/in (Hotel)',
    Occupation: 'Reception Secretary (Hotel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788803,
    OccDE: 'Empfangssekretär/in (nicht Hotel)',
    Occupation: 'Reception Secretary (not Hotel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788802,
    OccDE: 'Empfangsbürokraft',
    Occupation: 'Reception Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914599,
    OccDE: 'Rezeptionskraft',
    Occupation: 'Reception Staff Member',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921502,
    OccDE: 'Empfangsdame (Hausdame)',
    Occupation: 'Receptionist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914523,
    OccDE: 'Receptionist/in (Hotelempfang)',
    Occupation: 'Receptionist (Hotel Reception)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914528,
    OccDE: 'Rezeptionist/in (Hotel)',
    Occupation: 'Receptionist (Hotel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788804,
    OccDE: 'Receptionist/in (nicht Hotelempfang)',
    Occupation: 'Receptionist (not Hotel Reception)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914508,
    OccDE: 'Empfangsdame (Empfangsbüro)',
    Occupation: 'Receptionist (Reception Office)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660804,
    OccDE: 'Empfangsdame (Verkauf)',
    Occupation: 'Receptionist (Sales)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 811156,
    OccDE: 'Richteranwärter/in',
    Occupation: 'Recorder',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 757499,
    OccDE: 'Personalvermittler/in',
    Occupation: 'Recruiter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 757403,
    OccDE: 'Personalberater/in',
    Occupation: 'Recruitment Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 757402,
    OccDE: 'Personalberater/in (Unternehmensberater/in)',
    Occupation: 'Recruitment Consultant (Business Consultant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 870804,
    OccDE: 'Rektor/in',
    Occupation: 'Rector',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 872804,
    OccDE: 'Rektor/in (an Gymnasien)',
    Occupation: 'Rector (at Secondary Schools Gymnasium)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 873813,
    OccDE: 'Rektor/in (an Grund- und Hauptschulen)',
    Occupation: 'Rector (Primary to Secondary Education)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 997114,
    OccDE: 'Recycler/in',
    Occupation: 'Recycler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628242,
    OccDE: 'REFA-Fachmann/Fachfrau für Fertigungsorganisation',
    Occupation: 'REFA Consultant for Production Organisation',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 879610,
    OccDE: 'REFA-Lehrer/in',
    Occupation: 'REFA Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628245,
    OccDE: 'REFA-Mann/REFA-Frau',
    Occupation: 'REFA Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 628246,
    OccDE: 'REFA-Sachbearbeiter/in',
    Occupation: 'REFA Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 628243,
    OccDE: 'REFA-Fachmann/Fachfrau',
    Occupation: 'REFA Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 628247,
    OccDE: 'REFA-Techniker/in',
    Occupation: 'REFA Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 764036,
    OccDE: 'Referent/in',
    Occupation: 'Referendary',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 764012,
    OccDE: 'Assessor/in',
    Occupation: 'Referendary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764034,
    OccDE: 'Referendar/in (Verwaltung, höherer Dienst)',
    Occupation:
      'Referendary (Administrative Department, highest civil service)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 764035,
    OccDE: 'Referent/in (Verwaltung, höherer Dienst)',
    Occupation:
      'Referendary (Administrative Department, highest civil service)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 661607,
    OccDE: 'Trinkhallenverkäufer/in',
    Occupation: 'Refreshment Shop Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 266203,
    OccDE: 'Kälte- und Klimaanlagenmonteur/in',
    Occupation: 'Refrigeration and Air Conditioning Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621521,
    OccDE: 'Kälte- und Klimatechniker',
    Occupation: 'Refrigeration and Air Conditioning Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261301,
    OccDE: 'Kühlerbauer/in',
    Occupation: 'Refrigeration Equipment Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621508,
    OccDE: 'Kältemaschinentechniker/in',
    Occupation: 'Refrigeration Equipment Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 266207,
    OccDE: 'Kältemonteur/in',
    Occupation: 'Refrigeration Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 266102,
    OccDE: 'Kälteanlagenbauer/in',
    Occupation: 'Refrigeration Systems Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 266105,
    OccDE: 'Kühlanlagenbauer/in',
    Occupation: 'Refrigeration Systems Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 266210,
    OccDE: 'Kühlanlagenmonteur/in',
    Occupation: 'Refrigeration Systems Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 266204,
    OccDE: 'Kälteanlageninstallateur/in',
    Occupation: 'Refrigeration Systems Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 266209,
    OccDE: 'Kühlanlageninstallateur/in',
    Occupation: 'Refrigeration Systems Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621509,
    OccDE: 'Kältetechniker/in',
    Occupation: 'Refrigeration Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 804101,
    OccDE: 'Bezirksschornsteinfeger/in',
    Occupation: 'Regional Chimney Sweep',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751313,
    OccDE: 'Regionalleiter/in',
    Occupation: 'Regional Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705136,
    OccDE: 'Regionalleiter/in (Autovermietung)',
    Occupation: 'Regional Manager (Car Hire)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788647,
    OccDE: 'Registrator/in',
    Occupation: 'Registrar',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 812120,
    OccDE: 'Urkundsbeamt(er/in)',
    Occupation: 'Registrar Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 788648,
    OccDE: 'Registraturgehilf(e/in)',
    Occupation: 'Registry Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765633,
    OccDE: 'Standesbeamt(er/in)',
    Occupation: 'Registry Civil Servant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 801183,
    OccDE: 'Zeitsoldat',
    Occupation: 'Regular Soldier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801189,
    OccDE: 'Zeitsoldat (Stabsdienst)',
    Occupation: 'Regular Soldier (Staff Service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622414,
    OccDE: 'Regelungs- und Steuerungstechniker/in',
    Occupation: 'Regulation and Open Loop Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 863901,
    OccDE: 'Arbeitserzieher/in',
    Occupation: 'Rehabilitation and Occupational Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861324,
    OccDE: 'Rehabilitationsberater/in (nicht Arbeitsamt)',
    Occupation: 'Rehabilitation Consultant (not Employment Office)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861325,
    OccDE: 'Rehabilitationspädagog(e/in)',
    Occupation: 'Rehabilitation Pedagogue',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 307108,
    OccDE: 'Rehabilitationsmechaniker/in',
    Occupation: 'Rehabilitation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442011,
    OccDE: 'Stahlbetonbauer',
    Occupation: 'Reinforced Concrete Construction Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873606,
    OccDE: 'Religionslehrer/in',
    Occupation: 'Religion Teacher',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 873607,
    OccDE: 'Religionspädagog(e/in)',
    Occupation: 'Religious Education Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873699,
    OccDE: 'Diplom-Religionspädagog(e/in)',
    Occupation: 'Religious Education Teacher with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 891409,
    OccDE: 'Ordenspriester',
    Occupation: 'Religious Priest',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 882409,
    OccDE: 'Religionswissenschaftler/in',
    Occupation: 'Religious Studies Scholar',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 743205,
    OccDE: 'Möbelträger/in',
    Occupation: 'Removal Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 743203,
    OccDE: 'Möbelpacker/in (Transportwerker/in)',
    Occupation: 'Removal Worker (Transport Workers)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 786199,
    OccDE: 'RENO-Gehilf(e/in)',
    Occupation:
      'RENO Assistant (German Association of Attorney and Notary Assistants)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 623909,
    OccDE: 'Sanierungstechniker/in',
    Occupation: 'Renovation Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705143,
    OccDE: 'Vermietrepräsentant/in',
    Occupation: 'Rental Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788934,
    OccDE: 'Reparaturannehmer/in',
    Occupation: 'Repair Acceptance Inspection Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 274009,
    OccDE: 'Reparaturmechaniker/in',
    Occupation: 'Repair Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269020,
    OccDE: 'Reparaturmonteur/in',
    Occupation: 'Repair Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 274010,
    OccDE: 'Reparaturschlosser/in',
    Occupation: 'Repairman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662402,
    OccDE: 'Ersatzteilverkäufer/in (Kfz)',
    Occupation: 'Replacement Parts Salesperson (Automotive)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821519,
    OccDE: 'Reporter/in',
    Occupation: 'Reporter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 761101,
    OccDE: 'Abgeordnete/r',
    Occupation: 'Representative',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 689021,
    OccDE: 'Vertreter/in',
    Occupation: 'Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689209,
    OccDE: 'Repräsentant/in',
    Occupation: 'Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 692108,
    OccDE: 'Vertreter/in (Bausparkasse)',
    Occupation: 'Representative (Building Society)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695436,
    OccDE: 'Vertreter/in (Versicherung)',
    Occupation: 'Representative (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683906,
    OccDE: 'Repräsentant/in (Verlag)',
    Occupation: 'Representative (Publishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627805,
    OccDE: 'Reproduktionstechniker/in',
    Occupation: 'Reproduction Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627809,
    OccDE: 'Repro-Techniker/in',
    Occupation: 'Reproduction Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172003,
    OccDE: 'Facharbeiter/in für Reproduktionstechnik',
    Occupation: 'Reproduction Technology Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172217,
    OccDE: 'Reprohersteller/in',
    Occupation: 'Reprographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 176003,
    OccDE: 'Reprograf/in',
    Occupation: 'Reprographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172216,
    OccDE: 'Reprofotograf/in',
    Occupation: 'Reprophotographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713517,
    OccDE: 'Rettungsleitstellenleiter/in',
    Occupation: 'Rescue Coordination Centre Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 803801,
    OccDE: 'Bergungstaucher/in',
    Occupation: 'Rescue Diver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854208,
    OccDE: 'Rettungsdiensthelfer/in',
    Occupation: 'Rescue Service Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 880204,
    OccDE: 'Wissenschaftliche(r) Assistent/in',
    Occupation: 'Research Assistant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 997120,
    OccDE: 'Wissenschaftliche Hilfskraft',
    Occupation: 'Research Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633004,
    OccDE: 'Forschungsassistent/in (Chemielabor)',
    Occupation: 'Research Assistant (Chemistry Lab)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 880203,
    OccDE: 'Wissenschaftliche(r) Angestellte/r',
    Occupation: 'Research Associate',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 880205,
    OccDE: 'Wissenschaftliche(r) Mitarbeiter/in',
    Occupation: 'Research Associate',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 764434,
    OccDE: 'Wissenschaftliche(r) Mitarbeiter/in (Verwaltung)',
    Occupation: 'Research Associate (Administration)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 871304,
    OccDE: 'Wissenschaftliche(r) Angestellte/r (Hochschule)',
    Occupation: 'Research Associate (University College)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 871306,
    OccDE: 'Wissenschaftliche(r) Mitarbeiter/in (Hochschule)',
    Occupation: 'Research Associate (University College)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 823342,
    OccDE: 'Wissenschaftliche(r) Dokumentar/in',
    Occupation: 'Research Documentalist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 600104,
    OccDE: 'Forschungsingenieur/in',
    Occupation: 'Research Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 880103,
    OccDE: 'Forschungsmitarbeiter/in',
    Occupation: 'Research Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620104,
    OccDE: 'Forschungstechniker/in',
    Occupation: 'Research Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 880101,
    OccDE: 'Forscher/in',
    Occupation: 'Researcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702113,
    OccDE: 'Reservierungsangestellte/r',
    Occupation: 'Reservations Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914526,
    OccDE: 'Reservierungsleiter/in (Hotel)',
    Occupation: 'Reservations Manager (Hotel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861909,
    OccDE: 'Heimleiter/in',
    Occupation: 'Residence Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 865099,
    OccDE: 'Haus- und Familienpfleger/in',
    Occupation: 'Residential and Family Care Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 864702,
    OccDE: 'Haus- und Altenpflegehelfer/in',
    Occupation: 'Residential and Geriatric Care Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863302,
    OccDE: 'Erzieher/in für Heime',
    Occupation: 'Residential Education Institution Housemaster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 859901,
    OccDE: 'Atemtherapeut/in',
    Occupation: 'Respiratory Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915103,
    OccDE: 'Gaststättengehilf(e/in)',
    Occupation: 'Restaurant Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914205,
    OccDE: 'Restaurantdirektionsassistent/in',
    Occupation: 'Restaurant Assistant Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 773906,
    OccDE: 'Restaurantkassierer/in',
    Occupation: 'Restaurant Cashier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411042,
    OccDE: 'Restaurantkoch/-köchin',
    Occupation: 'Restaurant Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911411,
    OccDE: 'Gaststättenbetriebsleiter/in',
    Occupation: 'Restaurant Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911433,
    OccDE: 'Restaurantleiter/in',
    Occupation: 'Restaurant Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915399,
    OccDE: 'Restaurantangestellte/r',
    Occupation: 'Restaurant Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912113,
    OccDE: 'Restaurant-Oberkellner/in',
    Occupation: 'Restaurant Head Waiter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912099,
    OccDE: 'Restauranthilfe',
    Occupation: 'Restaurant Help',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911414,
    OccDE: 'Gaststättenleiter/in',
    Occupation: 'Restaurant Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911429,
    OccDE: 'Restaurantchef/in',
    Occupation: 'Restaurant Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911434,
    OccDE: 'Restaurantmanager',
    Occupation: 'Restaurant Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911412,
    OccDE: 'Gaststättengeschäftsführer/in',
    Occupation: 'Restaurant Managing Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911431,
    OccDE: 'Restaurantgeschäftsführer/in',
    Occupation: 'Restaurant Managing Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911428,
    OccDE: 'Restaurantbetriebsleiter/in',
    Occupation: 'Restaurant Operations Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911410,
    OccDE: 'Gaststättenbesitzer/in',
    Occupation: 'Restaurant Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911413,
    OccDE: 'Gaststätteninhaber/in',
    Occupation: 'Restaurant Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911427,
    OccDE: 'Restaurantbesitzer/in',
    Occupation: 'Restaurant Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911432,
    OccDE: 'Restaurantinhaber/in',
    Occupation: 'Restaurant Proprietor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914003,
    OccDE: 'Gaststättenkaufmann/-kauffrau',
    Occupation: 'Restaurant Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912014,
    OccDE: 'Restaurantkellner/in',
    Occupation: 'Restaurant Server',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912802,
    OccDE: 'Restaurantmeister/in',
    Occupation: 'Restaurant Services Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912012,
    OccDE: 'Restaurantfachkraft',
    Occupation: 'Restaurant Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912013,
    OccDE: 'Restaurantfachmann/Restaurantfachfrau',
    Occupation: 'Restaurant Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915102,
    OccDE: 'Gaststättenfacharbeiter/in',
    Occupation: 'Restaurant Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911002,
    OccDE: 'Gastronom/in',
    Occupation: 'Restaurateur',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 823811,
    OccDE: 'Restaurator/in',
    Occupation: 'Restaurateur',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911101,
    OccDE: 'Gastwirt/in und Hotelier',
    Occupation: 'Restaurateur and Hotelier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481405,
    OccDE: 'Restaurator/in im Stukkateurhandwerk',
    Occupation: 'Restaurateur in Plastering',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501607,
    OccDE: 'Restaurator/in (Holz)',
    Occupation: 'Restorer (Timber)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101108,
    OccDE: 'Restaurator/in im Steinmetz- und Steinbildhauerhandwerk',
    Occupation: 'Restorer in Stonemasonry and Sculpting',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 674106,
    OccDE: 'Sortimentsbuchhändler/in',
    Occupation: 'Retail Bookseller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401404,
    OccDE: 'Verkaufsmetzger/in',
    Occupation: 'Retail Butcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 672003,
    OccDE: 'Einzelhandelskaufmann/-kauffrau',
    Occupation: 'Retail Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673599,
    OccDE: 'Kaufmann/Kauffrau im Einzelhandel -  Fachbereich Tankstellen',
    Occupation: 'Retail Clerk (Petrol Stations)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673910,
    OccDE: 'Einzelhandelskaufmann/-kauffrau im Zoofachhandel',
    Occupation: 'Retail Clerk for Pet Supply Shops',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673909,
    OccDE: 'Einzelhandelskaufmann/-kauffrau für Pflanzen und Gartenbedarf',
    Occupation: 'Retail Clerk for Plants and Garden Supplies',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 672001,
    OccDE: 'Einzelhändler/in',
    Occupation: 'Retailer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981105,
    OccDE: 'Umschüler/in',
    Occupation: 'Retrainee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702204,
    OccDE: 'Kurverwaltungsfachmann/-fachfrau',
    Occupation: 'Retreat Services Management Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787215,
    OccDE: 'Steuerassistent/in',
    Occupation: 'Revenue Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787217,
    OccDE: 'Steuerbeamt(er/in)',
    Occupation: 'Revenue Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787216,
    OccDE: 'Steuerbeamt(er/in) (mittlerer Dienst)',
    Occupation: 'Revenue Civil Servant (mid-level service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765217,
    OccDE: 'Steuersachbearbeiter/in',
    Occupation: 'Revenue Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 765210,
    OccDE: 'Steuer(amtmann/amtfrau)',
    Occupation: 'Revenue Deputy Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787219,
    OccDE: 'Steuerobersekretär/in',
    Occupation: 'Revenue Higher Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765214,
    OccDE: 'Steuerinspektor/in',
    Occupation: 'Revenue Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787214,
    OccDE: 'Steueramtsinspektor/in',
    Occupation: 'Revenue Higher Executive Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787299,
    OccDE: 'Steueranwärter/in',
    Occupation: 'Revenue Referendary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787220,
    OccDE: 'Steuersekretär/in',
    Occupation: 'Revenue Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787218,
    OccDE: 'Steuerhauptsekretär/in',
    Occupation: 'Revenue Senior Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765212,
    OccDE: 'Steueramts(rat/rätin)',
    Occupation: 'Revenue Senior Deputy Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 765216,
    OccDE: 'Steueroberinspektor/in',
    Occupation: 'Revenue Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 876602,
    OccDE: 'Reitlehrer/in',
    Occupation: 'Riding Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 859904,
    OccDE: 'Reittherapeut/in',
    Occupation: 'Riding Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 349514,
    OccDE: 'Rigger/in',
    Occupation: 'Rigger',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 743118,
    OccDE: 'Lascher/in (Hafen)',
    Occupation: 'Rigger (Port)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801404,
    OccDE: 'Bereitschaftspolizist/in',
    Occupation: 'Riot Police Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623402,
    OccDE: 'Straßenbautechniker/in',
    Occupation: 'Road Construction Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461106,
    OccDE: 'Meister des Straßenbauerhandwerks',
    Occupation: 'Road Construction Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461116,
    OccDE: 'Straßenbau(er)meister',
    Occupation: 'Road Construction Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 546608,
    OccDE: 'Straßenbaumaschinenführer/in',
    Occupation: 'Road Construction Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461117,
    OccDE: 'Straßenbaupolier',
    Occupation: 'Road Construction Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461008,
    OccDE: 'Straßenbauvorarbeiter',
    Occupation: 'Road construction Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461007,
    OccDE: 'Straßenbaufacharbeiter',
    Occupation: 'Road Construction Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461005,
    OccDE: 'Straßenbauarbeiter',
    Occupation: 'Road Construction Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461006,
    OccDE: 'Straßenbauer',
    Occupation: 'Road Construction Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603407,
    OccDE: 'Straßenbauingenieur/in',
    Occupation: 'Road Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 716003,
    OccDE: 'Straßenwärter/in',
    Occupation: 'Road Maintenance Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 716103,
    OccDE: 'Straßenmeister/in',
    Occupation: 'Road Maintenance Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 716004,
    OccDE: 'Straßenwart/in',
    Occupation: 'Road Maintenance Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 716002,
    OccDE: 'Straßenaufseher/in',
    Occupation: 'Road Maintenance Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 716305,
    OccDE: 'Straßenunterhaltungsarbeiter/in',
    Occupation: 'Road Maintenance Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461729,
    OccDE: 'Straßenarbeiter',
    Occupation: 'Road Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281202,
    OccDE: 'Pannendienstfahrer/in',
    Occupation: 'Roadside Assistance Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281203,
    OccDE: 'Pannenhilfsdienstfahrer/in',
    Occupation: 'Roadside Assistance Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281204,
    OccDE: 'Straßenwachtfahrer/in',
    Occupation: 'Roadway Safety Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831453,
    OccDE: 'Rockmusiker/in',
    Occupation: 'Rock Musician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 225417,
    OccDE: 'Walzenschleifer/in (Metallschleifer/in)',
    Occupation: 'Roll Grinder (Metal Grinder)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 259114,
    OccDE: 'Rolladen- und Jalousiebauer/in',
    Occupation: 'Roller Shutter and Window Blind Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 259120,
    OccDE: 'Rolladenmonteur/in',
    Occupation: 'Roller Shutter Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 259118,
    OccDE: 'Rolladenbauer/in',
    Occupation: 'Roller Shutter Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 194376,
    OccDE: 'Walzwerker/in',
    Occupation: 'Rolling Mill Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273933,
    OccDE: 'Schienenfahrzeugschlosser/in',
    Occupation: 'Rolling Stock Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882328,
    OccDE: 'Romanist/in',
    Occupation: 'Romance Studies Scholar',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 488001,
    OccDE: 'Dachdecker',
    Occupation: 'Roofer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 488510,
    OccDE: 'Dachdecker und Klempner',
    Occupation: 'Roofer and Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 488511,
    OccDE: 'Dachdecker und Spengler',
    Occupation: 'Roofer and Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 488701,
    OccDE: 'Dachdeckerhelfer',
    Occupation: 'Roofing Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 488002,
    OccDE: 'Dachdeckergeselle',
    Occupation: 'Roofing Journeyman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 332307,
    OccDE: 'Seiler/in',
    Occupation: 'Ropemaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931516,
    OccDE: 'Heißmangler/in',
    Occupation: 'Rotary Ironer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931547,
    OccDE: 'Wäschemangler/in',
    Occupation: 'Rotary Ironer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 179327,
    OccDE: 'Rotationsarbeiter/in',
    Occupation: 'Rotary Press Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 174909,
    OccDE: 'Rotationsdrucker/in',
    Occupation: 'Rotary Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145009,
    OccDE: 'Gummihersteller/in',
    Occupation: 'Rubber Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145005,
    OccDE: 'Gummibetriebsfachwerker/in',
    Occupation: 'Rubber Plant Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145010,
    OccDE: 'Gummiverarbeiter/in',
    Occupation: 'Rubber Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627601,
    OccDE: 'Kautschuktechniker/in',
    Occupation: 'Rubber Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145008,
    OccDE: 'Gummifacharbeiter/in',
    Occupation: 'Rubber Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145011,
    OccDE: 'Gummiwerker/in',
    Occupation: 'Rubber Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145717,
    OccDE: 'Gummiarbeiter/in',
    Occupation: 'Rubber Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935201,
    OccDE: 'Abfallbeseitiger/in',
    Occupation: 'Rubbish Disposal Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935715,
    OccDE: 'Müllablader/in',
    Occupation: 'Rubbish Dumper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714510,
    OccDE: 'Müllfahrer/in',
    Occupation: 'Rubbish Lorry Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 523201,
    OccDE: 'Abfallsortierer/in',
    Occupation: 'Rubbish Sorter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 894211,
    OccDE: 'Mesner/in',
    Occupation: 'Sacristan',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 374120,
    OccDE: 'Sattler/in',
    Occupation: 'Saddler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608241,
    OccDE: 'Sicherheitsberater/in (technische/r)',
    Occupation: 'Safety Consultant (Technical)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 803001,
    OccDE: 'Sicherheitskontrolleur/in',
    Occupation: 'Safety Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608242,
    OccDE: 'Sicherheitsingenieur/in (nicht Bergbau)',
    Occupation: 'Safety Engineer (not Mining)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 712307,
    OccDE: 'Sicherungsposten (Eisenbahnverkehr)',
    Occupation: 'Safety guard (Rail Transport)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801334,
    OccDE: 'Sicherheitsinspektor/in',
    Occupation: 'Safety Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608240,
    OccDE: 'Sicherheitsbeauftragte/r',
    Occupation: 'Safety Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 629208,
    OccDE: 'Sicherheitsfachkraft',
    Occupation: 'Safety Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629210,
    OccDE: 'Sicherheitstechniker/in (nicht Wach- und Sicherheitsgewerbe)',
    Occupation: 'Safety Technician (Surveillance and Security Industry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 358101,
    OccDE: 'Segelmacher/in',
    Occupation: 'Sailmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 723004,
    OccDE: 'Seemann',
    Occupation: 'Sailor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 723208,
    OccDE: 'Matrose',
    Occupation: 'Sailor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 757104,
    OccDE: 'Verkaufsberater/in (Vertriebsberater/in)',
    Occupation: 'Sales Advisor (Sales Consultant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660809,
    OccDE: 'Verkaufsberater/in (Verkäufer/in)',
    Occupation: 'Sales Advisor (Salesperson)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 678104,
    OccDE: 'Verkaufssachbearbeiter/in',
    Occupation: 'Sales Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 678108,
    OccDE: 'Vertriebssachbearbeiter/in',
    Occupation: 'Sales Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 678102,
    OccDE: 'Verkaufsassistent/in',
    Occupation: 'Sales Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 678106,
    OccDE: 'Vertriebsassistent/in',
    Occupation: 'Sales Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660007,
    OccDE: 'Verkaufsgehilf(e/in)',
    Occupation: 'Sales Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660705,
    OccDE: 'Verkaufshelfer/in',
    Occupation: 'Sales Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 914204,
    OccDE: 'Kaufmännische(r) Assistent/in (Hotel- und Gaststättengewerbe)',
    Occupation: 'Sales Assistant (Hotels and Restaurants)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661701,
    OccDE: 'Verkaufshilfe im Lebensmittel-Einzelhandel',
    Occupation: 'Sales Assistant at a Food Retailer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 773104,
    OccDE: 'Verkaufskassierer/in',
    Occupation: 'Sales Cashier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 678101,
    OccDE: 'Expedient/in (Verkaufssachbearbeiter/in)',
    Occupation: 'Sales Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 678107,
    OccDE: 'Vertriebskaufmann/-kauffrau',
    Occupation: 'Sales Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662803,
    OccDE: 'Verkaufsberater/in',
    Occupation: 'Sales Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662201,
    OccDE: 'Fachverkäufer/in (Bekleidung)',
    Occupation: 'Sales Consultant (Apparel)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662307,
    OccDE: 'Fachverkäufer/in (Waren des täglichen Bedarfs)',
    Occupation: 'Sales Consultant (Daily Goods)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662305,
    OccDE: 'Fachverkäufer/in (Heimwerkerbedarf)',
    Occupation: 'Sales Consultant (DIY Supplies)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 777205,
    OccDE: 'Vertriebsberater/in (EDV)',
    Occupation: 'Sales Consultant (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 662103,
    OccDE: 'Fachverkäufer/in (Unterhaltungselektronik)',
    Occupation: 'Sales Consultant (Entertainment Electronics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661803,
    OccDE: 'Verkaufsberater/in (Nahrungs-, Genußmittel)',
    Occupation: 'Sales Consultant (Food, Semi-luxury Products)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661001,
    OccDE: 'Fachverkäufer/in (Lebensmittel)',
    Occupation: 'Sales Consultant (Food)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662304,
    OccDE: 'Fachverkäufer/in (Haushaltswaren)',
    Occupation: 'Sales Consultant (Homewares)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662306,
    OccDE: 'Fachverkäufer/in (Innenausstattung, Möbel)',
    Occupation: 'Sales Consultant (Interior Decorating, Furniture)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 757105,
    OccDE: 'Vertriebsberater/in (nicht EDV)',
    Occupation: 'Sales Consultant (not EDP)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662303,
    OccDE: 'Fachverkäufer/in (Farben, Lacke, Tapeten)',
    Occupation: 'Sales Consultant (Paints, Varnishes, Wallpaper)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662101,
    OccDE: 'Fachverkäufer/in (Foto, Optik, Uhren, Schmuck)',
    Occupation: 'Sales consultant (Photo, Optics, Watches, Jewellery)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662202,
    OccDE: 'Fachverkäufer/in (Sportartikel)',
    Occupation: 'Sales Consultant (Sports Articles)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662203,
    OccDE: 'Fachverkäufer/in (Textil-, Lederwaren, Schuhe)',
    Occupation: 'Sales Consultant (Textiles, Leather Goods, Shoes)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662102,
    OccDE: 'Fachverkäufer/in (Spielwaren, Bürobedarf)',
    Occupation: 'Sales Consultant (Toys, Office Supplies)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662403,
    OccDE: 'Fachverkäufer/in (Fahrzeuge, Zubehör, Ersatzteile)',
    Occupation: 'Sales Consultant (Vehicles, Accessories, Spare Parts)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662501,
    OccDE: 'Fachverkäufer/in für Sanitätswaren',
    Occupation: 'Sales Consultant for Medical Supplies',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689513,
    OccDE: 'Verkaufsberater/in im Außendienst',
    Occupation: 'Sales Consultant in Field Sales',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661101,
    OccDE: 'Fachverkäufer/in im Nahrungsmittelhandwerk',
    Occupation: 'Sales Consultant in the Food Trade',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662601,
    OccDE: 'Fachverkäufer/in für Uhren und Schmuck',
    Occupation: 'Sales Consultant Watches and Jewellery',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 755202,
    OccDE: 'Vertriebs-Controller/in',
    Occupation: 'Sales Controller',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 676202,
    OccDE: 'Verkaufschef/in',
    Occupation: 'Sales Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683305,
    OccDE: 'Verkaufsleiter/in (Verlag)',
    Occupation: 'Sales Director (Publishing)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 683309,
    OccDE: 'Vertriebsleiter/in (Verlag)',
    Occupation: 'Sales Director (Publishing)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 678105,
    OccDE: 'Vertriebsangestellte/r',
    Occupation: 'Sales Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 777207,
    OccDE: 'Vertriebsmitarbeiter/in (EDV)',
    Occupation: 'Sales Employee (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 607117,
    OccDE: 'Vertriebsingenieur/in',
    Occupation: 'Sales Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 607115,
    OccDE: 'Verkaufsingenieur/in',
    Occupation: 'Sales Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '051904',
    OccDE: 'Verkaufsgärtner/in',
    Occupation: 'Sales Gardener',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 676208,
    OccDE: 'Vertriebschef/in',
    Occupation: 'Sales Head',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660706,
    OccDE: 'Verkaufshilfe',
    Occupation: 'Sales Help',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 676209,
    OccDE: 'Vertriebsinspektor/in',
    Occupation: 'Sales Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751314,
    OccDE: 'Sales Manager',
    Occupation: 'Sales Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751318,
    OccDE: 'Verkaufsdirektor/in',
    Occupation: 'Sales Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751324,
    OccDE: 'Vertriebsmanager',
    Occupation: 'Sales Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751319,
    OccDE: 'Verkaufsleiter/in',
    Occupation: 'Sales Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751320,
    OccDE: 'Vertriebsdirektor/in',
    Occupation: 'Sales Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751323,
    OccDE: 'Vertriebsleiter/in',
    Occupation: 'Sales Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751325,
    OccDE: 'Verkaufsmanager/in',
    Occupation: 'Sales Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751322,
    OccDE: 'Vertriebsleiter/in (nicht Verlag)',
    Occupation: 'Sales Manager (not Publishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 676205,
    OccDE: 'Verkaufsleiter/in (Handel)',
    Occupation: 'Sales Manager (Trade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 676206,
    OccDE: 'Verkaufsleiter/in im Nahrungsmittelhandwerk',
    Occupation: 'Sales Manager in the Food Trade',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756151,
    OccDE: 'Vertriebsorganisator/in',
    Occupation: 'Sales Organiser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 676308,
    OccDE: 'Verkaufsstellenverwalter/in',
    Occupation: 'Sales Outlet Administrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 676307,
    OccDE: 'Verkaufsstellenleiter/in',
    Occupation: 'Sales Outlet Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 755302,
    OccDE: 'Vertriebsplaner/in',
    Occupation: 'Sales Planner',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 703211,
    OccDE: 'Salespromoter',
    Occupation: 'Sales Promoter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 703213,
    OccDE: 'Verkaufsförder(er/in)',
    Occupation: 'Sales Promotion Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 687202,
    OccDE: 'Verkaufsbeauftragte/r',
    Occupation: 'Sales Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689210,
    OccDE: 'Verkaufsrepräsentant/in',
    Occupation: 'Sales Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689212,
    OccDE: 'Vertriebsrepräsentant/in',
    Occupation: 'Sales Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 777204,
    OccDE: 'Vertriebsbeauftragte/r (EDV)',
    Occupation: 'Sales Representative (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 687203,
    OccDE: 'Vertriebsbeauftragte/r (nicht EDV)',
    Occupation: 'Sales Representative (not EDP)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789209,
    OccDE: 'Vertriebssekretär/in',
    Occupation: 'Sales Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 755902,
    OccDE: 'Vertriebsfachmann/-fachfrau',
    Occupation: 'Sales Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 660808,
    OccDE: 'Verkaufsaufsicht',
    Occupation: 'Sales Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628105,
    OccDE: 'Verkaufstechniker/in',
    Occupation: 'Sales Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 628106,
    OccDE: 'Vertriebstechniker/in',
    Occupation: 'Sales Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 879528,
    OccDE: 'Verkaufstrainer/in',
    Occupation: 'Sales Trainer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689099,
    OccDE: 'Reisende/r',
    Occupation: 'Salesman (Field Work)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660002,
    OccDE: 'Fachverkäufer/in',
    Occupation: 'Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660006,
    OccDE: 'Verkäufer/in',
    Occupation: 'Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661206,
    OccDE: 'Verkäufer/in im Bäckerhandwerk',
    Occupation: 'Salesperson in the Baking Sector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661207,
    OccDE: 'Verkäufer/in im Konditorhandwerk',
    Occupation: 'Salesperson in the Confectionery Sector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661103,
    OccDE: 'Verkäufer/in im Nahrungsmittelhandwerk',
    Occupation: 'Salesperson in the Food Trade',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661308,
    OccDE: 'Verkäufer/in im Fleischerhandwerk',
    Occupation: 'Salesperson in the Meat Sector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661309,
    OccDE: 'Verkäufer/in im Metzgerhandwerk',
    Occupation: 'Salesperson in the Meat Sector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662912,
    OccDE: 'Verkäufer/in im Zoofachhandel',
    Occupation: 'Salesperson in the Pet Supply Sector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '070011',
    OccDE: 'Salzbergmann',
    Occupation: 'Salt Miner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 352009,
    OccDE: 'Modellnäher/in',
    Occupation: 'Sample Sewer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521225,
    OccDE: 'Musterprüfer/in',
    Occupation: 'Sample Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135311,
    OccDE: 'Sandstrahlmattierer/in',
    Occupation: 'Sandblast Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201828,
    OccDE: 'Sandstrahler (Gußputzer)',
    Occupation: 'Sandblaster (Fettling)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601531,
    OccDE: 'Sanitäringenieur/in',
    Occupation: 'Sanitary Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 267109,
    OccDE: 'Sanitärinstallateur/in',
    Occupation: 'Sanitary Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 267111,
    OccDE: 'Sanitärmonteur/in',
    Occupation: 'Sanitary Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671259,
    OccDE: 'Sanitärkaufmann/-kauffrau (Großhandel)',
    Occupation: 'Sanitary Goods Clerk (Wholesale)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621515,
    OccDE: 'Sanitärtechniker/in',
    Occupation: 'Sanitary Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 777199,
    OccDE: 'SAP-Berater/in',
    Occupation: 'SAP Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 777193,
    OccDE: 'SAP/R3-Consultant',
    Occupation: 'SAP/R3 Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 852304,
    OccDE: 'Saunameister/in',
    Occupation: 'Sauna Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 401325,
    OccDE: 'Wurstwarenhersteller/in',
    Occupation: 'Sausage Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661310,
    OccDE: 'Wurst(fach)verkäufer/in',
    Occupation: 'Sausage Salesperson (Consultant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691646,
    OccDE: 'Sparkassenzweigstellenleiter/in',
    Occupation: 'Savings Bank Branch Manager',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 691020,
    OccDE: 'Sparkassenangestellte/r',
    Occupation: 'Savings Bank Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691639,
    OccDE: 'Sparkassendirektor/in',
    Occupation: 'Savings Bank Manager',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 691024,
    OccDE: 'Sparkassenkaufmann/-kauffrau',
    Occupation: 'Savings Bank Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 651620,
    OccDE: 'Sägewerksmeister/in',
    Occupation: 'Saw Mill Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 181270,
    OccDE: 'Sägewerksleiter/in',
    Occupation: 'Sawmill Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627410,
    OccDE: 'Sägewerkstechniker/in',
    Occupation: 'Sawmill Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 181267,
    OccDE: 'Sägewerker/in',
    Occupation: 'Sawmill Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 181777,
    OccDE: 'Sägereiarbeiter/in',
    Occupation: 'Sawmill Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 181778,
    OccDE: 'Sägewerksarbeiter/in',
    Occupation: 'Sawmill Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 443104,
    OccDE: 'Gerüstbauer',
    Occupation: 'Scaffolder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 443703,
    OccDE: 'Gerüstbauhelfer',
    Occupation: 'Scaffolding Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 443701,
    OccDE: 'Gerüstaufrichter',
    Occupation: 'Scaffolding Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 443102,
    OccDE: 'Gerüstbau-Kolonnenführer',
    Occupation: 'Scaffolding foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 443106,
    OccDE: 'Gerüstmonteur',
    Occupation: 'Scaffolding Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300023,
    OccDE: 'Modellmechaniker/in (Geräte- und Feinwerktechnik)',
    Occupation: 'Scale Model Mechanic (Instrument and Precision Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522711,
    OccDE: 'Wiegearbeiter/in',
    Occupation: 'Scale Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172220,
    OccDE: 'Scanner/in',
    Occupation: 'Scanner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 172219,
    OccDE: 'Scanner-Operator/in',
    Occupation: 'Scanner Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787407,
    OccDE: 'Schulassistent/in (Verwaltung)',
    Occupation: 'School Assistant (Administration)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714313,
    OccDE: 'Schulbusfahrer/in',
    Occupation: 'School Bus Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 796302,
    OccDE: 'Schulhausmeister/in',
    Occupation: 'School Caretaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 868402,
    OccDE: 'Schulberater/in',
    Occupation: 'School Counsellor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 796303,
    OccDE: 'Schulhausverwalter/in',
    Occupation: 'School Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879906,
    OccDE: 'Lehrbeamt(er/in)',
    Occupation: 'School Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764421,
    OccDE: 'Schul(rat/rätin)',
    Occupation: 'School Officer (Tenured)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 886305,
    OccDE: 'Schulpsycholog(e/in)',
    Occupation: 'School Psychologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 789908,
    OccDE: 'Schulsekretär/in',
    Occupation: 'School Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861136,
    OccDE: 'Schulsozialarbeiter/in',
    Occupation: 'School Social Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981196,
    OccDE: 'Schüler/in',
    Occupation: 'School-Aged Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671261,
    OccDE: 'Schrotthändler/in',
    Occupation: 'Scrap Dealer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671201,
    OccDE: 'Alteisenhändler/in',
    Occupation: 'Scrap Metal Dealer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 523203,
    OccDE: 'Altstoffsortierer/in',
    Occupation: 'Scrap Sorter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 486701,
    OccDE: 'Estrichlegerhelfer/in',
    Occupation: 'Screed Installation Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 486203,
    OccDE: 'Estrichleger/in',
    Occupation: 'Screed Layer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 775194,
    OccDE: 'Screendesigner/in',
    Occupation: 'Screen Designer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 175119,
    OccDE: 'Siebdrucker/in',
    Occupation: 'Screen Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821107,
    OccDE: 'Drehbuchautor/in',
    Occupation: 'Screenplay Writer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101302,
    OccDE: 'Bildhauer/in',
    Occupation: 'Sculptor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101310,
    OccDE: 'Steinbildhauer/in',
    Occupation: 'Sculptor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 358013,
    OccDE: 'Näher/in',
    Occupation: 'Seamstress',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013742',
    OccDE: 'Saisonarbeiter/in',
    Occupation: 'Seasonal Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721005,
    OccDE: 'Schiffsbetriebsingenieur/in',
    Occupation: 'Second Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 872108,
    OccDE: 'Gymnasiallehrer/in',
    Occupation: 'Secondary School Teacher',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 872121,
    OccDE:
      'Sekundarstufenlehrer/in (Sekundarstufe II, allgemeinbildendes Schulwesen)',
    Occupation: 'Secondary School Teacher (general education)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 873301,
    OccDE: 'Haupt- und Realschullehrer/in',
    Occupation: 'Secondary School Teacher (Hauptschule and Realschule)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873308,
    OccDE: 'Realschullehrer/in',
    Occupation: 'Secondary School Teacher (Realfachschule)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873307,
    OccDE: 'Realfachschullehrer/in',
    Occupation: 'Secondary School Teacher (Realschule)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873411,
    OccDE: 'Sekundarstufenlehrer/in (Sekundarstufe I an Gesamtschulen)',
    Occupation:
      'Secondary School Teacher (Secondary Level I at Comprehensive Schools)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 789007,
    OccDE: 'Sekretär/in',
    Occupation: 'Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789406,
    OccDE: 'Sekretär/in (internationale Touristik)',
    Occupation: 'Secretary (International Tourism)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 763207,
    OccDE: 'Sekretär/in (Partei, Selbstverwaltungskammer, Verband)',
    Occupation: 'Secretary (Party, Association)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 789008,
    OccDE: 'Sekretariatsassistent/in',
    Occupation: 'Secretary Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856416,
    OccDE: 'Sekretär/in im Gesundheitswesen',
    Occupation: 'Secretary in Health Care',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 716407,
    OccDE: 'Streckenwart/in (Straßen)',
    Occupation: 'Section Attendant (Road)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691311,
    OccDE: 'Wertpapierberater/in',
    Occupation: 'Securities Adviser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691310,
    OccDE: 'Wertpapieranalyst/in',
    Occupation: 'Securities Analyst',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 704205,
    OccDE: 'Effektenmakler/in',
    Occupation: 'Securities Broker',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 704211,
    OccDE: 'Wertpapiermakler/in',
    Occupation: 'Securities Broker',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 791904,
    OccDE: 'Personenkontrolleur/in (Flughafen)',
    Occupation: 'Security Checker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792025,
    OccDE: 'Wachschutzmann/-schutzfrau',
    Occupation: 'Security Guard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791101,
    OccDE: 'Sicherheitsbeauftragte/r (Werkschutz)',
    Occupation: 'Security Service Officer (Company Security)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792096,
    OccDE: 'Wachdienstangestellte/r',
    Occupation: 'Security Staff Member',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791002,
    OccDE: 'Sicherheitsmitarbeiter/in (Wach- und Sicherheitsgewerbe)',
    Occupation: 'Security Technician (Surveillance and Security Industry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 995103,
    OccDE: 'Selbständige/r',
    Occupation: 'Self-employed',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316902,
    OccDE: 'Halbleiterfertiger/in',
    Occupation: 'Semiconductor Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 871914,
    OccDE: 'Seminarleiter/in',
    Occupation: 'Seminar Leader',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787011,
    OccDE: 'Hauptsekretär/in',
    Occupation: 'Senior Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 864006,
    OccDE: 'Seniorenpfleger/in',
    Occupation: 'Senior Care Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856210,
    OccDE: 'Zahnmedizinische(r) Fachhelfer/in',
    Occupation: 'Senior Dental Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765002,
    OccDE: 'Amtsrat/Amtsrätin',
    Occupation: 'Senior Deputy Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 841038,
    OccDE: 'Oberarzt/-ärztin',
    Occupation: 'Senior Doctor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 695409,
    OccDE: 'Direktionsbevollmächtigte/r (Versicherung)',
    Occupation: 'Senior Executive Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765012,
    OccDE: 'Oberamts(rat/rätin)',
    Occupation: 'Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 651025,
    OccDE: 'Oberwerkmeister/in',
    Occupation: 'Senior Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651909,
    OccDE: 'Oberwerkmeister/in (Justizvollzugsdienst)',
    Occupation: 'Senior Foreman (Correctional Service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 711112,
    OccDE: 'Oberlokomotivführer/in',
    Occupation: 'Senior Locomotive Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778122,
    OccDE: 'Senioroperator/in',
    Occupation: 'Senior Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 811210,
    OccDE: 'Oberstaatsanwalt/-anwältin',
    Occupation: 'Senior Prosecutor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 814227,
    OccDE: 'Obersekretär/in (Justiz-, Strafvollzugsdienst)',
    Occupation: 'Senior Secretary (Correctional Services, Prison)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861023,
    OccDE: 'Sozialoberinspektor/in',
    Occupation: 'Senior Social Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 754506,
    OccDE: 'Steuerfachwirt/in',
    Occupation: 'Senior Tax Specialist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 872125,
    OccDE: 'Studiendirektor/in',
    Occupation: 'Senior Tenured Secondary School Teacher',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 861201,
    OccDE: 'Altenfürsorger/in',
    Occupation: 'Seniors Welfare Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801108,
    OccDE: 'Feldwebel',
    Occupation: 'Sergeant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801119,
    OccDE: 'Hauptfeldwebel',
    Occupation: 'Sergeant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801137,
    OccDE: 'Oberfeldwebel',
    Occupation: 'Sergeant (Higher Grade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 794004,
    OccDE: 'Hausdiener/in',
    Occupation: 'Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912018,
    OccDE: 'Servierer/in',
    Occupation: 'Server',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912020,
    OccDE: 'Servierkellner/in',
    Occupation: 'Server',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912706,
    OccDE: 'Bediener/in (Restaurant)',
    Occupation: 'Server Manager (Restaurant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689512,
    OccDE: 'Service-Berater/in',
    Occupation: 'Service Advisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689906,
    OccDE: 'Service-Reisende/r',
    Occupation: 'Service Agent (Field Worker)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713502,
    OccDE: 'Dienstzusteller/in (Verkehrsbetrieb)',
    Occupation: 'Service Delivery Agent (Transport Services)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714917,
    OccDE: 'Servicefahrer/in',
    Occupation: 'Service Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 310045,
    OccDE: 'Serviceelektriker/in',
    Occupation: 'Service Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316130,
    OccDE: 'Serviceelektromechaniker/in',
    Occupation: 'Service Electromechanical Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600505,
    OccDE: 'Serviceingenieur/in',
    Occupation: 'Service Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 751315,
    OccDE: 'Service-Manager',
    Occupation: 'Service Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912115,
    OccDE: 'Servicechef/in',
    Occupation: 'Service Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 751316,
    OccDE: 'Serviceleiter/in (nicht Restaurant)',
    Occupation: 'Service Manager (not Restaurant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912116,
    OccDE: 'Serviceleiter/in (Restaurant)',
    Occupation: 'Service Manager (Restaurant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750198,
    OccDE: 'Dienstleistungsunternehmer/in',
    Occupation: 'Service Provider',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912016,
    OccDE: 'Servicefachkraft (Restaurant)',
    Occupation: 'Service Specialist (Restaurant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732002,
    OccDE: 'Dienstleistungsfachkraft im Postbetrieb',
    Occupation: 'Service Specialist in Postal Operations',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912019,
    OccDE: 'Servierfachkraft',
    Occupation: 'Service Staff Member',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912799,
    OccDE: 'Servicemitarbeiter/in',
    Occupation: 'Service Staff Member',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912002,
    OccDE: 'Bedienung (Restaurant)',
    Occupation: 'Service Staff Member (Restaurant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651033,
    OccDE: 'Servicestationsleiter/in',
    Occupation: 'Service Station Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620508,
    OccDE: 'Servicetechniker/in',
    Occupation: 'Service Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269015,
    OccDE: 'Kundendienstmonteur/in',
    Occupation: 'Service Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 269021,
    OccDE: 'Servicemonteur/in',
    Occupation: 'Service Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622806,
    OccDE: 'Servicetechniker/in (Elektrotechnik)',
    Occupation: 'Service Technician (Electrical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273036,
    OccDE: 'Servicemonteur/in (Maschinenbau)',
    Occupation: 'Service Technician (Mechanical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315123,
    OccDE: 'Servicetechniker/in (Radio- und Fernsehtechnik)',
    Occupation: 'Service Technician (Radio and Television Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 794510,
    OccDE: 'Gerätewart/in',
    Occupation: 'Serviceman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835104,
    OccDE: 'Bühnenausstatter/in',
    Occupation: 'Set Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835105,
    OccDE: 'Bühnenbildner/in',
    Occupation: 'Set Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835113,
    OccDE: 'Szenenbildner/in',
    Occupation: 'Set Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 171009,
    OccDE: 'Setzer/in (Schriftsetzer/in)',
    Occupation: 'Setter (typesetter)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771602,
    OccDE: 'Abrechnungsprüfer/in',
    Occupation: 'Settlement Auditor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 771601,
    OccDE: 'Abrechner/in',
    Occupation: 'Settlement Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935707,
    OccDE: 'Kanalisationsreiniger/in',
    Occupation: 'Sewage System Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935706,
    OccDE: 'Kanalisationsarbeiter/in',
    Occupation: 'Sewage System Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935414,
    OccDE: 'Klärwärter/in',
    Occupation: 'Sewage Treatment Official',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935416,
    OccDE: 'Klärwerkfacharbeiter/in',
    Occupation: 'Sewage Treatment Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935401,
    OccDE: 'Abwasseranlagenwärter/in',
    Occupation: 'Sewage Treatment Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466309,
    OccDE: 'Kanalisationsbauer',
    Occupation: 'Sewer Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935705,
    OccDE: 'Kanalarbeiter/in',
    Occupation: 'Sewer Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 300413,
    OccDE: 'Nähmaschinenmechaniker/in',
    Occupation: 'Sewing Machine Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 894208,
    OccDE: 'Küster/in',
    Occupation: 'Sexton',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466103,
    OccDE: 'Schachtbauer',
    Occupation: 'Shaft Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 112003,
    OccDE: 'Formsteinhersteller/in',
    Occupation: 'Shaped Brick Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 213303,
    OccDE: 'Blechbieger/in',
    Occupation: 'Sheet Metal Bender',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 211201,
    OccDE: 'Blechzieher/in',
    Occupation: 'Sheet Metal Drawing Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261002,
    OccDE: 'Blechklempner/in',
    Occupation: 'Sheet Metal Plumber',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621301,
    OccDE: 'Blechverarbeitungstechniker/in',
    Occupation: 'Sheet Metal Processing Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 211304,
    OccDE: 'Blechstanzer/in',
    Occupation: 'Sheet Metal Stamper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 265104,
    OccDE: 'Blechschlosser/in',
    Occupation: 'Sheet Metal Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 265005,
    OccDE: 'Blechbearbeiter/in',
    Occupation: 'Sheet Metal Working',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660703,
    OccDE: 'Regalauffüller/in',
    Occupation: 'Shelf Stocker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 265207,
    OccDE: 'Regalbauschlosser/in',
    Occupation: 'Shelving Maker Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023302',
    OccDE: 'Schäfer/in',
    Occupation: 'Shepherd',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023305',
    OccDE: 'Schäfergehilf(e/in)',
    Occupation: 'Shepherd Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311004,
    OccDE: 'Schichtelektriker/in (Energieversorgung)',
    Occupation: 'Shift Electrician (Power Supply)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651032,
    OccDE: 'Schichtmeister/in',
    Occupation: 'Shift Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651030,
    OccDE: 'Schichtführer/in',
    Occupation: 'Shift Leader',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141023,
    OccDE: 'Schichtführer/in (Chemiebetriebswerker/in)',
    Occupation: 'Shift Leader (Chemical Plant Worker)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651031,
    OccDE: 'Schichtleiter/in',
    Occupation: 'Shift Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 274012,
    OccDE: 'Schichtschlosser/in',
    Occupation: 'Shift Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531915,
    OccDE: 'Schichtarbeiter/in',
    Occupation: 'Shift Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704305,
    OccDE: 'Schiffsmakler/in',
    Occupation: 'Ship Broker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411807,
    OccDE: 'Schiffskoch/-köchin',
    Occupation: 'Ship Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721516,
    OccDE: 'Schiffsmaschineningenieur/in',
    Occupation: 'Ship Engine Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721602,
    OccDE: 'Schiffsbetriebstechniker/in',
    Occupation: 'Ship Engine Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721515,
    OccDE: 'Schiffsingenieur/in',
    Occupation: 'Ship Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 723003,
    OccDE: 'Schiffsmechaniker/in',
    Occupation: 'Ship Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981160,
    OccDE: 'Student/in Schiffsbetriebstechnik',
    Occupation: 'Ship Operation Engineering Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912612,
    OccDE: 'Schiffssteward/eß',
    Occupation: 'Ship Steward/Stewardess',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 255209,
    OccDE: 'Schiffbauer/in',
    Occupation: 'Shipbuilder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621210,
    OccDE: 'Schiffbautechniker/in',
    Occupation: 'Shipbuilding Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522009,
    OccDE: 'Versandgehilf(e/in)',
    Occupation: 'Shipping Assistance',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701311,
    OccDE: 'Reedereikaufmann/-kauffrau',
    Occupation: 'Shipping Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701028,
    OccDE: 'Versandangestellte/r',
    Occupation: 'Shipping Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701030,
    OccDE: 'Versandleiter/in',
    Occupation: 'Shipping manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 522007,
    OccDE: 'Versandfacharbeiter/in',
    Occupation: 'Shipping Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522006,
    OccDE: 'Versandarbeiter/in',
    Occupation: 'Shipping Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673305,
    OccDE: 'Schuhkaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Shoe Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673304,
    OccDE: 'Schuhhändler/in (Einzelhandel)',
    Occupation: 'Shoe Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 373770,
    OccDE: 'Schuhfabrikarbeiter/in',
    Occupation: 'Shoe factory worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662299,
    OccDE: 'Schuhverkäufer/in',
    Occupation: 'Shoe Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 373005,
    OccDE: 'Schuhfacharbeiter/in',
    Occupation: 'Shoe Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627338,
    OccDE: 'Schuhtechniker/in',
    Occupation: 'Shoe Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 373769,
    OccDE: 'Schuharbeiter/in',
    Occupation: 'Shoe Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 372004,
    OccDE: 'Schuhmacher/in',
    Occupation: 'Shoemaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 373006,
    OccDE: 'Schuhfertiger/in',
    Occupation: 'Shoemaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 372204,
    OccDE: 'Schuhmacher/in auf Orthopädie',
    Occupation: 'Shoemaker for Orthopaedics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660806,
    OccDE: 'Erstverkäufer/in',
    Occupation: 'Shop Assistant Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661801,
    OccDE: 'Erstverkäufer/in (Nahrungs-, Genußmittel)',
    Occupation: 'Shop Assistant Manager (Food, Semi-luxury Products)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788658,
    OccDE: 'Werkstattschreiber/in',
    Occupation: 'Shop Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505120,
    OccDE: 'Ladenbauer/in',
    Occupation: 'Shopfitters',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627904,
    OccDE: 'Ladenbautechniker/in',
    Occupation: 'Shopfitting Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712117,
    OccDE: 'Stellwerker/in',
    Occupation: 'Shunter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712120,
    OccDE: 'Stellwerkswärter/in',
    Occupation: 'Shunter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712207,
    OccDE: 'Rangierführer/in',
    Occupation: 'Shunter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712203,
    OccDE: 'Rangierarbeiter/in',
    Occupation: 'Shunter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712206,
    OccDE: 'Rangierer/in',
    Occupation: 'Shunter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712299,
    OccDE: 'Rangierleiter/in',
    Occupation: 'Shunting Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 711113,
    OccDE: 'Rangierlokomotivführer/in',
    Occupation: 'Shunting Locomotive Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861999,
    OccDE: 'Gebärdensprachdolmetscher/in',
    Occupation: 'Sign Language Interpreter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 839003,
    OccDE: 'Schilderhersteller/in',
    Occupation: 'Sign Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 839002,
    OccDE: 'Schilder- und Lichtreklamehersteller/in',
    Occupation: 'Signage and Neon Sign Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801195,
    OccDE: 'Fernmeldetruppe (Heer)',
    Occupation: 'Signal Corps (Army)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273604,
    OccDE: 'Signalmechaniker/in',
    Occupation: 'Signal Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622214,
    OccDE: 'Signaltechniker/in',
    Occupation: 'Signal Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 629910,
    OccDE: 'Werbetechniker/in (sonstige)',
    Occupation: 'Signmaker (other)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 839501,
    OccDE: 'Werbetechniker/in (Schilder- und Lichtreklameherstellung)',
    Occupation: 'Signmaker (Signage and Neon Sign Maker)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620907,
    OccDE: 'Werbetechniker/in',
    Occupation: 'Signmaking Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 741104,
    OccDE: 'Silomeister/in',
    Occupation: 'Silo Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 302203,
    OccDE: 'Silberschmied/in',
    Occupation: 'Silversmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832526,
    OccDE: 'Sänger/in',
    Occupation: 'Singer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882330,
    OccDE: 'Sinolog(e/in)',
    Occupation: 'Sinology Scholar',
    'Feather RC': 'B',
  },
  {
    OccupationCode: '051103',
    OccDE: 'Baustellenleiter/in (Garten-, Landschafts- und Sportplatzbau)',
    Occupation: 'Site Foreman (Gardening, Landscaping and Sports Facilities)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792312,
    OccDE: 'Grundstückswächter/in',
    Occupation: 'Site Guard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603908,
    OccDE: 'Baukontrolleur/in (Bauingenieur/in)',
    Occupation: 'Site Inspector (Civil Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 623106,
    OccDE: 'Baustellenleiter/in',
    Occupation: 'Site Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623107,
    OccDE: 'Baustellentechniker/in',
    Occupation: 'Site Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062213',
    OccDE: 'Holzrücker/in',
    Occupation: 'Skidder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876304,
    OccDE: 'Skilehrer/in',
    Occupation: 'Skiing Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780804,
    OccDE: 'Bürofachkraft (Reha)',
    Occupation: 'Skilled Office Worker (for people with disabilities)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 791001,
    OccDE: 'Sicherheitsfachkraft (Wach- und Sicherheitsgewerbe)',
    Occupation: 'Skilled Security Worker (Surveillance and Security Industry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 481401,
    OccDE: 'Fachkraft für Bauwerkinstandsetzung (Putz)',
    Occupation: 'Skilled Worker in Building Maintenance (Plaster)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 393001,
    OccDE: 'Fachkraft für Süßwarentechnik',
    Occupation: 'Skilled Worker in Confectionery Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835497,
    OccDE: 'Fachkraft für Veranstaltungstechnik  (Eventoperator)',
    Occupation: 'Skilled Worker in Event Technology (Event Operator)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732099,
    OccDE: 'Fachkraft für Brief- und             Frachtverkehr',
    Occupation: 'Skilled Worker in Mail and Freight',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629201,
    OccDE: 'Fachkraft für Arbeitssicherheit',
    Occupation: 'Skilled Worker in Occupational Safety',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756213,
    OccDE: 'Fachkraft für Operations Research',
    Occupation: 'Skilled Worker in Operations Research',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312012,
    OccDE: 'Facharbeiter/in für Nachrichtentechnik',
    Occupation: 'Skilled Worker in Telecommunications',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 915199,
    OccDE: 'Fachkraft im Gastgewerbe',
    Occupation: 'Skilled Worker in the Hospitality Industry',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741009,
    OccDE: 'Fachkraft für Lagerwirtschaft',
    Occupation: 'Skilled Worker in Warehousing and Logistics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623598,
    OccDE: 'Fachkraft für Wasserwirtschaft',
    Occupation: 'Skilled Worker in Water Management',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721112,
    OccDE: 'Schiffsführer/in',
    Occupation: 'Skipper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721309,
    OccDE: 'Schiffer/in',
    Occupation: 'Skipper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 724304,
    OccDE: 'Bootsführer/in',
    Occupation: 'Skipper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 724325,
    OccDE: 'Schiffsführer/in (Binnenschiffahrt)',
    Occupation: 'Skipper (Inland Waterways)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191419,
    OccDE: 'Schmelzer/in (Metallerzeuger/in)',
    Occupation: 'Smelter (Metal Manufacturing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241202,
    OccDE: 'Schmelzschweißer/in',
    Occupation: 'Smelting Welder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861702,
    OccDE: 'Sozialassistent/in',
    Occupation: 'Social Assistance Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869711,
    OccDE: 'Sozialpflegehelfer/in',
    Occupation: 'Social Care Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869018,
    OccDE: 'Sozialpfleger/in',
    Occupation: 'Social Care Professional',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 811161,
    OccDE: 'Sozialrichter/in',
    Occupation: 'Social Court Judge',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 861705,
    OccDE: 'Sozialpädagogische(r) Mitarbeiter/in',
    Occupation: 'Social Education Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861098,
    OccDE: 'Diplom-Sozialpädagog(e/in)',
    Occupation: 'Social Education Worker with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861020,
    OccDE: 'Sozialinspektor/in',
    Occupation: 'Social Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857804,
    OccDE: 'Sozialmedizinische(r) Assistent/in',
    Occupation: 'Social Medicine Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861024,
    OccDE: 'Sozialpädagog(e/in)',
    Occupation: 'Social Pedagogue',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981162,
    OccDE: 'Student/in Sozialpädagogik',
    Occupation: 'Social Pedagogy Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 886907,
    OccDE: 'Sozialpsycholog(e/in)',
    Occupation: 'Social Psychologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 884102,
    OccDE: 'Sozialwirt/in',
    Occupation: 'Social Sciences Researcher',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 884101,
    OccDE: 'Diplom-Sozialwirt/in',
    Occupation: 'Social Sciences Researcher with Degree',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 884002,
    OccDE: 'Sozialwissenschaftler/in',
    Occupation: 'Social Scientist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787314,
    OccDE: 'Sozialversicherungsfachangestellte/r',
    Occupation: 'Social Security Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787317,
    OccDE: 'Sozialversicherungskaufmann/-kauffrau',
    Occupation: 'Social Security Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787315,
    OccDE: 'Sozialversicherungsfachmann/-fachfrau',
    Occupation: 'Social Security Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861026,
    OccDE: 'Sozialtherapeut/in',
    Occupation: 'Social Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861030,
    OccDE: 'Wohlfahrtspfleger/in',
    Occupation: 'Social Welfare Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861018,
    OccDE: 'Sozialarbeiter/in',
    Occupation: 'Social Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869017,
    OccDE: 'Sozialbetreuer/in',
    Occupation: 'Social Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861099,
    OccDE: 'Diplom-Sozialarbeiter/in',
    Occupation: 'Social Worker with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869104,
    OccDE: 'Sozialberater/in',
    Occupation: 'Social Worker, Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869011,
    OccDE: 'Pädagogische(r) Betreuer/in',
    Occupation: 'Socio-educational Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 884202,
    OccDE: 'Soziolog(e/in)',
    Occupation: 'Sociologist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 884299,
    OccDE: 'Diplom-Soziolog(e/in)',
    Occupation: 'Sociologist with Degree',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 981163,
    OccDE: 'Student/in Sozialwissenschaften',
    Occupation: 'Sociology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602418,
    OccDE: 'Soft- und Hardware-Designer/in',
    Occupation: 'Soft and Hardware Designer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 776207,
    OccDE: 'Software-Analytiker/in',
    Occupation: 'Software Analyst',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775014,
    OccDE: 'Software-Assistent/in',
    Occupation: 'Software Assistant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 777114,
    OccDE: 'Softwareberater/in',
    Occupation: 'Software Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775017,
    OccDE: 'Softwareentwickler/in',
    Occupation: 'Software Developer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775013,
    OccDE: 'Software Engineer',
    Occupation: 'Software Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 775015,
    OccDE: 'Software-Ingenieur/in',
    Occupation: 'Software Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 776121,
    OccDE: 'Software-Planer/in',
    Occupation: 'Software Planner',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775305,
    OccDE: 'Software-Projektleiter/in',
    Occupation: 'Software Project Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775016,
    OccDE: 'Software-Projekteur/in',
    Occupation: 'Software Project Worker',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 777202,
    OccDE: 'Software-Verkäufer/in',
    Occupation: 'Software Salesman',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775018,
    OccDE: 'Softwarespezialist/in',
    Occupation: 'Software Specialist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775019,
    OccDE: 'Softwaretechniker/in',
    Occupation: 'Software Technician',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775020,
    OccDE: 'Softwaretester/in',
    Occupation: 'Software Tester',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 264905,
    OccDE: 'Solarmechaniker/in',
    Occupation: 'Solar Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 245116,
    OccDE: 'Löter/in',
    Occupation: 'Solderer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801160,
    OccDE: 'Soldat',
    Occupation: 'Soldier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912919,
    OccDE: 'Sommelier (Weinkellner/in)',
    Occupation: 'Sommelier',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 523005,
    OccDE: 'Sortierer/in',
    Occupation: 'Sorter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835422,
    OccDE: 'Tonassistent/in (Tontechniker/in)',
    Occupation: 'Sound Assistant (Sound Engineer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835308,
    OccDE: 'Toningenieur/in',
    Occupation: 'Sound Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 482409,
    OccDE: 'Schallschutz- und Akustikfachwerker/in',
    Occupation: 'Sound Insulation and Acoustics Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835423,
    OccDE: 'Tonmischer/in (Studio)',
    Occupation: 'Sound Mixer (studio)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835426,
    OccDE: 'Tontechniker/in',
    Occupation: 'Sound Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411043,
    OccDE: 'Sous-Chef (Koch)',
    Occupation: 'Sous Chef (cook)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741645,
    OccDE: 'Teiledienstleiter/in',
    Occupation: 'Spare Parts Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662902,
    OccDE: 'Ersatzteilverkäufer/in',
    Occupation: 'Spare Parts Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741605,
    OccDE: 'Ersatzteillagerleiter/in',
    Occupation: 'Spare Parts Warehouse Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440105,
    OccDE: 'Spezialbaufacharbeiter',
    Occupation: 'Special Building Trade Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 683902,
    OccDE: 'Fachberater/in (Verlag)',
    Occupation: 'Special Consultant (Publishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 866001,
    OccDE: 'Heilerzieher/in',
    Occupation: 'Special Education Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873515,
    OccDE: 'Sonderschullehrer/in',
    Occupation: 'Special Education Teacher',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 862007,
    OccDE: 'Sonderpädagog(e/in)',
    Occupation: 'Special Education Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 862006,
    OccDE: 'Sondererzieher/in',
    Occupation: 'Special Education Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 866002,
    OccDE: 'Heilerziehungspfleger/in',
    Occupation: 'Special Education Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 862703,
    OccDE: 'Heilpädagogische(r) Helfer/in',
    Occupation: 'Special Needs Aid',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 862005,
    OccDE: 'Heilpädagog(e/in)',
    Occupation: 'Special Needs Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273037,
    OccDE: 'Spezialmonteur/in (Maschinenbau)',
    Occupation: 'Special Technician (Mechanical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 460102,
    OccDE: 'Spezialbaufacharbeiter (Tiefbau)',
    Occupation: 'Specialised Construction Technician (civil engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856414,
    OccDE: 'Medizinische Fachschreibkraft',
    Occupation: 'Specialised Medical Transcriptionist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873601,
    OccDE: 'Fachlehrer/in an allgemeinbildenden Schulen',
    Occupation: 'Specialised Teacher at General Education Schools',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873602,
    OccDE: 'Fachlehrer/in an Sonderschulen',
    Occupation: 'Specialised Teacher at Special Education Schools',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 873603,
    OccDE:
      'Fachlehrer/in für musisch-technische Fächer (allgemeinbildende Schulen)',
    Occupation:
      'Specialised Teacher for Music-Technical Subjects (General Education)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879507,
    OccDE: 'Fachausbilder/in',
    Occupation: 'Specialised Trainer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841947,
    OccDE: 'Facharzt/-ärztin',
    Occupation: 'Specialist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 670001,
    OccDE: 'Fachkaufmann/-kauffrau',
    Occupation: 'Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440406,
    OccDE: 'Fachwerker',
    Occupation: 'Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861801,
    OccDE: 'Fachberater/in (Sozialarbeit)',
    Occupation: 'Specialist (Social Work)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 813104,
    OccDE: 'Fachanwalt/Fachanwältin',
    Occupation: 'Specialist Attorney',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 689505,
    OccDE: 'Fachberater/in im Direktvertrieb',
    Occupation: 'Specialist Consultant in Direct Sales',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689504,
    OccDE: 'Fachberater/in im Außendienst',
    Occupation: 'Specialist Consultant in Field Sales',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 775006,
    OccDE: 'Facharbeiter/in für Datenverarbeitung (Programmierung)',
    Occupation: 'Specialist Data Processing (Programming)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 774109,
    OccDE: 'Sachbearbeiter/in EDV/IDV',
    Occupation: 'Specialist EDP/IDP',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 703498,
    OccDE: 'Kaufmann/Kauffrau für                audiovisuelle Medien',
    Occupation: 'Specialist for Audio-Visual Media',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 774107,
    OccDE: 'Facharbeiter/in für Datenverarbeitung',
    Occupation: 'Specialist for Data Processing',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 775108,
    OccDE: 'Facharbeiter/in für Datenverarbeitung (DV-Projektierung)',
    Occupation: 'Specialist for Data Processing (DP Projects)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776111,
    OccDE: 'Facharbeiter/in für Datenverarbeitung (Organisation)',
    Occupation: 'Specialist for Data Processing (Organisation)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 779204,
    OccDE: 'Fachkaufmann/-kauffrau für DV-Organisation und Datenkommunikation',
    Occupation: 'Specialist for DP Organisation and Data Communication',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 741006,
    OccDE: 'Facharbeiter/in für Umschlag und Lagerung',
    Occupation: 'Specialist for Handling and Warehousing',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741007,
    OccDE: 'Facharbeiter/in für Umschlagprozesse und Lagerwirtschaft',
    Occupation:
      'Specialist for Handling Processes and Warehousing and Logistics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788302,
    OccDE: 'Fachkaufmann/-kauffrau für Personalwesen',
    Occupation: 'Specialist for Human Resources',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 732004,
    OccDE: 'Facharbeiter/in für Postverkehr',
    Occupation: 'Specialist for Mail',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 823495,
    OccDE: 'Fachangestellte/r für Medien- und    Informationsdienste',
    Occupation: 'Specialist for Media and Information Services',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823299,
    OccDE:
      'Fachangestellte/r für Medien- und    Informationsdienste - Fr. Bibliothek',
    Occupation: 'Specialist for Media and Information Services Special library',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 823499,
    OccDE:
      'Fachangestellte/r für Medien- und    Informationsdienste - Fachr. Archiv',
    Occupation:
      'Specialist for Media and Information Services specialising in Archiving',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787009,
    OccDE: 'Fachangestellte/r für Bürokommunikation',
    Occupation: 'Specialist for Office Communication',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712013,
    OccDE: 'Facharbeiter/in für Eisenbahnbetrieb',
    Occupation: 'Specialist for Railway Services',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 795199,
    OccDE: 'Fachangestellte/r für Bäderbetriebe',
    Occupation: 'Specialist for Swimming Facilities Management',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741005,
    OccDE: 'Facharbeiter/in für Lagerwirtschaft',
    Occupation: 'Specialist for Warehousing and Logistics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062116',
    OccDE: 'Waldfacharbeiter/in',
    Occupation: 'Specialist Forestry Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141417,
    OccDE: 'Facharbeiter/in für Kunststoffchemie',
    Occupation: 'Specialist in Chemistry of Plastics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550106,
    OccDE: 'Facharbeiter/in für Anlagentechnik',
    Occupation: 'Specialist in Equipment Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062101',
    OccDE: 'Facharbeiter/in für Forstwirtschaft',
    Occupation: 'Specialist in Forestry',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051105',
    OccDE:
      'Fachwerker/in im Gartenbau (Schwerpunkt Garten- und Landschaftsbau)',
    Occupation:
      'Specialist in Horticulture (specialising in Gardening and Landscaping)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051303',
    OccDE:
      'Fachwerker/in im Gartenbau (Schwerpunkt Garten- und Zierpflanzenbau)',
    Occupation:
      'Specialist in Horticulture (Specialising in Horticulture and Floriculture)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787303,
    OccDE: 'Fachangestellte/r für Arbeitsförderung',
    Occupation: 'Specialist in Jobs Creation',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 510302,
    OccDE: 'Fachpraktiker/in im Maler- und Lackiererhandwerk',
    Occupation: 'Specialist in Painting and Varnishing',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 510303,
    OccDE: 'Fachwerker/in im Maler- und Lackiererhandwerk',
    Occupation: 'Specialist in Painting and Varnishing',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521209,
    OccDE: 'Facharbeiter/in für Qualitätskontrolle',
    Occupation: 'Specialist in Quality Assurance',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505005,
    OccDE: 'Facharbeiter/in für Holztechnik',
    Occupation: 'Specialist in Wood Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505104,
    OccDE: 'Facharbeiter/in für Holztechnik (Möbelbau)',
    Occupation: 'Specialist in Wood Technology (Furniture Manufacturing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505103,
    OccDE: 'Facharbeiter/in für Holztechnik (industrielle Möbelfertigung)',
    Occupation:
      'Specialist in Wood Technology (Industrial Furniture Manufacturing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311003,
    OccDE: 'Fachmonteur/in (Energieversorgung)',
    Occupation: 'Specialist Installer (Power Supply)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853922,
    OccDE: 'Fachkrankenpfleger',
    Occupation: 'Specialist Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853923,
    OccDE: 'Fachkrankenschwester',
    Occupation: 'Specialist Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853924,
    OccDE: 'Fachpfleger (Krankenpflege)',
    Occupation: 'Specialist Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511601,
    OccDE: 'Fachberater/in für Farben, Lacke und Kunststoffe',
    Occupation: 'Specialist Paints, Varnishes and Plastics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874510,
    OccDE: 'Fachlehrer/in',
    Occupation: 'Specialist Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874503,
    OccDE: 'Fachlehrer/in an berufsbildenden Schulen',
    Occupation: 'Specialist Teacher at Vocational School',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874506,
    OccDE: 'Fachlehrer/in für Hauswirtschaft',
    Occupation: 'Specialist Teacher of Home Economics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876015,
    OccDE: 'Fachlehrer/in für Sport',
    Occupation: 'Specialist Teacher of Physical Education',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778319,
    OccDE: 'Spezialist/in Telekommunikation',
    Occupation: 'Specialist Telecommunications',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 822202,
    OccDE: 'Fachübersetzer/in',
    Occupation: 'Specialist Translator',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 874005,
    OccDE: 'Fachoberlehrer/in',
    Occupation: 'Specialized Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135806,
    OccDE: 'Brillenoptikschleifer/in',
    Occupation: 'Spectacle Lens Cutter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 135805,
    OccDE: 'Brillenoptiker/in',
    Occupation: 'Spectacle Optometrist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882911,
    OccDE: 'Sprechwissenschaftler/in',
    Occupation: 'Speech Scientist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 859105,
    OccDE: 'Logopäd(e/in)',
    Occupation: 'Speech Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 859107,
    OccDE: 'Sprachtherapeut/in',
    Occupation: 'Speech Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 862102,
    OccDE: 'Sprachheillehrer/in',
    Occupation: 'Speech Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 862103,
    OccDE: 'Sprachheilpädagog(e/in)',
    Occupation: 'Speech Therapy Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522449,
    OccDE: 'Gewürzabfüller/in',
    Occupation: 'Spice Bottler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 331213,
    OccDE: 'Spinnereimaschinenführer/in',
    Occupation: 'Spinning Machinery Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 331003,
    OccDE: 'Spinnereifacharbeiter/in',
    Occupation: 'Spinning Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 331704,
    OccDE: 'Spinnereiarbeiter/in',
    Occupation: 'Spinning Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 212802,
    OccDE: 'Spulenwickler/in (Drahtverarbeitung)',
    Occupation: 'Spool Winder (Wire Working)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 780499,
    OccDE: 'Sport- und Fitnesskaufmann/-kauffrau',
    Occupation: 'Sports and Fitness Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876036,
    OccDE: 'Sporttrainer/in',
    Occupation: 'Sports Coach',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750310,
    OccDE: 'Sportökonom/in',
    Occupation: 'Sports Economist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 821416,
    OccDE: 'Sportredakteur/in',
    Occupation: 'Sports Editor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 506814,
    OccDE: 'Sportgerätebauer/in',
    Occupation: 'Sports Equipment Builders',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 794521,
    OccDE: 'Sportplatzwart/in',
    Occupation: 'Sports Groundskeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837409,
    OccDE: 'Sportfotograf/in',
    Occupation: 'Sports Photographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 852104,
    OccDE: 'Sportphysiotherapeut/in',
    Occupation: 'Sports Physiotherapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876914,
    OccDE: 'Rehabilitationstrainer/in (Sport)',
    Occupation: 'Sports Physiotherapy Trainer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 884601,
    OccDE: 'Sportwissenschaftler/in',
    Occupation: 'Sports Scientist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981164,
    OccDE: 'Student/in Sport',
    Occupation: 'Sports Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876034,
    OccDE: 'Sportlehrer/in (im freien Beruf)',
    Occupation: 'Sports Teacher (Freelance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 852504,
    OccDE: 'Sporttherapeut/in',
    Occupation: 'Sports Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241102,
    OccDE: 'Punktschweißer/in',
    Occupation: 'Spot Welder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 212605,
    OccDE: 'Federmacher/in',
    Occupation: 'Spring Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268215,
    OccDE: 'Sprinkleranlagenbauer/in',
    Occupation: 'Sprinkler Systems Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 267114,
    OccDE: 'Sprinklermonteur/in',
    Occupation: 'Sprinkler Systems Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 872116,
    OccDE:
      'OSL (Lehrer/in an allgemeinbild. polytechn. od. erweiterten Oberschulen)',
    Occupation:
      'SST (teacher at general ed. poltytech or other secondary school)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 856001,
    OccDE: 'Praxishelfer/in (Arztpraxis)',
    Occupation: "Staff Member (Doctor's Office)",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 856002,
    OccDE: 'Praxishilfe (Arztpraxis)',
    Occupation: "Staff Member (Doctor's Office)",
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841055,
    OccDE: 'Stabsarzt/-ärztin',
    Occupation: 'Staff Physician',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 801164,
    OccDE: 'Stabsunteroffizier',
    Occupation: 'Staff Sergeant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832405,
    OccDE: 'Bühnentänzer/in',
    Occupation: 'Stage Dancer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501504,
    OccDE: 'Bühnenhandwerker/in',
    Occupation: 'Stage Handyman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835508,
    OccDE: 'Bühnenbeleuchter/in',
    Occupation: 'Stage Lighting Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835207,
    OccDE: 'Bühnenmeister/in',
    Occupation: 'Stage Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835208,
    OccDE: 'Bühnentechniker/in',
    Occupation: 'Stage Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744310,
    OccDE: 'Bühnenarbeiter/in',
    Occupation: 'Stagehand',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 486401,
    OccDE: 'Treppenbauer/in (Stein, Beton)',
    Occupation: 'Stair Builder (Stone, concrete)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254109,
    OccDE: 'Treppenbauer/in (Metall)',
    Occupation: 'Stair Maker (Metal)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487302,
    OccDE: 'Treppenbauer (Holz)',
    Occupation: 'Staircase Builder (Timber)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 211321,
    OccDE: 'Stanzer/in (Blechverformung)',
    Occupation: 'Stamper (Sheet Metal Forming)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 359765,
    OccDE: 'Stanzer/in (Textilverarbeitung)',
    Occupation: 'Stamper (Textiles Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550404,
    OccDE: 'Stanzeneinrichter/in',
    Occupation: 'Stamping Machine Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 211324,
    OccDE: 'Stanzmaschinenführer/in',
    Occupation: 'Stamping Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 211706,
    OccDE: 'Stanzmaschinenbediener/in',
    Occupation: 'Stamping Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 211703,
    OccDE: 'Presserhelfer/in',
    Occupation: 'Stamping Press Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 211704,
    OccDE: 'Preßmaschinenbediener/in',
    Occupation: 'Stamping Press Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836113,
    OccDE: 'Messebauer/in',
    Occupation: 'Stand Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836114,
    OccDE: 'Messegestalter/in',
    Occupation: 'Stand Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785302,
    OccDE: 'Staatlich geprüfte(r) Betriebswirt/in',
    Occupation: 'State Certified Business Administrator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 787130,
    OccDE: 'Landesbeamt(in/er)',
    Occupation: 'State Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 765921,
    OccDE: 'Landesinspektor/in',
    Occupation: 'State Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 761103,
    OccDE: 'Landtagsabgeordnete/r',
    Occupation: 'State Legislature Representative',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 761508,
    OccDE: 'Staatssekretär/in',
    Occupation: 'State Secretary',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 765922,
    OccDE: 'Landesoberinspektor/in',
    Occupation: 'State Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 686006,
    OccDE: 'Tankwart/in',
    Occupation: 'Station Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726522,
    OccDE: 'Stationsleiter/in (Luftverkehr)',
    Occupation: 'Station Manager (Aviation)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 712603,
    OccDE: 'Bahnhofsaufseher/in',
    Occupation: 'Station Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997184,
    OccDE: 'Inhaber Tabak-Schreibwaren-Laden',
    Occupation: 'Stationery Tobacco Shop Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 887108,
    OccDE: 'Statistiker/in',
    Occupation: 'Statistician',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 981166,
    OccDE: 'Student/in Statistik',
    Occupation: 'Statistics Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 541102,
    OccDE: 'Dampfkraftwerkmaschinist/in',
    Occupation: 'Steam Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442305,
    OccDE: 'Eisenbieger',
    Occupation: 'Steel Bender',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671265,
    OccDE: 'Stahlkaufmann/-kauffrau',
    Occupation: 'Steel Businessperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603208,
    OccDE: 'Stahlbauingenieur/in',
    Occupation: 'Steel Construction Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 623207,
    OccDE: 'Stahlbautechniker/in',
    Occupation: 'Steel Construction Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623206,
    OccDE: 'Stahlbaukonstrukteur/in (Bautechniker/in)',
    Occupation: 'Steel Construction Engineer (Construction Engineer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 255125,
    OccDE: 'Stahlbauschlosser/in',
    Occupation: 'Steel Construction Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744582,
    OccDE: 'Stahlfahrer/in',
    Occupation: 'Steel Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 294212,
    OccDE: 'Stahlgraveur/in',
    Occupation: 'Steel Engraver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 442308,
    OccDE: 'Eisenflechter',
    Occupation: 'Steel Fixer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191517,
    OccDE: 'Stahlgießer/in',
    Occupation: 'Steel Foundryman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 293112,
    OccDE: 'Stahlformenbauer/in',
    Occupation: 'Steel Mould Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 255213,
    OccDE: 'Stahlschiffbauer/in',
    Occupation: 'Steel Shipbuilder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 191428,
    OccDE: 'Stahlwerker/in',
    Occupation: 'Steel Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323906,
    OccDE: 'Stahlarbeiter/in',
    Occupation: 'Steelworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 782119,
    OccDE: 'Stenotypist/in',
    Occupation: 'Stenographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 782301,
    OccDE: 'Stenokontorist/in',
    Occupation: 'Stenography Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854126,
    OccDE: 'Sterilisationsarbeiter/in (Krankenhaus)',
    Occupation: 'Sterilization Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 743133,
    OccDE: 'Stauer/in',
    Occupation: 'Stevedore',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912599,
    OccDE: 'Steward/eß',
    Occupation: 'Steward/Stewardess',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912511,
    OccDE: 'Steward/eß (Flugzeug)',
    Occupation: 'Steward/Stewardess (Aircraft)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701816,
    OccDE: 'Lagerversandkaufmann/-kauffrau',
    Occupation: 'Stock Shipping Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 704201,
    OccDE: 'Börsenmakler/in',
    Occupation: 'Stockbroker',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 660701,
    OccDE: 'Auffüller/in (Kaufhaus)',
    Occupation: 'Stockist (Department Store)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101633,
    OccDE: 'Steinbehauer',
    Occupation: 'Stone Carver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 461524,
    OccDE: 'Steinsetzer',
    Occupation: 'Stone Layer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101004,
    OccDE: 'Steinbearbeiter/in',
    Occupation: 'Stone Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101212,
    OccDE: 'Steinmetz/in',
    Occupation: 'Stonemason',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101111,
    OccDE: 'Steinmetz/in und Steinbildhauer/in',
    Occupation: 'Stonemason and Sculptor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 101717,
    OccDE: 'Steinmetzhelfer/in',
    Occupation: 'Stonemason Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627509,
    OccDE: 'Steinmetztechniker/in',
    Occupation: 'Stonemason Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744005,
    OccDE: 'Lager- und Transportarbeiter/in',
    Occupation: 'Storage and Transport Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660702,
    OccDE: 'Ladenhilfe',
    Occupation: 'Store Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935731,
    OccDE: 'Straßenreiniger/in',
    Occupation: 'Street Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 716304,
    OccDE: 'Straßenmarkierer/in',
    Occupation: 'Street Marker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935729,
    OccDE: 'Straßenfeger/in',
    Occupation: 'Street Sweeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600303,
    OccDE: 'Berechnungsingenieur/in',
    Occupation: 'Structural Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603603,
    OccDE: 'Statiker/in',
    Occupation: 'Structural Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603604,
    OccDE: 'Baustatiker/in',
    Occupation: 'Structural Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 255123,
    OccDE: 'Stahlbaumonteur/in',
    Occupation: 'Structural Steel Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981198,
    OccDE: 'Student/in',
    Occupation: 'Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861912,
    OccDE: 'Studentenberater/in',
    Occupation: 'Student Counsellor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853615,
    OccDE: 'Hebammenschülerin',
    Occupation: 'Student Midwife',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853104,
    OccDE: 'Lehrschwester',
    Occupation: 'Student Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853019,
    OccDE: 'Krankenpflegeschüler/in',
    Occupation: 'Student Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853022,
    OccDE: 'Krankenschwesterschüler/in',
    Occupation: 'Student Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853043,
    OccDE: 'Schwesternschülerin',
    Occupation: 'Student Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854111,
    OccDE: 'Krankenpflegehelferschüler/in',
    Occupation: 'Student Nursing Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 872120,
    OccDE: 'Referendar/in',
    Occupation: 'Student Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835704,
    OccDE: 'Studioassistent/in (Bühnen-, Bild-, Tontechnik)',
    Occupation: 'Studio Assistant (Stage, Screen, Sound Equipment)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835232,
    OccDE: 'Studioleiter/in',
    Occupation: 'Studio Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 837601,
    OccDE: 'Atelierfotograf/in',
    Occupation: 'Studio Photographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834217,
    OccDE: 'Stylist/in (Bekleidung)',
    Occupation: 'Stylist (Clothing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901806,
    OccDE: 'Stylist/in (Frisuren)',
    Occupation: 'Stylist (Hair)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750110,
    OccDE: 'Subunternehmer/in',
    Occupation: 'Subcontractor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 871205,
    OccDE: 'Fachdozent/in',
    Occupation: 'Subject Lecturer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 997109,
    OccDE: 'Fachreferent/in',
    Occupation: 'Subject Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801177,
    OccDE: 'U-Boot-Besatzung',
    Occupation: 'Submarine Crewmember',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689101,
    OccDE: 'Abonnentenwerber/in',
    Occupation: 'Subscription Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 676104,
    OccDE: 'Substitut/in (Warenhandel)',
    Occupation: 'Substitute (merchandise trade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 393105,
    OccDE: 'Zuckerhersteller/in',
    Occupation: 'Sugar Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801312,
    OccDE: 'Kriminalkommissar/in',
    Occupation: 'Superintendent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 796205,
    OccDE: 'Hausinspektor/in (Hauswart/in)',
    Occupation: 'Superintendent (Caretaker)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 750221,
    OccDE: 'Supermarktleiter/in',
    Occupation: 'Supermarket Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 993107,
    OccDE: 'Supervisor/in',
    Occupation: 'Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869002,
    OccDE: 'Betreuer/in',
    Occupation: 'Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792801,
    OccDE: 'Aufsicht (Spielhalle)',
    Occupation: 'Supervisor (Arcade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792802,
    OccDE: 'Aufsichtsperson (Spielhalle)',
    Occupation: 'Supervisor (Arcade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 660801,
    OccDE: 'Aufsicht (Verkauf)',
    Occupation: 'Supervisor (Sales)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713901,
    OccDE: 'Aufsichtsführende/r (Verkehrsbetrieb)',
    Occupation: 'Supervisor (transport company)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792803,
    OccDE: 'Spielhallenaufseher/in',
    Occupation: 'Supervisor Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712601,
    OccDE: 'Aufsichtsbeamt(er/in) (Eisenbahnbetrieb)',
    Occupation: 'Supervisory Officer (Railway Services)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549119,
    OccDE: 'Versorger/in (Wasserversorgung)',
    Occupation: 'Supplier (Water Supply)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549118,
    OccDE: 'Ver- und Entsorger/in (Fachrichtung Wasserversorgung)',
    Occupation:
      'Supply and Waste Disposal Worker (specialising in Water Supply)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787319,
    OccDE: 'Versorgungsassistent/in',
    Occupation: 'Supply Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601532,
    OccDE: 'Versorgungsingenieur/in (Heizungs-, Lüftungs-, Klimatechnik)',
    Occupation: 'Supply Engineer (Heating, Ventilation, Air Conditioning)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 981176,
    OccDE: 'Student/in Versorgungstechnik',
    Occupation: 'Supply Engineering Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621519,
    OccDE: 'Versorgungstechniker/in (Haustechnik)',
    Occupation: 'Supply Technician (Building Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834123,
    OccDE: 'Oberflächengestalter/in',
    Occupation: 'Surface Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512099,
    OccDE: 'Oberflächenbeschichter/in',
    Occupation: 'Surface Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531914,
    OccDE: 'Oberflächenbearbeiter/in',
    Occupation: 'Surface Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841209,
    OccDE: 'Chirurg/in',
    Occupation: 'Surgeon',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 295103,
    OccDE: 'Chirurgiemechaniker/in',
    Occupation: 'Surgical Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853406,
    OccDE: 'Operationspfleger',
    Occupation: 'Surgical Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853407,
    OccDE: 'Operationsschwester',
    Occupation: 'Surgical Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 307202,
    OccDE: 'Bandagist/in',
    Occupation: 'Surgical Truss Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 604518,
    OccDE: 'Vermessungsassessor/in',
    Occupation: 'Surveying Assessor',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 624505,
    OccDE: 'Vermessungsassistent/in',
    Occupation: 'Surveying Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 624103,
    OccDE: 'Vermessungsgehilf(e/in) (Vermessungstechniker/in)',
    Occupation: 'Surveying Assistant (Surveying Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744918,
    OccDE: 'Meßgehilf(e/in) (Vermessungswesen)',
    Occupation: 'Surveying Assistant (Surveying)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 624506,
    OccDE: 'Vermessungsbeamt(er/in)',
    Occupation: 'Surveying Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 604515,
    OccDE: 'Vermessungs(rat/rätin)',
    Occupation: 'Surveying Deputy Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 624008,
    OccDE: 'Vermessungsangestellte/r',
    Occupation: 'Surveying Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 604107,
    OccDE: 'Vermessungsingenieur/in',
    Occupation: 'Surveying Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 604106,
    OccDE: 'Vermessungsingenieur/in (Geodäsie, Ingenieurgeodäsie)',
    Occupation: 'Surveying Engineer (Geodesy, Engineering Geodesy)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 604206,
    OccDE: 'Vermessungsingenieur/in (Liegenschaftsvermessung)',
    Occupation: 'Surveying Engineer (Property Surveying)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 604207,
    OccDE: 'Vermessungsingenieur/in (Topographie)',
    Occupation: 'Surveying Engineer (Topography)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 604520,
    OccDE: 'Vermessungsinspektor/in',
    Occupation: 'Surveying Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 604524,
    OccDE: 'Vermessungsreferendar/in',
    Occupation: 'Surveying Referendary',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 624513,
    OccDE: 'Vermessungssekretär/in',
    Occupation: 'Surveying Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 624512,
    OccDE: 'Vermessungsobersekretär/in',
    Occupation: 'Surveying Senior Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 604523,
    OccDE: 'Vermessungsoberinspektor/in',
    Occupation: 'Surveying Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 981175,
    OccDE: 'Student/in Vermessungswesen',
    Occupation: 'Surveying Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 624009,
    OccDE: 'Vermessungsfacharbeiter/in',
    Occupation: 'Surveying Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 624010,
    OccDE: 'Vermessungstechniker/in',
    Occupation: 'Surveying Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 624007,
    OccDE: 'Vermesser/in (Vermessungstechniker/in)',
    Occupation: 'Surveyor (Surveying Technology)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642603,
    OccDE: 'Vermessungszeichner/in',
    Occupation: 'Surveyor Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 393401,
    OccDE: 'Bonbonmacher/in',
    Occupation: 'Sweets Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876206,
    OccDE: 'Schwimmtrainer/in',
    Occupation: 'Swimming Coach',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 795303,
    OccDE: 'Schwimmbadbetriebsleiter/in',
    Occupation: 'Swimming Facilities Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876204,
    OccDE: 'Schwimmlehrer/in',
    Occupation: 'Swimming Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 795203,
    OccDE: 'Schwimmeister/in (nicht Lehrbetrieb)',
    Occupation: 'Swimming Instructor (not Instructor)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 795101,
    OccDE: 'Bademeistergehilf(e/in)',
    Occupation: 'Swimming Pool Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 267113,
    OccDE: 'Schwimmbadbauer/in',
    Occupation: 'Swimming Pool Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311305,
    OccDE: 'Schaltanlagenbauer/in',
    Occupation: 'Switchgear Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311306,
    OccDE: 'Schaltanlagenmonteur/in',
    Occupation: 'Switchgear Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712123,
    OccDE: 'Weichenwärter/in',
    Occupation: 'Switchguard',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549410,
    OccDE: 'Schaltwärter/in',
    Occupation: 'Switchingboard Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712122,
    OccDE: 'Weichensteller/in',
    Occupation: 'Switchman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 541313,
    OccDE: 'Schaltmeister/in',
    Occupation: 'Switchmaster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512912,
    OccDE: 'Kunststofflackierer/in',
    Occupation: 'Synthetics Varnisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 775203,
    OccDE: 'Systementwickler/in (EDV)',
    Occupation: 'System Developer (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775202,
    OccDE: 'System Engineer',
    Occupation: 'System Engineer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778320,
    OccDE: 'Systemadministrator/in (EDV)',
    Occupation: 'Systems Administrator (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778322,
    OccDE: 'Systemverwalter/in (EDV)',
    Occupation: 'Systems Administrator (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776208,
    OccDE: 'Systemanalytiker/in',
    Occupation: 'Systems Analyst',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776122,
    OccDE: 'Systemarchitekt/in (DV-Organisator/in)',
    Occupation: 'Systems Architect (DP Organiser)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776312,
    OccDE: 'System-Assistent/in (EDV)',
    Occupation: 'Systems Assistant (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775204,
    OccDE: 'Systeminformatiker/in',
    Occupation: 'Systems Computer Scientist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 777115,
    OccDE: 'Systemberater/in (EDV)',
    Occupation: 'Systems Consultant (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776314,
    OccDE: 'Systemkoordinator/in (EDV)',
    Occupation: 'Systems Coordinator (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776123,
    OccDE: 'Systemdesigner/in (DV-Organisator/in)',
    Occupation: 'Systems Designer (DP Organiser)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 311102,
    OccDE: 'Anlagenelektroniker/in',
    Occupation: 'Systems Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 775205,
    OccDE: 'Systemingenieur/in (EDV)',
    Occupation: 'Systems Engineer (EDP)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 311101,
    OccDE: 'Anlagenelektroinstallateur/in',
    Occupation: 'Systems Installation Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311103,
    OccDE: 'Anlagenmonteur/in (Energieanlagen)',
    Occupation: 'Systems Installer (Energy Systems)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 540005,
    OccDE: 'Anlagenwart/in',
    Occupation: 'Systems Maintenance Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778125,
    OccDE: 'Systemoperator/in',
    Occupation: 'Systems Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273941,
    OccDE: 'Systemführer/in',
    Occupation: 'Systems Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 540002,
    OccDE: 'Anlagenbediener/in',
    Occupation: 'Systems Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 540003,
    OccDE: 'Anlagenführer/in',
    Occupation: 'Systems Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 540099,
    OccDE: 'Anlagenfahrer/in',
    Occupation: 'Systems Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778123,
    OccDE: 'Systembediener/in (Konsoloperator/in)',
    Occupation: 'Systems Operator (Console Operator)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 201301,
    OccDE: 'Anlagenfahrer für Dauerformen',
    Occupation: 'Systems Operator for Permanent Moulds',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 776126,
    OccDE: 'Systemplaner/in (EDV)',
    Occupation: 'Systems Planner (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775206,
    OccDE: 'Systemprogrammierer/in',
    Occupation: 'Systems Programmer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 777203,
    OccDE: 'Systemverkäufer/in (EDV)',
    Occupation: 'Systems Salesman (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776399,
    OccDE: 'Systembetreuer/in',
    Occupation: 'Systems Service Technician',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776313,
    OccDE: 'Systembetreuer/in (EDV)',
    Occupation: 'Systems Service Technician (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 540004,
    OccDE: 'Anlagenwärter/in',
    Occupation: 'Systems Servicing Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 775207,
    OccDE: 'Systemsoftwareentwickler/in',
    Occupation: 'Systems Software Developer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 778321,
    OccDE: 'Systemspezialist/in (EDV)',
    Occupation: 'Systems Specialist (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 622319,
    OccDE: 'Systemtechniker/in (EDV-Wartungstechniker/in)',
    Occupation: 'Systems Technician (EDP Maintenance Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621919,
    OccDE: 'Systemtechniker/in (Maschinentechniker/in)',
    Occupation: 'Systems Technician (Machine Technician)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621416,
    OccDE: 'Systemtechniker/in (Meßwerke)',
    Occupation: 'Systems Technician (Measuring)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622605,
    OccDE: 'Systemtester/in (Elektrotechniker/in)',
    Occupation: 'Systems Tester (Electrical Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 505127,
    OccDE: 'Tischmacher/in',
    Occupation: 'Table Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 351007,
    OccDE: 'Maßschneider/in',
    Occupation: 'Tailor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 351011,
    OccDE: 'Schneider/in',
    Occupation: 'Tailor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 351109,
    OccDE: 'Schneider/in (Bekleidungsschneider/in)',
    Occupation: 'Tailor (Garment Tailor)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 911493,
    OccDE: 'Imbißstubenbesitzer/in',
    Occupation: 'Take Away Shop Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 937224,
    OccDE: 'Tankreiniger/in',
    Occupation: 'Tank Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 264907,
    OccDE: 'Tankanlagenmonteur/in',
    Occupation: 'Tank System Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714518,
    OccDE: 'Tankwagenführer/in',
    Occupation: 'Tanker Diver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 371002,
    OccDE: 'Gerber/in',
    Occupation: 'Tanner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 423803,
    OccDE: 'Koster/in',
    Occupation: 'Taster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 902902,
    OccDE: 'Tätowierer/in',
    Occupation: 'Tattoo Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 753302,
    OccDE: 'Steuerbevollmächtigte/r',
    Occupation: 'Tax Agent',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 754102,
    OccDE: 'Fachgehilf(e/in) in steuer- und wirtschaftsberatenden Berufen',
    Occupation: 'Tax and Business Advisory Professional',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 754112,
    OccDE: 'Steuergehilf(e/in)',
    Occupation: 'Tax Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 754111,
    OccDE: 'Steuerfachgehilf(e/in)',
    Occupation: 'Tax Assistant Associate',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 754199,
    OccDE: 'Steuerfachangestellte/r',
    Occupation: 'Tax Associate',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 753509,
    OccDE: 'Steuerprüfer/in',
    Occupation: 'Tax Auditor',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 753507,
    OccDE: 'Steuerfahnder/in',
    Occupation: 'Tax Investigation',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 714214,
    OccDE: 'Taxifahrer/in',
    Occupation: 'Taxi Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 715105,
    OccDE: 'Taxiunternehmer/in',
    Occupation: 'Taxi Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 309410,
    OccDE: 'Präparator/in',
    Occupation: 'Taxidermist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 652206,
    OccDE: 'Lehrmeister/in',
    Occupation: 'Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 870103,
    OccDE: 'Lehrer/in',
    Occupation: 'Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879907,
    OccDE: 'Lehrkraft',
    Occupation: 'Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873406,
    OccDE: 'Lehrer/in (an Gesamtschulen)',
    Occupation: 'Teacher (at Comprehensive Schools)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 873203,
    OccDE: 'Lehrer/in (an Hauptschulen)',
    Occupation: 'Teacher (at Lower Secondary Schools)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873102,
    OccDE: 'Lehrer/in (an Grundschulen)',
    Occupation: 'Teacher (at Primary Schools)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873302,
    OccDE: 'Lehrer/in (an Realschulen)',
    Occupation: 'Teacher (at Secondary Schools Realschule)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 872111,
    OccDE: 'Lehrer/in (an Gymnasien)',
    Occupation: 'Teacher (at Secondary Schools)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 873509,
    OccDE: 'Lehrer/in (an Sonderschulen)',
    Occupation: 'Teacher (at Special Education Schools)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 870102,
    OccDE: 'Lehrer/in (an allgemeinbildenden Schulen)',
    Occupation: 'Teacher (in General Education)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 872101,
    OccDE: 'Assessor/in des Lehramts',
    Occupation:
      'Teacher (non-tenured, with additional state certification for secondary education)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873407,
    OccDE: 'Lehrer/in (Primarstufe an Gesamtschulen)',
    Occupation: 'Teacher (Primary Level at Comprehensive Schools)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873408,
    OccDE: 'Lehrer/in (Sekundarstufe I an Gesamtschulen)',
    Occupation: 'Teacher (Secondary Level I at Comprehensive Schools)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 873204,
    OccDE: 'Lehrer/in (Sekundarstufe I an Grund- und Hauptschulen)',
    Occupation: 'Teacher (Secondary School I at Primary and Secondary Schools)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873303,
    OccDE: 'Lehrer/in (Sekundarstufe I an Realschulen)',
    Occupation: 'Teacher (Secondary School I at Secondary Schools)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874015,
    OccDE: 'Lehrer/in (Sekundarstufe II, berufsbildendes Schulwesen)',
    Occupation: 'Teacher (Secondary School II, Vocational Education)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 872112,
    OccDE: 'Lehrer/in (Sekundarstufe II, allgemeinbildendes Schulwesen)',
    Occupation: 'Teacher (upper secondary general education schools)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 872113,
    OccDE:
      'Lehrer/in an allgemeinbild. polytechn. oder erweiterten Oberschulen',
    Occupation: 'Teacher at a general ed. poltytech. or other secondary school',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 863104,
    OccDE: 'Erzieher/in für Schulhorte',
    Occupation: 'Teacher at After-School Centres',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874903,
    OccDE: 'Lehrer/in an berufsbildenden Schulen (Fachrichtung Gesundheit)',
    Occupation: 'Teacher at Vocational Schools (Health Care)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 870202,
    OccDE: 'Lehramtsbewerber/in',
    Occupation: 'Teacher Candidate',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873508,
    OccDE: 'Legastheniepädagog(e/in)',
    Occupation: 'Teacher for Students with Dyslexia',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 873502,
    OccDE: 'Blindenlehrer/in',
    Occupation: 'Teacher for Students with Impaired Sight',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 873511,
    OccDE: 'Schwerhörigenlehrer/in',
    Occupation: 'Teacher for the Hearing Impaired',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 873518,
    OccDE: 'Taubstummenlehrer/in',
    Occupation: 'Teacher for the Students with Hearing and Speech Impairments',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 873507,
    OccDE: 'Hörgeschädigtenlehrer/in',
    Occupation: 'Teacher for the Students with Hearing Impairments',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 873512,
    OccDE: 'Sehbehindertenlehrer/in',
    Occupation: 'Teacher for the Visually Impaired',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 879608,
    OccDE: 'Lehrer/in für Erwachsenenbildung',
    Occupation: 'Teacher of Adult Education',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879604,
    OccDE: 'Diplomlehrer/in für Erwachsenenbildung',
    Occupation: 'Teacher of Adult Education with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874522,
    OccDE: 'Lehrer/in für Fachpraxis',
    Occupation: 'Teacher of Applied Skills',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874208,
    OccDE: 'Lehrer/in für kaufmännische Fächer',
    Occupation: 'Teacher of Business Subjects',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874307,
    OccDE: 'Lehrer/in für Hauswirtschaft',
    Occupation: 'Teacher of Home Economics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873510,
    OccDE: 'Lehrer/in für Sonderpädagogik',
    Occupation: 'Teacher of Special Education',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 862003,
    OccDE: 'Erzieher/in für Sonderpädagogik',
    Occupation: 'Teacher of Special Education',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 872102,
    OccDE: 'Diplomlehrer/in an allgemeinbildenden polytechnischen Oberschulen',
    Occupation: 'Teacher with Degree for General Polytechnical schools',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 873902,
    OccDE: 'Pädagogische Unterrichtshilfe (Sonderschule)',
    Occupation: 'Teaching Aid (Special Education)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873409,
    OccDE: 'Pädagogische(r) Leiter/in (Gesamtschule)',
    Occupation: 'Teaching Director (Comprehensive Schools)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 981139,
    OccDE: 'Student/in Lehramt',
    Occupation: 'Teaching Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 789207,
    OccDE: 'Teamsekretär/in',
    Occupation: 'Team Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629140,
    OccDE: 'Technische(r) Verwaltungsbeamt(er/in)',
    Occupation: 'Technical Administration Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608140,
    OccDE: 'Technische(r) Verwaltungsoberinspektor/in',
    Occupation: 'Technical Administrative Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 629108,
    OccDE: 'Technische(r) Assistent/in',
    Occupation: 'Technical Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774412,
    OccDE: 'Technische(r) Assistent/in (Informatik)',
    Occupation: 'Technical Assistant (Computer Science)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 622008,
    OccDE: 'Technische(r) Assistent/in (Fachrichtung Elektrotechnik)',
    Occupation: 'Technical Assistant (Electrical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857154,
    OccDE: 'Technische(r) Assistent/in an medizinischen Instituten',
    Occupation: 'Technical Assistant at Medical Institutions',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 631008,
    OccDE:
      'Technische(r) Assistent/in für chemische und biologische Laboratorien',
    Occupation: 'Technical Assistant for chemical and biological laboratories',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621814,
    OccDE:
      'Technische(r) Assistent/in für Konstruktions- und Fertigungstechnik',
    Occupation:
      'Technical Assistant for Construction and Manufacturing Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626405,
    OccDE: 'Technische(r) Assistent/in für Physik',
    Occupation: 'Technical Assistant for Physics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785503,
    OccDE: 'Technische(r) Betriebswirt/in',
    Occupation: 'Technical Business Administrator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 881601,
    OccDE: 'Technische(r) Diplom-Betriebswirt/in',
    Occupation: 'Technical Business Administrator with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 785210,
    OccDE: 'Technische(r) Kaufmann/Kauffrau',
    Occupation: 'Technical Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 620006,
    OccDE: 'Technische(r) Sachbearbeiter/in',
    Occupation: 'Technical Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621012,
    OccDE: 'Technische(r) Sachbearbeiter/in (Maschinenbau)',
    Occupation: 'Technical Clerk (Mechanical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620804,
    OccDE: 'Technische(r) Berater/in',
    Occupation: 'Technical Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621010,
    OccDE: 'Technische(r) Berater/in (Maschinenbautechniker/in)',
    Occupation: 'Technical Consultant ((Mechanical Engineer)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 608246,
    OccDE: 'Technische(r) Berater/in (Gutachter/in)',
    Occupation: 'Technical Consultant (Appraiser)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621114,
    OccDE: 'Technische(r) Berater/in (Kfz-Techniker/in)',
    Occupation: 'Technical Consultant (Automotive Technician)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622009,
    OccDE: 'Technische(r) Berater/in (Elektrotechniker/in)',
    Occupation: 'Technical Consultant (Electrical Engineer)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 600805,
    OccDE: 'Technische(r) Berater/in (Ingenieur/in)',
    Occupation: 'Technical Consultant (Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 756228,
    OccDE: 'Technische(r) Controller/in',
    Occupation: 'Technical Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600909,
    OccDE: 'Technische(r) Koordinator/in',
    Occupation: 'Technical Coordinator',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 751317,
    OccDE: 'Technische(r) Kundendienstleiter/in',
    Occupation: 'Technical Customer Services Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608107,
    OccDE: 'Technische(r) Amtmann/Amtfrau',
    Occupation: 'Technical Deputy Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 600604,
    OccDE: 'Technische(r) Direktor/in',
    Occupation: 'Technical Director',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 608119,
    OccDE: 'Technische(r) Oberamtsrat/Oberamtsrätin',
    Occupation: 'Technical Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 641003,
    OccDE: 'Technische(r) Zeichner/in',
    Occupation: 'Technical Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 642003,
    OccDE: 'Technische(r) Bauzeichner/in',
    Occupation: 'Technical Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834607,
    OccDE: 'Technische(r) Illustrator/in',
    Occupation: 'Technical Draughtsman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 641311,
    OccDE: 'Technische(r) Zeichner/in (Elektrotechnik)',
    Occupation: 'Technical Draughtsman (Electrical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 641204,
    OccDE: 'Technische(r) Zeichner/in (Maschinen- und Fahrzeugbau)',
    Occupation:
      'Technical Draughtsman (Engineering and Automotive Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 641406,
    OccDE: 'Technische(r) Zeichner/in (Anlagen-, Behälter-, Rohrleitungsbau)',
    Occupation:
      'Technical Draughtsman (Equipment, Container, Pipe Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 641502,
    OccDE:
      'Technische(r) Zeichner/in (Heizungs-, Lüftungs-, Sanitäranlagenbau)',
    Occupation:
      'Technical Draughtsman (Heating, Ventilation, Sanitation Systems Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 641603,
    OccDE:
      'Technische(r) Zeichner/in (Betriebs-, Fertigungsmittelkonstruktion)',
    Occupation:
      'Technical Draughtsman (Industrial and Manufacturing Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821404,
    OccDE: 'Fachredakteur/in',
    Occupation: 'Technical Editor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821417,
    OccDE: 'Technische(r) Redakteur/in',
    Occupation: 'Technical Editor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620003,
    OccDE: 'Technische(r) Angestellte/r',
    Occupation: 'Technical Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620005,
    OccDE: 'Technische(r) Mitarbeiter/in',
    Occupation: 'Technical Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621008,
    OccDE: 'Technische(r) Angestellte/r (Maschinenbau)',
    Occupation: 'Technical Employee (Mechanical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608250,
    OccDE: 'Technische(r) Sachverständige/r',
    Occupation: 'Technical Expert',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 689907,
    OccDE: 'Technische(r) Außendienstmitarbeiter/in',
    Occupation: 'Technical Field Service Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608131,
    OccDE: 'Technische(r) Regierungsoberinspektor/in',
    Occupation: 'Technical Government Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 834606,
    OccDE: 'Technische(r) Grafiker/in',
    Occupation: 'Technical Graphic Artist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834006,
    OccDE: 'Gestaltungstechnische(r) Assistent/in',
    Occupation: 'Technical Graphic Design Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608116,
    OccDE: 'Technische(r) Inspektor/in',
    Occupation: 'Technical Higher Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 779205,
    OccDE: 'Fachschulkaufmann/-kauffrau (EDV)',
    Occupation: 'Technical Institute Clerk (EDP)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 633013,
    OccDE: 'Technische(r) Laborant/in',
    Occupation: 'Technical Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608245,
    OccDE:
      'Technische(r) Aufsichtsbeamt(er/in) der gesetzlichen Unfallversicherung',
    Occupation:
      'Technical Labour Inspector of the German Social Accident Insurance',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 220006,
    OccDE: 'Zerspanungsfacharbeiter/in',
    Occupation: 'Technical Machinist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600606,
    OccDE: 'Technische(r) Leiter/in',
    Occupation: 'Technical Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 750222,
    OccDE: 'Technische(r) Geschäftsführer/in',
    Occupation: 'Technical Managing Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835420,
    OccDE: 'Technische(r) Medienbearbeiter/in',
    Occupation: 'Technical Media Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785208,
    OccDE: 'Technische(r) Bürosachbearbeiter/in',
    Occupation: 'Technical Office Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 629109,
    OccDE: 'Technische(r) Beamt(er/in)',
    Occupation: 'Technical Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 721519,
    OccDE: 'Technische(r) Schiffsoffizier/in',
    Occupation: 'Technical Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620411,
    OccDE: 'Technische(r) Betriebsassistent/in',
    Occupation: 'Technical Operations Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600603,
    OccDE: 'Technische(r) Betriebsleiter/in',
    Occupation: 'Technical Operations Manager',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 776199,
    OccDE: 'Technische/r Projektmanager/in (EDV)',
    Occupation: 'Technical Project Manager (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 677114,
    OccDE: 'Technische(r) Einkäufer/in',
    Occupation: 'Technical Purchaser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 757103,
    OccDE: 'Technische(r) Vertriebsberater/in',
    Occupation: 'Technical Sales Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621921,
    OccDE: 'Technische(r) Verkaufsberater/in (Werkzeugmaschinen)',
    Occupation: 'Technical Sales Consultant (Tool Machines)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608120,
    OccDE: 'Technische(r) Oberinspektor/in',
    Occupation: 'Technical Senior Executive Officer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 629122,
    OccDE: 'Technische(r) Obersekretär/in',
    Occupation: 'Technical Senior Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629131,
    OccDE: 'Technische(r) Regierungsobersekretär/in',
    Occupation: 'Technical Senior Secretary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620004,
    OccDE: 'Technische(r) Fachwirt/in',
    Occupation: 'Technical Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 774196,
    OccDE: 'Technical Support Spezialist/in',
    Occupation: 'Technical Support Specialist',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 874534,
    OccDE: 'Technische(r) Lehrer/in',
    Occupation: 'Technical Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312217,
    OccDE: 'Technische(r) Fernmeldesekretär/in',
    Occupation: 'Technical Telecommunications Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312215,
    OccDE: 'Technische(r) Fernmeldehauptsekretär/in',
    Occupation: 'Technical Telecommunications Senior Administrative Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620112,
    OccDE: 'Technische(r) Prüfer/in',
    Occupation: 'Technical Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 785212,
    OccDE: 'Technische(r) Textilkaufmann/-kauffrau',
    Occupation: 'Technical Textile Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 822204,
    OccDE: 'Technische(r) Übersetzer/in',
    Occupation: 'Technical Translator',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 821122,
    OccDE: 'Technische(r) Autor/in',
    Occupation: 'Technical Writer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 278107,
    OccDE: 'Zurichter/in',
    Occupation: 'Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 991101,
    OccDE: 'Facharbeiter/in',
    Occupation: 'Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620002,
    OccDE: 'Techniker/in',
    Occupation: 'Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 179006,
    OccDE: 'Fachhilfsarbeiter/in (Buchdruckerei)',
    Occupation: 'Technician (Book Printing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '052406',
    OccDE: 'Techniker/in (Garten- und Landschaftsbau)',
    Occupation: 'Technician (Garden and Landscaping)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 278106,
    OccDE: 'Zurichter/in (Metall)',
    Occupation: 'Technician (Metal)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 276003,
    OccDE: 'Facharbeiter/in für automatisierte Produktionssysteme',
    Occupation: 'Technician for Automated Productions Systems',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 276002,
    OccDE: 'Facharbeiter/in für automatisierte Anlagen',
    Occupation: 'Technician for Automated Systems',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622909,
    OccDE: 'Techniker/in für Automatisierungstechnik',
    Occupation: 'Technician for Automated Technology',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 623304,
    OccDE: 'Techniker/in für Hoch- und Industriebau',
    Occupation:
      'Technician for Building Construction and Industrial Engineering',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623305,
    OccDE: 'Techniker/in für Hochbau',
    Occupation: 'Technician for Building Construction Engineering',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622322,
    OccDE: 'Techniker/in für Informatik',
    Occupation: 'Technician for Computer Science',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 317503,
    OccDE: 'Mechaniker/in für Datenverarbeitungs- und Büromaschinen',
    Occupation: 'Technician for Data Processing and Office Equipment',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622007,
    OccDE: 'Techniker/in für Elektrotechnik',
    Occupation: 'Technician for Electrical Engineering',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 622321,
    OccDE: 'Techniker/in für Elektronik',
    Occupation: 'Technician for Electronics',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621516,
    OccDE: 'Techniker/in für Energietechnik (Wärmeversorgung)',
    Occupation: 'Technician for Energy Technology Engineering (Heating Supply)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629315,
    OccDE: 'Techniker/in für Umweltschutz',
    Occupation: 'Technician for Environmental protection',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 550105,
    OccDE: 'Facharbeiter/in für Anlagen und Geräte',
    Occupation: 'Technician for Equipment',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621920,
    OccDE: 'Techniker/in für Anlagenbau',
    Occupation: 'Technician for Equipment Engineering',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621419,
    OccDE: 'Techniker/in für Gerätetechnik',
    Occupation: 'Technician for Equipment Engineering',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621810,
    OccDE: 'Techniker/in für Anlageninstandhaltung',
    Occupation: 'Technician for Equipment Maintenance',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 435404,
    OccDE: 'Facharbeiter/in für Lebensmitteltechnik (Nährmittel, Gewürze)',
    Occupation: 'Technician for Food Technology (Processed Foodstuffs, Spices)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 393101,
    OccDE: 'Facharbeiter/in für Lebensmitteltechnik (Zucker)',
    Occupation: 'Technician for Food Technology (Sugar)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 921604,
    OccDE: 'Techniker/in für Hauswirtschaft und Ernährung',
    Occupation: 'Technician for Housekeeping and Nutrition',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628251,
    OccDE: 'Techniker/in für Arbeitsorganisation und Arbeitsgestaltung',
    Occupation: 'Technician for Labour Organisation and Design',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621609,
    OccDE: 'Techniker/in für Maschinenkonstruktion',
    Occupation: 'Technician for Machinery Design',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 628252,
    OccDE: 'Techniker/in für Betriebswissenschaft - REFA',
    Occupation: 'Technician for Management Science REFA',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621812,
    OccDE: 'Techniker/in für Fertigungstechnologie der metallverarb. Industrie',
    Occupation:
      'Technician for Manufacturing Technology in the Metal Processing Industry',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629314,
    OccDE: 'Techniker/in für Medizintechnik',
    Occupation: 'Technician for Medical Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756146,
    OccDE: 'Techniker/in für Bürotechnik und Organisationshilfsmittel',
    Occupation: 'Technician for Office Technology and Organisational Resources',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626206,
    OccDE: 'Techniker/in für Farben, Lacke, Kunststoffe',
    Occupation: 'Technician for Paints, Varnish, Plastics',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606602,
    OccDE: 'Ingenieur/in für Farben, Lacke, Kunststoffe',
    Occupation: 'Technician for Paints, Varnishes, Plastics',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 627607,
    OccDE: 'Techniker/in für Kunststoffverarbeitung',
    Occupation: 'Technician for Plastics Processing',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 624004,
    OccDE: 'Techniker/in für Vermessungstechnik',
    Occupation: 'Technician for Surveying Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627350,
    OccDE: 'Techniker/in für Textilveredlung/Textilreinigung',
    Occupation: 'Technician for Textile Finishing / Textile Cleaning',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627412,
    OccDE: 'Techniker/in für Holzerzeugniskonstruktion',
    Occupation: 'Technician for Timber Products Construction',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 293101,
    OccDE: 'Facharbeiter/in für Fertigungsmittel (Formenbauer/in)',
    Occupation: 'Technician for Toolmaking (Casting and Stamping)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621112,
    OccDE: 'Techniker/in für Kraftfahrzeugtechnik',
    Occupation: 'Technician for Vehicle Engineering',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629312,
    OccDE: 'Techniker/in für Abwassertechnik',
    Occupation: 'Technician for Wastewater Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629316,
    OccDE: 'Techniker/in für Wasserversorgung und Abwasserbehandlung',
    Occupation: 'Technician for Water Supply and Wastewater Treatment',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 629317,
    OccDE: 'Techniker/in für Wasserversorgungstechnik',
    Occupation: 'Technician for Water Supply Technology',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 358205,
    OccDE: 'Schwergewebekonfektionär/in',
    Occupation: 'Technician in Heavy-Duty Fabric Goods Manufacturing',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 162201,
    OccDE: 'Facharbeiter/in für Anlagentechnik (Konfektionierung, Verpackung)',
    Occupation: 'Technician in Plant Systems (Manufacturing, Packaging)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600005,
    OccDE: 'Technolog(e/in)',
    Occupation: 'Technologist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 774413,
    OccDE: 'Technologieassistent/in (EDV)',
    Occupation: 'Technology Assistant (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 751811,
    OccDE: 'Technologieassistent/in der Wirtschaft',
    Occupation: 'Technology Assistant Business',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874104,
    OccDE: 'Diplomingenieurpädagog(e/in)',
    Occupation: 'Technology Teacher with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 612306,
    OccDE: 'Technomathematiker/in',
    Occupation: 'Technomathematician',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 602205,
    OccDE: 'Fernmeldeingenieur/in',
    Occupation: 'Telecommunication Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 622206,
    OccDE: 'Fernmeldesekretär/in (Fernmeldetechniker/in)',
    Occupation: 'Telecommunication Secretary (Telecommunications Technician)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 787513,
    OccDE: 'Fernmeldeassistent/in',
    Occupation: 'Telecommunications Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787514,
    OccDE: 'Fernmeldebeamt(er/in)',
    Occupation: 'Telecommunications Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787516,
    OccDE: 'Fernmeldesachbearbeiter/in',
    Occupation: 'Telecommunications Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622204,
    OccDE: 'Fernmeldebauleiter/in',
    Occupation: 'Telecommunications Construction Manager',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 312014,
    OccDE: 'Fernmeldeelektriker/in',
    Occupation: 'Telecommunications Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312037,
    OccDE: 'Telekommunikationselektroniker/in',
    Occupation: 'Telecommunications Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312401,
    OccDE: 'Fernmeldeelektroniker/in',
    Occupation: 'Telecommunications Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316345,
    OccDE: 'Nachrichtenelektroniker/in',
    Occupation: 'Telecommunications Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787512,
    OccDE: 'Fernmeldeangestellte/r',
    Occupation: 'Telecommunications Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 602208,
    OccDE: 'Nachrichtentechnikingenieur/in',
    Occupation: 'Telecommunications Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 312205,
    OccDE: 'Fernmeldebaumonteur/in',
    Occupation: 'Telecommunications Engineering Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316348,
    OccDE: 'Nachrichtengerätemechaniker/in',
    Occupation: 'Telecommunications Equipment Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312301,
    OccDE: 'Fernmeldeinstallateur/in',
    Occupation: 'Telecommunications Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312306,
    OccDE: 'Fernmeldemonteur/in',
    Occupation: 'Telecommunications Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787517,
    OccDE: 'Fernmeldesekretär/in (Verwaltung)',
    Occupation: 'Telecommunications Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312501,
    OccDE: 'Fernmeldeanlagenelektroniker/in',
    Occupation: 'Telecommunications Systems Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622208,
    OccDE: 'Fernmeldetechniker/in',
    Occupation: 'Telecommunications Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 312504,
    OccDE: 'Fernmeldemechaniker/in',
    Occupation: 'Telecommunications Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312017,
    OccDE: 'Fernmelder/in',
    Occupation: 'Telecommunications Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 312206,
    OccDE: 'Fernmeldehandwerker/in',
    Occupation: 'Telecommunications Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 735205,
    OccDE: 'Telegraphist/in',
    Occupation: 'Telegrapher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662910,
    OccDE: 'Telefonverkäufer/in',
    Occupation: 'Telemarketer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 735311,
    OccDE: 'Telefonist/in',
    Occupation: 'Telephone Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 735312,
    OccDE: 'Telephone Operator',
    Occupation: 'Telephone Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622209,
    OccDE: 'Fernsprechtechniker/in',
    Occupation: 'Telephone Technician',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 315101,
    OccDE: 'Fernseh-Rundfunk-Techniker/in (Mechaniker/in)',
    Occupation: 'Television and Radio Technician (Mechanic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316202,
    OccDE: 'Fertigungselektroniker/in',
    Occupation: 'Television Electronics Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315103,
    OccDE: 'Fernsehmechaniker/in',
    Occupation: 'Television Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622503,
    OccDE: 'Fernsehtechniker/in',
    Occupation: 'Television Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 315104,
    OccDE: 'Fernsehtechniker/in (Mechaniker/in)',
    Occupation: 'Television Technician (Mechanic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 411003,
    OccDE: 'Aushilfskoch/-köchin',
    Occupation: 'Temporary Cook',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912001,
    OccDE: 'Aushilfskellner/in',
    Occupation: 'Temporary Waiter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013754',
    OccDE: 'Zeitarbeiter/in',
    Occupation: 'Temporary Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997103,
    OccDE: 'Aushilfe',
    Occupation: 'Temporary Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997116,
    OccDE: 'Springer/in',
    Occupation: 'Temporary Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876801,
    OccDE: 'Tennislehrer/in',
    Occupation: 'Tennis Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876803,
    OccDE: 'Tennistrainer/in',
    Occupation: 'Tennis Trainer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 358208,
    OccDE: 'Zeltmacher/in',
    Occupation: 'Tentmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 487422,
    OccDE: 'Zeltebauer',
    Occupation: 'Tentmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764402,
    OccDE: 'Akademische(r) Rat/Rätin',
    Occupation: 'Tenured Academic Officer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 871303,
    OccDE: 'Akademische(r) Rat/Rätin (Hochschule)',
    Occupation: 'Tenured Academic Officer (University College)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 872123,
    OccDE: 'Studien(rat/rätin)',
    Occupation: 'Tenured Secondary School Teacher',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 714911,
    OccDE: 'Testfahrer/in',
    Occupation: 'Test Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600121,
    OccDE: 'Versuchsingenieur/in',
    Occupation: 'Test Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 600118,
    OccDE: 'Testingenieur/in',
    Occupation: 'Test Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 600111,
    OccDE: 'Prüfingenieur/in',
    Occupation: 'Test Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 608232,
    OccDE: 'Prüfingenieur/in für Sicherheitsfragen',
    Occupation: 'Test Engineer for Security Questions',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603601,
    OccDE: 'Prüfingenieur/in für Baustatik',
    Occupation: 'Test Engineer for Structural Engineering',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 778127,
    OccDE: 'Testoperator/in (EDV)',
    Occupation: 'Test Operator (EDP)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 726999,
    OccDE: 'Einflieger/in',
    Occupation: 'Test Pilot',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620106,
    OccDE: 'Prüftechniker/in',
    Occupation: 'Test Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 620114,
    OccDE: 'Versuchstechniker/in',
    Occupation: 'Test Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621415,
    OccDE: 'Prüfstandtechniker/in',
    Occupation: 'Test Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521008,
    OccDE: 'Prüfer/in',
    Occupation: 'Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621412,
    OccDE: 'Prüfer/in für Luftfahrtgerät',
    Occupation: 'Tester for Aviation Equipment',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 316806,
    OccDE: 'Prüffeldelektroniker/in',
    Occupation: 'Testing Bay Electrician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622407,
    OccDE: 'Prüffeldmeßtechniker/in',
    Occupation: 'Testing Bay Measuring Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 273032,
    OccDE: 'Prüfstandmechaniker/in',
    Occupation: 'Testing Bay Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273031,
    OccDE: 'Prüfstandfahrer/in (Maschinenbau)',
    Occupation: 'Testing Bay Operator (Mechanical Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 620105,
    OccDE: 'Prüffeldtechniker/in',
    Occupation: 'Testing Bay Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 276009,
    OccDE: 'Prüfmechaniker/in',
    Occupation: 'Testing Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622413,
    OccDE: 'Prüftechniker/in (Elektrotechnik)',
    Occupation: 'Testing Technician (Electrical Engineering)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 171013,
    OccDE: 'Textgestalter/in',
    Occupation: 'Text Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689908,
    OccDE: 'Textilvertreter/in',
    Occupation: 'Textile Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931003,
    OccDE: 'Textilreiniger/in',
    Occupation: 'Textile Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931004,
    OccDE: 'Textilreinigermeister/in',
    Occupation: 'Textile Cleaning Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931008,
    OccDE: 'Textilreinigungsmeister/in',
    Occupation: 'Textile Cleaning Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931007,
    OccDE: 'Textilreinigungsfachkraft',
    Occupation: 'Textile Cleaning Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931006,
    OccDE: 'Textilreinigungsfacharbeiter/in',
    Occupation: 'Textile Cleaning Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931408,
    OccDE: 'Textilreinigungsfacharbeiter/in (Wäscherei)',
    Occupation: 'Textile Cleaning Technician (Laundry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673307,
    OccDE: 'Textilkaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Textile Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662206,
    OccDE: 'Textilberater/in',
    Occupation: 'Textile Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 341632,
    OccDE: 'Textilstopfer/in',
    Occupation: 'Textile Darner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673306,
    OccDE: 'Textilhändler/in (Einzelhandel)',
    Occupation: 'Textile Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834218,
    OccDE: 'Textildesigner/in',
    Occupation: 'Textile Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 361235,
    OccDE: 'Textilfärber/in',
    Occupation: 'Textile Dyer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606319,
    OccDE: 'Textilingenieur/in',
    Occupation: 'Textile Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 361003,
    OccDE: 'Textilausrüster/in',
    Occupation: 'Textile Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 361007,
    OccDE: 'Textilveredler/in',
    Occupation: 'Textile Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 361006,
    OccDE: 'Textilveredler/in (Maschinenführung)',
    Occupation: 'Textile Finisher (Machinery Operation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 633303,
    OccDE: 'Textillaborant/in',
    Occupation: 'Textile Lab Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931002,
    OccDE: 'Textilpfleger/in',
    Occupation: 'Textile Launderer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 361005,
    OccDE: 'Textilmaschinenführer/in (Veredlung)',
    Occupation: 'Textile Machinery Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 344144,
    OccDE: 'Textilmaschinenführer/in',
    Occupation: 'Textile Machinery Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 331217,
    OccDE: 'Textilmaschinenführer/in (Spinnerei)',
    Occupation: 'Textile Machinery Operator (Spinning)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 349106,
    OccDE: 'Textilmaschinenführer/in (Tufting)',
    Occupation: 'Textile Machinery Operator (Tufting)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 341106,
    OccDE: 'Textilmaschinenführer/in (Weberei)',
    Occupation: 'Textile Machinery Operator (Weaving)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621922,
    OccDE: 'Textilmaschinentechniker/in',
    Occupation: 'Textile Machinery Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 341201,
    OccDE: 'Textilmechaniker/in (Weberei)',
    Occupation: 'Textile Mechanic (Weaving)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 834520,
    OccDE: 'Textilmustergestalter/in',
    Occupation: 'Textile Pattern Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 175321,
    OccDE: 'Textildrucker/in',
    Occupation: 'Textile Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 359005,
    OccDE: 'Textilverarbeiter/in',
    Occupation: 'Textile Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 358018,
    OccDE: 'Textilnäher/in',
    Occupation: 'Textile Seamstress',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662207,
    OccDE: 'Textilverkäufer/in',
    Occupation: 'Textile Seller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 359004,
    OccDE: 'Textilfacharbeiter/in',
    Occupation: 'Textile Skilled Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627352,
    OccDE: 'Textiltechniker/in',
    Occupation: 'Textile Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981168,
    OccDE: 'Student/in Textiltechnik',
    Occupation: 'Textile Technology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531502,
    OccDE: 'Textilarbeiter/in',
    Occupation: 'Textile Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671268,
    OccDE: 'Textilkaufmann/-kauffrau (Großhandel)',
    Occupation: 'Textiles Clerk (Wholesale)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 671267,
    OccDE: 'Textilhändler/in (Großhandel)',
    Occupation: 'Textiles Dealer (Wholesale)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 651819,
    OccDE: 'Textilmeister/in',
    Occupation: 'Textiles Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835299,
    OccDE: 'Diplom-Ingenieur/in Theater- und Veranstaltungstechnik',
    Occupation: 'Theatre and Event Technology Engineer with Degree',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 835519,
    OccDE: 'Theaterbeleuchter/in',
    Occupation: 'Theatre Lighting Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835242,
    OccDE: 'Theatermeister/in',
    Occupation: 'Theatre Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 885303,
    OccDE: 'Theaterpädagog(e/in)',
    Occupation: 'Theatre Pedagogue',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 882610,
    OccDE: 'Theaterwissenschaftler/in',
    Occupation: 'Theatre Studies Scholar',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 981169,
    OccDE: 'Student/in Theaterwissenschaften',
    Occupation: 'Theatre Studies Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835243,
    OccDE: 'Theatertechniker/in',
    Occupation: 'Theatre Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 891004,
    OccDE: 'Theolog(e/in) (Seelsorger/in)',
    Occupation: 'Theologian (Pastor)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 882410,
    OccDE: 'Theolog(e/in)',
    Occupation: 'Theologist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 981170,
    OccDE: 'Student/in Theologie',
    Occupation: 'Theology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 859906,
    OccDE: 'Therapeut/in',
    Occupation: 'Therapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702905,
    OccDE: 'Flugscheinagent/in',
    Occupation: 'Ticket Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 706304,
    OccDE: 'Kartenkontrolleur/in',
    Occupation: 'Ticket Checker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 706303,
    OccDE: 'Fahrkartenkontrolleur/in',
    Occupation: 'Ticket Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 706207,
    OccDE: 'Kartenverkäufer/in',
    Occupation: 'Ticket Seller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 706205,
    OccDE: 'Fahrkartenverkäufer/in',
    Occupation: 'Ticket Vendor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 483701,
    OccDE: 'Fliesenlegerhelfer/in',
    Occupation: 'Tile Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673499,
    OccDE: 'Fliesenhändler/in (Einzelhandel)',
    Occupation: 'Tile Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 484001,
    OccDE: 'Kachelofen- und Luftheizungsbauer/in',
    Occupation: 'Tiled Stove and Air Heater Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 484205,
    OccDE: 'Kachelofenbauer/in',
    Occupation: 'Tiled Stove Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 483202,
    OccDE: 'Fliesenleger/in',
    Occupation: 'Tiler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671231,
    OccDE: 'Holzkaufmann/-kauffrau',
    Occupation: 'Timber Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 603206,
    OccDE: 'Holzbauingenieur/in',
    Occupation: 'Timber Construction Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 487005,
    OccDE: 'Holzbauer',
    Occupation: 'Timber Construction Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671230,
    OccDE: 'Holzhändler/in',
    Occupation: 'Timber Dealer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 981128,
    OccDE: 'Student/in Holzwirtschaft, Holztechnik',
    Occupation: 'Timber Industry, Wood Technology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501804,
    OccDE: 'Holzfachwerker/in',
    Occupation: 'Timber Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671214,
    OccDE: 'Diplom-Holzwirt/in',
    Occupation: 'Timber Specialist with Degree',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 662904,
    OccDE: 'Holzfachverkäufer/in',
    Occupation: 'Timer Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 261004,
    OccDE: 'Blechschmied/in',
    Occupation: 'Tinsmith',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145833,
    OccDE: 'Reifenservicemonteur/in',
    Occupation: 'Tire Service Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673303,
    OccDE: 'Rauchwarenkaufmann/-kauffrau (Einzelhandel)',
    Occupation: 'Tobacco Clerk (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 424006,
    OccDE: 'Tabakmaschinenführer/in',
    Occupation: 'Tobacco Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 424007,
    OccDE: 'Tabakwarenmacher/in',
    Occupation: 'Tobacco Product Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661907,
    OccDE: 'Tabakwarenverkäufer/in',
    Occupation: 'Tobacco Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 424003,
    OccDE: 'Tabakarbeiter/in',
    Occupation: 'Tobacco Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835310,
    OccDE: 'Tonmeister/in',
    Occupation: 'Tonmeister',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 835309,
    OccDE: 'Tonmeister/in (nicht künstlerisch)',
    Occupation: 'Tonmeister (not Artistic)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 621614,
    OccDE: 'Werkzeugkonstrukteur/in',
    Occupation: 'Tool Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 290020,
    OccDE: 'Werkzeugvorrichter/in',
    Occupation: 'Tool Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 225814,
    OccDE: 'Werkzeugschleifer/in',
    Occupation: 'Tool Grinder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 290016,
    OccDE: 'Werkzeugrichter/in',
    Occupation: 'Tool Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 220002,
    OccDE: 'Facharbeiter/in für Werkzeugmaschinen',
    Occupation: 'Tool Machinery Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273944,
    OccDE: 'Werkzeugmaschinenschlosser/in',
    Occupation: 'Tool Machinist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 295107,
    OccDE: 'Werkzeugmechaniker/in (Fachrichtung Instrumententechnik)',
    Occupation: 'Tool Mechanic (specialising in Instrumentation Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 293702,
    OccDE: 'Werkzeugmechanikerhelfer/in (Formentechnik)',
    Occupation: 'Tool Mechanic Assistant (Mould Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 292702,
    OccDE: 'Werkzeugmechanikerhelfer/in (Stanz-, Umformtechnik)',
    Occupation:
      'Tool Mechanic Assistant (Punching, Reshaping Technology)Metal Forming)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 222929,
    OccDE: 'Werkzeugfräser/in',
    Occupation: 'Tool Miller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621615,
    OccDE: 'Werkzeugtechniker/in',
    Occupation: 'Tool Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 290015,
    OccDE: 'Werkzeugprüfer/in',
    Occupation: 'Tool Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 290009,
    OccDE: 'Werkzeugdreher/in',
    Occupation: 'Tool Turner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 290008,
    OccDE: 'Werkzeugbauer/in',
    Occupation: 'Toolmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 290012,
    OccDE: 'Werkzeugmacher/in',
    Occupation: 'Toolmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 290018,
    OccDE: 'Werkzeugschlosser/in',
    Occupation: 'Toolmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 292604,
    OccDE: 'Vorrichtungsschlosser/in',
    Occupation: 'Toolmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 293114,
    OccDE: 'Werkzeugmacher/in (Formwerkzeuge)',
    Occupation: 'Toolmaker (Moulding Tools)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 292314,
    OccDE: 'Werkzeugmacher/in (Blechform-, Schneidwerkzeuge)',
    Occupation: 'Toolmaker (Sheet Forming, Milling Tools)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 293116,
    OccDE: 'Werkzeugmechaniker/in (Fachrichtung Formentechnik)',
    Occupation: 'Toolmaker (Specialising in Mould Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 292101,
    OccDE: 'Werkzeugmechaniker/in (Fachrichtung Stanz- und Umformtechnik)',
    Occupation: 'Toolmaker (specialising in Stamping and Forming)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 292201,
    OccDE: 'Werkzeugmacher/in (Stanzwerkzeug-, Vorrichtungsbau)',
    Occupation: 'Toolmaker (Stamping, Jig Making)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 290014,
    OccDE: 'Werkzeugmechaniker/in',
    Occupation: 'Toolmaking Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741732,
    OccDE: 'Werkzeugausgeber/in',
    Occupation: 'Tools Issuer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491309,
    OccDE: 'Oberbodenleger/in',
    Occupation: 'Topsoil Layer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713520,
    OccDE: 'Tourenleiter/in (LKW-Fahrbetrieb)',
    Occupation: 'Tour Leader (Lorry Driving)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702118,
    OccDE: 'Touristiksachbearbeiter/in',
    Occupation: 'Tourism Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702006,
    OccDE: 'Touristikfachwirt/in',
    Occupation: 'Tourism and Leisure Agent',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 702111,
    OccDE: 'Reiseverkehrskaufmann/-kauffrau',
    Occupation: 'Tourism and Leisure Agent',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 702005,
    OccDE: 'Tourismuswirtschaftler/in',
    Occupation: 'Tourism and Leisure Expert',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702114,
    OccDE: 'Touristikberater/in',
    Occupation: 'Tourism Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702115,
    OccDE: 'Touristikfachberater/in',
    Occupation: 'Tourism Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702117,
    OccDE: 'Touristikrepräsentant/in',
    Occupation: 'Tourism Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702116,
    OccDE: 'Touristikkaufmann/-kauffrau',
    Occupation: 'Tourism Services Management Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 702003,
    OccDE: 'Fremdenverkehrsfachmann/-fachfrau',
    Occupation: 'Tourism Transportation Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702302,
    OccDE: 'Fremdenführer/in',
    Occupation: 'Tourist Guide',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 544608,
    OccDE: 'Turmdrehkranführer/in',
    Occupation: 'Tower Crane Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 611907,
    OccDE: 'Toxikolog(e/in)',
    Occupation: 'Toxicologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 185506,
    OccDE: 'Spielzeughersteller/in (Holz)',
    Occupation: 'Toy Maker (Wood)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512922,
    OccDE: 'Spielwarenmaler/in',
    Occupation: 'Toy Painter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 937109,
    OccDE: 'Bahnreiniger/in',
    Occupation: 'Track Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 463704,
    OccDE: 'Gleisbauerhelfer',
    Occupation: 'Track Construction Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 463202,
    OccDE: 'Gleisbaupolier',
    Occupation: 'Track Construction Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 463703,
    OccDE: 'Gleisbauarbeiter (nicht unter Tage)',
    Occupation: 'Track Construction Worker (not underground)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603829,
    OccDE: 'Oberbau(rat/rätin)',
    Occupation: 'Track Director General',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 463110,
    OccDE: 'Gleisbauer',
    Occupation: 'Track Layer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 463111,
    OccDE: 'Gleisbaufacharbeiter',
    Occupation: 'Track Laying Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 463119,
    OccDE: 'Gleiswerker',
    Occupation: 'Track Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013221',
    OccDE: 'Schlepperfahrer/in',
    Occupation: 'Tractor Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 714523,
    OccDE: 'Traktorist/in',
    Occupation: 'Tractor Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 670302,
    OccDE: 'Handelsassistent/in',
    Occupation: 'Trade Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 670202,
    OccDE: 'Handelsbetriebswirt/in',
    Occupation: 'Trade Business Administrator',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 670304,
    OccDE: 'Handelsfachwirt/in',
    Occupation: 'Trade Businessperson',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 672004,
    OccDE: 'Handelskaufmann/-kauffrau (Konsumgüter, Einzelhandel)',
    Occupation: 'Trade Businessperson (Consumer Goods, Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671228,
    OccDE: 'Handelskaufmann/-kauffrau (Konsumgüter, Großhandel)',
    Occupation: 'Trade Businessperson (Consumer Goods, Wholesale)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 671318,
    OccDE: 'Wirtschaftskaufmann/-kauffrau (Außenhandel, Außenwirtschaft)',
    Occupation: 'Trade Clerk (Export, Foreign Trade)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 670999,
    OccDE: 'Handelsangestellte/r',
    Occupation: 'Trade Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501222,
    OccDE: 'Messeschreiner/in',
    Occupation: 'Trade Fair Carpenter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 756123,
    OccDE: 'Messeorganisator/in',
    Occupation: 'Trade Fair Organiser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836115,
    OccDE: 'Messestandbauer/in',
    Occupation: 'Trade Fair Stand Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 670103,
    OccDE: 'Handelskaufmann/-kauffrau',
    Occupation: 'Trade Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 670102,
    OccDE: 'Handelskaufmann/-kauffrau (Konsumgüter)',
    Occupation: 'Trade Salesperson (Consumer Goods)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691404,
    OccDE: 'Börsenhändler/in',
    Occupation: 'Trader',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 713906,
    OccDE: 'Verkehrskontrolleur/in',
    Occupation: 'Traffic Controller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623407,
    OccDE: 'Verkehrstechniker/in',
    Occupation: 'Traffic Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603411,
    OccDE: 'Verkehrsingenieur/in',
    Occupation: 'Traffic Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 713521,
    OccDE: 'Verkehrsmeister/in',
    Occupation: 'Traffic Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603412,
    OccDE: 'Verkehrsplaner/in',
    Occupation: 'Traffic Planner',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 801475,
    OccDE: 'Verkehrspolizist/in',
    Occupation: 'Traffic Police',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801438,
    OccDE: 'Politesse',
    Occupation: 'Traffic Warden',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 506101,
    OccDE: 'Anhängerbauer/in',
    Occupation: 'Trailer Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712413,
    OccDE: 'Zugbegleiter/in',
    Occupation: 'Train Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712303,
    OccDE: 'Bahnwart/in',
    Occupation: 'Train Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712417,
    OccDE: 'Zugschaffner/in',
    Occupation: 'Train Conductor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712412,
    OccDE: 'Zugabfertiger/in',
    Occupation: 'Train Dispatcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 711002,
    OccDE: 'Schienenfahrzeugführer/in',
    Occupation: 'Train Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712414,
    OccDE: 'Zugführer/in',
    Occupation: 'Train Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712621,
    OccDE: 'Bahnaufseher/in',
    Occupation: 'Train Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 712501,
    OccDE: 'Wagenmeister/in (Eisenbahn)',
    Occupation: 'Train Technical Engineer (Railway)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981103,
    OccDE: 'Lehrling',
    Occupation: 'Trainee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853024,
    OccDE: 'Lernschwester',
    Occupation: 'Trainee Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854121,
    OccDE: 'Schwesternvorschülerin',
    Occupation: 'Trainee Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 844109,
    OccDE: 'Apothekerpraktikant/in',
    Occupation: 'Trainee Pharmacist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 861025,
    OccDE: 'Sozialpraktikant/in',
    Occupation: 'Trainee Social Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 876037,
    OccDE: 'Trainer/in',
    Occupation: 'Trainer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 873103,
    OccDE: 'Lehrer/in (Primarstufe an Grund- und Hauptschulen)',
    Occupation: 'Trainer (primary to lower secondary education)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 868301,
    OccDE: 'Ausbildungsberater/in',
    Occupation: 'Training Advisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879523,
    OccDE: 'Schulungsreferent/in (nicht EDV)',
    Occupation: 'Training Advisor (not EDP)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879524,
    OccDE: 'Schulungstrainer/in (nicht EDV)',
    Occupation: 'Training Leader (not EDP)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 652303,
    OccDE: 'Ausbildungsleiter/in',
    Occupation: 'Training Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879413,
    OccDE: 'Schulungsleiter/in (EDV)',
    Occupation: 'Training Manager (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 879414,
    OccDE: 'Schulungsreferent/in (EDV)',
    Occupation: 'Training Provider (EDP)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 879415,
    OccDE: 'Schulungstrainer/in (EDV)',
    Occupation: 'Training Session Leader (EDP)',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 652301,
    OccDE: 'Ausbildungsbeauftragte(r)',
    Occupation: 'Training Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879522,
    OccDE: 'Schulungsleiter/in (nicht EDV)',
    Occupation: 'Training Supervisor (not EDP)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 711305,
    OccDE: 'Straßenbahnfahrer/in',
    Occupation: 'Tram Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 711306,
    OccDE: 'Straßenbahnführer/in',
    Occupation: 'Tram Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 313301,
    OccDE: 'Transformatorenbauer/in',
    Occupation: 'Transformer Builder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981184,
    OccDE: 'Student/in Übersetzungswesen',
    Occupation: 'Translation Studies Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 822207,
    OccDE: 'Übersetzer/in',
    Occupation: 'Translator',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 822298,
    OccDE: 'Diplom-Übersetzer/in',
    Occupation: 'Translator with Degree',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 273203,
    OccDE: 'Getriebemonteur/in',
    Occupation: 'Transmission Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273204,
    OccDE: 'Getriebeschlosser/in',
    Occupation: 'Transmission Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 315911,
    OccDE: 'Sendertechniker/in (Radio- u. Fernsehmechaniker/in beim Rundfunk)',
    Occupation:
      'Transmission Technician (Radio & TV Mechanic for Radio Broadcasting)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744007,
    OccDE: 'Transport- und Lagerfacharbeiter/in',
    Occupation: 'Transport and Storage Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701024,
    OccDE: 'Verkehrsassistent/in',
    Occupation: 'Transport Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701027,
    OccDE: 'Verkehrskaufmann/-kauffrau',
    Occupation: 'Transport Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701610,
    OccDE: 'Verkehrskaufmann/-kauffrau (Luftverkehr)',
    Occupation: 'Transport Clerk (Aviation)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701108,
    OccDE: 'Verkehrskaufmann/-kauffrau (Spedition)',
    Occupation: 'Transport Clerk (Forwarding)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701025,
    OccDE: 'Verkehrsfachwirt/in (Güterverkehr)',
    Occupation: 'Transport Clerk (Goods Transport)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 702008,
    OccDE: 'Verkehrsfachwirt/in (Personenverkehr)',
    Occupation: 'Transport Clerk (Passenger Transport)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 701508,
    OccDE: 'Verkehrskaufmann/-kauffrau (Eisenbahnverkehr)',
    Occupation: 'Transport Clerk (Rail Transport)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701107,
    OccDE: 'Verkehrskaufmann/-kauffrau (Kraftverkehr)',
    Occupation: 'Transport Clerk (Road Transport)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702119,
    OccDE: 'Verkehrskaufmann/-kauffrau (Tourismus)',
    Occupation: 'Transport Clerk (Tourism)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 744217,
    OccDE: 'Transportfahrer/in (Fahrzeugbegleiter/in)',
    Occupation: 'Transport Driver (Vehicle Attendant)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701022,
    OccDE: 'Transportmanager/in',
    Occupation: 'Transport Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744587,
    OccDE: 'Transportarbeiter/in (nicht Bergbau)',
    Occupation: 'Transport Workers (not Mining)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 463127,
    OccDE: 'Tiefbaufacharbeiter (Gleisbau)',
    Occupation: 'Transportation Engineering Worker (Track Construction)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 742004,
    OccDE: 'Transportgeräteführer/in',
    Occupation: 'Transportation Equipment Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701099,
    OccDE: 'Verkehrsfachwirt/in',
    Occupation: 'Transportation Management Assistant',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 981172,
    OccDE: 'Student/in Transportwesen',
    Occupation: 'Transportation Studies Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 715002,
    OccDE: 'Transportunternehmer/in',
    Occupation: 'Transporter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744589,
    OccDE: 'Transporteur/in',
    Occupation: 'Transporter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841214,
    OccDE: 'Unfallchirurg/in',
    Occupation: 'Trauma Surgeon',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 702109,
    OccDE: 'Reisebürokaufmann/-kauffrau',
    Occupation: 'Travel Agency Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 702108,
    OccDE: 'Reisebüroinhaber/in',
    Occupation: 'Travel Agency Owner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702105,
    OccDE: 'Reisebüroangestellte/r',
    Occupation: 'Travel Agency Staff',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702112,
    OccDE: 'Reisevermittler/in',
    Occupation: 'Travel Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702103,
    OccDE: 'Reiseassistent/in',
    Occupation: 'Travel Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702104,
    OccDE: 'Reiseberater/in',
    Occupation: 'Travel Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702110,
    OccDE: 'Reisefachberater/in',
    Occupation: 'Travel Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702399,
    OccDE: 'Reiseleiter/in',
    Occupation: 'Travel Guide',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702305,
    OccDE: 'Reiseleiter/in (Fremdenverkehr)',
    Occupation: 'Travel Guide (Tourism)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 702398,
    OccDE: 'Reisemanager/in',
    Occupation: 'Travel Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689019,
    OccDE: 'Reisevertreter/in',
    Occupation: 'Travel Representative',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 672502,
    OccDE: 'Ambulante(r) Händler/in',
    Occupation: 'Travelling Dealer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689003,
    OccDE: 'Außendienstreisende/r',
    Occupation: 'Travelling Sales Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689018,
    OccDE: 'Reisende/r (nicht Verlag)',
    Occupation: 'Travelling Salesman (not Publishing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 764601,
    OccDE: 'Kämmerer',
    Occupation: 'Treasurer',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 771118,
    OccDE: 'Treasurer',
    Occupation: 'Treasurer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787208,
    OccDE: 'Finanzbearbeiter/in',
    Occupation: 'Treasury Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787205,
    OccDE: 'Finanzassistent/in',
    Occupation: 'Treasury Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787207,
    OccDE: 'Finanzbeamt(er/in)',
    Occupation: 'Treasury Civil Servant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787206,
    OccDE: 'Finanzbeamt(er/in) (mittlerer Dienst)',
    Occupation: 'Treasury Civil Servant (mid-level service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787209,
    OccDE: 'Finanzkaufmann/-kauffrau',
    Occupation: 'Treasury Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787203,
    OccDE: 'Finanzangestellte/r',
    Occupation: 'Treasury Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787202,
    OccDE: 'Finanzangestellte/r (mittlerer Dienst)',
    Occupation: 'Treasury Employee (mid-level service)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787204,
    OccDE: 'Finanzanwärter/in',
    Occupation: 'Treasury Referendary',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787211,
    OccDE: 'Finanzsekretär/in',
    Occupation: 'Treasury Senior Administrative Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062212',
    OccDE: 'Holzmacher/in',
    Occupation: 'Tree Feller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051206',
    OccDE: 'Baumschulgärtner/in',
    Occupation: 'Tree Nursery Gardener',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051205',
    OccDE: 'Baumschuler/in',
    Occupation: 'Tree Nursery Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '011504',
    OccDE: 'Baumchirurg/in',
    Occupation: 'Tree Surgeon',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 349626,
    OccDE: 'Schmucktextilienhersteller/in',
    Occupation: 'Trimming Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831451,
    OccDE: 'Posaunist/in',
    Occupation: 'Trombonist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 632503,
    OccDE: 'Justierer/in',
    Occupation: 'Tuner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466109,
    OccDE: 'Tunnelbauer',
    Occupation: 'Tunnel Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 273116,
    OccDE: 'Turbinenmonteur/in',
    Occupation: 'Turbine Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 221007,
    OccDE: 'Dreher/in',
    Occupation: 'Turner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 221703,
    OccDE: 'Dreherhelfer/in',
    Occupation: 'Turning Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 221006,
    OccDE: 'Dreher-Fachwerker/in',
    Occupation: 'Turning Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879803,
    OccDE: 'Nachhilfestundenlehrer/in',
    Occupation: 'Tutor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608253,
    OccDE: 'TüV-Sachverständige/r',
    Occupation: 'TüV Expert',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 629212,
    OccDE: 'TÜV-Prüfer/in',
    Occupation: 'TÜV Tester',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 608252,
    OccDE: 'TÜV-Prüfer/in (Ingenieur/in)',
    Occupation: 'TÜV Tester (Engineer)',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 171007,
    OccDE: 'Schriftsetzer/in',
    Occupation: 'Typesetter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 782003,
    OccDE: 'Schreibpool-Leiter/in',
    Occupation: 'Typing Pool Head Writer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 782002,
    OccDE: 'Schreibdienstleiter/in',
    Occupation: 'Typing Services Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 782518,
    OccDE: 'Schreibkraft',
    Occupation: 'Typist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 171222,
    OccDE: 'Typografsetzer/in',
    Occupation: 'Typographer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981116,
    OccDE: 'Student/in Druck- und Medientechnik',
    Occupation: 'Typography and Media Technology Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673509,
    OccDE: 'Reifenhändler/in (Einzelhandel)',
    Occupation: 'Tyre Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671258,
    OccDE: 'Reifenhändler/in (Großhandel)',
    Occupation: 'Tyre Dealer (Wholesale)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281098,
    OccDE: 'Reifenmonteur/in',
    Occupation: 'Tyre Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522925,
    OccDE: 'Reifenmonteur/in (Aufzieher/in)',
    Occupation: 'Tyre Fitter (Changer)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145318,
    OccDE: 'Reifenbauer/in',
    Occupation: 'Tyre Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145732,
    OccDE: 'Reifenarbeiter/in',
    Occupation: 'Tyre Processor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145824,
    OccDE: 'Reifendienstmonteur/in',
    Occupation: 'Tyre Service Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627606,
    OccDE: 'Reifentechniker/in',
    Occupation: 'Tyre Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145829,
    OccDE: 'Reifenmonteur/in (Vulkaniseur/in)',
    Occupation: 'Tyre Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145840,
    OccDE: 'Vulkaniseur/in',
    Occupation: 'Tyre Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145842,
    OccDE: 'Vulkanisierer/in',
    Occupation: 'Tyre Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145899,
    OccDE: 'Vulkaniseur/in und                   Reifenmechaniker/in',
    Occupation: 'Tyre Technician and Tyre Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 145322,
    OccDE: 'Reifenwickler/in',
    Occupation: 'Tyre Turner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521243,
    OccDE: 'Ultraschallprüfer/in',
    Occupation: 'Ultrasonic Testing Agent',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 711309,
    OccDE: 'U-Bahn-Fahrer/in',
    Occupation: 'Underground Driver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695530,
    OccDE: 'Risikoprüfer/in (Versicherung)',
    Occupation: 'Underwriter (Insurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 695531,
    OccDE: 'Underwriter/in (Rückversicherung)',
    Occupation: 'Underwriter (Reinsurance)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 763203,
    OccDE: 'Gewerkschaftssekretär/in',
    Occupation: 'Union Secretary',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 225103,
    OccDE: 'Universalschleifer/in',
    Occupation: 'Universal Grinding Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 222101,
    OccDE: 'Universalfräser/in',
    Occupation: 'Universal Milling Cutter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 787409,
    OccDE: 'Universitätsassistent/in',
    Occupation: 'University Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 871208,
    OccDE: 'Hochschuldozent/in',
    Occupation: 'University College Lecturer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 871108,
    OccDE: 'Hochschulprofessor/in',
    Occupation: 'University College Professor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 871107,
    OccDE: 'Hochschullehrer/in',
    Occupation: 'University College Teacher',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 871211,
    OccDE: 'Universitätsdozent/in',
    Occupation: 'University Lecturer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 871117,
    OccDE: 'Universitätsprofessor/in',
    Occupation: 'University Professor',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 744302,
    OccDE: 'Auspacker/in',
    Occupation: 'Unpacker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 492007,
    OccDE: 'Polster(er/in)',
    Occupation: 'Upholsterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 492607,
    OccDE: 'Polster- und Dekorationsnäher/in',
    Occupation: 'Upholstery and decorative sewing',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 492708,
    OccDE: 'Polstererhelfer/in',
    Occupation: 'Upholstery Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505307,
    OccDE: 'Polstergestellbauer/in',
    Occupation: 'Upholstery Frame Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 492608,
    OccDE: 'Polsternäher/in',
    Occupation: 'Upholstery seamstress',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 823208,
    OccDE: 'Bibliotheksobersekretär/in',
    Occupation: 'Upper Library Secretary',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 466316,
    OccDE: 'Tiefbaufacharbeiter (Kanalbau)',
    Occupation:
      'Urban and Municipal Engineering Specialist (Canal Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981167,
    OccDE: 'Student/in Städtebau',
    Occupation: 'Urban Development Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 609314,
    OccDE: 'Städtebauplaner/in',
    Occupation: 'Urban Planner',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 841975,
    OccDE: 'Urolog(e/in)',
    Occupation: 'Urologist',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 673504,
    OccDE: 'Gebrauchtwagenhändler/in (Einzelhandel)',
    Occupation: 'Used Car Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281904,
    OccDE: 'Gebrauchtwagenaufbereiter/in',
    Occupation: 'Used Car Refurbisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 777101,
    OccDE: 'Anwendersoftwareberater/in',
    Occupation: 'User Software Consultant',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775101,
    OccDE: 'Anwendersoftwareentwickler/in',
    Occupation: 'User Software Developers',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 775102,
    OccDE: 'Anwendersoftwareprogrammierer/in',
    Occupation: 'User Software Programmers',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 784303,
    OccDE: 'Amtsbot(e/in)',
    Occupation: 'Usher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 281028,
    OccDE: 'Nutzfahrzeugmechaniker/in',
    Occupation: 'Utility Vehicle Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626205,
    OccDE: 'Lacktechniker/in',
    Occupation: 'Varnish Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 141749,
    OccDE: 'Lackarbeiter/in',
    Occupation: 'Varnish Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512008,
    OccDE: 'Lackierer/in',
    Occupation: 'Varnisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 511103,
    OccDE: 'Lackierer/in (Ausbau)',
    Occupation: 'Varnisher (expansion)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512007,
    OccDE: 'Lackierer/in (Holz und Metall)',
    Occupation: 'Varnisher (Wood and Metal)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051508',
    OccDE: 'Gemüsegärtner/in',
    Occupation: 'Vegetable Gardener',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '051507',
    OccDE: 'Gemüsebauer/in',
    Occupation: 'Vegetable Grower',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661401,
    OccDE: 'Gemüseverkäufer/in',
    Occupation: 'Vegetable Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 936004,
    OccDE: 'Fahrzeugreiniger/in',
    Occupation: 'Vehicle Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621101,
    OccDE: 'Fahrzeugbautechniker/in',
    Occupation: 'Vehicle Construction Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621102,
    OccDE: 'Fahrzeugkonstrukteur/in',
    Occupation: 'Vehicle Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601101,
    OccDE: 'Fahrzeugbauingenieur/in',
    Occupation: 'Vehicle Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601102,
    OccDE: 'Fahrzeugingenieur/in',
    Occupation: 'Vehicle Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 287101,
    OccDE: 'Fahrzeugbauer/in',
    Occupation: 'Vehicle Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522912,
    OccDE: 'Fahrzeugfertigsteller/in',
    Occupation: 'Vehicle Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 936003,
    OccDE: 'Fahrzeugpfleger/in',
    Occupation: 'Vehicle Maintenance Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522911,
    OccDE: 'Fahrzeugbereitsteller/in',
    Occupation: 'Vehicle Provider',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621103,
    OccDE: 'Fahrzeugtechniker/in',
    Occupation: 'Vehicle Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 374304,
    OccDE: 'Fahrzeugsattler/in',
    Occupation: 'Vehicle Upholsterer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705103,
    OccDE: 'Automatenaufsteller/in (Verleiher/in)',
    Occupation: 'Vending Machine Fitter (Hire)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505704,
    OccDE: 'Furniererhelfer/in',
    Occupation: 'Veneer Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268207,
    OccDE: 'Lüftungs- und Klimaanlagenbauer/in',
    Occupation: 'Ventilation and Air Conditioning Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268210,
    OccDE: 'Lüftungsbauer/in',
    Occupation: 'Ventilation Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 484301,
    OccDE: 'Luftheizungsbauer/in',
    Occupation: 'Ventilation Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601524,
    OccDE: 'Lüftungsingenieur/in',
    Occupation: 'Ventilation Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 268213,
    OccDE: 'Lüftungsmonteur/in',
    Occupation: 'Ventilation Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 268211,
    OccDE: 'Lüftungsinstallateur/in',
    Occupation: 'Ventilation Installation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621514,
    OccDE: 'Lüftungstechniker/in',
    Occupation: 'Ventilation Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 843120,
    OccDE: 'Tierarzt/-ärztin',
    Occupation: 'Veterinarian',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 843123,
    OccDE: 'Veterinär/in',
    Occupation: 'Veterinarian',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 843102,
    OccDE: 'Amtstierarzt/-ärztin',
    Occupation: 'Veterinarian Official',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 856301,
    OccDE: 'Tierarzthelfer/in',
    Occupation: 'Veterinary Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '024602',
    OccDE: 'Tierheilpraktiker/in',
    Occupation: 'Veterinary Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981171,
    OccDE: 'Student/in Tiermedizin',
    Occupation: 'Veterinary Medicine Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '024604',
    OccDE: 'Tierphysiotherapeut/in',
    Occupation: 'Veterinary Physiotherapist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 843103,
    OccDE: 'Fachtierarzt/-ärztin',
    Occupation: 'Veterinary Specialist',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 857301,
    OccDE: 'Veterinärmedizinisch-technische(r) Assistent/in',
    Occupation: 'Veterinary Technical Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 891225,
    OccDE: 'Vikar/in',
    Occupation: 'Vicar',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 764052,
    OccDE: 'Vizepräsident/in',
    Occupation: 'Vice President',
    'Feather RC': 'B',
  },
  {
    OccupationCode: 835427,
    OccDE: 'Video-Editor/in',
    Occupation: 'Video Editor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835302,
    OccDE: 'Bildingenieur/in',
    Occupation: 'Video Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 835405,
    OccDE: 'Bildmischer/in',
    Occupation: 'Video Mixer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705146,
    OccDE: 'Videoverleiher/in',
    Occupation: 'Video Rental Provider',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 705145,
    OccDE: 'Videothekar/in',
    Occupation: 'Video Store Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835430,
    OccDE: 'Videotechniker/in',
    Occupation: 'Video Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 835407,
    OccDE: 'Bildtechniker/in (Film, Fernsehen)',
    Occupation: 'Video Technician (Film, Television)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '012012',
    OccDE: 'Weingutsdirektor/in',
    Occupation: 'Vineyard Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013308',
    OccDE: 'Weinhauer/in',
    Occupation: 'Vintner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 305410,
    OccDE: 'Geigenbauer/in',
    Occupation: 'Violin Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 831413,
    OccDE: 'Geiger/in',
    Occupation: 'Violinist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 871403,
    OccDE: 'Lehrbeauftragte/r',
    Occupation: 'Visiting Lecturer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 521015,
    OccDE: 'Sichtprüfer/in',
    Occupation: 'Visual Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 521014,
    OccDE: 'Sichtkontrolleur/in',
    Occupation: 'Visual Inspector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836126,
    OccDE: 'Werbegestalter/in',
    Occupation: 'Visual Merchandiser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836125,
    OccDE: 'Werbegestalter/in (Schauwerbung)',
    Occupation: 'Visual Merchandising (Window Dresser)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857305,
    OccDE: 'VMTA',
    Occupation: 'VMTA',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874803,
    OccDE: 'Berufsschulleiter/in',
    Occupation: 'Vocational School Director',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 874003,
    OccDE: 'Berufsschullehrer/in',
    Occupation: 'Vocational School Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874107,
    OccDE: 'Gewerbeschullehrer/in',
    Occupation: 'Vocational School Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874007,
    OccDE: 'Fachschullehrer/in',
    Occupation: 'Vocational School Teacher (Fachschule)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981191,
    OccDE: 'Fachschüler/in',
    Occupation: 'Vocational Student',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 981192,
    OccDE: 'Berufsfachschüler/in',
    Occupation: 'Vocational Students',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874105,
    OccDE: 'Gewerbelehrer/in',
    Occupation: 'Vocational Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 874201,
    OccDE: 'Diplom-Handelslehrer/in',
    Occupation: 'Vocational Teacher with Degree',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 832711,
    OccDE: 'Synchronsprecher/in',
    Occupation: 'Voice Actor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875402,
    OccDE: 'Gesangpädagog(e/in)',
    Occupation: 'Voice Pedagogue',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 875401,
    OccDE: 'Gesanglehrer/in',
    Occupation: 'Voice Teacher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 982110,
    OccDE: 'Volontär/in',
    Occupation: 'Volunteer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912008,
    OccDE: 'Kellner/in',
    Occupation: 'Waiter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 912716,
    OccDE: 'Servicedame',
    Occupation: 'Waitress',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 491405,
    OccDE: 'Tapezierer/in',
    Occupation: 'Wallpaperer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854124,
    OccDE: 'Stationshilfe',
    Occupation: 'Ward Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854122,
    OccDE: 'Stationsgehilf(e/in) (Krankenpflege)',
    Occupation: 'Ward Assistant (Nursing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 854123,
    OccDE: 'Stationshelfer/in (Krankenpflege)',
    Occupation: 'Ward Assistant (Nursing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853044,
    OccDE: 'Stationsleiter/in (Krankenpflege)',
    Occupation: 'Ward Manager (Nursing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853045,
    OccDE: 'Stationspfleger',
    Occupation: 'Ward Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 853046,
    OccDE: 'Stationsschwester',
    Occupation: 'Ward Nurse',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 841056,
    OccDE: 'Stationsarzt/-ärztin',
    Occupation: 'Ward Physician',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 741044,
    OccDE: 'Magazinverwalter/in',
    Occupation: 'Warehouse Administrator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741626,
    OccDE: 'Lagerverwalter/in (Warenlager)',
    Occupation: 'Warehouse Administrator (Warehouse)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 522801,
    OccDE: 'Lagerfachhelfer/in',
    Occupation: 'Warehouse Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741026,
    OccDE: 'Lagergehilf(e/in)',
    Occupation: 'Warehouse Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741628,
    OccDE: 'Lagerwart/in (Warenlager)',
    Occupation: 'Warehouse Attendant (Warehouse)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741025,
    OccDE: 'Lagerfachkraft',
    Occupation: 'Warehouse Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741624,
    OccDE: 'Lagerleiter/in (Warenlager)',
    Occupation: 'Warehouse Director (Warehouse)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741024,
    OccDE: 'Lagerdisponent/in',
    Occupation: 'Warehouse Dispatcher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741022,
    OccDE: 'Lagerangestellte/r',
    Occupation: 'Warehouse Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741011,
    OccDE: 'Hallenmeister/in',
    Occupation: 'Warehouse Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741627,
    OccDE: 'Lagervorsteher/in (Warenlager)',
    Occupation: 'Warehouse Foreman (Warehouse)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744341,
    OccDE: 'Lagerhilfsarbeiter/in',
    Occupation: 'Warehouse Labourer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701812,
    OccDE: 'Lagerhausverwalter/in',
    Occupation: 'Warehouse Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741647,
    OccDE: 'Warenlagerverwalter/in',
    Occupation: 'Warehouse Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741649,
    OccDE: 'Lagerverwalter/in',
    Occupation: 'Warehouse Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741031,
    OccDE: 'Lagermeister/in',
    Occupation: 'Warehouse Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741099,
    OccDE: 'Lagerleiter (Metall)',
    Occupation: 'Warehouse Manager (Metal)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741623,
    OccDE: 'Lagerchef/in (Warenlager)',
    Occupation: 'Warehouse Manager (Warehouse)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741039,
    OccDE: 'Magaziner/in',
    Occupation: 'Warehouse Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662906,
    OccDE: 'Lagerverkäufer/in',
    Occupation: 'Warehouse Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741023,
    OccDE: 'Lageraufseher/in',
    Occupation: 'Warehouse Supervisor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744338,
    OccDE: 'Lagerarbeiter/in',
    Occupation: 'Warehouse Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744339,
    OccDE: 'Lagerfacharbeiter/in',
    Occupation: 'Warehouse Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744358,
    OccDE: 'Warenlagerarbeiter/in',
    Occupation: 'Warehouse Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741029,
    OccDE: 'Lagerist/in',
    Occupation: 'Warehousekeeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741027,
    OccDE: 'Lagerhalter/in (Lagerverwalter/in)',
    Occupation: 'Warehousekeeper (Depot Master)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 701810,
    OccDE: 'Lagerhalter/in (Speditions- und Lagerkaufmann/-kauffrau)',
    Occupation: 'Warehouser (freight Forwarding and Warehouse Clerk)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741055,
    OccDE: 'Meister/in für Lagerwirtschaft',
    Occupation: 'Warehousing and Logistics Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788915,
    OccDE: 'Garantiesachbearbeiter/in',
    Occupation: 'Warranty Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 788916,
    OccDE: 'Gewährleistungssachbearbeiter/in',
    Occupation: 'Warranty Clerk',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 997179,
    OccDE: 'Wäscher/in',
    Occupation: 'Washer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 931412,
    OccDE: 'Wäscher/in (Textilreiniger/in)',
    Occupation: 'Washer (Textile Cleaner)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935717,
    OccDE: 'Müllarbeiter/in',
    Occupation: 'Waste Disposal Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935720,
    OccDE: 'Müllwerker/in',
    Occupation: 'Waste Disposal Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 935411,
    OccDE: 'Kläranlagenwärter/in (Entsorger/in)',
    Occupation: 'Wastewater Treatment Plant Maintenance Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 662603,
    OccDE: 'Uhren und Schmuckverkäufer/in',
    Occupation: 'Watches and Jewellery Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 308010,
    OccDE: 'Uhrmacher/in',
    Occupation: 'Watchmaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 308901,
    OccDE: 'Uhrmacher/in und Augenoptiker/in',
    Occupation: 'Watchmaker and Optometrist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 792001,
    OccDE: 'Aufseher/in (Wachdienst)',
    Occupation: 'Watchman (Security)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 706406,
    OccDE: 'Wasserwart/in (Ableser/in)',
    Occupation: 'Water Agent (Metre Reader)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549122,
    OccDE: 'Wasserwärter/in',
    Occupation: 'Water Attendant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 267116,
    OccDE: 'Wasserinstallateur/in',
    Occupation: 'Water Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 549121,
    OccDE: 'Wassermeister/in',
    Occupation: 'Water Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 603533,
    OccDE: 'Wasserwirtschaftler/in (Ingenieur/in)',
    Occupation: 'Water Management Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603534,
    OccDE: 'Wasserwirtschaftsingenieur/in',
    Occupation: 'Water Management Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 629322,
    OccDE: 'Wasserversorgungs- und Abwasserbehandlungstechniker/in',
    Occupation: 'Water Supply and Wastewater Treatment Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601535,
    OccDE: 'Wasserwerksingenieur/in',
    Occupation: 'Water Works Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 603532,
    OccDE: 'Wasserbauingenieur/in',
    Occupation: 'Water Works Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 549123,
    OccDE: 'Wasserwerkfacharbeiter/in',
    Occupation: 'Water Works Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 801396,
    OccDE: 'Wasserschutzpolizeibeamt(er/in)',
    Occupation: 'Waterway Police Official',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 691309,
    OccDE: 'Vermögensberater/in',
    Occupation: 'Wealth Management Adviser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606908,
    OccDE: 'Waffeningenieur/in',
    Occupation: 'Weapons Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627912,
    OccDE: 'Waffentechniker/in',
    Occupation: 'Weapons Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626807,
    OccDE: 'Wetterdienstbeamt(er/in) (mittlerer und gehobener Dienst)',
    Occupation:
      'Weather Service Civil Servant (mid-level and higher civil service grade)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 626809,
    OccDE: 'Wetterdiensttechniker/in',
    Occupation: 'Weather Service Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 341007,
    OccDE: 'Weber/in',
    Occupation: 'Weaver',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 341707,
    OccDE: 'Webereiarbeiter/in',
    Occupation: 'Weaving Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 775193,
    OccDE: 'Web-Designer/in',
    Occupation: 'Web Designer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 775195,
    OccDE: 'Web-Entwickler/in',
    Occupation: 'Web Developer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 778399,
    OccDE: 'Web-Master (EDV)',
    Occupation: 'Web Master (EDP)',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 174225,
    OccDE: 'Rollenoffsetdrucker/in',
    Occupation: 'Web Offset Printer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 775196,
    OccDE: 'Web-Programmierer/in',
    Occupation: 'Web Programmer',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 522108,
    OccDE: 'Wiegemeister/in (amtlich)',
    Occupation: 'Weighing Manager (Public Official)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241007,
    OccDE: 'Schweißer/in',
    Occupation: 'Welder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241008,
    OccDE: 'Schweißermeister/in',
    Occupation: 'Welder Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241704,
    OccDE: 'Schweißerhelfer/in',
    Occupation: 'Welding Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 601613,
    OccDE: 'Schweißfachingenieur/in',
    Occupation: 'Welding Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 601614,
    OccDE: 'Schweißingenieur/in',
    Occupation: 'Welding Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 241011,
    OccDE: 'Schweißmaschinenführer/in',
    Occupation: 'Welding machine operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241705,
    OccDE: 'Schweißmaschinenbediener/in',
    Occupation: 'Welding Machine Operator',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241009,
    OccDE: 'Schweißfachmann/Schweißfachfrau',
    Occupation: 'Welding Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 621608,
    OccDE: 'Schweißtechniker/in',
    Occupation: 'Welding Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241003,
    OccDE: 'Facharbeiter/in für Schweißtechnik',
    Occupation: 'Welding Technology Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466209,
    OccDE: 'Brunnenbohrer',
    Occupation: 'Well Driller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 466205,
    OccDE: 'Brunnenbauer',
    Occupation: 'Well Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744809,
    OccDE: 'Kaiarbeiter/in',
    Occupation: 'Wharf Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 295313,
    OccDE: 'Felgenmacher/in (Metall)',
    Occupation: 'Wheelwright (Metal)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671101,
    OccDE: 'Groß- und Außenhandelskaufmann/-kauffrau',
    Occupation: 'Wholesale and Export Businessperson',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 671226,
    OccDE: 'Großhandelskaufmann/-kauffrau',
    Occupation: 'Wholesale Clerk',
    'Feather RC': 'C',
  },
  {
    OccupationCode: '053199',
    OccDE: 'Blumengroßhändler/in',
    Occupation: 'Wholesale Florist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671225,
    OccDE: 'Großhändler/in',
    Occupation: 'Wholesaler',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 241004,
    OccDE: 'Lehrschweißer/in',
    Occupation: 'Wielding Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 901503,
    OccDE: 'Perückenmacher/in',
    Occupation: 'Wig Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 305002,
    OccDE: 'Blasinstrumentenbauer/in',
    Occupation: 'Wind Instrument Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 313419,
    OccDE: 'Wickler/in',
    Occupation: 'Winder',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 259106,
    OccDE: 'Jalousiemonteur/in',
    Occupation: 'Window Blind Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 259104,
    OccDE: 'Jalousiebauer/in',
    Occupation: 'Window Blind Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934102,
    OccDE: 'Fensterreiniger/in',
    Occupation: 'Window Cleaner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836118,
    OccDE: 'Schaufenstergestalter/in',
    Occupation: 'Window Display Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836119,
    OccDE: 'Schauwerbe-Designer/in',
    Occupation: 'Window Display Designer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836117,
    OccDE: 'Schaufensterdekorateur/in',
    Occupation: 'Window Dresser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836122,
    OccDE: 'Schauwerbegestalter/in',
    Occupation: 'Window Dresser',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 836123,
    OccDE: 'Schauwerbegestalter/in und Verkäufer/in',
    Occupation: 'Window Dresser and Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501214,
    OccDE: 'Fenstermonteur/in',
    Occupation: 'Window Fitter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 254104,
    OccDE: 'Fenstermonteur/in (Metallbau)',
    Occupation: 'Window Fitter (Metal Engineering)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501213,
    OccDE: 'Fenstermonteur/in (Holz, Kunststoff)',
    Occupation: 'Window Fitter (Wood, Plastics)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 485303,
    OccDE: 'Fensterrahmenmacher/in (Glaser/in)',
    Occupation: 'Window Frame Maker (Glazier)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505202,
    OccDE: 'Fensterfertiger/in',
    Occupation: 'Window Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 485301,
    OccDE: 'Fensterbauer/in (Glaser/in)',
    Occupation: 'Window Maker (Glazier)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505201,
    OccDE: 'Fensterbauer/in (Holzmechaniker/in)',
    Occupation: 'Window Maker (Wood Mechanic)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 934101,
    OccDE: 'Fensterputzer/in',
    Occupation: 'Window Washer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 689514,
    OccDE: 'Wein(fach)berater/in',
    Occupation: 'Wine (Specialist) Consultant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 423313,
    OccDE: 'Weinhandelsküfer/in',
    Occupation: 'Wine Cooper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 423315,
    OccDE: 'Weinküfer/in',
    Occupation: 'Wine Cooper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 673109,
    OccDE: 'Weinhändler/in (Einzelhandel)',
    Occupation: 'Wine Dealer (Retail)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 671272,
    OccDE: 'Weinhändler/in (Großhandel)',
    Occupation: 'Wine Dealer (Wholesale)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '012006',
    OccDE: 'Weinbauer/-bäuerin',
    Occupation: 'Wine Grower',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 661908,
    OccDE: 'Weinverkäufer/in',
    Occupation: 'Wine Salesperson',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013303',
    OccDE: 'Weinbaugehilf(e/in)',
    Occupation: 'Winegrowing Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '032411',
    OccDE: 'Weinbauingenieur/in',
    Occupation: 'Winegrowing Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: '032412',
    OccDE: 'Weinbautechniker/in',
    Occupation: 'Winegrowing Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '012016',
    OccDE: 'Winzer/in',
    Occupation: 'Winemaker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '013310',
    OccDE: 'Winzergehilf(e/in)',
    Occupation: 'Winemaking Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 229501,
    OccDE: 'Drahterodierer/in',
    Occupation: 'Wire EDM Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 194404,
    OccDE: 'Drahtzieher/in',
    Occupation: 'Wire Puller',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 212101,
    OccDE: 'Drahtseiler/in',
    Occupation: 'Wire Rope Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 212002,
    OccDE: 'Drahtverarbeiter/in',
    Occupation: 'Wire Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 311417,
    OccDE: 'Leitungsnetzmonteur/in',
    Occupation: 'Wiring System Installer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 623204,
    OccDE: 'Holzbautechniker/in',
    Occupation: 'Wood Construction Engineer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 606402,
    OccDE: 'Holzingenieur/in',
    Occupation: 'Wood Engineer',
    'Feather RC': 'A',
  },
  {
    OccupationCode: 505115,
    OccDE: 'Holzmöbelbauer/in',
    Occupation: 'Wood furniture maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505009,
    OccDE: 'Holzmechaniker/in',
    Occupation: 'Wood Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505204,
    OccDE: 'Holzmechaniker/in (Bauzubehörindustrie)',
    Occupation: 'Wood Mechanic (Construction Supply Industry)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505114,
    OccDE:
      'Holzmechaniker/in (Möbel-, Gehäuseindustrie, Innenausbau, Ladenbau)',
    Occupation: 'Wood Mechanic (Furniture, Casing, Interiors, Shopfitting)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 502502,
    OccDE: 'Holzmodellbauer/in',
    Occupation: 'Wood Model Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 185001,
    OccDE: 'Holzwarenmacher/in',
    Occupation: 'Wood Products Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 185207,
    OccDE: 'Holzbildhauer/in',
    Occupation: 'Wood Sculptor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505008,
    OccDE: 'Holzfacharbeiter/in',
    Occupation: 'Wood Specialist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505110,
    OccDE: 'Holzfacharbeiter/in (Möbel)',
    Occupation: 'Wood Specialist (Furniture)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 505007,
    OccDE: 'Holzfacharbeiter/in (Holzverarbeitung)',
    Occupation: 'Wood Specialist (Wood Processing)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512803,
    OccDE: 'Holzoberflächenveredler/in',
    Occupation: 'Wood Surface Finisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 627404,
    OccDE: 'Holztechniker/in',
    Occupation: 'Wood Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 185109,
    OccDE: 'Drechsler/in',
    Occupation: 'Wood Turner',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 512402,
    OccDE: 'Holzlackierer/in',
    Occupation: 'Wood Varnisher',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '062208',
    OccDE: 'Holzfäller',
    Occupation: 'Woodcutter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 185502,
    OccDE: 'Holzspielwarenmacher/in',
    Occupation: 'Wooden Toy Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 185503,
    OccDE: 'Holzspielzeugmacher/in',
    Occupation: 'Wooden Toy Manufacturer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 181410,
    OccDE: 'Holzfräser/in',
    Occupation: 'Woodmilling Cutter',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 305611,
    OccDE: 'Holzblasinstrumentenmacher/in',
    Occupation: 'Woodwind Instrument Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 482999,
    OccDE: 'Holz- und Bautenschützer/Holz- und Bautenschützerin',
    Occupation: 'Woodwork and Building Protector',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 181003,
    OccDE: 'Holzaufbereiter/in',
    Occupation: 'Woodworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 181738,
    OccDE: 'Holzbearbeiter/in',
    Occupation: 'Woodworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 501806,
    OccDE: 'Holzwerker/in',
    Occupation: 'Woodworker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 181737,
    OccDE: 'Holzarbeiter/in (Holzaufbereiter/in)',
    Occupation: 'Woodworking Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 181005,
    OccDE: 'Holzbearbeitungsmechaniker/in',
    Occupation: 'Woodworking Mechanic',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 181232,
    OccDE: 'Holzbearbeitungsmechaniker/in (Fachrichtung Sägeindustrie)',
    Occupation: 'Woodworking Mechanic (specialising in Sawmilling)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 181002,
    OccDE: 'Facharbeiter/in für Holzbearbeitung',
    Occupation: 'Woodworking Technician',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 778201,
    OccDE: 'Arbeitsvorbereiter/in (EDV)',
    Occupation: 'Work Planner (EDP)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 440409,
    OccDE: 'Werker',
    Occupation: 'Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 531102,
    OccDE: 'Arbeiter/in',
    Occupation: 'Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 323103,
    OccDE: 'Fachwerker/in (Metallbearbeitung und Montage)',
    Occupation: 'Worker (Metal Processing and Assembly)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863902,
    OccDE: 'Erzieher/in am Arbeitsplatz',
    Occupation: 'Workplace Welfare Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 763302,
    OccDE: 'Betriebsratsvorsitzende/r',
    Occupation: 'Works Council Chairman',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 763301,
    OccDE: 'Betriebsrat/-rätin',
    Occupation: 'Works Council Representative',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 531918,
    OccDE: 'Werkstatthelfer/in',
    Occupation: 'Workshop Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651041,
    OccDE: 'Werkstattmeister/in',
    Occupation: 'Workshop Foreman',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 651040,
    OccDE: 'Werkstattleiter/in',
    Occupation: 'Workshop Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '053201',
    OccDE: 'Kranzbinder/in',
    Occupation: 'Wreath Maker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 472901,
    OccDE: 'Abbrucharbeiter',
    Occupation: 'Wrecker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821115,
    OccDE: 'Literat/in',
    Occupation: 'Writer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 821120,
    OccDE: 'Schriftsteller/in',
    Occupation: 'Writer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857218,
    OccDE: 'Röntgenassistent/in',
    Occupation: 'X-Ray Assistant',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 632309,
    OccDE: 'Röntgenkontrolleur/in (Metallprüfer/in)',
    Occupation: 'X-ray Checker (Metal Tester)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 857217,
    OccDE: 'Röntgen-MTA',
    Occupation: 'X-Ray MTA',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 622908,
    OccDE: 'Röntgentechniker/in',
    Occupation: 'X-Ray Technician',
    'Feather RC': 'C',
  },
  {
    OccupationCode: 741301,
    OccDE: 'Bauhofangestellt(e/r)',
    Occupation: 'Yard Employee',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741303,
    OccDE: 'Bauhofleiter/in',
    Occupation: 'Yard Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 741305,
    OccDE: 'Bauhofverwalter/in',
    Occupation: 'Yard Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 744303,
    OccDE: 'Bauhofarbeiter/in',
    Occupation: 'Yard Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 879316,
    OccDE: 'Yogalehrer/in',
    Occupation: 'Yoga Instructor',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861123,
    OccDE: 'Jugendheimleiter/in',
    Occupation: 'Youth Centre Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 894107,
    OccDE: 'Jugenddiakon/in',
    Occupation: 'Youth Deacon',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 869302,
    OccDE: 'Jugendherbergsvater',
    Occupation: 'Youth Hostel Warden',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 863309,
    OccDE: 'Jugendheimerzieher/in',
    Occupation: 'Youth Residential Education Institution Housemaster',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861120,
    OccDE: 'Jugendamtsleiter/in',
    Occupation: 'Youth Welfare Director',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861125,
    OccDE: 'Jugendleiter/in',
    Occupation: 'Youth Welfare Manager',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861127,
    OccDE: 'Jugendreferent/in',
    Occupation: 'Youth Welfare Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861129,
    OccDE: 'Jugendwart/in',
    Occupation: 'Youth Welfare Officer',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861121,
    OccDE: 'Jugendfürsorger/in',
    Occupation: 'Youth Welfare Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 861126,
    OccDE: 'Jugendpfleger/in',
    Occupation: 'Youth Welfare Worker',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 235910,
    OccDE: 'Verzinker/in',
    Occupation: 'Zinc-plater',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '024204',
    OccDE: 'Zootierpfleger/in',
    Occupation: 'Zoo Keeper',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023020',
    OccDE: 'Zootechniker/in-Mechanisator/in',
    Occupation: 'Zoo Technologist',
    'Feather RC': 'X',
  },
  {
    OccupationCode: '023138',
    OccDE: 'Zootechniker/in-Mechanisator/in (Kälber-, Rinderproduktion)',
    Occupation: 'Zoo Technology Machinery Engineer (Calves, Cattle Production)',
    'Feather RC': 'X',
  },
  {
    OccupationCode: 883163,
    OccDE: 'Zoolog(e/in)',
    Occupation: 'Zoologist',
    'Feather RC': 'A',
  },
];
