import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { TableInfoButton } from '@popsure/dirty-swan';

import styles from './PriceCell.module.scss';

interface PriceCellProps {
  price: number | string;
  onInfoClick: () => void;
}

export const PriceCell = ({ price, onInfoClick }: PriceCellProps) => (
  <div>
    <div className={styles.container}>
      <h1 className="p-h1 p--serif tc-primary-500">
        {typeof price === 'number'
          ? englishFormattedEuroCurrency(Math.round(price), true)
          : price}
      </h1>
      <div className={styles.button}>
        <TableInfoButton onClick={onInfoClick} />
      </div>
    </div>
    <p className="p-p--small tc-grey-500">per month</p>
  </div>
);
