import dayjs from 'dayjs';

export const DATE_FORMAT = 'DD MMM YYYY';

export const getEarliestStartDate = (arrivalDate?: string) => {
  const tomorrow = dayjs().add(1, 'day');

  const earliestStartDate = dayjs(tomorrow).isAfter(arrivalDate)
    ? tomorrow
    : arrivalDate;

  return dayjs(earliestStartDate).format(DATE_FORMAT);
};
