import { TFunction } from '@getpopsure/i18n-react';
import {
  BriefcaseIcon,
  CalendarIcon,
  DeathFuneralIcon,
  DependentsFamilyIcon,
  DoctorStethoscopeIcon,
  EuroClaimIcon,
  HatGraduationIcon,
  HomeIcon,
  WorldGlobeEarthIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const life = (t: TFunction): AdditionalInfoType => ({
  price: 1.98,
  description: t(
    'policyModal.life.description',
    'Life insurance provides a lump-sum payment to your beneficiaries in the event of your death. This payment helps support your loved ones, covering expenses like daily living costs, debts, or future financial needs. It offers peace of mind by ensuring that your family or dependents are financially secure even in your absence.'
  ),
  accordions: [
    {
      title: t('policyModal.life.title1', 'What it helps with'),
      items: [
        {
          id: 'covered-1',
          icon: <HomeIcon size={24} />,
          question: t(
            'policyModal.life.covered1.question',
            'Debt and mortgage'
          ),
          answer: t(
            'policyModal.life.covered1.answer',
            "Don't let your financial obligations get passed on to your family. Life insurance can help cover them."
          ),
        },
        {
          id: 'covered-2',
          icon: <EuroClaimIcon size={24} />,
          question: t('policyModal.life.covered2.question', 'Lost income'),
          answer: t(
            'policyModal.life.covered2.answer',
            "If you’re the primary source of income for your family, life insurance can provide for them when you're gone, so they can get back on their feet."
          ),
        },
        {
          id: 'covered-3',
          icon: <HatGraduationIcon size={24} />,
          question: t(
            'policyModal.life.covered3.question',
            'Childcare and education'
          ),
          answer: t(
            'policyModal.life.covered3.answer',
            "Education, childcare, and security. Life insurance can help ensure a future for your loved ones when you're no longer with them."
          ),
        },
        {
          id: 'covered-4',
          icon: <DeathFuneralIcon size={24} />,
          question: t('policyModal.life.covered4.question', 'Final expenses'),
          answer: t(
            'policyModal.life.covered4.answer',
            'No one wants to plan a funeral and the costs can be quite high. Life insurance can cover them while your family mourns.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.life.title2', "When it's needed"),
      items: [
        {
          id: 'needed-1',
          icon: <DependentsFamilyIcon size={24} />,
          question: t(
            'policyModal.life.needed1.question',
            'When you’re supporting others financially'
          ),
          answer: t(
            'policyModal.life.needed1.answer',
            'This can be a partner, child, or relative that is financially dependent on you.'
          ),
        },
        {
          id: 'needed-2',
          icon: <HomeIcon size={24} />,
          question: t(
            'policyModal.life.needed2.question',
            'When you have a mortgage'
          ),
          answer: t(
            'policyModal.life.needed2.answer',
            'If you have a mortgage together with your partner, it makes sense to either get a term life insurance for the main earner, or both if you’re contributing equally.'
          ),
        },
        {
          id: 'needed-3',
          icon: <BriefcaseIcon size={24} />,
          question: t(
            'policyModal.life.needed3.question',
            'When you have a business partner'
          ),
          answer: t(
            'policyModal.life.needed3.answer',
            'If your business partner would be financially impacted in the event of your death, it makes sense to add them as a beneficiary on your life insurance.'
          ),
        },
      ],
    },
  ],
  details: [
    {
      icon: CalendarIcon,
      title: t('policyModal.life.details.title1', 'Monthly cancellation'),
      description: t(
        'policyModal.life.details.description1',
        'Simply cancel before the renewal date'
      ),
    },
    {
      icon: WorldGlobeEarthIcon,
      title: t('policyModal.life.details.title2', 'Worldwide coverage'),
      description: t(
        'policyModal.life.details.description2',
        'Certain exclusions apply'
      ),
    },
    {
      icon: DoctorStethoscopeIcon,
      title: t('policyModal.life.details.title3', 'No medical exams required'),
      description: t(
        'policyModal.life.details.description3',
        'Everything happens in our digital sign-up'
      ),
    },
  ],
  buttonText: t('policyModal.life.actionButton', 'Continue to quote'),
});
