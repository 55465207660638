import classNames from 'classnames';

import GoogleReviewBadge from './googleReviewBadge';
import styles from './style.module.scss';
import TrustpilotReviewBadge from './trustpilotReviewBadge';

interface ReviewBadgeProps {
  className?: string;
  displayReviews?: boolean;
}

const ReviewBadge = ({ className, displayReviews }: ReviewBadgeProps) => (
  <div className={classNames(styles.container, className)}>
    <div className={styles['review-rating']}>
      <GoogleReviewBadge displayReviews={displayReviews} />
    </div>
    <TrustpilotReviewBadge displayReviews={displayReviews} />
  </div>
);

export default ReviewBadge;
