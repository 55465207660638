import { ClaimsAction, RequestAction } from 'constants/actions';
import { InsuranceTypes } from 'models/insurances/types';
import { PartialQuestionnaire } from 'models/questionnaireFramework';
import type { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import type { TFunction } from 'shared/i18n';
import { UploadedFile } from 'shared/models/types';
import {
  ZPayoutDetailsSchema,
  ZUploadedDocumentsSchema,
} from 'shared/models/validations';
import { z } from 'zod';

export type Questionnaire = PartialQuestionnaire<HouseholdClaims, GroupId>;
export type QuestionId = keyof HouseholdClaims;

export type GroupId = 'questionnaire';

const HouseholdClaimTypeArray = [
  'BURGLARY',
  'WATER_DAMAGE',
  'FIRE_DAMAGE',
  'BIKE_THEFT',
  'GLASS_BREAKAGE',
  'OTHER',
] as const;
export type HouseholdClaimType = typeof HouseholdClaimTypeArray[number];

export const claimTypeMapping = (
  t: TFunction
): { [K in HouseholdClaimType]: string } => ({
  BURGLARY: t('claims.household.questionnaire.claimType.burglary', 'Burglary'),
  WATER_DAMAGE: t(
    'claims.household.questionnaire.claimType.waterDamage',
    'Water damage'
  ),
  FIRE_DAMAGE: t(
    'claims.household.questionnaire.claimType.fireDamage',
    'Fire damage'
  ),
  BIKE_THEFT: t(
    'claims.household.questionnaire.claimType.bikeTheft',
    'Bike theft'
  ),
  GLASS_BREAKAGE: t(
    'claims.household.questionnaire.claimType.glassBreakage',
    'Glass breakage'
  ),
  OTHER: t('claims.household.questionnaire.claimType.otherDamage', 'Other'),
});

export interface HouseholdClaims {
  intro: boolean;
  dateOfEvent: string;
  claimType: HouseholdClaimType;
  details: string;
  uploadedDocumentTokens: UploadedFile[];
  payoutDetails: { amount: number; iban: string };
  processing: boolean;
  submitted: boolean;
}

export interface HouseholdClaimsMetaData {
  policyId: string;
}

export type HouseholdClaimsDispatch = ThunkDispatch<
  AppState,
  unknown,
  RequestAction | ClaimsAction
>;

export interface HouseholdClaimsSubmitInfo {
  type: InsuranceTypes;
  userPolicyId: string;
  claimType: HouseholdClaimType;
  dateOfEvent: string;
  details: string;
  uploadedDocumentTokens: string[];
  amount: number;
  iban: string;
}

export const ZHouseholdClaimsSubmissionSchema = z.object({
  claimType: z.enum(HouseholdClaimTypeArray),
  uploadedDocumentTokens: z.array(ZUploadedDocumentsSchema),
  payoutDetails: ZPayoutDetailsSchema,
  details: z.string(),
  dateOfEvent: z.string(),
});
