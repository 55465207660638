import { AppState } from 'reducers';

import { CheckoutInfo } from './paymentScreen.models';

export const getCheckoutInfo =
  (checkoutId: string) =>
  (state: AppState): CheckoutInfo | undefined =>
    state.paymentScreen.checkouts[checkoutId];

export const getReferralCode = (state: AppState) =>
  state.user?.userReferralInfo?.validReferrerCode; // TODO: Why does it rely on global user state?
