import { Address } from '@getpopsure/public-models';
import { UserAction } from 'constants/actions';
import type { UserReferralInfo } from 'features/referralEngine/models/referralEngine.model';
import { UserWithBrokerMandate } from 'models/user';
import { Reducer } from 'redux';

interface UserState {
  accountInfo?: UserWithBrokerMandate;
  userAddress?: Address | null;
  userReferralInfo?: UserReferralInfo;
}

export const defaultState = {
  accountInfo: undefined,
};

const user: Reducer<UserState, UserAction> = (state = defaultState, action) => {
  switch (action.type) {
    case 'MERGE_ACCOUNT_INFO':
      return {
        ...state,
        accountInfo: action.accountInfo,
      };
    case 'MERGE_USER_ADDRESS': {
      return {
        ...state,
        userAddress: action.userAddress,
      };
    }
    case 'MERGE_USER_REFERRAL_CODE_INFO': {
      return {
        ...state,
        userReferralInfo: {
          ...state.userReferralInfo,
          ...action.userReferralInfo,
        },
      };
    }
    default:
      return state;
  }
};

export default user;
