import { toast } from '@popsure/dirty-swan';
import LoadingSpinner from 'components/loadingSpinner';
import { onSuccessfulCheckout } from 'features/checkout/actions';
import { getRegionFromType } from 'features/checkout/utils';
import { PaymentMethodSelectorThunkDispatch } from 'features/paymentMethodSelector/paymentMethodSelector.thunks';
import { getReferralCode } from 'features/paymentScreen/paymentScreen.selectors';
import { getCheckout } from 'features/paymentScreen/paymentScreen.thunks';
import { clearReferrerCode } from 'features/referralEngine/actions';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

/**
 * The customer lands here after confirming a new payment on
 * a payment provider's page.
 */
const ConfirmPaymentRedirectGateway = () => {
  const { t } = useSafeTranslation();

  const redirectStatus = useQueryParamValue('redirect_status');
  const checkoutPath = useQueryParamValue('checkoutPath');
  const referralCode = useSelector(getReferralCode);
  const checkoutId = useQueryParamValue('checkoutId');

  const dispatch = useDispatch<PaymentMethodSelectorThunkDispatch>();

  const history = useHistory();
  const isPaymentSuccessful = redirectStatus === 'succeeded';

  useEffect(() => {
    const processPostConfirmPayment = async () => {
      const checkoutInfo = await dispatch(
        getCheckout({ checkoutId: checkoutId ?? '' })
      );

      if (isPaymentSuccessful) {
        const { policyDetails, id } = checkoutInfo.policies[0];
        await dispatch(
          onSuccessfulCheckout({
            verticalId: policyDetails.type,
            policyId: id,
            localeId: getRegionFromType(
              checkoutInfo.policies[0].policyDetails.type,
              checkoutInfo.policies[0].policyDetails.regionOfPurchase
            ),
          })
        );
        await dispatch(clearReferrerCode());
      } else {
        toast(
          t(
            'paymentScreen.errors.errorConfirmingCheckout',
            'The checkout was not successful'
          ),
          {
            type: 'error',
            description: t(
              'paymentScreen.errors.errorConfirmingCheckoutDescription',
              'Please, try again with a different payment method or contact customer support.'
            ),
            duration: 5000,
          }
        );
        history.replace(checkoutPath ?? '');
      }
    };

    processPostConfirmPayment();
  }, [
    redirectStatus,
    history,
    referralCode,
    t,
    dispatch,
    checkoutPath,
    checkoutId,
    isPaymentSuccessful,
  ]);

  return <LoadingSpinner />;
};

export default ConfirmPaymentRedirectGateway;
