import { WaveHandIcon } from 'components/waveHandIcon/WaveHandIcon';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';

import styles from './DependentsIntro.module.scss';

export const DependentsIntro = ({
  onSubmitValue,
}: CustomComponentProps<Record<string, unknown>>) => {
  const { t } = useSafeTranslation();

  const handleGetStarted = () => {
    onSubmitValue(true);
  };

  return (
    <div className={styles.container}>
      <div className="p-body">
        <WaveHandIcon />
        <h1 className="p-h1 mt16">
          {t(
            'expat.qnr.components.dependentsIntro.title',
            "Hey! Let's cover your loved one"
          )}
        </h1>
        <p className="p-p mt16">
          {t(
            'expat.qnr.components.dependentsIntro.description',
            'You can simply answer the following questions for them and their new policy will be added to your account.'
          )}
        </p>
        <button
          className={`p-btn p-btn--primary ws3 mt24 ${styles.nextButton}`}
          data-cy="button-continue"
          onClick={handleGetStarted}
          type="button"
        >
          {t('components.intro.cta', 'Get Started')}
        </button>
      </div>
    </div>
  );
};
