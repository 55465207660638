import { EuroClaimIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { isDelinquentUser } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';

import { PolicyDetailsButtonProps } from '../../sections/PolicyDetails/PolicyDetails.models';

type CreateClaimButtonProps = PolicyDetailsButtonProps & {
  route: string;
  title?: string;
  icon?: string | JSX.Element;
};
export const CreateClaimButton = (props: CreateClaimButtonProps) => {
  const {
    onOpenModal,
    policyData,
    route,
    title,
    icon = <EuroClaimIcon noMargin size={24} className="ml16" />,
  } = props;
  const { t } = useSafeTranslation();
  const history = useHistory();
  const isDelinquent = useSelector(isDelinquentUser);

  const handleClaimButton = () => {
    if (isDelinquent) {
      onOpenModal('DELINQUENT_BLOCKER_CREATE_CLAIM');
      return;
    }

    history.push(generatePath(route, { policyId: policyData.id }));
  };

  return (
    <ButtonCell
      title={
        title ?? t('myPolicies.policyDetails.claimsButton.caption', 'New claim')
      }
      data-cy="me-policies-detail-make-a-claim"
      icon={icon}
      onClick={handleClaimButton}
    />
  );
};
