import { AxiosInstance } from 'axios';
import { PolicyInfo } from 'features/checkout/models';

import {
  insuranceTypeToEndpointPrefix,
  PythonBackendInsuranceType,
  SignupsBackendInsuranceType,
} from '../models';

export function createPolicyTerms(
  network: AxiosInstance,
  insuranceType: PythonBackendInsuranceType | SignupsBackendInsuranceType,
  policyInfo: PolicyInfo
) {
  const endPointPrefix = insuranceTypeToEndpointPrefix[insuranceType];
  return network.post<{ id: string }>(
    `${endPointPrefix}/policy_terms`,
    policyInfo
  );
}

export function downloadDocuments(
  network: AxiosInstance,
  insuranceType: PythonBackendInsuranceType | SignupsBackendInsuranceType,
  id: string
) {
  const endPointPrefix = insuranceTypeToEndpointPrefix[insuranceType];

  return network.get<
    | { status: 'IN_PROGRESS' }
    | { status: 'READY'; url: string; filename: string }
  >(`${endPointPrefix}/policy_terms/${id}`);
}
