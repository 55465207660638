import { DependentsFamilyAddIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { useSafeTranslation } from 'shared/i18n';

export const PrivateHealthAddDependentsButton = (
  props: PolicyDetailsButtonProps
) => {
  const { onOpenModal } = props;
  const { t } = useSafeTranslation();

  const handleAddDependentsButton = () => {
    onOpenModal('PRIVATE_HEALTH_ADD_DEPENDENTS');
  };

  return (
    <ButtonCell
      key="ADD_DEPENDENTS"
      title={t(
        'myPolicies.policyDetails.addDependentsButton.caption',
        'Add dependents'
      )}
      icon={<DependentsFamilyAddIcon noMargin size={24} className="ml16" />}
      onClick={handleAddDependentsButton}
      data-cy="button-add-dependents"
    />
  );
};
