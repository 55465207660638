import dayjs from 'dayjs';
import Cell from 'features/checkout/components/Cell';
import { getCheckoutInfo } from 'features/checkout/selectors';
import { CheckoutPolicyDetails } from 'features/disabilityV2/models';
import { ReferrerCodeVerify } from 'features/referralEngine';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { formatCurrencyWithSpace } from 'shared/util/formatCurrencyWithSpace';

import styles from './PolicyDetails.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

export const PolicyDetails = () => {
  const { t } = useSafeTranslation();

  // @TODO add type DISABILITY_V2 to [InsuranceTypes](src/features/checkout/selectors/checkoutSelectors.ts)
  const checkoutInfo = useSelector(
    getCheckoutInfo<CheckoutPolicyDetails>('DISABILITY_V2')
  );

  return (
    <div className={styles.container}>
      <div className="p-h2 mb16">
        {t('disabilityv1.qnr.checkout.policyDetails .title', 'Policy details')}
      </div>
      <Cell
        title={t(
          'disabilityv1.qnr.checkout.policyDetails.startDate',
          'Start date'
        )}
        value={dayjs(checkoutInfo?.mainPolicy.startDate).format(DATE_FORMAT)}
      />
      <div className={`bg-grey-200 mt32 mb32 ${styles.divider}`} />
      {checkoutInfo?.mainPolicy.monthlyPrice && (
        <div className="d-flex">
          <div className="p-h3">
            {t(
              'disabilityv1.qnr.checkout.policyDetails.total',
              'Total per month'
            )}
          </div>
          <div className="p-h3 ml-auto">
            {formatCurrencyWithSpace(checkoutInfo?.mainPolicy.monthlyPrice, 2)}
          </div>
        </div>
      )}
      <ReferrerCodeVerify />
    </div>
  );
};
