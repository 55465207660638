import { DependentsFamilyAddIcon } from '@popsure/dirty-swan';
import { flushGenericQuestionnaire } from 'actions/genericQuestionnaire';
import ButtonCell from 'components/buttonCell';
import routes from 'constants/routes';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { isUnder18 } from 'shared/util/isUnder18';

export const ExpatEuAddDependentsButton = (props: PolicyDetailsButtonProps) => {
  const { policyData } = props;
  const { t } = useSafeTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const account = useSelector(getAccountInfo);

  const policyId = policyData.mainPolicyId || policyData.id;

  const handleAddDependentsButton = () => {
    dispatch(flushGenericQuestionnaire('expatEu'));

    const redirectUrl = generatePath(routes.policies.expatEu.dependents.path, {
      mainPolicyId: policyId,
      localeId: policyData.region,
    });

    history.push(redirectUrl);
  };

  if (account?.dateOfBirth && isUnder18(account?.dateOfBirth)) return null;

  return (
    <ButtonCell
      key="ADD_DEPENDENTS"
      title={t(
        'myPolicies.policyDetails.expatEu.addDependentsButton.caption',
        'Add dependents'
      )}
      icon={<DependentsFamilyAddIcon noMargin size={24} className="ml16" />}
      onClick={handleAddDependentsButton}
      data-cy="button-add-dependents"
    />
  );
};
