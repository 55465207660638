import {
  categoryByInsuranceType,
  RecommendationData,
  RecommendationType,
  TAllowedInsuranceTypes,
} from 'features/recommendationTool/models/recommendation';

const verticalWithoutUnansweredQuestions = (
  recommendation: RecommendationData,
  verticalId: RecommendationType | 'HEALTH_INSURANCE'
): boolean => {
  if (verticalId !== 'HEALTH_INSURANCE') {
    return (
      recommendation.find((r) => r.key === verticalId)?.eligibilityStatus !==
      'MORE_INFO_REQUIRED'
    );
  }

  const healthVerticals = [
    'PUBLIC_HEALTH',
    'PRIVATE_HEALTH',
    'EXPAT_HEALTH',
    'EHIC_HEALTH',
  ];

  return healthVerticals.some(
    (vertical) =>
      recommendation.find((r) => r.key === vertical)?.eligibilityStatus !==
      'MORE_INFO_REQUIRED'
  );
};

export const hasMoreInfoRequired = (
  recommendationData: RecommendationData,
  insuranceType: TAllowedInsuranceTypes
): boolean =>
  !recommendationData ||
  !verticalWithoutUnansweredQuestions(
    recommendationData,
    categoryByInsuranceType[insuranceType]
  ) ||
  recommendationData.some(
    (rec) =>
      rec.eligibilityStatus === 'MORE_INFO_REQUIRED' &&
      rec.key === categoryByInsuranceType[insuranceType]
  );
