import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { SliderWithModal } from 'components/SliderWithModal';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Trans, useSafeTranslation } from 'shared/i18n';

import infoIcon from './assets/info.svg';
import styles from './DisabilityCalculator.module.scss';

type DisabilityCalculatorProps = {
  getQuote: () => void;
  isLoading: boolean;
  monthlyPayout: number;
  onContinueClick: () => void;
  price: number;
  setMonthlyPayout: Dispatch<SetStateAction<number>>;
  error: ReturnType<typeof useRequestStatus>['error'];
  min: number;
  max: number;
  stepSize: number;
};

export const DisabilityCalculator = ({
  price,
  getQuote,
  isLoading,
  monthlyPayout,
  onContinueClick,
  min,
  max,
  stepSize,
  setMonthlyPayout,
  error,
}: DisabilityCalculatorProps) => {
  const { t } = useSafeTranslation();

  const [isDraggingSlider, setIsDraggingSlider] = useState(false);

  const showAdditionalDocumentsInfo = monthlyPayout > 2_000;

  const priceStr = englishFormattedEuroCurrency(price, true);

  useEffect(() => {
    if (!isDraggingSlider) {
      getQuote();
    }
  }, [monthlyPayout, isDraggingSlider]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="ml-auto mr-auto wmx10">
      {error ? (
        <>
          <p className="ta-center p-p tc-grey-600">
            {t(
              'disability.quote.calculator.error.title',
              'Sorry, something went wrong.'
            )}
          </p>
          <Trans i18nKey="disability.quote.calculator.error.tryAgain.message">
            <p className="ta-center p-p tc-grey-600">
              Please{' '}
              <button
                className="p-a c-pointer fw-bold p-p bg-transparent"
                onClick={getQuote}
                type="button"
              >
                try again
              </button>{' '}
              shortly.
            </p>
          </Trans>
        </>
      ) : (
        <>
          <div className="d-flex fd-column ai-center jc-center w100">
            {isDraggingSlider || isLoading ? (
              <span
                className={`ds-spinner ds-spinner__l ${styles.loadingSpinner}`}
              />
            ) : null}
            <h3 className="p-h1--xl p--serif tc-primary-500 ta-center">
              {priceStr}
            </h3>
          </div>
          <p className="p-p--small ta-center tc-grey-500">
            {t('disability.quote.calculator.perMonth', 'per month')}
          </p>
        </>
      )}
      <div className="d-flex f-wrap ai-center jc-center p32 gap72 bg-white mt40 br8">
        <div className="d-flex f-wrap jc-center r-gap8">
          <h3 className="p-h3">
            {t('disability.quote.calculator.title', 'Monthly payout')}
          </h3>
          <p className="p-p tc-grey-700 ta-center">
            {t(
              'disability.quote.calculator.description',
              'You’ll apply for insurance with a requested monthly payout sum. This is the gross amount you will receive per month after it has been determined that you are occupationally disabled.'
            )}
          </p>
          <Trans i18nKey="disability.quote.calculator.recommendation">
            <p className="p-p tc-grey-700 ta-center">
              Based on your net monthly income, you can ask for a monthly payout
              up to{' '}
              <span className="fw-bold">
                {{
                  recommendedValue: englishFormattedEuroCurrency(max, true),
                }}
              </span>
              .
            </p>
          </Trans>
        </div>
        <div className="d-flex fd-column ai-center w100">
          <SliderWithModal
            options={{ darkBG: true, bubbleVariant: true }}
            min={min}
            max={max}
            value={monthlyPayout}
            setValue={setMonthlyPayout}
            step={stepSize}
            onChangeStart={() => setIsDraggingSlider(true)}
            onChangeEnd={() => setIsDraggingSlider(false)}
            label={t(
              'disability.quote.calculator.slider.label',
              'Monthly payout'
            )}
            modalTextOverrides={{
              title: t(
                'disability.quote.calculator.inputModal.title',
                'Monthly payout'
              ),
              description: t(
                'disability.quote.calculator.inputModal.description',
                {
                  defaultValue:
                    'Please enter your desired monthly payout sum ranging from {{from}} to {{to}}.',
                  from: englishFormattedEuroCurrency(min, true),
                  to: englishFormattedEuroCurrency(max, true),
                }
              ),
              placeholder: t(
                'disability.quote.calculator.inputModal.placeholder',
                'Payout'
              ),
              belowMinErrorMessage: t(
                'disability.quote.calculator.inputModal.error.belowmin',
                {
                  defaultValue:
                    'The smallest monthly payout sum we can offer is {{min}}',
                  min: englishFormattedEuroCurrency(min, true),
                }
              ),
              aboveMaxErrorMessage: t(
                'disability.quote.calculator.inputModal.error.abovemax',
                {
                  defaultValue:
                    'The monthly payout sum exceeds the maximum we can offer ({{max}})',
                  max: englishFormattedEuroCurrency(max, true),
                }
              ),
              buttonCaption: t(
                'disability.quote.calculator.inputModal.applyButton.caption',
                'Apply'
              ),
            }}
          />
          <AnimateHeight
            duration={150}
            height={showAdditionalDocumentsInfo ? 'auto' : 0}
          >
            <div className="d-flex ai-start c-gap8 bg-grey-100 br8 mt24 wmx6 p16">
              <img src={infoIcon} alt="info" />
              <p className="p-p--small tc-grey-600">
                {t('disability.quote.calculator.infoBox.text', {
                  defaultValue:
                    'Additional documents are required for monthly payout sums over {{documentLimit}} per month. Our support team will help you fill these out in the extended application process.',
                  documentLimit: englishFormattedEuroCurrency(2000, true),
                })}
              </p>
            </div>
          </AnimateHeight>
        </div>
      </div>
      <div className="d-flex r-gap16 fd-column ai-center jc-center mt48 mb72">
        <button
          className="w100 p-btn p-btn--primary wmx4"
          onClick={onContinueClick}
          type="button"
          data-cy="button-continue"
          disabled={isDraggingSlider || isLoading}
        >
          {t('disability.quote.continueButton.caption', 'Continue')}
        </button>
        <p className="p-p--small tc-grey-500 wmx4 ta-center">
          {t(
            'disability.quote.calculator.disclaimer',
            'Possible exclusions and / or surcharges could apply based on the results of your health and lifestyle questionnaires.'
          )}
        </p>
      </div>
    </section>
  );
};
