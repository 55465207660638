import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { ErrorWithAction } from 'components/ErrorWithAction';
import { RequestType } from 'constants/requestTypes';
import routes from 'constants/routes';
import {
  ProcessingDispatch,
  ProcessingDispatchAction,
} from 'features/expatClaims/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';
import { Processing as ProcessingView } from 'shared/pages/Processing';
import { ProcessingView as ActionProcessingView } from 'shared/pages/Processing/Processing.view';

type ActionType = {
  requestType: RequestType;
  fn: ProcessingDispatchAction;
};

type ProcessingProps<Questionnaire extends QuestionnaireQuestions> = {
  textList?: string[];
  action?: ActionType;
} & QuestionnaireFormProps<Questionnaire>;

export const Processing = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  action,
  textList,
  description,
  ...props
}: ProcessingProps<Questionnaire> & { description?: string }) => {
  const { t } = useSafeTranslation();

  if (action) {
    return (
      <ActionProcessing
        onSubmitValue={onSubmitValue}
        action={action}
        textList={textList}
        {...props}
      />
    );
  }

  const list: string[] = textList ?? [
    t('generic.qnr.processing.loadingText', 'Processing claim...'),
  ];

  return (
    <ProcessingView
      onLoadComplete={onSubmitValue}
      textList={list}
      description={description}
    />
  );
};

export const ActionProcessing = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  action,
  textList,
  description,
}: ProcessingProps<Questionnaire> & {
  action: ActionType;
  description?: string;
}) => {
  const { t } = useSafeTranslation();
  const { policyId } = useParams<{ policyId: string }>();
  const dispatch = useDispatch<ProcessingDispatch>();
  const history = useHistory();

  const [processingFailed, setProcessingFailed] = useState(false);

  const { requestType, fn } = action;
  const refFn = useRef<ProcessingDispatchAction>(fn);
  const refOnSubmit = useRef<(value: unknown) => void>(onSubmitValue);

  const TIMEOUT_TIME = 3_000;
  const elementIndex = 0;
  const fadeInAnimation = false;

  // TODO: adapt logic from shared/pages/Processing to render more than one message from textList

  const list: string[] = textList
    ? [textList[0]]
    : [t('generic.qnr.processing.loadingText', 'Processing claim...')];

  const { error } = useRequestStatus(requestType);

  useEffect(() => {
    const submit = async () => {
      const status = await dispatch(refFn.current());

      if (status || error) {
        const timeoutId = setTimeout(() => {
          if (status === 'SUCCESS') refOnSubmit.current(true);
          if (error) setProcessingFailed(true);
        }, TIMEOUT_TIME);

        return () => clearTimeout(timeoutId);
      }
    };

    submit();
  }, [dispatch, error, refFn, refOnSubmit]);

  const handlePolicyError = () => {
    const policyDetailsPage = generatePath(routes.claims.expat.path, {
      policyId,
    });
    history.push(policyDetailsPage);
  };

  if (processingFailed) {
    return (
      <ErrorWithAction
        title={t(
          'claims.expatClaims.submissionError.title',
          'Something went wrong'
        )}
        description={t(
          'claims.expatClaims.submissionError.description',
          'Sorry, something didn’t work as it should. Starting over should address this issue.'
        )}
        cta={{
          title: t('claims.expatClaims.submissionError.cta', 'Start over'),
          onClick: handlePolicyError,
        }}
      />
    );
  }

  return (
    <ActionProcessingView
      elementIndex={elementIndex}
      fadeInAnimation={fadeInAnimation}
      text={list}
      description={description}
    />
  );
};
