import { ExpatQuestionnaire } from 'features/expat/models';
import { PartialQuestionnaire } from 'models/questionnaireFramework';

import { Review } from '../../components/Review';

export const reviewInfoQNR = (): PartialQuestionnaire<
  ExpatQuestionnaire,
  'signup'
> => ({
  review: {
    required: true,
    type: 'CUSTOM',
    groupId: 'signup',
    component: Review,
    retrieveAnswerObject: (
      review: ExpatQuestionnaire['review']
    ): Partial<ExpatQuestionnaire> => ({
      review,
    }),
  },
});
