import { NavButton } from 'components/NavButton';
import { WaveHandIcon } from 'components/waveHandIcon/WaveHandIcon';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';

import styles from './Intro.module.scss';

export const Intro = ({
  onSubmitValue,
  title,
  description,
}: CustomComponentProps<Record<string, unknown>> & {
  title?: string;
  description?: string;
}) => {
  const { t } = useSafeTranslation();

  const handleGetStarted = () => {
    onSubmitValue(true);
  };

  return (
    <>
      {isMobileApp && <NavButton title="Previous Question" />}
      <div className={styles.container}>
        <div className="p-body">
          <WaveHandIcon />
          <h1 className="p-h1 mt16">
            {title ||
              t(
                'components.intro.title',
                "Hey! Let's get you a quote in minutes"
              )}
          </h1>
          <p className="p-p mt16">
            {description ||
              t(
                'components.intro.description',
                'We need a few pieces of information about you and we can figure out the rest.'
              )}
          </p>
          <button
            className={`p-btn p-btn--primary ws3 mt24 ${styles.nextButton}`}
            data-cy="button-continue"
            onClick={handleGetStarted}
            type="button"
          >
            {t('components.intro.cta', 'Get Started')}
          </button>
        </div>
      </div>
    </>
  );
};
