import routes from 'constants/routes';
import { storeAddBeneficiariesAnswers } from 'features/lifeBeneficiaries/actions';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Redirect, useParams } from 'react-router';
import { AppState } from 'reducers';
import { getPolicyDetail } from 'selectors/policies';
import { UploadedFile } from 'shared/models/types';

export const EditBeneficiaries = () => {
  const { policyId } = useParams<{ policyId: string }>();
  const dispatch = useDispatch();
  const policy = useSelector((state: AppState) =>
    policyId !== undefined ? getPolicyDetail(state, policyId) : undefined
  );

  const policyHolderIdState = useSelector(
    ({ lifeBeneficiaries }: AppState) => lifeBeneficiaries.singlePolicyHolderId
  );

  dispatch(
    storeAddBeneficiariesAnswers({
      intro: true,
      name: { firstName: '', lastName: '' },
      dateOfBirth: '',
      uploads: [],
      policyHolderIdUpload: policyHolderIdState,
      beneficiaries: (policy?.attributes.beneficiaries ?? []).map(
        (beneficiary) => ({
          ...beneficiary,
          payoutPercentage: Number(beneficiary.payoutPercentage),
          uploads: beneficiary.userUploads?.map(
            (upload): UploadedFile => ({
              id: upload.id,
              name: upload.filename,
              type: upload.normalizedExtension,
              progress: 100,
              previewUrl: upload.publicUrl,
              token: upload.token,
            })
          ),
        })
      ),
    })
  );
  return (
    <Redirect
      to={generatePath(
        routes.me.policies.life.beneficiaries.questionnaire.path,
        {
          policyId,
          groupId: 'questionnaire',
          questionId: 'review',
        }
      )}
    />
  );
};
