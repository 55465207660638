/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-throw-literal */
import { InclusiveMember } from '@getpopsure/liability-insurance-pricing-engine';
import Session, { CSRF_TOKEN_KEY } from '@getpopsure/session';
import { getTrackingObject } from '@getpopsure/tracker';
import * as Sentry from '@sentry/react';
import {
  ConfirmCardSetupData,
  ConfirmSepaDebitSetupData,
  SetupIntent,
  Stripe,
} from '@stripe/stripe-js';
// TODO: [KONG] Untangle this dependency cycle
// eslint-disable-next-line import/no-cycle
import { signOut } from 'actions/session';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { InsuranceVertical } from 'features/checkout/models';
import type { SubmitInfo as DentalSubmitInfo } from 'features/dentalClaims/models';
import type {
  DogMeta,
  DogQuote,
  SubmittableDogQuestionnaire as DogSubmittableQuestionnaire,
} from 'features/dogLiability/models';
import {
  ExpatQuestionnaireType,
  SubmittableQuestionnaire as ExpatSubmittableQuestionnaire,
} from 'features/expat/models';
import { AuthProvider } from 'features/GoogleOAuth/googleOAuth.models';
import { HouseholdClaimsSubmitInfo } from 'features/householdClaims/models';
import { IdentityHashResponse } from 'features/intercomLauncher/models';
import { SubmitInfo as LiabilitySubmitInfo } from 'features/liabilityClaims/models';
import type {
  Beneficiary,
  Quote as LifeQuote,
  SubmittablePreQuoteLifeQuestionnaire,
} from 'features/life/models';
import { PrivateHealthClaimsSubmitInfo } from 'features/privateHealthClaims/models';
import {
  StudentChecklistRequest,
  StudentChecklistResponse,
} from 'features/studentChecklist/models';
import Cookies from 'js-cookie';
import {
  EmailNotificationQuestionnaire as BikeEmailNotificationQuestionnaire,
  PostQuoteQuestionnaire as BikePostQuoteQuestionnaire,
  PreQuoteQuestionnaire as BikePreQuoteQuestionnaire,
  QuestionnaireLabels as BikeQuestionnaireLabels,
  QuestionnaireType as BikeQuestionnaireType,
} from 'models/bike';
import { Claim, ClaimSubmitInfo } from 'models/claims';
import {
  CreateHealthCardPhotoUploadPayload,
  CreateHealthCardPhotoUploadResponse,
  FetchPhotoUploadTokenResponse,
  HealthCard,
  MarkUploadedResponse,
  UpdateHealthCardAddress,
} from 'models/healthCard';
import { InsuranceTypes } from 'models/insurances/types';
import {
  Answers as LiabilityAnswers,
  QuestionnaireLabels as LiabilityQuestionnaireLabels,
} from 'models/liability';
import {
  MailingListResponse,
  UpdateMailingPreference,
} from 'models/mailingPreferences';
import {
  CardSetupData,
  PaymentMethod,
  PaymentType,
  SepaSetupData,
  SetupIntent as SetupIntentResponse,
  StripeBillingAddress,
} from 'models/paymentMethods';
import {
  OptionalPolicyAttributes,
  Policy,
  PolicyCancellationReason,
} from 'models/policies';
import {
  RecommendationPersonaRequest,
  RecommendationPersonaResponse,
  RecommendationSnapshotRequest,
  RecommendationSnapshotResponse,
} from 'models/recommendationTool';
import { ExistingAccountResponse } from 'models/session';
import { UserNewWithRequiredInfo, UserWithBrokerMandate } from 'models/user';
import { store } from 'reducers';
import { Locale } from 'shared/i18n';
import { NestedPartial } from 'shared/models/util';

const endpoint = process.env.REACT_APP_ENDPOINT;

if (!endpoint) {
  throw new Error("REACT_APP_ENDPOINT isn't set");
}

const isRunningOnCypress = (): boolean => {
  return !!window.Cypress;
};

const handleOnAccountRefreshError = (response?: Response | void) => {
  if (response?.status === 401) {
    store.dispatch(signOut());

    if (Session.isAuthenticated) {
      Sentry.captureException(
        '[SESSION_ERROR]: Returned with a 401 while being authenticated on account refresh',
        {
          tags: {
            feature: 'SESSION',
          },
        }
      );

      // This is here to prevent an identified looping when a user is kicked out
      // Should be removed after getting logs and making sure this isn't a common issue
      Cookies.remove('_frt.expiry');
    }
  }
};

const handleSignoutError = (error: AxiosError) => {
  if (error?.response) {
    const isSignoutRequest = error.response.config.url === '/account/signout';
    if (error.response.status === 401 && !isSignoutRequest) {
      store.dispatch(signOut());
      if (Session.isAuthenticated) {
        Sentry.captureException(
          '[SESSION_ERROR]: Returned with a 401 while being authenticated',
          {
            tags: {
              feature: 'SESSION',
            },
          }
        );

        // This is here to prevent an identified looping when a user is kicked out
        // Should be removed after getting logs and making sure this isn't a common issue
        Cookies.remove('_frt.expiry');
      }
    }
  }
};

const handleLogsOnCsrfRefetch = (message: string) =>
  Sentry.addBreadcrumb({
    category: 'feather.session',
    message,
    level: 'info',
  });

export class Client {
  public network: AxiosInstance;

  constructor() {
    const isE2eTest = isRunningOnCypress();

    this.network = axios.create({
      ...(process.env.NODE_ENV !== 'development'
        ? {
            baseURL: endpoint,
          }
        : {}),
      timeout: 10000,
      xsrfCookieName: CSRF_TOKEN_KEY,
      xsrfHeaderName: 'csrf-token',
      withXSRFToken: true,
      withCredentials: true,
      headers: {
        'auth-version': 'v2',
        ...(isE2eTest ? { 'X-Feather-e2e-Tests': true } : {}),
      },
    });

    Session.addAxiosInterceptors(
      this.network,
      handleOnAccountRefreshError,
      handleSignoutError,
      handleLogsOnCsrfRefetch
    );
  }

  // <--- Endpoints -->
  public getAccountInfo() {
    return this.network.get<UserWithBrokerMandate>('/me');
  }

  public signBrokerMandate(signature: string) {
    return this.network.patch('/me/broker_mandate', { signature });
  }

  public updateLifeQuestionnaire(
    answers: Partial<SubmittablePreQuoteLifeQuestionnaire>,
    questionnaireId: string
  ) {
    return this.network.patch(`/questionnaires/${questionnaireId}`, {
      answers,
      questionnaireType: 'LIFE_PRE_QUOTE',
    });
  }

  public updateBikeQuestionnaire(
    answers: NestedPartial<BikePostQuoteQuestionnaire>,
    questionnaireId: string,
    uploadTokens?: string[]
  ) {
    return this.network.patch(`/questionnaires/${questionnaireId}`, {
      answers,
      questionnaireType: 'BIKE_POST_QUOTE',
      uploadTokens,
    });
  }

  public updateLiabilityQuestionnaire(
    answers: NestedPartial<LiabilityAnswers>,
    questionnaireId: string,
    uploadTokens?: string[]
  ) {
    return this.network.patch(`/questionnaires/${questionnaireId}`, {
      answers,
      questionnaireType: 'LIABILITY',
      uploadTokens,
    });
  }

  public getQuestionnaire(questionnaireId: string) {
    return this.network.get(`/questionnaires/${questionnaireId}`);
  }

  public async createNewUser(email: string) {
    return this.network.post('/user', { email }).then((data) => {
      return data;
    });
  }

  public validateAccount(email: string) {
    return this.network.post<ExistingAccountResponse>(
      '/user/email_validation',
      { email }
    );
  }

  public loginWithGoogle(token: string) {
    return this.network.post<{
      statusCode?: number;
      message?: string;
      error?: string;
    }>('/auth/google', {
      token,
    });
  }

  public connectGoogleAccount(token: string) {
    return this.network.post('/auth/providers/google', { token });
  }

  public disconnectGoogleAccount(authProviderId: string) {
    return this.network.delete(`/auth/providers/${authProviderId}`);
  }

  public getAuthProviders() {
    return this.network.get<AuthProvider[]>('/auth/providers');
  }

  public async createGenericPolicy(payload: {
    formId: string;
    insuranceName?: string;
    providerId: string;
    policyData?: Record<string, string>;
    insuranceType?: InsuranceTypes;
    source?: Record<string, string>;
  }) {
    return this.network.post<Policy>('/generic_signups/policies', payload);
  }

  public async createAccount(accountInfo: UserNewWithRequiredInfo) {
    return this.network.post('/user', accountInfo).then((data) => {
      return data;
    });
  }

  public async signInWithTemporaryLoginCode(code: string, email: string) {
    return this.network
      .post('/account/signin/code', {
        email,
        code,
      })
      .then((data) => {
        return data;
      });
  }

  public sendSignInEmail(email: string) {
    return this.network.post('/account/signin', { email });
  }

  public signOutUser() {
    return this.network.delete('/account/signout');
  }

  public deleteUserAccount() {
    return this.network.post('/account/request_removal');
  }

  public createLifeBeneficiaries(
    beneficiaries: Partial<Beneficiary>[],
    identificationDocumentToken: string,
    policyId: string
  ) {
    const source = getTrackingObject();
    return this.network.post<{ id: string }>(
      `/life/${policyId}/beneficiaries`,
      {
        beneficiaries,
        identificationDocumentToken,
        metadata: { source },
      }
    );
  }

  public createExpatQuotes(dateOfBirth: string) {
    return this.network.post<{ id: string }>('/signups/expat/quotes/', {
      dateOfBirth,
    });
  }

  public createDogQuotes({ isDeductible, deductibleAmount, price }: DogQuote) {
    return this.network.post<{ id: string; price: number }>(
      '/signups/dog_liability/quotes',
      {
        isDeductible,
        deductibleAmount,
        price,
      }
    );
  }

  // TODO: Legacy endpoint
  public createQuotes(verticalId: InsuranceVertical, questionnaireId: string) {
    return this.network.post(
      `/${verticalId}/quote/for_questionnaire/${questionnaireId}`
    );
  }

  public createQuotesByQuestionnaireId(
    verticalId: InsuranceVertical,
    questionnaireId: string
  ) {
    return this.network.post(`/${verticalId}/quotes`, {
      questionnaireId,
    });
  }

  public updateUserLangugae(language: Locale) {
    return this.network.patch('/user/language', { language });
  }

  public getMailingPreferences() {
    return this.network.get<MailingListResponse>(
      '/customerio/subscription_preferences'
    );
  }

  public updateMailingPreferences(options: UpdateMailingPreference) {
    return this.network.put<MailingListResponse>(
      '/customerio/subscription_preferences',
      options
    );
  }

  public getAppleWalletPassLink(payload: { policyId: string }) {
    return this.network.post('/wallet/apple', payload);
  }

  public getGoogleWalletPassLink(payload: { policyId: string }) {
    return this.network.post('/wallet/google', payload);
  }

  public getMyPolicies() {
    return this.network.get<Policy[]>('/me/policies');
  }

  public getPolicyDetail(policyId: string) {
    return this.network.get<Policy>(`/me/policies/${policyId}`);
  }

  public getPolicyCancellation(policyId: string) {
    return this.network.get<OptionalPolicyAttributes>(
      `/me/policies/${policyId}/preview_cancelation`
    );
  }

  public cancelPolicy(
    policyId: string,
    reason?: PolicyCancellationReason,
    additionalInfo?: string
  ) {
    return this.network.post(`/me/policies/${policyId}/cancel`, {
      reason,
      additionalInfo,
    });
  }

  public setCheckoutStarted(insuranceType: InsuranceTypes, email: string) {
    return this.network.post('/customerio/checkout_started', {
      email,
      insuranceType,
    });
  }

  public markPolicyDroppedOut(policyId: string) {
    return this.network.post(`/me/policies/${policyId}/mark_dropped_out`);
  }

  public getClaims() {
    return this.network.get<Claim[]>('/me/claims');
  }

  public getClaimsByInsurance(insuranceType: InsuranceTypes) {
    return this.network.get(`/me/claims/by_insurance_type/${insuranceType}`);
  }

  public getCurrentClaim(claimId: string) {
    return this.network.get<Claim>(`/me/claims/${claimId}`);
  }

  public submitClaim(
    claimInfo:
      | ClaimSubmitInfo
      | LiabilitySubmitInfo
      | DentalSubmitInfo
      | HouseholdClaimsSubmitInfo
      | PrivateHealthClaimsSubmitInfo
  ) {
    return this.network.post<Claim>('/me/claims', claimInfo);
  }

  public updateClaimDocuments = (
    claimId: string,
    uploadedDocumentTokens: string[]
  ) => {
    return this.network.patch<Claim>(`/me/claims/${claimId}`, {
      uploadedDocumentTokens,
    });
  };

  public submitExpatQuoteInformation(
    questionnaireResult: Partial<ExpatSubmittableQuestionnaire>,
    questionnaireType: ExpatQuestionnaireType
  ) {
    const source = getTrackingObject();
    return this.network.post<{ id: string }>('/questionnaires/', {
      answers: questionnaireResult,
      questionnaireType,
      metadata: { source },
    });
  }

  public submitDogQuoteInformation(
    questionnaireResult: DogSubmittableQuestionnaire,
    meta: DogMeta
  ) {
    const source = getTrackingObject();
    return this.network.post<{ id: string }>('/questionnaires/', {
      answers: questionnaireResult,
      questionnaireType: 'DOG_LIABILITY',
      metadata: { source, ...meta },
    });
  }

  public createLifeQuote(quote: LifeQuote) {
    return this.network.post<{ id: string; price: number }>(
      '/signups/life/quotes',
      quote
    );
  }

  public createBikeQuestionnaire(
    answers:
      | BikePreQuoteQuestionnaire
      | BikePostQuoteQuestionnaire
      | BikeEmailNotificationQuestionnaire,
    questionnaireType: BikeQuestionnaireType,
    labels?: BikeQuestionnaireLabels,
    uploadTokens?: string[]
  ) {
    const source = getTrackingObject();
    return this.network.post<{ id: string }>('/questionnaires/', {
      answers,
      uploadTokens,
      questionnaireType,
      metadata: { source, ...labels },
    });
  }

  public createLiabilityQuestionnaire(
    answers: LiabilityAnswers,
    labels: LiabilityQuestionnaireLabels
  ) {
    const source = getTrackingObject();
    return this.network.post<{ id: string }>('/questionnaires/', {
      answers,
      questionnaireType: 'LIABILITY',
      metadata: { source, ...labels },
    });
  }

  public createStudentChecklistUpload(userPolicyId: string, filename: string) {
    return this.network.post<{ id: string; presignedUrl: string }>(
      '/me/student_checklist_uploads/',
      { userPolicyId, filename }
    );
  }

  public updateStudentChecklist(
    userPolicyId: string,
    checklist?: StudentChecklistRequest
  ) {
    return this.network.patch<
      StudentChecklistRequest,
      AxiosResponse<StudentChecklistResponse>
    >(`/me/policies/${userPolicyId}/student_checklist`, checklist);
  }

  public uploadStudentChecklistDocument(presignedUrl: string, file: File) {
    return this.network.put(presignedUrl, file, {
      timeout: 0,
      withCredentials: false,
    });
  }

  public completeStudentChecklist(userPolicyId: string) {
    return this.network.post<StudentChecklistRequest>(
      `/me/policies/${userPolicyId}/complete_student_checklist`
    );
  }

  public getPaymentMethods() {
    return this.network.get<PaymentMethod[]>('/me/payment_methods/');
  }

  public startPaymentMethodSetup() {
    return this.network.post<SetupIntentResponse>(
      '/me/payment_methods/setup_intent'
    );
  }

  public async confirmCardPaymentSetup(
    stripe: Stripe,
    clientSecret: string,
    cardSetupData: CardSetupData
  ) {
    const payload: ConfirmCardSetupData = {
      payment_method: {
        card: cardSetupData.cardElement,
        billing_details: {
          name: cardSetupData.cardHolderName,
          address: { postal_code: cardSetupData.postalCode },
        },
      },
    };

    const { setupIntent, error } = await stripe.confirmCardSetup(
      clientSecret,
      payload
    );

    if (error?.message) {
      throw error;
    } else if (error || !setupIntent) {
      if (!setupIntent) {
        Sentry.captureException(
          'Empty setupIntent returned from stripe.confirmCardSetup'
        );
      }

      throw new Error('We were unable to add this payment method');
    }

    return setupIntent;
  }

  public async confirmSepaPaymentSetup(
    stripe: Stripe,
    clientSecret: string,
    sepaSetupData: SepaSetupData
  ) {
    const payload: ConfirmSepaDebitSetupData = {
      payment_method: {
        sepa_debit: {
          iban: sepaSetupData.iban,
        },
        billing_details: {
          name: sepaSetupData.accountHolderName,
          email: sepaSetupData.accountHolderEmail,
          ...(sepaSetupData.address ? { address: sepaSetupData.address } : {}),
        },
      },
    };

    const { setupIntent, error } = await stripe.confirmSepaDebitSetup(
      clientSecret,
      payload
    );

    if (error?.message) {
      throw error;
    } else if (error || !setupIntent) {
      if (!setupIntent) {
        Sentry.captureException(
          'Empty setupIntent returned from stripe.confirmSepaDebitSetup'
        );
      }

      throw new Error('We were unable to add this payment method');
    }

    return setupIntent;
  }

  public createPaymentMethodSetup(
    setupIntent: SetupIntent,
    makeDefault: boolean,
    paymentType: PaymentType,
    iban?: string,
    address?: StripeBillingAddress
  ) {
    return this.network.post<PaymentMethod>('/me/payment_methods/', {
      stripeId: setupIntent.payment_method,
      makeDefault,
      type: paymentType,
      iban,
      ...(address ? { address } : {}),
    });
  }

  public async addCardPaymentMethod(
    stripe: Stripe,
    cardSetupData: CardSetupData
  ) {
    const {
      data: { clientSecret },
    } = await this.startPaymentMethodSetup();

    const setupIntent = await this.confirmCardPaymentSetup(
      stripe,
      clientSecret,
      cardSetupData
    );

    return this.createPaymentMethodSetup(
      setupIntent,
      cardSetupData.makeDefault,
      'CARD'
    );
  }

  public async addSepaPaymentMethod(
    stripe: Stripe,
    sepaSetupData: SepaSetupData
  ) {
    const {
      data: { clientSecret },
    } = await this.startPaymentMethodSetup();

    const setupIntent = await this.confirmSepaPaymentSetup(
      stripe,
      clientSecret,
      sepaSetupData
    );

    return this.createPaymentMethodSetup(
      setupIntent,
      sepaSetupData.makeDefault,
      'SEPA',
      sepaSetupData.iban,
      sepaSetupData.address
    );
  }

  public deletePaymentMethod(paymentMethodId: string) {
    return this.network.delete(`/me/payment_methods/${paymentMethodId}`);
  }

  public makePaymentMethodDefault(paymentMethodId: string) {
    return this.network.patch<PaymentMethod[]>(
      `/me/payment_methods/${paymentMethodId}/make_default`
    );
  }

  public validatePostcode(insuranceType: InsuranceTypes, postcode: string) {
    return this.network.post('/validate_postcode', {
      insuranceType,
      postcode,
    });
  }

  public retrieveUploadDocumentDetails(filename: string) {
    return this.network.post('/uploads', { filename });
  }

  public uploadFile(
    file: File,
    fileType: string,
    uploadUrl: string,
    onUploadProgress?: (p: { loaded: number; total: number }) => void
  ) {
    return this.network.put(uploadUrl, file, {
      timeout: 0,
      onUploadProgress,
      headers: {
        'Content-Type': fileType,
      },
      withCredentials: false,
    });
  }

  public uploadQuestionnaireDocument(filename: string, questionId?: string) {
    return this.network.post('/questionnaires/uploads', {
      filename,
      questionId: questionId || filename,
    });
  }

  public fetchHealthCardDetails = (healthCardId: string) =>
    this.network.get<HealthCard>(`/health_cards/${healthCardId}`);

  public updateHealthCardAddress = (payload: UpdateHealthCardAddress) => {
    const { id, address } = payload;
    return this.network.patch(`/health_cards/${id}/address`, address);
  };

  public fetchPhotoUploadToken = (id: string) => {
    return this.network.post<
      string,
      AxiosResponse<FetchPhotoUploadTokenResponse> | AxiosError
    >(`/health_cards/${id}/generate_photo_upload_token`);
  };

  public createHealthCardPhotoUpload = (
    payload: CreateHealthCardPhotoUploadPayload
  ) => {
    const { token, fileExtension } = payload;
    return this.network.post<
      string,
      AxiosResponse<CreateHealthCardPhotoUploadResponse>
    >('/health_card_photos', {
      token,
      fileExtension,
    });
  };

  public markHealthCardPhotoUploaded = (photoId: string, token: string) =>
    this.network.post<string, AxiosResponse<MarkUploadedResponse>>(
      `/health_card_photos/${photoId}/mark_uploaded`,
      {
        token,
      }
    );

  public approveHealthCardPhoto = (photoId: string) =>
    this.network.post(`/health_card_photos/${photoId}/approve`);

  public finalizeHealthCard = (healthCardId: string) =>
    this.network.post(`/health_cards/${healthCardId}/finalize`);

  public markHealthCardAsReceived = (policyId: string) =>
    this.network.post('/health_cards/mark_received', {
      policyId,
    });

  public createHealthCardRecord = (policyId: string) =>
    this.network.post('/health_cards', {
      policyId,
    });

  public triggerLegalPhoneConsultationRequestEvent = (policyId: string) =>
    this.network.post(`/claims/legal/phone_consultation/${policyId}`);

  // Signups-backend apis ------------------------------------------------------

  public liabilityCreateQuote(inclusiveMembers: InclusiveMember[]) {
    return this.network.post<{ id: string; price: number }>(
      `/signups/liability/quotes`,
      {
        inclusiveMembers,
      }
    );
  }

  // Recommendation tool 2.0 ------------------------------------------------------
  public createRecommendationPersona(persona: RecommendationPersonaRequest) {
    return this.network.post<
      RecommendationPersonaRequest,
      AxiosResponse<RecommendationPersonaResponse> | AxiosError
    >('/recommendation-tool/persona', persona);
  }

  public createRecommendationSnapshot(data: RecommendationSnapshotRequest) {
    return this.network.post<
      RecommendationSnapshotRequest,
      AxiosResponse<RecommendationSnapshotResponse> | AxiosError
    >('/recommendation-tool/snapshot', data);
  }

  public getRecommendationById(recommendationId: string) {
    return this.network.get(
      `/recommendation-tool/recommendation/${recommendationId}`
    );
  }

  public getIntercomIdentityHash(): Promise<
    AxiosResponse<IdentityHashResponse>
  > {
    return this.network.post('/me/intercom_identity_hash');
  }
}

const client = new Client();

export default client;

// Expose apiClient when run in Cypress
if (isRunningOnCypress()) {
  window.apiClient = client;
}
