import {
  PartialQuestionnaire,
  RoutesObject,
} from 'models/questionnaireFramework';

import { camelCaseToKebabCase } from '../camelCaseToKebapCase';

export const getRoutes = <Questionnaire extends Object, GroupId extends string>(
  questionnaire: PartialQuestionnaire<Questionnaire, GroupId>,
  prefix?: string
): RoutesObject<Questionnaire> => {
  const routes = {} as RoutesObject<Questionnaire>;
  for (const questionId in questionnaire) {
    const id = questionId as keyof Questionnaire;
    const question = camelCaseToKebabCase(questionId);
    const groupId = questionnaire[id]?.groupId;
    const group = groupId ? camelCaseToKebabCase(groupId) : '';
    const requiresAuth: boolean = questionnaire[id]?.requiresAuth ?? false;

    routes[id] = {
      path: `${prefix ? `${prefix}/` : ''}${
        group ? `${group}/` : ''
      }${question}`,
      requiresAuth,
    };
  }
  return routes;
};
