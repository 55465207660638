import PageNotFound from 'components/pageNotFound';
import routes from 'constants/routes';
import { TAllowedInsuranceTypes } from 'features/recommendationTool/models/recommendation';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { OverviewPageContainer } from './view/OverviewPage.container';
import { QuestionnaireWrapper } from './view/QuestionnaireWrapper';
import { ResultPage } from './view/ResultPage';
import { WelcomeScreen } from './view/WelcomeScreen';

interface MatchParams {
  insuranceType: TAllowedInsuranceTypes;
}
export type InsuranceTypeRouteProps = RouteComponentProps<MatchParams>;

export const InsuranceToolRoutes = () => {
  return (
    <Switch>
      <Route
        exact={true}
        component={OverviewPageContainer}
        path={routes.insuranceTool.path}
      />
      <Route
        exact={true}
        component={WelcomeScreen}
        path={routes.insuranceTool.intro.path}
      />
      <Route
        component={QuestionnaireWrapper}
        path={routes.insuranceTool.questionnaire.path}
      />
      <Route
        component={ResultPage}
        path={routes.insuranceTool.recommendation.path}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
};
