import type { ClaimsAction, RequestAction } from 'constants/actions';
import { InsuranceTypes } from 'models/insurances/types';
import { PartialQuestionnaire } from 'models/questionnaireFramework';
import type { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import type { TFunction } from 'shared/i18n';
import { UploadedFile } from 'shared/models/types';
import { ZUploadedDocumentsSchema } from 'shared/models/validations';
import { z } from 'zod';

export type Questionnaire = PartialQuestionnaire<PrivateHealthClaims, GroupId>;
export type QuestionId = keyof PrivateHealthClaims;

export type GroupId = 'questionnaire';

const PrivateHealthClaimTypeArray = [
  'COST_PLAN',
  'DOCTORS_VISIT',
  'SPECIALIST_VISIT',
  'DENTAL',
  'MEDICATION',
  'AIDS',
  'PHYSICAL_OR_SPEECH_THERAPY',
  'HOSPITAL_TREATMENT',
  'NON_MEDICAL_PROFESSIONAL',
  'OTHER',
] as const;
export type PrivateHealthClaimType = typeof PrivateHealthClaimTypeArray[number];

export const claimTypeMapping = (
  t: TFunction
): {
  [K in PrivateHealthClaimType]:
    | string
    | { title: string; description: string };
} => ({
  COST_PLAN: {
    title: t(
      'claims.privateHealth.questionnaire.claimType.costPlan.title',
      'Cost plan'
    ),
    description: t(
      'claims.privateHealth.questionnaire.claimType.costPlan.description',
      'Receive a coverage estimate for your planned treatment'
    ),
  },
  DOCTORS_VISIT: {
    title: t(
      'claims.privateHealth.questionnaire.claimType.doctorsVisit.title',
      "General doctors' visit"
    ),
    description: t(
      'claims.privateHealth.questionnaire.claimType.doctorsVisit.description',
      'General practitioners, eye doctors, gynecologists, pediatricians'
    ),
  },
  SPECIALIST_VISIT: t(
    'claims.privateHealth.questionnaire.claimType.specialistVisit.title',
    "Specialists' visit"
  ),
  DENTAL: t(
    'claims.privateHealth.questionnaire.claimType.dental.title',
    'Dental'
  ),
  MEDICATION: t(
    'claims.privateHealth.questionnaire.claimType.medication',
    'Medication'
  ),
  AIDS: {
    title: t('claims.privateHealth.questionnaire.claimType.aids.title', 'Aids'),
    description: t(
      'claims.privateHealth.questionnaire.claimType.aids.description',
      'Vision aids, hearing aids, bandages, prostheses'
    ),
  },
  PHYSICAL_OR_SPEECH_THERAPY: t(
    'claims.privateHealth.questionnaire.claimType.physicalOrSpeechTherapy.title',
    'Physical or speech therapy'
  ),
  HOSPITAL_TREATMENT: {
    title: t(
      'claims.privateHealth.questionnaire.claimType.hospitalTreatment.title',
      'Hospital treatment'
    ),
    description: t(
      'claims.privateHealth.questionnaire.claimType.hospitalTreatment.description',
      'Emergency, outpatient, inpatient'
    ),
  },
  NON_MEDICAL_PROFESSIONAL: t(
    'claims.privateHealth.questionnaire.claimType.nonMedicalProfessional.title',
    'Non-medical professional'
  ),
  OTHER: t(
    'claims.privateHealth.questionnaire.claimType.OtherOption.title',
    'Other'
  ),
});

export interface PrivateHealthClaims {
  intro: boolean;
  claimTypes: PrivateHealthClaimType[];
  uploadedDocumentTokens: UploadedFile[];
  year: string;
  amount: number;
  processing: boolean;
  submitted: boolean;
}

export interface PrivateHealthClaimsMetaData {
  policyId: string;
}

export type PrivateHealthClaimsDispatch = ThunkDispatch<
  AppState,
  unknown,
  RequestAction | ClaimsAction
>;

export interface PrivateHealthClaimsSubmitInfo {
  type: InsuranceTypes;
  userPolicyId: string;
  claimTypes: Array<{ claimType: PrivateHealthClaimType }>;
  uploadedDocumentTokens: string[];
  amount: number;
  year: string;
}

export const ZPrivateHealthClaimsSubmissionSchema = z.object({
  claimTypes: z.array(z.enum(PrivateHealthClaimTypeArray)),
  uploadedDocumentTokens: z.array(ZUploadedDocumentsSchema),
  amount: z.number(),
  year: z.string(),
});
