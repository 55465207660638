import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { Input } from '@popsure/dirty-swan';
import { useEffect, useState } from 'react';

type Entries<T> = {
  [K in keyof T]: [K, { placeholder: string }];
}[keyof T][];

export const MultiNumberInput = <
  Questionnaire extends QuestionnaireQuestions,
  MultiInputValue extends Record<string, number>,
  MultiInputData = Record<keyof MultiInputValue, { placeholder: string }>
>({
  data,
  value: defaultValue,
  onSubmitValue,
  setValidForSubmission,
  featureName,
  validate,
  question,
}: {
  data: MultiInputData;
  validate?: (value: Partial<MultiInputValue>) => boolean;
} & QuestionnaireFormProps<Questionnaire, Partial<MultiInputValue>>) => {
  const [value, setValue] = useState<Partial<MultiInputValue>>(
    defaultValue ?? {}
  );

  const setInputValues = <T extends keyof MultiInputValue>(k: T, v: number) => {
    setValue((prevValue) => ({ ...prevValue, [k]: v }));
  };

  const handleSubmit = () => {
    if (!value) {
      throw new Error(
        `[${featureName}] Input value is missing for submission for question: ${question}`
      );
    }
    onSubmitValue(value);
  };

  useEffect(() => {
    const isValid = value && (validate ? validate(value) : true);
    setValidForSubmission(Boolean(isValid));
  });

  const entries = Object.entries(
    data as Record<string, unknown>
  ) as Entries<MultiInputValue>;

  return (
    <Header onSubmit={handleSubmit}>
      <div className="d-flex fd-column r-gap8">
        {entries.map(([key, element]) => {
          return (
            <Input
              key={String(key)}
              placeholder={element.placeholder}
              type="number"
              required
              value={value[key]}
              onChange={(e) => setInputValues(key, Number(e.target.value))}
              data-cy="input-text"
            />
          );
        })}
      </div>
    </Header>
  );
};
