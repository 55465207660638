import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { createQuote } from 'features/dental/actions';
import { useEffect, useRef } from 'react';
import { useSafeTranslation } from 'shared/i18n';
import { Processing } from 'shared/pages/Processing';

import { Dental } from '../../models';

const TIME_OUT = 1_000;

export const QuoteProcessing = ({
  onSubmitValue,
  questionnaireAnswers,
}: QuestionnaireFormProps<Dental>) => {
  const { t } = useSafeTranslation();
  const loaded = useRef(false);

  useEffect(() => {
    const submitInfo = async (loadedRef: { current: boolean }) => {
      if (loadedRef.current) {
        return;
      }
      const { dateOfBirth, publicInsurer } = questionnaireAnswers;

      if (!dateOfBirth || !publicInsurer) {
        return null;
      }

      try {
        const { data: quotes } = await createQuote(
          dateOfBirth,
          publicInsurer.value
        );
        onSubmitValue([...quotes]);
      } catch (e) {
        return null;
      }
      loadedRef.current = true;
    };

    let id: ReturnType<typeof setTimeout>;
    if (loaded.current === false) {
      id = setTimeout(() => {
        submitInfo(loaded);
      }, TIME_OUT);
    }
    return () => {
      clearTimeout(id);
    };
  }, [loaded, onSubmitValue, questionnaireAnswers]);

  const textList: string[] = [
    t(
      'dental.qnr.preQuote.quote.processing.loadingText',
      'Preparing your options'
    ),
  ];

  const description: string = t(
    'dental.qnr.preQuote.quoteProcessing.description',
    "We're preparing the best options based on the answers you provided."
  );

  return (
    <Processing
      onLoadComplete={onSubmitValue}
      textList={textList}
      description={description}
    />
  );
};
