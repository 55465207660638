import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { BottomOrRegularModal, Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { info } from 'features/recommendationTool/assets/icons';
import { VerticalCategory } from 'features/recommendationTool/models/recommendation';
import { getOverviewVerticalRoute } from 'features/recommendationTool/utils/getOverviewVerticalRoute';
import { RecommendationGroupVerticalData } from 'features/recommendationTool/utils/groupRecommendationVerticals';
import { RecommendationDetails } from 'models/recommendationTool';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import styles from './OverviewModal.module.scss';

export const OverviewModal = ({
  closeModal,
  verticalData,
  recommendation,
  vertical,
}: {
  closeModal: () => void;
  vertical?: VerticalCategory;
  verticalData?: RecommendationGroupVerticalData;
  recommendation?: RecommendationDetails;
}) => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const insuranceType = verticalData?.insuranceType;
  const formattedVerticalPrice = verticalData?.price
    ? englishFormattedEuroCurrency(verticalData.price)
    : undefined;

  return (
    <BottomOrRegularModal isOpen={!!insuranceType} onClose={closeModal}>
      <div className={`p24 pt0 ws8 ${styles.modalContainer}`}>
        {insuranceType && verticalData && (
          <div className="ta-center">
            <img
              className="br8"
              width="112px"
              height="112px"
              src={verticalData.icon}
              srcSet={verticalData.iconSrcSet}
              alt={vertical}
            />
            <h2 className="p-h2 ta-center mt16 mb8 w100">
              {verticalData.title}
            </h2>

            <p className="p-h4 fw-normal tc-grey-500 mb16">
              {formattedVerticalPrice ? (
                <>
                  {vertical === 'TRAVEL'
                    ? `from ${formattedVerticalPrice} ${t(
                        'recommendationtool.recommendation.pricePerYear',
                        '/year'
                      )}`
                    : `${formattedVerticalPrice} ${t(
                        'recommendationtool.recommendation.pricePerMonth',
                        '/mo'
                      )}`}
                </>
              ) : (
                t(
                  'recommendationtool.recommendation.priceVaries',
                  'price varies'
                )
              )}
            </p>

            <ReactMarkdown
              className="p-p ta-left tc-grey-600"
              source={verticalData.description}
              renderers={{
                link: ({ href, children }) => (
                  <a
                    href={href}
                    className="p-a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {children}
                  </a>
                ),
              }}
            />

            <div className={`mt24 jc-center gap16 ${styles.buttonWrapper}`}>
              {verticalData.enabled && (
                <Button
                  data-cy="overview-modal-cta"
                  className={classNames('mb16', styles.button)}
                  onClick={() =>
                    history.push(
                      getOverviewVerticalRoute(insuranceType, recommendation)
                    )
                  }
                >
                  {verticalData?.key === 'HEALTH_INSURANCE'
                    ? 'Compare health insurances'
                    : 'In-depth recommendation'}
                </Button>
              )}

              {verticalData.quotePage && (
                <a
                  className={classNames('p-btn--primary', styles.button)}
                  href={verticalData.quotePage}
                  target="_blank"
                  rel="noreferrer"
                >
                  Get a quote
                </a>
              )}
            </div>

            {!verticalData.enabled && (
              <div className="d-flex ai-center jc-center p-h4 mt24 mb16 tc-grey-500">
                <img
                  className="mr8"
                  width="16px"
                  height="16px"
                  src={info}
                  alt=""
                  aria-hidden
                />
                In-depth recommendation coming soon
              </div>
            )}
          </div>
        )}
      </div>
    </BottomOrRegularModal>
  );
};
