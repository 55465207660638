import { useEffect, useState } from 'react';

import { DummyPage } from './DummyComponents';

const DEFAULT_THRESHOLD_IN_MILLISECONDS = 0;

export const LoadingSkeleton = ({
  showSkeleton = true,
  showOnlyAfter = DEFAULT_THRESHOLD_IN_MILLISECONDS,
}: {
  showSkeleton?: boolean;
  showOnlyAfter?: number;
}) => {
  const [isAfterThreshold, setIsAfterThreshold] = useState(showOnlyAfter === 0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAfterThreshold(true);
    }, showOnlyAfter);

    return () => clearTimeout(timeout);
  }, [showOnlyAfter]);

  if (showSkeleton && isAfterThreshold) {
    return <DummyPage />;
  }

  return null;
};
