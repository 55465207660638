import { Button } from '@popsure/dirty-swan';
import routes from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import { emptyList } from '../../assets';

type EmptyStateProps = {
  showRecommendation?: boolean;
};

const EmptyState = ({ showRecommendation = true }: EmptyStateProps) => {
  const { t } = useSafeTranslation();
  const history = useHistory();

  return (
    <div className="ta-center d-flex ai-center fd-column">
      <img src={emptyList} className="ws2 mb16" aria-hidden alt="" />
      <h2 className="w100 p-h2" data-testid="my-policies-no-active-policies">
        {t(
          'policies.dashboard.emptyState.title',
          'You don‘t have any policies'
        )}
      </h2>
      <div className="ws4">
        <Button
          className="w100 mt16 mb16"
          onClick={() => history.push(routes.me.policies.list.path)}
        >
          {t('policies.dashboard.emptyState.seeProducts', 'See all products')}
        </Button>
        {showRecommendation && (
          <Button
            variant="filledWhite"
            className="w100"
            onClick={() => history.push(routes.insuranceTool.path)}
          >
            {t(
              'policies.dashboard.emptyState.recommendationTool',
              'Get a recommendation'
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export { EmptyState };
