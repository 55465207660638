import { insurance } from '@getpopsure/private-constants';
import routes from 'constants/routes';
import {
  bannerColorMapping,
  imageSrcSetTypeMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';

import { CardText, CategoryLookup, Section } from '../models/lookups';
import { typeformLinkWithVertical } from './shared';

export const dentalCategory: CategoryLookup = {
  title: 'Dental insurance',
  shortTitle: 'Dental',
  excerpt: 'Comprehensive dental coverage',
  description:
    'Dental insurance is an extension for public health insurance and covers the additional needs you might have for your teeth (with no waiting times). This includes high-quality fillings, surgeries for root canals, and professional cleanings. We also offer an advanced plan that covers teeth replacement surgeries, and has a whitening allowance.',
  icon: imageTypeMapping.DENTAL,
  iconSrcSet: imageSrcSetTypeMapping.DENTAL,
  bannerColor: bannerColorMapping.DENTAL,
  landingPage: insurance.dental.landingPage,
  quotePage: routes.policies.dental.path,
  enabled: true,
};

export const dentalSection: Section = {
  title: '',
  description:
    'Dental insurance is recommended for anyone on public health insurance because, even with a basic plan, you’ll get more than you pay in if you go to the dentist at least once per year for a professional cleaning.',
  icon: '',
  status: {
    ELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.dental.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('public'),
      },
    },
    PARTIAL: {
      cta: {
        caption: 'Learn more',
        href: insurance.dental.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('public'),
      },
    },
    MORE_INFO_REQUIRED: {
      cta: {
        caption: 'Learn more',
        href: insurance.dental.landingPage,
      },
    },
    INELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.dental.landingPage,
      },
    },
  },
};

export const dentalCardText: CardText = {
  DENTAL_PUBLIC_REQUIRED_PARTIAL: {
    title: 'You need public health insurance',
    description:
      'Dental insurance is an add-on to public health insurance and can only be purchased when you have a German public health insurance provider.',
  },
  /**
   * @deprecated
   *
   * This key is deprecated and will be removed in the future.
   * Use DENTAL_PROFESSIONAL_CLEANING_PRO instead.
   */
  DENTAL_UNLIMITED_PROFESSIONAL_CLEANING_PRO: {
    title: 'Professional cleanings',
    description:
      "It's very unlikely that your public health insurance provider will cover any cleanings. Instead, they'll cover a routine check-up. If you need any treatments or cleanings, public health insurance will cover any pain medication you need while dental insurance would cover the rest. That includes professional cleanings, high-quality fillings, etc.",
  },
  DENTAL_PROFESSIONAL_CLEANING_PRO: {
    title: 'Professional cleanings',
    description:
      "It's very unlikely that your public health insurance provider will cover any cleanings. Instead, they'll cover a routine check-up. If you need any treatments or cleanings, public health insurance will cover any pain medication you need while dental insurance would cover the rest. That includes professional cleanings, high-quality fillings, etc.",
  },
  DENTAL_PUBLIC_DOES_NOT_COVER_PRO: {
    title: "Public insurance doesn't cover most treatments",
    description:
      "Most public health insurance providers assume that you'll get dental insurance if you need it. That's why only the most basic of procedures are covered. For example, pain medications and low-quality cavity fillings that often need to be replaced more often than high-quality fillings.",
  },
  DENTAL_COVERS_CHILDREN_TREATMENT_PRO: {
    title: 'Covers orthodontic treatments for children',
    description:
      "Dental insurance will cover orthodontic treatments for children (as long as they have their own plan). You'll need to make sure they sign up when they're younger since there are limitations for the first two years of being on the plan. Treatment must also be started before the child is 21.",
  },
  DENTAL_TEETH_WHITENING_PRO: {
    title: 'If you need teeth whitening',
    description:
      "With the advanced plan, you'll get an allowance for €200 for whitening procedures every two years.",
  },
  DENTAL_CHOOSE_DENTIST_PRO: {
    title: 'Choose your dentist',
    description:
      "You're able to pick which dentist you want to go to. Just make sure that their intensity factor for their dental cleanings is 3.5 or less (you can ask at the reception).",
  },
  DENTAL_EUROPE_WIDE_COVER_PRO: {
    title: 'Europe-wide coverage',
    description:
      "Need to get a dental treatment done while in another EU country? You're covered (as long as you're still with a German public health insurance provider).",
  },
  DENTAL_ONGOING_NOT_COVERED_CON: {
    title: 'On-going or scheduled dental treatments are not covered',
    description: `If you have any on-going treatments, they can't be covered.
    For example, if your teeth hurt, and you're having problems eating, you can get dental insurance to get a check-up and treatment afterwards.
    What's not covered: If your teeth hurt, you go to the dentist, you're recommended a treatment, and then get dental insurance. This is an on-going treatment.
    Our advice is to get dental insurance before going to the dentist for any problems.`,
  },
  DENTAL_ANNUAL_LIMITS_CON: {
    title: 'Annual limits apply',
    description:
      'There are more limitations for basic than there are for advanced, but you might have to wait for unlimited coverage. The only exception is if you need treatment due to an accident like falling down or eating something hard and hurting yourself.',
  },
};
