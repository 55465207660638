import { TFunction } from '@getpopsure/i18n-react';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { FAQInfo } from 'components/faq';

export const faqQuestions = (t: TFunction): FAQInfo[] => [
  {
    questionId: 1,
    question: t(
      'page.household.quote.faq.1.question',
      'Do I have to insure my entire house or flat?'
    ),
    answer: t(
      'page.household.quote.faq.1.answer',
      'Yes. Unfortunately, single rooms cannot be insured. If you live in a shared household, you will need to have one policy to cover all the belongings and can split the cost with the household members.'
    ),
  },
  {
    questionId: 2,
    question: t(
      'page.household.quote.faq.2.question',
      "What's the difference between household contents insurance, home contents insurance, and personal property insurance?"
    ),
    answer: t(
      'page.household.quote.faq.2.answer',
      'Household insurance, household contents insurance, home contents insurance, and personal property insurance all refer to the same type of insurance provided on this page.\n\nThis is different from homeowners insurance, or Wohngebäudeversicherung, which applies to the actual building.'
    ),
  },
  {
    questionId: 3,
    question: t('page.household.quote.faq.3.question', 'Is it mandatory?'),
    answer: t(
      'page.household.quote.faq.3.answer',
      "No, household contents insurance in Germany isn't required by law. Still, some landlords may have it as a requirement in your rental contract."
    ),
  },
  {
    questionId: 4,
    question: t('page.household.quote.faq.4.question', 'What are the copays?'),
    answer: t(
      'page.household.quote.faq.4.answer',
      'There are no copays, but certain areas of coverage come with limits to what you can get back. See below.'
    ),
  },
  {
    questionId: 5,
    question: t(
      'page.household.quote.faq.5.question',
      'Are there limits to what I can get back?'
    ),
    answer: t('page.household.quote.faq.5.answer', {
      defaultValue:
        "Yes, some limits apply to different types of damages depending on whether you're on the Basic or Premium plan.\n\nIf your stuff is stolen from your garden area it could still be covered but only up to {{coverageLimit}} depending on the type of coverage you signed up for. If rain water is the cause for a claim there is a limit of {{rainwaterLimit}} per claim with a {{copay}} co-pay.\n\nCheck out the full policy summary table for more information.",
      coverageLimit: englishFormattedEuroCurrency(5000, true),
      rainwaterLimit: englishFormattedEuroCurrency(5000, true),
      copay: englishFormattedEuroCurrency(500, true),
    }),
  },
  {
    questionId: 6,
    question: t(
      'page.household.quote.faq.6.question',
      'Will I be reimbursed for the current value or the price when purchased when I file a claim?'
    ),
    answer: t(
      'page.household.quote.faq.6.answer',
      "You'll get reimbursed for the price of your valuables when they were in **new condition** and not when they were damaged or stolen."
    ),
  },
  {
    questionId: 7,
    question: t(
      'page.household.quote.faq.7.question',
      'What are coverage restrictions?'
    ),
    answer: t(
      'page.household.quote.faq.7.answer',
      'Coverage restrictions are instances in which insurance coverage may be limited. Events which are always excluded include war, nuclear energy, wet and dry rot, storm surge, flooding, backwater, groundwater, earthquake, ground subsidence, landslide, snow pressure, avalanches, volcanic eruptions, and intentionally caused damage.'
    ),
  },
  {
    questionId: 8,
    question: t(
      'page.household.quote.faq.8.question',
      'Are there waiting periods?'
    ),
    answer: t(
      'page.household.quote.faq.8.answer',
      "No, there's no waiting period for using your household contents insurance in Germany. Your policy is effective immediately from the start date. Events that happened before the start date of the insurance are not covered."
    ),
  },
  {
    questionId: 9,
    question: t('page.household.quote.faq.9.question', 'How can I cancel?'),
    answer: t(
      'page.household.quote.faq.9.answer',
      "You can end your contract at any time, and it's effective as soon as you cancel or at a later date that you can specify."
    ),
  },
];
