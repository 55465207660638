import Session from '@getpopsure/session';
import { signOut as onSignOut } from 'actions/session';
import AuthenticatedRoute from 'components/authenticatedRoute';
import { connect } from 'react-redux';
import { AppState } from 'reducers';

/**
 * @deprecated - Use RouteWithAuthentication component instead (components/RouteWithAuthentication)
 */
const mapStateToProps = (state: AppState) => {
  return {
    isAuthenticated: Session.isAuthenticated,
    userEmail: state.user.accountInfo?.email,
  };
};

const mapDispatchToProps = {
  onSignOut,
};

export interface StateProps {
  isAuthenticated: boolean;
}

export interface OwnProps {
  component: any;
  path: string;
  exact: boolean;
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute);
