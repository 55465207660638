import routes from 'constants/routes';

export const isSignupPath = (path: string) => {
  if (
    path.startsWith(routes.policies.path) ||
    path.startsWith(routes.account.signIn.path)
  ) {
    return true;
  }

  return false;
};
