import routes from 'constants/routes';
import type { ViewProps } from 'features/claims/containers/legalClaim/processing';
import { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

const TIMEOUT_TIME = 4_000;

const Processing = ({ policyId, history }: ViewProps) => {
  const { t } = useSafeTranslation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      history.push(
        generatePath(routes.me.claims.legalClaim.confirmation.path, {
          policyId,
        })
      );
    }, TIMEOUT_TIME);

    return () => clearTimeout(timeoutId);
  }, [policyId, history]);

  return (
    <div className="p-body">
      <div className={styles.container}>
        <div className={`ds-spinner ds-spinner__m ${styles.spinner}`} />
        <div className={`p-h2 mt24 ${styles['text-appear']}`}>
          {t('claims.legal.processing.loadingText', 'Processing claim...')}
        </div>
      </div>
    </div>
  );
};

export default Processing;
