import { AddressModal } from 'features/policySingle/components/legacy/modals/liability/AddressModal';
import { PolicySingleModalProps } from 'features/policySingle/components/modals';

export type LiabilityModalType = 'LIABILITY_UPDATE_ADDRESS';

export const LiabilityUpdateAddressModal = (props: PolicySingleModalProps) => {
  const {
    onClose,
    policyData: {
      id,
      attributes: { questionnaireId, address },
    },
  } = props;
  return (
    <AddressModal
      isOpen
      onClose={onClose}
      policyId={id}
      questionnaireId={questionnaireId}
      address={address}
    />
  );
};
