import routes from 'constants/routes';
import { useEffect } from 'react';

const InsuranceOptions = () => {
  useEffect(() => {
    // TODO: this redirect is added on 01.09.2022, please remove it after 3 months.
    window.location.assign(routes.base.insuranceOptions.link);
  }, []);
  return null;
};

export default InsuranceOptions;
