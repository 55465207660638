import { IconCard } from 'components/IconCard';
import SectionHeader from 'components/sectionHeader';
import { useSafeTranslation } from 'shared/i18n';

import documentCrossIcon from './assets/documentCross.svg';
import headCrossIcon from './assets/headCross.svg';
import medicineIcon from './assets/medicine.svg';
import radioactiveIcon from './assets/radioactive.svg';
import styles from './WhatsNotCoveredSection.module.scss';

export const WhatsNotCoveredSection = () => {
  const { t } = useSafeTranslation();

  return (
    <section className="bg-white pt24 pb80">
      <div className={`p-container d-flex fd-column ${styles.titleContainer}`}>
        <SectionHeader
          title={t(
            'disabilityV2.quote.whatsnotcovered.title',
            'What’s not covered?'
          )}
          greyUnderline
        />
      </div>
      <div className={`p-container d-grid gap32 mt56 ${styles.cardGrid}`}>
        <IconCard
          className="p8"
          title={t(
            'disabilityV2.quote.whatsnotcovered.card.1.title',
            'Performance-based unemployment'
          )}
          iconSrc={documentCrossIcon}
          iconAlt="document with cross on it"
        >
          {t(
            'disabilityV2.quote.whatsnotcovered.card.1.description',
            'If you get fired or quit your job and are physically and mentally fit, then insurance will not cover this. It covers you if you are _incapable of working to a certain extend_ and not because you wish to remain unemployed.'
          )}
        </IconCard>
        <IconCard
          className="p8"
          title={t(
            'disabilityV2.quote.whatsnotcovered.card.2.title',
            'Short-term illnesses'
          )}
          iconSrc={medicineIcon}
          iconAlt="pills"
        >
          {t(
            'disabilityV2.quote.whatsnotcovered.card.2.description',
            'Short-term illnesses like the flu or food poisoning that last about a week or a broken wrist that lasts 6 weeks are almost always covered under health insurance and your employer’s sick leave.'
          )}
        </IconCard>
        <IconCard
          className="p8"
          title={t(
            'disabilityV2.quote.whatsnotcovered.card.3.title',
            'Occupations that aren’t covered'
          )}
          iconSrc={radioactiveIcon}
          iconAlt="radioactive sign"
        >
          {t(
            'disabilityV2.quote.whatsnotcovered.card.3.description',
            'Certain dangerous occupations aren’t covered, like working with hazardous substances, in crisis areas, at a height of more than 10 meters, in aviation, in diving, in mountain sports, in martial arts, in motor sports, and in other high-risk fields.'
          )}
        </IconCard>
        <IconCard
          className="p8"
          title={t(
            'disabilityV2.quote.whatsnotcovered.card.4.title',
            'Intentionally caused illnesses'
          )}
          iconSrc={headCrossIcon}
          iconAlt="head with cross on it"
        >
          {t(
            'disabilityV2.quote.whatsnotcovered.card.4.description',
            'Intentionally caused occupational disabilities are not covered (including self-harm and suicide).'
          )}
        </IconCard>
      </div>
    </section>
  );
};
