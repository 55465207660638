export const breedsList = [
  {
    id: 1,
    de: 'Afghane',
    en: 'Afghan',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: 2,
    de: 'Aidi',
    en: 'Aidi',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: 3,
    de: 'Akita Inu',
    en: 'Akita Inu',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: 4,
    de: 'Alano',
    en: 'Alano',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '5',
    de: 'Alaskan Malamute',
    en: 'Alaskan Malamute',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '6',
    de: 'Anglo Francais de Petite Venerie',
    en: 'Anglo Francais de Petite Venerie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '7',
    de: 'Ariegeois',
    en: 'Aregeois',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '8',
    de: 'Australian Cattle Dog',
    en: 'Australian Cattle Dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '9',
    de: 'Australian Kelpie',
    en: 'Australian Kelpie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '10',
    de: 'Australian Shepherd',
    en: 'Australian Shepherd',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '11',
    de: 'Azawakh',
    en: 'Azawakh',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '12',
    de: 'Bandog',
    en: 'Bandog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '13',
    de: 'Barbet',
    en: 'Barbet',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '14',
    de: 'Karelischer Bärenhund',
    en: 'Karelian bear dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '15',
    de: 'Barzoi',
    en: 'Barzoi',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '16',
    de: 'Basenji',
    en: 'Baseji',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '17',
    de: 'Basset Artesien-Normand',
    en: 'Basset Artesien-Normand',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '18',
    de: 'Basset Bleu de Gascogne',
    en: 'Basset Bleu de Gascogne',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '19',
    de: 'Basset d Artois',
    en: 'Basset D Artois',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '20',
    de: 'Basset Fauve de Bretagne',
    en: 'Basset Fauve de Bretagne',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '21',
    de: 'Beagle',
    en: 'beagle',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '22',
    de: 'Beagle Harrier',
    en: 'Beagle Harrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '23',
    de: 'Beauceron',
    en: 'Beauceron',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '24',
    de: 'Bergamasker',
    en: 'Bergamasker',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '25',
    de: 'Berger de Brie',
    en: 'Berger de Brie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '26',
    de: 'Berger des Pyrenees',
    en: 'Berger des Pyrenees',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '27',
    de: 'Berger Picard',
    en: 'Berger Picard',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '28',
    de: 'Bernhardiner',
    en: 'Bernard',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '29',
    de: 'Bichon a poil frise',
    en: 'Bichon a Poil Frise',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '30',
    de: 'Bichon Havanais',
    en: 'Bichon Havanais',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '31',
    de: 'Billy',
    en: 'Billy',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '32',
    de: 'Bluthund',
    en: 'Blood dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '33',
    de: 'Bobtail',
    en: 'Bobtail',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '34',
    de: 'Böhmischer Rauhbart',
    en: 'Bohemian rough beard',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '35',
    de: 'Bologneser',
    en: 'Bolognese',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '36',
    de: 'Bouvier des Flandres',
    en: 'Bouvier of the Flandre',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '37',
    de: 'Boxer',
    en: 'boxer',
    visibility: true,
    OP4: 4,
    KV4: 4,
  },
  {
    id: '38',
    de: 'Bracco Italiano',
    en: 'Bracco Italiano',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '39',
    de: 'Alpenländische Dachsbracke',
    en: 'Alpine Dachsbracke',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '40',
    de: 'Balkan Bracke',
    en: 'Balkan bracket',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '41',
    de: 'Brandlbracke',
    en: 'Brandlbracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '42',
    de: 'Deutsche Bracke',
    en: 'German bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '43',
    de: 'Finnenbracke',
    en: 'Finnnbracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '44',
    de: 'Hygenbracke',
    en: 'Hygenbracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '45',
    de: 'Jugoslawische Dreifarbige',
    en: 'Yugoslav three -colored',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '46',
    de: 'Kurzhaarige Istrianer Bracke',
    en: 'Short -haired Istrian Bracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '47',
    de: 'Polnische Bracke',
    en: 'Polish bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '48',
    de: 'Rauhhaarige Istrianer Bracke',
    en: 'Shrine -haired Istrian Bracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '49',
    de: 'Sauerländer Dachsbracke',
    en: 'Sauerland Dachsbracke',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '50',
    de: 'Savetal Bracke',
    en: 'Savetal Bracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '51',
    de: 'Schwedische Dachsbracke',
    en: 'Swedish Dachsbracke',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '52',
    de: 'Siebenbürger Bracke',
    en: 'Transylvanian Bracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '53',
    de: 'Slowakische Schwarzwildbracke',
    en: 'Slovak Black Bild Bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '54',
    de: 'Hochgebirgsbracke',
    en: 'Hochgebirgsbracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '55',
    de: 'Tiroler Bracke',
    en: 'Tyrolean bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '56',
    de: 'Westfälische Dachsbracke',
    en: 'Westphalian Dachsbracke',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '57',
    de: "Braque d'Auverne",
    en: "Braque d'uerverne",
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '58',
    de: "Braque de l'Ari\u00e8ge",
    en: "Braque de l'Ari\u00e8ge",
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '59',
    de: 'Braque du Bourbonnais',
    en: 'Braque du bourbonnais',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '60',
    de: 'Braque Dupuy',
    en: 'Braque dupuy',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '61',
    de: 'Braque Francais',
    en: 'Braque francais',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '62',
    de: 'Braque Saint-Germain',
    en: 'Braque Saint-Germain',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '63',
    de: 'Briard',
    en: 'Briard',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '64',
    de: 'Broholmer',
    en: 'Brawolmer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '65',
    de: 'Bruno du Jura',
    en: 'Bruno du law',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '66',
    de: 'Norwegischer Buhund',
    en: 'Norwegian Buhund',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '67',
    de: 'American Bulldog',
    en: 'American Bulldog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '68',
    de: 'Bulldog',
    en: 'Bulldog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '69',
    de: 'Azoren-Bulldogge',
    en: 'Azores bulldog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '70',
    de: 'Englische Bulldogge',
    en: 'English bulldog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '71',
    de: 'Französische Bulldogge',
    en: 'French bulldog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '72',
    de: 'Bullmastiff',
    en: 'Bullmastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '73',
    de: 'Ca De Bestiar',
    en: 'Ca de Bestiar',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '74',
    de: 'Ca de Bou',
    en: 'Ca de Bou',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '75',
    de: 'Cane Corso',
    en: 'Cane Corso',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '76',
    de: 'Cane Da Pastore',
    en: 'Cane da Pastore',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '77',
    de: 'Cao Da Serra Da Estrela',
    en: 'Cao da Serra da Estrela',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '78',
    de: 'Cao Da Serra De Aires',
    en: 'Cao da Serra de Aires',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '79',
    de: 'Cao De Agua Portugues',
    en: 'Cao de Agua Portugues',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '80',
    de: 'Cao de Castro Laboreiro',
    en: 'Cao de Castro Laboreiro',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '81',
    de: 'Cesky Fousek',
    en: 'Cesky Fousek',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '82',
    de: 'Chien de Berger de Beauce',
    en: 'Chien de Berger de Beauce',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '83',
    de: 'Chien de Saint Hubert',
    en: 'Chien de Saint Hubert',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '84',
    de: 'Chihuahua',
    en: 'Chihuahua',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '85',
    de: 'Chincha Bull',
    en: 'Chincha Bull',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '86',
    de: 'Chinese Crested Dog',
    en: 'Chinese Crested Dog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '87',
    de: 'Chinesischer Faltenhund',
    en: 'Chinese wrinkle dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '88',
    de: 'Chinesischer Haarloser Schopfhund',
    en: 'Chinese hairless skip dog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '89',
    de: 'Chow-Chow',
    en: 'Chow-Chow',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '90',
    de: 'Coban Köpegi',
    en: 'Coban Köpegi',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '91',
    de: 'Bearded Collie',
    en: 'Bearded Collie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '92',
    de: 'Border Collie',
    en: 'Border Collie',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '93',
    de: 'Collie',
    en: 'collie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '94',
    de: 'Coton de Tulear',
    en: 'Coton de Tulear',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '95',
    de: 'Dachshund',
    en: 'Roof dog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '96',
    de: 'Dackel',
    en: 'dachshund',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '97',
    de: 'Dalmatiner',
    en: 'Dalmatian',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '98',
    de: 'Deutsch Drahthaar',
    en: 'German wire hair',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '99',
    de: 'Deutsch Kurzhaar',
    en: 'German short hair',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '100',
    de: 'Deutsch Langhaar',
    en: 'German long hair',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '101',
    de: 'Deutsch Stichelhaar',
    en: 'German stubborn hair',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '102',
    de: 'Deutscher Boxer',
    en: 'German boxer',
    visibility: true,
    OP4: 4,
    KV4: 4,
  },
  {
    id: '103',
    de: 'Deutscher Wachtelhund',
    en: 'German quail dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '104',
    de: 'Do Khyi',
    en: 'Do Khyi',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '105',
    de: 'Dobermann',
    en: 'Doberman',
    visibility: true,
    OP4: 4,
    KV4: 4,
  },
  {
    id: '106',
    de: 'Bordeaux Dogge',
    en: 'Bordeaux mast',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '107',
    de: 'Niederlaufhund',
    en: 'Downhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '108',
    de: 'Brasilianische Dogge',
    en: 'Brazilian mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '109',
    de: 'Dänische Dogge',
    en: 'Danish mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '110',
    de: 'Deutsche Dogge',
    en: 'German Mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '111',
    de: 'Englische Dogge',
    en: 'English mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '112',
    de: 'Italienische Dogge',
    en: 'Italian mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '113',
    de: 'Spanische Dogge',
    en: 'Spanish mastiff',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '114',
    de: 'Tibet Dogge',
    en: 'Tibet Dogge',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '115',
    de: 'Dogo Argentino',
    en: 'Dogo Argentino',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '116',
    de: 'Dogo Canario',
    en: 'Dogo Canario',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '117',
    de: 'Dogo Mallorquin',
    en: 'Dogo Mallorquin',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '118',
    de: 'Drentsche Patijshond',
    en: 'Drentsche Patjshond',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '119',
    de: 'Drever',
    en: 'Drever',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '120',
    de: 'Dunker',
    en: 'Dunker',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '121',
    de: 'Schwedischer Elchhund',
    en: 'Swedish moose dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '122',
    de: 'Norwegischer Elchhund',
    en: 'Norwegian moose dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '123',
    de: 'Englischer Pointer',
    en: 'English pointer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '124',
    de: 'Epagneul Bleu De Picardie',
    en: 'Epagnule Bleu de Picardie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '125',
    de: 'Epagneul Breton',
    en: 'EPAGENUL Breton',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '126',
    de: 'Epagneul de Pont Audemer',
    en: 'EPAGENUL de Pont Audemer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '127',
    de: 'Epagneul francais',
    en: 'EPAGENUL Francais',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '128',
    de: 'Epagneul nain continental',
    en: 'EPAGENUL Nain Continental',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '129',
    de: 'Epagneul Picard',
    en: 'EPAGENUL PICARD',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '130',
    de: 'Erdelyi Kopo',
    en: 'Erdelyi Kopo',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '131',
    de: 'Eurasier',
    en: 'Eurasian',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '132',
    de: 'Fila Brasileiro',
    en: 'Fila Brasileiro',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '133',
    de: 'Finsk Spets',
    en: 'Finsk Spets',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '134',
    de: 'Finsk Stövare',
    en: 'Finsk Stövare',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '135',
    de: 'Amerikanischer Foxhound',
    en: 'American Foxhound',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '136',
    de: 'Foxhound',
    en: 'Foxhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '137',
    de: 'Francais blanc et noir',
    en: 'Francais Blanc et Noir',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '138',
    de: 'Francais blanc et orange',
    en: 'Francais Blanc et Orange',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '139',
    de: 'Francais tricolor',
    en: 'Francais tricolor',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '140',
    de: 'Galgo Espanol',
    en: 'Galgo Espanol',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '141',
    de: 'Gammel Dansk H\u00f8nsehund',
    en: 'Gammel dansk h\u00f8nsehund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '142',
    de: 'GebirgsJugoslawisch',
    en: 'Mountain Jugoslav',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '143',
    de: 'Balkanski Gonic',
    en: 'Balkanski Gonic',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '144',
    de: 'Istarski Ostrodlaki Gonic',
    en: 'IStarski Ostrodlaki Gonic',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '145',
    de: 'Jugoslavenski Planinski Gonic',
    en: 'Jugoslavenski Planinski Gonic',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '146',
    de: 'Jugoslavenski Trobojni Gonic',
    en: 'Jugoslavenski Trobojni Gonic',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '147',
    de: 'Grand Anglo Francais',
    en: 'Grand Anglo Francais',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '148',
    de: 'Grand Bleu De Gascogne',
    en: 'Grand Bleu de Gascogne',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '149',
    de: 'Grand Gascon Saintongeois',
    en: 'Grand Gascon Saintongeois',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '150',
    de: 'Greyhound',
    en: 'Greyhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '151',
    de: 'Griechischer Spürhund',
    en: 'Greek detection dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '152',
    de: 'Belgischer Griffon',
    en: 'Belgian grip',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '153',
    de: 'Briquet Griffon Vendeen',
    en: 'Briquet Griffon Vendenen',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '154',
    de: 'Brüsseler Griffon',
    en: 'Brussels Griffon',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '155',
    de: 'Grand Basset Griffon Vendeen',
    en: 'Grand Basset Griffon Vendenen',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '156',
    de: 'Grand Griffon Vendeen',
    en: 'Grand Griffon Vendenen',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '157',
    de: 'Griffon a poil laineux',
    en: 'Griffon a Poil Laineux',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '158',
    de: 'Griffon Belge',
    en: 'Griffon Belge',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '159',
    de: 'Griffon Bruxellois',
    en: 'Griffon Bruxellois',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '160',
    de: 'Griffon d Arret \u00e0 poil dur',
    en: 'Griffon d arret \u00e0 poil major',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '161',
    de: 'Griffon Fauve de Bretagne',
    en: 'Griffon Fauve de Bretagne',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '162',
    de: 'Griffon nivernais',
    en: 'Griffon Nivernais',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '163',
    de: 'Korthals Griffon',
    en: 'Korthals Griffon',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '164',
    de: 'Petit Basset Griffon Vendeen',
    en: 'Petit Basset Griffon Vendenen',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '165',
    de: 'Groenendael',
    en: 'Groenendael',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '166',
    de: 'Grönlandshund',
    en: 'Greenlandhund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '167',
    de: 'Haldenstoever',
    en: 'Haldenstoever',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '168',
    de: 'Hamilton Stövare',
    en: 'Hamilton Stövare',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '169',
    de: 'Harrier',
    en: 'Harrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '170',
    de: 'Havaneser',
    en: 'Havanese',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '171',
    de: 'Heidewachtel',
    en: 'Heath',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '172',
    de: 'Hellenikos Ichnilatis',
    en: 'Hellenikos ichnilatis',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '173',
    de: 'Anatolischer Hirtenhund',
    en: 'Anatolian shepherd dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '174',
    de: 'Hokkaido',
    en: 'Hokkaido',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '175',
    de: 'Holländischer Rattler',
    en: 'Dutch rattler',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '176',
    de: 'Hollandse Smoushond',
    en: 'Hollandse Smoushond',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '177',
    de: 'Basset Hound',
    en: 'Basset Hound',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '178',
    de: 'Black and tan Coonhound',
    en: 'Black and Tan Coonhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '179',
    de: 'Bloodhound',
    en: 'Bloodhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '180',
    de: 'Irish Wolfhound',
    en: 'Irish Wolfhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '181',
    de: 'Norwegian Hound',
    en: 'Norwegian Hound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '182',
    de: 'Otterhound',
    en: 'Otterhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '183',
    de: 'Pharaoh Hound',
    en: 'Pharaoh Hound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '184',
    de: 'Saarloos Wolfhound',
    en: 'Saarloos Wolfhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '185',
    de: 'Hovawart',
    en: 'Hovawart',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '186',
    de: 'Hrvatski Ovcar',
    en: 'Hrvatski Ovcar',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '187',
    de: 'Altdänischer Hühnerhund',
    en: 'Old Dine chicken dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '188',
    de: 'Burgos-Hühnerhund',
    en: 'Burgos chicken dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '189',
    de: 'Portugiesischer Hühnerhund',
    en: 'Portuguese chicken dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '190',
    de: 'Husky',
    en: 'Husky',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '191',
    de: 'Hygenhund',
    en: 'Hygenhund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '192',
    de: 'Islandhund',
    en: 'Iceland dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '193',
    de: 'Italienisches Windspiel',
    en: 'Italian wind chime',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '194',
    de: 'Jämthund',
    en: 'Jämthund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '195',
    de: 'Jämtländer',
    en: 'Jämtländer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '196',
    de: 'Japan Chin',
    en: 'Japan Chin',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '197',
    de: 'Kai Inu',
    en: 'Kai Inu',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '198',
    de: 'Kanaan Hund',
    en: 'Kanaan dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '199',
    de: 'Kangal',
    en: 'Kangal',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '200',
    de: 'Kelb Tal Fenek',
    en: 'Kelb Tal Fenek',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '201',
    de: 'Kishu Inu',
    en: 'Kishu Inu',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '202',
    de: 'Kleiner Brabanter',
    en: 'Small Brabant',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '203',
    de: 'Kleiner Münsterländer',
    en: 'Small Münsterländer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '204',
    de: 'Komondor',
    en: 'Comondor',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '205',
    de: 'Kuvasz',
    en: 'Kuvasz',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '206',
    de: 'Laekenois',
    en: 'Laekenois',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '207',
    de: 'Lagotto',
    en: 'Lagotto',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '208',
    de: 'Landseer',
    en: 'Landseer',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '209',
    de: 'Lapinporokoira',
    en: 'Lapin -poroira',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '210',
    de: 'Finnischer Lapphund',
    en: 'Finnish Lapphund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '211',
    de: 'Schwedischer Lapphund',
    en: 'Swedish Lapphund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '212',
    de: 'Svensk Lapphund',
    en: 'Svensk Lapphund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '213',
    de: 'Lapplaendske Spets',
    en: 'Lapplatske Spets',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '214',
    de: 'Lappländer Rentierhund',
    en: 'Lappland reindeer dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '215',
    de: 'Balearen Laufhund',
    en: 'Balearic drives',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '216',
    de: 'Berner Laufhund',
    en: 'Bernese drive',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '217',
    de: 'Italienischer Laufhund',
    en: 'Italian drive dog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '218',
    de: 'Luzerner Laufhund',
    en: 'Lucerne drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '219',
    de: 'Schweizer Laufhund',
    en: 'Swiss drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '220',
    de: 'Schwyzer Laufhund',
    en: 'Schwyzer drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '221',
    de: 'Leonberger',
    en: 'Leonberger',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '222',
    de: 'Lhasa Apso',
    en: 'Lhasa apso',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '223',
    de: 'Loewchen',
    en: 'Loan',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '224',
    de: 'Norwegischer Lundehund',
    en: 'Norwegian Lundehund',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '225',
    de: 'Magyar Agar',
    en: 'Magyar Agar',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '226',
    de: 'Magyar Vizsla',
    en: 'Magyar Vizsla',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '227',
    de: 'Malinois',
    en: 'Malinois',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '228',
    de: 'Malteser',
    en: 'Maltese',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '229',
    de: 'Mastiff',
    en: 'Mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '230',
    de: 'Mastin de los Pirineos',
    en: 'Mastin de los pirineos',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '231',
    de: 'Mastin Espanol',
    en: 'Mastine Espanol',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '232',
    de: 'Mastino Napoletano',
    en: 'Mastino Napoletano',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '233',
    de: 'Molosser',
    en: 'Molosser',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '234',
    de: 'Mops',
    en: 'pug',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '235',
    de: 'Mudi',
    en: 'Mudi',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '236',
    de: 'Mexikanischer Nackthund',
    en: 'Mexican naked',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '237',
    de: 'Peruanischer Nackthund',
    en: 'Peruvian naked',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '238',
    de: 'Napoletano',
    en: 'Napoletano',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '239',
    de: 'Neufundländer',
    en: 'Newfoundland',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '240',
    de: 'Niederländischer Kooikerhondje',
    en: 'Dutch kooikerhondje',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '241',
    de: 'Nihon Supittsu',
    en: 'Nihon Supittsu',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '242',
    de: 'Nihon Teria',
    en: 'Nihon teria',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '243',
    de: 'Norbotten Spets',
    en: 'Norbotten Spets',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '244',
    de: 'Otterhund',
    en: 'Otterhund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '245',
    de: 'Owtscharka',
    en: 'Owscharka',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '246',
    de: 'Peking Palasthund',
    en: 'Beijing palace dog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '247',
    de: 'Papillon',
    en: 'Papillon',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '248',
    de: 'Pekinese',
    en: 'Pekinese',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '249',
    de: 'Perdigueiro Portugues',
    en: 'Perdigueiro Portugues',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '250',
    de: 'Perdiguere de Burgos',
    en: 'Perdiguere de Burgos',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '251',
    de: 'Perro de Agua Espanol',
    en: 'Perro de Agua Espanol',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '252',
    de: 'Perro de Pastor Catalan',
    en: 'Perro de Pastor Catalan',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '253',
    de: 'Perro de Presa Canario',
    en: 'Perro de Presa Canario',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '254',
    de: 'Perro de Presa Mallorquin',
    en: 'Perro de Presa Mallorquin',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '255',
    de: 'Perro sin Pelo del Peru',
    en: 'Perro Sin Pelo del Peru',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '256',
    de: 'Petit Bleu de Gascogne',
    en: 'Petit Bleu de Gascogne',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '257',
    de: 'Petit Brabancon',
    en: 'Petit Brancon',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '258',
    de: 'Petit Chien Lion',
    en: 'Petit Chien Lion',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '259',
    de: 'Petit Gascon Saintongeois',
    en: 'Petit Gascon Saintongeois',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '260',
    de: 'Phalene',
    en: 'Phalene',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '261',
    de: 'Pharaonenhund',
    en: 'Pharaoh dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '262',
    de: 'Picard',
    en: 'Picard',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '263',
    de: 'Affenpinscher',
    en: 'Affenpinscher',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '264',
    de: 'Deutscher Pinscher',
    en: 'German Pinscher',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '265',
    de: '\u00d6sterreichischer Pinscher',
    en: 'Austrian Pinscher',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '266',
    de: 'Zwergpinscher',
    en: 'Dwarf',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '267',
    de: 'Pitbull',
    en: 'Pit bull',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '268',
    de: 'Podenco Canario',
    en: 'Podenco Canario',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '269',
    de: 'Podenco Ibicenco',
    en: 'Podenco ibicenco',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '270',
    de: 'Podengo Portugues',
    en: 'Podengo Portugues',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '271',
    de: 'Podhalaner',
    en: 'Podhalan',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '272',
    de: 'Poitevin',
    en: 'Poitevin',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '273',
    de: 'Polnischer Niederungshütehund',
    en: 'Polish lowlands dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '274',
    de: 'Polski Owczarek Nizinny',
    en: 'Polski Owczarek Nizinny',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '275',
    de: 'Polski Owczarek Podhalanski',
    en: 'Polski owczarek podhalanski',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '276',
    de: 'Porcelaine',
    en: 'Porcelaine',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '277',
    de: 'Niederländischer Schafspudel',
    en: 'Dutch sheep sp fored',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '278',
    de: 'Pudel',
    en: 'poodle',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '279',
    de: 'Pudelpointer',
    en: 'Poodle pointer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '280',
    de: 'Puli',
    en: 'Puli',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '281',
    de: 'Pumi',
    en: 'Pumi',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '282',
    de: 'Pyrenäenberghund',
    en: 'Pyrenäenenberghund',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '283',
    de: 'Pyrenäenhund',
    en: 'Pyrenea dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '284',
    de: 'Rafeiro do Alentejo',
    en: 'Rafeiro do Alentejo',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '285',
    de: 'Rebhuhnhund',
    en: 'Vineyard dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '286',
    de: 'Chesapeake Bay Retriever',
    en: 'Chesapeake Bay Retriever',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '287',
    de: 'Curly Coated Retriever',
    en: 'Curly Coated Retriever',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '288',
    de: 'Flat Coated Retriever',
    en: 'Flat Coated Retriever',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '289',
    de: 'Golden Retriever',
    en: 'Golden retriever',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '290',
    de: 'Labrador',
    en: 'Labrador',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '291',
    de: 'Nova Scotia Duck Tolling',
    en: 'Nova Scotia Duck Tolling',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '292',
    de: 'Rhodesian Ridgeback',
    en: 'Rhodesian Ridback',
    visibility: true,
    OP4: 4,
    KV4: 4,
  },
  {
    id: '293',
    de: 'Rottweiler',
    en: 'rottweiler',
    visibility: true,
    OP4: 4,
    KV4: 4,
  },
  {
    id: '294',
    de: 'Russisch-Europäischer Laika',
    en: 'Russian-European Laika',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '295',
    de: 'Sabueso Espanol',
    en: 'Sabueso Espanol',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '296',
    de: 'Saluki',
    en: 'Saluki',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '297',
    de: 'Samojede',
    en: 'Samojede',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '298',
    de: 'Sankt-Bernhards-Hund',
    en: 'Sankt-Bernhards dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '299',
    de: 'Sarplaninac',
    en: 'Sarplaninac',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '300',
    de: 'AC Weisser Schäferhund',
    en: 'AC Weisser Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '301',
    de: 'Belgischer Schäferhund',
    en: 'Belgian German Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '302',
    de: 'Deutscher Schäferhund',
    en: 'German shepherd dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '303',
    de: 'Holländischer Schäferhund',
    en: 'Dutch German Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '304',
    de: 'Illyrischer Schäferhund',
    en: 'Illyrian Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '305',
    de: 'Karst Schäferhund',
    en: 'Karst Shepherd',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '306',
    de: 'Katalonischer Schäferhund',
    en: 'Catalonic German Shepherd',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '307',
    de: 'Kroatischer Schäferhund',
    en: 'Croatian German Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '308',
    de: 'Maremmen-Abruzze',
    en: 'Maremmememen-Abruzzze',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '309',
    de: 'Pyrenäen Schäferhund (Kurzhaar)',
    en: 'Pyrenees Shepherd (shorthair)',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '310',
    de: 'Pyrenäen Schäferhund (Langhaar)',
    en: 'Pyrenees Shepherd (long hair)',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '311',
    de: 'Schäferhund',
    en: 'Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '312',
    de: 'Weisser Schäferhund',
    en: 'White shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '313',
    de: 'Schapendoes',
    en: 'Schapendoes',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '314',
    de: 'Schillerstövare',
    en: 'Schillerstövare',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '315',
    de: 'Riesenschnauzer',
    en: 'Giant',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '316',
    de: 'Schnauzer',
    en: 'schnauzer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '317',
    de: 'Zwergschnauzer',
    en: 'Dwarf snout',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '318',
    de: 'Segugio Italiano',
    en: 'Segugio Italiano',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '319',
    de: 'Appenzeller Sennenhund',
    en: 'Appenzell Mountain dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '320',
    de: 'Berner Sennenhund',
    en: 'Bernese Mountain Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '321',
    de: 'Entlebucher Sennenhund',
    en: 'Entlebuch Sennenhund',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '322',
    de: 'Schweizer',
    en: 'Swiss',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '323',
    de: 'English Setter',
    en: 'English Setter',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '324',
    de: 'Gordon Setter',
    en: 'Gordon Setter',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '325',
    de: 'Irisch rot-weisser Setter',
    en: 'Irish red and white setting',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '326',
    de: 'Irish Red and White Setter',
    en: 'Irish Red and White Setter',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '327',
    de: 'Irish Setter',
    en: 'Irish setter',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '328',
    de: 'Shar-Pei',
    en: 'Shar-Pei',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '329',
    de: 'Old English Sheepdog',
    en: 'Old English Sheepdog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '330',
    de: 'Shetland Sheepdog',
    en: 'Shetland Sheepdog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '331',
    de: 'Sheltie',
    en: 'Sheltie',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '332',
    de: 'Shiba Inu',
    en: 'Shiba Inu',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '333',
    de: 'Shih Tzu',
    en: 'Shih Tzu',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '334',
    de: 'Shikoku Inu',
    en: 'Shikoku Inu',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '335',
    de: 'Siberian Husky',
    en: 'Siberian Husky',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '336',
    de: 'Sloughi',
    en: 'Sloughi',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '337',
    de: 'Cuvac Slovensky',
    en: 'Cuvac Slovensky',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '338',
    de: 'Hruborsty Stavac Slovensky',
    en: 'Hruborsty Stavac Slovensky',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '339',
    de: 'Kopov Slovensky',
    en: 'Kopov Slovensky',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '340',
    de: 'Slowakischer Tchuwatsch',
    en: 'Slovakian Tchuwatsch',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '341',
    de: 'Smalandsstövare',
    en: 'Smalandstövare',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '342',
    de: 'American Cocker Spaniel',
    en: 'American Cocker Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '343',
    de: 'American Water Spaniel',
    en: 'American Water Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '344',
    de: 'Amerikanischer Wasserspaniel',
    en: 'American water paniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '345',
    de: 'Cavalier King Charles Spaniel',
    en: 'Cavalier King Charles Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '346',
    de: 'Clumber Spaniel',
    en: 'Clumber Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '347',
    de: 'Cocker Spaniel',
    en: 'Cocker spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '348',
    de: 'Englischer Cocker Spaniel',
    en: 'English cocker Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '349',
    de: 'Englischer Springer Spaniel',
    en: 'English jumper Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '350',
    de: 'Field Spaniel',
    en: 'Field Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '351',
    de: 'Irischer Wasserspaniel',
    en: 'Irish water paniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '352',
    de: 'Irish Water Spaniel',
    en: 'Irish Water Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '353',
    de: 'King Charles Spaniel',
    en: 'King Charles Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '354',
    de: 'Sussex Spaniel',
    en: 'Sussex Spaniel',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '355',
    de: 'Tibet Spaniel',
    en: 'Tibeti Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '356',
    de: 'Welsh Springer Spaniel',
    en: 'Welsh Springer Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '357',
    de: 'Spanish Mastiff',
    en: 'Spanish Mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '358',
    de: 'Spinone Italiano',
    en: 'Spinone Italiano',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '359',
    de: 'Deutscher Spitz',
    en: 'German point',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '360',
    de: 'Finnenspitz',
    en: 'Finnenspitz',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '361',
    de: 'Japan-Spitz',
    en: 'Japan-Spitz',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '362',
    de: 'Norbottenspitz',
    en: 'Norbottenpitz',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '363',
    de: 'Schwedischer Schäferspitz',
    en: 'Swedish Schäferspitz',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '364',
    de: 'Spitz',
    en: 'Pointed',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '365',
    de: 'Stabijhoun',
    en: 'Stabijhoun',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '366',
    de: 'Südrussischer Owtscharka',
    en: 'South Russian Owscharka',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '367',
    de: 'Suomenajokoira',
    en: 'Suomenajokoira',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '368',
    de: 'Suomenlapinkoira',
    en: 'Suomenlapinkoira',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '369',
    de: 'Suomenpystykorva',
    en: 'Suomenpystykorva',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '370',
    de: 'Tatrahund',
    en: 'Tapahund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '371',
    de: 'Teckel',
    en: 'Teckel',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '372',
    de: 'Airedale Terrier',
    en: 'Airedale Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '373',
    de: 'American Pit Bull Terrier',
    en: 'American Pit Bull Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '374',
    de: 'American Stafford Terrier',
    en: 'American Stafford Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '375',
    de: 'American Staffordshire Terrier',
    en: 'American Staffordshire Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '376',
    de: 'Australian Silky Terrier',
    en: 'Australian Silky Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '377',
    de: 'Australian Terrier',
    en: 'Australian Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '378',
    de: 'Bedlington Terrier',
    en: 'Bedding Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '379',
    de: 'Border Terrier',
    en: 'Border Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '380',
    de: 'Bullterrier',
    en: 'Bull terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '381',
    de: 'Cairn Terrier',
    en: 'Cairn Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '382',
    de: 'Cesky Terrier',
    en: 'Cesky Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '383',
    de: 'Dandie Dinmont Terrier',
    en: 'Dandie Dinmont Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '384',
    de: 'Deutscher Jagdterrier',
    en: 'German hunting terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '385',
    de: 'English Toy Terrier',
    en: 'English Toy Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '386',
    de: 'Fox Terrier',
    en: 'Fox Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '387',
    de: 'Glen of Imaal Terrier',
    en: 'Glen of Imaal Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '388',
    de: 'Irish Terrier',
    en: 'Irish Terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '389',
    de: 'Jack Russell Terrier',
    en: 'Jack Russell Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '390',
    de: 'Japanischer Terrier',
    en: 'Japanese terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '391',
    de: 'Kerry Blue Terrier',
    en: 'Kerry Blue Terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '392',
    de: 'Lakeland Terrier',
    en: 'Lakeland Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '393',
    de: 'Manchester Terrier',
    en: 'Manchester Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '394',
    de: 'Norfolk Terrier',
    en: 'Norfolk Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '395',
    de: 'Norwich Terrier',
    en: 'Norwich Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '396',
    de: 'Parson Jack Russell Terrier',
    en: 'Parson Jack Russell Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '397',
    de: 'Pitbullterrier',
    en: 'Pit bull terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '398',
    de: 'Schwarzer Russischer Terrier',
    en: 'Black Russian Terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '399',
    de: 'Schwarzer Terrier',
    en: 'Black terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '400',
    de: 'Scottish Terrier',
    en: 'Scottish Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '401',
    de: 'Sealyham Terrier',
    en: 'Sealyham Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '402',
    de: 'Skye Terrier',
    en: 'Skye Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '403',
    de: 'Soft-Coated Wheaten Terrier',
    en: 'Soft-Coated Wheate Terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '404',
    de: 'Staffordshire Bullterrier',
    en: 'Staffordshire bull terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '405',
    de: 'Tibet Terrier',
    en: 'Tibet terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '406',
    de: 'Welsh Terrier',
    en: 'Welsh Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '407',
    de: 'West Highland White Terrier',
    en: 'West Highland White Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '408',
    de: 'Yorkshire Terrier',
    en: 'Yorkshire Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '409',
    de: 'Tervueren',
    en: 'Tervueren',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '410',
    de: 'Tibet Mastiff',
    en: 'Tibet Mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '411',
    de: 'Toller',
    en: 'Great',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '412',
    de: 'Tosa Inu',
    en: 'Tosa Inu',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '413',
    de: 'Vallhund',
    en: 'Vallhund',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '414',
    de: 'Västgötaspets',
    en: 'VestgötaSpets',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '415',
    de: 'Volpino Italiano',
    en: 'Volpino Italiano',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '416',
    de: 'Deutscher Vorstehhund (drahthaar)',
    en: 'German prime dog (wire hair)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '417',
    de: 'Deutscher Vorstehhund (kurzhaar)',
    en: 'German pretense dog (short hair)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '418',
    de: 'Deutscher Vorstehhund (langhaar)',
    en: 'German prime dog (long hair)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '419',
    de: 'Deutscher Vorstehhund (stichelhaarig)',
    en: 'German prime dog (stitch -haired)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '420',
    de: 'Gro\u00dfer Münsterländer Vorstehhund',
    en: 'Great Münsterländer',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '421',
    de: 'Italienischer Vorstehhund',
    en: 'Italian pointing dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '422',
    de: 'Slowakischer Vorstehhund (drahthaar)',
    en: 'Slovakian into the hunde (wire hair)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '423',
    de: 'Wäller',
    en: 'Roller',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '424',
    de: 'Portugiesischer Wasserhund',
    en: 'Portuguese water dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '425',
    de: 'Spanischer Wasserhund',
    en: 'Spanish water dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '426',
    de: 'Weimaraner',
    en: 'Weimaran',
    visibility: true,
    OP4: 4,
    KV4: 4,
  },
  {
    id: '427',
    de: 'Welsh Corgi Cardigan',
    en: 'Welsh Corgi Cardigan',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '428',
    de: 'Welsh Corgi Pembroke',
    en: 'Welsh Corgi Pembroke',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '429',
    de: 'Westsibirischer Laika',
    en: 'West Siberian Laika',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '430',
    de: 'Wetterhoun',
    en: 'Weather hoop',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '431',
    de: 'Whippet',
    en: 'Whippet',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '432',
    de: 'Afghanischer Windhund',
    en: 'Afghan greyhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '433',
    de: 'Arabischer Windhund',
    en: 'Arabic greyhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '434',
    de: 'Russischer Windhund',
    en: 'Russian greyhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '435',
    de: 'Spanischer Windhund',
    en: 'Spanish greyhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '436',
    de: 'Ungarischer Windhund',
    en: 'Hungarian greyhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '437',
    de: 'Irischer Wolfshund',
    en: 'Irish wolfhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '438',
    de: 'Agassin',
    en: 'Agassin',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '439',
    de: 'Akbasch',
    en: 'Akbasch',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '440',
    de: 'Alangu Mastiff',
    en: 'Alangu Mastiff',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '441',
    de: 'Alapaha Blue Blood Bulldog',
    en: 'Alapaha Blue Blood Bulldog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '442',
    de: 'Altdänischer Vorstehhund',
    en: 'Old Dan',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '443',
    de: 'Altdeutscher Hütehund',
    en: 'Old German herding dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '444',
    de: 'Fuchs (Altdeutscher Hütehund)',
    en: 'Fuchs (old German herding dog)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '445',
    de: 'Gelbbacke (Altdeutscher Hütehund)',
    en: 'Yellow cheek (old German herding dog)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '446',
    de: 'Harzer Fuchs (Altdeutscher Hütehund)',
    en: 'Harzer Fuchs (old German herding dog)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '447',
    de: 'Kuhhund (Altdeutscher Hütehund)',
    en: 'Cow dog (old German herding dog)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '448',
    de: 'Schafpudel (Altdeutscher Hütehund)',
    en: 'Schaf poodle (old German herding dog)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '449',
    de: 'Schwarzer (Altdeutscher Hütehund)',
    en: 'Black (old German herding dog)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '450',
    de: 'Altdeutscher Hütehund (Strobel)',
    en: 'Old German herding dog (Strobel)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '451',
    de: 'Tiger (Altdeutscher Hütehund)',
    en: 'Tiger (old German herding dog)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '452',
    de: 'Ardennen-Treibhund',
    en: 'Ardennes dribble dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '453',
    de: 'Armant',
    en: 'Armant',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '454',
    de: 'Australian Stumpy Tail Cattle Dog',
    en: 'Australian Stumpy Tail Cattle Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '455',
    de: 'Australien Heeler',
    en: 'Australia Heeler',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '456',
    de: 'Bakarwal',
    en: 'Bakarwal',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '457',
    de: 'Bangkaew Dog',
    en: 'Bangkaew Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '458',
    de: 'Barak',
    en: 'Barak',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '459',
    de: 'Bardino',
    en: 'Bardino',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '460',
    de: 'Germanischer Bärenhund',
    en: 'Germanic bear dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '461',
    de: 'Baskischer Ratero',
    en: 'Basque ratero',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '462',
    de: 'Bayerischer Gebirgsschwei\u00dfhund',
    en: 'Bavarian mountain welding dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '463',
    de: 'Berger Blanc Suisse',
    en: 'Berger Blanc Suisse',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '464',
    de: 'Berger de Laeken',
    en: 'Berger de Laeken',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '465',
    de: 'Berger de Savoie (Berger des Alpes)',
    en: 'Berger de Savoie (Berger des Alpes)',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '466',
    de: 'Berger du Languedoc',
    en: 'Berger du Languedoc',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '467',
    de: 'Bernen Laufhuind',
    en: 'Berne Laufhuind',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '468',
    de: 'Bisben (Himalaja Schäferhund)',
    en: 'Bisben (Himalaja Schäferhund)',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '469',
    de: 'Blenheim',
    en: 'Blenheim',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '470',
    de: 'Blue Lacy',
    en: 'Blue Lacy',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '471',
    de: 'Boerboel',
    en: 'Boerboel',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '472',
    de: 'Bolonka Franzuska',
    en: 'Bolonka Franzuska',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '473',
    de: 'Bolonka Zwetna',
    en: 'Bolonka Zwetna',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '474',
    de: 'Italienische Bracke',
    en: 'Italian bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '475',
    de: 'Keltische Bracke',
    en: 'Celtic bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '476',
    de: 'Schwarzwildbracke',
    en: 'Black -fashion bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '477',
    de: 'Sizilianische Bracke',
    en: 'Sicilian bracket',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '478',
    de: 'Steinbracke',
    en: 'Steinbracke',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '479',
    de: 'Steirische Bracke',
    en: 'Styrian bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '480',
    de: 'Ungarische Bracke',
    en: 'Hungarian bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '481',
    de: 'Brasilianischer Mastiff',
    en: 'Brazilian mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '482',
    de: 'Rater Mallorqui',
    en: 'Rater Mallorqui',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '483',
    de: 'Cane Da Pastore Maremmano Abruzzese',
    en: 'Cane da Pastore Maremmano Abruzzese',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '484',
    de: 'Cao Fila de Sao Miguel',
    en: 'Cao Fila de Sao Miguel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '485',
    de: 'Carolina Hund',
    en: 'Carolina dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '486',
    de: 'Carpatin',
    en: 'Carpatin',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '487',
    de: 'Catahoula Bulldogge',
    en: 'Catahoula Bulldogge',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '488',
    de: 'Catahoula Leopard Hund',
    en: 'Catahoula Leopard dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '489',
    de: 'Chambray',
    en: 'Chambray',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '490',
    de: 'Chien de Montagne des Pyr\u00e9n\u00e9es',
    en: 'Chien de Montagne des Pyr\u00e9n\u00e9es',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '491',
    de: 'Chien de Trait Belge',
    en: 'Chien de Trait Belge',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '492',
    de: "Chien d'Artois",
    en: "Chien d'Artois",
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '493',
    de: 'Chin',
    en: 'Chin',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '494',
    de: 'Chinesischer Chongqing Hund',
    en: 'Chinese Chongqing dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '495',
    de: 'Chinook',
    en: 'Chinoook',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '496',
    de: 'Chippiparai',
    en: 'Chippiparai',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '497',
    de: 'Chodenhund',
    en: 'Choden dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '498',
    de: 'Chortaj (Hortaya Borzay)',
    en: 'Chortaj (Hortaya Borzay)',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '499',
    de: 'Cimarr\u00f3n Uruguayo',
    en: 'Cimarr\u00f3n Uruguayo',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '500',
    de: 'Cirneco dell\u2019Etna',
    en: 'Cirneco dell\u2019etna',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '501',
    de: 'Cocker Poo',
    en: 'Cocker Poo',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '502',
    de: 'Amerikanischer Collie',
    en: 'American Collie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '503',
    de: 'Combai',
    en: 'Combai',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '504',
    de: 'Continental Bulldog',
    en: 'Continental Bulldog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '505',
    de: 'Coonhound',
    en: 'Coonhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '506',
    de: 'Corgi',
    en: 'Corgi',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '507',
    de: 'Cursinu',
    en: 'Cursinu',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '508',
    de: 'Da Capo Dog',
    en: 'Da Capo Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '509',
    de: 'Zwergdackel',
    en: 'Dwarf dack',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '510',
    de: 'Dansk-Svensk G\u00e5rdshund',
    en: 'Dansk-Svensk G\u00e5rdshund',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '511',
    de: 'Designerhund',
    en: 'Designer dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '512',
    de: 'Dingo',
    en: 'dingo',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '513',
    de: 'Argentinische Dogge',
    en: 'Argentine mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '514',
    de: 'Kanarische Dogge',
    en: 'Canarian mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '515',
    de: 'Elo',
    en: 'Elo',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '516',
    de: 'English Shepherd',
    en: 'English Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '517',
    de: 'Epagneul de Saint-Usuge',
    en: 'Epagnule de Saint-Uusuge',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '518',
    de: 'Estrela Berghund',
    en: 'Estrela mountain dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '519',
    de: 'Europäischer Schlittenhund',
    en: 'European sled dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '520',
    de: 'English Foxhound',
    en: 'English foxhound',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '521',
    de: 'Französischer Vorstehhund',
    en: 'French base dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '522',
    de: 'Friesischer Wasserhund',
    en: 'Frisian water dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '523',
    de: 'Gr\u00e5hund',
    en: 'Gr\u00e5hog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '524',
    de: 'Gro\u00dfpudel',
    en: 'Big poodle',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '525',
    de: 'Hallstrom-Hund',
    en: 'Hallstrom dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '526',
    de: 'Hannoverscher Schwei\u00dfhund',
    en: 'Hannoverian welding dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '527',
    de: 'Hawaiian Poi Dog',
    en: 'Hawaiian Poi Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '528',
    de: 'Karakatschan Bulgarischer Hirtenhund',
    en: 'Karakatsch Bulgarian shepherd dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '529',
    de: 'Maremmaner Hirtenhund',
    en: "Maremman's shepherd dog",
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '530',
    de: 'Rumänischer Hirtenhund',
    en: 'Romanian shepherd dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '531',
    de: 'Bluetick Coonhound',
    en: 'Bluetick Coonhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '532',
    de: 'Caravan Hound',
    en: 'Caravan Hound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '533',
    de: 'English Coonhound',
    en: 'English Coonhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '534',
    de: 'Mudhol Hound',
    en: 'Mudhol Hound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '535',
    de: 'Plott Coonhound',
    en: 'Plott Coonhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '536',
    de: 'Rampur Hound',
    en: 'Rampur Hound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '537',
    de: 'Redbone Coonhound',
    en: 'Redbone Coonhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '538',
    de: 'Redtick Coonhound',
    en: 'Redtick Coonhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '539',
    de: 'Scandinavian Hound',
    en: 'Scandinavian Hound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '540',
    de: 'Treeing Walker Coonhound',
    en: 'Treing Walker Coonhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '541',
    de: 'Hubertushund',
    en: 'Hubertus dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '542',
    de: 'Huntaway',
    en: 'Huntaway',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '543',
    de: 'Neuseeländer Huntaway',
    en: 'New Zealander Huntaway',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '544',
    de: 'Alaska Husky',
    en: 'Alaska Husky',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '545',
    de: 'Hybridhund',
    en: 'Hybrid dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '546',
    de: 'Irish Red Setter',
    en: 'Irish Red Setter',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '547',
    de: 'Italienischer Wolfhund',
    en: 'Italian wolf dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '548',
    de: 'Jonangi',
    en: 'Jonangi',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '549',
    de: 'Kaikadi',
    en: 'Kaikadi',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '550',
    de: 'Karelo-Finnish Laika',
    en: 'Karelo-Finnish Laika',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '551',
    de: 'Kafkas (Kars-Hund)',
    en: 'Kafkas (kars dog)',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '552',
    de: 'Kashmir Mastiff',
    en: 'Kashmir Mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '553',
    de: 'Kaukasischer Owtscharka',
    en: 'Caucasian owscharka',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '554',
    de: 'Keeshond',
    en: 'Keeshond',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '555',
    de: 'Kelpie',
    en: 'Kelpie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '556',
    de: 'Kerry Beagle',
    en: 'Kerry Beagle',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '557',
    de: 'Kintamanihund (Bali-Berghund)',
    en: 'Kintamani dog (Bali-Mountain Dog)',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '558',
    de: 'Kooikerhondje',
    en: 'Kooikerhondje',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '559',
    de: 'Kopov',
    en: 'Kopov',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '560',
    de: 'Korea Jindo Dog',
    en: 'Korea Jindo Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '561',
    de: 'Kromfohrländer',
    en: 'Kromfohrländer',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '562',
    de: 'Kyi Leo',
    en: 'Kyi Leo',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '563',
    de: 'Labradoodle',
    en: 'Labradoodle',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '564',
    de: 'Lacy Dog',
    en: 'Lacy Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '565',
    de: 'Laika',
    en: 'Laika',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '566',
    de: 'Laika Jakutisch',
    en: 'Laika Jacutic',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '567',
    de: 'Laika Ostsibirisch',
    en: 'Laika Eastern Siberian',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '568',
    de: 'Laika Russisch-Europäisch',
    en: 'Laika Russian-European',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '569',
    de: 'Lancashire Heeler',
    en: 'Lancashire Heeler',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '570',
    de: 'Bosnischer Laufhund',
    en: 'Bosnian',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '571',
    de: 'Französischer Laufhund',
    en: 'French drive',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '572',
    de: 'Jura Laufhund',
    en: 'Jura drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '573',
    de: 'Montenegrinischer Gebirgslaufhund',
    en: 'Montenegrin Montalen',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '574',
    de: 'Polnischer Laufhund',
    en: 'Polish drive dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '575',
    de: 'Transsylvanischer Laufhund',
    en: 'Transylvanic drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '576',
    de: 'Laverack Setter',
    en: 'Laverack Setter',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '577',
    de: 'Longdog',
    en: 'Longdog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '578',
    de: 'Louisiana Catahoula Leopard Dog',
    en: 'Louisiana Catahoula Leopard Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '579',
    de: 'Lurcher',
    en: 'Ambush',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '580',
    de: 'Maneto',
    en: 'Maneto',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '581',
    de: 'Markiesje',
    en: 'Markiesje',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '582',
    de: 'M\u00e2tin',
    en: 'M\u00e2tin',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '583',
    de: 'McNab',
    en: 'Mcnab',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '584',
    de: 'Mikawa Inu',
    en: 'Mikawa Inu',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '585',
    de: 'Miniatur Australian Shepherd',
    en: 'Miniature Australian Shepherd',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '586',
    de: 'Mioritic',
    en: 'Mioritic',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '587',
    de: 'Mittelasiatischer Tazi',
    en: 'Central Asian Tazi',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '588',
    de: 'Moskauer Wachhund',
    en: 'Moscow watchdog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '589',
    de: 'Mucuchies',
    en: 'Mucuchies',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '590',
    de: 'Münsterländer',
    en: 'Münsterländer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '591',
    de: 'Inka-Nackthund',
    en: 'Inca-Nackthund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '592',
    de: 'Nederlandse Herdershond',
    en: 'Nederlandse Herdershond',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '593',
    de: 'Norwegischer Elchhund grau',
    en: 'Norwegian moose dog gray',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '594',
    de: 'Norwegischer Elchhund schwarz',
    en: 'Norwegian moose dog black',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '595',
    de: 'Ogar Polski',
    en: 'Ogar Polski',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '596',
    de: 'Ohar',
    en: 'Ohar',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '597',
    de: 'Pachon Navarro',
    en: 'Pachon Navarro',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '598',
    de: 'Pai',
    en: 'Pai',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '599',
    de: 'Paleiyakaran',
    en: 'Paleiyakaran',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '600',
    de: 'Pariahunde',
    en: 'Pariah round',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '601',
    de: 'Pastor Garafiano',
    en: 'Pastor Garafiano',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '602',
    de: 'Pastor Vasco Euskal Artzain Txakurra',
    en: 'Pastor Vasco Euskal Artzain Txakurra',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '603',
    de: 'Perro de Ganado Majorero',
    en: 'Perro de Ganado Majorero',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '604',
    de: 'Perro Majorero',
    en: 'Perro Majorero',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '605',
    de: 'Harlekinpinscher',
    en: 'Harlekinpin',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '606',
    de: 'Rehpinscher',
    en: 'Deer',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '607',
    de: 'Podenco Andaluz',
    en: 'Podenco Andaluz',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '608',
    de: 'Podenco Andaluz Maneto',
    en: 'Podenco Andaluz Maneto',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '609',
    de: 'Podenco Enano',
    en: 'Podenco Enano',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '610',
    de: 'Podenco Malgueno',
    en: 'Podenco Malgueno',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '611',
    de: 'Pointer',
    en: 'pointer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '612',
    de: 'Prager Rattler',
    en: 'Prague Rattler',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '613',
    de: 'Prazsky Krysarik',
    en: 'Prazsky Krysarik',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '614',
    de: 'Kleinpudel',
    en: 'Small poodle',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '615',
    de: 'Königspudel',
    en: 'Royal spuge',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '616',
    de: 'Schafpudel',
    en: 'Sheep poodle',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '617',
    de: 'Zwergpudel',
    en: 'Dwarf poodle',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '618',
    de: 'Pug',
    en: 'Pug',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '619',
    de: 'Puwo',
    en: 'Pulp',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '620',
    de: 'Rajapalayam',
    en: 'Rajapalayam',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '621',
    de: 'Rastreador Brasileiro',
    en: 'Rastreador Brasileiro',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '622',
    de: 'Ratonero Bodeguero Andaluz',
    en: 'Ratonero Bodeguero Andaluz',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '623',
    de: 'Ratonero Mallorqu\u00edn',
    en: 'Ratonero Mallorqu\u00edn',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '624',
    de: 'Ratonero Valenciano',
    en: 'Ratonero Valenciano',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '625',
    de: 'Römischer Kampfhund',
    en: 'Roman battle dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '626',
    de: 'Ruby',
    en: 'Ruby',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '627',
    de: 'Russkiy Toy',
    en: 'Russkiy Toy',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '628',
    de: 'Saarloos-Wolfhund',
    en: 'Saarloos Wolfhund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '629',
    de: 'Sanshu',
    en: 'Sanshu',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '630',
    de: 'Sapsali',
    en: 'Sapsali',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '631',
    de: 'Belgischer Schäferhund Malinois',
    en: 'Belgian Shepherd Malinois',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '632',
    de: 'Belgischer Schäferhund Tervueren',
    en: 'Belgian German Shepherd Tervueren',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '633',
    de: 'Kanadischer Schäferhund',
    en: 'Canadian Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '634',
    de: 'Osteuropäischer Schäferhund',
    en: 'Eastern European Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '635',
    de: 'Portugiesischer Schäferhund',
    en: 'Portuguese German Shepherd',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '636',
    de: 'Rumänischer Schäferhund',
    en: 'Romanian shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '637',
    de: 'Schottischer Schäferhund',
    en: 'Scottish shepherd',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '638',
    de: 'Ungarischer Schäferhund',
    en: 'Hungarian shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '639',
    de: 'Zentralasiatischer Schäferhund',
    en: 'Central Asian Shepherd',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '640',
    de: 'Belgischer Schäferhund Groenandael',
    en: 'Belgian Shepherd Groenandael',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '641',
    de: 'Belgischer Schäferhund Laekenois',
    en: 'Belgian Shepherd Laekenois',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '642',
    de: 'Schensi Hunde',
    en: 'Slensi dogs',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '643',
    de: 'Schipperke',
    en: 'Sailing',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '644',
    de: 'Mittelschnauzer',
    en: 'Medium -sized',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '645',
    de: 'Segusier',
    en: 'Segusian',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '646',
    de: 'Siegerländer Kuhhund',
    en: 'Siegerländer cowhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '647',
    de: 'Silken Windsprite',
    en: 'Silken Windsprite',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '648',
    de: 'Boykin Spaniel',
    en: 'Boykin Spaniel',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '649',
    de: 'Französischer Spaniel',
    en: 'French Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '650',
    de: 'Kontinentaler Zwergspaniel',
    en: 'Continental dwarf spaniel',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '651',
    de: 'Norfolk Spaniel',
    en: 'Norfolk Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '652',
    de: 'Gro\u00dfspitz',
    en: 'Gro\u00dfpitz',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '653',
    de: 'Holländischer Spitz',
    en: 'Dutch point',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '654',
    de: 'Italienischer Kleinspitz',
    en: 'Italian small spitz',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '655',
    de: 'Kleinspitz',
    en: 'Small tip',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '656',
    de: 'Mittelspitz',
    en: 'Medium -spitz',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '657',
    de: 'Wolfsspitz',
    en: 'Wolfspitz',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '658',
    de: 'Zwergspitz',
    en: 'Dwarf',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '659',
    de: 'St.-Bernhards-Hund',
    en: 'St. Bernhards dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '660',
    de: 'Taigan',
    en: 'Taigan',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '661',
    de: 'Taiwanhund',
    en: 'Taiwanhund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '662',
    de: 'Tamaskan',
    en: 'Tamascan',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '663',
    de: 'Telomian',
    en: 'Telomian',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '664',
    de: 'T\u00e9n\u00e9riffe',
    en: 'T\u00e9n\u00e9riffs',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '665',
    de: 'Tepeizeuintli',
    en: 'Teepezeuzeuimtli',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '666',
    de: 'American Hairless Terrier',
    en: 'American Hairless Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '667',
    de: 'American Toy Terrier',
    en: 'American Toy Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '668',
    de: 'Biewer Terrier',
    en: 'Biewer Terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '669',
    de: 'Biewer Yorkshireterrier',
    en: 'Biewer Yorkshireterrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '670',
    de: 'Boston Terrier',
    en: 'Boston Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '671',
    de: 'Brasilianischer Terrier',
    en: 'Brazilian terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '672',
    de: 'English Bull Terrier',
    en: 'English Bull Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '673',
    de: 'Patterdale Terrier',
    en: 'Patterdale Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '674',
    de: 'Rat Terrier',
    en: 'Council Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '675',
    de: 'Silky Terrier',
    en: 'Silky Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '676',
    de: 'Tchiorny Terrier',
    en: 'Tchiorny Terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '677',
    de: 'Zwerg Bull Terrier',
    en: 'Dwarf Bull Terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '678',
    de: 'Tesem',
    en: 'Tesem',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '679',
    de: 'Texas Blue Lacy',
    en: 'Texas Blue Lacy',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '680',
    de: 'Thai Bangkaew Dog',
    en: 'Thai Bangkaew Dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '681',
    de: 'Thai Ridgeback',
    en: 'Thai Ridback',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '682',
    de: 'Torfhund',
    en: 'Peat dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '683',
    de: 'Tornjak',
    en: 'Tornjak',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '684',
    de: 'Treeing Tennessee Brindle',
    en: 'Treing Tennessee Brindle',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '685',
    de: 'Turnspit Dog',
    en: 'Turnpit Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '686',
    de: 'Ungarischer Vorstehhund',
    en: 'Hungarian occasion',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '687',
    de: 'Urhund',
    en: 'Original dog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '688',
    de: 'Urrador',
    en: 'Urban',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '689',
    de: 'Verdino',
    en: 'Verdino',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '690',
    de: 'Villano de las Encartaciones',
    en: 'Villano de Las Encartaciones',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '691',
    de: 'Villanuco de Las Encartaciones',
    en: 'Villanuco de Las Encartaciones',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '692',
    de: 'Vizsla',
    en: 'Vizla',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '693',
    de: 'Wachtel',
    en: 'quail',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '694',
    de: 'Welsh Sheepdog',
    en: 'Welsh Sheepdog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '695',
    de: 'Westerwälder Kuhhund',
    en: 'Westerwald cowhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '696',
    de: 'Windhund',
    en: 'Greyhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '697',
    de: 'Barsoi',
    en: 'Barsoi',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '698',
    de: 'Berber-Windhund',
    en: 'Berber wind dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '699',
    de: 'Chart Polski',
    en: 'Chart Polski',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '700',
    de: 'Deerhound',
    en: 'Deerhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '701',
    de: 'Silken Windhund',
    en: 'Silken greyhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '702',
    de: 'Tuareg-Windhund',
    en: 'Tuareg wind dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '703',
    de: 'Windspiel',
    en: 'Wind chime',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '704',
    de: 'Tschechoslowakischer Wolfshund',
    en: 'Czechoslovakian Wolfhund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '705',
    de: 'Xoloitzcuintle',
    en: 'Xoloitzcuintle',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '706',
    de: 'Miniature Bull Terrier',
    en: 'Miniature Bull Terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '707',
    de: 'Berger de Beauce',
    en: 'Berger de Beauce',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '708',
    de: 'Goralenhund',
    en: 'Goran dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '709',
    de: 'Karabas',
    en: 'Karabas',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '710',
    de: 'Kraski Ovcar',
    en: 'Kraski Ovcar',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '711',
    de: 'Liptak',
    en: 'Liptak',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '712',
    de: 'Mittelasiatischer Owtscharka',
    en: 'Central Asian Owscharka',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '713',
    de: 'Slovensky Cuvac',
    en: 'Slovensky Cuvac',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '714',
    de: 'American Akita',
    en: 'American Akita',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '715',
    de: 'Gro\u00dfer Schweizer Sennenhund',
    en: 'Great Swiss Sennen Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '716',
    de: 'American Bully',
    en: 'American Bully',
    visibility: true,
    OP4: 4,
    KV4: 4,
  },
  {
    id: '717',
    de: 'Old English Bulldog',
    en: 'Old English Bulldog',
    visibility: true,
    OP4: 4,
    KV4: 4,
  },
  {
    id: '718',
    de: 'Kurzhaarcollie',
    en: 'Short -haired',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '719',
    de: 'Afghan Hound',
    en: 'Afghan Hound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '720',
    de: 'Balutschi-Windhund',
    en: 'Balutschi wind dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '721',
    de: 'Atlas-Berghund',
    en: 'Atlas Mountain Dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '722',
    de: 'Aiidi',
    en: 'Aiidi',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '723',
    de: 'Ayidi',
    en: 'Ayidi',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '724',
    de: "Chien de l'Atlas",
    en: "Chien de l'Tlas",
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '725',
    de: 'Atlas Sheepdog',
    en: 'Atlas Sheepdog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '726',
    de: 'Waterside Terrier',
    en: 'Waterside Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '727',
    de: 'Bingley Terrier',
    en: 'Bingley Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '728',
    de: 'Akbas',
    en: 'Akbas',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '729',
    de: 'Akita Ken',
    en: 'Akita Ken',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '730',
    de: 'Japanischer Akita',
    en: 'Japanese Akita',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '731',
    de: 'Akita-Inu',
    en: 'Akita-Inu',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '732',
    de: 'Bully Kutta',
    en: 'Bully Kutta',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '733',
    de: 'Alano Espanol',
    en: 'Alano Espanol',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '734',
    de: 'Otto',
    en: 'Otto',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '735',
    de: 'Alpenländische-Erzgebirgler Dachsbracke',
    en: 'Alpine Erzgebirgler Dachsbracke',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '736',
    de: '\u00d6sterreichische Dachsbracke',
    en: 'Austrian Dachsbracke',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '737',
    de: 'Great Japanese Dog',
    en: 'Great Japanese Dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '738',
    de: 'Gro\u00dfer Japanischer Hund',
    en: 'Great Japanese dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '739',
    de: 'Amerikanische Bulldogge',
    en: 'American bulldog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '740',
    de: 'Old Country Bulldog',
    en: 'Old Country Bulldog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '741',
    de: 'Old English White',
    en: 'Old English White',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '742',
    de: 'Amerikanischer Nackthund',
    en: 'American nude and',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '743',
    de: 'Pit Bull',
    en: 'Pit Bull',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '744',
    de: 'APBT',
    en: 'Apbt',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '745',
    de: 'Amstaff',
    en: 'Amstaff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '746',
    de: 'Toy Fox Terrier',
    en: 'Toy Fox Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '747',
    de: "Chien d'eau am\u00e9ricain",
    en: "Chien d'Eau Am\u00e9ricain",
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '748',
    de: 'Perro de agua Americano',
    en: 'Perro de Agua Americano',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '749',
    de: 'American Foxhound',
    en: 'American Foxhound',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '750',
    de: 'Appenzellerhund',
    en: 'Appenzellerhund',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '751',
    de: 'Appezöller Bläss',
    en: 'Appezöller Bläs',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '752',
    de: 'Bouvier des Ardennes',
    en: 'Bouvier des Ardennes',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '753',
    de: 'Australischer Treibhund',
    en: 'Australian driving dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '754',
    de: 'Blue Heeler',
    en: 'Blue Heeler',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '755',
    de: "Hall's Heeler",
    en: "Hall's Heeler",
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '756',
    de: 'Queensland Heeler',
    en: 'Queensland Heeler',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '757',
    de: 'Australischer Schäferhund',
    en: 'Australian Shepherd',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '758',
    de: 'Australian Sheepdog',
    en: 'Australian Sheepdog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '759',
    de: 'Barb',
    en: 'Barb',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '760',
    de: 'Sydney Silky',
    en: 'Sydney Silky',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '761',
    de: 'Australian Silky',
    en: 'Australian Silky',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '762',
    de: 'Bakharwal Mastiff',
    en: 'Bakharwal Mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '763',
    de: 'Gujjar Watchdog',
    en: 'Gujjar Watchdog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '764',
    de: 'Kashmiri Bakharwal Dog',
    en: 'Kashmiri Bakharwal Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '765',
    de: 'Srpski Gonic',
    en: 'Srpski Gonic',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '766',
    de: 'Ilirski Gonic',
    en: 'Ilirski Gonic',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '767',
    de: 'Illyrischer Laufhund',
    en: 'Illyrian',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '768',
    de: 'Französischer Wasserhund',
    en: 'French water dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '769',
    de: 'Barzaia',
    en: 'Barzaia',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '770',
    de: 'Russischer Wolfshund',
    en: 'Russian wolfhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '771',
    de: 'Borzoi',
    en: 'Borzoi',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '772',
    de: 'Kongo-Terrier',
    en: 'Congo terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '773',
    de: 'Blauer Basset der Gascogne',
    en: 'Blue basset of the Gascogne',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '774',
    de: 'Basset',
    en: 'Basset',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '775',
    de: 'Highland Collie',
    en: 'Highland Collie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '776',
    de: 'Mountain Collie',
    en: 'Mountain Collie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '777',
    de: 'Hairy Mou ed Collie',
    en: 'Hairy Mou Ed Collie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '778',
    de: 'Bas-Rouge',
    en: 'BAS-ROUGE',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '779',
    de: 'Rothbury Terrier',
    en: 'Rothbury Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '780',
    de: 'Chien de Berger Belge',
    en: 'Chien de Berger Belge',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '781',
    de: 'Berger de Bergame',
    en: 'Berger de Bergame',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '782',
    de: 'Cane da pastore Bergamasco',
    en: 'Cane da Pastore Bergamasco',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '783',
    de: 'Bergamasco Shepherd Dog',
    en: 'Bergamasco Shepherd Dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '784',
    de: 'Perro de pastor Bergamasco',
    en: 'Perro de Pastor Bergamasco',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '785',
    de: 'Berger des Cevennes',
    en: 'Berger des cevennes',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '786',
    de: 'Berger de la Crau',
    en: 'Berger de la Crau',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '787',
    de: 'Berger de Picardie',
    en: 'Berger de Picardie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '788',
    de: 'Dürrbächler',
    en: 'Dürrbächler',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '789',
    de: 'Bouvier bernois',
    en: 'Bouvier Bernois',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '790',
    de: 'Bernese Mountain Dog',
    en: 'Bernese Mountain Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '791',
    de: 'Perro Boyero de montana Bernes',
    en: 'Perro Boyero de Montana Bernes',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '792',
    de: 'Chien du Saint-Bernard',
    en: 'Chien du Saint-Bernard',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '793',
    de: 'Saint Bernard Dog',
    en: 'Saint Bernard Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '794',
    de: 'Perro San Bernardo',
    en: 'Perro San Bernardo',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '795',
    de: 'Gelockter Bichon',
    en: 'Curved bichon',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '796',
    de: 'Biewer Yorkshire a la Pom-Pon',
    en: 'Biewer Yorkshire a la Pom-Pon',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '797',
    de: 'Himalayan Bisben Sheepdog',
    en: 'Himalayan Bisben Sheepdog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '798',
    de: 'Schwarz-roter Waschbärenhund',
    en: 'Black-red raccoon dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '799',
    de: 'Lacy Game Dog',
    en: 'Lacy Game Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '800',
    de: 'Lacy Hog Dog',
    en: 'Lacy Hog Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '801',
    de: 'Altenglischer Schäferhund',
    en: 'Elder English Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '802',
    de: 'Franzuskaya Bolonka',
    en: 'Franzuskaya Bolonka',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '803',
    de: 'Tsvetnaya Bolonka',
    en: 'Tsvetnaya Bolonka',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '804',
    de: 'Dogue de Bordeaux',
    en: 'Dogue de Bordeaux',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '805',
    de: 'Bordeaux Mastiff',
    en: 'Bordeaux Mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '806',
    de: 'Flandrischer Treibhund',
    en: 'Flandrian driving dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '807',
    de: 'Kärntner Bracke',
    en: 'Carinthian bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '808',
    de: 'Ariege-Vorstehhund',
    en: 'Arege-headed dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '809',
    de: 'Bourbonnaiser Vorstehhund',
    en: 'Bourbonnaiser',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '810',
    de: 'Braque Charles',
    en: 'Braque Charles',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '811',
    de: 'Braque Compiegne',
    en: 'Braque compiegne',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '812',
    de: 'Terrier Brasileiro',
    en: 'Terrier Brasileiro',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '813',
    de: 'Perro de Pastor Mallorqu\u00edn',
    en: 'Perro de Pastor Mallorqu\u00edn',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '814',
    de: 'Mallorca-Schäferhund',
    en: 'Mallorca head dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '815',
    de: 'Mallorca-Dogge',
    en: 'Mallorca matters',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '816',
    de: 'Mallorca Mastiff',
    en: 'Mallorca Mastiff',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '817',
    de: 'Perro Dogo Mallorquin',
    en: 'Perro Dogo Mallorquin',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '818',
    de: 'Cane Corso Italiano',
    en: 'Cane Corso Italiano',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '819',
    de: 'Italienischer Corso-Hund',
    en: 'Italian Corso dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '820',
    de: 'Corso-Hund',
    en: 'Corso dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '821',
    de: 'Cane Di Maccelaio',
    en: 'Cane di Maccelaio',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '822',
    de: 'American Dingo',
    en: 'American dingo',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '823',
    de: 'Dixie Dingo',
    en: 'Dixie dingo',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '824',
    de: 'North American Native Dog',
    en: 'North American Native Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '825',
    de: "Indian's Dog",
    en: "Indian's Dog",
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '826',
    de: 'Yellow Dog',
    en: 'Yellow Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '827',
    de: 'Yaller Dog',
    en: 'Yaller Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '828',
    de: 'Ciobanesc Romanesc Carpatin',
    en: 'Ciobanesc Romanesc Carpatin',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '829',
    de: 'Zavod',
    en: 'Zavod',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '830',
    de: 'Tschechischer Terrier',
    en: 'Czech terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '831',
    de: 'Polnischer Windhund',
    en: 'Polish greyhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '832',
    de: 'Artois Hound',
    en: 'Artois Hound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '833',
    de: 'Techichi',
    en: 'Techichi',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '834',
    de: 'Chihuahue\u00f1o',
    en: 'Chihuahue\u00f1o',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '835',
    de: 'Chinese Chongqing Dog',
    en: 'Chinese Chongqing Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '836',
    de: 'Thanjavurhund',
    en: 'Thanjavur dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '837',
    de: 'Chodsky pes',
    en: 'Chodsky pes',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '838',
    de: 'Chodenländer Hund',
    en: 'Choden countries dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '839',
    de: 'Chortaja Borzaja',
    en: 'Chortaja Borzaja',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '840',
    de: 'Hortaya Borzaya',
    en: 'Hortaya Borzaya',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '841',
    de: 'Hort',
    en: 'Hoard',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '842',
    de: 'Horty',
    en: 'Horty',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '843',
    de: 'Perro Criollo',
    en: 'Perro Criollo',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '844',
    de: 'Perro Gaucho',
    en: 'Perro Gaucho',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '845',
    de: 'Cockerpoo',
    en: 'Cocker poo',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '846',
    de: 'Cockapoo',
    en: 'Cockapoo',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '847',
    de: 'Indian Beardog',
    en: 'Indian Beardog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '848',
    de: 'Chien Corse',
    en: 'Chien Corse',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '849',
    de: 'Kurzhaar Dackel',
    en: 'Shorthair Dachshund',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '850',
    de: 'Langhaar Dackel',
    en: 'Long -haired dachshund',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '851',
    de: 'Rauhaar Dackel',
    en: 'Radhaar Dachshund',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '852',
    de: 'Dalmatinac',
    en: 'Dalmatinac',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '853',
    de: 'Sällskapshund',
    en: 'Sällkapshund',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '854',
    de: 'Dänisch-Schwedischer Farmhund',
    en: 'Danish-Swedish farm dog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '855',
    de: 'Scottish Deerhound',
    en: 'Scottish Deerhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '856',
    de: 'Highland Deerhound',
    en: 'Highland Deerhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '857',
    de: 'Schottischer Hirschhund',
    en: 'Scottish deer dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '858',
    de: 'Westfälische Bracke',
    en: 'Westfälische Bracke',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '859',
    de: 'Olper Bracke',
    en: 'Olper Bracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '860',
    de: 'Sauerländer Bracke',
    en: 'Sauerländer Bracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '861',
    de: 'Ulmer Dogge',
    en: 'Ulm mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '862',
    de: 'Hatzrüde',
    en: 'Hatzrüde',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '863',
    de: 'Saupacker',
    en: 'Saupacker',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '864',
    de: 'Gro\u00dfe Dogge',
    en: 'Big mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '865',
    de: 'Altdeutscher Schäferhund',
    en: 'Old German Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '866',
    de: 'Pomeranian',
    en: 'Pomeranian',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '867',
    de: 'Deutscher Wachtel',
    en: 'German quail',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '868',
    de: 'Dobermannpinscher',
    en: 'Dobermanpinscher',
    visibility: true,
    OP4: 4,
    KV4: 4,
  },
  {
    id: '869',
    de: 'Argentinischer Mastiff',
    en: 'Argentine Mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '870',
    de: 'Canary Dog',
    en: 'Canary Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '871',
    de: 'Dunkerbracke',
    en: 'Dunkerbracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '872',
    de: 'Dunkerlaufhund',
    en: 'Dunkerlaufhund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '873',
    de: 'American English Coonhound',
    en: 'American English Coonhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '874',
    de: 'Brittany',
    en: 'Brittany',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '875',
    de: 'Bretonischer Vorstehhund',
    en: 'Bretonian',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '876',
    de: 'Pont-Audemer-Spaniel',
    en: 'Pont-Audemer-Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '877',
    de: 'Picardie-Spaniel',
    en: 'Picardie spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '878',
    de: 'Eurohound',
    en: 'EuroHound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '879',
    de: 'Finnischer Spitz',
    en: 'Finnish pointed',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '880',
    de: 'Lapinkoira',
    en: 'Lapinkoira',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '881',
    de: 'Französischer Wei\u00df-Schwarzer Laufhund',
    en: 'French white and black drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '882',
    de: 'Französischer Wei\u00df-Oranger Laufhund',
    en: 'French white-orange drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '883',
    de: 'Französischer Dreifarbiger Laufhund',
    en: 'French three -color drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '884',
    de: 'Bouledogue francais',
    en: 'Bouledogue Francais',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '885',
    de: 'Irish Glen of Imaal Terrier',
    en: 'Irish Glen of Imaal Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '886',
    de: 'Gro\u00dfer Basset Griffon Vend\u00e9en',
    en: 'Big Basset Griffon Vend\u00e9en',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '887',
    de: 'Gro\u00dfer Blauer Gascogner Laufhund',
    en: 'Big Blue Gascogner Drive Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '888',
    de: 'Gro\u00dfer Englischer Windhund',
    en: 'Great English greyhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '889',
    de: 'Grand Griffon Nivernais',
    en: 'Grand Griffon Nivernais',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '890',
    de: 'Chien de Pays',
    en: 'Chien de Pays',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '891',
    de: 'Haldenstövare',
    en: 'Haldenstövare',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '892',
    de: 'Haldenstover',
    en: 'Haldstover',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '893',
    de: 'Haldenbracke',
    en: 'Haldenbracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '894',
    de: 'Haldenlaufhund',
    en: 'Hald run',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '895',
    de: 'Halden-Stövare',
    en: 'Halden-Stövare',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '896',
    de: 'Neuguinea-Dingo',
    en: 'New Guinea thing',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '897',
    de: 'Hamiltonstövare',
    en: 'Hamiltonstövare',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '898',
    de: 'Hamilton-Laufhund',
    en: 'Hamilton Run dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '899',
    de: 'Hamilton-Bracke',
    en: 'Hamilton Bracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '900',
    de: 'Bichon Habanero',
    en: 'Bichon Habanero',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '901',
    de: 'Griechischer Laufhund',
    en: 'Greek drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '902',
    de: 'Griechische Bracke',
    en: 'Greek bracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '903',
    de: 'Hokkaido-Hund',
    en: 'Hokkaido dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '904',
    de: 'Ainu-Hund',
    en: 'Ainu dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '905',
    de: 'Ainu Inu',
    en: 'Ainu Inu',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '906',
    de: 'Hokkaido-Ken',
    en: 'Hokkaido-Ken',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '907',
    de: 'Hollandse Herdershond',
    en: 'Hollandse Herdershond',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '908',
    de: 'Herder',
    en: 'Herder',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '909',
    de: 'Slowakischer Rauhbart',
    en: 'Slovakian rough beard',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '910',
    de: 'Hygenlaufhund',
    en: 'Hygenlauf dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '911',
    de: 'Irischer Rot-Wei\u00dfer Setter',
    en: 'Irish red and white setting',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '912',
    de: 'Isländischer Spitz',
    en: 'Icelandic point',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '913',
    de: 'Isländischer Schäferhund',
    en: 'Icelandic German Shepherd',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '914',
    de: 'Lupo Italiano',
    en: 'Lupo Italiano',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '915',
    de: 'Italian Greyhound',
    en: 'Italian Greyhound',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '916',
    de: 'Piccolo Levriero Italiano',
    en: 'Piccolo Levriero Italiano',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '917',
    de: 'Nippon-Terrier',
    en: 'Nippon terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '918',
    de: 'Nishon-Terrier',
    en: 'Nishon terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '919',
    de: 'Japanischer Spitz',
    en: 'Japanese point',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '920',
    de: 'Dreifarbiger Serbischer Laufhund',
    en: 'Three -colored Serbian drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '921',
    de: 'Inu',
    en: 'Inu',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '922',
    de: 'Tora Inu',
    en: 'Tora Inu',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '923',
    de: 'Kai tora-ken',
    en: 'Kai Tora-Ken',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '924',
    de: 'Israel Spitz',
    en: 'Israel Spitz',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '925',
    de: 'Canaan Dog',
    en: 'Canaan Dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '926',
    de: 'Karabash',
    en: 'Carabash',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '927',
    de: 'Bulgarischer Schäferhund',
    en: 'Bulgarian German Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '928',
    de: 'Karjalankarhukoira',
    en: 'Karjalankarhukoira',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '929',
    de: 'Björnhund',
    en: 'Björnhund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '930',
    de: 'Karsthund',
    en: 'Karst dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '931',
    de: 'Gos d\u2019Atura Catala',
    en: 'GOS d\u2019Artura Catala',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '932',
    de: 'Kaukase',
    en: 'Chewing',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '933',
    de: 'Caucasian Owtscharka',
    en: 'Caucasian Owscharka',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '934',
    de: 'Kaukasischer Schäferhund',
    en: 'Caucasian Shepherd',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '935',
    de: 'Kawkasky Owtscharka',
    en: 'Kawkasky Owscharka',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '936',
    de: 'Kavkazskaia Ovtcharka',
    en: 'Kavkazkaia Ovtcharka',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '937',
    de: 'Pocadan',
    en: 'Pocadan',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '938',
    de: 'Blauer Irischer Terrier',
    en: 'Blue Irish terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '939',
    de: 'English Toy Spaniel',
    en: 'English toy Spaniel',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '940',
    de: 'Anjing Kintamani Bali',
    en: 'Anjing Kintamani Bali',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '941',
    de: 'Kishu-Ken',
    en: 'Kishu-Ken',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '942',
    de: 'Ungarischer Hirtenhund',
    en: 'Hungarian shepherd dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '943',
    de: 'Schmetterlingshündchen',
    en: 'Butterfly dogs',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '944',
    de: 'Slowakischer Laufhund',
    en: 'Slovakian drive',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '945',
    de: 'Jindo-kae',
    en: 'Jindo-Kae',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '946',
    de: 'Jindo-kyon',
    en: 'Jindo-Kyon',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '947',
    de: 'Griffon a Poil Dur',
    en: 'Griffon a Poil Dur',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '948',
    de: 'Istarski Kratkodlaki Gonic',
    en: 'IStarski Kratkodlaki Gonic',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '949',
    de: 'Kuvacs',
    en: 'Kuvacs',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '950',
    de: 'Lagotto Romagnolo',
    en: 'Lagotto Romagnolo',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '951',
    de: 'Wasserhund der Romagna',
    en: 'Romagna water dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '952',
    de: 'Ormskirk Heeler',
    en: 'Ormskirk Heeler',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '953',
    de: 'Finnischer Lapplandhirtenhund',
    en: 'Finnish Laplandhirten dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '954',
    de: 'Lhasaterrier',
    en: 'Lhasatersrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '955',
    de: 'Löwenhund',
    en: 'Lion dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '956',
    de: 'Catahoula Cur',
    en: 'Catahoula Cur',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '957',
    de: 'Maremmaner Berghund',
    en: 'Maremmaner Mountain Dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '958',
    de: 'Hollandse Tulphond',
    en: 'Hollandse Tulphond',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '959',
    de: 'Old English Mastiff',
    en: 'Old English Mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '960',
    de: 'Mast\u00edn del Pirineo',
    en: 'Mast\u00edn del Pirineo',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '961',
    de: 'Pyrenäen-Mastiff',
    en: 'Pyrenees-Mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '962',
    de: 'Spanischer Mastiff',
    en: 'Spanish mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '963',
    de: 'Mastin leon\u00e9s',
    en: 'Mastin Leon\u00e9s',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '964',
    de: 'Mastin extremeno',
    en: 'Mastine Extremeo',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '965',
    de: 'Mastin manchego',
    en: 'Mastin Manchego',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '966',
    de: 'Neapolitanischer Mastiff',
    en: 'Neapolitan Mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '967',
    de: 'Miniature American Shepherd',
    en: 'Miniature American Shepherd',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '968',
    de: 'Miniatur Bullterrier',
    en: 'Miniature bull terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '969',
    de: 'Mini-Bullterrier',
    en: 'Mini bull terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '970',
    de: 'Ciobanesc Romanesc Mioritic',
    en: 'Ciobanesc Romanesc Mioritic',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '971',
    de: 'Mocano',
    en: 'Mocano',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '972',
    de: 'Barac',
    en: 'Barac',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '973',
    de: 'Mittelasiatischer Schäferhund',
    en: 'Central Asian Shepherd',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '974',
    de: 'Tasi',
    en: 'Tasi',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '975',
    de: 'Tazy',
    en: 'Tazy',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '976',
    de: 'Sredneaziatskaya Borzaya',
    en: 'Sredneaziatskaya Borzaya',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '977',
    de: 'Kasachskaja Tazi',
    en: 'Kazakhskaya Tazi',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '978',
    de: 'Montenegrinische Gebirgsbracke',
    en: 'Montenegrinische Gebirgsbracke',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '979',
    de: 'Carlin',
    en: 'Carlin',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '980',
    de: 'Carlino',
    en: 'Carlino',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '981',
    de: 'Doguillo',
    en: 'Doguillo',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '982',
    de: 'Perro Gocho',
    en: 'Perro Gocho',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '983',
    de: 'Perro de los Paramos',
    en: 'Perro de los Paramos',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '984',
    de: 'Nederlandse Kooikerhondje',
    en: 'Nederlandse Kooikerhondje',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '985',
    de: 'Schweizerischer Niederlaufhund',
    en: 'Swiss low -litter dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '986',
    de: 'Berner Niederlaufhund Glatthaar',
    en: 'Bernese Lower Run dog smooth hair',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '987',
    de: 'Berner Niederlaufhund Rauhhaar',
    en: 'Bernese Lower Run dog Raughaar',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '988',
    de: 'Jura Niederlaufhund',
    en: 'Jura Lower Run dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '989',
    de: 'Luzerner Niederlaufhund',
    en: 'Lucerne Lower Run dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '990',
    de: 'Schwyzer Niederlaufhund',
    en: 'Schwyzer Lower Run dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '991',
    de: 'Pohjanpystykorvat',
    en: 'Pohjanpystykorvat',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '992',
    de: 'Sort Dyrehund',
    en: 'Sort Dyrehund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '993',
    de: 'Norsk Lundehund',
    en: 'Norsk Lundefund',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '994',
    de: 'Nova Scotia Retriever',
    en: 'Nova Scotia Retriever',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '995',
    de: 'Olde English Bulldog',
    en: 'Olde English Bulldog',
    visibility: true,
    OP4: 4,
    KV4: 4,
  },
  {
    id: '996',
    de: 'Leavitt Bulldog',
    en: 'Leavitt Bulldog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '997',
    de: 'Hound',
    en: 'Hound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '998',
    de: 'Perdiguero Navarro',
    en: 'Perdiguero Navarro',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '999',
    de: 'Old Spanish Pointer',
    en: 'Old Spanish Pointer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1000',
    de: 'Navarro Pointer',
    en: 'Navarro Pointer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1001',
    de: 'Pachon De Victoria',
    en: 'Pachon de Victoria',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1002',
    de: 'Pastor Vasco',
    en: 'Pastor Vasco',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1003',
    de: 'Euskal Artzain Txakurra',
    en: 'Euskal Artzain Txakurra',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1004',
    de: 'Pekingese',
    en: 'Beijingesis',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1005',
    de: 'Portugiesischer Vorstehhund',
    en: 'Portuguese base dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1006',
    de: 'Perro Turco',
    en: 'Perro Turco',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1007',
    de: 'Türkenhund',
    en: 'Turkish dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1008',
    de: 'Turco Andaluz',
    en: 'Turco Andaluz',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1009',
    de: 'Andalusischer Türke',
    en: 'Andalusian Turk',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1010',
    de: 'Majorero Canario',
    en: 'Majorero Canario',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1011',
    de: 'Perro Bardino Majorero',
    en: 'Perro Bardino Majorero',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1012',
    de: 'Viringo',
    en: 'Viringo',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1013',
    de: 'Kleiner Basset Griffon Vend\u00e9en',
    en: 'Small basset Griffon Vend\u00e9en',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1014',
    de: 'Kleiner Blauer Gascogne Laufhund',
    en: 'Little Blue Gascogne Drive Dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1015',
    de: 'Brabanter Griffon',
    en: 'Brabant Griffon',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1016',
    de: 'Kanarischer Podenco',
    en: 'Canarian Podenco',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1017',
    de: 'Ca Eivissec',
    en: 'CA EIVISSEC',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1018',
    de: 'Mallorqui',
    en: 'Mallorqui',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1019',
    de: 'Xarnelo',
    en: 'Xarnelo',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1020',
    de: 'Mayorquais',
    en: 'Mayorquais',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1021',
    de: 'Charnegue',
    en: 'Charnegue',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1022',
    de: 'Charnegui',
    en: 'Charnegui',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1023',
    de: 'Haut-Poitou',
    en: 'Skin-poitou',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1024',
    de: 'Polish Hound',
    en: 'Polish Hound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1025',
    de: 'Brachet polonais',
    en: 'Brachet Polonais',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1026',
    de: 'Sabueso Polaco',
    en: 'Sabueso Polaco',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1027',
    de: 'Gonczy Polski',
    en: 'Gonczy polski',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1028',
    de: 'Polish Hunting Dog',
    en: 'Polish Hunting Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1029',
    de: 'Polish Scenthound',
    en: 'Polish scenthound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1030',
    de: 'PON',
    en: 'Pon',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1031',
    de: 'Chien de Franche-Comt\u00e9',
    en: 'Chien de Franche-Comt\u00e9',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1032',
    de: 'Caniche',
    en: 'Caniche',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1033',
    de: 'Berger des Pyrenees a face rase',
    en: 'Berger des Pyrenees a face rase',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1034',
    de: 'Berger des Pyrenees a poil long',
    en: 'Berger des Pyrenees a Poil Long',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1035',
    de: 'Berger des Pyrenees au museau normal',
    en: 'Berger of the Pyrenees au Museau normal',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1036',
    de: 'Patou',
    en: 'Patou',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1037',
    de: 'Poligar Hound',
    en: 'Poligar Hound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1038',
    de: 'Rampuri',
    en: 'Rampuri',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1039',
    de: 'Rampuree',
    en: 'Rampure',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1040',
    de: 'Ratonero Andaluz',
    en: 'Ratonero Andaluz',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1041',
    de: 'Andalusian Mouse-Hunting Dog',
    en: 'Andalusian Mouse-Hunting Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1042',
    de: 'Bodeguero Andaluz',
    en: 'Bodeguero Andaluz',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1043',
    de: 'Perro Ratonero Bodeguero',
    en: 'Perro Ratonero Bodeguero',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1044',
    de: 'Ratero',
    en: 'Ratero',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1045',
    de: 'Gos Rater Valenci\u00e0',
    en: 'GOS Rater Valenci\u00e0',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1046',
    de: 'Ca de Rater Valencia',
    en: 'Ca de Rater Valencia',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1047',
    de: 'Russischer Toy',
    en: 'Russian toy',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1048',
    de: 'Russischer Zwergspaniel',
    en: 'Russian dwarf spaniel',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1049',
    de: 'Moskauer Langhaariger Toy Terrier',
    en: 'Moscow long -haired Toy Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1050',
    de: 'Spanischer Laufhund',
    en: 'Spanish drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1051',
    de: 'Samoiedska\u00efa Sabaka',
    en: 'Samoiedska\u00efa Sabaka',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1052',
    de: 'Sapsaree',
    en: 'Sapsaree',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1053',
    de: 'Mazedonischer Hirtenhund',
    en: 'Macedonian shepherd dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1054',
    de: 'Save-Bracke',
    en: 'Save bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1055',
    de: 'Posavatz-Bracke',
    en: 'Posavatz-Bracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1056',
    de: 'Posavski Gonic',
    en: 'Posavski Gonic',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1057',
    de: 'Posavatz Laufhund',
    en: 'Posavatz drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1058',
    de: 'Nederlandse Schapendoes',
    en: 'Nederlandse Schapendoes',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1059',
    de: 'Niederländischer Schapendoes',
    en: 'Dutch Schapendoes',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1060',
    de: 'Schiller-Laufhund',
    en: 'Schiller run dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1061',
    de: 'Schiller-Bracke',
    en: 'Schiller-Bracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1062',
    de: 'Russischer Terrier',
    en: 'Russian terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1063',
    de: 'Chinesischer Kampfhund',
    en: 'Chinese fighting dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1064',
    de: 'Shiba',
    en: 'Shiba',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1065',
    de: 'Shikoku',
    en: 'Shikoku',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1066',
    de: 'Kochi-Ken',
    en: 'Kochi-Ken',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1067',
    de: 'Nordafrikanischer Windhund',
    en: 'North African greyhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1068',
    de: 'Oskai',
    en: 'Oskai',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1069',
    de: 'Oskay',
    en: 'Oskay',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1070',
    de: 'Smaland-Laufhund',
    en: 'Smaland run dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1071',
    de: 'Smaland-Bracke',
    en: 'Smaland bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1072',
    de: 'Irish Soft Coated Wheaten Terrier',
    en: 'Irish Soft Coated Wheate Terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1073',
    de: 'Italienischer Rauhhaariger Vorstehhund',
    en: 'Italian shear -haired upright dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1074',
    de: 'Stabij',
    en: 'Stabij',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1075',
    de: 'Stabyhoun',
    en: 'Stbyhoun',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1076',
    de: 'Englischer Stafford',
    en: 'English Stafford',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1077',
    de: 'Nurse Maid',
    en: 'Nurse maid',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1078',
    de: 'Staffie',
    en: 'Staff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1079',
    de: 'The Nanny Dog',
    en: 'The Nanny Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1080',
    de: 'Staff',
    en: 'Staff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1081',
    de: 'Steirische Rauhhaarbracke',
    en: 'Styrian Rauhhaarbracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1082',
    de: 'Peintinger-Bracke',
    en: 'Peintinger-Bracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1083',
    de: 'Südrussischer Schäferhund',
    en: 'South Russian Shepherd',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1084',
    de: 'Ioujnorousska\u00efa Ovtcharka',
    en: 'Ioujnorousska\u00efa Ovtcharka',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1085',
    de: 'Kirghiz Barzoi',
    en: 'Kirghiz Barzoi',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1086',
    de: 'Tajgan',
    en: 'Tajgan',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1087',
    de: 'Tamaskan Husky',
    en: 'Tamascan Husky',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1088',
    de: 'Tamaskan Dog',
    en: 'Tamascan Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1089',
    de: 'Tamaskan Wolf-dog',
    en: 'Tamaskan Wolf-Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1090',
    de: 'Finnish Tamaskan Dog',
    en: 'Finnish Tamaskan Dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1091',
    de: 'Tibetan Terrier',
    en: 'Tibetan Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1092',
    de: 'Tsang Apso',
    en: 'Tsang Apso',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1093',
    de: 'Bosnisch-herzegowinischer-kroatischer Schäferhund',
    en: 'Bosnian-Herzegovinian-Croatian Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1094',
    de: 'Tosa Ken',
    en: 'Tosa Ken',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1095',
    de: 'Tosa Token',
    en: 'Tosa token',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1096',
    de: 'Tosa',
    en: 'Tosa',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1097',
    de: 'Japanischer Kampfhund',
    en: 'Japanese combat dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1098',
    de: 'Treeing Tennessee Brindle Coonhound',
    en: 'Treing Tennessee Brindle Coonhound',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1099',
    de: 'Westgotenspitz',
    en: 'West Gothspitz',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1100',
    de: 'Swedish Cattle Dog',
    en: 'Swedish Cattle Dog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1101',
    de: 'Schwedischer Vallhund',
    en: 'Swedish Vallhund',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1102',
    de: 'Italienischer Volpino',
    en: 'Italian Volpino',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1103',
    de: 'Ci Defaid Cymreig',
    en: 'Ci Defaid CymreiG',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1104',
    de: 'Bugeilgi',
    en: 'Bugeilgi',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1105',
    de: 'Welsh Collie',
    en: 'Welsh Collie',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1106',
    de: 'Wei\u00dfer Hochlandterrier',
    en: 'White highland terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1107',
    de: 'Westie',
    en: 'Westie',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1108',
    de: 'Poltalloch Terrier',
    en: 'Poltal hole Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1109',
    de: 'Roseneath Terrier',
    en: 'Roseath Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1110',
    de: 'Wetterhond',
    en: 'Wetterhond',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1111',
    de: 'Kleiner Englischer Windhund',
    en: 'Little English greyhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1112',
    de: 'Miniature Pinscher',
    en: 'Miniature Pinscher',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1113',
    de: 'Minpin',
    en: 'Minpine',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1114',
    de: 'Minidoberman',
    en: 'Minidoberman',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1115',
    de: 'Rehrattler',
    en: 'Rehrattler',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1116',
    de: 'Staffordshire Terrier',
    en: 'Staffordshire Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1117',
    de: 'Cao de Fila',
    en: 'Cao de Fila',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1118',
    de: 'Türkischer Hirtenhund',
    en: 'Turkish shepherd dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1119',
    de: 'Türkischer Schäferhund',
    en: 'Turkish German Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1120',
    de: 'Anatolier',
    en: 'Anatolian',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1121',
    de: 'Sivas Kangal',
    en: 'Sivas Kangal',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1122',
    de: 'Tibetanischer Mastiff',
    en: 'Tibetan mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1123',
    de: 'Irish Staffordshire Terrier',
    en: 'Irish Staffordshire Terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1124',
    de: 'Canary Mastiff',
    en: 'Canary Mastiff',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1125',
    de: 'Mastin Canario',
    en: 'Mastine Canario',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1126',
    de: 'Karakatschan',
    en: 'Karakatsch',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1127',
    de: 'SBT',
    en: 'SBT',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1128',
    de: 'Mini Staffordshire Bullterrier',
    en: 'Mini Staffordshire Bull Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1129',
    de: 'Mastin de Aragon',
    en: 'Mastin de Aragon',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1130',
    de: 'Alaunt',
    en: 'Alum',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1131',
    de: 'Toy-Pudel',
    en: 'Toy poodle',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1132',
    de: 'Kokoni',
    en: 'Cocoon',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1133',
    de: 'Neue Englische Bulldogge',
    en: 'New English bulldog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1134',
    de: 'Small Greek Domestic Dog',
    en: 'Small Greek Domestic Dog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1135',
    de: 'Retro-Mops',
    en: 'Retro-mops',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1136',
    de: 'Amerikanischer Cocker Spaniel',
    en: 'American cocker Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1137',
    de: 'Anglo-Fran\u00e7ais de petite v\u00e9nerie',
    en: 'Anglo-Fran\u00e7ais de Petite V\u00e9nerie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1138',
    de: 'Basset art\u00e9sien normand',
    en: 'Basset Art\u00e9sien Normand',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1139',
    de: "Basset d'Artois",
    en: "Basset d'Artois",
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1140',
    de: 'Beagle-Harrier',
    en: 'Beagle-Harrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1141',
    de: 'Bergamasker Hirtenhund',
    en: 'Bergamasker shepherd dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1142',
    de: 'Bichon Fris\u00e9',
    en: 'Bichon Fris\u00e9',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1143',
    de: 'Wei\u00dfer Schweizer Schäferhund',
    en: 'White Swiss Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1144',
    de: 'Bordeauxdogge',
    en: 'Bordeaux',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1145',
    de: 'Chinesischer Schopfhund',
    en: 'Chinese skip dog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1146',
    de: 'English Bulldog',
    en: 'English Bulldog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1147',
    de: 'English Cocker Spaniel',
    en: 'English Cocker Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1148',
    de: 'Pyrenäenschäferhund',
    en: 'Pyrenea',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1149',
    de: 'Foxterrier',
    en: 'Foxterrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1150',
    de: 'Franz. Rauhaariger Vorstehhund',
    en: 'Franz. Rauhaariger Posschund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1151',
    de: 'Friesischer Vorstehhund',
    en: 'Frisian',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1152',
    de: 'Goldendoodle',
    en: 'Gold duties',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1153',
    de: 'Grönlandhund',
    en: 'Greenland dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1154',
    de: 'Gro\u00dfer Münsterländer',
    en: 'Great Münsterländer',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1155',
    de: 'Haldenst\u00f8ver',
    en: 'Haldenst\u00f8ver',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1156',
    de: 'Istrianer Bracke',
    en: 'Istrian Bracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1157',
    de: 'Italienischer Spinone',
    en: 'Italian spinones',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1158',
    de: 'Japan Spitz',
    en: 'Japan Spitz',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1159',
    de: 'Kanaanhund',
    en: 'Kanaan dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1160',
    de: 'Katalanischer Schäferhund',
    en: 'Catalan Shepherd',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1161',
    de: 'Labrador Retriever',
    en: 'Labrador Retriever',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1162',
    de: 'Langhaarcollie',
    en: 'Long -haired collie',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1163',
    de: 'Lappländischer Rentierhund',
    en: 'Lapland reindeer dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1164',
    de: 'Löwchen',
    en: 'Lion',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1165',
    de: 'Maremmen-Abruzzen-Schäferhund',
    en: 'Maremmemen-Abruzzo-Schäferhund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1166',
    de: 'Nova Scotia Duck Tolling Retriever',
    en: 'Nova Scotia Duck Tolling Retriever',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1167',
    de: 'Parson Russell Terrier',
    en: 'Parson Russell Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1168',
    de: 'Pit Bull Terrier',
    en: 'Pit Bull Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1169',
    de: 'English Springer Spaniel',
    en: 'English Springer Spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1170',
    de: 'Saarlooswolfhund',
    en: 'Saarlooswolfhund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1171',
    de: 'Serbischer Laufhund',
    en: 'Serbian drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1172',
    de: 'Stichelhaariger Bosnischer Laufhund',
    en: 'Hair -haired Bosnian drive dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1173',
    de: 'Tatra-Hirtenhund',
    en: 'Tatra-Hirten dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1174',
    de: 'Tibet-Spaniel',
    en: 'Tibet spaniel',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1175',
    de: 'Tibet-Terrier',
    en: 'Tibet terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1176',
    de: 'Alaskan Indian Dog',
    en: 'Alaskan Indian Dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1177',
    de: 'Alternativ Bulldog',
    en: 'Alternatively Bulldog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1178',
    de: 'Argentinische Bulldogge',
    en: 'Argentine Bulldogge',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1179',
    de: 'Atlas Schäferhund',
    en: 'Atlas Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1180',
    de: 'Basset Griffon petit',
    en: 'Basset Griffon Petit',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1181',
    de: 'Basset Griffon Vendeen',
    en: 'Basset Griffon Vendenen',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1182',
    de: 'Bosnische Bracke',
    en: 'Bosnian bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1183',
    de: 'Bosnische Rauhhaarbracke',
    en: 'Bosnian Rauhhaarbracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1184',
    de: 'Bouvier',
    en: 'Bouvier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1185',
    de: 'Braque Francais Typ Gascogne',
    en: 'Braque Francais Type Gascogne',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1186',
    de: 'Braque Francais Typ Pyr\u00e9n\u00e9es',
    en: 'Braque Francais type Pyr\u00e9n\u00e9es',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1187',
    de: 'Bullterrier Miniature',
    en: 'Bull terrier miniature',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1188',
    de: 'Bullterrier Stafford Shire',
    en: 'Bull terrier Stafford Shire',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1189',
    de: 'Coban Hirtenhund',
    en: 'Coban shepherd dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1190',
    de: 'Dänisch-Schwedischer Hofhund',
    en: 'Danish-Swedish court dog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1191',
    de: 'Drahthaar Foxterrier',
    en: 'Wirehaar Fox Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1192',
    de: 'English Pitbull Terrier',
    en: 'English Pitbull Terrier',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1193',
    de: 'Elchhund',
    en: 'Moose dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1194',
    de: 'Ellenikos Ichnilatis',
    en: 'Ellenikos ichnilatis',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1195',
    de: 'Fell Terrier',
    en: 'Fell Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1196',
    de: 'Finnischer Rentierhütehund',
    en: 'Finnish reindeer dog dog',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1197',
    de: 'Flämischer Treibhund',
    en: 'Flemish driving dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1198',
    de: 'German Shepherd',
    en: 'German Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1199',
    de: 'Glatthaar Foxterrier',
    en: 'Smooth hair fox terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1200',
    de: 'Griffon Bleu des Gascogne',
    en: 'Griffon Bleu des Gascogne',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1201',
    de: 'Griffon Korthals',
    en: 'Griffon Korthals',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1202',
    de: 'Harlekin Pudel',
    en: 'Harlekin Pudel',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1203',
    de: 'Hertha Pointer',
    en: 'Hertha pointer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1204',
    de: 'Holländischer Schnauzer',
    en: 'Dutch schnauzer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1205',
    de: 'Holzbracke',
    en: 'Wooden bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1206',
    de: 'Irish Blue Terrier',
    en: 'Irish Blue Terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1207',
    de: 'Istrianer Schäferhund',
    en: 'Istrian Shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1208',
    de: 'Italienischer Spitz',
    en: 'Italian point',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1209',
    de: 'Jugoslawische Dreifarbige Bracke',
    en: 'Yugoslav three -color bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1210',
    de: 'Jura Typ St. Hubert Laufhund',
    en: 'Jura Type St. Hubert Laufhund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1211',
    de: 'Jura Typ Bruno Laufhund',
    en: 'Jura Type Bruno Drive Dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1212',
    de: 'King Charles Cavalier',
    en: 'King Charles Cavalier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1213',
    de: 'Kishu',
    en: 'Kishu',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1214',
    de: 'Kobe-Terrier',
    en: 'Kobe-Terrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1215',
    de: 'Kopov Bracke',
    en: 'Kopov Bracke',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1216',
    de: 'Kurzhaar Chow Chow',
    en: 'Shorthair Chow Chow',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1217',
    de: 'Kurzhaarteckel',
    en: 'Short hair',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1218',
    de: 'Langhaar Schäferhund',
    en: 'Long -haired shepherd',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1219',
    de: 'Langhaar Teckel',
    en: 'Langhaar Teckel',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1220',
    de: 'Moskauer Zwergterrier',
    en: 'Moscow dwarf terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1221',
    de: 'Old German Sheepdog',
    en: 'Old German Sheepdog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1222',
    de: 'Ostsibirische Laika',
    en: 'Eastern Siberian Laika',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1223',
    de: '\u00d6sterreichischer Kurzhaarpinscher',
    en: 'Austrian short hairpin',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1224',
    de: 'Pannonischer Spürhund',
    en: 'Pannonic detection dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1225',
    de: 'Persischer Windhund',
    en: 'Persian greyhound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1226',
    de: 'Plott Hound',
    en: 'Plot hound',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1227',
    de: 'Podenco Grande',
    en: 'Podenco Grande',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1228',
    de: 'Podengo Medio',
    en: 'Podengo Medio',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1229',
    de: 'Podengo Pequeno',
    en: 'Podengo Pequeno',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1230',
    de: 'Portugiesischer Pointer',
    en: 'Portuguese pointer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1231',
    de: 'Rauhaar Teckel',
    en: 'Radhaar Teckel',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1232',
    de: 'Rotwei\u00dfer Irish Setter',
    en: 'Rotwei\u00dfer Irish Setter',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1233',
    de: 'Schottischer Setter',
    en: 'Scottish setter',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1234',
    de: 'Shetland Schäferhund',
    en: 'Shetland Shepherd',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1235',
    de: 'Skye Silky Terrier',
    en: 'Skye Silky Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1236',
    de: 'Spanischer Pointer',
    en: 'Spanish pointer',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1237',
    de: 'Tibet Apso',
    en: 'Tibet Apso',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1238',
    de: 'Wolfs-Chow',
    en: 'Wolfs-chow',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1239',
    de: 'Transsylvanischer Spürhund',
    en: 'Transylvanic sniffer dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1240',
    de: 'Tschechischer Stichelhaar',
    en: 'Czech stitch hair',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1241',
    de: 'Türkischer Hütehund',
    en: 'Turkish herding dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1242',
    de: 'Ungarisch Drahthaar',
    en: 'Hungarian wire hair',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1243',
    de: 'Ungarischer Kurzhaar',
    en: 'Hungarian short hair',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1244',
    de: 'Vieräugl',
    en: 'Vieräugl',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1245',
    de: 'Welsh Hound',
    en: 'Welsh Hound',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1246',
    de: 'West Country Harrier',
    en: 'West Country Harrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1247',
    de: 'Alabai',
    en: 'Alabai',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1248',
    de: 'Alsatian',
    en: 'Alsatian',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1249',
    de: 'Westfalenterrier',
    en: 'Westfalerärier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1250',
    de: 'Bawarski Sokol',
    en: 'Bawarski Sokol',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1251',
    de: 'Bedlington',
    en: 'Bond',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1252',
    de: 'Berger Allemand',
    en: 'Berger Allemand',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1253',
    de: 'Lucas Terrier',
    en: 'Lucas Terrier',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1254',
    de: 'Branchiero Siciliano',
    en: 'Branchiero Siciliano',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1255',
    de: 'Somerset Harrier',
    en: 'Somerset Harrier',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1256',
    de: 'Eskimohund',
    en: 'Eskimohund',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1257',
    de: 'Slowakische Bracke',
    en: 'Slovak bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1258',
    de: 'Sennenhund',
    en: 'Set dog',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1259',
    de: 'Russische Bracke',
    en: 'Russian bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1260',
    de: 'Russischer Jagdspaniel',
    en: 'Russian hunting spaniel',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1261',
    de: 'Jack Russel Parson',
    en: 'Jack Russel Parson',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1262',
    de: 'Kaukasischer Hirtenhund',
    en: 'Caucasian shepherd dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1263',
    de: 'Presa Canario',
    en: 'Presa Canario',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1264',
    de: 'Kretischer Spürhund',
    en: 'Cretan detection dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1265',
    de: 'Portugiesischer Hirtenhund',
    en: 'Portuguese shepherd dog',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1266',
    de: 'Lettische Bracke',
    en: 'Latvian bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1267',
    de: 'Litauische Bracke',
    en: 'Lithuanian bracket',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
  {
    id: '1272',
    de: 'Perro Lobo Herreno',
    en: 'Perro Lobo Herreno',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1268',
    de: 'Rasse nicht gelistet bis 44 cm',
    en: 'Race not listed up to 44 cm',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1269',
    de: 'Rasse nicht gelistet ab 45 cm',
    en: 'Race not listed from 45 cm',
    visibility: true,
    OP4: 3,
    KV4: 3,
  },
  {
    id: '1270',
    de: 'Mischling bis 44 cm',
    en: 'Mixed breed up to 44 cm',
    visibility: true,
    OP4: 1,
    KV4: 1,
  },
  {
    id: '1271',
    de: 'Mischling ab 45 cm',
    en: 'Mixed breed from 45 cm',
    visibility: true,
    OP4: 2,
    KV4: 2,
  },
];
