import { calendly, faq, insurance } from '@getpopsure/private-constants';
import FAQSection from 'components/faq';
import MoreQuestionsSection from 'components/moreQuestions';
import openBoxIcon from 'features/liability/icons/open-box.svg';
import InfoCard from 'features/liability/pages/quote/components/infoCard';
import PricingCalculator from 'features/liability/pages/quote/components/pricingCalculator';
import {
  getCardContent,
  getFaqContent,
  getWhatsCoveredContent,
  getWhatsNotCoveredContent,
} from 'features/liability/pages/quote/content';
import { Trans, useSafeTranslation } from 'shared/i18n';

import CoverageSection from './components/coverageSection';
import styles from './style.module.scss';

const LiabilityQuote = () => {
  const { t } = useSafeTranslation();
  return (
    <div className={`w100 ${styles.container}`}>
      <section className="p-body d-flex fd-column ai-center py40">
        <img src={openBoxIcon} alt="open box" />
        <h1 className="p-h1 mt24 p--serif ta-center">
          {t('page.liability.quote.title', "Here's your quote")}
        </h1>
      </section>
      <section className="p-body pb96">
        <PricingCalculator />
        <div className="mt40">
          <InfoCard cardInfo={getCardContent(t)} />
        </div>
      </section>
      <section className="p-body pb96">
        <CoverageSection
          cardInfo={getWhatsCoveredContent(t)}
          title={t('page.liability.whatscovered.title', 'What’s covered')}
          showCoverageDetails
        />
      </section>
      <section className="p-body pb96">
        <CoverageSection
          cardInfo={getWhatsNotCoveredContent(t)}
          title={t(
            'page.liability.whatsnotcovered.title',
            "What isn't covered"
          )}
        />
      </section>
      <section className="p-body pb96">
        <FAQSection data={getFaqContent(t)}>
          <Trans i18nKey="page.liability.faq.linksection">
            <div className="p-p tc-grey-600">
              See our{' '}
              <a
                href={faq.liabilityInsurance}
                className="p-a"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ page
              </a>{' '}
              for more answers.
            </div>
          </Trans>
        </FAQSection>
      </section>
      <MoreQuestionsSection
        bookACallUrl={calendly.liabilityInsurance}
        bookACallGACategory="LIABILITY_INSURANCE"
        secondaryActionLink={insurance.liability.policyDetail}
        showSecondaryIcon
        openInNewTab
      />
    </div>
  );
};

export default LiabilityQuote;
