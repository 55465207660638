import { PartialQuestionnaire } from 'models/questionnaireFramework';

import { ProcessingAnswers, ProcessingGroup } from './processing';

export const legalSystemFamiliarity = {
  VERY_FAMILIAR: 'Very familiar',
  SOMEWHAT_FAMILIAR: 'Somewhat familiar',
  NOT_FAMILIAR_AT_ALL: 'Not at all familiar',
};

export type ILegalSystemFamiliarity = keyof typeof legalSystemFamiliarity;

interface Personal {
  familiarityWithGermanLegalSystem: ILegalSystemFamiliarity;
}

export type QuestionnaireAnswers = Personal & ProcessingAnswers;

export type GroupId = 'personalInfo' | ProcessingGroup;

export type LegalQuestionnaire = PartialQuestionnaire<
  QuestionnaireAnswers,
  GroupId
>;
