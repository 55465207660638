import routes from 'constants/routes';
import { getPoliciesListAction } from 'features/policiesDashboard/utils/getPoliciesListAction';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from 'features/policySingle/PolicySingle.models';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';
import { generatePath } from 'react-router';

import type { PolicySingleModal } from '../../components/modals';
import {
  CancelPolicyModal,
  DelinquencyBlockerCancelPolicyModal,
  DelinquencyBlockerCreateClaimModal,
} from '../../components/modals';
import { getPolicyDocuments } from '../../sections/PolicyDocuments/utils/getPolicyDocuments';
import { getBikeButtons } from './getBikeButtons';
import { getBikeInfo } from './getBikeInfo';
import { BikeFrameNumberModal, BikeUploadDocumentModal } from './modals';

export const getBikeConfig = (args: GetPolicyConfigArgs): PolicyConfig => {
  const [policy, t] = args;

  const action = getPoliciesListAction(policy);
  const policyStatus =
    action && action !== 'DETAIL'
      ? 'ACTION_NEEDED'
      : getPolicyStatus(policy, true);
  const title = getTitleMapping(t)[policy.type];

  const header: PolicyConfig['header'] = {
    title,
    backgroundColor: bannerColorMapping.BIKE,
    insuranceType: policy.type,
    subtitle: `${policy.attributes.brand}, ${policy.attributes.model}`,
    policyStatus,
    startDate: policy.attributes.startDate,
  };

  if (policy.attributes.price) {
    header.price = {
      amount: parseFloat(policy.attributes.price),
      billingType: policy.attributes.billingType as BillingType,
    };
  }

  const sections: PolicySingleSection[] = [
    {
      id: 'POLICY_DETAILS',
      props: {
        policyType: policy.type,
        info: getBikeInfo(policy, t),
        buttons: getBikeButtons(policy),
      },
    },
    {
      id: 'POLICY_COVERAGE',
      props: {
        entries:
          policy.attributes?.planId === 'COMFORT'
            ? [
                {
                  id: 'comfort-stolen-essential',
                  title: t(
                    'myPolicies.policyDetails.bike.covered.basicStolenEssential.title',
                    'Stolen bike & its essential parts'
                  ),
                  description: t(
                    'myPolicies.policyDetails.bike.covered.basicStolenEssential.description',
                    'e.g. wheels, brakes, seat'
                  ),
                  covered: true,
                },
                {
                  id: 'comfort-stolen-accessories',
                  title: t(
                    'myPolicies.policyDetails.bike.covered.comfortStolenAccessories.title',
                    'Stolen bike & its accessories'
                  ),
                  description: t(
                    'myPolicies.policyDetails.bike.covered.comfortStolenAccessories.description',
                    'e.g. baskets, lights. Must be stolen with the entire bike'
                  ),
                  covered: true,
                },
                {
                  id: 'comfort-accident-damages',
                  title: t(
                    'myPolicies.policyDetails.bike.covered.comfortAccidentDamages.title',
                    'Damages from an accident'
                  ),
                  covered: true,
                },
                {
                  id: 'comfort-vandalism-damages',
                  title: t(
                    'myPolicies.policyDetails.bike.covered.comfortVandalismDamages.title',
                    'Damages caused by vandalism'
                  ),
                  covered: true,
                },
              ]
            : policy.providerId === 'BARMENIA'
            ? [
                {
                  id: 'basic-stolen',
                  title: t(
                    'myPolicies.policyDetails.bike.covered.basicStolen.title',
                    'Stolen bike & its essential parts'
                  ),
                  description: t(
                    'myPolicies.policyDetails.bike.covered.basicStolen.description',
                    'e.g. wheels, brakes, seat'
                  ),
                  covered: true,
                },
                {
                  id: 'basic-stolen-accessories',
                  title: t(
                    'myPolicies.policyDetails.bike.covered.basicStolenAccessories.title',
                    'Stolen bike accessories'
                  ),
                  description: t(
                    'myPolicies.policyDetails.bike.covered.basicStolenAccessories.description',
                    'e.g. baskets, lights. Must be stolen with the entire bike'
                  ),
                  covered: true,
                },
                {
                  id: 'basic-accident-damages',
                  title: t(
                    'myPolicies.policyDetails.bike.covered.basicAccidentDamages.title',
                    'Damages caused by accident'
                  ),
                  covered: true,
                },
                {
                  id: 'basic-vandalism-damages',
                  title: t(
                    'myPolicies.policyDetails.bike.covered.basicVandalismDamages.title',
                    'Damages caused by vandalism'
                  ),
                  covered: true,
                },
                {
                  id: 'basic-wear-damages',
                  title: t(
                    'myPolicies.policyDetails.bike.covered.basicWearDamages.title',
                    'Wear & tear damages'
                  ),
                  covered: true,
                },
                {
                  id: 'basic-inspection-fees',
                  title: t(
                    'myPolicies.policyDetails.bike.notCovered.basicInspectionFees.title',
                    'Bike inspection fees'
                  ),
                  covered: false,
                },
                {
                  id: 'basic-cosmetic-repairs',
                  title: t(
                    'myPolicies.policyDetails.bike.notCovered.basicCosmeticRepairs.title',
                    'Cosmetic repairs'
                  ),
                  description: t(
                    'myPolicies.policyDetails.bike.notCovered.basicCosmeticRepairs.description',
                    'e.g. scratches, paint touch-ups'
                  ),
                  covered: false,
                },
              ]
            : [
                {
                  id: 'basic-stolen',
                  title: t(
                    'myPolicies.policyDetails.bike.covered.basicStolen.title',
                    'Stolen bike & its essential parts'
                  ),
                  description: t(
                    'myPolicies.policyDetails.bike.covered.basicStolen.description',
                    'e.g. wheels, brakes, seat'
                  ),
                  covered: true,
                },
                {
                  id: 'basic-stolen-accessories',
                  title: t(
                    'myPolicies.policyDetails.bike.covered.basicStolenAccessories.title',
                    'Stolen bike accessories'
                  ),
                  description: t(
                    'myPolicies.policyDetails.bike.covered.basicStolenAccessories.description',
                    'e.g. baskets, lights. Must be stolen with the entire bike'
                  ),
                  covered: true,
                },
                {
                  id: 'basic-wear-damages',
                  title: t(
                    'myPolicies.policyDetails.bike.notCovered.basicWearDamages.title',
                    'Wear & tear damages'
                  ),
                  covered: false,
                },
                {
                  id: 'basic-vandalism-damages',
                  title: t(
                    'myPolicies.policyDetails.bike.notCovered.basicVandalismDamages.title',
                    'Damages caused by vandalism'
                  ),
                  covered: false,
                },
                {
                  id: 'basic-accident-damages',
                  title: t(
                    'myPolicies.policyDetails.bike.notCovered.basicAccidentDamages.title',
                    'Damages from an accident'
                  ),
                  covered: false,
                },
              ],
      },
    },
    {
      id: 'POLICY_CLAIMS',
      props: {
        claims: policy.claims,
        route: generatePath(routes.claims.bike.path, {
          policyId: policy.id,
        }),
        insuranceType: 'BIKE',
        activeUntil:
          policy.status === 'ACTIVE'
            ? undefined
            : policy.attributes.activeUntil,
        policyStatus: policy.status,
        screen: {
          button: {
            dataCy: 'bike-policy-detail-make-a-claim',
          },
        },
      },
    },
    {
      id: 'POLICY_DOCUMENTS',
      props: {
        documents: getPolicyDocuments(policy),
      },
    },
  ];

  const modals: PolicySingleModal[] = [
    {
      id: 'CANCEL_POLICY',
      component: CancelPolicyModal,
    },
    {
      id: 'BIKE_UPLOAD_DOCUMENT',
      component: BikeUploadDocumentModal,
    },
    {
      id: 'BIKE_UPDATE_FRAME_NUMBER',
      component: BikeFrameNumberModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CREATE_CLAIM',
      component: DelinquencyBlockerCreateClaimModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
  ];

  return { header, modals, sections };
};
