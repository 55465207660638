import { NavButton } from 'components/NavButton';

const PaymentMethodPageHeader = ({
  title,
  backUrl,
  backText,
}: {
  title: string;
  backUrl: string;
  backText: string;
}) => {
  return (
    <>
      <NavButton path={backUrl} title={backText} />
      <h1 className="p-h1">{title}</h1>
    </>
  );
};

export default PaymentMethodPageHeader;
