import { HealthQuestionnaire } from 'models/recommendationTool';

import {
  getArrivalDateYearRange,
  getDateOfBirthYearRange,
  getIsArrivalDateRequired,
  nationalitiesAnswerObject,
  nationalitiesRetrieveNextPageId,
} from './utils';

export const personalInfo: HealthQuestionnaire = {
  nationalities: {
    required: (answers) => !answers.nationalities,
    type: 'COUNTRY_MULTI',
    props: {
      question: 'Which countries do you have citizenship in?',
    },
    retrieveAnswerObject: nationalitiesAnswerObject,
    retrieveNextPageId: nationalitiesRetrieveNextPageId,
    groupId: 'personalInfo',
  },
  arrivalDate: {
    required: getIsArrivalDateRequired,
    type: 'DATE',
    props: {
      question: 'When did you or will you relocate to Germany?',
      description: 'This can be an approximate date if you’re not certain.',
      yearRange: getArrivalDateYearRange(),
    },
    retrieveAnswerObject: (arrivalDate: string) => ({ arrivalDate }),
    retrieveNextPageId: () => 'dateOfBirth',
    groupId: 'personalInfo',
  },
  dateOfBirth: {
    required: (answers) => !answers.dateOfBirth,
    type: 'DATE',
    props: {
      question: 'What’s your date of birth?',
      yearRange: getDateOfBirthYearRange(),
    },
    retrieveAnswerObject: (dateOfBirth: string) => ({ dateOfBirth }),
    retrieveNextPageId: () => 'insuranceSituation',
    groupId: 'personalInfo',
  },
};
