import { capitalizeName } from '@getpopsure/public-utility';
import { TallyModal } from 'components/TallyModal';
import { PolicySingleModalProps } from 'features/policySingle/components/modals';

export const PrivateHealthChangePaymentMethodModal = (
  props: PolicySingleModalProps
) => {
  const {
    onClose,
    policyData: {
      attributes: { insuredPerson, policyNumber, address },
    },
  } = props;

  const URLparams = {
    policynumber: policyNumber,
    AccountHolderName:
      insuredPerson?.name && capitalizeName(insuredPerson.name),
    PaymentMethod: 'Bank',
    Street: address?.street,
    AdditionalInfo: address?.additionalInformation,
    City: address?.city,
    PostalCode: address?.postcode,
    Country: address?.country,
  };

  return (
    <TallyModal
      isVisible
      onClose={onClose}
      tallyId="nG9d6L"
      title="Private health change payment method"
      URLparams={URLparams}
    />
  );
};
