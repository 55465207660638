import { MailIcon } from '@popsure/dirty-swan';
import { IntercomLauncher } from 'features/intercomLauncher';

import style from './ContactUsButton.module.scss';

export const ContactUsButton = () => (
  <div className="d-block">
    <IntercomLauncher
      icon={<MailIcon color="grey-900" noMargin size={24} />}
      cardProps={{
        dropShadow: false,
        classNames: {
          wrapper: style.button,
        },
      }}
    />
  </div>
);
