import { IconTile } from 'components/IconTile';
import SectionHeader from 'components/sectionHeader';
import { HouseholdCoverageDetails } from 'features/household/components/HouseholdCoverageDetails';
import { useSafeTranslation } from 'shared/i18n';

import lampIcon from './assets/lamp.svg';
import leakIcon from './assets/leak.svg';
import pipeIcon from './assets/pipe.svg';
import toasterIcon from './assets/toaster.svg';
import trolleyIcon from './assets/trolley.svg';
import styles from './WhatsCoveredSection.module.scss';

export const WhatsCoveredSection = () => {
  const { t } = useSafeTranslation();

  return (
    <section className={`p-container ${styles.container}`}>
      <div
        className={`d-flex ai-center jc-between gap8 ${styles.titleContainer}`}
      >
        <div className={`d-flex fd-column ${styles.sectionHeaderContainer}`}>
          <SectionHeader
            title={t(
              'page.household.quote.whatscovered.title',
              "What's covered?"
            )}
          />
        </div>
        <HouseholdCoverageDetails useAlternateDefaultCaption />
      </div>
      <div
        className={`d-flex f-wrap jc-center c-gap24 r-gap32 mt32 ${styles.tileContainer}`}
      >
        <IconTile
          className={styles.tile}
          title={t(
            'page.household.quote.whatscovered.tile.1.title',
            'Water damage (from pipes)'
          )}
          iconSrc={pipeIcon}
          iconAlt="damaged pipe"
        >
          {t(
            'page.household.quote.whatscovered.tile.1.description',
            "You're covered if a pipe explodes, and you're left with water damage throughout your apartment."
          )}
        </IconTile>
        <IconTile
          className={styles.tile}
          title={t('page.household.quote.whatscovered.tile.2.title', 'Fire')}
          iconSrc={toasterIcon}
          iconAlt="toaster on fire"
        >
          {t(
            'page.household.quote.whatscovered.tile.2.description',
            'Fires are unpredictable. Insurance will cover the cost of your belongings and accommodation. '
          )}
        </IconTile>
        <IconTile
          className={styles.tile}
          title={t(
            'page.household.quote.whatscovered.tile.3.title',
            'Water damage (from containers)'
          )}
          iconSrc={leakIcon}
          iconAlt="water leaking from container"
        >
          {t(
            'page.household.quote.whatscovered.tile.3.description',
            'You’re covered if water from an aquarium, water bed, or kiddie pool damages your belongings.'
          )}
        </IconTile>
        <IconTile
          className={styles.tile}
          title={t(
            'page.household.quote.whatscovered.tile.4.title',
            'Vandalism'
          )}
          iconSrc={lampIcon}
          iconAlt="damaged lamp"
        >
          {t(
            'page.household.quote.whatscovered.tile.4.description',
            "If someone damages your belongings during a break-in, it's also included in your coverage."
          )}
        </IconTile>
        <IconTile
          className={styles.tile}
          title={t('page.household.quote.whatscovered.tile.5.title', 'Moving')}
          iconSrc={trolleyIcon}
          iconAlt="moving trolley"
        >
          {t(
            'page.household.quote.whatscovered.tile.5.description',
            'If you’re moving, both apartments are temporarily covered until you’re in the new apartment.'
          )}
        </IconTile>
      </div>
    </section>
  );
};
