import { FrameNumberModal } from 'features/policySingle/components/legacy/modals/bike/FrameNumberModal';

import { PolicySingleModalProps } from '../../../components/modals';

export const BikeFrameNumberModal = (props: PolicySingleModalProps) => {
  const {
    onClose,
    policyData: {
      id,
      attributes: { questionnaireId },
    },
  } = props;
  return (
    <FrameNumberModal
      isOpen
      policyId={id}
      questionnaireId={questionnaireId}
      onClose={onClose}
    />
  );
};
