import { TallyModal } from 'components/TallyModal';
import { PolicySingleModalProps } from 'features/policySingle/components/modals';
import { useSelector } from 'react-redux';
import { getAccountInfo } from 'selectors/user';

export const CompanyLifeAddBeneficiariesModal = ({
  onClose,
}: PolicySingleModalProps) => {
  const account = useSelector(getAccountInfo);

  const URLparams = {
    firstName: account?.firstName,
    lastName: account?.lastName,
    email: account?.email,
  };

  return (
    <TallyModal
      isVisible
      onClose={onClose}
      tallyId="3jl20J"
      title="Company life beneficiaries form"
      URLparams={URLparams}
    />
  );
};
