export type StatusBadgeColorClass =
  | 'bg-primary-900'
  | 'bg-blue-100'
  | 'bg-grey-200'
  | 'bg-glacier-100';

type StatusBadgeProps = {
  title: string;
  badgeColor: string;
};

export const StatusBadge = ({ title, badgeColor }: StatusBadgeProps) => (
  <div
    className={`py8 px16 br8 d-flex f-nowrap ai-center jc-center p-p--small ${badgeColor}`}
  >
    <div className="fw-bold f-nowrap">{title}</div>
  </div>
);
