import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import {
  BottomOrRegularModal,
  Button,
  DownloadIcon,
} from '@popsure/dirty-swan';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import {
  useAsyncPaymentRetry,
  useAsyncReceiptGeneration,
} from 'features/billingHistory/hooks';
import { Billing } from 'features/billingHistory/models';
import {
  getPaymentMethodIcon,
  mapBillingStatus,
  mapBillingType,
  mapStatusBadgeColors,
  mapUserFriendlyInsuranceName,
  retrieveRefundStatus,
  shouldGenerateReceipt,
} from 'features/billingHistory/utils';
import { Link } from 'react-router-dom';
import { TFunction } from 'shared/i18n';
import { formatCardLastDigits } from 'shared/util/cardFormatters';

import { StatusBadge } from '../StatusBadge';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  billingInfo: Billing;
  t: TFunction;
}

const MAX_PAYMENT_ATTEMPTS = 5;

export const TransactionDetailsModal = ({
  onClose,
  billingInfo,
  billingInfo: {
    paidAmount,
    paymentType,
    latestChargeDetails,
    billingPeriod: { end, start },
    paymentMethodDetails: { lastDigits, identifier, type },
    status,
    refundDetails,
    appliedBalance,
  },
  t,
}: Props) => {
  const { generatingReceipt, onDownloadClick } =
    useAsyncReceiptGeneration(billingInfo);
  const { retryingPayment, paymentError, onRetryPaymentClick } =
    useAsyncPaymentRetry(billingInfo);

  const isFailedPayment =
    status === 'REQUIRES_ACTION' || status === 'REQUIRES_PAYMENT_METHOD';

  const downloadButtonLabel = billingInfo.receipt?.downloadUrl
    ? t(
        'billingHistory.billingModal.downloadButton.downloadReceipt',
        'Download receipt'
      )
    : t(
        'billingHistory.billingModal.downloadButton.generateReceipt',
        'Generate receipt'
      );

  return (
    <BottomOrRegularModal
      isOpen={true}
      onClose={onClose}
      title={mapBillingType[paymentType]}
    >
      <div
        className={`px24 pb24 ws6 ${styles.container}`}
        data-testid="transaction-details-modal"
      >
        <div
          className={`py24 d-flex fd-column ai-center jc-center bg-grey-100 br8 w100 ${styles.paymentAmountTime}`}
        >
          <h2 className="p-h2">{englishFormattedEuroCurrency(paidAmount)}</h2>
          {refundDetails && (
            <>
              {retrieveRefundStatus(paidAmount, refundDetails.refundAmount) ===
                'REFUNDED' && (
                <p className="p-h3 tc-grey-600 mt8 mb8">
                  {t(
                    'billingHistory.billingModal.fullyRefunded',
                    'Fully refunded'
                  )}
                </p>
              )}
              {retrieveRefundStatus(paidAmount, refundDetails.refundAmount) ===
                'PARTIALLY_REFUNDED' && (
                <p className="p-h3 tc-grey-600 mt8 mb8">
                  {t('billingHistory.billingModal.partiallyRefunded', {
                    defaultValue: '{{refundAmount}} refunded',
                    refundAmount: englishFormattedEuroCurrency(
                      refundDetails.refundAmount
                    ),
                  })}
                </p>
              )}
            </>
          )}
          <p className="p-p tc-grey-500">
            {dayjs(latestChargeDetails.paidAt).format('DD MMM YYYY, HH:MM')}
          </p>
        </div>
        {start !== null && end !== null && (
          <>
            <div className="d-flex jc-between mt24">
              <p className="p-p">
                {t(
                  'billingHistory.billingModal.billingPeriod.title',
                  'Billing period'
                )}
              </p>
              <p className={`p-p ${styles.billingPeriod}`}>{`${dayjs(
                start
              ).format('DD MMM YYYY')} - ${dayjs(end).format(
                'DD MMM YYYY'
              )}`}</p>
            </div>
            <div className={`w100 bg-grey-200 mt24 ${styles.divider}`} />
          </>
        )}
        <div className="mt24">
          {latestChargeDetails.lines.map(
            ({ amount: latestChargeAmount, insuranceType }) => (
              <div
                className="d-flex ai-start jc-between mt16"
                key={`${insuranceType}-${latestChargeAmount}`}
              >
                <div className="p-p">
                  <p>{mapUserFriendlyInsuranceName(t)[insuranceType]}</p>
                  {/* TODO: [KONG] Add policy holder / dependent name */}
                </div>
                <p className="p-p">
                  {englishFormattedEuroCurrency(latestChargeAmount)}
                </p>
              </div>
            )
          )}
        </div>
        {appliedBalance !== 0 && (
          <>
            <div className={`w100 bg-grey-200 mt24 ${styles.divider}`} />
            <div className="mt24">
              <div className="d-flex ai-start jc-between">
                <div className="p-p">
                  <p>
                    {t(
                      'billingHistory.billingModal.appliedBalance',
                      'Applied balance'
                    )}
                  </p>
                </div>
                <p className="p-p">
                  {englishFormattedEuroCurrency(appliedBalance)}
                </p>
              </div>
            </div>
          </>
        )}
        <div className={`mt24 p16 br8 ${styles.paymentDetails}`}>
          <div className={`${styles.paymentStatus}`}>
            <div className="d-flex ai-center">
              <img
                className={`mr16 bg-grey-200 ${styles.brandIcon}`}
                src={getPaymentMethodIcon(billingInfo)}
                alt="payment provider logo"
              />

              <p className={`p-p ${styles.paypalEmail}`}>
                {type === 'paypal'
                  ? identifier
                  : formatCardLastDigits(lastDigits)}
              </p>
            </div>
            <div className={styles.statusBadge}>
              <StatusBadge
                title={mapBillingStatus(t)[status]}
                badgeColor={mapStatusBadgeColors[status]}
              />
            </div>
          </div>
          {latestChargeDetails.errorDetails && !paymentError && (
            <p className="p-p tc-red-500 mt16">
              {latestChargeDetails.errorDetails}
            </p>
          )}
          {paymentError && (
            <p className="p-p tc-red-500 mt16">{paymentError}</p>
          )}
          {billingInfo.paymentAttempts >= MAX_PAYMENT_ATTEMPTS && (
            <p className="p-p tc-red-500 mt16">
              {t(
                'billingHistory.billingModal.tooManyPaymentAttempts',
                'Too many failed attempts were made on this payment. Please contact your bank'
              )}
            </p>
          )}
          {latestChargeDetails.nextRetryAt && (
            <Markdown paragraphClassName="p-p tc-grey-500 mt8">
              {t('billingHistory.billingModal.nextRetry', {
                defaultValue:
                  'Next retry on your default payment method will be on **{{retryDate}}**',
                retryDate: dayjs(latestChargeDetails.nextRetryAt).format(
                  'DD MMM YYYY'
                ),
              })}
            </Markdown>
          )}
        </div>

        {/* TODO: [KONG] Implement download invoice button */}
        {isFailedPayment && (
          <>
            {billingInfo.invoiceId && (
              <Button
                className="p-btn--primary mt24 w100"
                loading={retryingPayment}
                onClick={onRetryPaymentClick}
                disabled={billingInfo.paymentAttempts >= MAX_PAYMENT_ATTEMPTS}
              >
                {t(
                  'billingHistory.billingModal.cta.retryPayment.title',
                  'Retry'
                )}
              </Button>
            )}
            <Link
              className={classNames('mt24', 'w100', {
                'p-btn--primary': !billingInfo.invoiceId,
                'p-btn--secondary': billingInfo.invoiceId,
                'bg-white': billingInfo.invoiceId,
              })}
              to={routes.me.paymentMethods.path}
            >
              {t(
                'billingHistory.billingModal.cta.paymentMethod.title',
                'Change payment method'
              )}
            </Link>
          </>
        )}

        {shouldGenerateReceipt(billingInfo) && (
          <Button
            className="p-btn--primary mt24 w100"
            loading={!!generatingReceipt}
            disabled={!!generatingReceipt}
            leftIcon={generatingReceipt ? <DownloadIcon /> : undefined}
            onClick={onDownloadClick}
          >
            {downloadButtonLabel}
          </Button>
        )}
      </div>
    </BottomOrRegularModal>
  );
};
