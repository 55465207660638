import { Card, CreditCardIcon } from '@popsure/dirty-swan';
import routes from 'constants/routes';
import { PaymentMethod } from 'models/paymentMethods';
import { ReactNode } from 'react';
import { useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import List from '../../components/PaymentMethodList/PaymentMethodList.view';
import PageHeader from '../../components/PaymentMethodPageHeader/PaymentMethodPageHeader.view';

const BillingScreenView = ({
  defaultPaymentMethod,
  otherPaymentMethods,
  handleDelete,
  handleMakeDefault,
  renderDefaultPaymentMethodAfter,
  showPaymentMethodTitle = false,
}: {
  defaultPaymentMethod?: PaymentMethod;
  renderDefaultPaymentMethodAfter?: ReactNode;
  otherPaymentMethods: PaymentMethod[];
  handleMakeDefault: (paymentMethodId: string) => Promise<void>;
  handleDelete: (paymentMethodId: string) => Promise<void>;
  showPaymentMethodTitle?: boolean;
}) => {
  const history = useHistory();
  const { t } = useSafeTranslation();

  const hasPaymentMethods =
    defaultPaymentMethod || otherPaymentMethods.length > 0;

  return (
    <div className="p-body wmx8">
      <section>
        <PageHeader
          title={t('account.paymentMethods.title', 'Payment method')}
          backUrl={routes.account.billingAndPayment.path}
          backText={t(
            'account.paymentMethods.backButton.caption',
            'Billing and payment'
          )}
        />
      </section>

      <section>
        {hasPaymentMethods ? (
          <List
            showPaymentMethodTitle={showPaymentMethodTitle}
            defaultPaymentMethod={defaultPaymentMethod}
            renderDefaultPaymentMethodAfter={renderDefaultPaymentMethodAfter}
            otherPaymentMethods={otherPaymentMethods}
            onDelete={handleDelete}
            onMakeDefault={handleMakeDefault}
          />
        ) : (
          renderDefaultPaymentMethodAfter
        )}
      </section>
      <section className="mt24 mb80">
        <Card
          title={t(
            'account.paymentMethods.addPaymentMethodButton.caption',
            'Add payment method'
          )}
          onClick={() => history.push(routes.me.paymentMethods.add.path)}
          data-cy="add-payment-method-button"
          density="compact"
          titleVariant="small"
          icon={<CreditCardIcon size={24} noMargin color="grey-600" />}
        />
      </section>
    </div>
  );
};

export default BillingScreenView;
