import { Spinner } from '@popsure/dirty-swan';
import classNames from 'classnames';

import styles from './style.module.css';

const LoadingSpinner = ({ isLoading = true }: { isLoading?: boolean }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles['loading-spinner'],
        'd-flex ai-center jc-center'
      )}
      data-testid="loading-spinner"
    >
      <div>
        <Spinner size="m" />
      </div>
    </div>
  );
};

export default LoadingSpinner;
