import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import classNames from 'classnames';
import { activePolicy } from 'features/recommendationTool/assets/icons';
import { VerticalCategory } from 'features/recommendationTool/models/recommendation';
import { TFunction, useSafeTranslation } from 'shared/i18n';

import styles from './OverviewCard.module.scss';

interface Src {
  src: string;
  srcSet?: string;
  alt: string;
}

export interface RecommendationCardProps {
  img: Src;
  title: string;
  subtitle: string;
  price?: number;
  showFromLabel: boolean;
  onClick: () => void;
  isCompleted?: boolean;
  isNotRelevant?: boolean;
  vertical: VerticalCategory;
}

const renderPrice = (
  t: TFunction,
  showFromLabel: boolean,
  price?: number,
  vertical?: RecommendationCardProps['vertical']
) => (
  <>
    {price ? (
      <>
        {showFromLabel
          ? t('recommendationtool.recommendation.priceFrom', 'from')
          : '~'}{' '}
        {englishFormattedEuroCurrency(price)}
        <span className="p-p--small tc-grey-500">
          {vertical === 'TRAVEL'
            ? t('recommendationtool.recommendation.pricePerYear', '/year')
            : t('recommendationtool.recommendation.pricePerMonth', '/mo')}
        </span>
      </>
    ) : (
      <>
        {'~ '}
        {t('recommendationtool.recommendation.priceVaries', 'price varies')}
      </>
    )}
  </>
);

const OverviewCard = ({
  img,
  title,
  subtitle,
  price,
  showFromLabel,
  onClick,
  isCompleted,
  isNotRelevant,
  vertical,
}: RecommendationCardProps) => {
  const { t } = useSafeTranslation();

  return (
    <div
      data-cy={`overview-card-${vertical}`}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
      className={classNames(
        'd-flex br8 ai-center c-pointer jc-between p-a p8',
        styles.link
      )}
    >
      <div className="d-flex ai-center">
        <div>
          <img
            src={isCompleted ? activePolicy : img.src}
            srcSet={!isCompleted ? img.srcSet : undefined}
            className={classNames('br8 mr16', styles.img, {
              [styles.imgBW]: isNotRelevant,
            })}
            alt={img.alt}
          />
        </div>

        <div className={styles.content}>
          <div className="d-flex">
            <h3 className="p-h4 tc-grey-900 mr8">{title}</h3>
            {!isCompleted && (
              <p
                className={classNames(
                  'p-h4 fw-normal tc-grey-500',
                  styles.mobileHidden
                )}
              >
                {renderPrice(t, showFromLabel, price, vertical)}
              </p>
            )}
          </div>

          <p
            className={classNames(
              'p-p--small tc-grey-600',
              styles.mobileHidden
            )}
          >
            {subtitle}
          </p>

          {!isCompleted && (
            <p
              className={classNames(
                'p-h4 fw-normal tc-grey-500',
                styles.mobileDisplay
              )}
            >
              {renderPrice(t, showFromLabel, price)}
            </p>
          )}
        </div>
      </div>

      <div className="d-flex ai-center p16">
        <span className={styles.infoIcon} />
      </div>
    </div>
  );
};

export { OverviewCard };
