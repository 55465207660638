import { TFunction } from '@getpopsure/i18n-react';

import { questionPropLookup } from '../lookups';
import { DisabilityV2, SubmittableDisabilityV2Questionnaire } from '../models';

const omittedPersonalInfoKeys = ['jobCollar', 'extremeSports'] as const;
const omittedTariffInfoKeys = [
  'price',
  'quoteId',
  'monthlyPayout',
  'price',
  'plan',
] as const;

export const createLabelTitles = (
  answers: SubmittableDisabilityV2Questionnaire,
  t: TFunction
): Record<
  keyof SubmittableDisabilityV2Questionnaire,
  Record<string, string>
> => {
  const labelTitles = {
    personalInfo: {} as Record<
      keyof SubmittableDisabilityV2Questionnaire['personalInfo'],
      string
    >,
    insuranceHistory: {} as Record<
      keyof SubmittableDisabilityV2Questionnaire['insuranceHistory'],
      string
    >,
    medicalHistory: {} as Record<
      keyof SubmittableDisabilityV2Questionnaire['medicalHistory'],
      string
    >,
    tariffInfo: {} as Record<
      keyof SubmittableDisabilityV2Questionnaire['tariffInfo'],
      string
    >,
  };

  Object.keys(answers.personalInfo).forEach((key) => {
    labelTitles.personalInfo[
      key as keyof SubmittableDisabilityV2Questionnaire['personalInfo']
    ] = questionPropLookup(t)[key as keyof DisabilityV2]
      ? questionPropLookup(t)[key as keyof DisabilityV2].question
      : '';
  });

  Object.keys(answers.personalInfo.extremeSports).forEach((key) => {
    labelTitles.personalInfo[
      key as keyof SubmittableDisabilityV2Questionnaire['personalInfo']
    ] = questionPropLookup(t)[key as keyof DisabilityV2]
      ? questionPropLookup(t)[key as keyof DisabilityV2].question
      : '';
  });

  Object.keys(answers.insuranceHistory).forEach((key) => {
    labelTitles.insuranceHistory[
      key as keyof SubmittableDisabilityV2Questionnaire['insuranceHistory']
    ] = questionPropLookup(t)[key as keyof DisabilityV2]
      ? questionPropLookup(t)[key as keyof DisabilityV2].question
      : '';
  });

  Object.keys(answers.medicalHistory).forEach((key) => {
    labelTitles.medicalHistory[
      key as keyof SubmittableDisabilityV2Questionnaire['medicalHistory']
    ] = questionPropLookup(t)[key as keyof DisabilityV2]
      ? questionPropLookup(t)[key as keyof DisabilityV2].question
      : '';
  });

  Object.keys(answers.tariffInfo).forEach((key) => {
    labelTitles.tariffInfo[
      key as keyof SubmittableDisabilityV2Questionnaire['tariffInfo']
    ] = questionPropLookup(t)[key as keyof DisabilityV2]
      ? questionPropLookup(t)[key as keyof DisabilityV2].question
      : '';
  });

  omittedPersonalInfoKeys.forEach(
    (key) => delete labelTitles.personalInfo[key]
  );

  omittedTariffInfoKeys.forEach((key) => delete labelTitles.tariffInfo[key]);

  return labelTitles;
};
