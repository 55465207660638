import { TableTrueFalse } from '@popsure/dirty-swan';
import classNames from 'classnames';

import styles from './style.module.scss';

interface ValueCellProps {
  value: boolean | string;
  description?: string;
}

export const ValueCell = (props: ValueCellProps) => {
  const { value, description } = props;

  return (
    <div className="d-flex fd-column ai-center">
      {typeof value === 'boolean' ? (
        <TableTrueFalse value={value} />
      ) : (
        <span>{value}</span>
      )}
      {description && (
        <p
          className={classNames(
            'ml8 p-p--small tc-grey-500',
            {
              [`${styles.booleanDescription}`]: typeof value === 'boolean',
            },
            styles.description
          )}
        >
          {description}
        </p>
      )}
    </div>
  );
};
