import classnames from 'classnames';

import { AddOnCoverageItem } from '../models';
import checkIcon from './icons/checkmark.svg';
import crossIcon from './icons/cross.svg';
import styles from './style.module.scss';

const CoverageItem = ({ entry }: { entry: AddOnCoverageItem }) => (
  <div className="d-flex jc-between fd-row mb24 mr8">
    <div className={classnames('w50', styles.row)}>
      <div className="p-p tc-grey-900">{entry.title}</div>
      <div className="p-p--small tc-grey-500">{entry.description}</div>
    </div>
    <div className="d-flex jc-center ai-center fd-row w30">
      {entry.covered ? (
        <img src={checkIcon} alt="checkmark" />
      ) : (
        <img src={crossIcon} alt="cross" />
      )}
    </div>
  </div>
);

export default CoverageItem;
