import { Region } from '@getpopsure/public-models';
import classNames from 'classnames';
import { InsuranceTypes } from 'models/insurances/types';
import {
  getExcerptMapping,
  getTitleMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';
import { useSafeTranslation } from 'shared/i18n';

import styles from './PolicyListCard.module.scss';

const PolicyListCard = ({
  onClick,
  region,
  type,
}: {
  onClick: () => void;
  region: Region;
  type: InsuranceTypes;
}) => {
  const { t } = useSafeTranslation();
  const nonDeIncomingType = region === 'es' ? 'INCOMING_ES' : 'INCOMING_EU';
  const insuranceType =
    type !== 'INCOMING' || region === 'de' ? type : nonDeIncomingType;

  const policyTitle = getTitleMapping(t)?.[type];

  return (
    <button
      className={classNames(
        styles.cardContainer,
        'd-flex ds-card ds-card--actionable ds-card--no-dropshadow gap16 ta-left'
      )}
      onClick={onClick}
      type="button"
    >
      <img
        className={classNames('br8', styles.cardImage)}
        src={imageTypeMapping?.[insuranceType] || ''}
        alt={`${policyTitle} icon`}
      />

      <div>
        <h4 className={classNames(styles.title, 'p-h4 tc-grey-900')}>
          {policyTitle}
        </h4>
        <p className="p-p--small tc-grey-600">
          {getExcerptMapping(t)?.[insuranceType]}
        </p>
      </div>
    </button>
  );
};

export { PolicyListCard };
