import type { Disability } from 'features/disability/models';
import { TFunction } from 'shared/i18n';

export type QuestionTextData = {
  question: string;
  description?: string;
  placeholder?: string;
  placeholderPerUnit?: [string, string];
  additionalInfo?: {
    title: string;
    description: string;
  };
  mapValue?: Record<string, string>;
  options?: Record<string, unknown>;
};

export const questionPropLookup = (
  t: TFunction
): Record<keyof Disability, QuestionTextData> => ({
  dateOfBirth: {
    question: t('disability.qnr.dateofbirth.question', 'When were you born?'),
  },
  ageBlocker: { question: '' },
  occupation: {
    question: t(
      'disability.qnr.occupation.question',
      'What is your main occupation in Germany?'
    ),
    mapValue: {
      EMPLOYED: t('disability.qnr.occupation.employed', 'Employed'),
      SELF_EMPLOYED: t(
        'disability.qnr.occupation.selfEmployed',
        'Self-employed'
      ),
      STUDENT: t('disability.qnr.occupation.student', 'Student'),
      UNEMPLOYED: t('disability.qnr.occupation.unemployed', 'Unemployed'),
      OTHER: t('disability.qnr.occupation.otherOccupation', 'Other'),
    },
  },
  studentBlocker: { question: '' },
  unemployedBlocker: { question: '' },
  monthlyNetIncome: {
    question: t(
      'disability.qnr.occupation.monthlyNetIncome.question',
      'What is your monthly net income in Euros?'
    ),
    placeholder: t(
      'disability.qnr.occupation.monthlyNetIncome.placeholder',
      'Monthly sum'
    ),
  },
  monthlyNetIncomeBlocker: { question: '' },
  officeWork: {
    question: t(
      'disability.qnr.occupation.officeWork.question',
      'Do you mostly work in an office?'
    ),
    additionalInfo: {
      title: t(
        'disability.qnr.occupation.officeWork.additionalInfo.title',
        'How to determine your main place of work'
      ),
      description: t(
        'disability.qnr.occupation.officeWork.additionalInfo.description',
        "If less than 20% of the time you spend at work requires physical activity (like moving boxes, walking around the store, etc.), you're considered to be working in an office."
      ),
    },
  },
  degree: {
    question: t(
      'disability.qnr.degree.question',
      'What is your highest level of education?'
    ),
    mapValue: {
      NO_DEGREE: t('disability.qnr.degree.noDegree', 'No degree'),
      HIGH_SCHOOL: t('disability.qnr.degree.highSchool', 'High-school diploma'),
      UNIVERSITY_SHORT_TERM: t(
        'disability.qnr.degree.uniShort',
        'University (1-2 years)'
      ),
      UNIVERSITY_LONG_TERM: t(
        'disability.qnr.degree.uniLong',
        'University (3+ years)'
      ),
    },
  },
  height: {
    question: t('disability.qnr.height.question', 'How tall are you?'),
    placeholderPerUnit: [
      t('disability.qnr.height.placeholder.cm', 'Centimeters'),
      t('disability.qnr.height.placeholder.ft', 'Feet'),
    ],
  },
  weight: {
    question: t('disability.qnr.weight.question', 'What is your weight?'),
    placeholderPerUnit: [
      t('disability.qnr.weight.placeholder.kg', 'Kilograms'),
      t('disability.qnr.weight.placeholder.lbs', 'Pound'),
    ],
  },
  monthlyPayout: {
    question: t('disability.qnr.monthlyPayout.question', 'Monthly payout'),
  },
  calculatorQuote: { question: '' },
  addOns: { question: t('disability.qnr.addOns.question', 'Add-ons') },
  quote: { question: '' },
  nextSteps: { question: '' },
  form: { question: '' },
});
