import { Accordion, AccordionProps } from '@popsure/dirty-swan';
import Markdown from 'components/markdown';

import styles from './styles.module.scss';

interface AdditionInfoSectionProps {
  items?: AccordionProps['items'];
  isBordered?: boolean;
  title: string;
}

const AdditionalInfoSection = ({
  isBordered,
  items,
  title,
}: AdditionInfoSectionProps) => {
  if (!items || !items.length) {
    return null;
  }
  return (
    <>
      <h3 className="p-h2 mt40 mb16">{title}</h3>
      <Accordion
        items={items.map(({ answer, ...rest }) => ({
          answer: (
            <Markdown
              className={styles.markdown}
              paragraphClassName="p-p tc-grey-600"
            >
              {String(answer)}
            </Markdown>
          ),
          ...rest,
        }))}
        variant={isBordered ? 'bordered' : 'default'}
      />
    </>
  );
};

export { AdditionalInfoSection };
