import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import { Route, Switch } from 'react-router';
import { isMobileApp } from 'shared/util/isMobileApp';

import { DependentsSignupPage } from './components/DependentsSignupPage';
import { SignupPage } from './components/SignupPage';

const ExpatRoutes = () => {
  return (
    <>
      {isMobileApp && <NavButton title="Previous question" />}

      <Switch>
        <Route
          component={DependentsSignupPage}
          path={routes.policies.expat.dependents.path}
        />
        <Route component={SignupPage} path={routes.policies.expat.path} />
      </Switch>
    </>
  );
};

export default ExpatRoutes;
