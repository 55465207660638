import { TFunction } from '@getpopsure/i18n-react';
import {
  ExpatDependentQuestionnaire,
  ExpatQuestionnaire,
} from 'features/expat/models';
import { PartialQuestionnaire } from 'models/questionnaireFramework';

import { checkoutQNR } from './checkout';
import { checkoutDependentQNR } from './checkout/checkoutDependentQNR';
import { postQuoteDependentQNR, postQuoteQNR } from './postQuote';
import { preQuoteDependentQNR, preQuoteQNR } from './preQuote';
import { quoteDependentQNR, quoteQNR } from './quote';
import { reviewInfoDependentQNR, reviewInfoQNR } from './reviewInfo';

export const getTranslatedQuestionnaire = (
  t: TFunction
): PartialQuestionnaire<
  ExpatQuestionnaire,
  'prequote' | 'quote' | 'signup'
> => ({
  ...preQuoteQNR(t),
  ...quoteQNR(),
  ...postQuoteQNR(t),
  ...reviewInfoQNR(),
  ...checkoutQNR(),
});

export const getTranslatedDependentQuestionnaire = (
  t: TFunction,
  mainPolicyId: string
): PartialQuestionnaire<
  ExpatDependentQuestionnaire,
  'prequote' | 'quote' | 'signup'
> => ({
  ...preQuoteDependentQNR(t, mainPolicyId),
  ...quoteDependentQNR(),
  ...postQuoteDependentQNR(t),
  ...reviewInfoDependentQNR(),
  ...checkoutDependentQNR(),
});
