import { EuroClaimIcon } from '@popsure/dirty-swan';
import ButtonCell from 'components/buttonCell';
import { PolicyDetailsButtonProps } from 'features/policySingle/sections/PolicyDetails/PolicyDetails.models';
import { useSafeTranslation } from 'shared/i18n';

export const TravelHealthFranceSubmitClaimButton = (
  props: PolicyDetailsButtonProps
) => {
  const { onOpenModal } = props;
  const { t } = useSafeTranslation();

  const handleClaimSubmissionButton = () => {
    onOpenModal('TRAVEL_FR_SUBMIT_CLAIM');
  };

  return (
    <ButtonCell
      title={t(
        'myPolicies.policyDetails.claimsButton.travelFrance.caption',
        'Submit a claim'
      )}
      icon={<EuroClaimIcon noMargin size={24} className="ml16" />}
      onClick={handleClaimSubmissionButton}
    />
  );
};
