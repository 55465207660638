import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import QuestionnaireWrapper from '../QuestionnaireWrapper';

type BooleanOptions = [boolean, string][];

interface Props
  extends QuestionnaireFormProps<boolean>,
    QuestionnaireWrapperProps {}

export const BooleanForm = ({
  value,
  onSubmitValue,
  question,
  description,
  additionalInfo,
  buttonText,
  featureName,
  header,
  error,
  loading,
  questionClassName = '',
}: Props) => {
  const { t } = useSafeTranslation();

  const options: BooleanOptions = [
    [true, t('qnf.forms.boolean.yes', 'Yes')],
    [false, t('qnf.forms.boolean.no', 'No')],
  ];

  const [booleanValue, setBooleanValue] = useState<boolean | undefined>(value);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (booleanValue === undefined) {
      throw new Error(
        `[${featureName}] Boolean value is missing for submission for question: ${question}`
      );
    }
    onSubmitValue(booleanValue);
  };

  return (
    <QuestionnaireWrapper
      header={header}
      question={question}
      questionClassName={questionClassName}
      description={description}
      onSubmit={handleSubmit}
      error={error}
      loading={loading}
      buttonText={buttonText}
      validForSubmission={booleanValue !== undefined}
      additionalInfo={additionalInfo}
    >
      <div className="p-label-container">
        {options.map(([mappableValue, label]) => (
          <div className="mb8" key={label as string}>
            <input
              id={label as string}
              className="p-radio"
              type="radio"
              value={label as string}
              onChange={() => setBooleanValue(mappableValue as boolean)}
              checked={booleanValue === mappableValue}
            />
            <label
              htmlFor={label as string}
              className="p-label p-label--bordered"
              data-cy={`radio-${label}`}
            >
              {label}
            </label>
          </div>
        ))}
      </div>
    </QuestionnaireWrapper>
  );
};
