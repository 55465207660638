import { IconCard } from 'components/IconCard';
import SectionHeader from 'components/sectionHeader';
import { useSafeTranslation } from 'shared/i18n';

import fixtureIcon from './assets/fixture.svg';
import helmetIcon from './assets/helmet.svg';
import pipesGreyIcon from './assets/pipesGrey.svg';
import plugIcon from './assets/plug.svg';
import styles from './WhatsNotCoveredSection.module.scss';

export const WhatsNotCoveredSection = () => {
  const { t } = useSafeTranslation();

  return (
    <section className="p-container mt80 mb72">
      <div className={`d-flex fd-column ${styles.titleContainer}`}>
        <SectionHeader
          title={t(
            'page.household.quote.whatsnotcovered.title',
            'What’s not covered?'
          )}
          greyUnderline
        />
      </div>
      <div className={`d-grid gap32 mt56 ${styles.cardGrid}`}>
        <IconCard
          className="p8"
          title={t(
            'page.household.quote.whatsnotcovered.card.1.title',
            'Building fixtures'
          )}
          iconSrc={fixtureIcon}
          iconAlt={t(
            'page.household.quote.whatsnotcovered.card.1.icon.alt',
            'Building fixture'
          )}
        >
          {t(
            'page.household.quote.whatsnotcovered.card.1.description',
            'Things like doors or sinks are not covered as they are building fixtures and are covered by property insurance.'
          )}
        </IconCard>
        <IconCard
          className="p8"
          title={t(
            'page.household.quote.whatsnotcovered.card.2.title',
            'Blown fuse'
          )}
          iconSrc={plugIcon}
          iconAlt={t(
            'page.household.quote.whatsnotcovered.card.2.icon.alt',
            'power plug'
          )}
        >
          {t(
            'page.household.quote.whatsnotcovered.card.2.description',
            'Electronics damaged through blown fuses are not covered (unless from lightning), but rather electronics insurance.'
          )}
        </IconCard>
        <IconCard
          className="p8"
          title={t(
            'page.household.quote.whatsnotcovered.card.3.title',
            'Pipes (under the floor)'
          )}
          iconSrc={pipesGreyIcon}
          iconAlt={t(
            'page.household.quote.whatsnotcovered.card.3.icon.alt',
            'pipes'
          )}
        >
          {t(
            'page.household.quote.whatsnotcovered.card.3.description',
            'Pipes under the floor or in the walls are not covered as the landlord should cover them under household insurance.'
          )}
        </IconCard>
        <IconCard
          className="p8"
          title={t(
            'page.household.quote.whatsnotcovered.card.4.title',
            'Large-scale conflict'
          )}
          iconSrc={helmetIcon}
          iconAlt={t(
            'page.household.quote.whatsnotcovered.card.4.icon.alt',
            "soldier's helmet"
          )}
        >
          {t(
            'page.household.quote.whatsnotcovered.card.4.description',
            "If large-scale conflict breaks out in Germany which causes damages to your household or belongings, you're not covered."
          )}
        </IconCard>
      </div>
    </section>
  );
};
