import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import type { SliderOptions } from './EditableSlider';
import { EditableSlider } from './EditableSlider';
import { InputModal } from './InputModal';

type ModalTextOverrides = {
  aboveMaxErrorMessage?: string;
  belowMinErrorMessage?: string;
  buttonCaption?: string;
  description?:
    | string
    | (({ min, max }: { min: string; max: string }) => string);
  placeholder?: string;
  title?: string;
};

type SliderWithModalProps = {
  className?: string;
  disabled?: boolean;
  label: string;
  sublabel?: string;
  valueSuffix?: string;
  max: number;
  min: number;
  modalTextOverrides?: ModalTextOverrides;
  modalSetValue?: (value: number) => void;
  onChangeStart?: () => void;
  onChangeEnd?: () => void;
  recommendedValue?: number;
  setValue: (value: number) => void;
  options?: SliderOptions;
  step?: number;
  value: number;
};

export const SliderWithModal = ({
  className,
  disabled = false,
  label,
  sublabel,
  max,
  min,
  modalTextOverrides,
  modalSetValue,
  onChangeStart,
  onChangeEnd,
  options,
  setValue,
  recommendedValue,
  step = 1,
  valueSuffix,
  value,
}: SliderWithModalProps) => {
  const [showInputModal, setShowInputModal] = useState(false);

  const { t } = useSafeTranslation();

  const description =
    (typeof modalTextOverrides?.description === 'string' &&
      modalTextOverrides?.description) ||
    (typeof modalTextOverrides?.description === 'function' &&
      modalTextOverrides?.description?.({
        min: englishFormattedEuroCurrency(min, true),
        max: englishFormattedEuroCurrency(max, true),
      })) ||
    undefined;

  return (
    <>
      {!options?.noEdit && (
        <InputModal
          isOpen={showInputModal}
          min={min}
          max={max}
          onClose={() => setShowInputModal(false)}
          setValue={modalSetValue || setValue}
          value={value}
          title={
            modalTextOverrides?.title ||
            t('components.sliderwithmodal.defaults.modal.title', 'Coverage')
          }
          description={
            description ||
            t('components.sliderwithmodal.defaults.modal.description', {
              defaultValue:
                'Please enter your desired amount ranging from {{min}} to {{max}}.',
              min: englishFormattedEuroCurrency(min, true),
              max: englishFormattedEuroCurrency(max, true),
            })
          }
          placeholder={
            modalTextOverrides?.placeholder ||
            t(
              'components.sliderwithmodal.defaults.modal.placeholder',
              'Coverage'
            )
          }
          belowMinErrorMessage={
            modalTextOverrides?.belowMinErrorMessage ||
            t('components.sliderwithmodal.defaults.modal.error.belowmin', {
              defaultValue: 'The smallest amount we can offer is {{min}}',
              min: englishFormattedEuroCurrency(min, true),
            })
          }
          aboveMaxErrorMessage={
            modalTextOverrides?.aboveMaxErrorMessage ||
            t('components.sliderwithmodal.defaults.modal.error.abovemax', {
              defaultValue:
                'The entered amount exceeds the maximum we can offer ({{max}})',
              max: englishFormattedEuroCurrency(max, true),
            })
          }
          buttonCaption={
            modalTextOverrides?.buttonCaption ||
            t(
              'components.sliderwithmodal.defaults.modal.error.button.caption',
              'Apply'
            )
          }
        />
      )}
      <EditableSlider
        options={options}
        className={className}
        disabled={disabled}
        label={label}
        sublabel={sublabel}
        min={min}
        max={max}
        recommendedValue={recommendedValue}
        onChangeStart={onChangeStart}
        onChangeEnd={onChangeEnd}
        onEditClick={() => {
          setShowInputModal(true);
        }}
        setValue={setValue}
        step={step}
        valueSuffix={valueSuffix}
        value={value}
      />
    </>
  );
};
