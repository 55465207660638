import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import classnames from 'classnames';
import { isEligible, Life } from 'features/life/models';
import { FormEvent, useEffect, useRef, useState } from 'react';

import styles from './EligibilityCheck.module.scss';
import { CalculatingCompleteIcon, CalculatingIcon } from './img';
import ProgressRow from './progressRow';

const generateLoadingContent = ({
  ineligible,
}: {
  ineligible: boolean;
}): {
  description: string;
  timingFunction: number[];
}[] => {
  const timingFunction = [
    [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 95, 98, 100],
    [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      10,
      30,
      40,
      55,
      65,
      70,
      75,
      78,
      ineligible ? -1 : 80,
      90,
      100,
    ],
    [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      30, 60, 80, 90, 100,
    ],
    [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 25, 50, 85, 90, 100,
    ],
  ];
  return [
    'Reviewing answers',
    'Analysing risk profile',
    'Doing last checks',
  ].map((description, index) => {
    return {
      description,
      timingFunction: timingFunction[index],
    };
  });
};

export const EligibilityCheck = ({
  onSubmitValue,
  questionnaireAnswers,
}: QuestionnaireFormProps<Life, boolean>) => {
  const [tick, setTick] = useState(0);
  const [hasLoaded, setHasLoaded] = useState(false);

  const interval = useRef<NodeJS.Timeout>();
  const isQuestionnaireEligible = isEligible(questionnaireAnswers);

  const stopTimer = () => {
    interval.current && clearInterval(interval.current);
  };

  const content = generateLoadingContent({
    ineligible: !isQuestionnaireEligible,
  });

  useEffect(() => {
    interval.current = setInterval(() => {
      setTick((t) => t + 1);
    }, 300);

    return () => interval.current && clearInterval(interval.current);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmitValue(true);
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <div className="p-body">
        <div className="wmx8 ml-auto mr-auto">
          {hasLoaded ? (
            <CalculatingCompleteIcon className="d-block ml-auto mr-auto" />
          ) : (
            <CalculatingIcon className="d-block ml-auto mr-auto" />
          )}
          <h1 className="p-h1 ta-center mt16">
            {hasLoaded ? 'You’re eligible 🎉' : 'Checking your answers'}
          </h1>
          <p className="p-p mt8 ta-center">
            {hasLoaded
              ? "We've prepared a final quote for you."
              : 'We’re reviewing your data to assess your eligibility'}
          </p>
          <div className="mt48">
            {content.map(({ description, timingFunction }, index) => {
              const progress =
                timingFunction[Math.min(tick, timingFunction.length - 1)];
              if (progress === -1) {
                stopTimer();
                setTimeout(() => {
                  onSubmitValue(false);
                }, 1000);
              }

              if (
                index === content.length - 1 &&
                progress === 100 &&
                !hasLoaded
              ) {
                setHasLoaded(true);
                stopTimer();
              }

              return (
                <ProgressRow
                  key={description}
                  description={description}
                  progress={progress}
                  warning={progress === -1}
                />
              );
            })}
          </div>
          <button
            className={classnames(`p-btn--primary wmn3 ml-auto mr-auto mt48`, {
              [styles.continueBtn]: !hasLoaded,
              [styles.continueBtnDisplay]: hasLoaded,
            })}
            data-cy="button-continue"
            type="submit"
          >
            Continue
          </button>
        </div>
      </div>
    </form>
  );
};
