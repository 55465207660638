import { insurance } from '@getpopsure/private-constants';
import routes from 'constants/routes';
import {
  bannerColorMapping,
  imageSrcSetTypeMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';

import { CardText, CategoryLookup, Section } from '../models/lookups';
import { typeformLinkWithVertical } from './shared';

export const householdCategory: CategoryLookup = {
  shortTitle: 'Household',
  title: 'Household contents insurance',
  excerpt: 'Protection for your home and your belongings',
  description:
    'Household contents insurance covers any belongings in your home that aren’t attached in some way to the building. This can include things like furniture, dishes, electronics, clothing, books, and other belongings. You’re covered in case of a break-in, fire, water damage, or other unexpected events where your belongings might be damaged or stolen.',
  icon: imageTypeMapping.HOUSEHOLD,
  iconSrcSet: imageSrcSetTypeMapping.HOUSEHOLD,
  bannerColor: bannerColorMapping.HOUSEHOLD,
  landingPage: insurance.household.landingPage,
  quotePage: routes.policies.household.path,
  enabled: true,
};

export const householdSection: Section = {
  title: '',
  description:
    'When evaluating your need for household contents insurance, we consider where you live (how likely a fire or robbery is to happen here), how large your apartment is, and how much your belongings are worth.',
  icon: '',
  status: {
    ELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.household.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('householdcontents'),
      },
    },
    PARTIAL: {
      cta: {
        caption: 'Learn more',
        href: insurance.household.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('householdcontents'),
      },
    },
    MORE_INFO_REQUIRED: {
      cta: {
        caption: 'Learn more',
        href: insurance.household.landingPage,
      },
    },
    INELIGIBLE: {
      cta: {
        caption: 'Learn more',
        href: insurance.household.landingPage,
      },
      expert: {
        caption: 'Talk to an expert.',
        href: typeformLinkWithVertical('householdcontents'),
      },
    },
  },
};

export const householdCardText: CardText = {
  HOUSEHOLD_VERY_HIGH_RISK_AREA_PRO: {
    title: 'You live in a very high risk area',
    description:
      'Your postal code is in an area where theft and vandalism are very frequent compared to the rest of the country.',
  },
  HOUSEHOLD_HIGH_RISK_AREA_PRO: {
    title: 'You live in a high risk area',
    description:
      'Your postal code is in an area where theft and vandalism are quite frequent compared to the rest of the country.',
  },
  HOUSEHOLD_AVERAGE_RISK_AREA_PRO: {
    title: 'You live in an average risk area',
    description:
      'Your postal code is in an area with an average risk for theft and vandalism compared to the rest of the country.',
  },
  HOUSEHOLD_BELONGINGS_COVERED_PRO: {
    title: 'Your belongings are covered when you travel',
    description:
      'No matter if your belongings are at home, at a temporary stay, or a hotel room, they will be covered.',
  },
  HOUSEHOLD_GLASS_COVERED_PRO: {
    title: 'Glass coverage can be added',
    description:
      'Have a glass stove or worried about your windows? We have add-ons for household insurance that can cover broken glass.',
  },
  HOUSEHOLD_BIKE_COVERAGE_PRO: {
    title: 'Bike coverage can be added',
    description:
      "Have a bike you'd like to cover? We have add-ons for household insurance that can cover bikes.",
  },
  HOUSEHOLD_ACCOMMODATION_COVERAGE_PRO: {
    title: 'Covers accommodation during emergencies',
    description:
      'If you can no longer live in your apartment due to a fire or flood (extended coverage), insurance will pay for 180 days of accommodation.',
  },
  HOUSEHOLD_WILLING_TO_CARRY_RISK_CON: {
    title: 'You might be willing to carry the risk',
    description:
      'If you believe that the total value of your belongings is less than €5,000, you might be willing to carry the risk of losing them.',
  },
  HOUSEHOLD_CERTAIN_THINGS_NOT_COVERED_CON: {
    title: 'Certain things are not covered',
    description:
      'Things like doors or walls are not covered as they are building fixtures and are covered by property insurance. High value belongings like artwork, paper bonds and cash are generally not covered. Anything included in liability insurance is not covered.',
  },
};
