import { TableTrueFalse } from '@popsure/dirty-swan';

interface ValueCellProps {
  value: boolean | string;
  description?: string;
}

export const ValueCell = (props: ValueCellProps) => {
  const { value, description } = props;
  return (
    <div className="d-flex fd-column ai-center">
      {typeof value === 'boolean' ? (
        <TableTrueFalse value={value} />
      ) : (
        <span>{value}</span>
      )}
      {description && <p className="ml8 mt8">{description}</p>}
    </div>
  );
};
