import GenericReview from 'components/review';
import { createPostQuote, CreateQuoteDispatch } from 'features/expat/actions';
import {
  answersToReviewValues,
  ExpatQuestionnaire,
} from 'features/expat/models';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

export const Review = ({
  routes,
  onSubmitValue,
  questionnaireAnswers,
}: CustomComponentProps<ExpatQuestionnaire>) => {
  const { t } = useSafeTranslation();

  const { loading, error } = useRequestStatus('EXPAT_SUBMIT_INFO');

  const dispatch = useDispatch<CreateQuoteDispatch>();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const { status } = await dispatch(createPostQuote(t));
    if (status === 'SUCCESS') {
      onSubmitValue(true);
    }
  };

  const questionReviewValues = answersToReviewValues(
    t,
    questionnaireAnswers,
    routes
  );

  return (
    <GenericReview
      title={
        <h1 className="p-h1 mb8">
          {t('expat.qnr.review.title', "Here's what we know about you:")}
        </h1>
      }
      questionReviewValues={questionReviewValues}
      loading={loading}
      error={error?.message}
      handleSubmit={handleSubmit}
      insuranceType="INCOMING"
    />
  );
};
