import Session from '@getpopsure/session';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Policy } from 'models/policies';
import endpoint from 'shared/api';

export const getMyPolicies = async (): Promise<Policy[]> => {
  const { data } = await endpoint.getMyPolicies();
  return data;
};

export const useGetPolicies = () => {
  const { isAuthenticated } = Session;

  const {
    isLoading,
    data: policies,
    error,
  } = useQuery<Policy[], AxiosError>({
    queryKey: ['getMyPolicies'],
    queryFn: getMyPolicies,
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });
  return {
    error,
    // TODO: loading state should be tied to enabled flag, currently it is not supported
    // https://github.com/TanStack/query/issues/3975
    isLoading: isAuthenticated ? isLoading : false,
    policies,
  };
};
