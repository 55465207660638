import { storeSubmittableClaimInfo } from 'actions/claims';
import routes from 'constants/routes';
import { ClaimType } from 'models/claims';
import { connect } from 'react-redux';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { AppState } from 'reducers';
import { getSubmittableClaimInfo } from 'selectors/claims';

import ClaimTypes from './view';

interface StateProps {
  initialValue?: ClaimType;
}

interface DispatchProps {
  storeClaimType: (claimType: ClaimType) => void;
}

type OwnProps = RouteComponentProps<{ policyId: string }>;

export type ViewProps = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: AppState): StateProps => {
  const submitClaimType = getSubmittableClaimInfo(state)?.claimType;

  return {
    initialValue: submitClaimType,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    history,
    match: {
      params: { policyId },
    },
  }: OwnProps
): DispatchProps => ({
  storeClaimType: (claimType: ClaimType) => {
    dispatch(storeSubmittableClaimInfo({ claimType }));
    history.push(
      generatePath(routes.me.claims.uploadDocuments.path, {
        policyId,
      })
    );
  },
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(ClaimTypes);
