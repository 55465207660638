import dayjs from 'dayjs';
import Cell from 'features/checkout/components/Cell';
import { CheckoutInfo } from 'features/checkout/models';
import { formatInclusiveMembers } from 'features/liability/utils';
import { ReferrerCodeVerify } from 'features/referralEngine';
import { PolicyDetails as LiabilityPolicyDetails } from 'models/liability';
import { useSafeTranslation } from 'shared/i18n';
import { formatCurrencyWithSpace } from 'shared/util/formatCurrencyWithSpace';

import styles from './style.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

interface Props {
  checkoutInfo?: CheckoutInfo<LiabilityPolicyDetails>;
}

const View = ({ checkoutInfo }: Props) => {
  const { t } = useSafeTranslation();

  return (
    <div className={styles.container}>
      <div className="p-h2 mb16">
        {t('page.liability.qnr.checkout.policydetails.title', 'Policy details')}
      </div>
      {checkoutInfo?.mainPolicy.startDate && (
        <Cell
          title={t(
            'page.liability.qnr.checkout.policydetails.requestedStartDate',
            'Requested start date'
          )}
          value={dayjs(checkoutInfo.mainPolicy.startDate).format(DATE_FORMAT)}
          additionalInfo={{
            title: t(
              'page.liability.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
              'Requested start date'
            ),
            description: t(
              'page.liability.qnr.checkout.policydetails.requestedStartDate.additionalInfo.description',
              'Your coverage should begin on {{coverageDate}}, but only after you have received the official confirmation.',
              {
                coverageDate: dayjs(checkoutInfo.mainPolicy.startDate).format(
                  DATE_FORMAT
                ),
              }
            ),
          }}
        />
      )}
      <Cell
        title={t(
          'page.liability.qnr.checkout.policydetails.inclusivemembers',
          'Included household members'
        )}
        value={
          checkoutInfo?.mainPolicy.policyDetails.inclusiveMembers &&
          checkoutInfo?.mainPolicy.policyDetails.inclusiveMembers.length > 0
            ? formatInclusiveMembers(
                checkoutInfo.mainPolicy.policyDetails.inclusiveMembers,
                t
              )
            : 'None'
        }
      />
      <div className={`bg-grey-200 mt32 mb32 ${styles.divider}`} />
      {checkoutInfo?.totalMonthlyPrice && (
        <div className={styles['price-container']}>
          <div className="p-h3">
            {t(
              'page.liability.qnr.checkout.policydetails.total',
              'Total per month'
            )}
          </div>
          <div className="p-h3 ml-auto">
            {formatCurrencyWithSpace(checkoutInfo.totalMonthlyPrice, 2)}
          </div>
        </div>
      )}
      <ReferrerCodeVerify />
    </div>
  );
};

export default View;
