import { insurance } from '@getpopsure/private-constants';
import dayjs from 'dayjs';

import { QuotePlan } from '../models';

export const getDateOfBirthYearRange = () => {
  const now = dayjs();
  return {
    min: now.subtract(100, 'years').year(),
    max: now.year(),
  };
};

export const getArrivalDateYearRange = () => {
  const now = dayjs();
  return {
    min: now.subtract(100, 'years').year(),
    max: now.add(2, 'years').year(),
  };
};

export const getJobOfferDateYearRange = () => {
  const now = dayjs();
  return {
    min: now.year(),
    max: now.add(2, 'years').year(),
  };
};

export const isOver = (dateOfBirth: string, threshold: number): boolean =>
  dayjs().diff(dateOfBirth, 'years') >= threshold;

export const isMoreThanFiveYearsInGermany = (arrivalDate?: string): boolean =>
  dayjs().diff(arrivalDate, 'months') >= 59;

export const isBetweenFourAndFiveYearsInGermanyBlocker = (
  arrivalDate?: string
): boolean => {
  const diff = dayjs().diff(arrivalDate, 'months');
  return diff >= 48 && diff < 59;
};

export const isWithinMonth = (date?: string): boolean => {
  const diff = dayjs().diff(date, 'month');
  return diff >= 0 && diff <= 1;
};

export const isDateInThePast = (date?: string): boolean => {
  const diff = dayjs().diff(date, 'hours');
  return diff > 0;
};

export const YEARLY_MIN_INCOME_THRESHOLD =
  12 * insurance.healthInsurance.miniJobThreshold;

export const WAITING_PERIOD_DAYS = 31;

export const isMoveDateExceedingWaitingPeriod = (
  startDate: string,
  arrivalDate?: string
): boolean | null => {
  if (!arrivalDate) {
    return null;
  }
  return dayjs(startDate).diff(arrivalDate, 'days') > WAITING_PERIOD_DAYS;
};

export const mapPlanId: { [K in QuotePlan]: string } = {
  BASIC: 'Basic',
  PREMIUM: 'Premium',
};
