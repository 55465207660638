import { insurance } from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { OtherOccupation } from 'features/recommendationTool/models/shared';
import { HealthQuestionnaire } from 'models/recommendationTool';
import {
  StudentSituation,
  UnemploymentBenefits,
  University,
  WorkingMoreThanTwentyHours,
} from 'models/recommendationTool/healthQuestionnaire';

import { mainOcuppationMapping, otherOccupationMapping } from '../../shared';
import UniversityName from './components/UniversityName';
import {
  getUniversityStartDateYearRange,
  retrieveAnswerObject,
  retrieveNextPageId,
} from './utils';

const unemploymentBenefitsMapping: { [k in UnemploymentBenefits]: string } = {
  ALG1: 'Yes, Arbeitslosengeld 1',
  ALG2: 'Yes, Arbeitslosengeld 2',
  NONE: 'None of the above',
};

const studentSituationMapping: { [k in StudentSituation]: string } = {
  RECOGNIZED_UNIVERSITY: 'At a university in Germany',
  LANGUAGE_STUDENT: 'I’m a language student',
  OTHER: 'Somewhere else',
};

const workingMoreThanTwentyHoursMapping: {
  [K in WorkingMoreThanTwentyHours]: string;
} = {
  AS_EMPLOYEE: 'Yes, as an employee',
  AS_FREELANCER: 'Yes, I’m self-employed',
  NO: 'No',
};

export const financialHistory: HealthQuestionnaire = {
  occupation: {
    required: true,
    type: 'RADIO',
    props: {
      question: 'What is your main occupation in Germany?',
      description:
        "This can be for the future, too (e.g. if you're starting a job at a later date).",
      mapValue: mainOcuppationMapping,
      additionalInfo: {
        title: 'Working for an international company?',
        description:
          'If your company has an office in Germany or has a German tax accountant, you are considered to be employed in Germany.',
      },
    },
    retrieveAnswerObject: retrieveAnswerObject.occupationAnswerObject,
    retrieveNextPageId: retrieveNextPageId.occupationRetrieveNextPageId,
    groupId: 'financialHistory',
  },

  otherOccupation: {
    required: (answers) => answers.occupation === 'OTHER',
    type: 'RADIO',
    props: {
      question: 'What is your main occupation in Germany?',
      mapValue: otherOccupationMapping,
    },
    retrieveAnswerObject: (otherOccupation: OtherOccupation) => ({
      otherOccupation,
      occupation: otherOccupation,
    }),
    retrieveNextPageId: retrieveNextPageId.otherOccupationRetrieveNextPageId,
    groupId: 'financialHistory',
  },

  isRegisteredWithKsk: {
    required: (answers) => answers.occupation === 'ARTIST',
    type: 'BOOLEAN',
    props: {
      question: 'Are you registered as a professional artist with the KSK?',
      description:
        'The KSK (Künstlersozialkasse) is an organization that helps both beginner and experienced artists register for public health insurance.',
    },
    retrieveAnswerObject: (isRegisteredWithKsk: boolean) => ({
      isRegisteredWithKsk,
    }),
    retrieveNextPageId: () => 'totalIncome',
    groupId: 'financialHistory',
  },

  unemploymentBenefits: {
    required: (answers) => answers.occupation === 'UNEMPLOYED',
    type: 'RADIO',
    props: {
      question: 'Are you receiving unemployment benefits in Germany?',
      mapValue: unemploymentBenefitsMapping,
    },
    retrieveAnswerObject: (unemploymentBenefits: UnemploymentBenefits) => ({
      unemploymentBenefits,
    }),
    retrieveNextPageId: () => 'totalIncome',
    groupId: 'financialHistory',
  },

  studentSituation: {
    required: (answers) => answers.occupation === 'STUDENT',
    type: 'RADIO',
    props: {
      question: 'Where are you studying?',
      mapValue: studentSituationMapping,
    },
    retrieveAnswerObject: retrieveAnswerObject.studentSituationAnswerObject,
    retrieveNextPageId: retrieveNextPageId.studentSituationRetrieveNextPageId,
    groupId: 'financialHistory',
  },

  workingMoreThanTwentyHours: {
    required: (answers) => answers.studentSituation === 'RECOGNIZED_UNIVERSITY',
    type: 'RADIO',
    props: {
      question: 'Are you working more than 20 hours per week?',
      mapValue: workingMoreThanTwentyHoursMapping,
    },
    retrieveAnswerObject:
      retrieveAnswerObject.workingMoreThanTwentyHoursAnswerObject,
    retrieveNextPageId:
      retrieveNextPageId.workingMoreThanTwentyHoursRetrieveNextPageId,
    groupId: 'financialHistory',
  },

  universityName: {
    required: (answers) =>
      answers.occupation === 'STUDENT' &&
      answers.studentSituation === 'RECOGNIZED_UNIVERSITY' &&
      answers.isListedUniversity !== undefined,
    type: 'CUSTOM',
    component: UniversityName,
    retrieveAnswerObject: retrieveAnswerObject.universityNameAnswerObject,
    retrieveNextPageId: (answer?: University) =>
      answer ? 'universityStartDate' : 'totalIncome',
    groupId: 'financialHistory',
  },

  universityStartDate: {
    required: (answers) =>
      (answers &&
        answers.occupation === 'STUDENT' &&
        answers.isListedUniversity) ||
      false,
    type: 'DATE',
    props: {
      question: 'When did you or will you begin your studies?',
      description: 'This can be an approximate date if you’re not certain.',
      yearRange: getUniversityStartDateYearRange(),
    },
    retrieveAnswerObject: (universityStartDate: string) => ({
      universityStartDate,
    }),
    retrieveNextPageId: () => 'totalIncome',
    groupId: 'financialHistory',
  },

  isEmployedForTheFirstTime: {
    required: (answers) => answers.occupation === 'EMPLOYED',
    type: 'BOOLEAN',
    props: {
      question: 'Is this your first time being employed in Germany?',
      description:
        'Mini-jobs, student jobs, and unpaid internships don’t count.',
    },
    retrieveAnswerObject: (isEmployedForTheFirstTime: boolean) => ({
      isEmployedForTheFirstTime,
    }),
    retrieveNextPageId: () => 'employmentIncome',
    groupId: 'financialHistory',
  },

  employmentIncome: {
    required: (answers) => answers.occupation === 'EMPLOYED',
    type: 'CURRENCY',
    props: {
      question: 'What is your annual *employment* income before taxes?',
      description:
        'Only consider the income from your main employment at this step. If you have multiple jobs, enter the income from the one that pays the most.',
      placeholder: 'Annual income in euros',
      additionalInfo: {
        title: 'Why does my income matter?',
        description: `To be eligible for public health insurance, you need to make more than **${englishFormattedEuroCurrency(
          12 * insurance.healthInsurance.miniJobThreshold,
          true
        )}** per year (income from employment). If you're making less than ${englishFormattedEuroCurrency(
          12 * insurance.healthInsurance.miniJobThreshold,
          true
        )} (considered as a mini-job), you won't be able to sign up.\n\n If you're considering signing up for private health insurance, you need to have a total annual income above **${englishFormattedEuroCurrency(
          insurance.privateHealth.employeeThreshold,
          true
        )}**. This threshold changes annually. `,
      },
    },
    retrieveAnswerObject: (employmentIncome: number) => ({
      employmentIncome,
    }),
    retrieveNextPageId: () => 'additionalIncome',
    groupId: 'financialHistory',
  },

  additionalIncome: {
    required: (answers) => answers.occupation === 'EMPLOYED',
    type: 'CURRENCY',
    props: {
      question: 'What is your annual *additional* income before taxes?',
      description:
        'Include any income from other jobs, investments, retirement funds, etc.',
      placeholder: '0',
      hideLabel: true,
      label: 'Additional income in euros',
      defaultValue: 0,
      validate: () => true, // Accept empty values
    },
    retrieveAnswerObject: retrieveAnswerObject.additionalIncomeAnswerObject,
    retrieveNextPageId: () => 'dependents',
    groupId: 'financialHistory',
  },

  totalIncome: {
    required: (answers) => answers.occupation !== 'EMPLOYED',
    type: 'CURRENCY',
    props: {
      question: 'What is your total annual income before taxes?',
      description:
        'Include any income from side jobs, investments, retirement funds, etc.',
      validate: () => true,
    },
    retrieveAnswerObject: (totalIncome: number) => ({
      totalIncome,
    }),
    retrieveNextPageId: () => 'dependents',
    groupId: 'financialHistory',
  },
};
