import {
  associatedCountryFlagForCountryCode,
  getAllCountries,
} from '@getpopsure/public-models';
import { CollectableBillingAddress } from 'models/paymentMethods';
import { useEffect, useState } from 'react';
import { TFunction } from 'shared/i18n';

import { AddressInputView } from './AddressInput.view';

interface Props {
  t: TFunction;
  setAddress: (address: Partial<CollectableBillingAddress>) => void;
  address: Partial<CollectableBillingAddress>;
}

export interface Option {
  value: string;
  leftIcon?: string;
}

export const AddressInput = ({ address, setAddress, t }: Props) => {
  const [countryInput, setCountryInput] = useState(
    address?.country?.name ?? ''
  );
  const [suggestions, setSuggestions] = useState<Option[]>([]);

  useEffect(() => {
    if (address?.country?.name) {
      setCountryInput(address.country.name);
    }
  }, [address?.country]);

  /**
   * TODO: [KONG] Need locale here?
   */
  const countries = getAllCountries();

  const setSelectedCountry = (value: string) => {
    setCountryInput(value);
    const selectedCountry = countries.find(({ name }) => name === value);
    setAddress({ ...address, country: selectedCountry });
  };

  /**
   * TODO: [KONG] Test logic after creating custom hooks
   */
  const handleSuggestionFetchRequest = (value: string) => {
    const filteredOptions = countries
      .filter(({ name }) => {
        const countryWithMatchingFirstLetters = name
          .toLowerCase()
          .startsWith(value.toLowerCase());
        const countryWithMatchingNames = name
          .toLowerCase()
          .split(' ')
          .find((partialName) => partialName.startsWith(value.toLowerCase()));
        return countryWithMatchingFirstLetters || countryWithMatchingNames;
      })
      .map(({ name, code }) => ({
        value: name,
        leftIcon: associatedCountryFlagForCountryCode(code),
      }));
    setSuggestions(filteredOptions);
  };

  const clearSuggestions = () => {
    setSuggestions([]);
  };

  return (
    <AddressInputView
      t={t}
      suggestions={suggestions}
      clearSuggestions={clearSuggestions}
      countryInput={countryInput}
      setSelectedCountry={setSelectedCountry}
      handleSuggestionFetchRequest={handleSuggestionFetchRequest}
      setAddress={setAddress}
      address={address}
    />
  );
};
